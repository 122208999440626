import { useFetchResults } from './useFetchResults';
import moment from 'moment';

export const useFetchPatients = query => {
  const [patients, isLoading, requestMoreResults, refresh] = useFetchResults(query);
  const postProcessPatient = firestorePatient => ({
    ...firestorePatient,
    lastVisit: {
      ...firestorePatient.lastVisit,
      dateTime: moment(firestorePatient.lastVisit.dateTime.toDate()).format('YYYY-MM-DDTHH:mm'),
    },
  });

  return [patients.map(postProcessPatient), isLoading, requestMoreResults, refresh];
};
