import React, { createContext, useEffect, useState } from 'react';
import { Router } from './pages/Router';
import { useAuthUser } from './hooks/useAuthUser';
import { useFirestoreCollection } from './hooks/useFirestoreCollection';
import firebase from 'firebase'

export const AppContext = createContext({});

export const App = () => {
  const [authUser, isLoading] = useAuthUser();
  const [getFirestoreRef, setGetFirestoreRef] = useState(null);
  const dashboard = useFirestoreCollection(authUser, 'dashboard');
  const settings = useFirestoreCollection(authUser, 'settings');
  const [appContextValue, setAppContextValue] = useState({});

  useEffect(() => {
    const getFirestoreRef = (collection) => {
      if (!authUser) return;
      return firebase
        .firestore()
        .collection('users')
        .doc(authUser.uid)
        .collection(collection);
    };

    setGetFirestoreRef(() => getFirestoreRef);
  }, [authUser]);

  useEffect(() => {
    setAppContextValue({
      authUser,
      dashboard,
      getFirestoreRef,
      isLoading,
      settings,
    });
  }, [authUser, dashboard, getFirestoreRef, isLoading, settings]);

  return (
    <AppContext.Provider value={appContextValue}>
      <Router/>
    </AppContext.Provider>
  );
};
