import React, { useContext, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { useOpeningHours } from '../hooks/useOpeningHours'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AppContext } from '../App'
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: '100%',
  },
  datePicker: {
    minWidth: '100%',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  hidden: {
    display: 'none',
  }
}))

export const OpeningHoursPage = props => {
  const {authUser = {}} = useContext(AppContext)
  const [, isLoading, calendar, saveCalendar] = useOpeningHours(authUser.uid)
  const [showDefault, setShowDefault] = useState(false)
  const classes = useStyles()

  const onDelete = index => {
    saveCalendar(calendar.filter((_, idx) => idx !== index)).then()
  }

  const onAdd = () => {
    saveCalendar([...calendar, {
      type: 'off',
      date: new Date(),
    }]).then()
  }

  const onChanged = (index, key, newValue) => {
    if (key === 'type' && newValue === 'delete') {
      return onDelete(index)
    }

    saveCalendar(
      calendar
        .map((hours, idx) => index === idx ? {...hours, [key]: newValue} : hours)
        .map(cleanUpHours))
      .then()
  }

  const cleanUpHours = hours => {
    switch (hours.type) {
      case 'weekdays':
      case 'saturday':
      case 'sunday':
        return {
          type: hours.type,
          start: hours.start || new Date(),
          end: hours.end || new Date(),
        }
      case 'on':
        return {
          type: hours.type,
          date: hours.date || new Date(),
          start: hours.start || new Date(),
          end: hours.end || new Date(),
        }
      case 'off':
        return {
          type: hours.type,
          date: hours.date || new Date(),
        }
      default:
        return hours
    }
  }

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('opening_hours_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return (
    <Paper className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" color={'primary'} gutterBottom>
              Giờ khám bệnh
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid item xs={12}>
              <Grid container justify={'center'}>
                <CircularProgress/>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container justify={'center'}>
                <Button color="primary" onClick={() => {
                  setShowDefault(showDefault => !showDefault)
                }}>
                  {showDefault ? 'Ẩn' : 'Hiển thị'} giờ khám mặc định
                </Button>
              </Grid>
              {calendar.map((hours, index) => {
                const isDefaultHours = !['on', 'off'].includes(hours.type)
                const shouldHide = !showDefault && isDefaultHours
                return (
                  <Grid
                    className={shouldHide ? classes.hidden : null}
                    item
                    xs={12}
                    key={'calendar-hours-' + index}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="opening-hour-row-select-label">
                            Thời gian
                          </InputLabel>
                          <Select
                            labelId="opening-hour-row-select-label"
                            id="opening-hour-row-select"
                            value={hours.type}
                            onChange={e => onChanged(index, 'type', e.target.value)}
                          >
                            <MenuItem value={'weekdays'} disabled={!showDefault}>Thứ Hai đến Thứ Sáu</MenuItem>
                            <MenuItem value={'saturday'} disabled={!showDefault}>Thứ 7</MenuItem>
                            <MenuItem value={'sunday'} disabled={!showDefault}>Chủ Nhật</MenuItem>
                            <MenuItem value={'on'}>Chỉnh giờ khám</MenuItem>
                            <MenuItem value={'off'}>Nghỉ khám</MenuItem>
                            <MenuItem value={'delete'}>
                              <Typography color={'error'}>Xoá</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label="Ngày"
                          disabled={!['on', 'off'].includes(hours.type)}
                          onChange={date => onChanged(index, 'date', date)}
                          value={hours.date || null}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TimePicker
                          margin="normal"
                          label="Từ"
                          disabled={hours.type === 'off'}
                          value={hours.start || null}
                          onChange={time => onChanged(index, 'start', time)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TimePicker
                          margin="normal"
                          label="Đến"
                          disabled={hours.type === 'off'}
                          value={hours.end || null}
                          onChange={time => onChanged(index, 'end', time)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
              <Grid item xs={12}>
                <Grid container justify={'center'}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={onAdd}
                  >
                    Thêm
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  )
}

OpeningHoursPage.propTypes = {}
