import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PrescriptionPaper } from '../components/PrescriptionPaper'
import { makeStyles } from '@material-ui/core'
import { LoadingContainer } from '../components/LoadingContainer'
import { usePrescriptionPage } from '../hooks/usePrescriptionPage'
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '34em',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
  },
}))

export const PrescriptionPage = props => {
  const {username, visitId} = props.match.params
  const [visit, template, isLoading, isError] = usePrescriptionPage(username, visitId)
  const classes = useStyles()

  useEffect(() => {
    if (username && visitId) {
      firebase.analytics().logEvent('prescription_page_view', {
        username,
        visitId,
        timestamp: new Date(),
      })
    }
  }, [username, visitId])

  return (
    <LoadingContainer maxWidth="sm"
                      className={classes.container}
                      isLoading={isLoading}
                      isError={isError}>
      {visit && template && (
        <PrescriptionPaper visit={visit} patient={visit.patient} template={template}/>
      )}
    </LoadingContainer>
  )
}

PrescriptionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string.isRequired,
      visitId: PropTypes.string.isRequired,
    }),
  }),
}
