import { useContext, useEffect, useState } from 'react';
import { getSearchPatientsQuery } from '../pages/AddVisitPage/firebase';
import { getNormalisedText } from '../utils/string';
import { AppContext } from '../App';
import { useFetchPatients } from './useFetchPatients';

export const useSearchPatients = draftPatient => {
  const {getFirestoreRef} = useContext(AppContext);
  const [lastSearchData, setLastSearchData] = useState({});
  const [searchPatientQuery, setSearchPatientQuery] = useState(null);
  const [patients, isLoading, requestMoreResults, refresh] = useFetchPatients(searchPatientQuery);

  useEffect(() => {
    const searchProperties = ['lastName', 'middleName', 'firstName', 'gender', 'dateOfBirth'];
    const currentSearchData = {};
    searchProperties.forEach(key => currentSearchData[key] = getNormalisedText(draftPatient[key]));
    const hasNewSearchData = searchProperties.reduce((hasNewSearchData, key) =>
      hasNewSearchData || (lastSearchData[key] !== currentSearchData[key]), false);

    if (hasNewSearchData) {
      setSearchPatientQuery(getSearchPatientsQuery(draftPatient, getFirestoreRef));
      setLastSearchData(currentSearchData);
    }
  }, [draftPatient, getFirestoreRef, lastSearchData]);

  return [patients, isLoading, requestMoreResults, refresh];
};
