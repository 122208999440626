import { validateNotEmpty } from '../../utils/validation'

export const validateBooking = (draftPatient) => {
  const [isDateOfBirthValid, dateOfBirthError] = validateNotEmpty(draftPatient.dateOfBirth)
  const [isPhoneNumberValid, phoneNumberError] = validateNotEmpty(draftPatient.phoneNumber)

  return [isDateOfBirthValid && isPhoneNumberValid, {
    dateOfBirth: dateOfBirthError,
    phoneNumber: phoneNumberError,
  }]
}

export const validatePatient = (draftPatient) => {
  const [isFirstNameValid, firstNameError] = validateNotEmpty(draftPatient.firstName)
  const [isLastNameValid, lastNameError] = validateNotEmpty(draftPatient.lastName)
  const [isPhoneNumberValid, phoneNumberError] = validateNotEmpty(draftPatient.phoneNumber)

  return [isFirstNameValid && isLastNameValid && isPhoneNumberValid, {
    firstName: firstNameError,
    lastName: lastNameError,
    phoneNumber: phoneNumberError,
  }]
}

export const validateVisit = draftVisit => {
  const [isWeightValid, weightError] = validateNotEmpty(draftVisit.weight)
  const [isBodyTemperatureValid, bodyTemperatureError] = validateNotEmpty(draftVisit.bodyTemperature)

  return [isWeightValid && isBodyTemperatureValid, {
    weight: weightError,
    bodyTemperature: bodyTemperatureError,
  }]
}
