import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/performance';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './translation';

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCT2g22Wsicph1w8DqmT71qw57avwhnUQY',
  authDomain: 'clinic-f1937.firebaseapp.com',
  databaseURL: 'https://clinic-f1937.firebaseio.com',
  projectId: 'clinic-f1937',
  storageBucket: 'clinic-f1937.appspot.com',
  messagingSenderId: '536291187890',
  appId: '1:536291187890:web:8b0c7f217e23e110',
  measurementId: 'G-PJWLPPKD9D',
};
firebase.initializeApp(config);
// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
firebase.analytics();
firebase.performance();

// Enable offline data
db.enablePersistence({synchronizeTabs: true}).catch(err => console.error(err));

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
