import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { getNormalisedText, trimAndCapitalised } from '../utils/string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetchResults } from '../hooks/useFetchResults';
import { useAtBottomPage } from '../hooks/useAtBottomPage';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  actionCell: {
    minWidth: 160,
  },
  toolbar: {
    padding: theme.spacing(3, 2, 2, 2),
  },
}));

export const AsyncTable = props => {
  const {collectionFirestoreRef, removeMessage, title, onEdit, onDelete} = props;
  const allRecordQuery = collectionFirestoreRef.orderBy('name').limit(20);
  const classes = useStyles();
  const isAtBottomPage = useAtBottomPage();
  const [query, setQuery] = useState(allRecordQuery);
  const [fetchedResults, isLoading, requestMoreResults] = useFetchResults(query);
  const [results, setResults] = useState(fetchedResults);
  const [updatingIndex, setUpdatingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState(null);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (isAtBottomPage) requestMoreResults();
  }, [isAtBottomPage, requestMoreResults]);

  useEffect(() => setResults(fetchedResults), [fetchedResults]);

  const resetUpdatingState = () => {
    setUpdatingIndex(null);
    setEditingValue(null);
    setIsDeleteAction(false);
    setIsUpdating(false);
  };

  const onEditButtonClicked = recordIndex => {
    resetUpdatingState();
    setUpdatingIndex(recordIndex);
    setEditingValue(results[recordIndex].name);
  };

  const onDeleteButtonClicked = recordIndex => {
    resetUpdatingState();
    setUpdatingIndex(recordIndex);
    setIsDeleteAction(true);
  };

  const editRecord = async () => {
    if (editingValue === results[updatingIndex].name) {
      return resetUpdatingState();
    }

    setIsUpdating(true);
    const updatedResult = await onEdit(results[updatingIndex], editingValue);
    const updatedResults = results
      .map((result, index) => updatingIndex === index ? updatedResult : result);
    resetUpdatingState();
    setResults(updatedResults);
  };

  const deleteRecord = async () => {
    setIsUpdating(true);
    await onDelete(results[updatingIndex].id);
    const updatedResults = results.filter((_, index) => updatingIndex !== index);
    resetUpdatingState();
    setResults(updatedResults);
  };

  const onSaveButtonClicked = async () => {
    if (isDeleteAction) await deleteRecord();
    else await editRecord();
  };

  const search = searchInput => {
    if (!searchInput) {
      return setQuery(allRecordQuery);
    }

    const nameSearch = getNormalisedText(searchInput);
    const searchQuery = collectionFirestoreRef
      .where('nameSearch', '>=', nameSearch)
      .orderBy('nameSearch').limit(20);
    setQuery(searchQuery);
  };

  return (
    <Paper>
      <Grid className={classes.toolbar} container>
        <Grid item xs={8} md={9}>
          <Typography variant="h6" color={'primary'}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} md={3}>
          <TextField
            placeholder="Tìm kiếm..."
            type="search"
            fullWidth
            onChange={e => search(e.target.value)}
          />
        </Grid>
      </Grid>
      <Table size="small">
        <TableBody>
          {results.map((result, index) => (
            <TableRow key={result.id}>
              <TableCell component="th" scope="row">
                {updatingIndex === index && !isDeleteAction && (
                  <TextField fullWidth
                             value={editingValue}
                             onChange={e => setEditingValue(e.target.value)}
                             onBlur={e => setEditingValue(trimAndCapitalised(e.target.value))}
                  />
                )}
                {updatingIndex === index && isDeleteAction && removeMessage(result.name)}
                {updatingIndex !== index && result.name}
              </TableCell>
              <TableCell className={classes.actionCell} align="right">
                {updatingIndex === index ? (isUpdating ? (
                  <CircularProgress size={43}/>
                ) : (
                  <React.Fragment>
                    <IconButton onClick={() => onSaveButtonClicked(index)}>
                      <DoneIcon/>
                    </IconButton>
                    <IconButton onClick={resetUpdatingState}>
                      <CloseIcon/>
                    </IconButton>
                  </React.Fragment>
                )) : (
                  <React.Fragment>
                    <IconButton disabled={isUpdating} onClick={() => onEditButtonClicked(index)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton disabled={isUpdating} onClick={() => onDeleteButtonClicked(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </React.Fragment>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && <LinearProgress/>}
    </Paper>
  );
};

AsyncTable.propTypes = {
  collectionFirestoreRef: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  removeMessage: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
