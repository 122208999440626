import React, { useContext, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, Redirect } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import { prescriptionMockPatient, prescriptionMockVisit } from '../../constants/PrescriptionMockData';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase';
import { validateStep } from './validation';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../../components/LanguageSelector';
import { PrescriptionPaper } from '../../components/PrescriptionPaper';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © KiTrau '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  statusMessage: {
    marginLeft: theme.spacing(3),
  },
}));

export const SignUpPage = props => {
  const classes = useStyles();
  const {authUser} = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorProps, setErrorProps] = useState({});
  const [clinicName, setClinicName] = useState('');
  const [clinicAddress, setClinicAddress] = useState('');
  const [clinicContact, setClinicContact] = useState('');
  const [signatureName, setSignatureName] = useState('');
  const [signatureTitle, setSignatureTitle] = useState('');
  const {from} = props.location.state || {from: {pathname: '/'}};
  const {t} = useTranslation();
  const steps = [t('account'), t('clinic'), t('samplePrescription')];

  const getPrescriptionSetting = () => ({
    clinicName,
    clinicAddress,
    clinicContact,
    signatureName,
    signatureTitle,
  });

  const onNextStep = () => {
    const [isValid, errorProps] = validateStep(activeStep, {
      email, password, confirmPassword, ...getPrescriptionSetting(),
    });

    if (isValid) {
      setActiveStep(activeStep + 1);
      if (activeStep === steps.length - 1) createUser().then();
    } else {
      setErrorProps(errorProps);
    }
  };

  const onPreviousStep = () => {
    setErrorProps({});
    setActiveStep(activeStep - 1);
  };

  const createUser = async () => {
    setIsSigningUp(true);
    setStatusMessage('Đang tạo tài khoản...');
    try {
      const credential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      setStatusMessage('Lưu mẫu đơn thuốc...');
      await firebase
        .firestore()
        .collection('users')
        .doc(credential.user.uid)
        .collection('settings')
        .doc('prescription')
        .set(getPrescriptionSetting());
      setIsSigningUp(false);
    } catch (error) {
      setIsSigningUp(false);
      setStatusMessage(error.message);
    }
  };

  if (authUser && !isSigningUp) return <Redirect to={from}/>;
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('registration')}
        </Typography>
        <form className={classes.form} noValidate>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container spacing={2}>
            {activeStep === 0 && (
              <React.Fragment>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth label={t('email')}
                             value={email} onChange={e => setEmail(e.target.value)}
                             {...errorProps.email}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant="outlined" required fullWidth label={t('password')}
                             type="password"
                             value={password} onChange={e => setPassword(e.target.value)}
                             {...errorProps.password}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant="outlined" required fullWidth label={t('reenterPassword')}
                             type="password"
                             value={confirmPassword}
                             onChange={e => setConfirmPassword(e.target.value)}
                             {...errorProps.confirmPassword}/>
                </Grid>
              </React.Fragment>
            )}
            {activeStep === 1 && (
              <React.Fragment>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth label={t('clinicName')}
                             value={clinicName} onChange={e => setClinicName(e.target.value)}
                             {...errorProps.clinicName}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth label={t('address')}
                             value={clinicAddress} onChange={e => setClinicAddress(e.target.value)}
                             {...errorProps.clinicAddress}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth label={t('contactNumber')}
                             value={clinicContact} onChange={e => setClinicContact(e.target.value)}
                             {...errorProps.clinicContact}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant="outlined" required fullWidth label={t('signature')}
                             value={signatureName} onChange={e => setSignatureName(e.target.value)}
                             {...errorProps.signatureName}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant="outlined" fullWidth label={t('title')}
                             value={signatureTitle}
                             onChange={e => setSignatureTitle(e.target.value)}
                             {...errorProps.signatureTitle}/>
                </Grid>
              </React.Fragment>
            )}
            {activeStep === 2 && (
              <Grid item xs={12}>
                <PrescriptionPaper patient={prescriptionMockPatient}
                                   visit={prescriptionMockVisit}
                                   template={getPrescriptionSetting()}/>
              </Grid>
            )}
            {activeStep === 3 && (
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    {isSigningUp && <CircularProgress/>}
                    {!isSigningUp && <CloseIcon/>}
                  </Grid>
                  <Grid item>
                    <Typography className={classes.statusMessage} variant="body1"
                                color="textSecondary" align="center">
                      {statusMessage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep > 0 && !isSigningUp && (
              <Grid item xs={activeStep < steps.length ? 6 : 12}>
                <Button fullWidth variant="contained" color="primary" className={classes.submit}
                        onClick={onPreviousStep}>
                  {t('goBack')}
                </Button>
              </Grid>
            )}
            {activeStep < steps.length && (
              <Grid item xs={activeStep > 0 ? 6 : 12}>
                <Button fullWidth variant="contained" color="primary" className={classes.submit}
                        onClick={onNextStep}>
                  {activeStep < steps.length - 1 ? t('next') : t('createAccount')}
                </Button>
              </Grid>
            )}
          </Grid>
          {activeStep < steps.length && (
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/signin" className={classes.link}>{t('alreadyHaveAnAccount')}</Link>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
      <Box mt={5} mb={5}>
        <LanguageSelector/>
        <Copyright/>
      </Box>
    </Container>
  );
};
