import React, { useContext, useEffect } from 'react'
import { AsyncTable } from '../components/AsyncTable'
import { getNormalisedText } from '../utils/string'
import firebase from 'firebase'
import { AppContext } from '../App'

export const SymptomPage = props => {
  const {getFirestoreRef, authUser = {}} = useContext(AppContext)

  const removeMessage = symptomName => {
    return 'Xoá triệu chứng "' + symptomName + '" ?'
  }

  const prepareFirestoreSymptom = (symptom, newSymptomName) => {
    const symptomRef = getFirestoreRef('symptoms').doc(symptom.id)
    const updatedSymptom = {
      id: symptom.id,
      name: newSymptomName,
      nameSearch: getNormalisedText(newSymptomName),
      createdAt: symptom.createdAt,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    return [symptomRef, updatedSymptom]
  }

  const editFirestoreSymptom = async (symptom, newSymptomName) => {
    const [symptomRef, firestoreSymptom] = prepareFirestoreSymptom(symptom, newSymptomName)
    await symptomRef.set(firestoreSymptom)
    return firestoreSymptom
  }

  const deleteFirestoreSymptom = async symptomId => {
    await getFirestoreRef('symptoms').doc(symptomId).delete()
  }

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('symptom_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return <AsyncTable collectionFirestoreRef={getFirestoreRef('symptoms')}
                     onEdit={editFirestoreSymptom}
                     onDelete={deleteFirestoreSymptom}
                     removeMessage={removeMessage}
                     title={'Triệu chứng'}/>
}
