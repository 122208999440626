import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { LoadingContainer } from '../components/LoadingContainer'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useOpeningHours } from '../hooks/useOpeningHours'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { green, orange } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import red from '@material-ui/core/colors/red'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { Alert, AlertTitle } from '@material-ui/lab'
import { addDays, differenceInMinutes, format, formatDistanceToNow, isWithinInterval, } from 'date-fns'
import viLocale from 'date-fns/locale/vi'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { useUserId } from '../hooks/useUserId'
import { useClinicInfo } from '../hooks/useClinicInfo'
import { HowToReg } from '@material-ui/icons'
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  spaceDivider: {
    padding: theme.spacing(3),
  },
  activeIcon: {
    fill: green[500],
  },
  idleIcon: {
    fill: orange[500],
  },
  closedIcon: {
    fill: red[500],
  },
  weekdayBox: {
    width: theme.spacing(12),
  },
  timeBox: {
    width: theme.spacing(19),
  },
  today: {
    color: theme.palette.primary.main,
  },
}))

export const DoctorHomePage = props => {
  const {username} = props.match.params
  const [userId, isUserIdLoading, isUserIdError] = useUserId(username)
  const [clinicInfo, lastActive, isLoadingClinicInfo, isErrorClinicInfo] = useClinicInfo(userId)
  const [openingHours, isLoadingHours] = useOpeningHours(userId, 7)
  const isLoading = isUserIdLoading || isLoadingClinicInfo || isLoadingHours
  const isError = isUserIdError || isErrorClinicInfo
  const classes = useStyles()
  const now = new Date()
  const todayRanges = openingHours ? (openingHours[format(now, 'yyyy-MM-dd')] || []) : []
  const isOpened =
    todayRanges.reduce(
      (isOpened, range) => isOpened || isWithinInterval(now, {
        start: range[0],
        end: range[1],
      }), false)

  const getDistanceToNowString = (lastActive) => {
    if (!lastActive) {
      return ''
    }
    return ' (cập nhật '
      + formatDistanceToNow(lastActive, {locale: viLocale, includeSeconds: true})
      + ' trước)'
  }

  useEffect(() => {
    if (username && lastActive) {
      firebase.analytics().logEvent('doctor_home_page_view', {
        username,
        lastActive,
        timestamp: new Date(),
      })
    }
  }, [username, lastActive])

  return (
    <LoadingContainer maxWidth="md"
                      isLoading={isLoading}
                      isError={isError}>
      {clinicInfo && !isLoading && !isError && (
        <Paper className={classes.root} elevation={3}>
          <Grid container justify={'center'}>
            <Typography color={'primary'} variant={'h6'}>{clinicInfo.clinicName}</Typography>
          </Grid>
          <Grid container justify={'center'}>
            <Typography color={'primary'}>
              {clinicInfo.signatureTitle}
            </Typography>
          </Grid>
          <Grid container justify={'center'}>
            <Typography>{clinicInfo.clinicAddress}</Typography>
          </Grid>
          <Grid container justify={'center'}>
            <Typography>{clinicInfo.clinicContact}</Typography>
          </Grid>
          {clinicInfo.announcement && (
            <>
              <div className={classes.spaceDivider}/>
              <Grid container justify={'center'}>
                <Grid item xs={12} sm={8} md={6}>
                  <Alert severity="error">
                    <AlertTitle>Thông báo</AlertTitle>
                    {clinicInfo.announcement}
                  </Alert>
                </Grid>
              </Grid>
            </>
          )}
          <div className={classes.spaceDivider}/>
          <Grid container justify={'center'}>
            <Chip
              avatar={isOpened ? (lastActive && differenceInMinutes(now, lastActive) > 20 ? (
                <RadioButtonCheckedIcon className={classes.idleIcon}/>) : (
                <CheckCircleIcon className={classes.activeIcon}/>)) : (
                <RemoveCircleIcon className={classes.closedIcon}/>)}
              variant="outlined"
              clickable
              label={
                (isOpened
                  ? (lastActive && differenceInMinutes(now, lastActive) > 20
                    ? 'Nhàn rỗi' : 'Đang khám bệnh') : 'Ngoài giờ khám')
                + getDistanceToNowString(lastActive)}/>
          </Grid>
          <div className={classes.spaceDivider}/>
          <Grid container justify={'center'}>
            <Grid item>
              {[...Array(7).keys()].map(d => {
                const date = addDays(now, d)
                const dateStr = format(date, 'yyyy-MM-dd')
                const isToday = d === 0
                return (
                  <Grid container spacing={2} key={'calendar-row-' + dateStr}>
                    <Grid item>
                      <Typography component="div">
                        <Box
                          className={clsx(classes.weekdayBox, {[classes.today]: isToday})}
                          fontWeight={isToday ? 500 : null}
                        >
                          {format(date, 'cccc', {locale: viLocale})}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {openingHours[dateStr].map((range, idx) => (
                        <Grid container key={'time-row-' + dateStr + '-' + idx}>
                          <Typography component="div">
                            <Box
                              className={clsx(classes.timeBox, {[classes.today]: isToday})}
                              fontWeight={isToday ? 500 : null}
                            >
                              {format(range[0], 'h:mma') + ' - ' + format(range[1], 'h:mma')}
                            </Box>
                          </Typography>
                        </Grid>
                      ))}
                      {(!openingHours || !openingHours[dateStr] || openingHours[dateStr].length === 0) && (
                        <Grid container key={'time-row-' + dateStr}>
                          <Typography component="div">
                            <Box
                              className={clsx(classes.timeBox, {[classes.today]: isToday})}
                              fontWeight={isToday ? 500 : null}
                            >
                              Nghỉ
                            </Box>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <div className={classes.spaceDivider}/>
          <Grid item xs={12}>
            <Grid container justify={'center'}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<HowToReg/>}
                onClick={() => props.history.push(props.history.location.pathname + '/r')}
              >
                Đăng ký khám bệnh
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </LoadingContainer>
  )
}

DoctorHomePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }),
  }),
}
