import React, { Component } from 'react'
import logo from '../../assets/logo.svg'
import './InitialLoader.css'

class InitialLoader extends Component {
  render() {
    return (
      <header className="InitialLoader-header">
        <img src={logo} className="InitialLoader-logo" alt="logo"/>
        <p>
          <code>Đang tải...</code>
        </p>
      </header>
    )
  }
}

export default InitialLoader
