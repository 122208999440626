import { useState } from 'react'
import firebase from 'firebase'

export const useBooking = (userId) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)


  const addToQueue = async (visitDateString, draftPatient) => {
    if (!userId) {
      console.error(`UserId is empty`)
      return
    }

    setIsLoading(true)
    try {
      const dbRef = firebase.firestore().collection('users').doc(userId)
      const queueRef = dbRef.collection('queue')
      const counterRef = dbRef.collection('queueCounters')
      await counterRef
        .doc(visitDateString)
        .set({counter: firebase.firestore.FieldValue.increment(1)}, {merge: true})
      const counterSnapshot = await counterRef.doc(visitDateString).get()

      if (!counterSnapshot.exists) {
        console.error(`Error updating queue counter for date=${visitDateString}`)
        setIsLoading(false)
        setIsError(true)
        return
      }
      const counter = counterSnapshot.data().counter
      await queueRef.add({
        visitDate: visitDateString,
        draftPatient,
        number: counter,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      setIsLoading(false)
      setIsError(false)
      return counter
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setIsError(true)
    }
  }

  return [addToQueue, isLoading, isError]
}
