import { useEffect, useState } from 'react';
import firebase from 'firebase';

export const useFirestoreCollection = (authUser, collection) => {
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    if (!authUser || !collection) return setDocuments(null);

    return firebase
      .firestore()
      .collection('users')
      .doc(authUser.uid)
      .collection(collection)
      .onSnapshot(snapshot => {
        const documents = {};
        snapshot.forEach(doc => {
          documents[doc.id] = doc.data();
        });
        setDocuments(documents);
      });
  }, [authUser, collection]);

  return documents;
};
