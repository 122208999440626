import { useEffect, useState } from 'react';
import firebase from 'firebase';

export const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      setAuthUser(user);
      setIsLoading(false);
    });
  }, []);

  return [authUser, isLoading];
};
