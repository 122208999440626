import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { useFetchResults } from '../../hooks/useFetchResults';
import { TagInputComponents } from './components';
import { getStyles } from './styles';
import { trimAndCapitalised } from '../../utils/string';

const useStyles = makeStyles(getStyles);

export const AsyncTagInput = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {label, isMulti, value, handleInputChange, query, resultToTag} = props;
  const [results, isLoading, requestMoreResults] = useFetchResults(query);

  const handleChange = options => {
    props.handleChange(options ? options : (isMulti ? [] : null));
  };

  const handleBlur = e => {
    const name = e.target.value;
    if (name) {
      const newValue = {label: name, value: name};
      if (Array.isArray(value)) {
        // Dont allow duplicate values
        if (value.find(v => v.label === trimAndCapitalised(name))) {
          return handleChange(value);
        }
        handleChange([...value, newValue]);
      } else if (isMulti) {
        handleChange([newValue]);
      } else {
        handleChange(newValue);
      }
    }
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <CreatableSelect
          classes={classes} styles={selectStyles} inputId={`react-select-multiple-${label}`}
          components={TagInputComponents}
          TextFieldProps={{
            label: label,
            InputLabelProps: {htmlFor: `react-select-multiple-${label}`, shrink: true},
          }}
          isMulti={isMulti} filterOption={null} isClearable
          value={value} onChange={handleChange}
          onInputChange={handleInputChange}
          options={results.map(result => resultToTag(result))} isLoading={isLoading}
          onMenuScrollToBottom={requestMoreResults}
          createOptionPosition="first"
          placeholder="Chọn..."
          formatCreateLabel={value => 'Thêm "' + value + '"'}
          noOptionsMessage={() => 'Không tìm thấy kết quả'}
          loadingMessage={() => 'Đang tìm...'}
          onBlur={handleBlur}
        />
      </NoSsr>
    </div>
  );

};

AsyncTagInput.propTypes = {
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  query: PropTypes.object,
  resultToTag: PropTypes.func.isRequired,
};
