import { useEffect, useState } from 'react'

export const useFetchResults = (query) => {
  const [results, setResults] = useState([])
  const [ids, setIds] = useState([])
  const refresh = () => setPendingRequest({shouldOverwriteResults: true})
  const requestMoreResults = () => setPendingRequest(pendingRequest => {
    return pendingRequest ? pendingRequest : {shouldOverwriteResults: false}
  })

  // Internal state
  const [pendingRequest, setPendingRequest] = useState({shouldOverwriteResults: true})
  const [isFetching, setIsFetching] = useState(false)
  const [lastFetchedDocument, setLastFetchedDocument] = useState(null)
  const isLoading = !!pendingRequest || isFetching

  useEffect(() => {
    setPendingRequest({shouldOverwriteResults: true})
  }, [query])

  useEffect(() => {
    const fetchMoreResults = async () => {
      const {shouldOverwriteResults} = pendingRequest
      if (shouldOverwriteResults) {
        setResults([])
        setIds([])
        setLastFetchedDocument(null)
      }

      setIsFetching(true)
      setPendingRequest(null)
      if (!query) return setIsFetching(false)

      const snapshot = shouldOverwriteResults
        ? await query.get()
        : await query.startAfter(lastFetchedDocument).get()
      const extraResults = snapshot.docs.map(doc => doc.data())
      const extraIds = snapshot.docs.map(doc => doc.id)
      setResults(oldResults => shouldOverwriteResults ? extraResults : [...oldResults, ...extraResults])
      setIds(oldIds => shouldOverwriteResults ? extraIds : [...oldIds, ...extraIds])
      if (snapshot.docs.length > 0) setLastFetchedDocument(snapshot.docs[snapshot.docs.length - 1])
      setIsFetching(false)
    }

    if (pendingRequest && !isFetching) {
      fetchMoreResults().then()
    }
  }, [pendingRequest, isFetching, query, lastFetchedDocument])

  return [results, isLoading, requestMoreResults, refresh, ids]
}
