import firebase from 'firebase'
import { getNormalisedText } from '../../utils/string'
import { getPatientRecordInVisit } from '../../utils/firebase'
import moment from 'moment'
import { Gender } from '../../constants/GenderEnum'

export const prepareFirestoreVisit = (patient, visit, preparedFirestoreSymptoms, preparedFirestoreDrugs, getFirestoreRef) => {
  const isVisitCreated = !!visit.id
  const newVisitId = getFirestoreRef('visits').doc().id
  const visitId = isVisitCreated ? visit.id : newVisitId
  const visitRef = getFirestoreRef('visits').doc(visitId)

  const isPatientCreated = !!patient.id
  const newPatientId = getFirestoreRef('patients').doc().id
  const patientId = isPatientCreated ? patient.id : newPatientId

  const firestoreVisitDateTime = moment(visit.dateTime).toDate()
  const firestoreServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

  const patientRecordInVisit = {
    ...getPatientRecordInVisit(patient),
    id: patientId,
  }

  const firestoreSymptomsInVisit = preparedFirestoreSymptoms.map(([, firestoreSymptom]) => {
    const symptomInVisit = {
      id: firestoreSymptom.id,
      name: firestoreSymptom.name,
    }
    return symptomInVisit
  })

  const firestoreDrugsInVisit = preparedFirestoreDrugs.map(([, firestoreDrug], drugIndex) => {
    const drugInVisit = {
      id: firestoreDrug.id,
      name: firestoreDrug.name,
      instruction: visit.prescribedDrugs[drugIndex].instruction,
      quantity: visit.prescribedDrugs[drugIndex].quantity,
    }
    return drugInVisit
  })

  const symptomIds = firestoreSymptomsInVisit.map(symptom => symptom.id)

  const prescribedDrugIds = firestoreDrugsInVisit.map(drug => drug.id)

  return [visitRef, {
    ...visit,
    id: visitId,
    patient: patientRecordInVisit,
    symptomIds,
    symptoms: firestoreSymptomsInVisit,
    prescribedDrugIds,
    prescribedDrugs: firestoreDrugsInVisit,
    dateTime: firestoreVisitDateTime,
    createdAt: isVisitCreated ? visit.createdAt : firestoreServerTimestamp,
    updatedAt: firestoreServerTimestamp,
  }]
}

export const prepareFirestorePatient = (patient, firestoreVisit, getFirestoreRef) => {
  const isPatientCreated = !!patient.id
  const patientId = firestoreVisit.patient.id
  const patientRef = getFirestoreRef('patients').doc(patientId)
  const firestoreServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

  return [patientRef, {
    ...patient,
    id: patientId,
    firstNameSearch: getNormalisedText(patient.firstName),
    middleNameSearch: getNormalisedText(patient.middleName),
    lastNameSearch: getNormalisedText(patient.lastName),
    lastVisit: {
      id: firestoreVisit.id,
      primaryDiagnosis: firestoreVisit.primaryDiagnosis,
      dateTime: firestoreVisit.dateTime,
    },
    createdAt: isPatientCreated ? patient.createdAt : firestoreServerTimestamp,
    updatedAt: firestoreServerTimestamp,
  }]
}

export const prepareFirestoreSymptoms = (visit, getFirestoreRef) => {
  return visit.symptoms.map(symptom => {
    const isSymptomCreated = !!symptom.id
    const newSymptomId = getFirestoreRef('symptoms').doc().id
    const symptomId = isSymptomCreated ? symptom.id : newSymptomId
    const symptomRef = getFirestoreRef('symptoms').doc(symptomId)
    const firestoreServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    return [symptomRef, {
      id: symptomId,
      name: symptom.name,
      nameSearch: getNormalisedText(symptom.name),
      createdAt: isSymptomCreated ? symptom.createdAt : firestoreServerTimestamp,
      updatedAt: firestoreServerTimestamp,
    }]
  })
}

export const prepareFirestoreDrugs = (visit, getFirestoreRef) => {
  return visit.prescribedDrugs.map(drug => {
    const isDrugCreated = !!drug.id
    const newDrugId = getFirestoreRef('drugs').doc().id
    const drugId = isDrugCreated ? drug.id : newDrugId
    const drugRef = getFirestoreRef('drugs').doc(drugId)
    const firestoreServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    return [drugRef, {
      id: drugId,
      name: drug.name,
      nameSearch: getNormalisedText(drug.name),
      defaultUsage: {
        instruction: drug.instruction,
        quantity: drug.quantity,
      },
      createdAt: isDrugCreated ? drug.createdAt : firestoreServerTimestamp,
      updatedAt: firestoreServerTimestamp,
    }]
  })
}

export const prepareFirestoreVisitCount = (visit, getFirestoreRef) => {
  const visitCountRef = getFirestoreRef('dashboard').doc('visit_count')
  const visitCountKey = moment(visit.dateTime).format('YYYY-MM-DD')
  return [visitCountRef, {
    [visitCountKey]: firebase.firestore.FieldValue.increment(1),
  }]
}

export const getSearchPatientsQuery = (draftPatient, getFirestoreRef) => {
  const todayDate = moment().format('YYYY-MM-DD')
  const isDefaultDateOfBirth = !draftPatient.dateOfBirth || todayDate === draftPatient.dateOfBirth
  const firstNameSearch = getNormalisedText(draftPatient.firstName)
  const middleNameSearch = getNormalisedText(draftPatient.middleName)
  const lastNameSearch = getNormalisedText(draftPatient.lastName)
  const isDefaultGender = draftPatient.gender === Gender.UNKNOWN
  const isDraftPatientEmpty = !firstNameSearch && !middleNameSearch && !lastNameSearch &&
    isDefaultDateOfBirth && isDefaultGender && !draftPatient.phoneNumber
  let query = getFirestoreRef('patients')
  query = firstNameSearch ? query.where('firstNameSearch', '==', firstNameSearch) : query
  query = middleNameSearch ? query.where('middleNameSearch', '==', middleNameSearch) : query
  query = lastNameSearch ? query.where('lastNameSearch', '==', lastNameSearch) : query
  query = !isDefaultDateOfBirth ? query.where('dateOfBirth', '==', draftPatient.dateOfBirth) : query
  query = !isDefaultGender ? query.where('gender', '==', draftPatient.gender) : query
  query = draftPatient.phoneNumber ? query.where('phoneNumber', '==', draftPatient.phoneNumber) : query
  return isDraftPatientEmpty ? null : query.orderBy('lastVisit.dateTime', 'desc').limit(10)
}
