export const trimAndCapitalised = (text) => {
  if (!text) return '';

  const trimmedText = text.split(' ').filter(Boolean).join(' ');
  return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1).toLowerCase();
};

export const getNormalisedText = (text) => {
  if (!text) return '';

  return text.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace('đ', 'd')
    .split(' ')
    .filter(Boolean)
    .join(' ');
};
