export const Diseases = [{'id': 'A00', 'english': 'Cholera', 'vietnamese': 'Bệnh tả'}, {
  'id': 'A00.0',
  'english': 'Cholera due to Vibrio cholerae 01, biovar cholerae',
  'vietnamese': 'Bệnh tả do Vibrio cholerae 01, typ sinh học cholerae',
}, {
  'id': 'A00.1',
  'english': 'Cholera due to Vibrio cholerae 01, biovar eltor',
  'vietnamese': 'Bệnh tả do Vibrio cholerae 01, typ sinh học eltor',
}, {'id': 'A00.9', 'english': 'Cholera, unspecified', 'vietnamese': 'Bệnh tả, không đặc hiệu'}, {
  'id': 'A01',
  'english': 'Typhoid and paratyphoid fevers',
  'vietnamese': 'Bệnh thương hàn và phó thương hàn',
}, {'id': 'A01.0', 'english': 'Typhoid fever', 'vietnamese': 'Thương hàn'}, {
  'id': 'A01.1',
  'english': 'Paratyphoid fever A',
  'vietnamese': 'Bệnh phó thương hàn A',
}, {'id': 'A01.2', 'english': 'Paratyphoid fever B', 'vietnamese': 'Bệnh phó thương hàn B'}, {
  'id': 'A01.3',
  'english': 'Paratyphoid fever C',
  'vietnamese': 'Bệnh phó thương hàn C',
}, {
  'id': 'A01.4',
  'english': 'Paratyphoid fever, unspecified',
  'vietnamese': 'Bệnh phó thương hàn, không đặc hiệu',
}, {
  'id': 'A02',
  'english': 'Other salmonella infections',
  'vietnamese': 'Nhiễm salmonella khác',
}, {
  'id': 'A02.0',
  'english': 'Salmonella enteritis',
  'vietnamese': 'Viêm ruột do Salmonella',
}, {
  'id': 'A02.1',
  'english': 'Salmonella septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do Salmonella',
}, {
  'id': 'A02.2',
  'english': 'Localized salmonella infections',
  'vietnamese': 'Nhiễm salmonella khu trú',
}, {
  'id': 'A02.8',
  'english': 'Other specified salmonella infections',
  'vietnamese': 'Nhiễm trùng salmonella xác định khác',
}, {
  'id': 'A02.9',
  'english': 'Salmonella infection, unspecified',
  'vietnamese': 'Nhiễm trùng salmonella, không đặc hiệu khác',
}, {'id': 'A03', 'english': 'Shigellosis', 'vietnamese': 'Bệnh lỵ trực khuẩn'}, {
  'id': 'A03.0',
  'english': 'Shigellosis due to Shigella dysenteriae',
  'vietnamese': 'Bệnh lỵ trực khuẩn do Shigella dysenteriae',
}, {
  'id': 'A03.1',
  'english': 'Shigellosis due to Shigella flexneri',
  'vietnamese': 'Bệnh lỵ trực khuẩn do Shigella flexneri',
}, {
  'id': 'A03.2',
  'english': 'Shigellosis due to Shigella boydii',
  'vietnamese': 'Bệnh lỵ trực khuẩn do Shigella boydii',
}, {
  'id': 'A03.3',
  'english': 'Shigellosis due to Shigella sonnei',
  'vietnamese': 'Bệnh lỵ trực khuẩn do Shigella sonnei',
}, {
  'id': 'A03.8',
  'english': 'Other shigellosis',
  'vietnamese': 'Bệnh lỵ trực khuẩn do Shigella khác',
}, {
  'id': 'A03.9',
  'english': 'Shigellosis, unspecified',
  'vietnamese': 'Bệnh lỵ trực khuẩn, không đặc hiệu',
}, {
  'id': 'A04',
  'english': 'Other bacterial intestinal infections',
  'vietnamese': 'Nhiễm trùng đường ruột do vi khuẩn khác',
}, {
  'id': 'A04.0',
  'english': 'Enteropathogenic Escherichia coli infection',
  'vietnamese': 'Nhiễm Escherichia coli gây bệnh đường ruột',
}, {
  'id': 'A04.1',
  'english': 'Enterotoxigenic Escherichia coli infection',
  'vietnamese': 'Nhiễm Escherichia coli gây độc tố ruột',
}, {
  'id': 'A04.2',
  'english': 'Enteroinvasive Escherichia coli infection',
  'vietnamese': 'Nhiễm Escherichia coli xâm nhập',
}, {
  'id': 'A04.3',
  'english': 'Enterohaemorrhagic Escherichia coli infection',
  'vietnamese': 'Nhiễm Escherichia coli gây xuất huyết đường ruột',
}, {
  'id': 'A04.4',
  'english': 'Other intestinal Escherichia coli infections',
  'vietnamese': 'Nhiễm Escherichia coli đường ruột khác',
}, {
  'id': 'A04.5',
  'english': 'Campylobacter enteritis',
  'vietnamese': 'Viêm ruột do Campylobacter',
}, {
  'id': 'A04.6',
  'english': 'Enteritis due to Yersinia enterocolitica',
  'vietnamese': 'Viêm ruột do Yersinia enterocolitica',
}, {
  'id': 'A04.7',
  'english': 'Enterocolitis due to Clostridium difficile',
  'vietnamese': 'Viêm ruột do Clostridium difficile',
}, {
  'id': 'A04.8',
  'english': 'Other specified bacterial intestinal infections',
  'vietnamese': 'Nhiễm trùng đường ruột do vi khuẩn xác định khác',
}, {
  'id': 'A04.9',
  'english': 'Bacterial intestinal infection, unspecified',
  'vietnamese': 'Nhiễm trùng đường ruột do vi khuẩn, không xác định loài',
}, {
  'id': 'A05',
  'english': 'Other bacterial foodborne intoxications, not elsewhere classified',
  'vietnamese': 'Nhiễm độc thức ăn do vi trùng khác, chưa phân loại nơi khác',
}, {
  'id': 'A05.0',
  'english': 'Foodborne staphylococcal intoxication',
  'vietnamese': 'Nhiễm độc thức ăn do độc tố của tụ cầu',
}, {'id': 'A05.1', 'english': 'Botulism', 'vietnamese': 'Bệnh ngộ độc thịt (ngộ độc đồ hộp)'}, {
  'id': 'A05.2',
  'english': 'Foodborne Clostridium perfringens [Clostridium welchii] intoxication',
  'vietnamese': 'Nhiễm độc thức ăn do độc tố của Clostridium perfringens [Clostridium welchii]',
}, {
  'id': 'A05.3',
  'english': 'Foodborne Vibrio parahaemolyticus intoxication',
  'vietnamese': 'Nhiễm độc thức ăn do độc tố của Vibrio parahaemolyticus',
}, {
  'id': 'A05.4',
  'english': 'Foodborne Bacillus cereus intoxication',
  'vietnamese': 'Nhiễm độc thức ăn do độc tố của Bacillus cereus',
}, {
  'id': 'A05.8',
  'english': 'Other specified bacterial foodborne intoxications',
  'vietnamese': 'Nhiễm độc thức ăn do độc tố của vi khuẩn xác định khác',
}, {
  'id': 'A05.9',
  'english': 'Bacterial foodborne intoxication, unspecified',
  'vietnamese': 'Nhiễm độc thức ăn do vi khuẩn, không xác định loài',
}, {'id': 'A06', 'english': 'Amoebiasis', 'vietnamese': 'Bệnh do amíp'}, {
  'id': 'A06.0',
  'english': 'Acute amoebic dysentery',
  'vietnamese': 'Bệnh lỵ amíp cấp',
}, {
  'id': 'A06.1',
  'english': 'Chronic intestinal amoebiasis',
  'vietnamese': 'Bệnh lỵ amíp đường ruột mãn tính',
}, {
  'id': 'A06.2',
  'english': 'Amoebic nondysenteric colitis',
  'vietnamese': 'Viêm đại tràng do amíp không gây hội chứng lỵ',
}, {'id': 'A06.3', 'english': 'Amoeboma of intestine', 'vietnamese': 'U do amíp đường ruột'}, {
  'id': 'A06.4',
  'english': 'Amoebic liver abscess',
  'vietnamese': 'Áp xe gan do amíp',
}, {
  'id': 'A06.5†',
  'english': 'Amoebic lung abscess (J99.8*)',
  'vietnamese': 'Áp xe phổi do amíp (J99.8*)',
}, {
  'id': 'A06.6†',
  'english': 'Amoebic brain abscess (G07*)',
  'vietnamese': 'Áp xe não do amíp (G07*)',
}, {'id': 'A06.7', 'english': 'Cutaneous amoebiasis', 'vietnamese': 'Nhiễm amíp ở da'}, {
  'id': 'A06.8',
  'english': 'Amoebic infection of other sites',
  'vietnamese': 'Nhiễm amíp ở vị trí khác',
}, {
  'id': 'A06.9',
  'english': 'Amoebiasis, unspecified',
  'vietnamese': 'Bệnh do amíp, không xác định loài',
}, {
  'id': 'A07',
  'english': 'Other protozoal intestinal diseases',
  'vietnamese': 'Bệnh đường ruột do đơn bào khác',
}, {'id': 'A07.0', 'english': 'Balantidiasis', 'vietnamese': 'Bệnh do Balantidium'}, {
  'id': 'A07.1',
  'english': 'Giardiasis [lambliasis]',
  'vietnamese': 'Bệnh do Giardia [lamblia]',
}, {'id': 'A07.2', 'english': 'Cryptosporidiosis', 'vietnamese': 'Bệnh do Cryptosporidia'}, {
  'id': 'A07.3',
  'english': 'Isosporiasis',
  'vietnamese': 'Bệnh do lsospora',
}, {
  'id': 'A07.8',
  'english': 'Other specified protozoal intestinal diseases',
  'vietnamese': 'Bệnh nhiễm trùng đường ruột do đơn bào xác định khác',
}, {
  'id': 'A07.9',
  'english': 'Protozoal intestinal disease, unspecified',
  'vietnamese': 'Bệnh đường ruột do đơn bào, không xác định loài',
}, {
  'id': 'A08',
  'english': 'Viral and other specified intestinal infections',
  'vietnamese': 'Nhiễm trùng đường ruột do virus và tác nhân xác định khác',
}, {'id': 'A08.0', 'english': 'Rotaviral enteritis', 'vietnamese': 'Viêm ruột do rotavirus'}, {
  'id': 'A08.1',
  'english': 'Acute gastroenteropathy due to Norwalk agent',
  'vietnamese': 'Bệnh lý dạ dày ruột cấp do tác nhân Norwalk',
}, {'id': 'A08.2', 'english': 'Adenoviral enteritis', 'vietnamese': 'Viêm ruột do Adenovirus'}, {
  'id': 'A08.3',
  'english': 'Other viral enteritis',
  'vietnamese': 'Viêm ruột do virus khác',
}, {
  'id': 'A08.4',
  'english': 'Viral intestinal infection, unspecified',
  'vietnamese': 'Nhiễm trùng đường ruột do virus, không xác định loài',
}, {
  'id': 'A08.5',
  'english': 'Other specified intestinal infections',
  'vietnamese': 'Nhiễm trùng đường ruột, xác định khác',
}, {
  'id': 'A09',
  'english': 'Other gastroenteritis and colitis of infectious and unspecified origin',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng do nguyên nhân nhiễm trùng và chưa xác định được nguồn gốc nhiễm trùng',
}, {
  'id': 'A09.0',
  'english': 'Other and unspecified gastroenteritis and colitis of infectious origin',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng khác do nhiễm trùng và không xác định loài',
}, {
  'id': 'A09.9',
  'english': 'Gastroenteritis and colitis of unspecified origin',
  'vietnamese': 'Viêm dạ dày - ruột và viêm đại tràng khác không rõ nguyên nhân',
}, {
  'id': 'A15',
  'english': 'Respiratory tuberculosis, bacteriologically and histologically confirmed',
  'vietnamese': 'Lao hô hấp, có xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A15.0',
  'english': 'Tuberculosis of lung, confirmed by sputum microscopy with or without culture',
  'vietnamese': 'Lao phổi, xác nhận bằng soi đờm có cấy hoặc không cấy đờm',
}, {
  'id': 'A15.1',
  'english': 'Tuberculosis of lung, confirmed by culture only',
  'vietnamese': 'Lao phổi, chỉ xác nhận bằng nuôi cấy',
}, {
  'id': 'A15.2',
  'english': 'Tuberculosis of lung, confirmed histologically',
  'vietnamese': 'Lao phổi, xác nhận về mô học',
}, {
  'id': 'A15.3',
  'english': 'Tuberculosis of lung, confirmed by unspecified means',
  'vietnamese': 'Lao phổi được xác nhận bằng những phương pháp không xác định loài',
}, {
  'id': 'A15.4',
  'english': 'Tuberculosis of intrathoracic lymph nodes, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao hạch lympho trong lồng ngực, xác nhận về vi trùng học hoặc mô học',
}, {
  'id': 'A15.5',
  'english': 'Tuberculosis of larynx, trachea and bronchus, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao thanh quản, khí quản và phế quản, xác nhận về vi trùng học và mô học',
}, {
  'id': 'A15.6',
  'english': 'Tuberculous pleurisy, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao màng phổi, xác nhận về vi trùng học và mô học',
}, {
  'id': 'A15.7',
  'english': 'Primary respiratory tuberculosis, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao hô hấp sơ nhiễm, xác nhận về vi trùng học và mô học',
}, {
  'id': 'A15.8',
  'english': 'Other respiratory tuberculosis, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao hô hấp khác, xác nhận về vi trùng học và mô học',
}, {
  'id': 'A15.9',
  'english': 'Respiratory tuberculosis unspecified, confirmed bacteriologically and histologically',
  'vietnamese': 'Lao hô hấp không xác định, xác nhận về vi trùng học và mô học',
}, {
  'id': 'A16',
  'english': 'Respiratory tuberculosis, not confirmed bacteriologically or histologically',
  'vietnamese': 'Lao đường hô hấp, không xác nhận về vi khuẩn học hoặc mô học',
}, {
  'id': 'A16.0',
  'english': 'Tuberculosis of lung, bacteriologically and histologically negative',
  'vietnamese': 'Lao phổi, âm tính về vi khuẩn học và mô học',
}, {
  'id': 'A16.1',
  'english': 'Tuberculosis of lung, bacteriological and histological examination not done',
  'vietnamese': 'Lao phổi, không xét nghiệm vi khuẩn học và mô học',
}, {
  'id': 'A16.2',
  'english': 'Tuberculosis of lung, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao phổi, không đề cập đến việc xác nhận về vi khuẩn và mô học',
}, {
  'id': 'A16.3',
  'english': 'Tuberculosis of intrathoracic lymph nodes, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao hạch lympho trong lồng ngực, không đề cập đến việc xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A16.4',
  'english': 'Tuberculosis of larynx, trachea and bronchus, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao thanh quản, khí quản và phế quản, không đề cập đến việc xác định về vi khuẩn học và mô học',
}, {
  'id': 'A16.5',
  'english': 'Tuberculous pleurisy, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao màng phổi, không đề cập đến việc xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A16.7',
  'english': 'Primary respiratory tuberculosis without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao hô hấp sơ nhiễm không đề cập đến việc xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A16.8',
  'english': 'Other respiratory tuberculosis, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao hô hấp khác, không đề cập đến việc xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A16.9',
  'english': 'Respiratory tuberculosis unspecified, without mention of bacteriological or histological confirmation',
  'vietnamese': 'Lao hô hấp không xác định và không đề cập đến việc xác nhận về vi khuẩn học và mô học',
}, {
  'id': 'A17†',
  'english': 'Tuberculosis of nervous system',
  'vietnamese': 'Lao hệ thần kinh',
}, {
  'id': 'A17.0†',
  'english': 'Tuberculous meningitis (G01*)',
  'vietnamese': 'Viêm màng não do lao (G01*)',
}, {
  'id': 'A17.1†',
  'english': 'Meningeal tuberculoma (G07*)',
  'vietnamese': 'U lao màng não (G07*)',
}, {
  'id': 'A17.8†',
  'english': 'Other tuberculosis of nervous system',
  'vietnamese': 'Lao khác của hệ thần kinh',
}, {
  'id': 'A17.9†',
  'english': 'Tuberculosis of nervous system, unspecified (G99.8*)',
  'vietnamese': 'Lao hệ thần kinh, không đặc hiệu (G99.8*)',
}, {
  'id': 'A18',
  'english': 'Tuberculosis of other organs',
  'vietnamese': 'Lao các cơ quan khác',
}, {
  'id': 'A18.0†',
  'english': 'Tuberculosis of bones and joints',
  'vietnamese': 'Lao xương và khớp',
}, {
  'id': 'A18.1',
  'english': 'Tuberculosis of genitourinary system',
  'vietnamese': 'Lao hệ tiết niệu sinh dục',
}, {
  'id': 'A18.2',
  'english': 'Tuberculous peripheral lymphadenopathy',
  'vietnamese': 'Bệnh lý hạch lympho ngoại vi do lao',
}, {
  'id': 'A18.3',
  'english': 'Tuberculosis of intestines, peritoneum and mesenteric glands',
  'vietnamese': 'Lao ruột, màng bụng và hạch mạc treo',
}, {
  'id': 'A18.4',
  'english': 'Tuberculosis of skin and subcutaneous tissue',
  'vietnamese': 'Lao da và mô dưới da',
}, {'id': 'A18.5', 'english': 'Tuberculosis of eye', 'vietnamese': 'Lao ở mắt'}, {
  'id': 'A18.6',
  'english': 'Tuberculosis of ear',
  'vietnamese': 'Lao ở tai',
}, {
  'id': 'A18.7†',
  'english': 'Tuberculosis of adrenal glands E35.1',
  'vietnamese': 'Lao tuyến thượng thận E35.1',
}, {
  'id': 'A18.8',
  'english': 'Tuberculosis of other specified organs',
  'vietnamese': 'Lao các cơ quan khác',
}, {'id': 'A19', 'english': 'Miliary tuberculosis', 'vietnamese': 'Lao kê'}, {
  'id': 'A19.0',
  'english': 'Acute miliary tuberculosis of a single specified site',
  'vietnamese': 'Lao kê cấp của một vị trí xác định',
}, {
  'id': 'A19.1',
  'english': 'Acute miliary tuberculosis of multiple sites',
  'vietnamese': 'Lao kê cấp của nhiều vị trí',
}, {
  'id': 'A19.2',
  'english': 'Acute miliary tuberculosis, unspecified',
  'vietnamese': 'Lao kê cấp, không đặc hiệu',
}, {'id': 'A19.8', 'english': 'Other miliary tuberculosis', 'vietnamese': 'Lao kê khác'}, {
  'id': 'A19.9',
  'english': 'Miliary tuberculosis, unspecified',
  'vietnamese': 'Lao kê, không đặc hiệu',
}, {'id': 'A20', 'english': 'Plague', 'vietnamese': 'Dịch hạch'}, {
  'id': 'A20.0',
  'english': 'Bubonic plague',
  'vietnamese': 'Dịch hạch thể hạch',
}, {
  'id': 'A20.1',
  'english': 'Cellulocutaneous plague',
  'vietnamese': 'Dịch hạch thể viêm da mô mềm',
}, {'id': 'A20.2', 'english': 'Pneumonic plague', 'vietnamese': 'Dịch hạch thể phổi'}, {
  'id': 'A20.3',
  'english': 'Plague meningitis',
  'vietnamese': 'Dịch hạch thể viêm màng não',
}, {
  'id': 'A20.7',
  'english': 'Septicaemic plague',
  'vietnamese': 'Dịch hạch thể nhiễm trùng huyết',
}, {'id': 'A20.8', 'english': 'Other forms of plague', 'vietnamese': 'Dịch hạch thể khác'}, {
  'id': 'A20.9',
  'english': 'Plague, unspecified',
  'vietnamese': 'Dịch hạch không đặc hiệu',
}, {'id': 'A21', 'english': 'Tularaemia', 'vietnamese': 'Bệnh Tularensis'}, {
  'id': 'A21.0',
  'english': 'Ulceroglandular tularaemia',
  'vietnamese': 'Loét hạch do tularaemia',
}, {
  'id': 'A21.1',
  'english': 'Oculoglandular tularaemia',
  'vietnamese': 'Bệnh tularaemia hạch ở mắt',
}, {'id': 'A21.2', 'english': 'Pulmonary tularaemia', 'vietnamese': 'Bệnh tularaemia thể phổi'}, {
  'id': 'A21.3',
  'english': 'Gastrointestinal tularaemia',
  'vietnamese': 'Bệnh tularaemia thể dạ dày ruột',
}, {
  'id': 'A21.7',
  'english': 'Generalized tularaemia',
  'vietnamese': 'Bệnh tularaemia thể toàn thân',
}, {
  'id': 'A21.8',
  'english': 'Other forms of tularaemia',
  'vietnamese': 'Bệnh tularaemia các thể khác',
}, {
  'id': 'A21.9',
  'english': 'Tularaemia, unspecified',
  'vietnamese': 'Bệnh tularaemia không đặc hiệu',
}, {'id': 'A22', 'english': 'Anthrax', 'vietnamese': 'Bệnh than'}, {
  'id': 'A22.0',
  'english': 'Cutaneous anthrax',
  'vietnamese': 'Bệnh than thể da',
}, {'id': 'A22.1', 'english': 'Pulmonary anthrax', 'vietnamese': 'Bệnh than thể phổi'}, {
  'id': 'A22.2',
  'english': 'Gastrointestinal anthrax',
  'vietnamese': 'Bệnh than thể dạ dày ruột',
}, {
  'id': 'A22.7',
  'english': 'Anthrax septicaemia',
  'vietnamese': 'Bệnh than thể nhiễm trùng huyết',
}, {'id': 'A22.8', 'english': 'Other forms of anthrax', 'vietnamese': 'Bệnh than thể khác'}, {
  'id': 'A22.9',
  'english': 'Anthrax, unspecified',
  'vietnamese': 'Bệnh than không đặc hiệu',
}, {'id': 'A23', 'english': 'Brucellosis', 'vietnamese': 'Bệnh do Brucella'}, {
  'id': 'A23.0',
  'english': 'Brucellosis due to Brucella melitensis',
  'vietnamese': 'Bệnh do Brucella melitensis',
}, {
  'id': 'A23.1',
  'english': 'Brucellosis due to Brucella abortus',
  'vietnamese': 'Bệnh do Brucella abortus',
}, {
  'id': 'A23.2',
  'english': 'Brucellosis due to Brucella suis',
  'vietnamese': 'Bệnh do Brucella suis',
}, {
  'id': 'A23.3',
  'english': 'Brucellosis due to Brucella canis',
  'vietnamese': 'Bệnh do Brucella canis',
}, {'id': 'A23.8', 'english': 'Other brucellosis', 'vietnamese': 'Bệnh do Brucella khác'}, {
  'id': 'A23.9',
  'english': 'Brucellosis, unspecified',
  'vietnamese': 'Bệnh do Brucella, không đặc hiệu',
}, {
  'id': 'A24',
  'english': 'Glanders and melioidosis',
  'vietnamese': 'Bệnh Malleomyces mallei và bệnh Malleomyces pseudomallei',
}, {
  'id': 'A24.0',
  'english': 'Glanders',
  'vietnamese': 'Nhiễm Malleomyces mallei (Bệnh sổ mũi ở ngựa)',
}, {
  'id': 'A24.1',
  'english': 'Acute and fulminating melioidosis',
  'vietnamese': 'Nhiễm Malleomyces Pseudomallei cấp tính và tối cấp',
}, {
  'id': 'A24.2',
  'english': 'Subacute and chronic melioidosis',
  'vietnamese': 'Nhiễm Malleomyces Pseudomallei bán cấp và mãn tính',
}, {
  'id': 'A24.3',
  'english': 'Other melioidosis',
  'vietnamese': 'Nhiễm Malleomyces Pseudomallei khác',
}, {
  'id': 'A24.4',
  'english': 'Melioidosis, unspecified',
  'vietnamese': 'Nhiễm Malleomyces Pseudomallei, không đặc hiệu',
}, {'id': 'A25', 'english': 'Rat-bite fevers', 'vietnamese': 'Sốt do chuột cắn'}, {
  'id': 'A25.0',
  'english': 'Spirillosis',
  'vietnamese': 'Bệnh do spirilla',
}, {'id': 'A25.1', 'english': 'Streptobacillosis', 'vietnamese': 'Bệnh do streptobacilla'}, {
  'id': 'A25.9',
  'english': 'Rat-bite fever, unspecified',
  'vietnamese': 'Sốt do chuột cắn không đặc hiệu',
}, {
  'id': 'A26',
  'english': 'Erysipeloid',
  'vietnamese': 'Bệnh do Erysipelothrix rhusiopathiae (viêm quầng)',
}, {
  'id': 'A26.0',
  'english': 'Cutaneous erysipeloid',
  'vietnamese': 'Bệnh do Erysipelothrix ở da',
}, {
  'id': 'A26.7',
  'english': 'Erysipelothrix septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do Erysipelothrix',
}, {
  'id': 'A26.8',
  'english': 'Other forms of erysipeloid',
  'vietnamese': 'Thể khác của viêm quầng',
}, {
  'id': 'A26.9',
  'english': 'Erysipeloid, unspecified',
  'vietnamese': 'Bệnh do Erysipelothrix không đặc hiệu',
}, {'id': 'A27', 'english': 'Leptospirosis', 'vietnamese': 'Bệnh do leptospira'}, {
  'id': 'A27.0',
  'english': 'Leptospirosis icterohaemorrhagica',
  'vietnamese': 'Bệnh do Leptospira gây vàng da xuất huyết',
}, {
  'id': 'A27.8',
  'english': 'Other forms of leptospirosis',
  'vietnamese': 'Thể khác của nhiễm leptospira',
}, {
  'id': 'A27.9',
  'english': 'Leptospirosis, unspecified',
  'vietnamese': 'Nhiễm leptospira, không đặc hiệu',
}, {
  'id': 'A28',
  'english': 'Other zoonotic bacterial diseases, not elsewhere classified',
  'vietnamese': 'Bệnh nhiễm trùng khác do động vật truyền sang người chưa được phân loại',
}, {'id': 'A28.0', 'english': 'Pasteurellosis', 'vietnamese': 'Bệnh do Pasteurella'}, {
  'id': 'A28.1',
  'english': 'Cat-scratch disease',
  'vietnamese': 'Bệnh mèo cào',
}, {
  'id': 'A28.2',
  'english': 'Extraintestinal yersiniosis',
  'vietnamese': 'Nhiễm yersinia ngoài ruột',
}, {
  'id': 'A28.8',
  'english': 'Other specified zoonotic bacterial diseases, not elsewhere classified',
  'vietnamese': 'Bệnh nhiễm trùng do động vật truyền sang người khác, chưa được phân loại nơi khác',
}, {
  'id': 'A28.9',
  'english': 'Zoonotic bacterial disease, unspecified',
  'vietnamese': 'Bệnh nhiễm trùng do động vật truyền sang người, không đặc hiệu',
}, {
  'id': 'A30',
  'english': 'Leprosy [Hansen s disease]',
  'vietnamese': 'Bệnh phong [bệnh Hansen]',
}, {
  'id': 'A30.0',
  'english': 'Indeterminate leprosy',
  'vietnamese': 'Bệnh phong bất định',
}, {'id': 'A30.1', 'english': 'Tuberculoid leprosy', 'vietnamese': 'Bệnh phong thể củ'}, {
  'id': 'A30.2',
  'english': 'Borderline tuberculoid leprosy',
  'vietnamese': 'Bệnh phong thể củ ranh giới',
}, {'id': 'A30.3', 'english': 'Borderline leprosy', 'vietnamese': 'Bệnh phong thể ranh giới'}, {
  'id': 'A30.4',
  'english': 'Borderline lepromatous leprosy',
  'vietnamese': 'Bệnh phong thể u ranh giới',
}, {'id': 'A30.5', 'english': 'Lepromatous leprosy', 'vietnamese': 'Bệnh phong thể u'}, {
  'id': 'A30.8',
  'english': 'Other forms of leprosy',
  'vietnamese': 'Thể khác của bệnh phong',
}, {
  'id': 'A30.9',
  'english': 'Leprosy, unspecified',
  'vietnamese': 'Bệnh phong, không đặc hiệu',
}, {
  'id': 'A31',
  'english': 'Infection due to other mycobacteria',
  'vietnamese': 'Nhiễm trùng do mycobacteria khác',
}, {
  'id': 'A31.0',
  'english': 'Pulmonary mycobacterial infection',
  'vietnamese': 'Nhiễm mycobacteria ở phổi',
}, {
  'id': 'A31.1',
  'english': 'Cutaneous mycobacterial infection',
  'vietnamese': 'Nhiễm mycobacteria ở da',
}, {
  'id': 'A31.8',
  'english': 'Other mycobacterial infections',
  'vietnamese': 'Nhiễm khuẩn mycobacteria khác',
}, {
  'id': 'A31.9',
  'english': 'Mycobacterial infection, unspecified',
  'vietnamese': 'Nhiễm khuẩn mycobacteria không đặc hiệu',
}, {
  'id': 'A32',
  'english': 'Listeriosis',
  'vietnamese': 'Nhiễm trùng do Listeria monocytogenes',
}, {
  'id': 'A32.0',
  'english': 'Cutaneous listeriosis',
  'vietnamese': 'Nhiễm trùng listeria ở da',
}, {
  'id': 'A32.1†',
  'english': 'Listerial meningitis and meningoencephalitis',
  'vietnamese': 'Viêm màng não và viêm não màng não do listeria',
}, {'id': 'A32.7', 'english': 'Listerial septicaemia', 'vietnamese': 'Nhiễm trùng do listeria'}, {
  'id': 'A32.8',
  'english': 'Other forms of listeriosis',
  'vietnamese': 'Thể khác của nhiễm trùng listeria',
}, {
  'id': 'A32.9',
  'english': 'Listeriosis, unspecified',
  'vietnamese': 'Nhiễm listeria, không đặc hiệu',
}, {'id': 'A33', 'english': 'Tetanus neonatorum', 'vietnamese': 'Bệnh uốn ván sơ sinh'}, {
  'id': 'A34',
  'english': 'Obstetrical tetanus',
  'vietnamese': 'Bệnh uốn ván sản khoa',
}, {'id': 'A35', 'english': 'Other tetanus', 'vietnamese': 'Bệnh uốn ván khác'}, {
  'id': 'A36',
  'english': 'Diphtheria',
  'vietnamese': 'Bệnh bạch hầu',
}, {'id': 'A36.0', 'english': 'Pharyngeal diphtheria', 'vietnamese': 'Bệnh Bạch hầu họng'}, {
  'id': 'A36.1',
  'english': 'Nasopharyngeal diphtheria',
  'vietnamese': 'Bệnh bạch hầu thể mũi - họng',
}, {'id': 'A36.2', 'english': 'Laryngeal diphtheria', 'vietnamese': 'Bệnh bạch hầu thanh quản'}, {
  'id': 'A36.3',
  'english': 'Cutaneous diphtheria',
  'vietnamese': 'Bệnh bạch hầu da',
}, {'id': 'A36.8', 'english': 'Other diphtheria', 'vietnamese': 'Bệnh bạch hầu khác'}, {
  'id': 'A36.9',
  'english': 'Diphtheria, unspecified',
  'vietnamese': 'Bạch hầu, không đặc hiệu',
}, {'id': 'A37', 'english': 'Whooping cough', 'vietnamese': 'Bệnh ho gà'}, {
  'id': 'A37.0',
  'english': 'Whooping cough due to Bordetella pertussis',
  'vietnamese': 'Bệnh ho gà do Bordetella pertussis',
}, {
  'id': 'A37.1',
  'english': 'Whooping cough due to Bordetella parapertussis',
  'vietnamese': 'Bệnh ho gà do Bordetella parapertussis',
}, {
  'id': 'A37.8',
  'english': 'Whooping cough due to other Bordetella species',
  'vietnamese': 'Bệnh ho gà do Bordetella khác',
}, {
  'id': 'A37.9',
  'english': 'Whooping cough, unspecified',
  'vietnamese': 'Bệnh ho gà, không đặc hiệu',
}, {'id': 'A38', 'english': 'Scarlet fever', 'vietnamese': 'Bệnh tinh hồng nhiệt'}, {
  'id': 'A39',
  'english': 'Meningococcal infection',
  'vietnamese': 'Nhiễm trùng do não mô cầu',
}, {
  'id': 'A39.0†',
  'english': 'Meningococcal meningitis (G01*)',
  'vietnamese': 'Viêm màng não do não mô cầu (G01*)',
}, {
  'id': 'A39.1†',
  'english': 'Waterhouse-Friderichsen syndrome (E35.1*)',
  'vietnamese': 'Hội chứng Waterhouse-Friderichsen (E35.1*)',
}, {
  'id': 'A39.2',
  'english': 'Acute meningococcaemia',
  'vietnamese': 'Nhiễm khuẩn huyết não mô cầu cấp tính',
}, {
  'id': 'A39.3',
  'english': 'Chronic meningococcaemia',
  'vietnamese': 'Nhiễm khuẩn huyết não mô cầu mãn tính',
}, {
  'id': 'A39.4',
  'english': 'Meningococcaemia, unspecified',
  'vietnamese': 'Nhiễm khuẩn huyết não mô cầu không đặc hiệu',
}, {
  'id': 'A39.5†',
  'english': 'Meningococcal heart disease',
  'vietnamese': 'Bệnh tim do não mô cầu',
}, {
  'id': 'A39.8',
  'english': 'Other meningococcal infections',
  'vietnamese': 'Nhiễm não mô cầu khác',
}, {
  'id': 'A39.9',
  'english': 'Meningococcal infection, unspecified',
  'vietnamese': 'Nhiễm não mô cầu, không đặc hiệu',
}, {
  'id': 'A40',
  'english': 'Streptococcal septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu',
}, {
  'id': 'A40.0',
  'english': 'Septicaemia due to streptococcus, group A',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu, nhóm A',
}, {
  'id': 'A40.1',
  'english': 'Septicaemia due to streptococcus, group B',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu, nhóm B',
}, {
  'id': 'A40.2',
  'english': 'Septicaemia due to streptococcus, group D',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu, nhóm D',
}, {
  'id': 'A40.3',
  'english': 'Septicaemia due to Streptococcus pneumoniae',
  'vietnamese': 'Nhiễm trùng do Streptococcus pneumoniae',
}, {
  'id': 'A40.8',
  'english': 'Other streptococcal septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu khác',
}, {
  'id': 'A40.9',
  'english': 'Streptococcal septicaemia, unspecified',
  'vietnamese': 'Nhiễm trùng huyết do liên cầu, không đặc hiệu',
}, {'id': 'A41', 'english': 'Other septicaemia', 'vietnamese': 'Nhiễm trùng khác'}, {
  'id': 'A41.0',
  'english': 'Septicaemia due to Staphylococcus aureus',
  'vietnamese': 'Nhiễm trùng do tụ cầu vàng',
}, {
  'id': 'A41.1',
  'english': 'Septicaemia due to other specified staphylococcus',
  'vietnamese': 'Nhiễm trùng do tụ cầu xác định khác',
}, {
  'id': 'A41.2',
  'english': 'Septicaemia due to unspecified staphylococcus',
  'vietnamese': 'Nhiễm trùng do tụ cầu không đặc hiệu',
}, {
  'id': 'A41.3',
  'english': 'Septicaemia due to Haemophilus influenzae',
  'vietnamese': 'Nhiễm trùng do Haemophilus influenzae',
}, {
  'id': 'A41.4',
  'english': 'Septicaemia due to anaerobes',
  'vietnamese': 'Nhiễm trùng do vi khuẩn kỵ khí',
}, {
  'id': 'A41.5',
  'english': 'Septicaemia due to other Gram-negative organisms',
  'vietnamese': 'Nhiễm trùng huyết do vi trùng gram âm khác',
}, {
  'id': 'A41.8',
  'english': 'Other specified septicaemia',
  'vietnamese': 'Nhiễm trùng huyết xác định khác',
}, {
  'id': 'A41.9',
  'english': 'Septicaemia, unspecified',
  'vietnamese': 'Nhiễm trùng huyết, không đặc hiệu',
}, {'id': 'A42', 'english': 'Actinomycosis', 'vietnamese': 'Bệnh do actinomyces'}, {
  'id': 'A42.0',
  'english': 'Pulmonary actinomycosis',
  'vietnamese': 'Bệnh do actinomyces ở phổi',
}, {
  'id': 'A42.1',
  'english': 'Abdominal actinomycosis',
  'vietnamese': 'Bệnh do actinomyces ở bụng',
}, {
  'id': 'A42.2',
  'english': 'Cervicofacial actinomycosis',
  'vietnamese': 'Bệnh do actinomyces ở mặt - cổ',
}, {
  'id': 'A42.7',
  'english': 'Actinomycotic septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do actinomyces',
}, {
  'id': 'A42.8',
  'english': 'Other forms of actinomycosis',
  'vietnamese': 'Các thể khác của nhiễm actinomyces',
}, {
  'id': 'A42.9',
  'english': 'Actinomycosis, unspecified',
  'vietnamese': 'Bệnh do actinomyces, không đặc hiệu',
}, {'id': 'A43', 'english': 'Nocardiosis', 'vietnamese': 'Bệnh do Nocardia'}, {
  'id': 'A43.0',
  'english': 'Pulmonary nocardiosis',
  'vietnamese': 'Bệnh do Nocardia ở phổi',
}, {'id': 'A43.1', 'english': 'Cutaneous nocardiosis', 'vietnamese': 'Bệnh do Nocardia ở da'}, {
  'id': 'A43.8',
  'english': 'Other forms of nocardiosis',
  'vietnamese': 'Các thể khác của bệnh nocardia',
}, {
  'id': 'A43.9',
  'english': 'Nocardiosis, unspecified',
  'vietnamese': 'Bệnh do Nocardia, không đặc hiệu',
}, {'id': 'A44', 'english': 'Bartonellosis', 'vietnamese': 'Bệnh do Bartonella'}, {
  'id': 'A44.0',
  'english': 'Systemic bartonellosis',
  'vietnamese': 'Bệnh do Bartonella toàn thân',
}, {
  'id': 'A44.1',
  'english': 'Cutaneous and mucocutaneous bartonellosis',
  'vietnamese': 'Bệnh do Bartonella ở da và niêm mạc',
}, {
  'id': 'A44.8',
  'english': 'Other forms of bartonellosis',
  'vietnamese': 'Các thể khác của bệnh do Bartonella',
}, {
  'id': 'A44.9',
  'english': 'Bartonellosis, unspecified',
  'vietnamese': 'Bệnh do Bartonella, không đặc hiệu',
}, {
  'id': 'A46',
  'english': 'Erysipelas',
  'vietnamese': 'Viêm quầng (nhiễm streptococcus ở da)',
}, {
  'id': 'A48',
  'english': 'Other bacterial diseases, not elsewhere classified',
  'vietnamese': 'Bệnh nhiễm khuẩn khác, chưa được phân loại',
}, {'id': 'A48.0', 'english': 'Gas gangrene', 'vietnamese': 'Bệnh hoại thư sinh hơi'}, {
  'id': 'A48.1',
  'english': 'Legionnaires disease',
  'vietnamese': 'Bệnh do Legionnaire',
}, {
  'id': 'A48.2',
  'english': 'Nonpneumonic Legionnaires disease [Pontiac fever]',
  'vietnamese': 'Bệnh Legionnaire không ở phổi [sốt Pontiac]',
}, {'id': 'A48.3', 'english': 'Toxic shock syndrome', 'vietnamese': 'Hội chứng sốc nhiễm độc'}, {
  'id': 'A48.4',
  'english': 'Brazilian purpuric fever',
  'vietnamese': 'Sốt ban xuất huyết Brasil',
}, {
  'id': 'A48.8',
  'english': 'Other specified bacterial diseases',
  'vietnamese': 'Bệnh nhiễm khuẩn xác định khác',
}, {
  'id': 'A49',
  'english': 'Bacterial infection of unspecified site',
  'vietnamese': 'Nhiễm trùng ở các vị trí không xác định',
}, {
  'id': 'A49.0',
  'english': 'Staphylococcal infection, unspecified site',
  'vietnamese': 'Nhiễm trùng tụ cầu, không xác định được vị trí',
}, {
  'id': 'A49.1',
  'english': 'Streptococcal infection, unspecified site',
  'vietnamese': 'Nhiễm trùng liên cầu, không xác định được vị trí',
}, {
  'id': 'A49.2',
  'english': 'Haemophilus influenzae infection, unspecified site',
  'vietnamese': 'Nhiễm Haemophilus influenzae, không xác định được vị trí',
}, {
  'id': 'A49.3',
  'english': 'Mycoplasma infection, unspecified site',
  'vietnamese': 'Nhiễm Mycoplasma, không xác định được vị trí',
}, {
  'id': 'A49.8',
  'english': 'Other bacterial infections of unspecified site',
  'vietnamese': 'Nhiễm khuẩn khác, không xác định được vị trí',
}, {
  'id': 'A49.9',
  'english': 'Bacterial infection, unspecified',
  'vietnamese': 'Nhiễm khuẩn, không đặc hiệu',
}, {'id': 'A50', 'english': 'Congenital syphilis', 'vietnamese': 'Giang mai bẩm sinh'}, {
  'id': 'A50.0',
  'english': 'Early congenital syphilis, symptomatic',
  'vietnamese': 'Giang mai bẩm sinh sớm, có triệu chứng',
}, {
  'id': 'A50.1',
  'english': 'Early congenital syphilis, latent',
  'vietnamese': 'Giang mai bẩm sinh sớm, tiềm ẩn',
}, {
  'id': 'A50.2',
  'english': 'Early congenital syphilis, unspecified',
  'vietnamese': 'Giang mai bẩm sinh sớm, không đặc hiệu',
}, {
  'id': 'A50.3',
  'english': 'Late congenital syphilitic oculopathy',
  'vietnamese': 'Bệnh lý nhãn cầu do giang mai bẩm sinh muộn',
}, {
  'id': 'A50.4',
  'english': 'Late congenital neurosyphilis [juvenile neurosyphilis]',
  'vietnamese': 'Giang mai thần kinh bẩm sinh muộn [giang mai thần kinh ở người trẻ]',
}, {
  'id': 'A50.5',
  'english': 'Other late congenital syphilis, symptomatic',
  'vietnamese': 'Giang mai bẩm sinh muộn khác, có triệu chứng',
}, {
  'id': 'A50.6',
  'english': 'Late congenital syphilis, latent',
  'vietnamese': 'Giang mai bẩm sinh muộn, tiềm ẩn',
}, {
  'id': 'A50.7',
  'english': 'Late congenital syphilis, unspecified',
  'vietnamese': 'Giang mai bẩm sinh muộn, không đặc hiệu',
}, {
  'id': 'A50.9',
  'english': 'Congenital syphilis, unspecified',
  'vietnamese': 'Giang mai bẩm sinh, không đặc hiệu',
}, {'id': 'A51', 'english': 'Early syphilis', 'vietnamese': 'Giang mai sớm'}, {
  'id': 'A51.0',
  'english': 'Primary genital syphilis',
  'vietnamese': 'Giang mai sinh dục tiên phát',
}, {
  'id': 'A51.1',
  'english': 'Primary anal syphilis',
  'vietnamese': 'Giang mai hậu môn tiên phát',
}, {
  'id': 'A51.2',
  'english': 'Primary syphilis of other sites',
  'vietnamese': 'Giang mai tiên phát ở vị trí khác',
}, {
  'id': 'A51.3',
  'english': 'Secondary syphilis of skin and mucous membranes',
  'vietnamese': 'Giang mai thứ phát ở da và niêm mạc',
}, {
  'id': 'A51.4',
  'english': 'Other secondary syphilis',
  'vietnamese': 'Giang mai thứ phát khác',
}, {
  'id': 'A51.5',
  'english': 'Early syphilis, latent',
  'vietnamese': 'Giang mai sớm, tiềm ẩn',
}, {
  'id': 'A51.9',
  'english': 'Early syphilis, unspecified',
  'vietnamese': 'Giang mai sớm, không đặc hiệu',
}, {'id': 'A52', 'english': 'Late syphilis', 'vietnamese': 'Giang mai muộn'}, {
  'id': 'A52.0†',
  'english': 'Cardiovascular syphilis',
  'vietnamese': 'Giang mai tim mạch',
}, {
  'id': 'A52.1',
  'english': 'Symptomatic neurosyphilis',
  'vietnamese': 'Giang mai thần kinh có triệu chứng',
}, {
  'id': 'A52.2',
  'english': 'Asymptomatic neurosyphilis',
  'vietnamese': 'Giang mai thần kinh không triệu chứng',
}, {
  'id': 'A52.3',
  'english': 'Neurosyphilis, unspecified',
  'vietnamese': 'Giang mai thần kinh, không đặc hiệu',
}, {
  'id': 'A52.7',
  'english': 'Other symptomatic late syphilis',
  'vietnamese': 'Giang mai muộn khác có triệu chứng',
}, {'id': 'A52.8', 'english': 'Late syphilis, latent', 'vietnamese': 'Giang mai muộn, tiềm ẩn'}, {
  'id': 'A52.9',
  'english': 'Late syphilis, unspecified',
  'vietnamese': 'Giang mai muộn, không đặc hiệu',
}, {
  'id': 'A53',
  'english': 'Other and unspecified syphilis',
  'vietnamese': 'Giang mai khác và không đặc hiệu',
}, {
  'id': 'A53.0',
  'english': 'Latent syphilis, unspecified as early or late',
  'vietnamese': 'Giang mai tiềm ẩn, không xác định là sớm hoặc muộn',
}, {
  'id': 'A53.9',
  'english': 'Syphilis, unspecified',
  'vietnamese': 'Giang mai, không đặc hiệu',
}, {
  'id': 'A54',
  'english': 'Gonococcal infection',
  'vietnamese': 'Bệnh lậu',
}, {
  'id': 'A54.0',
  'english': 'Gonococcal infection of lower genitourinary tract without periurethral or accessory gland abscess',
  'vietnamese': 'Bệnh nâu ở đường niệu - sinh dục dưới không có áp xe quanh niệu đạo hoặc các tuyến',
}, {
  'id': 'A54.1',
  'english': 'Gonococcal infection of lower genitourinary tract with periurethral and accessory gland abscess',
  'vietnamese': 'Bệnh lậu ở đường niệu - sinh dục dưới có áp xe quanh niệu đạo và các tuyến',
}, {
  'id': 'A54.2',
  'english': 'Gonococcal pelviperitonitis and other gonococcal genitourinary infections',
  'vietnamese': 'Viêm phúc mạc tiểu khung do lậu và nhiễm trùng niệu - sinh dục khác do lậu',
}, {
  'id': 'A54.3',
  'english': 'Gonococcal infection of eye',
  'vietnamese': 'Bệnh lậu cầu ở mắt',
}, {
  'id': 'A54.4†',
  'english': 'Gonococcal infection of musculoskeletal system',
  'vietnamese': 'Bệnh lậu ở hệ cơ xương',
}, {'id': 'A54.5', 'english': 'Gonococcal pharyngitis', 'vietnamese': 'Viêm họng do lậu'}, {
  'id': 'A54.6',
  'english': 'Gonococcal infection of anus and rectum',
  'vietnamese': 'Bệnh lậu ở hậu môn và trực tràng',
}, {'id': 'A54.8', 'english': 'Other gonococcal infections', 'vietnamese': 'Bệnh lậu khác'}, {
  'id': 'A54.9',
  'english': 'Gonococcal infection, unspecified',
  'vietnamese': 'Nhiễm lậu cầu, không đặc hiệu',
}, {
  'id': 'A55',
  'english': 'Clamydia lymphogranuloma (venereum)',
  'vietnamese': 'Viêm hạch lympho do Chlamydia (Bệnh hột xoài)',
}, {
  'id': 'A56',
  'english': 'Other sexually transmitted chlamydial diseases',
  'vietnamese': 'Bệnh khác do chlamydia lây truyền qua đường tình dục',
}, {
  'id': 'A56.0',
  'english': 'Chlamydial infection of lower genitourinary tract',
  'vietnamese': 'Bệnh do chlamydia ở đường niệu - sinh dục dưới',
}, {
  'id': 'A56.1',
  'english': 'Chlamydial infection of pelviperitoneum and other genitourinary organs',
  'vietnamese': 'Viêm phúc mạc tiểu khung và các cơ quan niệu - sinh dục khác do chlamydia',
}, {
  'id': 'A56.2',
  'english': 'Chlamydial infection of genitourinary tract, unspecified',
  'vietnamese': 'Bệnh do chlamydia ở đường niệu - sinh dục, không đặc hiệu',
}, {
  'id': 'A56.3',
  'english': 'Chlamydial infection of anus and rectum',
  'vietnamese': 'Bệnh do chlamydia ở hậu môn và trực tràng',
}, {
  'id': 'A56.4',
  'english': 'Chlamydial infection of pharynx',
  'vietnamese': 'Bệnh do chlamydia ở họng',
}, {
  'id': 'A56.8',
  'english': 'Sexually transmitted chlamydial infection of other sites',
  'vietnamese': 'Bệnh do chlamydia lây truyền qua đường tình dục có vị trí khác',
}, {'id': 'A57', 'english': 'Chancroid', 'vietnamese': 'Bệnh hạ cam'}, {
  'id': 'A58',
  'english': 'Granuloma inguinable',
  'vietnamese': 'U hạt ở bẹn',
}, {'id': 'A59', 'english': 'Trichomoniasis', 'vietnamese': 'Bệnh do trichomonas'}, {
  'id': 'A59.0',
  'english': 'Urogenital trichomoniasis',
  'vietnamese': 'Bệnh do trichomonas đường niệu - sinh dục',
}, {
  'id': 'A59.8',
  'english': 'Trichomoniasis of other sites',
  'vietnamese': 'Bệnh do trichomonas ở vị trí khác',
}, {
  'id': 'A59.9',
  'english': 'Trichomoniasis, unspecified',
  'vietnamese': 'Bệnh do trichomonas, không đặc hiệu',
}, {
  'id': 'A60',
  'english': 'Anogenital herpesviral [herpes simplex] infection',
  'vietnamese': 'Bệnh do Herpes simplex vùng hậu môn sinh dục',
}, {
  'id': 'A60.0',
  'english': 'Herpesviral infection of genitalia and urogenital tract',
  'vietnamese': 'Bệnh do virus herpes ở đường sinh dục và niệu - sinh dục',
}, {
  'id': 'A60.1',
  'english': 'Herpesviral infection of perianal skin and rectum',
  'vietnamese': 'Bệnh do virus herpes ở da quanh hậu môn và trực tràng',
}, {
  'id': 'A60.9',
  'english': 'Anogenital herpesviral infection, unspecified',
  'vietnamese': 'Bệnh do virus herpes ở hậu môn - sinh dục, không đặc hiệu',
}, {
  'id': 'A63',
  'english': 'Other predominantly sexually transmitted diseases, not elsewhere classified',
  'vietnamese': 'Bệnh lây khác chủ yếu qua đường tình dục, chưa được phân loại',
}, {
  'id': 'A63.0',
  'english': 'Anogenital (venereal) warts',
  'vietnamese': 'Mụn cơm (hoa liễu) ở hậu môn - sinh dục',
}, {
  'id': 'A63.8',
  'english': 'Other specified predominantly sexually transmitted diseases',
  'vietnamese': 'Bệnh lây truyền chủ yếu qua đường tình dục xác định khác',
}, {
  'id': 'A64',
  'english': 'Unspecified sexually transmitted disease',
  'vietnamese': 'Bệnh lây truyền qua đường tình dục không đặc hiệu',
}, {
  'id': 'A65',
  'english': 'Nonvenereal syphilis',
  'vietnamese': 'Giang mai không lây qua đường tình dục',
}, {'id': 'A66', 'english': 'Yaws', 'vietnamese': 'Ghẻ cóc (do nhiễm Treponema pertenue)'}, {
  'id': 'A66.0',
  'english': 'Initial lesions of yaws',
  'vietnamese': 'Tổn thương ban đầu của ghẻ cóc',
}, {
  'id': 'A66.1',
  'english': 'Multiple papillomata and wet crab yaws',
  'vietnamese': 'Đau nhú mềm và ghẻ cóc dạng u',
}, {
  'id': 'A66.2',
  'english': 'Other early skin lesions of yaws',
  'vietnamese': 'Tổn thương da sớm khác của ghẻ cóc',
}, {
  'id': 'A66.3',
  'english': 'Hyperkeratosis of yaws',
  'vietnamese': 'Tăng sừng hóa của ghẻ cóc',
}, {
  'id': 'A66.4',
  'english': 'Gummata and ulcers of yaws',
  'vietnamese': 'Gôm và loét của ghẻ cóc',
}, {'id': 'A66.5', 'english': 'Gangosa', 'vietnamese': 'Bệnh Gangosa (bệnh loét quanh mũi)'}, {
  'id': 'A66.6',
  'english': 'Bone and joint lesions of yaws',
  'vietnamese': 'Tổn thương xương và khớp của ghẻ cóc',
}, {
  'id': 'A66.7',
  'english': 'Other manifestations of yaws',
  'vietnamese': 'Biểu hiện khác của ghẻ cóc',
}, {'id': 'A66.8', 'english': 'Latent yaws', 'vietnamese': 'Ghẻ cóc tiềm ẩn'}, {
  'id': 'A66.9',
  'english': 'Yaws, unspecified',
  'vietnamese': 'Ghẻ cóc, không đặc hiệu',
}, {
  'id': 'A67',
  'english': 'Pinta [carate]',
  'vietnamese': 'Bệnh Pinta [bệnh do Treponema carateum]',
}, {
  'id': 'A67.0',
  'english': 'Primary lesions of pinta',
  'vietnamese': 'Tổn thương tiên phát của pinta',
}, {
  'id': 'A67.1',
  'english': 'Intermediate lesions of pinta',
  'vietnamese': 'Tổn thương trung gian của pinta',
}, {
  'id': 'A67.2',
  'english': 'Late lesions of pinta',
  'vietnamese': 'Tổn thương muộn của pinta',
}, {
  'id': 'A67.3',
  'english': 'Mixed lesions of pinta',
  'vietnamese': 'Tổn thương hỗn hợp của pinta',
}, {'id': 'A67.9', 'english': 'Pinta, unspecified', 'vietnamese': 'Pinta, không đặc hiệu'}, {
  'id': 'A68',
  'english': 'Relapsing fevers',
  'vietnamese': 'Bệnh sốt hồi qui',
}, {
  'id': 'A68.0',
  'english': 'Louse-borne relapsing fever',
  'vietnamese': 'Sốt hồi qui do chấy rận',
}, {
  'id': 'A68.1',
  'english': 'Tick-borne relapsing fever',
  'vietnamese': 'Sốt hồi qui do ve truyền',
}, {
  'id': 'A68.9',
  'english': 'Relapsing fever, unspecified',
  'vietnamese': 'Sốt hồi qui, không xếp loại',
}, {
  'id': 'A69',
  'english': 'Other spirochaetal infections',
  'vietnamese': 'Bệnh do xoắn trùng khác',
}, {
  'id': 'A69.0',
  'english': 'Necrotizing ulcerative stomatitis',
  'vietnamese': 'Viêm loét hoại tử ở miệng',
}, {
  'id': 'A69.1',
  'english': 'Other Vincent s infections',
  'vietnamese': 'Nhiễm trùng do kiểu Vincent khác',
}, {'id': 'A69.2', 'english': 'Lyme disease', 'vietnamese': 'Bệnh Lyme'}, {
  'id': 'A69.8',
  'english': 'Other specified spirochaetal infections',
  'vietnamese': 'Nhiễm xoắn khuẩn xác định khác',
}, {
  'id': 'A69.9',
  'english': 'Spirochaetal infection, unspecified',
  'vietnamese': 'Nhiễm xoắn khuẩn không đặc hiệu',
}, {
  'id': 'A70',
  'english': 'Chlamydia psittaci infection',
  'vietnamese': 'Bệnh do Chlamydia psittaci',
}, {'id': 'A71', 'english': 'Trachoma', 'vietnamese': 'Bệnh mắt hột'}, {
  'id': 'A71.0',
  'english': 'Initial stage of trachoma',
  'vietnamese': 'Giai đoạn đầu của bệnh mắt hột',
}, {
  'id': 'A71.1',
  'english': 'Active stage of trachoma',
  'vietnamese': 'Giai đoạn hoạt động của mắt hột',
}, {
  'id': 'A71.9',
  'english': 'Trachoma, unspecified',
  'vietnamese': 'Bệnh mắt hột, không đặc hiệu',
}, {
  'id': 'A74',
  'english': 'Other diseases caused by chlamydiae',
  'vietnamese': 'Bệnh khác do chlamydia',
}, {
  'id': 'A74.0†',
  'english': 'Chlamydial conjunctivitis (H13.1*)',
  'vietnamese': 'Viêm kết mạc do chlamydia (H13.1*)',
}, {
  'id': 'A74.8',
  'english': 'Other chlamydial diseases',
  'vietnamese': 'Bệnh do chlamydia khác',
}, {
  'id': 'A74.9',
  'english': 'Chlamydial infection, unspecified',
  'vietnamese': 'Bệnh do chlamydia, không đặc hiệu',
}, {'id': 'A75', 'english': 'Typhus fever', 'vietnamese': 'Bệnh sốt phát ban do Rickettsia'}, {
  'id': 'A75.0',
  'english': 'Epidemic louse-borne typhus fever due to Rickettsia prowazekii',
  'vietnamese': 'Sốt phát ban dịch tễ do chấy rận Rickettsia prowazekii',
}, {
  'id': 'A75.1',
  'english': 'Recrudescent typhus [Brill s disease]',
  'vietnamese': 'Sốt phát ban tái diễn [bệnh Brill]',
}, {
  'id': 'A75.2',
  'english': 'Typhus fever due to Rickettsia typhi',
  'vietnamese': 'Sốt phát ban do Rickettsia typhi',
}, {
  'id': 'A75.3',
  'english': 'Typhus fever due to Rickettsia tsutsugamushi',
  'vietnamese': 'Sốt phát ban do Rickettsia tsutsugamushi',
}, {
  'id': 'A75.9',
  'english': 'Typhus fever, unspecified',
  'vietnamese': 'Sốt phát ban, không đặc hiệu',
}, {
  'id': 'A77',
  'english': 'Spotted fever [tick-borne rickettsioses]',
  'vietnamese': 'Sốt phát ban dạng đốm [bệnh Rickttsia do bọ ve truyền]',
}, {
  'id': 'A77.0',
  'english': 'Spotted fever due to Rickettsia rickettsii',
  'vietnamese': 'Sốt phát ban dạng đốm do Rickettsia rikettsii',
}, {
  'id': 'A77.1',
  'english': 'Spotted fever due to Rickettsia conorii',
  'vietnamese': 'Sốt phát ban dạng đốm do Rickettsia conoril',
}, {
  'id': 'A77.2',
  'english': 'Spotted fever due to Rickettsia sibirica',
  'vietnamese': 'Sốt phát ban dạng đốm do Rickettsia siberica',
}, {
  'id': 'A77.3',
  'english': 'Spotted fever due to Rickettsia australis',
  'vietnamese': 'Sốt phát ban dạng đốm do Rickettsia australis',
}, {
  'id': 'A77.8',
  'english': 'Other spotted fevers',
  'vietnamese': 'Sốt phát ban dạng đốm khác',
}, {
  'id': 'A77.9',
  'english': 'Spotted fever, unspecified',
  'vietnamese': 'Sốt phát ban dạng đốm, không đặc hiệu',
}, {'id': 'A78', 'english': 'Q fever', 'vietnamese': 'Sốt Q'}, {
  'id': 'A79',
  'english': 'Other rickettsioses',
  'vietnamese': 'Các bệnh do Rickettsia khác',
}, {'id': 'A79.0', 'english': 'Trench fever', 'vietnamese': 'Sốt chiến hào'}, {
  'id': 'A79.1',
  'english': 'Rickettsialpox due to Rickettsia akari',
  'vietnamese': 'Mụn Rickettsia do Rickettsia akari',
}, {
  'id': 'A79.8',
  'english': 'Other specified rickettsioses',
  'vietnamese': 'Bệnh do Rickettsia xác định khác',
}, {
  'id': 'A79.9',
  'english': 'Rickettsiosis, unspecified',
  'vietnamese': 'Nhiễm Rickettsia, không đặc hiệu',
}, {'id': 'A80', 'english': 'Acute poliomyelitis', 'vietnamese': 'Bệnh bại liệt cấp'}, {
  'id': 'A80.0',
  'english': 'Acute paralytic poliomyelitis, vaccine-associated',
  'vietnamese': 'Bệnh bại liệt cấp, thể liệt, liên quan đến vaccins',
}, {
  'id': 'A80.1',
  'english': 'Acute paralytic poliomyelitis, wild virus, imported',
  'vietnamese': 'Bệnh bại liệt cấp, thể liệt, do virus hoang dại, ngoại lai',
}, {
  'id': 'A80.2',
  'english': 'Acute paralytic poliomyelitis, wild virus, indigenous',
  'vietnamese': 'Bệnh bại liệt cấp, thể liệt, virus hoang dại, nội địa',
}, {
  'id': 'A80.3',
  'english': 'Acute paralytic poliomyelitis, other and unspecified',
  'vietnamese': 'Bệnh bại liệt cấp, thể liệt khác và không đặc hiệu',
}, {
  'id': 'A80.4',
  'english': 'Acute nonparalytic poliomyelitis',
  'vietnamese': 'Bệnh bại liệt cấp, không liệt',
}, {
  'id': 'A80.9',
  'english': 'Acute poliomyelitis, unspecified',
  'vietnamese': 'Bệnh bại liệt cấp, không đặc hiệu',
}, {
  'id': 'A81',
  'english': 'Atypical virus infections of central nervous system',
  'vietnamese': 'Bệnh do virus không điển hình ở hệ thần kinh trung ương',
}, {
  'id': 'A81.0',
  'english': 'Creutzfeldt-Jakob disease',
  'vietnamese': 'Bệnh bò điên (Creutzfeidt-Jakob)',
}, {
  'id': 'A81.1',
  'english': 'Subacute sclerosing panencephalitis',
  'vietnamese': 'Viêm toàn não xơ hóa bán cấp',
}, {
  'id': 'A81.2',
  'english': 'Progressive multifocal leukoencephalopathy',
  'vietnamese': 'Bệnh lý não chất trắng đa ổ tiến triển',
}, {
  'id': 'A81.8',
  'english': 'Other atypical virus infections of central nervous system',
  'vietnamese': 'Nhiễm virus không điển hình khác của hệ thần kinh trung ương',
}, {
  'id': 'A81.9',
  'english': 'Atypical virus infection of central nervous system, unspecified',
  'vietnamese': 'Nhiễm vius không điển hình của hệ thần kinh trung ương, không đặc hiệu',
}, {'id': 'A82', 'english': 'Rabies', 'vietnamese': 'Bệnh dại'}, {
  'id': 'A82.0',
  'english': 'Sylvatic rabies',
  'vietnamese': 'Bệnh dại vùng rừng núi',
}, {'id': 'A82.1', 'english': 'Urban rabies', 'vietnamese': 'Bệnh dại thành thị'}, {
  'id': 'A82.9',
  'english': 'Rabies, unspecified',
  'vietnamese': 'Bệnh dại, không đặc hiệu',
}, {
  'id': 'A83',
  'english': 'Mosquito-borne viral encephalitis',
  'vietnamese': 'Viêm não virus do muỗi truyền',
}, {'id': 'A83.0', 'english': 'Japanese encephalitis', 'vietnamese': 'Viêm não Nhật Bản'}, {
  'id': 'A83.1',
  'english': 'Western equine encephalitis',
  'vietnamese': 'Viêm não ngựa miền Tây',
}, {
  'id': 'A83.2',
  'english': 'Eastern equine encephalitis',
  'vietnamese': 'Viêm não ngựa miền Đông',
}, {'id': 'A83.3', 'english': 'St Louis encephalitis', 'vietnamese': 'Viêm não St. Louis'}, {
  'id': 'A83.4',
  'english': 'Australian encephalitis',
  'vietnamese': 'Viêm não châu Úc',
}, {'id': 'A83.5', 'english': 'California encephalitis', 'vietnamese': 'Viêm não California'}, {
  'id': 'A83.6',
  'english': 'Rocio virus disease',
  'vietnamese': 'Bệnh virus Rocio',
}, {
  'id': 'A83.8',
  'english': 'Other mosquito-borne viral encephalitis',
  'vietnamese': 'Viêm não virus khác do muỗi truyền',
}, {
  'id': 'A83.9',
  'english': 'Mosquito-borne viral encephalitis, unspecified',
  'vietnamese': 'Viêm não virus do muỗi truyền, không đặc hiệu',
}, {
  'id': 'A84',
  'english': 'Tick-borne viral encephalitis',
  'vietnamese': 'Viêm não virus do ve truyền',
}, {
  'id': 'A84.0',
  'english': 'Far Eastern tick-borne encephalitis [Russian spring-summer encephalitis]',
  'vietnamese': 'Viêm não Viễn Đông do ve truyền [viêm não xuân hè Nga]',
}, {
  'id': 'A84.1',
  'english': 'Central European tick-borne encephalitis',
  'vietnamese': 'Viêm não Trung Âu do ve truyền',
}, {
  'id': 'A84.8',
  'english': 'Other tick-borne viral encephalitis',
  'vietnamese': 'Viêm não virus khác do ve truyền',
}, {
  'id': 'A84.9',
  'english': 'Tick-borne viral encephalitis, unspecified',
  'vietnamese': 'Viêm não virus do ve truyền, không đặc hiệu',
}, {
  'id': 'A85',
  'english': 'Other viral encephalitis, not elsewhere classified',
  'vietnamese': 'Viêm não virus khác, chưa phân loại',
}, {
  'id': 'A85.0†',
  'english': 'Enteroviral encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do enterovirus (G05.1*)',
}, {
  'id': 'A85.1†',
  'english': 'Adenoviral encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do Adenovirus (G05.1*)',
}, {
  'id': 'A85.2',
  'english': 'Arthropod-borne viral encephalitis, unspecified',
  'vietnamese': 'Viêm não virus do tiết túc truyền, không đặc hiệu',
}, {
  'id': 'A85.8',
  'english': 'Other specified viral encephalitis',
  'vietnamese': 'Viêm não virus xác định khác',
}, {
  'id': 'A86',
  'english': 'Unspecified viral encephalitis',
  'vietnamese': 'Viêm não virus không đặc hiệu',
}, {'id': 'A87', 'english': 'Viral meningitis', 'vietnamese': 'Viêm màng não do virus'}, {
  'id': 'A87.0†',
  'english': 'Enteroviral meningitis (G02.0*)',
  'vietnamese': 'Viêm màng não do Enterovirus (G02.0*)',
}, {
  'id': 'A87.1†',
  'english': 'Adenoviral meningitis (G02.0*)',
  'vietnamese': 'Viêm màng não do Adenovirus (G02.0*',
}, {
  'id': 'A87.2',
  'english': 'Lymphocytic choriomeningitis',
  'vietnamese': 'Viêm màng não - màng nhện tăng lympho bào',
}, {
  'id': 'A87.8',
  'english': 'Other viral meningitis',
  'vietnamese': 'Viêm màng não do virus khác',
}, {
  'id': 'A87.9',
  'english': 'Viral meningitis, unspecified',
  'vietnamese': 'Viêm màng não do virus, không đặc hiệu',
}, {
  'id': 'A88',
  'english': 'Other viral infections of central nervous system, not elsewhere classified',
  'vietnamese': 'Nhiễm virus khác của hệ thần kinh trung ương, chưa phân loại',
}, {
  'id': 'A88.0',
  'english': 'Enteroviral exanthematous fever [Boston exanthem]',
  'vietnamese': 'Sốt phát ban do Enterovirus (phát ban Boston)',
}, {'id': 'A88.1', 'english': 'Epidemic vertigo', 'vietnamese': 'Chóng mặt gây dịch'}, {
  'id': 'A88.8',
  'english': 'Other specified viral infections of central nervous system',
  'vietnamese': 'Nhiễm virus xác định khác của hệ thần kinh trung ương',
}, {
  'id': 'A89',
  'english': 'Unspecified viral infection of central nervous system',
  'vietnamese': 'Nhiễm virus không đặc hiệu của hệ thần kinh trung ương',
}, {
  'id': 'A90',
  'english': 'Dengue fever (classical Dengue)',
  'vietnamese': 'Sốt Dengue (Dengue cổ điển)',
}, {'id': 'A91', 'english': 'Dengue heamorrhagic fever', 'vietnamese': 'Sốt xuất huyết Dengue'}, {
  'id': 'A92',
  'english': 'Other mosquito-borne viral fevers',
  'vietnamese': 'Bệnh sốt virus khác do muỗi truyền',
}, {
  'id': 'A92.0',
  'english': 'Chikungunya virus disease',
  'vietnamese': 'Bệnh virus Chikungunya',
}, {
  'id': 'A92.1',
  'english': 'O nyong-nyong fever',
  'vietnamese': 'Sốt do O nyong-nyong',
}, {'id': 'A92.2', 'english': 'Venezuelan equine fever', 'vietnamese': 'Sốt ngựa Venezuelan'}, {
  'id': 'A92.3',
  'english': 'West Nile virus infection',
  'vietnamese': 'Bệnh virus vùng Tây sông Nil',
}, {'id': 'A92.4', 'english': 'Rift Valley fever', 'vietnamese': 'Sốt thung lũng Rift'}, {
  'id': 'A92.8',
  'english': 'Other specified mosquito-borne viral fevers',
  'vietnamese': 'Sốt virus do muỗi truyền xác định khác',
}, {
  'id': 'A92.9',
  'english': 'Mosquito-borne viral fever, unspecified',
  'vietnamese': 'Sốt virus do muỗi truyền, không đặc hiệu',
}, {
  'id': 'A93',
  'english': 'Other arthropod-borne viral fevers, not elsewhere classified',
  'vietnamese': 'Sốt virus khác do tiết túc truyền, chưa phân loại',
}, {'id': 'A93.0', 'english': 'Oropouche virus disease', 'vietnamese': 'Bệnh virus Oropouche'}, {
  'id': 'A93.1',
  'english': 'Sandfly fever',
  'vietnamese': 'Sốt muỗi cát',
}, {'id': 'A93.2', 'english': 'Colorado tick fever', 'vietnamese': 'Sốt do ve Colorado'}, {
  'id': 'A93.8',
  'english': 'Other specified arthropod-borne viral fevers',
  'vietnamese': 'Sốt virus xác định khác do tiết túc truyền',
}, {
  'id': 'A94',
  'english': 'Unspecified arthropod-borne viral fever',
  'vietnamese': 'Sốt virus do tiết túc truyền không đặc hiệu',
}, {'id': 'A95', 'english': 'Yellow fever', 'vietnamese': 'Sốt vàng'}, {
  'id': 'A95.0',
  'english': 'Sylvatic yellow fever',
  'vietnamese': 'Sốt vàng ở rừng',
}, {'id': 'A95.1', 'english': 'Urban yellow fever', 'vietnamese': 'Sốt vàng thành thị'}, {
  'id': 'A95.9',
  'english': 'Yellow fever, unspecified',
  'vietnamese': 'Sốt vàng, không đặc hiệu',
}, {
  'id': 'A96',
  'english': 'Arenaviral haemorrhagic fever',
  'vietnamese': 'Sốt xuất huyết do Arenavirus',
}, {'id': 'A96.0', 'english': 'Junin haemorrhagic fever', 'vietnamese': 'Sốt xuất huyết Junin'}, {
  'id': 'A96.1',
  'english': 'Machupo haemorrhagic fever',
  'vietnamese': 'Sốt xuất huyết Machupo',
}, {'id': 'A96.2', 'english': 'Lassa fever', 'vietnamese': 'Sốt Lassa'}, {
  'id': 'A96.8',
  'english': 'Other arenaviral haemorrhagic fevers',
  'vietnamese': 'Sốt xuất huyết do Arenavirus khác',
}, {
  'id': 'A96.9',
  'english': 'Arenaviral haemorrhagic fever, unspecified',
  'vietnamese': 'Sốt xuất huyết do Arenavirus không đặc hiệu',
}, {
  'id': 'A98',
  'english': 'Other viral haemorrhagic fevers, not elsewhere classified',
  'vietnamese': 'Sốt xuất huyết do virus khác, chưa phân loại',
}, {
  'id': 'A98.0',
  'english': 'Crimean-Congo haemorrhagic fever',
  'vietnamese': 'Sốt xuất huyết Crimean - Congo',
}, {'id': 'A98.1', 'english': 'Omsk haemorrhagic fever', 'vietnamese': 'Sốt xuất huyết Omsk'}, {
  'id': 'A98.2',
  'english': 'Kyasanur Forest disease',
  'vietnamese': 'Bệnh vùng rừng Kyasanur',
}, {'id': 'A98.3', 'english': 'Marburg virus disease', 'vietnamese': 'Bệnh virus Marburg'}, {
  'id': 'A98.4',
  'english': 'Ebola virus disease',
  'vietnamese': 'Bệnh virus Ebola',
}, {
  'id': 'A98.5',
  'english': 'Haemorrhagic fever with renal syndrome',
  'vietnamese': 'Sốt xuất huyết với hội chứng thận',
}, {
  'id': 'A98.8',
  'english': 'Other specified viral haemorrhagic fevers',
  'vietnamese': 'Sốt xuất huyết do virus xác định khác',
}, {
  'id': 'A99',
  'english': 'Unspecified viral haemorrhagic Fever',
  'vietnamese': 'Sốt xuất huyết do virus không xác định',
}, {
  'id': 'B00',
  'english': 'Herpesviral [herpes simplex] infections',
  'vietnamese': 'Bệnh do Herpes simplex',
}, {'id': 'B00.0', 'english': 'Eczema herpeticum', 'vietnamese': 'Chàm do virus Herpes'}, {
  'id': 'B00.1',
  'english': 'Herpesviral vesicular dermatitis',
  'vietnamese': 'Viêm da rộp nước do virus Herpes',
}, {
  'id': 'B00.2',
  'english': 'Herpesviral gingivostomatitis and pharyngotonsillitis',
  'vietnamese': 'Viêm miệng - lợi và viêm amidan - hầu do virus Herpes',
}, {
  'id': 'B00.3†',
  'english': 'Herpesviral meningitisG02.0',
  'vietnamese': 'Viêm màng não do virus Herpes G02.0',
}, {
  'id': 'B00.4†',
  'english': 'Herpesviral encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do virus Herpes (G05.1*)',
}, {
  'id': 'B00.5',
  'english': 'Herpesviral ocular disease',
  'vietnamese': 'Bệnh mắt do virus Herpes',
}, {
  'id': 'B00.7',
  'english': 'Disseminated herpesviral disease',
  'vietnamese': 'Bệnh virus Herpes lan tỏa',
}, {
  'id': 'B00.8',
  'english': 'Other forms of herpesviral infection',
  'vietnamese': 'Dạng khác của nhiễm virus Herpes',
}, {
  'id': 'B00.9',
  'english': 'Herpesviral infection, unspecified',
  'vietnamese': 'Nhiễm Virus Herpes, không đặc hiệu',
}, {'id': 'B01', 'english': 'Varicella [chickenpox]', 'vietnamese': 'Thủy đậu'}, {
  'id': 'B01.0†',
  'english': 'Varicella meningitis (G02.0*)',
  'vietnamese': 'Viêm màng não do thủy đậu (G02.0*)',
}, {
  'id': 'B01.1†',
  'english': 'Varicella encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do thủy đậu (G05.1*)',
}, {
  'id': 'B01.2†',
  'english': 'Varicella pneumonia (J17.1*)',
  'vietnamese': 'Viêm phổi do thủy đậu (J17.1*)',
}, {
  'id': 'B01.8',
  'english': 'Varicella with other complications',
  'vietnamese': 'Thủy đậu với biến chứng khác',
}, {
  'id': 'B01.9',
  'english': 'Varicella without complication',
  'vietnamese': 'Thủy đậu không biến chứng',
}, {'id': 'B02', 'english': 'Zoster [herpes zoster]', 'vietnamese': 'Bệnh do herpes zoster'}, {
  'id': 'B02.0†',
  'english': 'Zoster encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do Zoster (G05.1*)',
}, {
  'id': 'B02.1†',
  'english': 'Zoster meningitis (G02.0*)',
  'vietnamese': 'Viêm màng não do Zoster (G02.0*)',
}, {
  'id': 'B02.2†',
  'english': 'Zoster with other nervous system involvement',
  'vietnamese': 'Tổn thương hệ thần kinh khác do zoster',
}, {'id': 'B02.3', 'english': 'Zoster ocular disease', 'vietnamese': 'Bệnh mắt do Zoster'}, {
  'id': 'B02.7',
  'english': 'Disseminated zoster',
  'vietnamese': 'Bệnh Zoster lan tỏa',
}, {
  'id': 'B02.8',
  'english': 'Zoster with other complications',
  'vietnamese': 'Bệnh Zoster với biến chứng khác',
}, {
  'id': 'B02.9',
  'english': 'Zoster without complication',
  'vietnamese': 'Bệnh Zoster không biến chứng',
}, {'id': 'B03', 'english': 'Smallpox', 'vietnamese': 'Đậu mùa'}, {
  'id': 'B04',
  'english': 'Monkeypox',
  'vietnamese': 'Đậu khỉ',
}, {'id': 'B05', 'english': 'Measles', 'vietnamese': 'Sởi'}, {
  'id': 'B05.0†',
  'english': 'Measles complicated by encephalitisG05.1',
  'vietnamese': 'Sởi biến chứng viêm não G05.1',
}, {
  'id': 'B05.1†',
  'english': 'Measles complicated by meningitisG02.0',
  'vietnamese': 'Sởi biến chứng viêm màng não G02.0',
}, {
  'id': 'B05.2†',
  'english': 'Measles complicated by pneumoniaJ17.1',
  'vietnamese': 'Sởi biến chứng viêm phổi J17.1',
}, {
  'id': 'B05.3†',
  'english': 'Measles complicated by otitis mediaH67.1',
  'vietnamese': 'Sởi biến chứng viêm tai giữa H67.1',
}, {
  'id': 'B05.4',
  'english': 'Measles with intestinal complications',
  'vietnamese': 'Sởi với biến chứng ở ruột',
}, {
  'id': 'B05.8',
  'english': 'Measles with other complications',
  'vietnamese': 'Sởi với biến chứng khác',
}, {
  'id': 'B05.9',
  'english': 'Measles without complication',
  'vietnamese': 'Sởi không biến chứng',
}, {
  'id': 'B06',
  'english': 'Rubella [German measles]',
  'vietnamese': 'Bệnh rubella (sởi Đức)',
}, {
  'id': 'B06.0†',
  'english': 'Rubella with neurological complications',
  'vietnamese': 'Bệnh rubella với biến chứng thần kinh',
}, {
  'id': 'B06.8',
  'english': 'Rubella with other complications',
  'vietnamese': 'Bệnh rubella với biến chứng khác',
}, {
  'id': 'B06.9',
  'english': 'Rubella without complication',
  'vietnamese': 'Bệnh rubella không có biến chứng',
}, {'id': 'B07', 'english': 'Viral warts', 'vietnamese': 'Bệnh mụn cóc do virus'}, {
  'id': 'B08',
  'english': 'Other viral infections characterized by skin and mucous membrane lesions, not elsewhere classified',
  'vietnamese': 'Nhiễm virus khác, có biểu hiện tổn thương tại da và niêm mạc, chưa phân loại',
}, {
  'id': 'B08.0',
  'english': 'Other orthopoxvirus infections',
  'vietnamese': 'Nhiễm orthopoxvirus khác',
}, {'id': 'B08.1', 'english': 'Molluscum contagiosum', 'vietnamese': 'U mềm lây'}, {
  'id': 'B08.2',
  'english': 'Exanthema subitum [sixth disease]',
  'vietnamese': 'Phát ban đột ngột [bệnh thứ sáu]',
}, {
  'id': 'B08.3',
  'english': 'Erythema infectiosum [fifth disease]',
  'vietnamese': 'Ban đỏ truyền nhiễm [bệnh thứ năm]',
}, {
  'id': 'B08.4',
  'english': 'Enteroviral vesicular stomatitis with exanthem',
  'vietnamese': 'Viêm họng có phỏng nước do virus đường ruột với phát ban',
}, {
  'id': 'B08.5',
  'english': 'Enteroviral vesicular pharyngitis',
  'vietnamese': 'Viêm họng có phỏng nước do virus đường ruột',
}, {
  'id': 'B08.8',
  'english': 'Other specified viral infections characterized by skin and mucous membrane lesions',
  'vietnamese': 'Nhiễm virus xác định khác có biểu hiện tổn thương tại da và niêm mạc',
}, {
  'id': 'B09',
  'english': 'Unspecified viral infection characterized by skin and mucous membrane lesions',
  'vietnamese': 'Nhiễm virus không đặc hiệu, có biểu hiện tổn thương tại da và niêm mạc',
}, {'id': 'B15', 'english': 'Acute hepatitis A', 'vietnamese': 'Viêm gan A cấp'}, {
  'id': 'B15.0',
  'english': 'Hepatitis A with hepatic coma',
  'vietnamese': 'Viêm gan A có hôn mê gan',
}, {
  'id': 'B15.9',
  'english': 'Hepatitis A without hepatic coma',
  'vietnamese': 'Viêm gan A không có hôn mê gan',
}, {'id': 'B16', 'english': 'Acute hepatitis B', 'vietnamese': 'Viêm gan B cấp'}, {
  'id': 'B16.0',
  'english': 'Acute hepatitis B with delta-agent (coinfection) with hepatic coma',
  'vietnamese': 'Viêm gan B cấp có đồng nhiễm virus viêm gan D, có hôn mê gan',
}, {
  'id': 'B16.1',
  'english': 'Acute hepatitis B with delta-agent (coinfection) without hepatic coma',
  'vietnamese': 'Viêm gan B cấp có đồng nhiễm virus viêm gan D,, không có hôn mê gan',
}, {
  'id': 'B16.2',
  'english': 'Acute hepatitis B without delta-agent with hepatic coma',
  'vietnamese': 'Viêm gan B cấp, không có tác nhân delta, có hôn mê gan',
}, {
  'id': 'B16.9',
  'english': 'Acute hepatitis B without delta-agent and without hepatic coma',
  'vietnamese': 'Viêm gan B cấp, không có đồng nhiễm virus viêm gan D, và không có hôn mê gan',
}, {
  'id': 'B17',
  'english': 'Other acute viral hepatitis',
  'vietnamese': 'Viêm gan virus cấp khác',
}, {
  'id': 'B17.0',
  'english': 'Acute delta-(super)infection of hepatitis B carrier',
  'vietnamese': 'Viêm gan D cấp tính bội nhiễm trên người mang viêm gan B',
}, {'id': 'B17.1', 'english': 'Acute hepatitis C', 'vietnamese': 'Viêm gan C cấp'}, {
  'id': 'B17.2',
  'english': 'Acute hepatitis E',
  'vietnamese': 'Viêm gan E cấp',
}, {
  'id': 'B17.8',
  'english': 'Other specified acute viral hepatitis',
  'vietnamese': 'Viêm gan virus cấp xác định khác',
}, {
  'id': 'B17.9',
  'english': 'Acute viral hepatitis, unspecified',
  'vietnamese': 'Viên gan virus cấp, không đặc hiệu',
}, {'id': 'B18', 'english': 'Chronic viral hepatitis', 'vietnamese': 'Viêm gan virus mạn'}, {
  'id': 'B18.0',
  'english': 'Chronic viral hepatitis B with delta-agent',
  'vietnamese': 'Viêm gan virus B mạn, có đồng nhiễm viêm gan virus D',
}, {
  'id': 'B18.1',
  'english': 'Chronic viral hepatitis B without delta-agent',
  'vietnamese': 'Viêm gan virus B mạn, không có đồng nhiễm viêm gan virus D',
}, {
  'id': 'B18.2',
  'english': 'Chronic viral hepatitis C',
  'vietnamese': 'Viêm gan (virus) C mạn',
}, {
  'id': 'B18.8',
  'english': 'Other chronic viral hepatitis',
  'vietnamese': 'Viêm gan virus mạn khác',
}, {
  'id': 'B18.9',
  'english': 'Chronic viral hepatitis, unspecified',
  'vietnamese': 'Viêm gan virus mạn, không đặc hiệu',
}, {
  'id': 'B19',
  'english': 'Unspecified viral hepatitis',
  'vietnamese': 'Viêm gan virus không đặc hiệu',
}, {
  'id': 'B19.0',
  'english': 'Unspecified viral hepatitis hepatic with coma',
  'vietnamese': 'Viêm gan virus không đặc hiệu, có hôn mê gan',
}, {
  'id': 'B19.9',
  'english': 'Unspecified viral hepatitis without hepatic coma',
  'vietnamese': 'Viêm gan virus không đặc hiệu, không có hôn mê gan',
}, {
  'id': 'B20',
  'english': 'Human immunodeficiency virus [HIV] disease resulting in infectious and parasitic diseases',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh nhiễm trùng và ký sinh trùng',
}, {
  'id': 'B20.0',
  'english': 'HIV disease resulting in mycobacterial infection',
  'vietnamese': 'Bệnh HIV dẫn đến nhiễm mycobacterium',
}, {
  'id': 'B20.1',
  'english': 'HIV disease resulting in other bacterial infections',
  'vietnamese': 'Bệnh HIV dẫn đến nhiễm trùng khác',
}, {
  'id': 'B20.2',
  'english': 'HIV disease resulting in cytomegaloviral disease',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh virus đại bào',
}, {
  'id': 'B20.3',
  'english': 'HIV disease resulting in other viral infections',
  'vietnamese': 'Bệnh HIV dẫn đến nhiễm virus khác',
}, {
  'id': 'B20.4',
  'english': 'HIV disease resulting in candidiasis',
  'vietnamese': 'Bệnh HIV dẫn đến nhiễm candida',
}, {
  'id': 'B20.5',
  'english': 'HIV disease resulting in other mycoses',
  'vietnamese': 'Bệnh HIV dẫn đến nhiễm nấm khác',
}, {
  'id': 'B20.6',
  'english': 'HIV disease resulting in Pneumocystis jirovecii pneumonia',
  'vietnamese': 'Bệnh HIV dẫn đến viêm phổi do Pneumocystis jirovecii',
}, {
  'id': 'B20.7',
  'english': 'HIV disease resulting in multiple infections',
  'vietnamese': 'Bệnh do HIV dẫn đến nhiễm trùng do nhiều căn nguyên',
}, {
  'id': 'B20.8',
  'english': 'HIV disease resulting in other infectious and parasitic diseases',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh nhiễm trùng và ký sinh trùng khác',
}, {
  'id': 'B20.9',
  'english': 'HIV disease resulting in unspecified infectious or parasitic disease',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh nhiễm trùng và ký sinh trùng không đặc hiệu',
}, {
  'id': 'B21',
  'english': 'Human immunodeficiency virus [HIV] disease resulting in malignant neoplasms',
  'vietnamese': 'Bệnh HIV gây u ác tính',
}, {
  'id': 'B21.0',
  'english': 'HIV disease resulting in Kaposi s sarcoma',
  'vietnamese': 'Bệnh HIV dẫn đến u sarcom Kaposi',
}, {
  'id': 'B21.1',
  'english': 'HIV disease resulting in Burkitt s lymphoma',
  'vietnamese': 'Bệnh HIV dẫn đến u lympho Burkitt',
}, {
  'id': 'B21.2',
  'english': 'HIV disease resulting in other types of non-Hodgkin s lymphoma',
  'vietnamese': 'Bệnh HIV dẫn đến u lympho không do Hodgkin',
}, {
  'id': 'B21.3',
  'english': 'HIV disease resulting in other malignant neoplasms of lymphoid, haematopoietic and related tissue',
  'vietnamese': 'Bệnh HIV dẫn đến u ác tính khác của tổ chức bạch huyết, tạo máu và tổ chức liên quan',
}, {
  'id': 'B21.7',
  'english': 'HIV disease resulting in multiple malignant neoplasms',
  'vietnamese': 'Bệnh HIV dẫn đến nhiều u ác tính',
}, {
  'id': 'B21.8',
  'english': 'HIV disease resulting in other malignant neoplasms',
  'vietnamese': 'Bệnh HIV dẫn đến u ác tính khác',
}, {
  'id': 'B21.9',
  'english': 'HIV disease resulting in unspecified malignant neoplasm',
  'vietnamese': 'Bệnh HIV dẫn đến u ác tính không đặc hiệu',
}, {
  'id': 'B22',
  'english': 'Human immunodeficiency virus [HIV] disease resulting in other specified diseases',
  'vietnamese': 'Bệnh HIV dẫn đến các bệnh xác định khác',
}, {
  'id': 'B22.0',
  'english': 'HIV disease resulting in encephalopathy',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh lý não',
}, {
  'id': 'B22.1',
  'english': 'HIV disease resulting in lymphoid interstitial pneumonitis',
  'vietnamese': 'Bệnh do HIV dẫn đến viêm phổi mô kẽ lympho bào',
}, {
  'id': 'B22.2',
  'english': 'HIV disease resulting in wasting syndrome',
  'vietnamese': 'Bệnh HIV dẫn đến hội chứng suy kiệt',
}, {
  'id': 'B22.7',
  'english': 'HIV disease resulting in multiple diseases classified elsewhere',
  'vietnamese': 'Bệnh HIV dẫn đến nhiều bệnh phân loại phần khác',
}, {
  'id': 'B23',
  'english': 'Human immunodeficiency virus [HIV] disease resulting in other conditions',
  'vietnamese': 'Bệnh HIV dẫn đến bệnh lý khác',
}, {
  'id': 'B23.0',
  'english': 'Acute HIV infection syndrome',
  'vietnamese': 'Hội chứng nhiễm HIV cấp',
}, {
  'id': 'B23.1',
  'english': 'HIV disease resulting in (persistent) generalized lymphadenopathy',
  'vietnamese': 'Bệnh HIV dẫn đến (kéo dài) bệnh lý hạch toàn thể',
}, {
  'id': 'B23.2',
  'english': 'HIV disease resulting in haematological and immunological abnormalities, not elsewhere classified',
  'vietnamese': 'Bệnh HIV dẫn đến rối loạn huyết học và miễn dịch, chưa phân loại',
}, {
  'id': 'B23.8',
  'english': 'HIV disease resulting in other specified conditions',
  'vietnamese': 'Bệnh HIV dẫn đến các tình trạng xác định khác',
}, {
  'id': 'B24',
  'english': 'Unspecified human immunodeficiency virus',
  'vietnamese': 'Bệnh do HIV không đặc hiệu',
}, {'id': 'B25', 'english': 'Cytomegaloviral disease', 'vietnamese': 'Nhiễm virus đại bào'}, {
  'id': 'B25.0†',
  'english': 'Cytomegaloviral pneumonitisJ17.1',
  'vietnamese': 'Viêm phổi do cytomegalovirus (J17.1*)',
}, {
  'id': 'B25.1†',
  'english': 'Cytomegaloviral hepatitisK77.0',
  'vietnamese': 'Viêm gan do cytomegalovirus (K77.0*)',
}, {
  'id': 'B25.2†',
  'english': 'Cytomegaloviral pancreatitisK87.1',
  'vietnamese': 'Viêm tụy do cytomegalovirus (K87.1*)',
}, {
  'id': 'B25.8',
  'english': 'Other cytomegaloviral diseases',
  'vietnamese': 'Bệnh khác do cytomegalovirus',
}, {
  'id': 'B25.9',
  'english': 'Cytomegaloviral disease, unspecified',
  'vietnamese': 'Bệnh do cytomegalovirus, không đặc hiệu',
}, {'id': 'B26', 'english': 'Mumps', 'vietnamese': 'Bệnh quai bị'}, {
  'id': 'B26.0†',
  'english': 'Mumps orchitis (N51.1*)',
  'vietnamese': 'Viêm tinh hoàn do quai bị (N51.1*)',
}, {
  'id': 'B26.1†',
  'english': 'Mumps meningitis (G02.0*)',
  'vietnamese': 'Viêm màng não do quai bị (G02.0*)',
}, {
  'id': 'B26.2†',
  'english': 'Mumps encephalitis (G05.1*)',
  'vietnamese': 'Viêm não do quai bị (G05.1*)',
}, {
  'id': 'B26.3†',
  'english': 'Mumps pancreatitis (K87.1*)',
  'vietnamese': 'Viêm tụy do quai bị (K87.1*)',
}, {
  'id': 'B26.8',
  'english': 'Mumps with other complications',
  'vietnamese': 'Bệnh quai bị với biến chứng khác',
}, {
  'id': 'B26.9',
  'english': 'Mumps without complication',
  'vietnamese': 'Bệnh quai bị không biến chứng',
}, {
  'id': 'B27',
  'english': 'Infectious mononucleosis',
  'vietnamese': 'Bệnh tăng bạch cầu đơn nhân nhiễm trùng',
}, {
  'id': 'B27.0',
  'english': 'Gammaherpesviral mononucleosis',
  'vietnamese': 'Bệnh tăng bạch cầu đơn nhân do virus Herpes gamma',
}, {
  'id': 'B27.1',
  'english': 'Cytomegaloviral mononucleosis',
  'vietnamese': 'Bệnh tăng bạch cầu đơn nhân do virus đại bào',
}, {
  'id': 'B27.8',
  'english': 'Other infectious mononucleosis',
  'vietnamese': 'Bệnh tăng bạch cầu đơn nhân nhiễm trùng khác',
}, {
  'id': 'B27.9',
  'english': 'Infectious mononucleosis, unspecified',
  'vietnamese': 'Bệnh tăng bạch cầu đơn nhân nhiễm trùng, không đặc hiệu',
}, {'id': 'B30', 'english': 'Viral conjunctivitis', 'vietnamese': 'Viêm kết mạc do virus'}, {
  'id': 'B30.0†',
  'english': 'Keratoconjunctivitis due to adenovirus (H19.2*)',
  'vietnamese': 'Viêm kết - giác mạc do adenovirus (H19.2*)',
}, {
  'id': 'B30.1†',
  'english': 'Conjunctivitis due to adenovirus (H13.1*)',
  'vietnamese': 'Viêm kết mạc do adenovirus (H13.1*)',
}, {
  'id': 'B30.2',
  'english': 'Viral pharyngoconjunctivitis',
  'vietnamese': 'Viêm hầu - kết mạc do virus',
}, {
  'id': 'B30.3†',
  'english': 'Acute epidemic haemorrhagic conjunctivitis (enteroviral) (H13.1*)',
  'vietnamese': 'Viêm kết mạc gây dịch xuất huyết cấp (do virus đường ruột) (H13.1*)',
}, {
  'id': 'B30.8†',
  'english': 'Other viral conjunctivitis (H13.1*)',
  'vietnamese': 'Viêm kết mạc do virus khác (H13.1*)',
}, {
  'id': 'B30.9',
  'english': 'Viral conjunctivitis, unspecified',
  'vietnamese': 'Viêm kết mạc do virus, không đặc hiệu',
}, {
  'id': 'B33',
  'english': 'Other viral diseases, not elsewhere classified',
  'vietnamese': 'Bệnh nhiễm virus khác, chưa phân loại',
}, {'id': 'B33.0', 'english': 'Epidemic myalgia', 'vietnamese': 'Bệnh đau cơ gây dịch'}, {
  'id': 'B33.1',
  'english': 'Ross River disease',
  'vietnamese': 'Bệnh Ross River',
}, {'id': 'B33.2', 'english': 'Viral carditis', 'vietnamese': 'Viêm tim do virus'}, {
  'id': 'B33.3',
  'english': 'Retrovirus infections, not elsewhere classified',
  'vietnamese': 'Nhiễm Retrovirus, chưa được phân loại',
}, {
  'id': 'B33.4†',
  'english': 'Hantavirus (cardio-)pulmonary syndrome [HPS] [HCPS] (J17.1*)',
  'vietnamese': 'Hội chứng tim- phổi do Hanta virus [HPS] [HCPS] (J17.1*)',
}, {
  'id': 'B33.8',
  'english': 'Other specified viral diseases',
  'vietnamese': 'Bệnh do virus xác định khác',
}, {
  'id': 'B34',
  'english': 'Viral infection of unspecified site',
  'vietnamese': 'Nhiễm virus ở vị trí không xác định',
}, {
  'id': 'B34.0',
  'english': 'Adenovirus infection, unspecified site',
  'vietnamese': 'Nhiễm adenovirus, vị trí không xác định',
}, {
  'id': 'B34.1',
  'english': 'Enterovirus infection, unspecified site',
  'vietnamese': 'Nhiễm virus đường ruột, vị trí không xác định',
}, {
  'id': 'B34.2',
  'english': 'Coronavirus infection, unspecified site',
  'vietnamese': 'Nhiễm coronavirus, vị trí không xác định',
}, {
  'id': 'B34.3',
  'english': 'Parvovirus infection, unspecified site',
  'vietnamese': 'Nhiễm Parvovirus, vị trí không xác định',
}, {
  'id': 'B34.4',
  'english': 'Papovavirus infection, unspecified site',
  'vietnamese': 'Nhiễm Papovavirus, vị trí không xác định',
}, {
  'id': 'B34.8',
  'english': 'Other viral infections of unspecified site',
  'vietnamese': 'Nhiễm virus khác ở vị trí không xác định',
}, {
  'id': 'B34.9',
  'english': 'Viral infection, unspecified',
  'vietnamese': 'Nhiễm virus, không xác định',
}, {'id': 'B35', 'english': 'Dermatophytosis', 'vietnamese': 'Nhiễm nấm da'}, {
  'id': 'B35.0',
  'english': 'Tinea barbae and tinea capitis',
  'vietnamese': 'Bệnh nấm ở cằm và nấm da đầu',
}, {'id': 'B35.1', 'english': 'Tinea unguium', 'vietnamese': 'Nấm móng'}, {
  'id': 'B35.2',
  'english': 'Tinea manuum',
  'vietnamese': 'Bệnh nấm da bàn tay',
}, {'id': 'B35.3', 'english': 'Tinea pedis', 'vietnamese': 'Bệnh nấm da chân'}, {
  'id': 'B35.4',
  'english': 'Tinea corporis',
  'vietnamese': 'Bệnh nấm da thân',
}, {'id': 'B35.5', 'english': 'Tinea imbricata', 'vietnamese': 'Bệnh nấm da vảy xếp lớp'}, {
  'id': 'B35.6',
  'english': 'Tinea cruris',
  'vietnamese': 'Bệnh nấm da đùi',
}, {'id': 'B35.8', 'english': 'Other dermatophytoses', 'vietnamese': 'Bệnh nấm da khác'}, {
  'id': 'B35.9',
  'english': 'Dermatophytosis, unspecified',
  'vietnamese': 'Bệnh nấm da, không đặc hiệu',
}, {'id': 'B36', 'english': 'Other superficial mycoses', 'vietnamese': 'Nhiễm nấm nông khác'}, {
  'id': 'B36.0',
  'english': 'Pityriasis versicolor',
  'vietnamese': 'Bệnh lang ben',
}, {'id': 'B36.1', 'english': 'Tinea nigra', 'vietnamese': 'Bệnh nấm da có thương tổn màu đen'}, {
  'id': 'B36.2',
  'english': 'White piedra',
  'vietnamese': 'Bệnh nấm do Trichosporum cuteneum (râu, lông)',
}, {'id': 'B36.3', 'english': 'Black piedra', 'vietnamese': 'Bệnh nấm trứng đen (tóc)'}, {
  'id': 'B36.8',
  'english': 'Other specified superficial mycoses',
  'vietnamese': 'Bệnh nấm nông xác định khác',
}, {
  'id': 'B36.9',
  'english': 'Superficial mycosis, unspecified',
  'vietnamese': 'Bệnh nấm nông, không đặc hiệu',
}, {'id': 'B37', 'english': 'Candidiasis', 'vietnamese': 'Nhiễm candida'}, {
  'id': 'B37.0',
  'english': 'Candidal stomatitis',
  'vietnamese': 'Viêm miệng do candida',
}, {'id': 'B37.1', 'english': 'Pulmonary candidiasis', 'vietnamese': 'Nhiễm candida phổi'}, {
  'id': 'B37.2',
  'english': 'Candidiasis of skin and nail',
  'vietnamese': 'Nhiễm candida da và móng',
}, {
  'id': 'B37.3†',
  'english': 'Candidiasis of vulva and vagina (N77.1*)',
  'vietnamese': 'Nhiễm candida âm hộ và âm đạo (N77.1*)',
}, {
  'id': 'B37.4',
  'english': 'Candidiasis of other urogenital sites',
  'vietnamese': 'Nhiễm candida ở vị trí giữa tiết niệu âm đạo khác',
}, {
  'id': 'B37.5†',
  'english': 'Candidal meningitis (G02.1*)',
  'vietnamese': 'Viêm màng não do candida (G02.1*)',
}, {
  'id': 'B37.6†',
  'english': 'Candidal endocarditis (I39.8*)',
  'vietnamese': 'Viêm nội tâm mạc do candida (I39.8*)',
}, {
  'id': 'B37.7',
  'english': 'Candidal septicaemia',
  'vietnamese': 'Nhiễm trùng huyết do candida',
}, {
  'id': 'B37.8',
  'english': 'Candidiasis of other sites',
  'vietnamese': 'Nhiễm candida ở vị trí khác',
}, {
  'id': 'B37.9',
  'english': 'Candidiasis, unspecified',
  'vietnamese': 'Nhiễm candida không xác định',
}, {'id': 'B38', 'english': 'Coccidioidomycosis', 'vietnamese': 'Nhiễm nấm coccidioides'}, {
  'id': 'B38.0',
  'english': 'Acute pulmonary coccidioidomycosis',
  'vietnamese': 'Nhiễm nấm coccidioides ở phổi cấp tính',
}, {
  'id': 'B38.1',
  'english': 'Chronic pulmonary coccidioidomycosis',
  'vietnamese': 'Nhiễm nấm coccidioides ở phổi mãn tính',
}, {
  'id': 'B38.2',
  'english': 'Pulmonary coccidioidomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm coccidioides ở phổi, không đặc hiệu',
}, {
  'id': 'B38.3',
  'english': 'Cutaneous coccidioidomycosis',
  'vietnamese': 'Nhiễm nấm coccidioides ở da',
}, {
  'id': 'B38.4†',
  'english': 'Coccidioidomycosis meningitis (G02.1*)',
  'vietnamese': 'Viêm màng não do nấm coccidioides (G02.1*)',
}, {
  'id': 'B38.7',
  'english': 'Disseminated coccidioidomycosis',
  'vietnamese': 'Nhiễm nấm coccidioides lan tỏa',
}, {
  'id': 'B38.8',
  'english': 'Other forms of coccidioidomycosis',
  'vietnamese': 'Dạng khác của nhiễm nấm coccidioides',
}, {
  'id': 'B38.9',
  'english': 'Coccidioidomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm coccidioides, không đặc hiệu',
}, {'id': 'B39', 'english': 'Histoplasmosis', 'vietnamese': 'Nhiễm histoplasma'}, {
  'id': 'B39.0',
  'english': 'Acute pulmonary histoplasmosis capsulati',
  'vietnamese': 'Nhiễm histoplasma capsulatum ở phổi cấp tính',
}, {
  'id': 'B39.1',
  'english': 'Chronic pulmonary histoplasmosis capsulati',
  'vietnamese': 'Nhiễm histoplasma capsulatum ở phổi mãn tính',
}, {
  'id': 'B39.2',
  'english': 'Pulmonary histoplasmosis capsulati, unspecified',
  'vietnamese': 'Nhiễm histoplasma capsulatum ở phổi, không đặc hiệu',
}, {
  'id': 'B39.3',
  'english': 'Disseminated histoplasmosis capsulati',
  'vietnamese': 'Nhiễm histoplasma capsulatum lan tỏa',
}, {
  'id': 'B39.4',
  'english': 'Histoplasmosis capsulati, unspecified',
  'vietnamese': 'Nhiễm histoplasma capsulatum, không đặc hiệu',
}, {
  'id': 'B39.5',
  'english': 'Histoplasmosis duboisii',
  'vietnamese': 'Nhiễm histoplasma duboisii',
}, {
  'id': 'B39.9',
  'english': 'Histoplasmosis, unspecified',
  'vietnamese': 'Nhiễm histoplasma, không đặc hiệu',
}, {'id': 'B40', 'english': 'Blastomycosis', 'vietnamese': 'Nhiễm nấm blastomyces'}, {
  'id': 'B40.0',
  'english': 'Acute pulmonary blastomycosis',
  'vietnamese': 'Nhiễm nấm blastomyces ở phổi cấp tính',
}, {
  'id': 'B40.1',
  'english': 'Chronic pulmonary blastomycosis',
  'vietnamese': 'Nhiễm nấm blastomyces ở phổi mãn tính',
}, {
  'id': 'B40.2',
  'english': 'Pulmonary blastomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm blastomyces ở phổi, không đặc hiệu',
}, {
  'id': 'B40.3',
  'english': 'Cutaneous blastomycosis',
  'vietnamese': 'Nhiễm nấm blastomyces ở da',
}, {
  'id': 'B40.7',
  'english': 'Disseminated blastomycosis',
  'vietnamese': 'Nhiễm nấm blastomyces lan tỏa',
}, {
  'id': 'B40.8',
  'english': 'Other forms of blastomycosis',
  'vietnamese': 'Dạng khác của nhiễm nấm blastomyces',
}, {
  'id': 'B40.9',
  'english': 'Blastomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm blastomyces, không đặc hiệu',
}, {
  'id': 'B41',
  'english': 'Paracoccidioidomycosis',
  'vietnamese': 'Nhiễm nấm paracoccidioides',
}, {
  'id': 'B41.0',
  'english': 'Pulmonary paracoccidioidomycosis',
  'vietnamese': 'Nhiễm nấm paracoccidioides ở phổi',
}, {
  'id': 'B41.7',
  'english': 'Disseminated paracoccidioidomycosis',
  'vietnamese': 'Nhiễm nấm paracoccidioides lan tỏa',
}, {
  'id': 'B41.8',
  'english': 'Other forms of paracoccidioidomycosis',
  'vietnamese': 'Dạng khác của nhiễm nấm paracoccidioides',
}, {
  'id': 'B41.9',
  'english': 'Paracoccidioidomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm paracoccidioides, không đặc hiệu',
}, {'id': 'B42', 'english': 'Sporotrichosis', 'vietnamese': 'Nhiễm sporotrichum'}, {
  'id': 'B42.0†',
  'english': 'Pulmonary sporotrichosis (J99.8*)',
  'vietnamese': 'Nhiễm sporotrichum ở phổi (J99.8*)',
}, {
  'id': 'B42.1',
  'english': 'Lymphocutaneous sporotrichosis',
  'vietnamese': 'Nhiễm sporotrichum da - bạch huyết',
}, {
  'id': 'B42.7',
  'english': 'Disseminated sporotrichosis',
  'vietnamese': 'Nhiễm sporotrichum lan tỏa',
}, {
  'id': 'B42.8',
  'english': 'Other forms of sporotrichosis',
  'vietnamese': 'Dạng khác của nhiễm sporotrichum',
}, {
  'id': 'B42.9',
  'english': 'Sporotrichosis, unspecified',
  'vietnamese': 'Nhiễm sporotrichum, không đặc hiệu',
}, {
  'id': 'B43',
  'english': 'Chromomycosis and phaeomycotic abscess',
  'vietnamese': 'Nhiễm nấm chromoblastomycosa (nấm màu) và áp xe do phaeomyces',
}, {
  'id': 'B43.0',
  'english': 'Cutaneous chromomycosis',
  'vietnamese': 'Nhiễm nấm chromoblastomycosa ở da',
}, {
  'id': 'B43.1',
  'english': 'Phaeomycotic brain abscess',
  'vietnamese': 'Áp xe não do phaeomyces',
}, {
  'id': 'B43.2',
  'english': 'Subcutaneous phaeomycotic abscess and cyst',
  'vietnamese': 'Nang và áp xe dưới da do phaeomyces',
}, {
  'id': 'B43.8',
  'english': 'Other forms of chromomycosis',
  'vietnamese': 'Dạng khác của nhiễm nấm chromoblastomycosa',
}, {
  'id': 'B43.9',
  'english': 'Chromomycosis, unspecified',
  'vietnamese': 'Nhiễm nấm chromoblastomycosa, không đặc hiệu',
}, {'id': 'B44', 'english': 'Aspergillosis', 'vietnamese': 'Nhiễm aspergillus'}, {
  'id': 'B44.0',
  'english': 'Invasive pulmonary aspergillosis',
  'vietnamese': 'Nhiễm aspergillus ở phổi xâm lấn',
}, {
  'id': 'B44.1',
  'english': 'Other pulmonary aspergillosis',
  'vietnamese': 'Nhiễm aspergillus ở phổi khác',
}, {
  'id': 'B44.2',
  'english': 'Tonsillar aspergillosis',
  'vietnamese': 'Nhiễm aspergillus ở hạnh nhân',
}, {
  'id': 'B44.7',
  'english': 'Disseminated aspergillosis',
  'vietnamese': 'Nhiễm aspergillus lan tỏa',
}, {
  'id': 'B44.8',
  'english': 'Other forms of aspergillosis',
  'vietnamese': 'Dạng khác của nhiễm aspergillus',
}, {
  'id': 'B44.9',
  'english': 'Aspergillosis, unspecified',
  'vietnamese': 'Nhiễm aspergillus không đặc hiệu',
}, {'id': 'B45', 'english': 'Cryptococcosis', 'vietnamese': 'Nhiễm cryptococcus'}, {
  'id': 'B45.0',
  'english': 'Pulmonary cryptococcosis',
  'vietnamese': 'Nhiễm cryptococcus ở phổi',
}, {
  'id': 'B45.1',
  'english': 'Cerebral cryptococcosis',
  'vietnamese': 'Nhiễm cryptococcus ở não',
}, {
  'id': 'B45.2',
  'english': 'Cutaneous cryptococcosis',
  'vietnamese': 'Nhiễm cryptococcus ở da',
}, {
  'id': 'B45.3',
  'english': 'Osseous cryptococcosis',
  'vietnamese': 'Nhiễm cryptococcus ở xương',
}, {
  'id': 'B45.7',
  'english': 'Disseminated cryptococcosis',
  'vietnamese': 'Nhiễm cryptococcus lan tỏa',
}, {
  'id': 'B45.8',
  'english': 'Other forms of cryptococcosis',
  'vietnamese': 'Dạng khác của nhiễm cryptococcus',
}, {
  'id': 'B45.9',
  'english': 'Cryptococcosis, unspecified',
  'vietnamese': 'Nhiễm cryptococcus, không đặc hiệu',
}, {'id': 'B46', 'english': 'Zygomycosis', 'vietnamese': 'Nhiễm zygomycota'}, {
  'id': 'B46.0',
  'english': 'Pulmonary mucormycosis',
  'vietnamese': 'Nhiễm mucor ở phổi',
}, {
  'id': 'B46.1',
  'english': 'Rhinocerebral mucormycosis',
  'vietnamese': 'Nhiễm mucor ở mũi - não',
}, {
  'id': 'B46.2',
  'english': 'Gastrointestinal mucormycosis',
  'vietnamese': 'Nhiễm mucor ở đường tiêu hóa',
}, {'id': 'B46.3', 'english': 'Cutaneous mucormycosis', 'vietnamese': 'Nhiễm mucor ở da'}, {
  'id': 'B46.4',
  'english': 'Disseminated mucormycosis',
  'vietnamese': 'Nhiễm mucor lan toả',
}, {
  'id': 'B46.5',
  'english': 'Mucormycosis, unspecified',
  'vietnamese': 'Nhiễm mucor, không đặc hiệu',
}, {'id': 'B46.8', 'english': 'Other zygomycoses', 'vietnamese': 'Nhiễm zygomycetes khác'}, {
  'id': 'B46.9',
  'english': 'Zygomycosis, unspecified',
  'vietnamese': 'Nhiễm zygomycetes, không đặc hiệu',
}, {'id': 'B47', 'english': 'Mycetoma', 'vietnamese': 'U nấm'}, {
  'id': 'B47.0',
  'english': 'Eumycetoma',
  'vietnamese': 'U do eumycetes',
}, {'id': 'B47.1', 'english': 'Actinomycetoma', 'vietnamese': 'U do actinomyces'}, {
  'id': 'B47.9',
  'english': 'Mycetoma, unspecified',
  'vietnamese': 'U nấm, không đặc hiệu',
}, {
  'id': 'B48',
  'english': 'Other mycoses, not elsewhere classified',
  'vietnamese': 'Nhiễm nấm khác, chưa được phân loại',
}, {'id': 'B48.0', 'english': 'Lobomycosis', 'vietnamese': 'Nhiễm nấm lobo'}, {
  'id': 'B48.1',
  'english': 'Rhinosporidiosis',
  'vietnamese': 'Nhiễm rhinosporidium',
}, {'id': 'B48.2', 'english': 'Allescheriasis', 'vietnamese': 'Nhiễm allesscheria'}, {
  'id': 'B48.3',
  'english': 'Geotrichosis',
  'vietnamese': 'Nhiễm geotrichum',
}, {'id': 'B48.4', 'english': 'Penicillosis', 'vietnamese': 'Nhiễm penicillium'}, {
  'id': 'B48.7',
  'english': 'Opportunistic mycoses',
  'vietnamese': 'Nhiễm nấm cơ hội',
}, {
  'id': 'B48.8',
  'english': 'Other specified mycoses',
  'vietnamese': 'Nhiễm nấm xác định khác',
}, {
  'id': 'B49',
  'english': 'Unspecified mycosis',
  'vietnamese': 'Nhiễm nấm không đặc hiệu',
}, {
  'id': 'B50',
  'english': 'Plasmodium falciparum malaria',
  'vietnamese': 'Sốt rét do Plasmodium falciparum',
}, {
  'id': 'B50.0',
  'english': 'Plasmodium falciparum malaria with cerebral complications',
  'vietnamese': 'Sốt rét Plasmodium falciparum với biến chứng não',
}, {
  'id': 'B50.8',
  'english': 'Other severe and complicated Plasmodium falciparum malaria',
  'vietnamese': 'Sốt rét Plasmodium falciparum thể nặng và biến chứng khác',
}, {
  'id': 'B50.9',
  'english': 'Plasmodium falciparum malaria, unspecified',
  'vietnamese': 'Sốt rét Plasmodium falciparum, không đặc hiệu',
}, {
  'id': 'B51',
  'english': 'Plasmodium vivax malaria',
  'vietnamese': 'Sốt rét do Plasmodium vivax',
}, {
  'id': 'B51.0',
  'english': 'Plasmodium vivax malaria with rupture of spleen',
  'vietnamese': 'Sốt rét Plasmodium vivax với vỡ lách',
}, {
  'id': 'B51.8',
  'english': 'Plasmodium vivax malaria with other complications',
  'vietnamese': 'Sốt rét Plasmodium vivax với biến chứng khác',
}, {
  'id': 'B51.9',
  'english': 'Plasmodium vivax malaria without complication',
  'vietnamese': 'Sốt rét Plasmodium vivax không biến chứng',
}, {
  'id': 'B52',
  'english': 'Plasmodium malariae malaria',
  'vietnamese': 'Sốt rét do Plasmodium malariae',
}, {
  'id': 'B52.0',
  'english': 'Plasmodium malariae malaria with nephropathy',
  'vietnamese': 'Sốt rét Plasmodium malariae với bệnh lý thận',
}, {
  'id': 'B52.8',
  'english': 'Plasmodium malariae malaria with other complications',
  'vietnamese': 'Sốt rét Plasmodium malariae với biến chứng khác',
}, {
  'id': 'B52.9',
  'english': 'Plasmodium malariae malaria without complication',
  'vietnamese': 'Sốt rét Plasmodium malariae không biến chứng',
}, {
  'id': 'B53',
  'english': 'Other parasitologically confirmed malaria',
  'vietnamese': 'Sốt rét khác có xác nhận về ký sinh trùng học',
}, {
  'id': 'B53.0',
  'english': 'Plasmodium ovale malaria',
  'vietnamese': 'Sốt rét Plasmodium ovale',
}, {
  'id': 'B53.1',
  'english': 'Malaria due to simian plasmodia',
  'vietnamese': 'Sốt rét do plasmodia ở khỉ',
}, {
  'id': 'B53.8',
  'english': 'Other parasitologically confirmed malaria, not elsewhere classified',
  'vietnamese': 'Sốt rét khác xác nhận bằng ký sinh trùng học, chưa được phân loại',
}, {'id': 'B54', 'english': 'Unspecified malaria', 'vietnamese': 'Sốt rét không đặc hiệu'}, {
  'id': 'B55',
  'english': 'Leishmaniasis',
  'vietnamese': 'Bệnh do Leishmania',
}, {
  'id': 'B55.0',
  'english': 'Visceral leishmaniasis',
  'vietnamese': 'Bệnh do leishmania nội tạng',
}, {'id': 'B55.1', 'english': 'Cutaneous leishmaniasis', 'vietnamese': 'Bệnh do leishmania da'}, {
  'id': 'B55.2',
  'english': 'Mucocutaneous leishmaniasis',
  'vietnamese': 'Bệnh do leishmania da niêm mạc',
}, {
  'id': 'B55.9',
  'english': 'Leishmaniasis, unspecified',
  'vietnamese': 'Bệnh do leishmania, không đặc hiệu',
}, {
  'id': 'B56',
  'english': 'African trypanosomiasis',
  'vietnamese': 'Bệnh do trypanosoma châu Phi',
}, {
  'id': 'B56.0',
  'english': 'Gambiense trypanosomiasis',
  'vietnamese': 'Bệnh do Trypanosoma gambiense',
}, {
  'id': 'B56.1',
  'english': 'Rhodesiense trypanosomiasis',
  'vietnamese': 'Bệnh do Trypanosoma rhodesiense',
}, {
  'id': 'B56.9',
  'english': 'African trypanosomiasis, unspecified',
  'vietnamese': 'Bệnh do trypanosoma châu Phi, không đặc hiệu',
}, {'id': 'B57', 'english': 'Chagas disease', 'vietnamese': 'Bệnh Chagas'}, {
  'id': 'B57.0†',
  'english': 'Acute Chagas disease with heart involvement (I41.2*, I98.1*)',
  'vietnamese': 'Bệnh Chagas cấp ảnh hưởng đến tim (I41.2*, I98.1*)',
}, {
  'id': 'B57.1',
  'english': 'Acute Chagas disease without heart involvement',
  'vietnamese': 'Bệnh Chagas cấp không ảnh hưởng đến tim',
}, {
  'id': 'B57.2',
  'english': 'Chagas disease (chronic) with heart involvement',
  'vietnamese': 'Bệnh Chagas (mãn tính) ảnh hưởng đến tim',
}, {
  'id': 'B57.3',
  'english': 'Chagas disease (chronic) with digestive system involvement',
  'vietnamese': 'Bệnh Chagas (mãn tính) ảnh hưởng đến hệ tiêu hóa',
}, {
  'id': 'B57.4',
  'english': 'Chagas disease (chronic) with nervous system involvement',
  'vietnamese': 'Bệnh Chagas (mãn tính) ảnh hưởng đến hệ thần kinh',
}, {
  'id': 'B57.5',
  'english': 'Chagas disease (chronic) with other organ involvement',
  'vietnamese': 'Bệnh Chagas (mãn tính) ảnh hưởng đến cơ quan khác',
}, {'id': 'B58', 'english': 'Toxoplasmosis', 'vietnamese': 'Bệnh do toxoplasma'}, {
  'id': 'B58.0†',
  'english': 'Toxoplasma oculopathy',
  'vietnamese': 'Bệnh lý mắt do toxoplasma',
}, {
  'id': 'B58.1†',
  'english': 'Toxoplasma hepatitis (K77.0*)',
  'vietnamese': 'Viêm gan do toxoplasma (K77.0*)',
}, {
  'id': 'B58.2†',
  'english': 'Toxoplasma meningoencephalitis (G05.2*)',
  'vietnamese': 'Viêm não màng não do toxoplasma (G05.2*)',
}, {
  'id': 'B58.3†',
  'english': 'Pulmonary toxoplasmosisJ17.3',
  'vietnamese': 'Bệnh do toxoplasma ở phổi (J17.3*)',
}, {
  'id': 'B58.8',
  'english': 'Toxoplasmosis with other organ involvement',
  'vietnamese': 'Bệnh do toxoplasma có ảnh hưởng đến cơ quan khác',
}, {
  'id': 'B58.9',
  'english': 'Toxoplasmosis, unspecified',
  'vietnamese': 'Bệnh do toxoplasma, không đặc hiệu',
}, {
  'id': 'B59†',
  'english': 'Pneumocytosis (J17.3*)',
  'vietnamese': 'Bệnh do pneumocystosis (J17.3*)',
}, {
  'id': 'B60',
  'english': 'Other protozoal diseases, not elsewhere classified',
  'vietnamese': 'Bệnh do ký sinh trùng đơn bào khác, chưa phân loại',
}, {'id': 'B60.0', 'english': 'Babesiosis', 'vietnamese': 'Bệnh do babesia'}, {
  'id': 'B60.1',
  'english': 'Acanthamoebiasis',
  'vietnamese': 'Bệnh do acanthamoeba',
}, {'id': 'B60.2', 'english': 'Naegleriasis', 'vietnamese': 'Bệnh do naegleria'}, {
  'id': 'B60.8',
  'english': 'Other specified protozoal diseases',
  'vietnamese': 'Bệnh do ký sinh trùng đơn bào, xác định khác',
}, {
  'id': 'B64',
  'english': 'Unspecified protozoal disease',
  'vietnamese': 'Bệnh do ký sinh trùng đơn bào không đặc hiệu',
}, {
  'id': 'B65',
  'english': 'Schistosomiasis [bilharziasis]',
  'vietnamese': 'Bệnh sán máng [bilharziasis]',
}, {
  'id': 'B65.0',
  'english': 'Schistosomiasis due to Schistosoma haematobium [urinary schistosomiasis]',
  'vietnamese': 'Bệnh sán máng do Schistosoma haematobium [bệnh sán máng đường tiết niệu]',
}, {
  'id': 'B65.1',
  'english': 'Schistosomiasis due to Schistosoma mansoni [intestinal schistosomiasis]',
  'vietnamese': 'Bệnh sán máng do Schistosoma mansoni [bệnh sán máng đường ruột]',
}, {
  'id': 'B65.2',
  'english': 'Schistosomiasis due to Schistosoma japonicum',
  'vietnamese': 'Bệnh sán máng do Schistosoma japonicum',
}, {'id': 'B65.3', 'english': 'Cercarial dermatitis', 'vietnamese': 'Viêm da do ấu trùng'}, {
  'id': 'B65.8',
  'english': 'Other schistosomiases',
  'vietnamese': 'Bệnh sán máng khác',
}, {
  'id': 'B65.9',
  'english': 'Schistosomiasis, unspecified',
  'vietnamese': 'Bệnh sán máng, không đặc hiệu',
}, {'id': 'B66', 'english': 'Other fluke infections', 'vietnamese': 'Bệnh sán lá gan khác'}, {
  'id': 'B66.0',
  'english': 'Opisthorchiasis',
  'vietnamese': 'Bệnh sán lá gan Opisthorchis',
}, {'id': 'B66.1', 'english': 'Clonorchiasis', 'vietnamese': 'Bệnh sán lá gan nhỏ'}, {
  'id': 'B66.2',
  'english': 'Dicrocoeliasis',
  'vietnamese': 'Bệnh sán Dicrocoelium',
}, {'id': 'B66.3', 'english': 'Fascioliasis', 'vietnamese': 'Bệnh Fasciola'}, {
  'id': 'B66.4',
  'english': 'Paragonimiasis',
  'vietnamese': 'Bệnh sán Paragonimus',
}, {'id': 'B66.5', 'english': 'Fasciolopsiasis', 'vietnamese': 'Bệnh do Fasciolopsis'}, {
  'id': 'B66.8',
  'english': 'Other specified fluke infections',
  'vietnamese': 'Nhiễm sán lá xác định khác',
}, {
  'id': 'B66.9',
  'english': 'Fluke infection, unspecified',
  'vietnamese': 'Bệnh sán lá, không đặc hiệu',
}, {'id': 'B67', 'english': 'Echinococcosis', 'vietnamese': 'Bệnh do Echinococcus'}, {
  'id': 'B67.0',
  'english': 'Echinococcus granulosus infection of liver',
  'vietnamese': 'Nhiễm Echinococcus granulosus ở gan',
}, {
  'id': 'B67.1',
  'english': 'Echinococcus granulosus infection of lung',
  'vietnamese': 'Nhiễm Echinococcus granulosus ở phổi',
}, {
  'id': 'B67.2',
  'english': 'Echinococcus granulosus infection of bone',
  'vietnamese': 'Nhiễm Echinococcus granulosus ở xương',
}, {
  'id': 'B67.3',
  'english': 'Echinococcus granulosus infection, other and multiple sites',
  'vietnamese': 'Nhiễm Echinococcus granulosus, vị trí khác và nhiều vị trí',
}, {
  'id': 'B67.4',
  'english': 'Echinococcus granulosus infection, unspecified',
  'vietnamese': 'Nhiễm Echinococcus granulosus, không đặc hiệu',
}, {
  'id': 'B67.5',
  'english': 'Echinococcus multilocularis infection of liver',
  'vietnamese': 'Nhiễm Echinococcus multilocularis ở gan',
}, {
  'id': 'B67.6',
  'english': 'Echinococcus multilocularis infection, other and multiple sites',
  'vietnamese': 'Nhiễm Echinococcus multilocularis vị trí khác và nhiều vị trí',
}, {
  'id': 'B67.7',
  'english': 'Echinococcus multilocularis infection, unspecified',
  'vietnamese': 'Nhiễm Echinococcus multilocularis không đặc hiệu',
}, {
  'id': 'B67.8',
  'english': 'Echinococcosis, unspecified, of liver',
  'vietnamese': 'Bệnh do sán echinococcus ở gan, không đặc hiệu',
}, {
  'id': 'B67.9',
  'english': 'Echinococcosis, other and unspecified',
  'vietnamese': 'Nhiễm ấu trùng sán echinococcus, khác và không đặc hiệu',
}, {'id': 'B68', 'english': 'Taeniasis', 'vietnamese': 'Bệnh sán dây'}, {
  'id': 'B68.0',
  'english': 'Taenia solium taeniasis',
  'vietnamese': 'Bệnh sán Taenia solium',
}, {
  'id': 'B68.1',
  'english': 'Taenia saginata taeniasis',
  'vietnamese': 'Bệnh sán Taenia saginata',
}, {
  'id': 'B68.9',
  'english': 'Taeniasis, unspecified',
  'vietnamese': 'Bệnh sán Taenia, không đặc hiệu',
}, {'id': 'B69', 'english': 'Cysticercosis', 'vietnamese': 'Bệnh ấu trùng sán lợn'}, {
  'id': 'B69.0',
  'english': 'Cysticercosis of central nervous system',
  'vietnamese': 'Bệnh ấu trùng sán lợn ở hệ thần kinh trung ương',
}, {
  'id': 'B69.1',
  'english': 'Cysticercosis of eye',
  'vietnamese': 'Bệnh ấu trùng sán lợn ở mắt',
}, {
  'id': 'B69.8',
  'english': 'Cysticercosis of other sites',
  'vietnamese': 'Bệnh ấu trùng sán lợn ở vị trí khác',
}, {
  'id': 'B69.9',
  'english': 'Cysticercosis, unspecified',
  'vietnamese': 'Bệnh ấu trùng sán lợn, không đặc hiệu',
}, {
  'id': 'B70',
  'english': 'Diphyllobothriasis and sparganosis',
  'vietnamese': 'Bệnh sán diphyllobothrium và sparganum',
}, {'id': 'B70.0', 'english': 'Diphyllobothriasis', 'vietnamese': 'Bệnh sán diphyllobothrium'}, {
  'id': 'B70.1',
  'english': 'Sparganosis',
  'vietnamese': 'Bệnh sán spargamum',
}, {'id': 'B71', 'english': 'Other cestode infections', 'vietnamese': 'Nhiễm sán dây khác'}, {
  'id': 'B71.0',
  'english': 'Hymenolepiasis',
  'vietnamese': 'Bệnh sán dây nhỏ hymenolepis',
}, {'id': 'B71.1', 'english': 'Dipylidiasis', 'vietnamese': 'Bệnh sán dipylium'}, {
  'id': 'B71.8',
  'english': 'Other specified cestode infections',
  'vietnamese': 'Nhiễm sán dây xác định khác',
}, {
  'id': 'B71.9',
  'english': 'Cestode infection, unspecified',
  'vietnamese': 'Nhiễm sán dây không đặc hiệu',
}, {'id': 'B72', 'english': 'Dracunculiasis', 'vietnamese': 'Bệnh giun Dracunculus'}, {
  'id': 'B73',
  'english': 'Onchocerciasis',
  'vietnamese': 'Bệnh giun chỉ',
}, {'id': 'B74', 'english': 'Filariasis', 'vietnamese': 'Bệnh giun chỉ'}, {
  'id': 'B74.0',
  'english': 'Filariasis due to Wuchereria bancrofti',
  'vietnamese': 'Bệnh giun chỉ do Wuchereria bancrofti',
}, {
  'id': 'B74.1',
  'english': 'Filariasis due to Brugia malayi',
  'vietnamese': 'Bệnh giun chỉ do Brugia malayi',
}, {
  'id': 'B74.2',
  'english': 'Filariasis due to Brugia timori',
  'vietnamese': 'Bệnh giun chỉ do Brugia malayi',
}, {'id': 'B74.3', 'english': 'Loiasis', 'vietnamese': 'Bệnh giun chỉ Loa loa'}, {
  'id': 'B74.4',
  'english': 'Mansonelliasis',
  'vietnamese': 'Nhiễm giun chỉ Mansonella',
}, {'id': 'B74.8', 'english': 'Other filariases', 'vietnamese': 'Bệnh giun chỉ khác'}, {
  'id': 'B74.9',
  'english': 'Filariasis, unspecified',
  'vietnamese': 'Bệnh giun chỉ, không đặc hiệu',
}, {'id': 'B75', 'english': 'Trichinellosis', 'vietnamese': 'Bệnh giun xoắn Trichinella'}, {
  'id': 'B76',
  'english': 'Hookworm diseases',
  'vietnamese': 'Bệnh giun móc',
}, {'id': 'B76.0', 'english': 'Ancylostomiasis', 'vietnamese': 'Bệnh giun ancylostoma'}, {
  'id': 'B76.1',
  'english': 'Necatoriasis',
  'vietnamese': 'Bệnh giun necator',
}, {'id': 'B76.8', 'english': 'Other hookworm diseases', 'vietnamese': 'Bệnh giun móc khác'}, {
  'id': 'B76.9',
  'english': 'Hookworm disease, unspecified',
  'vietnamese': 'Bệnh giun móc, không đặc hiệu',
}, {'id': 'B77', 'english': 'Ascariasis', 'vietnamese': 'Bệnh giun đũa'}, {
  'id': 'B77.0',
  'english': 'Ascariasis with intestinal complications',
  'vietnamese': 'Bệnh giun đũa với biến chứng đường ruột',
}, {
  'id': 'B77.8',
  'english': 'Ascariasis with other complications',
  'vietnamese': 'Bệnh giun đũa với biến chứng khác',
}, {
  'id': 'B77.9',
  'english': 'Ascariasis, unspecified',
  'vietnamese': 'Bệnh giun đũa, không đặc hiệu',
}, {'id': 'B78', 'english': 'Strongyloidiasis', 'vietnamese': 'Bệnh giun lươn'}, {
  'id': 'B78.0',
  'english': 'Intestinal strongyloidiasis',
  'vietnamese': 'Bệnh giun lươn đường ruột',
}, {
  'id': 'B78.1',
  'english': 'Cutaneous strongyloidiasis',
  'vietnamese': 'Bệnh giun lươn ở da',
}, {
  'id': 'B78.7',
  'english': 'Disseminated strongyloidiasis',
  'vietnamese': 'Bệnh giun lan tỏa',
}, {
  'id': 'B78.9',
  'english': 'Strongyloidiasis, unspecified',
  'vietnamese': 'Bệnh giun lươn, không đặc hiệu',
}, {'id': 'B79', 'english': 'Trichuriasis', 'vietnamese': 'Bệnh giun tóc'}, {
  'id': 'B80',
  'english': 'Enterobiasis',
  'vietnamese': 'Bệnh giun kim',
}, {
  'id': 'B81',
  'english': 'Other intestinal helminthiases, not elsewhere classified',
  'vietnamese': 'Nhiễm giun đường ruột khác, chưa phân loại',
}, {'id': 'B81.0', 'english': 'Anisakiasis', 'vietnamese': 'Bệnh giun anisakis'}, {
  'id': 'B81.1',
  'english': 'Intestinal capillariasis',
  'vietnamese': 'Bệnh giun capillaria đường ruột',
}, {
  'id': 'B81.2',
  'english': 'Trichostrongyliasis',
  'vietnamese': 'Bệnh giun trichostrongylus',
}, {
  'id': 'B81.3',
  'english': 'Intestinal angiostrongyliasis',
  'vietnamese': 'Bệnh giun angiostrongylus đường ruột',
}, {
  'id': 'B81.4',
  'english': 'Mixed intestinal helminthiases',
  'vietnamese': 'Bệnh angiostrongylus do Parastrongylus costaricensis',
}, {
  'id': 'B81.8',
  'english': 'Other specified intestinal helminthiases',
  'vietnamese': 'Bệnh giun sán đường ruột xác định khác',
}, {
  'id': 'B82',
  'english': 'Unspecified intestinal parasitism',
  'vietnamese': 'Bệnh ký sinh trùng đường ruột không đặc hiệu',
}, {
  'id': 'B82.0',
  'english': 'Intestinal helminthiasis, unspecified',
  'vietnamese': 'Bệnh giun sán đường ruột không đặc hiệu',
}, {
  'id': 'B82.9',
  'english': 'Intestinal parasitism, unspecified',
  'vietnamese': 'Nhiễm ký sinh trùng đường ruột không đặc hiệu',
}, {'id': 'B83', 'english': 'Other helminthiases', 'vietnamese': 'Bệnh giun sán khác'}, {
  'id': 'B83.0',
  'english': 'Visceral larva migrans',
  'vietnamese': 'Ấu trùng di chuyển nội tạng',
}, {'id': 'B83.1', 'english': 'Gnathostomiasis', 'vietnamese': 'Bệnh giun gnathostoma'}, {
  'id': 'B83.2',
  'english': 'Angiostrongyliasis due to Parastrongylus cantonensis',
  'vietnamese': 'Bệnh giun angiostrongylus do Parastrongylus cantonensis',
}, {'id': 'B83.3', 'english': 'Syngamiasis', 'vietnamese': 'Nhiễm giun syngamia'}, {
  'id': 'B83.4',
  'english': 'Internal hirudiniasis',
  'vietnamese': 'Bệnh đỉa nội tạng',
}, {
  'id': 'B83.8',
  'english': 'Other specified helminthiases',
  'vietnamese': 'Bệnh giun sán đặc hiệu khác',
}, {
  'id': 'B83.9',
  'english': 'Helminthiasis, unspecified',
  'vietnamese': 'Bệnh giun sán, không đặc hiệu',
}, {'id': 'B85', 'english': 'Pediculosis and phthiriasis', 'vietnamese': 'Bệnh chấy rận'}, {
  'id': 'B85.0',
  'english': 'Pediculosis due to Pediculus humanus capitis',
  'vietnamese': 'Bệnh chấy rận do Pediculus humanus capitis',
}, {
  'id': 'B85.1',
  'english': 'Pediculosis due to Pediculus humanus corporis',
  'vietnamese': 'Bệnh chấy rận do Pediculus humanus corporis',
}, {
  'id': 'B85.2',
  'english': 'Pediculosis, unspecified',
  'vietnamese': 'Bệnh chấy rận, không đặc hiệu',
}, {'id': 'B85.3', 'english': 'Phthiriasis', 'vietnamese': 'Bệnh chấy rận'}, {
  'id': 'B85.4',
  'english': 'Mixed pediculosis and phthiriasis',
  'vietnamese': 'Bệnh chấy rận phối hợp',
}, {'id': 'B86', 'english': 'Scabies', 'vietnamese': 'Bệnh ngứa do ghẻ'}, {
  'id': 'B87',
  'english': 'Myiasis',
  'vietnamese': 'Bệnh giòi',
}, {'id': 'B87.0', 'english': 'Cutaneous myiasis', 'vietnamese': 'Bệnh giòi ở da'}, {
  'id': 'B87.1',
  'english': 'Wound myiasis',
  'vietnamese': 'Bệnh giòi trên vết thương',
}, {'id': 'B87.2', 'english': 'Ocular myiasis', 'vietnamese': 'Bệnh giòi ở mắt'}, {
  'id': 'B87.3',
  'english': 'Nasopharyngeal myiasis',
  'vietnamese': 'Bệnh giòi ở mũi họng',
}, {'id': 'B87.4', 'english': 'Aural myiasis', 'vietnamese': 'Bệnh giòi ở tai'}, {
  'id': 'B87.8',
  'english': 'Myiasis of other sites',
  'vietnamese': 'Bệnh giòi ở vị trí khác',
}, {'id': 'B87.9', 'english': 'Myiasis, unspecified', 'vietnamese': 'Bệnh giòi không đặc hiệu'}, {
  'id': 'B88',
  'english': 'Other infestations',
  'vietnamese': 'Nhiễm ký sinh trùng khác',
}, {'id': 'B88.0', 'english': 'Other acariasis', 'vietnamese': 'Các bệnh do ve khác'}, {
  'id': 'B88.1',
  'english': 'Tungiasis [sandflea infestation]',
  'vietnamese': 'Nhiễm do bọ tunga [nhiễm do bọ chét cái]',
}, {
  'id': 'B88.2',
  'english': 'Other arthropod infestations',
  'vietnamese': 'Nhiễm ký sinh trùng do tiết túc khác',
}, {
  'id': 'B88.3',
  'english': 'External hirudiniasis',
  'vietnamese': 'Bệnh do đỉa ngoại ký sinh',
}, {
  'id': 'B88.8',
  'english': 'Other specified infestations',
  'vietnamese': 'Nhiễm ký sinh trùng xác định khác',
}, {
  'id': 'B88.9',
  'english': 'Infestation, unspecified',
  'vietnamese': 'Nhiễm ký sinh trùng, không đặc hiệu',
}, {
  'id': 'B89',
  'english': 'Unspecified parasitic disease',
  'vietnamese': 'Bệnh do ký sinh trùng không đặc hiệu',
}, {'id': 'B90', 'english': 'Sequelae of tuberculosis', 'vietnamese': 'Di chứng do lao'}, {
  'id': 'B90.0',
  'english': 'Sequelae of central nervous system tuberculosis',
  'vietnamese': 'Di chứng do lao hệ thần kinh trung ương',
}, {
  'id': 'B90.1',
  'english': 'Sequelae of genitourinary tuberculosis',
  'vietnamese': 'Di chứng do lao tiết niệu - sinh dục',
}, {
  'id': 'B90.2',
  'english': 'Sequelae of tuberculosis of bones and joints',
  'vietnamese': 'Di chứng do lao xương và khớp',
}, {
  'id': 'B90.8',
  'english': 'Sequelae of tuberculosis of other organs',
  'vietnamese': 'Di chứng do lao cơ quan khác',
}, {
  'id': 'B90.9',
  'english': 'Sequelae of respiratory and unspecified tuberculosis',
  'vietnamese': 'Di chứng do lao hô hấp và bệnh lao không đặc hiệu',
}, {'id': 'B91', 'english': 'Sequelae of poliomyelitis', 'vietnamese': 'Di chứng do bại liệt'}, {
  'id': 'B92',
  'english': 'Sequelea of leprosy',
  'vietnamese': 'Di chứng do phong',
}, {
  'id': 'B94',
  'english': 'Sequelae of other and unspecified infectious and parasitic diseases',
  'vietnamese': 'Di chứng của bệnh nhiễm trùng và ký sinh trùng khác, không đặc hiệu',
}, {'id': 'B94.0', 'english': 'Sequelae of trachoma', 'vietnamese': 'Di chứng bệnh mắt hột'}, {
  'id': 'B94.1',
  'english': 'Sequelae of viral encephalitis',
  'vietnamese': 'Di chứng viêm não do virus',
}, {
  'id': 'B94.2',
  'english': 'Sequelae of viral hepatitis',
  'vietnamese': 'Di chứng viêm gan virus',
}, {
  'id': 'B94.8',
  'english': 'Sequelae of other specified infectious and parasitic diseases',
  'vietnamese': 'Di chứng của bệnh nhiễm trùng và ký sinh trùng xác định khác',
}, {
  'id': 'B94.9',
  'english': 'Sequelae of unspecified infectious or parasitic disease',
  'vietnamese': 'Di chứng của bệnh nhiễm trùng và ký sinh trùng không đặc hiệu',
}, {
  'id': 'B95',
  'english': 'Streptococcus and staphylococcus as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu và tụ cầu là nguyên nhân gây bệnh, được phân loại ở các chương khác',
}, {
  'id': 'B95.0',
  'english': 'Streptococcus, group A, as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu, nhóm A, là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.1',
  'english': 'Streptococcus, group B, as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu, nhóm B, là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.2',
  'english': 'Streptococcus, group D, as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu, nhóm D, là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.3',
  'english': 'Streptococcus pneumoniae as the cause of diseases classified to other chapters',
  'vietnamese': 'Phế cầu là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.4',
  'english': 'Other streptococcus as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu khác là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.5',
  'english': 'Unspecified streptococcus as the cause of diseases classified to other chapters',
  'vietnamese': 'Liên cầu không xác định là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.6',
  'english': 'Staphylococcus aureus as the cause of diseases classified to other chapters',
  'vietnamese': 'Tụ cầu vàng là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.7',
  'english': 'Other staphylococcus as the cause of diseases classified to other chapters',
  'vietnamese': 'Tụ cầu khác là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B95.8',
  'english': 'Unspecified staphylococcus as the cause of diseases classified to other chapters',
  'vietnamese': 'Tụ cầu không xác định là nguyên nhân gây các bệnh phân loại ở chương khác',
}, {
  'id': 'B96',
  'english': 'Other bacterial agents as the cause of diseases classified to other chapters',
  'vietnamese': 'Tác nhân vi khuẩn xác định khác như là nguyên nhân gây bệnh, phân loại ở các chương khác',
}, {
  'id': 'B96.0',
  'english': 'Mycoplasma pneumoniae [M. pneumoniae] as the cause of diseases classified to other chapters',
  'vietnamese': 'Mycoplasma pneumoniae [M. pneumoniae] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.1',
  'english': 'Klebsiella pneumoniae [K. pneumoniae] as the cause of diseases classified to other chapters',
  'vietnamese': 'Klebsiella pneumoniae [K. pneumoniae] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.2',
  'english': 'Escherichia coli [E. coli] as the cause of diseases classified to other chapters',
  'vietnamese': 'Escherichia coli [E. coli] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.3',
  'english': 'Haemophilus influenzae [H. influenzae] as the cause of diseases classified to other chapters',
  'vietnamese': 'Haemophilus influenzae [H. influenzae] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.4',
  'english': 'Proteus (mirabilis)(morganii) as the cause of diseases classified to other chapters',
  'vietnamese': 'Proteus (mirabilis)(morganii) gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.5',
  'english': 'Pseudomonas (aeruginosa)(mallei)(pseudomallei) as the cause of diseases classified to other chapters',
  'vietnamese': 'Pseudomonas (aeruginosa) gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.6',
  'english': 'Bacillus fragilis [B. fragilis] as the cause of diseases classified to other chapters',
  'vietnamese': 'Bacillus fragilis [B. fragilis] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.7',
  'english': 'Clostridium perfringens [C. perfringens] as the cause of diseases classified to other chapters',
  'vietnamese': 'Clostridium perfringens [C. Perfringens] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B96.8',
  'english': 'Other specified bacterial agents as the cause of diseases classified to other chapters',
  'vietnamese': 'Vi khuẩn xác định khác gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97',
  'english': 'Viral agents as the cause of diseases classified to other chapters',
  'vietnamese': 'Tác nhân virus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.0',
  'english': 'Adenovirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Adenovirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.1',
  'english': 'Enterovirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Enterovirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.2',
  'english': 'Coronavirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Coronavirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.3',
  'english': 'Retrovirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Retrovirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.4',
  'english': 'Respiratory syncytial virus as the cause of diseases classified to other chapters',
  'vietnamese': 'Virus hợp bào đường hô hấp gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.5',
  'english': 'Reovirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Reovirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.6',
  'english': 'Parvovirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Parvovirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.7',
  'english': 'Papillomavirus as the cause of diseases classified to other chapters',
  'vietnamese': 'Papillomavirus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B97.8',
  'english': 'Other viral agents as the cause of diseases classified to other chapters',
  'vietnamese': 'Các virus gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B98',
  'english': 'Other specified infectious agents as the cause of diseases classified to other chapters',
  'vietnamese': 'Tác nhân nhiễm trùng xác định khác gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B98.0',
  'english': 'Helicobacter pylori [H.pylori] as the cause of diseases classified to other chapters',
  'vietnamese': 'Helicobacter pylori [H.pylori] gây các bệnh đã được phân loại ở chương khác',
}, {
  'id': 'B99',
  'english': 'Other and unspecified infetious disease',
  'vietnamese': 'Các bệnh nhiễm trùng khác và không đặc hiệu',
}, {'id': 'C00', 'english': 'Malignant neoplasm of lip', 'vietnamese': 'U ác của môi'}, {
  'id': 'C00.0',
  'english': 'Malignant neoplasm: External upper lip',
  'vietnamese': 'U ác của môi phần ngoài môi trên',
}, {
  'id': 'C00.1',
  'english': 'Malignant neoplasm: External lower lip',
  'vietnamese': 'Phần ngoài môi dưới',
}, {
  'id': 'C00.2',
  'english': 'Malignant neoplasm: External lip, unspecified',
  'vietnamese': 'U ác của phần ngoài môi không đặc hiệu',
}, {
  'id': 'C00.3',
  'english': 'Malignant neoplasm: Upper lip, inner aspect',
  'vietnamese': 'U ác của môi trên, mặt trong',
}, {
  'id': 'C00.4',
  'english': 'Malignant neoplasm: Lower lip, inner aspect',
  'vietnamese': 'U ác của môi dưới, mặt trong',
}, {
  'id': 'C00.5',
  'english': 'Malignant neoplasm: Lip, unspecified, inner aspect',
  'vietnamese': 'U ác của môi, mặt trong không đặc hiệu',
}, {
  'id': 'C00.6',
  'english': 'Malignant neoplasm: Commissure of lip',
  'vietnamese': 'U ác của mép môi',
}, {
  'id': 'C00.8',
  'english': 'Malignant neoplasm: Overlapping lesion of lip',
  'vietnamese': 'U ác với tổn thương chồng lấn của môi',
}, {
  'id': 'C00.9',
  'english': 'Malignant neoplasm: Lip, unspecified',
  'vietnamese': 'U ác của môi, không đặc hiệu',
}, {
  'id': 'C01',
  'english': 'Malignant neoplasm of base of tongue',
  'vietnamese': 'U ác của đáy lưỡi',
}, {
  'id': 'C02',
  'english': 'Malignant neoplasm of other and unspecified parts of tongue',
  'vietnamese': 'U ác của phần khác và không đặc hiệu của lưỡi',
}, {
  'id': 'C02.0',
  'english': 'Malignant neoplasm: Dorsal surface of tongue',
  'vietnamese': 'U ác mặt lưng của lưỡi',
}, {
  'id': 'C02.1',
  'english': 'Malignant neoplasm: Border of tongue',
  'vietnamese': 'U ác bờ của lưỡi',
}, {
  'id': 'C02.2',
  'english': 'Malignant neoplasm: Ventral surface of tongue',
  'vietnamese': 'U ác mặt bụng của lưỡi',
}, {
  'id': 'C02.3',
  'english': 'Malignant neoplasm: Anterior two-thirds of tongue, part unspecified',
  'vietnamese': 'U ác hai phần ba trước của lưỡi, phần không xác định',
}, {
  'id': 'C02.4',
  'english': 'Malignant neoplasm: Lingual tonsil',
  'vietnamese': 'U ác của Amiđan đáy lưỡi',
}, {
  'id': 'C02.8',
  'english': 'Malignant neoplasm: Overlapping lesion of tongue',
  'vietnamese': 'U ác với tổn thương chồng lấn của lưỡi',
}, {
  'id': 'C02.9',
  'english': 'Malignant neoplasm: Tongue, unspecified',
  'vietnamese': 'U ác của lưỡi không đặc hiệu',
}, {
  'id': 'C03',
  'english': 'Malignant neoplasm of gum',
  'vietnamese': 'U ác của nướu (lợi) răng',
}, {
  'id': 'C03.0',
  'english': 'Malignant neoplasm: Upper gum',
  'vietnamese': 'U ác của nướu hàm trên',
}, {
  'id': 'C03.1',
  'english': 'Malignant neoplasm: Lower gum',
  'vietnamese': 'U ác của nướu hàm dưới',
}, {
  'id': 'C03.9',
  'english': 'Malignant neoplasm: Gum, unspecified',
  'vietnamese': 'U ác của nướu, không đặc hiệu',
}, {
  'id': 'C04',
  'english': 'Malignant neoplasm of floor of mouth',
  'vietnamese': 'U ác của sàn miệng',
}, {
  'id': 'C04.0',
  'english': 'Malignant neoplasm: Anterior floor of mouth',
  'vietnamese': 'U ác của sàn trước miệng',
}, {
  'id': 'C04.1',
  'english': 'Malignant neoplasm: Lateral floor of mouth',
  'vietnamese': 'U ác của sàn miệng bên',
}, {
  'id': 'C04.8',
  'english': 'Malignant neoplasm: Overlapping lesion of floor of mouth',
  'vietnamese': 'U ác với tổn thương chồng lấn của sàn miệng',
}, {
  'id': 'C04.9',
  'english': 'Malignant neoplasm: Floor of mouth, unspecified',
  'vietnamese': 'U ác của sàn miệng không đặc hiệu',
}, {'id': 'C05', 'english': 'Malignant neoplasm of palate', 'vietnamese': 'U ác của khẩu cái'}, {
  'id': 'C05.0',
  'english': 'Malignant neoplasm: Hard palate',
  'vietnamese': 'U ác của khẩu cái cứng',
}, {
  'id': 'C05.1',
  'english': 'Malignant neoplasm: Soft palate',
  'vietnamese': 'U ác của khẩu cái mềm',
}, {'id': 'C05.2', 'english': 'Malignant neoplasm: Uvula', 'vietnamese': 'U ác của lưỡi gà'}, {
  'id': 'C05.8',
  'english': 'Malignant neoplasm: Overlapping lesion of palate',
  'vietnamese': 'U ác với tổn thương chồng lấn của khẩu cái',
}, {
  'id': 'C05.9',
  'english': 'Malignant neoplasm: Palate, unspecified',
  'vietnamese': 'U ác của khẩu cái, không đặc hiệu',
}, {
  'id': 'C06',
  'english': 'Malignant neoplasm of other and unspecified parts of mouth',
  'vietnamese': 'U ác của phần khác và không xác định của miệng',
}, {
  'id': 'C06.0',
  'english': 'Malignant neoplasm: Cheek mucosa',
  'vietnamese': 'U ác của niêm mạc má',
}, {
  'id': 'C06.1',
  'english': 'Malignant neoplasm: Vestibule of mouth',
  'vietnamese': 'U ác của tiền đình của miệng',
}, {
  'id': 'C06.2',
  'english': 'Malignant neoplasm: Retromolar area',
  'vietnamese': 'U ác của vùng sau răng hàm',
}, {
  'id': 'C06.8',
  'english': 'Malignant neoplasm: Overlapping lesion of other and unspecified parts of mouth',
  'vietnamese': 'U ác với tổn thương chồng lấn của phần khác và không xác định của miệng',
}, {
  'id': 'C06.9',
  'english': 'Malignant neoplasm: Mouth, unspecified',
  'vietnamese': 'U ác của miệng, không đặc hiệu',
}, {
  'id': 'C07',
  'english': 'Malignant neoplasm of parotid gland',
  'vietnamese': 'U ác tuyến mang tai',
}, {
  'id': 'C08',
  'english': 'Malignant neoplasm of other and unspecified major salivary glands',
  'vietnamese': 'U ác của các tuyến nước bọt chính khác và không xác định',
}, {
  'id': 'C08.0',
  'english': 'Malignant neoplasm: Submandibular gland',
  'vietnamese': 'U ác của tuyến nước bọt dưới hàm dưới',
}, {
  'id': 'C08.1',
  'english': 'Malignant neoplasm: Sublingual gland',
  'vietnamese': 'U ác của tuyến nước bọt dưới lưỡi',
}, {
  'id': 'C08.8',
  'english': 'Malignant neoplasm: Overlapping lesion of major salivary glands',
  'vietnamese': 'U ác với tổn thương chồng lấn của tuyến nước bọt chính',
}, {
  'id': 'C08.9',
  'english': 'Malignant neoplasm: Major salivary gland, unspecified',
  'vietnamese': 'U ác của tuyến nước bọt chính, không đặc hiệu',
}, {'id': 'C09', 'english': 'Malignant neoplasm of tonsil', 'vietnamese': 'U ác của amiđan'}, {
  'id': 'C09.0',
  'english': 'Malignant neoplasm: Tonsillar fossa',
  'vietnamese': 'U ác của hố amiđan',
}, {
  'id': 'C09.1',
  'english': 'Malignant neoplasm: Tonsillar pillar (anterior)(posterior)',
  'vietnamese': 'U ác của trụ amiđan (trước) (sau)',
}, {
  'id': 'C09.8',
  'english': 'Malignant neoplasm: Overlapping lesion of tonsil',
  'vietnamese': 'U ác với tổn thương chồng lấn của amiđan',
}, {
  'id': 'C09.9',
  'english': 'Malignant neoplasm: Tonsil, unspecified',
  'vietnamese': 'U ác của amiđan không đặc hiệu',
}, {
  'id': 'C10',
  'english': 'Malignant neoplasm of oropharynx',
  'vietnamese': 'U ác của hầu miệng',
}, {
  'id': 'C10.0',
  'english': 'Malignant neoplasm: Vallecula',
  'vietnamese': 'U ác của thung lũng (nắp thanh quản)',
}, {
  'id': 'C10.1',
  'english': 'Malignant neoplasm: Anterior surface of epiglottis',
  'vietnamese': 'U ác của mặt trước của nắp thanh môn',
}, {
  'id': 'C10.2',
  'english': 'Malignant neoplasm: Lateral wall of oropharynx',
  'vietnamese': 'U ác của thành bên hầu-miệng',
}, {
  'id': 'C10.3',
  'english': 'Malignant neoplasm: Posterior wall of oropharynx',
  'vietnamese': 'U ác của thành sau hầu-miệng',
}, {
  'id': 'C10.4',
  'english': 'Malignant neoplasm: Branchial cleft',
  'vietnamese': 'Khe cạch cổ',
}, {
  'id': 'C10.8',
  'english': 'Malignant neoplasm: Overlapping lesion of oropharynx',
  'vietnamese': 'U ác với tổn thương chồng lấn của hầu miệng',
}, {
  'id': 'C10.9',
  'english': 'Malignant neoplasm: Oropharynx, unspecified',
  'vietnamese': 'U ác của hầu-miệng, không đặc hiệu',
}, {
  'id': 'C11',
  'english': 'Malignant neoplasm of nasopharynx',
  'vietnamese': 'U ác của hầu-mũi',
}, {
  'id': 'C11.0',
  'english': 'Malignant neoplasm: Superior wall of nasopharynx',
  'vietnamese': 'U ác của vách trên của hầu-mũi',
}, {
  'id': 'C11.1',
  'english': 'Malignant neoplasm: Posterior wall of nasopharynx',
  'vietnamese': 'U ác của vách sau của hầu-mũi',
}, {
  'id': 'C11.2',
  'english': 'Malignant neoplasm: Lateral wall of nasopharynx',
  'vietnamese': 'U ác của vách bên của hầu-mũi',
}, {
  'id': 'C11.3',
  'english': 'Malignant neoplasm: Anterior wall of nasopharynx',
  'vietnamese': 'U ác của vách trước của hầu-mũi',
}, {
  'id': 'C11.8',
  'english': 'Malignant neoplasm: Overlapping lesion of nasopharynx',
  'vietnamese': 'U ác với tổn thương chồng lấn của hầu-mũi',
}, {
  'id': 'C11.9',
  'english': 'Malignant neoplasm: Nasopharynx, unspecified',
  'vietnamese': 'U ác của mũi hầu không đặc hiệu',
}, {
  'id': 'C12',
  'english': 'Malignant neoplasm of piriform sinus',
  'vietnamese': 'U ác của xoang lê',
}, {
  'id': 'C13',
  'english': 'Malignant neoplasm of hypopharynx',
  'vietnamese': 'U ác của hạ hầu',
}, {
  'id': 'C13.0',
  'english': 'Malignant neoplasm: Postcricoid region',
  'vietnamese': 'U ác của vùng sau sụn nhẫn',
}, {
  'id': 'C13.1',
  'english': 'Malignant neoplasm: Aryepiglottic fold, hypopharyngeal aspect',
  'vietnamese': 'U ác của nếp phễu nắp, mặt hạ hầu',
}, {
  'id': 'C13.2',
  'english': 'Malignant neoplasm: Posterior wall of hypopharynx',
  'vietnamese': 'U ác của vách sau của hạ hầu',
}, {
  'id': 'C13.8',
  'english': 'Malignant neoplasm: Overlapping lesion of hypopharynx',
  'vietnamese': 'U ác với tổn thương chồng lấn của hạ hầu',
}, {
  'id': 'C13.9',
  'english': 'Malignant neoplasm: Hypopharynx, unspecified',
  'vietnamese': 'U ác của hạ hầu không đặc hiệu',
}, {
  'id': 'C14',
  'english': 'Malignant neoplasm of other and ill-defined sites in the lip, oral cavity and pharynx',
  'vietnamese': 'U ác vị trí khác và không rõ ràng của môi, khoang miệng và hầu',
}, {
  'id': 'C14.0',
  'english': 'Malignant neoplasm: Pharynx, unspecified',
  'vietnamese': 'U ác của hầu, không đặc hiệu',
}, {
  'id': 'C14.2',
  'english': 'Malignant neoplasm: Waldeyer s ring',
  'vietnamese': 'U ác của hầu thanh quản',
}, {
  'id': 'C14.8',
  'english': 'Malignant neoplasm: Overlapping lesion of lip, oral cavity and pharynx',
  'vietnamese': 'U ác với tổn thương chồng lấn của môi, khoang miệng và hầu họng',
}, {'id': 'C15', 'english': 'Malignant neoplasm of oesophagus', 'vietnamese': 'U ác thực quản'}, {
  'id': 'C15.0',
  'english': 'Malignant neoplasm: Cervical part of oesophagus',
  'vietnamese': 'U ác của thực quản phần cổ',
}, {
  'id': 'C15.1',
  'english': 'Malignant neoplasm: Thoracic part of oesophagus',
  'vietnamese': 'U ác của thực quản phần ngực',
}, {
  'id': 'C15.2',
  'english': 'Malignant neoplasm: Abdominal part of oesophagus',
  'vietnamese': 'U ác của thực quản phần bụng',
}, {
  'id': 'C15.3',
  'english': 'Malignant neoplasm: Upper third of oesophagus',
  'vietnamese': 'U ác của phần ba trên thực quản',
}, {
  'id': 'C15.4',
  'english': 'Malignant neoplasm: Middle third of oesophagus',
  'vietnamese': 'U ác của phần ba giữa thực quản',
}, {
  'id': 'C15.5',
  'english': 'Malignant neoplasm: Lower third of oesophagus',
  'vietnamese': 'U ác của phần ba dưới thực quản',
}, {
  'id': 'C15.8',
  'english': 'Malignant neoplasm: Overlapping lesion of oesophagus',
  'vietnamese': 'U ác với tổn thương chồng lấn của thực quản',
}, {
  'id': 'C15.9',
  'english': 'Malignant neoplasm: Oesophagus, unspecified',
  'vietnamese': 'U ác của thực quản, không đặc hiệu',
}, {'id': 'C16', 'english': 'Malignant neoplasm of stomach', 'vietnamese': 'U ác của dạ dày'}, {
  'id': 'C16.0',
  'english': 'Malignant neoplasm: Cardia',
  'vietnamese': 'U ác của tâm vị',
}, {
  'id': 'C16.1',
  'english': 'Malignant neoplasm: Fundus of stomach',
  'vietnamese': 'U ác của đáy vị',
}, {
  'id': 'C16.2',
  'english': 'Malignant neoplasm: Body of stomach',
  'vietnamese': 'U ác của thân vị',
}, {
  'id': 'C16.3',
  'english': 'Malignant neoplasm: Pyloric antrum',
  'vietnamese': 'U ác của hang môn vị',
}, {'id': 'C16.4', 'english': 'Malignant neoplasm: Pylorus', 'vietnamese': 'U ác của môn vị'}, {
  'id': 'C16.5',
  'english': 'Malignant neoplasm: Lesser curvature of stomach, unspecified',
  'vietnamese': 'U ác của bờ cong nhỏ dạ dày, không đặc hiệu',
}, {
  'id': 'C16.6',
  'english': 'Malignant neoplasm: Greater curvature of stomach, unspecified',
  'vietnamese': 'U ác của bờ cong lớn dạ dày, không đặc hiệu',
}, {
  'id': 'C16.8',
  'english': 'Malignant neoplasm: Overlapping lesion of stomach',
  'vietnamese': 'U ác với tổn thương chồng lấn của dạ dày',
}, {
  'id': 'C16.9',
  'english': 'Malignant neoplasm: Stomach, unspecified',
  'vietnamese': 'U ác của dạ dày không đặc hiệu',
}, {
  'id': 'C17',
  'english': 'Malignant neoplasm of small intestine',
  'vietnamese': 'U ác ruột non',
}, {
  'id': 'C17.0',
  'english': 'Malignant neoplasm: Duodenum',
  'vietnamese': 'U ác của tá tràng',
}, {
  'id': 'C17.1',
  'english': 'Malignant neoplasm: Jejunum',
  'vietnamese': 'U ác của hỗng tràng',
}, {
  'id': 'C17.2',
  'english': 'Malignant neoplasm: Ileum',
  'vietnamese': 'U ác của hồi tràng',
}, {
  'id': 'C17.3',
  'english': 'Malignant neoplasm: Meckel s diverticulum',
  'vietnamese': 'U ác của túi thừa Meckel',
}, {
  'id': 'C17.8',
  'english': 'Malignant neoplasm: Overlapping lesion of small intestine',
  'vietnamese': 'U ác với tổn thương chồng lấn của ruột non',
}, {
  'id': 'C17.9',
  'english': 'Malignant neoplasm: Small intestine, unspecified',
  'vietnamese': 'U ác của ruột non, không đặc hiệu',
}, {'id': 'C18', 'english': 'Malignant neoplasm of colon', 'vietnamese': 'U ác của đại tràng'}, {
  'id': 'C18.0',
  'english': 'Malignant neoplasm: Caecum',
  'vietnamese': 'U ác của manh tràng',
}, {
  'id': 'C18.1',
  'english': 'Malignant neoplasm: Appendix',
  'vietnamese': 'U ác của ruột thừa',
}, {
  'id': 'C18.2',
  'english': 'Malignant neoplasm: Ascending colon',
  'vietnamese': 'U ác của đại tràng lên',
}, {
  'id': 'C18.3',
  'english': 'Malignant neoplasm: Hepatic flexure',
  'vietnamese': 'U ác của đại tràng góc gan',
}, {
  'id': 'C18.4',
  'english': 'Malignant neoplasm: Transverse colon',
  'vietnamese': 'U ác của đại tràng ngang',
}, {
  'id': 'C18.5',
  'english': 'Malignant neoplasm: Splenic flexure',
  'vietnamese': 'U ác của đại tràng góc lách',
}, {
  'id': 'C18.6',
  'english': 'Malignant neoplasm: Descending colon',
  'vietnamese': 'U ác của đại tràng xuống',
}, {
  'id': 'C18.7',
  'english': 'Malignant neoplasm: Sigmoid colon',
  'vietnamese': 'U ác của đại tràng xích ma',
}, {
  'id': 'C18.8',
  'english': 'Malignant neoplasm: Overlapping lesion of colon',
  'vietnamese': 'U ác với tổn thương chồng lấn của đại tràng',
}, {
  'id': 'C18.9',
  'english': 'Malignant neoplasm: Colon, unspecified',
  'vietnamese': 'U ác của đại tràng không đặc hiệu',
}, {
  'id': 'C19',
  'english': 'Malignant neoplasm of rectosigmoid junction',
  'vietnamese': 'U ác của nơi nối trực tràng - xích ma',
}, {
  'id': 'C20',
  'english': 'Malignant neoplasm of rectum',
  'vietnamese': 'U ác của trực tràng',
}, {
  'id': 'C21',
  'english': 'Malignant neoplasm of anus and anal canal',
  'vietnamese': 'U ác của hậu môn và ống hậu môn',
}, {
  'id': 'C21.0',
  'english': 'Malignant neoplasm: Anus, unspecified',
  'vietnamese': 'U ác của hậu môn, không đặc hiệu',
}, {
  'id': 'C21.1',
  'english': 'Malignant neoplasm: Anal canal',
  'vietnamese': 'U ác của ống hậu môn',
}, {
  'id': 'C21.2',
  'english': 'Malignant neoplasm: Cloacogenic zone',
  'vietnamese': 'U ác của vùng có nguồn gốc từ ổ nhớp',
}, {
  'id': 'C21.8',
  'english': 'Malignant neoplasm: Overlapping lesion of rectum, anus and anal canal',
  'vietnamese': 'U ác với tổn thương chồng lấn của đại tràng, hậu môn và ống hậu môn',
}, {
  'id': 'C22',
  'english': 'Malignant neoplasm of liver and intrahepatic bile ducts',
  'vietnamese': 'U ác của gan và đường mật trong gan',
}, {
  'id': 'C22.0',
  'english': 'Malignant neoplasm: Liver cell carcinoma',
  'vietnamese': 'Ung thư biểu mô tế bào gan',
}, {
  'id': 'C22.1',
  'english': 'Malignant neoplasm: Intrahepatic bile duct carcinoma',
  'vietnamese': 'Ung thư biểu mô đường mật trong gan',
}, {
  'id': 'C22.2',
  'english': 'Malignant neoplasm: Hepatoblastoma',
  'vietnamese': 'U nguyên bào gan',
}, {
  'id': 'C22.3',
  'english': 'Malignant neoplasm: Angiosarcoma of liver',
  'vietnamese': 'Ung thư mô liên kết mạch máu của gan',
}, {
  'id': 'C22.4',
  'english': 'Malignant neoplasm: Other sarcomas of liver',
  'vietnamese': 'Ung thư mô liên kết khác của gan',
}, {
  'id': 'C22.7',
  'english': 'Malignant neoplasm: Other specified carcinomas of liver',
  'vietnamese': 'Ung thư biểu mô xác định khác của gan',
}, {
  'id': 'C22.9',
  'english': 'Malignant neoplasm: Liver, unspecified',
  'vietnamese': 'U ác của gan, không đặc hiệu',
}, {'id': 'C23', 'english': 'Malignant neoplasm of gallbladder', 'vietnamese': 'U ác túi mật'}, {
  'id': 'C24',
  'english': 'Malignant neoplasm of other and unspecified parts of biliary tract',
  'vietnamese': 'U ác của phần khác và không xác định vị trí của đường mật',
}, {
  'id': 'C24.0',
  'english': 'Malignant neoplasm: Extrahepatic bile duct',
  'vietnamese': 'U ác của đường mật ngoài gan',
}, {
  'id': 'C24.1',
  'english': 'Malignant neoplasm: Ampulla of Vater',
  'vietnamese': 'U ác của bóng Vater',
}, {
  'id': 'C24.8',
  'english': 'Malignant neoplasm: Overlapping lesion of biliary tract',
  'vietnamese': 'U ác với tổn thương chồng lấn của đường mật',
}, {
  'id': 'C24.9',
  'english': 'Malignant neoplasm: Biliary tract, unspecified',
  'vietnamese': 'U ác của đường mật, không đặc hiệu',
}, {'id': 'C25', 'english': 'Malignant neoplasm of pancreas', 'vietnamese': 'U ác tụy'}, {
  'id': 'C25.0',
  'english': 'Malignant neoplasm: Head of pancreas',
  'vietnamese': 'U ác của đầu tụy',
}, {
  'id': 'C25.1',
  'english': 'Malignant neoplasm: Body of pancreas',
  'vietnamese': 'U ác của thân tụy',
}, {
  'id': 'C25.2',
  'english': 'Malignant neoplasm: Tail of pancreas',
  'vietnamese': 'U ác của đuôi tụy',
}, {
  'id': 'C25.3',
  'english': 'Malignant neoplasm: Pancreatic duct',
  'vietnamese': 'U ác của ống tụy',
}, {
  'id': 'C25.4',
  'english': 'Malignant neoplasm: Endocrine pancreas',
  'vietnamese': 'U ác của tụy nội tiết',
}, {
  'id': 'C25.7',
  'english': 'Malignant neoplasm: Other parts of pancreas',
  'vietnamese': 'U ác phần khác của tụy',
}, {
  'id': 'C25.8',
  'english': 'Malignant neoplasm: Overlapping lesion of pancreas',
  'vietnamese': 'U ác với tổn thương chồng lấn của tụy',
}, {
  'id': 'C25.9',
  'english': 'Malignant neoplasm: Pancreas, unspecified',
  'vietnamese': 'U ác của tụỵ, không đặc hiệu',
}, {
  'id': 'C26',
  'english': 'Malignant neoplasm of other and ill-defined digestive organs',
  'vietnamese': 'U ác của cơ quan tiêu hóa khác và không rõ ràng',
}, {
  'id': 'C26.0',
  'english': 'Malignant neoplasm: Intestinal tract, part unspecified',
  'vietnamese': 'U ác của đường ruột phần không xác định vị trí',
}, {'id': 'C26.1', 'english': 'Malignant neoplasm: Spleen', 'vietnamese': 'U ác tính ở lách'}, {
  'id': 'C26.8',
  'english': 'Malignant neoplasm: Overlapping lesion of digestive system',
  'vietnamese': 'U ác với tổn thương chồng lấn của hệ tiêu hóa',
}, {
  'id': 'C26.9',
  'english': 'Malignant neoplasm: Ill-defined sites within the digestive system',
  'vietnamese': 'U ác ở vị trí không rõ ràng trong hệ tiêu hóa',
}, {
  'id': 'C30',
  'english': 'Malignant neoplasm of nasal cavity and middle ear',
  'vietnamese': 'U ác của hốc mũi và tai giữa',
}, {
  'id': 'C30.0',
  'english': 'Malignant neoplasm: Nasal cavity',
  'vietnamese': 'U ác của hốc mũi',
}, {
  'id': 'C30.1',
  'english': 'Malignant neoplasm: Middle ear',
  'vietnamese': 'U ác của tai giữa',
}, {
  'id': 'C31',
  'english': 'Malignant neoplasm of accessory sinuses',
  'vietnamese': 'U ác của các xoang phụ',
}, {
  'id': 'C31.0',
  'english': 'Malignant neoplasm: Maxillary sinus',
  'vietnamese': 'U ác của xoang hàm',
}, {
  'id': 'C31.1',
  'english': 'Malignant neoplasm: Ethmoidal sinus',
  'vietnamese': 'U ác của xoang sàng',
}, {
  'id': 'C31.2',
  'english': 'Malignant neoplasm: Frontal sinus',
  'vietnamese': 'U ác của xoang trán',
}, {
  'id': 'C31.3',
  'english': 'Malignant neoplasm: Sphenoidal sinus',
  'vietnamese': 'U ác của xoang bướm',
}, {
  'id': 'C31.8',
  'english': 'Malignant neoplasm: Overlapping lesion of accessory sinuses',
  'vietnamese': 'U ác với tổn thương lan rộng của xoang phụ',
}, {
  'id': 'C31.9',
  'english': 'Malignant neoplasm: Accessory sinus, unspecified',
  'vietnamese': 'U ác của xoang phụ, không đặc hiệu',
}, {'id': 'C32', 'english': 'Malignant neoplasm of larynx', 'vietnamese': 'U ác thanh quản'}, {
  'id': 'C32.0',
  'english': 'Malignant neoplasm: Glottis',
  'vietnamese': 'U ác của thanh môn',
}, {
  'id': 'C32.1',
  'english': 'Malignant neoplasm: Supraglottis',
  'vietnamese': 'U ác vùng trên thanh môn',
}, {
  'id': 'C32.2',
  'english': 'Malignant neoplasm: Subglottis',
  'vietnamese': 'U ác vùng dưới thanh môn',
}, {
  'id': 'C32.3',
  'english': 'Malignant neoplasm: Laryngeal cartilage',
  'vietnamese': 'U ác của sụn thanh quản',
}, {
  'id': 'C32.8',
  'english': 'Malignant neoplasm: Overlapping lesion of larynx',
  'vietnamese': 'U ác với tổn thương chồng lấn của thanh quản',
}, {
  'id': 'C32.9',
  'english': 'Malignant neoplasm: Larynx, unspecified',
  'vietnamese': 'U ác của thanh quản, không đặc hiệu',
}, {'id': 'C33', 'english': 'Malignant neoplasm of trachea', 'vietnamese': 'U ác khí quản'}, {
  'id': 'C34',
  'english': 'Malignant neoplasm of bronchus and lung',
  'vietnamese': 'U ác của phế quản và phổi',
}, {
  'id': 'C34.0',
  'english': 'Malignant neoplasm: Main bronchus',
  'vietnamese': 'U ác của phế quản chính',
}, {
  'id': 'C34.1',
  'english': 'Malignant neoplasm: Upper lobe, bronchus or lung',
  'vietnamese': 'U ác của thuỳ trên, phế quản hoặc phổi',
}, {
  'id': 'C34.2',
  'english': 'Malignant neoplasm: Middle lobe, bronchus or lung',
  'vietnamese': 'U ác của thuỳ giữa, phế quản hoặc phổi',
}, {
  'id': 'C34.3',
  'english': 'Malignant neoplasm: Lower lobe, bronchus or lung',
  'vietnamese': 'U ác của thuỳ dưới, phế quản hoặc phổi',
}, {
  'id': 'C34.8',
  'english': 'Malignant neoplasm: Overlapping lesion of bronchus and lung',
  'vietnamese': 'U ác với tổn thương chồng lấn của phế quản và phổi',
}, {
  'id': 'C34.9',
  'english': 'Malignant neoplasm: Bronchus or lung, unspecified',
  'vietnamese': 'U ác của khí quản hoặc phổi, không đặc hiệu',
}, {'id': 'C37', 'english': 'Malignant neoplasm of thymus', 'vietnamese': 'U ác tuyến ức'}, {
  'id': 'C38',
  'english': 'Malignant neoplasm of heart, mediastinum and pleura',
  'vietnamese': 'U ác của tim, trung thất và màng phổi',
}, {'id': 'C38.0', 'english': 'Malignant neoplasm: Heart', 'vietnamese': 'U ác của tim'}, {
  'id': 'C38.1',
  'english': 'Malignant neoplasm: Anterior mediastinum',
  'vietnamese': 'U ác của trung thất trước',
}, {
  'id': 'C38.2',
  'english': 'Malignant neoplasm: Posterior mediastinum',
  'vietnamese': 'U ác của trung thất sau',
}, {
  'id': 'C38.3',
  'english': 'Malignant neoplasm: Mediastinum, part unspecified',
  'vietnamese': 'U ác của trung thất, phần không xác định',
}, {'id': 'C38.4', 'english': 'Malignant neoplasm: Pleura', 'vietnamese': 'U ác của màng phổi'}, {
  'id': 'C38.8',
  'english': 'Malignant neoplasm: Overlapping lesion of heart, mediastinum and pleura',
  'vietnamese': 'U ác với tổn thương chồng lấn của tim, trung thất, màng phổi',
}, {
  'id': 'C39',
  'english': 'Malignant neoplasm of other and ill-defined sites in the respiratory system and intrathoracic organs',
  'vietnamese': 'U ác ở những vị trí khác và không rõ ràng của hệ hô hấp và cơ quan trong lồng ngực',
}, {
  'id': 'C39.0',
  'english': 'Malignant neoplasm: Upper respiratory tract, part unspecified',
  'vietnamese': 'U ác của đường hô hấp trên, phần không xác định vị trí',
}, {
  'id': 'C39.8',
  'english': 'Malignant neoplasm: Overlapping lesion of respiratory and intrathoracic organs',
  'vietnamese': 'U ác với tổn thương chồng lấn của cơ quan hô hấp và trong lồng ngực',
}, {
  'id': 'C39.9',
  'english': 'Malignant neoplasm: Ill-defined sites within the respiratory system',
  'vietnamese': 'U ác ở vị trí khó xác định trong hệ hô hấp',
}, {
  'id': 'C40',
  'english': 'Malignant neoplasm of bone and articular cartilage of limbs',
  'vietnamese': 'U ác của xương và sụn khớp của các chi',
}, {
  'id': 'C40.0',
  'english': 'Malignant neoplasm: Scapula and long bones of upper limb',
  'vietnamese': 'U ác của xương bả vai và xương dài của chi trên',
}, {
  'id': 'C40.1',
  'english': 'Malignant neoplasm: Short bones of upper limb',
  'vietnamese': 'U ác của xương ngắn của chi trên',
}, {
  'id': 'C40.2',
  'english': 'Malignant neoplasm: Long bones of lower limb',
  'vietnamese': 'U ác của xương dài của chi dưới',
}, {
  'id': 'C40.3',
  'english': 'Malignant neoplasm: Short bones of lower limb',
  'vietnamese': 'U ác của xương ngắn của chi dưới',
}, {
  'id': 'C40.8',
  'english': 'Malignant neoplasm: Overlapping lesion of bone and articular cartilage of limbs',
  'vietnamese': 'U ác với tổn thương chồng lấn của xương và sụn khớp của các chi',
}, {
  'id': 'C40.9',
  'english': 'Malignant neoplasm: Bone and articular cartilage of limb, unspecified',
  'vietnamese': 'U ác của xương và sụn khớp của chi, không đặc hiệu',
}, {
  'id': 'C41',
  'english': 'Malignant neoplasm of bone and articular cartilage of other and unspecified sites',
  'vietnamese': 'U ác của xương và sụn khớp ở vị trí khác và không xác định vị trí',
}, {
  'id': 'C41.0',
  'english': 'Malignant neoplasm: Bones of skull and face',
  'vietnamese': 'U ác của xương sọ và mặt',
}, {
  'id': 'C41.1',
  'english': 'Malignant neoplasm: Mandible',
  'vietnamese': 'U ác của xương hàm dưới',
}, {
  'id': 'C41.2',
  'english': 'Malignant neoplasm: Vertebral column',
  'vietnamese': 'U ác của cột sống',
}, {
  'id': 'C41.3',
  'english': 'Malignant neoplasm: Ribs, sternum and clavicle',
  'vietnamese': 'U ác của xương sườn, xương ức và xương đòn',
}, {
  'id': 'C41.4',
  'english': 'Malignant neoplasm: Pelvic bones, sacrum and coccyx',
  'vietnamese': 'U ác của xương chậu, xương cùng và xương cụt',
}, {
  'id': 'C41.8',
  'english': 'Malignant neoplasm: Overlapping lesion of bone and articular cartilage',
  'vietnamese': 'U ác với tổn thương chồng lấn của xương và sụn khớp',
}, {
  'id': 'C41.9',
  'english': 'Malignant neoplasm: Bone and articular cartilage, unspecified',
  'vietnamese': 'U ác của xương và sụn khớp không đặc hiệu',
}, {'id': 'C43', 'english': 'Malignant melanoma of skin', 'vietnamese': 'U hắc tố ác của da'}, {
  'id': 'C43.0',
  'english': 'Malignant neoplasm: Malignant melanoma of lip',
  'vietnamese': 'U hắc tố ác của môi',
}, {
  'id': 'C43.1',
  'english': 'Malignant neoplasm: Malignant melanoma of eyelid, including canthus',
  'vietnamese': 'U hắc tố ác của mi mắt, bao gồm khóe mắt',
}, {
  'id': 'C43.2',
  'english': 'Malignant neoplasm: Malignant melanoma of ear and external auricular canal',
  'vietnamese': 'U hắc tố ác của tai và ống tai ngoài',
}, {
  'id': 'C43.3',
  'english': 'Malignant neoplasm: Malignant melanoma of other and unspecified parts of face',
  'vietnamese': 'U hắc tố ác của phần khác và phần không xác định vị trí của mặt',
}, {
  'id': 'C43.4',
  'english': 'Malignant neoplasm: Malignant melanoma of scalp and neck',
  'vietnamese': 'U hắc tố ác của đầu và cổ',
}, {
  'id': 'C43.5',
  'english': 'Malignant neoplasm: Malignant melanoma of trunk',
  'vietnamese': 'U hắc tố ác của thân mình',
}, {
  'id': 'C43.6',
  'english': 'Malignant neoplasm: Malignant melanoma of upper limb, including shoulder',
  'vietnamese': 'U hắc tố ác của chi trên, bao gồm vai',
}, {
  'id': 'C43.7',
  'english': 'Malignant neoplasm: Malignant melanoma of lower limb, including hip',
  'vietnamese': 'U hắc tố ác của chi dưới, bao gồm háng',
}, {
  'id': 'C43.8',
  'english': 'Malignant neoplasm: Overlapping malignant melanoma of skin',
  'vietnamese': 'U hắc tố ác lan rông của da',
}, {
  'id': 'C43.9',
  'english': 'Malignant neoplasm: Malignant melanoma of skin, unspecified',
  'vietnamese': 'U hắc tố ác của da, không đặc hiệu',
}, {
  'id': 'C44',
  'english': 'Other malignant neoplasms of skin',
  'vietnamese': 'U ác khác của da',
}, {
  'id': 'C44.0',
  'english': 'Malignant neoplasm: Skin of lip',
  'vietnamese': 'U da ác tính của môi',
}, {
  'id': 'C44.1',
  'english': 'Malignant neoplasm: Skin of eyelid, including canthus',
  'vietnamese': 'U da ác tính của mi mắt, bao gồm khóe mắt',
}, {
  'id': 'C44.2',
  'english': 'Malignant neoplasm: Skin of ear and external auricular canal',
  'vietnamese': 'U da ác tính của tai và ống tai ngoài',
}, {
  'id': 'C44.3',
  'english': 'Malignant neoplasm: Skin of other and unspecified parts of face',
  'vietnamese': 'U da ác tính của phần khác và không xác định vị trí của mặt',
}, {
  'id': 'C44.4',
  'english': 'Malignant neoplasm: Skin of scalp and neck',
  'vietnamese': 'U da ác tính đầu và da cổ',
}, {
  'id': 'C44.5',
  'english': 'Malignant neoplasm: Skin of trunk',
  'vietnamese': 'U da ác tính của thân mình',
}, {
  'id': 'C44.6',
  'english': 'Malignant neoplasm: Skin of upper limb, including shoulder',
  'vietnamese': 'U da ác tính của chi trên, bao gồm vai',
}, {
  'id': 'C44.7',
  'english': 'Malignant neoplasm: Skin of lower limb, including hip',
  'vietnamese': 'U da ác tính của chi dưới, bao gồm háng',
}, {
  'id': 'C44.8',
  'english': 'Malignant neoplasm: Overlapping lesion of skin',
  'vietnamese': 'U ác với tổn thương chồng lấn của da',
}, {
  'id': 'C44.9',
  'english': 'Malignant neoplasm: Malignant neoplasm of skin, unspecified',
  'vietnamese': 'U da ác tính không đặc hiệu',
}, {'id': 'C45', 'english': 'Mesothelioma', 'vietnamese': 'U trung biểu mô'}, {
  'id': 'C45.0',
  'english': 'Mesothelioma of pleura',
  'vietnamese': 'U trung biểu mô của màng phổi',
}, {
  'id': 'C45.1',
  'english': 'Mesothelioma of peritoneum',
  'vietnamese': 'U trung biểu mô của phúc mạc',
}, {
  'id': 'C45.2',
  'english': 'Mesothelioma of pericardium',
  'vietnamese': 'U trung biểu mô màng ngoài tim',
}, {
  'id': 'C45.7',
  'english': 'Mesothelioma of other sites',
  'vietnamese': 'U trung biểu mô của các vị trí khác',
}, {
  'id': 'C45.9',
  'english': 'Mesothelioma, unspecified',
  'vietnamese': 'U trung biểu mô không đặc hiệu',
}, {'id': 'C46', 'english': 'Kaposi s sarcoma', 'vietnamese': 'Ung thư mô liên kết Kaposi'}, {
  'id': 'C46.0',
  'english': 'Kaposi s sarcoma of skin',
  'vietnamese': 'Ung thư mô liên kết Kaposi của da',
}, {
  'id': 'C46.1',
  'english': 'Kaposi s sarcoma of soft tissue',
  'vietnamese': 'Ung thư mô liên kết Kaposi của mô mềm',
}, {
  'id': 'C46.2',
  'english': 'Kaposi s sarcoma of palate',
  'vietnamese': 'Ung thư mô liên kết Kaposi của vòm khẩu cái',
}, {
  'id': 'C46.3',
  'english': 'Kaposi s sarcoma of lymph nodes',
  'vietnamese': 'Ung thư mô liên kết Kaposi của hạch lympho',
}, {
  'id': 'C46.7',
  'english': 'Kaposi s sarcoma of other sites',
  'vietnamese': 'Ung thư mô liên kết Kaposi của các nơi khác',
}, {
  'id': 'C46.8',
  'english': 'Kaposi s sarcoma of multiple organs',
  'vietnamese': 'Ung thư mô liên kết Kaposi của nhiều cơ quan',
}, {
  'id': 'C46.9',
  'english': 'Kaposi s sarcoma, unspecified',
  'vietnamese': 'Ung thư mô liên kết Kaposi không đặc hiệu',
}, {
  'id': 'C47',
  'english': 'Malignant neoplasm of peripheral nerves and autonomic nervous system',
  'vietnamese': 'U ác của dây thần kinh ngoại biên và của hệ thần kinh tự động',
}, {
  'id': 'C47.0',
  'english': 'Malignant neoplasm: Peripheral nerves of head, face and neck',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của đầu, mặt, cổ',
}, {
  'id': 'C47.1',
  'english': 'Malignant neoplasm: Peripheral nerves of upper limb, including shoulder',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của chi trên, bao gồm vai',
}, {
  'id': 'C47.2',
  'english': 'Malignant neoplasm: Peripheral nerves of lower limb, including hip',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của chi dưới, bao gồm háng',
}, {
  'id': 'C47.3',
  'english': 'Malignant neoplasm: Peripheral nerves of thorax',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của lồng ngực',
}, {
  'id': 'C47.4',
  'english': 'Malignant neoplasm: Peripheral nerves of abdomen',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của bụng',
}, {
  'id': 'C47.5',
  'english': 'Malignant neoplasm: Peripheral nerves of pelvis',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của chậu',
}, {
  'id': 'C47.6',
  'english': 'Malignant neoplasm: Peripheral nerves of trunk, unspecified',
  'vietnamese': 'U ác của dây thần kinh ngoại biên của thân mình, không đặc hiệu',
}, {
  'id': 'C47.8',
  'english': 'Malignant neoplasm: Overlapping lesion of peripheral nerves and autonomic nervous system',
  'vietnamese': 'U ác với tổn thương chồng lấn của dây thần kinh ngoại biên và của hệ thần kinh tự động',
}, {
  'id': 'C47.9',
  'english': 'Malignant neoplasm: Peripheral nerves and autonomic nervous system, unspecified',
  'vietnamese': 'U ác của dây thần kinh ngoại biên và hệ thần kinh tự động, không đặc hiệu',
}, {
  'id': 'C48',
  'english': 'Malignant neoplasm of retroperitoneum and peritoneum',
  'vietnamese': 'U ác của vùng sau phúc mạc và phúc mạc',
}, {
  'id': 'C48.0',
  'english': 'Malignant neoplasm: Retroperitoneum',
  'vietnamese': 'U ác của vùng sau phúc mạc',
}, {
  'id': 'C48.1',
  'english': 'Malignant neoplasm: Specified parts of peritoneum',
  'vietnamese': 'U ác của các phần xác định của phúc mạc',
}, {
  'id': 'C48.2',
  'english': 'Malignant neoplasm: Peritoneum, unspecified',
  'vietnamese': 'U ác của phúc mạc không đặc hiệu',
}, {
  'id': 'C48.8',
  'english': 'Malignant neoplasm: Overlapping lesion of retroperitoneum and peritoneum',
  'vietnamese': 'U ác với tổn thương chồng lấn của vùng sau phúc mạc và phúc mạc',
}, {
  'id': 'C49',
  'english': 'Malignant neoplasm of other connective and soft tissue',
  'vietnamese': 'U ác của mô liên kết và mô mềm khác',
}, {
  'id': 'C49.0',
  'english': 'Malignant neoplasm: Connective and soft tissue of head, face and neck',
  'vietnamese': 'U ác của mô liên kết và mềm của đầu, mặt và cổ',
}, {
  'id': 'C49.1',
  'english': 'Malignant neoplasm: Connective and soft tissue of upper limb, including shoulder',
  'vietnamese': 'U ác của mô liên kết và mô mềm của chi trên bao gồm vai',
}, {
  'id': 'C49.2',
  'english': 'Malignant neoplasm: Connective and soft tissue of lower limb, including hip',
  'vietnamese': 'U ác của mô liên kết và mô mềm của chi dưới bao gồm háng',
}, {
  'id': 'C49.3',
  'english': 'Malignant neoplasm: Connective and soft tissue of thorax',
  'vietnamese': 'U ác của mô liên kết và mô mềm của lồng ngực',
}, {
  'id': 'C49.4',
  'english': 'Malignant neoplasm: Connective and soft tissue of abdomen',
  'vietnamese': 'U ác của mô liên kết và mô mềm của bụng',
}, {
  'id': 'C49.5',
  'english': 'Malignant neoplasm: Connective and soft tissue of pelvis',
  'vietnamese': 'U ác của mô liên kết và mô mềm của vùng chậu',
}, {
  'id': 'C49.6',
  'english': 'Malignant neoplasm: Connective and soft tissue of trunk, unspecified',
  'vietnamese': 'U ác của mô liên kết và mô mềm của thân mình, không đặc hiệu',
}, {
  'id': 'C49.8',
  'english': 'Malignant neoplasm: Overlapping lesion of connective and soft tissue',
  'vietnamese': 'U ác với tổn thương chồng lấn của mô liên kết và mô mềm',
}, {
  'id': 'C49.9',
  'english': 'Malignant neoplasm: Connective and soft tissue, unspecified',
  'vietnamese': 'U ác của mô liên kết và mô mềm, không đặc hiệu',
}, {'id': 'C50', 'english': 'Malignant neoplasm of breast', 'vietnamese': 'U ác của vú'}, {
  'id': 'C50.0',
  'english': 'Malignant neoplasm: Nipple and areola',
  'vietnamese': 'U ác của núm và quầng vú',
}, {
  'id': 'C50.1',
  'english': 'Malignant neoplasm: Central portion of breast',
  'vietnamese': 'U ác của vùng trung tâm vú',
}, {
  'id': 'C50.2',
  'english': 'Malignant neoplasm: Upper-inner quadrant of breast',
  'vietnamese': 'U ác của 1/4 trên - trong vú',
}, {
  'id': 'C50.3',
  'english': 'Malignant neoplasm: Lower-inner quadrant of breast',
  'vietnamese': 'U ác của 1/4 dưới - trong vú',
}, {
  'id': 'C50.4',
  'english': 'Malignant neoplasm: Upper-outer quadrant of breast',
  'vietnamese': 'U ác của 1/4 trên - ngoài vú',
}, {
  'id': 'C50.5',
  'english': 'Malignant neoplasm: Lower-outer quadrant of breast',
  'vietnamese': 'U ác của 1/4 dưới - ngoài vú',
}, {
  'id': 'C50.6',
  'english': 'Malignant neoplasm: Axillary tail of breast',
  'vietnamese': 'U ác của đuôi nách của vú',
}, {
  'id': 'C50.8',
  'english': 'Malignant neoplasm: Overlapping lesion of breast',
  'vietnamese': 'U ác với tổn thương chồng lấn của vú',
}, {
  'id': 'C50.9',
  'english': 'Malignant neoplasm: Breast, unspecified',
  'vietnamese': 'U ác của vú không đặc hiệu',
}, {'id': 'C51', 'english': 'Malignant neoplasm of vulva', 'vietnamese': 'U ác âm hộ'}, {
  'id': 'C51.0',
  'english': 'Malignant neoplasm: Labium majus',
  'vietnamese': 'U ác của môi lớn',
}, {
  'id': 'C51.1',
  'english': 'Malignant neoplasm: Labium minus',
  'vietnamese': 'U ác của môi nhỏ',
}, {
  'id': 'C51.2',
  'english': 'Malignant neoplasm: Clitoris',
  'vietnamese': 'U ác của âm vật',
}, {
  'id': 'C51.8',
  'english': 'Malignant neoplasm: Overlapping lesion of vulva',
  'vietnamese': 'U ác với tổn thương lan rộng của âm hộ',
}, {
  'id': 'C51.9',
  'english': 'Malignant neoplasm: Vulva, unspecified',
  'vietnamese': 'U ác của âm hộ không đặc hiệu',
}, {'id': 'C52', 'english': 'Malignant neoplasm of vagina', 'vietnamese': 'U ác của âm đạo'}, {
  'id': 'C53',
  'english': 'Malignant neoplasm of cervix uteri',
  'vietnamese': 'U ác của cổ tử cung',
}, {
  'id': 'C53.0',
  'english': 'Malignant neoplasm: Endocervix',
  'vietnamese': 'U ác của cổ tử cung trong',
}, {
  'id': 'C53.1',
  'english': 'Malignant neoplasm: Exocervix',
  'vietnamese': 'U ác của cổ tử cung ngoài',
}, {
  'id': 'C53.8',
  'english': 'Malignant neoplasm: Overlapping lesion of cervix uteri',
  'vietnamese': 'U ác với tổn thương chồng lấn của cổ tử cung',
}, {
  'id': 'C53.9',
  'english': 'Malignant neoplasm: Cervix uteri, unspecified',
  'vietnamese': 'U ác của cổ tử cung không đặc hiệu',
}, {
  'id': 'C54',
  'english': 'Malignant neoplasm of corpus uteri',
  'vietnamese': 'U ác của thân tử cung',
}, {
  'id': 'C54.0',
  'english': 'Malignant neoplasm: Isthmus uteri',
  'vietnamese': 'U ác của eo tử cung',
}, {
  'id': 'C54.1',
  'english': 'Malignant neoplasm: Endometrium',
  'vietnamese': 'U ác của nội mạc tử cung',
}, {
  'id': 'C54.2',
  'english': 'Malignant neoplasm: Myometrium',
  'vietnamese': 'U ác của cơ tử cung',
}, {
  'id': 'C54.3',
  'english': 'Malignant neoplasm: Fundus uteri',
  'vietnamese': 'U ác của đáy tử cung',
}, {
  'id': 'C54.8',
  'english': 'Malignant neoplasm: Overlapping lesion of corpus uteri',
  'vietnamese': 'U ác với tổn thương chồng lấn của thân tử cung',
}, {
  'id': 'C54.9',
  'english': 'Malignant neoplasm: Corpus uteri, unspecified',
  'vietnamese': 'U ác của thân tử cung không đặc hiệu',
}, {
  'id': 'C55',
  'english': 'Malignant neoplasm of uterus, part unspecified',
  'vietnamese': 'U ác tử cung phần không xác định vị trí',
}, {'id': 'C56', 'english': 'Malignant neoplasm of ovary', 'vietnamese': 'U ác buồng trứng'}, {
  'id': 'C57',
  'english': 'Malignant neoplasm of other and unspecified female genital organs',
  'vietnamese': 'U ác của cơ quan sinh dục nữ khác và không xác định',
}, {
  'id': 'C57.0',
  'english': 'Malignant neoplasm: Fallopian tube',
  'vietnamese': 'U ác của vòi Fallop',
}, {
  'id': 'C57.1',
  'english': 'Malignant neoplasm: Broad ligament',
  'vietnamese': 'U ác của dây chằng rộng',
}, {
  'id': 'C57.2',
  'english': 'Malignant neoplasm: Round ligament',
  'vietnamese': 'U ác của dây chằng tròn',
}, {
  'id': 'C57.3',
  'english': 'Malignant neoplasm: Parametrium',
  'vietnamese': 'U ác của cận tử cung',
}, {
  'id': 'C57.4',
  'english': 'Malignant neoplasm: Uterine adnexa, unspecified',
  'vietnamese': 'U ác của phần phụ tử cung không đặc hiệu',
}, {
  'id': 'C57.7',
  'english': 'Malignant neoplasm: Other specified female genital organs',
  'vietnamese': 'U ác của cơ quan sinh dục nữ xác định khác',
}, {
  'id': 'C57.8',
  'english': 'Malignant neoplasm: Overlapping lesion of female genital organs',
  'vietnamese': 'U ác với tổn thương chồng lấn của cơ quan sinh dục nữ',
}, {
  'id': 'C57.9',
  'english': 'Malignant neoplasm: Female genital organ, unspecified',
  'vietnamese': 'U ác của cơ quan sinh dục nữ không đặc hiệu',
}, {
  'id': 'C58',
  'english': 'Malignant neoplasm of placenta',
  'vietnamese': 'U ác của rau thai (nhau thai)',
}, {'id': 'C60', 'english': 'Malignant neoplasm of penis', 'vietnamese': 'U ác của dương vật'}, {
  'id': 'C60.0',
  'english': 'Malignant neoplasm: Prepuce',
  'vietnamese': 'U da ác tính bao quy đầu',
}, {
  'id': 'C60.1',
  'english': 'Malignant neoplasm: Glans penis',
  'vietnamese': 'U da ác của quy đầu dương vật',
}, {
  'id': 'C60.2',
  'english': 'Malignant neoplasm: Body of penis',
  'vietnamese': 'U ác của thân dương vật',
}, {
  'id': 'C60.8',
  'english': 'Malignant neoplasm: Overlapping lesion of penis',
  'vietnamese': 'U ác với tổn thương chồng lấn của dương vật',
}, {
  'id': 'C60.9',
  'english': 'Malignant neoplasm: Penis, unspecified',
  'vietnamese': 'U ác của dương vật không đặc hiệu',
}, {
  'id': 'C61',
  'english': 'Malignant neoplasm of prostate',
  'vietnamese': 'U ác của tuyến tiền liệt',
}, {'id': 'C62', 'english': 'Malignant neoplasm of testis', 'vietnamese': 'U ác của tinh hoàn'}, {
  'id': 'C62.0',
  'english': 'Malignant neoplasm: Undescended testis',
  'vietnamese': 'U ác của tinh hoàn không xuống',
}, {
  'id': 'C62.1',
  'english': 'Malignant neoplasm: Descended testis',
  'vietnamese': 'U ác của tinh hoàn đã xuống',
}, {
  'id': 'C62.9',
  'english': 'Malignant neoplasm: Testis, unspecified',
  'vietnamese': 'U ác của tinh hoàn không đặc hiệu',
}, {
  'id': 'C63',
  'english': 'Malignant neoplasm of other and unspecified male genital organs',
  'vietnamese': 'U ác của cơ quan sinh dục nam khác và không đặc hiệu khác',
}, {
  'id': 'C63.0',
  'english': 'Malignant neoplasm: Epididymis',
  'vietnamese': 'U ác của mào tinh',
}, {
  'id': 'C63.1',
  'english': 'Malignant neoplasm: Spermatic cord',
  'vietnamese': 'U ác của thừng tinh',
}, {
  'id': 'C63.2',
  'english': 'Malignant neoplasm: Scrotum',
  'vietnamese': 'U ác của bìu (Dái)',
}, {
  'id': 'C63.7',
  'english': 'Malignant neoplasm: Other specified male genital organs',
  'vietnamese': 'U ác của cơ quan sinh dục nam xác định khác',
}, {
  'id': 'C63.8',
  'english': 'Malignant neoplasm: Overlapping lesion of male genital organs',
  'vietnamese': 'U ác với tổn thương chồng lấn của cơ quan sinh dục nam',
}, {
  'id': 'C63.9',
  'english': 'Malignant neoplasm: Male genital organ, unspecified',
  'vietnamese': 'U ác của cơ quan sinh dục nam không xác định',
}, {
  'id': 'C64',
  'english': 'Malignant neoplasm of kidney, except renal pelvis',
  'vietnamese': 'U ác của thận ngoại trừ bể thận',
}, {
  'id': 'C65',
  'english': 'Malignant neoplasm of renal pelvis',
  'vietnamese': 'U ác của bể thận',
}, {
  'id': 'C66',
  'english': 'Malignant neoplasm of ureter',
  'vietnamese': 'U ác niệu quản',
}, {
  'id': 'C67',
  'english': 'Malignant neoplasm of bladder',
  'vietnamese': 'U ác của bàng quang',
}, {
  'id': 'C67.0',
  'english': 'Malignant neoplasm: Trigone of bladder',
  'vietnamese': 'U ác của tam giác bàng quang',
}, {
  'id': 'C67.1',
  'english': 'Malignant neoplasm: Dome of bladder',
  'vietnamese': 'U ác của đáy bàng quang',
}, {
  'id': 'C67.2',
  'english': 'Malignant neoplasm: Lateral wall of bladder',
  'vietnamese': 'U ác của thành bên bàng quang',
}, {
  'id': 'C67.3',
  'english': 'Malignant neoplasm: Anterior wall of bladder',
  'vietnamese': 'U ác của thành trước bàng quang',
}, {
  'id': 'C67.4',
  'english': 'Malignant neoplasm: Posterior wall of bladder',
  'vietnamese': 'U ác của thành sau bàng quang',
}, {
  'id': 'C67.5',
  'english': 'Malignant neoplasm: Bladder neck',
  'vietnamese': 'U ác của cổ bàng quang',
}, {
  'id': 'C67.6',
  'english': 'Malignant neoplasm: Ureteric orifice',
  'vietnamese': 'U ác của lỗ niệu quản',
}, {
  'id': 'C67.7',
  'english': 'Malignant neoplasm: Urachus',
  'vietnamese': 'U ác của dây treo bàng quang',
}, {
  'id': 'C67.8',
  'english': 'Malignant neoplasm: Overlapping lesion of bladder',
  'vietnamese': 'U ác với tổn thương chồng lấn của bàng quang',
}, {
  'id': 'C67.9',
  'english': 'Malignant neoplasm: Bladder, unspecified',
  'vietnamese': 'U ác của bàng quang không đặc hiệu',
}, {
  'id': 'C68',
  'english': 'Malignant neoplasm of other and unspecified urinary organs',
  'vietnamese': 'U ác của cơ quan tiết niệu khác và không đặc hiệu',
}, {'id': 'C68.0', 'english': 'Malignant neoplasm: Urethra', 'vietnamese': 'U ác của niệu đạo'}, {
  'id': 'C68.1',
  'english': 'Malignant neoplasm: Paraurethral gland',
  'vietnamese': 'U ác của tuyến cận niệu đạo',
}, {
  'id': 'C68.8',
  'english': 'Malignant neoplasm: Overlapping lesion of urinary organs',
  'vietnamese': 'U ác với tổn thương chồng lấn của cơ quan tiết niệu',
}, {
  'id': 'C68.9',
  'english': 'Malignant neoplasm: Urinary organ, unspecified',
  'vietnamese': 'U ác của cơ quan tiết niệu, không đặc hiệu',
}, {
  'id': 'C69',
  'english': 'Malignant neoplasm of eye and adnexa',
  'vietnamese': 'U ác của mắt và phần phụ',
}, {
  'id': 'C69.0',
  'english': 'Malignant neoplasm: Conjunctiva',
  'vietnamese': 'U ác của kết mạc',
}, {
  'id': 'C69.1',
  'english': 'Malignant neoplasm: Cornea',
  'vietnamese': 'U ác của củng mạc',
}, {'id': 'C69.2', 'english': 'Malignant neoplasm: Retina', 'vietnamese': 'U ác của võng mạc'}, {
  'id': 'C69.3',
  'english': 'Malignant neoplasm: Choroid',
  'vietnamese': 'U ác của màng mạch',
}, {
  'id': 'C69.4',
  'english': 'Malignant neoplasm: Ciliary body',
  'vietnamese': 'U ác của thể mi',
}, {
  'id': 'C69.5',
  'english': 'Malignant neoplasm: Lacrimal gland and duct',
  'vietnamese': 'U ác của tuyến lệ và ống',
}, {'id': 'C69.6', 'english': 'Malignant neoplasm: Orbit', 'vietnamese': 'U ác của hốc mắt'}, {
  'id': 'C69.8',
  'english': 'Malignant neoplasm: Overlapping lesion of eye and adnexa',
  'vietnamese': 'U ác với tổn thương chồng lấn của mắt và phần phụ',
}, {
  'id': 'C69.9',
  'english': 'Malignant neoplasm: Eye, unspecified',
  'vietnamese': 'U ác của mắt không đặc hiệu',
}, {
  'id': 'C70',
  'english': 'Malignant neoplasm of meninges',
  'vietnamese': 'U ác của màng não',
}, {
  'id': 'C70.0',
  'english': 'Malignant neoplasm: Cerebral meninges',
  'vietnamese': 'U ác của màng não',
}, {
  'id': 'C70.1',
  'english': 'Malignant neoplasm: Spinal meninges',
  'vietnamese': 'U ác của màng tủy',
}, {
  'id': 'C70.9',
  'english': 'Malignant neoplasm: Meninges, unspecified',
  'vietnamese': 'U ác của màng não, không đặc hiệu',
}, {'id': 'C71', 'english': 'Malignant neoplasm of brain', 'vietnamese': 'U ác của não'}, {
  'id': 'C71.0',
  'english': 'Malignant neoplasm: Cerebrum, except lobes and ventricles',
  'vietnamese': 'U ác của đại não ngoại trừ thùy não và não thất',
}, {
  'id': 'C71.1',
  'english': 'Malignant neoplasm: Frontal lobe',
  'vietnamese': 'U ác của thuỳ trán',
}, {
  'id': 'C71.2',
  'english': 'Malignant neoplasm: Temporal lobe',
  'vietnamese': 'U ác của thuỳ thái dương',
}, {
  'id': 'C71.3',
  'english': 'Malignant neoplasm: Parietal lobe',
  'vietnamese': 'U ác của thuỳ đỉnh',
}, {
  'id': 'C71.4',
  'english': 'Malignant neoplasm: Occipital lobe',
  'vietnamese': 'U ác của thùy chẩm',
}, {
  'id': 'C71.5',
  'english': 'Malignant neoplasm: Cerebral ventricle',
  'vietnamese': 'U ác của não thất',
}, {
  'id': 'C71.6',
  'english': 'Malignant neoplasm: Cerebellum',
  'vietnamese': 'U ác của tiểu não',
}, {
  'id': 'C71.7',
  'english': 'Malignant neoplasm: Brain stem',
  'vietnamese': 'U ác của cuống não',
}, {
  'id': 'C71.8',
  'english': 'Malignant neoplasm: Overlapping lesion of brain',
  'vietnamese': 'U ác với tổn thương chồng lấn của não',
}, {
  'id': 'C71.9',
  'english': 'Malignant neoplasm: Brain, unspecified',
  'vietnamese': 'U ác của não không đặc hiệu',
}, {
  'id': 'C72',
  'english': 'Malignant neoplasm of spinal cord, cranial nerves and other parts of central nervous system',
  'vietnamese': 'U ác của tủy sống, dây thần kinh sọ và các phần khác của hệ thần kinh trung ương',
}, {
  'id': 'C72.0',
  'english': 'Malignant neoplasm: Spinal cord',
  'vietnamese': 'U ác của tủy sống',
}, {
  'id': 'C72.1',
  'english': 'Malignant neoplasm: Cauda equina',
  'vietnamese': 'U ác của chùm đuôi ngựa',
}, {
  'id': 'C72.2',
  'english': 'Malignant neoplasm: Olfactory nerve',
  'vietnamese': 'U ác của thần kinh khứu giác',
}, {
  'id': 'C72.3',
  'english': 'Malignant neoplasm: Optic nerve',
  'vietnamese': 'U ác của thần kinh thị giác',
}, {
  'id': 'C72.4',
  'english': 'Malignant neoplasm: Acoustic nerve',
  'vietnamese': 'U ác của thần kinh thính giác',
}, {
  'id': 'C72.5',
  'english': 'Malignant neoplasm: Other and unspecified cranial nerves',
  'vietnamese': 'U ác của dây thần kinh sọ khác và không xác định',
}, {
  'id': 'C72.8',
  'english': 'Malignant neoplasm: Overlapping lesion of brain and other parts of central nervous system',
  'vietnamese': 'U ác với tổn thương chồng lấn của não và các phần khác của hệ thần kinh trung ương',
}, {
  'id': 'C72.9',
  'english': 'Malignant neoplasm: Central nervous system, unspecified',
  'vietnamese': 'U ác của hệ thần kinh trung ương không đặc hiệu',
}, {
  'id': 'C73',
  'english': 'Malignant neoplasm of thyroid gland',
  'vietnamese': 'U ác của tuyến giáp',
}, {
  'id': 'C74',
  'english': 'Malignant neoplasm of adrenal gland',
  'vietnamese': 'U ác của tuyến thượng thận',
}, {
  'id': 'C74.0',
  'english': 'Malignant neoplasm: Cortex of adrenal gland',
  'vietnamese': 'U ác của vỏ tuyến thượng thận',
}, {
  'id': 'C74.1',
  'english': 'Malignant neoplasm: Medulla of adrenal gland',
  'vietnamese': 'U ác của tủy tuyến thượng thận',
}, {
  'id': 'C74.9',
  'english': 'Malignant neoplasm: Adrenal gland, unspecified',
  'vietnamese': 'U ác của tuyến thượng thận, không đặc hiệu',
}, {
  'id': 'C75',
  'english': 'Malignant neoplasm of other endocrine glands and related structures',
  'vietnamese': 'U ác của các tuyến nội tiết khác và các cơ quan liên quan',
}, {
  'id': 'C75.0',
  'english': 'Malignant neoplasm: Parathyroid gland',
  'vietnamese': 'U ác của tuyến cận giáp',
}, {
  'id': 'C75.1',
  'english': 'Malignant neoplasm: Pituitary gland',
  'vietnamese': 'U ác của tuyến yên',
}, {
  'id': 'C75.2',
  'english': 'Malignant neoplasm: Craniopharyngeal duct',
  'vietnamese': 'U ác của ống sọ hầu',
}, {
  'id': 'C75.3',
  'english': 'Malignant neoplasm: Pineal gland',
  'vietnamese': 'U ác của tuyến tùng',
}, {
  'id': 'C75.4',
  'english': 'Malignant neoplasm: Carotid body',
  'vietnamese': 'U ác của thể cảnh',
}, {
  'id': 'C75.5',
  'english': 'Malignant neoplasm: Aortic body and other paraganglia',
  'vietnamese': 'U ác của thể động mạch chủ và phó hạch khác',
}, {
  'id': 'C75.8',
  'english': 'Malignant neoplasm: Pluriglandular involvement, unspecified',
  'vietnamese': 'U ác ảnh hưởng nhiều tuyến không đặc hiệu',
}, {
  'id': 'C75.9',
  'english': 'Malignant neoplasm: Endocrine gland, unspecified',
  'vietnamese': 'U ác của tuyến nội tiết không đặc hiệu',
}, {
  'id': 'C76',
  'english': 'Malignant neoplasm of other and ill-defined sites',
  'vietnamese': 'U ác có vị trí khác và không rõ ràng',
}, {
  'id': 'C76.0',
  'english': 'Malignant neoplasm of other and ill-defined sites: Head, face and neck',
  'vietnamese': 'U ác của đầu mặt và cổ',
}, {
  'id': 'C76.1',
  'english': 'Malignant neoplasm of other and ill-defined sites: Thorax',
  'vietnamese': 'U ác của ngực',
}, {
  'id': 'C76.2',
  'english': 'Malignant neoplasm of other and ill-defined sites: Abdomen',
  'vietnamese': 'U ác của bụng',
}, {
  'id': 'C76.3',
  'english': 'Malignant neoplasm of other and ill-defined sites: Pelvis',
  'vietnamese': 'U ác của chậu',
}, {
  'id': 'C76.4',
  'english': 'Malignant neoplasm of other and ill-defined sites: Upper limb',
  'vietnamese': 'U ác của chi trên',
}, {
  'id': 'C76.5',
  'english': 'Malignant neoplasm of other and ill-defined sites: Lower limb',
  'vietnamese': 'U ác của chi dưới',
}, {
  'id': 'C76.7',
  'english': 'Malignant neoplasm of other and ill-defined sites: Other ill-defined sites',
  'vietnamese': 'U ác ở vị trí không rõ ràng khác',
}, {
  'id': 'C76.8',
  'english': 'Malignant neoplasm of other and ill-defined sites: Overlapping lesion of other and ill-defined sites',
  'vietnamese': 'U ác với tổn thương chồng lấn của các vị trí khác và không rõ ràng',
}, {
  'id': 'C77',
  'english': 'Secondary and unspecified malignant neoplasm of lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch lympho',
}, {
  'id': 'C77.0',
  'english': 'Secondary and unspecified malignant neoplasm: Lymph nodes of head, face and neck',
  'vietnamese': 'U ác của hạch của vùng đầu mặt cổ',
}, {
  'id': 'C77.1',
  'english': 'Secondary and unspecified malignant neoplasm: Intrathoracic lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch trong lồng ngực',
}, {
  'id': 'C77.2',
  'english': 'Secondary and unspecified malignant neoplasm: Intra-abdominal lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch trong xoang bụng',
}, {
  'id': 'C77.3',
  'english': 'Secondary and unspecified malignant neoplasm: Axillary and upper limb lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch nách và hạch chi trên',
}, {
  'id': 'C77.4',
  'english': 'Secondary and unspecified malignant neoplasm: Inguinal and lower limb lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch bẹn và hạch chi dưới',
}, {
  'id': 'C77.5',
  'english': 'Secondary and unspecified malignant neoplasm: Intrapelvic lymph nodes',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch trong vùng chậu',
}, {
  'id': 'C77.8',
  'english': 'Secondary and unspecified malignant neoplasm: Lymph nodes of multiple regions',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch của nhiều vùng',
}, {
  'id': 'C77.9',
  'english': 'Secondary and unspecified malignant neoplasm: Lymph node, unspecified',
  'vietnamese': 'U ác thứ phát và không đặc hiệu của hạch lympho, không xác định',
}, {
  'id': 'C78',
  'english': 'Secondary malignant neoplasm of respiratory and digestive organs',
  'vietnamese': 'U ác thứ phát của cơ quan hô hấp và tiêu hóa',
}, {
  'id': 'C78.0',
  'english': 'Secondary malignant neoplasm of lung',
  'vietnamese': 'U ác thứ phát của phổi',
}, {
  'id': 'C78.1',
  'english': 'Secondary malignant neoplasm of mediastinum',
  'vietnamese': 'U ác thứ phát của trung thất',
}, {
  'id': 'C78.2',
  'english': 'Secondary malignant neoplasm of pleura',
  'vietnamese': 'U ác thứ phát của màng phổi',
}, {
  'id': 'C78.3',
  'english': 'Secondary malignant neoplasm of other and unspecified respiratory organs',
  'vietnamese': 'U ác thứ phát của cơ quan hô hấp và không xác định',
}, {
  'id': 'C78.4',
  'english': 'Secondary malignant neoplasm of small intestine',
  'vietnamese': 'U ác thứ phát của ruột non',
}, {
  'id': 'C78.5',
  'english': 'Secondary malignant neoplasm of large intestine and rectum',
  'vietnamese': 'U ác thứ phát của đại tràng và trực tràng',
}, {
  'id': 'C78.6',
  'english': 'Secondary malignant neoplasm of retroperitoneum and peritoneum',
  'vietnamese': 'U ác thứ phát của sau phúc mạc và phúc mạc',
}, {
  'id': 'C78.7',
  'english': 'Secondary malignant neoplasm of liver',
  'vietnamese': 'U ác thứ phát của gan và đường mật trong gan',
}, {
  'id': 'C78.8',
  'english': 'Secondary malignant neoplasm of other and unspecified digestive organs',
  'vietnamese': 'U ác thứ phát của cơ quan tiêu hóa khác và không đặc hiệu',
}, {
  'id': 'C79',
  'english': 'Secondary malignant neoplasm of other sites',
  'vietnamese': 'U ác thứ phát ở vị trí khác',
}, {
  'id': 'C79.0',
  'english': 'Secondary malignant neoplasm of kidney and renal pelvis',
  'vietnamese': 'U ác thứ phát của thận và bể thận',
}, {
  'id': 'C79.1',
  'english': 'Secondary malignant neoplasm of bladder and other and unspecified urinary organs',
  'vietnamese': 'U ác thứ phát của bàng quang và cơ quan tiết niệu khác và không đặc hiệu',
}, {
  'id': 'C79.2',
  'english': 'Secondary malignant neoplasm of skin',
  'vietnamese': 'U ác thứ phát của da',
}, {
  'id': 'C79.3',
  'english': 'Secondary malignant neoplasm of brain and cerebral meninges',
  'vietnamese': 'U ác thứ phát của não và màng não',
}, {
  'id': 'C79.4',
  'english': 'Secondary malignant neoplasm of other and unspecified parts of nervous system',
  'vietnamese': 'U ác thứ phát không xác định vị trí của hệ thần kinh',
}, {
  'id': 'C79.5',
  'english': 'Secondary malignant neoplasm of bone and bone marrow',
  'vietnamese': 'U ác thứ phát của xương và tủy xương',
}, {
  'id': 'C79.6',
  'english': 'Secondary malignant neoplasm of ovary',
  'vietnamese': 'U ác thứ phát của buồng trứng',
}, {
  'id': 'C79.7',
  'english': 'Secondary malignant neoplasm of adrenal gland',
  'vietnamese': 'U ác thứ phát của tuyến thượng thận',
}, {
  'id': 'C79.8',
  'english': 'Secondary malignant neoplasm of other specified sites',
  'vietnamese': 'U ác thứ phát của các vị trí xác định rõ khác',
}, {
  'id': 'C80',
  'english': 'Malignant neoplasms of ill-defined, secondary and unspecified sites',
  'vietnamese': 'U ác thứ phát trong một số bệnh cụ thể nhưng không xác định vị trí',
}, {
  'id': 'C80.0',
  'english': 'Malignant neoplasm, primary site unknown, so stated',
  'vietnamese': 'U ác tính, không biết vị trí nguyên phát',
}, {
  'id': 'C80.9',
  'english': 'Malignant neoplasm, unspecified',
  'vietnamese': 'U ác, không đặc hiệu',
}, {'id': 'C81', 'english': 'Hodgkin s disease', 'vietnamese': 'U lympho Hodgkin'}, {
  'id': 'C81.0',
  'english': 'Hodgkin s disease: Lymphocytic predominance',
  'vietnamese': 'U lympho Hodgkin dạng nốt ưu thế lympho bào',
}, {
  'id': 'C81.1',
  'english': 'Hodgkin s disease: Nodular sclerosis',
  'vietnamese': 'U lympho Hodgkin (kinh điển) xơ nốt',
}, {
  'id': 'C81.2',
  'english': 'Hodgkin s disease: Mixed cellularity',
  'vietnamese': 'U lympho Hodgkin (kinh điển) hỗn hợp tế bào',
}, {
  'id': 'C81.3',
  'english': 'Hodgkin s disease: Lymphocytic depletion',
  'vietnamese': 'U lympho Hodgkin (kinh điển) giảm lympho bào',
}, {
  'id': 'C81.4',
  'english': 'Lymphocyte-rich (classical) Hodgkin lymphoma',
  'vietnamese': 'U lympho Hodgkin (kinh điển) giàu lympho bào',
}, {
  'id': 'C81.7',
  'english': 'Hodgkin s disease: Other Hodgkin s disease',
  'vietnamese': 'U lympho Hodgkin (kinh điển) khác',
}, {
  'id': 'C81.9',
  'english': 'Hodgkin s disease: Hodgkin s disease, unspecified',
  'vietnamese': 'U lympho Hodgkin, không đặc hiệu',
}, {
  'id': 'C82',
  'english': 'Follicular [nodular] non-Hodgkin s lymphoma',
  'vietnamese': 'U lympho dạng nang',
}, {
  'id': 'C82.0',
  'english': 'Non-Hodgkin s lymphoma: Small cleaved cell, follicular',
  'vietnamese': 'U lympho dạng nang độ 1',
}, {
  'id': 'C82.1',
  'english': 'Non-Hodgkin s lymphoma: Mixed small cleaved and large cell, follicular',
  'vietnamese': 'U lympho dạng nang độ II',
}, {
  'id': 'C82.2',
  'english': 'Non-Hodgkin s lymphoma: Large cell, follicular',
  'vietnamese': 'U lympho dạng nang độ III, không xác định',
}, {
  'id': 'C82.3',
  'english': 'Follicular lymphoma grade IIIa',
  'vietnamese': 'U lympho dạng nang độ IIIa',
}, {
  'id': 'C82.4',
  'english': 'Follicular lymphoma grade IIIb',
  'vietnamese': 'U lympho dạng nang độ IIIb',
}, {
  'id': 'C82.5',
  'english': 'Diffuse follicle centre lymphoma',
  'vietnamese': 'U lympho trung tâm nang tỏa rộng',
}, {
  'id': 'C82.6',
  'english': 'Cutaneous follicle centre lymphoma',
  'vietnamese': 'U lympho trung tâm nang da',
}, {
  'id': 'C82.7',
  'english': 'Other types of follicular non-Hodgkin s lymphoma',
  'vietnamese': 'Loại khác của u lympho dạng nang',
}, {
  'id': 'C82.9',
  'english': 'Follicular non-Hodgkin s lymphoma, unspecified',
  'vietnamese': 'U lympho dạng nang, không xác định',
}, {
  'id': 'C83',
  'english': 'Diffuse non-Hodgkin s lymphoma',
  'vietnamese': 'U Lympho dạng không phải nang',
}, {
  'id': 'C83.0',
  'english': 'Non-Hodgkin s lymphoma: Small cell (diffuse)',
  'vietnamese': 'U lympho không Hodgkin tế bào B nhỏ',
}, {
  'id': 'C83.1',
  'english': 'Non-Hodgkin s lymphoma: Small cleaved cell (diffuse)',
  'vietnamese': 'U lympho không Hodgkin tế bào Mantle',
}, {
  'id': 'C83.2',
  'english': 'Non-Hodgkin s lymphoma: Mixed small and large cell (diffuse)',
  'vietnamese': 'U lympho không Hodgkin hỗn hợp tế bào nhỏ-tế bào lớn (toả rộng)',
}, {
  'id': 'C83.3',
  'english': 'Non-Hodgkin s lymphoma: Large cell (diffuse)',
  'vietnamese': 'U lympho không Hodgkin tế bào B lớn tỏa rộng',
}, {
  'id': 'C83.4',
  'english': 'Non-Hodgkin s lymphoma: Immunoblastic (diffuse)',
  'vietnamese': 'U lympho không Hodgkin nguyên bào miễn dịch (toả rộng)',
}, {
  'id': 'C83.5',
  'english': 'Non-Hodgkin s lymphoma: Lymphoblastic (diffuse)',
  'vietnamese': 'U lympho không Hodgkin dạng nguyên bào lympho (tỏa rộng)',
}, {
  'id': 'C83.6',
  'english': 'Non-Hodgkin s lymphoma: Undifferentiated (diffuse)',
  'vietnamese': 'U lympho không Hodgkin không biệt hóa tỏa rộng',
}, {'id': 'C83.7', 'english': 'Burkitt s tumour', 'vietnamese': 'U lympho Burkitt'}, {
  'id': 'C83.8',
  'english': 'Other types of diffuse non-Hodgkin s lymphoma',
  'vietnamese': 'U lympho không phải dạng nang khác',
}, {
  'id': 'C83.9',
  'english': 'Diffuse non-Hodgkin s lymphoma, unspecified',
  'vietnamese': 'U lympho tỏa rộng không Hodgkin, không dạng nang, (tỏa rộng), không xác định',
}, {
  'id': 'C84',
  'english': 'Peripheral and cutaneous T-cell lymphomas',
  'vietnamese': 'Các loại U lympho tế bào T/NK trưởng thành',
}, {'id': 'C84.0', 'english': 'Mycosis fungoides', 'vietnamese': 'U sùi dạng nấm'}, {
  'id': 'C84.1',
  'english': 'Sézary s disease',
  'vietnamese': 'Bệnh Sézary',
}, {'id': 'C84.2', 'english': 'T-zone lymphoma', 'vietnamese': 'U lympho vùng T'}, {
  'id': 'C84.3',
  'english': 'Lymphoepithelioid lymphoma',
  'vietnamese': 'U lympho loại u lympho-biểu mô',
}, {
  'id': 'C84.4',
  'english': 'Peripheral T-cell lymphoma',
  'vietnamese': 'U lympho tế bào T ngoại vi, không phân loại ở nơi khác',
}, {
  'id': 'C84.5',
  'english': 'Other and unspecified T-cell lymphomas',
  'vietnamese': 'U lympho tế bào T/NK trưởng thành khác',
}, {
  'id': 'C84.6',
  'english': 'Anaplastic large cell lymphoma, ALK- positive',
  'vietnamese': 'U lympho tế bào lớn bất sản, ALK- dương tính',
}, {
  'id': 'C84.7',
  'english': 'Anaplastic large cell lymphoma, ALK- negative',
  'vietnamese': 'U lympho tế bào lớn bất sản, ALK âm tính',
}, {
  'id': 'C84.8',
  'english': 'Cutaneous T-cell lymphoma, unspecified',
  'vietnamese': 'U lympho tế bào T ở da, không đặc hiệu',
}, {
  'id': 'C84.9',
  'english': 'Mature T/NK-cell lymphoma, unspecified',
  'vietnamese': 'U lympho tế bào T/NK trưởng thành, không đặc hiệu',
}, {
  'id': 'C85',
  'english': 'Other and unspecified types of non-Hodgkin s lymphoma',
  'vietnamese': 'U lympho không Hodgkin, loại khác và không đặc hiệu',
}, {'id': 'C85.0', 'english': 'Lymphosarcoma', 'vietnamese': 'U lympho sarcom'}, {
  'id': 'C85.1',
  'english': 'B-cell lymphoma, unspecified',
  'vietnamese': 'U lympho tế bào B, không đặc hiệu',
}, {
  'id': 'C85.2',
  'english': 'Mediastinal (thymic) large B-cell lymphoma',
  'vietnamese': 'U lympho tế bào B lớn trung thất (tuyến ức)',
}, {
  'id': 'C85.7',
  'english': 'Other specified types of non-Hodgkin s lymphoma',
  'vietnamese': 'Loại xác định khác của u lympho không Hodgkin',
}, {
  'id': 'C85.9',
  'english': 'Non-Hodgkin s lymphoma, unspecified type',
  'vietnamese': 'U lympho không Hodgkin, loại không xác định',
}, {
  'id': 'C86',
  'english': 'Other and unspecified types of non-Hodgkin s lymphoma',
  'vietnamese': 'Loại xác định khác của u lympho tế bào T/NK',
}, {
  'id': 'C86.0',
  'english': 'Extranodal NK/T-cell lymphoma, nasal type',
  'vietnamese': 'U lympho tế bào NK/T ngoại nút, loại mũi',
}, {
  'id': 'C86.1',
  'english': 'Hepatosplenic T-cell lymphoma',
  'vietnamese': 'U lympho tế bào T gan- lách',
}, {
  'id': 'C86.2',
  'english': 'Enteropathy-type (intestinal) T-cell lymphoma',
  'vietnamese': 'U lympho tế bào T loại bệnh lý ruột',
}, {
  'id': 'C86.3',
  'english': 'Subcutaneous panniculitis-like T-cell lymphoma',
  'vietnamese': 'U lympho tế bào T giống viêm mô mỡ dưới da',
}, {
  'id': 'C86.4',
  'english': 'Blastic NK-cell lymphoma',
  'vietnamese': 'U lympho nguyên bào NK',
}, {
  'id': 'C86.5',
  'english': 'Angioimmunoblastic T-cell lymphoma',
  'vietnamese': 'U lympho tế bào T nguyên bào mạch- nguyên bào miễn dịch',
}, {
  'id': 'C86.6',
  'english': 'Primary cutaneous CD30-positive T-cell proliferations',
  'vietnamese': 'Tăng sinh tế bào T da nguyên phát CD-30 dương tính',
}, {
  'id': 'C88',
  'english': 'Malignant immunoproliferative diseases',
  'vietnamese': 'Bệnh tăng sinh miễn dịch ác tính',
}, {
  'id': 'C88.0',
  'english': 'Waldenström s macroglobulinaemia',
  'vietnamese': 'Macroglogulin máu của WaldenstrÖn',
}, {
  'id': 'C88.1',
  'english': 'Alpha heavy chain disease',
  'vietnamese': 'Bệnh chuỗi alpha nặng',
}, {
  'id': 'C88.2',
  'english': 'Gamma heavy chain disease',
  'vietnamese': 'Bệnh chuỗi gamma nặng',
}, {
  'id': 'C88.3',
  'english': 'Immunoproliferative small intestinal disease',
  'vietnamese': 'Bệnh tăng sinh miễn dịch ruột non',
}, {
  'id': 'C88.4',
  'english': 'Extranodal marginal zone B-cell lymphoma of mucosa-associated lymphoid tissue [MALT-lyphoma]',
  'vietnamese': 'U lympho tế bào B ngoài hạch của các mô lympho chế nhầy',
}, {
  'id': 'C88.7',
  'english': 'Other malignant immunoproliferative diseases',
  'vietnamese': 'Bệnh tăng sinh miễn dịch khác',
}, {
  'id': 'C88.9',
  'english': 'Malignant immunoproliferative disease, unspecified',
  'vietnamese': 'Bệnh tăng sinh miễn dịch không đặc hiệu',
}, {
  'id': 'C90',
  'english': 'Multiple myeloma and malignant plasma cell neoplasms',
  'vietnamese': 'Đa u tủy và các u tương bào',
}, {'id': 'C90.0', 'english': 'Multiple myeloma', 'vietnamese': 'Đa u tủy'}, {
  'id': 'C90.1',
  'english': 'Plasma cell leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tương bào',
}, {
  'id': 'C90.2',
  'english': 'Plasmacytoma, extramedullary',
  'vietnamese': 'U tương bào ngoài tủy',
}, {'id': 'C90.3', 'english': 'Solitary plasmacytoma', 'vietnamese': 'Bướu tương bào đơn độc'}, {
  'id': 'C91',
  'english': 'Lymphoid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng lympho',
}, {
  'id': 'C91.0',
  'english': 'Acute lymphoblastic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng nguyên bào lympho cấp (ALL)',
}, {
  'id': 'C91.1',
  'english': 'Chronic lymphocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng lympho mãn tính của loại tế bào B',
}, {
  'id': 'C91.2',
  'english': 'Subacute lymphocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng lympho bán cấp',
}, {
  'id': 'C91.3',
  'english': 'Prolymphocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tiền lympho của tế bào B',
}, {
  'id': 'C91.4',
  'english': 'Hairy-cell leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tế bào có lông',
}, {
  'id': 'C91.5',
  'english': 'Adult T-cell leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tế bào T trưởng thành',
}, {
  'id': 'C91.6',
  'english': 'Prolymphocytic leukaemia of T-cell type',
  'vietnamese': 'Bệnh bạch cầu dạng tiền lympho của tế bào T',
}, {
  'id': 'C91.7',
  'english': 'Other lymphoid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng lympho khác',
}, {
  'id': 'C91.8',
  'english': 'Mature B-cell leukaemia Burkitt-type',
  'vietnamese': 'Bệnh bạch cầu tế bào B trưởng thành loại Burkitt',
}, {
  'id': 'C91.9',
  'english': 'Lymphoid leukaemia, unspecified',
  'vietnamese': 'Bệnh bạch cầu dạng lympho, không đặc hiệu',
}, {'id': 'C92', 'english': 'Myeloid leukaemia', 'vietnamese': 'Bệnh bạch cầu tủy'}, {
  'id': 'C92.0',
  'english': 'Acute myeloid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy cấp',
}, {
  'id': 'C92.1',
  'english': 'Chronic myeloid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy mạn [CML], BCR/ABL- dương tính',
}, {
  'id': 'C92.2',
  'english': 'Subacute myeloid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy mãn tính không điển hình, BCR/ABL âm tính',
}, {'id': 'C92.3', 'english': 'Myeloid sarcoma', 'vietnamese': 'Ung thư mô liên kết dòng tủy'}, {
  'id': 'C92.4',
  'english': 'Acute promyelocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tiền tủy bào (PML)',
}, {
  'id': 'C92.5',
  'english': 'Acute myelomonocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy đơn nhân cấp',
}, {
  'id': 'C92.6',
  'english': 'Acute myeloid leukaemia with 11Q23- abnormality',
  'vietnamese': 'Bệnh bạch cầu dạng tủy cấp tính có bất thường 11Q23',
}, {
  'id': 'C92.7',
  'english': 'Other myeloid leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy khác',
}, {
  'id': 'C92.8',
  'english': 'Acute myeloid leukaemia with multilineage dysplasia',
  'vietnamese': 'Bệnh bạch cầu dạng tủy cấp tính có loạn sản đa dòng',
}, {
  'id': 'C92.9',
  'english': 'Myeloid leukaemia, unspecified',
  'vietnamese': 'Bệnh bạch cầu dạng tủy, không đặc hiệu',
}, {'id': 'C93', 'english': 'Monocytic leukaemia', 'vietnamese': 'Bệnh bạch cầu đơn nhân'}, {
  'id': 'C93.0',
  'english': 'Acute monocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu đơn nhân/nguyên bào đơn nhân cấp tính',
}, {
  'id': 'C93.1',
  'english': 'Chronic monocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dòng tủy đơn nhân mãn tính',
}, {
  'id': 'C93.2',
  'english': 'Subacute monocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu đơn nhân bán cấp',
}, {
  'id': 'C93.3',
  'english': 'Juvenile myelomonocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dòng tủy đơn nhân tuổi vị thành niên',
}, {
  'id': 'C93.7',
  'english': 'Other monocytic leukaemia',
  'vietnamese': 'Bệnh bạch cầu đơn nhân khác',
}, {
  'id': 'C93.9',
  'english': 'Monocytic leukaemia, unspecified',
  'vietnamese': 'Bệnh bạch cầu đơn nhân không đặc hiệu',
}, {
  'id': 'C94',
  'english': 'Other leukaemias of specified cell type',
  'vietnamese': 'Bệnh bạch cầu khác có loại tế bào xác định',
}, {
  'id': 'C94.0',
  'english': 'Acute erythraemia and erythroleukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tăng hồng cầu',
}, {'id': 'C94.1', 'english': 'Chronic erythraemia', 'vietnamese': 'Bệnh tăng hồng cầu mạn'}, {
  'id': 'C94.2',
  'english': 'Acute megakaryoblastic leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng tiền nguyên tiểu cầu cấp',
}, {
  'id': 'C94.3',
  'english': 'Mast cell leukaemia',
  'vietnamese': 'Bệnh bạch cầu dạng dưỡng bào',
}, {
  'id': 'C94.4',
  'english': 'Acute panmyelosis',
  'vietnamese': 'Bệnh toàn tủy cấp tính có xơ tủy',
}, {'id': 'C94.5', 'english': 'Acute myelofibrosis', 'vietnamese': 'Bệnh xơ tủy cấp'}, {
  'id': 'C94.6',
  'english': 'Myelodysplastic and myeloproliferative disease, not elsewhere classified',
  'vietnamese': 'Bệnh loạn sản tủy và tăng sinh tủy, chưa phân loại nơi khác',
}, {
  'id': 'C94.7',
  'english': 'Other specified leukaemias',
  'vietnamese': 'Bệnh bạch cầu xác định khác',
}, {
  'id': 'C95',
  'english': 'Leukaemia of unspecified cell type',
  'vietnamese': 'Bệnh bạch cầu có loại tế bào không xác định',
}, {
  'id': 'C95.0',
  'english': 'Acute leukaemia of unspecified cell type',
  'vietnamese': 'Bệnh bạch cầu cấp loại tế bào không xác định',
}, {
  'id': 'C95.1',
  'english': 'Chronic leukaemia of unspecified cell type',
  'vietnamese': 'Bệnh bạch cầu mạn loại tế bào không xác định',
}, {
  'id': 'C95.2',
  'english': 'Subacute leukaemia of unspecified cell type',
  'vietnamese': 'Bệnh bạch cầu bán cấp loại tế bào không xác định',
}, {
  'id': 'C95.7',
  'english': 'Other leukaemia of unspecified cell type',
  'vietnamese': 'Bệnh bạch cầu khác, loại tế bào không xác định',
}, {
  'id': 'C95.9',
  'english': 'Leukaemia, unspecified',
  'vietnamese': 'Bệnh bạch cầu không xác định',
}, {
  'id': 'C96',
  'english': 'Other and unspecified malignant neoplasms of lymphoid, haematopoietic and related tissue',
  'vietnamese': 'U ác khác và không đặc hiệu của hệ lympho, hệ tạo máu và mô liên quan',
}, {
  'id': 'C96.0',
  'english': 'Letterer-Siwe disease',
  'vietnamese': 'Bệnh tổ chức bào tế bào Langerhans đa ổ và đa hệ thống (rải rác) [bệnh Letterer-Siwe]',
}, {'id': 'C96.1', 'english': 'Malignant histiocytosis', 'vietnamese': 'Bệnh mô bào ác tính'}, {
  'id': 'C96.2',
  'english': 'Malignant mast cell tumour',
  'vietnamese': 'Bệnh dưỡng bào hệ thống xâm lấn',
}, {
  'id': 'C96.3',
  'english': 'True histiocytic lymphoma',
  'vietnamese': 'U lympho mô bào thực sự',
}, {
  'id': 'C96.4',
  'english': 'Sarcoma of dendritic cells (accessory cells)',
  'vietnamese': 'Sarcom tế bào đuôi gai (tế bào phụ)',
}, {
  'id': 'C96.5',
  'english': 'Multifocal and unisystemic Langerhans- cell histiocytosis',
  'vietnamese': 'Bệnh tổ chức bào tế bào Langerhans đa ổ và đơn hệ thống',
}, {
  'id': 'C96.6',
  'english': 'Unifocal Langerhans-cell histiocytosis',
  'vietnamese': 'Bệnh tổ chức bào Langerhans đơn ổ',
}, {
  'id': 'C96.7',
  'english': 'Other specified malignant neoplasms of lymphoid, haematopoietic and related tissue',
  'vietnamese': 'U ác tính khác của mô dạng lympho, hệ tạo máu và mô liên quan',
}, {'id': 'C96.8', 'english': 'Histiocytic sarcoma', 'vietnamese': 'Sarcom tổ chức bào'}, {
  'id': 'C96.9',
  'english': 'Malignant neoplasm of lymphoid, haematopoietic and related tissue, unspecified',
  'vietnamese': 'U ác tính không đặc hiệu của mô dạng lympho, hệ tạo máu và mô liên quan',
}, {
  'id': 'C97',
  'english': 'Malignant neoplasms of independent (primary) multiple sites',
  'vietnamese': 'U ác có nhiều vị trí độc lập (nguyên phát) nhiều chỗ',
}, {
  'id': 'D00',
  'english': 'Carcinoma in situ of oral cavity, oesophagus and stomach',
  'vietnamese': 'Ung thư biểu mô tại chỗ của khoang miệng, thực quản và dạ dày',
}, {
  'id': 'D00.0',
  'english': 'Carcinoma in situ: Lip, oral cavity and pharynx',
  'vietnamese': 'Ung thư biểu mô tại chỗ của của môi khoang miệng và hầu',
}, {
  'id': 'D00.1',
  'english': 'Carcinoma in situ: Oesophagus',
  'vietnamese': 'Ung thư biểu mô tại chỗ của của thực quản',
}, {'id': 'D00.2', 'english': 'Carcinoma in situ: Stomach', 'vietnamese': 'U ác của Dạ dày'}, {
  'id': 'D01',
  'english': 'Carcinoma in situ of other and unspecified digestive organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan tiêu hóa khác và không đặc hiệu',
}, {
  'id': 'D01.0',
  'english': 'Carcinoma in situ: Colon',
  'vietnamese': 'Ung thư biểu mô tại chỗ của đại tràng',
}, {
  'id': 'D01.1',
  'english': 'Carcinoma in situ: Rectosigmoid junction',
  'vietnamese': 'Ung thư biểu mô tại chỗ nơi nối trực tràng - đại tràng sigma',
}, {
  'id': 'D01.2',
  'english': 'Carcinoma in situ: Rectum',
  'vietnamese': 'Ung thư biểu mô tại chỗ của trực tràng',
}, {
  'id': 'D01.3',
  'english': 'Carcinoma in situ: Anus and anal canal',
  'vietnamese': 'Ung thư biểu mô tại chỗ của hậu môn và ống hậu môn',
}, {
  'id': 'D01.4',
  'english': 'Carcinoma in situ: Other and unspecified parts of intestine',
  'vietnamese': 'Ung thư biểu mô tại chỗ của phần khác và không đặc hiệu của ruột non',
}, {
  'id': 'D01.5',
  'english': 'Carcinoma in situ: Liver, gallbladder and bile ducts',
  'vietnamese': 'Ung thư biểu mô tại chỗ của gan, túi mật và đường dẫn mật',
}, {
  'id': 'D01.7',
  'english': 'Carcinoma in situ: Other specified digestive organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan tiêu hóa xác định khác',
}, {
  'id': 'D01.9',
  'english': 'Carcinoma in situ: Digestive organ, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan tiêu hóa không đặc hiệu',
}, {
  'id': 'D02',
  'english': 'Carcinoma in situ of middle ear and respiratory system',
  'vietnamese': 'Ung thư biểu mô tại chỗ của tai giữa và hệ hô hấp',
}, {
  'id': 'D02.0',
  'english': 'Carcinoma in situ: Larynx',
  'vietnamese': 'Ung thư biểu mô tại chỗ của thanh quản',
}, {
  'id': 'D02.1',
  'english': 'Carcinoma in situ: Trachea',
  'vietnamese': 'Ung thư biểu mô tại chỗ của khí quản',
}, {
  'id': 'D02.2',
  'english': 'Carcinoma in situ: Bronchus and lung',
  'vietnamese': 'Ung thư biểu mô tại chỗ của phế quản và phổi',
}, {
  'id': 'D02.3',
  'english': 'Carcinoma in situ: Other parts of respiratory system',
  'vietnamese': 'Ung thư biểu mô tại chỗ của phần khác và hệ hô hấp',
}, {
  'id': 'D02.4',
  'english': 'Carcinoma in situ: Respiratory system, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ của hệ hô hấp không đặc hiệu',
}, {'id': 'D03', 'english': 'Melanoma in situ', 'vietnamese': 'U hắc tố tại chỗ'}, {
  'id': 'D03.0',
  'english': 'Melanoma in situ of lip',
  'vietnamese': 'U hắc tố tại chỗ của môi',
}, {
  'id': 'D03.1',
  'english': 'Melanoma in situ of eyelid, including canthus',
  'vietnamese': 'U hắc tố tại chỗ của mi mắt, bao gồm góc mắt',
}, {
  'id': 'D03.2',
  'english': 'Melanoma in situ of ear and external auricular canal',
  'vietnamese': 'U hắc tố tại chỗ của tai và ống ngoài tai',
}, {
  'id': 'D03.3',
  'english': 'Melanoma in situ of other and unspecified parts of face',
  'vietnamese': 'U hắc tố tại chỗ của phần khác và phần không xác định của mặt',
}, {
  'id': 'D03.4',
  'english': 'Melanoma in situ of scalp and neck',
  'vietnamese': 'U hắc tố tại chỗ của da đầu và cổ',
}, {
  'id': 'D03.5',
  'english': 'Melanoma in situ of trunk',
  'vietnamese': 'U hắc tố tại chỗ của thân mình',
}, {
  'id': 'D03.6',
  'english': 'Melanoma in situ of upper limb, including shoulder',
  'vietnamese': 'U hắc tố tại chỗ của chi trên, bao gồm vai',
}, {
  'id': 'D03.7',
  'english': 'Melanoma in situ of lower limb, including hip',
  'vietnamese': 'U hắc tố tại chỗ của chi dưới, bao gồm vùng háng',
}, {
  'id': 'D03.8',
  'english': 'Melanoma in situ of other sites',
  'vietnamese': 'U hắc tố tại chỗ của vị trí khác',
}, {
  'id': 'D03.9',
  'english': 'Melanoma in situ, unspecified',
  'vietnamese': 'U hắc tố tại chỗ, không đặc hiệu',
}, {
  'id': 'D04',
  'english': 'Carcinoma in situ of skin',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da',
}, {
  'id': 'D04.0',
  'english': 'Carcinoma in situ: Skin of lip',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da môi',
}, {
  'id': 'D04.1',
  'english': 'Carcinoma in situ: Skin of eyelid, including canthus',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da mí mắt bao gồm góc mắt',
}, {
  'id': 'D04.2',
  'english': 'Carcinoma in situ: Skin of ear and external auricular canal',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da vùng tai và ống tai ngoài',
}, {
  'id': 'D04.3',
  'english': 'Carcinoma in situ: Skin of other and unspecified parts of face',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da của phần khác và không xác định của mặt',
}, {
  'id': 'D04.4',
  'english': 'Carcinoma in situ: Skin of scalp and neck',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da đầu và cổ',
}, {
  'id': 'D04.5',
  'english': 'Carcinoma in situ: Skin of trunk',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da thân mình',
}, {
  'id': 'D04.6',
  'english': 'Carcinoma in situ: Skin of upper limb, including shoulder',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da chi trên, bao gồm vai',
}, {
  'id': 'D04.7',
  'english': 'Carcinoma in situ: Skin of lower limb, including hip',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da chi dưới, bao gồm háng',
}, {
  'id': 'D04.8',
  'english': 'Carcinoma in situ: Skin of other sites',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da ở vị trí khác',
}, {
  'id': 'D04.9',
  'english': 'Carcinoma in situ: Skin, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ của da, không đặc hiệu',
}, {
  'id': 'D05',
  'english': 'Carcinoma in situ of breast',
  'vietnamese': 'Ung thư biểu mô tại chỗ của vú',
}, {
  'id': 'D05.0',
  'english': 'Lobular carcinoma in situ',
  'vietnamese': 'Ung thư biểu mô tiểu thuỳ tại chỗ',
}, {
  'id': 'D05.1',
  'english': 'Intraductal carcinoma in situ',
  'vietnamese': 'Ung thư biểu mô ống tuyến vú tại chỗ',
}, {
  'id': 'D05.7',
  'english': 'Other carcinoma in situ of breast',
  'vietnamese': 'Ung thư biểu mô ống tuyến khác tại chỗ của vú',
}, {
  'id': 'D05.9',
  'english': 'Carcinoma in situ of breast, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ của vú, không đặc hiệu',
}, {
  'id': 'D06',
  'english': 'Carcinoma in situ of cervix uteri',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cổ tử cung',
}, {
  'id': 'D06.0',
  'english': 'Carcinoma in situ: Endocervix',
  'vietnamese': 'Ung thư biểu mô tại chỗ của nội mạc',
}, {
  'id': 'D06.1',
  'english': 'Carcinoma in situ: Exocervix',
  'vietnamese': 'Ung thư biểu mô tại chỗ của ngoại mạc',
}, {
  'id': 'D06.7',
  'english': 'Carcinoma in situ: Other parts of cervix',
  'vietnamese': 'Ung thư biểu mô tại chỗ của phần khác của cổ tử cung',
}, {
  'id': 'D06.9',
  'english': 'Carcinoma in situ: Cervix, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cổ tử cung, không đặc hiệu',
}, {
  'id': 'D07',
  'english': 'Carcinoma in situ of other and unspecified genital organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan sinh dục khác và không đặc hiệu',
}, {
  'id': 'D07.0',
  'english': 'Carcinoma in situ: Endometrium',
  'vietnamese': 'Ung thư biểu mô tại chỗ của nội mạc tử cung',
}, {
  'id': 'D07.1',
  'english': 'Carcinoma in situ: Vulva',
  'vietnamese': 'Ung thư biểu mô tại chỗ của âm hộ',
}, {
  'id': 'D07.2',
  'english': 'Carcinoma in situ: Vagina',
  'vietnamese': 'Ung thư biểu mô tại chỗ của âm đạo',
}, {
  'id': 'D07.3',
  'english': 'Carcinoma in situ: Other and unspecified female genital organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan sinh dục nữ khác và không đặc hiệu',
}, {
  'id': 'D07.4',
  'english': 'Carcinoma in situ: Penis',
  'vietnamese': 'Ung thư biểu mô tại chỗ của dương vật',
}, {
  'id': 'D07.5',
  'english': 'Carcinoma in situ: Prostate',
  'vietnamese': 'Ung thư biểu mô tại chỗ của tuyến tiền liệt',
}, {
  'id': 'D07.6',
  'english': 'Carcinoma in situ: Other and unspecified male genital organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan sinh dục nam khác và không đặc hiệu',
}, {
  'id': 'D09',
  'english': 'Carcinoma in situ of other and unspecified sites',
  'vietnamese': 'Ung thư biểu mô tại chỗ ở vị trí khác và không xác định',
}, {
  'id': 'D09.0',
  'english': 'Carcinoma in situ: Bladder',
  'vietnamese': 'Ung thư biểu mô tại chỗ của bàng quang',
}, {
  'id': 'D09.1',
  'english': 'Carcinoma in situ: Other and unspecified urinary organs',
  'vietnamese': 'Ung thư biểu mô tại chỗ của cơ quan tiết niệu khác và không xác định',
}, {
  'id': 'D09.2',
  'english': 'Carcinoma in situ: Eye',
  'vietnamese': 'Ung thư biểu mô tại chỗ của mắt',
}, {
  'id': 'D09.3',
  'english': 'Carcinoma in situ: Thyroid and other endocrine glands',
  'vietnamese': 'Ung thư biểu mô tại chỗ của tuyến giáp và tuyến nội tiết khác',
}, {
  'id': 'D09.7',
  'english': 'Carcinoma in situ of other specified sites',
  'vietnamese': 'Ung thư biểu mô tại chỗ ở vị trí xác định khác',
}, {
  'id': 'D09.9',
  'english': 'Carcinoma in situ, unspecified',
  'vietnamese': 'Ung thư biểu mô tại chỗ, không xác định',
}, {
  'id': 'D10',
  'english': 'Benign neoplasm of mouth and pharynx',
  'vietnamese': 'U lành của miệng và hầu',
}, {'id': 'D10.0', 'english': 'Benign neoplasm: Lip', 'vietnamese': 'U lành của môi'}, {
  'id': 'D10.1',
  'english': 'Benign neoplasm: Tongue',
  'vietnamese': 'U lành của lưỡi',
}, {
  'id': 'D10.2',
  'english': 'Benign neoplasm: Floor of mouth',
  'vietnamese': 'U lành của sàn miệng',
}, {
  'id': 'D10.3',
  'english': 'Benign neoplasm: Other and unspecified parts of mouth',
  'vietnamese': 'U lành của phần khác và không xác định của miệng',
}, {'id': 'D10.4', 'english': 'Benign neoplasm: Tonsil', 'vietnamese': 'U lành của amiđan'}, {
  'id': 'D10.5',
  'english': 'Benign neoplasm: Other parts of oropharynx',
  'vietnamese': 'U lành của phần khác của hầu - khẩu',
}, {
  'id': 'D10.6',
  'english': 'Benign neoplasm: Nasopharynx',
  'vietnamese': 'U lành của hầu - mũi',
}, {
  'id': 'D10.7',
  'english': 'Benign neoplasm: Hypopharynx',
  'vietnamese': 'U lành của hạ hầu',
}, {
  'id': 'D10.9',
  'english': 'Benign neoplasm: Pharynx, unspecified',
  'vietnamese': 'U lành của hầu, không đặc hiệu',
}, {
  'id': 'D11',
  'english': 'Benign neoplasm of major salivary glands',
  'vietnamese': 'U lành của các tuyến nước bọt chính',
}, {
  'id': 'D11.0',
  'english': 'Benign neoplasm: Parotid gland',
  'vietnamese': 'U lành của tuyến mang tai',
}, {
  'id': 'D11.7',
  'english': 'Benign neoplasm: Other major salivary glands',
  'vietnamese': 'U lành của tuyến nước bọt chính khác',
}, {
  'id': 'D11.9',
  'english': 'Benign neoplasm: Major salivary gland, unspecified',
  'vietnamese': 'U lành của tuyến nước bọt chính không đặc hiệu',
}, {
  'id': 'D12',
  'english': 'Benign neoplasm of colon, rectum, anus and anal canal',
  'vietnamese': 'U lành của đại tràng, trực tràng, hậu môn và ống hậu môn',
}, {'id': 'D12.0', 'english': 'Benign neoplasm: Caecum', 'vietnamese': 'U lành của manh tràng'}, {
  'id': 'D12.1',
  'english': 'Benign neoplasm: Appendix',
  'vietnamese': 'U lành của ruột thừa',
}, {
  'id': 'D12.2',
  'english': 'Benign neoplasm: Ascending colon',
  'vietnamese': 'U lành của đại tràng lên',
}, {
  'id': 'D12.3',
  'english': 'Benign neoplasm: Transverse colon',
  'vietnamese': 'U lành của đại tràng ngang',
}, {
  'id': 'D12.4',
  'english': 'Benign neoplasm: Descending colon',
  'vietnamese': 'U lành của đại tràng xuống',
}, {
  'id': 'D12.5',
  'english': 'Benign neoplasm: Sigmoid colon',
  'vietnamese': 'U lành của đại tràng sigma',
}, {
  'id': 'D12.6',
  'english': 'Benign neoplasm: Colon, unspecified',
  'vietnamese': 'U lành của Đại tràng không đặc hiệu',
}, {
  'id': 'D12.7',
  'english': 'Benign neoplasm: Rectosigmoid junction',
  'vietnamese': 'U lành của nơi nối trực tràng sigma - trực tràng',
}, {'id': 'D12.8', 'english': 'Benign neoplasm: Rectum', 'vietnamese': 'U lành của trực tràng'}, {
  'id': 'D12.9',
  'english': 'Benign neoplasm: Anus and anal canal',
  'vietnamese': 'U lành của hậu môn và ống hậu môn',
}, {
  'id': 'D13',
  'english': 'Benign neoplasm of other and ill-defined parts of digestive system',
  'vietnamese': 'U lành của phần khác và không rõ ràng của hệ tiêu hóa',
}, {
  'id': 'D13.0',
  'english': 'Benign neoplasm: Oesophagus',
  'vietnamese': 'U lành của thực quản',
}, {
  'id': 'D13.1',
  'english': 'Benign neoplasm: Stomach',
  'vietnamese': 'U lành của dạ dày',
}, {'id': 'D13.2', 'english': 'Benign neoplasm: Duodenum', 'vietnamese': 'U lành của tá tràng'}, {
  'id': 'D13.3',
  'english': 'Benign neoplasm: Other and unspecified parts of small intestine',
  'vietnamese': 'U lành của phần khác và không xác định của ruột non',
}, {'id': 'D13.4', 'english': 'Benign neoplasm: Liver', 'vietnamese': 'U lành của gan'}, {
  'id': 'D13.5',
  'english': 'Benign neoplasm: Extrahepatic bile ducts',
  'vietnamese': 'U lành của đường mật ngoài gan',
}, {'id': 'D13.6', 'english': 'Benign neoplasm: Pancreas', 'vietnamese': 'U lành của tụy'}, {
  'id': 'D13.7',
  'english': 'Benign neoplasm: Endocrine pancreas',
  'vietnamese': 'U lành của tụy nội tiết',
}, {
  'id': 'D13.9',
  'english': 'Benign neoplasm: Ill-defined sites within the digestive system',
  'vietnamese': 'U lành của vị trí không rõ ràng trong hệ tiêu hóa',
}, {
  'id': 'D14',
  'english': 'Benign neoplasm of middle ear and respiratory system',
  'vietnamese': 'U lành tai giữa và hệ hô hấp',
}, {
  'id': 'D14.0',
  'english': 'Benign neoplasm: Middle ear, nasal cavity and accessory sinuses',
  'vietnamese': 'U lành của tai giữa, hốc mũi và các xoang phụ',
}, {'id': 'D14.1', 'english': 'Benign neoplasm: Larynx', 'vietnamese': 'U lành của thanh quản'}, {
  'id': 'D14.2',
  'english': 'Benign neoplasm: Trachea',
  'vietnamese': 'U lành của khí quản',
}, {
  'id': 'D14.3',
  'english': 'Benign neoplasm: Bronchus and lung',
  'vietnamese': 'U lành của phế quản và phổi',
}, {
  'id': 'D14.4',
  'english': 'Benign neoplasm: Respiratory system, unspecified',
  'vietnamese': 'U lành của hệ hô hấp, không đặc hiệu',
}, {
  'id': 'D15',
  'english': 'Benign neoplasm of other and unspecified intrathoracic organs',
  'vietnamese': 'U lành của cơ quan khác và không xác định trong lồng ngực',
}, {'id': 'D15.0', 'english': 'Benign neoplasm: Thymus', 'vietnamese': 'U lành của tuyến ức'}, {
  'id': 'D15.1',
  'english': 'Benign neoplasm: Heart',
  'vietnamese': 'U lành của tim',
}, {
  'id': 'D15.2',
  'english': 'Benign neoplasm: Mediastinum',
  'vietnamese': 'U lành của trung thất',
}, {
  'id': 'D15.7',
  'english': 'Benign neoplasm: Other specified intrathoracic organs',
  'vietnamese': 'U lành của cơ quan trong lồng ngực xác định khác',
}, {
  'id': 'D15.9',
  'english': 'Benign neoplasm: Intrathoracic organ, unspecified',
  'vietnamese': 'U lành của cơ quan trong lồng ngực không đặc hiệu',
}, {
  'id': 'D16',
  'english': 'Benign neoplasm of bone and articular cartilage',
  'vietnamese': 'U lành của xương và sụn khớp',
}, {
  'id': 'D16.0',
  'english': 'Benign neoplasm: Scapula and long bones of upper limb',
  'vietnamese': 'U lành của xương bả vai và xương dài của chi trên',
}, {
  'id': 'D16.1',
  'english': 'Benign neoplasm: Short bones of upper limb',
  'vietnamese': 'U lành của xương ngắn của chi trên',
}, {
  'id': 'D16.2',
  'english': 'Benign neoplasm: Long bones of lower limb',
  'vietnamese': 'U lành của xương dài của chi dưới',
}, {
  'id': 'D16.3',
  'english': 'Benign neoplasm: Short bones of lower limb',
  'vietnamese': 'U lành của xương ngắn của chi dưới',
}, {
  'id': 'D16.4',
  'english': 'Benign neoplasm: Bones of skull and face',
  'vietnamese': 'U lành của xương sọ và mặt',
}, {
  'id': 'D16.5',
  'english': 'Benign neoplasm: Lower jaw bone',
  'vietnamese': 'U lành của xương hàm dưới',
}, {'id': 'D16.6', 'english': 'Benign neoplasm: Vertebral column', 'vietnamese': 'Cột sống'}, {
  'id': 'D16.7',
  'english': 'Benign neoplasm: Ribs, sternum and clavicle',
  'vietnamese': 'U lành của xương sườn, xương ức và xương đòn',
}, {
  'id': 'D16.8',
  'english': 'Benign neoplasm: Pelvic bones, sacrum and coccyx',
  'vietnamese': 'U lành của xương chậu, xương thiêng và xương cụt',
}, {
  'id': 'D16.9',
  'english': 'Benign neoplasm: Bone and articular cartilage, unspecified',
  'vietnamese': 'U lành của xương và sụn khớp không đặc hiệu',
}, {'id': 'D17', 'english': 'Benign lipomatous neoplasm', 'vietnamese': 'U mỡ'}, {
  'id': 'D17.0',
  'english': 'Benign lipomatous neoplasm of skin and subcutaneous tissue of head, face and neck',
  'vietnamese': 'U mỡ lành tính của da và mô dưới da ở đầu, mặt và cổ',
}, {
  'id': 'D17.1',
  'english': 'Benign lipomatous neoplasm of skin and subcutaneous tissue of trunk',
  'vietnamese': 'U mỡ lành tính của da và mô dưới da ở thân hình',
}, {
  'id': 'D17.2',
  'english': 'Benign lipomatous neoplasm of skin and subcutaneous tissue of limbs',
  'vietnamese': 'U mỡ lành tính của da và mô dưới da ở các chi',
}, {
  'id': 'D17.3',
  'english': 'Benign lipomatous neoplasm of skin and subcutaneous tissue of other and unspecified sites',
  'vietnamese': 'U mỡ lành tính của da và mô dưới da ở vị trí khác và không đặc hiệu',
}, {
  'id': 'D17.4',
  'english': 'Benign lipomatous neoplasm of intrathoracic organs',
  'vietnamese': 'U mỡ lành tính của cơ quan trong lồng ngực',
}, {
  'id': 'D17.5',
  'english': 'Benign lipomatous neoplasm of intra-abdominal organs',
  'vietnamese': 'U mỡ lành tính của cơ quan trong ổ bụng',
}, {
  'id': 'D17.6',
  'english': 'Benign lipomatous neoplasm of spermatic cord',
  'vietnamese': 'U mỡ lành tính của thừng tinh',
}, {
  'id': 'D17.7',
  'english': 'Benign lipomatous neoplasm of other sites',
  'vietnamese': 'U mỡ lành tính của vị trí khác',
}, {
  'id': 'D17.9',
  'english': 'Benign lipomatous neoplasm, unspecified',
  'vietnamese': 'U mỡ lành tính không đặc hiệu',
}, {
  'id': 'D18',
  'english': 'Haemangioma and lymphangioma, any site',
  'vietnamese': 'U mạch máu và u hạch bạch huyết, vị trí bất kỳ',
}, {
  'id': 'D18.0',
  'english': 'Haemangioma, any site',
  'vietnamese': 'U mạch máu, vị trí bất kỳ',
}, {
  'id': 'D18.1',
  'english': 'Lymphangioma, any site',
  'vietnamese': 'U mạch bạch huyết, vị trí bất kỳ',
}, {
  'id': 'D19',
  'english': 'Benign neoplasm of mesothelial tissue',
  'vietnamese': 'U lành của trung mô',
}, {
  'id': 'D19.0',
  'english': 'Benign neoplasm: Mesothelial tissue of pleura',
  'vietnamese': 'U lành trung mô của màng phổi',
}, {
  'id': 'D19.1',
  'english': 'Benign neoplasm: Mesothelial tissue of peritoneum',
  'vietnamese': 'U lành trung mô của phúc mạc',
}, {
  'id': 'D19.7',
  'english': 'Benign neoplasm: Mesothelial tissue of other sites',
  'vietnamese': 'U lành trung mô của các vị trí khác',
}, {
  'id': 'D19.9',
  'english': 'Benign neoplasm: Mesothelial tissue, unspecified',
  'vietnamese': 'U lành trung mô không xác định',
}, {
  'id': 'D20',
  'english': 'Benign neoplasm of soft tissue of retroperitoneum and peritoneum',
  'vietnamese': 'U lành mô mềm sau phúc mạc và phúc mạc',
}, {
  'id': 'D20.0',
  'english': 'Benign neoplasm: Retroperitoneum',
  'vietnamese': 'U lành mô mềm sau phúc mạc',
}, {
  'id': 'D20.1',
  'english': 'Benign neoplasm: Peritoneum',
  'vietnamese': 'U lành mô mềm phúc mạc',
}, {
  'id': 'D21',
  'english': 'Other benign neoplasms of connective and other soft tissue',
  'vietnamese': 'U lành khác của mô liên kết và mô mềm khác',
}, {
  'id': 'D21.0',
  'english': 'Benign neoplasm: Connective and other soft tissue of head, face and neck',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của đầu, mặt và cổ',
}, {
  'id': 'D21.1',
  'english': 'Benign neoplasm: Connective and other soft tissue of upper limb, including shoulder',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của chi trên bao gồm vai',
}, {
  'id': 'D21.2',
  'english': 'Benign neoplasm: Connective and other soft tissue of lower limb, including hip',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của chi dưới, bào gồm háng',
}, {
  'id': 'D21.3',
  'english': 'Benign neoplasm: Connective and other soft tissue of thorax',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của lồng ngực',
}, {
  'id': 'D21.4',
  'english': 'Benign neoplasm: Connective and other soft tissue of abdomen',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của bụng',
}, {
  'id': 'D21.5',
  'english': 'Benign neoplasm: Connective and other soft tissue of pelvis',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của chậu',
}, {
  'id': 'D21.6',
  'english': 'Benign neoplasm: Connective and other soft tissue of trunk, unspecified',
  'vietnamese': 'U lành mô liên kết và mô mềm khác của thân mình, không đặc hiệu',
}, {
  'id': 'D21.9',
  'english': 'Benign neoplasm: Connective and other soft tissue, unspecified',
  'vietnamese': 'U lành mô liên kết và mô mềm khác, không đặc hiệu',
}, {'id': 'D22', 'english': 'Melanocytic naevi', 'vietnamese': 'Nốt ruồi'}, {
  'id': 'D22.0',
  'english': 'Melanocytic naevi of lip',
  'vietnamese': 'Nốt ruồi của môi',
}, {
  'id': 'D22.1',
  'english': 'Melanocytic naevi of eyelid, including canthus',
  'vietnamese': 'Nốt ruồi của khóe mắt bao gồm mí mắt',
}, {
  'id': 'D22.2',
  'english': 'Melanocytic naevi of ear and external auricular canal',
  'vietnamese': 'Nốt ruồi của tai và ống tai ngoài',
}, {
  'id': 'D22.3',
  'english': 'Melanocytic naevi of other and unspecified parts of face',
  'vietnamese': 'Nốt ruồi của phần khác và phần không xác định của mặt',
}, {
  'id': 'D22.4',
  'english': 'Melanocytic naevi of scalp and neck',
  'vietnamese': 'Nốt ruồi của da đầu và cổ',
}, {
  'id': 'D22.5',
  'english': 'Melanocytic naevi of trunk',
  'vietnamese': 'Nốt ruồi của thân mình',
}, {
  'id': 'D22.6',
  'english': 'Melanocytic naevi of upper limb, including shoulder',
  'vietnamese': 'Nốt ruồi của chi trên bao gồm vai',
}, {
  'id': 'D22.7',
  'english': 'Melanocytic naevi of lower limb, including hip',
  'vietnamese': 'Nốt ruồi của chi dưới bao gồm háng',
}, {
  'id': 'D22.9',
  'english': 'Melanocytic naevi, unspecified',
  'vietnamese': 'Nốt ruồi không có gì đặc hiệu',
}, {
  'id': 'D23',
  'english': 'Other benign neoplasms of skin',
  'vietnamese': 'U lành khác của da',
}, {
  'id': 'D23.0',
  'english': 'Benign neoplasm: Skin of lip',
  'vietnamese': 'U lành da của môi',
}, {
  'id': 'D23.1',
  'english': 'Benign neoplasm: Skin of eyelid, including canthus',
  'vietnamese': 'U lành da của mi mắt kể cả góc mắt',
}, {
  'id': 'D23.2',
  'english': 'Benign neoplasm: Skin of ear and external auricular canal',
  'vietnamese': 'U lành da tai và ống tai ngoài',
}, {
  'id': 'D23.3',
  'english': 'Benign neoplasm: Skin of other and unspecified parts of face',
  'vietnamese': 'U lành da các phần khác và những phần không đặc trưng',
}, {
  'id': 'D23.4',
  'english': 'Benign neoplasm: Skin of scalp and neck',
  'vietnamese': 'U lành da đầu và cổ',
}, {
  'id': 'D23.5',
  'english': 'Benign neoplasm: Skin of trunk',
  'vietnamese': 'U lành da thân mình',
}, {
  'id': 'D23.6',
  'english': 'Benign neoplasm: Skin of upper limb, including shoulder',
  'vietnamese': 'U lành da chi trên, bao gồm vai',
}, {
  'id': 'D23.7',
  'english': 'Benign neoplasm: Skin of lower limb, including hip',
  'vietnamese': 'U lành da chi dưới bao gồm háng',
}, {
  'id': 'D23.9',
  'english': 'Benign neoplasm: Skin, unspecified',
  'vietnamese': 'U lành của da, không đặc hiệu',
}, {'id': 'D24', 'english': 'Benign neoplasm of breast', 'vietnamese': 'U lành vú'}, {
  'id': 'D25',
  'english': 'Leiomyoma of uterus',
  'vietnamese': 'U cơ trơn tử cung',
}, {
  'id': 'D25.0',
  'english': 'Submucous leiomyoma of uterus',
  'vietnamese': 'U cơ trơn dưới niêm mạc tử cung',
}, {
  'id': 'D25.1',
  'english': 'Intramural leiomyoma of uterus',
  'vietnamese': 'U cơ trơn trong vách tử cung',
}, {
  'id': 'D25.2',
  'english': 'Subserosal leiomyoma of uterus',
  'vietnamese': 'U cơ trơn dưới thanh mạc tử cung',
}, {
  'id': 'D25.9',
  'english': 'Leiomyoma of uterus, unspecified',
  'vietnamese': 'U cơ trơn tử cung, không đặc hiệu',
}, {
  'id': 'D26',
  'english': 'Other benign neoplasms of uterus',
  'vietnamese': 'U lành khác của tử cung',
}, {
  'id': 'D26.0',
  'english': 'Other benign neoplasm: Cervix uteri',
  'vietnamese': 'U lành cổ tử cung',
}, {
  'id': 'D26.1',
  'english': 'Other benign neoplasm: Corpus uteri',
  'vietnamese': 'U lành thân tử cung',
}, {
  'id': 'D26.7',
  'english': 'Other benign neoplasm: Other parts of uterus',
  'vietnamese': 'U lành phần khác của tử cung',
}, {
  'id': 'D26.9',
  'english': 'Other benign neoplasm: Uterus, unspecified',
  'vietnamese': 'U lành tử cung, không đặc hiệu',
}, {'id': 'D27', 'english': 'Benign neoplasm of ovary', 'vietnamese': 'U lành buồng trứng'}, {
  'id': 'D28',
  'english': 'Benign neoplasm of other and unspecified female genital organs',
  'vietnamese': 'U lành của cơ quan sinh dục khác và không xác định',
}, {'id': 'D28.0', 'english': 'Benign neoplasm: Vulva', 'vietnamese': 'U lành âm hộ'}, {
  'id': 'D28.1',
  'english': 'Benign neoplasm: Vagina',
  'vietnamese': 'U lành âm đạo',
}, {
  'id': 'D28.2',
  'english': 'Benign neoplasm: Uterine tubes and ligaments',
  'vietnamese': 'U lành vòi tử cung và dây chằng',
}, {
  'id': 'D28.7',
  'english': 'Benign neoplasm: Other specified female genital organs',
  'vietnamese': 'U lành cơ quan sinh dục nữ xác định khác',
}, {
  'id': 'D28.9',
  'english': 'Benign neoplasm: Female genital organ, unspecified',
  'vietnamese': 'U lành cơ quan sinh dục nữ, không đặc hiệu',
}, {
  'id': 'D29',
  'english': 'Benign neoplasm of male genital organs',
  'vietnamese': 'U lành của cơ quan sinh dục nam',
}, {'id': 'D29.0', 'english': 'Benign neoplasm: Penis', 'vietnamese': 'U lành của Dương vật'}, {
  'id': 'D29.1',
  'english': 'Benign neoplasm: Prostate',
  'vietnamese': 'U lành của Tuyến tiền liệt',
}, {'id': 'D29.2', 'english': 'Benign neoplasm: Testis', 'vietnamese': 'U lành của Tinh hoàn'}, {
  'id': 'D29.3',
  'english': 'Benign neoplasm: Epididymis',
  'vietnamese': 'U lành của Mào tinh hoàn',
}, {'id': 'D29.4', 'english': 'Benign neoplasm: Scrotum', 'vietnamese': 'Bìu'}, {
  'id': 'D29.7',
  'english': 'Benign neoplasm: Other male genital organs',
  'vietnamese': 'Cơ quan sinh dục nam khác',
}, {
  'id': 'D29.9',
  'english': 'Benign neoplasm: Male genital organ, unspecified',
  'vietnamese': 'Cơ quan sinh dục nam, không đặc hiệu',
}, {
  'id': 'D30',
  'english': 'Benign neoplasm of urinary organs',
  'vietnamese': 'U lành của cơ quan tiết niệu',
}, {'id': 'D30.0', 'english': 'Benign neoplasm: Kidney', 'vietnamese': 'U lành của Thận'}, {
  'id': 'D30.1',
  'english': 'Benign neoplasm: Renal pelvis',
  'vietnamese': 'U lành của Bồn thận',
}, {'id': 'D30.2', 'english': 'Benign neoplasm: Ureter', 'vietnamese': 'U lành của Niệu quản'}, {
  'id': 'D30.3',
  'english': 'Benign neoplasm: Bladder',
  'vietnamese': 'U lành của Bàng quang',
}, {'id': 'D30.4', 'english': 'Benign neoplasm: Urethra', 'vietnamese': 'U lành của Niệu đạo'}, {
  'id': 'D30.7',
  'english': 'Benign neoplasm: Other urinary organs',
  'vietnamese': 'Cơ quan tiết niệu khác',
}, {
  'id': 'D30.9',
  'english': 'Benign neoplasm: Urinary organ, unspecified',
  'vietnamese': 'Cơ quan tiết niệu không đặc hiệu',
}, {
  'id': 'D31',
  'english': 'Benign neoplasm of eye and adnexa',
  'vietnamese': 'U lành của mắt và phần phụ',
}, {'id': 'D31.0', 'english': 'Benign neoplasm: Conjunctiva', 'vietnamese': 'U lành kết mạc'}, {
  'id': 'D31.1',
  'english': 'Benign neoplasm: Cornea',
  'vietnamese': 'U lành giác mạc',
}, {'id': 'D31.2', 'english': 'Benign neoplasm: Retina', 'vietnamese': 'U lành võng mạc'}, {
  'id': 'D31.3',
  'english': 'Benign neoplasm: Choroid',
  'vietnamese': 'U lành màng mạch mắt',
}, {'id': 'D31.4', 'english': 'Benign neoplasm: Ciliary body', 'vietnamese': 'U lành thể mi'}, {
  'id': 'D31.5',
  'english': 'Benign neoplasm: Lacrimal gland and duct',
  'vietnamese': 'U lành tuyến và ống lệ',
}, {
  'id': 'D31.6',
  'english': 'Benign neoplasm: Orbit, unspecified',
  'vietnamese': 'U lành hốc mắt không đặc hiệu',
}, {
  'id': 'D31.9',
  'english': 'Benign neoplasm: Eye, unspecified',
  'vietnamese': 'U lành của mắt, không đặc hiệu',
}, {'id': 'D32', 'english': 'Benign neoplasm of meninges', 'vietnamese': 'U lành của màng não'}, {
  'id': 'D32.0',
  'english': 'Benign neoplasm: Cerebral meninges',
  'vietnamese': 'U lành màng não',
}, {
  'id': 'D32.1',
  'english': 'Benign neoplasm: Spinal meninges',
  'vietnamese': 'U lành màng não tủy sống',
}, {
  'id': 'D32.9',
  'english': 'Benign neoplasm: Meninges, unspecified',
  'vietnamese': 'U lành màng não, không đặc hiệu',
}, {
  'id': 'D33',
  'english': 'Benign neoplasm of brain and other parts of central nervous system',
  'vietnamese': 'U lành của não và các phần khác của hệ thần kinh trung ương',
}, {
  'id': 'D33.0',
  'english': 'Benign neoplasm: Brain, supratentorial',
  'vietnamese': 'U lành của não trên lều',
}, {
  'id': 'D33.1',
  'english': 'Benign neoplasm: Brain, infratentorial',
  'vietnamese': 'U lành của não, lều dưới',
}, {
  'id': 'D33.2',
  'english': 'Benign neoplasm: Brain, unspecified',
  'vietnamese': 'U lành của não, không đặc hiệu',
}, {
  'id': 'D33.3',
  'english': 'Benign neoplasm: Cranial nerves',
  'vietnamese': 'U lành thần kinh sọ não',
}, {
  'id': 'D33.4',
  'english': 'Benign neoplasm: Spinal cord',
  'vietnamese': 'U lành của tủy sống',
}, {
  'id': 'D33.7',
  'english': 'Benign neoplasm: Other specified parts of central nervous system',
  'vietnamese': 'U lành của phần xác định khác của hệ thần kinh trung ương',
}, {
  'id': 'D33.9',
  'english': 'Benign neoplasm: Central nervous system, unspecified',
  'vietnamese': 'U lành của hệ thần kinh trung ương, không đặc hiệu',
}, {
  'id': 'D34',
  'english': 'Benign neoplasm of thyroid gland',
  'vietnamese': 'U lành của tuyến giáp',
}, {
  'id': 'D35',
  'english': 'Benign neoplasm of other and unspecified endocrine glands',
  'vietnamese': 'U lành của tuyến nội tiết khác và tuyến nội tiết không đặc hiệu',
}, {
  'id': 'D35.0',
  'english': 'Benign neoplasm: Adrenal gland',
  'vietnamese': 'U lành tuyến thượng thận',
}, {
  'id': 'D35.1',
  'english': 'Benign neoplasm: Parathyroid gland',
  'vietnamese': 'U lành tuyến cận giáp',
}, {
  'id': 'D35.2',
  'english': 'Benign neoplasm: Pituitary gland',
  'vietnamese': 'U lành tuyến yên',
}, {
  'id': 'D35.3',
  'english': 'Benign neoplasm: Craniopharyngeal duct',
  'vietnamese': 'U lành ống sọ hầu',
}, {
  'id': 'D35.4',
  'english': 'Benign neoplasm: Pineal gland',
  'vietnamese': 'U lành tuyến tùng',
}, {
  'id': 'D35.5',
  'english': 'Benign neoplasm: Carotid body',
  'vietnamese': 'U lành thể cảnh',
}, {
  'id': 'D35.6',
  'english': 'Benign neoplasm: Aortic body and other paraganglia',
  'vietnamese': 'U lành thể động mạch chủ và thể cận hạch khác',
}, {
  'id': 'D35.7',
  'english': 'Benign neoplasm: Other specified endocrine glands',
  'vietnamese': 'U lành tuyến nội tiết xác định khác',
}, {
  'id': 'D35.8',
  'english': 'Benign neoplasm: Pluriglandular involvement',
  'vietnamese': 'U lành liên quan nhiều tuyến nội tiết',
}, {
  'id': 'D35.9',
  'english': 'Benign neoplasm: Endocrine gland, unspecified',
  'vietnamese': 'U lành của tuyến nội tiết, không đặc hiệu',
}, {
  'id': 'D36',
  'english': 'Benign neoplasm of other and unspecified sites',
  'vietnamese': 'U lành có vị trí khác và không xác định',
}, {
  'id': 'D36.0',
  'english': 'Benign neoplasm: Lymph nodes',
  'vietnamese': 'U lành hạch lympho',
}, {
  'id': 'D36.1',
  'english': 'Benign neoplasm: Peripheral nerves and autonomic nervous system',
  'vietnamese': 'U lành thần kinh ngoại biên và hệ thần kinh tự động',
}, {
  'id': 'D36.7',
  'english': 'Benign neoplasm: Other specified sites',
  'vietnamese': 'U lành vị trí xác định khác',
}, {
  'id': 'D36.9',
  'english': 'Benign neoplasm: Benign neoplasm of unspecified site',
  'vietnamese': 'U lành của vị trí không xác định',
}, {
  'id': 'D37',
  'english': 'Neoplasm of uncertain or unknown behaviour of oral cavity and digestive organs',
  'vietnamese': 'U tân sinh không rõ hoặc không biết tính chất của khoang miệng và cơ quan tiêu hóa',
}, {
  'id': 'D37.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Lip, oral cavity and pharynx',
  'vietnamese': 'U tân sinh chưa rõ tính chất của môi, xoang miệng và hầu',
}, {
  'id': 'D37.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Stomach',
  'vietnamese': 'U tân sinh chưa rõ tính chất của dạ dày',
}, {
  'id': 'D37.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Small intestine',
  'vietnamese': 'U tân sinh chưa rõ tính chất của ruột non',
}, {
  'id': 'D37.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Appendix',
  'vietnamese': 'U tân sinh chưa rõ tính chất của ruột thừa',
}, {
  'id': 'D37.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Colon',
  'vietnamese': 'U tân sinh chưa rõ tính chất của đại tràng',
}, {
  'id': 'D37.5',
  'english': 'Neoplasm of uncertain or unknown behaviour: Rectum',
  'vietnamese': 'U tân sinh chưa rõ tính chất của trực tràng',
}, {
  'id': 'D37.6',
  'english': 'Neoplasm of uncertain or unknown behaviour: Liver, gallbladder and bile ducts',
  'vietnamese': 'U tân sinh chưa rõ tính chất của gan, túi mật và ống dẫn mật',
}, {
  'id': 'D37.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other digestive organs',
  'vietnamese': 'U tân sinh chưa rõ tính chất của cơ quan tiêu hóa khác',
}, {
  'id': 'D37.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Digestive organ, unspecified',
  'vietnamese': 'U tân sinh chưa rõ tính chất của cơ quan tiêu hóa không đặc hiệu',
}, {
  'id': 'D38',
  'english': 'Neoplasm of uncertain or unknown behaviour of middle ear and respiratory and intrathoracic organs',
  'vietnamese': 'U tân sinh không rõ hoặc không biết tính chất của tai giữa, cơ quan hô hấp và cơ quan trong lồng ngực',
}, {
  'id': 'D38.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Larynx',
  'vietnamese': 'U tân sinh chưa rõ tính chất của thanh quản',
}, {
  'id': 'D38.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Trachea, bronchus and lung',
  'vietnamese': 'U tân sinh chưa rõ tính chất của khí quản, phế quản và phổi',
}, {
  'id': 'D38.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Pleura',
  'vietnamese': 'U tân sinh chưa rõ tính chất màng phổi',
}, {
  'id': 'D38.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Mediastinum',
  'vietnamese': 'U tân sinh chưa rõ tính chất của trung thất',
}, {
  'id': 'D38.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Thymus',
  'vietnamese': 'U tân sinh chưa rõ tính chất của tuyến ức',
}, {
  'id': 'D38.5',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other respiratory organs',
  'vietnamese': 'U tân sinh chưa rõ tính chất của cơ quan hô hấp khác',
}, {
  'id': 'D38.6',
  'english': 'Neoplasm of uncertain or unknown behaviour: Respiratory organ, unspecified',
  'vietnamese': 'U tân sinh chưa rõ tính chất của cơ quan hô hấp không đặc hiệu',
}, {
  'id': 'D39',
  'english': 'Neoplasm of uncertain or unknown behaviour of female genital organs',
  'vietnamese': 'U không chắc chắn hoặc không biết tính chất của cơ quan sinh dục nữ',
}, {
  'id': 'D39.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Uterus',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của tử cung',
}, {
  'id': 'D39.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Ovary',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của buồng trứng',
}, {
  'id': 'D39.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Placenta',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của nhau (rau) thai',
}, {
  'id': 'D39.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other female genital organs',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của cơ quan sinh dục nữ khác',
}, {
  'id': 'D39.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Female genital organ, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của cơ quan sinh dục nữ không đặc hiệu',
}, {
  'id': 'D40',
  'english': 'Neoplasm of uncertain or unknown behaviour of male genital organs',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của cơ quan sinh dục nam',
}, {
  'id': 'D40.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Prostate',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tiền liệt tuyến',
}, {
  'id': 'D40.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Testis',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tinh hoàn',
}, {
  'id': 'D40.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other male genital organs',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Các cơ quan sinh dục nam khác',
}, {
  'id': 'D40.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Male genital organ, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Cơ quan sinh dục nam không đặc hiệu',
}, {
  'id': 'D41',
  'english': 'Neoplasm of uncertain or unknown behaviour of urinary organs',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của cơ quan tiết niệu',
}, {
  'id': 'D41.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Kidney',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Thận',
}, {
  'id': 'D41.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Renal pelvis',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Bể thận',
}, {
  'id': 'D41.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Ureter',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Niệu quản',
}, {
  'id': 'D41.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Urethra',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Niệu đạo',
}, {
  'id': 'D41.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Bladder',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Bàng quang',
}, {
  'id': 'D41.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other urinary organs',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Cơ quan tiết niệu khác',
}, {
  'id': 'D41.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Urinary organ, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Cơ quan tiết niệu không đặc hiệu',
}, {
  'id': 'D42',
  'english': 'Neoplasm of uncertain or unknown behaviour of meninges',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của màng não',
}, {
  'id': 'D42.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Cerebral meninges',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của màng não thuộc não',
}, {
  'id': 'D42.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Spinal meninges',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của màng não thuộc tủy sống',
}, {
  'id': 'D42.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Meninges, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của màng não, không đặc hiệu',
}, {
  'id': 'D43',
  'english': 'Neoplasm of uncertain or unknown behaviour of brain and central nervous system',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của màng não',
}, {
  'id': 'D43.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Brain, supratentorial',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Não, trên lều não',
}, {
  'id': 'D43.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Brain, infratentorial',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Não, dưới lều não',
}, {
  'id': 'D43.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Brain, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Não, không đặc hiệu',
}, {
  'id': 'D43.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Cranial nerves',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Thần kinh sọ',
}, {
  'id': 'D43.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Spinal cord',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của tủy sống',
}, {
  'id': 'D43.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other parts of central nervous system',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Phần khác của hệ thần kinh trung ương',
}, {
  'id': 'D43.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Central nervous system, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Hệ thần kinh trung ương, không đặc hiệu',
}, {
  'id': 'D44',
  'english': 'Neoplasm of uncertain or unknown behaviour of endocrine glands',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của tuyến nội tiết',
}, {
  'id': 'D44.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Thyroid gland',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tuyến giáp',
}, {
  'id': 'D44.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Adrenal gland',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tuyến thượng thận',
}, {
  'id': 'D44.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Parathyroid gland',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tuyến cận giáp',
}, {
  'id': 'D44.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Pituitary gland',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tuyến yên',
}, {
  'id': 'D44.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Craniopharyngeal duct',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Ống sọ-hầu',
}, {
  'id': 'D44.5',
  'english': 'Neoplasm of uncertain or unknown behaviour: Pineal gland',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Tuyến tùng',
}, {
  'id': 'D44.6',
  'english': 'Neoplasm of uncertain or unknown behaviour: Carotid body',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Thể cảnh',
}, {
  'id': 'D44.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Aortic body and other paraganglia',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất của Thể động mạch chủ và thể cận hạch khác',
}, {
  'id': 'D44.8',
  'english': 'Neoplasm of uncertain or unknown behaviour: Pluriglandular involvement',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất liên quan nhiều tuyến nội tiết',
}, {
  'id': 'D44.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Endocrine gland, unspecified',
  'vietnamese': 'U lành của tuyến nội tiết không đặc hiệu',
}, {
  'id': 'D45',
  'english': 'Polycythaemia vera',
  'vietnamese': 'Bệnh tăng hồng cầu vô căn',
}, {
  'id': 'D46',
  'english': 'Myelodysplastic syndromes',
  'vietnamese': 'Hội chứng loạn sản tủy xương',
}, {
  'id': 'D46.0',
  'english': 'Refractory anaemia without sideroblasts, so stated',
  'vietnamese': 'Thiếu máu kháng điều trị không có nguyên hồng cầu sắc hình vòng, được xác lập như vậy',
}, {
  'id': 'D46.1',
  'english': 'Refractory anaemia with sideroblasts',
  'vietnamese': 'Thiếu máu kháng điều trị có nguyên hồng cầu hình vòng',
}, {
  'id': 'D46.2',
  'english': 'Refractory anaemia with excess of blasts',
  'vietnamese': 'Thiếu máu đề kháng với quá nhiều nguyên bào',
}, {
  'id': 'D46.3',
  'english': 'Refractory anaemia with excess of blasts with transformation',
  'vietnamese': 'Thiếu máu đề kháng với quá nhiều nguyên bào chuyển dạng',
}, {
  'id': 'D46.4',
  'english': 'Refractory anaemia, unspecified',
  'vietnamese': 'Thiếu máu đề kháng, không đặc hiệu',
}, {
  'id': 'D46.5',
  'english': 'Refractory anaemia with multi-lineage dysplasia',
  'vietnamese': 'Thiếu máu kháng điều trị có loạn sản đa dòng',
}, {
  'id': 'D46.6',
  'english': 'Myelodysplastic syndrome with isolated del (5q) chromosomal abnormality',
  'vietnamese': 'Hội chứng loạn sản tủy có bất thường nhiễm sắc thể del (5q) đơn độc',
}, {
  'id': 'D46.7',
  'english': 'Other myelodysplastic syndromes',
  'vietnamese': 'Hội chứng loạn sản tủy xương khác',
}, {
  'id': 'D46.9',
  'english': 'Myelodysplastic syndrome, unspecified',
  'vietnamese': 'Hội chứng loạn tủy xương không đặc hiệu',
}, {
  'id': 'D47',
  'english': 'Other neoplasms of uncertain or unknown behaviour of lymphoid, haematopoietic and related tissue',
  'vietnamese': 'U tân sinh khác không chắc chắn hoặc không biết sinh chất của mô ulympho, mô tạo huyết và mô liên quan',
}, {
  'id': 'D47.0',
  'english': 'Histiocytic and mast cell tumours of uncertain and unknown behaviour',
  'vietnamese': 'Bệnh dưỡng bào hệ thống không triệu chứng',
}, {
  'id': 'D47.1',
  'english': 'Chronic myeloproliferative disease',
  'vietnamese': 'Bệnh bạch cầu dòng trung tính mãn tính',
}, {
  'id': 'D47.2',
  'english': 'Monoclonal gammopathy',
  'vietnamese': 'Bệnh lý gamma globulin đơn dòng chưa xác định ý nghĩa',
}, {
  'id': 'D47.3',
  'english': 'Essential (haemorrhagic) thrombocythaemia',
  'vietnamese': 'Bệnh tăng tiểu cầu (xuất huyết) vô căn',
}, {'id': 'D47.4', 'english': 'Osteomyelofibrosis', 'vietnamese': 'Bệnh xơ hóa tủy xương'}, {
  'id': 'D47.5',
  'english': 'Chronic eosinophilic leukaemia [hypereosinophilic syndrome]',
  'vietnamese': 'Bệnh bạch cầu dòng tế bào ưa acid mãn tính [hội chứng tăng bạch cầu ưa acid]',
}, {
  'id': 'D47.7',
  'english': 'Other specified neoplasms of uncertain or unknown behaviour of lymphoid, haematopoietic and related tissue',
  'vietnamese': 'U tân sinh xác định khác, không rõ hoặc không biết tính chất của mô lympho, mô tạo huyết và mô liên quan',
}, {
  'id': 'D47.9',
  'english': 'Neoplasm of uncertain or unknown behaviour of lymphoid, haematopoietic and related tissue, unspecified',
  'vietnamese': 'U tân sinh không đặc hiệu, không chắc chắn hoặc không biết rõ tính chất của mô lympho, mô tạo huyết và mô liên quan',
}, {
  'id': 'D48',
  'english': 'Neoplasm of uncertain or unknown behaviour of other and unspecified sites',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất có vị trí khác và không xác định',
}, {
  'id': 'D48.0',
  'english': 'Neoplasm of uncertain or unknown behaviour: Bone and articular cartilage',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của xương và sụn khớp',
}, {
  'id': 'D48.1',
  'english': 'Neoplasm of uncertain or unknown behaviour: Connective and other soft tissue',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của mô liên kết và mô mềm khác',
}, {
  'id': 'D48.2',
  'english': 'Neoplasm of uncertain or unknown behaviour: Peripheral nerves and autonomic nervous system',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của thần kinh ngoại biên và hệ thần kinh tự động',
}, {
  'id': 'D48.3',
  'english': 'Neoplasm of uncertain or unknown behaviour: Retroperitoneum',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của vùng sau phúc mạc',
}, {
  'id': 'D48.4',
  'english': 'Neoplasm of uncertain or unknown behaviour: Peritoneum',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của phúc mạc',
}, {
  'id': 'D48.5',
  'english': 'Neoplasm of uncertain or unknown behaviour: Skin',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của da',
}, {
  'id': 'D48.6',
  'english': 'Neoplasm of uncertain or unknown behaviour: Breast',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của vú',
}, {
  'id': 'D48.7',
  'english': 'Neoplasm of uncertain or unknown behaviour: Other specified sites',
  'vietnamese': 'U tân sinh không xác định, không chắc chắn hoặc không biết rõ tính chất của vị trí xác định khác',
}, {
  'id': 'D48.9',
  'english': 'Neoplasm of uncertain or unknown behaviour: Neoplasm of uncertain or unknown behaviour, unspecified',
  'vietnamese': 'U tân sinh không chắc chắn hoặc không biết tính chất, không đặc hiệu',
}, {'id': 'D50', 'english': 'Iron deficiency anaemia', 'vietnamese': 'Thiếu máu do thiếu sắt'}, {
  'id': 'D50.0',
  'english': 'Iron deficiency anaemia secondary to blood loss (chronic)',
  'vietnamese': 'Thiếu máu thiếu sắt thứ phát do mất máu (mãn tính)',
}, {
  'id': 'D50.1',
  'english': 'Sideropenic dysphagia',
  'vietnamese': 'Chứng khó nuốt do thiếu sắt',
}, {
  'id': 'D50.8',
  'english': 'Other iron deficiency anaemias',
  'vietnamese': 'Các thiếu máu thiếu sắt khác',
}, {
  'id': 'D50.9',
  'english': 'Iron deficiency anaemia, unspecified',
  'vietnamese': 'Thiếu máu thiếu sắt không đặc hiệu',
}, {
  'id': 'D51',
  'english': 'Vitamin B12 deficiency anaemia',
  'vietnamese': 'Thiếu máu do thiếu vitamin B12',
}, {
  'id': 'D51.0',
  'english': 'Vitamin B12 deficiency anaemia due to intrinsic factor deficiency',
  'vietnamese': 'Thiếu máu thiếu vitamin B12 do thiếu yếu tố nội',
}, {
  'id': 'D51.1',
  'english': 'Vitamin B12 deficiency anaemia due to selective vitamin B12 malabsorption with proteinuria',
  'vietnamese': 'Thiếu vitamin B12 do giảm hấp thu chọn lọc vitamin B12 kèm theo đái protein',
}, {
  'id': 'D51.2',
  'english': 'Transcobalamin II deficiency',
  'vietnamese': 'Thiếu Transcobalamin II',
}, {
  'id': 'D51.3',
  'english': 'Other dietary vitamin B12 deficiency anaemia',
  'vietnamese': 'Thiếu máu thiếu vitamin B12 khác do dinh dưỡng',
}, {
  'id': 'D51.8',
  'english': 'Other vitamin B12 deficiency anaemias',
  'vietnamese': 'Các thiếu máu thiếu vitamin B12 khác',
}, {
  'id': 'D51.9',
  'english': 'Vitamin B12 deficiency anaemia, unspecified',
  'vietnamese': 'Thiếu máu thiếu vitamin B12 không đặc hiệu',
}, {
  'id': 'D52',
  'english': 'Folate deficiency anaemia',
  'vietnamese': 'Thiếu máu do chế độ dinh dưỡng',
}, {
  'id': 'D52.0',
  'english': 'Dietary folate deficiency anaemia',
  'vietnamese': 'Thiếu máu thiếu folate do chế độ dinh dưỡng',
}, {
  'id': 'D52.1',
  'english': 'Drug-induced folate deficiency anaemia',
  'vietnamese': 'Thiếu máu thiếu folate do thuốc',
}, {
  'id': 'D52.8',
  'english': 'Other folate deficiency anaemias',
  'vietnamese': 'Các thiếu máu thiếu folate khác',
}, {
  'id': 'D52.9',
  'english': 'Folate deficiency anaemia, unspecified',
  'vietnamese': 'Thiếu máu thiếu folate không đặc hiệu',
}, {
  'id': 'D53',
  'english': 'Other nutritional anaemias',
  'vietnamese': 'Các thiếu máu dinh dưỡng khác',
}, {
  'id': 'D53.0',
  'english': 'Protein deficiency anaemia',
  'vietnamese': 'Thiếu máu do thiếu protein',
}, {
  'id': 'D53.1',
  'english': 'Other megaloblastic anaemias, not elsewhere classified',
  'vietnamese': 'Các thiếu máu nguyên hồng cầu khổng lồ khác, chưa được phân loại ở phần khác',
}, {'id': 'D53.2', 'english': 'Scorbutic anaemia', 'vietnamese': 'Thiếu máu thiếu vitamin C'}, {
  'id': 'D53.8',
  'english': 'Other specified nutritional anaemias',
  'vietnamese': 'Các thiếu máu dinh dưỡng đặc hiệu khác',
}, {
  'id': 'D53.9',
  'english': 'Nutritional anaemia, unspecified',
  'vietnamese': 'Thiếu máu dinh dưỡng không đặc hiệu',
}, {
  'id': 'D55',
  'english': 'Anaemia due to enzyme disorders',
  'vietnamese': 'Thiếu máu do rối loạn men',
}, {
  'id': 'D55.0',
  'english': 'Anaemia due to glucose-6-phosphate dehydrogenase [G6PD] deficiency',
  'vietnamese': 'Thiếu máu do thiếu men glucose-6-phosphate dehydrogenase',
}, {
  'id': 'D55.1',
  'english': 'Anaemia due to other disorders of glutathione metabolism',
  'vietnamese': 'Thiếu máu do các rối loạn chuyển hóa glutathione khác',
}, {
  'id': 'D55.2',
  'english': 'Anaemia due to disorders of glycolytic enzymes',
  'vietnamese': 'Thiếu máu do rối loạn các men phân giải glucose',
}, {
  'id': 'D55.3',
  'english': 'Anaemia due to disorders of nucleotide metabolism',
  'vietnamese': 'Thiếu máu do rối loạn chuyển hóa nucleotide',
}, {
  'id': 'D55.8',
  'english': 'Other anaemias due to enzyme disorders',
  'vietnamese': 'Các thiếu máu khác do rối loạn men',
}, {
  'id': 'D55.9',
  'english': 'Anaemia due to enzyme disorder, unspecified',
  'vietnamese': 'Thiếu máu do rối loạn men, không đặc hiệu',
}, {'id': 'D56', 'english': 'Thalassaemia', 'vietnamese': 'Bệnh Thalassaemia'}, {
  'id': 'D56.0',
  'english': 'Alpha thalassaemia',
  'vietnamese': 'Alpha thalassaemia',
}, {'id': 'D56.1', 'english': 'Beta thalassaemia', 'vietnamese': 'Beta thalassaemia'}, {
  'id': 'D56.2',
  'english': 'Delta-beta thalassaemia',
  'vietnamese': 'Delta-beta thalassaemia',
}, {'id': 'D56.3', 'english': 'Thalassaemia trait', 'vietnamese': 'Thalassaemia vết'}, {
  'id': 'D56.4',
  'english': 'Hereditary persistence of fetal haemoglobin [HPFH]',
  'vietnamese': 'Tồn tại di truyền huyết sắc tố bào thai [HPFH]',
}, {'id': 'D56.8', 'english': 'Other thalassaemias', 'vietnamese': 'Các thalassaemias khác'}, {
  'id': 'D56.9',
  'english': 'Thalassaemia, unspecified',
  'vietnamese': 'Thalassaemia không đặc hiệu',
}, {'id': 'D57', 'english': 'Sickle-cell disorders', 'vietnamese': 'Bệnh hồng cầu liềm'}, {
  'id': 'D57.0',
  'english': 'Sickle-cell anaemia with crisis',
  'vietnamese': 'Thiếu máu hồng cầu liềm có cơn tan máu',
}, {
  'id': 'D57.1',
  'english': 'Sickle-cell anaemia without crisis',
  'vietnamese': 'Thiếu máu hồng cầu liềm không có cơn tan máu',
}, {
  'id': 'D57.2',
  'english': 'Double heterozygous sickling disorders',
  'vietnamese': 'Di hợp tử kép hồng cầu hình liềm với các Hb bất thường khác',
}, {'id': 'D57.3', 'english': 'Sickle-cell trait', 'vietnamese': 'Hồng cầu liềm thể nhẹ'}, {
  'id': 'D57.8',
  'english': 'Other sickle-cell disorders',
  'vietnamese': 'Các rối loạn hồng cầu liềm khác',
}, {
  'id': 'D58',
  'english': 'Other hereditary haemolytic anaemias',
  'vietnamese': 'Các thiếu máu tan máu di truyền khác',
}, {
  'id': 'D58.0',
  'english': 'Hereditary spherocytosis',
  'vietnamese': 'Hồng cầu hình cầu di truyền',
}, {
  'id': 'D58.1',
  'english': 'Hereditary elliptocytosis',
  'vietnamese': 'Hồng cầu hình elip di truyền',
}, {
  'id': 'D58.2',
  'english': 'Other haemoglobinopathies',
  'vietnamese': 'Các bệnh huyết sắc tố khác',
}, {
  'id': 'D58.8',
  'english': 'Other specified hereditary haemolytic anaemias',
  'vietnamese': 'Các thiếu máu tan máu di truyền không đặc hiệu khác',
}, {
  'id': 'D58.9',
  'english': 'Hereditary haemolytic anaemia, unspecified',
  'vietnamese': 'Thiếu máu tan máu di truyền không đặc hiệu',
}, {
  'id': 'D59',
  'english': 'Acquired haemolytic anaemia',
  'vietnamese': 'Thiếu máu tan máu mắc phải',
}, {
  'id': 'D59.0',
  'english': 'Drug-induced autoimmune haemolytic anaemia',
  'vietnamese': 'Thiếu máu tan máu tự miễn dịch do thuốc',
}, {
  'id': 'D59.1',
  'english': 'Other autoimmune haemolytic anaemias',
  'vietnamese': 'Các thiếu máu tan máu tự miễn dịch khác',
}, {
  'id': 'D59.2',
  'english': 'Drug-induced nonautoimmune haemolytic anaemia',
  'vietnamese': 'Thiếu máu tan máu do thuốc không phải tự miễn dịch',
}, {
  'id': 'D59.3',
  'english': 'Haemolytic-uraemic syndrome',
  'vietnamese': 'Hội chứng tan máu urê máu cao',
}, {
  'id': 'D59.4',
  'english': 'Other nonautoimmune haemolytic anaemias',
  'vietnamese': 'Các thiếu máu tan máu không phải tự miễn dịch khác',
}, {
  'id': 'D59.5',
  'english': 'Paroxysmal nocturnal haemoglobinuria [Marchiafava-Micheli]',
  'vietnamese': 'Đái huyết sắc tố kịch phát ban đêm [Hội chứng Marchifava-Micheli]',
}, {
  'id': 'D59.6',
  'english': 'Haemoglobinuria due to haemolysis from other external causes',
  'vietnamese': 'Đái huyết sắc tố do tan máu từ những nguyên nhân bên ngoài khác',
}, {
  'id': 'D59.8',
  'english': 'Other acquired haemolytic anaemias',
  'vietnamese': 'Các thiếu máu tan máu mắc phải khác',
}, {
  'id': 'D59.9',
  'english': 'Acquired haemolytic anaemia, unspecified',
  'vietnamese': 'Thiếu máu tan máu mắc phải không đặc hiệu',
}, {
  'id': 'D60',
  'english': 'Acquired pure red cell aplasia [erythroblastopenia]',
  'vietnamese': 'Suy tủy xương một dòng hồng cầu mắc phải [giảm nguyên hồng cầu]',
}, {
  'id': 'D60.0',
  'english': 'Chronic acquired pure red cell aplasia',
  'vietnamese': 'Suy tủy xương một dòng hồng cầu mắc phải mãn tính',
}, {
  'id': 'D60.1',
  'english': 'Transient acquired pure red cell aplasia',
  'vietnamese': 'Suy tủy xương một dòng hồng cầu mắc phải thóang qua',
}, {
  'id': 'D60.8',
  'english': 'Other acquired pure red cell aplasias',
  'vietnamese': 'Suy tủy xương một dòng hồng cầu mắc phải khác',
}, {
  'id': 'D60.9',
  'english': 'Acquired pure red cell aplasia, unspecified',
  'vietnamese': 'Suy tủy xương một dòng hồng cầu mắc phải không đặc hiệu',
}, {
  'id': 'D61',
  'english': 'Other aplastic anaemias',
  'vietnamese': 'Các thể suy tủy xương khác',
}, {
  'id': 'D61.0',
  'english': 'Constitutional aplastic anaemia',
  'vietnamese': 'Suy tủy xương bẩm sinh',
}, {
  'id': 'D61.1',
  'english': 'Drug-induced aplastic anaemia',
  'vietnamese': 'Suy tủy xương do thuốc',
}, {
  'id': 'D61.2',
  'english': 'Aplastic anaemia due to other external agents',
  'vietnamese': 'Suy tủy xương do các nguyên nhân bên ngoài khác',
}, {
  'id': 'D61.3',
  'english': 'Idiopathic aplastic anaemia',
  'vietnamese': 'Suy tủy xương vô căn',
}, {
  'id': 'D61.8',
  'english': 'Other specified aplastic anaemias',
  'vietnamese': 'Suy tủy xương đặc hiệu khác',
}, {
  'id': 'D61.9',
  'english': 'Aplastic anaemia, unspecified',
  'vietnamese': 'Suy tủy xương không đặc hiệu khác',
}, {
  'id': 'D62',
  'english': 'Acute posthaemorrhagic',
  'vietnamese': 'Thiếu máu sau chảy máu cấp tính',
}, {
  'id': 'D63*',
  'english': 'Anaemia in chronic diseases classified elsewhere',
  'vietnamese': 'Thiếu máu trong các bệnh mãn tính đã được phân loại ở phần khác',
}, {
  'id': 'D63.0',
  'english': 'Anaemia in neoplastic disease (C00-D48†)',
  'vietnamese': 'Thiếu máu trong bệnh ác tính (C00-D48†)',
}, {
  'id': 'D63.8',
  'english': 'Anaemia in other chronic diseases classified elsewhere',
  'vietnamese': 'Thiếu máu trong các bệnh mãn tính đã được phân loại ở phần khác',
}, {'id': 'D64', 'english': 'Other anaemias', 'vietnamese': 'Các thiếu máu khác'}, {
  'id': 'D64.0',
  'english': 'Hereditary sideroblastic anaemia',
  'vietnamese': 'Thiếu máu nhược sắc có nguyên hồng cầu',
}, {
  'id': 'D64.1',
  'english': 'Secondary sideroblastic anaemia due to disease',
  'vietnamese': 'Thiếu máu nguyên hồng cầu sắt thứ phát do bệnh lý',
}, {
  'id': 'D64.2',
  'english': 'Secondary sideroblastic anaemia due to drugs and toxins',
  'vietnamese': 'Thiếu máu nguyên hồng cầu sắt thứ phát do thuốc và độc chất',
}, {
  'id': 'D64.3',
  'english': 'Other sideroblastic anaemias',
  'vietnamese': 'Các thiếu máu nguyên hồng cầu sắt khác',
}, {
  'id': 'D64.4',
  'english': 'Congenital dyserythropoietic anaemia',
  'vietnamese': 'Thiếu máu rối loạn sinh sản dòng hồng cầu bẩm sinh',
}, {
  'id': 'D64.8',
  'english': 'Other specified anaemias',
  'vietnamese': 'Các thiếu máu không đặc hiệu khác',
}, {'id': 'D64.9', 'english': 'Anaemia, unspecified', 'vietnamese': 'Thiếu máu không đặc hiệu'}, {
  'id': 'D65',
  'english': 'Disseminated intravascular coagulation (defibrination syndrome)',
  'vietnamese': 'Đông máu nội mạch rải rác (hội chứng tiêu fibrin)',
}, {
  'id': 'D66',
  'english': 'Hereditary factor VIII dificiency',
  'vietnamese': 'Thiếu yếu tố VIII di truyền',
}, {
  'id': 'D67',
  'english': 'Hereditary factor IX dificiency',
  'vietnamese': 'Thiếu yếu tố IX di truyền',
}, {
  'id': 'D68',
  'english': 'Other coagulation defects',
  'vietnamese': 'Các bất thường đông máu khác',
}, {'id': 'D68.0', 'english': 'Von Willebrand s disease', 'vietnamese': 'Bệnh Von Willebrand'}, {
  'id': 'D68.1',
  'english': 'Hereditary factor XI deficiency',
  'vietnamese': 'Thiếu yếu tố XI di truyền',
}, {
  'id': 'D68.2',
  'english': 'Hereditary deficiency of other clotting factors',
  'vietnamese': 'Thiếu các yếu tố đông máu khác do di truyền',
}, {
  'id': 'D68.3',
  'english': 'Haemorrhagic disorder due to circulating anticoagulants',
  'vietnamese': 'Xuất huyết trong khi sử dụng dài ngày các chất chống đông máu',
}, {
  'id': 'D68.4',
  'english': 'Acquired coagulation factor deficiency',
  'vietnamese': 'Thiếu hụt yếu tố đông máu mắc phải',
}, {
  'id': 'D68.5',
  'english': 'Primary Thrombophilia',
  'vietnamese': 'Bệnh tăng đông máu nguyên phát',
}, {'id': 'D68.6', 'english': 'Other Thrombophilia', 'vietnamese': 'Bệnh tăng đông máu khác'}, {
  'id': 'D68.8',
  'english': 'Other specified coagulation defects',
  'vietnamese': 'Các rối loạn đông máu đặc biệt khác',
}, {
  'id': 'D68.9',
  'english': 'Coagulation defect, unspecified',
  'vietnamese': 'Rối loạn đông máu không đặc hiệu',
}, {
  'id': 'D69',
  'english': 'Purpura and other haemorrhagic conditions',
  'vietnamese': 'Ban xuất huyết và các tình trạng xuất huyết khác',
}, {'id': 'D69.0', 'english': 'Allergic purpura', 'vietnamese': 'Ban xuất huyết dị ứng'}, {
  'id': 'D69.1',
  'english': 'Qualitative platelet defects',
  'vietnamese': 'Bất thường chất lượng tiểu cầu',
}, {
  'id': 'D69.2',
  'english': 'Other nonthrombocytopenic purpura',
  'vietnamese': 'Ban xuất huyết không giảm tiểu cầu khác',
}, {
  'id': 'D69.3',
  'english': 'Idiopathic thrombocytopenic purpura',
  'vietnamese': 'Ban xuất huyết giảm tiểu cầu vô căn',
}, {
  'id': 'D69.4',
  'english': 'Other primary thrombocytopenia',
  'vietnamese': 'Giảm tiểu cầu tiên phát khác',
}, {
  'id': 'D69.5',
  'english': 'Secondary thrombocytopenia',
  'vietnamese': 'Giảm tiểu cầu thứ phát',
}, {
  'id': 'D69.6',
  'english': 'Thrombocytopenia, unspecified',
  'vietnamese': 'Giảm tiểu cầu không đặc hiệu',
}, {
  'id': 'D69.8',
  'english': 'Other specified haemorrhagic conditions',
  'vietnamese': 'Các tình trạng xuất huyết đặc hiệu khác',
}, {
  'id': 'D69.9',
  'english': 'Haemorrhagic condition, unspecified',
  'vietnamese': 'Tình trạng xuất huyết không đặc hiệu',
}, {
  'id': 'D70',
  'english': 'Agranulocytosis',
  'vietnamese': 'Tình trạng không có bạch cầu hạt',
}, {
  'id': 'D71',
  'english': 'Funtional disorders of polymorphonuclear neutrophils',
  'vietnamese': 'Rối loạn chức năng bạch cầu hạt trung tính',
}, {
  'id': 'D72',
  'english': 'Other disorders of white blood cells',
  'vietnamese': 'Các rối loạn khác của bạch cầu',
}, {
  'id': 'D72.0',
  'english': 'Genetic anomalies of leukocytes',
  'vietnamese': 'Bất thường di truyền của bạch cầu',
}, {'id': 'D72.1', 'english': 'Eosinophilia', 'vietnamese': 'Tình trạng tăng bạch cầu ưa acid'}, {
  'id': 'D72.8',
  'english': 'Other specified disorders of white blood cells',
  'vietnamese': 'Các rối loạn đặc hiệu khác của bạch cầu',
}, {
  'id': 'D72.9',
  'english': 'Disorder of white blood cells, unspecified',
  'vietnamese': 'Rối loạn bạch cầu không đặc hiệu',
}, {'id': 'D73', 'english': 'Diseases of spleen', 'vietnamese': 'Bệnh lý lách'}, {
  'id': 'D73.0',
  'english': 'Hyposplenism',
  'vietnamese': 'Thiểu năng lách',
}, {'id': 'D73.1', 'english': 'Hypersplenism', 'vietnamese': 'Cường lách'}, {
  'id': 'D73.2',
  'english': 'Chronic congestive splenomegaly',
  'vietnamese': 'Lách to sung huyết mãn tính',
}, {'id': 'D73.3', 'english': 'Abscess of spleen', 'vietnamese': 'Áp xe lách'}, {
  'id': 'D73.4',
  'english': 'Cyst of spleen',
  'vietnamese': 'Nang lách',
}, {'id': 'D73.5', 'english': 'Infarction of spleen', 'vietnamese': 'Nhồi máu lách'}, {
  'id': 'D73.8',
  'english': 'Other diseases of spleen',
  'vietnamese': 'Các bệnh khác của lách',
}, {
  'id': 'D73.9',
  'english': 'Disease of spleen, unspecified',
  'vietnamese': 'Bệnh lách không đặc hiệu',
}, {'id': 'D74', 'english': 'Methaemoglobinaemia', 'vietnamese': 'methemoglobin máu'}, {
  'id': 'D74.0',
  'english': 'Congenital methaemoglobinaemia',
  'vietnamese': 'methemoglobin máu bẩm sinh',
}, {
  'id': 'D74.8',
  'english': 'Other methaemoglobinaemias',
  'vietnamese': 'methaemoglobin máu khác',
}, {
  'id': 'D74.9',
  'english': 'Methaemoglobinaemia, unspecified',
  'vietnamese': 'Methaemoglobin máu không đặc hiệu',
}, {
  'id': 'D75',
  'english': 'Other diseases of blood and blood-forming organs',
  'vietnamese': 'Các bệnh máu và cơ quan tạo máu khác',
}, {
  'id': 'D75.0',
  'english': 'Familial erythrocytosis',
  'vietnamese': 'Tăng hồng cầu gia đình',
}, {
  'id': 'D75.1',
  'english': 'Secondary polycythaemia',
  'vietnamese': 'Bệnh tăng hồng cầu thứ phát',
}, {
  'id': 'D75.2',
  'english': 'Essential thrombocytosis',
  'vietnamese': 'Tăng tiểu cầu tiền phát',
}, {
  'id': 'D75.8',
  'english': 'Other specified diseases of blood and blood-forming organs',
  'vietnamese': 'Các bệnh đặc hiệu khác của máu và cơ quan tạo máu',
}, {
  'id': 'D75.9',
  'english': 'Disease of blood and blood-forming organs, unspecified',
  'vietnamese': 'Bệnh của máu và cơ quan tạo máu không đặc hiệu',
}, {
  'id': 'D76',
  'english': 'Certain diseases involving lymphoreticular tissue and reticulohistiocytic system',
  'vietnamese': 'Các bệnh của tổ chức lympho- liên võng và - (tổ chức bào) mô bào- liên võng xác định khác',
}, {
  'id': 'D76.0',
  'english': 'Langerhans cell histiocytosis, not elsewhere classified',
  'vietnamese': 'Bệnh tổ chức bào tế bào langerhans, chưa được phân loại ở phần khác',
}, {
  'id': 'D76.1',
  'english': 'Haemophagocytic lymphohistiocytosis',
  'vietnamese': 'Bệnh tổ chức bào thực bào đơn nhân',
}, {
  'id': 'D76.2',
  'english': 'Haemophagocytic syndrome, infection-associated',
  'vietnamese': 'Hội chứng thực bào tế bào máu liên quan đến nhiễm trùng',
}, {
  'id': 'D76.3',
  'english': 'Other histiocytosis syndromes',
  'vietnamese': 'Các hội chứng mô bào khác',
}, {
  'id': 'D77*',
  'english': 'Other disorders of blood and blood-forming organs in disease classified elsewhere',
  'vietnamese': 'Rối loạn của máu và cơ quan tạo máu trong các bệnh khác',
}, {
  'id': 'D80',
  'english': 'Immunodeficiency with predominantly antibody defects',
  'vietnamese': 'Thiếu hụt miễn dịch chủ yếu do bất thường kháng thể',
}, {
  'id': 'D80.0',
  'english': 'Hereditary hypogammaglobulinaemia',
  'vietnamese': 'Giảm gammaglobulin máu di truyền',
}, {
  'id': 'D80.1',
  'english': 'Nonfamilial hypogammaglobulinaemia',
  'vietnamese': 'Giảm gammaglobulin máu không có yếu tố gia đình',
}, {
  'id': 'D80.2',
  'english': 'Selective deficiency of immunoglobulin A [IgA]',
  'vietnamese': 'Thiếu hụt immunoglobulin A IgA chọn lọc',
}, {
  'id': 'D80.3',
  'english': 'Selective deficiency of immunoglobulin G [IgG] subclasses',
  'vietnamese': 'Thiếu hụt immunoglobulin A IgA chọn lọc',
}, {
  'id': 'D80.4',
  'english': 'Selective deficiency of immunoglobulin M [IgM]',
  'vietnamese': 'Thiếu hụt immunoglobulin M [IgM] chọn lọc',
}, {
  'id': 'D80.5',
  'english': 'Immunodeficiency with increased immunoglobulin M [IgM]',
  'vietnamese': 'Suy giảm miễn dịch có tăng immunoglobulin M [IgM]',
}, {
  'id': 'D80.6',
  'english': 'Antibody deficiency with near-normal immunoglobulins or with hyperimmunoglobulinaemia',
  'vietnamese': 'Thiếu hụt kháng thể với hàm lượng các immunoglobulin giảm ít hoặc tăng ịmmuglobulin máu',
}, {
  'id': 'D80.7',
  'english': 'Transient hypogammaglobulinaemia of infancy',
  'vietnamese': 'Thiếu hụt gammaglobulin máu thóang qua ở trẻ nhỏ',
}, {
  'id': 'D80.8',
  'english': 'Other immunodeficiencies with predominantly antibody defects',
  'vietnamese': 'Suy giảm miễn dịch khác do thiếu kháng thể là chủ yếu',
}, {
  'id': 'D80.9',
  'english': 'Immunodeficiency with predominantly antibody defects, unspecified',
  'vietnamese': 'Thiếu hụt miễn dịch do thiếu kháng thể là chủ yếu, không xác',
}, {
  'id': 'D81',
  'english': 'Combined immunodeficiencies',
  'vietnamese': 'Suy giảm miễn dịch kết hợp',
}, {
  'id': 'D81.0',
  'english': 'Severe combined immunodeficiency [SCID] with reticular dysgenesis',
  'vietnamese': 'Suy giảm miễn dịch hỗn hợp nặng [SCID] với loạn sinh liên võng',
}, {
  'id': 'D81.1',
  'english': 'Severe combined immunodeficiency [SCID] with low T- and B-cell numbers',
  'vietnamese': 'Suy giảm miễn dịch hỗn hợp nặng [SCID] với giảm số lượng lympho T và B',
}, {
  'id': 'D81.2',
  'english': 'Severe combined immunodeficiency [SCID] with low or normal B-cell numbers',
  'vietnamese': 'Thiếu hụt miễn dịch kết hợp nguy kịch [SCID] với số lượng lympho B thấp hoặc bình thường',
}, {
  'id': 'D81.3',
  'english': 'Adenosine deaminase [ADA] deficiency',
  'vietnamese': 'Thiếu hụt enzim adenosine deaminase [ADA]',
}, {'id': 'D81.4', 'english': 'Nezelof s syndrome', 'vietnamese': 'Hội chứng Nezelof'}, {
  'id': 'D81.5',
  'english': 'Purine nucleoside phosphorylase [PNP] deficiency',
  'vietnamese': 'Thiếu hụt emzim purine nucleoside phosphorylase [PNP]',
}, {
  'id': 'D81.6',
  'english': 'Major histocompatibility complex class I deficiency',
  'vietnamese': 'Thiếu hụt phức hợp hoà hợp tổ chức lớp I (MHC I)',
}, {
  'id': 'D81.7',
  'english': 'Major histocompatibility complex class II deficiency',
  'vietnamese': 'Thiếu hụt phức hợp hoà hợp tổ chức lớp II (MHC II)',
}, {
  'id': 'D81.8',
  'english': 'Other combined immunodeficiencies',
  'vietnamese': 'Các suy giảm miễn dịch kết hợp khác',
}, {
  'id': 'D81.9',
  'english': 'Combined immunodeficiency, unspecified',
  'vietnamese': 'Suy giảm miễn dịch hỗn hợp không đặc hiệu',
}, {
  'id': 'D82',
  'english': 'Immunodeficiency associated with other major defects',
  'vietnamese': 'Suy giảm miễn dịch liên quan đến các bất thường nặng khác',
}, {
  'id': 'D82.0',
  'english': 'Wiskott-Aldrich syndrome',
  'vietnamese': 'Hội chứng Wiskott-Aldrich',
}, {'id': 'D82.1', 'english': 'Di George s syndrome', 'vietnamese': 'Hội chứng Di George s'}, {
  'id': 'D82.2',
  'english': 'Immunodeficiency with short-limbed stature',
  'vietnamese': 'Suy giảm miễn dịch kèm chứng ngắn chi',
}, {
  'id': 'D82.3',
  'english': 'Immunodeficiency following hereditary defective response to Epstein-Barr virus',
  'vietnamese': 'Suy giảm miễn dịch sau đáp ứng với virus Epstein-Barr bị khuyết thiếu mang tính di truyền',
}, {
  'id': 'D82.4',
  'english': 'Hyperimmunoglobulin E [IgE] syndrome',
  'vietnamese': 'Hội chứng tăng immunoglobulin E [IgE]',
}, {
  'id': 'D82.8',
  'english': 'Immunodeficiency associated with other specified major defects',
  'vietnamese': 'Suy giảm miễn dịch liên quan với các bất thường lớn hoặc không xác định',
}, {
  'id': 'D82.9',
  'english': 'Immunodeficiency associated with major defect, unspecified',
  'vietnamese': 'Suy giảm miễn dịch khiếm khuyết chủ yếu, không đặc hiệu',
}, {
  'id': 'D83',
  'english': 'Common variable immunodeficiency',
  'vietnamese': 'Một số các biến thể khác của suy giảm miễn dịch',
}, {
  'id': 'D83.0',
  'english': 'Common variable immunodeficiency with predominant abnormalities of B-cell numbers and function',
  'vietnamese': 'Suy giảm miễn dịch một số biến thể do bất thường về số lượng và chức năng lympho B',
}, {
  'id': 'D83.1',
  'english': 'Common variable immunodeficiency with predominant immunoregulatory T-cell disorders',
  'vietnamese': 'Suy giảm miễn dịch biến thể chủ yếu do rối loạn điều hoà miễn dịch của lympho T',
}, {
  'id': 'D83.2',
  'english': 'Common variable immunodeficiency with autoantibodies to B- or T-cells',
  'vietnamese': 'Suy giảm miễn dịch biến thể phổ biến với tự kháng thể chống lympho B hoặc T',
}, {
  'id': 'D83.8',
  'english': 'Other common variable immunodeficiencies',
  'vietnamese': 'Các suy giảm miễn dịch biến thiên phổ biến khác',
}, {
  'id': 'D83.9',
  'english': 'Common variable immunodeficiency, unspecified',
  'vietnamese': 'Suy giảm miễn dịch biến thiên phổ biến không đặc hiệu',
}, {
  'id': 'D84',
  'english': 'Other immunodeficiencies',
  'vietnamese': 'Các suy giảm miễn dịch khác',
}, {
  'id': 'D84.0',
  'english': 'Lymphocyte function antigen-1 [LFA-1] defect',
  'vietnamese': 'Bất thường kháng nguyên chức năng 1 của lymphocyte [LFA-1]',
}, {
  'id': 'D84.1',
  'english': 'Defects in the complement system',
  'vietnamese': 'Các bất thường của hệ thống bổ thể',
}, {
  'id': 'D84.8',
  'english': 'Other specified immunodeficiencies',
  'vietnamese': 'Các suy giảm miễn dịch đặc hiệu khác',
}, {
  'id': 'D84.9',
  'english': 'Immunodeficiency, unspecified',
  'vietnamese': 'Suy giảm miễn dịch không đặc hiệu',
}, {'id': 'D86', 'english': 'Sarcoidosis', 'vietnamese': 'Bệnh sarcoid'}, {
  'id': 'D86.0',
  'english': 'Sarcoidosis of lung',
  'vietnamese': 'Bệnh sarcoid phổi',
}, {
  'id': 'D86.1',
  'english': 'Sarcoidosis of lymph nodes',
  'vietnamese': 'Bệnh sarcoid hạch bạch huyết',
}, {
  'id': 'D86.2',
  'english': 'Sarcoidosis of lung with sarcoidosis of lymph nodes',
  'vietnamese': 'Bệnh sarcoid phổi và hạch bạch huyết',
}, {'id': 'D86.3', 'english': 'Sarcoidosis of skin', 'vietnamese': 'Bệnh sarcoid da'}, {
  'id': 'D86.8',
  'english': 'Sarcoidosis of other and combined sites',
  'vietnamese': 'Bệnh sarcoid hỗn hợp và tại các khu vực khác',
}, {
  'id': 'D86.9',
  'english': 'Sarcoidosis, unspecified',
  'vietnamese': 'Bệnh sarcoid không đặc hiệu',
}, {
  'id': 'D89',
  'english': 'Other disorders involving the immune mechanism, not elsewhere classified',
  'vietnamese': 'Rối loạn liên quan đến cơ chế miễn dịch, chưa phân loại nơi khác',
}, {
  'id': 'D89.0',
  'english': 'Polyclonal hypergammaglobulinaemia',
  'vietnamese': 'Tăng gammaglobulin máu đa dòng',
}, {
  'id': 'D89.1',
  'english': 'Cryoglobulinaemia',
  'vietnamese': 'Bệnh kháng thể (tăng globulin) ngưng kết lạnh',
}, {
  'id': 'D89.2',
  'english': 'Hypergammaglobulinaemia, unspecified',
  'vietnamese': 'Tăng gammaglobulin máu không đặc hiệu',
}, {
  'id': 'D89.3',
  'english': 'Immune reconstitution syndrome',
  'vietnamese': 'Hội chứng tái tạo miễn dịch',
}, {
  'id': 'D89.8',
  'english': 'Other specified disorders involving the immune mechanism, not elsewhere classified',
  'vietnamese': 'Các rối loạn xác định khác liên quan đến cơ chế miễn dịch, chưa được phân loại ở phần khác',
}, {
  'id': 'D89.9',
  'english': 'Disorder involving the immune mechanism, unspecified',
  'vietnamese': 'Rối loạn liên quan đến cơ chế miễn dịch, không đặc hiệu',
}, {
  'id': 'E00',
  'english': 'Congenital iodine-deficiency syndrome',
  'vietnamese': 'Hội chứng thiếu iod bẩm sinh',
}, {
  'id': 'E00.0',
  'english': 'Congenital iodine-deficiency syndrome, neurological type',
  'vietnamese': 'Hội chứng thiếu iod bẩm sinh, thể thần kinh',
}, {
  'id': 'E00.1',
  'english': 'Congenital iodine-deficiency syndrome, myxoedematous type',
  'vietnamese': 'Hội chứng thiếu iod bẩm sinh, thể phù niêm',
}, {
  'id': 'E00.2',
  'english': 'Congenital iodine-deficiency syndrome, mixed type',
  'vietnamese': 'Hội chứng thiếu iod bẩm sinh,-thể phối hợp',
}, {
  'id': 'E00.9',
  'english': 'Congenital iodine-deficiency syndrome, unspecified',
  'vietnamese': 'Hội chứng thiếu iod bẩm sinh, không đặc hiệu',
}, {
  'id': 'E01',
  'english': 'Iodine-deficiency-related thyroid disorders and allied conditions',
  'vietnamese': 'Rối loạn tuyến giáp -liên quan đến thiếu iod và bệnh phối hợp',
}, {
  'id': 'E01.0',
  'english': 'Iodine-deficiency-related diffuse (endemic) goitre',
  'vietnamese': 'Bướu giáp lan toả (địa phương) -liên quan đến thiếu iod',
}, {
  'id': 'E01.1',
  'english': 'Iodine-deficiency-related multinodular (endemic) goitre',
  'vietnamese': 'Bướu giáp đa nhân (địa phương) do thiếu iod',
}, {
  'id': 'E01.2',
  'english': 'Iodine-deficiency-related (endemic) goitre, unspecified',
  'vietnamese': 'Bướu giáp (địa phương) do thiếu iod, không đặc hiệu',
}, {
  'id': 'E01.8',
  'english': 'Other iodine-deficiency-related thyroid disorders and allied conditions',
  'vietnamese': 'Rối loạn tuyến giáp liên quan đến thiếu iod khác và những bệnh phối hợp',
}, {
  'id': 'E02',
  'english': 'Subclinical iodine-deficiency hypothyroidism',
  'vietnamese': 'Suy giáp do thiếu iod dưới lâm sàng',
}, {'id': 'E03', 'english': 'Other hypothyroidism', 'vietnamese': 'Suy giáp khác'}, {
  'id': 'E03.0',
  'english': 'Congenital hypothyroidism with diffuse goitre',
  'vietnamese': 'Suy giáp bẩm sinh với bướu lan toả',
}, {
  'id': 'E03.1',
  'english': 'Congenital hypothyroidism without goitre',
  'vietnamese': 'Suy giáp bẩm sinh không có bướu',
}, {
  'id': 'E03.2',
  'english': 'Hypothyroidism due to medicaments and other exogenous substances',
  'vietnamese': 'Suy giáp do thuốc và chất ngoại sinh khác',
}, {
  'id': 'E03.3',
  'english': 'Postinfectious hypothyroidism',
  'vietnamese': 'Suy giáp sau nhiễm trùng',
}, {
  'id': 'E03.4',
  'english': 'Atrophy of thyroid (acquired)',
  'vietnamese': 'Teo tuyến giáp (mắc phải)',
}, {'id': 'E03.5', 'english': 'Myxoedema coma', 'vietnamese': 'Hôn mê phù niêm'}, {
  'id': 'E03.8',
  'english': 'Other specified hypothyroidism',
  'vietnamese': 'Suy giáp xác định khác',
}, {
  'id': 'E03.9',
  'english': 'Hypothyroidism, unspecified',
  'vietnamese': 'Suy giáp, không đặc hiệu',
}, {'id': 'E04', 'english': 'Other nontoxic goitre', 'vietnamese': 'Bướu -không độc khác'}, {
  'id': 'E04.0',
  'english': 'Nontoxic diffuse goitre',
  'vietnamese': 'Bướu giáp lan toả -không độc',
}, {
  'id': 'E04.1',
  'english': 'Nontoxic single thyroid nodule',
  'vietnamese': 'Bướu giáp đơn nhân -không độc',
}, {
  'id': 'E04.2',
  'english': 'Nontoxic multinodular goitre',
  'vietnamese': 'Bướu giáp đa nhân -không độc',
}, {
  'id': 'E04.8',
  'english': 'Other specified nontoxic goitre',
  'vietnamese': 'Bướu giáp không độc xác định khác',
}, {
  'id': 'E04.9',
  'english': 'Nontoxic goitre, unspecified',
  'vietnamese': 'bướu giáp không độc, không đặc hiệu',
}, {
  'id': 'E05',
  'english': 'Thyrotoxicosis [hyperthyroidism]',
  'vietnamese': 'Nhiễm độc giáp [cường giáp]',
}, {
  'id': 'E05.0',
  'english': 'Thyrotoxicosis with diffuse goitre',
  'vietnamese': 'Nhiễm độc giáp với bướu lan toả',
}, {
  'id': 'E05.1',
  'english': 'Thyrotoxicosis with toxic single thyroid nodule',
  'vietnamese': 'Nhiễm độc giáp với bướu giáp đơn nhân độc',
}, {
  'id': 'E05.2',
  'english': 'Thyrotoxicosis with toxic multinodular goitre',
  'vietnamese': 'Nhiễm độc giáp với bướu giáp đa nhân độc',
}, {
  'id': 'E05.3',
  'english': 'Thyrotoxicosis from ectopic thyroid tissue',
  'vietnamese': 'Nhiễm độc giáp từ mô giáp lạc chỗ',
}, {
  'id': 'E05.4',
  'english': 'Thyrotoxicosis factitia',
  'vietnamese': 'Nhiễm độc giáp do dùng thuốc',
}, {
  'id': 'E05.5',
  'english': 'Thyroid crisis or storm',
  'vietnamese': 'Cơn cường giáp cấp hay cơn bão giáp',
}, {'id': 'E05.8', 'english': 'Other thyrotoxicosis', 'vietnamese': 'Nhiễm độc giáp khác'}, {
  'id': 'E05.9',
  'english': 'Thyrotoxicosis, unspecified',
  'vietnamese': 'Nhiễm độc giáp, không đặc hiệu',
}, {'id': 'E06', 'english': 'Thyroiditis', 'vietnamese': 'Viêm giáp'}, {
  'id': 'E06.0',
  'english': 'Acute thyroiditis',
  'vietnamese': 'Viêm giáp cấp',
}, {'id': 'E06.1', 'english': 'Subacute thyroiditis', 'vietnamese': 'Viêm giáp bán cấp'}, {
  'id': 'E06.2',
  'english': 'Chronic thyroiditis with transient thyrotoxicosis',
  'vietnamese': 'Viêm giáp mãn với nhiễm độc giáp thóang qua',
}, {'id': 'E06.3', 'english': 'Autoimmune thyroiditis', 'vietnamese': 'Viêm giáp tự miễn'}, {
  'id': 'E06.4',
  'english': 'Drug-induced thyroiditis',
  'vietnamese': 'Viêm giáp do thuốc',
}, {
  'id': 'E06.5',
  'english': 'Other chronic thyroiditis',
  'vietnamese': 'Viêm giáp mãn tính -khác',
}, {
  'id': 'E06.9',
  'english': 'Thyroiditis, unspecified',
  'vietnamese': 'Viêm giáp không đặc hiệu',
}, {
  'id': 'E07',
  'english': 'Other disorders of thyroid',
  'vietnamese': 'Các rối loạn khác của tuyến giáp',
}, {
  'id': 'E07.0',
  'english': 'Hypersecretion of calcitonin',
  'vietnamese': 'Tăng tiết calcitonin',
}, {
  'id': 'E07.1',
  'english': 'Dyshormogenetic goitre',
  'vietnamese': 'Rối loạn -được xác định khác của tuyến giáp',
}, {
  'id': 'E07.8',
  'english': 'Other specified disorders of thyroid',
  'vietnamese': 'Rối loạn -được xác định khác của tuyến giáp',
}, {
  'id': 'E07.9',
  'english': 'Disorder of thyroid, unspecified',
  'vietnamese': 'Rối loạn tuyến giáp, không đặc hiệu',
}, {
  'id': 'E10',
  'english': 'Insuline-dependent diabetes mellitus',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline',
}, {
  'id': 'E10.0',
  'english': 'Insuline-dependent diabetes mellitus (With coma)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có hôn mê)',
}, {
  'id': 'E10.1',
  'english': 'Insuline-dependent diabetes mellitus (With ketoacidosis)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có nhiễm toan ceton)',
}, {
  'id': 'E10.2†',
  'english': 'Insuline-dependent diabetes mellitus (With renal complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng thận)',
}, {
  'id': 'E10.3†',
  'english': 'Insuline-dependent diabetes mellitus (With ophthalmic complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng mắt)',
}, {
  'id': 'E10.4†',
  'english': 'Insuline-dependent diabetes mellitus (With neurological complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng thần kinh)',
}, {
  'id': 'E10.5',
  'english': 'Insuline-dependent diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng mạch máu ngoại vi)',
}, {
  'id': 'E10.6',
  'english': 'Insuline-dependent diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng xác định khác)',
}, {
  'id': 'E10.7',
  'english': 'Insuline-dependent diabetes mellitus (With multiple complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có đa biến chứng)',
}, {
  'id': 'E10.8',
  'english': 'Insuline-dependent diabetes mellitus (With unspecified complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Có biến chứng không xác định khác)',
}, {
  'id': 'E10.9',
  'english': 'Insuline-dependent diabetes mellitus (Without complications)',
  'vietnamese': 'Bệnh đái tháo đường phụ thuộc insuline (Chưa có biến chứng)',
}, {
  'id': 'E11',
  'english': 'Non-insuline-dependent diabetes mellitus',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline',
}, {
  'id': 'E11.0',
  'english': 'Non-insuline-dependent diabetes mellitus (With coma)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có hôn mê)',
}, {
  'id': 'E11.1',
  'english': 'Non-insuline-dependent diabetes mellitus (With ketoacidosis)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có nhiễm toan ceton)',
}, {
  'id': 'E11.2†',
  'english': 'Non-insuline-dependent diabetes mellitus (With renal complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng thận)',
}, {
  'id': 'E11.3†',
  'english': 'Non-insuline-dependent diabetes mellitus (With ophthalmic complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng mắt)',
}, {
  'id': 'E11.4†',
  'english': 'Non-insuline-dependent diabetes mellitus (With neurological complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng thần kinh)',
}, {
  'id': 'E11.5',
  'english': 'Non-insuline-dependent diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng mạch máu ngoại vi)',
}, {
  'id': 'E11.6',
  'english': 'Non-insuline-dependent diabetes mellitus (With other specified complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng xác định khác)',
}, {
  'id': 'E11.7',
  'english': 'Non-insuline-dependent diabetes mellitus (With multiple complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có đa biến chứng)',
}, {
  'id': 'E11.8',
  'english': 'Non-insuline-dependent diabetes mellitus (With unspecified complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Có biến chứng không xác định khác)',
}, {
  'id': 'E11.9',
  'english': 'Non-insuline-dependent diabetes mellitus (Without complications)',
  'vietnamese': 'Bệnh đái tháo đường không phụ thuộc insuline (Chưa có biến chứng)',
}, {
  'id': 'E12',
  'english': 'Malnutrition-related diabetes mellitus',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng',
}, {
  'id': 'E12.0',
  'english': 'Malnutrition-related diabetes mellitus (With coma)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có hôn mê)',
}, {
  'id': 'E12.1',
  'english': 'Malnutrition-related diabetes mellitus (With ketoacidosis)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có Nhiễm toan ceton)',
}, {
  'id': 'E12.2†',
  'english': 'Malnutrition-related diabetes mellitus (With renal complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng thận)',
}, {
  'id': 'E12.3†',
  'english': 'Malnutrition-related diabetes mellitus (With ophthalmic complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng mắt)',
}, {
  'id': 'E12.4†',
  'english': 'Malnutrition-related diabetes mellitus (With neurological complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng thần kinh)',
}, {
  'id': 'E12.5',
  'english': 'Malnutrition-related diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng mạch máu ngoại vi)',
}, {
  'id': 'E12.6',
  'english': 'Malnutrition-related diabetes mellitus (With other specified complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng xác định khác)',
}, {
  'id': 'E12.7',
  'english': 'Malnutrition-related diabetes mellitus (With multiple complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có đa biến chứng)',
}, {
  'id': 'E12.8',
  'english': 'Malnutrition-related diabetes mellitus (With unspecified complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Có biến chứng không xác định khác)',
}, {
  'id': 'E12.9',
  'english': 'Malnutrition-related diabetes mellitus (Without complications)',
  'vietnamese': 'Bệnh đái tháo đường liên quan đến suy dinh dưỡng (Chưa có biến chứng)',
}, {
  'id': 'E13',
  'english': 'Other specified diabetes mellitus',
  'vietnamese': 'Bệnh đái tháo đường xác định khác',
}, {
  'id': 'E13.0',
  'english': 'Other specified diabetes mellitus (With coma)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có hôn mê)',
}, {
  'id': 'E13.1',
  'english': 'Other specified diabetes mellitus (With ketoacidosis)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có nhiễm toan ceton)',
}, {
  'id': 'E13.2†',
  'english': 'Other specified diabetes mellitus (With renal complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng thận)',
}, {
  'id': 'E13.3†',
  'english': 'Other specified diabetes mellitus (With ophthalmic complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng mắt)',
}, {
  'id': 'E13.4†',
  'english': 'Other specified diabetes mellitus (With neurological complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng thần kinh)',
}, {
  'id': 'E13.5',
  'english': 'Other specified diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng mạch máu ngoại vi)',
}, {
  'id': 'E13.6',
  'english': 'Other specified diabetes mellitus (With other specified complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng xác định khác)',
}, {
  'id': 'E13.7',
  'english': 'Other specified diabetes mellitus (With multiple complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có đa biến chứng)',
}, {
  'id': 'E13.8',
  'english': 'Other specified diabetes mellitus (With unspecified complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Có biến chứng không xác định khác)',
}, {
  'id': 'E13.9',
  'english': 'Other specified diabetes mellitus (Without complications)',
  'vietnamese': 'Bệnh đái tháo đường xác định khác (Chưa có biến chứng)',
}, {
  'id': 'E14',
  'english': 'Unspecified diabetes mellitus',
  'vietnamese': 'Các thể loại đái tháo đường không xác định',
}, {
  'id': 'E14.0',
  'english': 'Unspecified diabetes mellitus (With coma)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có hôn mê)',
}, {
  'id': 'E14.1',
  'english': 'Unspecified diabetes mellitus (With ketoacidosis)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có Nhiễm toan ceton)',
}, {
  'id': 'E14.2†',
  'english': 'Unspecified diabetes mellitus (With renal complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng thận)',
}, {
  'id': 'E14.3†',
  'english': 'Unspecified diabetes mellitus (With ophthalmic complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng mắt)',
}, {
  'id': 'E14.4†',
  'english': 'Unspecified diabetes mellitus (With neurological complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng thần kinh)',
}, {
  'id': 'E14.5',
  'english': 'Unspecified diabetes mellitus (With peripheral circulatory complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng mạch máu ngoại vi)',
}, {
  'id': 'E14.6',
  'english': 'Unspecified diabetes mellitus (With other specified complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng xác định khác)',
}, {
  'id': 'E14.7',
  'english': 'Unspecified diabetes mellitus (With multiple complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có đa biến chứng)',
}, {
  'id': 'E14.8',
  'english': 'Unspecified diabetes mellitus (With unspecified complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Có biến chứng không xác định khác)',
}, {
  'id': 'E14.9',
  'english': 'Unspecified diabetes mellitus (Without complications)',
  'vietnamese': 'Các thể loại đái tháo đường không xác định (Chưa có biến chứng)',
}, {
  'id': 'E15',
  'english': 'Nondiabetic hypoglycaemic coma',
  'vietnamese': 'Hôn mê hạ đường máu không do đái tháo đường',
}, {
  'id': 'E16',
  'english': 'Other disorders of pancreatic internal secretion',
  'vietnamese': 'Rối loạn khác của tuyến tụy nội tiết',
}, {
  'id': 'E16.0',
  'english': 'Drug-induced hypoglycaemia without coma',
  'vietnamese': 'Hạ glucose máu do thuốc, không hôn mê',
}, {'id': 'E16.1', 'english': 'Other hypoglycaemia', 'vietnamese': 'Hạ glucose máu khác'}, {
  'id': 'E16.2',
  'english': 'Hypoglycaemia, unspecified',
  'vietnamese': 'Hạ glucose máu không đặc hiệu',
}, {
  'id': 'E16.3',
  'english': 'Increased secretion of glucagon',
  'vietnamese': 'Tăng tiết glucagon',
}, {
  'id': 'E16.4',
  'english': 'Abnormal secretion of gastrin',
  'vietnamese': 'Tiết gastrin bất thường',
}, {
  'id': 'E16.8',
  'english': 'Other specified disorders of pancreatic internal secretion',
  'vietnamese': 'Các rối loạn xác định khác của - bài tiết của tuyến tụy nội tiết',
}, {
  'id': 'E16.9',
  'english': 'Disorder of pancreatic internal secretion, unspecified',
  'vietnamese': 'Các rối loạn bài tiết của tuyến tụy nội tiết, không xác định',
}, {'id': 'E20', 'english': 'Hypoparathyroidism', 'vietnamese': 'Suy tuyến cận giáp'}, {
  'id': 'E20.0',
  'english': 'Idiopathic hypoparathyroidism',
  'vietnamese': 'Suy cận giáp không rõ nguyên nhân',
}, {'id': 'E20.1', 'english': 'Pseudohypoparathyroidism', 'vietnamese': 'Giả Suy cận giáp'}, {
  'id': 'E20.8',
  'english': 'Other hypoparathyroidism',
  'vietnamese': 'Suy cận giáp khác',
}, {
  'id': 'E20.9',
  'english': 'Hypoparathyroidism, unspecified',
  'vietnamese': 'Suy cận giáp, không đặc hiệu',
}, {
  'id': 'E21',
  'english': 'Hyperparathyroidism and other disorders of parathyroid gland',
  'vietnamese': 'Cường cận giáp và các rối loạn khác của tuyến cận giáp',
}, {
  'id': 'E21.0',
  'english': 'Primary hyperparathyroidism',
  'vietnamese': 'Cường cận giáp nguyên phát',
}, {
  'id': 'E21.1',
  'english': 'Secondary hyperparathyroidism, not elsewhere classified',
  'vietnamese': 'Cường cận giáp thứ phát chưa được phân loại ở phần khác',
}, {'id': 'E21.2', 'english': 'Other hyperparathyroidism', 'vietnamese': 'Cường cận giáp độ 3'}, {
  'id': 'E21.3',
  'english': 'Hyperparathyroidism, unspecified',
  'vietnamese': 'Cường cận giáp, không các định',
}, {
  'id': 'E21.4',
  'english': 'Other specified disorders of parathyroid gland',
  'vietnamese': 'Các rối loạn xác định khác của tuyến cận giáp',
}, {
  'id': 'E21.5',
  'english': 'Disorder of parathyroid gland, unspecified',
  'vietnamese': 'Rối loạn tuyến cận giáp, không đặc hiệu',
}, {
  'id': 'E22',
  'english': 'Hyperfunction of pituitary gland',
  'vietnamese': 'Cường tuyến yên',
}, {
  'id': 'E22.0',
  'english': 'Acromegaly and pituitary gigantism (M14.5*)',
  'vietnamese': 'Bệnh to cực và chứng khổng lồ do tuyến yên (M14.5*)',
}, {'id': 'E22.1', 'english': 'Hyperprolactinaemia', 'vietnamese': 'Tăng prolactin máu'}, {
  'id': 'E22.2',
  'english': 'Syndrome of inappropriate secretion of antidiuretic hormone',
  'vietnamese': 'Hội chứng tiết hormon kháng bài niệu (ADH) không thích hợp',
}, {
  'id': 'E22.8',
  'english': 'Other hyperfunction of pituitary gland',
  'vietnamese': 'Cường năng khác của tuyến yên',
}, {
  'id': 'E22.9',
  'english': 'Hyperfunction of pituitary gland, unspecified',
  'vietnamese': 'Cường năng tuyến yên, không đặc hiệu',
}, {
  'id': 'E23',
  'english': 'Hypofunction and other disorders of pituitary gland',
  'vietnamese': 'Suy chức năng - và rối loạn khác của tuyến yên',
}, {'id': 'E23.0', 'english': 'Hypopituitarism', 'vietnamese': 'Suy tuyến yên'}, {
  'id': 'E23.1',
  'english': 'Drug-induced hypopituitarism',
  'vietnamese': 'Suy tuyến yên do thuốc',
}, {'id': 'E23.2', 'english': 'Diabetes insipidus', 'vietnamese': 'Đái tháo nhạt'}, {
  'id': 'E23.3',
  'english': 'Hypothalamic dysfunction, not elsewhere classified',
  'vietnamese': 'Rối loạn chức năng vùng dưới đồi, chưa được phân loại ở phần khác',
}, {
  'id': 'E23.6',
  'english': 'Other disorders of pituitary gland',
  'vietnamese': 'Rối loạn khác của tuyến yên',
}, {
  'id': 'E23.7',
  'english': 'Disorder of pituitary gland, unspecified',
  'vietnamese': 'Rối loạn tuyến yên, không đặc hiệu',
}, {'id': 'E24', 'english': 'Cushing s syndrome', 'vietnamese': 'Hội chứng Cushing'}, {
  'id': 'E24.0',
  'english': 'Pituitary-dependent Cushing s disease',
  'vietnamese': 'Bệnh Cushing phụ thuộc tuyến yên',
}, {'id': 'E24.1', 'english': 'Nelson s syndrome', 'vietnamese': 'Hội chứng Nelson'}, {
  'id': 'E24.2',
  'english': 'Drug-induced Cushing s syndrome',
  'vietnamese': 'Hội chứng Cushing do thuốc',
}, {'id': 'E24.3', 'english': 'Ectopic ACTH syndrome', 'vietnamese': 'Hội chứng ACTH lạc chỗ'}, {
  'id': 'E24.4',
  'english': 'Alcohol-induced pseudo-Cushing s syndrome',
  'vietnamese': 'Hội chứng Cushing giả do rượu',
}, {
  'id': 'E24.8',
  'english': 'Other Cushing s syndrome',
  'vietnamese': 'Hội chứng Cushing khác',
}, {
  'id': 'E24.9',
  'english': 'Cushing s syndrome, unspecified',
  'vietnamese': 'Hội chứng Cushing, không đặc hiệu',
}, {
  'id': 'E25',
  'english': 'Adrenogenital disorders',
  'vietnamese': 'Rối loạn thượng thận sinh dục',
}, {
  'id': 'E25.0',
  'english': 'Congenital adrenogenital disorders associated with enzyme deficiency',
  'vietnamese': 'Rối loạn thượng thận - sinh dục bẩm sinh kết hợp với thiếu enzym',
}, {
  'id': 'E25.8',
  'english': 'Other adrenogenital disorders',
  'vietnamese': 'Các rối loạn khác của thượng thận - sinh dục',
}, {
  'id': 'E25.9',
  'english': 'Adrenogenital disorder, unspecified',
  'vietnamese': 'Rối loạn thượng thận - sinh dục, không đặc hiệu',
}, {'id': 'E26', 'english': 'Hyperaldosteronism', 'vietnamese': 'Cường aldosterone'}, {
  'id': 'E26.0',
  'english': 'Primary hyperaldosteronism',
  'vietnamese': 'Cường aldosterone nguyên phát',
}, {
  'id': 'E26.1',
  'english': 'Secondary hyperaldosteronism',
  'vietnamese': 'Cường aldosterone thứ phát',
}, {
  'id': 'E26.8',
  'english': 'Other hyperaldosteronism',
  'vietnamese': 'Cường aldosterone thứ phát',
}, {
  'id': 'E26.9',
  'english': 'Hyperaldosteronism, unspecified',
  'vietnamese': 'Cường aldosterone, không đặc hiệu',
}, {
  'id': 'E27',
  'english': 'Other disorders of adrenal gland',
  'vietnamese': 'Các rối loạn khác của tuyến thượng thận',
}, {
  'id': 'E27.0',
  'english': 'Other adrenocortical overactivity',
  'vietnamese': 'Tăng hoạt động vỏ thượng thận khác',
}, {
  'id': 'E27.1',
  'english': 'Primary adrenocortical insufficiency',
  'vietnamese': 'Thiểu năng vỏ thượng thận nguyên phát',
}, {'id': 'E27.2', 'english': 'Addisonian crisis', 'vietnamese': 'Cơn Addison'}, {
  'id': 'E27.3',
  'english': 'Drug-induced adrenocortical insufficiency',
  'vietnamese': 'Thiểu nặng vỏ thượng thận do thuốc',
}, {
  'id': 'E27.4',
  'english': 'Other and unspecified adrenocortical insufficiency',
  'vietnamese': 'Thiểu nặng vỏ thượng thận khác và không đặc hiệu',
}, {
  'id': 'E27.5',
  'english': 'Adrenomedullary hyperfunction',
  'vietnamese': 'Tăng năng tủy thượng thận',
}, {
  'id': 'E27.8',
  'english': 'Other specified disorders of adrenal gland',
  'vietnamese': 'Các rối loạn xác định khác của tuyến thượng thận',
}, {
  'id': 'E27.9',
  'english': 'Disorder of adrenal gland, unspecified',
  'vietnamese': 'Rối loạn tuyến thượng thận, không đặc hiệu',
}, {
  'id': 'E28',
  'english': 'Ovarian dysfunction',
  'vietnamese': 'Rối loạn chức năng buồng trứng',
}, {
  'id': 'E28.0',
  'english': 'Ovarian dysfunction: Estrogen excess',
  'vietnamese': 'Thừa estrogen',
}, {
  'id': 'E28.1',
  'english': 'Ovarian dysfunction: Androgen excess',
  'vietnamese': 'Thừa androgen',
}, {
  'id': 'E28.2',
  'english': 'Polycystic ovarian syndrome',
  'vietnamese': 'Hội chứng buồng trứng đa nang',
}, {
  'id': 'E28.3',
  'english': 'Primary ovarian failure',
  'vietnamese': 'Suy buồng trứng nguyên phát',
}, {
  'id': 'E28.8',
  'english': 'Other ovarian dysfunction',
  'vietnamese': 'Rối loạn chức năng buồng trứng khác',
}, {
  'id': 'E28.9',
  'english': 'Ovarian dysfunction, unspecified',
  'vietnamese': 'Các thể rối loạn chức năng buồng trứng, không đặc hiệu',
}, {
  'id': 'E29',
  'english': 'Testicular dysfunction',
  'vietnamese': 'Rối loạn chức năng tinh hoàn',
}, {
  'id': 'E29.0',
  'english': 'Testicular hyperfunction',
  'vietnamese': 'Cường năng tinh hoàn',
}, {'id': 'E29.1', 'english': 'Testicular hypofunction', 'vietnamese': 'Thiểu năng tinh hoàn'}, {
  'id': 'E29.8',
  'english': 'Other testicular dysfunction',
  'vietnamese': 'Rối loạn chức năng tinh hoàn khác',
}, {
  'id': 'E29.9',
  'english': 'Testicular dysfunction, unspecified',
  'vietnamese': 'Rối loạn chức năng tinh hoàn, không đặc hiệu',
}, {
  'id': 'E30',
  'english': 'Disorders of puberty, not elsewhere classified',
  'vietnamese': 'Rối loạn lúc dậy thì, không phân loại ở phần khác',
}, {'id': 'E30.0', 'english': 'Delayed puberty', 'vietnamese': 'Dậy thì muộn'}, {
  'id': 'E30.1',
  'english': 'Precocious puberty',
  'vietnamese': 'Dậy thì sớm',
}, {
  'id': 'E30.8',
  'english': 'Other disorders of puberty',
  'vietnamese': 'Rối loạn dậy thì khác',
}, {
  'id': 'E30.9',
  'english': 'Disorder of puberty, unspecified',
  'vietnamese': 'Rối loạn dậy thì, không đặc hiệu',
}, {
  'id': 'E31',
  'english': 'Polyglandular dysfunction',
  'vietnamese': 'Rối loạn chức năng đa tuyến',
}, {
  'id': 'E31.0',
  'english': 'Autoimmune polyglandular failure',
  'vietnamese': 'Suy đa tuyến tự miễn',
}, {
  'id': 'E31.1',
  'english': 'Polyglandular hyperfunction',
  'vietnamese': 'Cường năng đa tuyến',
}, {
  'id': 'E31.8',
  'english': 'Other polyglandular dysfunction',
  'vietnamese': 'Rối loạn chức năng đa tuyến khác',
}, {
  'id': 'E31.9',
  'english': 'Polyglandular dysfunction, unspecified',
  'vietnamese': 'Rối loạn chức năng đa tuyến, không đặc hiệu',
}, {'id': 'E32', 'english': 'Diseases of thymus', 'vietnamese': 'Bệnh tuyến ức'}, {
  'id': 'E32.0',
  'english': 'Persistent hyperplasia of thymus',
  'vietnamese': 'Tăng sản tuyến ức kéo dài',
}, {'id': 'E32.1', 'english': 'Abscess of thymus', 'vietnamese': 'Áp-xe tuyến ức'}, {
  'id': 'E32.8',
  'english': 'Other diseases of thymus',
  'vietnamese': 'Bệnh tuyến ức khác',
}, {
  'id': 'E32.9',
  'english': 'Disease of thymus, unspecified',
  'vietnamese': 'Bệnh tuyến ức không đặc hiệu',
}, {
  'id': 'E34',
  'english': 'Other endocrine disorders',
  'vietnamese': 'Rối loạn nội tiết khác',
}, {
  'id': 'E34.0',
  'english': 'Carcinoid syndrome',
  'vietnamese': 'Hội chứng carcinoid',
}, {
  'id': 'E34.1',
  'english': 'Other hypersecretion of intestinal hormones',
  'vietnamese': 'Tăng tiết khác của hormon ruột',
}, {
  'id': 'E34.2',
  'english': 'Ectopic hormone secretion, not elsewhere classified',
  'vietnamese': 'Tiết hormon lạc chỗ, chưa được phân loại ở phần khác',
}, {
  'id': 'E34.3',
  'english': 'Short stature, not elsewhere classified',
  'vietnamese': 'Vóc dáng lùn, chưa được phân loại ở phần khác',
}, {'id': 'E34.4', 'english': 'Constitutional tall stature', 'vietnamese': 'Thể tạng cao'}, {
  'id': 'E34.5',
  'english': 'Androgen resistance syndrome',
  'vietnamese': 'Hội chứng kháng androgen',
}, {
  'id': 'E34.8',
  'english': 'Other specified endocrine disorders',
  'vietnamese': 'Rối loạn nội tiết xác định khác',
}, {
  'id': 'E34.9',
  'english': 'Endocrine disorder, unspecified',
  'vietnamese': 'Rối loạn nội tiết, không đặc hiệu',
}, {
  'id': 'E35*',
  'english': 'Disorders of endocrine glands in diseases classified elsewhere',
  'vietnamese': 'Rối loạn tuyến nội tiết trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'E35.0*',
  'english': 'Disorders of thyroid gland in diseases classified elsewhere',
  'vietnamese': 'Rối loạn tuyến giáp trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'E35.1*',
  'english': 'Disorders of adrenal glands in diseases classified elsewhere',
  'vietnamese': 'Rối loạn tuyến thượng thận trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'E35.8*',
  'english': 'Disorders of other endocrine glands in diseases classified elsewhere',
  'vietnamese': 'Rối loạn của các tuyến nội tiết khác trong các bệnh đã được phân loại ở phần khác',
}, {'id': 'E40', 'english': 'Kwashiorkor', 'vietnamese': 'Kwashiorkor'}, {
  'id': 'E41',
  'english': 'Nutritional marasmus',
  'vietnamese': 'Suy dinh dưỡng thể marasmus',
}, {
  'id': 'E42',
  'english': 'Marasmic kwashiorkor',
  'vietnamese': 'Thể hỗn hợp Kwashiorkor-marasmus',
}, {
  'id': 'E43',
  'english': 'Unspecified severe protein-energy malnutrition',
  'vietnamese': 'Suy dinh dưỡng nặng do thiếu protein - năng lượng, không đặc hiệu',
}, {
  'id': 'E44',
  'english': 'Protein-energy malnutrition of moderate and mild degree',
  'vietnamese': 'Suy dinh dưỡng vừa và nhẹ do thiếu protein - năng lượng',
}, {
  'id': 'E44.0',
  'english': 'Moderate protein-energy malnutrition',
  'vietnamese': 'Suy dinh dưỡng vừa do thiếu protein năng lượng',
}, {
  'id': 'E44.1',
  'english': 'Mild protein-energy malnutrition',
  'vietnamese': 'Suy dinh dưỡng nhẹ do thiếu protein năng lượng',
}, {
  'id': 'E45',
  'english': 'Retarded development following protein-energy malnutrition',
  'vietnamese': 'Chậm phát triển sau suy dinh dưỡng do thiếu protein năng lượng',
}, {
  'id': 'E46',
  'english': 'Unspecified protein-energy malnutrition',
  'vietnamese': 'Suy dinh dưỡng do thiếu protein năng lượng không xác định',
}, {'id': 'E50', 'english': 'Vitamin A deficiency', 'vietnamese': 'Thiếu vitamin A'}, {
  'id': 'E50.0',
  'english': 'Vitamin A deficiency with conjunctival xerosis',
  'vietnamese': 'Thiếu vitamin A có khô kết mạc',
}, {
  'id': 'E50.1',
  'english': 'Vitamin A deficiency with Bitot s spot and conjunctival xerosis',
  'vietnamese': 'thiếu vitamin A có vết Bitot và khô kết mạc',
}, {
  'id': 'E50.2',
  'english': 'Vitamin A deficiency with corneal xerosis',
  'vietnamese': 'Khô giác mạc do thiếu vitamin A',
}, {
  'id': 'E50.3',
  'english': 'Vitamin A deficiency with corneal ulceration and xerosis',
  'vietnamese': 'thiếu vitamin A có loét và khô giác mạc',
}, {
  'id': 'E50.4',
  'english': 'Vitamin A deficiency with keratomalacia',
  'vietnamese': 'thiếu vitamin A có loét và khô giác mạc',
}, {
  'id': 'E50.5',
  'english': 'Vitamin A deficiency with night blindness',
  'vietnamese': 'Thiếu vitamin A có quáng gà',
}, {
  'id': 'E50.6',
  'english': 'Vitamin A deficiency with xerophthalmic scars of cornea',
  'vietnamese': 'Thiếu vitamin A có sẹo do khô giác mạc',
}, {
  'id': 'E50.7',
  'english': 'Other ocular manifestations of vitamin A deficiency',
  'vietnamese': 'Biểu hiện khác ở mắt do thiếu vitamin A',
}, {
  'id': 'E50.8',
  'english': 'Other manifestations of vitamin A deficiency',
  'vietnamese': 'Biểu hiện khác của thiếu vitamin A',
}, {
  'id': 'E50.9',
  'english': 'Vitamin A deficiency, unspecified',
  'vietnamese': 'Thiếu vitamin A, không đặc hiệu',
}, {'id': 'E51', 'english': 'Thiamine deficiency', 'vietnamese': 'Thiếu thiamine'}, {
  'id': 'E51.1',
  'english': 'Beriberi',
  'vietnamese': 'Bệnh tê phù',
}, {
  'id': 'E51.2',
  'english': 'Wernicke s encephalopathy',
  'vietnamese': 'Bệnh lý não Wernicke',
}, {
  'id': 'E51.8',
  'english': 'Other manifestations of thiamine deficiency',
  'vietnamese': 'Các biểu hiện khác của thiếu thiamine',
}, {
  'id': 'E51.9',
  'english': 'Thiamine deficiency, unspecified',
  'vietnamese': 'Thiếu thiamine, không đặc hiệu',
}, {'id': 'E52', 'english': 'Niacin deficifiency', 'vietnamese': 'Thiếu niacin'}, {
  'id': 'E53',
  'english': 'Deficiency of other B group vitamins',
  'vietnamese': 'Thiếu các vitamin nhóm B khác',
}, {'id': 'E53.0', 'english': 'Riboflavin deficiency', 'vietnamese': 'Thiếu riboflavin'}, {
  'id': 'E53.1',
  'english': 'Pyridoxine deficiency',
  'vietnamese': 'Thiếu pyridoxine',
}, {
  'id': 'E53.8',
  'english': 'Deficiency of other specified B group vitamins',
  'vietnamese': 'Thiếu các vitamin nhóm B đặc hiệu khác',
}, {
  'id': 'E53.9',
  'english': 'Vitamin B deficiency, unspecified',
  'vietnamese': 'Thiếu vitamin B, không đặc hiệu',
}, {'id': 'E54', 'english': 'Ascorbic acid deficiency', 'vietnamese': 'Thiếu acid ascorbic'}, {
  'id': 'E55',
  'english': 'Vitamin D deficiency',
  'vietnamese': 'Thiếu Vitamin D',
}, {'id': 'E55.0', 'english': 'Rickets, active', 'vietnamese': 'Còi xương, tiến triển'}, {
  'id': 'E55.9',
  'english': 'Vitamin D deficiency, unspecified',
  'vietnamese': 'Thiếu vitamin D, không xác định',
}, {'id': 'E56', 'english': 'Other vitamin deficiencies', 'vietnamese': 'Thiếu Vitamin khác'}, {
  'id': 'E56.0',
  'english': 'Deficiency of vitamin E',
  'vietnamese': 'Thiếu vitamin E',
}, {'id': 'E56.1', 'english': 'Deficiency of vitamin K', 'vietnamese': 'Thiếu vitamin K'}, {
  'id': 'E56.8',
  'english': 'Deficiency of other vitamins',
  'vietnamese': 'Thiếu các vitamin khác',
}, {
  'id': 'E56.9',
  'english': 'Vitamin deficiency, unspecified',
  'vietnamese': 'Thiếu vitamin, không đặc hiệu',
}, {
  'id': 'E58',
  'english': 'Dietary calcium deficiency',
  'vietnamese': 'Thiếu Calci do chế độ ăn',
}, {
  'id': 'E59',
  'english': 'Dietary selenium deficiency',
  'vietnamese': 'Thiếu Selen do chế độ ăn',
}, {'id': 'E60', 'english': 'Dietary zinc deficiency', 'vietnamese': 'Thiếu kẽm do chế độ ăn'}, {
  'id': 'E61',
  'english': 'Deficiency of other nutrient elements',
  'vietnamese': 'Thiếu các yếu tố dinh dưỡng khác',
}, {'id': 'E61.0', 'english': 'Copper deficiency', 'vietnamese': 'Thiếu đồng'}, {
  'id': 'E61.1',
  'english': 'Iron deficiency',
  'vietnamese': 'Thiếu sắt',
}, {'id': 'E61.2', 'english': 'Magnesium deficiency', 'vietnamese': 'Thiếu magie'}, {
  'id': 'E61.3',
  'english': 'Manganese deficiency',
  'vietnamese': 'Thiếu mangan',
}, {'id': 'E61.4', 'english': 'Chromium deficiency', 'vietnamese': 'Thiếu crôm'}, {
  'id': 'E61.5',
  'english': 'Molybdenum deficiency',
  'vietnamese': 'Thiếu molypđen',
}, {'id': 'E61.6', 'english': 'Vanadium deficiency', 'vietnamese': 'Thiếu vanadin'}, {
  'id': 'E61.7',
  'english': 'Deficiency of multiple nutrient elements',
  'vietnamese': 'Thiếu nhiều yếu tố dinh dưỡng',
}, {
  'id': 'E61.8',
  'english': 'Deficiency of other specified nutrient elements',
  'vietnamese': 'Thiếu các yếu tố dinh dưỡng xác định khác',
}, {
  'id': 'E61.9',
  'english': 'Deficiency of nutrient element, unspecified',
  'vietnamese': 'Thiếu các yếu tố dinh dưỡng không đặc hiệu',
}, {
  'id': 'E63',
  'english': 'Other nutritional deficiencies',
  'vietnamese': 'Thiếu dinh dưỡng khác',
}, {
  'id': 'E63.0',
  'english': 'Essential fatty acid [EFA] deficiency',
  'vietnamese': 'Thiếu acid béo cần thiết [EFA)',
}, {
  'id': 'E63.1',
  'english': 'Imbalance of constituents of food intake',
  'vietnamese': 'Mất cân đối trong thành phần thức ăn',
}, {
  'id': 'E63.8',
  'english': 'Other specified nutritional deficiencies',
  'vietnamese': 'Thiếu dinh dưỡng đặc hiệu khác',
}, {
  'id': 'E63.9',
  'english': 'Nutritional deficiency, unspecified',
  'vietnamese': 'Thiếu dinh dưỡng, không đặc hiệu',
}, {
  'id': 'E64',
  'english': 'Sequelae of malnutrition and other nutritional deficiencies',
  'vietnamese': 'Di chứng của suy dinh dưỡng và bệnh thiếu dinh dưỡng khác',
}, {
  'id': 'E64.0',
  'english': 'Sequelae of protein-energy malnutrition',
  'vietnamese': 'Di chứng của suy dinh dưỡng do thiếu protein - năng lượng',
}, {
  'id': 'E64.1',
  'english': 'Sequelae of vitamin A deficiency',
  'vietnamese': 'Di chứng của thiếu vitamin A',
}, {
  'id': 'E64.2',
  'english': 'Sequelae of vitamin C deficiency',
  'vietnamese': 'Di chứng của thiếu vitamin C',
}, {
  'id': 'E64.3',
  'english': 'Sequelae of rickets',
  'vietnamese': 'Di chứng của bệnh còi xương',
}, {
  'id': 'E64.8',
  'english': 'Sequelae of other nutritional deficiencies',
  'vietnamese': 'Di chứng của bệnh thiếu dinh dưỡng khác',
}, {
  'id': 'E64.9',
  'english': 'Sequelae of unspecified nutritional deficiency',
  'vietnamese': 'Di chứng của thiếu dinh dưỡng không đặc hiệu',
}, {'id': 'E65', 'english': 'Localized adiposity', 'vietnamese': 'Béo phì khu trú'}, {
  'id': 'E66',
  'english': 'Obesity',
  'vietnamese': 'Bệnh béo phì',
}, {
  'id': 'E66.0',
  'english': 'Obesity due to excess calories',
  'vietnamese': 'Béo phì do thừa calo',
}, {'id': 'E66.1', 'english': 'Drug-induced obesity', 'vietnamese': 'Béo phì do thuốc'}, {
  'id': 'E66.2',
  'english': 'Extreme obesity with alveolar hypoventilation',
  'vietnamese': 'Béo phì quá mức với giảm thông khí phế nang',
}, {'id': 'E66.8', 'english': 'Other obesity', 'vietnamese': 'Béo phì khác'}, {
  'id': 'E66.9',
  'english': 'Obesity, unspecified',
  'vietnamese': 'Béo phì, không đặc hiệu',
}, {
  'id': 'E67',
  'english': 'Other hyperalimentation',
  'vietnamese': 'Tình trạng thừa dinh dưỡng khác',
}, {'id': 'E67.0', 'english': 'Hypervitaminosis A', 'vietnamese': 'Thừa vitamin A'}, {
  'id': 'E67.1',
  'english': 'Hypercarotenaemia',
  'vietnamese': 'Tăng caroten máu',
}, {
  'id': 'E67.2',
  'english': 'Megavitamin-B6 syndrome',
  'vietnamese': 'Hội chứng Megavitamin-B6',
}, {
  'id': 'E67.3',
  'english': 'Hypervitaminosis D',
  'vietnamese': 'Thừa vitamin D',
}, {
  'id': 'E67.8',
  'english': 'Other specified hyperalimentation',
  'vietnamese': 'Thừa dinh dưỡng xác định khác',
}, {
  'id': 'E68',
  'english': 'Metabolic disorders',
  'vietnamese': 'Di chứng của thừa dinh dưỡng',
}, {
  'id': 'E70',
  'english': 'Disorders of aromatic amino-acid metabolism',
  'vietnamese': 'Rối loạn chuyển hóa acid amin thơm',
}, {
  'id': 'E70.0',
  'english': 'Classical phenylketonuria',
  'vietnamese': 'Phenyl-ceton niệu kinh điển',
}, {
  'id': 'E70.1',
  'english': 'Other hyperphenylalaninaemias',
  'vietnamese': 'Tăng phenylalanin máu khác',
}, {
  'id': 'E70.2',
  'english': 'Disorders of tyrosine metabolism',
  'vietnamese': 'Rối loạn chuyển hóa tyrosine',
}, {'id': 'E70.3', 'english': 'Albinism', 'vietnamese': 'Chứng Bạch tạng'}, {
  'id': 'E70.8',
  'english': 'Other disorders of aromatic amino-acid metabolism',
  'vietnamese': 'Rối loạn chuyển hóa khác của acid amin thơm',
}, {
  'id': 'E70.9',
  'english': 'Disorder of aromatic amino-acid metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa acid amin thơm không xác định.',
}, {
  'id': 'E71',
  'english': 'Disorders of branched-chain amino-acid metabolism and fatty-acid metabolism',
  'vietnamese': 'Rối loạn chuyển hóa acid amin chuỗi nhánh và rối loạn chuyển hóa acid béo',
}, {
  'id': 'E71.0',
  'english': 'Maple-syrup-urine disease',
  'vietnamese': 'Bệnh nước tiểu mùi sirô (Maple-syrup)',
}, {
  'id': 'E71.1',
  'english': 'Other disorders of branched-chain amino-acid metabolism',
  'vietnamese': 'Rối loạn khác của chuyển hóa acid amin chuỗi nhánh',
}, {
  'id': 'E71.2',
  'english': 'Disorder of branched-chain amino-acid metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa acid amin chuỗi phân nhánh, không đặc hiệu',
}, {
  'id': 'E71.3',
  'english': 'Disorders of fatty-acid metabolism',
  'vietnamese': 'Rối loạn chuyển hóa acid béo',
}, {
  'id': 'E72',
  'english': 'Other disorders of amino-acid metabolism',
  'vietnamese': 'Các rối loạn khác của chuyển hóa acid amin',
}, {
  'id': 'E72.0',
  'english': 'Disorders of amino-acid transport',
  'vietnamese': 'Rối loạn vận chuyển acid amin',
}, {
  'id': 'E72.1',
  'english': 'Disorders of sulfur-bearing amino-acid metabolism',
  'vietnamese': 'Rối loạn chuyển hóa acid amin chứa sulfur',
}, {
  'id': 'E72.2',
  'english': 'Disorders of urea cycle metabolism',
  'vietnamese': 'Rối loạn chuyển hóa chu trình urê',
}, {
  'id': 'E72.3',
  'english': 'Disorders of lysine and hydroxylysine metabolism',
  'vietnamese': 'Rối loạn chuyển hóa lysine và hydroxylysine',
}, {
  'id': 'E72.4',
  'english': 'Disorders of ornithine metabolism',
  'vietnamese': 'Rối loạn chuyển hóa ornithine',
}, {
  'id': 'E72.5',
  'english': 'Disorders of glycine metabolism',
  'vietnamese': 'Rối loạn chuyển hóa glycine',
}, {
  'id': 'E72.8',
  'english': 'Other specified disorders of amino-acid metabolism',
  'vietnamese': 'Các rối loạn xác định khác của chuyển hóa acid amin',
}, {
  'id': 'E72.9',
  'english': 'Disorder of amino-acid metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa acid amin, không đặc hiệu',
}, {'id': 'E73', 'english': 'Lactose intolerance', 'vietnamese': 'Không dung nạp lactose'}, {
  'id': 'E73.0',
  'english': 'Congenital lactase deficiency',
  'vietnamese': 'Thiếu men lactase bẩm sinh',
}, {
  'id': 'E73.1',
  'english': 'Secondary lactase deficiency',
  'vietnamese': 'Thiếu men lactase thứ phát',
}, {
  'id': 'E73.8',
  'english': 'Other lactose intolerance',
  'vietnamese': 'Không dung nạp lactose khác',
}, {
  'id': 'E73.9',
  'english': 'Lactose intolerance, unspecified',
  'vietnamese': 'Không dung nạp lactose, không đặc hiệu',
}, {
  'id': 'E74',
  'english': 'Other disorders of carbohydrate metabolism',
  'vietnamese': 'Rối loạn khác của chuyển hóa carbohydrat',
}, {
  'id': 'E74.0',
  'english': 'Glycogen storage disease',
  'vietnamese': 'Bệnh tích luỹ glycogen',
}, {
  'id': 'E74.1',
  'english': 'Disorders of fructose metabolism',
  'vietnamese': 'Rối loạn chuyển hóa fructose',
}, {
  'id': 'E74.2',
  'english': 'Disorders of galactose metabolism',
  'vietnamese': 'Rối loạn chuyển hóa galactose',
}, {
  'id': 'E74.3',
  'english': 'Other disorders of intestinal carbohydrate absorption',
  'vietnamese': 'Rối loạn khác của hấp thu carbohydrat ở ruột non',
}, {
  'id': 'E74.4',
  'english': 'Disorders of pyruvate metabolism and gluconeogenesis',
  'vietnamese': 'Rối loạn chuyển hóa pyruvat và tân tạo glucose',
}, {
  'id': 'E74.8',
  'english': 'Other specified disorders of carbohydrate metabolism',
  'vietnamese': 'Rối loạn xác định khác của chuyển hóa carbohydrat',
}, {
  'id': 'E74.9',
  'english': 'Disorder of carbohydrate metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa carbohydrat, không đặc hiệu',
}, {
  'id': 'E75',
  'english': 'Disorders of sphingolipid metabolism and other lipid storage disorders',
  'vietnamese': 'Rối loạn chuyển hóa sphingolipid và rối loạn tích luỹ lipid',
}, {'id': 'E75.0', 'english': 'GM2 gangliosidosis', 'vietnamese': 'Bệnh nhiễm gangliosid GM2'}, {
  'id': 'E75.1',
  'english': 'Other gangliosidosis',
  'vietnamese': 'Bệnh nhiễm gangliosid khác',
}, {
  'id': 'E75.2',
  'english': 'Other sphingolipidosis',
  'vietnamese': 'Bệnh nhiễm sphingolipid khác',
}, {
  'id': 'E75.3',
  'english': 'Sphingolipidosis, unspecified',
  'vietnamese': 'Bệnh nhiễm sphingolipid, không đặc hiệu',
}, {
  'id': 'E75.4',
  'english': 'Neuronal ceroid lipofuscinosis',
  'vietnamese': 'Bệnh lý tích tụ lipofuscin ở neuron (NCL)',
}, {
  'id': 'E75.5',
  'english': 'Other lipid storage disorders',
  'vietnamese': 'Rối loạn tích luỹ lipid khác',
}, {
  'id': 'E75.6',
  'english': 'Lipid storage disorder, unspecified',
  'vietnamese': 'Rối loạn tích luỹ lipid, không đặc hiệu',
}, {
  'id': 'E76',
  'english': 'Disorders of glycosaminoglycan metabolism',
  'vietnamese': 'Rối loạn chuyển hóa glycosaminoglycan',
}, {
  'id': 'E76.0',
  'english': 'Mucopolysaccharidosis, type I',
  'vietnamese': 'Nhiễm mucopolysaccharid, typ I',
}, {
  'id': 'E76.1',
  'english': 'Mucopolysaccharidosis, type II',
  'vietnamese': 'Nhiễm mucopolysaccharid, typ II',
}, {
  'id': 'E76.2',
  'english': 'Other mucopolysaccharidoses',
  'vietnamese': 'Nhiễm mucopolysaccharid khác',
}, {
  'id': 'E76.3',
  'english': 'Mucopolysaccharidosis, unspecified',
  'vietnamese': 'Nhiễm mucopolysaccharid không đặc hiệu',
}, {
  'id': 'E76.8',
  'english': 'Other disorders of glucosaminoglycan metabolism',
  'vietnamese': 'Rối loạn chuyển hóa glucosaminoglycan khác',
}, {
  'id': 'E76.9',
  'english': 'Disorder of glucosaminoglycan metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa glucosaminoglycan không đặc hiệu',
}, {
  'id': 'E77',
  'english': 'Disorders of glycoprotein metabolism',
  'vietnamese': 'Rối loạn chuyển hóa glycoprotein',
}, {
  'id': 'E77.0',
  'english': 'Defects in post-translational modification of lysosomal enzymes',
  'vietnamese': 'Khiếm khuyết trong sự biến đổi sau chuyển mã của các men tiêu bào',
}, {
  'id': 'E77.1',
  'english': 'Defects in glycoprotein degradation',
  'vietnamese': 'Khiếm khuyết trong quá trình phân hủy glycoprotein',
}, {
  'id': 'E77.8',
  'english': 'Other disorders of glycoprotein metabolism',
  'vietnamese': 'Rối loạn chuyển hóa glycoprotein khác',
}, {
  'id': 'E77.9',
  'english': 'Disorder of glycoprotein metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa glycoprotein, không đặc hiệu',
}, {
  'id': 'E78',
  'english': 'Disorders of lipoprotein metabolism and other lipidaemias',
  'vietnamese': 'Rối loạn chuyển hóa lipoprotein và tình trạng tăng lipid máu khác',
}, {
  'id': 'E78.0',
  'english': 'Pure hypercholesterolaemia',
  'vietnamese': 'Tăng cholesterol máu đơn thuần',
}, {
  'id': 'E78.1',
  'english': 'Pure hyperglyceridaemia',
  'vietnamese': 'Tăng triglycerid máu đơn thuần',
}, {'id': 'E78.2', 'english': 'Mixed hyperlipidaemia', 'vietnamese': 'Tăng lipid máu hỗn hợp'}, {
  'id': 'E78.3',
  'english': 'Hyperchylomicronaemia',
  'vietnamese': 'Tăng -chylomicron máu',
}, {'id': 'E78.4', 'english': 'Other hyperlipidaemia', 'vietnamese': 'Tăng lipid máu khác'}, {
  'id': 'E78.5',
  'english': 'Hyperlipidaemia, unspecified',
  'vietnamese': 'Tăng lipid máu, không đặc hiệu',
}, {'id': 'E78.6', 'english': 'Lipoprotein deficiency', 'vietnamese': 'Thiếu Lipoprotein'}, {
  'id': 'E78.8',
  'english': 'Other disorders of lipoprotein metabolism',
  'vietnamese': 'Rối loạn chuyển hóa lipoprotein khác',
}, {
  'id': 'E78.9',
  'english': 'Disorder of lipoprotein metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa lipoprotein không đặc hiệu',
}, {
  'id': 'E79',
  'english': 'Disorders of purine and pyrimidine metabolism',
  'vietnamese': 'Rối loạn chuyển hóa purine và pyrimidine',
}, {
  'id': 'E79.0',
  'english': 'Hyperuricaemia without signs of inflammatory arthritis and tophaceous disease',
  'vietnamese': 'Tăng acid uric máu không có biểu hiện của viêm khớp và bệnh tạo sỏi',
}, {'id': 'E79.1', 'english': 'Lesch-Nyhan syndrome', 'vietnamese': 'Hội chứng Lesch-Nyhan'}, {
  'id': 'E79.8',
  'english': 'Other disorders of purine and pyrimidine metabolism',
  'vietnamese': 'Rối loạn khác của chuyển hóa purine and pyrimidine',
}, {
  'id': 'E79.9',
  'english': 'Disorder of purine and pyrimidine metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa purine và pyrimidine không đặc hiệu',
}, {
  'id': 'E80',
  'english': 'Disorders of porphyrin and bilirubin metabolism',
  'vietnamese': 'Rối loạn chuyển hóa porphyrin và bilirubin',
}, {
  'id': 'E80.0',
  'english': 'Hereditary erythropoietic porphyria',
  'vietnamese': 'Rối loạn chuyển hóa porphyrin sinh hồng cầu di truyền',
}, {
  'id': 'E80.1',
  'english': 'Porphyria cutanea tarda',
  'vietnamese': 'Rối loạn chuyển hóa porphyrin biểu hiện muộn ở da',
}, {
  'id': 'E80.2',
  'english': 'Other porphyria',
  'vietnamese': 'Rối loạn chuyển hóa porphyrin máu khác',
}, {
  'id': 'E80.3',
  'english': 'Defects of catalase and peroxidase',
  'vietnamese': 'Khiếm khuyết men catalase và peroxidase',
}, {'id': 'E80.4', 'english': 'Gilbert s syndrome', 'vietnamese': 'Hội chứng Glibert'}, {
  'id': 'E80.5',
  'english': 'Crigler-Najjar syndrome',
  'vietnamese': 'Hội chứng Crigler-Najjar',
}, {
  'id': 'E80.6',
  'english': 'Other disorders of bilirubin metabolism',
  'vietnamese': 'Rối loạn chuyển hóa bilirubin khác',
}, {
  'id': 'E80.7',
  'english': 'Disorder of bilirubin metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa bilirubin, không đặc hiệu',
}, {
  'id': 'E83',
  'english': 'Disorders of mineral metabolism',
  'vietnamese': 'Rối loạn chuyển hóa chất khóang',
}, {
  'id': 'E83.0',
  'english': 'Disorders of copper metabolism (include Wilson disease)',
  'vietnamese': 'Rối loạn chuyển hóa đồng (bao gồm cả bệnh Wilson)',
}, {
  'id': 'E83.1',
  'english': 'Disorders of iron metabolism',
  'vietnamese': 'Rối loạn chuyển hóa sắt',
}, {
  'id': 'E83.2',
  'english': 'Disorders of zinc metabolism',
  'vietnamese': 'Rối loạn chuyển hóa kẽm',
}, {
  'id': 'E83.3',
  'english': 'Disorders of phosphorus metabolism',
  'vietnamese': 'Rối loạn chuyển hóa phospho và phosphatase',
}, {
  'id': 'E83.4',
  'english': 'Disorders of magnesium metabolism',
  'vietnamese': 'Rối loạn chuyển hóa magnie',
}, {
  'id': 'E83.5',
  'english': 'Disorders of calcium metabolism',
  'vietnamese': 'Rối loạn chuyển hóa calci',
}, {
  'id': 'E83.8',
  'english': 'Other disorders of mineral metabolism',
  'vietnamese': 'Rối loạn chuyển hóa chất khóang khác',
}, {
  'id': 'E83.9',
  'english': 'Disorder of mineral metabolism, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa chất khóang, không đặc hiệu',
}, {'id': 'E84', 'english': 'Cystic fibrosis', 'vietnamese': 'Xơ nang'}, {
  'id': 'E84.0',
  'english': 'Cystic fibrosis with pulmonary manifestations',
  'vietnamese': 'Xơ nang kèm biểu hiện tại phổi',
}, {
  'id': 'E84.1',
  'english': 'Cystic fibrosis with intestinal manifestations',
  'vietnamese': 'Xơ nang kèm biểu hiện tại ruột',
}, {
  'id': 'E84.8',
  'english': 'Cystic fibrosis with other manifestations',
  'vietnamese': 'Xơ nang với các biểu hiện khác',
}, {
  'id': 'E84.9',
  'english': 'Cystic fibrosis, unspecified',
  'vietnamese': 'Xơ nang, không đặc hiệu',
}, {'id': 'E85', 'english': 'Amyloidosis', 'vietnamese': 'thoái hóa dạng bột'}, {
  'id': 'E85.0',
  'english': 'Non-neuropathic heredofamilial amyloidosis',
  'vietnamese': 'thoái hóa dạng bột mang tính di truyền gia đình không có bệnh lý thần kinh',
}, {
  'id': 'E85.1',
  'english': 'Neuropathic heredofamilial amyloidosis',
  'vietnamese': 'thoái hóa dạng bột mang tính di truyền gia đình, có bệnh lý thần kinh',
}, {
  'id': 'E85.2',
  'english': 'Heredofamilial amyloidosis, unspecified',
  'vietnamese': 'thoái hóa dạng bột mang tính di truyền gia đình, không đặc hiệu',
}, {
  'id': 'E85.3',
  'english': 'Secondary systemic amyloidosis',
  'vietnamese': 'thoái hóa dạng bột toàn thân thứ phát',
}, {
  'id': 'E85.4',
  'english': 'Organ-limited amyloidosis',
  'vietnamese': 'thoái hóa dạng bột giới hạn ở cơ quan',
}, {'id': 'E85.8', 'english': 'Other amyloidosis', 'vietnamese': 'thoái hóa dạng bột khác'}, {
  'id': 'E85.9',
  'english': 'Amyloidosis, unspecified',
  'vietnamese': 'thoái hóa dạng bột, không đặc hiệu',
}, {'id': 'E86', 'english': 'Volume depletion', 'vietnamese': 'Giảm thể tích'}, {
  'id': 'E87',
  'english': 'Other disorders of fluid, electrolyte and acid-base balance',
  'vietnamese': 'Rối loạn cân bằng nước, điện giải và thăng bằng kiềm toan',
}, {
  'id': 'E87.0',
  'english': 'Hyperosmolality and hypernatraemia',
  'vietnamese': 'Tăng áp suất thẩm thấu và tăng natri máu',
}, {
  'id': 'E87.1',
  'english': 'Hypo-osmolality and hyponatraemia',
  'vietnamese': 'Giảm áp suất thẩm thấu và giảm Na máu',
}, {'id': 'E87.2', 'english': 'Acidosis', 'vietnamese': 'Nhiễm toan'}, {
  'id': 'E87.3',
  'english': 'Alkalosis',
  'vietnamese': 'Nhiễm kiềm',
}, {
  'id': 'E87.4',
  'english': 'Mixed disorder of acid-base balance',
  'vietnamese': 'Rối loạn cân bằng kiềm toan phối hợp',
}, {'id': 'E87.5', 'english': 'Hyperkalaemia', 'vietnamese': 'Tăng kali máu'}, {
  'id': 'E87.6',
  'english': 'Hypokalaemia',
  'vietnamese': 'Hạ kali máu',
}, {'id': 'E87.7', 'english': 'Fluid overload', 'vietnamese': 'Quá tải dịch'}, {
  'id': 'E87.8',
  'english': 'Other disorders of electrolyte and fluid balance, not elsewhere classified',
  'vietnamese': 'Rối loạn khác về cân bằng điện giải và nước, chưa được phân loại ở phần khác',
}, {
  'id': 'E88',
  'english': 'Other metabolic disorders',
  'vietnamese': 'Rối loạn chuyển hóa khác',
}, {
  'id': 'E88.0',
  'english': 'Disorders of plasma-protein metabolism, not elsewhere classified',
  'vietnamese': 'Rối loạn chuyển hóa protein huyết tương, chưa được phân loại ở phần khác',
}, {
  'id': 'E88.1',
  'english': 'Lipodystrophy, not elsewhere classified',
  'vietnamese': 'Loạn dưỡng mỡ, chưa được phân loại ở phần khác',
}, {
  'id': 'E88.2',
  'english': 'Lipomatosis, not elsewhere classified',
  'vietnamese': 'Bệnh u mỡ, chưa được phân loại ở phần khác',
}, {
  'id': 'E88.3',
  'english': 'Tumour lysis syndrome',
  'vietnamese': 'Hội chứng ly giải khối u',
}, {
  'id': 'E88.8',
  'english': 'Other specified metabolic disorders',
  'vietnamese': 'Rối loạn chuyển hóa xác định khác',
}, {
  'id': 'E88.9',
  'english': 'Metabolic disorder, unspecified',
  'vietnamese': 'Rối loạn chuyển hóa, không đặc hiệu',
}, {
  'id': 'E89',
  'english': 'Postprocedural endocrine and metabolic disorders, not elsewhere classified',
  'vietnamese': 'Rối loạn nội tiết và chuyển hóa sau điều trị, chưa được phân loại ở phần khác',
}, {
  'id': 'E89.0',
  'english': 'Postprocedural hypothyroidism',
  'vietnamese': 'Suy giáp sau điều trị',
}, {
  'id': 'E89.1',
  'english': 'Postprocedural hypoinsulinaemia',
  'vietnamese': 'Hạ insulin máu sau điều trị',
}, {
  'id': 'E89.2',
  'english': 'Postprocedural hypoparathyroidism',
  'vietnamese': 'Suy cận giáp sau điều trị',
}, {
  'id': 'E89.3',
  'english': 'Postprocedural hypopituitarism',
  'vietnamese': 'Suy tuyến yên sau điều trị',
}, {
  'id': 'E89.4',
  'english': 'Postprocedural ovarian failure',
  'vietnamese': 'Suy buồng trứng sau điều trị',
}, {
  'id': 'E89.5',
  'english': 'Postprocedural testicular hypofunction',
  'vietnamese': 'Suy tinh hoàn sau điều trị',
}, {
  'id': 'E89.6',
  'english': 'Postprocedural adrenocortical(-medullary) hypofunction',
  'vietnamese': 'Suy vỏ (-tủy) thượng thận sau điều trị',
}, {
  'id': 'E89.8',
  'english': 'Other postprocedural endocrine and metabolic disorders',
  'vietnamese': 'Rối loạn nội tiết và chuyển hóa sau điều trị khác',
}, {
  'id': 'E89.9',
  'english': 'Postprocedural endocrine and metabolic disorder, unspecified',
  'vietnamese': 'Rối loạn nội tiết và chuyển hóa sau điều trị, không đặc hiệu',
}, {
  'id': 'E90*',
  'english': 'Nutritional and metabolic disorders in disease classified elsewhere',
  'vietnamese': 'Rối loạn chuyển hóa và dinh dưỡng trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'F00*',
  'english': 'Dementia in Alzheimer s disease G30.-†)',
  'vietnamese': 'Mất trí trong bệnh Alzheimer (G30.-†)',
}, {
  'id': 'F00.0*',
  'english': 'Dementia in Alzheimer s disease with early onset (G30.0†)',
  'vietnamese': 'Mất trí trong bệnh Alzheimer khởi phát sớm (G30.0†)',
}, {
  'id': 'F00.1*',
  'english': 'Dementia in Alzheimer s disease with late onset (G30.1†)',
  'vietnamese': 'Mất trí trong bệnh Alzheimer khởi phát muộn (G30.1†)',
}, {
  'id': 'F00.2*',
  'english': 'Dementia in Alzheimer s disease, atypical or mixed type (G30.8†)',
  'vietnamese': 'Mất trí trong bệnh Alzheimer, thể không điển hình hoặc thể hỗn hợp (G30.8†)',
}, {
  'id': 'F00.9*',
  'english': 'Dementia in Alzheimer s disease, unspecified (G30.9†)',
  'vietnamese': 'Mất trí trong bệnh Alzheimer, không xác định (G30.9†)',
}, {'id': 'F01', 'english': 'Vascular dementia', 'vietnamese': 'Mất trí trong bệnh mạch máu'}, {
  'id': 'F01.0',
  'english': 'Vascular dementia of acute onset',
  'vietnamese': 'Mất trí trong bệnh mạch máu, khởi động cấp',
}, {
  'id': 'F01.1',
  'english': 'Multi-infarct dementia',
  'vietnamese': 'Mất trí do nhồi máu đa ổ',
}, {
  'id': 'F01.2',
  'english': 'Subcortical vascular dementia',
  'vietnamese': 'Mất trí trong bệnh mạch máu dưới vỏ',
}, {
  'id': 'F01.3',
  'english': 'Mixed cortical and subcortical vascular dementia',
  'vietnamese': 'Mất trí trong bệnh mạch máu hỗn hợp vỏ não và dưới vỏ',
}, {
  'id': 'F01.8',
  'english': 'Other vascular dementia',
  'vietnamese': 'Mất trí trong bệnh mạch máu khác',
}, {
  'id': 'F01.9',
  'english': 'Vascular dementia, unspecified',
  'vietnamese': 'Mất trí trong bệnh mạch máu, không biệt định',
}, {
  'id': 'F02*',
  'english': 'Dementia in other diseases classified elsewhere',
  'vietnamese': 'Mất trí trong các bệnh khác đã được xếp loại ở chỗ khác',
}, {
  'id': 'F02.0*',
  'english': 'Dementia in Pick s disease (G31.0†)',
  'vietnamese': 'Mất trí trong bệnh Pick (G31.0†)',
}, {
  'id': 'F02.1*',
  'english': 'Dementia in Creutzfeldt-Jakob disease (A81.0†)',
  'vietnamese': 'Mất trí trong bệnh Creutzfeldt-Jakob (A81.0†)',
}, {
  'id': 'F02.2*',
  'english': 'Dementia in Huntington s disease (G10†)',
  'vietnamese': 'Mất trí trong bệnh Huntington (G10†)',
}, {
  'id': 'F02.3*',
  'english': 'Dementia in Parkinson s disease (G20†)',
  'vietnamese': 'Mất trí trong bệnh Parkinson (G20†)',
}, {
  'id': 'F02.4*',
  'english': 'Dementia in human immunodeficiency virus [HIV] disease (B22.0†)',
  'vietnamese': 'Mất trí trong bệnh (HIV) nhiễm virus gây suy giảm miễn dịch ở người (B22.0†)',
}, {
  'id': 'F02.8*',
  'english': 'Dementia in other specified diseases classified elsewhere',
  'vietnamese': 'Mất trí trong các bệnh lý đặc biệt khác đã được xếp loại ở chỗ khác',
}, {'id': 'F03', 'english': 'Unspecified dementia', 'vietnamese': 'Mất trí không biệt định'}, {
  'id': 'F04',
  'english': 'Organic amnesic syndrome, not induced by alcohol and other psychoative substances',
  'vietnamese': 'Hội chứng quên thực tổn không do rượu và chất tác động tâm thần khác',
}, {
  'id': 'F05',
  'english': 'Delirium, not induced by alcohol and other psychoactive substances',
  'vietnamese': 'Sảng không do rượu và chất tác động tâm thần khác',
}, {
  'id': 'F05.0',
  'english': 'Delirium not superimposed on dementia, so described',
  'vietnamese': 'Sảng không gối lên mất trí như đã mô tả',
}, {
  'id': 'F05.1',
  'english': 'Delirium superimposed on dementia',
  'vietnamese': 'Sảng gối lên mất trí',
}, {'id': 'F05.8', 'english': 'Other delirium', 'vietnamese': 'Sảng khác'}, {
  'id': 'F05.9',
  'english': 'Delirium, unspecified',
  'vietnamese': 'Sảng, không biệt định',
}, {
  'id': 'F06',
  'english': 'Other mental disorders due to brain damage and dysfunction and to physical disease',
  'vietnamese': 'Rối loạn tâm thần khác do tổn thương và rối loạn chức năng não và do bệnh cơ thể',
}, {'id': 'F06.0', 'english': 'Organic hallucinosis', 'vietnamese': 'Các ảo giác thực tổn'}, {
  'id': 'F06.1',
  'english': 'Organic catatonic disorder',
  'vietnamese': 'Rối loạn căng trương lực thực tổn',
}, {
  'id': 'F06.2',
  'english': 'Organic delusional [schizophrenia-like] disorder',
  'vietnamese': 'Rối loạn hoang tưởng thực tổn [giống tâm thần phân liệt]',
}, {
  'id': 'F06.3',
  'english': 'Organic mood [affective] disorders',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] thực tổn',
}, {
  'id': 'F06.4',
  'english': 'Organic anxiety disorder',
  'vietnamese': 'Rối loạn lo âu thực tổn',
}, {
  'id': 'F06.5',
  'english': 'Organic dissociative disorder',
  'vietnamese': 'Rối loạn phân ly thực tổn',
}, {
  'id': 'F06.6',
  'english': 'Organic emotionally labile [asthenic] disorder',
  'vietnamese': 'Rối loạn cảm xúc không ổn định [suy nhược] thực tổn',
}, {
  'id': 'F06.7',
  'english': 'Mild cognitive disorder',
  'vietnamese': 'Rối loạn nhận thức nhẹ',
}, {
  'id': 'F06.8',
  'english': 'Other specified mental disorders due to brain damage and dysfunction and to physical disease',
  'vietnamese': 'Các rối loạn tâm thần biệt định khác do tổn thương não, rối loạn chức năng não và bệnh lý cơ thể',
}, {
  'id': 'F06.9',
  'english': 'Unspecified mental disorder due to brain damage and dysfunction and to physical disease',
  'vietnamese': 'Các rối loạn tâm thần không biệt định do tổn thương não và rối loạn chức năng não và bệnh lý cơ thể',
}, {
  'id': 'F07',
  'english': 'Personality and behavioural disorders due to brain disease, damage and dysfunction',
  'vietnamese': 'Các rối loạn nhân cách và hành vi do bệnh lý não, tổn thương não và rối loạn chức năng não',
}, {
  'id': 'F07.0',
  'english': 'Organic personality disorder',
  'vietnamese': 'Rối loạn nhân cách thực tổn',
}, {
  'id': 'F07.1',
  'english': 'Postencephalitic syndrome',
  'vietnamese': 'Hội chứng sau viêm não',
}, {
  'id': 'F07.2',
  'english': 'Postconcussional syndrome',
  'vietnamese': 'Hội chứng sau chấn động não',
}, {
  'id': 'F07.8',
  'english': 'Other organic personality and behavioural disorders due to brain disease, damage and dysfunction',
  'vietnamese': 'Rối loạn nhân cách và hành vi thực tổn khác do bệnh não, tổn thương và rối loạn chức năng não',
}, {
  'id': 'F07.9',
  'english': 'Unspecified organic personality and behavioural disorder due to brain disease, damage and dysfunction',
  'vietnamese': 'Rối loạn nhân cách và hành vi thực thể không biệt định do bệnh não, tổn thương và rối loạn chức năng não',
}, {
  'id': 'F09',
  'english': 'Unspecified organic or symptomatic mental disorder',
  'vietnamese': 'Rối loạn tâm thần thực tổn hoặc triệu chứng không xác định',
}, {
  'id': 'F10',
  'english': 'Mental and behavioural disorders due to use of alcohol',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu',
}, {
  'id': 'F10.0',
  'english': 'Mental and behavioural disorders due to use of alcohol (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Nhiễm độc cấp)',
}, {
  'id': 'F10.1',
  'english': 'Mental and behavioural disorders due to use of alcohol (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Sử dụng gây hại)',
}, {
  'id': 'F10.2',
  'english': 'Mental and behavioural disorders due to use of alcohol (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Hội chứng nghiện)',
}, {
  'id': 'F10.3',
  'english': 'Mental and behavioural disorders due to use of alcohol (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Trạng thái cai)',
}, {
  'id': 'F10.4',
  'english': 'Mental and behavioural disorders due to use of alcohol (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Trạng thái cai với mê sảng)',
}, {
  'id': 'F10.5',
  'english': 'Mental and behavioural disorders due to use of alcohol (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Rối loạn tâm thần)',
}, {
  'id': 'F10.6',
  'english': 'Mental and behavioural disorders due to use of alcohol (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Hội chứng quên)',
}, {
  'id': 'F10.7',
  'english': 'Mental and behavioural disorders due to use of alcohol (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F10.8',
  'english': 'Mental and behavioural disorders due to use of alcohol (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F10.9',
  'english': 'Mental and behavioural disorders due to use of alcohol (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng rượu (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F11',
  'english': 'Mental and behavioural disorders due to use of opioids',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện',
}, {
  'id': 'F11.0',
  'english': 'Mental and behavioural disorders due to use of opioids (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Nhiễm độc cấp)',
}, {
  'id': 'F11.1',
  'english': 'Mental and behavioural disorders due to use of opioids (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Sử dụng gây hại)',
}, {
  'id': 'F11.2',
  'english': 'Mental and behavioural disorders due to use of opioids (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Hội chứng nghiện)',
}, {
  'id': 'F11.3',
  'english': 'Mental and behavioural disorders due to use of opioids (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Trạng thái cai)',
}, {
  'id': 'F11.4',
  'english': 'Mental and behavioural disorders due to use of opioids (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Trạng thái cai với mê sảng)',
}, {
  'id': 'F11.5',
  'english': 'Mental and behavioural disorders due to use of opioids (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Rối loạn tâm thần)',
}, {
  'id': 'F11.6',
  'english': 'Mental and behavioural disorders due to use of opioids (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Hội chứng quên)',
}, {
  'id': 'F11.7',
  'english': 'Mental and behavioural disorders due to use of opioids (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F11.8',
  'english': 'Mental and behavioural disorders due to use of opioids (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F11.9',
  'english': 'Mental and behavioural disorders due to use of opioids (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các dạng thuốc phiện (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F12',
  'english': 'Mental and behavioural disorders due to use of cannabinoids',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa',
}, {
  'id': 'F12.0',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Nhiễm độc cấp)',
}, {
  'id': 'F12.1',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Sử dụng gây hại)',
}, {
  'id': 'F12.2',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Hội chứng nghiện)',
}, {
  'id': 'F12.3',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Trạng thái cai)',
}, {
  'id': 'F12.4',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Trạng thái cai với mê sảng)',
}, {
  'id': 'F12.5',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Rối loạn tâm thần)',
}, {
  'id': 'F12.6',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Hội chứng quên)',
}, {
  'id': 'F12.7',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F12.8',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F12.9',
  'english': 'Mental and behavioural disorders due to use of cannabinoids (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cần sa (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F13',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ',
}, {
  'id': 'F13.0',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Nhiễm độc cấp)',
}, {
  'id': 'F13.1',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Sử dụng gây hại)',
}, {
  'id': 'F13.2',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Hội chứng nghiện)',
}, {
  'id': 'F13.3',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Trạng thái cai)',
}, {
  'id': 'F13.4',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Trạng thái cai với mê sảng)',
}, {
  'id': 'F13.5',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Rối loạn tâm thần)',
}, {
  'id': 'F13.6',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Hội chứng quên)',
}, {
  'id': 'F13.7',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F13.8',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F13.9',
  'english': 'Mental and behavioural disorders due to use of sedatives or hypnotics (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất an thần hoặc các thuốc ngủ (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F14',
  'english': 'Mental and behavioural disorders due to use of cocaine',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain',
}, {
  'id': 'F14.0',
  'english': 'Mental and behavioural disorders due to use of cocaine (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Nhiễm độc cấp)',
}, {
  'id': 'F14.1',
  'english': 'Mental and behavioural disorders due to use of cocaine (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Sử dụng gây hại)',
}, {
  'id': 'F14.2',
  'english': 'Mental and behavioural disorders due to use of cocaine (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Hội chứng nghiện)',
}, {
  'id': 'F14.3',
  'english': 'Mental and behavioural disorders due to use of cocaine (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Trạng thái cai)',
}, {
  'id': 'F14.4',
  'english': 'Mental and behavioural disorders due to use of cocaine (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Trạng thái cai với mê sảng)',
}, {
  'id': 'F14.5',
  'english': 'Mental and behavioural disorders due to use of cocaine (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Rối loạn tâm thần)',
}, {
  'id': 'F14.6',
  'english': 'Mental and behavioural disorders due to use of cocaine (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Hội chứng quên)',
}, {
  'id': 'F14.7',
  'english': 'Mental and behavioural disorders due to use of cocaine (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F14.8',
  'english': 'Mental and behavioural disorders due to use of cocaine (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F14.9',
  'english': 'Mental and behavioural disorders due to use of cocaine (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng cocain (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F15',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein',
}, {
  'id': 'F15.0',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Nhiễm độc cấp)',
}, {
  'id': 'F15.1',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Sử dụng gây hại)',
}, {
  'id': 'F15.2',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Hội chứng nghiện)',
}, {
  'id': 'F15.3',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Trạng thái cai)',
}, {
  'id': 'F15.4',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Trạng thái cai với mê sảng)',
}, {
  'id': 'F15.5',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Rối loạn tâm thần)',
}, {
  'id': 'F15.6',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Hội chứng quên)',
}, {
  'id': 'F15.7',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F15.8',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F15.9',
  'english': 'Mental and behavioural disorders due to use of other stimulants, including caffeine (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng chất kích thích khác, bao gồm cả caffein (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F16',
  'english': 'Mental and behavioural disorders due to use of hallucinogens',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác',
}, {
  'id': 'F16.0',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Nhiễm độc cấp)',
}, {
  'id': 'F16.1',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Sử dụng gây hại)',
}, {
  'id': 'F16.2',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Hội chứng nghiện)',
}, {
  'id': 'F16.3',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Trạng thái cai)',
}, {
  'id': 'F16.4',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Trạng thái cai với mê sảng)',
}, {
  'id': 'F16.5',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Rối loạn tâm thần)',
}, {
  'id': 'F16.6',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Hội chứng quên)',
}, {
  'id': 'F16.7',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F16.8',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F16.9',
  'english': 'Mental and behavioural disorders due to use of hallucinogens (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F17',
  'english': 'Mental and behavioural disorders due to use of tobaco',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá',
}, {
  'id': 'F17.0',
  'english': 'Mental and behavioural disorders due to use of tobaco (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Nhiễm độc cấp)',
}, {
  'id': 'F17.1',
  'english': 'Mental and behavioural disorders due to use of tobaco (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Sử dụng gây hại)',
}, {
  'id': 'F17.2',
  'english': 'Mental and behavioural disorders due to use of tobaco (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Hội chứng nghiện)',
}, {
  'id': 'F17.3',
  'english': 'Mental and behavioural disorders due to use of tobaco (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Trạng thái cai)',
}, {
  'id': 'F17.4',
  'english': 'Mental and behavioural disorders due to use of tobaco (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Trạng thái cai với mê sảng)',
}, {
  'id': 'F17.5',
  'english': 'Mental and behavioural disorders due to use of tobaco (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Rối loạn tâm thần)',
}, {
  'id': 'F17.6',
  'english': 'Mental and behavioural disorders due to use of tobaco (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Hội chứng quên)',
}, {
  'id': 'F17.7',
  'english': 'Mental and behavioural disorders due to use of tobaco (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F17.8',
  'english': 'Mental and behavioural disorders due to use of tobaco (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F17.9',
  'english': 'Mental and behavioural disorders due to use of tobaco (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng thuốc lá (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F18',
  'english': 'Mental and behavioural disorders due to use of volatile solvents',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi',
}, {
  'id': 'F18.0',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Nhiễm độc cấp)',
}, {
  'id': 'F18.1',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Sử dụng gây hại)',
}, {
  'id': 'F18.2',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Hội chứng nghiện)',
}, {
  'id': 'F18.3',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Trạng thái cai)',
}, {
  'id': 'F18.4',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Trạng thái cai với mê sảng)',
}, {
  'id': 'F18.5',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Rối loạn tâm thần)',
}, {
  'id': 'F18.6',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Hội chứng quên)',
}, {
  'id': 'F18.7',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F18.8',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F18.9',
  'english': 'Mental and behavioural disorders due to use of volatile solvents (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng dung môi dễ bay hơi (Rối loạn tâm thần và hành vi không biệt định)',
}, {
  'id': 'F19',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác',
}, {
  'id': 'F19.0',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Acute intoxication)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Nhiễm độc cấp)',
}, {
  'id': 'F19.1',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Harmful use)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Sử dụng gây hại)',
}, {
  'id': 'F19.2',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Dependence syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Hội chứng nghiện)',
}, {
  'id': 'F19.3',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Withdrawal state)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Trạng thái cai)',
}, {
  'id': 'F19.4',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Withdrawal state with delirium)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Trạng thái cai với mê sảng)',
}, {
  'id': 'F19.5',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Rối loạn tâm thần)',
}, {
  'id': 'F19.6',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Amnesic syndrome)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Hội chứng quên)',
}, {
  'id': 'F19.7',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Residual and late-onset psychotic disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Rối loạn loạn thần di chứng và khởi phát muộn)',
}, {
  'id': 'F19.8',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Other mental and behavioural disorders)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Rối loạn tâm thần và hành vi khác)',
}, {
  'id': 'F19.9',
  'english': 'Mental and behavioural disorders due to multiple drug use and of other psychoactive substances (Unspecified mental and behavioural disorder)',
  'vietnamese': 'Rối loạn tâm thần và hành vi do sử dụng nhiều loại ma túy và chất tác động tâm thần khác (Rối loạn tâm thần và hành vi không biệt định)',
}, {'id': 'F20', 'english': 'Schizophrenia', 'vietnamese': 'Tâm thần phân liệt'}, {
  'id': 'F20.0',
  'english': 'Paranoid schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể paranoid',
}, {
  'id': 'F20.1',
  'english': 'Hebephrenic schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể thanh xuân',
}, {
  'id': 'F20.2',
  'english': 'Catatonic schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể căng trương lực',
}, {
  'id': 'F20.3',
  'english': 'Undifferentiated schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể không biệt định',
}, {
  'id': 'F20.4',
  'english': 'Post-schizophrenic depression',
  'vietnamese': 'Tâm thần phân liệt thể trầm cảm sau phân liệt',
}, {
  'id': 'F20.5',
  'english': 'Residual schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể di chứng',
}, {
  'id': 'F20.6',
  'english': 'Simple schizophrenia',
  'vietnamese': 'Tâm thần phân liệt thể đơn thuần',
}, {
  'id': 'F20.8',
  'english': 'Other schizophrenia',
  'vietnamese': 'Các thể tâm thần phân liệt khác',
}, {
  'id': 'F20.9',
  'english': 'Schizophrenia, unspecified',
  'vietnamese': 'Tâm thần phân liệt thể không đặc hiệu',
}, {'id': 'F21', 'english': 'Schizotypla disorder', 'vietnamese': 'Rối loạn loại phân liệt'}, {
  'id': 'F22',
  'english': 'Persistent delusional disorders',
  'vietnamese': 'Rối loạn hoang tưởng dai dẳng',
}, {'id': 'F22.0', 'english': 'Delusional disorder', 'vietnamese': 'Rối loạn hoang tưởng'}, {
  'id': 'F22.8',
  'english': 'Other persistent delusional disorders',
  'vietnamese': 'Rối loạn hoang tưởng dai dẳng khác',
}, {
  'id': 'F22.9',
  'english': 'Persistent delusional disorder, unspecified',
  'vietnamese': 'Rối loạn hoang tưởng dai dẳng không biệt định',
}, {
  'id': 'F23',
  'english': 'Acute and transient psychotic disorders',
  'vietnamese': 'Rối loạn loạn thần cấp và nhất thời',
}, {
  'id': 'F23.0',
  'english': 'Acute polymorphic psychotic disorder without symptoms of schizophrenia',
  'vietnamese': 'Rối loạn loạn thần cấp đa dạng không có các triệu chứng của bệnh tâm thần phân liệt',
}, {
  'id': 'F23.1',
  'english': 'Acute polymorphic psychotic disorder with symptoms of schizophrenia',
  'vietnamese': 'Rối loạn loạn thần cấp đa dạng với triệu chứng của bệnh tâm thần phân liệt',
}, {
  'id': 'F23.2',
  'english': 'Acute schizophrenia-like psychotic disorder',
  'vietnamese': 'Rối loạn loạn thần cấp giống phân liệt',
}, {
  'id': 'F23.3',
  'english': 'Other acute predominantly delusional psychotic disorders',
  'vietnamese': 'Các rối loạn loạn thần cấp khác chủ yếu hoang tưởng',
}, {
  'id': 'F23.8',
  'english': 'Other acute and transient psychotic disorders',
  'vietnamese': 'Rối loạn loạn thần cấp và nhất thời khác',
}, {
  'id': 'F23.9',
  'english': 'Acute and transient psychotic disorder, unspecified',
  'vietnamese': 'Rối loạn loạn thần cấp và nhất thời không biệt định',
}, {
  'id': 'F24',
  'english': 'Induced delusional disorder',
  'vietnamese': 'Rối loạn hoang tưởng cảm ứng',
}, {
  'id': 'F25',
  'english': 'Schizoaffective disorders',
  'vietnamese': 'Rối loạn phân liệt cảm xúc',
}, {
  'id': 'F25.0',
  'english': 'Schizoaffective disorder, manic type',
  'vietnamese': 'Rối loạn phân liệt cảm xúc, loại hưng cảm',
}, {
  'id': 'F25.1',
  'english': 'Schizoaffective disorder, depressive type',
  'vietnamese': 'Rối loạn phân liệt cảm xúc, loại trầm cảm',
}, {
  'id': 'F25.2',
  'english': 'Schizoaffective disorder, mixed type',
  'vietnamese': 'Rối loạn phân liệt cảm xúc, loại hỗn hợp',
}, {
  'id': 'F25.8',
  'english': 'Other schizoaffective disorders',
  'vietnamese': 'Rối loạn phân liệt cảm xúc khác',
}, {
  'id': 'F25.9',
  'english': 'Schizoaffective disorder, unspecified',
  'vietnamese': 'Rối loạn phân liệt cảm xúc không biệt định',
}, {
  'id': 'F28',
  'english': 'Other nonorganic psychotic disorders',
  'vietnamese': 'Rối loạn loạn thần không thực tổn khác',
}, {
  'id': 'F29',
  'english': 'Unspecified nonorganic psychosis',
  'vietnamese': 'Loạn thần không thực tổn không biệt định',
}, {'id': 'F30', 'english': 'Manic episode', 'vietnamese': 'Giai đoạn hưng cảm'}, {
  'id': 'F30.0',
  'english': 'Hypomania',
  'vietnamese': 'Hưng cảm nhẹ',
}, {
  'id': 'F30.1',
  'english': 'Mania without psychotic symptoms',
  'vietnamese': 'Hưng cảm không có các triệu chứng loạn thần',
}, {
  'id': 'F30.2',
  'english': 'Mania with psychotic symptoms',
  'vietnamese': 'Hưng cảm với các triệu chứng loạn thần',
}, {
  'id': 'F30.8',
  'english': 'Other manic episodes',
  'vietnamese': 'Các giai đoạn hưng cảm khác',
}, {
  'id': 'F30.9',
  'english': 'Manic episode, unspecified',
  'vietnamese': 'Giai đoạn hưng cảm không đặc hiệu',
}, {
  'id': 'F31',
  'english': 'Bipolar affective disorder',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực',
}, {
  'id': 'F31.0',
  'english': 'Bipolar affective disorder, current episode hypomanic',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn hưng cảm nhẹ',
}, {
  'id': 'F31.1',
  'english': 'Bipolar affective disorder, current episode manic without psychotic symptoms',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn hưng cảm không có các triệu chứng loạn thần',
}, {
  'id': 'F31.2',
  'english': 'Bipolar affective disorder, current episode manic with psychotic symptoms',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn hưng cảm có các triệu chứng loạn thần',
}, {
  'id': 'F31.3',
  'english': 'Bipolar affective disorder, current episode mild or moderate depression',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn trầm cảm nhẹ hoặc trung bình',
}, {
  'id': 'F31.4',
  'english': 'Bipolar affective disorder, current episode severe depression without psychotic symptoms',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn trầm cảm nặng không có các triệu chứng loạn thần',
}, {
  'id': 'F31.5',
  'english': 'Bipolar affective disorder, current episode severe depression with psychotic symptoms',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn trầm cảm nặng với các triệu chứng loạn thần',
}, {
  'id': 'F31.6',
  'english': 'Bipolar affective disorder, current episode mixed',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại giai đoạn hỗn hợp',
}, {
  'id': 'F31.7',
  'english': 'Bipolar affective disorder, currently in remission',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực, hiện tại thuyên giảm',
}, {
  'id': 'F31.8',
  'english': 'Other bipolar affective disorders',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực khác',
}, {
  'id': 'F31.9',
  'english': 'Bipolar affective disorder, unspecified',
  'vietnamese': 'Rối loạn cảm xúc lưỡng cực không biệt định',
}, {'id': 'F32', 'english': 'Depressive episode', 'vietnamese': 'Giai đoạn trầm cảm'}, {
  'id': 'F32.0',
  'english': 'Mild depressive episode',
  'vietnamese': 'Giai đoạn trầm cảm nhẹ',
}, {
  'id': 'F32.1',
  'english': 'Moderate depressive episode',
  'vietnamese': 'Giai đoạn trầm cảm vừa',
}, {
  'id': 'F32.2',
  'english': 'Severe depressive episode without psychotic symptoms',
  'vietnamese': 'Giai đoạn trầm cảm nặng không có các triệu chứng loạn thần',
}, {
  'id': 'F32.3',
  'english': 'Severe depressive episode with psychotic symptoms',
  'vietnamese': 'Giai đoạn trầm cảm nặng với các triệu chứng loạn thần',
}, {
  'id': 'F32.8',
  'english': 'Other depressive episodes',
  'vietnamese': 'Giai đoạn trầm cảm khác',
}, {
  'id': 'F32.9',
  'english': 'Depressive episode, unspecified',
  'vietnamese': 'Giai đoạn trầm cảm không đặc hiệu',
}, {
  'id': 'F33',
  'english': 'Recurrent depressive disorder',
  'vietnamese': 'Rối loạn trầm cảm tái diễn',
}, {
  'id': 'F33.0',
  'english': 'Recurrent depressive disorder, current episode mild',
  'vietnamese': 'Rối loạn trầm cảm tái diễn, hiện tại giai đoạn nhẹ',
}, {
  'id': 'F33.1',
  'english': 'Recurrent depressive disorder, current episode moderate',
  'vietnamese': 'Rối loạn trầm cảm tái diễn, hiện tại giai đoạn vừa',
}, {
  'id': 'F33.2',
  'english': 'Recurrent depressive disorder, current episode severe without psychotic symptoms',
  'vietnamese': 'Rối loạn trầm cảm tái diễn, hiện tại giai đoạn nặng không có triệu chứng loạn thần',
}, {
  'id': 'F33.3',
  'english': 'Recurrent depressive disorder, current episode severe with psychotic symptoms',
  'vietnamese': 'Rối loạn trầm cảm tái diễn, hiện tại giai đoạn nặng với các triệu chứng loạn thần',
}, {
  'id': 'F33.4',
  'english': 'Recurrent depressive disorder, currently in remission',
  'vietnamese': 'Rối loạn trầm cảm tái diễn, hiện đang thuyên giảm',
}, {
  'id': 'F33.8',
  'english': 'Other recurrent depressive disorders',
  'vietnamese': 'Rối loạn trầm cảm tái diễn khác',
}, {
  'id': 'F33.9',
  'english': 'Recurrent depressive disorder, unspecified',
  'vietnamese': 'Rối loạn trầm cảm tái phát, không biệt định',
}, {
  'id': 'F34',
  'english': 'Persistent mood [affective] disorders',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] dai dẳng',
}, {'id': 'F34.0', 'english': 'Cyclothymia', 'vietnamese': 'Khí sắc chu kỳ'}, {
  'id': 'F34.1',
  'english': 'Dysthymia',
  'vietnamese': 'Loạn khí sắc',
}, {
  'id': 'F34.8',
  'english': 'Other persistent mood [affective] disorders',
  'vietnamese': 'Các rối loạn khí sắc [cảm xúc] dai dẳng khác',
}, {
  'id': 'F34.9',
  'english': 'Persistent mood [affective] disorder, unspecified',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] dai dẳng không đặc hiệu',
}, {
  'id': 'F38',
  'english': 'Other mood [affective] disorders',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] khác',
}, {
  'id': 'F38.0',
  'english': 'Other single mood [affective] disorders',
  'vietnamese': 'Các rối loạn khí sắc [cảm xúc] đơn độc khác',
}, {
  'id': 'F38.1',
  'english': 'Other recurrent mood [affective] disorders',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] tái diễn',
}, {
  'id': 'F38.8',
  'english': 'Other specified mood [affective] disorders',
  'vietnamese': 'Rối loạn khí sắc [cảm xúc] biệt định khác',
}, {
  'id': 'F39',
  'english': 'Unspecified mood (affective) disorder',
  'vietnamese': 'Rối loạn khí sắc (cảm xúc) biệt định',
}, {
  'id': 'F40',
  'english': 'Phobic anxiety disorders',
  'vietnamese': 'Rối loạn lo âu ám ảnh sợ hãi',
}, {'id': 'F40.0', 'english': 'Agoraphobia', 'vietnamese': 'Ám ảnh sợ khoảng trống'}, {
  'id': 'F40.1',
  'english': 'Social phobias',
  'vietnamese': 'Ám ảnh sợ xã hội',
}, {
  'id': 'F40.2',
  'english': 'Specific (isolated) phobias',
  'vietnamese': 'Ám ảnh sợ đặc hiệu (riêng lẻ)',
}, {
  'id': 'F40.8',
  'english': 'Other phobic anxiety disorders',
  'vietnamese': 'Các rối loạn lo âu ám ảnh sợ khác',
}, {
  'id': 'F40.9',
  'english': 'Phobic anxiety disorder, unspecified',
  'vietnamese': 'Rối loạn lo âu ám ảnh sợ, không biệt định',
}, {'id': 'F41', 'english': 'Other anxiety disorders', 'vietnamese': 'Các rối loạn lo âu khác'}, {
  'id': 'F41.0',
  'english': 'Panic disorder [episodic paroxysmal anxiety]',
  'vietnamese': 'Rối loạn hoảng sợ [lo âu kịch phát từng giai đoạn]',
}, {
  'id': 'F41.1',
  'english': 'Generalized anxiety disorder',
  'vietnamese': 'Rối loạn lo âu lan toả',
}, {
  'id': 'F41.2',
  'english': 'Mixed anxiety and depressive disorder',
  'vietnamese': 'Rối loạn hỗn hợp lo âu và trầm cảm',
}, {
  'id': 'F41.3',
  'english': 'Other mixed anxiety disorders',
  'vietnamese': 'Các rối loạn lo âu hỗn hợp khác',
}, {
  'id': 'F41.8',
  'english': 'Other specified anxiety disorders',
  'vietnamese': 'Các rối loạn lo âu biệt định khác',
}, {
  'id': 'F41.9',
  'english': 'Anxiety disorder, unspecified',
  'vietnamese': 'Rối loạn lo âu, không biệt định',
}, {
  'id': 'F42',
  'english': 'Obsessive-compulsive disorder',
  'vietnamese': 'Rối loạn ám ảnh nghi thức',
}, {
  'id': 'F42.0',
  'english': 'Predominantly obsessional thoughts or ruminations',
  'vietnamese': 'Những ý tưởng hoặc nghiền ngẫm ám ảnh chiếm ưu thế',
}, {
  'id': 'F42.1',
  'english': 'Predominantly compulsive acts [obsessional rituals]',
  'vietnamese': 'Các hành vi nghi thức chiếm ưu thế [các nghi thức ám ảnh]',
}, {
  'id': 'F42.2',
  'english': 'Mixed obsessional thoughts and acts',
  'vietnamese': 'Các ý tưởng và các hành vi ám ảnh hỗn hợp',
}, {
  'id': 'F42.8',
  'english': 'Other obsessive-compulsive disorders',
  'vietnamese': 'Rối loạn ám ảnh nghi thức khác',
}, {
  'id': 'F42.9',
  'english': 'Obsessive-compulsive disorder, unspecified',
  'vietnamese': 'Rối loạn ám ảnh nghi thức, không biệt định',
}, {
  'id': 'F43',
  'english': 'Reaction to severe stress, and adjustment disorders',
  'vietnamese': 'Phản ứng với stress trầm trọng và rối loạn sự thích ứng',
}, {'id': 'F43.0', 'english': 'Acute stress reaction', 'vietnamese': 'Phản ứng stress cấp'}, {
  'id': 'F43.1',
  'english': 'Post-traumatic stress disorder',
  'vietnamese': 'Rối loạn stress sau sang chấn',
}, {
  'id': 'F43.2',
  'english': 'Adjustment disorders',
  'vietnamese': 'các rối loạn sự thích ứng',
}, {
  'id': 'F43.8',
  'english': 'Other reactions to severe stress',
  'vietnamese': 'Phản ứng khác với stress trầm trọng',
}, {
  'id': 'F43.9',
  'english': 'Reaction to severe stress, unspecified',
  'vietnamese': 'Phản ứng với stress trầm trọng, không đặc hiệu',
}, {
  'id': 'F44',
  'english': 'Dissociative [conversion] disorders',
  'vietnamese': 'Các rối loạn phân ly [chuyển di]',
}, {'id': 'F44.0', 'english': 'Dissociative amnesia', 'vietnamese': 'Quên phân ly'}, {
  'id': 'F44.1',
  'english': 'Dissociative fugue',
  'vietnamese': 'Trốn nhà phân ly',
}, {'id': 'F44.2', 'english': 'Dissociative stupor', 'vietnamese': 'Sững sờ phân ly'}, {
  'id': 'F44.3',
  'english': 'Trance and possession disorders',
  'vietnamese': 'Rối loạn lên đồng và bị xâm nhập',
}, {
  'id': 'F44.4',
  'english': 'Dissociative motor disorders',
  'vietnamese': 'Rối loạn vận động phân ly',
}, {'id': 'F44.5', 'english': 'Dissociative convulsions', 'vietnamese': 'Co giật phân ly'}, {
  'id': 'F44.6',
  'english': 'Dissociative anaesthesia and sensory loss',
  'vietnamese': 'Tê và mất cảm giác phân ly',
}, {
  'id': 'F44.7',
  'english': 'Mixed dissociative [conversion] disorders',
  'vietnamese': 'Rối loạn phân ly [chuyển di] hỗn hợp',
}, {
  'id': 'F44.8',
  'english': 'Other dissociative [conversion] disorders',
  'vietnamese': 'Rối loạn phân ly [chuyển di] khác',
}, {
  'id': 'F44.9',
  'english': 'Dissociative [conversion] disorder, unspecified',
  'vietnamese': 'Rối loạn phân ly [chuyển di], không biệt định',
}, {'id': 'F45', 'english': 'Somatoform disorders', 'vietnamese': 'Rối loạn dạng cơ thể'}, {
  'id': 'F45.0',
  'english': 'Somatization disorder',
  'vietnamese': 'Rối loạn cơ thể hóa',
}, {
  'id': 'F45.1',
  'english': 'Undifferentiated somatoform disorder',
  'vietnamese': 'Rối loạn dạng cơ thể không biệt định',
}, {'id': 'F45.2', 'english': 'Hypochondriacal disorder', 'vietnamese': 'Rối loạn nghi bệnh'}, {
  'id': 'F45.3',
  'english': 'Somatoform autonomic dysfunction',
  'vietnamese': 'Loạn chức năng thần kinh tự trị dạng cơ thể',
}, {
  'id': 'F45.4',
  'english': 'Persistent somatoform pain disorder',
  'vietnamese': 'Rối loạn đau dạng cơ thể dai dẳng',
}, {
  'id': 'F45.8',
  'english': 'Other somatoform disorders',
  'vietnamese': 'Rối loạn dạng cơ thể khác',
}, {
  'id': 'F45.9',
  'english': 'Somatoform disorder, unspecified',
  'vietnamese': 'Rối loạn dạng cơ thể, không biệt định',
}, {
  'id': 'F48',
  'english': 'Other neurotic disorders',
  'vietnamese': 'Các rối loạn tâm căn khác',
}, {
  'id': 'F48.0',
  'english': 'Neurasthenia',
  'vietnamese': 'Bệnh suy nhược thần kinh',
}, {
  'id': 'F48.1',
  'english': 'Depersonalization-derealization syndrome',
  'vietnamese': 'Hội chứng giải thể nhân cách-tri giác sai thực tại',
}, {
  'id': 'F48.8',
  'english': 'Other specified neurotic disorders',
  'vietnamese': 'Rối loạn tâm căn biệt định khác',
}, {
  'id': 'F48.9',
  'english': 'Neurotic disorder, unspecified',
  'vietnamese': 'Rối loạn tâm căn, không biệt định',
}, {'id': 'F50', 'english': 'Eating disorders', 'vietnamese': 'Các rối loạn ăn uống'}, {
  'id': 'F50.0',
  'english': 'Anorexia nervosa',
  'vietnamese': 'Chán ăn tâm thần',
}, {
  'id': 'F50.1',
  'english': 'Atypical anorexia nervosa',
  'vietnamese': 'Chán ăn tâm thần không điển hình',
}, {'id': 'F50.2', 'english': 'Bulimia nervosa', 'vietnamese': 'Ăn vô độ tâm thần'}, {
  'id': 'F50.3',
  'english': 'Atypical bulimia nervosa',
  'vietnamese': 'Ăn vô độ tâm thần không điển hình',
}, {
  'id': 'F50.4',
  'english': 'Overeating associated with other psychological disturbances',
  'vietnamese': 'Chứng ăn nhiều kết hợp với các rối loạn tâm lý khác',
}, {
  'id': 'F50.5',
  'english': 'Vomiting associated with other psychological disturbances',
  'vietnamese': 'Nôn kết hợp với các rối loạn tâm lý khác',
}, {'id': 'F50.8', 'english': 'Other eating disorders', 'vietnamese': 'Rối loạn ăn uống khác'}, {
  'id': 'F50.9',
  'english': 'Eating disorder, unspecified',
  'vietnamese': 'Rối loạn ăn uống, không biệt định',
}, {
  'id': 'F51',
  'english': 'Nonorganic sleep disorders',
  'vietnamese': 'Rối loạn giấc ngủ không thực tổn',
}, {'id': 'F51.0', 'english': 'Nonorganic insomnia', 'vietnamese': 'Mất ngủ không thực tổn'}, {
  'id': 'F51.1',
  'english': 'Nonorganic hypersomnia',
  'vietnamese': 'Ngủ nhiều không thực tổn',
}, {
  'id': 'F51.2',
  'english': 'Nonorganic disorder of the sleep-wake schedule',
  'vietnamese': 'Rối loạn nhịp thức ngủ không thực tổn',
}, {'id': 'F51.3', 'english': 'Sleepwalking [somnambulism]', 'vietnamese': 'Chứng miên hành'}, {
  'id': 'F51.4',
  'english': 'Sleep terrors [night terrors]',
  'vietnamese': 'Hoảng sợ khi ngủ [hoảng sợ ban đêm]',
}, {'id': 'F51.5', 'english': 'Nightmares', 'vietnamese': 'Ác mộng'}, {
  'id': 'F51.8',
  'english': 'Other nonorganic sleep disorders',
  'vietnamese': 'Rối loạn giấc ngủ không thực tổn khác',
}, {
  'id': 'F51.9',
  'english': 'Nonorganic sleep disorder, unspecified',
  'vietnamese': 'Rối loạn giấc ngủ không thực tổn, không biệt định',
}, {
  'id': 'F52',
  'english': 'Sexual dysfunction, not caused by organic disorder or disease',
  'vietnamese': 'Loạn chức năng tình dục, không do rối loạn hoặc bệnh thực tổn',
}, {
  'id': 'F52.0',
  'english': 'Lack or loss of sexual desire',
  'vietnamese': 'Thiếu hoặc mất ham muốn tình dục',
}, {
  'id': 'F52.1',
  'english': 'Sexual aversion and lack of sexual enjoyment',
  'vietnamese': 'Ghét sợ tình dục và thiếu thích thú tình dục',
}, {
  'id': 'F52.2',
  'english': 'Failure of genital response',
  'vietnamese': 'Thất bại trong đáp ứng tình dục',
}, {'id': 'F52.3', 'english': 'Orgasmic dysfunction', 'vietnamese': 'Loạn chức năng cực khóai'}, {
  'id': 'F52.4',
  'english': 'Premature ejaculation',
  'vietnamese': 'Phóng tinh sớm',
}, {
  'id': 'F52.5',
  'english': 'Nonorganic vaginismus',
  'vietnamese': 'Co thắt âm đạo không do nguyên nhân thực thể',
}, {
  'id': 'F52.6',
  'english': 'Nonorganic dyspareunia',
  'vietnamese': 'Đau khi giao hợp không thực tổn',
}, {
  'id': 'F52.7',
  'english': 'Excessive sexual drive',
  'vietnamese': 'Xu hướng tình dục quá độ',
}, {
  'id': 'F52.8',
  'english': 'Other sexual dysfunction, not caused by organic disorder or disease',
  'vietnamese': 'Loạn chức năng tình dục khác, không do bệnh lý hoặc rối loạn thực tổn',
}, {
  'id': 'F52.9',
  'english': 'Unspecified sexual dysfunction, not caused by organic disorder or disease',
  'vietnamese': 'Loạn chức năng tình dục không biệt định khác, không do bệnh lý hoặc rối loạn thực tổn',
}, {
  'id': 'F53',
  'english': 'Mental and behavioural disorders associated with the puerperium, not elsewhere classified',
  'vietnamese': 'Rối loạn hành vi và tâm thần kết hợp với thời kì sinh đẻ, không phân loại ở nơi khác',
}, {
  'id': 'F53.0',
  'english': 'Mild mental and behavioural disorders associated with the puerperium, not elsewhere classified',
  'vietnamese': 'Rối loạn hành vi và tâm thần nhẹ kết hợp với thời kì sinh đẻ, không phân loại nơi khác',
}, {
  'id': 'F53.1',
  'english': 'Severe mental and behavioural disorders associated with the puerperium, not elsewhere classified',
  'vietnamese': 'Rối loạn hành vi và tâm thần nặng kết hợp với thời kì sinh đẻ, không phân loại nơi khác',
}, {
  'id': 'F53.8',
  'english': 'Other mental and behavioural disorders associated with the puerperium, not elsewhere classified',
  'vietnamese': 'Các rối loạn hành vi và tâm thần khác kết hợp với thời kì sinh đẻ, không phân loại nơi khác',
}, {
  'id': 'F53.9',
  'english': 'Puerperal mental disorder, unspecified',
  'vietnamese': 'Rối loạn tâm thần kết hợp với thời kì sinh đẻ, không biệt định',
}, {
  'id': 'F54',
  'english': 'Psychological and behavioural factors associated with disorders or diseases classified elsewhere',
  'vietnamese': 'Các nhân tố tâm lý và hành vi kết hợp với rối loạn hoặc các bệnh phân loại ở nơi khác',
}, {
  'id': 'F55',
  'english': 'Abuse of non-dependence-producing substances',
  'vietnamese': 'Lạm dụng các chất không gây nghiện',
}, {
  'id': 'F59',
  'english': 'Unspecified behavioural syndromes associated with physiological disturbances and physical factors',
  'vietnamese': 'Các hội chứng hành vi ứng xử không biệt định kết hợp với rối loạn sinh lý và yếu tố thể chất',
}, {
  'id': 'F60',
  'english': 'Specific personality disorders',
  'vietnamese': 'Rối loạn nhân cách đặc hiệu',
}, {
  'id': 'F60.0',
  'english': 'Paranoid personality disorder',
  'vietnamese': 'Rối loạn nhân cách paranoid',
}, {
  'id': 'F60.1',
  'english': 'Schizoid personality disorder',
  'vietnamese': 'Rối loạn nhân cách dạng phân liệt',
}, {
  'id': 'F60.2',
  'english': 'Dissocial personality disorder',
  'vietnamese': 'Rối loạn nhân cách chống đối xã hội',
}, {
  'id': 'F60.3',
  'english': 'Emotionally unstable personality disorder',
  'vietnamese': 'Rối loạn nhân cách cảm xúc không ổn định',
}, {
  'id': 'F60.4',
  'english': 'Histrionic personality disorder',
  'vietnamese': 'Rối loạn nhân cách kịch tính',
}, {
  'id': 'F60.5',
  'english': 'Anankastic personality disorder',
  'vietnamese': 'Rối loạn nhân cách ám ảnh nghi thức',
}, {
  'id': 'F60.6',
  'english': 'Anxious [avoidant] personality disorder',
  'vietnamese': 'Rối loạn nhân cách lo âu [tránh né]',
}, {
  'id': 'F60.7',
  'english': 'Dependent personality disorder',
  'vietnamese': 'Rối loạn nhân cách phụ thuộc',
}, {
  'id': 'F60.8',
  'english': 'Other specific personality disorders',
  'vietnamese': 'Rối loạn nhân cách đặc hiệu khác',
}, {
  'id': 'F60.9',
  'english': 'Personality disorder, unspecified',
  'vietnamese': 'Rối loạn nhân cách, không biệt định',
}, {
  'id': 'F61',
  'english': 'Mixed and other personality disorders',
  'vietnamese': 'Rối loạn nhân cách khác và hỗn hợp',
}, {
  'id': 'F62',
  'english': 'Enduring personality changes, not attributable to brain damage and disease',
  'vietnamese': 'Biến đổi nhân cách kéo dài, không thể quy cho một tổn thương hay bệnh não',
}, {
  'id': 'F62.0',
  'english': 'Enduring personality change after catastrophic experience',
  'vietnamese': 'Thay đổi nhân cách kéo dài sau một sự kiện thảm họa',
}, {
  'id': 'F62.1',
  'english': 'Enduring personality change after psychiatric illness',
  'vietnamese': 'Thay đổi nhân cách kéo dài sau bệnh tâm thần',
}, {
  'id': 'F62.8',
  'english': 'Other enduring personality changes',
  'vietnamese': 'Thay đổi nhân cách kéo dài khác',
}, {
  'id': 'F62.9',
  'english': 'Enduring personality change, unspecified',
  'vietnamese': 'Thay đổi nhân cách kéo dài không biệt định',
}, {
  'id': 'F63',
  'english': 'Habit and impulse disorders',
  'vietnamese': 'Rối loạn thói quen và xung động',
}, {'id': 'F63.0', 'english': 'Pathological gambling', 'vietnamese': 'Đánh bạc bệnh lý'}, {
  'id': 'F63.1',
  'english': 'Pathological fire-setting [pyromania]',
  'vietnamese': 'Gây cháy bệnh lý',
}, {
  'id': 'F63.2',
  'english': 'Pathological stealing [kleptomania]',
  'vietnamese': 'Trộm cắp bệnh lý',
}, {'id': 'F63.3', 'english': 'Trichotillomania', 'vietnamese': 'Nhổ tóc'}, {
  'id': 'F63.8',
  'english': 'Other habit and impulse disorders',
  'vietnamese': 'Rối loạn thói quen và xung động khác',
}, {
  'id': 'F63.9',
  'english': 'Habit and impulse disorder, unspecified',
  'vietnamese': 'Rối loạn thói quen và xung động không biệt định',
}, {
  'id': 'F64',
  'english': 'Gender identity disorders',
  'vietnamese': 'Các rối loạn phân định giới tính',
}, {'id': 'F64.0', 'english': 'Transsexualism', 'vietnamese': 'Loạn dục chuyển giới'}, {
  'id': 'F64.1',
  'english': 'Dual-role transvestism',
  'vietnamese': 'Loạn dục cải trang, vai trò kép',
}, {
  'id': 'F64.2',
  'english': 'Gender identity disorder of childhood',
  'vietnamese': 'Rối loạn xác định giới tính ở trẻ em',
}, {
  'id': 'F64.8',
  'english': 'Other gender identity disorders',
  'vietnamese': 'Rối loạn xác định giới tính khác',
}, {
  'id': 'F64.9',
  'english': 'Gender identity disorder, unspecified',
  'vietnamese': 'Rối loạn phân định giới tính không biệt định',
}, {
  'id': 'F65',
  'english': 'Disorders of sexual preference',
  'vietnamese': 'Rối loạn trong ưa chuộng tình dục',
}, {'id': 'F65.0', 'english': 'Fetishism', 'vietnamese': 'Loạn dục đồ vật'}, {
  'id': 'F65.1',
  'english': 'Fetishistic transvestism',
  'vietnamese': 'Loạn dục cải trang đồ vật',
}, {'id': 'F65.2', 'english': 'Exhibitionism', 'vietnamese': 'Loạn dục phô bày'}, {
  'id': 'F65.3',
  'english': 'Voyeurism',
  'vietnamese': 'Loạn dục nhìn trộm',
}, {'id': 'F65.4', 'english': 'Paedophilia', 'vietnamese': 'Loạn dục với trẻ em'}, {
  'id': 'F65.5',
  'english': 'Sadomasochism',
  'vietnamese': 'Loạn dục gây đau chủ động và bị động',
}, {
  'id': 'F65.6',
  'english': 'Multiple disorders of sexual preference',
  'vietnamese': 'Đa rối loạn ưa chuộng tình dục',
}, {
  'id': 'F65.8',
  'english': 'Other disorders of sexual preference',
  'vietnamese': 'Rối loạn ưa chuộng tình dục khác',
}, {
  'id': 'F65.9',
  'english': 'Disorder of sexual preference, unspecified',
  'vietnamese': 'Rối loạn ưa chuộng tình dục, không biệt định',
}, {
  'id': 'F66',
  'english': 'Psychological and behavioural disorders associated with sexual development and orientation',
  'vietnamese': 'Những rối loạn tâm lý và hành vi kết hợp với sự phát triển và định hướng tình dục',
}, {
  'id': 'F66.0',
  'english': 'Sexual maturation disorder',
  'vietnamese': 'Rối loạn về sự trưởng thành tình dục',
}, {
  'id': 'F66.1',
  'english': 'Egodystonic sexual orientation',
  'vietnamese': 'Định hướng tình dục loạn trương lực bản thân',
}, {
  'id': 'F66.2',
  'english': 'Sexual relationship disorder',
  'vietnamese': 'Rối loạn quan hệ tình dục',
}, {
  'id': 'F66.8',
  'english': 'Other psychosexual development disorders',
  'vietnamese': 'Những rối loạn phát triển tâm lý tình dục khác',
}, {
  'id': 'F66.9',
  'english': 'Psychosexual development disorder, unspecified',
  'vietnamese': 'Rối loạn phát triển tâm lý tình dục, không biệt định',
}, {
  'id': 'F68',
  'english': 'Other disorders of adult personality and behaviour',
  'vietnamese': 'Rối loạn khác về hành vi và nhân cách ở người trưởng thành',
}, {
  'id': 'F68.0',
  'english': 'Elaboration of physical symptoms for psychological reasons',
  'vietnamese': 'Hình thành các triệu chứng cơ thể vì lý do tâm lý',
}, {
  'id': 'F68.1',
  'english': 'Intentional production or feigning of symptoms or disabilities, either physical or psychological [factitious disorder]',
  'vietnamese': 'Dụng ý đưa ra hay giả tạo các triệu chứng hoặc rối loạn hoạt năng cơ thể hoặc tâm lý [rối loạn giả tạo]',
}, {
  'id': 'F68.8',
  'english': 'Other specified disorders of adult personality and behaviour',
  'vietnamese': 'Rối loạn biệt định khác về nhân cách và hành vi ở người trưởng thành',
}, {
  'id': 'F69',
  'english': 'Unspecified disorder of adult personality and behaviour',
  'vietnamese': 'Rối loạn không biệt định về nhân cách và hành vi ở người trưởng thành',
}, {
  'id': 'F70',
  'english': 'Mild mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần nhẹ',
}, {
  'id': 'F71',
  'english': 'Moderate mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần trung bình',
}, {
  'id': 'F72',
  'english': 'Severe mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần nặng',
}, {
  'id': 'F73',
  'english': 'Profound mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần nghiêm trọng',
}, {
  'id': 'F78',
  'english': 'Other mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần khác',
}, {
  'id': 'F79',
  'english': 'Unspecified mental retardation',
  'vietnamese': 'Chậm phát triển tâm thần không biệt định',
}, {
  'id': 'F80',
  'english': 'Specific developmental disorders of speech and language',
  'vietnamese': 'Các rối loạn đặc hiệu về phát triển lời nói và ngôn ngữ',
}, {
  'id': 'F80.0',
  'english': 'Specific speech articulation disorder',
  'vietnamese': 'Rối loạn đặc hiệu trong sự kết âm',
}, {
  'id': 'F80.1',
  'english': 'Expressive language disorder',
  'vietnamese': 'Rối loạn ngôn ngữ biểu hiện',
}, {
  'id': 'F80.2',
  'english': 'Receptive language disorder',
  'vietnamese': 'Rối loạn ngôn ngữ tiếp nhận',
}, {
  'id': 'F80.3',
  'english': 'Acquired aphasia with epilepsy [Landau-Kleffner]',
  'vietnamese': 'Vong ngôn mắc phải với động kinh [Landau-Kleffner]',
}, {
  'id': 'F80.8',
  'english': 'Other developmental disorders of speech and language',
  'vietnamese': 'Rối loạn phát triển về lời nói và ngôn ngữ khác',
}, {
  'id': 'F80.9',
  'english': 'Developmental disorder of speech and language, unspecified',
  'vietnamese': 'Rối loạn phát triển về lời nói và ngôn ngữ không đặc hiệu',
}, {
  'id': 'F81',
  'english': 'Specific developmental disorders of scholastic skills',
  'vietnamese': 'Rối loạn đặc hiệu về phát triển các kỹ năng ở trường',
}, {
  'id': 'F81.0',
  'english': 'Specific reading disorder',
  'vietnamese': 'Rối loạn đặc hiệu về đọc',
}, {
  'id': 'F81.1',
  'english': 'Specific spelling disorder',
  'vietnamese': 'Rối loạn đặc hiệu về chính tả',
}, {
  'id': 'F81.2',
  'english': 'Specific disorder of arithmetical skills',
  'vietnamese': 'Rối loạn đặc hiệu về kỹ năng tính toán',
}, {
  'id': 'F81.3',
  'english': 'Mixed disorder of scholastic skills',
  'vietnamese': 'Rối loạn hỗn hợp các kỹ năng ở nhà trường',
}, {
  'id': 'F81.8',
  'english': 'Other developmental disorders of scholastic skills',
  'vietnamese': 'Rối loạn khác về sự phát triển các kỹ năng ở trường',
}, {
  'id': 'F81.9',
  'english': 'Developmental disorder of scholastic skills, unspecified',
  'vietnamese': 'Rối loạn phát triển của kỹ năng ở trường, không biệt định',
}, {
  'id': 'F82',
  'english': 'Specific developmental disorder of motor function',
  'vietnamese': 'Rối loạn đặc hiệu sự phát triển chức\nnăng vận động',
}, {
  'id': 'F83',
  'english': 'Mixed specific developmental disorders',
  'vietnamese': 'Các rối loạn đặc hiệu hỗn hợp của\nsự phát triển',
}, {
  'id': 'F84',
  'english': 'Pervasive developmental disorders',
  'vietnamese': 'Rối loạn lan tỏa sự phát triển',
}, {'id': 'F84.0', 'english': 'Childhood autism', 'vietnamese': 'Tính tự kỷ ở trẻ em'}, {
  'id': 'F84.1',
  'english': 'Atypical autism',
  'vietnamese': 'Tính tự kỷ không điển hình',
}, {'id': 'F84.2', 'english': 'Rett s syndrome', 'vietnamese': 'Hội chứng Rett'}, {
  'id': 'F84.3',
  'english': 'Other childhood disintegrative disorder',
  'vietnamese': 'Rối loạn phân rã khác của trẻ em',
}, {
  'id': 'F84.4',
  'english': 'Overactive disorder associated with mental retardation and stereotyped movements',
  'vietnamese': 'Rối loạn tăng hoạt động kết hợp với chậm phát triển tâm thần và các động tác định hình',
}, {'id': 'F84.5', 'english': 'Asperger s syndrome', 'vietnamese': 'Hội chứng Asperger'}, {
  'id': 'F84.8',
  'english': 'Other pervasive developmental disorders',
  'vietnamese': 'Các rối loạn lan tỏa khác của sự phát triển',
}, {
  'id': 'F84.9',
  'english': 'Pervasive developmental disorder, unspecified',
  'vietnamese': 'Rối loạn phát triển lan tỏa không biệt định',
}, {
  'id': 'F88',
  'english': 'Other disorders of psychological development',
  'vietnamese': 'Các rối loạn khác của phát triển tâm lý',
}, {
  'id': 'F89',
  'english': 'Unspecified disorder of psychological development',
  'vietnamese': 'Rối loạn không biệt định của phát triển tâm lý',
}, {'id': 'F90', 'english': 'Hyperkinetic disorders', 'vietnamese': 'Các rối loạn tăng động'}, {
  'id': 'F90.0',
  'english': 'Disturbance of activity and attention',
  'vietnamese': 'Rối loạn của hoạt động và chú ý',
}, {
  'id': 'F90.1',
  'english': 'Hyperkinetic conduct disorder',
  'vietnamese': 'Rối loạn hành vi tăng động',
}, {
  'id': 'F90.8',
  'english': 'Other hyperkinetic disorders',
  'vietnamese': 'Rối loạn tăng động khác',
}, {
  'id': 'F90.9',
  'english': 'Hyperkinetic disorder, unspecified',
  'vietnamese': 'Rối loạn tăng động, không biệt định',
}, {'id': 'F91', 'english': 'Conduct disorders', 'vietnamese': 'Rối loạn hành vi'}, {
  'id': 'F91.0',
  'english': 'Conduct disorder confined to the family context',
  'vietnamese': 'Rối loạn hành vi khu trú trong môi trường gia đình',
}, {
  'id': 'F91.1',
  'english': 'Unsocialized conduct disorder',
  'vietnamese': 'Rối loạn hành vi ở những người kém thích ứng xã hội',
}, {
  'id': 'F91.2',
  'english': 'Socialized conduct disorder',
  'vietnamese': 'Rối loạn cư xử xã hội hóa',
}, {
  'id': 'F91.3',
  'english': 'Oppositional defiant disorder',
  'vietnamese': 'Rối loạn hành vi ở những người còn thích ứng xã hội',
}, {
  'id': 'F91.8',
  'english': 'Other conduct disorders',
  'vietnamese': 'Các rối loạn hành vi khác',
}, {
  'id': 'F91.9',
  'english': 'Conduct disorder, unspecified',
  'vietnamese': 'Rối loạn hành vi, không biệt định',
}, {
  'id': 'F92',
  'english': 'Mixed disorders of conduct and emotions',
  'vietnamese': 'Các rối loạn hỗn hợp về hành vi và cảm xúc',
}, {
  'id': 'F92.0',
  'english': 'Depressive conduct disorder',
  'vietnamese': 'Rối loạn hành vi trầm cảm',
}, {
  'id': 'F92.8',
  'english': 'Other mixed disorders of conduct and emotions',
  'vietnamese': 'Rối loạn hỗn hợp của hành vi và cảm xúc khác',
}, {
  'id': 'F92.9',
  'english': 'Mixed disorder of conduct and emotions, unspecified',
  'vietnamese': 'Rối loạn hỗn hợp của hành vi và cảm xúc không biệt định',
}, {
  'id': 'F93',
  'english': 'Emotional disorders with onset specific to childhood',
  'vietnamese': 'Rối loạn cảm xúc với sự khởi phát đặc biệt ở trẻ em',
}, {
  'id': 'F93.0',
  'english': 'Separation anxiety disorder of childhood',
  'vietnamese': 'Rối loạn lo âu chia ly ở trẻ em',
}, {
  'id': 'F93.1',
  'english': 'Phobic anxiety disorder of childhood',
  'vietnamese': 'Rối loạn ám ảnh sợ ở trẻ em',
}, {
  'id': 'F93.2',
  'english': 'Social anxiety disorder of childhood',
  'vietnamese': 'Rối loạn lo âu xã hội ở trẻ em',
}, {
  'id': 'F93.3',
  'english': 'Sibling rivalry disorder',
  'vietnamese': 'Rối loạn ganh tỵ đối với anh chị em ruột',
}, {
  'id': 'F93.8',
  'english': 'Other childhood emotional disorders',
  'vietnamese': 'Rối loạn cảm xúc khác ở trẻ em',
}, {
  'id': 'F93.9',
  'english': 'Childhood emotional disorder, unspecified',
  'vietnamese': 'Rối loạn cảm xúc ở tuổi trẻ em, không biệt định',
}, {
  'id': 'F94',
  'english': 'Disorders of social functioning with onset specific to childhood and adolescence',
  'vietnamese': 'Các rối loạn hoạt động xã hội khởi phát đặc biệt ở tuổi trẻ em và thanh thiếu niên',
}, {'id': 'F94.0', 'english': 'Elective mutism', 'vietnamese': 'Không nói chọn lọc'}, {
  'id': 'F94.1',
  'english': 'Reactive attachment disorder of childhood',
  'vietnamese': 'Rối loạn phản ứng trong sự gắn bó ở tuổi trẻ em',
}, {
  'id': 'F94.2',
  'english': 'Disinhibited attachment disorder of childhood',
  'vietnamese': 'Rối loạn sự gắn bó giải ức chế của trẻ em',
}, {
  'id': 'F94.8',
  'english': 'Other childhood disorders of social functioning',
  'vietnamese': 'Rối loạn khác ở trẻ em về hoạt động xã hội',
}, {
  'id': 'F94.9',
  'english': 'Childhood disorder of social functioning, unspecified',
  'vietnamese': 'Rối loạn hoạt động xã hội ở trẻ em không biệt định',
}, {'id': 'F95', 'english': 'Tic disorders', 'vietnamese': 'Các rối loạn Tic'}, {
  'id': 'F95.0',
  'english': 'Transient tic disorder',
  'vietnamese': 'Rối loạn Tic nhất thời',
}, {
  'id': 'F95.1',
  'english': 'Chronic motor or vocal tic disorder',
  'vietnamese': 'Rối loạn Tic vận động hoặc lời nói mãn tính',
}, {
  'id': 'F95.2',
  'english': 'Combined vocal and multiple motor tic disorder [de la Tourette]',
  'vietnamese': 'Rối loạn kết hợp Tic lời nói với Tic vận động nhiều loại [Hội chứng Tourette]',
}, {'id': 'F95.8', 'english': 'Other tic disorders', 'vietnamese': 'Các rối loạn Tic khác'}, {
  'id': 'F95.9',
  'english': 'Tic disorder, unspecified',
  'vietnamese': 'Rối loạn Tic, không biệt định',
}, {
  'id': 'F98',
  'english': 'Other behavioural and emotional disorders with onset usually occurring in childhood and adolescence',
  'vietnamese': 'Các rối loạn tác phong và cảm xúc khác thường khởi phát ở lứa tuổi trẻ em và thanh thiếu niên',
}, {'id': 'F98.0', 'english': 'Nonorganic enuresis', 'vietnamese': 'Đái dầm không thực tổn'}, {
  'id': 'F98.1',
  'english': 'Nonorganic encopresis',
  'vietnamese': 'Ỉa bậy không do thực tổn',
}, {
  'id': 'F98.2',
  'english': 'Feeding disorder of infancy and childhood',
  'vietnamese': 'Rối loạn ăn uống ở trẻ dưới một năm và trẻ em',
}, {
  'id': 'F98.3',
  'english': 'Pica of infancy and childhood',
  'vietnamese': 'Chứng ăn chất không dinh dưỡng ở trẻ dưới một năm và trẻ em',
}, {
  'id': 'F98.4',
  'english': 'Stereotyped movement disorders',
  'vietnamese': 'Rối loạn động tác định hình',
}, {'id': 'F98.5', 'english': 'Stuttering [stammering]', 'vietnamese': 'Nói lắp'}, {
  'id': 'F98.6',
  'english': 'Cluttering',
  'vietnamese': 'Nói lúng búng',
}, {
  'id': 'F98.8',
  'english': 'Other specified behavioural and emotional disorders with onset usually occurring in childhood and adolescence',
  'vietnamese': 'Rối loạn hành vi cảm xúc biệt định khác, thường khởi phát trong tuổi trẻ em và thanh thiếu niên',
}, {
  'id': 'F98.9',
  'english': 'Unspecified behavioural and emotional disorders with onset usually occurring in childhood and adolescence',
  'vietnamese': 'Các rối loạn hành vi và cảm xúc biệt không biệt định c, thường khởi phát trong tuổi trẻ em và thanh thiếu niên',
}, {
  'id': 'F99',
  'english': 'Mental disorder, not otherwise specified',
  'vietnamese': 'Rối loạn tâm thần không biệt định khác',
}, {
  'id': 'G00',
  'english': 'Bacterial meningitis, not elsewhere classified',
  'vietnamese': 'Viêm màng não vi khuẩn, không phân loại nơi khác',
}, {
  'id': 'G00.0',
  'english': 'Haemophilus meningitis',
  'vietnamese': 'Viêm màng não do Haemophilus',
}, {
  'id': 'G00.1',
  'english': 'Pneumococcal meningitis',
  'vietnamese': 'Viêm màng não do phế cầu',
}, {
  'id': 'G00.2',
  'english': 'Streptococcal meningitis',
  'vietnamese': 'Viêm màng não do liên cầu',
}, {
  'id': 'G00.3',
  'english': 'Staphylococcal meningitis',
  'vietnamese': 'Viêm màng não do tụ cầu',
}, {
  'id': 'G00.8',
  'english': 'Other bacterial meningitis',
  'vietnamese': 'Viêm màng não do vi khuẩn khác',
}, {
  'id': 'G00.9',
  'english': 'Bacterial meningitis, unspecified',
  'vietnamese': 'Viêm màng não vi khuẩn, không biệt định',
}, {
  'id': 'G01*',
  'english': 'Meningitis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm màng não trong bệnh nhiễm khuẩn phân loại ở mục khác',
}, {
  'id': 'G02*',
  'english': 'Meningitis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm màng não trong bệnh nhiễm trùng và ký sinh trùng phân loại ở mục khác',
}, {
  'id': 'G02.0*',
  'english': 'Meningitis in viral diseases classified elsewhere',
  'vietnamese': 'Viêm màng não trong bệnh virus phân loại ở mục khác',
}, {
  'id': 'G02.1*',
  'english': 'Meningitis in mycoses',
  'vietnamese': 'Viêm màng não trong bệnh nấm',
}, {
  'id': 'G02.8*',
  'english': 'Meningitis in other specified infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm màng não trong bệnh nhiễm trùng và ký sinh trùng biệt định khác, phân loại ở mục khác',
}, {
  'id': 'G03',
  'english': 'Meningitis due to other and unspecified causes',
  'vietnamese': 'Viêm màng não do nguyên nhân khác và không xác định',
}, {
  'id': 'G03.0',
  'english': 'Nonpyogenic meningitis',
  'vietnamese': 'Viêm màng não không sinh mủ',
}, {'id': 'G03.1', 'english': 'Chronic meningitis', 'vietnamese': 'Viêm màng não mãn tính'}, {
  'id': 'G03.2',
  'english': 'Benign recurrent meningitis [Mollaret]',
  'vietnamese': 'Viêm màng não tái diễn lành tính [Mollaret)',
}, {
  'id': 'G03.8',
  'english': 'Meningitis due to other specified causes',
  'vietnamese': 'Viêm màng não do nguyên nhân biệt định khác',
}, {
  'id': 'G03.9',
  'english': 'Meningitis, unspecified',
  'vietnamese': 'Viêm màng não, không biệt định',
}, {
  'id': 'G04',
  'english': 'Encephalitis, myelitis and encephalomyelitis',
  'vietnamese': 'Viêm não, viêm tủy và viêm não-tủy',
}, {
  'id': 'G04.0',
  'english': 'Acute disseminated encephalitis',
  'vietnamese': 'Viêm não rải rác cấp tính',
}, {
  'id': 'G04.1',
  'english': 'Tropical spastic paraplegia',
  'vietnamese': 'Liệt co cứng hai chân vùng nhiệt đới',
}, {
  'id': 'G04.2',
  'english': 'Bacterial meningoencephalitis and meningomyelitis, not elsewhere classified',
  'vietnamese': 'Viêm não - màng não và viêm tủy - màng tủy do vi khuẩn, không phân loại ở mục khác',
}, {
  'id': 'G04.8',
  'english': 'Other encephalitis, myelitis and encephalomyelitis',
  'vietnamese': 'Viêm não, viêm tủy và viêm não - tủy khác',
}, {
  'id': 'G04.9',
  'english': 'Encephalitis, myelitis and encephalomyelitis, unspecified',
  'vietnamese': 'Viêm não, viêm tủy và viêm não-tủy, không biệt định',
}, {
  'id': 'G05*',
  'english': 'Encephalitis, myelitis and encephalomyelitis in diseases classified elsewhere',
  'vietnamese': 'Viêm não, viêm tủy và viêm não- tủy trong bệnh phân loại ở mục khác',
}, {
  'id': 'G05.0*',
  'english': 'Encephalitis, myelitis and encephalomyelitis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm não, viêm tủy và viêm não - tủy trong bệnh nhiễm khuẩn phân loại nơi khác',
}, {
  'id': 'G05.1*',
  'english': 'Encephalitis, myelitis and encephalomyelitis in viral diseases classified elsewhere',
  'vietnamese': 'Viêm não, viêm tủy và viêm não - tủy trong bệnh virus phân loại nơi khác',
}, {
  'id': 'G05.2*',
  'english': 'Encephalitis, myelitis and encephalomyelitis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm não, viêm tủy và viêm não - tủy trong bệnh nhiễm trùng, ký sinh trùng khác, phân loại ở mục khác',
}, {
  'id': 'G05.8*',
  'english': 'Encephalitis, myelitis and encephalomyelitis in other diseases classified elsewhere',
  'vietnamese': 'Viêm não, viêm tủy và viêm não- tủy trong bệnh khác, phân loại nơi khác',
}, {
  'id': 'G06',
  'english': 'Intracranial and intraspinal abscess and granuloma',
  'vietnamese': 'Áp xe và u hạt nội sọ và nội tủy',
}, {
  'id': 'G06.0',
  'english': 'Intracranial abscess and granuloma',
  'vietnamese': 'Áp xe và u hạt nội sọ',
}, {
  'id': 'G06.1',
  'english': 'Intraspinal abscess and granuloma',
  'vietnamese': 'Áp xe và u hạt nội tủy',
}, {
  'id': 'G06.2',
  'english': 'Extradural and subdural abscess, unspecified',
  'vietnamese': 'Áp xe ngoài màng cứng và dưới màng cứng, không đặc hiệu',
}, {
  'id': 'G07*',
  'english': 'Intracranial and intraspinal abscess and granuloma in diseases specified elsewhere',
  'vietnamese': 'Áp xe và u hạt nội sọ và nội tủy trong bệnh phân loại ở mục khác',
}, {
  'id': 'G08',
  'english': 'Intracranial and intraspinal phlebitis and thrombophlebitis',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch nội sọ và nội tủy',
}, {
  'id': 'G09',
  'english': 'Sequelae of inflammatory diseases of central nervous system',
  'vietnamese': 'Di chứng của bệnh viêm hệ thần kinh trung ương',
}, {'id': 'G10', 'english': 'Hungtington disease', 'vietnamese': 'Bệnh Hungtington'}, {
  'id': 'G11',
  'english': 'Hereditary ataxia',
  'vietnamese': 'Thất điều di truyền',
}, {
  'id': 'G11.0',
  'english': 'Congenital nonprogressive ataxia',
  'vietnamese': 'Thất điều bẩm sinh không tiến triển',
}, {
  'id': 'G11.1',
  'english': 'Early-onset cerebellar ataxia',
  'vietnamese': 'Thất điều tiểu não khởi phát sớm',
}, {
  'id': 'G11.2',
  'english': 'Late-onset cerebellar ataxia',
  'vietnamese': 'Thất điều tiểu não khởi phát muộn',
}, {
  'id': 'G11.3',
  'english': 'Cerebellar ataxia with defective DNA repair',
  'vietnamese': 'Thất điều tiểu não với rối loạn sửa chữa DNA',
}, {
  'id': 'G11.4',
  'english': 'Hereditary spastic paraplegia',
  'vietnamese': 'Liệt cứng hai chân di truyền',
}, {
  'id': 'G11.8',
  'english': 'Other hereditary ataxias',
  'vietnamese': 'Thất điều di truyền khác',
}, {
  'id': 'G11.9',
  'english': 'Hereditary ataxia, unspecified',
  'vietnamese': 'Thất điều di truyền, không đặc hiệu',
}, {
  'id': 'G12',
  'english': 'Spinal muscular atrophy and related syndromes',
  'vietnamese': 'Teo cơ do tổn thương tủy sống và hội chứng liên quan',
}, {
  'id': 'G12.0',
  'english': 'Infantile spinal muscular atrophy, type I [Werdnig-Hoffman]',
  'vietnamese': 'Teo cơ do tủy trẻ em, loại I [Werdnig - Hofman)',
}, {
  'id': 'G12.1',
  'english': 'Other inherited spinal muscular atrophy',
  'vietnamese': 'Teo cơ do tủy sống di truyền khác',
}, {
  'id': 'G12.2',
  'english': 'Motor neuron disease',
  'vietnamese': 'Bệnh tế bào thần kinh vận động',
}, {
  'id': 'G12.8',
  'english': 'Other spinal muscular atrophies and related syndromes',
  'vietnamese': 'Teo cơ tủy khác và hội chứng liên quan',
}, {
  'id': 'G12.9',
  'english': 'Spinal muscular atrophy, unspecified',
  'vietnamese': 'Teo cơ tủy sống, không đặc hiệu',
}, {
  'id': 'G13*',
  'english': 'Systemic atrophies primarily affecting central nervous system in diseases classified elsewhere',
  'vietnamese': 'Bệnh teo hệ thống ảnh hưởng chủ yếu tới hệ thần kinh trung ương trong bệnh phân loại nơi khác',
}, {
  'id': 'G13.0*',
  'english': 'Paraneoplastic neuromyopathy and neuropathy',
  'vietnamese': 'Bệnh thần kinh cơ và bệnh thần kinh cận u',
}, {
  'id': 'G13.1*',
  'english': 'Other systemic atrophy primarily affecting central nervous system in neoplastic disease',
  'vietnamese': 'Bệnh teo hệ thống ảnh hưởng chủ yếu tới hệ thần kinh trung ương khác, trong u',
}, {
  'id': 'G13.2*',
  'english': 'Systemic atrophy primarily affecting central nervous system in myxoedema (E00.1†, E03.- †)',
  'vietnamese': 'Bệnh teo hệ thống ảnh hưởng chủ yếu tới hệ thần kinh trung ương trong bệnh phù niêm (E00.1†, E03.- †)',
}, {
  'id': 'G13.8*',
  'english': 'Systemic atrophy primarily affecting central nervous system in other diseases classified elsewhere',
  'vietnamese': 'Bệnh teo hệ thống ảnh hưởng chủ yếu tới hệ thần kinh trung ương trong bệnh khác, phân loại nơi khác',
}, {'id': 'G14', 'english': 'Postpolio syndrome', 'vietnamese': 'Hội chứng sau bại liệt'}, {
  'id': 'G20',
  'english': 'Parkinson disease',
  'vietnamese': 'Bệnh Parkinson',
}, {
  'id': 'G21',
  'english': 'Secondary parkinsonism',
  'vietnamese': 'Hội chứng Parkinson thứ phát',
}, {
  'id': 'G21.0',
  'english': 'Malignant neuroleptic syndrome',
  'vietnamese': 'Hội chứng an thần kinh ác tính',
}, {
  'id': 'G21.1',
  'english': 'Other drug-induced secondary parkinsonism',
  'vietnamese': 'Hội chứng Parkinson thứ phát khác do thuốc',
}, {
  'id': 'G21.2',
  'english': 'Secondary parkinsonism due to other external agents',
  'vietnamese': 'Hội chứng Parkinson thứ phát do tác nhân bên ngoài khác',
}, {
  'id': 'G21.3',
  'english': 'Postencephalitic parkinsonism',
  'vietnamese': 'Hội chứng Parkinson sau viêm não',
}, {
  'id': 'G21.4',
  'english': 'Vascular parkinsonism',
  'vietnamese': 'Hội chứng Parkinson do bệnh mạch máu',
}, {
  'id': 'G21.8',
  'english': 'Other secondary parkinsonism',
  'vietnamese': 'Hội chứng Parkinson thứ phát khác',
}, {
  'id': 'G21.9',
  'english': 'Secondary parkinsonism, unspecified',
  'vietnamese': 'Hội chứng Parkinson thứ phát, không đặc hiệu',
}, {
  'id': 'G22*',
  'english': 'Parkinsonism in diseases classified else where',
  'vietnamese': 'Hội chứng Parkinson trong bệnh phân loại nơi khác',
}, {
  'id': 'G23',
  'english': 'Other degenerative diseases of basal ganglia',
  'vietnamese': 'Bệnh thoái hóa khác của hạch nền não',
}, {
  'id': 'G23.0',
  'english': 'Hallervorden-Spatz disease',
  'vietnamese': 'Bệnh Hallervorden-Spatz',
}, {
  'id': 'G23.1',
  'english': 'Progressive supranuclear ophthalmoplegia [Steele-Richardson-Olszewski]',
  'vietnamese': 'Liệt vận nhãn trên nhân tiến triển [Steele-Richardson-Olszewski]',
}, {
  'id': 'G23.2',
  'english': 'Striatonigral degeneration',
  'vietnamese': 'thoái hóa thể vân liềm đen',
}, {
  'id': 'G23.8',
  'english': 'Other specified degenerative diseases of basal ganglia',
  'vietnamese': 'BBệnh thoái hóa xác định khác của hạch nền',
}, {
  'id': 'G23.9',
  'english': 'Degenerative disease of basal ganglia, unspecified',
  'vietnamese': 'Bệnh thoái hóa hạch nền, không đặc hiệu',
}, {'id': 'G24', 'english': 'Dystonia', 'vietnamese': 'Loạn trương lực cơ'}, {
  'id': 'G24.0',
  'english': 'Drug-induced dystonia',
  'vietnamese': 'Loạn trương lực cơ do thuốc',
}, {
  'id': 'G24.1',
  'english': 'Idiopathic familial dystonia',
  'vietnamese': 'Loạn trương lực cơ gia đình tự phát',
}, {
  'id': 'G24.2',
  'english': 'Idiopathic nonfamilial dystonia',
  'vietnamese': 'Loạn trương lực cơ không có tính gia đình nguyên phát',
}, {'id': 'G24.3', 'english': 'Spasmodic torticollis', 'vietnamese': 'Vẹo cổ'}, {
  'id': 'G24.4',
  'english': 'Idiopathic orofacial dystonia',
  'vietnamese': 'Loạn trương lực cơ mặt - miệng nguyên phát',
}, {'id': 'G24.5', 'english': 'Blepharospasm', 'vietnamese': 'Co thắt cơ vòng mi'}, {
  'id': 'G24.8',
  'english': 'Other dystonia',
  'vietnamese': 'Loạn trương lực cơ khác',
}, {
  'id': 'G24.9',
  'english': 'Dystonia, unspecified',
  'vietnamese': 'Loạn trương lực cơ, không xác định',
}, {
  'id': 'G25',
  'english': 'Other extrapyramidal and movement disorders',
  'vietnamese': 'Hội chứng ngoại tháp và rối loạn vận động khác',
}, {'id': 'G25.0', 'english': 'Essential tremor', 'vietnamese': 'Run vô căn'}, {
  'id': 'G25.1',
  'english': 'Drug-induced tremor',
  'vietnamese': 'Run do thuốc',
}, {
  'id': 'G25.2',
  'english': 'Other specified forms of tremor',
  'vietnamese': 'Các thể run khác',
}, {
  'id': 'G25.3',
  'english': 'Myoclonus',
  'vietnamese': 'Giật cơ',
}, {'id': 'G25.4', 'english': 'Drug-induced chorea', 'vietnamese': 'Múa giật do thuốc'}, {
  'id': 'G25.5',
  'english': 'Other chorea',
  'vietnamese': 'Múa giật khác',
}, {
  'id': 'G25.6',
  'english': 'Drug-induced tics and other tics of organic origin',
  'vietnamese': 'Tic (thói tật) do thuốc và các tic (thói tật) khác do nguyên nhân thực thể',
}, {
  'id': 'G25.8',
  'english': 'Other specified extrapyramidal and movement disorders',
  'vietnamese': 'Hội chứng ngoại tháp và rối loạn vận động',
}, {
  'id': 'G25.9',
  'english': 'Extrapyramidal and movement disorder, unspecified',
  'vietnamese': 'Hội chứng ngoại tháp và rối loạn vận động không đặc hiệu',
}, {
  'id': 'G26*',
  'english': 'Extrapyramidal and movement disorders in diseases classified elsewhere',
  'vietnamese': 'Hội chứng ngoại tháp và rối loạn vận động trong bệnh phân loại nơi khác',
}, {'id': 'G30', 'english': 'Alzheimer s disease', 'vietnamese': 'Bệnh Alzheimer'}, {
  'id': 'G30.0',
  'english': 'Alzheimer s disease with early onset',
  'vietnamese': 'Bệnh Alzheimer khởi phát sớm',
}, {
  'id': 'G30.1',
  'english': 'Alzheimer s disease with late onset',
  'vietnamese': 'Bệnh Alzheimer khởi phát muộn',
}, {'id': 'G30.8', 'english': 'Other Alzheimer s disease', 'vietnamese': 'Bệnh Alzheimer khác'}, {
  'id': 'G30.9',
  'english': 'Alzheimer s disease, unspecified',
  'vietnamese': 'Bệnh Alzheimer không đặc hiệu',
}, {
  'id': 'G31',
  'english': 'Other degenerative diseases of nervous system, not elsewhere classified',
  'vietnamese': 'Bệnh thoái hóa khác của hệ thần kinh, không phân loại ở mục khác',
}, {'id': 'G31.0', 'english': 'Circumscribed brain atrophy', 'vietnamese': 'Teo não khu trú'}, {
  'id': 'G31.1',
  'english': 'Senile degeneration of brain, not elsewhere classified',
  'vietnamese': 'thoái hóa não tuổi già, không phân loại nơi khác',
}, {
  'id': 'G31.2',
  'english': 'Degeneration of nervous system due to alcohol',
  'vietnamese': 'thoái hóa hệ thần kinh do rượu',
}, {
  'id': 'G31.8',
  'english': 'Other specified degenerative diseases of nervous system',
  'vietnamese': 'Bệnh thoái hóa xác định khác của hệ thần kinh',
}, {
  'id': 'G31.9',
  'english': 'Degenerative disease of nervous system, unspecified',
  'vietnamese': 'Bệnh thoái hóa hệ thần kinh, không đặc hiệu',
}, {
  'id': 'G32*',
  'english': 'Other degenerative disorders of nervous system in diseases classified elsewhere',
  'vietnamese': 'Bệnh thoái hóa khác của hệ thần kinh, trong bệnh phân loại nơi khác',
}, {
  'id': 'G32.0*',
  'english': 'Subacute combined degeneration of spinal cord in diseases classified elsewhere',
  'vietnamese': 'thoái hóa phối hợp bán cấp tủy sống trong bệnh phân loại nơi khác',
}, {
  'id': 'G32.8*',
  'english': 'Other specified degenerative disorders of nervous system in diseases classified elsewhere',
  'vietnamese': 'Bệnh thoái hóa xác định khác của hệ thần kinh trong bệnh phân loại nơi khác',
}, {'id': 'G35', 'english': 'Multiple sclerosis', 'vietnamese': 'Xơ cứng rải rác'}, {
  'id': 'G36',
  'english': 'Other acute disseminated demyelination',
  'vietnamese': 'Mất myelin rải rác cấp tính khác',
}, {
  'id': 'G36.0',
  'english': 'Neuromyelitis optica [Devic]',
  'vietnamese': 'Viêm tủy thị thần kinh [Devic]',
}, {
  'id': 'G36.1',
  'english': 'Acute and subacute haemorrhagic leukoencephalitis [Hurst]',
  'vietnamese': 'Viêm não chất trắng chảy máu cấp và bán cấp',
}, {
  'id': 'G36.8',
  'english': 'Other specified acute disseminated demyelination',
  'vietnamese': 'Mất myelin rải rác cấp tính xác định khác',
}, {
  'id': 'G36.9',
  'english': 'Acute disseminated demyelination, unspecified',
  'vietnamese': 'Mất myelin rải rác cấp tính không đặc hiệu',
}, {
  'id': 'G37',
  'english': 'Other demyelinating diseases of central nervous system',
  'vietnamese': 'Bệnh mất myelin khác của hệ thần kinh trung ương',
}, {'id': 'G37.0', 'english': 'Diffuse sclerosis', 'vietnamese': 'Xơ cứng toả lan'}, {
  'id': 'G37.1',
  'english': 'Central demyelination of corpus callosum',
  'vietnamese': 'Mất myelin trung tâm của thể trai',
}, {
  'id': 'G37.2',
  'english': 'Central pontine myelinolysis',
  'vietnamese': 'Tiêu myelin trung tâm cầu não',
}, {
  'id': 'G37.3',
  'english': 'Acute transverse myelitis in demyelinating disease of central nervous system',
  'vietnamese': 'Viêm tủy ngang cấp trong bệnh mất myelin của hệ thần kinh trung ương',
}, {
  'id': 'G37.4',
  'english': 'Subacute necrotizing myelitis',
  'vietnamese': 'Viêm tủy hoại tử bán cấp',
}, {
  'id': 'G37.5',
  'english': 'Concentric sclerosis [Baló]',
  'vietnamese': 'Xơ cứng đồng tâm [Baló]',
}, {
  'id': 'G37.8',
  'english': 'Other specified demyelinating diseases of central nervous system',
  'vietnamese': 'Bệnh mất myelin xác định khác của hệ thần kinh trung ương',
}, {
  'id': 'G37.9',
  'english': 'Demyelinating disease of central nervous system, unspecified',
  'vietnamese': 'Bệnh mất myelin của hệ thần kinh trung ương, không đặc hiệu',
}, {'id': 'G40', 'english': 'Epilepsy', 'vietnamese': 'Động kinh'}, {
  'id': 'G40.0',
  'english': 'Localization-related (focal)(partial) idiopathic epilepsy and epileptic syndromes with seizures of localized onset',
  'vietnamese': 'Động kinh nguyên phát khu trú (cục bộ) (từng phần) và hội chứng động kinh với cơn khởi phát khu trú',
}, {
  'id': 'G40.1',
  'english': 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with simple partial seizures',
  'vietnamese': 'Động kinh triệu chứng có khu trú (cục bộ) (từng phần) và hội chứng động kinh có cơn cục bộ đơn giản',
}, {
  'id': 'G40.2',
  'english': 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with complex partial seizures',
  'vietnamese': 'Động kinh triệu chứng khu trú (cục bộ) (từng phần) và hội chứng động kinh có cơn động kinh cục bộ phức tạp',
}, {
  'id': 'G40.3',
  'english': 'Generalized idiopathic epilepsy and epileptic syndromes',
  'vietnamese': 'Hội chứng động kinh và động kinh toàn thể nguyên phát',
}, {
  'id': 'G40.4',
  'english': 'Other generalized epilepsy and epileptic syndromes',
  'vietnamese': 'Động kinh toàn bộ và hội chứng động kinh khác',
}, {
  'id': 'G40.5',
  'english': 'Special epileptic syndromes',
  'vietnamese': 'Hội chứng động kinh đặc hiệu',
}, {
  'id': 'G40.6',
  'english': 'Grand mal seizures, unspecified (with or without petit mal)',
  'vietnamese': 'Động kinh cơn lớn, không đặc hiệu (kèm hay không có cơn nhỏ)',
}, {
  'id': 'G40.7',
  'english': 'Petit mal, unspecified, without grand mal seizures',
  'vietnamese': 'Cơn nhỏ, không đặc hiệu, không kèm theo động kinh cơn lớn',
}, {'id': 'G40.8', 'english': 'Other epilepsy', 'vietnamese': 'Động kinh khác'}, {
  'id': 'G40.9',
  'english': 'Epilepsy, unspecified',
  'vietnamese': 'Động kinh không đặc hiệu',
}, {'id': 'G41', 'english': 'Status epilepticus', 'vietnamese': 'Trạng thái động kinh'}, {
  'id': 'G41.0',
  'english': 'Grand mal status epilepticus',
  'vietnamese': 'Trạng thái động kinh cơn lớn',
}, {
  'id': 'G41.1',
  'english': 'Petit mal status epilepticus',
  'vietnamese': 'Trạng thái động kinh cơn nhỏ',
}, {
  'id': 'G41.2',
  'english': 'Complex partial status epilepticus',
  'vietnamese': 'Trạng thái động kinh cục bộ phức tạp',
}, {
  'id': 'G41.8',
  'english': 'Other status epilepticus',
  'vietnamese': 'Trạng thái động kinh khác',
}, {
  'id': 'G41.9',
  'english': 'Status epilepticus, unspecified',
  'vietnamese': 'Trạng thái động kinh, không đặc hiệu',
}, {'id': 'G43', 'english': 'Migraine', 'vietnamese': 'Migraine'}, {
  'id': 'G43.0',
  'english': 'Migraine without aura [common migraine]',
  'vietnamese': 'Migraine không có aura (thóang báo) (migraine chung)',
}, {
  'id': 'G43.1',
  'english': 'Migraine with aura [classical migraine]',
  'vietnamese': 'Migraine có aura (thóang báo) (migraine cổ điển)',
}, {'id': 'G43.2', 'english': 'Status migrainosus', 'vietnamese': 'Trạng thái Migraine'}, {
  'id': 'G43.3',
  'english': 'Complicated migraine',
  'vietnamese': 'Migraine biến chứng',
}, {'id': 'G43.8', 'english': 'Other migraine', 'vietnamese': 'Migraine khác'}, {
  'id': 'G43.9',
  'english': 'Migraine, unspecified',
  'vietnamese': 'Migraine, không đặc hiệu',
}, {'id': 'G44', 'english': 'Other headache syndromes', 'vietnamese': 'Hội chứng đau đầu khác'}, {
  'id': 'G44.0',
  'english': 'Cluster headache syndrome',
  'vietnamese': 'Hội chứng đau đầu chuỗi',
}, {
  'id': 'G44.1',
  'english': 'Vascular headache, not elsewhere classified',
  'vietnamese': 'Nhức đầu do mạch máu, không phân loại nơi khác',
}, {'id': 'G44.2', 'english': 'Tension-type headache', 'vietnamese': 'Đau đầu do căng thẳng'}, {
  'id': 'G44.3',
  'english': 'Chronic post-traumatic headache',
  'vietnamese': 'Đau đầu mãn tính sau chấn thương',
}, {
  'id': 'G44.4',
  'english': 'Drug-induced headache, not elsewhere classified',
  'vietnamese': 'Đau do thuốc, không phân loại ở mục khác',
}, {
  'id': 'G44.8',
  'english': 'Other specified headache syndromes',
  'vietnamese': 'Chứng đau đầu xác định khác',
}, {
  'id': 'G45',
  'english': 'Transient cerebral ischaemic attacks and related syndromes',
  'vietnamese': 'Cơn thiếu máu não cục bộ thóang qua và hội chứng liên quan',
}, {
  'id': 'G45.0',
  'english': 'Vertebro-basilar artery syndrome',
  'vietnamese': 'Hội chứng động mạch sống - nền',
}, {
  'id': 'G45.1',
  'english': 'Carotid artery syndrome (hemispheric)',
  'vietnamese': 'Hội chứng động mạch cảnh (bán cầu não)',
}, {
  'id': 'G45.2',
  'english': 'Multiple and bilateral precerebral artery syndromes',
  'vietnamese': 'Các hội chứng động mạch não trước rải rác hai bên',
}, {'id': 'G45.3', 'english': 'Amaurosis fugax', 'vietnamese': 'Mù thóang qua'}, {
  'id': 'G45.4',
  'english': 'Transient global amnesia',
  'vietnamese': 'Quên toàn bộ thóang qua',
}, {
  'id': 'G45.8',
  'english': 'Other transient cerebral ischaemic attacks and related syndromes',
  'vietnamese': 'Cơn thiếu máu não thóang qua khác và hội chứng liên quan',
}, {
  'id': 'G45.9',
  'english': 'Transient cerebral ischaemic attack, unspecified',
  'vietnamese': 'Cơn thiếu máu não thóang qua không đặc hiệu',
}, {
  'id': 'G46*',
  'english': 'Vascular syndromes of brain in cerebrovascular diseases (I60-I67†)',
  'vietnamese': 'Hội chứng mạch máu não trong bệnh mạch não (I60-I67†)',
}, {
  'id': 'G46.0*',
  'english': 'Middle cerebral artery syndrome (I66.0†)',
  'vietnamese': 'Hội chứng động mạch não giữa (I66.0†)',
}, {
  'id': 'G46.1*',
  'english': 'Anterior cerebral artery syndrome (I66.1†)',
  'vietnamese': 'Hội chứng động mạch não trước  (I66.1†)',
}, {
  'id': 'G46.2*',
  'english': 'Posterior cerebral artery syndrome (I66.2†)',
  'vietnamese': 'Hội chứng động mạch não sau  (I66.2†)',
}, {
  'id': 'G46.3*',
  'english': 'Brain stem stroke syndrome (I60-I67†)',
  'vietnamese': 'Hội chứng đột quỵ thân não (I60-I67†)',
}, {
  'id': 'G46.4*',
  'english': 'Cerebellar stroke syndrome (I60-I67†)',
  'vietnamese': 'Hội chứng đột quỵ tiểu não (I60-I67†)',
}, {
  'id': 'G46.5*',
  'english': 'Pure motor lacunar syndrome (I60-I67†)',
  'vietnamese': 'Hội chứng ổ khuyết vận động đơn thuần (I60-I67†)',
}, {
  'id': 'G46.6*',
  'english': 'Pure sensory lacunar syndrome (I60-I67†)',
  'vietnamese': 'Hội chứng ổ khuyết cảm giác đơn thuần (I60-I67†)',
}, {
  'id': 'G46.7*',
  'english': 'Other lacunar syndromes (I60-I67†)',
  'vietnamese': 'Hội chứng ổ khuyết khác (I60-I67†)',
}, {
  'id': 'G46.8*',
  'english': 'Other vascular syndromes of brain in cerebrovascular diseases (I60-I67†)',
  'vietnamese': 'Hội chứng mạch máu não khác trong bệnh mạch máu não (I60-I67†)',
}, {'id': 'G47', 'english': 'Sleep disorders', 'vietnamese': 'Rối loạn giấc ngủ'}, {
  'id': 'G47.0',
  'english': 'Disorders of initiating and maintaining sleep [insomnias]',
  'vietnamese': 'Rối loạn vào giấc và duy trì giấc ngủ [mất ngủ]',
}, {
  'id': 'G47.1',
  'english': 'Disorders of excessive somnolence [hypersomnias]',
  'vietnamese': 'Rối loạn buồn ngủ quá mức [ngủ quá nhiều]',
}, {
  'id': 'G47.2',
  'english': 'Disorders of the sleep-wake schedule',
  'vietnamese': 'Rối loạn chu kỳ thức ngủ',
}, {'id': 'G47.3', 'english': 'Sleep apnoea', 'vietnamese': 'Ngừng thở khi ngủ'}, {
  'id': 'G47.4',
  'english': 'Narcolepsy and cataplexy',
  'vietnamese': 'Chứng ngủ rũ và mất trương lực đột ngột',
}, {'id': 'G47.8', 'english': 'Other sleep disorders', 'vietnamese': 'Rối loạn giấc ngủ khác'}, {
  'id': 'G47.9',
  'english': 'Sleep disorder, unspecified',
  'vietnamese': 'Rối loạn giấc ngủ, không biệt định',
}, {
  'id': 'G50',
  'english': 'Disorders of trigeminal nerve',
  'vietnamese': 'Bệnh dây thần kinh tam thoa',
}, {
  'id': 'G50.0',
  'english': 'Trigeminal neuralgia',
  'vietnamese': 'Đau dây thần kinh tam thoa',
}, {
  'id': 'G50.1',
  'english': 'Atypical facial pain',
  'vietnamese': 'Đau mặt không điển hình',
}, {
  'id': 'G50.8',
  'english': 'Other disorders of trigeminal nerve',
  'vietnamese': 'Bệnh khác của dây thần kinh tam thoa',
}, {
  'id': 'G50.9',
  'english': 'Disorder of trigeminal nerve, unspecified',
  'vietnamese': 'Bệnh dây thần kinh tam thoa, chưa phân loại',
}, {'id': 'G51', 'english': 'Facial nerve disorders', 'vietnamese': 'Bệnh dây thần kinh mặt'}, {
  'id': 'G51.0',
  'english': 'Bell palsy',
  'vietnamese': 'Liệt Bell',
}, {'id': 'G51.1', 'english': 'Geniculate ganglionitis', 'vietnamese': 'Viêm hạch gối'}, {
  'id': 'G51.2',
  'english': 'Melkersson syndrome',
  'vietnamese': 'Hội chứng Melkersson',
}, {
  'id': 'G51.3',
  'english': 'Clonic hemifacial spasm',
  'vietnamese': 'Co thắt và giật nửa mặt',
}, {
  'id': 'G51.4',
  'english': 'Facial myokymia',
  'vietnamese': 'Máy cơ mặt',
}, {
  'id': 'G51.8',
  'english': 'Other disorders of facial nerve',
  'vietnamese': 'Bệnh khác của dây thần kinh mặt',
}, {
  'id': 'G51.9',
  'english': 'Disorder of facial nerve, unspecified',
  'vietnamese': 'Bệnh dây thần kinh mặt, không đặc hiệu',
}, {
  'id': 'G52',
  'english': 'Disorders of other cranial nerves',
  'vietnamese': 'Bệnh các dây thần kinh sọ khác',
}, {
  'id': 'G52.0',
  'english': 'Disorders of olfactory nerve',
  'vietnamese': 'Bệnh dây thần kinh khứu giác',
}, {
  'id': 'G52.1',
  'english': 'Disorders of glossopharyngeal nerve',
  'vietnamese': 'Bệnh dây thần kinh lưỡi hầu (thiệt hầu)',
}, {
  'id': 'G52.2',
  'english': 'Disorders of vagus nerve',
  'vietnamese': 'Bệnh dây thần kinh phế vị',
}, {
  'id': 'G52.3',
  'english': 'Disorders of hypoglossal nerve',
  'vietnamese': 'Bệnh dây thần kinh dưới lưỡi (hạ thiệt)',
}, {
  'id': 'G52.7',
  'english': 'Disorders of multiple cranial nerves',
  'vietnamese': 'Nhiều dây thần kinh sọ',
}, {
  'id': 'G52.8',
  'english': 'Disorders of other specified cranial nerves',
  'vietnamese': 'Bệnh dây thần kinh sọ xác định khác',
}, {
  'id': 'G52.9',
  'english': 'Cranial nerve disorder, unspecified',
  'vietnamese': 'Bệnh dây thần kinh sọ, không đặc hiệu',
}, {
  'id': 'G53*',
  'english': 'Cranial nerve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh dây thần kinh sọ trong bệnh phân loại nơi khác',
}, {
  'id': 'G53.0*',
  'english': 'Postzoster neuralgia (B02.2†)',
  'vietnamese': 'Đau dây thần kinh sau zona (B02.2†)',
}, {
  'id': 'G53.1*',
  'english': 'Multiple cranial nerve palsies in infectious and parasitic diseases classified elsewhere (A00-B99†)',
  'vietnamese': 'Liệt nhiều dây thần kinh sọ trong bệnh nhiễm trùng và ký sinh trùng phân loại ở mục khác (A00-B99†)',
}, {
  'id': 'G53.2*',
  'english': 'Multiple cranial nerve palsies in sarcoidosis (D86.8†)',
  'vietnamese': 'Liệt nhiều dây thần kinh sọ trong bệnh Saccoit (D86.8†)',
}, {
  'id': 'G53.3*',
  'english': 'Multiple cranial nerve palsies in neoplastic disease (C00-D48†)',
  'vietnamese': 'Liệt nhiều dây thần kinh sọ trong u (C00-D48†)',
}, {
  'id': 'G53.8*',
  'english': 'Other cranial nerve disorders in other diseases classified elsewhere',
  'vietnamese': 'Bệnh dây thần kinh sọ khác trong các bệnh khác được phân loại nơi khác',
}, {
  'id': 'G54',
  'english': 'Nerve root and plexus disorders',
  'vietnamese': 'Bệnh rễ và đám rối thần kinh',
}, {
  'id': 'G54.0',
  'english': 'Brachial plexus disorders',
  'vietnamese': 'Bệnh đám rối thần kinh cánh tay',
}, {
  'id': 'G54.1',
  'english': 'Lumbosacral plexus disorders',
  'vietnamese': 'Bệnh đám rối thắt lưng - cùng',
}, {
  'id': 'G54.2',
  'english': 'Cervical root disorders, not elsewhere classified',
  'vietnamese': 'Bệnh rễ thần kinh cổ, không phân loại nơi khác',
}, {
  'id': 'G54.3',
  'english': 'Thoracic root disorders, not elsewhere classified',
  'vietnamese': 'Bệnh rễ thần kinh ngực, không phân loại nơi khác',
}, {
  'id': 'G54.4',
  'english': 'Lumbosacral root disorders, not elsewhere classified',
  'vietnamese': 'Bệnh rễ thần kinh thắt lưng - cùng, không phân loại nơi khác',
}, {'id': 'G54.5', 'english': 'Neuralgic amyotrophy', 'vietnamese': 'Teo cơ đau thần kinh'}, {
  'id': 'G54.6',
  'english': 'Phantom limb syndrome with pain',
  'vietnamese': 'Hội chứng chi ma có đau',
}, {
  'id': 'G54.7',
  'english': 'Phantom limb syndrome without pain',
  'vietnamese': 'Hội chứng chi ma không đau',
}, {
  'id': 'G54.8',
  'english': 'Other nerve root and plexus disorders',
  'vietnamese': 'Bệnh rễ và đám rối thần kinh khác',
}, {
  'id': 'G54.9',
  'english': 'Nerve root and plexus disorder, unspecified',
  'vietnamese': 'Bệnh rễ và đám rối thần kinh, không đặc hiệu',
}, {
  'id': 'G55*',
  'english': 'Nerve root and plexus compressions in diseases classified elsewhere',
  'vietnamese': 'Chèn ép rễ thần kinh và đám rối trong bệnh phân loại nơi khác',
}, {
  'id': 'G55.0*',
  'english': 'Nerve root and plexus compressions in neoplastic disease (C00-D48†)',
  'vietnamese': 'Chèn ép rễ thần kinh và đám rối trong u (C00-D48†)',
}, {
  'id': 'G55.1*',
  'english': 'Nerve root and plexus compressions in intervertebral disc disorders (M50-M51†)',
  'vietnamese': 'Chèn ép rễ và đám rối thần kinh trong bệnh đĩa đệm cột sống (M50-M51†)',
}, {
  'id': 'G55.2*',
  'english': 'Nerve root and plexus compressions in spondylosis (M47.-†)',
  'vietnamese': 'Chèn ép rễ và đám rối thần kinh trong thoái hóa đốt sống (M47.-†)',
}, {
  'id': 'G55.3*',
  'english': 'Nerve root and plexus compressions in other dorsopathiesM45-M46M48.-M53-M54',
  'vietnamese': 'Chèn ép rễ và đám rối thần kinh trong bệnh khác vùng lưng (M45-M46†, M48.-†, M53-M54†)',
}, {
  'id': 'G55.8*',
  'english': 'Nerve root and plexus compressions in other diseases classified elsewhere',
  'vietnamese': 'Chèn ép rễ và đám rối thần kinh trong bệnh khác phân loại nơi khác',
}, {
  'id': 'G56',
  'english': 'Mononeuropathies of upper limb',
  'vietnamese': 'Bệnh đơn dây thần kinh chi trên',
}, {'id': 'G56.0', 'english': 'Carpal tunnel syndrome', 'vietnamese': 'Hội chứng ống cổ tay'}, {
  'id': 'G56.1',
  'english': 'Other lesions of median nerve',
  'vietnamese': 'Tổn thương khác của dây thần kinh giữa',
}, {
  'id': 'G56.2',
  'english': 'Lesion of ulnar nerve',
  'vietnamese': 'Tổn thương dây thần kinh trụ',
}, {
  'id': 'G56.3',
  'english': 'Lesion of radial nerve',
  'vietnamese': 'Tổn thương dây thần kinh quay',
}, {'id': 'G56.4', 'english': 'Causalgia', 'vietnamese': 'Bỏng buốt'}, {
  'id': 'G56.8',
  'english': 'Other mononeuropathies of upper limb',
  'vietnamese': 'Bệnh đơn dây thần kinh khác của chi trên',
}, {
  'id': 'G56.9',
  'english': 'Mononeuropathy of upper limb, unspecified',
  'vietnamese': 'Bệnh đơn dây thần kinh của chi trên không đặc hiệu',
}, {
  'id': 'G57',
  'english': 'Mononeuropathies of lower limb',
  'vietnamese': 'Bệnh đơn dây thần kinh chi dưới',
}, {
  'id': 'G57.0',
  'english': 'Lesion of sciatic nerve',
  'vietnamese': 'Tổn thương dây thần kinh hông to',
}, {'id': 'G57.1', 'english': 'Meralgia paraesthetica', 'vietnamese': 'Đau đùi dị cảm'}, {
  'id': 'G57.2',
  'english': 'Lesion of femoral nerve',
  'vietnamese': 'Tổn thương dây thần kinh đùi',
}, {
  'id': 'G57.3',
  'english': 'Lesion of lateral popliteal nerve',
  'vietnamese': 'Tổn thương dây thần kinh khoeo ngoài',
}, {
  'id': 'G57.4',
  'english': 'Lesion of medial popliteal nerve',
  'vietnamese': 'Tổn thương dây thần kinh khoeo trong',
}, {'id': 'G57.5', 'english': 'Tarsal tunnel syndrome', 'vietnamese': 'Hội chứng ống cổ chân'}, {
  'id': 'G57.6',
  'english': 'Lesion of plantar nerve',
  'vietnamese': 'Tổn thương dây thần kinh gan bàn chân',
}, {
  'id': 'G57.8',
  'english': 'Other mononeuropathies of lower limb',
  'vietnamese': 'Bệnh đơn dây thần kinh khác ở chi dưới',
}, {
  'id': 'G57.9',
  'english': 'Mononeuropathy of lower limb, unspecified',
  'vietnamese': 'Bệnh đơn dây thần kinh ở chi dưới không đặc hiệu',
}, {
  'id': 'G58',
  'english': 'Other mononeuropathies',
  'vietnamese': 'Bệnh đơn dây thần kinh khác',
}, {
  'id': 'G58.0',
  'english': 'Intercostal neuropathy',
  'vietnamese': 'Bệnh dây thần kinh liên sườn',
}, {
  'id': 'G58.7',
  'english': 'Mononeuritis multiplex',
  'vietnamese': 'Viêm đơn dây thần kinh nhiều ổ',
}, {
  'id': 'G58.8',
  'english': 'Other specified mononeuropathies',
  'vietnamese': 'Bệnh đơn dây thần kinh xác định khác',
}, {
  'id': 'G58.9',
  'english': 'Mononeuropathy, unspecified',
  'vietnamese': 'Bệnh đơn dây thần kinh, không đặc hiệu',
}, {
  'id': 'G59*',
  'english': 'Mononeuropathy in diseases classified elsewhere',
  'vietnamese': 'Bệnh đơn dây thần kinh được phân loại nơi khác',
}, {
  'id': 'G59.0*',
  'english': 'Diabetic mononeuropathy (E10-E14†) with common fourth character .4',
  'vietnamese': 'Bệnh đơn dây thần kinh do đái tháo đường (E10-E14†) (với ký tự thứ tư chung là .4)',
}, {
  'id': 'G59.8*',
  'english': 'Other mononeuropathies in diseases classified elsewhere',
  'vietnamese': 'Bệnh đơn dây thần kinh khác trong bệnh được phân loại nơi khác',
}, {
  'id': 'G60',
  'english': 'Hereditary and idiopathic neuropathy',
  'vietnamese': 'Bệnh dây thần kinh di truyền và nguyên phát',
}, {
  'id': 'G60.0',
  'english': 'Hereditary motor and sensory neuropathy',
  'vietnamese': 'Bệnh dây thần kinh cảm giác và vận động di truyền',
}, {'id': 'G60.1', 'english': 'Refsum s disease', 'vietnamese': 'Bệnh Refsum'}, {
  'id': 'G60.2',
  'english': 'Neuropathy in association with hereditary ataxia',
  'vietnamese': 'Bệnh dây thần kinh kết hợp với thất điều di truyền',
}, {
  'id': 'G60.3',
  'english': 'Idiopathic progressive neuropathy',
  'vietnamese': 'Bệnh dây thần kinh tiến triển nguyên phát',
}, {
  'id': 'G60.8',
  'english': 'Other hereditary and idiopathic neuropathies',
  'vietnamese': 'Bệnh dây thần kinh nguyên phát và di truyền khác',
}, {
  'id': 'G60.9',
  'english': 'Hereditary and idiopathic neuropathy, unspecified',
  'vietnamese': 'Bệnh dây thần kinh di truyền nguyên phát, không đặc hiệu khác',
}, {
  'id': 'G61',
  'english': 'Inflammatory polyneuropathy',
  'vietnamese': 'Viêm đa dây thần kinh',
}, {
  'id': 'G61.0',
  'english': 'Guillain-Barré syndrome',
  'vietnamese': 'Hội chứng Guillain-Barré',
}, {
  'id': 'G61.1',
  'english': 'Serum neuropathy',
  'vietnamese': 'Bệnh dây thần kinh do huyết thanh',
}, {
  'id': 'G61.8',
  'english': 'Other inflammatory polyneuropathies',
  'vietnamese': 'Bệnh viêm đa dây thần kinh khác',
}, {
  'id': 'G61.9',
  'english': 'Inflammatory polyneuropathy, unspecified',
  'vietnamese': 'Bệnh viêm đa dây thần kinh, không đặc hiệu',
}, {
  'id': 'G62',
  'english': 'Other polyneuropathies',
  'vietnamese': 'Bệnh đa dây thần kinh khác',
}, {
  'id': 'G62.0',
  'english': 'Drug-induced polyneuropathy',
  'vietnamese': 'Bệnh đa dây thần kinh do thuốc',
}, {
  'id': 'G62.1',
  'english': 'Alcoholic polyneuropathy',
  'vietnamese': 'Bệnh đa dây thần kinh do rượu',
}, {
  'id': 'G62.2',
  'english': 'Polyneuropathy due to other toxic agents',
  'vietnamese': 'Bệnh đa dây thần kinh do độc tố khác',
}, {
  'id': 'G62.8',
  'english': 'Other specified polyneuropathies',
  'vietnamese': 'Bệnh đa dây thần kinh xác định khác',
}, {
  'id': 'G62.9',
  'english': 'Polyneuropathy, unspecified',
  'vietnamese': 'Bệnh đa dây thần kinh, không đặc hiệu',
}, {
  'id': 'G63*',
  'english': 'Polyneuropathy in diseases classified elsewhere',
  'vietnamese': 'Bệnh đa dây thần kinh trong các bệnh phân loại nơi khác',
}, {
  'id': 'G63.0*',
  'english': 'Polyneuropathy in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Bệnh đa dây thần kinh trong các bệnh nhiễm trùng và ký sinh trùng đã phân loại nơi khác',
}, {
  'id': 'G63.1*',
  'english': 'Polyneuropathy in neoplastic disease (C00-D48†)',
  'vietnamese': 'Bệnh đa dây thần kinh trong u (C00-D48†)',
}, {
  'id': 'G63.2*',
  'english': 'Diabetic polyneuropathy (E10-E14 with common fourth character .4†)',
  'vietnamese': 'Bệnh đa dây thần kinh do đái tháo đường (E10-E14 với ký tự thứ tự chung là .4†)',
}, {
  'id': 'G63.3*',
  'english': 'Polyneuropathy in other endocrine and metabolic diseases (E00-E07†, E15-E16†, E20-E34†, E70-E89†)',
  'vietnamese': 'Bệnh đa dây thần kinh trong các bệnh nội tiết và chuyển hóa khác (E00-E07†, E15-E16†, E20-E34†, E70-E89†)',
}, {
  'id': 'G63.4*',
  'english': 'Polyneuropathy in nutritional deficiency (E40-E64†)',
  'vietnamese': 'Bệnh đa dây thần kinh trong thiểu dinh dưỡng (E40-E64†)',
}, {
  'id': 'G63.5*',
  'english': 'Polyneuropathy in systemic connective tissue disorders (M30-M35†)',
  'vietnamese': 'Bệnh đa dây thần kinh trong bệnh hệ thống mô liên kết (M30-M35†)',
}, {
  'id': 'G63.6*',
  'english': 'Polyneuropathy in other musculoskeletal disorders (M00-M25†, M40-M96†)',
  'vietnamese': 'Bệnh đa dây thần kinh trong bệnh cơ xương khác (M00-M25†, M40-M96†)',
}, {
  'id': 'G63.8*',
  'english': 'Polyneuropathy in other diseases classified elsewhere',
  'vietnamese': 'Bệnh đa dây thần kinh trong bệnh khác, phân loại nơi khác',
}, {
  'id': 'G64',
  'english': 'Other disorders of peripheral nervous system',
  'vietnamese': 'Bệnh khác của hệ thần kinh ngoại vi',
}, {
  'id': 'G70',
  'english': 'Myasthenia gravis and other myoneural disorders',
  'vietnamese': 'Nhược cơ và bệnh thần kinh - cơ khác',
}, {'id': 'G70.0', 'english': 'Myasthenia gravis', 'vietnamese': 'Nhược cơ'}, {
  'id': 'G70.1',
  'english': 'Toxic myoneural disorders',
  'vietnamese': 'Bệnh thần kinh - cơ do nhiễm độc',
}, {
  'id': 'G70.2',
  'english': 'Congenital and developmental myasthenia',
  'vietnamese': 'Nhược cơ bẩm sinh và trong quá trình phát triển',
}, {
  'id': 'G70.8',
  'english': 'Other specified myoneural disorders',
  'vietnamese': 'Bệnh thần kinh - cơ xác định khác',
}, {
  'id': 'G70.9',
  'english': 'Myoneural disorder, unspecified',
  'vietnamese': 'Bệnh thần kinh - cơ, không đặc hiệu',
}, {'id': 'G71', 'english': 'Primary disorders of muscles', 'vietnamese': 'Bệnh cơ tiên phát'}, {
  'id': 'G71.0',
  'english': 'Muscular dystrophy',
  'vietnamese': 'Loạn dưỡng cơ',
}, {'id': 'G71.1', 'english': 'Myotonic disorders', 'vietnamese': 'Bệnh loạn trương lực cơ'}, {
  'id': 'G71.2',
  'english': 'Congenital myopathies',
  'vietnamese': 'Bệnh cơ bẩm sinh',
}, {
  'id': 'G71.3',
  'english': 'Mitochondrial myopathy, not elsewhere classified',
  'vietnamese': 'Bệnh cơ do thể ty lạp, không phân loại nơi khác',
}, {
  'id': 'G71.8',
  'english': 'Other primary disorders of muscles',
  'vietnamese': 'Các bệnh tiên phát khác của cơ',
}, {
  'id': 'G71.9',
  'english': 'Primary disorder of muscle, unspecified',
  'vietnamese': 'Bệnh cơ tiên phát, không đặc hiệu',
}, {'id': 'G72', 'english': 'Other myopathies', 'vietnamese': 'Bệnh cơ khác'}, {
  'id': 'G72.0',
  'english': 'Drug-induced myopathy',
  'vietnamese': 'Bệnh cơ do thuốc',
}, {'id': 'G72.1', 'english': 'Alcoholic myopathy', 'vietnamese': 'Bệnh cơ do rượu'}, {
  'id': 'G72.2',
  'english': 'Myopathy due to other toxic agents',
  'vietnamese': 'Bệnh cơ do độc tố khác',
}, {'id': 'G72.3', 'english': 'Periodic paralysis', 'vietnamese': 'Liệt chu kỳ'}, {
  'id': 'G72.4',
  'english': 'Inflammatory myopathy, not elsewhere classified',
  'vietnamese': 'Viêm cơ, không phân loại nơi khác',
}, {
  'id': 'G72.8',
  'english': 'Other specified myopathies',
  'vietnamese': 'Bệnh cơ xác định khác',
}, {
  'id': 'G72.9',
  'english': 'Myopathy, unspecified',
  'vietnamese': 'Bệnh cơ, không đặc hiệu',
}, {
  'id': 'G73*',
  'english': 'Disorders of myoneural junction and muscle in diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp thần kinh- cơ và cơ trong các bệnh phân loại nơi khác',
}, {
  'id': 'G73.0*',
  'english': 'Myasthenic syndromes in endocrine diseases',
  'vietnamese': 'Hội chứng nhược cơ trong bệnh nội tiết',
}, {
  'id': 'G73.1*',
  'english': 'Eaton-Lambert syndromeC80',
  'vietnamese': 'Hội chứng Lambert-Eaton (C80†',
}, {
  'id': 'G73.2*',
  'english': 'Other myasthenic syndromes in neoplastic diseaseC00-D48',
  'vietnamese': 'Hội chứng nhược cơ khác trong u (C00-D48†)',
}, {
  'id': 'G73.3*',
  'english': 'Myasthenic syndromes in other diseases classified elsewhere',
  'vietnamese': 'Hội chứng nhược cơ trong các bệnh khác phân loại nơi khác',
}, {
  'id': 'G73.4*',
  'english': 'Myopathy in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ trong bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'G73.5*',
  'english': 'Myopathy in endocrine diseases',
  'vietnamese': 'Bệnh cơ trong bệnh nội tiết',
}, {
  'id': 'G73.6*',
  'english': 'Myopathy in metabolic diseases',
  'vietnamese': 'Bệnh cơ trong bệnh chuyển hóa',
}, {
  'id': 'G73.7*',
  'english': 'Myopathy in other diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ trong bệnh khác, phân loại nơi khác',
}, {'id': 'G80', 'english': 'Cerebral palsy', 'vietnamese': 'Bại não'}, {
  'id': 'G80.0',
  'english': 'Spastic quadriplegic cerebral palsy',
  'vietnamese': 'Bại não liệt tứ chi co cứng',
}, {
  'id': 'G80.1',
  'english': 'Spastic diplegic cerebral palsy',
  'vietnamese': 'Bại não liệt co cứng hai bên',
}, {
  'id': 'G80.2',
  'english': 'Spastic hemiplegic cerebral palsy',
  'vietnamese': 'Bại não liệt nửa người co cứng',
}, {'id': 'G80.3', 'english': 'Dyskinetic cerebral palsy', 'vietnamese': 'Bại não loạn động'}, {
  'id': 'G80.4',
  'english': 'Ataxic cerebral palsy',
  'vietnamese': 'Bại não thất điều',
}, {'id': 'G80.8', 'english': 'Other cerebral palsy', 'vietnamese': 'Bại não khác'}, {
  'id': 'G80.9',
  'english': 'Cerebral palsy, unspecified',
  'vietnamese': 'Bại não, không đặc hiệu',
}, {'id': 'G81', 'english': 'Hemiplegia', 'vietnamese': 'Liệt nửa người'}, {
  'id': 'G81.0',
  'english': 'Flaccid hemiplegia',
  'vietnamese': 'Liệt mềm nửa người',
}, {'id': 'G81.1', 'english': 'Spastic hemiplegia', 'vietnamese': 'Liệt cứng nửa người'}, {
  'id': 'G81.9',
  'english': 'Hemiplegia, unspecified',
  'vietnamese': 'Liệt nửa người không đặc hiệu',
}, {
  'id': 'G82',
  'english': 'Paraplegia and tetraplegia',
  'vietnamese': 'Liệt hai chân và liệt tứ chi',
}, {'id': 'G82.0', 'english': 'Flaccid paraplegia', 'vietnamese': 'Liệt mềm hai chi dưới'}, {
  'id': 'G82.1',
  'english': 'Spastic paraplegia',
  'vietnamese': 'Liệt cứng hai chi dưới',
}, {
  'id': 'G82.2',
  'english': 'Paraplegia, unspecified',
  'vietnamese': 'Liệt hai chi dưới, không đặc hiệu',
}, {'id': 'G82.3', 'english': 'Flaccid tetraplegia', 'vietnamese': 'Liệt mềm tứ chi'}, {
  'id': 'G82.4',
  'english': 'Spastic tetraplegia',
  'vietnamese': 'Liệt cứng tứ chi',
}, {
  'id': 'G82.5',
  'english': 'Tetraplegia, unspecified',
  'vietnamese': 'Liệt tứ chi, không đặc hiệu',
}, {'id': 'G83', 'english': 'Other paralytic syndromes', 'vietnamese': 'Hội chứng liệt khác'}, {
  'id': 'G83.0',
  'english': 'Diplegia of upper limbs',
  'vietnamese': 'Liệt hai chi trên',
}, {'id': 'G83.1', 'english': 'Monoplegia of lower limb', 'vietnamese': 'Liệt một chi dưới'}, {
  'id': 'G83.2',
  'english': 'Monoplegia of upper limb',
  'vietnamese': 'Liệt một chi trên',
}, {
  'id': 'G83.3',
  'english': 'Monoplegia, unspecified',
  'vietnamese': 'Liệt một chi, không đặc hiệu',
}, {'id': 'G83.4', 'english': 'Cauda equina syndrome', 'vietnamese': 'Hội chứng đuôi ngựa'}, {
  'id': 'G83.8',
  'english': 'Other specified paralytic syndromes',
  'vietnamese': 'Các hội chứng liệt xác định khác',
}, {
  'id': 'G83.9',
  'english': 'Paralytic syndrome, unspecified',
  'vietnamese': 'Hội chứng liệt, không đặc hiệu',
}, {
  'id': 'G90',
  'english': 'Disorders of autonomic nervous system',
  'vietnamese': 'Bệnh hệ thần kinh tự động',
}, {
  'id': 'G90.0',
  'english': 'Idiopathic peripheral autonomic neuropathy',
  'vietnamese': 'Bệnh thần kinh tự động ngoại vi nguyên phát',
}, {
  'id': 'G90.1',
  'english': 'Familial dysautonomia [Riley-Day]',
  'vietnamese': 'Rối loạn thần kinh tự động gia đình [Riley-Day]',
}, {'id': 'G90.2', 'english': 'Horner s syndrome', 'vietnamese': 'Hội chứng Horner'}, {
  'id': 'G90.3',
  'english': 'Multi-system degeneration',
  'vietnamese': 'thoái hóa đa hệ',
}, {'id': 'G90.4', 'english': 'Autonomic dysreflexia', 'vietnamese': 'Loạn phản xạ tự quản'}, {
  'id': 'G90.8',
  'english': 'Other disorders of autonomic nervous system',
  'vietnamese': 'Bệnh khác của hệ thần kinh tự động',
}, {
  'id': 'G90.9',
  'english': 'Disorder of autonomic nervous system, unspecified',
  'vietnamese': 'Bệnh hệ thần kinh tự động, không đặc hiệu',
}, {'id': 'G91', 'english': 'Hydrocephalus', 'vietnamese': 'Tràn dịch não'}, {
  'id': 'G91.0',
  'english': 'Communicating hydrocephalus',
  'vietnamese': 'Tràn dịch não thông',
}, {'id': 'G91.1', 'english': 'Obstructive hydrocephalus', 'vietnamese': 'Tràn dịch não tắc'}, {
  'id': 'G91.2',
  'english': 'Normal-pressure hydrocephalus',
  'vietnamese': 'Tràn dịch não áp lực bình thường',
}, {
  'id': 'G91.3',
  'english': 'Post-traumatic hydrocephalus, unspecified',
  'vietnamese': 'Tràn dịch não sau chấn thương, không đặc hiệu',
}, {'id': 'G91.8', 'english': 'Other hydrocephalus', 'vietnamese': 'Tràn dịch não khác'}, {
  'id': 'G91.9',
  'english': 'Hydrocephalus, unspecified',
  'vietnamese': 'Tràn dịch não, không đặc hiệu',
}, {'id': 'G92', 'english': 'Toxic encephalopathy', 'vietnamese': 'Bệnh não nhiễm độc'}, {
  'id': 'G93',
  'english': 'Other disorders of brain',
  'vietnamese': 'Bệnh khác của não',
}, {'id': 'G93.0', 'english': 'Cerebral cysts', 'vietnamese': 'Kén dịch não'}, {
  'id': 'G93.1',
  'english': 'Anoxic brain damage, not elsewhere classified',
  'vietnamese': 'Tổn thương não do thiếu oxy, không phân loại nơi khác',
}, {
  'id': 'G93.2',
  'english': 'Benign intracranial hypertension',
  'vietnamese': 'Tăng áp lực trong sọ lành tính',
}, {
  'id': 'G93.3',
  'english': 'Postviral fatigue syndrome',
  'vietnamese': 'Hội chứng mệt mỏi sau nhiễm virus',
}, {
  'id': 'G93.4',
  'english': 'Encephalopathy, unspecified',
  'vietnamese': 'Bệnh não, không đặc hiệu',
}, {'id': 'G93.5', 'english': 'Compression of brain', 'vietnamese': 'Chèn ép não'}, {
  'id': 'G93.6',
  'english': 'Cerebral oedema',
  'vietnamese': 'Phù não',
}, {'id': 'G93.7', 'english': 'Reye s syndrome', 'vietnamese': 'Hội chứng Reye'}, {
  'id': 'G93.8',
  'english': 'Other specified disorders of brain',
  'vietnamese': 'Các bệnh xác định khác của não',
}, {
  'id': 'G93.9',
  'english': 'Disorder of brain, unspecified',
  'vietnamese': 'Bệnh não, không đặc hiệu',
}, {
  'id': 'G94*',
  'english': 'Other disorders of brain in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của não trong bệnh phân loại nơi khác',
}, {
  'id': 'G94.0*',
  'english': 'Hydrocephalus in infectious and parasitic diseases classified elsewhere (A00-B99†)',
  'vietnamese': 'Tràn dịch não trong bệnh nhiễm trùng và ký sinh trùng phân loại ở mục khác (A00-B99†)',
}, {
  'id': 'G94.1*',
  'english': 'Hydrocephalus in neoplastic disease (C00-D48†)',
  'vietnamese': 'Tràn dịch não trong u (C00-D48†)',
}, {
  'id': 'G94.2*',
  'english': 'Hydrocephalus in other diseases classified elsewhere',
  'vietnamese': 'Tràn dịch não trong các bệnh khác, phân loại nơi khác',
}, {
  'id': 'G94.8*',
  'english': 'Other specified disorders of brain in diseases classified elsewhere',
  'vietnamese': 'Các bệnh xác định khác của não trong các bệnh phân loại nơi khác',
}, {
  'id': 'G95',
  'english': 'Other diseases of spinal cord',
  'vietnamese': 'Bệnh khác của tủy sống',
}, {
  'id': 'G95.0',
  'english': 'Syringomyelia and syringobulbia',
  'vietnamese': 'Bệnh rỗng tủy sống và rỗng hành não',
}, {'id': 'G95.1', 'english': 'Vascular myelopathies', 'vietnamese': 'Bệnh mạch máu tủy'}, {
  'id': 'G95.2',
  'english': 'Cord compression, unspecified',
  'vietnamese': 'Chèn ép tủy, không đặc hiệu',
}, {
  'id': 'G95.8',
  'english': 'Other specified diseases of spinal cord',
  'vietnamese': 'Các bệnh tủy xác định khác của tủy',
}, {
  'id': 'G95.9',
  'english': 'Disease of spinal cord, unspecified',
  'vietnamese': 'Bệnh của tủy, không đặc hiệu',
}, {
  'id': 'G96',
  'english': 'Other disorders of central nervous system',
  'vietnamese': 'Bệnh khác của hệ thần kinh trung ương',
}, {'id': 'G96.0', 'english': 'Cerebrospinal fluid leak', 'vietnamese': 'Dò dịch não tủy'}, {
  'id': 'G96.1',
  'english': 'Disorders of meninges, not elsewhere classified',
  'vietnamese': 'Bệnh màng não không phân loại nơi khác',
}, {
  'id': 'G96.8',
  'english': 'Other specified disorders of central nervous system',
  'vietnamese': 'Bệnh xác định khác của hệ thần kinh trung ương',
}, {
  'id': 'G96.9',
  'english': 'Disorder of central nervous system, unspecified',
  'vietnamese': 'Bệnh thần kinh trung ương, không đặc hiệu',
}, {
  'id': 'G97',
  'english': 'Postprocedural disorders of nervous system, not elsewhere classified',
  'vietnamese': 'Bệnh hệ thần kinh sau thủ thuật, không phân loại nơi khác',
}, {
  'id': 'G97.0',
  'english': 'Cerebrospinal fluid leak from spinal puncture',
  'vietnamese': 'Dò dịch não tủy do chọc dò thắt lưng',
}, {
  'id': 'G97.1',
  'english': 'Other reaction to spinal and lumbar puncture',
  'vietnamese': 'Phản ứng khác đối với chọc dò thắt lưng',
}, {
  'id': 'G97.2',
  'english': 'Intracranial hypotension following ventricular shunting',
  'vietnamese': 'Hạ áp lực nội sọ sau dẫn lưu não thất',
}, {
  'id': 'G97.8',
  'english': 'Other postprocedural disorders of nervous system',
  'vietnamese': 'Bệnh khác của hệ thần kinh sau thủ thuật',
}, {
  'id': 'G97.9',
  'english': 'Postprocedural disorder of nervous system, unspecified',
  'vietnamese': 'Bệnh của hệ thần kinh sau thủ thuật, không đặc hiệu',
}, {
  'id': 'G98',
  'english': 'Other disorders of nervous system, not elsewhere classified',
  'vietnamese': 'Bệnh khác của hệ thần kinh không phân loại nơi khác',
}, {
  'id': 'G99*',
  'english': 'Other disorders of nervous system in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của hệ thần kinh trong bệnh phân loại nơi khác',
}, {
  'id': 'G99.0*',
  'english': 'Autonomic neuropathy in endocrine and metabolic diseases',
  'vietnamese': 'Bệnh hệ thần kinh tự động trong bệnh nội tiết và chuyển hóa',
}, {
  'id': 'G99.1*',
  'english': 'Other disorders of autonomic nervous system in other diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của hệ thần kinh tự động trong các bệnh phân loại nơi khác',
}, {
  'id': 'G99.2*',
  'english': 'Myelopathy in diseases classified elsewhere',
  'vietnamese': 'Bệnh tủy trong các bệnh phân loại nơi khác',
}, {
  'id': 'G99.8*',
  'english': 'Other specified disorders of nervous system in diseases classified elsewhere',
  'vietnamese': 'Các bệnh xác định khác của hệ thần kinh trong các bệnh phân loại nơi khác',
}, {'id': 'H00', 'english': 'Hordeolum and chalazion', 'vietnamese': 'Lẹo và chắp'}, {
  'id': 'H00.0',
  'english': 'Hordeolum and other deep inflammation of eyelid',
  'vietnamese': 'Lẹo và viêm sâu khác của mí mắt',
}, {'id': 'H00.1', 'english': 'Chalazion', 'vietnamese': 'Chắp'}, {
  'id': 'H01',
  'english': 'Other inflammation of eyelid',
  'vietnamese': 'Viêm khác của mí mắt',
}, {'id': 'H01.0', 'english': 'Blepharitis', 'vietnamese': 'Viêm bờ mi'}, {
  'id': 'H01.1',
  'english': 'Noninfectious dermatoses of eyelid',
  'vietnamese': 'Bệnh da mí không nhiễm trùng',
}, {
  'id': 'H01.8',
  'english': 'Other specified inflammation of eyelid',
  'vietnamese': 'Viêm mí mắt xác định khác',
}, {
  'id': 'H01.9',
  'english': 'Inflammation of eyelid, unspecified',
  'vietnamese': 'Viêm mí mắt không đặc hiệu',
}, {'id': 'H02', 'english': 'Other disorders of eyelid', 'vietnamese': 'Bệnh khác của mí mắt'}, {
  'id': 'H02.0',
  'english': 'Entropion and trichiasis of eyelid',
  'vietnamese': 'Quặm và lông xiêu của mí mắt',
}, {'id': 'H02.1', 'english': 'Ectropion of eyelid', 'vietnamese': 'Lật mi'}, {
  'id': 'H02.2',
  'english': 'Lagophthalmos',
  'vietnamese': 'Hở mi',
}, {'id': 'H02.3', 'english': 'Blepharochalasis', 'vietnamese': 'Sa da mi'}, {
  'id': 'H02.4',
  'english': 'Ptosis of eyelid',
  'vietnamese': 'Sụp mi',
}, {
  'id': 'H02.5',
  'english': 'Other disorders affecting eyelid function',
  'vietnamese': 'Bệnh khác ảnh hưởng đến chức năng của mí mắt',
}, {'id': 'H02.6', 'english': 'Xanthelasma of eyelid', 'vietnamese': 'U vàng ở mi mắt'}, {
  'id': 'H02.7',
  'english': 'Other degenerative disorders of eyelid and periocular area',
  'vietnamese': 'Bệnh thoái hóa khác của mi mắt và vùng quanh mắt',
}, {
  'id': 'H02.8',
  'english': 'Other specified disorders of eyelid',
  'vietnamese': 'Bệnh khác của mí mắt',
}, {
  'id': 'H02.9',
  'english': 'Disorder of eyelid, unspecified',
  'vietnamese': 'Bệnh mí mắt không đặc hiệu',
}, {
  'id': 'H03*',
  'english': 'Disorders of eyelid in diseases classified elsewhere',
  'vietnamese': 'Bệnh mí mắt trong các bệnh phân loại nơi khác',
}, {
  'id': 'H03.0*',
  'english': 'Parasitic infestation of eyelid in diseases classified elsewhere',
  'vietnamese': 'Nhiễm ký sinh trùng ở mí mắt trong các bệnh phân loại nơi khác',
}, {
  'id': 'H03.1*',
  'english': 'Involvement of eyelid in other infectious diseases classified elsewhere',
  'vietnamese': 'Liên quan đến mí mắt trong các bệnh khác phân loại nơi khác',
}, {
  'id': 'H03.8*',
  'english': 'Involvement of eyelid in other diseases classified elsewhere',
  'vietnamese': 'Liên quan đến mí mắt trong các bệnh khác phân loại nơi khác',
}, {'id': 'H04', 'english': 'Disorders of lacrimal system', 'vietnamese': 'Bệnh của lệ bộ'}, {
  'id': 'H04.0',
  'english': 'Dacryoadenitis',
  'vietnamese': 'Viêm tuyến lệ',
}, {
  'id': 'H04.1',
  'english': 'Other disorders of lacrimal gland (include Dry eye syndrome )',
  'vietnamese': 'Bệnh khác của tuyến lệ (bao gồm Hội chứng khô mắt)',
}, {'id': 'H04.2', 'english': 'Epiphora', 'vietnamese': 'Chảy nước mắt'}, {
  'id': 'H04.3',
  'english': 'Acute and unspecified inflammation of lacrimal passages',
  'vietnamese': 'Viêm lệ đạo cấp tính và không đặc hiệu',
}, {
  'id': 'H04.4',
  'english': 'Chronic inflammation of lacrimal passages',
  'vietnamese': 'Viêm lệ đạo mãn tính',
}, {
  'id': 'H04.5',
  'english': 'Stenosis and insufficiency of lacrimal passages',
  'vietnamese': 'Hẹp và suy giảm chức năng của lệ đạo',
}, {
  'id': 'H04.6',
  'english': 'Other changes in lacrimal passages',
  'vietnamese': 'Biến đổi khác trong lệ đạo',
}, {
  'id': 'H04.8',
  'english': 'Other disorders of lacrimal system',
  'vietnamese': 'Bệnh khác của lệ bộ',
}, {
  'id': 'H04.9',
  'english': 'Disorder of lacrimal system, unspecified',
  'vietnamese': 'Bệnh của lệ bộ, không đặc hiệu',
}, {'id': 'H05', 'english': 'Disorders of orbit', 'vietnamese': 'Bệnh của hốc mắt'}, {
  'id': 'H05.0',
  'english': 'Acute inflammation of orbit',
  'vietnamese': 'Viêm hốc mắt cấp tính',
}, {
  'id': 'H05.1',
  'english': 'Chronic inflammatory disorders of orbit',
  'vietnamese': 'Viêm hốc mắt mãn tính',
}, {'id': 'H05.2', 'english': 'Exophthalmic conditions', 'vietnamese': 'Các bệnh lồi mắt'}, {
  'id': 'H05.3',
  'english': 'Deformity of orbit',
  'vietnamese': 'Biến dạng của hốc mắt',
}, {'id': 'H05.4', 'english': 'Enophthalmos', 'vietnamese': 'Lõm mắt'}, {
  'id': 'H05.5',
  'english': 'Retained (old) foreign body following penetrating wound of orbit',
  'vietnamese': 'Dị vật do vết thương xuyên hốc mắt',
}, {
  'id': 'H05.8',
  'english': 'Other disorders of orbit',
  'vietnamese': 'Bệnh khác của hốc mắt',
}, {
  'id': 'H05.9',
  'english': 'Disorder of orbit, unspecified',
  'vietnamese': 'Bệnh của hốc mắt, không đặc hiệu',
}, {
  'id': 'H06*',
  'english': 'Disorders of lacrimal system and orbit in diseases classified elsewhere',
  'vietnamese': 'Bệnh của lệ bộ và hốc mắt trong các bệnh phân loại nơi khác',
}, {
  'id': 'H06.0*',
  'english': 'Disorders of lacrimal system in diseases classified elsewhere',
  'vietnamese': 'Bệnh của lệ bộ trong các bệnh phân loại nơi khác',
}, {
  'id': 'H06.1*',
  'english': 'Parasitic infestation of orbit in diseases classified elsewhere',
  'vietnamese': 'Nhiễm ký sinh trùng của hốc mắt trong các bệnh phân loại nơi khác',
}, {
  'id': 'H06.2*',
  'english': 'Dysthyroid exophthalmos (E05.- †)',
  'vietnamese': 'Lồi mắt do loạn năng tuyến giáp (E05.- †)',
}, {
  'id': 'H06.3*',
  'english': 'Other disorders of orbit in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của hốc mắt trong các bệnh phân loại nơi khác',
}, {'id': 'H10', 'english': 'Conjunctivitis', 'vietnamese': 'Viêm kết mạc'}, {
  'id': 'H10.0',
  'english': 'Mucopurulent conjunctivitis',
  'vietnamese': 'Viêm kết mạc nhầy mủ',
}, {
  'id': 'H10.1',
  'english': 'Acute atopic conjunctivitis',
  'vietnamese': 'Viêm kết mạc dị ứng cấp',
}, {
  'id': 'H10.2',
  'english': 'Other acute conjunctivitis',
  'vietnamese': 'Viêm kết mạc cấp khác',
}, {
  'id': 'H10.3',
  'english': 'Acute conjunctivitis, unspecified',
  'vietnamese': 'Viêm kết mạc cấp, không đặc hiệu',
}, {'id': 'H10.4', 'english': 'Chronic conjunctivitis', 'vietnamese': 'Viêm kết mạc mạn'}, {
  'id': 'H10.5',
  'english': 'Blepharoconjunctivitis',
  'vietnamese': 'Viêm kết mí mắt',
}, {'id': 'H10.8', 'english': 'Other conjunctivitis', 'vietnamese': 'Viêm kết mạc khác'}, {
  'id': 'H10.9',
  'english': 'Conjunctivitis, unspecified',
  'vietnamese': 'Viêm kết mạc, không đặc hiệu',
}, {
  'id': 'H11',
  'english': 'Other disorders of conjunctiva',
  'vietnamese': 'Bệnh khác của kết mạc',
}, {'id': 'H11.0', 'english': 'Pterygium', 'vietnamese': 'Mộng thịt'}, {
  'id': 'H11.1',
  'english': 'Conjunctival degenerations and deposits',
  'vietnamese': 'thoái hóa kết mạc',
}, {'id': 'H11.2', 'english': 'Conjunctival scars', 'vietnamese': 'Sẹo kết mạc'}, {
  'id': 'H11.3',
  'english': 'Conjunctival haemorrhage',
  'vietnamese': 'Xuất huyết kết mạc',
}, {
  'id': 'H11.4',
  'english': 'Other conjunctival vascular disorders and cysts',
  'vietnamese': 'Nang các bệnh mạch máu kết mạc khác',
}, {
  'id': 'H11.8',
  'english': 'Other specified disorders of conjunctiva',
  'vietnamese': 'Bệnh xác định khác của kết mạc',
}, {
  'id': 'H11.9',
  'english': 'Disorder of conjunctiva, unspecified',
  'vietnamese': 'Bệnh của kết mạc, không đặc hiệu',
}, {
  'id': 'H13*',
  'english': 'Disorders of conjunctiva in diseases classified elsewhere',
  'vietnamese': 'Bệnh của kết mạc trong các bệnh phân loại nơi khác',
}, {
  'id': 'H13.0*',
  'english': 'Filarial infection of conjunctiva (B74.-†)',
  'vietnamese': 'Nhiễm giun chỉ ở kết mạc (B74.-†)',
}, {
  'id': 'H13.1*',
  'english': 'Conjunctivitis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm kết mạc trong các bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'H13.2*',
  'english': 'Conjunctivitis in other diseases classified elsewhere',
  'vietnamese': 'Viêm kết mạc trong bệnh khác, phân loại nơi khác',
}, {
  'id': 'H13.3*',
  'english': 'Ocular pemphigoid (L12.-†)',
  'vietnamese': 'Dạng pempigus ở mắt (L12.-†)',
}, {
  'id': 'H13.8*',
  'english': 'Other disorders of conjunctiva in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của kết mạc trong các bệnh phân loại nơi khác',
}, {'id': 'H15', 'english': 'Disorders of sclera', 'vietnamese': 'Bệnh của củng mạc'}, {
  'id': 'H15.0',
  'english': 'Scleritis',
  'vietnamese': 'Viêm củng mạc',
}, {'id': 'H15.1', 'english': 'Episcleritis', 'vietnamese': 'Viêm thượng củng mạc'}, {
  'id': 'H15.8',
  'english': 'Other disorders of sclera',
  'vietnamese': 'Bệnh khác của củng mạc',
}, {
  'id': 'H15.9',
  'english': 'Disorder of sclera, unspecified',
  'vietnamese': 'Bệnh củng mạc, không đặc hiệu',
}, {'id': 'H16', 'english': 'Keratitis', 'vietnamese': 'Viêm giác mạc'}, {
  'id': 'H16.0',
  'english': 'Corneal ulcer',
  'vietnamese': 'Loét giác mạc',
}, {
  'id': 'H16.1',
  'english': 'Other superficial keratitis without conjunctivitis',
  'vietnamese': 'Viêm giác mạc nông khác không viêm kết mạc',
}, {'id': 'H16.2', 'english': 'Keratoconjunctivitis', 'vietnamese': 'Viêm kết giác mạc'}, {
  'id': 'H16.3',
  'english': 'Interstitial and deep keratitis',
  'vietnamese': 'Viêm giác mạc sâu và viêm giác mạc kẽ',
}, {'id': 'H16.4', 'english': 'Corneal neovascularization', 'vietnamese': 'Tân mạch giác mạc'}, {
  'id': 'H16.8',
  'english': 'Other keratitis',
  'vietnamese': 'Viêm giác mạc khác',
}, {
  'id': 'H16.9',
  'english': 'Keratitis, unspecified',
  'vietnamese': 'Viêm giác mạc, không đặc hiệu',
}, {'id': 'H17', 'english': 'Corneal scars and opacities', 'vietnamese': 'Sẹo và đục giác mạc'}, {
  'id': 'H17.0',
  'english': 'Adherent leukoma',
  'vietnamese': 'Sẹo dính',
}, {
  'id': 'H17.1',
  'english': 'Other central corneal opacity',
  'vietnamese': 'Đục giác mạc trung tâm khác',
}, {
  'id': 'H17.8',
  'english': 'Other corneal scars and opacities',
  'vietnamese': 'Sẹo và đục giác mạc khác',
}, {
  'id': 'H17.9',
  'english': 'Corneal scar and opacity, unspecified',
  'vietnamese': 'Sẹo và đục giác mạc, không đặc hiệu',
}, {
  'id': 'H18',
  'english': 'Other disorders of cornea',
  'vietnamese': 'Bệnh khác của giác mạc',
}, {
  'id': 'H18.0',
  'english': 'Corneal pigmentations and deposits',
  'vietnamese': 'Nhiễm sắc tố và lắng đọng ở giác mạc',
}, {'id': 'H18.1', 'english': 'Bullous keratopathy', 'vietnamese': 'Bệnh giác mạc bọng'}, {
  'id': 'H18.2',
  'english': 'Other corneal oedema',
  'vietnamese': 'Phù giác mạc khác',
}, {
  'id': 'H18.3',
  'english': 'Changes in corneal membranes',
  'vietnamese': 'Biến đổi ở các lớp giác mạc',
}, {'id': 'H18.4', 'english': 'Corneal degeneration', 'vietnamese': 'thoái hóa giác mạc'}, {
  'id': 'H18.5',
  'english': 'Hereditary corneal dystrophies',
  'vietnamese': 'Loạn dưỡng giác mạc di truyền',
}, {'id': 'H18.6', 'english': 'Keratoconus', 'vietnamese': 'Giác mạc hình chóp'}, {
  'id': 'H18.7',
  'english': 'Other corneal deformities',
  'vietnamese': 'Biến dạng giác mạc khác',
}, {
  'id': 'H18.8',
  'english': 'Other specified disorders of cornea',
  'vietnamese': 'Bệnh giác mạc xác định khác',
}, {
  'id': 'H18.9',
  'english': 'Disorder of cornea, unspecified',
  'vietnamese': 'Bệnh giác mạc không đặc hiệu',
}, {
  'id': 'H19*',
  'english': 'Disorders of sclera and cornea in diseases classified elsewhere',
  'vietnamese': 'Bệnh củng mạc và giác mạc trong các bệnh phân loại nơi khác',
}, {
  'id': 'H19.0*',
  'english': 'Scleritis and episcleritis in diseases classified elsewhere',
  'vietnamese': 'Viêm củng mạc và thượng củng mạc trong các bệnh phân loại nơi khác',
}, {
  'id': 'H19.1*',
  'english': 'Herpesviral keratitis and keratoconjunctivitis (B00.5†)',
  'vietnamese': 'Viêm giác mạc và viêm kết mạc do virus Herpes (B00.5†)',
}, {
  'id': 'H19.2*',
  'english': 'Keratitis and keratoconjunctivitis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm giác mạc và viêm kết giác mạc trong bệnh nhiễm trùng và nhiễm ký sinh khác phân loại nơi khác',
}, {
  'id': 'H19.3*',
  'english': 'Keratitis and keratoconjunctivitis in other diseases classified elsewhere',
  'vietnamese': 'Viêm giác mạc và viêm kết giác mạc trong bệnh khác, phân loại nơi khác',
}, {
  'id': 'H19.8*',
  'english': 'Other disorders of sclera and cornea in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của củng mạc và giác mạc trong bệnh phân loại nơi khác',
}, {'id': 'H20', 'english': 'Iridocyclitis', 'vietnamese': 'Viêm mống thể mi'}, {
  'id': 'H20.0',
  'english': 'Acute and subacute iridocyclitis',
  'vietnamese': 'Viêm mống mắt thể mi cấp và bán cấp',
}, {
  'id': 'H20.1',
  'english': 'Chronic iridocyclitis',
  'vietnamese': 'Viêm mống mắt thể mi mạn',
}, {
  'id': 'H20.2',
  'english': 'Lens-induced iridocyclitis',
  'vietnamese': 'Viêm mống mắt thể mi do thể thủy tinh',
}, {'id': 'H20.8', 'english': 'Other iridocyclitis', 'vietnamese': 'Viêm mống mắt thể mi khác'}, {
  'id': 'H20.9',
  'english': 'Iridocyclitis, unspecified',
  'vietnamese': 'Viêm mống mắt thể mi, không đặc hiệu',
}, {
  'id': 'H21',
  'english': 'Other disorders of iris and ciliary body',
  'vietnamese': 'Bệnh khác của mống mắt và thể mi',
}, {'id': 'H21.0', 'english': 'Hyphaema', 'vietnamese': 'Xuất huyết tiền phòng'}, {
  'id': 'H21.1',
  'english': 'Other vascular disorders of iris and ciliary body',
  'vietnamese': 'Bệnh mạch máu khác của mống mắt và thể mi',
}, {
  'id': 'H21.2',
  'english': 'Degeneration of iris and ciliary body',
  'vietnamese': 'thoái hóa mống mắt và thể mi',
}, {
  'id': 'H21.3',
  'english': 'Cyst of iris, ciliary body and anterior chamber',
  'vietnamese': 'Nang mống mắt, thể mi và tiền phòng',
}, {'id': 'H21.4', 'english': 'Pupillary membranes', 'vietnamese': 'Màng đồng từ'}, {
  'id': 'H21.5',
  'english': 'Other adhesions and disruptions of iris and ciliary body',
  'vietnamese': 'Dính và đứt khác của mống mắt và thể mi',
}, {
  'id': 'H21.8',
  'english': 'Other specified disorders of iris and ciliary body',
  'vietnamese': 'Bệnh khác của mống mắt và thể mi',
}, {
  'id': 'H21.9',
  'english': 'Disorder of iris and ciliary body, unspecified',
  'vietnamese': 'Bệnh của mống mắt và thể mi, không đặc hiệu',
}, {
  'id': 'H22*',
  'english': 'Disorders of iris and ciliary body in diseases classified elsewhere',
  'vietnamese': 'Bệnh của mống mắt và thể mi trong bệnh phân loại nơi khác',
}, {
  'id': 'H22.0*',
  'english': 'Iridocyclitis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm mống mắt thể mi trong các bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'H22.1*',
  'english': 'Iridocyclitis in other diseases classified elsewhere',
  'vietnamese': 'Viêm mống mắt thể mi trong các bệnh khác, phân loại nơi khác',
}, {
  'id': 'H22.8*',
  'english': 'Other disorders of iris and ciliary body in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của mống mắt và thể mi trong các bệnh phân loại nơi khác',
}, {'id': 'H25', 'english': 'Senile cataract', 'vietnamese': 'Đục thủy tinh thể người già'}, {
  'id': 'H25.0',
  'english': 'Senile incipient cataract',
  'vietnamese': 'Đục thủy tinh thể bắt đầu ở người già',
}, {
  'id': 'H25.1',
  'english': 'Senile nuclear cataract',
  'vietnamese': 'Đục thủy tinh thể vùng nhân ở người già',
}, {
  'id': 'H25.2',
  'english': 'Senile cataract, morgagnian type',
  'vietnamese': 'Đục thủy tinh thể hình thái Morgagni',
}, {
  'id': 'H25.8',
  'english': 'Other senile cataract',
  'vietnamese': 'Đục thủy tinh thể người già khác',
}, {
  'id': 'H25.9',
  'english': 'Senile cataract, unspecified',
  'vietnamese': 'Đục thủy tinh thể người già, không đặc hiệu',
}, {'id': 'H26', 'english': 'Other cataract', 'vietnamese': 'Đục thủy tinh thể khác'}, {
  'id': 'H26.0',
  'english': 'Infantile, juvenile and presenile cataract',
  'vietnamese': 'Đục thủy tinh thể trẻ em, người trẻ và trước tuổi già',
}, {
  'id': 'H26.1',
  'english': 'Traumatic cataract',
  'vietnamese': 'Đục thủy tinh thể do chấn thương',
}, {
  'id': 'H26.2',
  'english': 'Complicated cataract',
  'vietnamese': 'Đục thủy tinh thể biến chứng',
}, {
  'id': 'H26.3',
  'english': 'Drug-induced cataract',
  'vietnamese': 'Đục thủy tinh thể do thuốc',
}, {
  'id': 'H26.4',
  'english': 'After-cataract',
  'vietnamese': 'Đục bao sau mổ đục thủy tinh thể ngoài bao',
}, {
  'id': 'H26.8',
  'english': 'Other specified cataract',
  'vietnamese': 'Đục thủy tinh thể xác định khác',
}, {
  'id': 'H26.9',
  'english': 'Cataract, unspecified',
  'vietnamese': 'Đục thủy tinh thể, không đặc hiệu khác',
}, {
  'id': 'H27',
  'english': 'Other disorders of lens',
  'vietnamese': 'Các bệnh khác của thủy tinh thể',
}, {'id': 'H27.0', 'english': 'Aphakia', 'vietnamese': 'Không có thủy tinh thể'}, {
  'id': 'H27.1',
  'english': 'Dislocation of lens',
  'vietnamese': 'Lệch thủy tinh thể',
}, {
  'id': 'H27.8',
  'english': 'Other specified disorders of lens',
  'vietnamese': 'Bệnh thủy tinh thể xác định khác',
}, {
  'id': 'H27.9',
  'english': 'Disorder of lens, unspecified',
  'vietnamese': 'Bệnh thủy tinh thể, không đặc hiệu',
}, {
  'id': 'H28',
  'english': 'Cataract and other disorders of lens in diseases classified elsewhere',
  'vietnamese': 'Đục thủy tinh thể và bệnh của thủy tinh thể khác trong các bệnh phân loại nơi khác',
}, {
  'id': 'H28.0*',
  'english': 'Diabetic cataract (E10-E14 with common fourth character .3†)',
  'vietnamese': 'Đục thủy tinh thể do đái tháo đường (E10-E14 với ký tự thứ tư chung la .3†)',
}, {
  'id': 'H28.1*',
  'english': 'Cataract in other endocrine, nutritional and metabolic diseases',
  'vietnamese': 'Đục thủy tinh thể trong các bệnh nội tiết, dinh dưỡng và chuyển hóa khác',
}, {
  'id': 'H28.2*',
  'english': 'Cataract in other diseases classified elsewhere',
  'vietnamese': 'Đục thủy tinh thể trong bệnh khác phân loại nơi khác',
}, {
  'id': 'H28.8*',
  'english': 'Other disorders of lens in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của thủy tinh thể trong các bệnh phân loại nơi khác',
}, {'id': 'H30', 'english': 'Chorioretinal inflammation', 'vietnamese': 'Viêm hắc võng mạc'}, {
  'id': 'H30.0',
  'english': 'Focal chorioretinal inflammation',
  'vietnamese': 'Viêm hắc võng mạc khu trú',
}, {
  'id': 'H30.1',
  'english': 'Disseminated chorioretinal inflammation',
  'vietnamese': 'Viêm hắc võng mạc rải rác',
}, {'id': 'H30.2', 'english': 'Posterior cyclitis', 'vietnamese': 'Viêm thể mi sau'}, {
  'id': 'H30.8',
  'english': 'Other chorioretinal inflammations',
  'vietnamese': 'Bệnh viêm hắc võng mạc khác',
}, {
  'id': 'H30.9',
  'english': 'Chorioretinal inflammation, unspecified',
  'vietnamese': 'Viêm hắc võng mạc, không đặc hiệu',
}, {
  'id': 'H31',
  'english': 'Other disorders of choroid',
  'vietnamese': 'Bệnh khác của hắc mạc',
}, {
  'id': 'H31.0',
  'english': 'Chorioretinal scars',
  'vietnamese': 'Sẹo hắc võng mạc',
}, {'id': 'H31.1', 'english': 'Choroidal degeneration', 'vietnamese': 'thoái hóa hắc mạc'}, {
  'id': 'H31.2',
  'english': 'Hereditary choroidal dystrophy',
  'vietnamese': 'Loạn dưỡng hắc mạc di truyền',
}, {
  'id': 'H31.3',
  'english': 'Choroidal haemorrhage and rupture',
  'vietnamese': 'Xuất huyết và rách hắc mạc',
}, {'id': 'H31.4', 'english': 'Choroidal detachment', 'vietnamese': 'Bong hắc mạc'}, {
  'id': 'H31.8',
  'english': 'Other specified disorders of choroid',
  'vietnamese': 'Bệnh xác định khác của hắc mạc',
}, {
  'id': 'H31.9',
  'english': 'Disorder of choroid, unspecified',
  'vietnamese': 'Bệnh hắc mạc, không đặc hiệu',
}, {
  'id': 'H32*',
  'english': 'Chorioretinal disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh hắc võng mạc trong các bệnh phân loại nơi khác',
}, {
  'id': 'H32.0*',
  'english': 'Chorioretinal inflammation in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm hắc võng mạc trong bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'H32.8*',
  'english': 'Other chorioretinal disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh hắc võng mạc khác trong các bệnh phân loại nơi khác',
}, {
  'id': 'H33',
  'english': 'Retinal detachments and breaks',
  'vietnamese': 'Bong và rách võng mạc',
}, {
  'id': 'H33.0',
  'english': 'Retinal detachment with retinal break',
  'vietnamese': 'Bong võng mạc có vết rách',
}, {
  'id': 'H33.1',
  'english': 'Retinoschisis and retinal cysts',
  'vietnamese': 'Tách lớp võng mạc và nang võng mạc',
}, {
  'id': 'H33.2',
  'english': 'Serous retinal detachment',
  'vietnamese': 'Bong võng mạc thanh dịch',
}, {
  'id': 'H33.3',
  'english': 'Retinal breaks without detachment',
  'vietnamese': 'Vết rách võng mạc không có bong',
}, {
  'id': 'H33.4',
  'english': 'Traction detachment of retina',
  'vietnamese': 'Bong võng mạc do kéo',
}, {'id': 'H33.5', 'english': 'Other retinal detachments', 'vietnamese': 'Bong võng mạc khác'}, {
  'id': 'H34',
  'english': 'Retinal vascular occlusions',
  'vietnamese': 'Tắc mạch võng mạc',
}, {
  'id': 'H34.0',
  'english': 'Transient retinal artery occlusion',
  'vietnamese': 'Tắc động mạch võng mạc thóang qua',
}, {
  'id': 'H34.1',
  'english': 'Central retinal artery occlusion',
  'vietnamese': 'Tắc động mạch trung tâm võng mạc',
}, {
  'id': 'H34.2',
  'english': 'Other retinal artery occlusions',
  'vietnamese': 'Tắc động mạch võng mạc khác',
}, {
  'id': 'H34.8',
  'english': 'Other retinal vascular occlusions',
  'vietnamese': 'Các tắc mạch võng mạc khác',
}, {
  'id': 'H34.9',
  'english': 'Retinal vascular occlusion, unspecified',
  'vietnamese': 'Tắc mạch võng mạc, không đặc hiệu',
}, {'id': 'H35', 'english': 'Other retinal disorders', 'vietnamese': 'Các bệnh võng mạc khác'}, {
  'id': 'H35.0',
  'english': 'Background retinopathy and retinal vascular changes',
  'vietnamese': 'Bệnh lý võng mạc sơ phát và biến đổi mạch máu võng mạc',
}, {
  'id': 'H35.1',
  'english': 'Retinopathy of prematurity',
  'vietnamese': 'Bệnh lý võng mạc của trẻ đẻ non',
}, {
  'id': 'H35.2',
  'english': 'Other proliferative retinopathy',
  'vietnamese': 'Bệnh lý võng mạc tăng sinh khác',
}, {
  'id': 'H35.3',
  'english': 'Degeneration of macula and posterior pole',
  'vietnamese': 'thoái hóa hoàng điểm và cực sau',
}, {
  'id': 'H35.4',
  'english': 'Peripheral retinal degeneration',
  'vietnamese': 'thoái hóa võng mạc ngoại biên',
}, {
  'id': 'H35.5',
  'english': 'Hereditary retinal dystrophy',
  'vietnamese': 'Loạn dưỡng võng mạc di truyền',
}, {'id': 'H35.6', 'english': 'Retinal haemorrhage', 'vietnamese': 'Xuất huyết võng mạc'}, {
  'id': 'H35.7',
  'english': 'Separation of retinal layers',
  'vietnamese': 'Bong các lớp võng mạc',
}, {
  'id': 'H35.8',
  'english': 'Other specified retinal disorders',
  'vietnamese': 'Bệnh võng mạc xác định khác',
}, {
  'id': 'H35.9',
  'english': 'Retinal disorder, unspecified',
  'vietnamese': 'Bệnh võng mạc, không đặc hiệu',
}, {
  'id': 'H36*',
  'english': 'Retinal disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh võng mạc trong các bệnh phân loại nơi khác',
}, {
  'id': 'H36.0*',
  'english': 'Diabetic retinopathy (E10-E14 with common fourth character .3†)',
  'vietnamese': 'Bệnh lý võng mạc do đái tháo đường (E10-E14 với ký tự thứ tư chung là .3†)',
}, {
  'id': 'H36.8*',
  'english': 'Other retinal disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh võng mạc khác trong các bệnh phân loại nơi khác',
}, {'id': 'H40', 'english': 'Glaucoma', 'vietnamese': 'Glôcôm'}, {
  'id': 'H40.0',
  'english': 'Glaucoma suspect',
  'vietnamese': 'Nghi ngờ glôcôm',
}, {
  'id': 'H40.1',
  'english': 'Primary open-angle glaucoma',
  'vietnamese': 'Glôcôm góc mở nguyên phát',
}, {
  'id': 'H40.2',
  'english': 'Primary angle-closure glaucoma',
  'vietnamese': 'Glôcôm góc đóng nguyên phát',
}, {
  'id': 'H40.3',
  'english': 'Glaucoma secondary to eye trauma',
  'vietnamese': 'Glôcôm thứ phát do chấn thương mắt',
}, {
  'id': 'H40.4',
  'english': 'Glaucoma secondary to eye inflammation',
  'vietnamese': 'Glôcôm thứ phát do viêm mắt',
}, {
  'id': 'H40.5',
  'english': 'Glaucoma secondary to other eye disorders',
  'vietnamese': 'Glôcôm thứ phát do bệnh mắt khác',
}, {
  'id': 'H40.6',
  'english': 'Glaucoma secondary to drugs',
  'vietnamese': 'Glôcôm thứ phát do thuốc',
}, {'id': 'H40.8', 'english': 'Other glaucoma', 'vietnamese': 'Glôcôm khác'}, {
  'id': 'H40.9',
  'english': 'Glaucoma, unspecified',
  'vietnamese': 'Glôcôm, không đặc hiệu',
}, {
  'id': 'H42*',
  'english': 'Glaucoma in diseases classified elsewhere',
  'vietnamese': 'Glôcôm trong các bệnh phân loại nơi khác',
}, {
  'id': 'H42.0*',
  'english': 'Glaucoma in endocrine, nutritional and metabolic diseases',
  'vietnamese': 'Glôcôm trong bệnh nội tiết, dinh dưỡng và chuyển h',
}, {
  'id': 'H42.8*',
  'english': 'Glaucoma in other diseases classified elsewhere',
  'vietnamese': 'Glôcôm trong các bệnh phân loại nơi khác',
}, {'id': 'H43', 'english': 'Disorders of vitreous body', 'vietnamese': 'Bệnh của dịch kính'}, {
  'id': 'H43.0',
  'english': 'Vitreous prolapse',
  'vietnamese': 'Phòi dịch kính',
}, {'id': 'H43.1', 'english': 'Vitreous haemorrhage', 'vietnamese': 'Xuất huyết dịch kính'}, {
  'id': 'H43.2',
  'english': 'Crystalline deposits in vitreous body',
  'vietnamese': 'Cặn lắng tinh thể trong dịch kính',
}, {
  'id': 'H43.3',
  'english': 'Other vitreous opacities',
  'vietnamese': 'Vẩn đục dịch kính khác',
}, {
  'id': 'H43.8',
  'english': 'Other disorders of vitreous body',
  'vietnamese': 'Bệnh khác của dịch kính',
}, {
  'id': 'H43.9',
  'english': 'Disorder of vitreous body, unspecified',
  'vietnamese': 'Bệnh dịch kính, không đặc hiệu',
}, {'id': 'H44', 'english': 'Disorders of globe', 'vietnamese': 'Bệnh của nhãn cầu'}, {
  'id': 'H44.0',
  'english': 'Purulent endophthalmitis',
  'vietnamese': 'Viêm mủ nội nhãn',
}, {'id': 'H44.1', 'english': 'Other endophthalmitis', 'vietnamese': 'Viêm nội nhãn khác'}, {
  'id': 'H44.2',
  'english': 'Degenerative myopia',
  'vietnamese': 'Cận thị thoái hóa',
}, {
  'id': 'H44.3',
  'english': 'Other degenerative disorders of globe',
  'vietnamese': 'Bệnh thoái hóa khác của nhãn cầu',
}, {'id': 'H44.4', 'english': 'Hypotony of eye', 'vietnamese': 'Hạ nhãn áp'}, {
  'id': 'H44.5',
  'english': 'Degenerated conditions of globe',
  'vietnamese': 'Các bệnh thoái hóa của nhãn cầu',
}, {
  'id': 'H44.6',
  'english': 'Retained (old) intraocular foreign body, magnetic',
  'vietnamese': 'Dị vật nội nhãn, có từ tính',
}, {
  'id': 'H44.7',
  'english': 'Retained (old) intraocular foreign body, nonmagnetic',
  'vietnamese': 'Dị vật nội nhãn, không từ tính',
}, {
  'id': 'H44.8',
  'english': 'Other disorders of globe',
  'vietnamese': 'Các bệnh khác của nhãn cầu',
}, {
  'id': 'H44.9',
  'english': 'Disorder of globe, unspecified',
  'vietnamese': 'Bệnh nhãn cầu, không đặc hiệu',
}, {
  'id': 'H45*',
  'english': 'Disorders of vitreous body and globe in diseases classified elsewhere',
  'vietnamese': 'Bệnh của dịch kính và nhãn cầu trong các bệnh phân loại nơi khác',
}, {
  'id': 'H45.0*',
  'english': 'Vitreous haemorrhage in diseases classified elsewhere',
  'vietnamese': 'Xuất huyết dịch kính trong các bệnh phân loại nơi khác',
}, {
  'id': 'H45.1*',
  'english': 'Endophthalmitis in diseases classified elsewhere',
  'vietnamese': 'Viêm nội nhãn trong các bệnh phân loại nơi khác',
}, {
  'id': 'H45.8*',
  'english': 'Other disorders of vitreous body and globe in diseases classified elsewhere',
  'vietnamese': 'Các bệnh khác của dịch kính và nhãn cầu trong các bệnh phân loại nơi khác',
}, {'id': 'H46', 'english': 'Optic neuritis', 'vietnamese': 'Viêm thần kinh thị'}, {
  'id': 'H47',
  'english': 'Other disorders of optic [2nd] nerve and visual pathways',
  'vietnamese': 'Các bệnh khác của thần kinh thị (dây thần kinh II) và đường thị giác',
}, {
  'id': 'H47.0',
  'english': 'Disorders of optic nerve, not elsewhere classified',
  'vietnamese': 'Bệnh thần kinh thị, không phân loại nơi khác',
}, {
  'id': 'H47.1',
  'english': 'Papilloedema, unspecified',
  'vietnamese': 'Phù gai, không đặc hiệu',
}, {
  'id': 'H47.2',
  'english': 'Optic atrophy',
  'vietnamese': 'Teo thần kinh thị',
}, {
  'id': 'H47.3',
  'english': 'Other disorders of optic disc',
  'vietnamese': 'Bệnh khác của đĩa thị - khuyết gai thị',
}, {
  'id': 'H47.4',
  'english': 'Disorders of optic chiasm',
  'vietnamese': 'Bệnh giao thoa thị giác',
}, {
  'id': 'H47.5',
  'english': 'Disorders of other visual pathways',
  'vietnamese': 'Bệnh đường thị giác',
}, {
  'id': 'H47.6',
  'english': 'Disorders of visual cortex',
  'vietnamese': 'Bệnh vỏ não thị giác',
}, {
  'id': 'H47.7',
  'english': 'Disorder of visual pathways, unspecified',
  'vietnamese': 'Bệnh đường thị giác, không đặc hiệu',
}, {
  'id': 'H48*',
  'english': 'Disorders of optic [2nd] nerve and visual pathways in diseases classified elsewhere',
  'vietnamese': 'Bệnh thần kinh thị và đường dẫn thị giác trong các bệnh phân loại nơi khác',
}, {
  'id': 'H48.0*',
  'english': 'Optic atrophy in diseases classified elsewhere',
  'vietnamese': 'Teo thần kinh thị trong các bệnh phân loại nơi khác',
}, {
  'id': 'H48.1*',
  'english': 'Retrobulbar neuritis in diseases classified elsewhere',
  'vietnamese': 'Viêm thần kinh hậu cầu trong các bệnh phân loại nơi khác',
}, {
  'id': 'H48.8*',
  'english': 'Other disorders of optic nerve and visual pathways in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của thần kinh thị và đường dẫn truyền thị giác trong các bệnh phân loại nơi khác',
}, {'id': 'H49', 'english': 'Paralytic strabismus', 'vietnamese': 'Lác liệt'}, {
  'id': 'H49.0',
  'english': 'Third [oculomotor] nerve palsy',
  'vietnamese': 'Liệt dây thần kinh [vận nhãn] III',
}, {
  'id': 'H49.1',
  'english': 'Fourth [trochlear] nerve palsy',
  'vietnamese': 'Liệt dây thần kinh [ròng rọc] IV',
}, {
  'id': 'H49.2',
  'english': 'Sixth [abducent] nerve palsy',
  'vietnamese': 'Liệt dây thần kinh [giạng] VI',
}, {
  'id': 'H49.3',
  'english': 'Total (external) ophthalmoplegia',
  'vietnamese': 'Liệt vận nhãn (ngoài) toàn bộ',
}, {
  'id': 'H49.4',
  'english': 'Progressive external ophthalmoplegia',
  'vietnamese': 'Liệt vận nhãn ngoài tiến triển',
}, {'id': 'H49.8', 'english': 'Other paralytic strabismus', 'vietnamese': 'Các loại liệt khác'}, {
  'id': 'H49.9',
  'english': 'Paralytic strabismus, unspecified',
  'vietnamese': 'Các loại liệt, không đặc hiệu',
}, {'id': 'H50', 'english': 'Other strabismus', 'vietnamese': 'Lác khác'}, {
  'id': 'H50.0',
  'english': 'Convergent concomitant strabismus',
  'vietnamese': 'Lác hội tụ đồng hành',
}, {
  'id': 'H50.1',
  'english': 'Divergent concomitant strabismus',
  'vietnamese': 'Lác phân kỳ đồng hành',
}, {'id': 'H50.2', 'english': 'Vertical strabismus', 'vietnamese': 'Lác đứng'}, {
  'id': 'H50.3',
  'english': 'Intermittent heterotropia',
  'vietnamese': 'Lác từng hồi',
}, {
  'id': 'H50.4',
  'english': 'Other and unspecified heterotropia',
  'vietnamese': 'Lác khác và lác không đặc hiệu',
}, {'id': 'H50.5', 'english': 'Heterophoria', 'vietnamese': 'Lác ẩn'}, {
  'id': 'H50.6',
  'english': 'Mechanical strabismus',
  'vietnamese': 'Lác cơ học',
}, {'id': 'H50.8', 'english': 'Other specified strabismus', 'vietnamese': 'Lác xác định khác'}, {
  'id': 'H50.9',
  'english': 'Strabismus, unspecified',
  'vietnamese': 'Lác, không đặc hiệu',
}, {
  'id': 'H51',
  'english': 'Other disorders of binocular movement',
  'vietnamese': 'Các rối loạn vận nhãn hai mắt khác',
}, {
  'id': 'H51.0',
  'english': 'Palsy of conjugate gaze',
  'vietnamese': 'Liệt phối hợp hướng nhìn',
}, {
  'id': 'H51.1',
  'english': 'Convergence insufficiency and excess',
  'vietnamese': 'Thiểu năng hay gia tăng quy tụ',
}, {
  'id': 'H51.2',
  'english': 'Internuclear ophthalmoplegia',
  'vietnamese': 'Liệt vận nhãn gian',
}, {
  'id': 'H51.8',
  'english': 'Other specified disorders of binocular movement',
  'vietnamese': 'Các rối loạn vận nhãn hai mắt xác định khác',
}, {
  'id': 'H51.9',
  'english': 'Disorder of binocular movement, unspecified',
  'vietnamese': 'Rối loạn vận nhãn hai mắt, không đặc hiệu',
}, {
  'id': 'H52',
  'english': 'Disorders of refraction and accommodation',
  'vietnamese': 'Bệnh khúc xạ và điều tiết',
}, {'id': 'H52.0', 'english': 'Hypermetropia', 'vietnamese': 'Viễn thị'}, {
  'id': 'H52.1',
  'english': 'Myopia',
  'vietnamese': 'Cận thị',
}, {'id': 'H52.2', 'english': 'Astigmatism', 'vietnamese': 'Loạn thị'}, {
  'id': 'H52.3',
  'english': 'Anisometropia and aniseikonia',
  'vietnamese': 'Khúc xạ hai mắt không đều và hình võng mạc hai mắt không đều',
}, {'id': 'H52.4', 'english': 'Presbyopia', 'vietnamese': 'Lão thị'}, {
  'id': 'H52.5',
  'english': 'Disorders of accommodation',
  'vietnamese': 'Rối loạn điều tiết',
}, {
  'id': 'H52.6',
  'english': 'Other disorders of refraction',
  'vietnamese': 'Tật khúc xạ khác',
}, {
  'id': 'H52.7',
  'english': 'Disorder of refraction, unspecified',
  'vietnamese': 'Tật khúc xạ, không đặc hiệu',
}, {'id': 'H53', 'english': 'Visual disturbances', 'vietnamese': 'Rối loạn thị giác'}, {
  'id': 'H53.0',
  'english': 'Amblyopia ex anopsia',
  'vietnamese': 'Nhược thị do không nhìn',
}, {
  'id': 'H53.1',
  'english': 'Subjective visual disturbances',
  'vietnamese': 'Rối loạn thị giác chủ quan',
}, {'id': 'H53.2', 'english': 'Diplopia', 'vietnamese': 'Song thị'}, {
  'id': 'H53.3',
  'english': 'Other disorders of binocular vision',
  'vietnamese': 'Rối loạn thị giác hai mắt khác',
}, {'id': 'H53.4', 'english': 'Visual field defects', 'vietnamese': 'Tổn hại thị trường'}, {
  'id': 'H53.5',
  'english': 'Colour vision deficiencies',
  'vietnamese': 'Tổn hại sắc giác',
}, {'id': 'H53.6', 'english': 'Night blindness', 'vietnamese': 'Quáng gà'}, {
  'id': 'H53.8',
  'english': 'Other visual disturbances',
  'vietnamese': 'Rối loạn thị giác khác',
}, {
  'id': 'H53.9',
  'english': 'Visual disturbance, unspecified',
  'vietnamese': 'Rối loạn thị giác, không đặc hiệu',
}, {
  'id': 'H54',
  'english': 'Blindness and low vision',
  'vietnamese': 'Giảm thị lực bao gồm mù lòa (hai mắt hoặc một mắt)',
}, {'id': 'H54.0', 'english': 'Blindness, both eyes', 'vietnamese': 'Mù, hai mắt'}, {
  'id': 'H54.1',
  'english': 'Blindness, one eye, low vision other eye',
  'vietnamese': 'Giảm thị lực mức độ nặng, hai mắt',
}, {
  'id': 'H54.2',
  'english': 'Low vision, both eyes',
  'vietnamese': 'Giảm thị lực mức độ trung bình, hai mắt',
}, {
  'id': 'H54.3',
  'english': 'Unqualified visual loss, both eyes',
  'vietnamese': 'Giảm thị lực mức độ nhẹ hoặc không giảm, hai mắt',
}, {'id': 'H54.4', 'english': 'Blindness, one eye', 'vietnamese': 'Mù, một mắt'}, {
  'id': 'H54.5',
  'english': 'Low vision, one eye',
  'vietnamese': 'Giảm thị lực mức độ nặng, một mắt',
}, {
  'id': 'H54.6',
  'english': 'Unqualified visual loss, one eye',
  'vietnamese': 'Giảm thị lực mức độ trung bình, một mắt',
}, {
  'id': 'H54.7',
  'english': 'Unspecified visual loss',
  'vietnamese': 'Mất thị lực, không đặc hiệu',
}, {
  'id': 'H54.9',
  'english': 'Unspecified visual impairment (binocular)',
  'vietnamese': 'Giảm thị lực không đặc hiệu (hai mắt)',
}, {
  'id': 'H55',
  'english': 'Nystagmus and other irregular eye movements',
  'vietnamese': 'Rung giật nhãn cầu và rối loạn vận nhãn khác',
}, {
  'id': 'H57',
  'english': 'Other disorders of eye and adnexa',
  'vietnamese': 'Các bệnh khác của mắt và phần phụ',
}, {
  'id': 'H57.0',
  'english': 'Anomalies of pupillary function',
  'vietnamese': 'Bất thường chức năng đồng tử',
}, {'id': 'H57.1', 'english': 'Ocular pain', 'vietnamese': 'Nhức mắt'}, {
  'id': 'H57.8',
  'english': 'Other specified disorders of eye and adnexa',
  'vietnamese': 'Các bệnh xác định khác của mắt và phần phụ',
}, {
  'id': 'H57.9',
  'english': 'Disorder of eye and adnexa, unspecified',
  'vietnamese': 'Các bệnh của mắt và phần phụ, không đặc hiệu',
}, {
  'id': 'H58*',
  'english': 'Other disorders of eye and adnexa in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của mắt và phần phụ trong các bệnh phân loại nơi khác',
}, {
  'id': 'H58.0*',
  'english': 'Anomalies of pupillary function in diseases classified elsewhere',
  'vietnamese': 'Bất thường chức năng đồng tử trong các bệnh phân loại nơi khác',
}, {
  'id': 'H58.1*',
  'english': 'Visual disturbances in diseases classified elsewhere',
  'vietnamese': 'Rối loạn thị giác trong các bệnh phân loại nơi khác',
}, {
  'id': 'H58.8*',
  'english': 'Other specified disorders of eye and adnexa in diseases classified elsewhere',
  'vietnamese': 'Bệnh của mắt và phần phụ xác định khác trong các bệnh phân loại nơi khác',
}, {
  'id': 'H59',
  'english': 'Postprocedural disorders of eye and adnexa, not elsewhere classified',
  'vietnamese': 'Bệnh mắt và phần phụ sau phẫu thuật không phân loại nơi khác',
}, {
  'id': 'H59.0',
  'english': 'Keratopathy (bullous aphakic) following cataract surgery',
  'vietnamese': 'Bệnh lý giác mạc (không có thủy tinh thể)',
}, {
  'id': 'H59.8',
  'english': 'Other postprocedural disorders of eye and adnexa',
  'vietnamese': 'Bệnh khác của mắt và phần phụ sau phẫu thuật',
}, {
  'id': 'H59.9',
  'english': 'Postprocedural disorder of eye and adnexa, unspecified',
  'vietnamese': 'Bệnh mắt và phần phụ sau phẫu thuật, không đặc hiệu',
}, {'id': 'H60', 'english': 'Otitis externa', 'vietnamese': 'Viêm tai ngoài'}, {
  'id': 'H60.0',
  'english': 'Abscess of external ear',
  'vietnamese': 'Apxe tai ngoài',
}, {
  'id': 'H60.1',
  'english': 'Cellulitis of external ear',
  'vietnamese': 'Viêm mô tế bào tai ngoài',
}, {
  'id': 'H60.2',
  'english': 'Malignant otitis externa',
  'vietnamese': 'Viêm tai ngoài ác tính',
}, {
  'id': 'H60.3',
  'english': 'Other infective otitis externa',
  'vietnamese': 'Viêm tai ngoài nhiễm trùng khác',
}, {
  'id': 'H60.4',
  'english': 'Cholesteatoma of external ear',
  'vietnamese': 'Cholesteoma của tai ngoài',
}, {
  'id': 'H60.5',
  'english': 'Acute otitis externa, noninfective',
  'vietnamese': 'Viêm tai ngoài cấp không nhiễm trùng',
}, {'id': 'H60.8', 'english': 'Other otitis externa', 'vietnamese': 'Viêm tai ngoài khác'}, {
  'id': 'H60.9',
  'english': 'Otitis externa, unspecified',
  'vietnamese': 'Viêm tai ngoài, không đặc hiệu',
}, {
  'id': 'H61',
  'english': 'Other disorders of external ear',
  'vietnamese': 'Bệnh khác của tai ngoài',
}, {
  'id': 'H61.0',
  'english': 'Perichondritis of external ear',
  'vietnamese': 'Viêm màng bao sụn tai ngoài viêm màng sụn vành tai',
}, {
  'id': 'H61.1',
  'english': 'Noninfective disorders of pinna',
  'vietnamese': 'Bệnh loa tai không nhiễm trùng',
}, {'id': 'H61.2', 'english': 'Impacted cerumen', 'vietnamese': 'Nút ráy tai'}, {
  'id': 'H61.3',
  'english': 'Acquired stenosis of external ear canal',
  'vietnamese': 'Hẹp ống tai ngoài mắc phải',
}, {
  'id': 'H61.8',
  'english': 'Other specified disorders of external ear',
  'vietnamese': 'Bệnh tai ngoài xác định khác',
}, {
  'id': 'H61.9',
  'english': 'Disorder of external ear, unspecified',
  'vietnamese': 'Bệnh tai ngoài, không đặc hiệu',
}, {
  'id': 'H62*',
  'english': 'Disorders of external ear in diseases classified elsewhere',
  'vietnamese': 'Bệnh tai ngoài trong bệnh phân loại nơi khác',
}, {
  'id': 'H62.0*',
  'english': 'Otitis externa in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm tai ngoài trong bệnh nhiễm trùng phân loại nơi khác',
}, {
  'id': 'H62.1*',
  'english': 'Otitis externa in viral diseases classified elsewhere',
  'vietnamese': 'Viêm tai ngoài trong bệnh do virus phân loại nơi khác',
}, {
  'id': 'H62.2*',
  'english': 'Otitis externa in mycoses',
  'vietnamese': 'Viêm tai ngoài trong bệnh nhiễm nấm',
}, {
  'id': 'H62.3*',
  'english': 'Otitis externa in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm tai ngoài trong bệnh nhiễm trùng và ký sinh trùng khác phân loại nơi khác',
}, {
  'id': 'H62.4*',
  'english': 'Otitis externa in other diseases classified elsewhere',
  'vietnamese': 'Viêm tai ngoài trong bệnh khác phân loại nơi khác',
}, {
  'id': 'H62.8*',
  'english': 'Other disorders of external ear in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của tai ngoài trong bệnh phân loại nơi khác',
}, {
  'id': 'H65',
  'english': 'Nonsuppurative otitis media',
  'vietnamese': 'Viêm tai giữa không nung mủ viêm tai giữa không mủ',
}, {
  'id': 'H65.0',
  'english': 'Acute serous otitis media',
  'vietnamese': 'Viêm tai giữa xuất tiết cấp và bán cấp viêm tai giữa thanh dịch cấp',
}, {
  'id': 'H65.1',
  'english': 'Other acute nonsuppurative otitis media',
  'vietnamese': 'Viêm tai giữa không nung mủ cấp khác',
}, {
  'id': 'H65.2',
  'english': 'Chronic serous otitis media',
  'vietnamese': 'Viêm tai giữa xuất tiết mạn viêm tai giữa thanh dịch mạn',
}, {
  'id': 'H65.3',
  'english': 'Chronic mucoid otitis media',
  'vietnamese': 'Viêm tai giữa tiết nhày mạn',
}, {
  'id': 'H65.4',
  'english': 'Other chronic nonsuppurative otitis media',
  'vietnamese': 'Viêm tai giữa không nung mủ mạn khác',
}, {
  'id': 'H65.9',
  'english': 'Nonsuppurative otitis media, unspecified',
  'vietnamese': 'Viêm tai giữa không nung mủ, không đặc hiệu',
}, {
  'id': 'H66',
  'english': 'Suppurative and unspecified otitis media',
  'vietnamese': 'Viêm tai giữa nung mủ và không đặc hiệu',
}, {
  'id': 'H66.0',
  'english': 'Acute suppurative otitis media',
  'vietnamese': 'Viêm tai giữa nung mủ cấp viêm tai giữa cấp mủ',
}, {
  'id': 'H66.1',
  'english': 'Chronic tubotympanic suppurative otitis media',
  'vietnamese': 'Viêm tai giữa vòi nhĩ mủ mạn',
}, {
  'id': 'H66.2',
  'english': 'Chronic atticoantral suppurative otitis media',
  'vietnamese': 'Viêm tai giữa thượng nhĩ sào bào mủ mạn',
}, {
  'id': 'H66.3',
  'english': 'Other chronic suppurative otitis media',
  'vietnamese': 'Viêm tai giữa nung mủ mạn khác viêm tai giữa mủ mạn khác',
}, {
  'id': 'H66.4',
  'english': 'Suppurative otitis media, unspecified',
  'vietnamese': 'Viêm tai giữa nung mủ không đặc hiệu viêm tai giữa mủ không đặc hiệu',
}, {
  'id': 'H66.9',
  'english': 'Otitis media, unspecified',
  'vietnamese': 'Viêm tai giữa không đặc hiệu',
}, {
  'id': 'H67*',
  'english': 'Otitis media in diseases classified elsewhere',
  'vietnamese': 'Viêm tai giữa trong bệnh phân loại nơi khác',
}, {
  'id': 'H67.0*',
  'english': 'Otitis media in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm tai giữa trong bệnh nhiễm trùng phân loại nơi khác',
}, {
  'id': 'H67.1*',
  'english': 'Otitis media in viral diseases classified elsewhere',
  'vietnamese': 'Viêm tai giữa trong bệnh do virus phân loại nơi khác',
}, {
  'id': 'H67.8*',
  'english': 'Otitis media in other diseases classified elsewhere',
  'vietnamese': 'Viêm tai giữa trong bệnh khác phân loại nơi khác',
}, {
  'id': 'H68',
  'english': 'Eustachian salpingitis and obstruction',
  'vietnamese': 'Viêm và tắc vòi Eustache',
}, {'id': 'H68.0', 'english': 'Eustachian salpingitis', 'vietnamese': 'Viêm vòi Eustache'}, {
  'id': 'H68.1',
  'english': 'Obstruction of Eustachian tube',
  'vietnamese': 'Tắc vòi Eustache',
}, {
  'id': 'H69',
  'english': 'Other disorders of Eustachian tube',
  'vietnamese': 'Bệnh khác của vòi Eustache',
}, {'id': 'H69.0', 'english': 'Patulous Eustachian tube', 'vietnamese': 'Vòi Eustache rộng'}, {
  'id': 'H69.8',
  'english': 'Other specified disorders of Eustachian tube',
  'vietnamese': 'Bệnh vòi Eustache xác định khác',
}, {
  'id': 'H69.9',
  'english': 'Eustachian tube disorder, unspecified',
  'vietnamese': 'Bệnh vòi Eustache, không đặc hiệu',
}, {
  'id': 'H70',
  'english': 'Mastoiditis and related conditions',
  'vietnamese': 'Viêm xương chũm và tình trạng liên quan',
}, {'id': 'H70.0', 'english': 'Acute mastoiditis', 'vietnamese': 'Viêm xương chũm cấp'}, {
  'id': 'H70.1',
  'english': 'Chronic mastoiditis',
  'vietnamese': 'Viêm xương chũm mạn',
}, {'id': 'H70.2', 'english': 'Petrositis', 'vietnamese': 'Viêm xương đá'}, {
  'id': 'H70.8',
  'english': 'Other mastoiditis and related conditions',
  'vietnamese': 'Viêm xương chũm khác và tình trạng liên quan',
}, {
  'id': 'H70.9',
  'english': 'Mastoiditis, unspecified',
  'vietnamese': 'Viêm xương chũm, không đặc hiệu',
}, {
  'id': 'H71',
  'english': 'Cholesteatoma of middle ear',
  'vietnamese': 'Cholesteatoma của tai giữa',
}, {'id': 'H72', 'english': 'Perforation of tympanic membrane', 'vietnamese': 'Thủng màng nhĩ'}, {
  'id': 'H72.0',
  'english': 'Central perforation of tympanic membrane',
  'vietnamese': 'Thủng màng nhĩ trung tâm',
}, {
  'id': 'H72.1',
  'english': 'Attic perforation of tympanic membrane',
  'vietnamese': 'Thủng màng nhĩ ở vị trí thượng nhĩ',
}, {
  'id': 'H72.2',
  'english': 'Other marginal perforations of tympanic membrane',
  'vietnamese': 'Thủng rìa màng nhĩ khác thủng màng nhĩ sát xương khác',
}, {
  'id': 'H72.8',
  'english': 'Other perforations of tympanic membrane',
  'vietnamese': 'Thủng màng nhĩ khác',
}, {
  'id': 'H72.9',
  'english': 'Perforation of tympanic membrane, unspecified',
  'vietnamese': 'Thủng màng nhĩ, không đặc hiệu',
}, {
  'id': 'H73',
  'english': 'Other disorders of tympanic membrane',
  'vietnamese': 'Bệnh khác của màng nhĩ',
}, {'id': 'H73.0', 'english': 'Acute myringitis', 'vietnamese': 'Viêm màng nhĩ cấp'}, {
  'id': 'H73.1',
  'english': 'Chronic myringitis',
  'vietnamese': 'Viêm màng nhĩ mạn',
}, {
  'id': 'H73.8',
  'english': 'Other specified disorders of tympanic membrane',
  'vietnamese': 'Những rối loạn đặc hiệu khác của màng nhĩ',
}, {
  'id': 'H73.9',
  'english': 'Disorder of tympanic membrane, unspecified',
  'vietnamese': 'Bệnh màng nhĩ, không đặc hiệu',
}, {
  'id': 'H74',
  'english': 'Other disorders of middle ear and mastoid',
  'vietnamese': 'Bệnh khác của tai giữa và xương chũm',
}, {'id': 'H74.0', 'english': 'Tympanosclerosis', 'vietnamese': 'Xơ nhĩ'}, {
  'id': 'H74.1',
  'english': 'Adhesive middle ear disease',
  'vietnamese': 'Bệnh tai giữa kết dính bệnh tai giữa dính',
}, {
  'id': 'H74.2',
  'english': 'Discontinuity and dislocation of ear ossicles',
  'vietnamese': 'Gián đoạn và trật khớp xương con của tai gián đoạn và trật khớp xương con',
}, {
  'id': 'H74.3',
  'english': 'Other acquired abnormalities of ear ossicles',
  'vietnamese': 'Những bất thường mắc phải khác của chuỗi xương con dị dạng xương con mắc phải khác',
}, {'id': 'H74.4', 'english': 'Polyp of middle ear', 'vietnamese': 'Polip tai giữa'}, {
  'id': 'H74.8',
  'english': 'Other specified disorders of middle ear and mastoid',
  'vietnamese': 'Những rối loạn đặc hiệu khác của tai giữa và xương chũm',
}, {
  'id': 'H74.9',
  'english': 'Disorder of middle ear and mastoid, unspecified',
  'vietnamese': 'Bệnh tai, những rối loạn ở tai giữa và xương chũm không đặc hiệu những rối loạn ở tai giữa và xương chũm không đặc hiệu',
}, {
  'id': 'H75*',
  'english': 'Other disorders of middle ear and mastoid in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của tai giữa và xương chũm trong bệnh phân loại nơi khác',
}, {
  'id': 'H75.0*',
  'english': 'Mastoiditis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm xương chũm trong bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'H75.8*',
  'english': 'Other specified disorders of middle ear and mastoid in diseases classified elsewhere',
  'vietnamese': 'Những rối loạn đặc hiệu khác của tai giữa và xương chũm trong các bệnh đã được phân loại nơi khác',
}, {'id': 'H80', 'english': 'Otosclerosis', 'vietnamese': 'Xốp xơ tai'}, {
  'id': 'H80.0',
  'english': 'Otosclerosis involving oval window, nonobliterative',
  'vietnamese': 'Xốp xơ tai xâm lấn cửa sổ bầu dục làm bít tắc xốp xơ tai xâm lấn cửa sổ bầu dục bít lấp',
}, {
  'id': 'H80.1',
  'english': 'Otosclerosis involving oval window, obliterative',
  'vietnamese': 'Xốp xơ tai xâm lấn cửa sổ bầu dục làm bít tắc xốp xơ tai xâm lấn cửa sổ bầu dục bít lấp',
}, {'id': 'H80.2', 'english': 'Cochlear otosclerosis', 'vietnamese': 'Xốp xơ ốc tai'}, {
  'id': 'H80.8',
  'english': 'Other otosclerosis',
  'vietnamese': 'Xốp xơ tai khác',
}, {
  'id': 'H80.9',
  'english': 'Otosclerosis, unspecified',
  'vietnamese': 'Xơ nhĩ không đặc hiệu xốp xơ tai không đặc hiệu',
}, {
  'id': 'H81',
  'english': 'Disorders of vestibular function',
  'vietnamese': 'Rối loạn chức năng tiền đình',
}, {'id': 'H81.0', 'english': 'Ménière s disease', 'vietnamese': 'Bệnh Ménière'}, {
  'id': 'H81.1',
  'english': 'Benign paroxysmal vertigo',
  'vietnamese': 'Chóng mặt kịch phát lành tính',
}, {
  'id': 'H81.2',
  'english': 'Vestibular neuronitis',
  'vietnamese': 'Viêm thần kinh tiền đình',
}, {
  'id': 'H81.3',
  'english': 'Other peripheral vertigo',
  'vietnamese': 'Chóng mặt do nguyên nhân ngoại biên khác',
}, {
  'id': 'H81.4',
  'english': 'Vertigo of central origin',
  'vietnamese': 'Chóng mặt nguồn gốc trung ương',
}, {
  'id': 'H81.8',
  'english': 'Other disorders of vestibular function',
  'vietnamese': 'Rối loạn chức năng tiền đình khác',
}, {
  'id': 'H81.9',
  'english': 'Disorder of vestibular function, unspecified',
  'vietnamese': 'Rối loạn chức năng tiền đình, không đặc hiệu',
}, {
  'id': 'H82*',
  'english': 'Vertiginous syndromes in diseases classified elsewhere',
  'vietnamese': 'Hội chứng chóng mặt trong bệnh phân loại nơi khác',
}, {
  'id': 'H83',
  'english': 'Other diseases of inner ear',
  'vietnamese': 'Bệnh khác của tai trong',
}, {
  'id': 'H83.0',
  'english': 'Labyrinthitis',
  'vietnamese': 'Viêm mê nhĩ',
}, {'id': 'H83.1', 'english': 'Labyrinthine fistula', 'vietnamese': 'Rò mê nhĩ'}, {
  'id': 'H83.2',
  'english': 'Labyrinthine dysfunction',
  'vietnamese': 'Rối loạn chức năng mê nhĩ',
}, {
  'id': 'H83.3',
  'english': 'Noise effects on inner ear',
  'vietnamese': 'Ảnh hưởng tiếng ồn lên tai trong',
}, {
  'id': 'H83.8',
  'english': 'Other specified diseases of inner ear',
  'vietnamese': 'Bệnh tai trong, đặc hiệu bệnh tai trong đặc hiệu khác',
}, {
  'id': 'H83.9',
  'english': 'Disease of inner ear, unspecified',
  'vietnamese': 'Bệnh tai trong, không đặc hiệu',
}, {
  'id': 'H90',
  'english': 'Conductive and sensorineural hearing loss',
  'vietnamese': 'Điếc dẫn truyền và điếc thần kinh giác quan điếc dẫn truyền và tiếp nhận',
}, {
  'id': 'H90.0',
  'english': 'Conductive hearing loss, bilateral',
  'vietnamese': 'Điếc dẫn truyền hai bên',
}, {
  'id': 'H90.1',
  'english': 'Conductive hearing loss, unilateral with unrestricted hearing on the contralateral side',
  'vietnamese': 'Điếc dẫn truyền một bên với sức nghe không hạn chế bên đối diện',
}, {
  'id': 'H90.2',
  'english': 'Conductive hearing loss, unspecified',
  'vietnamese': 'Điếc dẫn truyền không đặc hiệu',
}, {
  'id': 'H90.3',
  'english': 'Sensorineural hearing loss, bilateral',
  'vietnamese': 'Điếc thần kinh hai bên điếc tiếp nhận hai bên',
}, {
  'id': 'H90.4',
  'english': 'Sensorineural hearing loss, unilateral with unrestricted hearing on the contralateral side',
  'vietnamese': 'Điếc giác quan, một bên với sức nghe không hạn chế bên đối diện điếc tiếp nhận một bên với sức nghe không hạn chế bên đối diện',
}, {
  'id': 'H90.5',
  'english': 'Sensorineural hearing loss, unspecified',
  'vietnamese': 'Điếc thần kinh không đặc hiệu điếc tiếp nhận không đặc hiệu',
}, {
  'id': 'H90.6',
  'english': 'Mixed conductive and sensorineural hearing loss, bilateral',
  'vietnamese': 'Điếc hỗn hợp dẫn truyền và giác quan, hai bên điếc hỗn hợp hai bên',
}, {
  'id': 'H90.7',
  'english': 'Mixed conductive and sensorineural hearing loss, unilateral with unrestricted hearing on the contralateral side',
  'vietnamese': 'Điếc hỗn hợp dẫn truyền và giác quan, một bên với sức nghe không hạn chế bên tai kia điếc hỗn hợp một bên với sức nghe không hạn chế bên đối diện',
}, {
  'id': 'H90.8',
  'english': 'Mixed conductive and sensorineural hearing loss, unspecified',
  'vietnamese': 'Điếc hỗn hợp dẫn truyền và giác quan không đặc hiệu điếc hỗn hợp không đặc hiệu',
}, {'id': 'H91', 'english': 'Other hearing loss', 'vietnamese': 'Nghe kém khác'}, {
  'id': 'H91.0',
  'english': 'Ototoxic hearing loss',
  'vietnamese': 'Nghe kém do ngộ độc tai',
}, {'id': 'H91.1', 'english': 'Presbycusis', 'vietnamese': 'Điếc tuổi già hay lão thính'}, {
  'id': 'H91.2',
  'english': 'Sudden idiopathic hearing loss',
  'vietnamese': 'Điếc đột ngột không rõ nguyên do',
}, {
  'id': 'H91.3',
  'english': 'Deaf mutism, not elsewhere classified',
  'vietnamese': 'Câm điếc, không phân loại nơi khác điếc câm không phân loại nơi khác',
}, {
  'id': 'H91.8',
  'english': 'Other specified hearing loss',
  'vietnamese': 'Nghe kém (do nguyên nhân) đặc hiệu khác',
}, {
  'id': 'H91.9',
  'english': 'Hearing loss, unspecified',
  'vietnamese': 'Nghe kém không đặc hiệu khác nghe kém không đặc hiệu',
}, {
  'id': 'H92',
  'english': 'Otalgia and effusion of ear',
  'vietnamese': 'Đau tai và chảy dịch ở tai',
}, {'id': 'H92.0', 'english': 'Otalgia', 'vietnamese': 'Đau tai'}, {
  'id': 'H92.1',
  'english': 'Otorrhoea',
  'vietnamese': 'Chảy dịch tai',
}, {'id': 'H92.2', 'english': 'Otorrhagia', 'vietnamese': 'Chảy máu tai'}, {
  'id': 'H93',
  'english': 'Other disorders of ear, not elsewhere classified',
  'vietnamese': 'Bệnh khác của tai, không phân loại nơi khác',
}, {
  'id': 'H93.0',
  'english': 'Degenerative and vascular disorders of ear',
  'vietnamese': 'Bệnh do thoái hóa và do mạch máu của tai',
}, {'id': 'H93.1', 'english': 'Tinnitus', 'vietnamese': 'Ù tai'}, {
  'id': 'H93.2',
  'english': 'Other abnormal auditory perceptions',
  'vietnamese': 'Nhận thức thính giác bất thường khác. Tiếp nhận thính giác bất thường khác',
}, {
  'id': 'H93.3',
  'english': 'Disorders of acoustic nerve',
  'vietnamese': 'Rối loạn của thần kinh thính giác',
}, {
  'id': 'H93.8',
  'english': 'Other specified disorders of ear',
  'vietnamese': 'Bệnh tai đặc hiệu khác',
}, {
  'id': 'H93.9',
  'english': 'Disorder of ear, unspecified',
  'vietnamese': 'Bệnh tai, không đặc hiệu',
}, {
  'id': 'H94*',
  'english': 'Other disorders of ear in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của tai trong bệnh phân loại nơi khác',
}, {
  'id': 'H94.0*',
  'english': 'Acoustic neuritis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm dây thần kinh thính giác trong bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'H94.8*',
  'english': 'Other specified disorders of ear in diseases classified elsewhere',
  'vietnamese': 'Những rối loạn đặc hiệu khác của tai trong những bệnh đã phân loại khác',
}, {
  'id': 'H95',
  'english': 'Postprocedural disorders of ear and mastoid process, not elsewhere classified',
  'vietnamese': 'Bệnh tai và xương chũm sau phẫu thuật, không phân loại nơi khác',
}, {
  'id': 'H95.0',
  'english': 'Recurrent cholesteatoma of postmastoidectomy cavity',
  'vietnamese': 'Cholesteatoma tái phát ở hố mổ khóet chũm',
}, {
  'id': 'H95.1',
  'english': 'Other disorders following mastoidectomy',
  'vietnamese': 'Bệnh khác sau mổ khóet chũm',
}, {
  'id': 'H95.8',
  'english': 'Other postprocedural disorders of ear and mastoid process',
  'vietnamese': 'Rối loạn khác của tai và xương chũm sau phẫu thuật',
}, {
  'id': 'H95.9',
  'english': 'Postprocedural disorder of ear and mastoid process, unspecified',
  'vietnamese': 'Rối loạn của tai và xương chũm sau phẫu thuật, không đặc hiệu',
}, {
  'id': 'I00',
  'english': 'Rheumatic fever without mention of I00 heart involvement',
  'vietnamese': 'Thấp không có tổn thương tim',
}, {
  'id': 'I01',
  'english': 'Rheumatic fever with heart involvement',
  'vietnamese': 'Thấp ảnh hưởng đến tim',
}, {
  'id': 'I01.0',
  'english': 'Acute rheumatic pericarditis',
  'vietnamese': 'Viêm màng ngoài tim do thấp cấp',
}, {
  'id': 'I01.1',
  'english': 'Acute rheumatic endocarditis',
  'vietnamese': 'Viêm nội tâm mạc cấp do thấp',
}, {
  'id': 'I01.2',
  'english': 'Acute rheumatic myocarditis',
  'vietnamese': 'Viêm cơ tim do thấp cấp',
}, {
  'id': 'I01.8',
  'english': 'Other acute rheumatic heart disease',
  'vietnamese': 'Bệnh tim cấp khác do thấp',
}, {
  'id': 'I01.9',
  'english': 'Acute rheumatic heart disease, unspecified',
  'vietnamese': 'Bệnh tim do thấp cấp, không đặc hiệu',
}, {'id': 'I02', 'english': 'Rheumatic chorea', 'vietnamese': 'Múa giật do thấp'}, {
  'id': 'I02.0',
  'english': 'Rheumatic chorea with heart involvement',
  'vietnamese': 'Múa giật do thấp có ảnh hưởng đến tim',
}, {
  'id': 'I02.9',
  'english': 'Rheumatic chorea without heart involvement',
  'vietnamese': 'Múa giật do thấp không ảnh hưởng đến tim',
}, {
  'id': 'I05',
  'english': 'Rheumatic mitral valve diseases',
  'vietnamese': 'Bệnh lý van hai lá do thấp',
}, {'id': 'I05.0', 'english': 'Mitral stenosis', 'vietnamese': 'Hẹp van hai lá'}, {
  'id': 'I05.1',
  'english': 'Rheumatic mitral insufficiency',
  'vietnamese': 'Hở van hai lá do thấp',
}, {
  'id': 'I05.2',
  'english': 'Mitral stenosis with insufficiency',
  'vietnamese': 'Hẹp hở van hai lá',
}, {
  'id': 'I05.8',
  'english': 'Other mitral valve diseases',
  'vietnamese': 'Bệnh van hai lá khác',
}, {
  'id': 'I05.9',
  'english': 'Mitral valve disease, unspecified',
  'vietnamese': 'Bệnh van hai lá, không đặc hiệu',
}, {
  'id': 'I06',
  'english': 'Rheumatic aortic valve diseases',
  'vietnamese': 'Bệnh van động mạch chủ do thấp',
}, {
  'id': 'I06.0',
  'english': 'Rheumatic aortic stenosis',
  'vietnamese': 'Hẹp van động mạch chủ do thấp',
}, {
  'id': 'I06.1',
  'english': 'Rheumatic aortic insufficiency',
  'vietnamese': 'Hở van động mạch chủ do thấp',
}, {
  'id': 'I06.2',
  'english': 'Rheumatic aortic stenosis with insufficiency',
  'vietnamese': 'Hẹp Hở van động mạch chủ do thấp',
}, {
  'id': 'I06.8',
  'english': 'Other rheumatic aortic valve diseases',
  'vietnamese': 'Bệnh van động mạch chủ do thấp khác',
}, {
  'id': 'I06.9',
  'english': 'Rheumatic aortic valve disease, unspecified',
  'vietnamese': 'Bệnh van động mạch chủ do thấp không đặc hiệu',
}, {
  'id': 'I07',
  'english': 'Rheumatic tricuspid valve diseases',
  'vietnamese': 'Bệnh lý van ba lá do thấp',
}, {'id': 'I07.0', 'english': 'Tricuspid stenosis', 'vietnamese': 'Hẹp van ba lá'}, {
  'id': 'I07.1',
  'english': 'Tricuspid insufficiency',
  'vietnamese': 'Hở van ba lá',
}, {
  'id': 'I07.2',
  'english': 'Tricuspid stenosis with insufficiency',
  'vietnamese': 'Hẹp hở van ba lá',
}, {
  'id': 'I07.8',
  'english': 'Other tricuspid valve diseases',
  'vietnamese': 'Bệnh lý khác của van ba lá',
}, {
  'id': 'I07.9',
  'english': 'Tricuspid valve disease, unspecified',
  'vietnamese': 'Bệnh lý van ba lá khác không đặc hiệu',
}, {
  'id': 'I08',
  'english': 'Multiple valve diseases',
  'vietnamese': 'Bệnh lý của nhiều van tim',
}, {
  'id': 'I08.0',
  'english': 'Disorders of both mitral and aortic valves',
  'vietnamese': 'Bệnh Lý cả hai lá và van động mạch chủ',
}, {
  'id': 'I08.1',
  'english': 'Disorders of both mitral and tricuspid valves',
  'vietnamese': 'Bệnh cả van hai lá và van ba lá',
}, {
  'id': 'I08.2',
  'english': 'Disorders of both aortic and tricuspid valves',
  'vietnamese': 'Bệnh cả van động mạch chủ và van ba lá',
}, {
  'id': 'I08.3',
  'english': 'Combined disorders of mitral, aortic and tricuspid valves',
  'vietnamese': 'Bệnh lý phối hợp van hai lá, van động mạch chủ và van ba lá',
}, {
  'id': 'I08.8',
  'english': 'Other multiple valve diseases',
  'vietnamese': 'Bệnh lý nhiều van khác',
}, {
  'id': 'I08.9',
  'english': 'Multiple valve disease, unspecified',
  'vietnamese': 'Bệnh lý nhiều van khác, không đặc hiệu',
}, {
  'id': 'I09',
  'english': 'Other rheumatic heart diseases',
  'vietnamese': 'Các bệnh tim khác do thấp',
}, {'id': 'I09.0', 'english': 'Rheumatic myocarditis', 'vietnamese': 'Viêm cơ tim do thấp'}, {
  'id': 'I09.1',
  'english': 'Rheumatic diseases of endocardium, valve unspecified',
  'vietnamese': 'Bệnh nội tâm mạc do thấp, có tổn thương van không đặc hiệu',
}, {
  'id': 'I09.2',
  'english': 'Chronic rheumatic pericarditis',
  'vietnamese': 'Viêm màng ngoài tim do thấp mãn tính',
}, {
  'id': 'I09.8',
  'english': 'Other specified rheumatic heart diseases',
  'vietnamese': 'Các bệnh tim do thấp khác',
}, {
  'id': 'I09.9',
  'english': 'Rheumatic heart disease, unspecified',
  'vietnamese': 'Bệnh tim do thấp, không đặc hiệu',
}, {
  'id': 'I10',
  'english': 'Essential (primary) hypertension',
  'vietnamese': 'Bệnh Tăng huyết áp vô căn (nguyên phát)',
}, {
  'id': 'I11',
  'english': 'Hypertensive heart disease',
  'vietnamese': 'Bệnh tim do tăng huyết áp',
}, {
  'id': 'I11.0',
  'english': 'Hypertensive heart disease with (congestive) heart failure',
  'vietnamese': 'Bệnh tim do tăng huyết áp, có suy tim (sung huyết)',
}, {
  'id': 'I11.9',
  'english': 'Hypertensive heart disease without (congestive) heart failure',
  'vietnamese': 'Bệnh tim do tăng huyết áp, không suy tim (sung huyết)',
}, {
  'id': 'I12',
  'english': 'Hypertensive renal disease',
  'vietnamese': 'Bệnh thận do tăng huyết áp',
}, {
  'id': 'I12.0',
  'english': 'Hypertensive renal disease with renal failure',
  'vietnamese': 'Bệnh thận do tăng huyết áp, có suy thận',
}, {
  'id': 'I12.9',
  'english': 'Hypertensive renal disease without renal failure',
  'vietnamese': 'Bệnh thận do tăng huyết áp, không suy thận',
}, {
  'id': 'I13',
  'english': 'Hypertensive heart and renal disease',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp',
}, {
  'id': 'I13.0',
  'english': 'Hypertensive heart and renal disease with (congestive) heart failure',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp, có suy tim (sung huyết)',
}, {
  'id': 'I13.1',
  'english': 'Hypertensive heart and renal disease with renal failure',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp, có suy thận',
}, {
  'id': 'I13.2',
  'english': 'Hypertensive heart and renal disease with both (congestive) heart failure and renal failure',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp, có suy tim (sung huyết) và suy thận',
}, {
  'id': 'I13.9',
  'english': 'Hypertensive heart and renal disease, unspecified',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp, không đặc hiệu',
}, {'id': 'I15', 'english': 'Secondary hypertension', 'vietnamese': 'Tăng huyết áp thứ phát'}, {
  'id': 'I15.0',
  'english': 'Renovascular hypertension',
  'vietnamese': 'Tăng huyết áp do nguyên nhân mạch thận',
}, {
  'id': 'I15.1',
  'english': 'Hypertension secondary to other renal disorders',
  'vietnamese': 'Tăng huyết áp thứ phát do bệnh thận khác',
}, {
  'id': 'I15.2',
  'english': 'Hypertension secondary to endocrine disorders',
  'vietnamese': 'Tăng huyết áp thứ phát do rối loạn nội tiết',
}, {
  'id': 'I15.8',
  'english': 'Other secondary hypertension',
  'vietnamese': 'Tăng huyết áp thứ phát khác',
}, {
  'id': 'I15.9',
  'english': 'Secondary hypertension, unspecified',
  'vietnamese': 'Tăng huyết áp thứ phát không đặc hiệu',
}, {'id': 'I20', 'english': 'Angina pectoris', 'vietnamese': 'Cơn đau thắt ngực'}, {
  'id': 'I20.0',
  'english': 'Unstable angina',
  'vietnamese': 'Cơn đau thắt ngực không ổn định',
}, {
  'id': 'I20.1',
  'english': 'Angina pectoris with documented spasm',
  'vietnamese': 'Cơn đau thắt ngực do co thắt mạch',
}, {
  'id': 'I20.8',
  'english': 'Other forms of angina pectoris',
  'vietnamese': 'Dạng khác của cơn đau thắt ngực',
}, {
  'id': 'I20.9',
  'english': 'Angina pectoris, unspecified',
  'vietnamese': 'Cơn đau thắt ngực, không đặc hiệu',
}, {'id': 'I21', 'english': 'Acute myocardial infarction', 'vietnamese': 'Nhồi máu cơ tim cấp'}, {
  'id': 'I21.0',
  'english': 'Acute transmural myocardial infarction of anterior wall',
  'vietnamese': 'Nhồi máu cơ tim trước vách cấp xuyên thành của thành trước',
}, {
  'id': 'I21.1',
  'english': 'Acute transmural myocardial infarction of inferior wall',
  'vietnamese': 'Nhồi máu cơ tim xuyên thành cấp của thành dưới',
}, {
  'id': 'I21.2',
  'english': 'Acute transmural myocardial infarction of other sites',
  'vietnamese': 'Nhồi máu xuyên thành cấp ở vị trí khác',
}, {
  'id': 'I21.3',
  'english': 'Acute transmural myocardial infarction of unspecified site',
  'vietnamese': 'Nhồi máu cơ tim xuyên thành cấp không rõ vị trí',
}, {
  'id': 'I21.4',
  'english': 'Acute subendocardial myocardial infarction',
  'vietnamese': 'Nhồi máu cơ tim dưới nội tâm mạc cấp',
}, {
  'id': 'I21.9',
  'english': 'Acute myocardial infarction, unspecified',
  'vietnamese': 'Nhồi máu cơ tim cấp, không đặc hiệu',
}, {
  'id': 'I22',
  'english': 'Subsequent myocardial infarction',
  'vietnamese': 'Nhồi máu cơ tim tiến triển',
}, {
  'id': 'I22.0',
  'english': 'Subsequent myocardial infarction of anterior wall',
  'vietnamese': 'Nhồi máu cơ tim tiến triển của thành trước',
}, {
  'id': 'I22.1',
  'english': 'Subsequent myocardial infarction of inferior wall',
  'vietnamese': 'Nhồi máu cơ tim tiến triển của thành dưới',
}, {
  'id': 'I22.8',
  'english': 'Subsequent myocardial infarction of other sites',
  'vietnamese': 'Nhồi máu cơ tim tiến triển ở vị trí khác',
}, {
  'id': 'I22.9',
  'english': 'Subsequent myocardial infarction of unspecified site',
  'vietnamese': 'Nhồi máu cơ tim tiến triển ở vị trí không xác định',
}, {
  'id': 'I23',
  'english': 'Certain current complications following acute myocardial infarction',
  'vietnamese': 'Một số biến chứng thường gặp sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.0',
  'english': 'Haemopericardium as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng tràn máu màng ngoài tim sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.1',
  'english': 'Atrial septal defect as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng thủng vách liên nhĩ xảy ra sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.2',
  'english': 'Ventricular septal defect as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng thủng vách liên thất sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.3',
  'english': 'Rupture of cardiac wall without haemopericardium as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng nứt thành tim không có tràn máu màng ngoài tim sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.4',
  'english': 'Rupture of chordae tendineae as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng đứt dây chằng van hai lá sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.5',
  'english': 'Rupture of papillary muscle as current complication following acute myocardial infarction',
  'vietnamese': 'Biến chứng đứt cơ nhú sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.6',
  'english': 'Thrombosis of atrium, auricular appendage, and ventricle as current complications following acute myocardial infarction',
  'vietnamese': 'Biến chứng huyết khối trong buồng tim tâm nhĩ, tiểu nhĩ và tâm thất sau nhồi máu cơ tim cấp',
}, {
  'id': 'I23.8',
  'english': 'Other current complications following acute myocardial infarction',
  'vietnamese': 'Biến chứng khác xảy ra sau nhồi máu cơ tim cấp',
}, {
  'id': 'I24',
  'english': 'Other acute ischaemic heart diseases',
  'vietnamese': 'Bệnh tim do thiếu máu cục bộ cấp khác',
}, {
  'id': 'I24.0',
  'english': 'Coronary thrombosis not resulting in myocardial infarction',
  'vietnamese': 'Huyết khối mạch vành không gây nhồi máu cơ tim',
}, {'id': 'I24.1', 'english': 'Dressler s syndrome', 'vietnamese': 'Hội chứng Dressler'}, {
  'id': 'I24.8',
  'english': 'Other forms of acute ischaemic heart disease',
  'vietnamese': 'Dạng khác của bệnh tim thiếu máu cục bộ cấp',
}, {
  'id': 'I24.9',
  'english': 'Acute ischaemic heart disease, unspecified',
  'vietnamese': 'Bệnh tim do thiếu máu cục bộ cấp, không đặc hiệu',
}, {
  'id': 'I25',
  'english': 'Chronic ischaemic heart disease',
  'vietnamese': 'Bệnh tim thiếu máu cục bộ mạn',
}, {
  'id': 'I25.0',
  'english': 'Atherosclerotic cardiovascular disease, so described',
  'vietnamese': 'Bệnh tim mạch do xơ vữa động mạch vành',
}, {
  'id': 'I25.1',
  'english': 'Atherosclerotic heart disease',
  'vietnamese': 'Bệnh tim mạch do xơ vữa động mạch',
}, {'id': 'I25.2', 'english': 'Old myocardial infarction', 'vietnamese': 'Nhồi máu cơ tim cũ'}, {
  'id': 'I25.3',
  'english': 'Aneurysm of heart',
  'vietnamese': 'Phình thành tim',
}, {'id': 'I25.4', 'english': 'Coronary artery aneurysm', 'vietnamese': 'Phình động mạch vành'}, {
  'id': 'I25.5',
  'english': 'Ischaemic cardiomyopathy',
  'vietnamese': 'Bệnh cơ tim do thiếu máu cục bộ',
}, {
  'id': 'I25.6',
  'english': 'Silent myocardial ischaemia',
  'vietnamese': 'Thiếu máu cơ tim thầm lặng',
}, {
  'id': 'I25.8',
  'english': 'Other forms of chronic ischaemic heart disease',
  'vietnamese': 'Dạng khác của bệnh tim thiếu máu cục bộ mạn',
}, {
  'id': 'I25.9',
  'english': 'Chronic ischaemic heart disease, unspecified',
  'vietnamese': 'Bệnh tim thiếu máu cục bộ mạn, không đặc hiệu',
}, {'id': 'I26', 'english': 'Pulmonary embolism', 'vietnamese': 'Tắc mạch phổi'}, {
  'id': 'I26.0',
  'english': 'Pulmonary embolism with mention of acute cor pulmonale',
  'vietnamese': 'Thuyên tắc phổi có tâm phế cấp',
}, {
  'id': 'I26.9',
  'english': 'Pulmonary embolism without mention of acute cor pulmonale',
  'vietnamese': 'Thuyên tắc phổi không có tâm phế cấp',
}, {
  'id': 'I27',
  'english': 'Other pulmonary heart diseases',
  'vietnamese': 'Các bệnh tim do phổi khác',
}, {
  'id': 'I27.0',
  'english': 'Primary pulmonary hypertension',
  'vietnamese': 'Tăng áp động mạch phổi nguyên phát',
}, {
  'id': 'I27.1',
  'english': 'Kyphoscoliotic heart disease',
  'vietnamese': 'Bệnh tim do gù vẹo cột sống',
}, {
  'id': 'I27.2',
  'english': 'Other secondary pulmonary hypertension',
  'vietnamese': 'Tăng huyết áp động mạch phổi thứ phát',
}, {
  'id': 'I27.8',
  'english': 'Other specified pulmonary heart diseases',
  'vietnamese': 'Bệnh tim do phổi đã xác định khác',
}, {
  'id': 'I27.9',
  'english': 'Pulmonary heart disease, unspecified',
  'vietnamese': 'Bệnh tim do phổi, không đặc hiệu',
}, {
  'id': 'I28',
  'english': 'Other diseases of pulmonary vessels',
  'vietnamese': 'Bệnh mạch máu phổi khác',
}, {
  'id': 'I28.0',
  'english': 'Arteriovenous fistula of pulmonary vessels',
  'vietnamese': 'Dò động - tĩnh mạch phổi',
}, {
  'id': 'I28.1',
  'english': 'Aneurysm of pulmonary artery',
  'vietnamese': 'Phình động mạch phổi',
}, {
  'id': 'I28.8',
  'english': 'Other specified diseases of pulmonary vessels',
  'vietnamese': 'Bệnh mạch máu phổi xác định khác',
}, {
  'id': 'I28.9',
  'english': 'Disease of pulmonary vessels, unspecified',
  'vietnamese': 'Bệnh mạch máu phổi không đặc hiệu',
}, {'id': 'I30', 'english': 'Acute pericarditis', 'vietnamese': 'Viêm màng ngoài tim cấp'}, {
  'id': 'I30.0',
  'english': 'Acute nonspecific idiopathic pericarditis',
  'vietnamese': 'Viêm màng ngoài tim cấp, không rõ nguyên nhân, không đặc hiệu',
}, {
  'id': 'I30.1',
  'english': 'Infective pericarditis',
  'vietnamese': 'Viêm màng ngoài tim nhiễm trùng',
}, {
  'id': 'I30.8',
  'english': 'Other forms of acute pericarditis',
  'vietnamese': 'Các thể khác của viêm màng ngoài tim cấp',
}, {
  'id': 'I30.9',
  'english': 'Acute pericarditis, unspecified',
  'vietnamese': 'Viêm màng ngoài tim cấp, không đặc hiệu',
}, {
  'id': 'I31',
  'english': 'Other diseases of pericardium',
  'vietnamese': 'Các bệnh màng ngoài tim khác',
}, {
  'id': 'I31.0',
  'english': 'Chronic adhesive pericarditis',
  'vietnamese': 'Viêm dày dính màng ngoài tim mạn',
}, {
  'id': 'I31.1',
  'english': 'Chronic constrictive pericarditis',
  'vietnamese': 'Viêm màng ngoài tim co thắt mạn',
}, {
  'id': 'I31.2',
  'english': 'Haemopericardium, not elsewhere classified',
  'vietnamese': 'Tràn máu ngoại tâm mạc, không phân loại nơi khác',
}, {
  'id': 'I31.3',
  'english': 'Pericardial effusion (noninflammatory)',
  'vietnamese': 'Tràn dịch màng ngoài tim (không do viêm)',
}, {
  'id': 'I31.8',
  'english': 'Other specified diseases of pericardium',
  'vietnamese': 'Bệnh màng ngoài tim khác',
}, {
  'id': 'I31.9',
  'english': 'Disease of pericardium, unspecified',
  'vietnamese': 'Bệnh ngoại tâm mạc, không đặc hiệu',
}, {
  'id': 'I32*',
  'english': 'Pericarditis in diseases classified elsewhere',
  'vietnamese': 'Viêm màng ngoài tim trong bệnh phân loại nơi khác',
}, {
  'id': 'I32.0*',
  'english': 'Pericarditis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm màng ngoài tim trong bệnh nhiễm khuẩn phân loại nơi khác',
}, {
  'id': 'I32.1*',
  'english': 'Pericarditis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm màng ngoài tim trong bệnh nhiễm trùng và ký sinh trùng khác phân loại nơi khác',
}, {
  'id': 'I32.8*',
  'english': 'Pericarditis in other diseases classified elsewhere',
  'vietnamese': 'Viêm màng ngoài tim trong bệnh khác phân loại nơi khác',
}, {
  'id': 'I33',
  'english': 'Acute and subacute endocarditis',
  'vietnamese': 'Viêm nội tâm mạc cấp và bán cấp',
}, {
  'id': 'I33.0',
  'english': 'Acute and subacute infective endocarditis',
  'vietnamese': 'Viêm nội tâm mạc nhiễm trùng cấp và bán cấp',
}, {
  'id': 'I33.9',
  'english': 'Acute endocarditis, unspecified',
  'vietnamese': 'Viêm nội tâm mạc cấp, không đặc hiệu',
}, {
  'id': 'I34',
  'english': 'Nonrheumatic mitral valve disorders',
  'vietnamese': 'Bệnh van hai lá không do thấp',
}, {'id': 'I34.0', 'english': 'Mitral (valve) insufficiency', 'vietnamese': 'Hở (van) hai lá'}, {
  'id': 'I34.1',
  'english': 'Mitral (valve) prolapse',
  'vietnamese': 'Sa (van) hai lá',
}, {
  'id': 'I34.2',
  'english': 'Nonrheumatic mitral (valve) stenosis',
  'vietnamese': 'Hẹp (van) hai lá không do thấp',
}, {
  'id': 'I34.8',
  'english': 'Other nonrheumatic mitral valve disorders',
  'vietnamese': 'Hẹp van hai lá không do thấp khác',
}, {
  'id': 'I34.9',
  'english': 'Nonrheumatic mitral valve disorder, unspecified',
  'vietnamese': 'Hẹp van hai lá không do thấp',
}, {
  'id': 'I35',
  'english': 'Nonrheumatic aortic valve disorders',
  'vietnamese': 'Bệnh van động mạch chủ không do thấp',
}, {
  'id': 'I35.0',
  'english': 'Aortic (valve) stenosis',
  'vietnamese': 'Hẹp (van) động mạch chủ',
}, {
  'id': 'I35.1',
  'english': 'Aortic (valve) insufficiency',
  'vietnamese': 'Hở (van) động mạch chủ',
}, {
  'id': 'I35.2',
  'english': 'Aortic (valve) stenosis with insufficiency',
  'vietnamese': 'Hẹp hở (van) động mạch chủ',
}, {
  'id': 'I35.8',
  'english': 'Other aortic valve disorders',
  'vietnamese': 'Bệnh van động mạch chủ khác',
}, {
  'id': 'I35.9',
  'english': 'Aortic valve disorder, unspecified',
  'vietnamese': 'Bệnh van động mạch chủ, không đặc hiệu',
}, {
  'id': 'I36',
  'english': 'Nonrheumatic tricuspid valve disorders',
  'vietnamese': 'Bệnh van ba lá không do thấp',
}, {
  'id': 'I36.0',
  'english': 'Nonrheumatic tricuspid (valve) stenosis',
  'vietnamese': 'Hẹp (van) ba lá không do thấp',
}, {
  'id': 'I36.1',
  'english': 'Nonrheumatic tricuspid (valve) insufficiency',
  'vietnamese': 'Hở (van) ba lá không do thấp',
}, {
  'id': 'I36.2',
  'english': 'Nonrheumatic tricuspid (valve) stenosis with insufficiency',
  'vietnamese': 'Hẹp kèm hở van ba lá không do thấp',
}, {
  'id': 'I36.8',
  'english': 'Other nonrheumatic tricuspid valve disorders',
  'vietnamese': 'Bệnh van ba lá không do thấp khác',
}, {
  'id': 'I36.9',
  'english': 'Nonrheumatic tricuspid valve disorder, unspecified',
  'vietnamese': 'Bệnh van ba lá không do thấp khác, không đặc hiệu',
}, {
  'id': 'I37',
  'english': 'Pulmonary valve disorders',
  'vietnamese': 'Bệnh van động mạch phổi',
}, {
  'id': 'I37.0',
  'english': 'Pulmonary valve stenosis',
  'vietnamese': 'Hẹp van động mạch phổi',
}, {
  'id': 'I37.1',
  'english': 'Pulmonary valve insufficiency',
  'vietnamese': 'Hở van động mạch phổi',
}, {
  'id': 'I37.2',
  'english': 'Pulmonary valve stenosis with insufficiency',
  'vietnamese': 'Hẹp hở van động mạch phổi',
}, {
  'id': 'I37.8',
  'english': 'Other pulmonary valve disorders',
  'vietnamese': 'Bệnh van động mạch phổi khác',
}, {
  'id': 'I37.9',
  'english': 'Pulmonary valve disorder, unspecified',
  'vietnamese': 'Bệnh van động mạch phổi, không xác định',
}, {
  'id': 'I38',
  'english': 'Endocarditis, valve unspecified',
  'vietnamese': 'Viêm nội tâm mạc, không xác định',
}, {
  'id': 'I39*',
  'english': 'Endocarditis and heart valve disorders in diseases classified elsewhere',
  'vietnamese': 'Viêm nội tâm mạc và bệnh van tim trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.0*',
  'english': 'Mitral valve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh van hai lá trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.1*',
  'english': 'Aortic valve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh van động mạch chủ trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.2*',
  'english': 'Tricuspid valve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh van ba lá trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.3*',
  'english': 'Pulmonary valve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh van động mạch phổi trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.4*',
  'english': 'Multiple valve disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh của nhiều van trong bệnh phân loại nơi khác',
}, {
  'id': 'I39.8*',
  'english': 'Endocarditis, valve unspecified, in diseases classified elsewhere',
  'vietnamese': 'Viêm nội tâm mạc, không xác định van, trong bệnh phân loại nơi khác',
}, {'id': 'I40', 'english': 'Acute myocarditis', 'vietnamese': 'Viêm cơ tim cấp'}, {
  'id': 'I40.0',
  'english': 'Infective myocarditis',
  'vietnamese': 'Viêm cơ tim nhiễm trùng',
}, {'id': 'I40.1', 'english': 'Isolated myocarditis', 'vietnamese': 'Viêm cơ tim đơn thuần'}, {
  'id': 'I40.8',
  'english': 'Other acute myocarditis',
  'vietnamese': 'Viêm cơ tim cấp khác',
}, {
  'id': 'I40.9',
  'english': 'Acute myocarditis, unspecified',
  'vietnamese': 'Viêm cơ tim cấp, không đặc hiệu',
}, {
  'id': 'I41*',
  'english': 'Myocarditis in diseases classified elsewhere',
  'vietnamese': 'Viêm cơ tim trong bệnh phân loại nơi khác',
}, {
  'id': 'I41.0*',
  'english': 'Myocarditis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm cơ tim trong bệnh nhiễm trùng phân loại nơi khác',
}, {
  'id': 'I41.1*',
  'english': 'Myocarditis in viral diseases classified elsewhere',
  'vietnamese': 'Viêm cơ tim trong bệnh do virus phân loại nơi khác',
}, {
  'id': 'I41.2*',
  'english': 'Myocarditis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm cơ tim trong bệnh nhiễm trùng và ký sinh trùng khác phân loại nơi khác',
}, {
  'id': 'I41.8*',
  'english': 'Myocarditis in other diseases classified elsewhere',
  'vietnamese': 'Viêm cơ tim trong bệnh khác phân loại nơi khác',
}, {'id': 'I42', 'english': 'Cardiomyopathy', 'vietnamese': 'Bệnh cơ tim'}, {
  'id': 'I42.0',
  'english': 'Dilated cardiomyopathy',
  'vietnamese': 'Bệnh cơ tim giãn',
}, {
  'id': 'I42.1',
  'english': 'Obstructive hypertrophic cardiomyopathy',
  'vietnamese': 'Bệnh cơ tim phì đại có tắc nghẽn',
}, {
  'id': 'I42.2',
  'english': 'Other hypertrophic cardiomyopathy',
  'vietnamese': 'Bệnh cơ tim phì đại khác',
}, {
  'id': 'I42.3',
  'english': 'Endomyocardial (eosinophilic) disease',
  'vietnamese': 'Bệnh cơ-nội tâm mạc (nhiễm bạch cầu ái toan)',
}, {
  'id': 'I42.4',
  'english': 'Endocardial fibroelastosis',
  'vietnamese': 'Xơ chun nội tâm mạc',
}, {
  'id': 'I42.5',
  'english': 'Other restrictive cardiomyopathy',
  'vietnamese': 'Bệnh lý cơ tim hạn chế',
}, {'id': 'I42.6', 'english': 'Alcoholic cardiomyopathy', 'vietnamese': 'Bệnh cơ tim do rượu'}, {
  'id': 'I42.7',
  'english': 'Cardiomyopathy due to drugs and other external agents',
  'vietnamese': 'Bệnh cơ tim do thuốc và tác nhân bên ngoài',
}, {'id': 'I42.8', 'english': 'Other cardiomyopathies', 'vietnamese': 'Bệnh cơ tim khác'}, {
  'id': 'I42.9',
  'english': 'Cardiomyopathy, unspecified',
  'vietnamese': 'Bệnh cơ tim không đặc hiệu',
}, {
  'id': 'I43*',
  'english': 'Cardiomyopathy in diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ tim trong bệnh phân loại nơi khác',
}, {
  'id': 'I43.0*',
  'english': 'Cardiomyopathy in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ tim trong bệnh nhiễm trùng và ký sinh trùng',
}, {
  'id': 'I43.1*',
  'english': 'Cardiomyopathy in metabolic diseases',
  'vietnamese': 'Bệnh cơ tim trong bệnh chuyển hóa',
}, {
  'id': 'I43.2*',
  'english': 'Cardiomyopathy in nutritional diseases',
  'vietnamese': 'Bệnh cơ tim trong các bệnh về dinh dưỡng',
}, {
  'id': 'I43.8*',
  'english': 'Cardiomyopathy in other diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ tim trong bệnh khác phân loại nơi khác',
}, {
  'id': 'I44',
  'english': 'Atrioventricular and left bundle-branch block',
  'vietnamese': 'Blốc nhĩ thất và nhánh trái',
}, {
  'id': 'I44.0',
  'english': 'Atrioventricular block, first degree',
  'vietnamese': 'Blốc nhĩ thất độ I',
}, {
  'id': 'I44.1',
  'english': 'Atrioventricular block, second degree',
  'vietnamese': 'Blốc nhĩ thất độ II',
}, {
  'id': 'I44.2',
  'english': 'Atrioventricular block, complete',
  'vietnamese': 'Blốc nhĩ thất, hoàn toàn',
}, {
  'id': 'I44.3',
  'english': 'Other and unspecified atrioventricular block',
  'vietnamese': 'Blốc nhĩ thất khác và không xác định',
}, {
  'id': 'I44.4',
  'english': 'Left anterior fascicular block',
  'vietnamese': 'Blốc nhánh trước trái',
}, {
  'id': 'I44.5',
  'english': 'Left posterior fascicular block',
  'vietnamese': 'Blốc nhánh sau trái',
}, {
  'id': 'I44.6',
  'english': 'Other and unspecified fascicular block',
  'vietnamese': 'Blốc nhánh khác và không xác định',
}, {
  'id': 'I44.7',
  'english': 'Left bundle-branch block, unspecified',
  'vietnamese': 'Blốc nhánh trái không đặc hiệu',
}, {
  'id': 'I45',
  'english': 'Other conduction disorders',
  'vietnamese': 'Rối loạn dẫn truyền khác',
}, {
  'id': 'I45.0',
  'english': 'Right fascicular block',
  'vietnamese': 'Blốc nhánh phải',
}, {
  'id': 'I45.1',
  'english': 'Other and unspecified right bundle-branch block',
  'vietnamese': 'Blốc nhánh phải khác và không xác định',
}, {'id': 'I45.2', 'english': 'Bifascicular block', 'vietnamese': 'Blốc hai nhánh'}, {
  'id': 'I45.3',
  'english': 'Trifascicular block',
  'vietnamese': 'Blốc ba nhánh',
}, {
  'id': 'I45.4',
  'english': 'Nonspecific intraventricular block',
  'vietnamese': 'Blốc trong thất không đặc hiệu',
}, {
  'id': 'I45.5',
  'english': 'Other specified heart block',
  'vietnamese': 'Blốc tim xác định khác',
}, {
  'id': 'I45.6',
  'english': 'Pre-excitation syndrome',
  'vietnamese': 'Hội chứng kích thích sớm (hội chứng tiền kích thích)',
}, {
  'id': 'I45.8',
  'english': 'Other specified conduction disorders',
  'vietnamese': 'Rối loạn dẫn truyền, xác định khác',
}, {
  'id': 'I45.9',
  'english': 'Conduction disorder, unspecified',
  'vietnamese': 'Rối loạn dẫn truyền không đặc hiệu',
}, {'id': 'I46', 'english': 'Cardiac arrest', 'vietnamese': 'Ngưng tim'}, {
  'id': 'I46.0',
  'english': 'Cardiac arrest with successful resuscitation',
  'vietnamese': 'Ngưng tim với hồi sức thành công',
}, {
  'id': 'I46.1',
  'english': 'Sudden cardiac death, so described',
  'vietnamese': 'Đột tử do tim (được mô tả)',
}, {
  'id': 'I46.9',
  'english': 'Cardiac arrest, unspecified',
  'vietnamese': 'Ngưng tim không đặc hiệu',
}, {'id': 'I47', 'english': 'Paroxysmal tachycardia', 'vietnamese': 'Nhịp nhanh kịch phát'}, {
  'id': 'I47.0',
  'english': 'Re-entry ventricular arrhythmia',
  'vietnamese': 'Loạn nhịp thất do cơ chế vòng vào lại',
}, {
  'id': 'I47.1',
  'english': 'Supraventricular tachycardia',
  'vietnamese': 'Nhịp nhanh trên thất',
}, {
  'id': 'I47.2',
  'english': 'Ventricular tachycardia',
  'vietnamese': 'Nhịp nhanh thất',
}, {
  'id': 'I47.9',
  'english': 'Paroxysmal tachycardia, unspecified',
  'vietnamese': 'Nhịp nhanh kịch phát, không đặc hiệu',
}, {'id': 'I48', 'english': 'Paroxysmal tachycardia', 'vietnamese': 'Rung nhĩ và cuồng nhĩ'}, {
  'id': 'I48.0',
  'english': 'Paroxysmal atrial fibrillation',
  'vietnamese': 'Rung nhĩ kịch phát',
}, {
  'id': 'I48.1',
  'english': 'Persistent atrial fibrillation',
  'vietnamese': 'Rung nhĩ dai dẳng',
}, {
  'id': 'I48.2',
  'english': 'Chronic atrial fibrillation',
  'vietnamese': 'Rung nhĩ mãn tính',
}, {'id': 'I48.3', 'english': 'Typical atrial flutter', 'vietnamese': 'Rung nhĩ điển hình'}, {
  'id': 'I48.4',
  'english': 'Atypical atrial flutter',
  'vietnamese': 'Cuồng nhĩ không điển hình',
}, {
  'id': 'I48.9',
  'english': 'Atrial fibrillation and atrial flutter, unspecified',
  'vietnamese': 'Rung nhĩ và cuồng nhĩ, không đặc hiệu',
}, {'id': 'I49', 'english': 'Other cardiac arrhythmias', 'vietnamese': 'Loạn nhịp tim khác'}, {
  'id': 'I49.0',
  'english': 'Ventricular fibrillation and flutter',
  'vietnamese': 'Rung thất và cuồng thất',
}, {
  'id': 'I49.1',
  'english': 'Atrial premature depolarization',
  'vietnamese': 'Ngoại tâm thu nhĩ',
}, {
  'id': 'I49.2',
  'english': 'Junctional premature depolarization',
  'vietnamese': 'Khử cực sớm vùng bộ nối',
}, {
  'id': 'I49.3',
  'english': 'Ventricular premature depolarization',
  'vietnamese': 'Ngoại tâm thu thất',
}, {
  'id': 'I49.4',
  'english': 'Other and unspecified premature depolarization',
  'vietnamese': 'Khử cực sớm khác và không xác định',
}, {'id': 'I49.5', 'english': 'Sick sinus syndrome', 'vietnamese': 'Hội chứng suy nút xoang'}, {
  'id': 'I49.8',
  'english': 'Other specified cardiac arrhythmias',
  'vietnamese': 'Loạn nhịp tim xác định khác',
}, {
  'id': 'I49.9',
  'english': 'Cardiac arrhythmia, unspecified',
  'vietnamese': 'Rối loạn nhịp tim, không đặc hiệu',
}, {'id': 'I50', 'english': 'Heart failure', 'vietnamese': 'Suy tim'}, {
  'id': 'I50.0',
  'english': 'Congestive heart failure',
  'vietnamese': 'Suy tim sung huyết',
}, {'id': 'I50.1', 'english': 'Left ventricular failure', 'vietnamese': 'Suy thất trái'}, {
  'id': 'I50.9',
  'english': 'Heart failure, unspecified',
  'vietnamese': 'Suy tim, không đặc hiệu',
}, {
  'id': 'I51',
  'english': 'Complications and ill-defined descriptions of heart disease',
  'vietnamese': 'Biến chứng và bệnh lý được xác định là do bệnh tim mạch',
}, {
  'id': 'I51.0',
  'english': 'Cardiac septal defect, acquired',
  'vietnamese': 'Thông vách ngăn tim, mắc phải',
}, {
  'id': 'I51.1',
  'english': 'Rupture of chordae tendineae, not elsewhere classified',
  'vietnamese': 'Đứt dây chằng van tim, không có trong phân loại nơi khác',
}, {
  'id': 'I51.2',
  'english': 'Rupture of papillary muscle, not elsewhere classified',
  'vietnamese': 'Đứt cơ nhú, không có trong phân loại nơi khác',
}, {
  'id': 'I51.3',
  'english': 'Intracardiac thrombosis, not elsewhere classified',
  'vietnamese': 'Huyết khối trong tim, không phân loại nơi khác',
}, {
  'id': 'I51.4',
  'english': 'Myocarditis, unspecified',
  'vietnamese': 'Viêm cơ tim, không đặc hiệu',
}, {'id': 'I51.5', 'english': 'Myocardial degeneration', 'vietnamese': 'thoái hóa cơ tim'}, {
  'id': 'I51.6',
  'english': 'Cardiovascular disease, unspecified',
  'vietnamese': 'Bệnh tim mạch, không đặc hiệu',
}, {'id': 'I51.7', 'english': 'Cardiomegaly', 'vietnamese': 'Tim to'}, {
  'id': 'I51.8',
  'english': 'Other ill-defined heart diseases',
  'vietnamese': 'Bệnh lý khác được xác định là bệnh lý của tim',
}, {
  'id': 'I51.9',
  'english': 'Heart disease, unspecified',
  'vietnamese': 'Bệnh tim, không đặc hiệu',
}, {
  'id': 'I52*',
  'english': 'Other heart disorders in diseases classified elsewhere',
  'vietnamese': 'Các bệnh lý khác trong các bệnh đã phân loại nơi khác',
}, {
  'id': 'I52.0*',
  'english': 'Other heart disorders in bacterial diseases classified elsewhere',
  'vietnamese': 'Bệnh tim khác trong bệnh nhiễm trùng phân loại nơi khác',
}, {
  'id': 'I52.1*',
  'english': 'Other heart disorders in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Bệnh tim khác trong bệnh nhiễm trùng và ký sinh trùng khác phân loại nơi khác',
}, {
  'id': 'I52.8*',
  'english': 'Other heart disorders in other diseases classified elsewhere',
  'vietnamese': 'Bệnh tim khác trong bệnh khác, phân loại nơi khác',
}, {
  'id': 'I60',
  'english': 'Subarachnoid haemorrhage',
  'vietnamese': 'Xuất huyết dưới màng nhện',
}, {
  'id': 'I60.0',
  'english': 'Subarachnoid haemorrhage from carotid siphon and bifurcation',
  'vietnamese': 'Xuất huyết dưới màng nhện từ hành cảnh và chỗ chia nhánh động mạch cảnh',
}, {
  'id': 'I60.1',
  'english': 'Subarachnoid haemorrhage from middle cerebral artery',
  'vietnamese': 'Xuất huyết dưới nhện từ động mạch não giữa',
}, {
  'id': 'I60.2',
  'english': 'Subarachnoid haemorrhage from anterior communicating artery',
  'vietnamese': 'Xuất huyết dưới nhện từ động mạch thông trước',
}, {
  'id': 'I60.3',
  'english': 'Subarachnoid haemorrhage from posterior communicating artery',
  'vietnamese': 'Xuất huyết dưới màng nhện từ động mạch thông sau',
}, {
  'id': 'I60.4',
  'english': 'Subarachnoid haemorrhage from basilar artery',
  'vietnamese': 'Xuất huyết dưới nhện từ động mạch sống nền',
}, {
  'id': 'I60.5',
  'english': 'Subarachnoid haemorrhage from vertebral artery',
  'vietnamese': 'Xuất huyết dưới nhện từ động mạch đốt sống',
}, {
  'id': 'I60.6',
  'english': 'Subarachnoid haemorrhage from other intracranial arteries',
  'vietnamese': 'Xuất huyết dưới nhện từ động mạch nội sọ khác',
}, {
  'id': 'I60.7',
  'english': 'Subarachnoid haemorrhage from intracranial artery, unspecified',
  'vietnamese': 'Xuất huyết dưới màng nhện từ động mạch nội sọ, không đặc hiệu',
}, {
  'id': 'I60.8',
  'english': 'Other subarachnoid haemorrhage',
  'vietnamese': 'Xuất huyết dưới màng nhện khác',
}, {
  'id': 'I60.9',
  'english': 'Subarachnoid haemorrhage, unspecified',
  'vietnamese': 'Xuất huyết dưới màng nhện, không đặc hiệu',
}, {'id': 'I61', 'english': 'Intracerebral haemorrhage', 'vietnamese': 'Xuất huyết nội sọ'}, {
  'id': 'I61.0',
  'english': 'Intracerebral haemorrhage in hemisphere, subcortical',
  'vietnamese': 'Xuất huyết nội sọ tại bán cầu, vùng dưới vỏ',
}, {
  'id': 'I61.1',
  'english': 'Intracerebral haemorrhage in hemisphere, cortical',
  'vietnamese': 'Xuất huyết nội sọ tại bán cầu, vùng vỏ',
}, {
  'id': 'I61.2',
  'english': 'Intracerebral haemorrhage in hemisphere, unspecified',
  'vietnamese': 'Xuất huyết nội sọ tại bán cầu, không đặc hiệu',
}, {
  'id': 'I61.3',
  'english': 'Intracerebral haemorrhage in brain stem',
  'vietnamese': 'Xuất huyết nội sọ tại thân não',
}, {
  'id': 'I61.4',
  'english': 'Intracerebral haemorrhage in cerebellum',
  'vietnamese': 'Xuất huyết nội sọ tại tiểu não',
}, {
  'id': 'I61.5',
  'english': 'Intracerebral haemorrhage, intraventricular',
  'vietnamese': 'Xuất huyết nội sọ, tại não thất',
}, {
  'id': 'I61.6',
  'english': 'Intracerebral haemorrhage, multiple localized',
  'vietnamese': 'Xuất huyết nội sọ, tại nhiều nơi',
}, {
  'id': 'I61.8',
  'english': 'Other intracerebral haemorrhage',
  'vietnamese': 'Xuất huyết nội sọ khác',
}, {
  'id': 'I61.9',
  'english': 'Intracerebral haemorrhage, unspecified',
  'vietnamese': 'Xuất huyết nội sọ, không đặc hiệu',
}, {
  'id': 'I62',
  'english': 'Other nontraumatic intracranial haemorrhage',
  'vietnamese': 'Xuất huyết nội sọ không do chấn thương khác',
}, {
  'id': 'I62.0',
  'english': 'Subdural haemorrhage (acute)(nontraumatic)',
  'vietnamese': 'Xuất huyết dưới màng cứng (cấp) (không do chấn thương)',
}, {
  'id': 'I62.1',
  'english': 'Nontraumatic extradural haemorrhage',
  'vietnamese': 'Xuất huyết ngoài màng cứng, không do chấn thương',
}, {
  'id': 'I62.9',
  'english': 'Intracranial haemorrhage (nontraumatic), unspecified',
  'vietnamese': 'Xuất huyết nội sọ không do chấn thương, không đặc hiệu',
}, {'id': 'I63', 'english': 'Cerebral infarction', 'vietnamese': 'Nhồi máu não'}, {
  'id': 'I63.0',
  'english': 'Cerebral infarction due to thrombosis of precerebral arteries',
  'vietnamese': 'Nhồi máu não do huyết khối động mạch trước não',
}, {
  'id': 'I63.1',
  'english': 'Cerebral infarction due to embolism of precerebral arteries',
  'vietnamese': 'Nhồi máu não do thuyên tắc động mạch trước não',
}, {
  'id': 'I63.2',
  'english': 'Cerebral infarction due to unspecified occlusion or stenosis of precerebral arteries',
  'vietnamese': 'Nhồi máu não không đặc hiệu do tắc hay hẹp ở động mạch trước não',
}, {
  'id': 'I63.3',
  'english': 'Cerebral infarction due to thrombosis of cerebral arteries',
  'vietnamese': 'Nhồi máu não do huyết khối động mạch não',
}, {
  'id': 'I63.4',
  'english': 'Cerebral infarction due to embolism of cerebral arteries',
  'vietnamese': 'Nhồi máu não do thuyên tắc động mạch não',
}, {
  'id': 'I63.5',
  'english': 'Cerebral infarction due to unspecified occlusion or stenosis of cerebral arteries',
  'vietnamese': 'Nhồi máu não không xác định do tắc hay hẹp ở động mạch não',
}, {
  'id': 'I63.6',
  'english': 'Cerebral infarction due to cerebral venous thrombosis, nonpyogenic',
  'vietnamese': 'Nhồi máu não do huyết khối tĩnh mạch não, không sinh mủ',
}, {'id': 'I63.8', 'english': 'Other cerebral infarction', 'vietnamese': 'Nhồi máu não khác'}, {
  'id': 'I63.9',
  'english': 'Cerebral infarction, unspecified',
  'vietnamese': 'Nhồi máu não, không đặc hiệu',
}, {
  'id': 'I64',
  'english': 'Stroke, not specified as haemorrhage or infarction',
  'vietnamese': 'Đột quị, không xác định do xuất huyết hay nhồi máu (Tai biến mạch máu não)',
}, {
  'id': 'I65',
  'english': 'Occlusion and stenosis of precerebral arteries, not resulting in cerebral infarction',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch trước não, không dẫn đến nhồi máu não',
}, {
  'id': 'I65.0',
  'english': 'Occlusion and stenosis of vertebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch đốt sống',
}, {
  'id': 'I65.1',
  'english': 'Occlusion and stenosis of basilar artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch nền',
}, {
  'id': 'I65.2',
  'english': 'Occlusion and stenosis of carotid artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch cảnh',
}, {
  'id': 'I65.3',
  'english': 'Occlusion and stenosis of multiple and bilateral precerebral arteries',
  'vietnamese': 'Nghẽn tắc và hẹp nhiều động mạch và động mạch trước não hai bên',
}, {
  'id': 'I65.8',
  'english': 'Occlusion and stenosis of other precerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp của động mạch trước não khác',
}, {
  'id': 'I65.9',
  'english': 'Occlusion and stenosis of unspecified precerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp của động mạch trước não không xác định',
}, {
  'id': 'I66',
  'english': 'Occlusion and stenosis of cerebral arteries, not resulting in cerebral infarction',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não, không dẫn đến nhồi máu não',
}, {
  'id': 'I66.0',
  'english': 'Occlusion and stenosis of middle cerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não giữa',
}, {
  'id': 'I66.1',
  'english': 'Occlusion and stenosis of anterior cerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não trước',
}, {
  'id': 'I66.2',
  'english': 'Occlusion and stenosis of posterior cerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não sau',
}, {
  'id': 'I66.3',
  'english': 'Occlusion and stenosis of cerebellar arteries',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch tiểu não',
}, {
  'id': 'I66.4',
  'english': 'Occlusion and stenosis of multiple and bilateral cerebral arteries',
  'vietnamese': 'Nghẽn tắc và hẹp nhiều động mạch não hai bên',
}, {
  'id': 'I66.8',
  'english': 'Occlusion and stenosis of other cerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não khác',
}, {
  'id': 'I66.9',
  'english': 'Occlusion and stenosis of unspecified cerebral artery',
  'vietnamese': 'Nghẽn tắc và hẹp động mạch não, không xác định',
}, {
  'id': 'I67',
  'english': 'Other cerebrovascular diseases',
  'vietnamese': 'Bệnh mạch máu não khác',
}, {
  'id': 'I67.0',
  'english': 'Dissection of cerebral arteries, nonruptured',
  'vietnamese': 'Tách thành động mạch não, không vỡ',
}, {
  'id': 'I67.1',
  'english': 'Cerebral aneurysm, nonruptured',
  'vietnamese': 'Phình động mạch não, không vỡ',
}, {'id': 'I67.2', 'english': 'Cerebral atherosclerosis', 'vietnamese': 'Xơ vữa động mạch não'}, {
  'id': 'I67.3',
  'english': 'Progressive vascular leukoencephalopathy',
  'vietnamese': 'Bệnh lý chất trắng não do nguyên nhân mạch máu',
}, {
  'id': 'I67.4',
  'english': 'Hypertensive encephalopathy',
  'vietnamese': 'Bệnh lý não do tăng huyết áp',
}, {'id': 'I67.5', 'english': 'Moyamoya disease', 'vietnamese': 'Bệnh Moyamoya'}, {
  'id': 'I67.6',
  'english': 'Nonpyogenic thrombosis of intracranial venous system',
  'vietnamese': 'Huyết khối không sinh mủ của hệ tĩnh mạch nội sọ',
}, {
  'id': 'I67.7',
  'english': 'Cerebral arteritis, not elsewhere classified',
  'vietnamese': 'Viêm động mạch não, không phân loại nơi khác',
}, {
  'id': 'I67.8',
  'english': 'Other specified cerebrovascular diseases',
  'vietnamese': 'Bệnh mạch máu não xác định khác',
}, {
  'id': 'I67.9',
  'english': 'Cerebrovascular disease, unspecified',
  'vietnamese': 'Bệnh mạch máu não không đặc hiệu',
}, {
  'id': 'I68*',
  'english': 'Cerebrovascular disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh mạch máu não trong bệnh phân loại nơi khác',
}, {
  'id': 'I68.0*',
  'english': 'Cerebral amyloid angiopathyE85.-',
  'vietnamese': 'Bệnh mạch máu não do thoái hóa dạng bột E85.-',
}, {
  'id': 'I68.1*',
  'english': 'Cerebral arteritis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm động mạch não trong bệnh nhiễm trùng và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'I68.2*',
  'english': 'Cerebral arteritis in other diseases classified elsewhere',
  'vietnamese': 'Viêm động mạch não trong bệnh khác phân loại nơi khác',
}, {
  'id': 'I68.8*',
  'english': 'Other cerebrovascular disorders in diseases classified elsewhere',
  'vietnamese': 'Ngập máu tăng ure máu trong bệnh thận mãn tính',
}, {
  'id': 'I69',
  'english': 'Sequelae of cerebrovascular disease',
  'vietnamese': 'Di chứng bệnh mạch máu não',
}, {
  'id': 'I69.0',
  'english': 'Sequelae of subarachnoid haemorrhage',
  'vietnamese': 'Di chứng xuất huyết dưới màng nhện',
}, {
  'id': 'I69.1',
  'english': 'Sequelae of intracerebral haemorrhage',
  'vietnamese': 'Di chứng xuất huyết nội sọ',
}, {
  'id': 'I69.2',
  'english': 'Sequelae of other nontraumatic intracranial haemorrhage',
  'vietnamese': 'Di chứng xuất huyết nội sọ không do chấn thương khác',
}, {
  'id': 'I69.3',
  'english': 'Sequelae of cerebral infarction',
  'vietnamese': 'Di chứng nhồi máu não',
}, {
  'id': 'I69.4',
  'english': 'Sequelae of stroke, not specified as haemorrhage or infarction',
  'vietnamese': 'Di chứng đột quỵ, không xác định là xuất huyết hay nhồi máu',
}, {
  'id': 'I69.8',
  'english': 'Sequelae of other and unspecified cerebrovascular diseases',
  'vietnamese': 'Di chứng bệnh mạch máu não khác và không xác định',
}, {'id': 'I70', 'english': 'Atherosclerosis', 'vietnamese': 'Xơ vữa động mạch'}, {
  'id': 'I70.0',
  'english': 'Atherosclerosis of aorta',
  'vietnamese': 'Xơ vữa động mạch chủ',
}, {
  'id': 'I70.1',
  'english': 'Atherosclerosis of renal artery',
  'vietnamese': 'Xơ vữa động mạch thận',
}, {
  'id': 'I70.2',
  'english': 'Atherosclerosis of arteries of extremities',
  'vietnamese': 'Xơ vữa động mạch ngoại biên',
}, {
  'id': 'I70.8',
  'english': 'Atherosclerosis of other arteries',
  'vietnamese': 'Xơ vữa động mạch khác',
}, {
  'id': 'I70.9',
  'english': 'Generalized and unspecified atherosclerosis',
  'vietnamese': 'Xơ vữa động mạch, toàn thể và không xác định',
}, {
  'id': 'I71',
  'english': 'Aortic aneurysm and dissection',
  'vietnamese': 'Phình và tách thành động mạch chủ',
}, {
  'id': 'I71.0',
  'english': 'Dissection of aorta [any part]',
  'vietnamese': 'Tách thành động mạch chủ (bất kỳ đoạn nào)',
}, {
  'id': 'I71.1',
  'english': 'Thoracic aortic aneurysm, ruptured',
  'vietnamese': 'Phình động mạch chủ ngực, vỡ',
}, {
  'id': 'I71.2',
  'english': 'Thoracic aortic aneurysm, without mention of rupture',
  'vietnamese': 'Phình động mạch chủ ngực, không vỡ',
}, {
  'id': 'I71.3',
  'english': 'Abdominal aortic aneurysm, ruptured',
  'vietnamese': 'Phình động mạch chủ bụng, vỡ',
}, {
  'id': 'I71.4',
  'english': 'Abdominal aortic aneurysm, without mention of rupture',
  'vietnamese': 'Phình động mạch chủ bụng, không vỡ',
}, {
  'id': 'I71.5',
  'english': 'Thoracoabdominal aortic aneurysm, ruptured',
  'vietnamese': 'Phình động mạch chủ ngực-bụng, vỡ',
}, {
  'id': 'I71.6',
  'english': 'Thoracoabdominal aortic aneurysm, without mention of rupture',
  'vietnamese': 'Phình động mạch chủ ngực-bụng, không vỡ',
}, {
  'id': 'I71.8',
  'english': 'Aortic aneurysm of unspecified site, ruptured',
  'vietnamese': 'Phình động mạch chủ, vị trí không xác định, vỡ',
}, {
  'id': 'I71.9',
  'english': 'Aortic aneurysm of unspecified site, without mention of rupture',
  'vietnamese': 'Phình động mạch chủ, vị trí không xác định, không vỡ',
}, {'id': 'I72', 'english': 'Other aneurysm', 'vietnamese': 'Phình và tách động mạch khác'}, {
  'id': 'I72.0',
  'english': 'Aneurysm of carotid artery',
  'vietnamese': 'Phình và tách động mạch cảnh',
}, {
  'id': 'I72.1',
  'english': 'Aneurysm of artery of upper extremity',
  'vietnamese': 'Phình và tách động mạch chi trên',
}, {
  'id': 'I72.2',
  'english': 'Aneurysm of renal artery',
  'vietnamese': 'Phình và tách động mạch thận',
}, {
  'id': 'I72.3',
  'english': 'Aneurysm of iliac artery',
  'vietnamese': 'Phình và tách động mạch chậu',
}, {
  'id': 'I72.4',
  'english': 'Aneurysm of artery of lower extremity',
  'vietnamese': 'Phình và tách động mạch chi dưới',
}, {
  'id': 'I72.5',
  'english': 'Aneurysm and dissection of other precerebral arteries',
  'vietnamese': 'Phình và tách động mạch nền (thân)',
}, {
  'id': 'I72.6',
  'english': 'Aneurysm and dissection of vertebral artery',
  'vietnamese': 'Phình và tách động mạch đốt sống',
}, {
  'id': 'I72.8',
  'english': 'Aneurysm of other specified arteries',
  'vietnamese': 'Phình và tách động mạch xác định khác',
}, {
  'id': 'I72.9',
  'english': 'Aneurysm of unspecified site',
  'vietnamese': 'Phình và tách động mạch, vị trí không xác định',
}, {
  'id': 'I73',
  'english': 'Other peripheral vascular diseases',
  'vietnamese': 'Bệnh mạch máu ngoại biên',
}, {'id': 'I73.0', 'english': 'Raynaud s syndrome', 'vietnamese': 'Hội chứng Raynaud'}, {
  'id': 'I73.1',
  'english': 'Thromboangiitis obliterans [Buerger]',
  'vietnamese': 'Viêm tắc mạch huyết khối [Buerger]',
}, {
  'id': 'I73.8',
  'english': 'Other specified peripheral vascular diseases',
  'vietnamese': 'Bệnh mạch máu ngoại biên xác định khác',
}, {
  'id': 'I73.9',
  'english': 'Peripheral vascular disease, unspecified',
  'vietnamese': 'Bệnh mạch máu ngoại biên, không đặc hiệu',
}, {
  'id': 'I74',
  'english': 'Arterial embolism and thrombosis',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch',
}, {
  'id': 'I74.0',
  'english': 'Embolism and thrombosis of abdominal aorta',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch chủ bụng',
}, {
  'id': 'I74.1',
  'english': 'Embolism and thrombosis of other and unspecified parts of aorta',
  'vietnamese': 'Thuyên tắc và huyết khối, đoạn động mạch chủ khác và không xác định',
}, {
  'id': 'I74.2',
  'english': 'Embolism and thrombosis of arteries of upper extremities',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch chi trên',
}, {
  'id': 'I74.3',
  'english': 'Embolism and thrombosis of arteries of lower extremities',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch chi dưới',
}, {
  'id': 'I74.4',
  'english': 'Embolism and thrombosis of arteries of extremities, unspecified',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch chi không đặc hiệu',
}, {
  'id': 'I74.5',
  'english': 'Embolism and thrombosis of iliac artery',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch chậu',
}, {
  'id': 'I74.8',
  'english': 'Embolism and thrombosis of other arteries',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch khác',
}, {
  'id': 'I74.9',
  'english': 'Embolism and thrombosis of unspecified artery',
  'vietnamese': 'Thuyên tắc và huyết khối động mạch, không xác định',
}, {
  'id': 'I77',
  'english': 'Other disorders of arteries and arterioles',
  'vietnamese': 'Các bệnh khác của hệ động mạch và tiểu động mạch',
}, {
  'id': 'I77.0',
  'english': 'Arteriovenous fistula, acquired',
  'vietnamese': 'Dò động - tĩnh mạch mắc phải',
}, {'id': 'I77.1', 'english': 'Stricture of artery', 'vietnamese': 'Co hẹp động mạch'}, {
  'id': 'I77.2',
  'english': 'Rupture of artery',
  'vietnamese': 'Vỡ động mạch',
}, {
  'id': 'I77.3',
  'english': 'Arterial fibromuscular dysplasia',
  'vietnamese': 'Loạn sản chun xơ cơ của động mạch',
}, {
  'id': 'I77.4',
  'english': 'Coeliac artery compression syndrome',
  'vietnamese': 'Hội chứng chèn ép động mạch tạng',
}, {'id': 'I77.5', 'english': 'Necrosis of artery', 'vietnamese': 'Hoại tử động mạch'}, {
  'id': 'I77.6',
  'english': 'Arteritis, unspecified',
  'vietnamese': 'Viêm động mạch, không đặc hiệu',
}, {
  'id': 'I77.8',
  'english': 'Other specified disorders of arteries and arterioles',
  'vietnamese': 'Bệnh xác định khác của động mạch và tiểu động mạch',
}, {
  'id': 'I77.9',
  'english': 'Disorder of arteries and arterioles, unspecified',
  'vietnamese': 'Bệnh động mạch và tiểu động mạch, không đặc hiệu',
}, {'id': 'I78', 'english': 'Diseases of capillaries', 'vietnamese': 'Bệnh của mao mạch'}, {
  'id': 'I78.0',
  'english': 'Hereditary haemorrhagic telangiectasia',
  'vietnamese': 'Dãn mạch di truyền xuất huyết',
}, {
  'id': 'I78.1',
  'english': 'Naevus, non-neoplastic',
  'vietnamese': 'Tổn thương mạch máu trên da (Naevus), không tân sinh',
}, {
  'id': 'I78.8',
  'english': 'Other diseases of capillaries',
  'vietnamese': 'Bệnh khác của mao mạch',
}, {
  'id': 'I78.9',
  'english': 'Disease of capillaries, unspecified',
  'vietnamese': 'Bệnh khác của mao mạch, không đặc hiệu',
}, {
  'id': 'I79*',
  'english': 'Disorders of arteries, arterioles and capillaries in diseases classified elsewhere',
  'vietnamese': 'Bệnh động mạch, tiểu động mạch và mao mạch trong bệnh phân loại nơi khác',
}, {
  'id': 'I79.0*',
  'english': 'Aneurysm of aorta in diseases classified elsewhere',
  'vietnamese': 'Phình động mạch chủ trong bệnh phân loại nơi khác',
}, {
  'id': 'I79.1*',
  'english': 'Aortitis in diseases classified elsewhere',
  'vietnamese': 'Viêm động mạch chủ trong bệnh phân loại nơi khác',
}, {
  'id': 'I79.2*',
  'english': 'Peripheral angiopathy in diseases classified elsewhere',
  'vietnamese': 'Bệnh lý mạch máu ngoại biên trong bệnh phân loại nơi khác',
}, {
  'id': 'I79.8*',
  'english': 'Other disorders of arteries, arterioles and capillaries in diseases classified elsewhere',
  'vietnamese': 'Bệnh động mạch, tiểu động mạch và mao mạch trong bệnh phân loại nơi khác',
}, {
  'id': 'I80',
  'english': 'Phlebitis and thrombophlebitis',
  'vietnamese': 'Viêm tĩnh mạch và tắc tĩnh mạch',
}, {
  'id': 'I80.0',
  'english': 'Phlebitis and thrombophlebitis of superficial vessels of lower extremities',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch nông ở chi dưới',
}, {
  'id': 'I80.1',
  'english': 'Phlebitis and thrombophlebitis of femoral vein',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch đùi',
}, {
  'id': 'I80.2',
  'english': 'Phlebitis and thrombophlebitis of other deep vessels of lower extremities',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch của các tĩnh mạch sâu khác ở chi dưới',
}, {
  'id': 'I80.3',
  'english': 'Phlebitis and thrombophlebitis of lower extremities, unspecified',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch ở chi dưới, không đặc hiệu',
}, {
  'id': 'I80.8',
  'english': 'Phlebitis and thrombophlebitis of other sites',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch ở vị trí khác',
}, {
  'id': 'I80.9',
  'english': 'Phlebitis and thrombophlebitis of unspecified site',
  'vietnamese': 'Viêm tĩnh mạch và viêm tắc tĩnh mạch, vị trí không xác định',
}, {'id': 'I81', 'english': 'Portal vein thrombosis', 'vietnamese': 'Huyết khối tĩnh mạch cửa'}, {
  'id': 'I82',
  'english': 'Other venous embolism and thrombosis',
  'vietnamese': 'Các thuyên tắc và huyết khối tĩnh mạch khác',
}, {'id': 'I82.0', 'english': 'Budd-Chiari syndrome', 'vietnamese': 'Hội chứng Budd-Chiari'}, {
  'id': 'I82.1',
  'english': 'Thrombophlebitis migrans',
  'vietnamese': 'Viêm tắc tĩnh mạch tái phát (migrans)',
}, {
  'id': 'I82.2',
  'english': 'Embolism and thrombosis of vena cava',
  'vietnamese': 'Thuyên tắc và huyết khối tĩnh mạch chủ',
}, {
  'id': 'I82.3',
  'english': 'Embolism and thrombosis of renal vein',
  'vietnamese': 'Thuyên tắc và huyết khối tĩnh mạch thận',
}, {
  'id': 'I82.8',
  'english': 'Embolism and thrombosis of other specified veins',
  'vietnamese': 'Thuyên tắc và huyết khối tĩnh mạch khác',
}, {
  'id': 'I82.9',
  'english': 'Embolism and thrombosis of unspecified vein',
  'vietnamese': 'Thuyên tắc và huyết khối, không xác định tĩnh mạch',
}, {
  'id': 'I83',
  'english': 'Varicose veins of lower extremities',
  'vietnamese': 'Dãn tĩnh mạch chi dưới',
}, {
  'id': 'I83.0',
  'english': 'Varicose veins of lower extremities with ulcer',
  'vietnamese': 'Dãn tĩnh mạch chi dưới có loét',
}, {
  'id': 'I83.1',
  'english': 'Varicose veins of lower extremities with inflammation',
  'vietnamese': 'Dãn tĩnh mạch chi dưới có viêm',
}, {
  'id': 'I83.2',
  'english': 'Varicose veins of lower extremities with both ulcer and inflammation',
  'vietnamese': 'Dãn tĩnh mạch chi dưới có loét và viêm',
}, {
  'id': 'I83.9',
  'english': 'Varicose veins of lower extremities without ulcer or inflammation',
  'vietnamese': 'Dãn tĩnh mạch chi dưới không loét không viêm',
}, {'id': 'I84', 'english': 'Haemorrhoids', 'vietnamese': 'Trĩ'}, {
  'id': 'I84.0',
  'english': 'Internal thrombosed haemorrhoids',
  'vietnamese': 'Trĩ nội gây huyết khối',
}, {
  'id': 'I84.1',
  'english': 'Internal haemorrhoids with other complications',
  'vietnamese': 'Trĩ nội có biến chứng khác',
}, {
  'id': 'I84.2',
  'english': 'Internal haemorrhoids without complication',
  'vietnamese': 'Trĩ nội không biến chứng',
}, {
  'id': 'I84.3',
  'english': 'External thrombosed haemorrhoids',
  'vietnamese': 'Trĩ ngoại huyết khối',
}, {
  'id': 'I84.4',
  'english': 'External haemorrhoids with other complications',
  'vietnamese': 'Trĩ ngoại với biến chứng khác',
}, {
  'id': 'I84.5',
  'english': 'External haemorrhoids without complication',
  'vietnamese': 'Trĩ ngoại không biến chứng',
}, {
  'id': 'I84.6',
  'english': 'Residual haemorrhoidal skin tags',
  'vietnamese': 'Dãn da do trĩ sót lại',
}, {
  'id': 'I84.7',
  'english': 'Unspecified thrombosed haemorrhoids',
  'vietnamese': 'Trĩ gây huyết khối không chẩn đoán',
}, {
  'id': 'I84.8',
  'english': 'Unspecified haemorrhoids with other complications',
  'vietnamese': 'Trĩ không xác định, có biến chứng khác',
}, {
  'id': 'I84.9',
  'english': 'Unspecified haemorrhoids without complication',
  'vietnamese': 'Trĩ không xác định, không biến chứng',
}, {'id': 'I85', 'english': 'Oesophageal varices', 'vietnamese': 'Dãn tĩnh mạch thực quản'}, {
  'id': 'I85.0',
  'english': 'Oesophageal varices with bleeding',
  'vietnamese': 'Dãn tĩnh mạch thực quản có chảy máu',
}, {
  'id': 'I85.9',
  'english': 'Oesophageal varices without bleeding',
  'vietnamese': 'Dãn tĩnh mạch thực quản không chảy máu',
}, {
  'id': 'I86',
  'english': 'Varicose veins of other sites',
  'vietnamese': 'Dãn tĩnh mạch vị trí khác',
}, {'id': 'I86.0', 'english': 'Sublingual varices', 'vietnamese': 'Dãn tĩnh mạch dưới lưỡi'}, {
  'id': 'I86.1',
  'english': 'Scrotal varices',
  'vietnamese': 'Túi dãn tĩnh mạch bìu',
}, {'id': 'I86.2', 'english': 'Pelvic varices', 'vietnamese': 'Dãn tĩnh mạch chậu'}, {
  'id': 'I86.3',
  'english': 'Vulval varices',
  'vietnamese': 'Dãn tĩnh mạch âm hộ',
}, {'id': 'I86.4', 'english': 'Gastric varices', 'vietnamese': 'Dãn tĩnh mạch dạ dày'}, {
  'id': 'I86.8',
  'english': 'Varicose veins of other specified sites',
  'vietnamese': 'Dãn tĩnh mạch ở vị trí xác định khác',
}, {
  'id': 'I87',
  'english': 'Other disorders of veins',
  'vietnamese': 'Rối loạn khác của tĩnh mạch',
}, {
  'id': 'I87.0',
  'english': 'Postphlebitic syndrome',
  'vietnamese': 'Hội chứng sau huyết khối',
}, {
  'id': 'I87.1',
  'english': 'Compression of vein',
  'vietnamese': 'Ép tĩnh mạch',
}, {
  'id': 'I87.2',
  'english': 'Venous insufficiency (chronic)(peripheral)',
  'vietnamese': 'Suy tĩnh mạch (mạn) (ngoại biên)',
}, {
  'id': 'I87.8',
  'english': 'Other specified disorders of veins',
  'vietnamese': 'Rối loạn khác tĩnh mạch xác định khác',
}, {
  'id': 'I87.9',
  'english': 'Disorder of vein, unspecified',
  'vietnamese': 'Rối loạn khác tĩnh mạch, không đặc hiệu khác',
}, {
  'id': 'I88',
  'english': 'Nonspecific lymphadenitis',
  'vietnamese': 'Viêm hạch bạch huyết không đặc hiệu',
}, {
  'id': 'I88.0',
  'english': 'Nonspecific mesenteric lymphadenitis',
  'vietnamese': 'Viêm hạch bạch huyết mạc treo không đặc hiệu',
}, {
  'id': 'I88.1',
  'english': 'Chronic lymphadenitis, except mesenteric',
  'vietnamese': 'Viêm hạch bạch huyết mạn, ngoại trừ mạc treo',
}, {
  'id': 'I88.8',
  'english': 'Other nonspecific lymphadenitis',
  'vietnamese': 'Viêm hạch bạch huyết không đặc hiệu khác',
}, {
  'id': 'I88.9',
  'english': 'Nonspecific lymphadenitis, unspecified',
  'vietnamese': 'Viêm hạch bạch huyết không đặc hiệu, không đặc hiệu',
}, {
  'id': 'I89',
  'english': 'Other noninfective disorders of lymphatic vessels and lymph nodes',
  'vietnamese': 'Rối loạn mạch bạch huyết và hạch bạch huyết không nhiễm trùng khác',
}, {
  'id': 'I89.0',
  'english': 'Lymphoedema, not elsewhere classified',
  'vietnamese': 'Phù bạch huyết, không phân loại nơi khác',
}, {'id': 'I89.1', 'english': 'Lymphangitis', 'vietnamese': 'Viêm mạch bạch huyết'}, {
  'id': 'I89.8',
  'english': 'Other specified noninfective disorders of lymphatic vessels and lymph nodes',
  'vietnamese': 'Rối loạn mạch bạch huyết và hạch bạch huyết xác định khác không do nhiễm trùng',
}, {
  'id': 'I89.9',
  'english': 'Noninfective disorder of lymphatic vessels and lymph nodes, unspecified',
  'vietnamese': 'Rối loạn mạch bạch huyết và hạch bạch huyết không do nhiễm trùng, không đặc hiệu',
}, {'id': 'I95', 'english': 'Hypotension', 'vietnamese': 'Huyết áp thấp (hạ huyết áp)'}, {
  'id': 'I95.0',
  'english': 'Idiopathic hypotension',
  'vietnamese': 'Hạ huyết áp không rõ nguyên nhân',
}, {'id': 'I95.1', 'english': 'Orthostatic hypotension', 'vietnamese': 'Hạ huyết áp thế đứng'}, {
  'id': 'I95.2',
  'english': 'Hypotension due to drugs',
  'vietnamese': 'Hạ huyết áp do thuốc',
}, {'id': 'I95.8', 'english': 'Other hypotension', 'vietnamese': 'Hạ huyết áp khác'}, {
  'id': 'I95.9',
  'english': 'Hypotension, unspecified',
  'vietnamese': 'Hạ huyết áp, không đặc hiệu',
}, {
  'id': 'I97',
  'english': 'Postprocedural disorders of circulatory system, not elsewhere classified',
  'vietnamese': 'Rối loạn hệ tuần hoàn sau phẫu thuật, chưa phân loại nơi khác',
}, {'id': 'I97.0', 'english': 'Postcardiotomy syndrome', 'vietnamese': 'Hội chứng sau mổ tim'}, {
  'id': 'I97.1',
  'english': 'Other functional disturbances following cardiac surgery',
  'vietnamese': 'Rối loạn chức năng khác sau phẫu thuật tim',
}, {
  'id': 'I97.2',
  'english': 'Postmastectomy lymphoedema syndrome',
  'vietnamese': 'Hội chứng phù hạch bạch huyết sau cắt bỏ tuyến vú',
}, {
  'id': 'I97.8',
  'english': 'Other postprocedural disorders of circulatory system, not elsewhere classified',
  'vietnamese': 'Rối loạn hệ tuần hoàn khác sau phẫu thuật, không phân loại nơi khác',
}, {
  'id': 'I97.9',
  'english': 'Postprocedural disorder of circulatory system, unspecified',
  'vietnamese': 'Rối loạn hệ tuần hoàn sau phẫu thuật, không đặc hiệu',
}, {
  'id': 'I98*',
  'english': 'Other disorders of circulatory system in diseases classified elsewhere',
  'vietnamese': 'Rối loạn khác của hệ tuần hoàn trong bệnh phân loại nơi khác',
}, {'id': 'I98.0*', 'english': 'Cardiovascular syphilis', 'vietnamese': 'Giang mai tim mạch'}, {
  'id': 'I98.1*',
  'english': 'Cardiovascular disorders in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Rối loạn tim mạch trong bệnh nhiễm trùng và ký sinh trùng khác phân loại nơi khác',
}, {
  'id': 'I98.2*',
  'english': 'Oesophageal varices in diseases classified elsewhere',
  'vietnamese': 'Giãn tĩnh mạch thực quản không chảy máu trong bệnh phân loại nơi khác',
}, {
  'id': 'I98.3*',
  'english': 'Oesophageal varices with bleeding in diseases classified elsewhere',
  'vietnamese': 'Dãn tĩnh mạch thực quản chảy máu trong bệnh phân loại nơi khác',
}, {
  'id': 'I98.8*',
  'english': 'Other specified disorders of circulatory system in diseases classified elsewhere',
  'vietnamese': 'Rối loạn hệ tuần hoàn xác định khác trong bệnh phân loại nơi khác',
}, {
  'id': 'I99',
  'english': 'Other and unspecified disorders of circulatory system',
  'vietnamese': 'Rối loạn hệ tuần hoàn khác và không xác định của hệ tuần hoàn',
}, {
  'id': 'J00',
  'english': 'Acute nasopharyngitis [common cold]',
  'vietnamese': 'Viêm mũi họng cấp [cảm thường]',
}, {'id': 'J01', 'english': 'Acute sinusitis', 'vietnamese': 'Viêm xoang cấp'}, {
  'id': 'J01.0',
  'english': 'Acute maxillary sinusitis',
  'vietnamese': 'Viêm xoang hàm cấp',
}, {'id': 'J01.1', 'english': 'Acute frontal sinusitis', 'vietnamese': 'Viêm xoang trán cấp'}, {
  'id': 'J01.2',
  'english': 'Acute ethmoidal sinusitis',
  'vietnamese': 'Viêm xoang sàng cấp',
}, {
  'id': 'J01.3',
  'english': 'Acute sphenoidal sinusitis',
  'vietnamese': 'Viêm xoang bướm cấp',
}, {
  'id': 'J01.4',
  'english': 'Acute pansinusitis',
  'vietnamese': 'Viêm toàn bộ xoang cấp',
}, {
  'id': 'J01.8',
  'english': 'Other acute sinusitis',
  'vietnamese': 'Viêm đa xoang cấp tính khác',
}, {
  'id': 'J01.9',
  'english': 'Acute sinusitis, unspecified',
  'vietnamese': 'Viêm xoang cấp, không phân loại',
}, {'id': 'J02', 'english': 'Acute pharyngitis', 'vietnamese': 'Viêm họng cấp'}, {
  'id': 'J02.0',
  'english': 'Streptococcal pharyngitis',
  'vietnamese': 'Viêm họng do liên cầu',
}, {
  'id': 'J02.8',
  'english': 'Acute pharyngitis due to other specified organisms',
  'vietnamese': 'Viêm họng cấp do các vi sinh vật khác đã được xác định',
}, {
  'id': 'J02.9',
  'english': 'Acute pharyngitis, unspecified',
  'vietnamese': 'Viêm họng cấp, không đặc hiệu',
}, {'id': 'J03', 'english': 'Acute tonsillitis', 'vietnamese': 'Viêm amydan cấp'}, {
  'id': 'J03.0',
  'english': 'Streptococcal tonsillitis',
  'vietnamese': 'Viêm amydan do liên cầu Streptococcus',
}, {
  'id': 'J03.8',
  'english': 'Acute tonsillitis due to other specified organisms',
  'vietnamese': 'Viêm amydan cấp tính do các vi sinh vật khác đã được xác định',
}, {
  'id': 'J03.9',
  'english': 'Acute tonsillitis, unspecified',
  'vietnamese': 'Viêm amydan cấp, không phân loại',
}, {
  'id': 'J04',
  'english': 'Acute laryngitis and tracheitis',
  'vietnamese': 'Viêm thanh quản và khí quản cấp',
}, {'id': 'J04.0', 'english': 'Acute laryngitis', 'vietnamese': 'Viêm thanh quản cấp'}, {
  'id': 'J04.1',
  'english': 'Acute tracheitis',
  'vietnamese': 'Viêm khí quản cấp',
}, {
  'id': 'J04.2',
  'english': 'Acute laryngotracheitis',
  'vietnamese': 'Viêm thanh khí quản cấp',
}, {
  'id': 'J05',
  'english': 'Acute obstructive laryngitis [croup] and epiglottitis',
  'vietnamese': 'Viêm thanh quản tắc nghẽn [tắc nghẽn thanh quản] và nắp thanh môn cấp',
}, {
  'id': 'J05.0',
  'english': 'Acute obstructive laryngitis [croup]',
  'vietnamese': 'Viêm thanh quản tắc nghẽn cấp [CROUP]',
}, {'id': 'J05.1', 'english': 'Acute epiglottitis', 'vietnamese': 'Viêm nắp thanh môn cấp'}, {
  'id': 'J06',
  'english': 'Acute upper respiratory infections of multiple and unspecified sites',
  'vietnamese': 'Nhiễm trùng đường hô hấp trên cấp ở nhiều nơi và vị trí không phân loại',
}, {
  'id': 'J06.0',
  'english': 'Acute laryngopharyngitis',
  'vietnamese': 'Viêm họng - thanh quản cấp',
}, {
  'id': 'J06.8',
  'english': 'Other acute upper respiratory infections of multiple sites',
  'vietnamese': 'Các Nhiễm trùng đường hô hấp trên cấp khác ở nhiều vị trí',
}, {
  'id': 'J06.9',
  'english': 'Acute upper respiratory infection, unspecified',
  'vietnamese': 'Nhiễm trùng đường hô hấp trên cấp, không phân loại',
}, {
  'id': 'J09',
  'english': 'Influenza due to certain identified influenza virus',
  'vietnamese': 'Cúm do virus đã được định danh',
}, {
  'id': 'J10',
  'english': 'Influenza due to other identified influenza virus',
  'vietnamese': 'Cảm cúm do virus cúm được định danh khác',
}, {
  'id': 'J10.0',
  'english': 'Influenza with pneumonia, other influenza virus identified',
  'vietnamese': 'Cảm cúm với viêm phổi, virus cúm được định danh khác',
}, {
  'id': 'J10.1',
  'english': 'Influenza with other respiratory manifestations, other influenza virus identified',
  'vietnamese': 'Cảm cúm với biểu hiện hô hấp khác, virus cúm được định',
}, {
  'id': 'J10.8',
  'english': 'Influenza with other manifestations, other influenza virus identified',
  'vietnamese': 'Cảm cúm với biểu hiện khác, virus cúm được định danh khác',
}, {
  'id': 'J11',
  'english': 'Influenza, virus not identified',
  'vietnamese': 'Cúm, virus không được định danh',
}, {
  'id': 'J11.0',
  'english': 'Influenza with pneumonia, virus not identified',
  'vietnamese': 'Cúm kèm viêm phổi, virus không được định danh',
}, {
  'id': 'J11.1',
  'english': 'Influenza with other respiratory manifestations, virus not identified',
  'vietnamese': 'Cúm với các biểu hiện hô hấp khác, virus không được định danh',
}, {
  'id': 'J11.8',
  'english': 'Influenza with other manifestations, virus not identified',
  'vietnamese': 'Cúm với các biểu hiện khác, virus không được định danh',
}, {
  'id': 'J12',
  'english': 'Viral pneumonia, not elsewhere classified',
  'vietnamese': 'Viêm phổi do virus, chưa được phân loại nơi khác',
}, {'id': 'J12.0', 'english': 'Adenoviral pneumonia', 'vietnamese': 'Viêm phổi do adenovirus'}, {
  'id': 'J12.1',
  'english': 'Respiratory syncytial virus pneumonia',
  'vietnamese': 'Viêm phổi do virus hợp bào hô hấp',
}, {
  'id': 'J12.2',
  'english': 'Parainfluenza virus pneumonia',
  'vietnamese': 'Viêm phổi do virus parainfluenza',
}, {
  'id': 'J12.3',
  'english': 'Human metapneumovirus pneumonia',
  'vietnamese': 'Viêm phổi do metapneumovirus người',
}, {'id': 'J12.8', 'english': 'Other viral pneumonia', 'vietnamese': 'Viêm phổi do virus khác'}, {
  'id': 'J12.9',
  'english': 'Viral pneumonia, unspecified',
  'vietnamese': 'Viêm phổi virus, không đặc hiệu',
}, {
  'id': 'J13',
  'english': 'Pneumonia due to Streptococcus pneumoniae',
  'vietnamese': 'Viêm phổi do Streptococcus\npneumoniae',
}, {
  'id': 'J14',
  'english': 'Pneumonia due to Haemophilus influenzae',
  'vietnamese': 'Viêm phổi do Haemophilus influenzae',
}, {
  'id': 'J15',
  'english': 'Bacterial pneumonia, not elsewhere classified',
  'vietnamese': 'Viêm phổi do vi khuẩn, chưa được phân loại nơi khác',
}, {
  'id': 'J15.0',
  'english': 'Pneumonia due to Klebsiella pneumoniae',
  'vietnamese': 'Viêm phổi do Klebsiella pneumoniae',
}, {
  'id': 'J15.1',
  'english': 'Pneumonia due to Pseudomonas',
  'vietnamese': 'Viêm phổi do Pseudomonas',
}, {
  'id': 'J15.2',
  'english': 'Pneumonia due to staphylococcus',
  'vietnamese': 'Viêm phổi do tụ cầu Staphylococcus',
}, {
  'id': 'J15.3',
  'english': 'Pneumonia due to streptococcus, group B',
  'vietnamese': 'Viêm phổi do liên cầu, nhóm B',
}, {
  'id': 'J15.4',
  'english': 'Pneumonia due to other streptococci',
  'vietnamese': 'Viêm phổi do các liên cầu Streptoccoccus khác',
}, {
  'id': 'J15.5',
  'english': 'Pneumonia due to Escherichia coli',
  'vietnamese': 'Viêm phổi do Escherichia coli',
}, {
  'id': 'J15.6',
  'english': 'Pneumonia due to other aerobic Gram-negative bacteria',
  'vietnamese': 'Viêm phổi do vi khuẩn Gram (-) hiếu khí khác',
}, {
  'id': 'J15.7',
  'english': 'Pneumonia due to Mycoplasma pneumoniae',
  'vietnamese': 'Viêm phổi do Mycoplasma pneumoniae',
}, {
  'id': 'J15.8',
  'english': 'Other bacterial pneumonia',
  'vietnamese': 'Viêm phổi do vi khuẩn khác',
}, {
  'id': 'J15.9',
  'english': 'Bacterial pneumonia, unspecified',
  'vietnamese': 'Viêm phổi do vi khuẩn, không phân loại',
}, {
  'id': 'J16',
  'english': 'Pneumonia due to other infectious organisms, not elsewhere classified',
  'vietnamese': 'Viêm phổi do tác nhân nhiễm khuẩn khác, chưa được phân loại nơi khác',
}, {'id': 'J16.0', 'english': 'Chlamydial pneumonia', 'vietnamese': 'Viêm phổi do chlamydia'}, {
  'id': 'J16.8',
  'english': 'Pneumonia due to other specified infectious organisms',
  'vietnamese': 'Viêm phổi do tác nhân nhiễm khuẩn khác',
}, {
  'id': 'J17*',
  'english': 'Pneumonia in diseases classified elsewhere',
  'vietnamese': 'Viêm phổi trong các bệnh đã được phân loại nơi khác',
}, {
  'id': 'J17.0*',
  'english': 'Pneumonia in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm phổi trong các bệnh do vi khuẩn phân loại nơi khác',
}, {
  'id': 'J17.1*',
  'english': 'Pneumonia in viral diseases classified elsewhere',
  'vietnamese': 'Viêm phổi trong các bệnh do virus được phân loại nơi khác',
}, {'id': 'J17.2*', 'english': 'Pneumonia in mycoses', 'vietnamese': 'Viêm phổi trong do nấm'}, {
  'id': 'J17.3*',
  'english': 'Pneumonia in parasitic diseases',
  'vietnamese': 'Viêm phổi trong bệnh ký sinh trùng',
}, {
  'id': 'J17.8*',
  'english': 'Pneumonia in other diseases classified elsewhere',
  'vietnamese': 'Viêm phổi trong bệnh khác phân loại nơi khác',
}, {
  'id': 'J18',
  'english': 'Pneumonia, organism unspecified',
  'vietnamese': 'Viêm phổi, tác nhân không đặc hiệu',
}, {
  'id': 'J18.0',
  'english': 'Bronchopneumonia, unspecified',
  'vietnamese': 'Viêm phế quản phổi, không phân loại',
}, {
  'id': 'J18.1',
  'english': 'Lobar pneumonia, unspecified',
  'vietnamese': 'Viêm phổi thuỳ, không phân loại',
}, {
  'id': 'J18.2',
  'english': 'Hypostatic pneumonia, unspecified',
  'vietnamese': 'Viêm phổi do nằm lâu ngày, không phân loại',
}, {
  'id': 'J18.8',
  'english': 'Other pneumonia, organism unspecified',
  'vietnamese': 'Viêm phổi khác, không xác định vi sinh vật',
}, {
  'id': 'J18.9',
  'english': 'Pneumonia, unspecified',
  'vietnamese': 'Viêm phổi, không phân loại',
}, {
  'id': 'J20',
  'english': 'Acute bronchitis',
  'vietnamese': 'Viêm phế quản cấp',
}, {
  'id': 'J20.0',
  'english': 'Acute bronchitis due to Mycoplasma pneumoniae',
  'vietnamese': 'Viêm phế quản cấp do Mycoplasma pneumoniae',
}, {
  'id': 'J20.1',
  'english': 'Acute bronchitis due to Haemophilus influenzae',
  'vietnamese': 'Viêm phế quản cấp do Haemophilus lnfluenzae',
}, {
  'id': 'J20.2',
  'english': 'Acute bronchitis due to streptococcus',
  'vietnamese': 'Viêm phế quản cấp do streptococcus',
}, {
  'id': 'J20.3',
  'english': 'Acute bronchitis due to coxsackievirus',
  'vietnamese': 'Viêm phế quản cấp do virus coxsackie',
}, {
  'id': 'J20.4',
  'english': 'Acute bronchitis due to parainfluenza virus',
  'vietnamese': 'Viêm phế quản cấp do virus parainfluenza',
}, {
  'id': 'J20.5',
  'english': 'Acute bronchitis due to respiratory syncytial virus',
  'vietnamese': 'Viêm phế quản cấp do virus hợp bào hô hấp',
}, {
  'id': 'J20.6',
  'english': 'Acute bronchitis due to rhinovirus',
  'vietnamese': 'Viêm phế quản cấp do rhinovirus',
}, {
  'id': 'J20.7',
  'english': 'Acute bronchitis due to echovirus',
  'vietnamese': 'Viêm phế quản cấp do echovirus',
}, {
  'id': 'J20.8',
  'english': 'Acute bronchitis due to other specified organisms',
  'vietnamese': 'Viêm phế quản cấp do vi sinh vật khác đã được định danh',
}, {
  'id': 'J20.9',
  'english': 'Acute bronchitis, unspecified',
  'vietnamese': 'Viêm phế quản cấp, không phân loại',
}, {'id': 'J21', 'english': 'Acute bronchiolitis', 'vietnamese': 'Viêm tiểu phế quản cấp'}, {
  'id': 'J21.0',
  'english': 'Acute bronchiolitis due to respiratory syncytial virus',
  'vietnamese': 'Viêm tiểu phế quản cấp do virus hợp bào hô hấp',
}, {
  'id': 'J21.1',
  'english': 'Acute bronchiolitis due to human metapneumovirus',
  'vietnamese': 'Viêm tiểu phế quản cấp tính do metapneumovirus người',
}, {
  'id': 'J21.8',
  'english': 'Acute bronchiolitis due to other specified organisms',
  'vietnamese': 'Viêm tiểu phế quản cấp do vi sinh vật khác đã được định danh',
}, {
  'id': 'J21.9',
  'english': 'Acute bronchiolitis, unspecified',
  'vietnamese': 'Viêm tiểu phế quản cấp, không phân loại',
}, {
  'id': 'J22',
  'english': 'Unspecified acute lower respiratory infection',
  'vietnamese': 'Nhiễm trùng hô hấp dưới cấp không phân loại',
}, {
  'id': 'J30',
  'english': 'Vasomotor and allergic rhinitis',
  'vietnamese': 'Viêm mũi vận mạch và viêm mũi dị ứng',
}, {'id': 'J30.0', 'english': 'Vasomotor rhinitis', 'vietnamese': 'Viêm mũi vận mạch'}, {
  'id': 'J30.1',
  'english': 'Allergic rhinitis due to pollen',
  'vietnamese': 'Viêm mũi dị ứng phấn hoa',
}, {
  'id': 'J30.2',
  'english': 'Other seasonal allergic rhinitis',
  'vietnamese': 'Viêm mũi dị ứng theo mùa khác',
}, {'id': 'J30.3', 'english': 'Other allergic rhinitis', 'vietnamese': 'Viêm mũi dị ứng khác'}, {
  'id': 'J30.4',
  'english': 'Allergic rhinitis, unspecified',
  'vietnamese': 'Viêm mũi dị ứng, không phân loại',
}, {
  'id': 'J31',
  'english': 'Chronic rhinitis, nasopharyngitis and pharyngitis',
  'vietnamese': 'Viêm mũi, viêm mũi họng, viêm họng mãn tính',
}, {'id': 'J31.0', 'english': 'Chronic rhinitis', 'vietnamese': 'Viêm mũi mãn tính'}, {
  'id': 'J31.1',
  'english': 'Chronic nasopharyngitis',
  'vietnamese': 'Viêm mũi họng mãn tính',
}, {'id': 'J31.2', 'english': 'Chronic pharyngitis', 'vietnamese': 'Viêm họng mãn tính'}, {
  'id': 'J32',
  'english': 'Chronic sinusitis',
  'vietnamese': 'Viêm xoang mãn tính',
}, {
  'id': 'J32.0',
  'english': 'Chronic maxillary sinusitis',
  'vietnamese': 'Viêm xoang hàm mãn tính',
}, {
  'id': 'J32.1',
  'english': 'Chronic frontal sinusitis',
  'vietnamese': 'Viêm xoang trán mãn tính',
}, {
  'id': 'J32.2',
  'english': 'Chronic ethmoidal sinusitis',
  'vietnamese': 'Viêm xoang sàng mãn tính',
}, {
  'id': 'J32.3',
  'english': 'Chronic sphenoidal sinusitis',
  'vietnamese': 'Viêm xoang bướm mãn tính',
}, {'id': 'J32.4', 'english': 'Chronic pansinusitis', 'vietnamese': 'Viêm toàn bộ xoang mạn'}, {
  'id': 'J32.8',
  'english': 'Other chronic sinusitis',
  'vietnamese': 'Viêm xoang mãn tính khác',
}, {
  'id': 'J32.9',
  'english': 'Chronic sinusitis, unspecified',
  'vietnamese': 'Viêm xoang mãn tính, không phân loại',
}, {'id': 'J33', 'english': 'Nasal polyp', 'vietnamese': 'Políp mũi'}, {
  'id': 'J33.0',
  'english': 'Polyp of nasal cavity',
  'vietnamese': 'Políp mũi',
}, {
  'id': 'J33.1',
  'english': 'Polypoid sinus degeneration',
  'vietnamese': 'thoái hóa xoang dạng políp',
}, {'id': 'J33.8', 'english': 'Other polyp of sinus', 'vietnamese': 'Políp của xoang khác'}, {
  'id': 'J33.9',
  'english': 'Nasal polyp, unspecified',
  'vietnamese': 'Políp mũi, không phân loại',
}, {
  'id': 'J34',
  'english': 'Other disorders of nose and nasal sinuses',
  'vietnamese': 'Bệnh khác của mũi và xoang',
}, {
  'id': 'J34.0',
  'english': 'Abscess, furuncle and carbuncle of nose',
  'vietnamese': 'Áp xe, nhọt và nhọt tiền đình mũi',
}, {
  'id': 'J34.1',
  'english': 'Cyst and mucocele of nose and nasal sinus',
  'vietnamese': 'U nang và u nhày của mũi và xoang mũi',
}, {'id': 'J34.2', 'english': 'Deviated nasal septum', 'vietnamese': 'Lệch vách mũi'}, {
  'id': 'J34.3',
  'english': 'Hypertrophy of nasal turbinates',
  'vietnamese': 'Phì đại cuốn mũi',
}, {
  'id': 'J34.8',
  'english': 'Other specified disorders of nose and nasal sinuses',
  'vietnamese': 'Bệnh xác định khác của mũi và xoang',
}, {
  'id': 'J35',
  'english': 'Chronic diseases of tonsils and adenoids',
  'vietnamese': 'Bệnh mãn tính của amydan và sùi dạng tuyến',
}, {'id': 'J35.0', 'english': 'Chronic tonsillitis', 'vietnamese': 'Viêm amydan mãn tính'}, {
  'id': 'J35.1',
  'english': 'Hypertrophy of tonsils',
  'vietnamese': 'amydan quá phát',
}, {'id': 'J35.2', 'english': 'Hypertrophy of adenoids', 'vietnamese': 'Phì đại V.A'}, {
  'id': 'J35.3',
  'english': 'Hypertrophy of tonsils with hypertrophy of adenoids',
  'vietnamese': 'Phì đại amydan',
}, {
  'id': 'J35.8',
  'english': 'Other chronic diseases of tonsils and adenoids',
  'vietnamese': 'Bệnh mãn tính khác của amydan và V.A',
}, {
  'id': 'J35.9',
  'english': 'Chronic disease of tonsils and adenoids, unspecified',
  'vietnamese': 'Bệnh mãn tính của amydan và V.A, không đặc hiệu',
}, {'id': 'J36', 'english': 'Peritonsilllar abscess', 'vietnamese': 'Áp xe quanh amydan'}, {
  'id': 'J37',
  'english': 'Chronic laryngitis and laryngotracheitis',
  'vietnamese': 'Viêm thanh quản và viêm thanh khí quản mãn tính',
}, {'id': 'J37.0', 'english': 'Chronic laryngitis', 'vietnamese': 'Viêm thanh quản mãn tính'}, {
  'id': 'J37.1',
  'english': 'Chronic laryngotracheitis',
  'vietnamese': 'Viêm thanh khí quản mạn',
}, {
  'id': 'J38',
  'english': 'Diseases of vocal cords and larynx, not elsewhere classified',
  'vietnamese': 'Bệnh lý của dây thanh âm và thanh quản, không phân loại nơi khác',
}, {
  'id': 'J38.0',
  'english': 'Paralysis of vocal cords and larynx',
  'vietnamese': 'Liệt dây thanh âm và thanh quản',
}, {
  'id': 'J38.1',
  'english': 'Polyp of vocal cord and larynx',
  'vietnamese': 'Polyp của dây thanh âm và thanh quản',
}, {'id': 'J38.2', 'english': 'Nodules of vocal cords', 'vietnamese': 'Nốt nhỏ ở dây thanh âm'}, {
  'id': 'J38.3',
  'english': 'Other diseases of vocal cords',
  'vietnamese': 'Các bệnh lý khác của dây thanh âm',
}, {'id': 'J38.4', 'english': 'Oedema of larynx', 'vietnamese': 'Phù thanh quản'}, {
  'id': 'J38.5',
  'english': 'Laryngeal spasm',
  'vietnamese': 'Co thắt thanh quản',
}, {'id': 'J38.6', 'english': 'Stenosis of larynx', 'vietnamese': 'Hẹp thanh quản'}, {
  'id': 'J38.7',
  'english': 'Other diseases of larynx',
  'vietnamese': 'Các bệnh lý khác của thanh quản',
}, {
  'id': 'J39',
  'english': 'Other diseases of upper respiratory tract',
  'vietnamese': 'Các bệnh khác của đường hô hấp trên',
}, {
  'id': 'J39.0',
  'english': 'Retropharyngeal and parapharyngeal abscess',
  'vietnamese': 'Áp xe sau họng và áp xe cận họng',
}, {'id': 'J39.1', 'english': 'Other abscess of pharynx', 'vietnamese': 'Áp xe khác của họng'}, {
  'id': 'J39.2',
  'english': 'Other diseases of pharynx',
  'vietnamese': 'Bệnh khác của họng',
}, {
  'id': 'J39.3',
  'english': 'Upper respiratory tract hypersensitivity reaction, site unspecified',
  'vietnamese': 'Phản ứng quá mẫn đường hô hấp trên, vị trí không xác định',
}, {
  'id': 'J39.8',
  'english': 'Other specified diseases of upper respiratory tract',
  'vietnamese': 'Các bệnh lý xác định khác của đường hô hấp trên',
}, {
  'id': 'J39.9',
  'english': 'Disease of upper respiratory tract, unspecified',
  'vietnamese': 'Bệnh của đường hô hấp trên, không đặc hiệu',
}, {
  'id': 'J40',
  'english': 'Bronchitis, not specified as acute or chronic',
  'vietnamese': 'Viêm phế quản không xác định được là cấp hay mãn tính',
}, {
  'id': 'J41',
  'english': 'Simple and mucopurulent chronic bronchitis',
  'vietnamese': 'Viêm phế quản mãn tính đơn thuần và nhầy mủ',
}, {
  'id': 'J41.0',
  'english': 'Simple chronic bronchitis',
  'vietnamese': 'Viêm phế quản mãn tính đơn thuần',
}, {
  'id': 'J41.1',
  'english': 'Mucopurulent chronic bronchitis',
  'vietnamese': 'Viêm phế quản mãn tính nhầy mủ',
}, {
  'id': 'J41.8',
  'english': 'Mixed simple and mucopurulent chronic bronchitis',
  'vietnamese': 'Viêm phế quản mãn tính hỗn hợp (đơn thuần và nhầy mủ)',
}, {
  'id': 'J42',
  'english': 'Unspecified chronic bronchitis',
  'vietnamese': 'Viêm phế quản mãn tính không phân loại',
}, {'id': 'J43', 'english': 'Emphysema', 'vietnamese': 'Giãn phế nang'}, {
  'id': 'J43.0',
  'english': 'MacLeod s syndrome',
  'vietnamese': 'Hội chứng MacLeod',
}, {
  'id': 'J43.1',
  'english': 'Panlobular emphysema',
  'vietnamese': 'Giãn phế nang toàn tiểu thuỳ',
}, {
  'id': 'J43.2',
  'english': 'Centrilobular emphysema',
  'vietnamese': 'Giãn phế nang trung tâm tiểu thuỳ',
}, {'id': 'J43.8', 'english': 'Other emphysema', 'vietnamese': 'Giãn phế nang khác'}, {
  'id': 'J43.9',
  'english': 'Emphysema, unspecified',
  'vietnamese': 'Giãn phế nang, không đặc hiệu',
}, {
  'id': 'J44',
  'english': 'Other chronic obstructive pulmonary disease',
  'vietnamese': 'Các bệnh phổi tắc nghẽn mãn tính khác',
}, {
  'id': 'J44.0',
  'english': 'Chronic obstructive pulmonary disease with acute lower respiratory infection',
  'vietnamese': 'Bệnh phổi tắc nghẽn mãn tính đợt cấp do bội nhiễm',
}, {
  'id': 'J44.1',
  'english': 'Chronic obstructive pulmonary disease with acute exacerbation, unspecified',
  'vietnamese': 'Bệnh phổi tắc nghẽn mãn tính đợt cấp, không phân loại',
}, {
  'id': 'J44.8',
  'english': 'Other specified chronic obstructive pulmonary disease',
  'vietnamese': 'Bệnh phổi tắc nghẽn mãn tính, phân loại khác',
}, {
  'id': 'J44.9',
  'english': 'Chronic obstructive pulmonary disease, unspecified',
  'vietnamese': 'Bệnh phổi tắc nghẽn mãn tính, không phân loại',
}, {'id': 'J45', 'english': 'Asthma', 'vietnamese': 'Hen [suyễn]'}, {
  'id': 'J45.0',
  'english': 'Predominantly allergic asthma',
  'vietnamese': 'Hen chủ yếu do dị ứng',
}, {'id': 'J45.1', 'english': 'Nonallergic asthma', 'vietnamese': 'Hen không dị ứng'}, {
  'id': 'J45.8',
  'english': 'Mixed asthma',
  'vietnamese': 'Hen hỗn hợp',
}, {'id': 'J45.9', 'english': 'Asthma, unspecified', 'vietnamese': 'Hen, không phân loại'}, {
  'id': 'J46',
  'english': 'Status asthmaticus',
  'vietnamese': 'Cơn hen ác tính',
}, {'id': 'J47', 'english': 'Bronchiectasis', 'vietnamese': 'Dãn phế quản'}, {
  'id': 'J60',
  'english': 'Coalworker pneumoconsiosis',
  'vietnamese': 'Bệnh bụi phổi của công nhân ngành than',
}, {
  'id': 'J61',
  'english': 'Pneumoconiosis due to asbestos and other mineral fibres',
  'vietnamese': 'Bệnh bụi phổi amian và các sợi khóang khác',
}, {
  'id': 'J62',
  'english': 'Pneumoconiosis due to dust containing silica',
  'vietnamese': 'Bệnh bụi phổi do bụi silic',
}, {
  'id': 'J62.0',
  'english': 'Pneumoconiosis due to talc dust',
  'vietnamese': 'Bệnh bụi phổi do bột talc',
}, {
  'id': 'J62.8',
  'english': 'Pneumoconiosis due to other dust containing silica',
  'vietnamese': 'Bệnh bụi phổi do bụi khác chứa silic',
}, {
  'id': 'J63',
  'english': 'Pneumoconiosis due to other inorganic dusts',
  'vietnamese': 'Bệnh bụi phổi do bụi vô cơ khác',
}, {
  'id': 'J63.0',
  'english': 'Aluminosis (of lung)',
  'vietnamese': 'Nhiễm bụi nhôm (của phổi)',
}, {
  'id': 'J63.1',
  'english': 'Bauxite fibrosis (of lung)',
  'vietnamese': 'Xơ hóa do quặng bô- xít (của phổi)',
}, {'id': 'J63.2', 'english': 'Berylliosis', 'vietnamese': 'Bụi phổi do beryllium'}, {
  'id': 'J63.3',
  'english': 'Graphite fibrosis (of lung)',
  'vietnamese': 'Xơ phổi do than chì (của phổi)',
}, {'id': 'J63.4', 'english': 'Siderosis', 'vietnamese': 'Bệnh xơ phổi do sắt'}, {
  'id': 'J63.5',
  'english': 'Stannosis',
  'vietnamese': 'Bệnh xơ phổi do thiếc',
}, {
  'id': 'J63.8',
  'english': 'Pneumoconiosis due to other specified inorganic dusts',
  'vietnamese': 'Bệnh phổi nghề nghiệp do bụi phổi vô cơ xác định khác',
}, {
  'id': 'J64',
  'english': 'Unspecified pneumoconiosis',
  'vietnamese': 'Bệnh bụi phổi không phân loại',
}, {
  'id': 'J65',
  'english': 'Pneumoconiosis associated with tuberculosis',
  'vietnamese': 'Bệnh bụi phổi kết hợp với lao',
}, {
  'id': 'J66',
  'english': 'Airway disease due to specific organic dust',
  'vietnamese': 'Bệnh đường dẫn khí do bụi hữu cơ đặc biệt khác',
}, {'id': 'J66.0', 'english': 'Byssinosis', 'vietnamese': 'Bụi phổi bông'}, {
  'id': 'J66.1',
  'english': 'Flax-dresser s disease',
  'vietnamese': 'Bệnh Flax - Dresser',
}, {'id': 'J66.2', 'english': 'Cannabinosis', 'vietnamese': 'Bệnh đường hô hấp do ma túy'}, {
  'id': 'J66.8',
  'english': 'Airway disease due to other specific organic dusts',
  'vietnamese': 'Bệnh đường dẫn bụi khí do bụi hữu cơ đặc biệt khác',
}, {
  'id': 'J67',
  'english': 'Hypersensitivity pneumonitis due to organic dust',
  'vietnamese': 'Viêm phổi tăng cảm do bụi hữu cơ',
}, {'id': 'J67.0', 'english': 'Farmer s lung', 'vietnamese': 'Bệnh phổi của người nông dân'}, {
  'id': 'J67.1',
  'english': 'Bagassosis',
  'vietnamese': 'Bệnh phổi do bã mía',
}, {
  'id': 'J67.2',
  'english': 'Bird fancier s lung',
  'vietnamese': 'Bệnh phổi ở người nuôi chim',
}, {
  'id': 'J67.3',
  'english': 'Suberosis',
  'vietnamese': 'Bệnh phổi do xơ cây bần',
}, {
  'id': 'J67.4',
  'english': 'Maltworker s lung',
  'vietnamese': 'Bệnh phổi của công nhân tiếp xúc với mạch nha bị mốc',
}, {
  'id': 'J67.5',
  'english': 'Mushroom-worker s lung',
  'vietnamese': 'Bệnh phổi của công nhân trồng nấm',
}, {
  'id': 'J67.6',
  'english': 'Maple-bark-stripper s lung',
  'vietnamese': 'Bệnh phổi của công nhân bóc vỏ cây thích',
}, {
  'id': 'J67.7',
  'english': 'Air-conditioner and humidifier lung',
  'vietnamese': 'Bệnh phổi do máy làm ẩm và máy điều hoà không khí',
}, {
  'id': 'J67.8',
  'english': 'Hypersensitivity pneumonitis due to other organic dusts',
  'vietnamese': 'Viêm phổi tăng cảm do bụi hữu cơ khác',
}, {
  'id': 'J67.9',
  'english': 'Hypersensitivity pneumonitis due to unspecified organic dust',
  'vietnamese': 'Viêm phổi tăng cảm do bụi hữu cơ không phân loại',
}, {
  'id': 'J68',
  'english': 'Respiratory conditions due to inhalation of chemicals, gases, fumes and vapours',
  'vietnamese': 'Bệnh hô hấp do hít hóa chất, khí, khói và chất bay hơi',
}, {
  'id': 'J68.0',
  'english': 'Bronchitis and pneumonitis due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Viêm phế quản và viêm phổi do hóa chất, chất khí, khói và chất bay hơi',
}, {
  'id': 'J68.1',
  'english': 'Pulmonary oedema due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Phù phổi do hóa chất, khí, khói và chất bay hơi',
}, {
  'id': 'J68.2',
  'english': 'Upper respiratory inflammation due to chemicals, gases, fumes and vapours, not elsewhere classified',
  'vietnamese': 'Viêm đường hô hấp trên do hóa chất, khí, khói và chất bay hơi, chưa phân loại nơi khác',
}, {
  'id': 'J68.3',
  'english': 'Other acute and subacute respiratory conditions due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Bệnh hô hấp cấp và bán cấp khác do hóa chất, khí, khói và chất bay hơi',
}, {
  'id': 'J68.4',
  'english': 'Chronic respiratory conditions due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Bệnh hô hấp mạn do hóa chât, khí, khói và chất bay hơi',
}, {
  'id': 'J68.8',
  'english': 'Other respiratory conditions due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Bệnh hô hấp khác do hóa chất, khí, khói và chất bay hơi',
}, {
  'id': 'J68.9',
  'english': 'Unspecified respiratory condition due to chemicals, gases, fumes and vapours',
  'vietnamese': 'Bệnh hô hấp không phân loại do hóa chất, khí, khói và chất bay hơi',
}, {
  'id': 'J69',
  'english': 'Pneumonitis due to solids and liquids',
  'vietnamese': 'Viêm phổi do chất rắn và chất lỏng',
}, {
  'id': 'J69.0',
  'english': 'Pneumonitis due to food and vomit',
  'vietnamese': 'Viêm phổi hít phải thức ăn và chất nôn',
}, {
  'id': 'J69.1',
  'english': 'Pneumonitis due to oils and essences',
  'vietnamese': 'Viêm phổi hít phải dầu và hương liệu',
}, {
  'id': 'J69.8',
  'english': 'Pneumonitis due to other solids and liquids',
  'vietnamese': 'Viêm phổi hít phải chất rắn và chất lỏng khác',
}, {
  'id': 'J70',
  'english': 'Respiratory conditions due to other external agents',
  'vietnamese': 'Bệnh hô hấp do tác nhân bên ngoài khác',
}, {
  'id': 'J70.0',
  'english': 'Acute pulmonary manifestations due to radiation',
  'vietnamese': 'Biểu hiện cấp tính ở phổi do phóng xạ',
}, {
  'id': 'J70.1',
  'english': 'Chronic and other pulmonary manifestations due to radiation',
  'vietnamese': 'Biểu hiện mãn tính và biểu hiện khác ở phổi do phóng xạ',
}, {
  'id': 'J70.2',
  'english': 'Acute drug-induced interstitial lung disorders',
  'vietnamese': 'Bệnh phổi mô kẽ cấp tính do thuốc',
}, {
  'id': 'J70.3',
  'english': 'Chronic drug-induced interstitial lung disorders',
  'vietnamese': 'Bệnh phổi mô kẽ mãn tính do thuốc',
}, {
  'id': 'J70.4',
  'english': 'Drug-induced interstitial lung disorders, unspecified',
  'vietnamese': 'Bệnh phổi mô kẽ do thuốc, không phân loại',
}, {
  'id': 'J70.8',
  'english': 'Respiratory conditions due to other specified external agents',
  'vietnamese': 'Bệnh lý hô hấp do tác nhân bên ngoài xác định khác',
}, {
  'id': 'J70.9',
  'english': 'Respiratory conditions due to unspecified external agent',
  'vietnamese': 'Bệnh lý hô hấp do các tác nhân bên ngoài không phân loại',
}, {
  'id': 'J80',
  'english': 'Adult respiratory distress syndrome',
  'vietnamese': 'Hội chứng suy hô hấp ở người lớn',
}, {'id': 'J81', 'english': 'Pulmonary oedema', 'vietnamese': 'Phù phổi'}, {
  'id': 'J82',
  'english': 'Pulmonary eosinophilia, not elsewhere classifed',
  'vietnamese': 'Tăng bạch cầu ái toan ở phổi, chưa được phân loại ở nơi khác',
}, {
  'id': 'J84',
  'english': 'Other interstitial pulmonary diseases',
  'vietnamese': 'Bệnh phổi mô kẽ khác',
}, {
  'id': 'J84.0',
  'english': 'Alveolar and parietoalveolar conditions',
  'vietnamese': 'Bệnh lý phế nang và thành phế nang',
}, {
  'id': 'J84.1',
  'english': 'Other interstitial pulmonary diseases with fibrosis',
  'vietnamese': 'Bệnh phổi mô kẽ khác có xơ',
}, {
  'id': 'J84.8',
  'english': 'Other specified interstitial pulmonary diseases',
  'vietnamese': 'Bệnh phổi mô kẽ xác định khác',
}, {
  'id': 'J84.9',
  'english': 'Interstitial pulmonary disease, unspecified',
  'vietnamese': 'Bệnh phổi mô kẽ, không phân loại',
}, {
  'id': 'J85',
  'english': 'Abscess of lung and mediastinum',
  'vietnamese': 'Áp xe phổi và trung thất',
}, {
  'id': 'J85.0',
  'english': 'Gangrene and necrosis of lung',
  'vietnamese': 'Hoại thư và hoại tử ở phổi',
}, {
  'id': 'J85.1',
  'english': 'Abscess of lung with pneumonia',
  'vietnamese': 'Áp xe phổi có viêm phổi',
}, {
  'id': 'J85.2',
  'english': 'Abscess of lung without pneumonia',
  'vietnamese': 'Áp xe phổi không có viêm phổi',
}, {'id': 'J85.3', 'english': 'Abscess of mediastinum', 'vietnamese': 'Áp xe trung thất'}, {
  'id': 'J86',
  'english': 'Pyothorax',
  'vietnamese': 'Mủ lồng ngực',
}, {'id': 'J86.0', 'english': 'Pyothorax with fistula', 'vietnamese': 'Mủ lồng ngực có lỗ rò'}, {
  'id': 'J86.9',
  'english': 'Pyothorax without fistula',
  'vietnamese': 'Mủ lồng ngực không có lỗ rò',
}, {
  'id': 'J90',
  'english': 'Pleural effusion, not elsewhere classified',
  'vietnamese': 'Tràn dịch màng phổi, không phân loại nơi khác',
}, {
  'id': 'J91*',
  'english': 'Pleural effusion incondition classified elsewhere',
  'vietnamese': 'Tràn dịch màng phổi trong bệnh phân loại nơi khác',
}, {'id': 'J92', 'english': 'Pleural plaque', 'vietnamese': 'Mảng màng phổi'}, {
  'id': 'J92.0',
  'english': 'Pleural plaque with presence of asbestos',
  'vietnamese': 'Mảng màng phổi do amian',
}, {
  'id': 'J92.9',
  'english': 'Pleural plaque without asbestos',
  'vietnamese': 'Mảng màng phổi không do amian',
}, {'id': 'J93', 'english': 'Pneumothorax', 'vietnamese': 'Tràn khí màng phổi'}, {
  'id': 'J93.0',
  'english': 'Spontaneous tension pneumothorax',
  'vietnamese': 'Tràn khí màng phổi tự phát có van',
}, {
  'id': 'J93.1',
  'english': 'Other spontaneous pneumothorax',
  'vietnamese': 'Các dạng tràn khí màng phổi tự phát khác',
}, {
  'id': 'J93.8',
  'english': 'Other pneumothorax',
  'vietnamese': 'Các dạng tràn khí màng phổi khác',
}, {
  'id': 'J93.9',
  'english': 'Pneumothorax, unspecified',
  'vietnamese': 'Tràn khí màng phổi, không phân loại',
}, {
  'id': 'J94',
  'english': 'Other pleural conditions',
  'vietnamese': 'Các bệnh màng phổi khác',
}, {
  'id': 'J94.0',
  'english': 'Chylous effusion',
  'vietnamese': 'Tràn dịch dưỡng trấp',
}, {'id': 'J94.1', 'english': 'Fibrothorax', 'vietnamese': 'Xơ hóa màng phổi'}, {
  'id': 'J94.2',
  'english': 'Haemothorax',
  'vietnamese': 'Tràn máu màng phổi',
}, {
  'id': 'J94.8',
  'english': 'Other specified pleural conditions',
  'vietnamese': 'Các bệnh màng phổi xác định khác',
}, {
  'id': 'J94.9',
  'english': 'Pleural condition, unspecified',
  'vietnamese': 'Bệnh màng phổi, không phân loại',
}, {
  'id': 'J95',
  'english': 'Postprocedural respiratory disorders, not elsewhere classified',
  'vietnamese': 'Các bệnh hô hấp sau can thiệp thủ thuật, chưa phân loại nơi khác',
}, {
  'id': 'J95.0',
  'english': 'Tracheostomy malfunction',
  'vietnamese': 'Rối loạn chức năng sau khi mở khí quản',
}, {
  'id': 'J95.1',
  'english': 'Acute pulmonary insufficiency following thoracic surgery',
  'vietnamese': 'Hở van động mạch phổi cấp tính sau phẫu thuật lồng ngực',
}, {
  'id': 'J95.2',
  'english': 'Acute pulmonary insufficiency following nonthoracic surgery',
  'vietnamese': 'Hở van động mạch phổi cấp tính sau phẫu thuật ngoài lồng ngực',
}, {
  'id': 'J95.3',
  'english': 'Chronic pulmonary insufficiency following surgery',
  'vietnamese': 'Hở van động mạch phổi mãn tính sau phẫu thuật',
}, {'id': 'J95.4', 'english': 'Mendelson s syndrome', 'vietnamese': 'Hội chứng Mendelson'}, {
  'id': 'J95.5',
  'english': 'Postprocedural subglottic stenosis',
  'vietnamese': 'Hẹp dưới thanh môn sau phẫu thuật',
}, {
  'id': 'J95.8',
  'english': 'Other postprocedural respiratory disorders',
  'vietnamese': 'Các bệnh hô hấp sau phẫu thuật khác',
}, {
  'id': 'J95.9',
  'english': 'Postprocedural respiratory disorder, unspecified',
  'vietnamese': 'Rối loạn hô hấp sau phẫu thuật, không đặc hiệu',
}, {
  'id': 'J96',
  'english': 'Respiratory failure, not elsewhere classified',
  'vietnamese': 'Suy hô hấp không phân loại nơi khác',
}, {'id': 'J96.0', 'english': 'Acute respiratory failure', 'vietnamese': 'Suy hô hấp cấp'}, {
  'id': 'J96.1',
  'english': 'Chronic respiratory failure',
  'vietnamese': 'Suy hô hấp mạn',
}, {
  'id': 'J96.9',
  'english': 'Respiratory failure, unspecified',
  'vietnamese': 'Suy hô hấp, không phân loại',
}, {
  'id': 'J98',
  'english': 'Other respiratory disorders',
  'vietnamese': 'Các bệnh hô hấp khác',
}, {
  'id': 'J98.0',
  'english': 'Diseases of bronchus, not elsewhere classified',
  'vietnamese': 'Bệnh phế quản, không phân loại nơi khác',
}, {'id': 'J98.1', 'english': 'Pulmonary collapse', 'vietnamese': 'Xẹp phổi'}, {
  'id': 'J98.2',
  'english': 'Interstitial emphysema',
  'vietnamese': 'Giãn phế nang mô kẽ',
}, {'id': 'J98.3', 'english': 'Compensatory emphysema', 'vietnamese': 'Giãn phế nang còn bù'}, {
  'id': 'J98.4',
  'english': 'Other disorders of lung',
  'vietnamese': 'Các Bệnh khác của phổi',
}, {
  'id': 'J98.5',
  'english': 'Diseases of mediastinum, not elsewhere classified',
  'vietnamese': 'Bệnh của trung thất, không phân loại nơi khác',
}, {'id': 'J98.6', 'english': 'Disorders of diaphragm', 'vietnamese': 'Bệnh của cơ hoành'}, {
  'id': 'J98.8',
  'english': 'Other specified respiratory disorders',
  'vietnamese': 'Bệnh hô hấp xác định khác',
}, {
  'id': 'J98.9',
  'english': 'Respiratory disorder, unspecified',
  'vietnamese': 'Bệnh hô hấp, không phân loại',
}, {
  'id': 'J99*',
  'english': 'Respiratory disorders in diseases classified elsewhere',
  'vietnamese': 'Bệnh hô hấp trong các bệnh được phân loại nơi khác',
}, {
  'id': 'J99.0*',
  'english': 'Rheumatoid lung disease (M05.1†)',
  'vietnamese': 'Bệnh phổi dạng thấp (M05.1†)',
}, {
  'id': 'J99.1*',
  'english': 'Respiratory disorders in other diffuse connective tissue disorders',
  'vietnamese': 'Bệnh hô hấp trong các bệnh mô liên kết lan toả khác',
}, {
  'id': 'J99.8*',
  'english': 'Respiratory disorders in other diseases classified elsewhere',
  'vietnamese': 'Bệnh hô hấp trong các bệnh được phân loại nơi khác',
}, {
  'id': 'K00',
  'english': 'Disorders of tooth development and eruption',
  'vietnamese': 'Rối loạn phát triển răng và mọc răng',
}, {'id': 'K00.0', 'english': 'Anodontia', 'vietnamese': 'Không đủ răng'}, {
  'id': 'K00.1',
  'english': 'Supernumerary teeth',
  'vietnamese': 'Răng thừa',
}, {
  'id': 'K00.2',
  'english': 'Abnormalities of size and form of teeth',
  'vietnamese': 'Bất thường kích thước và hình dạng răng',
}, {'id': 'K00.3', 'english': 'Mottled teeth', 'vietnamese': 'Răng lốm đốm'}, {
  'id': 'K00.4',
  'english': 'Disturbances in tooth formation',
  'vietnamese': 'Rối loạn tạo răng',
}, {
  'id': 'K00.5',
  'english': 'Hereditary disturbances in tooth structure, not elsewhere classified',
  'vietnamese': 'Rối loạn di truyền cấu trúc răng, không phân loại nơi khác',
}, {
  'id': 'K00.6',
  'english': 'Disturbances in tooth eruption',
  'vietnamese': 'Rối loạn mọc răng',
}, {
  'id': 'K00.7',
  'english': 'Teething syndrome',
  'vietnamese': 'Hội chứng mọc răng',
}, {
  'id': 'K00.8',
  'english': 'Other disorders of tooth development',
  'vietnamese': 'Rối loạn khác về phát triển răng',
}, {
  'id': 'K00.9',
  'english': 'Disorder of tooth development, unspecified',
  'vietnamese': 'Rối loạn phát triển răng, không đặc hiệu',
}, {
  'id': 'K01',
  'english': 'Embedded and impacted teeth',
  'vietnamese': 'Răng mọc kẹt và răng ngầm',
}, {'id': 'K01.0', 'english': 'Embedded teeth', 'vietnamese': 'Răng ngầm'}, {
  'id': 'K01.1',
  'english': 'Impacted teeth',
  'vietnamese': 'Răng mọc kẹt',
}, {'id': 'K02', 'english': 'Dental caries', 'vietnamese': 'Sâu răng'}, {
  'id': 'K02.0',
  'english': 'Caries limited to enamel',
  'vietnamese': 'Sâu giới hạn ở men',
}, {'id': 'K02.1', 'english': 'Caries of dentine', 'vietnamese': 'Sâu ngà'}, {
  'id': 'K02.2',
  'english': 'Caries of cementum',
  'vietnamese': 'Sâu chất xương',
}, {
  'id': 'K02.3',
  'english': 'Arrested dental caries',
  'vietnamese': 'Sâu răng ngưng tiến triển',
}, {
  'id': 'K02.4',
  'english': 'Odontoclasia',
  'vietnamese': 'Hủy răng',
}, {'id': 'K02.5', 'english': 'Caries with pulp exposure', 'vietnamese': 'Sâu răng với hở tủy'}, {
  'id': 'K02.8',
  'english': 'Other dental caries',
  'vietnamese': 'Sâu răng khác',
}, {
  'id': 'K02.9',
  'english': 'Dental caries, unspecified',
  'vietnamese': 'Sâu răng, không đặc hiệu',
}, {
  'id': 'K03',
  'english': 'Other diseases of hard tissues of teeth',
  'vietnamese': 'Bệnh mô cứng khác của răng',
}, {'id': 'K03.0', 'english': 'Excessive attrition of teeth', 'vietnamese': 'Mòn răng quá mức'}, {
  'id': 'K03.1',
  'english': 'Abrasion of teeth',
  'vietnamese': 'Mòn răng',
}, {'id': 'K03.2', 'english': 'Erosion of teeth', 'vietnamese': 'Mòn răng'}, {
  'id': 'K03.3',
  'english': 'Pathological resorption of teeth',
  'vietnamese': 'Tiêu răng bệnh lý',
}, {'id': 'K03.4', 'english': 'Hypercementosis', 'vietnamese': 'Tăng sinh chất răng'}, {
  'id': 'K03.5',
  'english': 'Ankylosis of teeth',
  'vietnamese': 'Cứng khớp răng',
}, {
  'id': 'K03.6',
  'english': 'Deposits [accretions] on teeth',
  'vietnamese': 'Cặn lắng [tăng tích tụ] trên răng',
}, {
  'id': 'K03.7',
  'english': 'Posteruptive colour changes of dental hard tissues',
  'vietnamese': 'Biến màu mô cứng sau gẫy răng',
}, {
  'id': 'K03.8',
  'english': 'Other specified diseases of hard tissues of teeth',
  'vietnamese': 'Bệnh xác định khác của mô cứng của răng',
}, {
  'id': 'K03.9',
  'english': 'Disease of hard tissues of teeth, unspecified',
  'vietnamese': 'Bệnh mô cứng của răng, không đặc hiệu',
}, {
  'id': 'K04',
  'english': 'Diseases of pulp and periapical tissues',
  'vietnamese': 'Bệnh tủy và mô quanh chân răng',
}, {'id': 'K04.0', 'english': 'Pulpitis', 'vietnamese': 'Viêm tủy'}, {
  'id': 'K04.1',
  'english': 'Necrosis of pulp',
  'vietnamese': 'Hoại tử tủy',
}, {'id': 'K04.2', 'english': 'Pulp degeneration', 'vietnamese': 'thoái hóa tủy'}, {
  'id': 'K04.3',
  'english': 'Abnormal hard tissue formation in pulp',
  'vietnamese': 'Tạo mô cứng bất thường trong tủy',
}, {
  'id': 'K04.4',
  'english': 'Acute apical periodontitis of pulpal origin',
  'vietnamese': 'Viêm nha chu chân răng cấp có nguồn gốc tủy',
}, {
  'id': 'K04.5',
  'english': 'Chronic apical periodontitis',
  'vietnamese': 'Viêm nha chu chân răng mãn',
}, {
  'id': 'K04.6',
  'english': 'Periapical abscess with sinus',
  'vietnamese': 'áp xe quanh chân răng có ổ',
}, {
  'id': 'K04.7',
  'english': 'Periapical abscess without sinus',
  'vietnamese': 'áp xe quanh chân răng không có ổ',
}, {'id': 'K04.8', 'english': 'Radicular cyst', 'vietnamese': 'Nang có cuống'}, {
  'id': 'K04.9',
  'english': 'Other and unspecified diseases of pulp and periapical tissues',
  'vietnamese': 'Bệnh tủy và mô quanh chân răng khác và không xác định',
}, {
  'id': 'K05',
  'english': 'Gingivitis and periodontal diseases',
  'vietnamese': 'Viêm nướu và bệnh nha chu',
}, {'id': 'K05.0', 'english': 'Acute gingivitis', 'vietnamese': 'Viêm nướu cấp'}, {
  'id': 'K05.1',
  'english': 'Chronic gingivitis',
  'vietnamese': 'Viêm nướu mãn',
}, {'id': 'K05.2', 'english': 'Acute periodontitis', 'vietnamese': 'Viêm nha chu cấp'}, {
  'id': 'K05.3',
  'english': 'Chronic periodontitis',
  'vietnamese': 'Viêm nha chu mãn',
}, {'id': 'K05.4', 'english': 'Periodontosis', 'vietnamese': 'thoái hóa nha chu'}, {
  'id': 'K05.5',
  'english': 'Other periodontal diseases',
  'vietnamese': 'Bệnh nha chu',
}, {
  'id': 'K05.6',
  'english': 'Periodontal disease, unspecified',
  'vietnamese': 'Bệnh nha chu, không đặc hiệu',
}, {
  'id': 'K06',
  'english': 'Other disorders of gingiva and edentulous alveolar ridge',
  'vietnamese': 'Rối loạn khác của nướu và sóng hàm vùng mất răng',
}, {'id': 'K06.0', 'english': 'Gingival recession', 'vietnamese': 'Tụt lợi răng'}, {
  'id': 'K06.1',
  'english': 'Gingival enlargement',
  'vietnamese': 'Lợi sưng',
}, {
  'id': 'K06.2',
  'english': 'Gingival and edentulous alveolar ridge lesions associated with trauma',
  'vietnamese': 'Chấn thương nướu răng và nang sống hàm liên quan đến chấn thương',
}, {
  'id': 'K06.8',
  'english': 'Other specified disorders of gingiva and edentulous alveolar ridge',
  'vietnamese': 'Rối loạn đặc hiệu khác của nướu và sống hàm',
}, {
  'id': 'K06.9',
  'english': 'Disorder of gingiva and edentulous alveolar ridge, unspecified',
  'vietnamese': 'Rối loạn ở nướu và sóng hàm, không đặc hiệu',
}, {
  'id': 'K07',
  'english': 'Dentofacial anomalies [including malocclusion]',
  'vietnamese': 'Bất thường hàm mặt [bao gồm khớp cắn lệch]',
}, {
  'id': 'K07.0',
  'english': 'Major anomalies of jaw size',
  'vietnamese': 'Bất thường chủ yếu của kích thước xương hàm',
}, {
  'id': 'K07.1',
  'english': 'Anomalies of jaw-cranial base relationship',
  'vietnamese': 'Bất thường có liên quan nền sọ xương hàm',
}, {
  'id': 'K07.2',
  'english': 'Anomalies of dental arch relationship',
  'vietnamese': 'Bất thường liên quan đến cung răng',
}, {
  'id': 'K07.3',
  'english': 'Anomalies of tooth position',
  'vietnamese': 'Vị trí răng bất thường',
}, {
  'id': 'K07.4',
  'english': 'Malocclusion, unspecified',
  'vietnamese': 'Khớp cắn lệch không xác định',
}, {
  'id': 'K07.5',
  'english': 'Dentofacial functional abnormalities',
  'vietnamese': 'Bất thường chức năng hàm mặt',
}, {
  'id': 'K07.6',
  'english': 'Temporomandibular joint disorders',
  'vietnamese': 'Rối loạn ở khớp thái dương',
}, {
  'id': 'K07.8',
  'english': 'Other dentofacial anomalies',
  'vietnamese': 'Các bất thường hàm mặt khác',
}, {
  'id': 'K07.9',
  'english': 'Dentofacial anomaly, unspecified',
  'vietnamese': 'Bất thường hàm mặt không xác định',
}, {
  'id': 'K08',
  'english': 'Other disorders of teeth and supporting structures',
  'vietnamese': 'Bệnh khác của răng và cấu trúc nâng dỡ',
}, {
  'id': 'K08.0',
  'english': 'Exfoliation of teeth due to systemic causes',
  'vietnamese': 'Mẻ răng do nguyên nhân hệ thống',
}, {
  'id': 'K08.1',
  'english': 'Loss of teeth due to accident, extraction or local periodontal disease',
  'vietnamese': 'Mất răng do tai nạn, do nhổ răng hay bệnh nha chu khu trú',
}, {
  'id': 'K08.2',
  'english': 'Atrophy of edentulous alveolar ridge',
  'vietnamese': 'Teo ổ chân răng',
}, {'id': 'K08.3', 'english': 'Retained dental root', 'vietnamese': 'Chân răng còn sót'}, {
  'id': 'K08.8',
  'english': 'Other specified disorders of teeth and supporting structures',
  'vietnamese': 'Bệnh đặc hiệu khác của răng và cấu trúc nâng đỡ',
}, {
  'id': 'K08.9',
  'english': 'Disorder of teeth and supporting structures, unspecified',
  'vietnamese': 'Bệnh của răng và cấu trúc nâng đỡ, không đặc hiệu',
}, {
  'id': 'K09',
  'english': 'Cysts of oral region, not elsewhere classified',
  'vietnamese': 'Nang vùng miệng, không phân loại nơi khác',
}, {
  'id': 'K09.0',
  'english': 'Developmental odontogenic cysts',
  'vietnamese': 'Nang răng phát triển',
}, {
  'id': 'K09.1',
  'english': 'Developmental (nonodontogenic) cysts of oral region',
  'vietnamese': 'Nang (không do răng) của vùng miệng',
}, {'id': 'K09.2', 'english': 'Other cysts of jaw', 'vietnamese': 'Nang khác của xương hàm'}, {
  'id': 'K09.8',
  'english': 'Other cysts of oral region, not elsewhere classified',
  'vietnamese': 'Nang khác ở vùng miệng, không phân loại nơi khác',
}, {
  'id': 'K09.9',
  'english': 'Cyst of oral region, unspecified',
  'vietnamese': 'Nang vùng miệng không đặc hiệu',
}, {'id': 'K10', 'english': 'Other diseases of jaws', 'vietnamese': 'Bệnh khác của xương hàm'}, {
  'id': 'K10.0',
  'english': 'Developmental disorders of jaws',
  'vietnamese': 'Rối loạn phát triển của xương hàm',
}, {
  'id': 'K10.1',
  'english': 'Giant cell granuloma, central',
  'vietnamese': 'U hạt tế bào khổng lồ, trung tâm',
}, {
  'id': 'K10.2',
  'english': 'Inflammatory conditions of jaws',
  'vietnamese': 'Tình trạng viêm của xương hàm',
}, {'id': 'K10.3', 'english': 'Alveolitis of jaws', 'vietnamese': 'Viêm ổ răng xương hàm'}, {
  'id': 'K10.8',
  'english': 'Other specified diseases of jaws',
  'vietnamese': 'Bệnh xác định khác của xương hàm',
}, {
  'id': 'K10.9',
  'english': 'Disease of jaws, unspecified',
  'vietnamese': 'Bệnh xương hàm, không đặc hiệu',
}, {'id': 'K11', 'english': 'Diseases of salivary glands', 'vietnamese': 'Bệnh tuyến nước bọt'}, {
  'id': 'K11.0',
  'english': 'Atrophy of salivary gland',
  'vietnamese': 'Teo tuyến nước bọt',
}, {
  'id': 'K11.1',
  'english': 'Hypertrophy of salivary gland',
  'vietnamese': 'Phì đại tuyến nước bọt',
}, {'id': 'K11.2', 'english': 'Sialoadenitis', 'vietnamese': 'Viêm tuyến nước bọt'}, {
  'id': 'K11.3',
  'english': 'Abscess of salivary gland',
  'vietnamese': 'áp xe tuyến nước bọt',
}, {
  'id': 'K11.4',
  'english': 'Fistula of salivary gland',
  'vietnamese': 'Lỗ dò tuyến nước bọt',
}, {
  'id': 'K11.5',
  'english': 'Sialolithiasis',
  'vietnamese': 'Bệnh sỏi tuyến nước bọt',
}, {
  'id': 'K11.6',
  'english': 'Mucocele of salivary gland',
  'vietnamese': 'Nang nhầy của tuyến nước bọt',
}, {
  'id': 'K11.7',
  'english': 'Disturbances of salivary secretion',
  'vietnamese': 'Rối loạn tiết nước bọt',
}, {
  'id': 'K11.8',
  'english': 'Other diseases of salivary glands',
  'vietnamese': 'Bệnh khác của tuyến nước bọt',
}, {
  'id': 'K11.9',
  'english': 'Disease of salivary gland, unspecified',
  'vietnamese': 'Bệnh tuyến nước bọt, không đặc hiệu',
}, {
  'id': 'K12',
  'english': 'Stomatitis and related lesions',
  'vietnamese': 'Viêm miệng và tổn thương liên quan',
}, {'id': 'K12.0', 'english': 'Recurrent oral aphthae', 'vietnamese': 'Loét miệng tái diễn'}, {
  'id': 'K12.1',
  'english': 'Other forms of stomatitis',
  'vietnamese': 'Dạng khác của viêm miệng',
}, {
  'id': 'K12.2',
  'english': 'Cellulitis and abscess of mouth',
  'vietnamese': 'Viêm mô tế bào và áp xe của miệng',
}, {'id': 'K12.3', 'english': 'Oral mucositis (ulcerative)', 'vietnamese': 'Viêm miệng (loét)'}, {
  'id': 'K13',
  'english': 'Other diseases of lip and oral mucosa',
  'vietnamese': 'Bệnh khác của môi và niêm mạc miệng',
}, {'id': 'K13.0', 'english': 'Diseases of lips', 'vietnamese': 'Bệnh của môi'}, {
  'id': 'K13.1',
  'english': 'Cheek and lip biting',
  'vietnamese': 'Đau má và môi',
}, {
  'id': 'K13.2',
  'english': 'Leukoplakia and other disturbances of oral epithelium, including tongue',
  'vietnamese': 'Mảng trắng và rối loạn khác của thượng bì miệng, bao gồm lưới',
}, {'id': 'K13.3', 'english': 'Hairy leukoplakia', 'vietnamese': 'Bạch sản dạng tóc'}, {
  'id': 'K13.4',
  'english': 'Granuloma and granuloma-like lesions of oral mucosa',
  'vietnamese': 'U hạt và tổn thương dạng u hạt của niêm mạc miệng',
}, {
  'id': 'K13.5',
  'english': 'Oral submucous fibrosis',
  'vietnamese': 'Xơ hóa dưới niêm mạc miệng',
}, {
  'id': 'K13.6',
  'english': 'Irritative hyperplasia of oral mucosa',
  'vietnamese': 'Tăng sản do kích thích của niêm mạc miệng',
}, {
  'id': 'K13.7',
  'english': 'Other and unspecified lesions of oral mucosa',
  'vietnamese': 'Tổn thương khác và không xác định của niêm mạc miệng',
}, {'id': 'K14', 'english': 'Diseases of tongue', 'vietnamese': 'Bệnh của lưỡi'}, {
  'id': 'K14.0',
  'english': 'Glossitis',
  'vietnamese': 'Viêm lưỡi',
}, {'id': 'K14.1', 'english': 'Geographic tongue', 'vietnamese': 'Lưỡi bản đồ'}, {
  'id': 'K14.2',
  'english': 'Median rhomboid glossitis',
  'vietnamese': 'Viêm lưỡi dạng thoi',
}, {
  'id': 'K14.3',
  'english': 'Hypertrophy of tongue papillae',
  'vietnamese': 'Phì đại gai lưỡi',
}, {
  'id': 'K14.4',
  'english': 'Atrophy of tongue papillae',
  'vietnamese': 'Teo gai lưỡi',
}, {'id': 'K14.5', 'english': 'Plicated tongue', 'vietnamese': 'Lưỡi bị gấp nếp'}, {
  'id': 'K14.6',
  'english': 'Glossodynia',
  'vietnamese': 'Đau lưỡi',
}, {'id': 'K14.8', 'english': 'Other diseases of tongue', 'vietnamese': 'Bệnh khác của lưỡi'}, {
  'id': 'K14.9',
  'english': 'Disease of tongue, unspecified',
  'vietnamese': 'Bệnh lưỡi, không đặc hiệu',
}, {'id': 'K20', 'english': 'Oesophagitis', 'vietnamese': 'Viêm thực quản'}, {
  'id': 'K21',
  'english': 'Gastro-oesophageal reflux disease',
  'vietnamese': 'Bệnh trào ngược dạ dày - thực quản',
}, {
  'id': 'K21.0',
  'english': 'Gastro-oesophageal reflux disease with oesophagitis',
  'vietnamese': 'Bệnh trào ngược dạ dày - thực quản với viêm thực quản',
}, {
  'id': 'K21.9',
  'english': 'Gastro-oesophageal reflux disease without oesophagitis',
  'vietnamese': 'Bệnh trào ngược dạ dày - thực quản không có viêm thực quản',
}, {
  'id': 'K22',
  'english': 'Other diseases of oesophagus',
  'vietnamese': 'Bệnh khác của thực quản',
}, {
  'id': 'K22.0',
  'english': 'Achalasia of cardia',
  'vietnamese': 'Mất khả năng giãn của tâm vị',
}, {
  'id': 'K22.1',
  'english': 'Ulcer of oesophagus',
  'vietnamese': 'Loét thực quản',
}, {'id': 'K22.2', 'english': 'Oesophageal obstruction', 'vietnamese': 'Tắc nghẽn thực quản'}, {
  'id': 'K22.3',
  'english': 'Perforation of oesophagus',
  'vietnamese': 'Thủng thực quản',
}, {
  'id': 'K22.4',
  'english': 'Dyskinesia of oesophagus',
  'vietnamese': 'Rối loạn vận động thực quản',
}, {
  'id': 'K22.5',
  'english': 'Diverticulum of oesophagus, acquired',
  'vietnamese': 'Túi thừa thực quản, mắc phải',
}, {
  'id': 'K22.6',
  'english': 'Gastro-oesophageal laceration-haemorrhage syndrome',
  'vietnamese': 'Hội chứng rách - chảy máu thực quản - dạ dày',
}, {'id': 'K22.7', 'english': 'Barrett s oesophagus', 'vietnamese': 'Thực quản Barrett'}, {
  'id': 'K22.8',
  'english': 'Other specified diseases of oesophagus',
  'vietnamese': 'Bệnh đặc hiệu khác của thực quản',
}, {
  'id': 'K22.9',
  'english': 'Disease of oesophagus, unspecified',
  'vietnamese': 'Bệnh thực quản, không đặc hiệu',
}, {
  'id': 'K23*',
  'english': 'Disorders of oesophagus in diseases classified elsewhere',
  'vietnamese': 'Rối loạn thực quản trong bệnh phân loại nơi khác',
}, {
  'id': 'K23.0*',
  'english': 'Tuberculous oesophagitisA18.8',
  'vietnamese': 'Viêm thực quản do laoA18.8',
}, {
  'id': 'K23.1*',
  'english': 'Megaoesophagus in Chagas disease (B57.3†)',
  'vietnamese': 'Phình thực quản trong bệnh Chagas (B57.3†)',
}, {
  'id': 'K23.8*',
  'english': 'Disorders of oesophagus in other diseases classified elsewhere',
  'vietnamese': 'Rối loạn thực quản trong bệnh phân loại nơi khác',
}, {'id': 'K25', 'english': 'Gastric ulcer', 'vietnamese': 'Loét dạ dày'}, {
  'id': 'K25.0',
  'english': 'Gastric ulcer (Acute with haemorrhage)',
  'vietnamese': 'Loét dạ dày (Cấp có xuất huyết)',
}, {
  'id': 'K25.1',
  'english': 'Gastric ulcer (Acute with perforation)',
  'vietnamese': 'Loét dạ dày (Cấp có thủng)',
}, {
  'id': 'K25.2',
  'english': 'Gastric ulcer (Acute with both haemorrhage and perforation)',
  'vietnamese': 'Loét dạ dày (Cấp, cả xuất huyết và thủng)',
}, {
  'id': 'K25.3',
  'english': 'Gastric ulcer (Acute without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày (Cấp không có xuất huyết hay thủng)',
}, {
  'id': 'K25.4',
  'english': 'Gastric ulcer (Chronic or unspecified with haemorrhage)',
  'vietnamese': 'Loét dạ dày (Mạn hay không đặc hiệu có xuất huyết)',
}, {
  'id': 'K25.5',
  'english': 'Gastric ulcer (Chronic or unspecified with perforation)',
  'vietnamese': 'Loét dạ dày (Mạn hay không đặc hiệu có thủng)',
}, {
  'id': 'K25.6',
  'english': 'Gastric ulcer (Chronic or unspecified with both haemorrhage\nand perforation)',
  'vietnamese': 'Loét dạ dày (Mạn hay không đặc hiệu có xuất huyết hay thủng)',
}, {
  'id': 'K25.7',
  'english': 'Gastric ulcer (Chronic without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày (Mạn không có xuất huyết hay thủng)',
}, {
  'id': 'K25.9',
  'english': 'Gastric ulcer (Unspecified as acute or chronic, without\nhaemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày (Không xác định là cấp hay mạn, không xuất huyết hay thủng)',
}, {'id': 'K26', 'english': 'Duodenal ulcer', 'vietnamese': 'Loét tá tràng'}, {
  'id': 'K26.0',
  'english': 'Duodenal ulcer (Acute with haemorrhage)',
  'vietnamese': 'Loét tá tràng (Cấp có xuất huyết)',
}, {
  'id': 'K26.1',
  'english': 'Duodenal ulcer (Acute with perforation)',
  'vietnamese': 'Loét tá tràng (Cấp có thủng)',
}, {
  'id': 'K26.2',
  'english': 'Duodenal ulcer (Acute with both haemorrhage and perforation)',
  'vietnamese': 'Loét tá tràng (Cấp, cả xuất huyết và thủng)',
}, {
  'id': 'K26.3',
  'english': 'Duodenal ulcer (Acute without haemorrhage or perforation)',
  'vietnamese': 'Loét tá tràng (Cấp không có xuất huyết hay thủng)',
}, {
  'id': 'K26.4',
  'english': 'Duodenal ulcer (Chronic or unspecified with haemorrhage)',
  'vietnamese': 'Loét tá tràng (Mạn hay không đặc hiệu có xuất huyết)',
}, {
  'id': 'K26.5',
  'english': 'Duodenal ulcer (Chronic or unspecified with perforation)',
  'vietnamese': 'Loét tá tràng (Mạn hay không đặc hiệu có thủng)',
}, {
  'id': 'K26.6',
  'english': 'Duodenal ulcer (Chronic or unspecified with both haemorrhage\nand perforation)',
  'vietnamese': 'Loét tá tràng (Mạn hay không đặc hiệu có xuất huyết hay thủng)',
}, {
  'id': 'K26.7',
  'english': 'Duodenal ulcer (Chronic without haemorrhage or perforation)',
  'vietnamese': 'Loét tá tràng (Mạn không có xuất huyết hay thủng)',
}, {
  'id': 'K26.9',
  'english': 'Duodenal ulcer (Unspecified as acute or chronic, without\nhaemorrhage or perforation)',
  'vietnamese': 'Loét tá tràng (Không xác định là cấp hay mạn, không xuất huyết hay thủng)',
}, {
  'id': 'K27',
  'english': 'Peptic ulcer, site unspecified',
  'vietnamese': 'Loét dạ dày-tá tràng, vị trí không đặc hiệu',
}, {
  'id': 'K27.0',
  'english': 'Peptic ulcer, site unspecified (Acute with haemorrhage)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Cấp có xuất huyết)',
}, {
  'id': 'K27.1',
  'english': 'Peptic ulcer, site unspecified (Acute with perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Cấp có thủng)',
}, {
  'id': 'K27.2',
  'english': 'Peptic ulcer, site unspecified (Acute with both haemorrhage and perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Cấp, cả xuất huyết và thủng)',
}, {
  'id': 'K27.3',
  'english': 'Peptic ulcer, site unspecified (Acute without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Cấp không có xuất huyết hay thủng)',
}, {
  'id': 'K27.4',
  'english': 'Peptic ulcer, site unspecified (Chronic or unspecified with haemorrhage)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Mạn hay không đặc hiệu có xuất huyết)',
}, {
  'id': 'K27.5',
  'english': 'Peptic ulcer, site unspecified (Chronic or unspecified with perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Mạn hay không đặc hiệu có thủng)',
}, {
  'id': 'K27.6',
  'english': 'Peptic ulcer, site unspecified (Chronic or unspecified with both haemorrhage\nand perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Mạn hay không đặc hiệu có xuất huyết hay thủng)',
}, {
  'id': 'K27.7',
  'english': 'Peptic ulcer, site unspecified (Chronic without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Mạn không có xuất huyết hay thủng)',
}, {
  'id': 'K27.9',
  'english': 'Peptic ulcer, site unspecified (Unspecified as acute or chronic, without\nhaemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - tá tràng, vị trí không đặc hiệu (Không xác định là cấp hay mạn, không xuất huyết hay thủng)',
}, {'id': 'K28', 'english': 'Gastrojejunal ulcer', 'vietnamese': 'Loét dạ dày - hỗng tràng'}, {
  'id': 'K28.0',
  'english': 'Gastrojejunal ulcer (Acute with haemorrhage)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Cấp có xuất huyết)',
}, {
  'id': 'K28.1',
  'english': 'Gastrojejunal ulcer (Acute with perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Cấp có thủng)',
}, {
  'id': 'K28.2',
  'english': 'Gastrojejunal ulcer (Acute with both haemorrhage and perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Cấp, cả xuất huyết và thủng)',
}, {
  'id': 'K28.3',
  'english': 'Gastrojejunal ulcer (Acute without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Cấp không có xuất huyết hay thủng)',
}, {
  'id': 'K28.4',
  'english': 'Gastrojejunal ulcer (Chronic or unspecified with haemorrhage)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Mạn hay không đặc hiệu có xuất huyết)',
}, {
  'id': 'K28.5',
  'english': 'Gastrojejunal ulcer (Chronic or unspecified with perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Mạn hay không đặc hiệu có thủng)',
}, {
  'id': 'K28.6',
  'english': 'Gastrojejunal ulcer (Chronic or unspecified with both haemorrhage\nand perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Mạn hay không đặc hiệu có xuất huyết hay thủng)',
}, {
  'id': 'K28.7',
  'english': 'Gastrojejunal ulcer (Chronic without haemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Mạn không có xuất huyết hay thủng)',
}, {
  'id': 'K28.9',
  'english': 'Gastrojejunal ulcer (Unspecified as acute or chronic, without\nhaemorrhage or perforation)',
  'vietnamese': 'Loét dạ dày - hỗng tràng (Không xác định là cấp hay mạn, không xuất huyết hay thủng)',
}, {
  'id': 'K29',
  'english': 'Gastritis and duodenitis',
  'vietnamese': 'Viêm dạ dày và tá tràng',
}, {
  'id': 'K29.0',
  'english': 'Acute haemorrhagic gastritis',
  'vietnamese': 'Viêm dạ dày xuất huyết cấp tính',
}, {'id': 'K29.1', 'english': 'Other acute gastritis', 'vietnamese': 'Viêm dạ dày cấp khác'}, {
  'id': 'K29.2',
  'english': 'Alcoholic gastritis',
  'vietnamese': 'Viêm dạ dày do rượu',
}, {
  'id': 'K29.3',
  'english': 'Chronic superficial gastritis',
  'vietnamese': 'Viêm nông niêm mạc dạ dày mãn tính',
}, {
  'id': 'K29.4',
  'english': 'Chronic atrophic gastritis',
  'vietnamese': 'Viêm teo niêm mạc dạ dày mãn tính',
}, {
  'id': 'K29.5',
  'english': 'Chronic gastritis, unspecified',
  'vietnamese': 'Viêm dạ dày mạn, không đặc hiệu',
}, {'id': 'K29.6', 'english': 'Other gastritis', 'vietnamese': 'Viêm dạ dày khác'}, {
  'id': 'K29.7',
  'english': 'Gastritis, unspecified',
  'vietnamese': 'Viêm dạ dày, không đặc hiệu',
}, {'id': 'K29.8', 'english': 'Duodenitis', 'vietnamese': 'Viêm tá tràng'}, {
  'id': 'K29.9',
  'english': 'Gastroduodenitis, unspecified',
  'vietnamese': 'Viêm dạ dày tá tràng, không đặc hiệu',
}, {'id': 'K30', 'english': 'Functional dyspepsia', 'vietnamese': 'Khó tiêu chức năng'}, {
  'id': 'K31',
  'english': 'Other diseases of stomach and duodenum',
  'vietnamese': 'Bệnh khác của dạ dày và tá tràng',
}, {'id': 'K31.0', 'english': 'Acute dilatation of stomach', 'vietnamese': 'Dãn dạ dày cấp'}, {
  'id': 'K31.1',
  'english': 'Adult hypertrophic pyloric stenosis',
  'vietnamese': 'Hẹp môn vị do phì đại ở người lớn',
}, {
  'id': 'K31.2',
  'english': 'Hourglass stricture and stenosis of stomach',
  'vietnamese': 'Hẹp dạ dày và chít hẹp dạng đồng hồ cát',
}, {
  'id': 'K31.3',
  'english': 'Pylorospasm, not elsewhere classified',
  'vietnamese': 'Co thắt môn vị, không phân loại nơi khác',
}, {'id': 'K31.4', 'english': 'Gastric diverticulum', 'vietnamese': 'Túi thừa dạ dày'}, {
  'id': 'K31.5',
  'english': 'Obstruction of duodenum',
  'vietnamese': 'Tắc tá tràng',
}, {
  'id': 'K31.6',
  'english': 'Fistula of stomach and duodenum',
  'vietnamese': 'Dò dạ dày và tá tràng',
}, {
  'id': 'K31.7',
  'english': 'Polyp of stomach and duodenum',
  'vietnamese': 'Polyp dạ dày và tá tràng',
}, {
  'id': 'K31.8',
  'english': 'Other specified diseases of stomach and duodenum',
  'vietnamese': 'Bệnh dạ dày và tá tràng đặc hiệu khác',
}, {
  'id': 'K31.9',
  'english': 'Disease of stomach and duodenum, unspecified',
  'vietnamese': 'Bệnh dạ dày và tá tràng, không đặc hiệu',
}, {'id': 'K35', 'english': 'Acute appendicitis', 'vietnamese': 'Viêm ruột thừa cấp'}, {
  'id': 'K35.0',
  'english': 'Acute appendicitis with generalized peritonitis',
  'vietnamese': 'Viêm ruột thừa với viêm phúc mạc toàn bộ',
}, {
  'id': 'K35.1',
  'english': 'Acute appendicitis with peritoneal abscess',
  'vietnamese': 'Viêm ruột thừa cấp với áp xe phúc mạc',
}, {
  'id': 'K35.2',
  'english': 'Acute appendicitis with generalized peritonitis',
  'vietnamese': 'Viêm ruột thừa cấp tính kèm viêm phúc mạc toàn bộ',
}, {
  'id': 'K35.3',
  'english': 'Acute appendicitis with localized peritonitis',
  'vietnamese': 'Viêm ruột thừa cấp tính kèm viêm phúc mạc khu trú',
}, {
  'id': 'K35.8',
  'english': 'Acute appendicitis, other and unspecified',
  'vietnamese': 'Viêm ruột thừa cấp tính, khác và không đặc hiệu',
}, {
  'id': 'K35.9',
  'english': 'Acute appendicitis, unspecified',
  'vietnamese': 'Viêm ruột thừa cấp - không đặc hiệu',
}, {'id': 'K36', 'english': 'Other appendicitis', 'vietnamese': 'Viêm ruột thừa khác'}, {
  'id': 'K37',
  'english': 'Unspecified appendicitis',
  'vietnamese': 'Viêm ruột thừa không đặc hiệu',
}, {
  'id': 'K38',
  'english': 'Other diseases of appendix',
  'vietnamese': 'Bệnh khác của ruột thừa',
}, {
  'id': 'K38.0',
  'english': 'Hyperplasia of appendix',
  'vietnamese': 'Tăng sản ở ruột thừa',
}, {'id': 'K38.1', 'english': 'Appendicular concretions', 'vietnamese': 'Sỏi ở ruột thừa'}, {
  'id': 'K38.2',
  'english': 'Diverticulum of appendix',
  'vietnamese': 'Túi thừa của ruột thừa',
}, {'id': 'K38.3', 'english': 'Fistula of appendix', 'vietnamese': 'Dò ruột thừa'}, {
  'id': 'K38.8',
  'english': 'Other specified diseases of appendix',
  'vietnamese': 'Bệnh ruột thừa đặc hiệu khác',
}, {
  'id': 'K38.9',
  'english': 'Disease of appendix, unspecified',
  'vietnamese': 'Bệnh ruột thừa, không đặc hiệu',
}, {'id': 'K40', 'english': 'Inguinal hernia', 'vietnamese': 'Thóat vị bẹn'}, {
  'id': 'K40.0',
  'english': 'Bilateral inguinal hernia, with obstruction, without gangrene',
  'vietnamese': 'Thóat vị bẹn hai bên, có tắc, không hoại tử',
}, {
  'id': 'K40.1',
  'english': 'Bilateral inguinal hernia, with gangrene',
  'vietnamese': 'Thóat vị bẹn hai bên, có hoại tử',
}, {
  'id': 'K40.2',
  'english': 'Bilateral inguinal hernia, without obstruction or gangrene',
  'vietnamese': 'Thóat vị bẹn hai bên, không tắc hay hoại thư',
}, {
  'id': 'K40.3',
  'english': 'Unilateral or unspecified inguinal hernia, with obstruction, without gangrene',
  'vietnamese': 'Thóat vị bẹn một bên hay không đặc hiệu, có tắc, không hoại tử',
}, {
  'id': 'K40.4',
  'english': 'Unilateral or unspecified inguinal hernia, with gangrene',
  'vietnamese': 'Thóat vị bẹn một bên hay không đặc hiệu, có hoại tử',
}, {
  'id': 'K40.9',
  'english': 'Unilateral or unspecified inguinal hernia, without obstruction or gangrene',
  'vietnamese': 'Thóat vị bẹn một bên hay không đặc hiệu, không tắc hay hoại tử',
}, {'id': 'K41', 'english': 'Femoral hernia', 'vietnamese': 'Thóat vị đùi'}, {
  'id': 'K41.0',
  'english': 'Bilateral femoral hernia, with obstruction, without gangrene',
  'vietnamese': 'Thóat vị đùi hai bên, có tắc, không hoại tử',
}, {
  'id': 'K41.1',
  'english': 'Bilateral femoral hernia, with gangrene',
  'vietnamese': 'Thóat vị đùi hai bên, có hoại tử',
}, {
  'id': 'K41.2',
  'english': 'Bilateral femoral hernia, without obstruction or gangrene',
  'vietnamese': 'Thóat vị đùi hai bên, không tắc hay hoại tử',
}, {
  'id': 'K41.3',
  'english': 'Unilateral or unspecified femoral hernia, with obstruction, without gangrene',
  'vietnamese': 'Thóat vị đùi một bên hay không đặc hiệu, có tắc, không hoại tử',
}, {
  'id': 'K41.4',
  'english': 'Unilateral or unspecified femoral hernia, with gangrene',
  'vietnamese': 'Thóat vị đùi một bên hay không đặc hiệu, có hoại tử',
}, {
  'id': 'K41.9',
  'english': 'Unilateral or unspecified femoral hernia, without obstruction or gangrene',
  'vietnamese': 'Thóat vị đùi một bên hay không đặc hiệu, không tắc hay hoại tử',
}, {'id': 'K42', 'english': 'Umbilical hernia', 'vietnamese': 'Thóat vị rốn'}, {
  'id': 'K42.0',
  'english': 'Umbilical hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị rốn có tắc, không hoại tử',
}, {
  'id': 'K42.1',
  'english': 'Umbilical hernia with gangrene',
  'vietnamese': 'Thóat vị rốn có hoại tử',
}, {
  'id': 'K42.9',
  'english': 'Umbilical hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị rốn, không tắc hay hoại tử',
}, {'id': 'K43', 'english': 'Ventral hernia', 'vietnamese': 'Thóat vị bụng'}, {
  'id': 'K43.0',
  'english': 'Ventral hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị qua đường mổ có tắc nghẽn, không hoại thư',
}, {
  'id': 'K43.1',
  'english': 'Ventral hernia with gangrene',
  'vietnamese': 'Thóat vị qua đường mổ có hoại thư',
}, {
  'id': 'K43.2',
  'english': 'Incisional hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị qua đường mổ không tắc hoặc hoại thư',
}, {
  'id': 'K43.3',
  'english': 'Parastomal hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị cạnh miệng nối có tắc, không hoại thư',
}, {
  'id': 'K43.4',
  'english': 'Parastomal hernia with gangrene',
  'vietnamese': 'Thóat vị cạnh miệng nối có hoại thư',
}, {
  'id': 'K43.5',
  'english': 'Parastomal hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị cạnh miệng nối không tắc hay hoại thư',
}, {
  'id': 'K43.6',
  'english': 'Other and unspecified ventral hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị bụng khác và không xác định có tắc nghẽn, không hoại thư',
}, {
  'id': 'K43.7',
  'english': 'Other and unspecified ventral hernia with gangrene',
  'vietnamese': 'Thóat vị bụng khác và không xác định có hoại thư',
}, {
  'id': 'K43.9',
  'english': 'Ventral hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị bụng khác và không đặc hiệu không tắc hay hoại thư',
}, {'id': 'K44', 'english': 'Diaphragmatic hernia', 'vietnamese': 'Thóat vị hoành'}, {
  'id': 'K44.0',
  'english': 'Diaphragmatic hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị hoành có tắc, không hoại tử',
}, {
  'id': 'K44.1',
  'english': 'Diaphragmatic hernia with gangrene',
  'vietnamese': 'Thóat vị hoành có hoại tử',
}, {
  'id': 'K44.9',
  'english': 'Diaphragmatic hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị hoành, không tắc hay hoại tử',
}, {'id': 'K45', 'english': 'Other abdominal hernia', 'vietnamese': 'Thóat vị bụng khác'}, {
  'id': 'K45.0',
  'english': 'Other specified abdominal hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị bụng xác định khác có tắc, không hoại thư',
}, {
  'id': 'K45.1',
  'english': 'Other specified abdominal hernia with gangrene',
  'vietnamese': 'Thóat vị bụng đặc hiệu khác, có hoại tử',
}, {
  'id': 'K45.8',
  'english': 'Other specified abdominal hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị bụng đặc hiệu khác, không tắc hay hoại tử',
}, {
  'id': 'K46',
  'english': 'Unspecified abdominal hernia',
  'vietnamese': 'Thóat vị bụng không đặc hiệu',
}, {
  'id': 'K46.0',
  'english': 'Unspecified abdominal hernia with obstruction, without gangrene',
  'vietnamese': 'Thóat vị bụng không đặc hiệu có tắc, không hoại tử',
}, {
  'id': 'K46.1',
  'english': 'Unspecified abdominal hernia with gangrene',
  'vietnamese': 'Thóat vị bụng không đặc hiệu, có hoại tử',
}, {
  'id': 'K46.9',
  'english': 'Unspecified abdominal hernia without obstruction or gangrene',
  'vietnamese': 'Thóat vị bụng không đặc hiệu, không tắc hay hoại tử',
}, {
  'id': 'K50',
  'english': 'Crohn s disease [regional enteritis]',
  'vietnamese': 'Bệnh Crohn (viêm ruột từng vùng)',
}, {
  'id': 'K50.0',
  'english': 'Crohn s disease of small intestine',
  'vietnamese': 'Bệnh Crohn của ruột non',
}, {
  'id': 'K50.1',
  'english': 'Crohn s disease of large intestine',
  'vietnamese': 'Bênh Crohn của đại tràng',
}, {'id': 'K50.8', 'english': 'Other Crohn s disease', 'vietnamese': 'Bệnh Crohn khác'}, {
  'id': 'K50.9',
  'english': 'Crohn s disease, unspecified',
  'vietnamese': 'Bệnh Crohn không đặc hiệu',
}, {'id': 'K51', 'english': 'Ulcerative colitis', 'vietnamese': 'Viêm loét đại tràng chảy máu'}, {
  'id': 'K51.0',
  'english': 'Ulcerative (chronic) enterocolitis',
  'vietnamese': 'Viêm loét toàn ruột (mãn tính)',
}, {
  'id': 'K51.1',
  'english': 'Ulcerative (chronic) ileocolitis',
  'vietnamese': 'Viêm loét hồi - đại tràng(mãn)',
}, {
  'id': 'K51.2',
  'english': 'Ulcerative (chronic) proctitis',
  'vietnamese': 'Viêm loét trực tràng chảy máu (mạn)',
}, {
  'id': 'K51.3',
  'english': 'Ulcerative (chronic) rectosigmoiditis',
  'vietnamese': 'Viêm loét đại tràng sigma - trực tràng chảy máu (mạn)',
}, {'id': 'K51.4', 'english': 'Pseudopolyposis of colon', 'vietnamese': 'Nhiều polyp viêm'}, {
  'id': 'K51.5',
  'english': 'Mucosal proctocolitis',
  'vietnamese': 'Viêm loét đại tràng chảy máu nửa đại tràng trái',
}, {
  'id': 'K51.8',
  'english': 'Other ulcerative colitis',
  'vietnamese': 'Viêm loét đại tràng chảy máu phần khác',
}, {
  'id': 'K51.9',
  'english': 'Ulcerative colitis, unspecified',
  'vietnamese': 'Viêm loét đại tràng chảy máu, không đặc hiệu',
}, {
  'id': 'K52',
  'english': 'Other noninfective gastroenteritis and colitis',
  'vietnamese': 'Viêm dạ dày - ruột và viêm đại tràng khác không nhiễm trùng khác',
}, {
  'id': 'K52.0',
  'english': 'Gastroenteritis and colitis due to radiation',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng do tia xạ',
}, {
  'id': 'K52.1',
  'english': 'Toxic gastroenteritis and colitis',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng do nhiễm độc',
}, {
  'id': 'K52.2',
  'english': 'Allergic and dietetic gastroenteritis and colitis',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng do dị ứng và thức ăn',
}, {
  'id': 'K52.3',
  'english': 'Indeterminate colitis',
  'vietnamese': 'Viêm đại tràng không xác định',
}, {
  'id': 'K52.8',
  'english': 'Other specified noninfective gastroenteritis and colitis',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng đặc hiệu khác không do nhiễm trùng',
}, {
  'id': 'K52.9',
  'english': 'Noninfective gastroenteritis and colitis, unspecified',
  'vietnamese': 'Viêm dạ dày - ruột và đại tràng không nhiễm trùng, không đặc hiệu',
}, {
  'id': 'K55',
  'english': 'Vascular disorders of intestine',
  'vietnamese': 'Rối loạn mạch máu của ruột',
}, {
  'id': 'K55.0',
  'english': 'Acute vascular disorders of intestine',
  'vietnamese': 'Rối loạn mạch máu ruột cấp tính',
}, {
  'id': 'K55.1',
  'english': 'Chronic vascular disorders of intestine',
  'vietnamese': 'Rối loạn mạch máu ruột mạn',
}, {
  'id': 'K55.2',
  'english': 'Angiodysplasia of colon',
  'vietnamese': 'Loạn sản mạch máu đại tràng',
}, {
  'id': 'K55.8',
  'english': 'Other vascular disorders of intestine',
  'vietnamese': 'Rối loạn mạch máu khác của ruột',
}, {
  'id': 'K55.9',
  'english': 'Vascular disorder of intestine, unspecified',
  'vietnamese': 'Rối loạn mạch máu ở ruột, không đặc hiệu',
}, {
  'id': 'K56',
  'english': 'Paralytic ileus and intestinal obstruction without hernia',
  'vietnamese': 'Liệt ruột và tắc ruột không có thóat vị',
}, {'id': 'K56.0', 'english': 'Paralytic ileus', 'vietnamese': 'Liệt ruột'}, {
  'id': 'K56.1',
  'english': 'Intussusception',
  'vietnamese': 'Lồng ruột',
}, {'id': 'K56.2', 'english': 'Volvulus', 'vietnamese': 'Xoắn ruột'}, {
  'id': 'K56.3',
  'english': 'Gallstone ileus',
  'vietnamese': 'Liệt ruột do sỏi',
}, {'id': 'K56.4', 'english': 'Other impaction of intestine', 'vietnamese': 'Nghẹt ruột khác'}, {
  'id': 'K56.5',
  'english': 'Intestinal adhesions [bands] with obstruction',
  'vietnamese': 'Dính ruột [thành dải] có tắc nghẽn',
}, {
  'id': 'K56.6',
  'english': 'Other and unspecified intestinal obstruction',
  'vietnamese': 'Tắc ruột khác và không xác định',
}, {'id': 'K56.7', 'english': 'Ileus, unspecified', 'vietnamese': 'Liệt ruột, không đặc hiệu'}, {
  'id': 'K57',
  'english': 'Diverticular disease of intestine',
  'vietnamese': 'Bệnh túi thừa của ruột',
}, {
  'id': 'K57.0',
  'english': 'Diverticular disease of small intestine with perforation and abscess',
  'vietnamese': 'Bệnh túi thừa của ruột non, có thủng và áp xe',
}, {
  'id': 'K57.1',
  'english': 'Diverticular disease of small intestine without perforation or abscess',
  'vietnamese': 'Bệnh túi thừa của ruột non, không thủng hay áp xe',
}, {
  'id': 'K57.2',
  'english': 'Diverticular disease of large intestine with perforation and abscess',
  'vietnamese': 'Bệnh túi thừa của đại tràng, có thủng và áp xe',
}, {
  'id': 'K57.3',
  'english': 'Diverticular disease of large intestine without perforation or abscess',
  'vietnamese': 'Bệnh túi thừa của đại tràng, không thủng hay áp xe',
}, {
  'id': 'K57.4',
  'english': 'Diverticular disease of both small and large intestine with perforation and abscess',
  'vietnamese': 'Bệnh túi thừa của ruột non và đại tràng, có thủng và áp xe',
}, {
  'id': 'K57.5',
  'english': 'Diverticular disease of both small and large intestine without perforation or abscess',
  'vietnamese': 'Bệnh túi thừa của cả ruột non và đại tràng, không thủng hay áp xe',
}, {
  'id': 'K57.8',
  'english': 'Diverticular disease of intestine, part unspecified, with perforation and abscess',
  'vietnamese': 'Bệnh túi thừa của ruột, vị trí không xác định, có thủng và áp xe',
}, {
  'id': 'K57.9',
  'english': 'Diverticular disease of intestine, part unspecified, without perforation or abscess',
  'vietnamese': 'Bệnh túi thừa của ruột, vị trí không xác định, không thủng hay áp xe',
}, {
  'id': 'K58',
  'english': 'Irritable bowel syndrome',
  'vietnamese': 'Hội chứng ruột kích thích',
}, {
  'id': 'K58.0',
  'english': 'Irritable bowel syndrome with diarrhoea',
  'vietnamese': 'Hội chứng ruột kích thích, có tiêu chảy',
}, {
  'id': 'K58.9',
  'english': 'Irritable bowel syndrome without diarrhoea',
  'vietnamese': 'Hội chứng ruột kích thích, không tiêu chảy',
}, {
  'id': 'K59',
  'english': 'Other functional intestinal disorders',
  'vietnamese': 'Rối loạn chức năng khác của ruột',
}, {'id': 'K59.0', 'english': 'Constipation', 'vietnamese': 'Táo bón'}, {
  'id': 'K59.1',
  'english': 'Functional diarrhoea',
  'vietnamese': 'Tiêu chảy rối loạn chức năng',
}, {
  'id': 'K59.2',
  'english': 'Neurogenic bowel, not elsewhere classified',
  'vietnamese': 'Rối loạn ruột do nguyên nhân thần kinh, không phân loại nơi khác',
}, {
  'id': 'K59.3',
  'english': 'Megacolon, not elsewhere classified',
  'vietnamese': 'Phình đại tràng, không phần loại nơi khác',
}, {'id': 'K59.4', 'english': 'Anal spasm', 'vietnamese': 'Co thắt hậu môn'}, {
  'id': 'K59.8',
  'english': 'Other specified functional intestinal disorders',
  'vietnamese': 'Rối loạn chức năng đặc hiệu khác',
}, {
  'id': 'K59.9',
  'english': 'Functional intestinal disorder, unspecified',
  'vietnamese': 'Rối loạn ruột chức năng, không đặc hiệu',
}, {
  'id': 'K60',
  'english': 'Fissure and fistula of anal and rectal regions',
  'vietnamese': 'rò vùng hậu môn và trực tràng',
}, {'id': 'K60.0', 'english': 'Acute anal fissure', 'vietnamese': 'Nứt kẽ hậu môn cấp'}, {
  'id': 'K60.1',
  'english': 'Chronic anal fissure',
  'vietnamese': 'Nứt kẽ hậu môn mạn',
}, {
  'id': 'K60.2',
  'english': 'Anal fissure, unspecified',
  'vietnamese': 'Nứt ống hậu môn, không đặc hiệu',
}, {'id': 'K60.3', 'english': 'Anal fistula', 'vietnamese': 'Rò hậu môn'}, {
  'id': 'K60.4',
  'english': 'Rectal fistula',
  'vietnamese': 'Rò trực tràng',
}, {'id': 'K60.5', 'english': 'Anorectal fistula', 'vietnamese': 'Rò hậu môn trực tràng'}, {
  'id': 'K61',
  'english': 'Abscess of anal and rectal regions',
  'vietnamese': 'áp xe vùng hậu môn - trực tràng',
}, {'id': 'K61.0', 'english': 'Anal abscess', 'vietnamese': 'áp xe hậu môn'}, {
  'id': 'K61.1',
  'english': 'Rectal abscess',
  'vietnamese': 'áp xe trực tràng',
}, {'id': 'K61.2', 'english': 'Anorectal abscess', 'vietnamese': 'áp xe hậu môn trực tràng'}, {
  'id': 'K61.3',
  'english': 'Ischiorectal abscess',
  'vietnamese': 'áp xe ụ ngồi - trực tràng',
}, {
  'id': 'K61.4',
  'english': 'Intrasphincteric abscess',
  'vietnamese': 'Áp xe trong cơ thắt hậu môn',
}, {
  'id': 'K62',
  'english': 'Other diseases of anus and rectum',
  'vietnamese': 'Bệnh khác của hậu môn và trực tràng',
}, {'id': 'K62.0', 'english': 'Anal polyp', 'vietnamese': 'Polip hậu môn'}, {
  'id': 'K62.1',
  'english': 'Rectal polyp',
  'vietnamese': 'Polip trực tràng',
}, {'id': 'K62.2', 'english': 'Anal prolapse', 'vietnamese': 'Sa hậu môn'}, {
  'id': 'K62.3',
  'english': 'Rectal prolapse',
  'vietnamese': 'Sa trực tràng',
}, {
  'id': 'K62.4',
  'english': 'Stenosis of anus and rectum',
  'vietnamese': 'Hẹp trực tràng và ống hậu môn',
}, {
  'id': 'K62.5',
  'english': 'Haemorrhage of anus and rectum',
  'vietnamese': 'Xuất huyết hậu môn và trực tràng',
}, {
  'id': 'K62.6',
  'english': 'Ulcer of anus and rectum',
  'vietnamese': 'Loét hậu môn và trực tràng',
}, {'id': 'K62.7', 'english': 'Radiation proctitis', 'vietnamese': 'Viêm trực tràng do tia xạ'}, {
  'id': 'K62.8',
  'english': 'Other specified diseases of anus and rectum',
  'vietnamese': 'Bệnh đặc hiệu khác của hậu môn và trực tràng',
}, {
  'id': 'K62.9',
  'english': 'Disease of anus and rectum, unspecified',
  'vietnamese': 'Bệnh hậu môn và trực tràng, không đặc hiệu',
}, {'id': 'K63', 'english': 'Other diseases of intestine', 'vietnamese': 'Bệnh khác của ruột'}, {
  'id': 'K63.0',
  'english': 'Abscess of intestine',
  'vietnamese': 'áp xe ruột',
}, {
  'id': 'K63.1',
  'english': 'Perforation of intestine (nontraumatic)',
  'vietnamese': 'Thủng ruột (không có chấn thương)',
}, {'id': 'K63.2', 'english': 'Fistula of intestine', 'vietnamese': 'Rò ruột'}, {
  'id': 'K63.3',
  'english': 'Ulcer of intestine',
  'vietnamese': 'Loét ruột',
}, {'id': 'K63.4', 'english': 'Enteroptosis', 'vietnamese': 'Sa ruột'}, {
  'id': 'K63.5',
  'english': 'Polyp of colon',
  'vietnamese': 'Polyp đại tràng',
}, {
  'id': 'K63.8',
  'english': 'Other specified diseases of intestine',
  'vietnamese': 'Bệnh đặc hiệu khác của ruột',
}, {
  'id': 'K63.9',
  'english': 'Disease of intestine, unspecified',
  'vietnamese': 'Bệnh ruột, không đặc hiệu',
}, {
  'id': 'K64',
  'english': 'Other diseases of intestine',
  'vietnamese': 'Trĩ và huyết khối tĩnh mạch qua hậu môn',
}, {'id': 'K64.0', 'english': 'First degree haemorrhoids', 'vietnamese': 'Trĩ độ I'}, {
  'id': 'K64.1',
  'english': 'Second degree haemorrhoids',
  'vietnamese': 'Trĩ độ II',
}, {'id': 'K64.2', 'english': 'Third degree haemorrhoids', 'vietnamese': 'Trĩ độ III'}, {
  'id': 'K64.3',
  'english': 'Fourth degree haemorrhoids',
  'vietnamese': 'Trĩ độ IV',
}, {
  'id': 'K64.4',
  'english': 'Residual haemorrhoidal skin tags',
  'vietnamese': 'Dãn da do trĩ sót lại',
}, {
  'id': 'K64.5',
  'english': 'Perianal venous thrombosis',
  'vietnamese': 'Huyết khối tĩnh mạch quanh hậu môn',
}, {
  'id': 'K64.8',
  'english': 'Other specified haemorrhoids',
  'vietnamese': 'Trĩ xác định khác',
}, {
  'id': 'K64.9',
  'english': 'Haemorrhoids, unspecified',
  'vietnamese': 'Trĩ, không đặc hiệu',
}, {'id': 'K65', 'english': 'Peritonitis', 'vietnamese': 'Viêm phúc mạc'}, {
  'id': 'K65.0',
  'english': 'Acute peritonitis',
  'vietnamese': 'Viêm phúc mạc cấp',
}, {'id': 'K65.8', 'english': 'Other peritonitis', 'vietnamese': 'Viêm phúc mạc khác'}, {
  'id': 'K65.9',
  'english': 'Peritonitis, unspecified',
  'vietnamese': 'Viêm phúc mạc, không đặc hiệu',
}, {
  'id': 'K66',
  'english': 'Other disorders of peritoneum',
  'vietnamese': 'Rối loạn khác của phúc mạc',
}, {'id': 'K66.0', 'english': 'Peritoneal adhesions', 'vietnamese': 'Dính phúc mạc'}, {
  'id': 'K66.1',
  'english': 'Haemoperitoneum',
  'vietnamese': 'Cổ trướng máu',
}, {
  'id': 'K66.8',
  'english': 'Other specified disorders of peritoneum',
  'vietnamese': 'Rối loạn đặc hiệu khác của phúc mạc',
}, {
  'id': 'K66.9',
  'english': 'Disorder of peritoneum, unspecified',
  'vietnamese': 'Rối loạn của phúc mạc, không đặc hiệu',
}, {
  'id': 'K67*',
  'english': 'Disorders of peritoneum in infectious diseases classified elsewhere',
  'vietnamese': 'Rối loạn của phúc mạc trong bệnh nhiễm trùng phân loại nơi khác',
}, {
  'id': 'K67.0*',
  'english': 'Chlamydial peritonitis (A74.8†)',
  'vietnamese': 'Viêm phúc mạc do Chlamydia (A74.8†)',
}, {
  'id': 'K67.1*',
  'english': 'Gonococcal peritonitis (A54.8†)',
  'vietnamese': 'Viêm phúc mạc do lậu cầu (A54.8†)',
}, {
  'id': 'K67.2*',
  'english': 'Syphilitic peritonitis (A52.7†)',
  'vietnamese': 'Viêm phúc mạc do giang mai (A52.7†)',
}, {
  'id': 'K67.3*',
  'english': 'Tuberculous peritonitis (A18.3†)',
  'vietnamese': 'Viêm phúc mạc do lao (A18.3†)',
}, {
  'id': 'K67.8*',
  'english': 'Other disorders of peritoneum in infectious diseases classified elsewhere',
  'vietnamese': 'Các rối loạn khác của phúc mạc trong nhiễm trùng đã phân loại nơi khác',
}, {'id': 'K70', 'english': 'Alcoholic liver disease', 'vietnamese': 'Bệnh gan do rượu'}, {
  'id': 'K70.0',
  'english': 'Alcoholic fatty liver',
  'vietnamese': 'Gan nhiễm mỡ do rượu',
}, {'id': 'K70.1', 'english': 'Alcoholic hepatitis', 'vietnamese': 'Viêm gan do rượu'}, {
  'id': 'K70.2',
  'english': 'Alcoholic fibrosis and sclerosis of liver',
  'vietnamese': 'Bệnh xơ hóa gan do rượu',
}, {'id': 'K70.3', 'english': 'Alcoholic cirrhosis of liver', 'vietnamese': 'Xơ gan do rượu'}, {
  'id': 'K70.4',
  'english': 'Alcoholic hepatic failure',
  'vietnamese': 'Suy gan do rượu',
}, {
  'id': 'K70.9',
  'english': 'Alcoholic liver disease, unspecified',
  'vietnamese': 'Bệnh gan do rượu, không đặc hiệu',
}, {'id': 'K71', 'english': 'Toxic liver disease', 'vietnamese': 'Bệnh gan do độc chất'}, {
  'id': 'K71.0',
  'english': 'Toxic liver disease with cholestasis',
  'vietnamese': 'Bệnh gan nhiễm độc, có ứ mật',
}, {
  'id': 'K71.1',
  'english': 'Toxic liver disease with hepatic necrosis',
  'vietnamese': 'Bệnh gan nhiễm độc có hoại tử gan',
}, {
  'id': 'K71.2',
  'english': 'Toxic liver disease with acute hepatitis',
  'vietnamese': 'Bệnh gan nhiễm độc có viêm gan cấp',
}, {
  'id': 'K71.3',
  'english': 'Toxic liver disease with chronic persistent hepatitis',
  'vietnamese': 'Bệnh gan nhiễm độc có viêm gan mạn dai dẳng',
}, {
  'id': 'K71.4',
  'english': 'Toxic liver disease with chronic lobular hepatitis',
  'vietnamese': 'Bệnh gan nhiễm độc có viêm tiểu thùy gan mạn',
}, {
  'id': 'K71.5',
  'english': 'Toxic liver disease with chronic active hepatitis',
  'vietnamese': 'Bệnh gan nhiễm độc có viêm gan mạn hoạt động',
}, {
  'id': 'K71.6',
  'english': 'Toxic liver disease with hepatitis, not elsewhere classified',
  'vietnamese': 'Bệnh gan nhiễm độc có viêm gan, không phân loại nơi khác',
}, {
  'id': 'K71.7',
  'english': 'Toxic liver disease with fibrosis and cirrhosis of liver',
  'vietnamese': 'Bệnh gan nhiễm độc có xơ hóa và xơ gan',
}, {
  'id': 'K71.8',
  'english': 'Toxic liver disease with other disorders of liver',
  'vietnamese': 'Bệnh gan nhiễm độc có rối loạn khác của gan',
}, {
  'id': 'K71.9',
  'english': 'Toxic liver disease, unspecified',
  'vietnamese': 'Bệnh gan nhiễm độc, không đặc hiệu',
}, {
  'id': 'K72',
  'english': 'Hepatic failure, not elsewhere classified',
  'vietnamese': 'Suy gan, không phân loại nơi khác',
}, {
  'id': 'K72.0',
  'english': 'Acute and subacute hepatic failure',
  'vietnamese': 'Suy gan cấp và bán cấp',
}, {'id': 'K72.1', 'english': 'Chronic hepatic failure', 'vietnamese': 'Suy gan mãn'}, {
  'id': 'K72.9',
  'english': 'Hepatic failure, unspecified',
  'vietnamese': 'Suy gan, không đặc hiệu',
}, {
  'id': 'K73',
  'english': 'Chronic hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm gan mãn, không phân loại nơi khác',
}, {
  'id': 'K73.0',
  'english': 'Chronic persistent hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm gan mãn trường diễn, không phân loại nơi khác',
}, {
  'id': 'K73.1',
  'english': 'Chronic lobular hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm tiểu thuỳ gan mạn, không phân loại nơi khác',
}, {
  'id': 'K73.2',
  'english': 'Chronic active hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm gan mãn hoạt động, không phân loại nơi khác',
}, {
  'id': 'K73.8',
  'english': 'Other chronic hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm gan mãn khác, không phân loại nơi khác',
}, {
  'id': 'K73.9',
  'english': 'Chronic hepatitis, unspecified',
  'vietnamese': 'Viêm gan mạn, không đặc hiệu',
}, {
  'id': 'K74',
  'english': 'Fibrosis and cirrhosis of liver',
  'vietnamese': 'Gan xơ hóa và xơ gan',
}, {'id': 'K74.0', 'english': 'Hepatic fibrosis', 'vietnamese': 'Gan xơ hóa'}, {
  'id': 'K74.1',
  'english': 'Hepatic sclerosis',
  'vietnamese': 'Gan xơ cứng',
}, {
  'id': 'K74.2',
  'english': 'Hepatic fibrosis with hepatic sclerosis',
  'vietnamese': 'Gan xơ hóa với gan xơ cứng',
}, {
  'id': 'K74.3',
  'english': 'Primary biliary cirrhosis',
  'vietnamese': 'Xơ gan mật tiên phát',
}, {
  'id': 'K74.4',
  'english': 'Secondary biliary cirrhosis',
  'vietnamese': 'Xơ gan mật thứ phát',
}, {
  'id': 'K74.5',
  'english': 'Biliary cirrhosis, unspecified',
  'vietnamese': 'Xơ gan mật không đặc hiệu',
}, {
  'id': 'K74.6',
  'english': 'Other and unspecified cirrhosis of liver',
  'vietnamese': 'Xơ gan khác và không đặc hiệu',
}, {
  'id': 'K75',
  'english': 'Other inflammatory liver diseases',
  'vietnamese': 'Bệnh viêm gan khác',
}, {'id': 'K75.0', 'english': 'Abscess of liver', 'vietnamese': 'áp xe ở gan'}, {
  'id': 'K75.1',
  'english': 'Phlebitis of portal vein',
  'vietnamese': 'Viêm tĩnh mạch của tĩnh mạch cửa',
}, {
  'id': 'K75.2',
  'english': 'Nonspecific reactive hepatitis',
  'vietnamese': 'Viêm gan tái hoạt động, không đặc hiệu',
}, {
  'id': 'K75.3',
  'english': 'Granulomatous hepatitis, not elsewhere classified',
  'vietnamese': 'Viêm gan dạng u hạt, không phân loại nơi khác',
}, {'id': 'K75.4', 'english': 'Autoimmune hepatitis', 'vietnamese': 'Viêm gan tự miễn'}, {
  'id': 'K75.8',
  'english': 'Other specified inflammatory liver diseases',
  'vietnamese': 'Bệnh viêm gan đặc hiệu khác',
}, {
  'id': 'K75.9',
  'english': 'Inflammatory liver disease, unspecified',
  'vietnamese': 'Bệnh viêm gan, không đặc hiệu',
}, {'id': 'K76', 'english': 'Other diseases of liver', 'vietnamese': 'Bệnh gan khác'}, {
  'id': 'K76.0',
  'english': 'Fatty (change of) liver, not elsewhere classified',
  'vietnamese': 'Gan (biến đổi) nhiễm mỡ, không phân loại nơi khác',
}, {
  'id': 'K76.1',
  'english': 'Chronic passive congestion of liver',
  'vietnamese': 'Xung huyết thụ động mãn tính ở gan',
}, {
  'id': 'K76.2',
  'english': 'Central haemorrhagic necrosis of liver',
  'vietnamese': 'Hoại tử xuất huyết trung tâm tiểu thùy gan',
}, {'id': 'K76.3', 'english': 'Infarction of liver', 'vietnamese': 'Nhồi máu gan'}, {
  'id': 'K76.4',
  'english': 'Peliosis hepatis',
  'vietnamese': 'Bệnh ứ máu xoang gan',
}, {
  'id': 'K76.5',
  'english': 'Hepatic veno-occlusive disease',
  'vietnamese': 'Bệnh tắc tĩnh mạch trên gan',
}, {'id': 'K76.6', 'english': 'Portal hypertension', 'vietnamese': 'Tăng áp lực tĩnh mạch cửa'}, {
  'id': 'K76.7',
  'english': 'Hepatorenal syndrome',
  'vietnamese': 'Hội chứng gan - thận',
}, {
  'id': 'K76.8',
  'english': 'Other specified diseases of liver',
  'vietnamese': 'Bệnh gan đặc hiệu khác',
}, {
  'id': 'K76.9',
  'english': 'Liver disease, unspecified',
  'vietnamese': 'Bệnh gan, không đặc hiệu',
}, {
  'id': 'K77*',
  'english': 'Liver disorders in diseases classified elsewhere',
  'vietnamese': 'Rối loạn chức năng gan trong bệnh phân loại nơi khác',
}, {
  'id': 'K77.0*',
  'english': 'Liver disorders in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Rối loạn chức năng gan trong bệnh nhiễm trùng và nhiễm ký sinh trùng được phân loại nơi khác',
}, {
  'id': 'K77.8*',
  'english': 'Liver disorders in other diseases classified elsewhere',
  'vietnamese': 'Rối loạn chức năng gan trong bệnh khác, phân loại nơi khác',
}, {'id': 'K80', 'english': 'Cholelithiasis', 'vietnamese': 'Sỏi mật'}, {
  'id': 'K80.0',
  'english': 'Calculus of gallbladder with acute cholecystitis',
  'vietnamese': 'Sỏi túi mật có viêm túi mật cấp',
}, {
  'id': 'K80.1',
  'english': 'Calculus of gallbladder with other cholecystitis',
  'vietnamese': 'Sỏi túi mật có viêm túi mật khác',
}, {
  'id': 'K80.2',
  'english': 'Calculus of gallbladder without cholecystitis',
  'vietnamese': 'Sỏi túi mật không có viêm túi mật',
}, {
  'id': 'K80.3',
  'english': 'Calculus of bile duct with cholangitis',
  'vietnamese': 'Sỏi ống mật có viêm đường mật',
}, {
  'id': 'K80.4',
  'english': 'Calculus of bile duct with cholecystitis',
  'vietnamese': 'Sỏi đường mật có viêm túi mật',
}, {
  'id': 'K80.5',
  'english': 'Calculus of bile duct without cholangitis or cholecystitis',
  'vietnamese': 'Sỏi mật không viêm đường mật hay viêm túi mật',
}, {'id': 'K80.8', 'english': 'Other cholelithiasis', 'vietnamese': 'Sỏi mật khác'}, {
  'id': 'K81',
  'english': 'Cholecystitis',
  'vietnamese': 'Viêm túi mật',
}, {'id': 'K81.0', 'english': 'Acute cholecystitis', 'vietnamese': 'Viêm túi mật cấp'}, {
  'id': 'K81.1',
  'english': 'Chronic cholecystitis',
  'vietnamese': 'Viêm túi mật mạn',
}, {'id': 'K81.8', 'english': 'Other cholecystitis', 'vietnamese': 'Viêm túi mật thể khác'}, {
  'id': 'K81.9',
  'english': 'Cholecystitis, unspecified',
  'vietnamese': 'Viêm túi mật, không đặc hiệu',
}, {
  'id': 'K82',
  'english': 'Other diseases of gallbladder',
  'vietnamese': 'Bệnh khác của túi mật',
}, {
  'id': 'K82.0',
  'english': 'Obstruction of gallbladder',
  'vietnamese': 'Tắc túi mật',
}, {'id': 'K82.1', 'english': 'Hydrops of gallbladder', 'vietnamese': 'Tràn dịch túi mật'}, {
  'id': 'K82.2',
  'english': 'Perforation of gallbladder',
  'vietnamese': 'Thủng túi mật',
}, {'id': 'K82.3', 'english': 'Fistula of gallbladder', 'vietnamese': 'Dò túi mật'}, {
  'id': 'K82.4',
  'english': 'Cholesterolosis of gallbladder',
  'vietnamese': 'Tích tụ Cholesterol ở túi mật',
}, {
  'id': 'K82.8',
  'english': 'Other specified diseases of gallbladder',
  'vietnamese': 'Bệnh đặc hiệu khác của túi mật',
}, {
  'id': 'K82.9',
  'english': 'Disease of gallbladder, unspecified',
  'vietnamese': 'Bệnh của túi mật, không đặc hiệu',
}, {
  'id': 'K83',
  'english': 'Other diseases of biliary tract',
  'vietnamese': 'Bệnh khác của đường mật',
}, {'id': 'K83.0', 'english': 'Cholangitis', 'vietnamese': 'Viêm đường mật'}, {
  'id': 'K83.1',
  'english': 'Obstruction of bile duct',
  'vietnamese': 'Tắc ống mật',
}, {'id': 'K83.2', 'english': 'Perforation of bile duct', 'vietnamese': 'Thủng đường mật'}, {
  'id': 'K83.3',
  'english': 'Fistula of bile duct',
  'vietnamese': 'Rò đường mật',
}, {
  'id': 'K83.4',
  'english': 'Spasm of sphincter of Oddi',
  'vietnamese': 'Co thắt cơ vòng Oddi',
}, {
  'id': 'K83.5',
  'english': 'Biliary cyst',
  'vietnamese': 'Nang đường mật',
}, {
  'id': 'K83.8',
  'english': 'Other specified diseases of biliary tract',
  'vietnamese': 'Bệnh đặc hiệu khác của đường mật',
}, {
  'id': 'K83.9',
  'english': 'Disease of biliary tract, unspecified',
  'vietnamese': 'Bệnh đường mật, không đặc hiệu',
}, {'id': 'K85', 'english': 'Acute pancreatitis', 'vietnamese': 'Viêm tụy cấp'}, {
  'id': 'K85.0',
  'english': 'Idiopathic acute pancreatitis',
  'vietnamese': 'Viêm tụy cấp tính tự phát',
}, {
  'id': 'K85.1',
  'english': 'Biliary acute pancreatitis',
  'vietnamese': 'Viêm tụy cấp tính do mật',
}, {
  'id': 'K85.2',
  'english': 'Alcohol-induced acute pancreatitis',
  'vietnamese': 'Viêm tụy cấp tính do rượu',
}, {
  'id': 'K85.3',
  'english': 'Drug-induced acute pancreatitis',
  'vietnamese': 'Viêm tụy cấp tính do thuốc',
}, {
  'id': 'K85.8',
  'english': 'Other acute pancreatitis',
  'vietnamese': 'Viêm tụy cấp tính khác',
}, {
  'id': 'K85.9',
  'english': 'Acute pancreatitis, unspecified',
  'vietnamese': 'Viêm tụy cấp tính, không đặc hiệu',
}, {'id': 'K86', 'english': 'Other diseases of pancreas', 'vietnamese': 'Bệnh tụy khác'}, {
  'id': 'K86.0',
  'english': 'Alcohol-induced chronic pancreatitis',
  'vietnamese': 'Viêm tụy mạn do rượu',
}, {
  'id': 'K86.1',
  'english': 'Other chronic pancreatitis',
  'vietnamese': 'Viêm tụy mạn thể khác',
}, {
  'id': 'K86.2',
  'english': 'Cyst of pancreas',
  'vietnamese': 'Nang tụy',
}, {'id': 'K86.3', 'english': 'Pseudocyst of pancreas', 'vietnamese': 'Nang giả tụy'}, {
  'id': 'K86.8',
  'english': 'Other specified diseases of pancreas',
  'vietnamese': 'Bệnh đặc hiệu khác của tụy',
}, {
  'id': 'K86.9',
  'english': 'Disease of pancreas, unspecified',
  'vietnamese': 'Bệnh tụy, không đặc hiệu',
}, {
  'id': 'K87*',
  'english': 'Disorders of gallbladder, biliary tract and pancreas in diseases classified elsewhere',
  'vietnamese': 'Rối loạn của túi mật, đường mật và tụy đã được phân loại nơi khác',
}, {
  'id': 'K87.0*',
  'english': 'Disorders of gallbladder and biliary tract in diseases classified elsewhere',
  'vietnamese': 'Rối loạn của túi mật và đường mật đã được phân loại ở nơi khác',
}, {
  'id': 'K87.1*',
  'english': 'Disorders of pancreas in diseases classified elsewhere',
  'vietnamese': 'Rối loạn tụy trong phân loại nơi khác',
}, {'id': 'K90', 'english': 'Intestinal malabsorption', 'vietnamese': 'Ruột kém hấp thu'}, {
  'id': 'K90.0',
  'english': 'Coeliac disease',
  'vietnamese': 'Bệnh Coeliac',
}, {'id': 'K90.1', 'english': 'Tropical sprue', 'vietnamese': 'Tiêu chảy nhiệt đới'}, {
  'id': 'K90.2',
  'english': 'Blind loop syndrome, not elsewhere classified',
  'vietnamese': 'Hội chứng quai tới, không phân loại nơi khác',
}, {
  'id': 'K90.3',
  'english': 'Pancreatic steatorrhoea',
  'vietnamese': 'Tiêu chảy phân mỡ do tụy',
}, {
  'id': 'K90.4',
  'english': 'Malabsorption due to intolerance, not elsewhere classified',
  'vietnamese': 'Kém hấp thụ do không dung nạp, không phân loại nơi khác',
}, {
  'id': 'K90.8',
  'english': 'Other intestinal malabsorption',
  'vietnamese': 'Ruột kém hấp thu khác',
}, {
  'id': 'K90.9',
  'english': 'Intestinal malabsorption, unspecified',
  'vietnamese': 'Ruột kém hấp thu, không đặc hiệu',
}, {
  'id': 'K91',
  'english': 'Postprocedural disorders of digestive system, not elsewhere classified',
  'vietnamese': 'Rối loạn tiêu hóa sau phẫu thuật không phân loại nơi khác',
}, {
  'id': 'K91.0',
  'english': 'Vomiting following gastrointestinal surgery',
  'vietnamese': 'Nôn mửa sau phẫu thuật dạ dày - ruột',
}, {
  'id': 'K91.1',
  'english': 'Postgastric surgery syndromes',
  'vietnamese': 'Hội chứng sau phẫu thuật dạ dày',
}, {
  'id': 'K91.2',
  'english': 'Postsurgical malabsorption, not elsewhere classified',
  'vietnamese': 'Kém hấp thu sau phẫu thuật, không phân loại nơi khác',
}, {
  'id': 'K91.3',
  'english': 'Postoperative intestinal obstruction',
  'vietnamese': 'Tắc ruột sau mổ',
}, {
  'id': 'K91.4',
  'english': 'Colostomy and enterostomy malfunction',
  'vietnamese': 'Suy chức năng sau mổ thông đại tràng và mổ thông đường ruột',
}, {
  'id': 'K91.5',
  'english': 'Postcholecystectomy syndrome',
  'vietnamese': 'Hội chứng sau cắt túi mật',
}, {
  'id': 'K91.8',
  'english': 'Other postprocedural disorders of digestive system, not elsewhere classified',
  'vietnamese': 'Rối loạn sau phẫu thuật khác của hệ tiêu hóa không phân loại nơi khác',
}, {
  'id': 'K91.9',
  'english': 'Postprocedural disorder of digestive system, unspecified',
  'vietnamese': 'Rối loạn sau phẫu thuật của hệ tiêu hóa, không đặc hiệu',
}, {
  'id': 'K92',
  'english': 'Other diseases of digestive system',
  'vietnamese': 'Bệnh khác của hệ tiêu hóa',
}, {'id': 'K92.0', 'english': 'Haematemesis', 'vietnamese': 'Nôn ra máu'}, {
  'id': 'K92.1',
  'english': 'Melaena',
  'vietnamese': 'Đi ngoài phân đen',
}, {
  'id': 'K92.2',
  'english': 'Gastrointestinal haemorrhage, unspecified',
  'vietnamese': 'Chảy máu tiêu hóa, không đặc hiệu',
}, {
  'id': 'K92.8',
  'english': 'Other specified diseases of digestive system',
  'vietnamese': 'Bệnh hệ tiêu hóa đặc hiệu khác',
}, {
  'id': 'K92.9',
  'english': 'Disease of digestive system, unspecified',
  'vietnamese': 'Bệnh hệ tiêu hóa, không đặc hiệu',
}, {
  'id': 'K93*',
  'english': 'Disorders of other digestive organs in diseases classified elsewhere',
  'vietnamese': 'Rối loạn của cơ quan tiêu hóa khác đã được phân loại ở nơi khác',
}, {
  'id': 'K93.0*',
  'english': 'Tuberculous disorders of intestines, peritoneum and mesenteric glands (A18.3†)',
  'vietnamese': 'Bệnh lao ở ruột, phúc mạc và tuyến mạc treo (A18.3†)',
}, {
  'id': 'K93.1*',
  'english': 'Megacolon in Chagas disease (B57.3†)',
  'vietnamese': 'Phình đại tràng trong bệnh Chagas (B57.3†)',
}, {
  'id': 'K93.8*',
  'english': 'Disorders of other specified digestive organs in diseases classified elsewhere',
  'vietnamese': 'Rối loạn cơ quan tiêu hóa đặc hiệu khác đã được phân loại ở nơi khác',
}, {
  'id': 'L00',
  'english': 'Staphylococcal scalded skin syndrome',
  'vietnamese': 'Hội chứng bong vảy da do tụ cầu',
}, {'id': 'L01', 'english': 'Impetigo', 'vietnamese': 'Chốc'}, {
  'id': 'L01.0',
  'english': 'Impetigo [any organism] [any site]',
  'vietnamese': 'Chốc [bất kỳ sinh vật nào] [cơ quan nào] [bất kỳ vị trí nào]',
}, {
  'id': 'L01.1',
  'english': 'Impetiginization of other dermatoses',
  'vietnamese': 'Chốc hóa của các bệnh da khác',
}, {
  'id': 'L02',
  'english': 'Cutaneous abscess, furuncle and carbuncle',
  'vietnamese': 'Áp xe da, nhọt, nhọt cụm',
}, {
  'id': 'L02.0',
  'english': 'Cutaneous abscess, furuncle and carbuncle of face',
  'vietnamese': 'Áp xe da, nhọt và cụm nhọt ở mặt',
}, {
  'id': 'L02.1',
  'english': 'Cutaneous abscess, furuncle and carbuncle of neck',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt ở cổ',
}, {
  'id': 'L02.2',
  'english': 'Cutaneous abscess, furuncle and carbuncle of trunk',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt ở thân',
}, {
  'id': 'L02.3',
  'english': 'Cutaneous abscess, furuncle and carbuncle of buttock',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt ở mông',
}, {
  'id': 'L02.4',
  'english': 'Cutaneous abscess, furuncle and carbuncle of limb',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt ở chi',
}, {
  'id': 'L02.8',
  'english': 'Cutaneous abscess, furuncle and carbuncle of other sites',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt ở vị trí khác',
}, {
  'id': 'L02.9',
  'english': 'Cutaneous abscess, furuncle and carbuncle, unspecified',
  'vietnamese': 'Áp xe da, nhọt, cụm nhọt không đặc hiệu',
}, {'id': 'L03', 'english': 'Cellulitis', 'vietnamese': 'Viêm mô bào'}, {
  'id': 'L03.0',
  'english': 'Cellulitis of finger and toe',
  'vietnamese': 'Viêm mô bào ở ngón tay và ngón chân',
}, {
  'id': 'L03.1',
  'english': 'Cellulitis of other parts of limb',
  'vietnamese': 'Viêm mô bào ở các phần khác của chi',
}, {'id': 'L03.2', 'english': 'Cellulitis of face', 'vietnamese': 'Viêm mô bào ở mặt'}, {
  'id': 'L03.3',
  'english': 'Cellulitis of trunk',
  'vietnamese': 'Viêm mô bào ở thân',
}, {
  'id': 'L03.8',
  'english': 'Cellulitis of other sites',
  'vietnamese': 'Viêm mô bào ở vị trí khác',
}, {
  'id': 'L03.9',
  'english': 'Cellulitis, unspecified',
  'vietnamese': 'Viêm mô bào không đặc hiệu',
}, {
  'id': 'L04',
  'english': 'Acute lymphadenitis',
  'vietnamese': 'Viêm hạch bạch huyết cấp tính',
}, {
  'id': 'L04.0',
  'english': 'Acute lymphadenitis of face, head and neck',
  'vietnamese': 'Viêm hạch bạch huyết cấp ở mặt, đầu và cổ',
}, {
  'id': 'L04.1',
  'english': 'Acute lymphadenitis of trunk',
  'vietnamese': 'Viêm bạch mạch huyết cấp ở thân',
}, {
  'id': 'L04.2',
  'english': 'Acute lymphadenitis of upper limb',
  'vietnamese': 'Viêm bạch mạch huyết cấp ở chi trên',
}, {
  'id': 'L04.3',
  'english': 'Acute lymphadenitis of lower limb',
  'vietnamese': 'Viêm bạch mạch huyết cấp ở chi dưới',
}, {
  'id': 'L04.8',
  'english': 'Acute lymphadenitis of other sites',
  'vietnamese': 'Viêm hạch bạch huyết cấp ở các vị trí khác',
}, {
  'id': 'L04.9',
  'english': 'Acute lymphadenitis, unspecified',
  'vietnamese': 'Viêm bạch mạch huyết cấp, không đặc hiệu',
}, {'id': 'L05', 'english': 'Pilonidal cyst', 'vietnamese': 'Kén nang lông'}, {
  'id': 'L05.0',
  'english': 'Pilonidal cyst with abscess',
  'vietnamese': 'Kén nang lông áp xe hóa',
}, {
  'id': 'L05.9',
  'english': 'Pilonidal cyst without abscess',
  'vietnamese': 'Kén nang lông không áp xe hóa',
}, {
  'id': 'L08',
  'english': 'Other local infections of skin and subcutaneous tissue',
  'vietnamese': 'Nhiễm khuẩn khu trú khác của da và mô dưới da',
}, {'id': 'L08.0', 'english': 'Pyoderma', 'vietnamese': 'Viêm da mủ'}, {
  'id': 'L08.1',
  'english': 'Erythrasma',
  'vietnamese': 'Bệnh Erythrasma',
}, {
  'id': 'L08.8',
  'english': 'Other specified local infections of skin and subcutaneous tissue',
  'vietnamese': 'Các nhiễm trùng khu trú ở da và tổ chức dưới da đặc hiệu khác',
}, {
  'id': 'L08.9',
  'english': 'Local infection of skin and subcutaneous tissue, unspecified',
  'vietnamese': 'Các nhiễm trùng khu trú ở da và tổ chức dưới da không đặc hiệu',
}, {'id': 'L10', 'english': 'Pemphigus', 'vietnamese': 'Pemphigus'}, {
  'id': 'L10.0',
  'english': 'Pemphigus vulgaris',
  'vietnamese': 'Pemphigus thông thường',
}, {'id': 'L10.1', 'english': 'Pemphigus vegetans', 'vietnamese': 'Pemphigus sùi'}, {
  'id': 'L10.2',
  'english': 'Pemphigus foliaceus',
  'vietnamese': 'Pemphigus vảy lá',
}, {
  'id': 'L10.3',
  'english': 'Brazilian pemphigus [fogo selvagem]',
  'vietnamese': 'Pemphigus Brazil',
}, {'id': 'L10.4', 'english': 'Pemphigus erythematosus', 'vietnamese': 'Pemphigus đỏ da'}, {
  'id': 'L10.5',
  'english': 'Drug-induced pemphigus',
  'vietnamese': 'Pemphigus do thuốc',
}, {'id': 'L10.8', 'english': 'Other pemphigus', 'vietnamese': 'Các Pemphigus khác'}, {
  'id': 'L10.9',
  'english': 'Pemphigus, unspecified',
  'vietnamese': 'Pemphigus không đặc hiệu',
}, {
  'id': 'L11',
  'english': 'Other acantholytic disorders',
  'vietnamese': 'Các bệnh da ly gai khác',
}, {
  'id': 'L11.0',
  'english': 'Acquired keratosis follicularis',
  'vietnamese': 'Dày sừng nang lông mắc phải',
}, {
  'id': 'L11.1',
  'english': 'Transient acantholytic dermatosis [Grover]',
  'vietnamese': 'Bệnh da do tiêu lớp gai thóang qua [Grover]',
}, {
  'id': 'L11.8',
  'english': 'Other specified acantholytic disorders',
  'vietnamese': 'Các bệnh ly gai đặc hiệu khác',
}, {
  'id': 'L11.9',
  'english': 'Acantholytic disorder, unspecified',
  'vietnamese': 'Các bệnh ly gai, không đặc hiệu',
}, {'id': 'L12', 'english': 'Pemphigoid', 'vietnamese': 'Pemphigus'}, {
  'id': 'L12.0',
  'english': 'Bullous pemphigoid',
  'vietnamese': 'Pemphigoid bọng nước',
}, {'id': 'L12.1', 'english': 'Cicatricial pemphigoid', 'vietnamese': 'Pemphigoid sẹo'}, {
  'id': 'L12.2',
  'english': 'Chronic bullous disease of childhood',
  'vietnamese': 'Bệnh bọng nước mãn tính ở trẻ em',
}, {
  'id': 'L12.3',
  'english': 'Acquired epidermolysis bullosa',
  'vietnamese': 'Ly thượng bì bọng nước mắc phải',
}, {'id': 'L12.8', 'english': 'Other pemphigoid', 'vietnamese': 'Pemphigoid khác'}, {
  'id': 'L12.9',
  'english': 'Pemphigoid, unspecified',
  'vietnamese': 'Pemphigoid không đặc hiệu',
}, {
  'id': 'L13',
  'english': 'Other bullous disorders',
  'vietnamese': 'Các bệnh da có bọng nước khác',
}, {'id': 'L13.0', 'english': 'Dermatitis herpetiformis', 'vietnamese': 'Viêm da dạng herpes'}, {
  'id': 'L13.1',
  'english': 'Subcorneal pustular dermatitis',
  'vietnamese': 'Viêm da mụn mủ dưới lớp sừng',
}, {
  'id': 'L13.8',
  'english': 'Other specified bullous disorders',
  'vietnamese': 'Các bệnh da bọng nước đặc hiệu khác',
}, {
  'id': 'L13.9',
  'english': 'Bullous disorder, unspecified',
  'vietnamese': 'Các bệnh da bọng nước không đặc hiệu',
}, {
  'id': 'L14*',
  'english': 'Bullous disorders in diseases classified elsewhere',
  'vietnamese': 'Các bệnh da bọng nước trong các bệnh đã được phân loại ở phần khác',
}, {'id': 'L20', 'english': 'Atopic dermatitis', 'vietnamese': 'Viêm da cơ địa'}, {
  'id': 'L20.0',
  'english': 'Besnier s prurigo',
  'vietnamese': 'Sẩn ngứa Besnier',
}, {'id': 'L20.8', 'english': 'Other atopic dermatitis', 'vietnamese': 'Viêm da cơ địa khác'}, {
  'id': 'L20.9',
  'english': 'Atopic dermatitis, unspecified',
  'vietnamese': 'Viêm da cơ địa, không đặc hiệu',
}, {'id': 'L21', 'english': 'Seborrhoeic dermatitis', 'vietnamese': 'Viêm da dầu'}, {
  'id': 'L21.0',
  'english': 'Seborrhoea capitis',
  'vietnamese': 'Viêm da dầu ở đầu',
}, {
  'id': 'L21.1',
  'english': 'Seborrhoeic infantile dermatitis',
  'vietnamese': 'Viêm da dầu ở trẻ em',
}, {'id': 'L21.8', 'english': 'Other seborrhoeic dermatitis', 'vietnamese': 'Viêm da dầu khác'}, {
  'id': 'L21.9',
  'english': 'Seborrhoeic dermatitis, unspecified',
  'vietnamese': 'Viêm da dầu không đặc hiệu',
}, {'id': 'L22', 'english': 'Diaper (napkin) dermatitis', 'vietnamese': 'Viêm da tã lót'}, {
  'id': 'L23',
  'english': 'Allergic contact dermatitis',
  'vietnamese': 'Viêm da tiếp xúc dị ứng',
}, {
  'id': 'L23.0',
  'english': 'Allergic contact dermatitis due to metals',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do kim loại',
}, {
  'id': 'L23.1',
  'english': 'Allergic contact dermatitis due to adhesives',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do keo dính',
}, {
  'id': 'L23.2',
  'english': 'Allergic contact dermatitis due to cosmetics',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do mỹ phẩm',
}, {
  'id': 'L23.3',
  'english': 'Allergic contact dermatitis due to drugs in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do thuốc tại chỗ',
}, {
  'id': 'L23.4',
  'english': 'Allergic contact dermatitis due to dyes',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do thuốc nhuộm',
}, {
  'id': 'L23.5',
  'english': 'Allergic contact dermatitis due to other chemical products',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do hóa chất khác',
}, {
  'id': 'L23.6',
  'english': 'Allergic contact dermatitis due to food in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do thực phẩm tại chỗ',
}, {
  'id': 'L23.7',
  'english': 'Allergic contact dermatitis due to plants, except food',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do thực vật, ngoại trừ thực phẩm',
}, {
  'id': 'L23.8',
  'english': 'Allergic contact dermatitis due to other agents',
  'vietnamese': 'Viêm da tiếp xúc dị ứng do tác nhân khác',
}, {
  'id': 'L23.9',
  'english': 'Allergic contact dermatitis, unspecified cause',
  'vietnamese': 'Viêm da tiếp xúc dị ứng, nguyên nhân không đặc hiệu',
}, {
  'id': 'L24',
  'english': 'Irritant contact dermatitis',
  'vietnamese': 'Viêm da tiếp xúc kích ứng',
}, {
  'id': 'L24.0',
  'english': 'Irritant contact dermatitis due to detergents',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do chất tẩy rửa',
}, {
  'id': 'L24.1',
  'english': 'Irritant contact dermatitis due to oils and greases',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do dầu mỡ',
}, {
  'id': 'L24.2',
  'english': 'Irritant contact dermatitis due to solvents',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do dung môi',
}, {
  'id': 'L24.3',
  'english': 'Irritant contact dermatitis due to cosmetics',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do mỹ phẩm',
}, {
  'id': 'L24.4',
  'english': 'Irritant contact dermatitis due to drugs in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do thuốc tại chỗ',
}, {
  'id': 'L24.5',
  'english': 'Irritant contact dermatitis due to other chemical products',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do các hóa chất khác',
}, {
  'id': 'L24.6',
  'english': 'Irritant contact dermatitis due to food in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do thực phẩm',
}, {
  'id': 'L24.7',
  'english': 'Irritant contact dermatitis due to plants, except food',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do thực vật, ngoại trừ thực phẩm',
}, {
  'id': 'L24.8',
  'english': 'Irritant contact dermatitis due to other agents',
  'vietnamese': 'Viêm da tiếp xúc kích ứng do tác nhân khác',
}, {
  'id': 'L24.9',
  'english': 'Irritant contact dermatitis, unspecified cause',
  'vietnamese': 'Viêm da tiếp xúc kích ứng, nguyên nhân không đặc hiệu',
}, {
  'id': 'L25',
  'english': 'Unspecified contact dermatitis',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu',
}, {
  'id': 'L25.0',
  'english': 'Unspecified contact dermatitis due to cosmetics',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu, do mỹ phẩm',
}, {
  'id': 'L25.1',
  'english': 'Unspecified contact dermatitis due to drugs in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu, do thuốc',
}, {
  'id': 'L25.2',
  'english': 'Unspecified contact dermatitis due to dyes',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu do thuốc nhuộm',
}, {
  'id': 'L25.3',
  'english': 'Unspecified contact dermatitis due to other chemical products',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu do hóa chất khác',
}, {
  'id': 'L25.4',
  'english': 'Unspecified contact dermatitis due to food in contact with skin',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu do thực phẩm',
}, {
  'id': 'L25.5',
  'english': 'Unspecified contact dermatitis due to plants, except food',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu, do thực vật, trừ thực phẩm',
}, {
  'id': 'L25.8',
  'english': 'Unspecified contact dermatitis due to other agents',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu, do tác nhân khác',
}, {
  'id': 'L25.9',
  'english': 'Unspecified contact dermatitis, unspecified cause',
  'vietnamese': 'Viêm da tiếp xúc không đặc hiệu',
}, {'id': 'L26', 'english': 'Exfoliative dermatitis', 'vietnamese': 'Đỏ da toàn thân'}, {
  'id': 'L27',
  'english': 'Dermatitis due to substances taken internally',
  'vietnamese': 'Viêm da do các chất được đưa vào trong cơ thể',
}, {
  'id': 'L27.0',
  'english': 'Generalized skin eruption due to drugs and medicaments',
  'vietnamese': 'Phát ban toàn thân do dược chất và thuốc',
}, {
  'id': 'L27.1',
  'english': 'Localized skin eruption due to drugs and medicaments',
  'vietnamese': 'Phát ban khu trú do dược chất và thuốc',
}, {
  'id': 'L27.2',
  'english': 'Dermatitis due to ingested food',
  'vietnamese': 'Viêm da do thức ăn',
}, {
  'id': 'L27.8',
  'english': 'Dermatitis due to other substances taken internally',
  'vietnamese': 'Viêm da do các chất khác đưa vào trong cơ thể',
}, {
  'id': 'L27.9',
  'english': 'Dermatitis due to unspecified substance taken internally',
  'vietnamese': 'Viêm da do các chất không đặc hiệu đưa vào trong cơ thể',
}, {
  'id': 'L28',
  'english': 'Lichen simplex chronicus and prurigo',
  'vietnamese': 'Lichen đơn dạng mãn tính và sẩn ngứa',
}, {
  'id': 'L28.0',
  'english': 'Lichen simplex chronicus',
  'vietnamese': 'Lichen đơn dạng mãn tính',
}, {
  'id': 'L28.1',
  'english': 'Prurigo nodularis',
  'vietnamese': 'Sẩn cục',
}, {'id': 'L28.2', 'english': 'Other prurigo', 'vietnamese': 'Sẩn ngứa khác'}, {
  'id': 'L29',
  'english': 'Pruritus',
  'vietnamese': 'Ngứa',
}, {'id': 'L29.0', 'english': 'Pruritus ani', 'vietnamese': 'Ngứa hậu môn'}, {
  'id': 'L29.1',
  'english': 'Pruritus scroti',
  'vietnamese': 'Ngứa bìu',
}, {'id': 'L29.2', 'english': 'Pruritus vulvae', 'vietnamese': 'Ngứa âm hộ'}, {
  'id': 'L29.3',
  'english': 'Anogenital pruritus, unspecified',
  'vietnamese': 'Ngứa hậu môn - sinh dục, không đặc hiệu',
}, {'id': 'L29.8', 'english': 'Other pruritus', 'vietnamese': 'Ngứa khác'}, {
  'id': 'L29.9',
  'english': 'Pruritus, unspecified',
  'vietnamese': 'Ngứa không đặc hiệu',
}, {'id': 'L30', 'english': 'Other dermatitis', 'vietnamese': 'Các viêm da khác'}, {
  'id': 'L30.0',
  'english': 'Nummular dermatitis',
  'vietnamese': 'Chàm đồng tiền',
}, {
  'id': 'L30.1',
  'english': 'Dyshidrosis [pompholyx]',
  'vietnamese': 'Tổ đỉa [Chàm dạng trứng sam]',
}, {'id': 'L30.2', 'english': 'Cutaneous autosensitization', 'vietnamese': 'Tự mẫn cảm da'}, {
  'id': 'L30.3',
  'english': 'Infective dermatitis',
  'vietnamese': 'Viêm da nhiễm trùng',
}, {'id': 'L30.4', 'english': 'Erythema intertrigo', 'vietnamese': 'Viêm kẽ'}, {
  'id': 'L30.5',
  'english': 'Pityriasis alba',
  'vietnamese': 'Vảy phấn trắng',
}, {
  'id': 'L30.8',
  'english': 'Other specified dermatitis',
  'vietnamese': 'Viêm da đặc hiệu khác',
}, {
  'id': 'L30.9',
  'english': 'Dermatitis, unspecified',
  'vietnamese': 'Viêm da, không đặc hiệu',
}, {'id': 'L40', 'english': 'Psoriasis', 'vietnamese': 'Vảy nến'}, {
  'id': 'L40.0',
  'english': 'Psoriasis vulgaris',
  'vietnamese': 'Vảy nến thông thường',
}, {
  'id': 'L40.1',
  'english': 'Generalized pustular psoriasis',
  'vietnamese': 'Vảy nến mủ toàn thân',
}, {
  'id': 'L40.2',
  'english': 'Acrodermatitis continua',
  'vietnamese': 'Viêm da đầu chi liên tục',
}, {
  'id': 'L40.3',
  'english': 'Pustulosis palmaris et plantaris',
  'vietnamese': 'Vảy nến thể mủ ở gan bàn tay - bàn chân',
}, {'id': 'L40.4', 'english': 'Guttate psoriasis', 'vietnamese': 'Vảy nến thể giọt'}, {
  'id': 'L40.5†',
  'english': 'Arthropathic psoriasis M07.0-M07.3 M09.0',
  'vietnamese': 'Vảy nến thể khớp M07.0- M07.3 M09.0',
}, {'id': 'L40.8', 'english': 'Other psoriasis', 'vietnamese': 'Vảy nến khác'}, {
  'id': 'L40.9',
  'english': 'Psoriasis, unspecified',
  'vietnamese': 'Vảy nến, không điển hình',
}, {'id': 'L41', 'english': 'Parapsoriasis', 'vietnamese': 'Á vảy nến'}, {
  'id': 'L41.0',
  'english': 'Pityriasis lichenoides et varioliformis acuta',
  'vietnamese': 'Vảy phấn dạng lichen và đậu mùa cấp tính',
}, {
  'id': 'L41.1',
  'english': 'Pityriasis lichenoides chronica',
  'vietnamese': 'Vảy phấn dạng lichen mãn tính',
}, {'id': 'L41.2', 'english': 'Lymphomatoid papulosis', 'vietnamese': 'Bệnh sẩn dạng lympho'}, {
  'id': 'L41.3',
  'english': 'Small plaque parapsoriasis',
  'vietnamese': 'Á vảy nến thể mảng nhỏ',
}, {
  'id': 'L41.4',
  'english': 'Large plaque parapsoriasis',
  'vietnamese': 'Á vảy nến thể mảng lớn',
}, {
  'id': 'L41.5',
  'english': 'Retiform parapsoriasis',
  'vietnamese': 'Á vảy nến dạng lưới',
}, {'id': 'L41.8', 'english': 'Other parapsoriasis', 'vietnamese': 'Á vảy nến khác'}, {
  'id': 'L41.9',
  'english': 'Parapsoriasis, unspecified',
  'vietnamese': 'Á vảy nến, không điển hình',
}, {'id': 'L42', 'english': 'Pityriasis', 'vietnamese': 'Vảy phấn hồng'}, {
  'id': 'L43',
  'english': 'Lichen planus',
  'vietnamese': 'Lichen phẳng',
}, {
  'id': 'L43.0',
  'english': 'Hypertrophic lichen planus',
  'vietnamese': 'Lichen phẳng phì đại',
}, {
  'id': 'L43.1',
  'english': 'Bullous lichen planus',
  'vietnamese': 'Lichen phẳng bọng nước',
}, {
  'id': 'L43.2',
  'english': 'Lichenoid drug reaction',
  'vietnamese': 'Phản ứng thuốc dạng lichen',
}, {
  'id': 'L43.3',
  'english': 'Subacute (active) lichen planus',
  'vietnamese': 'Lichen phẳng bán cấp (hoạt tính)',
}, {'id': 'L43.8', 'english': 'Other lichen planus', 'vietnamese': 'Lichen phẳng khác'}, {
  'id': 'L43.9',
  'english': 'Lichen planus, unspecified',
  'vietnamese': 'Lichen phẳng, không đặc hiệu',
}, {
  'id': 'L44',
  'english': 'Other papulosquamous disorders',
  'vietnamese': 'Các bệnh sẩn có vảy khác',
}, {
  'id': 'L44.0',
  'english': 'Pityriasis rubra pilaris',
  'vietnamese': 'Vảy phấn đỏ nang lông',
}, {
  'id': 'L44.1',
  'english': 'Lichen nitidus',
  'vietnamese': 'Lichen hình chuỗi hạt',
}, {'id': 'L44.2', 'english': 'Lichen striatus', 'vietnamese': 'Lichen thành dải'}, {
  'id': 'L44.3',
  'english': 'Lichen ruber moniliformis',
  'vietnamese': 'Lichen dạng vằn',
}, {
  'id': 'L44.4',
  'english': 'Infantile papular acrodermatitis [Giannotti-Crosti]',
  'vietnamese': 'Viêm da đầu chi dạng sẩn ở trẻ em [Glannotti-Crosti]',
}, {
  'id': 'L44.8',
  'english': 'Other specified papulosquamous disorders',
  'vietnamese': 'Các bệnh sẩn có vảy đặc hiệu khác',
}, {
  'id': 'L44.9',
  'english': 'Papulosquamous disorder, unspecified',
  'vietnamese': 'Các bệnh sẩn có vảy không điển hình',
}, {
  'id': 'L45*',
  'english': 'Papulosquamous disorders in diseases classified elsewhere',
  'vietnamese': 'Các bệnh sẩn có vảy trong các bệnh đã phân loại ở nơi khác',
}, {'id': 'L50', 'english': 'Urticaria', 'vietnamese': 'Mày đay'}, {
  'id': 'L50.0',
  'english': 'Allergic urticaria',
  'vietnamese': 'Mày đay dị ứng',
}, {'id': 'L50.1', 'english': 'Idiopathic urticaria', 'vietnamese': 'Mày đay tự phát'}, {
  'id': 'L50.2',
  'english': 'Urticaria due to cold and heat',
  'vietnamese': 'Mày đay do lạnh và nóng',
}, {'id': 'L50.3', 'english': 'Dermatographic urticaria', 'vietnamese': 'Da vẽ nổi'}, {
  'id': 'L50.4',
  'english': 'Vibratory urticaria',
  'vietnamese': 'mày đay do rung động',
}, {'id': 'L50.5', 'english': 'Cholinergic urticaria', 'vietnamese': 'Mày đay do Cholin'}, {
  'id': 'L50.6',
  'english': 'Contact urticaria',
  'vietnamese': 'Mày đay tiếp xúc',
}, {'id': 'L50.8', 'english': 'Other urticaria', 'vietnamese': 'Mày đay khác'}, {
  'id': 'L50.9',
  'english': 'Urticaria, unspecified',
  'vietnamese': 'Mày đay, không điển hình',
}, {'id': 'L51', 'english': 'Erythema multiforme', 'vietnamese': 'Hồng ban đa dạng'}, {
  'id': 'L51.0',
  'english': 'Nonbullous erythema multiforme',
  'vietnamese': 'Hồng ban đa dạng không có bọng nước',
}, {
  'id': 'L51.1',
  'english': 'Bullous erythema multiforme',
  'vietnamese': 'Hồng ban đa dạng có bọng nước',
}, {
  'id': 'L51.2',
  'english': 'Toxic epidermal necrolysis [Lyell]',
  'vietnamese': 'Hoại tử thượng bì nhiễm độc [Lyell]',
}, {
  'id': 'L51.8',
  'english': 'Other erythema multiforme',
  'vietnamese': 'Hồng ban đa dạng khác',
}, {
  'id': 'L51.9',
  'english': 'Erythema multiforme, unspecified',
  'vietnamese': 'Hồng ban đa dạng, không điển hình',
}, {'id': 'L52', 'english': 'Erythema nodosum', 'vietnamese': 'Hồng ban nút'}, {
  'id': 'L53',
  'english': 'Other erythematous conditions',
  'vietnamese': 'Các trạng thái hồng ban khác',
}, {'id': 'L53.0', 'english': 'Toxic erythema', 'vietnamese': 'Hồng ban do nhiễm độc'}, {
  'id': 'L53.1',
  'english': 'Erythema annulare centrifugum',
  'vietnamese': 'Hồng ban hình nhẫn',
}, {'id': 'L53.2', 'english': 'Erythema marginatum', 'vietnamese': 'Hồng ban hình bản đồ'}, {
  'id': 'L53.3',
  'english': 'Other chronic figurate erythema',
  'vietnamese': 'Hồng ban mãn tính khác',
}, {
  'id': 'L53.8',
  'english': 'Other specified erythematous conditions',
  'vietnamese': 'Các tình trạng hồng ban đặc hiệu khác',
}, {
  'id': 'L53.9',
  'english': 'Erythematous condition, unspecified',
  'vietnamese': 'Các tình trạng hồng ban không điển hình',
}, {
  'id': 'L54*',
  'english': 'Erythema in diseases classified elsewhere',
  'vietnamese': 'Hồng ban trong các bệnh đã phân loại ở phần khác',
}, {
  'id': 'L54.0*',
  'english': 'Erythema marginatum in acute rheumatic feverI00',
  'vietnamese': 'Hồng ban hình bản đồ trong thấp khớp cấp có sốt I00',
}, {
  'id': 'L54.8*',
  'english': 'Erythema in other diseases classified elsewhere',
  'vietnamese': 'Hồng ban trong các bệnh đã phân loại ở phần khác',
}, {'id': 'L55', 'english': 'Sunburn', 'vietnamese': 'Bỏng nắng'}, {
  'id': 'L55.0',
  'english': 'Sunburn of first degree',
  'vietnamese': 'Bỏng nắng độ một',
}, {'id': 'L55.1', 'english': 'Sunburn of second degree', 'vietnamese': 'Bỏng nắng độ hai'}, {
  'id': 'L55.2',
  'english': 'Sunburn of third degree',
  'vietnamese': 'Bỏng nắng độ ba',
}, {'id': 'L55.8', 'english': 'Other sunburn', 'vietnamese': 'Bỏng nắng khác'}, {
  'id': 'L55.9',
  'english': 'Sunburn, unspecified',
  'vietnamese': 'Bỏng nắng, không điển hình',
}, {
  'id': 'L56',
  'english': 'Other acute skin changes due to ultraviolet radiation',
  'vietnamese': 'Biến đổi da cấp tính khác do bức xạ tia cực tím',
}, {
  'id': 'L56.0',
  'english': 'Drug phototoxic response',
  'vietnamese': 'Nhiễm độc ánh sáng do thuốc',
}, {
  'id': 'L56.1',
  'english': 'Drug photoallergic response',
  'vietnamese': 'Dị ứng ánh sáng do thuốc',
}, {
  'id': 'L56.2',
  'english': 'Photocontact dermatitis [berloque dermatitis]',
  'vietnamese': 'Viêm da tiếp xúc ánh sáng [viêm da Berloque]',
}, {'id': 'L56.3', 'english': 'Solar urticaria', 'vietnamese': 'Mày đay do ánh nắng'}, {
  'id': 'L56.4',
  'english': 'Polymorphous light eruption',
  'vietnamese': 'Phát ban đa dạng do ánh sáng',
}, {
  'id': 'L56.8',
  'english': 'Other specified acute skin changes due to ultraviolet radiation',
  'vietnamese': 'Biến đổi da cấp tính đặc hiệu khác do tia cực tím',
}, {
  'id': 'L56.9',
  'english': 'Acute skin change due to ultraviolet radiation, unspecified',
  'vietnamese': 'Biến đổi da cấp tính do tia cực tím, không điển hình',
}, {
  'id': 'L57',
  'english': 'Skin changes due to chronic exposure to nonionizing radiation',
  'vietnamese': 'Bệnh da do tiếp xúc lâu dài với bức xạ không ion hóa',
}, {'id': 'L57.0', 'english': 'Actinic keratosis', 'vietnamese': 'Dày sừng ánh sáng'}, {
  'id': 'L57.1',
  'english': 'Actinic reticuloid',
  'vietnamese': 'Ban dạng lưới do ánh sáng',
}, {'id': 'L57.2', 'english': 'Cutis rhomboidalis nuchae', 'vietnamese': 'Bệnh dày da gáy'}, {
  'id': 'L57.3',
  'english': 'Poikiloderma of Civatte',
  'vietnamese': 'Bệnh teo da hình mạng lưới của Civatt',
}, {'id': 'L57.4', 'english': 'Cutis laxa senilis', 'vietnamese': 'Bệnh nhão da người già'}, {
  'id': 'L57.5',
  'english': 'Actinic granuloma',
  'vietnamese': 'U hạt do ánh sáng',
}, {
  'id': 'L57.8',
  'english': 'Other skin changes due to chronic exposure to nonionizing radiation',
  'vietnamese': 'Biển đổi khác ở da do tiếp xúc lâu dài với bức xạ không ion hóa',
}, {
  'id': 'L57.9',
  'english': 'Skin changes due to chronic exposure to nonionizing radiation, unspecified',
  'vietnamese': 'Biến đổi khác ở da do tiếp xúc lâu dài với bức xạ không ion hóa không điển hình',
}, {'id': 'L58', 'english': 'Radiodermatitis', 'vietnamese': 'Viêm da do quang tuyến'}, {
  'id': 'L58.0',
  'english': 'Acute radiodermatitis',
  'vietnamese': 'Viêm da do quang tuyến cấp tính',
}, {
  'id': 'L58.1',
  'english': 'Chronic radiodermatitis',
  'vietnamese': 'Viêm da do quang tuyến mãn tính',
}, {
  'id': 'L58.9',
  'english': 'Radiodermatitis, unspecified',
  'vietnamese': 'Viêm da do quang tuyến không điển hình',
}, {
  'id': 'L59',
  'english': 'Other disorders of skin and subcutaneous tissue related to radiation',
  'vietnamese': 'Các bệnh khác ở da và tổ chức dưới da liên quan đến bức xạ',
}, {
  'id': 'L59.0',
  'english': 'Erythema ab igne [dermatitis ab igne]',
  'vietnamese': 'Hồng ban nhiệt [viêm da do nhiệt]',
}, {
  'id': 'L59.8',
  'english': 'Other specified disorders of skin and subcutaneous tissue related to radiation',
  'vietnamese': 'Các bệnh đặc hiệu khác ở da và tổ chức dưới da liên quan đến bức xạ',
}, {
  'id': 'L59.9',
  'english': 'Disorder of skin and subcutaneous tissue related to radiation, unspecified',
  'vietnamese': 'Bệnh ở da và tổ chức dưới da liên quan đến bức xạ, không điển hình',
}, {'id': 'L60', 'english': 'Nail disorders', 'vietnamese': 'Các bệnh về móng'}, {
  'id': 'L60.0',
  'english': 'Ingrowing nail',
  'vietnamese': 'Móng chọc thịt',
}, {'id': 'L60.1', 'english': 'Onycholysis', 'vietnamese': 'Móng tách'}, {
  'id': 'L60.2',
  'english': 'Onychogryphosis',
  'vietnamese': 'Móng quặp',
}, {'id': 'L60.3', 'english': 'Nail dystrophy', 'vietnamese': 'Loạn dưỡng móng'}, {
  'id': 'L60.4',
  'english': 'Beau s lines',
  'vietnamese': 'Móng rãnh Beau',
}, {'id': 'L60.5', 'english': 'Yellow nail syndrome', 'vietnamese': 'Hội chứng vàng móng'}, {
  'id': 'L60.8',
  'english': 'Other nail disorders',
  'vietnamese': 'Các bệnh khác của móng',
}, {
  'id': 'L60.9',
  'english': 'Nail disorder, unspecified',
  'vietnamese': 'Bệnh móng khác, không điển hình',
}, {
  'id': 'L62*',
  'english': 'Nail disorders in diseases classified elsewhere',
  'vietnamese': 'Các bệnh móng trong các bệnh đã phân loại ở phần khác',
}, {
  'id': 'L62.0*',
  'english': 'Clubbed nail pachydermoperiostosisM89.4',
  'vietnamese': 'Móng tay dùi trống do tăng sinh màng xương M89.4',
}, {
  'id': 'L62.8*',
  'english': 'Nail disorders in other diseases classified elsewhere',
  'vietnamese': 'Các bệnh của móng trong các bệnh khác đã phân loại ở phần khác',
}, {'id': 'L63', 'english': 'Alopecia areata', 'vietnamese': 'Rụng tóc từng mảng'}, {
  'id': 'L63.0',
  'english': 'Alopecia (capitis) totalis',
  'vietnamese': 'Rụng tóc toàn thể',
}, {'id': 'L63.1', 'english': 'Alopecia universalis', 'vietnamese': 'Rụng lông tóc toàn bộ'}, {
  'id': 'L63.2',
  'english': 'Ophiasis',
  'vietnamese': 'Rụng tóc dạng dải',
}, {'id': 'L63.8', 'english': 'Other alopecia areata', 'vietnamese': 'Rụng tóc từng mảng khác'}, {
  'id': 'L63.9',
  'english': 'Alopecia areata, unspecified',
  'vietnamese': 'Rụng tóc từng mảng, không điển hình',
}, {
  'id': 'L64',
  'english': 'Androgenic alopecia',
  'vietnamese': 'Rụng tóc do nội tiết tố nam tính',
}, {
  'id': 'L64.0',
  'english': 'Drug-induced androgenic alopecia',
  'vietnamese': 'Rụng tóc do thuốc nội tiết tố nam',
}, {
  'id': 'L64.8',
  'english': 'Other androgenic alopecia',
  'vietnamese': 'Rụng tóc do nội tiết tố nam khác',
}, {
  'id': 'L64.9',
  'english': 'Androgenic alopecia, unspecified',
  'vietnamese': 'Rụng tóc do nội tiết tố nam, không đặc hiệu',
}, {
  'id': 'L65',
  'english': 'Other nonscarring hair loss',
  'vietnamese': 'Rụng tóc không sẹo khác',
}, {
  'id': 'L65.0',
  'english': 'Telogen effluvium',
  'vietnamese': 'Rụng tóc ở giai đoạn ngừng phát triển của tóc',
}, {
  'id': 'L65.1',
  'english': 'Anagen effluvium',
  'vietnamese': 'Rụng tóc ở giai đoạn đầu của sự phát triển tóc',
}, {
  'id': 'L65.2',
  'english': 'Alopecia mucinosa',
  'vietnamese': 'Rụng tóc do lắng đọng chất nhầy',
}, {
  'id': 'L65.8',
  'english': 'Other specified nonscarring hair loss',
  'vietnamese': 'Rụng tóc không do sẹo đặc hiệu khác',
}, {
  'id': 'L65.9',
  'english': 'Nonscarring hair loss, unspecified',
  'vietnamese': 'Rụng tóc không sẹo, không điển hình',
}, {
  'id': 'L66',
  'english': 'Cicatricial alopecia [scarring hair loss]',
  'vietnamese': 'Rụng tóc có sẹo',
}, {'id': 'L66.0', 'english': 'Pseudopelade', 'vietnamese': 'Giả rụng tóc pelade'}, {
  'id': 'L66.1',
  'english': 'Lichen planopilaris',
  'vietnamese': 'Lichen phẳng nang lông',
}, {
  'id': 'L66.2',
  'english': 'Folliculitis decalvans',
  'vietnamese': 'Viêm nang lông gây rụng tóc toàn thể',
}, {
  'id': 'L66.3',
  'english': 'Perifolliculitis capitis abscedens',
  'vietnamese': 'Viêm nang lông da đầu',
}, {
  'id': 'L66.4',
  'english': 'Folliculitis ulerythematosa reticulata',
  'vietnamese': 'Viêm nang lông hồng ban hình mạng lưới',
}, {
  'id': 'L66.8',
  'english': 'Other cicatricial alopecia',
  'vietnamese': 'Rụng tóc có sẹo khác',
}, {
  'id': 'L66.9',
  'english': 'Cicatricial alopecia, unspecified',
  'vietnamese': 'Rụng tóc có sẹo, không điển hình',
}, {
  'id': 'L67',
  'english': 'Hair colour and hair shaft abnormalities',
  'vietnamese': 'Bất thường về màu và sợi tóc',
}, {'id': 'L67.0', 'english': 'Trichorrhexis nodosa', 'vietnamese': 'Tóc giòn có nốt'}, {
  'id': 'L67.1',
  'english': 'Variations in hair colour',
  'vietnamese': 'Thay đổi màu tóc',
}, {
  'id': 'L67.8',
  'english': 'Other hair colour and hair shaft abnormalities',
  'vietnamese': 'Các bất thường khác về màu tóc sợi tóc',
}, {
  'id': 'L67.9',
  'english': 'Hair colour and hair shaft abnormality, unspecified',
  'vietnamese': 'Các bất thường về màu tóc và sợi tóc, không điển hình',
}, {'id': 'L68', 'english': 'Hypertrichosis', 'vietnamese': 'Rậm lông tóc'}, {
  'id': 'L68.0',
  'english': 'Hirsutism',
  'vietnamese': 'Rậm lông',
}, {
  'id': 'L68.1',
  'english': 'Acquired hypertrichosis lanuginosa',
  'vietnamese': 'Rậm lông tơ mắc phải',
}, {'id': 'L68.2', 'english': 'Localized hypertrichosis', 'vietnamese': 'Rậm lông khu trú'}, {
  'id': 'L68.3',
  'english': 'Polytrichia',
  'vietnamese': 'Đa nang tóc',
}, {'id': 'L68.8', 'english': 'Other hypertrichosis', 'vietnamese': 'Rậm lông tóc khác'}, {
  'id': 'L68.9',
  'english': 'Hypertrichosis, unspecified',
  'vietnamese': 'Rậm lông tóc, không điển hình',
}, {'id': 'L70', 'english': 'Acne', 'vietnamese': 'Trứng cá'}, {
  'id': 'L70.0',
  'english': 'Acne vulgaris',
  'vietnamese': 'Trứng cá thông thường',
}, {'id': 'L70.1', 'english': 'Acne conglobata', 'vietnamese': 'Trứng cá mạch lươn'}, {
  'id': 'L70.2',
  'english': 'Acne varioliformis',
  'vietnamese': 'Trứng cá dạng thủy đậu',
}, {'id': 'L70.3', 'english': 'Acne tropica', 'vietnamese': 'Trứng cá nhiệt đới'}, {
  'id': 'L70.4',
  'english': 'Infantile acne',
  'vietnamese': 'Trứng cá trẻ em',
}, {
  'id': 'L70.5',
  'english': 'Acné excoriée des jeunes filles',
  'vietnamese': 'Trứng cá trầy xước ở thiếu nữ',
}, {'id': 'L70.8', 'english': 'Other acne', 'vietnamese': 'Trứng cá khác'}, {
  'id': 'L70.9',
  'english': 'Acne, unspecified',
  'vietnamese': 'Trứng cá không điển hình',
}, {'id': 'L71', 'english': 'Rosacea', 'vietnamese': 'Trứng cá đỏ'}, {
  'id': 'L71.0',
  'english': 'Perioral dermatitis',
  'vietnamese': 'Viêm da quanh miệng',
}, {'id': 'L71.1', 'english': 'Rhinophyma', 'vietnamese': 'Mũi sư tử'}, {
  'id': 'L71.8',
  'english': 'Other rosacea',
  'vietnamese': 'Trứng cá đỏ khác',
}, {
  'id': 'L71.9',
  'english': 'Rosacea, unspecified',
  'vietnamese': 'Trứng cá đỏ, không điển hình',
}, {
  'id': 'L72',
  'english': 'Follicular cysts of skin and subcutaneous tissue',
  'vietnamese': 'Kén nang lông của da và tổ chức dưới da',
}, {'id': 'L72.0', 'english': 'Epidermal cyst', 'vietnamese': 'Kén thượng bì'}, {
  'id': 'L72.1',
  'english': 'Trichilemmal cyst',
  'vietnamese': 'Kén ở chân lông',
}, {'id': 'L72.2', 'english': 'Steatocystoma multiplex', 'vietnamese': 'Đa u tuyến bã'}, {
  'id': 'L72.8',
  'english': 'Other follicular cysts of skin and subcutaneous tissue',
  'vietnamese': 'Các u nang khác ở da và tổ chức dưới da',
}, {
  'id': 'L72.9',
  'english': 'Follicular cyst of skin and subcutaneous tissue, unspecified',
  'vietnamese': 'U nang lông ở da và tổ chức dưới da, không điển hình',
}, {
  'id': 'L73',
  'english': 'Other follicular disorders',
  'vietnamese': 'Các bệnh nang lông khác',
}, {
  'id': 'L73.0',
  'english': 'Acne keloid',
  'vietnamese': 'Sẹo lồi do trứng cá',
}, {
  'id': 'L73.1',
  'english': 'Pseudofolliculitis barbae',
  'vietnamese': 'Giả viêm nang lông ở cằm',
}, {
  'id': 'L73.2',
  'english': 'Hidradenitis suppurativa',
  'vietnamese': 'Viêm tuyến mồ hôi mủ (nhọt ổ gà)',
}, {
  'id': 'L73.8',
  'english': 'Other specified follicular disorders',
  'vietnamese': 'Các bệnh nang lông đặc hiệu khác',
}, {
  'id': 'L73.9',
  'english': 'Follicular disorder, unspecified',
  'vietnamese': 'Bệnh nang lông, không điển hình',
}, {
  'id': 'L74',
  'english': 'Eccrine sweat disorders',
  'vietnamese': 'Các bệnh của tuyến mồ hôi',
}, {
  'id': 'L74.0',
  'english': 'Miliaria rubra',
  'vietnamese': 'Rôm đỏ',
}, {'id': 'L74.1', 'english': 'Miliaria crystallina', 'vietnamese': 'Rôm mụn nước'}, {
  'id': 'L74.2',
  'english': 'Miliaria profunda',
  'vietnamese': 'Rôm sâu',
}, {'id': 'L74.3', 'english': 'Miliaria, unspecified', 'vietnamese': 'Rôm không điển hình'}, {
  'id': 'L74.4',
  'english': 'Anhidrosis',
  'vietnamese': 'Không tiết mồ hôi',
}, {
  'id': 'L74.8',
  'english': 'Other eccrine sweat disorders',
  'vietnamese': 'Các bệnh khác của tuyến mồ hôi',
}, {
  'id': 'L74.9',
  'english': 'Eccrine sweat disorder, unspecified',
  'vietnamese': 'Các bệnh tuyến mồ hôi, không điển hình',
}, {
  'id': 'L75',
  'english': 'Apocrine sweat disorders',
  'vietnamese': 'Bệnh tuyến mồ hôi bán hủy (nội tiết)',
}, {'id': 'L75.0', 'english': 'Bromhidrosis', 'vietnamese': 'Mồ hôi có mùi'}, {
  'id': 'L75.1',
  'english': 'Chromhidrosis',
  'vietnamese': 'Mồ hôi có màu',
}, {'id': 'L75.2', 'english': 'Apocrine miliaria', 'vietnamese': 'Rôm tuyến mồ hôi bán hủy'}, {
  'id': 'L75.8',
  'english': 'Other apocrine sweat disorders',
  'vietnamese': 'Các bệnh tuyến mồ hôi bán hủy khác',
}, {
  'id': 'L75.9',
  'english': 'Apocrine sweat disorder, unspecified',
  'vietnamese': 'Các bệnh tuyến mồ hôi bán hủy, không điển hình',
}, {'id': 'L80', 'english': 'Vitiligo', 'vietnamese': 'Bạch biến'}, {
  'id': 'L81',
  'english': 'Other disorders of pigmentation',
  'vietnamese': 'Bệnh rối loạn sắc tố khác',
}, {
  'id': 'L81.0',
  'english': 'Postinflammatory hyperpigmentation',
  'vietnamese': 'Tăng sắc tố sau viêm',
}, {'id': 'L81.1', 'english': 'Chloasma', 'vietnamese': 'Rám má'}, {
  'id': 'L81.2',
  'english': 'Freckles',
  'vietnamese': 'Tàn nhang',
}, {'id': 'L81.3', 'english': 'Café au lait spots', 'vietnamese': 'Dát cà phê sữa'}, {
  'id': 'L81.4',
  'english': 'Other melanin hyperpigmentation',
  'vietnamese': 'Bệnh tăng sắc tố do melanin khác',
}, {
  'id': 'L81.5',
  'english': 'Leukoderma, not elsewhere classified',
  'vietnamese': 'Bệnh da mất sắc tố chưa được phân loại ở phần khác',
}, {
  'id': 'L81.6',
  'english': 'Other disorders of diminished melanin formation',
  'vietnamese': 'Các bệnh khác do giảm sản xuất melanin',
}, {
  'id': 'L81.7',
  'english': 'Pigmented purpuric dermatosis',
  'vietnamese': 'Bệnh da xuất huyết tăng sắc tố',
}, {
  'id': 'L81.8',
  'english': 'Other specified disorders of pigmentation',
  'vietnamese': 'Bệnh sắc tố đặc hiệu khác',
}, {
  'id': 'L81.9',
  'english': 'Disorder of pigmentation, unspecified',
  'vietnamese': 'Bệnh sắc tố không đặc hiệu',
}, {'id': 'L82', 'english': 'Seborrhoeic keratosis', 'vietnamese': 'Dày sừng da dầu'}, {
  'id': 'L83',
  'english': 'Acanthosis nigricans',
  'vietnamese': 'Bệnh gai đen',
}, {'id': 'L84', 'english': 'Corns and callosities', 'vietnamese': 'Mắt cá và chai chân'}, {
  'id': 'L85',
  'english': 'Other epidermal thickening',
  'vietnamese': 'Dày thượng bì khác',
}, {'id': 'L85.0', 'english': 'Acquired ichthyosis', 'vietnamese': 'Bệnh da vảy cá mắc phải'}, {
  'id': 'L85.1',
  'english': 'Acquired keratosis [keratoderma] palmaris et plantaris',
  'vietnamese': 'Bệnh dày sừng mắc phải [da dày] ở lòng bàn tay và bàn chân',
}, {
  'id': 'L85.2',
  'english': 'Keratosis punctata (palmaris et plantaris)',
  'vietnamese': 'Dày sừng chấm (lòng bàn tay - bàn chân)',
}, {'id': 'L85.3', 'english': 'Xerosis cutis', 'vietnamese': 'Khô da'}, {
  'id': 'L85.8',
  'english': 'Other specified epidermal thickening',
  'vietnamese': 'Dày thượng bì đặc hiệu khác',
}, {
  'id': 'L85.9',
  'english': 'Epidermal thickening, unspecified',
  'vietnamese': 'Dày thượng bì, không điển hình',
}, {
  'id': 'L86*',
  'english': 'Keratoderma in diseases classified elsewhere',
  'vietnamese': 'Dày sừng trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'L87',
  'english': 'Transepidermal elimination disorders',
  'vietnamese': 'Các bệnh loại bỏ dị vật qua thượng bì',
}, {
  'id': 'L87.0',
  'english': 'Keratosis follicularis et parafollicularis in cutem penetrans [Kyrle]',
  'vietnamese': 'Dày sừng nang lông và quanh nang lông [Bệnh Kyrle]',
}, {
  'id': 'L87.1',
  'english': 'Reactive perforating collagenosis',
  'vietnamese': 'Bệnh tạo keo đục lỗ phản ứng',
}, {
  'id': 'L87.2',
  'english': 'Elastosis perforans serpiginosa',
  'vietnamese': 'Bệnh sợi chun đục lỗ ngoằn ngoèo',
}, {
  'id': 'L87.8',
  'english': 'Other transepidermal elimination disorders',
  'vietnamese': 'Các bệnh loại bỏ dị vật qua thượng bì khác',
}, {
  'id': 'L87.9',
  'english': 'Transepidermal elimination disorder, unspecified',
  'vietnamese': 'Các bệnh loại bỏ dị vật qua thượng bì, không điển hình',
}, {'id': 'L88', 'english': 'Pyoderma gangrenosum', 'vietnamese': 'Viêm da mủ hoại thư'}, {
  'id': 'L89',
  'english': 'Decubitus ulcer and pressure area',
  'vietnamese': 'Loét nằm và vùng đè ép',
}, {
  'id': 'L89.0',
  'english': 'Stage I decubitus ulcer and pressure area',
  'vietnamese': 'Loét nằm và vùng đè ép giai đoạn I',
}, {
  'id': 'L89.1',
  'english': 'Stage II decubitus ulcer',
  'vietnamese': 'Loét nằm giai đoạn II',
}, {
  'id': 'L89.2',
  'english': 'Stage III decubitus ulcer',
  'vietnamese': 'Loét nằm giai đoạn III',
}, {
  'id': 'L89.3',
  'english': 'Stage IV decubitus ulcer',
  'vietnamese': 'Loét nằm giai đoạn IV',
}, {
  'id': 'L89.9',
  'english': 'Decubitus ulcer and pressure area, unspecified',
  'vietnamese': 'Loét nằm và vùng đè ép, không đặc hiệu',
}, {'id': 'L90', 'english': 'Atrophic disorders of skin', 'vietnamese': 'Teo da'}, {
  'id': 'L90.0',
  'english': 'Lichen sclerosus et atrophicus',
  'vietnamese': 'Lichen xơ teo',
}, {
  'id': 'L90.1',
  'english': 'Anetoderma of Schweninger-Buzzi',
  'vietnamese': 'Bệnh teo da Schweninger-Buzzi',
}, {
  'id': 'L90.2',
  'english': 'Anetoderma of Jadassohn-Pellizzari',
  'vietnamese': 'Bệnh teo da Jadassohn - Pellizzari',
}, {
  'id': 'L90.3',
  'english': 'Atrophoderma of Pasini and Pierini',
  'vietnamese': 'Bệnh teo nhão da Pasinivà Pierini',
}, {
  'id': 'L90.4',
  'english': 'Acrodermatitis chronica atrophicans',
  'vietnamese': 'Viêm da đầu chi teo mãn tính',
}, {
  'id': 'L90.5',
  'english': 'Scar conditions and fibrosis of skin',
  'vietnamese': 'Các bệnh sẹo và xơ hóa của da',
}, {'id': 'L90.6', 'english': 'Striae atrophicae', 'vietnamese': 'Rạn da teo'}, {
  'id': 'L90.8',
  'english': 'Other atrophic disorders of skin',
  'vietnamese': 'Các bệnh teo da khác',
}, {
  'id': 'L90.9',
  'english': 'Atrophic disorder of skin, unspecified',
  'vietnamese': 'Teo da, không điển hình',
}, {
  'id': 'L91',
  'english': 'Hypertrophic disorders of skin',
  'vietnamese': 'Các bệnh quá sản của da',
}, {'id': 'L91.0', 'english': 'Keloid scar', 'vietnamese': 'Sẹo phì đại'}, {
  'id': 'L91.8',
  'english': 'Other hypertrophic disorders of skin',
  'vietnamese': 'Các bệnh quá sản khác của da',
}, {
  'id': 'L91.9',
  'english': 'Hypertrophic disorder of skin, unspecified',
  'vietnamese': 'Các bệnh quá sản của da không điển hình',
}, {
  'id': 'L92',
  'english': 'Granulomatous disorders of skin and subcutaneous tissue',
  'vietnamese': 'U hạt của da và tổ chức dưới da',
}, {'id': 'L92.0', 'english': 'Granuloma annulare', 'vietnamese': 'U hạt hình nhẫn'}, {
  'id': 'L92.1',
  'english': 'Necrobiosis lipoidica, not elsewhere classified',
  'vietnamese': 'Hoại tử mỡ, chưa phân loại ở phần khác',
}, {
  'id': 'L92.2',
  'english': 'Granuloma faciale [eosinophilic granuloma of skin]',
  'vietnamese': 'U hạt ở mặt [u hạt nhiễm bạch ái toan của da]',
}, {
  'id': 'L92.3',
  'english': 'Foreign body granuloma of skin and subcutaneous tissue',
  'vietnamese': 'U hạt ở da và tổ chức dưới da do dị vật',
}, {
  'id': 'L92.8',
  'english': 'Other granulomatous disorders of skin and subcutaneous tissue',
  'vietnamese': 'Các bệnh u hạt ở da và tổ chức dưới da khác',
}, {
  'id': 'L92.9',
  'english': 'Granulomatous disorder of skin and subcutaneous tissue, unspecified',
  'vietnamese': 'Các bệnh u hạt ở da và tổ chức dưới da, không điển hình',
}, {'id': 'L93', 'english': 'Lupus erythematosus', 'vietnamese': 'Lupus ban đỏ'}, {
  'id': 'L93.0',
  'english': 'Discoid lupus erythematosus',
  'vietnamese': 'Lupus ban đỏ dạng đĩa',
}, {
  'id': 'L93.1',
  'english': 'Subacute cutaneous lupus erythematosus',
  'vietnamese': 'Lupus ban đỏ bán cấp',
}, {
  'id': 'L93.2',
  'english': 'Other local lupus erythematosus',
  'vietnamese': 'Lupus ban đỏ khu trú khác',
}, {
  'id': 'L94',
  'english': 'Other localized connective tissue disorders',
  'vietnamese': 'Các bệnh tổ chức liên kết khu trú khác',
}, {
  'id': 'L94.0',
  'english': 'Localized scleroderma [morphea]',
  'vietnamese': 'Xơ cứng bì khu trú [dạng mảng]',
}, {'id': 'L94.1', 'english': 'Linear scleroderma', 'vietnamese': 'Xơ cứng bì dạng dải'}, {
  'id': 'L94.2',
  'english': 'Calcinosis cutis',
  'vietnamese': 'Lắng đọng Calci ở da',
}, {'id': 'L94.3', 'english': 'Sclerodactyly', 'vietnamese': 'Xơ cứng ngón'}, {
  'id': 'L94.4',
  'english': 'Gottron s papules',
  'vietnamese': 'Sẩn Gottron',
}, {
  'id': 'L94.5',
  'english': 'Poikiloderma vasculare atrophicans',
  'vietnamese': 'Bệnh teo da dạng mạng lưới giãn mạch',
}, {'id': 'L94.6', 'english': 'Ainhum', 'vietnamese': 'Bệnh Ainhum'}, {
  'id': 'L94.8',
  'english': 'Other specified localized connective tissue disorders',
  'vietnamese': 'Các bệnh tổ chức liên kết khu trú đặc hiệu khác',
}, {
  'id': 'L94.9',
  'english': 'Localized connective tissue disorder, unspecified',
  'vietnamese': 'Các bệnh tổ chức liên kết khu trú, không điển hình',
}, {
  'id': 'L95',
  'english': 'Vasculitis limited to skin, not elsewhere classified',
  'vietnamese': 'Viêm mao mạch ở da, chưa phân loại ở phần khác',
}, {'id': 'L95.0', 'english': 'Livedoid vasculitis', 'vietnamese': 'Viêm mạch mạng lưới'}, {
  'id': 'L95.1',
  'english': 'Erythema elevatum diutinum',
  'vietnamese': 'Ban đỏ nổi cục rắn',
}, {
  'id': 'L95.8',
  'english': 'Other vasculitis limited to skin',
  'vietnamese': 'Viêm mao mạch ở da',
}, {
  'id': 'L95.9',
  'english': 'Vasculitis limited to skin, unspecified',
  'vietnamese': 'Viêm mao mạch ở da, không điển hình',
}, {
  'id': 'L97',
  'english': 'Ulcer of lower limb, not elsewhere classified',
  'vietnamese': 'Loét chi dưới, chưa phân loại ở phần khác',
}, {
  'id': 'L98',
  'english': 'Other disorders of skin and subcutaneous tissue, not elsewhere classified',
  'vietnamese': 'Bệnh khác của da và mô dưới da chưa được phân loại ở phần khác',
}, {'id': 'L98.0', 'english': 'Pyogenic granuloma', 'vietnamese': 'U hạt sinh mủ'}, {
  'id': 'L98.1',
  'english': 'Factitial dermatitis',
  'vietnamese': 'Viêm da tự tạo',
}, {
  'id': 'L98.2',
  'english': 'Febrile neutrophilic dermatosis [Sweet]',
  'vietnamese': 'Bệnh da tăng bạch cầu trung tính có sốt [Hội chứng Sweet]',
}, {
  'id': 'L98.3',
  'english': 'Eosinophilic cellulitis [Wells]',
  'vietnamese': 'Viêm mô bào tăng bạch cầu ái toan [Hội chứng Wells]',
}, {
  'id': 'L98.4',
  'english': 'Chronic ulcer of skin, not elsewhere classified',
  'vietnamese': 'Loét da mãn tính, chưa phân loại ở phần khác',
}, {'id': 'L98.5', 'english': 'Mucinosis of skin', 'vietnamese': 'Bệnh thoái hóa nhày ở da'}, {
  'id': 'L98.6',
  'english': 'Other infiltrative disorders of skin and subcutaneous tissue',
  'vietnamese': 'Các bệnh có thâm nhiễm khác ở da và tổ chức dưới da',
}, {
  'id': 'L98.8',
  'english': 'Other specified disorders of skin and subcutaneous tissue',
  'vietnamese': 'Bệnh đặc hiệu khác ở da và tổ chức dưới da',
}, {
  'id': 'L98.9',
  'english': 'Disorder of skin and subcutaneous tissue, unspecified',
  'vietnamese': 'Bệnh ở da và tổ chức dưới da, không điển hình',
}, {
  'id': 'L99*',
  'english': 'Other disorders of skin and subcutaneous tissue in diseases classified elsewhere',
  'vietnamese': 'Bệnh khác của da và tổ chức dưới da trong các bệnh đã phân loại ở phần khác',
}, {
  'id': 'L99.0*',
  'english': 'Amyloidosis of skin (E85.-†)',
  'vietnamese': 'Thoái hóa dạng bột ở da (E85.-†)',
}, {
  'id': 'L99.8*',
  'english': 'Other specified disorders of skin and subcutaneous tissue in diseases classified elsewhere',
  'vietnamese': 'Bệnh ở da và tổ chức dưới da đặc hiệu khác trong các bệnh đã phân loại ở phần khác',
}, {
  'id': 'M00',
  'english': 'Pyogenic arthritis',
  'vietnamese': 'Viêm khớp nhiễm khuẩn do vi khuẩn sinh mủ',
}, {
  'id': 'M00.0',
  'english': 'Staphylococcal arthritis and polyarthritis',
  'vietnamese': 'Viêm khớp và viêm đa khớp do tụ cầu khuẩn',
}, {
  'id': 'M00.1',
  'english': 'Pneumococcal arthritis and polyarthritis',
  'vietnamese': 'Viêm khớp và viêm đa khớp do phế cầu',
}, {
  'id': 'M00.2',
  'english': 'Other streptococcal arthritis and polyarthritis',
  'vietnamese': 'Viêm khớp và viêm đa khớp khác do liên cầu',
}, {
  'id': 'M00.8',
  'english': 'Arthritis and polyarthritis due to other specified bacterial agents',
  'vietnamese': 'Viêm khớp và đa khớp do các vi khuẩn đặc hiệu khác',
}, {
  'id': 'M00.9',
  'english': 'Pyogenic arthritis, unspecified',
  'vietnamese': 'Viêm khớp do vi khuẩn sinh mủ không đặc hiệu',
}, {
  'id': 'M01*',
  'english': 'Direct infections of joint in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm khớp nhiễm khuẩn trực tiếp do các bệnh nhiễm khuẩn và ký sinh vật đã được xếp loại ở mục khác',
}, {
  'id': 'M01.0*',
  'english': 'Meningococcal arthritis (A39.8†)',
  'vietnamese': 'Viêm khớp do não mô cầu (A39.8†)',
}, {
  'id': 'M01.1*',
  'english': 'Tuberculous arthritis (A18.0†)',
  'vietnamese': 'Viêm khớp do lao (A18.0†)',
}, {
  'id': 'M01.2*',
  'english': 'Arthritis in Lyme disease (A69.2†)',
  'vietnamese': 'Viêm khớp trong bệnh Lyme (A69.2†)',
}, {
  'id': 'M01.3*',
  'english': 'Arthritis in other bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm khớp trong các bệnh nhiễm khuẩn được xếp loại ở mục khác',
}, {
  'id': 'M01.4*',
  'english': 'Rubella arthritis (B06.8†)',
  'vietnamese': 'Viêm khớp trong bệnh Rubêôn  (B06.8†)',
}, {
  'id': 'M01.5*',
  'english': 'Arthritis in other viral diseases classified elsewhere',
  'vietnamese': 'Viêm khớp trong nhiễm một số virus đã được xếp loại ở mục khác',
}, {
  'id': 'M01.6*',
  'english': 'Arthritis in mycoses (B35-B49†)',
  'vietnamese': 'Viêm khớp do nấm (B35-B49†)',
}, {
  'id': 'M01.8*',
  'english': 'Arthritis in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm khớp trong một số bệnh nhiễm khuẩn và ký sinh vật được xếp loại ở mục khác',
}, {'id': 'M02', 'english': 'Reactive arthropathies', 'vietnamese': 'Viêm khớp phản ứng'}, {
  'id': 'M02.0',
  'english': 'Arthropathy following intestinal bypass',
  'vietnamese': 'Bệnh khớp sau mổ chuyển đoạn ruột',
}, {'id': 'M02.1', 'english': 'Postdysenteric arthropathy', 'vietnamese': 'Bệnh khớp sau lỵ'}, {
  'id': 'M02.2',
  'english': 'Postimmunization arthropathy',
  'vietnamese': 'Bệnh khớp sau tiêm vaccin',
}, {'id': 'M02.3', 'english': 'Reiter s disease', 'vietnamese': 'Hội chứng Reiter'}, {
  'id': 'M02.8',
  'english': 'Other reactive arthropathies',
  'vietnamese': 'Bệnh viêm khớp phản ứng khác',
}, {
  'id': 'M02.9',
  'english': 'Reactive arthropathy, unspecified',
  'vietnamese': 'Bệnh viêm khớp phản ứng không đặc hiệu',
}, {
  'id': 'M03*',
  'english': 'Postinfective and reactive arthropathies in diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp sau nhiễm khuẩn và bệnh khớp phản ứng được xếp loại ở mục khác',
}, {
  'id': 'M03.0*',
  'english': 'Postmeningococcal arthritis (A39.8†)',
  'vietnamese': 'Viêm khớp sau nhiễm não mô cầu (A39.8†)',
}, {
  'id': 'M03.1*',
  'english': 'Postinfective arthropathy in syphilis',
  'vietnamese': 'Bệnh khớp sau nhiễm giang mai',
}, {
  'id': 'M03.2*',
  'english': 'Other postinfectious arthropathies in diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp sau nhiễm khuẩn khác được xếp loại ở mục khác',
}, {
  'id': 'M03.6*',
  'english': 'Reactive arthropathy in other diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp phản ứng sau một số bệnh được xếp loại ở mục khác',
}, {
  'id': 'M05',
  'english': 'Seropositive rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp huyết thanh dương tính',
}, {'id': 'M05.0', 'english': 'Felty s syndrome', 'vietnamese': 'Hội chứng Felty'}, {
  'id': 'M05.1†',
  'english': 'Rheumatoid lung disease (J99.0*)',
  'vietnamese': 'Tổn thương phổi trong các bệnh lý thấp khớp (J99.0*)',
}, {
  'id': 'M05.2',
  'english': 'Rheumatoid vasculitis',
  'vietnamese': 'Viêm mạch trong bệnh lý thấp khớp',
}, {
  'id': 'M05.3†',
  'english': 'Rheumatoid arthritis with involvement of other organs and systems',
  'vietnamese': 'Viêm khớp dạng thấp có tổn thương các tạng liên quan',
}, {
  'id': 'M05.8',
  'english': 'Other seropositive rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp huyết thanh dương tính khác',
}, {
  'id': 'M05.9',
  'english': 'Seropositive rheumatoid arthritis, unspecified',
  'vietnamese': 'Viêm khớp dạng thấp huyết thanh dương tính không đặc hiệu',
}, {
  'id': 'M06',
  'english': 'Other rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp khác',
}, {
  'id': 'M06.0',
  'english': 'Seronegative rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp huyết thanh âm tính',
}, {
  'id': 'M06.1',
  'english': 'Adult-onset Still s disease',
  'vietnamese': 'Bệnh Still khởi phát ở người lớn',
}, {
  'id': 'M06.2',
  'english': 'Rheumatoid bursitis',
  'vietnamese': 'Viêm bao hoạt dịch do thấp',
}, {
  'id': 'M06.3',
  'english': 'Rheumatoid nodule',
  'vietnamese': 'Hạt (nốt) thấp dưới da',
}, {'id': 'M06.4', 'english': 'Inflammatory polyarthropathy', 'vietnamese': 'Viêm nhiều khớp'}, {
  'id': 'M06.8',
  'english': 'Other specified rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp đặc hiệu khác',
}, {
  'id': 'M06.9',
  'english': 'Rheumatoid arthritis, unspecified',
  'vietnamese': 'Viêm khớp dạng thấp không đặc hiệu',
}, {
  'id': 'M07*',
  'english': 'Psoriatic and enteropathic arthropathies',
  'vietnamese': 'Bệnh khớp vẩy nến và bệnh đường ruột',
}, {
  'id': 'M07.0*',
  'english': 'Distal interphalangeal psoriatic arthropathy (L40.5†)',
  'vietnamese': 'Bệnh khớp vẩy nến có tổn thương khớp ngón xa (L40.5†)',
}, {
  'id': 'M07.1*',
  'english': 'Arthritis mutilans (L40.5†)',
  'vietnamese': 'Viêm khớp có hủy khớp (L40.5†)',
}, {
  'id': 'M07.2*',
  'english': 'Psoriatic spondylitis (L40.5†)',
  'vietnamese': 'Viêm cột sống vẩy nến (L40.5†)',
}, {
  'id': 'M07.3*',
  'english': 'Other psoriatic arthropathies (L40.5†)',
  'vietnamese': 'Bệnh viêm khớp vẩy nến khác (L40.5†)',
}, {
  'id': 'M07.4',
  'english': 'Arthropathy in Crohn s disease [regional enteritis] (K50.-†)',
  'vietnamese': 'Bệnh khớp trong bệnh Crohn [Viêm đoạn ruột] (K50.-†)',
}, {
  'id': 'M07.5',
  'english': 'Arthropathy in ulcerative colitis (K51.-†)',
  'vietnamese': 'Bệnh khớp trong viêm loét đại tràng (K51.-†)',
}, {
  'id': 'M07.6',
  'english': 'Other enteropathic arthropathies',
  'vietnamese': 'Bệnh khớp trong bệnh đường ruột khác',
}, {'id': 'M08', 'english': 'Juvenile arthritis', 'vietnamese': 'Viêm khớp trẻ em'}, {
  'id': 'M08.0',
  'english': 'Juvenile rheumatoid arthritis',
  'vietnamese': 'Viêm khớp dạng thấp trẻ em',
}, {
  'id': 'M08.1',
  'english': 'Juvenile ankylosing spondylitis',
  'vietnamese': 'Viêm cột sống dính khớp trẻ em',
}, {
  'id': 'M08.2',
  'english': 'Juvenile arthritis with systemic onset',
  'vietnamese': 'Viêm khớp trẻ em thể khởi đầu hệ thống',
}, {
  'id': 'M08.3',
  'english': 'Juvenile polyarthritis (seronegative)',
  'vietnamese': 'Viêm khớp trẻ em huyết thanh âm tính',
}, {
  'id': 'M08.4',
  'english': 'Pauciarticular juvenile arthritis',
  'vietnamese': 'Viêm khớp trẻ em thể ít khớp',
}, {
  'id': 'M08.8',
  'english': 'Other juvenile arthritis',
  'vietnamese': 'Các viêm khớp trẻ em khác',
}, {
  'id': 'M08.9',
  'english': 'Juvenile arthritis, unspecified',
  'vietnamese': 'Các viêm khớp trẻ em không đặc hiệu',
}, {
  'id': 'M09*',
  'english': 'Juvenile arthritis in diseases classified elsewhere',
  'vietnamese': 'Viêm khớp thiếu niên trong các bệnh được xếp ở mục khác',
}, {
  'id': 'M09.0*',
  'english': 'Juvenile arthritis in psoriasis (K50.-†)',
  'vietnamese': 'Viêm khớp thiếu niên sau vẩy nến (K50.-†)',
}, {
  'id': 'M09.1*',
  'english': 'Juvenile arthritis in Crohn s disease [regional enteritis] (K50.-†)',
  'vietnamese': 'Viêm khớp trẻ em trong bệnh Crohn (viêm ruột đoạn] (K50.-†)',
}, {
  'id': 'M09.2*',
  'english': 'Juvenile arthritis in ulcerative colitis (K51.-†)',
  'vietnamese': 'Viêm khớp trẻ em trong bệnh viêm loét đại tràng (K51.-†)',
}, {
  'id': 'M09.8*',
  'english': 'Juvenile arthritis in other diseases classified elsewhere',
  'vietnamese': 'Viêm khớp trẻ em trong các bệnh được xếp ở mục khác',
}, {'id': 'M10', 'english': 'Gout', 'vietnamese': 'Gút (thống phong)'}, {
  'id': 'M10.0',
  'english': 'Idiopathic gout',
  'vietnamese': 'Bệnh Gút vô căn',
}, {'id': 'M10.1', 'english': 'Lead-induced gout', 'vietnamese': 'Bệnh Gút do ngộ độc chì'}, {
  'id': 'M10.2',
  'english': 'Drug-induced gout',
  'vietnamese': 'Bệnh Gút do thuốc',
}, {
  'id': 'M10.3',
  'english': 'Gout due to impairment of renal function',
  'vietnamese': 'Bệnh Gút do thương tổn chức năng thận',
}, {'id': 'M10.4', 'english': 'Other secondary gout', 'vietnamese': 'Bệnh Gút thứ phát khác'}, {
  'id': 'M10.9',
  'english': 'Gout, unspecified',
  'vietnamese': 'Bệnh Gút không đặc hiệu',
}, {
  'id': 'M11',
  'english': 'Other crystal arthropathies',
  'vietnamese': 'Các bệnh khớp khác do vi tinh thể',
}, {
  'id': 'M11.0',
  'english': 'Hydroxyapatite deposition disease',
  'vietnamese': 'Bệnh do lắng đọng hydroxyapatit',
}, {
  'id': 'M11.1',
  'english': 'Familial chondrocalcinosis',
  'vietnamese': 'Bệnh vôi hóa sụn có tính chất gia đình',
}, {'id': 'M11.2', 'english': 'Other chondrocalcinosis', 'vietnamese': 'Bệnh vôi hóa sụn khác'}, {
  'id': 'M11.8',
  'english': 'Other specified crystal arthropathies',
  'vietnamese': 'Bệnh khớp khác do các vi tinh thể được xác định',
}, {
  'id': 'M11.9',
  'english': 'Crystal arthropathy, unspecified',
  'vietnamese': 'Bệnh khớp do vi tinh thể không đặc hiệu',
}, {
  'id': 'M12',
  'english': 'Other specific arthropathies',
  'vietnamese': 'Các bệnh khớp đặc hiệu khác',
}, {
  'id': 'M12.0',
  'english': 'Chronic postrheumatic arthropathy [Jaccoud]',
  'vietnamese': 'Bệnh khớp mãn tính sau thấp [Jaccoud]',
}, {'id': 'M12.1', 'english': 'Kaschin-Beck disease', 'vietnamese': 'Bệnh Kaschin-Beck'}, {
  'id': 'M12.2',
  'english': 'Villonodular synovitis (pigmented)',
  'vietnamese': 'Viêm màng hoạt dịch thể lông nốt (sắc tố)',
}, {
  'id': 'M12.3',
  'english': 'Palindromic rheumatism',
  'vietnamese': 'Bệnh thấp khớp thóang qua',
}, {
  'id': 'M12.4',
  'english': 'Intermittent hydrarthrosis',
  'vietnamese': 'Tràn dịch khớp hay tái phát',
}, {
  'id': 'M12.5',
  'english': 'Traumatic arthropathy',
  'vietnamese': 'Bệnh khớp do chấn thương',
}, {
  'id': 'M12.8',
  'english': 'Other specific arthropathies, not elsewhere classified',
  'vietnamese': 'Các bệnh khớp đặc hiệu khác không được xếp loại ở mục khác',
}, {'id': 'M13', 'english': 'Other arthritis', 'vietnamese': 'Các viêm khớp khác'}, {
  'id': 'M13.0',
  'english': 'Polyarthritis, unspecified',
  'vietnamese': 'Viêm đa khớp không đặc hiệu',
}, {
  'id': 'M13.1',
  'english': 'Monoarthritis, not elsewhere classified',
  'vietnamese': 'Viêm một khớp không được xếp loại ở mục khác',
}, {
  'id': 'M13.8',
  'english': 'Other specified arthritis',
  'vietnamese': 'Các viêm khớp đặc hiệu khác',
}, {
  'id': 'M13.9',
  'english': 'Arthritis, unspecified',
  'vietnamese': 'Viêm khớp không đặc hiệu',
}, {
  'id': 'M14*',
  'english': 'Arthropathies in other diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp trong các bệnh không được xếp loại ở mục khác',
}, {
  'id': 'M14.0*',
  'english': 'Gouty arthropathy due to enzyme defects and other inherited disorders',
  'vietnamese': 'Bệnh khớp gút do thiếu men và các bệnh lý di truyền khác',
}, {
  'id': 'M14.1*',
  'english': 'Crystal arthropathy in other metabolic disorders',
  'vietnamese': 'Bệnh khớp do vi tinh thể sau các rối loạn chuyển hóa được xếp loại ở mục khác',
}, {
  'id': 'M14.2*',
  'english': 'Diabetic arthropathy (E10-E14 with common fourth character .6†)',
  'vietnamese': 'Bệnh khớp do đái tháo đường đường (E10-E14 với ký tự thứ tư chung .6†)',
}, {
  'id': 'M14.3*',
  'english': 'Lipoid dermatoarthritis (E78.8†)',
  'vietnamese': 'Viêm khớp và da nhiễm mỡ (E78.8†)',
}, {
  'id': 'M14.4*',
  'english': 'Arthropathy in amyloidosis (E85.-†)',
  'vietnamese': 'Bệnh khớp nhiễm bột (E85.-†)',
}, {
  'id': 'M14.5*',
  'english': 'Arthropathies in other endocrine, nutritional and metabolic disorders',
  'vietnamese': 'Bệnh khớp trong các bệnh nội tiết, dinh dưỡng và các bệnh chuyển hóa khác',
}, {
  'id': 'M14.6*',
  'english': 'Neuropathic arthropathy',
  'vietnamese': 'Bệnh khớp do thần kinh',
}, {
  'id': 'M14.8*',
  'english': 'Arthropathies in other specified diseases classified elsewhere',
  'vietnamese': 'Bệnh khớp trong các bệnh đặc hiệu khác đã được phân loại mục khác',
}, {'id': 'M15', 'english': 'Polyarthrosis', 'vietnamese': 'thoái hóa đa khớp'}, {
  'id': 'M15.0',
  'english': 'Primary generalized (osteo)arthrosis',
  'vietnamese': 'thoái hóa khớp nguyên phát có tính gia đình',
}, {
  'id': 'M15.1',
  'english': 'Heberden s nodes (with arthropathy)',
  'vietnamese': 'Có hạt Heberden (có kèm tổn thương khớp)',
}, {
  'id': 'M15.2',
  'english': 'Bouchard s nodes (with arthropathy)',
  'vietnamese': 'Hạt Bouchard (có kèm tổn thương khớp)',
}, {
  'id': 'M15.3',
  'english': 'Secondary multiple arthrosis',
  'vietnamese': 'thoái hóa nhiều khớp thứ phát',
}, {
  'id': 'M15.4',
  'english': 'Erosive (osteo)arthrosis',
  'vietnamese': 'thoái hóa khớp kiểu bào mòn',
}, {
  'id': 'M15.8',
  'english': 'Other polyarthrosis',
  'vietnamese': 'Các thoái hóa đa khớp khác',
}, {
  'id': 'M15.9',
  'english': 'Polyarthrosis, unspecified',
  'vietnamese': 'Các thoái hóa đa khớp không đặc hiệu',
}, {
  'id': 'M16',
  'english': 'Coxarthrosis [arthrosis of hip]',
  'vietnamese': 'Thoái hóa khớp háng',
}, {
  'id': 'M16.0',
  'english': 'Primary coxarthrosis, bilateral',
  'vietnamese': 'Thoái hóa khớp háng nguyên phát cả 2 bên',
}, {
  'id': 'M16.1',
  'english': 'Other primary coxarthrosis',
  'vietnamese': 'Các thoái hóa khớp háng nguyên phát khác',
}, {
  'id': 'M16.2',
  'english': 'Coxarthrosis resulting from dysplasia, bilateral',
  'vietnamese': 'Các thoái hóa khớp háng do loạn dưỡng cả 2 bên',
}, {
  'id': 'M16.3',
  'english': 'Other dysplastic coxarthrosis',
  'vietnamese': 'Các thoái hóa khớp háng do loạn dưỡng khác',
}, {
  'id': 'M16.4',
  'english': 'Post-traumatic coxarthrosis, bilateral',
  'vietnamese': 'Thoái hóa khớp háng sau chấn thương cả 2 bên',
}, {
  'id': 'M16.5',
  'english': 'Other post-traumatic coxarthrosis',
  'vietnamese': 'Các thoái hóa khớp háng sau chấn thương khác',
}, {
  'id': 'M16.6',
  'english': 'Other secondary coxarthrosis, bilateral',
  'vietnamese': 'Các thoái hóa khớp háng thứ phát khác cả 2 bên',
}, {
  'id': 'M16.7',
  'english': 'Other secondary coxarthrosis',
  'vietnamese': 'Các thoái hóa khớp háng thứ phát khác',
}, {
  'id': 'M16.9',
  'english': 'Coxarthrosis, unspecified',
  'vietnamese': 'Thoái hóa khớp háng không đặc hiệu',
}, {
  'id': 'M17',
  'english': 'Gonarthrosis [arthrosis of knee]',
  'vietnamese': 'Thoái hóa khớp gối',
}, {
  'id': 'M17.0',
  'english': 'Primary gonarthrosis, bilateral',
  'vietnamese': 'Thoái hóa khớp gối nguyên phát cả 2 bên',
}, {
  'id': 'M17.1',
  'english': 'Other primary gonarthrosis',
  'vietnamese': 'Thoái hóa khớp gối nguyên phát khác',
}, {
  'id': 'M17.2',
  'english': 'Post-traumatic gonarthrosis, bilateral',
  'vietnamese': 'Thoái hóa khớp gối sau chấn thương cả 2 bên',
}, {
  'id': 'M17.3',
  'english': 'Other post-traumatic gonarthrosis',
  'vietnamese': 'Thoái hóa khớp gối sau chấn thương khác',
}, {
  'id': 'M17.4',
  'english': 'Other secondary gonarthrosis, bilateral',
  'vietnamese': 'Thoái hóa khớp gối thứ phát khác cả 2 bên',
}, {
  'id': 'M17.5',
  'english': 'Other secondary gonarthrosis',
  'vietnamese': 'Thoái hóa khớp gối thứ phát khác',
}, {
  'id': 'M17.9',
  'english': 'Gonarthrosis, unspecified',
  'vietnamese': 'Thoái hóa khớp gối không đặc hiệu',
}, {
  'id': 'M18',
  'english': 'Arthrosis of first carpometacarpal joint',
  'vietnamese': 'Thoái hóa khớp cổ - bàn ngón tay cái',
}, {
  'id': 'M18.0',
  'english': 'Primary arthrosis of first carpometacarpal joints, bilateral',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón tay cái cả 2 bên',
}, {
  'id': 'M18.1',
  'english': 'Other primary arthrosis of first carpometacarpal joint',
  'vietnamese': 'Các thoái hóa nguyên phát khác của khớp cổ - bàn ngón cái',
}, {
  'id': 'M18.2',
  'english': 'Post-traumatic arthrosis of first carpometacarpal joints, bilateral',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón cái sau chấn thương cả 2 bên',
}, {
  'id': 'M18.3',
  'english': 'Other post-traumatic arthrosis of first carpometacarpal joint',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón cái sau chấn thương khác',
}, {
  'id': 'M18.4',
  'english': 'Other secondary arthrosis of first carpometacarpal joints, bilateral',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón cái thứ phát khác cả 2 bên',
}, {
  'id': 'M18.5',
  'english': 'Other secondary arthrosis of first carpometacarpal joint',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón cái thứ phát khác',
}, {
  'id': 'M18.9',
  'english': 'Arthrosis of first carpometacarpal joint, unspecified',
  'vietnamese': 'Thoái hóa khớp cổ bàn ngón cái không đặc hiệu',
}, {'id': 'M19', 'english': 'Other arthrosis', 'vietnamese': 'Thoái hóa khớp khác'}, {
  'id': 'M19.0',
  'english': 'Primary arthrosis of other joints',
  'vietnamese': 'Thoái hóa khớp nguyên phát ở các khớp khác',
}, {
  'id': 'M19.1',
  'english': 'Post-traumatic arthrosis of other joints',
  'vietnamese': 'Thoái hóa khớp sau chấn thương ở các khớp khác',
}, {
  'id': 'M19.2',
  'english': 'Other secondary arthrosis',
  'vietnamese': 'Thoái hóa khớp thứ phát khác',
}, {
  'id': 'M19.8',
  'english': 'Other specified arthrosis',
  'vietnamese': 'Thoái hóa khớp điển hình khác',
}, {
  'id': 'M19.9',
  'english': 'Arthrosis, unspecified',
  'vietnamese': 'Thoái hóa khớp không đặc hiệu',
}, {
  'id': 'M20',
  'english': 'Acquired deformities of fingers and toes',
  'vietnamese': 'Biến dạng mắc phải của ngón tay và ngón chân',
}, {'id': 'M20.0', 'english': 'Deformity of finger(s)', 'vietnamese': 'Biến dạng ngón tay'}, {
  'id': 'M20.1',
  'english': 'Hallux valgus (acquired)',
  'vietnamese': 'Biến dạng quẹo ngón chân cái ra ngoài (mắc phải) Hallux valgus',
}, {
  'id': 'M20.2',
  'english': 'Hallux rigidus',
  'vietnamese': 'Biến dạng cứng ngón chân cái (hallux rigidus)',
}, {
  'id': 'M20.3',
  'english': 'Other deformity of hallux (acquired)',
  'vietnamese': 'Các biến dạng ngón chân cái mắc phải khác',
}, {
  'id': 'M20.4',
  'english': 'Other hammer toe(s) (acquired)',
  'vietnamese': 'Biến dạng ngón chân hình búa (mắc phải)',
}, {
  'id': 'M20.5',
  'english': 'Other deformities of toe(s) (acquired)',
  'vietnamese': 'Các biến dạng khác của ngón chân (mắc phải)',
}, {
  'id': 'M20.6',
  'english': 'Acquired deformity of toe(s), unspecified',
  'vietnamese': 'Các biến dạng mắc phải khác của ngón chân không đặc hiệu',
}, {
  'id': 'M21',
  'english': 'Other acquired deformities of limbs',
  'vietnamese': 'Biến dạng mắc phải khác của chi',
}, {
  'id': 'M21.0',
  'english': 'Valgus deformity, not elsewhere classified',
  'vietnamese': 'Biến dạng vẹo ra ngoài, không xếp loại ở mục khác',
}, {
  'id': 'M21.1',
  'english': 'Varus deformity, not elsewhere classified',
  'vietnamese': 'Biến dạng vẹo vào trong, không xếp loại ở mục khác',
}, {'id': 'M21.2', 'english': 'Flexion deformity', 'vietnamese': 'Biến dạng gấp'}, {
  'id': 'M21.3',
  'english': 'Wrist or foot drop (acquired)',
  'vietnamese': 'Biến dạng cổ tay hoặc bàn chân rủ(mắc phải)',
}, {
  'id': 'M21.4',
  'english': 'Flat foot [pes planus] (acquired)',
  'vietnamese': 'Biến dạng bàn chân phẳng (mắc phải)',
}, {
  'id': 'M21.5',
  'english': 'Acquired clawhand, clubhand, clawfoot and clubfoot',
  'vietnamese': 'Biến dạng bàn tay quặp, bàn tay vẹo, bàn chân quặp, bàn chân vẹo (mắc phải)',
}, {
  'id': 'M21.6',
  'english': 'Other acquired deformities of ankle and foot',
  'vietnamese': 'Các biến dạng mắc phải khác của cổ chân và bàn chân',
}, {
  'id': 'M21.7',
  'english': 'Unequal limb length (acquired)',
  'vietnamese': 'Chi lệch (mắc phải)',
}, {
  'id': 'M21.8',
  'english': 'Other specified acquired deformities of limbs',
  'vietnamese': 'Biến dạng mắc phải đặc hiệu khác của chi',
}, {
  'id': 'M21.9',
  'english': 'Acquired deformity of limb, unspecified',
  'vietnamese': 'Biến dạng mắc phải không đặc hiệu của chi',
}, {
  'id': 'M22',
  'english': 'Disorders of patella',
  'vietnamese': 'Các bất thường của xương bánh chè',
}, {
  'id': 'M22.0',
  'english': 'Recurrent dislocation of patella',
  'vietnamese': 'Trật xương bánh chè tái phát',
}, {
  'id': 'M22.1',
  'english': 'Recurrent subluxation of patella',
  'vietnamese': 'Bán trật xương bánh chè tái phát',
}, {
  'id': 'M22.2',
  'english': 'Patellofemoral disorders',
  'vietnamese': 'Bất thường khớp đùi bánh chè',
}, {
  'id': 'M22.3',
  'english': 'Other derangements of patella',
  'vietnamese': 'Các loại trật khác của xương bánh chè',
}, {
  'id': 'M22.4',
  'english': 'Chondromalacia patellae',
  'vietnamese': 'Chứng nhuyễn sụn xương bánh chè',
}, {
  'id': 'M22.8',
  'english': 'Other disorders of patella',
  'vietnamese': 'Các bất thường của xương bánh chè',
}, {
  'id': 'M22.9',
  'english': 'Disorder of patella, unspecified',
  'vietnamese': 'Các bất thường của xương bánh chè không đặc hiệu',
}, {
  'id': 'M23',
  'english': 'Internal derangement of knee',
  'vietnamese': 'Tổn thương bên trong khớp gối',
}, {'id': 'M23.0', 'english': 'Cystic meniscus', 'vietnamese': 'Kén (nang) sụn chêm'}, {
  'id': 'M23.1',
  'english': 'Discoid meniscus (congenital)',
  'vietnamese': 'Sụn chêm dạng đĩa (bẩm sinh)',
}, {
  'id': 'M23.2',
  'english': 'Derangement of meniscus due to old tear or injury',
  'vietnamese': 'Sụn chêm di lệch do tổn thương rách hay chấn thương cũ',
}, {
  'id': 'M23.3',
  'english': 'Other meniscus derangements',
  'vietnamese': 'Các tổn thương sụn chêm khác',
}, {'id': 'M23.4', 'english': 'Loose body in knee', 'vietnamese': 'Dị vật nội khớp'}, {
  'id': 'M23.5',
  'english': 'Chronic instability of knee',
  'vietnamese': 'Tổn thương mất vững mãn tính của khớp gối',
}, {
  'id': 'M23.6',
  'english': 'Other spontaneous disruption of ligament(s) of knee',
  'vietnamese': 'Các đứt tự phát khác của dây chằng trong khớp gối',
}, {
  'id': 'M23.8',
  'english': 'Other internal derangements of knee',
  'vietnamese': 'Các bệnh bên trong khác của khớp gối',
}, {
  'id': 'M23.9',
  'english': 'Internal derangement of knee, unspecified',
  'vietnamese': 'Bệnh bên trong khớp gối không đặc hiệu',
}, {
  'id': 'M24',
  'english': 'Other specific joint derangements',
  'vietnamese': 'Các tổn thương đặc hiệu khác ở khớp',
}, {'id': 'M24.0', 'english': 'Loose body in joint', 'vietnamese': 'Dị vật nội khớp'}, {
  'id': 'M24.1',
  'english': 'Other articular cartilage disorders',
  'vietnamese': 'Các bệnh sụn khớp khác',
}, {'id': 'M24.2', 'english': 'Disorder of ligament', 'vietnamese': 'Bệnh dây chằng'}, {
  'id': 'M24.3',
  'english': 'Pathological dislocation and subluxation of joint, not elsewhere classified',
  'vietnamese': 'Trật và bán trật bệnh lý của khớp chưa được xếp loại ở mục khác',
}, {
  'id': 'M24.4',
  'english': 'Recurrent dislocation and subluxation of joint',
  'vietnamese': 'Trật và bán trật khớp tái phát của khớp',
}, {'id': 'M24.5', 'english': 'Contracture of joint', 'vietnamese': 'Cứng khớp'}, {
  'id': 'M24.6',
  'english': 'Ankylosis of joint',
  'vietnamese': 'Dính khớp',
}, {
  'id': 'M24.7',
  'english': 'Protrusio acetabuli',
  'vietnamese': 'Lồi vào trong ổ cối (protrusioacetabuli)',
}, {
  'id': 'M24.8',
  'english': 'Other specific joint derangements, not elsewhere classified',
  'vietnamese': 'Các bệnh khớp đặc hiệu khác không xếp loại ở mục khác',
}, {
  'id': 'M24.9',
  'english': 'Joint derangement, unspecified',
  'vietnamese': 'Những bệnh lý khớp không đặc hiệu',
}, {
  'id': 'M25',
  'english': 'Other joint disorders, not elsewhere classified',
  'vietnamese': 'Bệnh khớp khác, không được xếp loại ở mục khác',
}, {'id': 'M25.0', 'english': 'Haemarthrosis', 'vietnamese': 'Chảy máu khớp'}, {
  'id': 'M25.1',
  'english': 'Fistula of joint',
  'vietnamese': 'Rò khớp',
}, {'id': 'M25.2', 'english': 'Flail joint', 'vietnamese': 'Lỏng lẻo khớp'}, {
  'id': 'M25.3',
  'english': 'Other instability of joint',
  'vietnamese': 'Các tổn thương mất vững khác của khớp',
}, {'id': 'M25.4', 'english': 'Effusion of joint', 'vietnamese': 'Tràn dịch khớp'}, {
  'id': 'M25.5',
  'english': 'Pain in joint',
  'vietnamese': 'Đau khớp',
}, {
  'id': 'M25.6',
  'english': 'Stiffness of joint, not elsewhere classified',
  'vietnamese': 'cứng khớp, không xếp loại ở mục khác',
}, {'id': 'M25.7', 'english': 'Osteophyte', 'vietnamese': 'Gai xương'}, {
  'id': 'M25.8',
  'english': 'Other specified joint disorders',
  'vietnamese': 'Các bệnh khớp đặc hiệu khác',
}, {
  'id': 'M25.9',
  'english': 'Joint disorder, unspecified',
  'vietnamese': 'Các bệnh khớp không đặc hiệu',
}, {
  'id': 'M30',
  'english': 'Polyarteritis nodosa and related conditions',
  'vietnamese': 'Viêm nút quanh động mạch và bệnh liên quan',
}, {
  'id': 'M30.0',
  'english': 'Polyarteritis nodosa',
  'vietnamese': 'Viêm đa động mạch hình nút',
}, {
  'id': 'M30.1',
  'english': 'Polyarteritis with lung involvement [Churg-Strauss]',
  'vietnamese': 'Viêm đa động mạch có tổn thương phổi [Churg-Strauss]',
}, {
  'id': 'M30.2',
  'english': 'Juvenile polyarteritis',
  'vietnamese': 'Viêm đa động mạch trẻ em',
}, {
  'id': 'M30.3',
  'english': 'Mucocutaneous lymph node syndrome [Kawasaki]',
  'vietnamese': 'Hội chứng hạch-da-niêm mạc  [Kawasaki]',
}, {
  'id': 'M30.8',
  'english': 'Other conditions related to polyarteritis nodosa',
  'vietnamese': 'Các bệnh khác liên quan đến viêm nút quanh động mạch',
}, {
  'id': 'M31',
  'english': 'Other necrotizing vasculopathies',
  'vietnamese': 'Bệnh mạch máu hoại tử khác',
}, {
  'id': 'M31.0',
  'english': 'Hypersensitivity angiitis',
  'vietnamese': 'Viêm đa mạch quá mẫn',
}, {
  'id': 'M31.1',
  'english': 'Thrombotic microangiopathy',
  'vietnamese': 'Bệnh vi mạch có huyết khối',
}, {
  'id': 'M31.2',
  'english': 'Lethal midline granuloma',
  'vietnamese': 'Bệnh u hạt ác tính dòng giữa',
}, {'id': 'M31.3', 'english': 'Wegener s granulomatosis', 'vietnamese': 'Bệnh u hạt Wegener'}, {
  'id': 'M31.4',
  'english': 'Aortic arch syndrome [Takayasu]',
  'vietnamese': 'Hội chứng quai động mạch chủ [Takayasu]',
}, {
  'id': 'M31.5',
  'english': 'Giant cell arteritis with polymyalgia rheumatica',
  'vietnamese': 'Bệnh viêm động mạch tế bào khổng lồ với đau cơ dạng thấp',
}, {
  'id': 'M31.6',
  'english': 'Other giant cell arteritis',
  'vietnamese': 'Bệnh viêm động mạch có tế bào khổng lồ khác',
}, {
  'id': 'M31.7',
  'english': 'Microscopic polyangiitis',
  'vietnamese': 'Viêm đa động mạch vi thể',
}, {
  'id': 'M31.8',
  'english': 'Other specified necrotizing vasculopathies',
  'vietnamese': 'Các bệnh viêm mạch đặc hiệu hoại tử khác',
}, {
  'id': 'M31.9',
  'english': 'Necrotizing vasculopathy, unspecified',
  'vietnamese': 'Viêm mạch hoại tử không đặc hiệu',
}, {
  'id': 'M32',
  'english': 'Systemic lupus erythematosus',
  'vietnamese': 'Lupus ban đỏ hệ thống',
}, {
  'id': 'M32.0',
  'english': 'Drug-induced systemic lupus erythematosus',
  'vietnamese': 'Bệnh Lupus ban đỏ hệ thống do thuốc',
}, {
  'id': 'M32.1†',
  'english': 'Systemic lupus erythematosus with organ or system involvement',
  'vietnamese': 'Bệnh Lupus ban đỏ hệ thống có tổn thương phủ tạng',
}, {
  'id': 'M32.8',
  'english': 'Other forms of systemic lupus erythematosus',
  'vietnamese': 'Các dạng khác của Lupus ban đỏ hệ thống',
}, {
  'id': 'M32.9',
  'english': 'Systemic lupus erythematosus, unspecified',
  'vietnamese': 'Lupus ban đỏ hệ thống không đặc hiệu',
}, {'id': 'M33', 'english': 'Dermatopolymyositis', 'vietnamese': 'Viêm da cơ'}, {
  'id': 'M33.0',
  'english': 'Juvenile dermatomyositis',
  'vietnamese': 'Viêm da cơ trẻ em',
}, {
  'id': 'M33.1',
  'english': 'Other dermatomyositis',
  'vietnamese': 'Các bệnh viêm da cơ khác',
}, {
  'id': 'M33.2',
  'english': 'Polymyositis',
  'vietnamese': 'Viêm đa cơ',
}, {
  'id': 'M33.9',
  'english': 'Dermatopolymyositis, unspecified',
  'vietnamese': 'Viêm da cơ không đặc hiệu',
}, {'id': 'M34', 'english': 'Systemic sclerosis', 'vietnamese': 'Xơ cứng toàn thể'}, {
  'id': 'M34.0',
  'english': 'Progressive systemic sclerosis',
  'vietnamese': 'Xơ cứng bì toàn thể tiến triển',
}, {'id': 'M34.1', 'english': 'CR(E)ST syndrome', 'vietnamese': 'Hội chứng CR(E)ST'}, {
  'id': 'M34.2',
  'english': 'Systemic sclerosis induced by drugs and chemicals',
  'vietnamese': 'Xơ cứng bì do dùng thuốc và hóa chất',
}, {
  'id': 'M34.8',
  'english': 'Other forms of systemic sclerosis',
  'vietnamese': 'Các dạng khác của xơ cứng bì toàn thể',
}, {
  'id': 'M34.9',
  'english': 'Systemic sclerosis, unspecified',
  'vietnamese': 'Xơ cứng bì toàn thể, không đặc hiệu',
}, {
  'id': 'M35',
  'english': 'Other systemic involvement of connective tissue',
  'vietnamese': 'Các tổn thương hệ thống khác của mô liên kết',
}, {
  'id': 'M35.0',
  'english': 'Sicca syndrome [Sjögren]',
  'vietnamese': 'Hội chứng khô (Hội chứng Sjogren)',
}, {
  'id': 'M35.1',
  'english': 'Other overlap syndromes',
  'vietnamese': 'Các hội chứng trùng lắp khác',
}, {'id': 'M35.2', 'english': 'Behçet s disease', 'vietnamese': 'Bệnh Behcet'}, {
  'id': 'M35.3',
  'english': 'Polymyalgia rheumatica',
  'vietnamese': 'Bệnh đau cơ dạng thấp',
}, {
  'id': 'M35.4',
  'english': 'Diffuse (eosinophilic) fasciitis',
  'vietnamese': 'Viêm cân mạc lan toả (tăng bạch cầu ái toan)',
}, {'id': 'M35.5', 'english': 'Multifocal fibrosclerosis', 'vietnamese': 'Xơ cứng nhiều ổ'}, {
  'id': 'M35.6',
  'english': 'Relapsing panniculitis [Weber-Christian]',
  'vietnamese': 'Viêm mô mỡ dưới da hay tái phát (Weber - Christian)',
}, {
  'id': 'M35.7',
  'english': 'Hypermobility syndrome',
  'vietnamese': 'Hội chứng tăng vận động',
}, {
  'id': 'M35.8',
  'english': 'Other specified systemic involvement of connective tissue',
  'vietnamese': 'Các tổn thương hệ thống đặc hiệu khác của mô liên kết',
}, {
  'id': 'M35.9',
  'english': 'Systemic involvement of connective tissue, unspecified',
  'vietnamese': 'Các tổn thương hệ thống của mô liên kết, không đặc hiệu',
}, {
  'id': 'M36*',
  'english': 'Systemic disorders of connective tissue in diseases classified elsewhere',
  'vietnamese': 'Tổn thương mô liên kết hệ thống trong các bệnh đã được xếp loại ở mục khác',
}, {
  'id': 'M36.0*',
  'english': 'Dermato(poly)myositis in neoplastic disease (C00-D48†)',
  'vietnamese': 'Viêm da cơ và viêm đa cơ tự miễn trong các bệnh lý khối u (C00-D48†)',
}, {
  'id': 'M36.1*',
  'english': 'Arthropathy in neoplastic disease (C00-D48†)',
  'vietnamese': 'Bệnh khớp trong các bệnh ung thư đã được xếp ở mục khác (C00-D48†)',
}, {
  'id': 'M36.2*',
  'english': 'Haemophilic arthropathy (D66-D68†)',
  'vietnamese': 'Tổn thương khớp trong bệnh ưa chảy máu (D66-D68†)',
}, {
  'id': 'M36.3*',
  'english': 'Arthropathy in other blood disorders (D50-D76†)',
  'vietnamese': 'Bệnh khớp trong các bệnh về máu khác (D50-D76†)',
}, {
  'id': 'M36.4*',
  'english': 'Arthropathy in hypersensitivity reactions classified elsewhere',
  'vietnamese': 'Bệnh khớp trong phản ứng quá mẫn đã được xếp loại ở mục khác',
}, {
  'id': 'M36.8*',
  'english': 'Systemic disorders of connective tissue in other diseases classified elsewhere',
  'vietnamese': 'Tổn thương hệ thống mô liên kết trong các bệnh đã được xếp loại ở mục khác',
}, {'id': 'M40', 'english': 'Kyphosis and lordosis', 'vietnamese': 'Gù và ưỡn cột sống'}, {
  'id': 'M40.0',
  'english': 'Postural kyphosis',
  'vietnamese': 'Gù do tư thế',
}, {
  'id': 'M40.1',
  'english': 'Other secondary kyphosis',
  'vietnamese': 'Các gù cột sống thứ phát khác',
}, {
  'id': 'M40.2',
  'english': 'Other and unspecified kyphosis',
  'vietnamese': 'Các gù không đặc hiệu khác',
}, {'id': 'M40.3', 'english': 'Flatback syndrome', 'vietnamese': 'Hội chứng lưng phẳng'}, {
  'id': 'M40.4',
  'english': 'Other lordosis',
  'vietnamese': 'Các ưỡn cột sống khác',
}, {
  'id': 'M40.5',
  'english': 'Lordosis, unspecified',
  'vietnamese': 'Ưỡn cột sống không đặc hiệu',
}, {
  'id': 'M41',
  'english': 'Scoliosis',
  'vietnamese': 'Vẹo cột sống',
}, {
  'id': 'M41.0',
  'english': 'Infantile idiopathic scoliosis',
  'vietnamese': 'Vẹo cột sống tự phát ở trẻ nhỏ',
}, {
  'id': 'M41.1',
  'english': 'Juvenile idiopathic scoliosis',
  'vietnamese': 'Vẹo cột sống tự phát thiếu niên',
}, {
  'id': 'M41.2',
  'english': 'Other idiopathic scoliosis',
  'vietnamese': 'Các vẹo cột sống tự phát khác',
}, {
  'id': 'M41.3',
  'english': 'Thoracogenic scoliosis',
  'vietnamese': 'Vẹo cột sống do bất thường vùng ngực',
}, {
  'id': 'M41.4',
  'english': 'Neuromuscular scoliosis',
  'vietnamese': 'Vẹo cột sống do nguyên nhân thần kinh-cơ',
}, {
  'id': 'M41.5',
  'english': 'Other secondary scoliosis',
  'vietnamese': 'Các vẹo cột sống thứ phát khác',
}, {
  'id': 'M41.8',
  'english': 'Other forms of scoliosis',
  'vietnamese': 'Các dạng khác của vẹo cột sống',
}, {
  'id': 'M41.9',
  'english': 'Scoliosis, unspecified',
  'vietnamese': 'Vẹo cột sống không đặc hiệu',
}, {
  'id': 'M42',
  'english': 'Spinal osteochondrosis',
  'vietnamese': 'Viêm xương sụn cột sống',
}, {
  'id': 'M42.0',
  'english': 'Juvenile osteochondrosis of spine',
  'vietnamese': 'Viêm xương sụn cột sống thanh thiếu niên',
}, {
  'id': 'M42.1',
  'english': 'Adult osteochondrosis of spine',
  'vietnamese': 'Hư điểm cốt hóa cột sống người lớn',
}, {
  'id': 'M42.9',
  'english': 'Spinal osteochondrosis, unspecified',
  'vietnamese': 'Hư điểm cốt hóa cột sống không đặc hiệu',
}, {
  'id': 'M43',
  'english': 'Other deforming dorsopathies',
  'vietnamese': 'Các biến dạng khác của cột sống',
}, {'id': 'M43.0', 'english': 'Spondylolysis', 'vietnamese': 'Trượt đốt sống'}, {
  'id': 'M43.1',
  'english': 'Spondylolisthesis',
  'vietnamese': 'Bệnh trượt đốt sống',
}, {'id': 'M43.2', 'english': 'Other fusion of spine', 'vietnamese': 'Dính đốt sống khác'}, {
  'id': 'M43.3',
  'english': 'Recurrent atlantoaxial subluxation with myelopathy',
  'vietnamese': 'Bán trật khớp trục-đội hay tái phát có tổn thương tủy sống',
}, {
  'id': 'M43.4',
  'english': 'Other recurrent atlantoaxial subluxation',
  'vietnamese': 'Bán trật khớp trục-đội hay tái phát khác',
}, {
  'id': 'M43.5',
  'english': 'Other recurrent vertebral subluxation',
  'vietnamese': 'Các bán trật đốt sống hay tái phát khác',
}, {'id': 'M43.6', 'english': 'Torticollis', 'vietnamese': 'Vẹo cổ'}, {
  'id': 'M43.8',
  'english': 'Other specified deforming dorsopathies',
  'vietnamese': 'Các dị tật điển hình khác của cột sống được xác định',
}, {
  'id': 'M43.9',
  'english': 'Deforming dorsopathy, unspecified',
  'vietnamese': 'Các bệnh cột sống có dị tật không đặc hiệu',
}, {
  'id': 'M45',
  'english': 'Ankylosing spondylitis',
  'vietnamese': 'Bệnh viêm cột sống dính khớp',
}, {
  'id': 'M46',
  'english': 'Other inflammatory spondylopathies',
  'vietnamese': 'Các bệnh viêm cột sống khác',
}, {
  'id': 'M46.0',
  'english': 'Spinal enthesopathy',
  'vietnamese': 'Bệnh gân-dây chằng quanh cột sống',
}, {
  'id': 'M46.1',
  'english': 'Sacroiliitis, not elsewhere classified',
  'vietnamese': 'Viêm khớp cùng chậu, chưa xếp loại ở mục khác',
}, {
  'id': 'M46.2',
  'english': 'Osteomyelitis of vertebra',
  'vietnamese': 'Viêm xương tủy thân đốt sống',
}, {
  'id': 'M46.3',
  'english': 'Infection of intervertebral disc (pyogenic)',
  'vietnamese': 'Viêm đĩa đệm đốt sống do vi khuẩn sinh mủ',
}, {
  'id': 'M46.4',
  'english': 'Discitis, unspecified',
  'vietnamese': 'Viêm đĩa đệm, không đặc hiệu',
}, {
  'id': 'M46.5',
  'english': 'Other infective spondylopathies',
  'vietnamese': 'Các bệnh nhiễm khuẩn khác của thân đốt sống',
}, {
  'id': 'M46.8',
  'english': 'Other specified inflammatory spondylopathies',
  'vietnamese': 'Các bệnh viêm khác của thân đốt sống, được xác định',
}, {
  'id': 'M46.9',
  'english': 'Inflammatory spondylopathy, unspecified',
  'vietnamese': 'Viêm đốt sống không đặc hiệu',
}, {'id': 'M47', 'english': 'Spondylosis', 'vietnamese': 'thoái hóa cột sống'}, {
  'id': 'M47.0†',
  'english': 'Anterior spinal and vertebral artery compression syndromes (G99.2*)',
  'vietnamese': 'Hội chứng chèn ép động mạch sống và động mạch gai sống trước (G99.2*)',
}, {
  'id': 'M47.1',
  'english': 'Other spondylosis with myelopathy',
  'vietnamese': 'thoái hóa cột sống khác kèm tổn thương tủy sống',
}, {
  'id': 'M47.2',
  'english': 'Other spondylosis with radiculopathy',
  'vietnamese': 'thoái hóa cột sống khác có tổn thương rễ sống',
}, {'id': 'M47.8', 'english': 'Other spondylosis', 'vietnamese': 'Các thoái hóa cột sống khác'}, {
  'id': 'M47.9',
  'english': 'Spondylosis, unspecified',
  'vietnamese': 'thoái hóa cột sống không đặc hiệu',
}, {
  'id': 'M48',
  'english': 'Other spondylopathies',
  'vietnamese': 'Các bệnh khác của thân đốt sống',
}, {'id': 'M48.0', 'english': 'Spinal stenosis', 'vietnamese': 'Hẹp ống sống'}, {
  'id': 'M48.1',
  'english': 'Ankylosing hyperostosis [Forestier]',
  'vietnamese': 'Cứng khớp do tăng tạo xương [Forestier]',
}, {'id': 'M48.2', 'english': 'Kissing spine', 'vietnamese': 'Hư khớp liên mỏm gai'}, {
  'id': 'M48.3',
  'english': 'Traumatic spondylopathy',
  'vietnamese': 'Chấn thương cột sống',
}, {
  'id': 'M48.4',
  'english': 'Fatigue fracture of vertebra',
  'vietnamese': 'gẫy đốt sống do mỏi',
}, {
  'id': 'M48.5',
  'english': 'Collapsed vertebra, not elsewhere classified',
  'vietnamese': 'Xẹp đốt sống, chưa được xếp loại ở mục khác',
}, {
  'id': 'M48.8',
  'english': 'Other specified spondylopathies',
  'vietnamese': 'Các bệnh thân đốt sống được xác định khác',
}, {
  'id': 'M48.9',
  'english': 'Spondylopathy, unspecified',
  'vietnamese': 'Các bệnh thân đốt sống không đặc hiệu',
}, {
  'id': 'M49*',
  'english': 'Spondylopathies in diseases classified elsewhere',
  'vietnamese': 'Tổn thương cột sống trong các bệnh đã xếp loại mục khác',
}, {
  'id': 'M49.0*',
  'english': 'Tuberculosis of spine (A18.0†)',
  'vietnamese': 'Lao cột sống (A18.0†)',
}, {
  'id': 'M49.1*',
  'english': 'Brucella spondylitis (A23.-†)',
  'vietnamese': 'Viêm đốt sống do Brucella (A23.-†)',
}, {
  'id': 'M49.2*',
  'english': 'Enterobacterial spondylitis (A01-A04†)',
  'vietnamese': 'Viêm đốt sống do vi khuẩn đường ruột (A01-A04†)',
}, {
  'id': 'M49.3*',
  'english': 'Spondylopathy in other infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Bệnh cột sống trong các bệnh nhiễm khuẩn và ký sinh vật, đã xếp loại ở mục khác',
}, {
  'id': 'M49.4*',
  'english': 'Neuropathic spondylopathy',
  'vietnamese': 'Tổn thương cột sống do nguyên nhân thần kinh',
}, {
  'id': 'M49.5*',
  'english': 'Collapsed vertebra in diseases classified elsewhere',
  'vietnamese': 'Xẹp đốt sống trong các bệnh đã xếp loại ở mục khác',
}, {
  'id': 'M49.8*',
  'english': 'Spondylopathy in other diseases classified elsewhere',
  'vietnamese': 'Tổn thương thân đốt sống trong các bệnh khác đã được xếp loại ở mục khác',
}, {
  'id': 'M50',
  'english': 'Cervical disc disorders',
  'vietnamese': 'Bệnh đĩa đệm đốt sống cổ',
}, {
  'id': 'M50.0†',
  'english': 'Cervical disc disorder with myelopathy (G99.2*)',
  'vietnamese': 'Bệnh một đĩa đệm đốt sống cổ kèm tổn thương tủy sống (G99.2*)',
}, {
  'id': 'M50.1',
  'english': 'Cervical disc disorder with radiculopathy',
  'vietnamese': 'Bệnh một đĩa đệm cột sống cổ kèm tổn thương rễ tủy sống',
}, {
  'id': 'M50.2',
  'english': 'Other cervical disc displacement',
  'vietnamese': 'Các thóat vị đĩa đệm cột sống cổ khác',
}, {
  'id': 'M50.3',
  'english': 'Other cervical disc degeneration',
  'vietnamese': 'Các thoái hóa đĩa đệm đốt sống cổ khác',
}, {
  'id': 'M50.8',
  'english': 'Other cervical disc disorders',
  'vietnamese': 'Các tổn thương đĩa đệm đốt sống cổ khác',
}, {
  'id': 'M50.9',
  'english': 'Cervical disc disorder, unspecified',
  'vietnamese': 'Các bệnh đĩa đệm đốt sống cổ khác không đặc hiệu',
}, {
  'id': 'M51',
  'english': 'Other intervertebral disc disorders',
  'vietnamese': 'Các bệnh đĩa đệm gian đốt sống khác',
}, {
  'id': 'M51.0†',
  'english': 'Lumbar and other intervertebral disc disorders with myelopathy (G99.2*)',
  'vietnamese': 'Các bệnh của đĩa đệm đốt sống thắt lưng và các đốt sống khác có kèm theo tổn thương tủy sống (G99.2*)',
}, {
  'id': 'M51.1†',
  'english': 'Lumbar and other intervertebral disc disorders with radiculopathy (G55.1*)',
  'vietnamese': 'Bệnh của đĩa đệm đốt sống thắt lưng và các đốt sống khác có kèm tổn thương của rễ tủy sống (G55.1*)',
}, {
  'id': 'M51.2',
  'english': 'Other specified intervertebral disc displacement',
  'vietnamese': 'Thóat vị đĩa đệm đốt sống đặc hiệu khác',
}, {
  'id': 'M51.3',
  'english': 'Other specified intervertebral disc degeneration',
  'vietnamese': 'thoái hóa đĩa đệm đốt sống đặc hiệu khác',
}, {'id': 'M51.4', 'english': 'Schmorl s nodes', 'vietnamese': 'Nhân Schmorl'}, {
  'id': 'M51.8',
  'english': 'Other specified intervertebral disc disorders',
  'vietnamese': 'Các tổn thương đặc hiệu khác của đĩa đệm',
}, {
  'id': 'M51.9',
  'english': 'Intervertebral disc disorder, unspecified',
  'vietnamese': 'Tổn thương đĩa đệm đốt sống không đặc hiệu',
}, {
  'id': 'M53',
  'english': 'Other dorsopathies, not elsewhere classified',
  'vietnamese': 'Bệnh cột sống khác, không được xếp loại ở mục khác',
}, {'id': 'M53.0', 'english': 'Cervicocranial syndrome', 'vietnamese': 'Hội chứng đầu - cổ'}, {
  'id': 'M53.1',
  'english': 'Cervicobrachial syndrome',
  'vietnamese': 'Hội chứng cánh tay cổ',
}, {'id': 'M53.2', 'english': 'Spinal instabilities', 'vietnamese': 'cột sống mất vững'}, {
  'id': 'M53.3',
  'english': 'Sacrococcygeal disorders, not elsewhere classified',
  'vietnamese': 'Tổn thương cùng cụt, chưa xếp loại mục khác',
}, {
  'id': 'M53.8',
  'english': 'Other specified dorsopathies',
  'vietnamese': 'Các bệnh cột sống xác định khác',
}, {
  'id': 'M53.9',
  'english': 'Dorsopathy, unspecified',
  'vietnamese': 'Bệnh cột sống không đặc hiệu',
}, {'id': 'M54', 'english': 'Dorsalgia', 'vietnamese': 'Đau lưng'}, {
  'id': 'M54.0',
  'english': 'Panniculitis affecting regions of neck and back',
  'vietnamese': 'Viêm mô mỡ dưới da tại vùng cổ và lưng',
}, {'id': 'M54.1', 'english': 'Radiculopathy', 'vietnamese': 'Bệnh rễ thần kinh tủy sống'}, {
  'id': 'M54.2',
  'english': 'Cervicalgia',
  'vietnamese': 'Đau vùng cổ gáy',
}, {'id': 'M54.3', 'english': 'Sciatica', 'vietnamese': 'Đau dây thần kinh tọa'}, {
  'id': 'M54.4',
  'english': 'Lumbago with sciatica',
  'vietnamese': 'Đau lưng kèm đau dây thần kinh tọa',
}, {'id': 'M54.5', 'english': 'Low back pain', 'vietnamese': 'Đau cột sống thắt lưng'}, {
  'id': 'M54.6',
  'english': 'Pain in thoracic spine',
  'vietnamese': 'Đau cột sống ngực',
}, {'id': 'M54.8', 'english': 'Other dorsalgia', 'vietnamese': 'Các đau lưng khác'}, {
  'id': 'M54.9',
  'english': 'Dorsalgia, unspecified',
  'vietnamese': 'Đau lưng không đặc hiệu',
}, {'id': 'M60', 'english': 'Myositis', 'vietnamese': 'Viêm cơ'}, {
  'id': 'M60.0',
  'english': 'Infective myositis',
  'vietnamese': 'Viêm cơ nhiễm khuẩn',
}, {'id': 'M60.1', 'english': 'Interstitial myositis', 'vietnamese': 'Viêm tổ chức kẽ của cơ'}, {
  'id': 'M60.2',
  'english': 'Foreign body granuloma of soft tissue, not elsewhere classified',
  'vietnamese': 'U hạt mô mềm do dị vật, không được xếp loại ở mục khác',
}, {'id': 'M60.8', 'english': 'Other myositis', 'vietnamese': 'Các loại viêm cơ khác'}, {
  'id': 'M60.9',
  'english': 'Myositis, unspecified',
  'vietnamese': 'Viêm cơ không đặc hiệu',
}, {
  'id': 'M61',
  'english': 'Calcification and ossification of muscle',
  'vietnamese': 'Vôi hóa và cốt hóa cơ',
}, {
  'id': 'M61.0',
  'english': 'Myositis ossificans traumatica',
  'vietnamese': 'Viêm cơ cốt hóa do chấn thương',
}, {
  'id': 'M61.1',
  'english': 'Myositis ossificans progressiva',
  'vietnamese': 'Viêm cơ cốt hóa tiến triển',
}, {
  'id': 'M61.2',
  'english': 'Paralytic calcification and ossification of muscle',
  'vietnamese': 'Vôi hóa và cốt hóacơ do liệt',
}, {
  'id': 'M61.3',
  'english': 'Calcification and ossification of muscles associated with burns',
  'vietnamese': 'Vôi hóa và cốt hóa cơ liên quan đến bỏng',
}, {
  'id': 'M61.4',
  'english': 'Other calcification of muscle',
  'vietnamese': 'Các dạng vôi hóa cơ khác',
}, {
  'id': 'M61.5',
  'english': 'Other ossification of muscle',
  'vietnamese': 'Các dạng cốt hóa cơ khác',
}, {
  'id': 'M61.9',
  'english': 'Calcification and ossification of muscle, unspecified',
  'vietnamese': 'Các chứng vôi hóa và cốt hóa cơ không đặc hiệu',
}, {'id': 'M62', 'english': 'Other disorders of muscle', 'vietnamese': 'Các bệnh cơ khác'}, {
  'id': 'M62.0',
  'english': 'Diastasis of muscle',
  'vietnamese': 'Lỏng nhẽo cơ',
}, {
  'id': 'M62.1',
  'english': 'Other rupture of muscle (nontraumatic)',
  'vietnamese': 'rách cơ khác (không do chấn thương)',
}, {
  'id': 'M62.2',
  'english': 'Ischaemic infarction of muscle',
  'vietnamese': 'Nhồi máu cơ do thiếu máu cục bộ',
}, {
  'id': 'M62.3',
  'english': 'Immobility syndrome (paraplegic)',
  'vietnamese': 'Hội chứng bất động (liệt 2 chi dưới)',
}, {'id': 'M62.4', 'english': 'Contracture of muscle', 'vietnamese': 'Co cứng cơ'}, {
  'id': 'M62.5',
  'english': 'Muscle wasting and atrophy, not elsewhere classified',
  'vietnamese': 'Nhẽo cơ và teo cơ, không được xếp loại ở mục khác',
}, {'id': 'M62.6', 'english': 'Muscle strain', 'vietnamese': 'Căng cơ quá mức'}, {
  'id': 'M62.8',
  'english': 'Other specified disorders of muscle',
  'vietnamese': 'Các bệnh cơ đặc hiệu khác',
}, {
  'id': 'M62.9',
  'english': 'Disorder of muscle, unspecified',
  'vietnamese': 'Bệnh cơ không đặc hiệu',
}, {
  'id': 'M63*',
  'english': 'Disorders of muscle in diseases classified elsewhere',
  'vietnamese': 'Bệnh cơ trong những bệnh được xếp loại ở mục khác',
}, {
  'id': 'M63.0*',
  'english': 'Myositis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm cơ trong các bệnh nhiễm khuẩn được xếp loại ở mục khác',
}, {
  'id': 'M63.1*',
  'english': 'Myositis in protozoal and parasitic infections classified elsewhere',
  'vietnamese': 'Viêm cơ trong nhiễm ký sinh trùng và động vật đơn bào được xếp loại ở mục khác',
}, {
  'id': 'M63.2*',
  'english': 'Myositis in other infectious diseases classified elsewhere',
  'vietnamese': 'Viêm cơ trong các bệnh nhiễm khuẩn khác được xếp loại ở mục khác',
}, {
  'id': 'M63.3*',
  'english': 'Myositis in sarcoidosisD86.8',
  'vietnamese': 'Viêm cơ trong bệnh sacoidosis D86.8',
}, {
  'id': 'M63.8*',
  'english': 'Other disorders of muscle in diseases classified elsewhere',
  'vietnamese': 'Các bệnh cơ khác trong những bệnh được xếp loại ở mục khác',
}, {
  'id': 'M65',
  'english': 'Synovitis and tenosynovitis',
  'vietnamese': 'Viêm màng hoạt dịch và viêm bao gân',
}, {'id': 'M65.0', 'english': 'Abscess of tendon sheath', 'vietnamese': 'Áp xe bao gân'}, {
  'id': 'M65.1',
  'english': 'Other infective (teno)synovitis',
  'vietnamese': 'Các chứng viêm màng hoạt dịch hoặc viêm bao gân nhiễm khuẩn khác',
}, {'id': 'M65.2', 'english': 'Calcific tendinitis', 'vietnamese': 'Viêm gân vôi hóa'}, {
  'id': 'M65.3',
  'english': 'Trigger finger',
  'vietnamese': 'Ngón tay lò xo',
}, {
  'id': 'M65.4',
  'english': 'Radial styloid tenosynovitis [de Quervain]',
  'vietnamese': 'Viêm bao gân mỏm trâm quay [de Quervain]',
}, {
  'id': 'M65.8',
  'english': 'Other synovitis and tenosynovitis',
  'vietnamese': 'Các viêm màng hoạt dịch và viêm bao gân khác',
}, {
  'id': 'M65.9',
  'english': 'Synovitis and tenosynovitis, unspecified',
  'vietnamese': 'Viêm màng hoạt dịch và viêm bao gân, không đặc hiệu',
}, {
  'id': 'M66',
  'english': 'Spontaneous rupture of synovium and tendon',
  'vietnamese': 'Rách (đứt) tự nhiên của màng hoạt dịch và gân',
}, {
  'id': 'M66.0',
  'english': 'Rupture of popliteal cyst',
  'vietnamese': 'Vỡ u nang khoeo chân',
}, {
  'id': 'M66.1',
  'english': 'Rupture of synovium',
  'vietnamese': 'Rách màng hoạt dịch',
}, {
  'id': 'M66.2',
  'english': 'Spontaneous rupture of extensor tendons',
  'vietnamese': 'Rách (đứt) tự nhiên các gân duỗi',
}, {
  'id': 'M66.3',
  'english': 'Spontaneous rupture of flexor tendons',
  'vietnamese': 'rách (đứt) tự nhiên các gân gấp',
}, {
  'id': 'M66.4',
  'english': 'Spontaneous rupture of other tendons',
  'vietnamese': 'rách (đứt) tự nhiên các gân khác',
}, {
  'id': 'M66.5',
  'english': 'Spontaneous rupture of unspecified tendon',
  'vietnamese': 'Rách (đứt) tự nhiên các gân không đặc hiệu',
}, {
  'id': 'M67',
  'english': 'Other disorders of synovium and tendon',
  'vietnamese': 'Các bệnh khác của màng hoạt dịch và gân',
}, {
  'id': 'M67.0',
  'english': 'Short Achilles tendon (acquired)',
  'vietnamese': 'Gân Achille (gót) ngắn (mắc phải)',
}, {
  'id': 'M67.1',
  'english': 'Other contracture of tendon (sheath)',
  'vietnamese': 'Các chứng co rút gân (bao gân) khác',
}, {
  'id': 'M67.2',
  'english': 'Synovial hypertrophy, not elsewhere classified',
  'vietnamese': 'Phì đại màng hoạt dịch, không được xếp loại ở nơi khác',
}, {
  'id': 'M67.3',
  'english': 'Transient synovitis',
  'vietnamese': 'Viêm màng hoạt dịch thóang qua',
}, {'id': 'M67.4', 'english': 'Ganglion', 'vietnamese': 'Kén'}, {
  'id': 'M67.8',
  'english': 'Other specified disorders of synovium and tendon',
  'vietnamese': 'Các bệnh của màng hoạt dịch và gân đặc hiệu khác',
}, {
  'id': 'M67.9',
  'english': 'Disorder of synovium and tendon, unspecified',
  'vietnamese': 'Bệnh của màng hoạt dịch và gân không đặc hiệu',
}, {
  'id': 'M68*',
  'english': 'Disorders of synovium and tendon in diseases classified elsewhere',
  'vietnamese': 'Các bệnh của màng hoạt dịch và gân trong những bệnh được xếp loại ở mục khác',
}, {
  'id': 'M68.0*',
  'english': 'Synovitis and tenosynovitis in bacterial diseases classified elsewhere',
  'vietnamese': 'Viêm màng hoạt dịch và viêm bao gân trong những bệnh nhiễm khuẩn được xếp loại ở mục khác',
}, {
  'id': 'M68.8*',
  'english': 'Other disorders of synovium and tendon in diseases classified elsewhere',
  'vietnamese': 'Các bệnh của màng hoạt dịch và gân khác trong những bệnh được xếp loại ở mục khác',
}, {
  'id': 'M70',
  'english': 'Soft tissue disorders related to use, overuse and pressure',
  'vietnamese': 'Các bệnh mô mềm liên quan đến vận động, vận động quá mức và bị chèn ép',
}, {
  'id': 'M70.0',
  'english': 'Chronic crepitant synovitis of hand and wrist',
  'vietnamese': 'Viêm màng hoạt dịch khô mãn tính ở bàn tay và cổ tay',
}, {'id': 'M70.1', 'english': 'Bursitis of hand', 'vietnamese': 'Viêm túi thanh mạc bàn tay'}, {
  'id': 'M70.2',
  'english': 'Olecranon bursitis',
  'vietnamese': 'Viêm túi thanh mạc mỏm khủyu',
}, {
  'id': 'M70.3',
  'english': 'Other bursitis of elbow',
  'vietnamese': 'Viêm túi thanh mạc khác ở khủyu',
}, {
  'id': 'M70.4',
  'english': 'Prepatellar bursitis',
  'vietnamese': 'Viêm túi thanh mạc trước xương bánh chè',
}, {
  'id': 'M70.5',
  'english': 'Other bursitis of knee',
  'vietnamese': 'Viêm túi thanh mạc khác ở khớp gối',
}, {
  'id': 'M70.6',
  'english': 'Trochanteric bursitis',
  'vietnamese': 'Viêm túi thanh mạc mấu chuyển',
}, {
  'id': 'M70.7',
  'english': 'Other bursitis of hip',
  'vietnamese': 'Viêm túi thanh mạc khác ở háng',
}, {
  'id': 'M70.8',
  'english': 'Other soft tissue disorders related to use, overuse and pressure',
  'vietnamese': 'Các bệnh mô mềm khác liên quan đến vận động, vận động quá mức và bị chèn ép',
}, {
  'id': 'M70.9',
  'english': 'Unspecified soft tissue disorder related to use, overuse and pressure',
  'vietnamese': 'Các bệnh mô mềm không đặc hiệu liên quan đến vận động, vận động quá mức và bị chèn ép',
}, {'id': 'M71', 'english': 'Other bursopathies', 'vietnamese': 'Các bệnh túi thanh mạc khác'}, {
  'id': 'M71.0',
  'english': 'Abscess of bursa',
  'vietnamese': 'Áp xe túi thanh mạc',
}, {
  'id': 'M71.1',
  'english': 'Other infective bursitis',
  'vietnamese': 'Viêm túi thanh mạc nhiễm khuẩn khác',
}, {
  'id': 'M71.2',
  'english': 'Synovial cyst of popliteal space [Baker]',
  'vietnamese': 'Kén (nang) màng hoạt dịch vùng khoeo [Baker]',
}, {
  'id': 'M71.3',
  'english': 'Other bursal cyst',
  'vietnamese': 'Kén (nang) túi thanh mạc khác',
}, {
  'id': 'M71.4',
  'english': 'Calcium deposit in bursa',
  'vietnamese': 'Lắng đọng can xi ở túi thanh mạc',
}, {
  'id': 'M71.5',
  'english': 'Other bursitis, not elsewhere classified',
  'vietnamese': 'Các chứng viêm túi thanh mạc khác không được xếp loại ở mục khác',
}, {
  'id': 'M71.8',
  'english': 'Other specified bursopathies',
  'vietnamese': 'Các bệnh túi thanh mạc đặc hiệu khác',
}, {
  'id': 'M71.9',
  'english': 'Bursopathy, unspecified',
  'vietnamese': 'Bệnh túi thanh mạc không đặc hiệu',
}, {'id': 'M72', 'english': 'Fibroblastic disorders', 'vietnamese': 'Các bệnh nguyên bào sợi'}, {
  'id': 'M72.0',
  'english': 'Palmar fascial fibromatosis [Dupuytren]',
  'vietnamese': 'Bệnh xơ cân gan tay (Dupuytren)',
}, {'id': 'M72.1', 'english': 'Knuckle pads', 'vietnamese': 'U đầu xương bàn tay'}, {
  'id': 'M72.2',
  'english': 'Plantar fascial fibromatosis',
  'vietnamese': 'Bệnh xơ cân gan chân',
}, {
  'id': 'M72.4',
  'english': 'Pseudosarcomatous fibromatosis',
  'vietnamese': 'Bệnh xơ giả sacôm',
}, {
  'id': 'M72.6',
  'english': 'Necrotizing fasciitis',
  'vietnamese': 'Viêm cân hoại tử',
}, {
  'id': 'M72.8',
  'english': 'Other fibroblastic disorders',
  'vietnamese': 'Các bệnh nguyên bào sợi khác',
}, {
  'id': 'M72.9',
  'english': 'Fibroblastic disorder, unspecified',
  'vietnamese': 'Bệnh nguyên bào sợi không đặc hiệu',
}, {
  'id': 'M73*',
  'english': 'Soft tissue disorders in diseases classified elsewhere',
  'vietnamese': 'Các bệnh mô mềm trong những bệnh được xếp loại ở mục khác',
}, {
  'id': 'M73.0*',
  'english': 'Gonococcal bursitis (A54.4†)',
  'vietnamese': 'Viêm túi thanh mạc do lậu cầu (A54.4†)',
}, {
  'id': 'M73.1*',
  'english': 'Syphilitic bursitis (A52.7†)',
  'vietnamese': 'Viêm túi thanh mạc do giang mai (A52.7†)',
}, {
  'id': 'M73.8*',
  'english': 'Other soft tissue disorders in diseases classified elsewhere',
  'vietnamese': 'Các rối loạn mô mềm khác trong những bệnh được xếp loại ở mục khác',
}, {'id': 'M75', 'english': 'Shoulder lesions', 'vietnamese': 'Tổn thương vai'}, {
  'id': 'M75.0',
  'english': 'Adhesive capsulitis of shoulder',
  'vietnamese': 'Viêm bao hoạt dịch co thắt khớp vai',
}, {'id': 'M75.1', 'english': 'Rotator cuff syndrome', 'vietnamese': 'Hội chứng bao gân xoay'}, {
  'id': 'M75.2',
  'english': 'Bicipital tendinitis',
  'vietnamese': 'Viêm gân cơ nhị đầu',
}, {
  'id': 'M75.3',
  'english': 'Calcific tendinitis of shoulder',
  'vietnamese': 'Viêm gân vôi hóa ở vai',
}, {
  'id': 'M75.4',
  'english': 'Impingement syndrome of shoulder',
  'vietnamese': 'Hội chứng chèn ép vùng vai',
}, {'id': 'M75.5', 'english': 'Bursitis of shoulder', 'vietnamese': 'Viêm túi thanh mạc ở vai'}, {
  'id': 'M75.8',
  'english': 'Other shoulder lesions',
  'vietnamese': 'Các tổn thương khác ở vai',
}, {
  'id': 'M75.9',
  'english': 'Shoulder lesion, unspecified',
  'vietnamese': 'Tổn thương vai không đặc hiệu',
}, {
  'id': 'M76',
  'english': 'Enthesopathies of lower limb, excluding foot',
  'vietnamese': 'Bệnh điểm bám gân-dây chằng ở chi dưới, không kể bàn chân',
}, {'id': 'M76.0', 'english': 'Gluteal tendinitis', 'vietnamese': 'Viêm gân ở vùng mông'}, {
  'id': 'M76.1',
  'english': 'Psoas tendinitis',
  'vietnamese': 'Viêm gân cơ thắt lưng',
}, {'id': 'M76.2', 'english': 'Iliac crest spur', 'vietnamese': 'gai xương ở mào chậu'}, {
  'id': 'M76.3',
  'english': 'Iliotibial band syndrome',
  'vietnamese': 'Hội chứng dải xơ chậu chày (Maissiat)',
}, {
  'id': 'M76.4',
  'english': 'Tibial collateral bursitis [Pellegrini-Stieda]',
  'vietnamese': 'Viêm túi thanh mạc bên của xương chày [Pellegrini - Stieda]',
}, {'id': 'M76.5', 'english': 'Patellar tendinitis', 'vietnamese': 'Viêm gân bánh chè'}, {
  'id': 'M76.6',
  'english': 'Achilles tendinitis',
  'vietnamese': 'Viêm gân Achille',
}, {'id': 'M76.7', 'english': 'Peroneal tendinitis', 'vietnamese': 'Viêm gân cơ mác'}, {
  'id': 'M76.8',
  'english': 'Other enthesopathies of lower limb, excluding foot',
  'vietnamese': 'Các bệnh điểm bám gân - dây chằng khác ở chi dưới, không kể bàn chân',
}, {
  'id': 'M76.9',
  'english': 'Enthesopathy of lower limb, unspecified',
  'vietnamese': 'Bệnh điểm bám gân dây chằng chi dưới không đặc hiệu',
}, {
  'id': 'M77',
  'english': 'Other enthesopathies',
  'vietnamese': 'Các bệnh điểm bám gân - dây chằng khác',
}, {
  'id': 'M77.0',
  'english': 'Medial epicondylitis',
  'vietnamese': 'Viêm điểm bám gân lồi cầu trong xương cánh tay',
}, {
  'id': 'M77.1',
  'english': 'Lateral epicondylitis',
  'vietnamese': 'Viêm điểm bám gân lồi cầu ngoài xương cánh tay',
}, {'id': 'M77.2', 'english': 'Periarthritis of wrist', 'vietnamese': 'Viêm quanh khớp cổ tay'}, {
  'id': 'M77.3',
  'english': 'Calcaneal spur',
  'vietnamese': 'Gai Xương gót',
}, {'id': 'M77.4', 'english': 'Metatarsalgia', 'vietnamese': 'Đau xương đốt bàn chân'}, {
  'id': 'M77.5',
  'english': 'Other enthesopathy of foot',
  'vietnamese': 'Các bệnh điểm bám gân - dây chằng bàn chân khác',
}, {
  'id': 'M77.8',
  'english': 'Other enthesopathies, not elsewhere classified',
  'vietnamese': 'Các bệnh điểm bám gân - dây chằng khác, không được xếp loại ở mục khác',
}, {
  'id': 'M77.9',
  'english': 'Enthesopathy, unspecified',
  'vietnamese': 'Bệnh điểm bám gân - dây chằng không đặc hiệu',
}, {
  'id': 'M79',
  'english': 'Other soft tissue disorders, not elsewhere classified',
  'vietnamese': 'Bệnh khác của mô mềm, chưa xếp loại mục khác',
}, {
  'id': 'M79.0',
  'english': 'Rheumatism, unspecified',
  'vietnamese': 'Bệnh thấp khớp, không đặc hiệu',
}, {'id': 'M79.1', 'english': 'Myalgia', 'vietnamese': 'Đau cơ'}, {
  'id': 'M79.2',
  'english': 'Neuralgia and neuritis, unspecified',
  'vietnamese': 'Đau dây thần kinh và viêm dây thần kinh, không đặc hiệu',
}, {
  'id': 'M79.3',
  'english': 'Panniculitis, unspecified',
  'vietnamese': 'Viêm mô mỡ dưới da, không đặc hiệu',
}, {
  'id': 'M79.4',
  'english': 'Hypertrophy of (infrapatellar) fat pad',
  'vietnamese': 'Phì đại mỡ vùng bánh chè',
}, {
  'id': 'M79.5',
  'english': 'Residual foreign body in soft tissue',
  'vietnamese': 'Dị vật tồn tại trong mô mềm',
}, {'id': 'M79.6', 'english': 'Pain in limb', 'vietnamese': 'Đau ở một chi'}, {
  'id': 'M79.7',
  'english': 'Fibromyalgia',
  'vietnamese': 'Đau mô sợi- cơ',
}, {
  'id': 'M79.8',
  'english': 'Other specified soft tissue disorders',
  'vietnamese': 'Các bệnh lý đặc hiệu khác của mô mềm',
}, {
  'id': 'M79.9',
  'english': 'Soft tissue disorder, unspecified',
  'vietnamese': 'Các bệnh lý của mô mềm, không đặc hiệu',
}, {
  'id': 'M80',
  'english': 'Osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.0',
  'english': 'Postmenopausal osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương sau mãn kinh có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.1',
  'english': 'Postoophorectomy osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương sau cắt buồng trứng có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.2',
  'english': 'Osteoporosis of disuse with pathological fracture',
  'vietnamese': 'Loãng xương do bất động có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.3',
  'english': 'Postsurgical malabsorption osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương do kém hấp thu sau phẫu thuật có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.4',
  'english': 'Drug-induced osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương do dùng thuốc có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.5',
  'english': 'Idiopathic osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương tự phát có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.8',
  'english': 'Other osteoporosis with pathological fracture',
  'vietnamese': 'Các loãng xương khác có kèm gẫy xương bệnh lý',
}, {
  'id': 'M80.9',
  'english': 'Unspecified osteoporosis with pathological fracture',
  'vietnamese': 'Loãng xương không đặc hiệu có kèm gẫy xương bệnh lý',
}, {
  'id': 'M81',
  'english': 'Osteoporosis without pathological fracture',
  'vietnamese': 'Loãng xương không kèm gẫy xương bệnh lý',
}, {
  'id': 'M81.0',
  'english': 'Postmenopausal osteoporosis',
  'vietnamese': 'Loãng xương sau mãn kinh',
}, {
  'id': 'M81.1',
  'english': 'Postoophorectomy osteoporosis',
  'vietnamese': 'Loãng xương sau cắt buồng trứng',
}, {
  'id': 'M81.2',
  'english': 'Osteoporosis of disuse',
  'vietnamese': 'Loãng xương do không vận động',
}, {
  'id': 'M81.3',
  'english': 'Postsurgical malabsorption osteoporosis',
  'vietnamese': 'Loãng xương do rối loạn hấp thu sau phẫu thuật',
}, {
  'id': 'M81.4',
  'english': 'Drug-induced osteoporosis',
  'vietnamese': 'Loãng xương do dùng thuốc',
}, {'id': 'M81.5', 'english': 'Idiopathic osteoporosis', 'vietnamese': 'Loãng xương tự phát'}, {
  'id': 'M81.6',
  'english': 'Localized osteoporosis [Lequesne]',
  'vietnamese': 'Loãng xương khu trú',
}, {'id': 'M81.8', 'english': 'Other osteoporosis', 'vietnamese': 'Các bệnh loãng xương khác'}, {
  'id': 'M81.9',
  'english': 'Osteoporosis, unspecified',
  'vietnamese': 'Loãng xương không đặc hiệu',
}, {
  'id': 'M82*',
  'english': 'Osteoporosis in diseases classified elsewhere',
  'vietnamese': 'Loãng xương trong các bệnh đã xếp loại ở mục khác',
}, {
  'id': 'M82.0*',
  'english': 'Osteoporosis in multiple myelomatosis (C90.0†)',
  'vietnamese': 'Loãng xương trong bệnh đa u tủy xương (C90.0†)',
}, {
  'id': 'M82.1*',
  'english': 'Osteoporosis in endocrine disorders (E00-E34†)',
  'vietnamese': 'Loãng xương trong các bệnh nội tiết (E00-E34†)',
}, {
  'id': 'M82.8*',
  'english': 'Osteoporosis in other diseases classified elsewhere',
  'vietnamese': 'Loãng xương trong các bệnh khác đã xếp loại',
}, {'id': 'M83', 'english': 'Adult osteomalacia', 'vietnamese': 'Nhuyễn xương người lớn'}, {
  'id': 'M83.0',
  'english': 'Puerperal osteomalacia',
  'vietnamese': 'Nhuyễn xương sau đẻ',
}, {'id': 'M83.1', 'english': 'Senile osteomalacia', 'vietnamese': 'Nhuyễn xương tuổi già'}, {
  'id': 'M83.2',
  'english': 'Adult osteomalacia due to malabsorption',
  'vietnamese': 'Nhuyễn xương người lớn do rối loạn hấp thu',
}, {
  'id': 'M83.3',
  'english': 'Adult osteomalacia due to malnutrition',
  'vietnamese': 'Nhuyễn xương người lớn do rối loạn dinh dưỡng',
}, {
  'id': 'M83.4',
  'english': 'Aluminium bone disease',
  'vietnamese': 'Bệnh nhiễm nhôm (almunium) xương',
}, {
  'id': 'M83.5',
  'english': 'Other drug-induced osteomalacia in adults',
  'vietnamese': 'Các bệnh nhuyễn xương khác ở người lớn do dùng thuốc',
}, {
  'id': 'M83.8',
  'english': 'Other adult osteomalacia',
  'vietnamese': 'Các bệnh nhuyễn xương khác ở người lớn',
}, {
  'id': 'M83.9',
  'english': 'Adult osteomalacia, unspecified',
  'vietnamese': 'Các bệnh nhuyễn xương ở người lớn không đặc hiệu',
}, {
  'id': 'M84',
  'english': 'Disorders of continuity of bone',
  'vietnamese': 'Các rối loạn về sự liên tục của xương',
}, {'id': 'M84.0', 'english': 'Malunion of fracture', 'vietnamese': 'Can lệch'}, {
  'id': 'M84.1',
  'english': 'Nonunion of fracture [pseudarthrosis]',
  'vietnamese': 'gẫy xương không liền (khớp giả)',
}, {'id': 'M84.2', 'english': 'Delayed union of fracture', 'vietnamese': 'gẫy xương chậm liền'}, {
  'id': 'M84.3',
  'english': 'Stress fracture, not elsewhere classified',
  'vietnamese': 'Gẫy xương do vi chấn, chưa xếp loại ở mục khác',
}, {
  'id': 'M84.4',
  'english': 'Pathological fracture, not elsewhere classified',
  'vietnamese': 'Gẫy xương bệnh lý, chưa xếp loại mục khác',
}, {
  'id': 'M84.8',
  'english': 'Other disorders of continuity of bone',
  'vietnamese': 'Các rối loạn khác về sự liên tục của xương',
}, {
  'id': 'M84.9',
  'english': 'Disorder of continuity of bone, unspecified',
  'vietnamese': 'Các rối loạn khác về sự liên tục của xương không đặc hiệu',
}, {
  'id': 'M85',
  'english': 'Other disorders of bone density and structure',
  'vietnamese': 'Rối loạn khác về tỉ trọng (mật độ) và cấu trúc của xương',
}, {
  'id': 'M85.0',
  'english': 'Fibrous dysplasia (monostotic)',
  'vietnamese': 'Loạn sản xơ xương (khu trú)',
}, {'id': 'M85.1', 'english': 'Skeletal fluorosis', 'vietnamese': 'Bệnh nhiễm Fluo xương'}, {
  'id': 'M85.2',
  'english': 'Hyperostosis of skull',
  'vietnamese': 'Quá sản xương sọ',
}, {'id': 'M85.3', 'english': 'Osteitis condensans', 'vietnamese': 'Viêm xương dạng đặc'}, {
  'id': 'M85.4',
  'english': 'Solitary bone cyst',
  'vietnamese': 'Nang xương đơn độc',
}, {
  'id': 'M85.5',
  'english': 'Aneurysmal bone cyst',
  'vietnamese': 'Nang phình mạch của xương',
}, {
  'id': 'M85.6',
  'english': 'Other cyst of bone',
  'vietnamese': 'Các nang khác của xương',
}, {
  'id': 'M85.8',
  'english': 'Other specified disorders of bone density and structure',
  'vietnamese': 'Những rối loạn đặc hiệu khác về mật độ và cấu trúc của xương',
}, {
  'id': 'M85.9',
  'english': 'Disorder of bone density and structure, unspecified',
  'vietnamese': 'Các rối loạn về mật độ và cấu trúc của xương, không đặc hiệu',
}, {'id': 'M86', 'english': 'Osteomyelitis', 'vietnamese': 'viêm xương tủy'}, {
  'id': 'M86.0',
  'english': 'Acute haematogenous osteomyelitis',
  'vietnamese': 'Viêm xương tủy theo đường máu cấp tính',
}, {
  'id': 'M86.1',
  'english': 'Other acute osteomyelitis',
  'vietnamese': 'Các bệnh viêm xương tủy cấp khác',
}, {'id': 'M86.2', 'english': 'Subacute osteomyelitis', 'vietnamese': 'Viêm xương tủy bán cấp'}, {
  'id': 'M86.3',
  'english': 'Chronic multifocal osteomyelitis',
  'vietnamese': 'Viêm xương tủy mãn tính nhiều ổ',
}, {
  'id': 'M86.4',
  'english': 'Chronic osteomyelitis with draining sinus',
  'vietnamese': 'Viêm xương tủy mãn tính có đường rò',
}, {
  'id': 'M86.5',
  'english': 'Other chronic haematogenous osteomyelitis',
  'vietnamese': 'Viêm xương tủy do bệnh máu mãn tínhkhác',
}, {
  'id': 'M86.6',
  'english': 'Other chronic osteomyelitis',
  'vietnamese': 'Viêm xương tủy mãn tính khác',
}, {'id': 'M86.8', 'english': 'Other osteomyelitis', 'vietnamese': 'Các viêm xương tủy khác'}, {
  'id': 'M86.9',
  'english': 'Osteomyelitis, unspecified',
  'vietnamese': 'Viêm xương tủy không đặc hiệu',
}, {'id': 'M87', 'english': 'Osteonecrosis', 'vietnamese': 'Hoại tử xương'}, {
  'id': 'M87.0',
  'english': 'Idiopathic aseptic necrosis of bone',
  'vietnamese': 'Bệnh hoại tử vô mạch tự phát của xương',
}, {
  'id': 'M87.1',
  'english': 'Osteonecrosis due to drugs',
  'vietnamese': 'Hoại tử xương do thuốc',
}, {
  'id': 'M87.2',
  'english': 'Osteonecrosis due to previous trauma',
  'vietnamese': 'Hoại tử xương do chấn thương từ trước',
}, {
  'id': 'M87.3',
  'english': 'Other secondary osteonecrosis',
  'vietnamese': 'Hoại tử xương thứ phát khác',
}, {'id': 'M87.8', 'english': 'Other osteonecrosis', 'vietnamese': 'Hoại tử xương khác'}, {
  'id': 'M87.9',
  'english': 'Osteonecrosis, unspecified',
  'vietnamese': 'Hoại tử xương không đặc hiệu',
}, {
  'id': 'M88',
  'english': 'Paget disease of bone [osteitis deformans]',
  'vietnamese': 'Bệnh Paget (viêm xương biến dạng)',
}, {'id': 'M88.0', 'english': 'Paget disease of skull', 'vietnamese': 'Bệnh Paget xương sọ'}, {
  'id': 'M88.8',
  'english': 'Paget disease of other bones',
  'vietnamese': 'Bệnh Paget của các xương khác',
}, {
  'id': 'M88.9',
  'english': 'Paget disease of bone, unspecified',
  'vietnamese': 'Bệnh Paget của xương, không đặc hiệu',
}, {'id': 'M89', 'english': 'Other disorders of bone', 'vietnamese': 'Rối loạn khác của xương'}, {
  'id': 'M89.0',
  'english': 'Algoneurodystrophy',
  'vietnamese': 'Hội chứng Loạn dưỡng-thần kinh-đau',
}, {'id': 'M89.1', 'english': 'Epiphyseal arrest', 'vietnamese': 'Không phát triển đầu xương'}, {
  'id': 'M89.2',
  'english': 'Other disorders of bone development and growth',
  'vietnamese': 'Các rối loạn khác về sự phát triển và tăng trưởng của xương',
}, {'id': 'M89.3', 'english': 'Hypertrophy of bone', 'vietnamese': 'Phì đại xương'}, {
  'id': 'M89.4',
  'english': 'Other hypertrophic osteoarthropathy',
  'vietnamese': 'Các bệnh phì đại xương khớp khác',
}, {'id': 'M89.5', 'english': 'Osteolysis', 'vietnamese': 'Tiêu xương'}, {
  'id': 'M89.6',
  'english': 'Osteopathy after poliomyelitis',
  'vietnamese': 'Bệnh xương sau viêm tủy xám (bại liệt)',
}, {
  'id': 'M89.8',
  'english': 'Other specified disorders of bone',
  'vietnamese': 'Các rối loạn đặc hiệu khác của xương',
}, {
  'id': 'M89.9',
  'english': 'Disorder of bone, unspecified',
  'vietnamese': 'Các rối loạn xương không đặc hiệu',
}, {
  'id': 'M90*',
  'english': 'Osteopathies in diseases classified elsewhere',
  'vietnamese': 'Bệnh xương trong các bệnh đã xếp loại mục khác',
}, {
  'id': 'M90.0*',
  'english': 'Tuberculosis of bone (A18.0†)',
  'vietnamese': 'Lao xương (A18.0†)',
}, {
  'id': 'M90.1*',
  'english': 'Periostitis in other infectious diseases classified elsewhere',
  'vietnamese': 'Viêm màng xương trong các bệnh nhiễm khuẩn khác đã phân loại ở nơi khác',
}, {
  'id': 'M90.2*',
  'english': 'Osteopathy in other infectious diseases classified elsewhere',
  'vietnamese': 'Bệnh xương trong các bệnh nhiễm khuẩn khác đã xếp loại ở mục khác',
}, {
  'id': 'M90.3*',
  'english': 'Osteonecrosis in caisson disease (T70.3†)',
  'vietnamese': 'Hoại tử xương trong bệnh khí nén (thùng thợ lặn) (T70.3†)',
}, {
  'id': 'M90.4*',
  'english': 'Osteonecrosis due to haemoglobinopathy (D50-D64†)',
  'vietnamese': 'Hoại tử xương do bệnh huyết cầu tố (D50-D64†)',
}, {
  'id': 'M90.5*',
  'english': 'Osteonecrosis in other diseases classified elsewhere',
  'vietnamese': 'Hoại tử xương trong các bệnh khác đã xếp loại ở mục khác',
}, {
  'id': 'M90.6*',
  'english': 'Osteitis deformans in neoplastic disease (C00-D48†)',
  'vietnamese': 'Viêm xương biến dạng trong các bệnh khối u (C00-D48†)',
}, {
  'id': 'M90.7*',
  'english': 'Fracture of bone in neoplastic disease (C00-D48†)',
  'vietnamese': 'Gẫy xương trong các bệnh khối u (C00-D48†)',
}, {
  'id': 'M90.8*',
  'english': 'Osteopathy in other diseases classified elsewhere',
  'vietnamese': 'Bệnh lý xương trong các bệnh khác đã xếp loại ở mục khác',
}, {
  'id': 'M91',
  'english': 'Juvenile osteochondrosis of hip and pelvis',
  'vietnamese': 'Hư điểm cốt hóa tại khớp háng và khung chậu ở trẻ em',
}, {
  'id': 'M91.0',
  'english': 'Juvenile osteochondrosis of pelvis',
  'vietnamese': 'Hư điểm cốt hóa tại khung chậu ở trẻ em',
}, {
  'id': 'M91.1',
  'english': 'Juvenile osteochondrosis of head of femur [Legg-Calvé-Perthes]',
  'vietnamese': 'Hư điểm cốt hóa chỏm xương đùi ở trẻ em[Legg-Calvé-Pethès]',
}, {'id': 'M91.2', 'english': 'Coxa plana', 'vietnamese': 'Khớp háng dẹt'}, {
  'id': 'M91.3',
  'english': 'Pseudocoxalgia',
  'vietnamese': 'Giả lao khớp háng',
}, {
  'id': 'M91.8',
  'english': 'Other juvenile osteochondrosis of hip and pelvis',
  'vietnamese': 'Hư điểm cốt hóa tại khớp háng và khung chậu khác ở trẻ em',
}, {
  'id': 'M91.9',
  'english': 'Juvenile osteochondrosis of hip and pelvis, unspecified',
  'vietnamese': 'Hư điểm cốt hóa tại khớp háng và khung chậu ở trẻ em không đặc hiệu',
}, {
  'id': 'M92',
  'english': 'Other juvenile osteochondrosis',
  'vietnamese': 'Hư điểm cốt hóa khác ở trẻ em',
}, {
  'id': 'M92.0',
  'english': 'Juvenile osteochondrosis of humerus',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương cánh tay',
}, {
  'id': 'M92.1',
  'english': 'Juvenile osteochondrosis of radius and ulna',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương quay và xương trụ',
}, {
  'id': 'M92.2',
  'english': 'Juvenile osteochondrosis of hand',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở tay',
}, {
  'id': 'M92.3',
  'english': 'Other juvenile osteochondrosis of upper limb',
  'vietnamese': 'Hư điểm cốt hóa khác ở trẻ em ở chi trên',
}, {
  'id': 'M92.4',
  'english': 'Juvenile osteochondrosis of patella',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương bánh chè',
}, {
  'id': 'M92.5',
  'english': 'Juvenile osteochondrosis of tibia and fibula',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương chày và xương mác',
}, {
  'id': 'M92.6',
  'english': 'Juvenile osteochondrosis of tarsus',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương cổ chân',
}, {
  'id': 'M92.7',
  'english': 'Juvenile osteochondrosis of metatarsus',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em ở xương bàn chân',
}, {
  'id': 'M92.8',
  'english': 'Other specified juvenile osteochondrosis',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em đặc hiệu khác',
}, {
  'id': 'M92.9',
  'english': 'Juvenile osteochondrosis, unspecified',
  'vietnamese': 'Hư điểm cốt hóa ở trẻ em khác, không đặc hiệu',
}, {
  'id': 'M93',
  'english': 'Other osteochondropathies',
  'vietnamese': 'Các bệnh xương - sụn khác',
}, {
  'id': 'M93.0',
  'english': 'Slipped upper femoral epiphysis (nontraumatic)',
  'vietnamese': 'Trượt đầu trên xương đùi (không do chấn thương)',
}, {
  'id': 'M93.1',
  'english': 'Kienböck s disease of adults',
  'vietnamese': 'Bệnh Kienbock ở người lớn',
}, {'id': 'M93.2', 'english': 'Osteochondritis dissecans', 'vietnamese': 'Viêm xương sụn tách'}, {
  'id': 'M93.8',
  'english': 'Other specified osteochondropathies',
  'vietnamese': 'Các bệnh xương sụn đặc hiệu khác',
}, {
  'id': 'M93.9',
  'english': 'Osteochondropathy, unspecified',
  'vietnamese': 'Các bệnh xương sụn không đặc hiệu',
}, {
  'id': 'M94',
  'english': 'Other disorders of cartilage',
  'vietnamese': 'Các bệnh lý khác của sụn',
}, {
  'id': 'M94.0',
  'english': 'Chondrocostal junction syndrome [Tietze]',
  'vietnamese': 'Hội chứng khớp sụn sườn (Tietze)',
}, {
  'id': 'M94.1',
  'english': 'Relapsing polychondritis',
  'vietnamese': 'Viêm đa sụn hay tái phát',
}, {
  'id': 'M94.2',
  'english': 'Chondromalacia',
  'vietnamese': 'Nhuyễn sụn',
}, {'id': 'M94.3', 'english': 'Chondrolysis', 'vietnamese': 'Tiêu sụn'}, {
  'id': 'M94.8',
  'english': 'Other specified disorders of cartilage',
  'vietnamese': 'Các bệnh lý sụn đặc hiệu khác',
}, {
  'id': 'M94.9',
  'english': 'Disorder of cartilage, unspecified',
  'vietnamese': 'Các bệnh sụn không đặc hiệu',
}, {
  'id': 'M95',
  'english': 'Other acquired deformities of musculoskeletal system and connective tissue',
  'vietnamese': 'Các biến dạng mắc phải của hệ cơ-xương-khớp và mô liên kết',
}, {
  'id': 'M95.0',
  'english': 'Acquired deformity of nose',
  'vietnamese': 'Biến dạng mắc phải của mũi',
}, {'id': 'M95.1', 'english': 'Cauliflower ear', 'vietnamese': 'Vành tai sùi'}, {
  'id': 'M95.2',
  'english': 'Other acquired deformity of head',
  'vietnamese': 'Biến dạng mắc phải khác của đầu',
}, {
  'id': 'M95.3',
  'english': 'Acquired deformity of neck',
  'vietnamese': 'Biến dạng mắc phải của cổ',
}, {
  'id': 'M95.4',
  'english': 'Acquired deformity of chest and rib',
  'vietnamese': 'Biến dạng mắc phải của lồng ngực và sườn',
}, {
  'id': 'M95.5',
  'english': 'Acquired deformity of pelvis',
  'vietnamese': 'Biến dạng mắc phải của khung chậu',
}, {
  'id': 'M95.8',
  'english': 'Other specified acquired deformities of musculoskeletal system',
  'vietnamese': 'Các biến dạng mắc phải đặc hiệukhác của hệ cơ-xương-khớp',
}, {
  'id': 'M95.9',
  'english': 'Acquired deformity of musculoskeletal system, unspecified',
  'vietnamese': 'Các biến dạng mắc phải không đặc hiệu của hệ cơ-xương-khớp',
}, {
  'id': 'M96',
  'english': 'Postprocedural musculoskeletal disorders, not elsewhere classified',
  'vietnamese': 'Các bệnh lý của hệ cơ-xương-khớp sau can thiệp, không xếp loại ở mục khác',
}, {
  'id': 'M96.0',
  'english': 'Pseudarthrosis after fusion or arthrodesis',
  'vietnamese': 'Khớp giả sau thủ thuật làm cứng khớp',
}, {
  'id': 'M96.1',
  'english': 'Postlaminectomy syndrome, not elsewhere classified',
  'vietnamese': 'Hội chứng sau cắt cung sau, không xếp loại ở mục khác',
}, {'id': 'M96.2', 'english': 'Postradiation kyphosis', 'vietnamese': 'Gù sau chiếu tia xạ'}, {
  'id': 'M96.3',
  'english': 'Postlaminectomy kyphosis',
  'vietnamese': 'Gù sau cắt cung sau',
}, {
  'id': 'M96.4',
  'english': 'Postsurgical lordosis',
  'vietnamese': 'Ưỡn cột sống thắt lưng sau phẫu thuật',
}, {'id': 'M96.5', 'english': 'Postradiation scoliosis', 'vietnamese': 'Vẹo sau chiếu tia xạ'}, {
  'id': 'M96.6',
  'english': 'Fracture of bone following insertion of orthopaedic implant, joint prosthesis, or bone plate',
  'vietnamese': 'gẫy xương sau chỉnh hình thay thế, sau lắp khớp giả hay sau tạo xương nhân tạo',
}, {
  'id': 'M96.8',
  'english': 'Other postprocedural musculoskeletal disorders',
  'vietnamese': 'Các bệnh ký khác của hệ cơ-xương-khớp sau thủ thuật',
}, {
  'id': 'M96.9',
  'english': 'Postprocedural musculoskeletal disorder, unspecified',
  'vietnamese': 'Các bệnh lý của hệ cơ-xương-khớp sau can thiệp không đặc hiệu',
}, {
  'id': 'M99',
  'english': 'Biomechanical lesions, not elsewhere classified',
  'vietnamese': 'Các tổn thương sinh-cơ học, không xếp loại ở mục khác',
}, {
  'id': 'M99.0',
  'english': 'Segmental and somatic dysfunction',
  'vietnamese': 'Rối loạn chức năng từng đoạn và phần thân',
}, {
  'id': 'M99.1',
  'english': 'Subluxation complex (vertebral)',
  'vietnamese': 'Bán trật khớp từng khối của cột sống',
}, {
  'id': 'M99.2',
  'english': 'Subluxation stenosis of neural canal',
  'vietnamese': 'Hẹp ống sống do bán trật khớp',
}, {
  'id': 'M99.3',
  'english': 'Osseous stenosis of neural canal',
  'vietnamese': 'Hẹp ống sống do tổn thương xương',
}, {
  'id': 'M99.4',
  'english': 'Connective tissue stenosis of neural canal',
  'vietnamese': 'Hẹp ống sống do tổn thương mô liên kết',
}, {
  'id': 'M99.5',
  'english': 'Intervertebral disc stenosis of neural canal',
  'vietnamese': 'Hẹp ống sống do tổn thương đĩa gian đốt',
}, {
  'id': 'M99.6',
  'english': 'Osseous and subluxation stenosis of intervertebral foramina',
  'vietnamese': 'Hẹp khoang liên đốt do cốt hóa và bán trật khớp',
}, {
  'id': 'M99.7',
  'english': 'Connective tissue and disc stenosis of intervertebral foramina',
  'vietnamese': 'Hẹp khoang liên đốt do mô liên kết và đĩa đệm',
}, {
  'id': 'M99.8',
  'english': 'Other biomechanical lesions',
  'vietnamese': 'Các tổn thương sinh-cơ học khác',
}, {
  'id': 'M99.9',
  'english': 'Biomechanical lesion, unspecified',
  'vietnamese': 'Tổn thương sinh-cơ học không đặc hiệu',
}, {
  'id': 'N00',
  'english': 'Acute nephritic syndrome',
  'vietnamese': 'Hội chứng viêm cầu thận cấp',
}, {
  'id': 'N01',
  'english': 'Rapidly progressive nephritic syndrome',
  'vietnamese': 'Hội chứng viêm cầu thận tiến triển nhanh',
}, {
  'id': 'N02',
  'english': 'Recurrent and persistent haematuria',
  'vietnamese': 'Đái máu dai dẳng và tái phát',
}, {
  'id': 'N03',
  'english': 'Chronic nephritic syndrome',
  'vietnamese': 'Hội chứng viêm cầu thận mạn',
}, {'id': 'N04', 'english': 'Nephrotic syndrome', 'vietnamese': 'Hội chứng thận hư'}, {
  'id': 'N05',
  'english': 'Unspecified nephritic syndrome',
  'vietnamese': 'Hội chứng viêm cầu thận không đặc hiệu',
}, {
  'id': 'N06',
  'english': 'Isolated proteinuria with specified morphological lesion',
  'vietnamese': 'Protein niệu đơn độc với tổn thương hình thái đặc hiệu',
}, {
  'id': 'N07',
  'english': 'Hereditary nephropathy, not elsewhere classified',
  'vietnamese': 'Bệnh thận di truyền, chưa phân loại ở nơi khác',
}, {
  'id': 'N08*',
  'english': 'Glomerular disorders in diseases classified elsewhere',
  'vietnamese': 'Biến đổi cầu thận trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N08.0*',
  'english': 'Glomerular disorders in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Biến đổi cầu thận trong bệnh nhiễm khuẩn và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'N08.1*',
  'english': 'Glomerular disorders in neoplastic diseases',
  'vietnamese': 'Biến đổi cầu thận trong các bệnh tân sinh',
}, {
  'id': 'N08.2*',
  'english': 'Glomerular disorders in blood diseases and disorders involving the immune mechanism',
  'vietnamese': 'Biến đổi cầu thận trong bệnh máu và các rối loạn liên quan cơ chế miễn dịch',
}, {
  'id': 'N08.3*',
  'english': 'Glomerular disorders in diabetes mellitusE10-E14 with common fourth character .2',
  'vietnamese': 'Biến đổi cầu thận trong đái tháo đường E10-E14 with common fourth character.2',
}, {
  'id': 'N08.4*',
  'english': 'Glomerular disorders in other endocrine, nutritional and metabolic diseases',
  'vietnamese': 'Biến đổi cầu thận trong các bệnh nội tiết, dinh dưỡng và chuyển hóa khác',
}, {
  'id': 'N08.5*',
  'english': 'Glomerular disorders in systemic connective tissue disorders',
  'vietnamese': 'Biến đổi cầu thận trong các bệnh mô liên kết hệ thống',
}, {
  'id': 'N08.8*',
  'english': 'Glomerular disorders in other diseases classified elsewhere',
  'vietnamese': 'Biến đổi cầu thận trong các bệnh khác đã phân loại nơi khác',
}, {
  'id': 'N10',
  'english': 'Acute tubulo-interstitial nephritis',
  'vietnamese': 'Viêm mô kẽ ống thận cấp',
}, {
  'id': 'N11',
  'english': 'Chronic tubulo-interstitial nephritis',
  'vietnamese': 'Viêm mô kẽ ống thận mạn',
}, {
  'id': 'N11.0',
  'english': 'Nonobstructive reflux-associated chronic pyelonephritis',
  'vietnamese': 'Viêm thận bể thận mạn do trào ngược không tắc nghẽn',
}, {
  'id': 'N11.1',
  'english': 'Chronic obstructive pyelonephritis',
  'vietnamese': 'Viêm thận bể thận mạn do tắc nghẽn',
}, {
  'id': 'N11.8',
  'english': 'Other chronic tubulo-interstitial nephritis',
  'vietnamese': 'Viêm mô kẽ ống thận mạn khác',
}, {
  'id': 'N11.9',
  'english': 'Chronic tubulo-interstitial nephritis, unspecified',
  'vietnamese': 'Viêm mô kẽ ống thận mạn, không đặc hiệu',
}, {
  'id': 'N12',
  'english': 'Tubulo-interstitial nephritis, not specified as acute or chronic',
  'vietnamese': 'Viêm mô kẽ ống thận, không xác định cấp hay mạn',
}, {
  'id': 'N13',
  'english': 'Obstructive and reflux uropathy',
  'vietnamese': 'Bệnh tiết niệu trào ngược và tắc nghẽn',
}, {
  'id': 'N13.0',
  'english': 'Hydronephrosis with ureteropelvic junction obstruction',
  'vietnamese': 'Thận ứ nước do chít hẹp khúc nối bể thận niệu quản',
}, {
  'id': 'N13.1',
  'english': 'Hydronephrosis with ureteral stricture, not elsewhere classified',
  'vietnamese': 'Thận ứ nước do chít hẹp khúc nối bể thận niệu quản chưa được phân loại ở phần khác',
}, {
  'id': 'N13.2',
  'english': 'Hydronephrosis with renal and ureteral calculous obstruction',
  'vietnamese': 'Thận ứ nước do sỏi thận và niệu quản gây tắc nghẽn',
}, {
  'id': 'N13.3',
  'english': 'Other and unspecified hydronephrosis',
  'vietnamese': 'Thận ứ nước khác và không đặc hiệu',
}, {'id': 'N13.4', 'english': 'Hydroureter', 'vietnamese': 'Niệu quản ứ nước'}, {
  'id': 'N13.5',
  'english': 'Kinking and stricture of ureter without hydronephrosis',
  'vietnamese': 'Niệu quản gấp khúc và chít hẹp không gây ứ nước thận',
}, {'id': 'N13.6', 'english': 'Pyonephrosis', 'vietnamese': 'Thận ứ mủ'}, {
  'id': 'N13.7',
  'english': 'Vesicoureteral-reflux-associated uropathy',
  'vietnamese': 'Bệnh tiết niệu kèm theo trào ngược bàng quang niệu quản',
}, {
  'id': 'N13.8',
  'english': 'Other obstructive and reflux uropathy',
  'vietnamese': 'Bệnh tiết niệu trào ngược và tắc nghẽn khác',
}, {
  'id': 'N13.9',
  'english': 'Obstructive and reflux uropathy, unspecified',
  'vietnamese': 'Bệnh tiết niệu trào ngược và tắc nghẽn, không đặc hiệu',
}, {
  'id': 'N14',
  'english': 'Drug- and heavy-metal-induced tubulo-interstitial and tubular conditions',
  'vietnamese': 'Bệnh ống thận và kẽ ống thận do thuốc và kim loại nặng',
}, {
  'id': 'N14.0',
  'english': 'Analgesic nephropathy',
  'vietnamese': 'Bệnh thận do thuốc giảm đau',
}, {
  'id': 'N14.1',
  'english': 'Nephropathy induced by other drugs, medicaments and biological substances',
  'vietnamese': 'Bệnh thận do thuốc, chất sinh học và cách chữa trị khác',
}, {
  'id': 'N14.2',
  'english': 'Nephropathy induced by unspecified drug, medicament or biological substance',
  'vietnamese': 'Bệnh thận do thuốc, chất sinh học và cách chữa trị không xác định',
}, {
  'id': 'N14.3',
  'english': 'Nephropathy induced by heavy metals',
  'vietnamese': 'Bệnh thận do kim loại nặng',
}, {
  'id': 'N14.4',
  'english': 'Toxic nephropathy, not elsewhere classified',
  'vietnamese': 'Bệnh thận nhiễm độc, không phân loại nơi khác',
}, {
  'id': 'N15',
  'english': 'Other renal tubulo-interstitial diseases',
  'vietnamese': 'Các bệnh kẽ ống thận khác',
}, {'id': 'N15.0', 'english': 'Balkan nephropathy', 'vietnamese': 'Bệnh thận vùng Balkan'}, {
  'id': 'N15.1',
  'english': 'Renal and perinephric abscess',
  'vietnamese': 'Áp xe thận và quanh thận',
}, {
  'id': 'N15.8',
  'english': 'Other specified renal tubulo-interstitial diseases',
  'vietnamese': 'Bệnh kẽ ống thận xác định khác',
}, {
  'id': 'N15.9',
  'english': 'Renal tubulo-interstitial disease, unspecified',
  'vietnamese': 'Bệnh kẽ ống thận không đặc hiệu',
}, {
  'id': 'N16*',
  'english': 'Renal tubulo-interstitial disorders in diseases classified elsewhere',
  'vietnamese': 'Biến đổi kẽ ống thận trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N16.0*',
  'english': 'Renal tubulo-interstitial disorders in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Biến đổi kẽ ống thận trong bệnh nhiễm khuẩn và ký sinh trùng đã được phân loại ở phần khác',
}, {
  'id': 'N16.1*',
  'english': 'Renal tubulo-interstitial disorders in neoplastic diseases',
  'vietnamese': 'Biến đổi kẽ ống thận trong bệnh tân sinh',
}, {
  'id': 'N16.2*',
  'english': 'Renal tubulo-interstitial disorders in blood diseases and disorders involving the immune mechanism',
  'vietnamese': 'Biến đổi kẽ ống thận trong các bệnh máu và các rối loạn liên quan đến cơ chế miễn dịch',
}, {
  'id': 'N16.3*',
  'english': 'Renal tubulo-interstitial disorders in metabolic diseases',
  'vietnamese': 'Biến đổi kẽ ống thận trong các bệnh chuyển hóa',
}, {
  'id': 'N16.4*',
  'english': 'Renal tubulo-interstitial disorders in systemic connective tissue disorders',
  'vietnamese': 'Biến đổi kẽ ống thận trong các rối loạn mô liên kết hệ thống',
}, {
  'id': 'N16.5*',
  'english': 'Renal tubulo-interstitial disorders in transplant rejectionT86.-',
  'vietnamese': 'Biến đổi kẽ ống thận trong thải ghép T86.-',
}, {
  'id': 'N16.8*',
  'english': 'Renal tubulo-interstitial disorders in other diseases classified elsewhere',
  'vietnamese': 'Biến đổi kẽ ống thận trong các bệnh khác phân loại nơi khác',
}, {'id': 'N17', 'english': 'Acute renal failure', 'vietnamese': 'Suy thận cấp'}, {
  'id': 'N17.0',
  'english': 'Acute renal failure with tubular necrosis',
  'vietnamese': 'Suy thận cấp có hoại tử ống thận',
}, {
  'id': 'N17.1',
  'english': 'Acute renal failure with acute cortical necrosis',
  'vietnamese': 'Suy thận cấp có hoại tử cấp vỏ thận',
}, {
  'id': 'N17.2',
  'english': 'Acute renal failure with medullary necrosis',
  'vietnamese': 'Suy thận cấp có hoại tử tủy thận',
}, {'id': 'N17.8', 'english': 'Other acute renal failure', 'vietnamese': 'Suy thận cấp khác'}, {
  'id': 'N17.9',
  'english': 'Acute renal failure, unspecified',
  'vietnamese': 'Suy thận cấp không đặc hiệu',
}, {'id': 'N18', 'english': 'Chronic renal failure', 'vietnamese': 'Suy thận mạn'}, {
  'id': 'N18.0',
  'english': 'End-stage renal disease',
  'vietnamese': 'Bệnh thận giai đoạn cuối',
}, {
  'id': 'N18.1',
  'english': 'Chronic kidney disease, stage 1',
  'vietnamese': 'Bệnh thận mạn, giai đoạn 1',
}, {
  'id': 'N18.2',
  'english': 'Chronic kidney disease, stage 2',
  'vietnamese': 'Bệnh thận mạn, giai đoạn 2',
}, {
  'id': 'N18.3',
  'english': 'Chronic kidney disease, stage 3',
  'vietnamese': 'Bệnh thận mạn, giai đoạn 3',
}, {
  'id': 'N18.4',
  'english': 'Chronic kidney disease, stage 4',
  'vietnamese': 'Bệnh thận mạn, giai đoạn 4',
}, {
  'id': 'N18.5',
  'english': 'Chronic kidney disease, stage 5',
  'vietnamese': 'Bệnh thận mạn, giai đoạn 5',
}, {'id': 'N18.8', 'english': 'Other chronic renal failure', 'vietnamese': 'Suy thận mạn khác'}, {
  'id': 'N18.9',
  'english': 'Chronic renal failure, unspecified',
  'vietnamese': 'Suy thận mạn, không đặc hiệu',
}, {
  'id': 'N19',
  'english': 'Unspecified kidney failure',
  'vietnamese': 'Suy thận không đặc hiệu',
}, {
  'id': 'N20',
  'english': 'Calculus of kidney and ureter',
  'vietnamese': 'Sỏi thận và niệu quản',
}, {'id': 'N20.0', 'english': 'Calculus of kidney', 'vietnamese': 'Sỏi thận'}, {
  'id': 'N20.1',
  'english': 'Calculus of ureter',
  'vietnamese': 'Sỏi niệu quản',
}, {
  'id': 'N20.2',
  'english': 'Calculus of kidney with calculus of ureter',
  'vietnamese': 'Sỏi thận và sỏi niệu quản',
}, {
  'id': 'N20.9',
  'english': 'Urinary calculus, unspecified',
  'vietnamese': 'Sỏi tiết niệu, không đặc hiệu',
}, {
  'id': 'N21',
  'english': 'Calculus of lower urinary tract',
  'vietnamese': 'Sỏi đường tiết niệu dưới',
}, {'id': 'N21.0', 'english': 'Calculus in bladder', 'vietnamese': 'Sỏi bàng quang'}, {
  'id': 'N21.1',
  'english': 'Calculus in urethra',
  'vietnamese': 'Sỏi niệu đạo',
}, {
  'id': 'N21.8',
  'english': 'Other lower urinary tract calculus',
  'vietnamese': 'Sỏi đường tiết niệu dưới khác',
}, {
  'id': 'N21.9',
  'english': 'Calculus of lower urinary tract, unspecified',
  'vietnamese': 'Sỏi đường tiết niệu dưới, không đặc hiệu',
}, {
  'id': 'N22*',
  'english': 'Calculus of urinary tract in diseases classified elsewhere',
  'vietnamese': 'Sỏi đường tiết niệu trong các bệnh đã phân loại nơi khác',
}, {
  'id': 'N22.0*',
  'english': 'Urinary calculus in schistosomiasis [bilharziasis]B65.-',
  'vietnamese': 'Sỏi tiết niệu trong bệnh sán máng B65.-',
}, {
  'id': 'N22.8*',
  'english': 'Calculus of urinary tract in other diseases classified elsewhere',
  'vietnamese': 'Sỏi đường tiết niệu trong các bệnh khác đã phân loại nơi khác',
}, {
  'id': 'N23',
  'english': 'Unspecified renal colic',
  'vietnamese': 'Cơn đau quặn thận không đặc hiệu',
}, {
  'id': 'N25',
  'english': 'Disorders resulting from impaired renal tubular function',
  'vietnamese': 'Rối loạn do suy giảm chức năng ống thận',
}, {'id': 'N25.0', 'english': 'Renal osteodystrophy', 'vietnamese': 'Loạn dưỡng xương do thận'}, {
  'id': 'N25.1',
  'english': 'Nephrogenic diabetes insipidus',
  'vietnamese': 'Đái tháo nhạt do thận',
}, {
  'id': 'N25.8',
  'english': 'Other disorders resulting from impaired renal tubular function',
  'vietnamese': 'Các rối loạn khác do suy giảm chức năng ống thận',
}, {
  'id': 'N25.9',
  'english': 'Disorder resulting from impaired renal tubular function, unspecified',
  'vietnamese': 'Rối loạn do suy giảm chức năng ống thận, không đặc hiệu',
}, {
  'id': 'N26',
  'english': 'Unspecified contracted kidney',
  'vietnamese': 'Thận nhỏ không đặc hiệu',
}, {
  'id': 'N27',
  'english': 'Small kidney of unknown cause',
  'vietnamese': 'Thận teo nhỏ không rõ nguyên nhân',
}, {
  'id': 'N27.0',
  'english': 'Small kidney, unilateral',
  'vietnamese': 'Thận teo nhỏ, một bên',
}, {
  'id': 'N27.1',
  'english': 'Small kidney, bilateral',
  'vietnamese': 'Thận teo nhỏ, hai bên',
}, {
  'id': 'N27.9',
  'english': 'Small kidney, unspecified',
  'vietnamese': 'Thận teo nhỏ, không đặc hiệu',
}, {
  'id': 'N28',
  'english': 'Other disorders of kidney and ureter, not elsewhere classified',
  'vietnamese': 'Các biến đổi khác của thận và niệu quản, chưa được phân loại',
}, {
  'id': 'N28.0',
  'english': 'Ischaemia and infarction of kidney',
  'vietnamese': 'Thiếu máu và nhồi máu thận',
}, {'id': 'N28.1', 'english': 'Cyst of kidney, acquired', 'vietnamese': 'Nang thận mắc phải'}, {
  'id': 'N28.8',
  'english': 'Other specified disorders of kidney and ureter',
  'vietnamese': 'Các biến đổi xác định khác của thận và niệu quản',
}, {
  'id': 'N28.9',
  'english': 'Disorder of kidney and ureter, unspecified',
  'vietnamese': 'Biến đổi của thận và niệu quản, không đặc hiệu',
}, {
  'id': 'N29*',
  'english': 'Other disorders of kidney and ureter in diseases classified elsewhere',
  'vietnamese': 'Các biến đổi khác của thận và niệu quản trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N29.0*',
  'english': 'Late syphilis of kidney (A52.7†)',
  'vietnamese': 'Giang mai muộn ở thận (A52.7†)',
}, {
  'id': 'N29.1*',
  'english': 'Other disorders of kidney and ureter in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Các biến đổi khác của thận và niệu quản trong các bệnh nhiễm khuẩn và ký sinh trùng đã phân loại nơi khác',
}, {
  'id': 'N29.8*',
  'english': 'Other disorders of kidney and ureter in other diseases classified elsewhere',
  'vietnamese': 'Các biến đổi khác của thận và niệu quản trong các bệnh khác phân loại nơi khác',
}, {'id': 'N30', 'english': 'Cystitis', 'vietnamese': 'Viêm bàng quang'}, {
  'id': 'N30.0',
  'english': 'Acute cystitis',
  'vietnamese': 'Viêm bàng quang cấp',
}, {
  'id': 'N30.1',
  'english': 'Interstitial cystitis (chronic)',
  'vietnamese': 'Viêm bàng quang kẽ (mạn)',
}, {
  'id': 'N30.2',
  'english': 'Other chronic cystitis',
  'vietnamese': 'Viêm bàng quang mạn khác',
}, {
  'id': 'N30.3',
  'english': 'Trigonitis',
  'vietnamese': 'Viêm tam giác bàng quang',
}, {
  'id': 'N30.4',
  'english': 'Irradiation cystitis',
  'vietnamese': 'Viêm bàng quang do tia xạ',
}, {
  'id': 'N30.8',
  'english': 'Other cystitis',
  'vietnamese': 'Viêm bàng quang khác',
}, {
  'id': 'N30.9',
  'english': 'Cystitis, unspecified',
  'vietnamese': 'Viêm bàng quang, không đặc hiệu',
}, {
  'id': 'N31',
  'english': 'Neuromuscular dysfunction of bladder, not elsewhere classified',
  'vietnamese': 'Rối loạn chức năng thần kinh cơ bàng quang, không phân loại nơi khác',
}, {
  'id': 'N31.0',
  'english': 'Uninhibited neuropathic bladder, not elsewhere classified',
  'vietnamese': 'Bàng quang thần kinh không được ức chế, không phân loại nơi khác',
}, {
  'id': 'N31.1',
  'english': 'Reflex neuropathic bladder, not elsewhere classified',
  'vietnamese': 'Bàng quang thần kinh phản xạ, không phân loại nơi khác',
}, {
  'id': 'N31.2',
  'english': 'Flaccid neuropathic bladder, not elsewhere classified',
  'vietnamese': 'Bàng quang thần kinh mất trương lực, không phân loại nơi khác',
}, {
  'id': 'N31.8',
  'english': 'Other neuromuscular dysfunction of bladder',
  'vietnamese': 'Rối loạn chức năng thần kinh cơ bàng quang khác',
}, {
  'id': 'N31.9',
  'english': 'Neuromuscular dysfunction of bladder, unspecified',
  'vietnamese': 'Rối loạn chức năng thần kinh cơ bàng quang, không đặc hiệu',
}, {
  'id': 'N32',
  'english': 'Other disorders of bladder',
  'vietnamese': 'Các rối loạn khác của bàng quang',
}, {
  'id': 'N32.0',
  'english': 'Bladder-neck obstruction',
  'vietnamese': 'Chít hẹp cổ bàng quang',
}, {
  'id': 'N32.1',
  'english': 'Vesicointestinal fistula',
  'vietnamese': 'Rò bàng quang ruột',
}, {
  'id': 'N32.2',
  'english': 'Vesical fistula, not elsewhere classified',
  'vietnamese': 'Rò bàng quang, không phân loại nơi khác',
}, {'id': 'N32.3', 'english': 'Diverticulum of bladder', 'vietnamese': 'Túi thừa bàng quang'}, {
  'id': 'N32.4',
  'english': 'Rupture of bladder, nontraumatic',
  'vietnamese': 'Vỡ bàng quang, không do chấn thương',
}, {
  'id': 'N32.8',
  'english': 'Other specified disorders of bladder',
  'vietnamese': 'Các rối loạn xác định khác của bàng quang',
}, {
  'id': 'N32.9',
  'english': 'Bladder disorder, unspecified',
  'vietnamese': 'Rối loạn bàng quang, không đặc hiệu',
}, {
  'id': 'N33',
  'english': 'Bladder disorders in diseases classified elsewhere',
  'vietnamese': 'Rối loạn bàng quang trong bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N33.0',
  'english': 'Tuberculous cystitis (A18.1†)',
  'vietnamese': 'Lao bàng quang  (A18.1†)',
}, {
  'id': 'N33.8',
  'english': 'Bladder disorders in other diseases classified elsewhere',
  'vietnamese': 'Rối loạn bàng quang trong các bệnh khác phân loại nơi khác',
}, {
  'id': 'N34',
  'english': 'Urethritis and urethral syndrome',
  'vietnamese': 'Viêm niệu đạo và hội chứng niệu đạo',
}, {'id': 'N34.0', 'english': 'Urethral abscess', 'vietnamese': 'Áp xe niệu đạo'}, {
  'id': 'N34.1',
  'english': 'Nonspecific urethritis',
  'vietnamese': 'Viêm niệu đạo không đặc hiệu',
}, {'id': 'N34.2', 'english': 'Other urethritis', 'vietnamese': 'Viêm niệu đạo khác'}, {
  'id': 'N34.3',
  'english': 'Urethral syndrome, unspecified',
  'vietnamese': 'Hội chứng niệu đạo, không đặc hiệu',
}, {'id': 'N35', 'english': 'Urethral stricture', 'vietnamese': 'Hẹp niệu đạo'}, {
  'id': 'N35.0',
  'english': 'Post-traumatic urethral stricture',
  'vietnamese': 'Hẹp niệu đạo sau chấn thương',
}, {
  'id': 'N35.1',
  'english': 'Postinfective urethral stricture, not elsewhere classified',
  'vietnamese': 'Hẹp niệu đạo sau nhiễm khuẩn, không phân loại nơi khác',
}, {'id': 'N35.8', 'english': 'Other urethral stricture', 'vietnamese': 'Hẹp niệu đạo khác'}, {
  'id': 'N35.9',
  'english': 'Urethral stricture, unspecified',
  'vietnamese': 'Hẹp niệu đạo, không đặc hiệu',
}, {
  'id': 'N36',
  'english': 'Other disorders of urethra',
  'vietnamese': 'Các biến đổi khác của niệu đạo',
}, {'id': 'N36.0', 'english': 'Urethral fistula', 'vietnamese': 'Rò niệu đạo'}, {
  'id': 'N36.1',
  'english': 'Urethral diverticulum',
  'vietnamese': 'Túi thừa niệu đạo',
}, {'id': 'N36.2', 'english': 'Urethral caruncle', 'vietnamese': 'Núm niệu đạo'}, {
  'id': 'N36.3',
  'english': 'Prolapsed urethral mucosa',
  'vietnamese': 'Sa niêm mạc niệu đạo',
}, {
  'id': 'N36.8',
  'english': 'Other specified disorders of urethra',
  'vietnamese': 'Các biến đổi niệu đạo xác định khác',
}, {
  'id': 'N36.9',
  'english': 'Urethral disorder, unspecified',
  'vietnamese': 'Biến đổi niệu đạo không đặc hiệu',
}, {
  'id': 'N37*',
  'english': 'Urethral disorders in diseases classified elsewhere',
  'vietnamese': 'Biến đổi niệu đạo trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N37.0*',
  'english': 'Urethritis in diseases classified elsewhere',
  'vietnamese': 'Viêm niệu đạo trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N37.8*',
  'english': 'Other urethral disorders in diseases classified elsewhere',
  'vietnamese': 'Biến đổi niệu đạo khác trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N39',
  'english': 'Other disorders of urinary system',
  'vietnamese': 'Biến đổi khác của hệ tiết niệu',
}, {
  'id': 'N39.0',
  'english': 'Urinary tract infection, site not specified',
  'vietnamese': 'Nhiễm khuẩn hệ tiết niệu, vị trí không đặc hiệu',
}, {
  'id': 'N39.1',
  'english': 'Persistent proteinuria, unspecified',
  'vietnamese': 'Protein niệu [Tiểu đạm] dai dẳng, không đặc hiệu',
}, {
  'id': 'N39.2',
  'english': 'Orthostatic proteinuria, unspecified',
  'vietnamese': 'Protein niệu [Tiểu đạm] tư thế, không đặc hiệu',
}, {
  'id': 'N39.3',
  'english': 'Stress incontinence',
  'vietnamese': 'Tiểu không kiểm soát do Stress',
}, {
  'id': 'N39.4',
  'english': 'Other specified urinary incontinence',
  'vietnamese': 'Tiểu không kiểm soát xác định khác',
}, {
  'id': 'N39.8',
  'english': 'Other specified disorders of urinary system',
  'vietnamese': 'Biến đổi xác định khác của hệ tiết niệu',
}, {
  'id': 'N39.9',
  'english': 'Disorder of urinary system, unspecified',
  'vietnamese': 'Biến đổi của hệ tiết niệu, không đặc hiệu',
}, {
  'id': 'N40',
  'english': 'Hyperplasia of prostate',
  'vietnamese': 'Tăng sản xuất tuyến tiền liệt',
}, {
  'id': 'N41',
  'english': 'Inflammatory diseases of prostate',
  'vietnamese': 'Các bệnh viêm tuyến tiền liệt',
}, {'id': 'N41.0', 'english': 'Acute prostatitis', 'vietnamese': 'Viêm tuyến tiền liệt cấp'}, {
  'id': 'N41.1',
  'english': 'Chronic prostatitis',
  'vietnamese': 'Viêm tuyến tiền liệt mạn',
}, {'id': 'N41.2', 'english': 'Abscess of prostate', 'vietnamese': 'Áp xe tuyến tiền liệt'}, {
  'id': 'N41.3',
  'english': 'Prostatocystitis',
  'vietnamese': 'Viêm tuyến tiền liệt - bàng quang',
}, {
  'id': 'N41.8',
  'english': 'Other inflammatory diseases of prostate',
  'vietnamese': 'Bệnh viêm khác của tuyến tiền liệt',
}, {
  'id': 'N41.9',
  'english': 'Inflammatory disease of prostate, unspecified',
  'vietnamese': 'Bệnh viêm tuyến tiền liệt, không đặc hiệu',
}, {
  'id': 'N42',
  'english': 'Other disorders of prostate',
  'vietnamese': 'Biến đổi khác của tuyến tiền liệt',
}, {'id': 'N42.0', 'english': 'Calculus of prostate', 'vietnamese': 'Sỏi tuyến tiền liệt'}, {
  'id': 'N42.1',
  'english': 'Congestion and haemorrhage of prostate',
  'vietnamese': 'Sung huyết và chảy máu tuyến tiền liệt',
}, {'id': 'N42.2', 'english': 'Atrophy of prostate', 'vietnamese': 'Teo tuyến tiền liệt'}, {
  'id': 'N42.3',
  'english': 'Dysplasia of prostate',
  'vietnamese': 'Loạn sản tuyến tiền liệt',
}, {
  'id': 'N42.8',
  'english': 'Other specified disorders of prostate',
  'vietnamese': 'Biến đổi xác định khác của tuyến tiền liệt',
}, {
  'id': 'N42.9',
  'english': 'Disorder of prostate, unspecified',
  'vietnamese': 'Biến đổi của tuyến tiền liệt, không đặc hiệu',
}, {
  'id': 'N43',
  'english': 'Hydrocele and spermatocele',
  'vietnamese': 'Tràn dịch màng tinh hoàn và nang mào tinh hoàn',
}, {
  'id': 'N43.0',
  'english': 'Encysted hydrocele',
  'vietnamese': 'Tràn dịch màng tinh hoàn nang hóa',
}, {
  'id': 'N43.1',
  'english': 'Infected hydrocele',
  'vietnamese': 'Tràn dịch màng tinh hoàn nhiễm khuẩn',
}, {'id': 'N43.2', 'english': 'Other hydrocele', 'vietnamese': 'Tràn dịch màng tinh hoàn khác'}, {
  'id': 'N43.3',
  'english': 'Hydrocele, unspecified',
  'vietnamese': 'Tràn dịch màng tinh hoàn, không đặc hiệu',
}, {'id': 'N43.4', 'english': 'Spermatocele', 'vietnamese': 'Nang mào tinh hoàn'}, {
  'id': 'N44',
  'english': 'Torsion of testis',
  'vietnamese': 'Xoắn tinh hoàn',
}, {
  'id': 'N45',
  'english': 'Orchitis and epididymitis',
  'vietnamese': 'Viêm tinh hoàn và viêm mào tinh hoàn',
}, {
  'id': 'N45.0',
  'english': 'Orchitis, epididymitis and epididymo-orchitis with abscess',
  'vietnamese': 'Viêm tinh hoàn, viêm mào tinh hoàn và viêm tinh hoàn- mào tinh hoàn có áp xe',
}, {
  'id': 'N45.9',
  'english': 'Orchitis, epididymitis and epididymo-orchitis without abscess',
  'vietnamese': 'Viêm tinh hoàn, mào tinh hoàn và viêm tinh hoàn- mào tinh hoàn không có áp xe',
}, {'id': 'N46', 'english': 'Male infertility', 'vietnamese': 'Vô sinh nam'}, {
  'id': 'N47',
  'english': 'Redundant prepuce, phimosis and paraphimosis',
  'vietnamese': 'Bao quy đầu dài, hẹp bao quy đầu và nghẹt bao quy đầu',
}, {
  'id': 'N48',
  'english': 'Other disorders of penis',
  'vietnamese': 'Biến đổi khác của dương vật',
}, {'id': 'N48.0', 'english': 'Leukoplakia of penis', 'vietnamese': 'Bạch sản dương vật'}, {
  'id': 'N48.1',
  'english': 'Balanoposthitis',
  'vietnamese': 'Viêm quy đầu - bao quy đầu',
}, {
  'id': 'N48.2',
  'english': 'Other inflammatory disorders of penis',
  'vietnamese': 'Biến đổi viêm khác của dương vật',
}, {'id': 'N48.3', 'english': 'Priapism', 'vietnamese': 'Cương đau dương vật kéo dài'}, {
  'id': 'N48.4',
  'english': 'Impotence of organic origin',
  'vietnamese': 'Bất lực do nguyên nhân thực thể',
}, {'id': 'N48.5', 'english': 'Ulcer of penis', 'vietnamese': 'Loét dương vật'}, {
  'id': 'N48.6',
  'english': 'Induratio penis plastica',
  'vietnamese': 'Xơ cứng dương vật',
}, {
  'id': 'N48.8',
  'english': 'Other specified disorders of penis',
  'vietnamese': 'Biến đổi xác định khác của dương vật',
}, {
  'id': 'N48.9',
  'english': 'Disorder of penis, unspecified',
  'vietnamese': 'Biến đổi của dương vật, không đặc hiệu',
}, {
  'id': 'N49',
  'english': 'Inflammatory disorders of male genital organs, not elsewhere classified',
  'vietnamese': 'Viêm của cơ quan sinh dục nam, không phân loại nơi khác',
}, {
  'id': 'N49.0',
  'english': 'Inflammatory disorders of seminal vesicle',
  'vietnamese': 'Viêm túi tinh',
}, {
  'id': 'N49.1',
  'english': 'Inflammatory disorders of spermatic cord, tunica vaginalis and vas deferens',
  'vietnamese': 'Viêm thừng tinh, màng tinh và ống tinh',
}, {'id': 'N49.2', 'english': 'Inflammatory disorders of scrotum', 'vietnamese': 'Viêm bìu'}, {
  'id': 'N49.8',
  'english': 'Inflammatory disorders of other specified male genital organs',
  'vietnamese': 'Viêm của cơ quan sinh dục nam khác',
}, {
  'id': 'N49.9',
  'english': 'Inflammatory disorder of unspecified male genital organ',
  'vietnamese': 'Viêm cơ quan sinh dục nam không đặc hiệu',
}, {
  'id': 'N50',
  'english': 'Other disorders of male genital organs',
  'vietnamese': 'Biến đổi khác của cơ quan sinh dục nam',
}, {'id': 'N50.0', 'english': 'Atrophy of testis', 'vietnamese': 'Teo tinh hoàn'}, {
  'id': 'N50.1',
  'english': 'Vascular disorders of male genital organs',
  'vietnamese': 'Rối loạn mạch máu của cơ quan sinh dục nam',
}, {
  'id': 'N50.8',
  'english': 'Other specified disorders of male genital organs',
  'vietnamese': 'Biến đổi khác của cơ quan sinh dục nam',
}, {
  'id': 'N50.9',
  'english': 'Disorder of male genital organs, unspecified',
  'vietnamese': 'Biến đổi của cơ quan sinh dục nam, không đặc hiệu',
}, {
  'id': 'N51*',
  'english': 'Disorders of male genital organs in diseases classified elsewhere',
  'vietnamese': 'Biến đổi cơ quan sinh dục nam trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N51.0*',
  'english': 'Disorders of prostate in diseases classified elsewhere',
  'vietnamese': 'Biến đổi của tuyến tiền liệt trong bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N51.1*',
  'english': 'Disorders of testis and epididymis in diseases classified elsewhere',
  'vietnamese': 'Biến đổi của tinh hoàn và mào tinh hoàn trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N51.2*',
  'english': 'Balanitis in diseases classified elsewhere',
  'vietnamese': 'Viêm quy đầu trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N51.8*',
  'english': 'Other disorders of male genital organs in diseases classified elsewhere',
  'vietnamese': 'Biến đổi khác của cơ quan sinh dục nam trong các bệnh đã được phân loại ở phần khác',
}, {'id': 'N60', 'english': 'Benign mammary dysplasia', 'vietnamese': 'Loạn sản vú lành tính'}, {
  'id': 'N60.0',
  'english': 'Solitary cyst of breast',
  'vietnamese': 'Nang đơn vú',
}, {'id': 'N60.1', 'english': 'Diffuse cystic mastopathy', 'vietnamese': 'Nang lan toả vú'}, {
  'id': 'N60.2',
  'english': 'Fibroadenosis of breast',
  'vietnamese': 'U xơ tuyến vú',
}, {'id': 'N60.3', 'english': 'Fibrosclerosis of breast', 'vietnamese': 'Xơ teo tuyến vú'}, {
  'id': 'N60.4',
  'english': 'Mammary duct ectasia',
  'vietnamese': 'Giãn ống tuyến vú',
}, {
  'id': 'N60.8',
  'english': 'Other benign mammary dysplasias',
  'vietnamese': 'Loạn sản lành tính vú khác',
}, {
  'id': 'N60.9',
  'english': 'Benign mammary dysplasia, unspecified',
  'vietnamese': 'Loạn sản lành tính vú, không đặc hiệu',
}, {'id': 'N61', 'english': 'Inflammatory disorders of breast', 'vietnamese': 'Viêm vú'}, {
  'id': 'N62',
  'english': 'Hypertrophy of breast',
  'vietnamese': 'Vú phì đại',
}, {
  'id': 'N63',
  'english': 'Unspecified lump in breast',
  'vietnamese': 'Khối u không xác định ở vú',
}, {
  'id': 'N64',
  'english': 'Other disorders of breast',
  'vietnamese': 'Biến đổi khác ở vú',
}, {
  'id': 'N64.0',
  'english': 'Fissure and fistula of nipple',
  'vietnamese': 'Nứt và rò ở núm vú',
}, {
  'id': 'N64.1',
  'english': 'Fat necrosis of breast',
  'vietnamese': 'Hoại tử mỡ của vú',
}, {'id': 'N64.2', 'english': 'Atrophy of breast', 'vietnamese': 'Teo vú'}, {
  'id': 'N64.3',
  'english': 'Galactorrhoea not associated with childbirth',
  'vietnamese': 'Tiết sữa không liên quan đến sinh đẻ',
}, {'id': 'N64.4', 'english': 'Mastodynia', 'vietnamese': 'Đau vú'}, {
  'id': 'N64.5',
  'english': 'Other signs and symptoms in breast',
  'vietnamese': 'Dấu hiệu và triệu chứng khác ở vú',
}, {
  'id': 'N64.8',
  'english': 'Other specified disorders of breast',
  'vietnamese': 'Biến đổi xác định khác của vú',
}, {
  'id': 'N64.9',
  'english': 'Disorder of breast, unspecified',
  'vietnamese': 'Biến đổi của vú, không đặc hiệu',
}, {
  'id': 'N70',
  'english': 'Salpingitis and oophoritis',
  'vietnamese': 'Viêm vòi và viêm buồng trứng',
}, {
  'id': 'N70.0',
  'english': 'Acute salpingitis and oophoritis',
  'vietnamese': 'Viêm vòi và viêm buồng trứng cấp',
}, {
  'id': 'N70.1',
  'english': 'Chronic salpingitis and oophoritis',
  'vietnamese': 'Viêm vòi và viêm buồng trứng mạn',
}, {
  'id': 'N70.9',
  'english': 'Salpingitis and oophoritis, unspecified',
  'vietnamese': 'Viêm vòi và buồng trứng, không đặc hiệu',
}, {
  'id': 'N71',
  'english': 'Inflammatory disease of uterus, except cervix',
  'vietnamese': 'Viêm tử cung, trừ cổ tử cung',
}, {
  'id': 'N71.0',
  'english': 'Acute inflammatory disease of uterus',
  'vietnamese': 'Viêm tử cung cấp',
}, {
  'id': 'N71.1',
  'english': 'Chronic inflammatory disease of uterus',
  'vietnamese': 'Viêm tử cung mạn',
}, {
  'id': 'N71.9',
  'english': 'Inflammatory disease of uterus, unspecified',
  'vietnamese': 'Viêm tử cung, không đặc hiệu',
}, {
  'id': 'N72',
  'english': 'Inflammatory disease of cervix uteri',
  'vietnamese': 'Viêm cổ tử cung',
}, {
  'id': 'N73',
  'english': 'Other female pelvic inflammatory diseases',
  'vietnamese': 'Các bệnh viêm vùng chậu nữ khác',
}, {
  'id': 'N73.0',
  'english': 'Acute parametritis and pelvic cellulitis',
  'vietnamese': 'Viêm mô cận tử cung và viêm mô tế bào vùng chậu',
}, {
  'id': 'N73.1',
  'english': 'Chronic parametritis and pelvic cellulitis',
  'vietnamese': 'Viêm mô cận tử cung và viêm mô tế bào vùng chậu mạn',
}, {
  'id': 'N73.2',
  'english': 'Unspecified parametritis and pelvic cellulitis',
  'vietnamese': 'Viêm mô cận tử cung và viêm mô tế bào vùng chậu không đặc hiệu',
}, {
  'id': 'N73.3',
  'english': 'Female acute pelvic peritonitis',
  'vietnamese': 'Viêm phúc mạc vùng chậu nữ cấp',
}, {
  'id': 'N73.4',
  'english': 'Female chronic pelvic peritonitis',
  'vietnamese': 'Viêm phúc mạc vùng chậu nữ mạn',
}, {
  'id': 'N73.5',
  'english': 'Female pelvic peritonitis, unspecified',
  'vietnamese': 'Viêm phúc mạc vùng chậu nữ, không đặc hiệu',
}, {
  'id': 'N73.6',
  'english': 'Female pelvic peritoneal adhesions',
  'vietnamese': 'Dính phúc mạc vùng chậu nữ',
}, {
  'id': 'N73.8',
  'english': 'Other specified female pelvic inflammatory diseases',
  'vietnamese': 'Các bệnh viêm vùng chậu nữ xác định khác',
}, {
  'id': 'N73.9',
  'english': 'Female pelvic inflammatory disease, unspecified',
  'vietnamese': 'Viêm vùng chậu nữ, không đặc hiệu',
}, {
  'id': 'N74*',
  'english': 'Female pelvic inflammatory disorders in diseases classified elsewhere',
  'vietnamese': 'Viêm vùng chậu nữ trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N74.0*',
  'english': 'Tuberculous infection of cervix uteri (A18.1†)',
  'vietnamese': 'Lao cổ tử cung (A18.1†)',
}, {
  'id': 'N74.1*',
  'english': 'Female tuberculous pelvic inflammatory disease (A18.1†)',
  'vietnamese': 'Viêm lao vùng chậu nữ (A18.1†)',
}, {
  'id': 'N74.2*',
  'english': 'Female syphilitic pelvic inflammatory disease (A51.4†, A52.7†)',
  'vietnamese': 'Viêm giang mai vùng chậu nữ (A51.4†, A52.7†)',
}, {
  'id': 'N74.3*',
  'english': 'Female gonococcal pelvic inflammatory disease (A54.2†)',
  'vietnamese': 'Viêm lậu vùng chậu nữ  (A54.2†)',
}, {
  'id': 'N74.4*',
  'english': 'Female chlamydial pelvic inflammatory disease (A56.1†)',
  'vietnamese': 'Viêm vùng chậu nữ do chlamydia (A56.1†)',
}, {
  'id': 'N74.8*',
  'english': 'Female pelvic inflammatory disorders in other diseases classified elsewhere',
  'vietnamese': 'Viêm vùng chậu nữ trong các bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N75',
  'english': 'Diseases of Bartholin s gland',
  'vietnamese': 'Bệnh của tuyến Bartholin',
}, {
  'id': 'N75.0',
  'english': 'Cyst of Bartholin s gland',
  'vietnamese': 'Nang tuyến Bartholin',
}, {
  'id': 'N75.1',
  'english': 'Abscess of Bartholin s gland',
  'vietnamese': 'Áp xe tuyến Bartholin',
}, {
  'id': 'N75.8',
  'english': 'Other diseases of Bartholin s gland',
  'vietnamese': 'Các bệnh khác của tuyến Bartholin',
}, {
  'id': 'N75.9',
  'english': 'Disease of Bartholin s gland, unspecified',
  'vietnamese': 'Bệnh tuyến Bartholin, không đặc hiệu',
}, {
  'id': 'N76',
  'english': 'Other inflammation of vagina and vulva',
  'vietnamese': 'Các viêm khác của âm đạo và âm hộ',
}, {'id': 'N76.0', 'english': 'Acute vaginitis', 'vietnamese': 'Viêm âm đạo cấp'}, {
  'id': 'N76.1',
  'english': 'Subacute and chronic vaginitis',
  'vietnamese': 'Viêm âm đạo bán cấp và mạn',
}, {'id': 'N76.2', 'english': 'Acute vulvitis', 'vietnamese': 'Viêm âm hộ cấp'}, {
  'id': 'N76.3',
  'english': 'Subacute and chronic vulvitis',
  'vietnamese': 'Viêm âm hộ bán cấp và mạn',
}, {'id': 'N76.4', 'english': 'Abscess of vulva', 'vietnamese': 'Áp xe âm hộ'}, {
  'id': 'N76.5',
  'english': 'Ulceration of vagina',
  'vietnamese': 'Loét âm đạo',
}, {'id': 'N76.6', 'english': 'Ulceration of vulva', 'vietnamese': 'Loét âm hộ'}, {
  'id': 'N76.8',
  'english': 'Other specified inflammation of vagina and vulva',
  'vietnamese': 'Viêm âm đạo và âm hộ xác định khác',
}, {
  'id': 'N77*',
  'english': 'Vulvovaginal ulceration and inflammation in diseases classified elsewhere',
  'vietnamese': 'Viêm và loét âm đạo, âm hộ trong bệnh đã được phân loại ở phần khác',
}, {
  'id': 'N77.0*',
  'english': 'Ulceration of vulva in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Loét âm hộ trong các bệnh nhiễm khuẩn và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'N77.1*',
  'english': 'Vaginitis, vulvitis and vulvovaginitis in infectious and parasitic diseases classified elsewhere',
  'vietnamese': 'Viêm âm đạo, viêm âm hộ và viêm âm đạo âm hộ trong các bệnh nhiễm khuẩn và ký sinh trùng phân loại nơi khác',
}, {
  'id': 'N77.8*',
  'english': 'Vulvovaginal ulceration and inflammation in other diseases classified elsewhere',
  'vietnamese': 'Viêm và loét âm đạo âm hộ trong các bệnh khác phân loại nơi khác',
}, {'id': 'N80', 'english': 'Endometriosis', 'vietnamese': 'Bệnh lạc nội mạc tử cung'}, {
  'id': 'N80.0',
  'english': 'Endometriosis of uterus',
  'vietnamese': 'Bệnh lạc nội mạc tử cung',
}, {
  'id': 'N80.1',
  'english': 'Endometriosis of ovary',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở buồng trứng',
}, {
  'id': 'N80.2',
  'english': 'Endometriosis of fallopian tube',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở vòi trứng',
}, {
  'id': 'N80.3',
  'english': 'Endometriosis of pelvic peritoneum',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở phúc mạc chậu',
}, {
  'id': 'N80.4',
  'english': 'Endometriosis of rectovaginal septum and vagina',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở vách trực tràng âm đạo và âm đạo',
}, {
  'id': 'N80.5',
  'english': 'Endometriosis of intestine',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở ruột',
}, {
  'id': 'N80.6',
  'english': 'Endometriosis in cutaneous scar',
  'vietnamese': 'Bệnh lạc nội mạc tử cung ở sẹo da',
}, {
  'id': 'N80.8',
  'english': 'Other endometriosis',
  'vietnamese': 'Bệnh lạc nội mạc tử cung khác',
}, {
  'id': 'N80.9',
  'english': 'Endometriosis, unspecified',
  'vietnamese': 'Bệnh lạc nội mạc tử cung, không đặc hiệu',
}, {'id': 'N81', 'english': 'Female genital prolapse', 'vietnamese': 'Sa sinh dục nữ'}, {
  'id': 'N81.0',
  'english': 'Female urethrocele',
  'vietnamese': 'Sa niệu đạo nữ',
}, {'id': 'N81.1', 'english': 'Cystocele', 'vietnamese': 'Sa bàng quang'}, {
  'id': 'N81.2',
  'english': 'Incomplete uterovaginal prolapse',
  'vietnamese': 'Sa tử cung âm đạo không hoàn toàn',
}, {
  'id': 'N81.3',
  'english': 'Complete uterovaginal prolapse',
  'vietnamese': 'Sa hoàn toàn tử cung âm đạo',
}, {
  'id': 'N81.4',
  'english': 'Uterovaginal prolapse, unspecified',
  'vietnamese': 'Sa tử cung âm đạo, không đặc hiệu',
}, {'id': 'N81.5', 'english': 'Vaginal enterocele', 'vietnamese': 'Thóat vị ruột trong âm đạo'}, {
  'id': 'N81.6',
  'english': 'Rectocele',
  'vietnamese': 'Sa trực tràng',
}, {
  'id': 'N81.8',
  'english': 'Other female genital prolapse',
  'vietnamese': 'Sa sinh dục nữ khác',
}, {
  'id': 'N81.9',
  'english': 'Female genital prolapse, unspecified',
  'vietnamese': 'Sa sinh dục nữ, không đặc hiệu',
}, {
  'id': 'N82',
  'english': 'Fistulae involving female genital tract',
  'vietnamese': 'Rò đường sinh dục nữ',
}, {'id': 'N82.0', 'english': 'Vesicovaginal fistula', 'vietnamese': 'Rò bàng quang âm đạo'}, {
  'id': 'N82.1',
  'english': 'Other female urinary-genital tract fistulae',
  'vietnamese': 'Các rò khác đường tiết niệu sinh dục nữ',
}, {
  'id': 'N82.2',
  'english': 'Fistula of vagina to small intestine',
  'vietnamese': 'Rò âm đạo vào ruột non',
}, {
  'id': 'N82.3',
  'english': 'Fistula of vagina to large intestine',
  'vietnamese': 'Rò âm đạo vào ruột già',
}, {
  'id': 'N82.4',
  'english': 'Other female intestinal-genital tract fistulae',
  'vietnamese': 'Các rò đường sinh dục nữ - ruột khác',
}, {
  'id': 'N82.5',
  'english': 'Female genital tract-skin fistulae',
  'vietnamese': 'Rò đường sinh dục nữ ra da',
}, {
  'id': 'N82.8',
  'english': 'Other female genital tract fistulae',
  'vietnamese': 'Các rò đường sinh dục nữ khác',
}, {
  'id': 'N82.9',
  'english': 'Female genital tract fistula, unspecified',
  'vietnamese': 'Rò đường sinh dục nữ, không đặc hiệu',
}, {
  'id': 'N83',
  'english': 'Noninflammatory disorders of ovary, fallopian tube and broad ligament',
  'vietnamese': 'Các biến đổi không do viêm của buồng trứng, vòi trứng và dây chằng rộng',
}, {'id': 'N83.0', 'english': 'Follicular cyst of ovary', 'vietnamese': 'U nang nang trứng'}, {
  'id': 'N83.1',
  'english': 'Corpus luteum cyst',
  'vietnamese': 'U nang hoàng thể',
}, {
  'id': 'N83.2',
  'english': 'Other and unspecified ovarian cysts',
  'vietnamese': 'Các u nang buồng trứng khác và không đặc hiệu',
}, {
  'id': 'N83.3',
  'english': 'Acquired atrophy of ovary and fallopian tube',
  'vietnamese': 'Teo buồng trứng và vòi trứng mắc phải',
}, {
  'id': 'N83.4',
  'english': 'Prolapse and hernia of ovary and fallopian tube',
  'vietnamese': 'Thóat vị và sa buồng trứng và vòi trứng',
}, {
  'id': 'N83.5',
  'english': 'Torsion of ovary, ovarian pedicle and fallopian tube',
  'vietnamese': 'Xoắn buồng trứng, cuống trứng và vòi trứng',
}, {'id': 'N83.6', 'english': 'Haematosalpinx', 'vietnamese': 'Vòi trứng ứ máu'}, {
  'id': 'N83.7',
  'english': 'Haematoma of broad ligament',
  'vietnamese': 'Ứ máu dây chằng rộng',
}, {
  'id': 'N83.8',
  'english': 'Other noninflammatory disorders of ovary, fallopian tube and broad ligament',
  'vietnamese': 'Các biến đổi không do viêm khác của buồng trứng, vòi trứng và dây chằng rộng',
}, {
  'id': 'N83.9',
  'english': 'Noninflammatory disorder of ovary, fallopian tube and broad ligament, unspecified',
  'vietnamese': 'Biến đổi không do viêm của buồng trứng, vòi trứng và dây chằng rộng, không đặc hiệu',
}, {
  'id': 'N84',
  'english': 'Polyp of female genital tract',
  'vietnamese': 'Polyp đường sinh dục nữ',
}, {'id': 'N84.0', 'english': 'Polyp of corpus uteri', 'vietnamese': 'Polyp thân tử cung'}, {
  'id': 'N84.1',
  'english': 'Polyp of cervix uteri',
  'vietnamese': 'Polyp cổ tử cung',
}, {'id': 'N84.2', 'english': 'Polyp of vagina', 'vietnamese': 'Polyp âm đạo'}, {
  'id': 'N84.3',
  'english': 'Polyp of vulva',
  'vietnamese': 'Polyp âm hộ',
}, {
  'id': 'N84.8',
  'english': 'Polyp of other parts of female genital tract',
  'vietnamese': 'Polyp các phần khác đường sinh dục nữ',
}, {
  'id': 'N84.9',
  'english': 'Polyp of female genital tract, unspecified',
  'vietnamese': 'Polyp đường sinh dục nữ, không đặc hiệu',
}, {
  'id': 'N85',
  'english': 'Other noninflammatory disorders of uterus, except cervix',
  'vietnamese': 'Các biến đổi không do viêm khác của tử cung trừ cổ tử cung',
}, {
  'id': 'N85.0',
  'english': 'Endometrial glandular hyperplasia',
  'vietnamese': 'Tăng sản tuyến nội mạc',
}, {
  'id': 'N85.1',
  'english': 'Endometrial adenomatous hyperplasia',
  'vietnamese': 'Tăng sản u tuyến nội mạc tử cung',
}, {'id': 'N85.2', 'english': 'Hypertrophy of uterus', 'vietnamese': 'Phì đại tử cung'}, {
  'id': 'N85.3',
  'english': 'Subinvolution of uterus',
  'vietnamese': 'Bán co tử cung',
}, {'id': 'N85.4', 'english': 'Malposition of uterus', 'vietnamese': 'Tư thế lệch của tử cung'}, {
  'id': 'N85.5',
  'english': 'Inversion of uterus',
  'vietnamese': 'Lộn tử cung',
}, {'id': 'N85.6', 'english': 'Intrauterine synechiae', 'vietnamese': 'Dính trong tử cung'}, {
  'id': 'N85.7',
  'english': 'Haematometra',
  'vietnamese': 'Ứ máu tử cung',
}, {
  'id': 'N85.8',
  'english': 'Other specified noninflammatory disorders of uterus',
  'vietnamese': 'Biến đổi khác xác định của tử cung không do viêm',
}, {
  'id': 'N85.9',
  'english': 'Noninflammatory disorder of uterus, unspecified',
  'vietnamese': 'Biến đổi của tử cung không do viêm, không đặc hiệu',
}, {
  'id': 'N86',
  'english': 'Erosion and ectropion of cervix uteri',
  'vietnamese': 'Xướcvà lộn niêm mạc cổ tử cung',
}, {'id': 'N87', 'english': 'Dysplasia of cervix uteri', 'vietnamese': 'Loạn sản cổ tử cung'}, {
  'id': 'N87.0',
  'english': 'Mild cervical dysplasia',
  'vietnamese': 'Loạn sản nhẹ cổ tử cung',
}, {
  'id': 'N87.1',
  'english': 'Moderate cervical dysplasia',
  'vietnamese': 'Loạn sản vừa cổ tử cung',
}, {
  'id': 'N87.2',
  'english': 'Severe cervical dysplasia, not elsewhere classified',
  'vietnamese': 'Loạn sản nặng cổ tử cung, không phân loại nơi khác',
}, {
  'id': 'N87.9',
  'english': 'Dysplasia of cervix uteri, unspecified',
  'vietnamese': 'Loạn sản cổ tử cung, không đặc hiệu',
}, {
  'id': 'N88',
  'english': 'Other noninflammatory disorders of cervix uteri',
  'vietnamese': 'Các biến đổi không do viêm khác của cổ tử cung',
}, {
  'id': 'N88.0',
  'english': 'Leukoplakia of cervix uteri',
  'vietnamese': 'Bạch sản cổ tử cung',
}, {
  'id': 'N88.1',
  'english': 'Old laceration of cervix uteri',
  'vietnamese': 'Rách cũ cổ tử cung',
}, {
  'id': 'N88.2',
  'english': 'Stricture and stenosis of cervix uteri',
  'vietnamese': 'Hẹp và chít hẹp cổ tử cung',
}, {
  'id': 'N88.3',
  'english': 'Incompetence of cervix uteri',
  'vietnamese': 'Kém hiệu lực cổ tử cung',
}, {
  'id': 'N88.4',
  'english': 'Hypertrophic elongation of cervix uteri',
  'vietnamese': 'Cổ tử cung bị kéo dài do phì đại',
}, {
  'id': 'N88.8',
  'english': 'Other specified noninflammatory disorders of cervix uteri',
  'vietnamese': 'Biến đổi không do viêm xác định khác của cổ tử cung',
}, {
  'id': 'N88.9',
  'english': 'Noninflammatory disorder of cervix uteri, unspecified',
  'vietnamese': 'Biến đổi không do viêm của cổ tử cung, không đặc hiệu',
}, {
  'id': 'N89',
  'english': 'Other noninflammatory disorders of vagina',
  'vietnamese': 'Biến đổi không do viêm khác của âm đạo',
}, {'id': 'N89.0', 'english': 'Mild vaginal dysplasia', 'vietnamese': 'Loạn sản âm đạo nhẹ'}, {
  'id': 'N89.1',
  'english': 'Moderate vaginal dysplasia',
  'vietnamese': 'Loạn sản âm đạo vừa',
}, {
  'id': 'N89.2',
  'english': 'Severe vaginal dysplasia, not elsewhere classified',
  'vietnamese': 'Loạn sản âm đạo nặng, không phân loại nơi khác',
}, {
  'id': 'N89.3',
  'english': 'Dysplasia of vagina, unspecified',
  'vietnamese': 'Loạn sản âm đạo, không đặc hiệu',
}, {'id': 'N89.4', 'english': 'Leukoplakia of vagina', 'vietnamese': 'Bạch sản âm đạo'}, {
  'id': 'N89.5',
  'english': 'Stricture and atresia of vagina',
  'vietnamese': 'Hẹp và tịt âm đạo',
}, {'id': 'N89.6', 'english': 'Tight hymenal ring', 'vietnamese': 'Vòng màng trinh hẹp'}, {
  'id': 'N89.7',
  'english': 'Haematocolpos',
  'vietnamese': 'Ứ máu âm đạo',
}, {
  'id': 'N89.8',
  'english': 'Other specified noninflammatory disorders of vagina',
  'vietnamese': 'Các biến đổi không do viêm xác định khác của âm đạo',
}, {
  'id': 'N89.9',
  'english': 'Noninflammatory disorder of vagina, unspecified',
  'vietnamese': 'Biến đổi không do viêm của âm đạo, không đặc hiệu',
}, {
  'id': 'N90',
  'english': 'Other noninflammatory disorders of vulva and perineum',
  'vietnamese': 'Biến đổi không do viêm khác của âm hộ và tầng sinh môn',
}, {'id': 'N90.0', 'english': 'Mild vulvar dysplasia', 'vietnamese': 'Loạn sản nhẹ âm hộ'}, {
  'id': 'N90.1',
  'english': 'Moderate vulvar dysplasia',
  'vietnamese': 'Loạn sản vừa âm hộ',
}, {
  'id': 'N90.2',
  'english': 'Severe vulvar dysplasia, not elsewhere classified',
  'vietnamese': 'Loạn sản nặng âm hộ, không phân loại nơi khác',
}, {
  'id': 'N90.3',
  'english': 'Dysplasia of vulva, unspecified',
  'vietnamese': 'Loạn sản âm hộ, không đặc hiệu',
}, {'id': 'N90.4', 'english': 'Leukoplakia of vulva', 'vietnamese': 'Bạch sản âm hộ'}, {
  'id': 'N90.5',
  'english': 'Atrophy of vulva',
  'vietnamese': 'Teo âm hộ',
}, {'id': 'N90.6', 'english': 'Hypertrophy of vulva', 'vietnamese': 'Phì đại âm hộ'}, {
  'id': 'N90.7',
  'english': 'Vulvar cyst',
  'vietnamese': 'Nang âm hộ',
}, {
  'id': 'N90.8',
  'english': 'Other specified noninflammatory disorders of vulva and perineum',
  'vietnamese': 'Biến đổi không do viêm xác định khác của âm hộ và tầng sinh môn',
}, {
  'id': 'N90.9',
  'english': 'Noninflammatory disorder of vulva and perineum, unspecified',
  'vietnamese': 'Biến đổi không do viêm của âm hộ và tầng sinh môn, không đặc hiệu',
}, {
  'id': 'N91',
  'english': 'Absent, scanty and rare menstruation',
  'vietnamese': 'Vô kinh, thiểu kinh, hiếm kinh',
}, {'id': 'N91.0', 'english': 'Primary amenorrhoea', 'vietnamese': 'Vô kinh nguyên phát'}, {
  'id': 'N91.1',
  'english': 'Secondary amenorrhoea',
  'vietnamese': 'Vô kinh thứ phát',
}, {
  'id': 'N91.2',
  'english': 'Amenorrhoea, unspecified',
  'vietnamese': 'Vô kinh, không đặc hiệu',
}, {
  'id': 'N91.3',
  'english': 'Primary oligomenorrhoea',
  'vietnamese': 'Thiểu kinh nguyên phát',
}, {'id': 'N91.4', 'english': 'Secondary oligomenorrhoea', 'vietnamese': 'Thiểu kinh thứ phát'}, {
  'id': 'N91.5',
  'english': 'Oligomenorrhoea, unspecified',
  'vietnamese': 'Thiểu kinh không đặc hiệu',
}, {
  'id': 'N92',
  'english': 'Excessive, frequent and irregular menstruation',
  'vietnamese': 'Kinh nguyệt nhiều, hay xuất hiện và không đều',
}, {
  'id': 'N92.0',
  'english': 'Excessive and frequent menstruation with regular cycle',
  'vietnamese': 'Kinh nguyệt nhiều và hay xuất hiện với chu kỳ đều',
}, {
  'id': 'N92.1',
  'english': 'Excessive and frequent menstruation with irregular cycle',
  'vietnamese': 'Kinh nguyệt nhiều và hay xảy ra với chu kỳ không đều',
}, {
  'id': 'N92.2',
  'english': 'Excessive menstruation at puberty',
  'vietnamese': 'Rong kinh lúc dậy thì',
}, {'id': 'N92.3', 'english': 'Ovulation bleeding', 'vietnamese': 'Chảy máu lúc rụng trứng'}, {
  'id': 'N92.4',
  'english': 'Excessive bleeding in the premenopausal period',
  'vietnamese': 'Chảy máu nặng lúc tiền mãn kinh',
}, {
  'id': 'N92.5',
  'english': 'Other specified irregular menstruation',
  'vietnamese': 'Kinh nguyệt không đều khác',
}, {
  'id': 'N92.6',
  'english': 'Irregular menstruation, unspecified',
  'vietnamese': 'Kinh nguyệt không đều, không đặc hiệu',
}, {
  'id': 'N93',
  'english': 'Other abnormal uterine and vaginal bleeding',
  'vietnamese': 'Chảy máu bất thường khác của tử cung và âm đạo',
}, {
  'id': 'N93.0',
  'english': 'Postcoital and contact bleeding',
  'vietnamese': 'Chảy máu sau tiếp xúc và giao hợp',
}, {
  'id': 'N93.8',
  'english': 'Other specified abnormal uterine and vaginal bleeding',
  'vietnamese': 'Chảy máu bất thường xác định khác của tử cung và âm đạo',
}, {
  'id': 'N93.9',
  'english': 'Abnormal uterine and vaginal bleeding, unspecified',
  'vietnamese': 'Chảy máu bất thường của tử cung và âm đạo, không đặc hiệu',
}, {
  'id': 'N94',
  'english': 'Pain and other conditions associated with female genital organs and menstrual cycle',
  'vietnamese': 'Đau và tình trạng khác liên quan đến cơ quan sinh dục nữ và chu kỳ kinh nguyệt',
}, {'id': 'N94.0', 'english': 'Mittelschmerz', 'vietnamese': 'Đau vừa'}, {
  'id': 'N94.1',
  'english': 'Dyspareunia',
  'vietnamese': 'Đau lúc giao hợp',
}, {'id': 'N94.2', 'english': 'Vaginismus', 'vietnamese': 'Co đau âm đạo'}, {
  'id': 'N94.3',
  'english': 'Premenstrual tension syndrome',
  'vietnamese': 'Hội chứng căng thẳng trước khi thấy kinh',
}, {'id': 'N94.4', 'english': 'Primary dysmenorrhoea', 'vietnamese': 'Đau kinh nguyên phát'}, {
  'id': 'N94.5',
  'english': 'Secondary dysmenorrhoea',
  'vietnamese': 'Đau kinh thứ phát',
}, {
  'id': 'N94.6',
  'english': 'Dysmenorrhoea, unspecified',
  'vietnamese': 'Đau kinh, không đặc hiệu',
}, {
  'id': 'N94.8',
  'english': 'Other specified conditions associated with female genital organs and menstrual cycle',
  'vietnamese': 'Các tình trạng xác định khác liên quan đến cơ quan sinh dục nữ và chu kỳ kinh nguyệt',
}, {
  'id': 'N94.9',
  'english': 'Unspecified condition associated with female genital organs and menstrual cycle',
  'vietnamese': 'Tình trạng không xác định liên quan đến cơ quan sinh dục nữ và chu kỳ kinh nguyệt',
}, {
  'id': 'N95',
  'english': 'Menopausal and other perimenopausal disorders',
  'vietnamese': 'Rối loạn mãn kinh và rối loạn chu mãn kinh',
}, {'id': 'N95.0', 'english': 'Postmenopausal bleeding', 'vietnamese': 'Chảy máu sau mãn kinh'}, {
  'id': 'N95.1',
  'english': 'Menopausal and female climacteric states',
  'vietnamese': 'Tình trạng mãn kinh nữ',
}, {
  'id': 'N95.2',
  'english': 'Postmenopausal atrophic vaginitis',
  'vietnamese': 'Viêm teo âm đạo sau mãn kinh',
}, {
  'id': 'N95.3',
  'english': 'States associated with artificial menopause',
  'vietnamese': 'Tình trạng liên quan đến mãn kinh nhân tạo',
}, {
  'id': 'N95.8',
  'english': 'Other specified menopausal and perimenopausal disorders',
  'vietnamese': 'Các rối loạn mãn kinh và chu mãn kinh, không đặc hiệu',
}, {
  'id': 'N95.9',
  'english': 'Menopausal and perimenopausal disorder, unspecified',
  'vietnamese': 'Rối loạn mãn kinh và chu mãn kinh, không đặc hiệu',
}, {'id': 'N96', 'english': 'Habitual aborter', 'vietnamese': 'Hay sảy thai'}, {
  'id': 'N97',
  'english': 'Female infertility',
  'vietnamese': 'Vô sinh nữ',
}, {
  'id': 'N97.0',
  'english': 'Female infertility associated with anovulation',
  'vietnamese': 'Vô sinh nữ liên quan đến không rụng trứng',
}, {
  'id': 'N97.1',
  'english': 'Female infertility of tubal origin',
  'vietnamese': 'Vô sinh nữ do nguyên nhân vòi trứng',
}, {
  'id': 'N97.2',
  'english': 'Female infertility of uterine origin',
  'vietnamese': 'Vô sinh nữ do nguyên nhân tử cung',
}, {
  'id': 'N97.3',
  'english': 'Female infertility of cervical origin',
  'vietnamese': 'Vô sinh nữ do nguyên nhân cổ tử cung',
}, {
  'id': 'N97.4',
  'english': 'Female infertility associated with male factors',
  'vietnamese': 'Vô sinh nữ liên quan đến các yếu tố nam',
}, {
  'id': 'N97.8',
  'english': 'Female infertility of other origin',
  'vietnamese': 'Vô sinh nữ liên quan đến các yếu tố khác',
}, {
  'id': 'N97.9',
  'english': 'Female infertility, unspecified',
  'vietnamese': 'Vô sinh nữ, không đặc hiệu',
}, {
  'id': 'N98',
  'english': 'Complications associated with artificial fertilization',
  'vietnamese': 'Biến chứng liên quan đến thụ thai nhân tạo',
}, {
  'id': 'N98.0',
  'english': 'Infection associated with artificial insemination',
  'vietnamese': 'Nhiễm khuẩn liên quan đến thụ tinh nhân tạo',
}, {
  'id': 'N98.1',
  'english': 'Hyperstimulation of ovaries',
  'vietnamese': 'Cường kích thích các buồng trứng',
}, {
  'id': 'N98.2',
  'english': 'Complications of attempted introduction of fertilized ovum following in vitro fertilization',
  'vietnamese': 'Biến chứng do đưa vào trứng đã thụ tinh trong quá trình thụ tinh trong ống nghiệm',
}, {
  'id': 'N98.3',
  'english': 'Complications of attempted introduction of embryo in embryo transfer',
  'vietnamese': 'Biến chứng do đưa phôi vào trong quá trình chuyển phôi',
}, {
  'id': 'N98.8',
  'english': 'Other complications associated with artificial fertilization',
  'vietnamese': 'Biến chứng khác liên quan đến thụ thai nhân tạo',
}, {
  'id': 'N98.9',
  'english': 'Complication associated with artificial fertilization, unspecified',
  'vietnamese': 'Biến chứng liên quan đến thụ thai nhân tạo, không đặc hiệu',
}, {
  'id': 'N99',
  'english': 'Postprocedural disorders of genitourinary system, not elsewhere classified',
  'vietnamese': 'Các rối loạn sau can thiệp của hệ sinh dục tiết niệu không phân loại nơi khác',
}, {
  'id': 'N99.0',
  'english': 'Postprocedural renal failure',
  'vietnamese': 'Suy thận sau can thiệp',
}, {
  'id': 'N99.1',
  'english': 'Postprocedural urethral stricture',
  'vietnamese': 'Hẹp niệu đạo sau can thiệp',
}, {
  'id': 'N99.2',
  'english': 'Postoperative adhesions of vagina',
  'vietnamese': 'Dính âm đạo sau phẫu thuật',
}, {
  'id': 'N99.3',
  'english': 'Prolapse of vaginal vault after hysterectomy',
  'vietnamese': 'Sa vòm âm đạo sau cắt tử cung',
}, {
  'id': 'N99.4',
  'english': 'Postprocedural pelvic peritoneal adhesions',
  'vietnamese': 'Dính phúc mạc chậu sau can thiệp',
}, {
  'id': 'N99.5',
  'english': 'Malfunction of external stoma of urinary tract',
  'vietnamese': 'Hoạt động kém của các lỗ thông ra ngoài của đường tiết niệu',
}, {
  'id': 'N99.8',
  'english': 'Other postprocedural disorders of genitourinary system',
  'vietnamese': 'Các rối loạn sau can thiệp khác của hệ sinh dục tiết niệu',
}, {
  'id': 'N99.9',
  'english': 'Postprocedural disorder of genitourinary system, unspecified',
  'vietnamese': 'Rối loạn sau can thiệp của hệ sinh dục tiết niệu, không đặc hiệu',
}, {'id': 'O00', 'english': 'Ectopic pregnancy', 'vietnamese': 'Chửa ngoài tử cung'}, {
  'id': 'O00.0',
  'english': 'Abdominal pregnancy',
  'vietnamese': 'Chửa trong ổ bụng',
}, {'id': 'O00.1', 'english': 'Tubal pregnancy', 'vietnamese': 'Chửa ở vòi tử cung'}, {
  'id': 'O00.2',
  'english': 'Ovarian pregnancy',
  'vietnamese': 'Chửa ở buồng trứng',
}, {
  'id': 'O00.8',
  'english': 'Other ectopic pregnancy',
  'vietnamese': 'Chửa ngoài tử cung khác',
}, {
  'id': 'O00.9',
  'english': 'Ectopic pregnancy, unspecified',
  'vietnamese': 'Chửa ngoài tử cung, chưa xác định rõ',
}, {'id': 'O01', 'english': 'Hydatidiform mole', 'vietnamese': 'Chửa trứng'}, {
  'id': 'O01.0',
  'english': 'Classical hydatidiform mole',
  'vietnamese': 'Chửa trứng cổ điển',
}, {
  'id': 'O01.1',
  'english': 'Incomplete and partial hydatidiform mole',
  'vietnamese': 'Chửa trứng không hoàn toàn và bán phần',
}, {
  'id': 'O01.9',
  'english': 'Hydatidiform mole, unspecified',
  'vietnamese': 'Chửa trứng không điển hình',
}, {
  'id': 'O02',
  'english': 'Other abnormal products of conception',
  'vietnamese': 'Bất thường khác của trứng',
}, {
  'id': 'O02.0',
  'english': 'Blighted ovum and nonhydatidiform mole',
  'vietnamese': 'Trứng thoái triển và chửa trứng không có nang',
}, {'id': 'O02.1', 'english': 'Missed abortion', 'vietnamese': 'Sẩy thai sớm'}, {
  'id': 'O02.8',
  'english': 'Other specified abnormal products of conception',
  'vietnamese': 'Bất thường xác định khác của trứng',
}, {
  'id': 'O02.9',
  'english': 'Abnormal product of conception, unspecified',
  'vietnamese': 'Bất thường khác trong thụ thai, chưa xác định',
}, {'id': 'O03', 'english': 'Spontaneous abortion', 'vietnamese': 'Sẩy thai tự nhiên'}, {
  'id': 'O03.0',
  'english': 'Spontaneous abortion (Incomplete, complicated by genital tract and pelvic infection)',
  'vietnamese': 'Sẩy thai tự nhiên (Sảy không hoàn toàn, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O03.1',
  'english': 'Spontaneous abortion (Incomplete, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Sẩy thai tự nhiên (Không hoàn toàn, gây biến chứng ra máu nhiều hay kéo dài)',
}, {
  'id': 'O03.2',
  'english': 'Spontaneous abortion (Incomplete, complicated by embolism)',
  'vietnamese': 'Sẩy thai tự nhiên (Không hoàn toàn, gây biến chứng tắc mạch)',
}, {
  'id': 'O03.3',
  'english': 'Spontaneous abortion (Incomplete, with other and unspecified complications)',
  'vietnamese': 'Sẩy thai tự nhiên (Không hoàn toàn, gây biến chứng khác không điển hình)',
}, {
  'id': 'O03.4',
  'english': 'Spontaneous abortion (Incomplete, without complication)',
  'vietnamese': 'Sẩy thai tự nhiên (Không hoàn toàn, không có biến chứng)',
}, {
  'id': 'O03.5',
  'english': 'Spontaneous abortion (Complete or unspecified, complicated by\ngenital tract and pelvic infection)',
  'vietnamese': 'Sẩy thai tự nhiên (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O03.6',
  'english': 'Spontaneous abortion (Complete or unspecified, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Sẩy thai tự nhiên (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng ra máu chậm và nhiều quá mức)',
}, {
  'id': 'O03.7',
  'english': 'Spontaneous abortion (Complete or unspecified, complicated by embolism)',
  'vietnamese': 'Sẩy thai tự nhiên (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng tắc mạch)',
}, {
  'id': 'O03.8',
  'english': 'Spontaneous abortion (Complete or unspecified, with other and unspecified complications)',
  'vietnamese': 'Sẩy thai tự nhiên (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng khác, chưa xác định rõ)',
}, {
  'id': 'O03.9',
  'english': 'Spontaneous abortion (Complete or unspecified, without complication)',
  'vietnamese': 'Sẩy thai tự nhiên (Hoàn toàn hoặc chưa xác định rõ, không có biến chứng)',
}, {'id': 'O04', 'english': 'Medical abortion', 'vietnamese': 'Phá thai nội khoa'}, {
  'id': 'O04.0',
  'english': 'Medical abortion (Incomplete, complicated by genital tract and pelvic infection)',
  'vietnamese': 'Phá thai nội khoa (Sảy không hoàn toàn, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O04.1',
  'english': 'Medical abortion (Incomplete, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai nội khoa (Không hoàn toàn, gây biến chứng ra máu nhiều hay kéo dài)',
}, {
  'id': 'O04.2',
  'english': 'Medical abortion (Incomplete, complicated by embolism)',
  'vietnamese': 'Phá thai nội khoa (Không hoàn toàn, gây biến chứng tắc mạch)',
}, {
  'id': 'O04.3',
  'english': 'Medical abortion (Incomplete, with other and unspecified complications)',
  'vietnamese': 'Phá thai nội khoa (Không hoàn toàn, gây biến chứng khác không điển hình)',
}, {
  'id': 'O04.4',
  'english': 'Medical abortion (Incomplete, without complication)',
  'vietnamese': 'Phá thai nội khoa (Không hoàn toàn, không có biến chứng)',
}, {
  'id': 'O04.5',
  'english': 'Medical abortion (Complete or unspecified, complicated by\ngenital tract and pelvic infection)',
  'vietnamese': 'Phá thai nội khoa (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O04.6',
  'english': 'Medical abortion (Complete or unspecified, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai nội khoa (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng ra máu chậm và nhiều quá mức)',
}, {
  'id': 'O04.7',
  'english': 'Medical abortion (Complete or unspecified, complicated by embolism)',
  'vietnamese': 'Phá thai nội khoa (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng tắc mạch)',
}, {
  'id': 'O04.8',
  'english': 'Medical abortion (Complete or unspecified, with other and unspecified complications)',
  'vietnamese': 'Phá thai nội khoa (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng khác, chưa xác định rõ)',
}, {
  'id': 'O04.9',
  'english': 'Medical abortion (Complete or unspecified, without complication)',
  'vietnamese': 'Phá thai nội khoa (Hoàn toàn hoặc chưa xác định rõ, không có biến chứng)',
}, {'id': 'O05', 'english': 'Other abortion', 'vietnamese': 'Phá thai khác'}, {
  'id': 'O05.0',
  'english': 'Other abortion (Incomplete, complicated by genital tract and pelvic infection)',
  'vietnamese': 'Phá thai khác (Sảy không hoàn toàn, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O05.1',
  'english': 'Other abortion (Incomplete, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai khác (Không hoàn toàn, gây biến chứng ra máu nhiều hay kéo dài)',
}, {
  'id': 'O05.2',
  'english': 'Other abortion (Incomplete, complicated by embolism)',
  'vietnamese': 'Phá thai khác (Không hoàn toàn, gây biến chứng tắc mạch)',
}, {
  'id': 'O05.3',
  'english': 'Other abortion (Incomplete, with other and unspecified complications)',
  'vietnamese': 'Phá thai khác (Không hoàn toàn, gây biến chứng khác không điển hình)',
}, {
  'id': 'O05.4',
  'english': 'Other abortion (Incomplete, without complication)',
  'vietnamese': 'Phá thai khác (Không hoàn toàn, không có biến chứng)',
}, {
  'id': 'O05.5',
  'english': 'Other abortion (Complete or unspecified, complicated by\ngenital tract and pelvic infection)',
  'vietnamese': 'Phá thai khác (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O05.6',
  'english': 'Other abortion (Complete or unspecified, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai khác (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng ra máu chậm và nhiều quá mức)',
}, {
  'id': 'O05.7',
  'english': 'Other abortion (Complete or unspecified, complicated by embolism)',
  'vietnamese': 'Phá thai khác (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng tắc mạch)',
}, {
  'id': 'O05.8',
  'english': 'Other abortion (Complete or unspecified, with other and unspecified complications)',
  'vietnamese': 'Phá thai khác (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng khác, chưa xác định rõ)',
}, {
  'id': 'O05.9',
  'english': 'Other abortion (Complete or unspecified, without complication)',
  'vietnamese': 'Phá thai khác (Hoàn toàn hoặc chưa xác định rõ, không có biến chứng)',
}, {'id': 'O06', 'english': 'Unspecified abortion', 'vietnamese': 'Phá thai chưa xác định rõ'}, {
  'id': 'O06.0',
  'english': 'Unspecified abortion (Incomplete, complicated by genital tract and pelvic infection)',
  'vietnamese': 'Phá thai chưa xác định rõ (Sảy không hoàn toàn, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O06.1',
  'english': 'Unspecified abortion (Incomplete, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai chưa xác định rõ (Không hoàn toàn, gây biến chứng ra máu nhiều hay kéo dài)',
}, {
  'id': 'O06.2',
  'english': 'Unspecified abortion (Incomplete, complicated by embolism)',
  'vietnamese': 'Phá thai chưa xác định rõ (Không hoàn toàn, gây biến chứng tắc mạch)',
}, {
  'id': 'O06.3',
  'english': 'Unspecified abortion (Incomplete, with other and unspecified complications)',
  'vietnamese': 'Phá thai chưa xác định rõ (Không hoàn toàn, gây biến chứng khác không điển hình)',
}, {
  'id': 'O06.4',
  'english': 'Unspecified abortion (Incomplete, without complication)',
  'vietnamese': 'Phá thai chưa xác định rõ (Không hoàn toàn, không có biến chứng)',
}, {
  'id': 'O06.5',
  'english': 'Unspecified abortion (Complete or unspecified, complicated by\ngenital tract and pelvic infection)',
  'vietnamese': 'Phá thai chưa xác định rõ (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung)',
}, {
  'id': 'O06.6',
  'english': 'Unspecified abortion (Complete or unspecified, complicated by delayed or excessive haemorrhage)',
  'vietnamese': 'Phá thai chưa xác định rõ (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng ra máu chậm và nhiều quá mức)',
}, {
  'id': 'O06.7',
  'english': 'Unspecified abortion (Complete or unspecified, complicated by embolism)',
  'vietnamese': 'Phá thai chưa xác định rõ (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng tắc mạch)',
}, {
  'id': 'O06.8',
  'english': 'Unspecified abortion (Complete or unspecified, with other and unspecified complications)',
  'vietnamese': 'Phá thai chưa xác định rõ (Hoàn toàn hoặc chưa xác định rõ, gây biến chứng khác, chưa xác định rõ)',
}, {
  'id': 'O06.9',
  'english': 'Unspecified abortion (Complete or unspecified, without complication)',
  'vietnamese': 'Phá thai chưa xác định rõ (Hoàn toàn hoặc chưa xác định rõ, không có biến chứng)',
}, {'id': 'O07', 'english': 'Failed attempted abortion', 'vietnamese': 'Phá thai thất bại'}, {
  'id': 'O07.0',
  'english': 'Failed medical abortion, complicated by genital tract and pelvic infection',
  'vietnamese': 'Phá thai nội khoa thất bại, gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung',
}, {
  'id': 'O07.1',
  'english': 'Failed medical abortion, complicated by delayed or excessive haemorrhage',
  'vietnamese': 'Phá thai nội khoa thất bại, gây biến chứng ra máu chậm hoặc nhiều quá mức',
}, {
  'id': 'O07.2',
  'english': 'Failed medical abortion, complicated by embolism',
  'vietnamese': 'Phá thai nội khoa thất bại, gây biến chứng tắc mạch',
}, {
  'id': 'O07.3',
  'english': 'Failed medical abortion, with other and unspecified complications',
  'vietnamese': 'Phá thai nội khoa thất bại, gây biến chứng khác, chưa xác định rõ',
}, {
  'id': 'O07.4',
  'english': 'Failed medical abortion, without complication',
  'vietnamese': 'Phá thai nội khoa thất bại, không có biến chứng',
}, {
  'id': 'O07.5',
  'english': 'Other and unspecified failed attempted abortion, complicated by genital tract and pelvic infection',
  'vietnamese': 'Phá thai nội khoa thất bại khác, chưa xác định rõ gây biến chứng nhiễm khuẩn đường sinh dục và tiểu khung',
}, {
  'id': 'O07.6',
  'english': 'Other and unspecified failed attempted abortion, complicated by delayed or excessive haemorrhage',
  'vietnamese': 'Phá thai nội khoa thất bại khác chưa xác định rõ, gây biến chứng ra máu chậm và nhiều quá mức',
}, {
  'id': 'O07.7',
  'english': 'Other and unspecified failed attempted abortion, complicated by embolism',
  'vietnamese': 'Phá thai nội khoa thất bại khác chưa xác định rõ, gây biến chứng tắc mạch',
}, {
  'id': 'O07.8',
  'english': 'Other and unspecified failed attempted abortion, with other and unspecified complications',
  'vietnamese': 'Phá thai nội khoa thất bại khác, chưa xác định rõ các biến chứng khác',
}, {
  'id': 'O07.9',
  'english': 'Other and unspecified failed attempted abortion, without complication',
  'vietnamese': 'Phá thai nội khoa thất bại khác không chỉ rõ, không có biến chứng',
}, {
  'id': 'O08',
  'english': 'Complications following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Biến chứng sau sẩy thai, chửa ngoài tử cung và chửa trứng',
}, {
  'id': 'O08.0',
  'english': 'Genital tract and pelvic infection following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Nhiễm khuẩn đường sinh dục và tiểu khung sau sẩy thai, chửa ngoài tử cung và chửa trứng',
}, {
  'id': 'O08.1',
  'english': 'Delayed or excessive haemorrhage following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Ra máu kéo dài hay quá nhiều sau sẩy thai, chửa ngoài tử cung và chửa trứng',
}, {
  'id': 'O08.2',
  'english': 'Embolism following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Tắc mạch sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.3',
  'english': 'Shock following abortion and ectopic and molar pregnancy',
  'vietnamese': 'choáng sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.4',
  'english': 'Renal failure following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Suy thận sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.5',
  'english': 'Metabolic disorders following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Rối loạn chuyển hóa sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.6',
  'english': 'Damage to pelvic organs and tissues following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Tổn thương các tạng và tổ chức ở tiểu khung sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.7',
  'english': 'Other venous complications following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Biến chứng tĩnh mạch khác sau sẩy thai chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.8',
  'english': 'Other complications following abortion and ectopic and molar pregnancy',
  'vietnamese': 'Biến chứng khác sau sẩy thai, chửa ngoài tử cung hay chửa trứng',
}, {
  'id': 'O08.9',
  'english': 'Complication following abortion and ectopic and molar pregnancy, unspecified',
  'vietnamese': 'Biến chứng sau sẩy thai, chửa ngoài tử cung hay chửa trứng, chưa xác định rõ',
}, {
  'id': 'O10',
  'english': 'Pre-existing hypertension complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Tăng huyết áp có sẵn gây biến chứng cho thai nghén, cuộc đẻ và sau khi đẻ',
}, {
  'id': 'O10.0',
  'english': 'Pre-existing essential hypertension complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Tăng huyết áp chủ yếu có sẵn gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O10.1',
  'english': 'Pre-existing hypertensive heart disease complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh tim do tăng huyết áp có sẵn gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O10.2',
  'english': 'Pre-existing hypertensive renal disease complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh thận do tăng huyết áp có sẵn gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O10.3',
  'english': 'Pre-existing hypertensive heart and renal disease complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh tim và thận do tăng huyết áp có sẵn gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O10.4',
  'english': 'Pre-existing secondary hypertension complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Tăng huyết áp thứ phát có sẵn gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O10.9',
  'english': 'Unspecified pre-existing hypertension complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Tăng huyết áp có sẵn chưa xác định rõ gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O11',
  'english': 'Pre-exclampsia superimposed on chronic hypertension',
  'vietnamese': 'Tiền sản giật kèm theo tăng huyết áp mãn tính',
}, {
  'id': 'O12',
  'english': 'Gestational [pregnancy-induced] oedema and proteinuria without hypertension',
  'vietnamese': 'Phù khi thai nghén (do thai nghén gây ra) và protein niệu mà không có tăng huyết áp',
}, {'id': 'O12.0', 'english': 'Gestational oedema', 'vietnamese': 'Phù thai nghén'}, {
  'id': 'O12.1',
  'english': 'Gestational proteinuria',
  'vietnamese': 'Protein niệu thai nghén',
}, {
  'id': 'O12.2',
  'english': 'Gestational oedema with proteinuria',
  'vietnamese': 'Phù và protein niệu thai nghén',
}, {
  'id': 'O13',
  'english': 'Gestational (pregnancy-induced) hypertension',
  'vietnamese': 'Tăng huyết áp thai nghén (do thai nghén gây ra)',
}, {
  'id': 'O14',
  'english': 'Gestational [pregnancy-induced] hypertension with significant proteinuria',
  'vietnamese': 'Tiền sản giật',
}, {
  'id': 'O14.0',
  'english': 'Moderate pre-eclampsia',
  'vietnamese': 'Tiền sản giật thể nhẹ đến trung bình',
}, {'id': 'O14.1', 'english': 'Severe pre-eclampsia', 'vietnamese': 'Tiền sản giật thể nặng'}, {
  'id': 'O14.2',
  'english': 'HELLP syndrome (Combination of hemolysis, elevated liver enzymes and low platelet count)',
  'vietnamese': 'Hội chứng HELLP (Sự kết hợp của tan máu, tăng men gan và giảm tiểu cầu)',
}, {
  'id': 'O14.9',
  'english': 'Pre-eclampsia, unspecified',
  'vietnamese': 'Tiền sản giật chưa xác định rõ',
}, {'id': 'O15', 'english': 'Eclampsia', 'vietnamese': 'Sản giật'}, {
  'id': 'O15.0',
  'english': 'Eclampsia in pregnancy',
  'vietnamese': 'Sản giật khi có thai',
}, {
  'id': 'O15.1',
  'english': 'Eclampsia in labour',
  'vietnamese': 'Sản giật trong khi chuyển dạ',
}, {
  'id': 'O15.2',
  'english': 'Eclampsia in the puerperium',
  'vietnamese': 'Sản giật trong thời kỳ sau đẻ',
}, {
  'id': 'O15.9',
  'english': 'Eclampsia, unspecified as to time period',
  'vietnamese': 'Sản giật chưa xác định rõ thời kỳ xảy ra',
}, {
  'id': 'O16',
  'english': 'Unspecified maternal hypertension',
  'vietnamese': 'Tăng huyết áp bà mẹ chưa xác định rõ',
}, {
  'id': 'O20',
  'english': 'Haemorrhage in early pregnancy',
  'vietnamese': 'Ra máu trong thời kỳ đầu thai nghén',
}, {'id': 'O20.0', 'english': 'Threatened abortion', 'vietnamese': 'Doạ sẩy thai'}, {
  'id': 'O20.8',
  'english': 'Other haemorrhage in early pregnancy',
  'vietnamese': 'Các ra máu khác trong thời kỳ đầu thai nghén',
}, {
  'id': 'O20.9',
  'english': 'Haemorrhage in early pregnancy, unspecified',
  'vietnamese': 'Ra máu trong thời kỳ đầu thai nghén, không đặc hiệu',
}, {
  'id': 'O21',
  'english': 'Excessive vomiting in pregnancy',
  'vietnamese': 'Nôn quá mức trong lúc có thai',
}, {
  'id': 'O21.0',
  'english': 'Mild hyperemesis gravidarum',
  'vietnamese': 'Nôn nghén thể trung bình',
}, {
  'id': 'O21.1',
  'english': 'Hyperemesis gravidarum with metabolic disturbance',
  'vietnamese': 'Nôn nghén có rối loạn chuyển hóa',
}, {
  'id': 'O21.2',
  'english': 'Late vomiting of pregnancy',
  'vietnamese': 'Nôn muộn trong khi có thai',
}, {
  'id': 'O21.8',
  'english': 'Other vomiting complicating pregnancy',
  'vietnamese': 'Các nôn khác gây biến chứng cho thai nghén',
}, {
  'id': 'O21.9',
  'english': 'Vomiting of pregnancy, unspecified',
  'vietnamese': 'Nôn trong khi có thai, chưa xác định rõ',
}, {
  'id': 'O22',
  'english': 'Venous complications in pregnancy',
  'vietnamese': 'Biến chứng tĩnh mạch và trĩ trong khi có thai',
}, {
  'id': 'O22.0',
  'english': 'Varicose veins of lower extremity in pregnancy',
  'vietnamese': 'Giãn tĩnh mạch chi dưới trong khi có thai',
}, {
  'id': 'O22.1',
  'english': 'Genital varices in pregnancy',
  'vietnamese': 'Giãn tĩnh mạch sinh dục trong khi có thai',
}, {
  'id': 'O22.2',
  'english': 'Superficial thrombophlebitis in pregnancy',
  'vietnamese': 'Viêm tắc tĩnh mạch nông trong khi có thai',
}, {
  'id': 'O22.3',
  'english': 'Deep phlebothrombosis in pregnancy',
  'vietnamese': 'Viêm tắc tĩnh mạch sâu trong khi có thai',
}, {
  'id': 'O22.4',
  'english': 'Haemorrhoids in pregnancy',
  'vietnamese': 'Trĩ trong khi có thai',
}, {
  'id': 'O22.5',
  'english': 'Cerebral venous thrombosis in pregnancy',
  'vietnamese': 'Huyết khối tĩnh mạch não trong khi có thai',
}, {
  'id': 'O22.8',
  'english': 'Other venous complications in pregnancy',
  'vietnamese': 'Biến chứng tĩnh mạch khác trong khi có thai',
}, {
  'id': 'O22.9',
  'english': 'Venous complication in pregnancy, unspecified',
  'vietnamese': 'Biến chứng tĩnh mạch trong khi có thai',
}, {
  'id': 'O23',
  'english': 'Infections of genitourinary tract in pregnancy',
  'vietnamese': 'Nhiễm khuẩn đường tiết niệu - sinh dục trong khi có thai',
}, {
  'id': 'O23.0',
  'english': 'Infections of kidney in pregnancy',
  'vietnamese': 'Nhiễm khuẩn thận trong khi có thai',
}, {
  'id': 'O23.1',
  'english': 'Infections of bladder in pregnancy',
  'vietnamese': 'Nhiễm khuẩn bàng quang trong khi có thai',
}, {
  'id': 'O23.2',
  'english': 'Infections of urethra in pregnancy',
  'vietnamese': 'Nhiễm khuẩn niệu đạo trong khi có thai',
}, {
  'id': 'O23.3',
  'english': 'Infections of other parts of urinary tract in pregnancy',
  'vietnamese': 'Nhiễm khuẩn phần khác của đường tiết niệu trong khi có thai',
}, {
  'id': 'O23.4',
  'english': 'Unspecified infection of urinary tract in pregnancy',
  'vietnamese': 'Nhiễm khuẩn đường tiết niệu chưa xác định rõ trong khi có thai',
}, {
  'id': 'O23.5',
  'english': 'Infections of the genital tract in pregnancy',
  'vietnamese': 'Nhiễm khuẩn đường sinh dục trong khi có thai',
}, {
  'id': 'O23.9',
  'english': 'Other and unspecified genitourinary tract infection in pregnancy',
  'vietnamese': 'Nhiễm khuẩn đường tiết niệu sinh dục khác và chưa xác định rõ trong lúc có thai',
}, {
  'id': 'O24',
  'english': 'Diabetes mellitus in pregnancy',
  'vietnamese': 'Đái tháo đường trong khi có thai',
}, {
  'id': 'O24.0',
  'english': 'Diabetes mellitus in pregnancy: Pre-existing diabetes mellitus, insulin-dependent',
  'vietnamese': 'Đái tháo đường sẵn có, lệ thuộc insulin',
}, {
  'id': 'O24.1',
  'english': 'Diabetes mellitus in pregnancy: Pre-existing diabetes mellitus, non-insulin-dependent',
  'vietnamese': 'Đái tháo đường sẵn có, không lệ thuộc insulin',
}, {
  'id': 'O24.2',
  'english': 'Diabetes mellitus in pregnancy: Pre-existing malnutrition-related diabetes mellitus',
  'vietnamese': 'Đái tháo đường sẵn có liên quan đến thiếu dinh dưỡng',
}, {
  'id': 'O24.3',
  'english': 'Diabetes mellitus in pregnancy: Pre-existing diabetes mellitus, unspecified',
  'vietnamese': 'Đái tháo đường sẵn có, chưa xác định rõ',
}, {
  'id': 'O24.4',
  'english': 'Diabetes mellitus arising in pregnancy',
  'vietnamese': 'Đái tháo đường tăng lên trong khi có thai',
}, {
  'id': 'O24.9',
  'english': 'Diabetes mellitus in pregnancy, unspecified',
  'vietnamese': 'Đái tháo đường trong khi có thai, chưa xác định rõ',
}, {
  'id': 'O25',
  'english': 'Malnutrition in pregnancy',
  'vietnamese': 'Thiếu dinh dưỡng khi có thai',
}, {
  'id': 'O26',
  'english': 'Maternal care for other conditions predominantly related to pregnancy',
  'vietnamese': 'Chăm sóc bà mẹ vì những tình trạng khác chủ yếu liên quan đến thai nghén',
}, {
  'id': 'O26.0',
  'english': 'Excessive weight gain in pregnancy',
  'vietnamese': 'Tăng cân nặng quá mức trong khi có thai',
}, {
  'id': 'O26.1',
  'english': 'Low weight gain in pregnancy',
  'vietnamese': 'Tăng cân ít trong khi có thai',
}, {
  'id': 'O26.2',
  'english': 'Pregnancy care of habitual aborter',
  'vietnamese': 'Chăm sóc thai nghén của sẩy thai liên tiếp',
}, {
  'id': 'O26.3',
  'english': 'Retained intrauterine contraceptive device in pregnancy',
  'vietnamese': 'Có thai khi mang dụng cụ tử cung',
}, {'id': 'O26.4', 'english': 'Herpes gestationis', 'vietnamese': 'Herpes và thai nghén'}, {
  'id': 'O26.5',
  'english': 'Maternal hypotension syndrome',
  'vietnamese': 'Hội chứng hạ huyết áp bà mẹ',
}, {
  'id': 'O26.6',
  'english': 'Liver disorders in pregnancy, childbirth and the puerperium',
  'vietnamese': 'Các rối loạn tại gan trong khi có thai, khi đẻ và sau khi đẻ',
}, {
  'id': 'O26.7',
  'english': 'Subluxation of symphysis (pubis) in pregnancy, childbirth and the puerperium',
  'vietnamese': 'Giãn khớp mu trong khi có thai, khi đẻ và sau khi đẻ',
}, {
  'id': 'O26.8',
  'english': 'Other specified pregnancy-related conditions',
  'vietnamese': 'Các tình trạng xác định khác liên quan đến thai nghén',
}, {
  'id': 'O26.9',
  'english': 'Pregnancy-related condition, unspecified',
  'vietnamese': 'Các tình trạng liên quan đến thai nghén chưa xác định rõ',
}, {
  'id': 'O28',
  'english': 'Abnormal findings on antenatal screening of mother',
  'vietnamese': 'Các dấu hiệu bất thường của bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.0',
  'english': 'Abnormal haematological finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu huyết học bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.1',
  'english': 'Abnormal biochemical finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu hóa sinh bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.2',
  'english': 'Abnormal cytological finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu tế bào học bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.3',
  'english': 'Abnormal ultrasonic finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu siêu âm bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.4',
  'english': 'Abnormal radiological finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu X quang bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.5',
  'english': 'Abnormal chromosomal and genetic finding on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu nhiễm sắc thể và di truyền bất thường ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.8',
  'english': 'Other abnormal findings on antenatal screening of mother',
  'vietnamese': 'Dấu hiệu bất thường khác ở bà mẹ phát hiện khi khám thai',
}, {
  'id': 'O28.9',
  'english': 'Abnormal finding on antenatal screening of mother, unspecified',
  'vietnamese': 'Dấu hiệu bất thường ở bà mẹ phát hiện khi khám thai chưa xác định rõ',
}, {
  'id': 'O29',
  'english': 'Complications of anaesthesia during pregnancy',
  'vietnamese': 'Các biến chứng của gây mê trong thời gian thai nghén',
}, {
  'id': 'O29.0',
  'english': 'Pulmonary complications of anaesthesia during pregnancy',
  'vietnamese': 'Biến chứng ở phổi do gây mê khi có thai',
}, {
  'id': 'O29.1',
  'english': 'Cardiac complications of anaesthesia during pregnancy',
  'vietnamese': 'Biến chứng tim của gây mê trong khi có thai',
}, {
  'id': 'O29.2',
  'english': 'Central nervous system complications of anaesthesia during pregnancy',
  'vietnamese': 'Biến chứng hệ thống thần kinh trung ương của gây mê trong khi có thai',
}, {
  'id': 'O29.3',
  'english': 'Toxic reaction to local anaesthesia during pregnancy',
  'vietnamese': 'Phản ứng ngộ độc do gây tê tại chỗ trong khi có thai',
}, {
  'id': 'O29.4',
  'english': 'Spinal and epidural anaesthesia-induced headache during pregnancy',
  'vietnamese': 'Đau đầu do gây tê tủy sống và ngoài màng cứng trong khi có thai',
}, {
  'id': 'O29.5',
  'english': 'Other complications of spinal and epidural anaesthesia during pregnancy',
  'vietnamese': 'Biến chứng khác của gây tê tủy sống và ngoài màng cứng trong khi có thai',
}, {
  'id': 'O29.6',
  'english': 'Failed or difficult intubation during pregnancy',
  'vietnamese': 'Không đặt được hay khó đặt nội khí quản trong khi có thai',
}, {
  'id': 'O29.8',
  'english': 'Other complications of anaesthesia during pregnancy',
  'vietnamese': 'Biến chứng khác của gây mê trong khi có thai',
}, {
  'id': 'O29.9',
  'english': 'Complication of anaesthesia during pregnancy, unspecified',
  'vietnamese': 'Các biến chứng khác của gây mê trong khi có thai chưa xác định rõ',
}, {'id': 'O30', 'english': 'Multiple gestation', 'vietnamese': 'Đa thai'}, {
  'id': 'O30.0',
  'english': 'Twin pregnancy',
  'vietnamese': 'Song thai',
}, {'id': 'O30.1', 'english': 'Triplet pregnancy', 'vietnamese': 'Thai ba'}, {
  'id': 'O30.2',
  'english': 'Quadruplet pregnancy',
  'vietnamese': 'Thai tư',
}, {'id': 'O30.8', 'english': 'Other multiple gestation', 'vietnamese': 'Đa thai khác'}, {
  'id': 'O30.9',
  'english': 'Multiple gestation, unspecified',
  'vietnamese': 'Đa thai chưa xác định rõ',
}, {
  'id': 'O31',
  'english': 'Complications specific to multiple gestation',
  'vietnamese': 'Biến chứng xác định của đa thai',
}, {'id': 'O31.0', 'english': 'Papyraceous fetus', 'vietnamese': 'Thai giấy'}, {
  'id': 'O31.1',
  'english': 'Continuing pregnancy after abortion of one fetus or more',
  'vietnamese': 'Thai nghén tiếp tục tiến triển sau khi sẩy một hay nhiều thai',
}, {
  'id': 'O31.2',
  'english': 'Continuing pregnancy after intrauterine death of one fetus or more',
  'vietnamese': 'Thai nghén tiếp tục tiến triển sau khi một hay nhiều thai bị chết lưu',
}, {
  'id': 'O31.8',
  'english': 'Other complications specific to multiple gestation',
  'vietnamese': 'Biến chứng xác định khác do đa thai',
}, {
  'id': 'O32',
  'english': 'Maternal care for known or suspected malpresentation of fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi thai bất thường hay nghi ngờ bất thường',
}, {
  'id': 'O32.0',
  'english': 'Maternal care for unstable lie',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi thai không ổn định',
}, {
  'id': 'O32.1',
  'english': 'Maternal care for breech presentation',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi mông',
}, {
  'id': 'O32.2',
  'english': 'Maternal care for transverse and oblique lie',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi ngang hay ngôi chếch',
}, {
  'id': 'O32.3',
  'english': 'Maternal care for face, brow and chin presentation',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi mặt, ngôi trán và ngôi thóp trước',
}, {
  'id': 'O32.4',
  'english': 'Maternal care for high head at term',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi đầu cao lỏng khi đủ tháng',
}, {
  'id': 'O32.5',
  'english': 'Maternal care for multiple gestation with malpresentation of one fetus or more',
  'vietnamese': 'Chăm sóc bà mẹ vì đa thai, trong đó một hay nhiều thai có ngôi bất thường',
}, {
  'id': 'O32.6',
  'english': 'Maternal care for compound presentation',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi thai chờm vệ',
}, {
  'id': 'O32.8',
  'english': 'Maternal care for other malpresentation of fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi thai bất thường khác',
}, {
  'id': 'O32.9',
  'english': 'Maternal care for malpresentation of fetus, unspecified',
  'vietnamese': 'Chăm sóc bà mẹ vì ngôi thai bất thường, chưa xác định rõ',
}, {
  'id': 'O33',
  'english': 'Maternal care for known or suspected disproportion',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng hay nghi ngờ có bất tương xứng',
}, {
  'id': 'O33.0',
  'english': 'Maternal care for disproportion due to deformity of maternal pelvic bones',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do biến dạng khung xương chậu',
}, {
  'id': 'O33.1',
  'english': 'Maternal care for disproportion due to generally contracted pelvis',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do khung chậu hẹp toàn bộ',
}, {
  'id': 'O33.2',
  'english': 'Maternal care for disproportion due to inlet contraction of pelvis',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do hẹp eo trên',
}, {
  'id': 'O33.3',
  'english': 'Maternal care for disproportion due to outlet contraction of pelvis',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do hẹp eo dưới',
}, {
  'id': 'O33.4',
  'english': 'Maternal care for disproportion of mixed maternal and fetal origin',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng có nguồn gốc phối hợp cả mẹ và thai',
}, {
  'id': 'O33.5',
  'english': 'Maternal care for disproportion due to unusually large fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do thai to bất thường',
}, {
  'id': 'O33.6',
  'english': 'Maternal care for disproportion due to hydrocephalic fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do thai bị não úng thủy',
}, {
  'id': 'O33.7',
  'english': 'Maternal care for disproportion due to other fetal deformities',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do các dị dạng của thai khác',
}, {
  'id': 'O33.8',
  'english': 'Maternal care for disproportion of other origin',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng do các nguyên nhân khác',
}, {
  'id': 'O33.9',
  'english': 'Maternal care for disproportion, unspecified',
  'vietnamese': 'Chăm sóc bà mẹ vì bất tương xứng, chưa xác định rõ',
}, {
  'id': 'O34',
  'english': 'Maternal care for known or suspected abnormality of pelvic organs',
  'vietnamese': 'Chăm sóc bà mẹ vì tử cung bị dị dạng bẩm sinh',
}, {
  'id': 'O34.0',
  'english': 'Maternal care for congenital malformation of uterus',
  'vietnamese': 'Chăm sóc bà mẹ vì tử cung bị dị dạng bẩm sinh',
}, {
  'id': 'O34.1',
  'english': 'Maternal care for tumour of corpus uteri',
  'vietnamese': 'Chăm sóc bà mẹ vì khối u của thân tử cung',
}, {
  'id': 'O34.2',
  'english': 'Maternal care due to uterine scar from previous surgery',
  'vietnamese': 'Chăm sóc bà mẹ vì tử cung có sẹo mổ trước đó',
}, {
  'id': 'O34.3',
  'english': 'Maternal care for cervical incompetence',
  'vietnamese': 'Chăm sóc bà mẹ bị hở eo tử cung',
}, {
  'id': 'O34.4',
  'english': 'Maternal care for other abnormalities of cervix',
  'vietnamese': 'Chăm sóc bà mẹ có các bất thường khác ở cổ tử cung',
}, {
  'id': 'O34.5',
  'english': 'Maternal care for other abnormalities of gravid uterus',
  'vietnamese': 'Chăm sóc bà mẹ vì các bất thường khác của tử cung có thai',
}, {
  'id': 'O34.6',
  'english': 'Maternal care for abnormality of vagina',
  'vietnamese': 'Chăm sóc bà mẹ vì bất thường của âm đạo',
}, {
  'id': 'O34.7',
  'english': 'Maternal care for abnormality of vulva and perineum',
  'vietnamese': 'Chăm sóc bà mẹ vì bất thường của âm hộ và tầng sinh môn',
}, {
  'id': 'O34.8',
  'english': 'Maternal care for other abnormalities of pelvic organs',
  'vietnamese': 'Chăm sóc bà mẹ vì các bất thường khác của các tạng trong tiểu khung',
}, {
  'id': 'O34.9',
  'english': 'Maternal care for abnormality of pelvic organ, unspecified',
  'vietnamese': 'Chăm sóc bà mẹ vì các bất thường của các tạng trong tiểu khung, chưa xác định rõ',
}, {
  'id': 'O35',
  'english': 'Maternal care for known or suspected fetal abnormality and damage',
  'vietnamese': 'Chăm sóc bà mẹ chỉ các bất thường, thương tổn của thai hay nghi ngờ',
}, {
  'id': 'O35.0',
  'english': 'Maternal care for (suspected) central nervous system malformation in fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì dị dạng (hay nghi ngờ dị dạng) hệ thống thần kinh trung ương của thai',
}, {
  'id': 'O35.1',
  'english': 'Maternal care for (suspected) chromosomal abnormality in fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì bất thường (hay nghi ngờ bất thường) nhiễm sắc thể của thai',
}, {
  'id': 'O35.2',
  'english': 'Maternal care for (suspected) hereditary disease in fetus',
  'vietnamese': 'Chăm sóc bà mẹ vì bệnh di truyền của thai (hay nghi ngờ bệnh do di truyền)',
}, {
  'id': 'O35.3',
  'english': 'Maternal care for (suspected) damage to fetus from viral disease in mother',
  'vietnamese': 'Chăm sóc bà mẹ vì tổn thương thai do bệnh virus của mẹ hay nghi ngờ',
}, {
  'id': 'O35.4',
  'english': 'Maternal care for (suspected) damage to fetus from alcohol',
  'vietnamese': 'Chăm sóc bà mẹ vì tổn thương thai hay nghi ngờ tổn thương thai do rượu',
}, {
  'id': 'O35.5',
  'english': 'Maternal care for (suspected) damage to fetus by drugs',
  'vietnamese': 'Chăm sóc bà mẹ vì tổn thương thai hay nghi ngờ tổn thương thai do thuốc',
}, {
  'id': 'O35.6',
  'english': 'Maternal care for (suspected) damage to fetus by radiation',
  'vietnamese': 'Chăm sóc bà mẹ vì tổn thương thai hay nghi ngờ tổn thương thai do tia xạ',
}, {
  'id': 'O35.7',
  'english': 'Maternal care for (suspected) damage to fetus by other medical procedures',
  'vietnamese': 'Chăm sóc bà mẹ vì tổn thương thai hay nghi ngờ tổn thương thai do các thủ thuật y học khác',
}, {
  'id': 'O35.8',
  'english': 'Maternal care for other (suspected) fetal abnormality and damage',
  'vietnamese': 'Chăm sóc bà mẹ vì bất thường, tổn thương thai hay nghi ngờ khác',
}, {
  'id': 'O35.9',
  'english': 'Maternal care for (suspected) fetal abnormality and damage, unspecified',
  'vietnamese': 'Chăm sóc bà mẹ vì bất thường, tổn thương thai hay nghi ngờ tổn thương thai khác, chưa xác định rõ',
}, {
  'id': 'O36',
  'english': 'Maternal care for other known or suspected fetal problems',
  'vietnamese': 'Chăm sóc bà mẹ vì có hay nghi ngờ có vấn đề của thai',
}, {
  'id': 'O36.0',
  'english': 'Maternal care for rhesus isoimmunization',
  'vietnamese': 'Chăm sóc bà mẹ vì miễn dịch đồng loại Rh',
}, {
  'id': 'O36.1',
  'english': 'Maternal care for other isoimmunization',
  'vietnamese': 'Chăm sóc bà mẹ vì miễn dịch đồng loại khác',
}, {
  'id': 'O36.2',
  'english': 'Maternal care for hydrops fetalis',
  'vietnamese': 'Chăm sóc bà mẹ vì phù thai',
}, {
  'id': 'O36.3',
  'english': 'Maternal care for signs of fetal hypoxia',
  'vietnamese': 'Chăm sóc bà mẹ vì dấu hiệu thiếu oxy thai',
}, {
  'id': 'O36.4',
  'english': 'Maternal care for intrauterine death',
  'vietnamese': 'Chăm sóc bà mẹ vì thai chết lưu trong tử cung',
}, {
  'id': 'O36.5',
  'english': 'Maternal care for poor fetal growth',
  'vietnamese': 'Chăm sóc bà mẹ vì thai kém phát triển',
}, {
  'id': 'O36.6',
  'english': 'Maternal care for excessive fetal growth',
  'vietnamese': 'Chăm sóc bà mẹ vì thai phát triển quá mức',
}, {
  'id': 'O36.7',
  'english': 'Maternal care for viable fetus in abdominal pregnancy',
  'vietnamese': 'Chăm sóc bà mẹ vì thai có thể sống được trong chửa trong ổ bụng',
}, {
  'id': 'O36.8',
  'english': 'Maternal care for other specified fetal problems',
  'vietnamese': 'Chăm sóc bà mẹ vì các vấn đề xác định khác của thai',
}, {
  'id': 'O36.9',
  'english': 'Maternal care for fetal problem, unspecified',
  'vietnamese': 'Chăm sóc bà mẹ vì các vấn đề của thai chưa xác định rõ',
}, {'id': 'O40', 'english': 'Polihydramnios', 'vietnamese': 'Đa ối'}, {
  'id': 'O41',
  'english': 'Other disorders of amniotic fluid and membranes',
  'vietnamese': 'Các rối loạn khác của nước ối và màng ối',
}, {'id': 'O41.0', 'english': 'Oligohydramnios', 'vietnamese': 'Thiểu ối'}, {
  'id': 'O41.1',
  'english': 'Infection of amniotic sac and membranes',
  'vietnamese': 'Nhiễm khuẩn ối và màng ối',
}, {
  'id': 'O41.8',
  'english': 'Other specified disorders of amniotic fluid and membranes',
  'vietnamese': 'Các rối loạn xác định khác của nước ối và màng ối',
}, {
  'id': 'O41.9',
  'english': 'Disorder of amniotic fluid and membranes, unspecified',
  'vietnamese': 'Rối loạn của màng ối và nước ối chưa xác định rõ',
}, {'id': 'O42', 'english': 'Premature rupture of membranes', 'vietnamese': 'Vỡ ối sớm'}, {
  'id': 'O42.0',
  'english': 'Premature rupture of membranes, onset of labour within 24 hours',
  'vietnamese': 'Vỡ ối sớm, xuất hiện chuyển dạ trong vòng 24 giờ',
}, {
  'id': 'O42.1',
  'english': 'Premature rupture of membranes, onset of labour after 24 hours',
  'vietnamese': 'Vỡ ối sớm, xuất hiện chuyển dạ sau 24 giờ',
}, {
  'id': 'O42.2',
  'english': 'Premature rupture of membranes, labour delayed by therapy',
  'vietnamese': 'Vỡ ối sớm, có điều trị để làm chậm chuyển dạ',
}, {
  'id': 'O42.9',
  'english': 'Premature rupture of membranes, unspecified',
  'vietnamese': 'Vỡ ối sớm, chưa xác định rõ',
}, {'id': 'O43', 'english': 'Placental disorders', 'vietnamese': 'Bệnh lý bánh rau'}, {
  'id': 'O43.0',
  'english': 'Placental transfusion syndromes',
  'vietnamese': 'Hội chứng truyền máu qua bánh rau',
}, {'id': 'O43.1', 'english': 'Malformation of placenta', 'vietnamese': 'Dị dạng bánh rau'}, {
  'id': 'O43.2',
  'english': 'Morbidly adherent placenta',
  'vietnamese': 'Rau cài răng lược',
}, {
  'id': 'O43.8',
  'english': 'Other placental disorders',
  'vietnamese': 'Bệnh lý khác của bánh rau',
}, {
  'id': 'O43.9',
  'english': 'Placental disorder, unspecified',
  'vietnamese': 'Bệnh lý bánh rau chưa xác định rõ',
}, {'id': 'O44', 'english': 'Placenta praevia', 'vietnamese': 'Rau tiền đạo'}, {
  'id': 'O44.0',
  'english': 'Placenta praevia specified as without haemorrhage',
  'vietnamese': 'Rau tiền đạo không có chảy máu',
}, {
  'id': 'O44.1',
  'english': 'Placenta praevia with haemorrhage',
  'vietnamese': 'Rau tiền đạo có chảy máu',
}, {
  'id': 'O45',
  'english': 'Premature separation of placenta [abruptio placentae]',
  'vietnamese': 'Rau bong non',
}, {
  'id': 'O45.0',
  'english': 'Premature separation of placenta with coagulation defect',
  'vietnamese': 'Rau bong non có thiếu hụt yếu tố đông máu',
}, {
  'id': 'O45.8',
  'english': 'Other premature separation of placenta',
  'vietnamese': 'Rau bong non khác',
}, {
  'id': 'O45.9',
  'english': 'Premature separation of placenta, unspecified',
  'vietnamese': 'Rau bong non khác, chưa rõ',
}, {
  'id': 'O46',
  'english': 'Antepartum haemorrhage, not elsewhere classified',
  'vietnamese': 'Chảy máu trước đẻ, chưa được phân loại',
}, {
  'id': 'O46.0',
  'english': 'Antepartum haemorrhage with coagulation defect',
  'vietnamese': 'Chảy máu trước đẻ do thiếu hụt yếu tố đông máu',
}, {
  'id': 'O46.8',
  'english': 'Other antepartum haemorrhage',
  'vietnamese': 'Chảy máu khác trước đẻ',
}, {
  'id': 'O46.9',
  'english': 'Antepartum haemorrhage, unspecified',
  'vietnamese': 'Chảy máu trước đẻ, chưa xác định rõ',
}, {'id': 'O47', 'english': 'False labour', 'vietnamese': 'Chuyển dạ giả'}, {
  'id': 'O47.0',
  'english': 'False labour before 37 completed weeks of gestation',
  'vietnamese': 'Chuyển dạ giả trước tuần thứ 37',
}, {
  'id': 'O47.1',
  'english': 'False labour at or after 37 completed weeks of gestation',
  'vietnamese': 'Chuyển dạ giả trong hay sau tuần thứ 37',
}, {
  'id': 'O47.9',
  'english': 'False labour, unspecified',
  'vietnamese': 'Chuyển dạ giả, chưa xác định rõ',
}, {'id': 'O48', 'english': 'Prolonged pregnancy', 'vietnamese': 'Thai quá ngày sinh'}, {
  'id': 'O60',
  'english': 'Preterm labour',
  'vietnamese': 'Chuyển dạ sớm và đẻ',
}, {
  'id': 'O60.0',
  'english': 'Preterm labour without delivery',
  'vietnamese': 'Chuyển dạ sớm không đẻ',
}, {
  'id': 'O60.1',
  'english': 'Preterm labour with preterm delivery',
  'vietnamese': 'Chuyển dạ sớm và đẻ sớm',
}, {
  'id': 'O60.2',
  'english': 'Preterm labour with term delivery',
  'vietnamese': 'Chuyển dạ sớm và đẻ đúng kỳ',
}, {
  'id': 'O60.3',
  'english': 'Preterm delivery without spontaneous labour',
  'vietnamese': 'Đẻ sớm không có chuyển dạ tự nhiên',
}, {
  'id': 'O61',
  'english': 'Failed induction of labour',
  'vietnamese': 'Khởi phát chuyển dạ thất bại',
}, {
  'id': 'O61.0',
  'english': 'Failed medical induction of labour',
  'vietnamese': 'Gây chuyển dạ bằng thuốc thất bại',
}, {
  'id': 'O61.1',
  'english': 'Failed instrumental induction of labour',
  'vietnamese': 'Gây chuyển dạ bằng dụng cụ thất bại',
}, {
  'id': 'O61.8',
  'english': 'Other failed induction of labour',
  'vietnamese': 'Gây chuyển dạ bị thất bại khác',
}, {
  'id': 'O61.9',
  'english': 'Failed induction of labour, unspecified',
  'vietnamese': 'Gây chuyển dạ bị thất bại, chưa xác định rõ',
}, {
  'id': 'O62',
  'english': 'Abnormalities of forces of labour',
  'vietnamese': 'Bất thường về động lực chuyển dạ',
}, {
  'id': 'O62.0',
  'english': 'Primary inadequate contractions',
  'vietnamese': 'Cơn co tử cung yếu nguyên phát',
}, {
  'id': 'O62.1',
  'english': 'Secondary uterine inertia',
  'vietnamese': 'Cơn co tử cung yếu thứ phát',
}, {
  'id': 'O62.2',
  'english': 'Other uterine inertia',
  'vietnamese': 'Thưa yếu khác của cơn co tử cung',
}, {'id': 'O62.3', 'english': 'Precipitate labour', 'vietnamese': 'Chuyển dạ nhanh'}, {
  'id': 'O62.4',
  'english': 'Hypertonic, incoordinate, and prolonged uterine contractions',
  'vietnamese': 'Cơn co tử cung tăng trương lực, không đồng bộ và kéo dài',
}, {
  'id': 'O62.8',
  'english': 'Other abnormalities of forces of labour',
  'vietnamese': 'Bất thường khác của động lực chuyển dạ',
}, {
  'id': 'O62.9',
  'english': 'Abnormality of forces of labour, unspecified',
  'vietnamese': 'Bất thường chưa xác định rõ của động lực chuyển dạ',
}, {'id': 'O63', 'english': 'Long labour', 'vietnamese': 'Chuyển dạ kéo dài'}, {
  'id': 'O63.0',
  'english': 'Prolonged first stage (of labour)',
  'vietnamese': 'Giai đoạn đầu của chuyển dạ kéo dài',
}, {
  'id': 'O63.1',
  'english': 'Prolonged second stage (of labour)',
  'vietnamese': 'Giai đoạn thứ hai của chuyển dạ kéo dài',
}, {
  'id': 'O63.2',
  'english': 'Delayed delivery of second twin, triplet, etc.',
  'vietnamese': 'Chậm đẻ thai thứ hai, thứ ba …',
}, {
  'id': 'O63.9',
  'english': 'Long labour, unspecified',
  'vietnamese': 'Chuyển dạ kéo dài, chưa xác định rõ',
}, {
  'id': 'O64',
  'english': 'Obstructed labour due to malposition and malpresentation of fetus',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi và thế của thai bất thường',
}, {
  'id': 'O64.0',
  'english': 'Obstructed labour due to incomplete rotation of fetal head',
  'vietnamese': 'Chuyển dạ đình trệ do đầu thai quay không hoàn toàn',
}, {
  'id': 'O64.1',
  'english': 'Obstructed labour due to breech presentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi mông',
}, {
  'id': 'O64.2',
  'english': 'Obstructed labour due to face presentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi mặt',
}, {
  'id': 'O64.3',
  'english': 'Obstructed labour due to brow presentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi trán',
}, {
  'id': 'O64.4',
  'english': 'Obstructed labour due to shoulder presentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi vai',
}, {
  'id': 'O64.5',
  'english': 'Obstructed labour due to compound presentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi thai chờm vệ',
}, {
  'id': 'O64.8',
  'english': 'Obstructed labour due to other malposition and malpresentation',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi và thế bất thường khác',
}, {
  'id': 'O64.9',
  'english': 'Obstructed labour due to malposition and malpresentation, unspecified',
  'vietnamese': 'Chuyển dạ đình trệ do ngôi và thế bất thường, chưa xác định rõ',
}, {
  'id': 'O65',
  'english': 'Obstructed labour due to maternal pelvic abnormality',
  'vietnamese': 'Chuyển dạ đình trệ do khung chậu bất thường',
}, {
  'id': 'O65.0',
  'english': 'Obstructed labour due to deformed pelvis',
  'vietnamese': 'Chuyển dạ đình trệ do khung chậu biến dạng',
}, {
  'id': 'O65.1',
  'english': 'Obstructed labour due to generally contracted pelvis',
  'vietnamese': 'Chuyển dạ đình trệ do khung chậu hẹp toàn diện',
}, {
  'id': 'O65.2',
  'english': 'Obstructed labour due to pelvic inlet contraction',
  'vietnamese': 'Chuyển dạ đình trệ do hẹp eo trên',
}, {
  'id': 'O65.3',
  'english': 'Obstructed labour due to pelvic outlet and mid-cavity contraction',
  'vietnamese': 'Chuyển dạ đình trệ do hẹp eo dưới và eo giữa',
}, {
  'id': 'O65.4',
  'english': 'Obstructed labour due to fetopelvic disproportion, unspecified',
  'vietnamese': 'Chuyển dạ đình trệ do bất tương xứng thai - khung chậu, chưa xác định rõ',
}, {
  'id': 'O65.5',
  'english': 'Obstructed labour due to abnormality of maternal pelvic organs',
  'vietnamese': 'Chuyển dạ đình trệ do bất thường các tạng trong tiểu khung của mẹ',
}, {
  'id': 'O65.8',
  'english': 'Obstructed labour due to other maternal pelvic abnormalities',
  'vietnamese': 'Chuyển dạ đình trệ do bất thường khác của khung chậu mẹ',
}, {
  'id': 'O65.9',
  'english': 'Obstructed labour due to maternal pelvic abnormality, unspecified',
  'vietnamese': 'Chuyển dạ đình trệ do bất thường khung chậu mẹ chưa xác định rõ',
}, {'id': 'O66', 'english': 'Other obstructed labour', 'vietnamese': 'Chuyển dạ đình trệ khác'}, {
  'id': 'O66.0',
  'english': 'Obstructed labour due to shoulder dystocia',
  'vietnamese': 'Chuyển dạ đình trệ do đẻ khó do vai',
}, {
  'id': 'O66.1',
  'english': 'Obstructed labour due to locked twins',
  'vietnamese': 'Chuyển dạ đình trệ do sinh đôi cản trở nhau',
}, {
  'id': 'O66.2',
  'english': 'Obstructed labour due to unusually large fetus',
  'vietnamese': 'Chuyển dạ đình trệ do thai to bất thường',
}, {
  'id': 'O66.3',
  'english': 'Obstructed labour due to other abnormalities of fetus',
  'vietnamese': 'Chuyển dạ đình trệ do bất thường khác của thai',
}, {
  'id': 'O66.4',
  'english': 'Failed trial of labour, unspecified',
  'vietnamese': 'Nghiệm pháp chuyển dạ thất bại, chưa xác định rõ',
}, {
  'id': 'O66.5',
  'english': 'Failed application of vacuum extractor and forceps, unspecified',
  'vietnamese': 'Đặt forcep hay giác hút thất bại, chưa xác định rõ',
}, {
  'id': 'O66.8',
  'english': 'Other specified obstructed labour',
  'vietnamese': 'Chuyển dạ đình trệ chỉ rõ khác',
}, {
  'id': 'O66.9',
  'english': 'Obstructed labour, unspecified',
  'vietnamese': 'Chuyển dạ đình trệ, chưa xác định rõ',
}, {
  'id': 'O67',
  'english': 'Labour and delivery complicated by intrapartum haemorrhage, not elsewhere classified',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng chảy máu trong đẻ chưa được xếp loại ở phần khác',
}, {
  'id': 'O67.0',
  'english': 'Intrapartum haemorrhage with coagulation defect',
  'vietnamese': 'Chảy máu trong đẻ do thiếu hụt yếu tố đông máu',
}, {
  'id': 'O67.8',
  'english': 'Other intrapartum haemorrhage',
  'vietnamese': 'Chảy máu trong đẻ khác',
}, {
  'id': 'O67.9',
  'english': 'Intrapartum haemorrhage, unspecified',
  'vietnamese': 'Chảy máu trong đẻ, chưa xác định rõ',
}, {
  'id': 'O68',
  'english': 'Labour and delivery complicated by fetal stress [distress]',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng suy thai',
}, {
  'id': 'O68.0',
  'english': 'Labour and delivery complicated by fetal heart rate anomaly',
  'vietnamese': 'Chuyển dạ và đẻ có nhịp tim thai bất thường',
}, {
  'id': 'O68.1',
  'english': 'Labour and delivery complicated by meconium in amniotic fluid',
  'vietnamese': 'Chuyển dạ và đẻ có nước ối lẫn phân su',
}, {
  'id': 'O68.2',
  'english': 'Labour and delivery complicated by fetal heart rate anomaly with meconium in amniotic fluid',
  'vietnamese': 'Chuyển dạ và đẻ có nhịp tim thai bất thường và nước ối lẫn phân su',
}, {
  'id': 'O68.3',
  'english': 'Labour and delivery complicated by biochemical evidence of fetal stress',
  'vietnamese': 'Chuyển dạ và đẻ có các dấu hiệu sinh hóa của suy thai',
}, {
  'id': 'O68.8',
  'english': 'Labour and delivery complicated by other evidence of fetal stress',
  'vietnamese': 'Chuyển dạ và đẻ có các dấu hiệu khác của suy thai',
}, {
  'id': 'O68.9',
  'english': 'Labour and delivery complicated by fetal stress, unspecified',
  'vietnamese': 'Chuyển dạ và đẻ có suy thai, chưa xác định rõ',
}, {
  'id': 'O69',
  'english': 'Labour and delivery complicated by umbilical cord complications',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng dây rốn',
}, {
  'id': 'O69.0',
  'english': 'Labour and delivery complicated by prolapse of cord',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng sa dây rốn',
}, {
  'id': 'O69.1',
  'english': 'Labour and delivery complicated by cord around neck, with compression',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng dây rốn quấn quanh cổ bị chèn ép',
}, {
  'id': 'O69.2',
  'english': 'Labour and delivery complicated by other cord entanglement',
  'vietnamese': 'Chuyển dạ và đẻ có vướng mắc khác của dây rốn với chèn ép',
}, {
  'id': 'O69.3',
  'english': 'Labour and delivery complicated by short cord',
  'vietnamese': 'Chuyển dạ và đẻ có dây rốn ngắn',
}, {
  'id': 'O69.4',
  'english': 'Labour and delivery complicated by vasa praevia',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng do mạch máu tiền đạo',
}, {
  'id': 'O69.5',
  'english': 'Labour and delivery complicated by vascular lesion of cord',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng tổn thương mạch',
}, {
  'id': 'O69.8',
  'english': 'Labour and delivery complicated by other cord complications',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng khác của dây rốn',
}, {
  'id': 'O69.9',
  'english': 'Labour and delivery complicated by cord complication, unspecified',
  'vietnamese': 'Chuyển dạ và đẻ có biến chứng khác của dây rốn, chưa xác định rõ',
}, {
  'id': 'O70',
  'english': 'Perineal laceration during delivery',
  'vietnamese': 'Rách tầng sinh môn trong đẻ',
}, {
  'id': 'O70.0',
  'english': 'First degree perineal laceration during delivery',
  'vietnamese': 'Rách tầng sinh môn độ I trong đẻ',
}, {
  'id': 'O70.1',
  'english': 'Second degree perineal laceration during delivery',
  'vietnamese': 'Rách tầng sinh môn độ II trong đẻ',
}, {
  'id': 'O70.2',
  'english': 'Third degree perineal laceration during delivery',
  'vietnamese': 'Rách tầng sinh môn độ III trong đẻ',
}, {
  'id': 'O70.3',
  'english': 'Fourth degree perineal laceration during delivery',
  'vietnamese': 'Rách tầng sinh môn độ IV trong đẻ',
}, {
  'id': 'O70.9',
  'english': 'Perineal laceration during delivery, unspecified',
  'vietnamese': 'Rách tầng sinh môn chưa xác định rõ',
}, {
  'id': 'O71',
  'english': 'Other obstetric trauma',
  'vietnamese': 'Chấn thương sản khoa khác',
}, {
  'id': 'O71.0',
  'english': 'Rupture of uterus before onset of labour',
  'vietnamese': 'Vỡ tử cung trước chuyển dạ',
}, {
  'id': 'O71.1',
  'english': 'Rupture of uterus during labour',
  'vietnamese': 'Vỡ tử cung trong chuyển dạ',
}, {
  'id': 'O71.2',
  'english': 'Postpartum inversion of uterus',
  'vietnamese': 'Lộn tử cung sau đẻ',
}, {
  'id': 'O71.3',
  'english': 'Obstetric laceration of cervix',
  'vietnamese': 'Rách cổ tử cung sản khoa',
}, {
  'id': 'O71.4',
  'english': 'Obstetric high vaginal laceration alone',
  'vietnamese': 'Rách âm đạo cao đơn thuần do sản khoa',
}, {
  'id': 'O71.5',
  'english': 'Other obstetric injury to pelvic organs',
  'vietnamese': 'Thương tổn các tạng tiểu khung khác do sản khoa',
}, {
  'id': 'O71.6',
  'english': 'Obstetric damage to pelvic joints and ligaments',
  'vietnamese': 'Tổn thương khớp vùng chậu và dây chằng do sản khoa',
}, {
  'id': 'O71.7',
  'english': 'Obstetric haematoma of pelvis',
  'vietnamese': 'Máu tụ tiểu khung do sản khoa',
}, {
  'id': 'O71.8',
  'english': 'Other specified obstetric trauma',
  'vietnamese': 'Chấn thương sản khoa chỉ rõ khác',
}, {
  'id': 'O71.9',
  'english': 'Obstetric trauma, unspecified',
  'vietnamese': 'Chấn thương sản khoa, chưa xác định rõ',
}, {'id': 'O72', 'english': 'Postpartum haemorrhage', 'vietnamese': 'Chảy máu sau đẻ'}, {
  'id': 'O72.0',
  'english': 'Third-stage haemorrhage',
  'vietnamese': 'Chảy máu thời kỳ sổ rau',
}, {
  'id': 'O72.1',
  'english': 'Other immediate postpartum haemorrhage',
  'vietnamese': 'Chảy máu ngay sau lúc đẻ khác',
}, {
  'id': 'O72.2',
  'english': 'Delayed and secondary postpartum haemorrhage',
  'vietnamese': 'Chảy máu muộn và thứ phát sau đẻ',
}, {
  'id': 'O72.3',
  'english': 'Postpartum coagulation defects',
  'vietnamese': 'Thiếu hụt đông máu sau đẻ',
}, {
  'id': 'O73',
  'english': 'Retained placenta and membranes, without haemorrhage',
  'vietnamese': 'Sót rau và màng rau không có chảy máu',
}, {
  'id': 'O73.0',
  'english': 'Retained placenta without haemorrhage',
  'vietnamese': 'Sót rau không có chảy máu',
}, {
  'id': 'O73.1',
  'english': 'Retained portions of placenta and membranes, without haemorrhage',
  'vietnamese': 'Sót phần bánh rau và màng rau, không có chảy máu',
}, {
  'id': 'O74',
  'english': 'Complications of anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng của gây mê trong chuyển dạ và trong đẻ',
}, {
  'id': 'O74.0',
  'english': 'Aspiration pneumonitis due to anaesthesia during labour and delivery',
  'vietnamese': 'Viêm phổi do hít vì gây mê trong chuyển dạ và trong đẻ',
}, {
  'id': 'O74.1',
  'english': 'Other pulmonary complications of anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng khác của phổi do gây mê trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.2',
  'english': 'Cardiac complications of anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng tim của gây mê trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.3',
  'english': 'Central nervous system complications of anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng hệ thống thần kinh trung ương của gây mê trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.4',
  'english': 'Toxic reaction to local anaesthesia during labour and delivery',
  'vietnamese': 'Phản ứng độc của gây tê tại chỗ trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.5',
  'english': 'Spinal and epidural anaesthesia-induced headache during labour and delivery',
  'vietnamese': 'Đau đầu do gây tê tủy sống và ngoài màng cứng trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.6',
  'english': 'Other complications of spinal and epidural anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng khác của gây tê tủy sống và gây tê ngoài màng cứng trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.7',
  'english': 'Failed or difficult intubation during labour and delivery',
  'vietnamese': 'Không đặt được hay khó đặt nội khí quản trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.8',
  'english': 'Other complications of anaesthesia during labour and delivery',
  'vietnamese': 'Biến chứng khác của gây mê trong chuyển dạ, trong đẻ',
}, {
  'id': 'O74.9',
  'english': 'Complication of anaesthesia during labour and delivery, unspecified',
  'vietnamese': 'Biến chứng của gây mê trong chuyển dạ, trong đẻ, chưa xác định rõ',
}, {
  'id': 'O75',
  'english': 'Other complications of labour and delivery, not elsewhere classified',
  'vietnamese': 'Biến chứng khác của chuyển dạ không xếp loại ở chỗ khác',
}, {
  'id': 'O75.0',
  'english': 'Maternal distress during labour and delivery',
  'vietnamese': 'Tình trạng nguy cấp của mẹ trong chuyển dạ, trong đẻ',
}, {
  'id': 'O75.1',
  'english': 'Shock during or following labour and delivery',
  'vietnamese': 'choáng trong hay sau chuyển dạ và đẻ',
}, {
  'id': 'O75.2',
  'english': 'Pyrexia during labour, not elsewhere classified',
  'vietnamese': 'Sốt trong chuyển dạ, không xếp loại ở chỗ khác',
}, {
  'id': 'O75.3',
  'english': 'Other infection during labour',
  'vietnamese': 'Nhiễm khuẩn khác trong chuyển dạ',
}, {
  'id': 'O75.4',
  'english': 'Other complications of obstetric surgery and procedures',
  'vietnamese': 'Biến chứng khác của phẩu thuật và thủ thuật sản khoa',
}, {
  'id': 'O75.5',
  'english': 'Delayed delivery after artificial rupture of membranes',
  'vietnamese': 'Đẻ muộn sau khi bấm ối',
}, {
  'id': 'O75.6',
  'english': 'Delayed delivery after spontaneous or unspecified rupture of membranes',
  'vietnamese': 'Đẻ muộn sau khi vỡ ối tự nhiên hay chưa xác định rõ',
}, {
  'id': 'O75.7',
  'english': 'Vaginal delivery following previous caesarean section',
  'vietnamese': 'Đẻ đường dưới sau mổ lấy thai cũ',
}, {
  'id': 'O75.8',
  'english': 'Other specified complications of labour and delivery',
  'vietnamese': 'Biến chứng chỉ rõ khác của chuyển dạ và đẻ',
}, {
  'id': 'O75.9',
  'english': 'Complication of labour and delivery, unspecified',
  'vietnamese': 'Biến chứng của chuyển dạ và đẻ chưa xác định rõ',
}, {'id': 'O80', 'english': 'Single spontaneous delivery', 'vietnamese': 'Đẻ thường một thai'}, {
  'id': 'O80.0',
  'english': 'Spontaneous vertex delivery',
  'vietnamese': 'Đẻ thường ngôi đầu',
}, {
  'id': 'O80.1',
  'english': 'Spontaneous breech delivery',
  'vietnamese': 'Đẻ thường ngôi mông',
}, {
  'id': 'O80.8',
  'english': 'Other single spontaneous delivery',
  'vietnamese': 'Đẻ thường một thai khác',
}, {
  'id': 'O80.9',
  'english': 'Single spontaneous delivery, unspecified',
  'vietnamese': 'Đẻ thường một thai chưa xác định rõ',
}, {
  'id': 'O81',
  'english': 'Single delivery by forceps and vacuum extractor',
  'vietnamese': 'Đẻ một thai bằng forcep hay giác hút',
}, {'id': 'O81.0', 'english': 'Low forceps delivery', 'vietnamese': 'Đặt forcep thấp'}, {
  'id': 'O81.1',
  'english': 'Mid-cavity forceps delivery',
  'vietnamese': 'Đặt forcep trung bình',
}, {
  'id': 'O81.2',
  'english': 'Mid-cavity forceps with rotation',
  'vietnamese': 'Đặt forcep trung bình có quay',
}, {
  'id': 'O81.3',
  'english': 'Other and unspecified forceps delivery',
  'vietnamese': 'Đặt forcep khác chưa xác định rõ',
}, {'id': 'O81.4', 'english': 'Vacuum extractor delivery', 'vietnamese': 'Đặt giác hút'}, {
  'id': 'O81.5',
  'english': 'Delivery by combination of forceps and vacuum extractor',
  'vietnamese': 'Đặt phối hợp cả forcep và giác hút',
}, {
  'id': 'O82',
  'english': 'Single delivery by caesarean section',
  'vietnamese': 'Mổ lấy thai cho một thai',
}, {
  'id': 'O82.0',
  'english': 'Delivery by elective caesarean section',
  'vietnamese': 'Mổ lấy thai chủ động',
}, {
  'id': 'O82.1',
  'english': 'Delivery by emergency caesarean section',
  'vietnamese': 'Mổ lấy thai cấp cứu',
}, {
  'id': 'O82.2',
  'english': 'Delivery by caesarean hysterectomy',
  'vietnamese': 'Mổ lấy thai, tiếp theo cắt tử cung',
}, {
  'id': 'O82.8',
  'english': 'Other single delivery by caesarean section',
  'vietnamese': 'Mổ lấy thai khác cho một thai',
}, {
  'id': 'O82.9',
  'english': 'Delivery by caesarean section, unspecified',
  'vietnamese': 'Mổ lấy thai chưa xác định rõ',
}, {
  'id': 'O83',
  'english': 'Other assisted single delivery',
  'vietnamese': 'Đẻ một thai với thủ thuật khác',
}, {'id': 'O83.0', 'english': 'Breech extraction', 'vietnamese': 'Kéo thai trong ngôi mông'}, {
  'id': 'O83.1',
  'english': 'Other assisted breech delivery',
  'vietnamese': 'Thủ thuật khác trong ngôi mông',
}, {
  'id': 'O83.2',
  'english': 'Other manipulation-assisted delivery',
  'vietnamese': 'Đẻ nhờ thủ thuật khác bằng tay',
}, {
  'id': 'O83.3',
  'english': 'Delivery of viable fetus in abdominal pregnancy',
  'vietnamese': 'Đẻ thai sống trong chửa trong ổ bụng',
}, {
  'id': 'O83.4',
  'english': 'Destructive operation for delivery',
  'vietnamese': 'Các thủ thuật hủy thai',
}, {
  'id': 'O83.8',
  'english': 'Other specified assisted single delivery',
  'vietnamese': 'Các thủ thuật chỉ rõ khác trong đẻ một thai',
}, {
  'id': 'O83.9',
  'english': 'Assisted single delivery, unspecified',
  'vietnamese': 'Các thủ thuật chưa xác định rõ khác trong đẻ một thai',
}, {'id': 'O84', 'english': 'Multiple delivery', 'vietnamese': 'Đẻ nhiều thai'}, {
  'id': 'O84.0',
  'english': 'Multiple delivery, all spontaneous',
  'vietnamese': 'Đẻ nhiều thai hoàn toàn tự nhiên',
}, {
  'id': 'O84.1',
  'english': 'Multiple delivery, all by forceps and vacuum extractor',
  'vietnamese': 'Đẻ nhiều thai, tất cả bằng forcep hay giác hút',
}, {
  'id': 'O84.2',
  'english': 'Multiple delivery, all by caesarean section',
  'vietnamese': 'Đẻ nhiều thai, tất cả bằng mổ lấy thai',
}, {'id': 'O84.8', 'english': 'Other multiple delivery', 'vietnamese': 'Đẻ nhiều thai khác'}, {
  'id': 'O84.9',
  'english': 'Multiple delivery, unspecified',
  'vietnamese': 'Đẻ nhiều thai chưa xác định rõ',
}, {'id': 'O85', 'english': 'Puerperal pepsis', 'vietnamese': 'Nhiễm khuẩn sau đẻ'}, {
  'id': 'O86',
  'english': 'Other puerperal infections',
  'vietnamese': 'Nhiễm khuẩn sau đẻ khác',
}, {
  'id': 'O86.0',
  'english': 'Infection of obstetric surgical wound',
  'vietnamese': 'Nhiễm khuẩn vết thương do phẫu thuật sản khoa',
}, {
  'id': 'O86.1',
  'english': 'Other infection of genital tract following delivery',
  'vietnamese': 'Nhiễm khuẩn đường sinh dục khác sau đẻ',
}, {
  'id': 'O86.2',
  'english': 'Urinary tract infection following delivery',
  'vietnamese': 'Nhiễm khuẩn đường tiết niệu sau đẻ',
}, {
  'id': 'O86.3',
  'english': 'Other genitourinary tract infections following delivery',
  'vietnamese': 'Nhiễm khuẩn khác của đường tiết niệu sinh dục sau đẻ',
}, {
  'id': 'O86.4',
  'english': 'Pyrexia of unknown origin following delivery',
  'vietnamese': 'Sốt không rõ nguyên nhân sau đẻ',
}, {
  'id': 'O86.8',
  'english': 'Other specified puerperal infections',
  'vietnamese': 'Nhiễm khuẩn sau đẻ xác định khác',
}, {
  'id': 'O87',
  'english': 'Venous complications in the puerperium',
  'vietnamese': 'Biến chứng tĩnh mạch và trĩ trong thời gian sau đẻ',
}, {
  'id': 'O87.0',
  'english': 'Superficial thrombophlebitis in the puerperium',
  'vietnamese': 'Viêm tắc tĩnh mạch nông sau đẻ',
}, {
  'id': 'O87.1',
  'english': 'Deep phlebothrombosis in the puerperium',
  'vietnamese': 'Viêm tắc tĩnh mạch sâu sau đẻ',
}, {'id': 'O87.2', 'english': 'Haemorrhoids in the puerperium', 'vietnamese': 'Trĩ sau đẻ'}, {
  'id': 'O87.3',
  'english': 'Cerebral venous thrombosis in the puerperium',
  'vietnamese': 'Huyết khối tĩnh mạch não sau đẻ',
}, {
  'id': 'O87.8',
  'english': 'Other venous complications in the puerperium',
  'vietnamese': 'Biến chứng tĩnh mạch khác sau đẻ',
}, {
  'id': 'O87.9',
  'english': 'Venous complication in the puerperium, unspecified',
  'vietnamese': 'Biến chứng tĩnh mạch sau đẻ chưa xác định rõ',
}, {'id': 'O88', 'english': 'Obstetric embolism', 'vietnamese': 'Tắc mạch sản khoa'}, {
  'id': 'O88.0',
  'english': 'Obstetric air embolism',
  'vietnamese': 'Tắc mạch sản khoa do khí',
}, {'id': 'O88.1', 'english': 'Amniotic fluid embolism', 'vietnamese': 'Tắc mạch ối'}, {
  'id': 'O88.2',
  'english': 'Obstetric blood-clot embolism',
  'vietnamese': 'Tắc mạch sản khoa do cục máu đông',
}, {
  'id': 'O88.3',
  'english': 'Obstetric pyaemic and septic embolism',
  'vietnamese': 'Tắc mạch do mủ huyết và nhiễm khuẩn sản khoa',
}, {
  'id': 'O88.8',
  'english': 'Other obstetric embolism',
  'vietnamese': 'Tắc mạch sản khoa khác',
}, {
  'id': 'O89',
  'english': 'Complications of anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng của gây mê trong thời kỳ sau đẻ',
}, {
  'id': 'O89.0',
  'english': 'Pulmonary complications of anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng phổi của gây mê trong thời kỳ sau đẻ',
}, {
  'id': 'O89.1',
  'english': 'Cardiac complications of anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng tim của gây mê trong thời kỳ sau đẻ',
}, {
  'id': 'O89.2',
  'english': 'Central nervous system complications of anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng của hệ thống thần kinh trung ương do gây mê sau đẻ',
}, {
  'id': 'O89.3',
  'english': 'Toxic reaction to local anaesthesia during the puerperium',
  'vietnamese': 'Phản ứng độc do gây tê tại chỗ trong thời kỳ sau đẻ',
}, {
  'id': 'O89.4',
  'english': 'Spinal and epidural anaesthesia-induced headache during the puerperium',
  'vietnamese': 'Đau đầu do gây tê tủy sống và ngoài màng cứng trong thời kỳ sau đẻ',
}, {
  'id': 'O89.5',
  'english': 'Other complications of spinal and epidural anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng khác của gây tê tủy sống và ngoài màng cứng trong thời kỳ sau đẻ',
}, {
  'id': 'O89.6',
  'english': 'Failed or difficult intubation during the puerperium',
  'vietnamese': 'Đặt nội khí quản khó hay thất bại trong thời kỳ sau đẻ',
}, {
  'id': 'O89.8',
  'english': 'Other complications of anaesthesia during the puerperium',
  'vietnamese': 'Biến chứng khác của gây mê trong thời kỳ sau đẻ',
}, {
  'id': 'O89.9',
  'english': 'Complication of anaesthesia during the puerperium, unspecified',
  'vietnamese': 'Biến chứng, của gây mê trong thời kỳ sau đẻ',
}, {
  'id': 'O90',
  'english': 'Complications of the puerperium, not elsewhere classified',
  'vietnamese': 'Biến chứng sau đẻ chưa được xếp loại',
}, {
  'id': 'O90.0',
  'english': 'Disruption of caesarean section wound',
  'vietnamese': 'Toác vết mổ lấy thai',
}, {
  'id': 'O90.1',
  'english': 'Disruption of perineal obstetric wound',
  'vietnamese': 'Toác vết khâu tầng sinh môn',
}, {
  'id': 'O90.2',
  'english': 'Haematoma of obstetric wound',
  'vietnamese': 'Máu tụ vết khâu sản khoa',
}, {
  'id': 'O90.3',
  'english': 'Cardiomyopathy in the puerperium',
  'vietnamese': 'Bệnh lý cơ tim trong thời kỳ hậu sản',
}, {
  'id': 'O90.4',
  'english': 'Postpartum acute renal failure',
  'vietnamese': 'Suy thận cấp tính sau đẻ',
}, {'id': 'O90.5', 'english': 'Postpartum thyroiditis', 'vietnamese': 'Viêm tuyến giáp sau đẻ'}, {
  'id': 'O90.8',
  'english': 'Other complications of the puerperium, not elsewhere classified',
  'vietnamese': 'Biến chứng khác sau đẻ chưa được xếp loại ở phần khác',
}, {
  'id': 'O90.9',
  'english': 'Complication of the puerperium, unspecified',
  'vietnamese': 'Biến chứng sau đẻ chưa xác định rõ',
}, {
  'id': 'O91',
  'english': 'Infections of breast associated with childbirth',
  'vietnamese': 'Nhiễm khuẩn vú phối hợp với đẻ',
}, {
  'id': 'O91.0',
  'english': 'Infection of nipple associated with childbirth',
  'vietnamese': 'Nhiễm khuẩn núm vú phối hợp với đẻ',
}, {
  'id': 'O91.1',
  'english': 'Abscess of breast associated with childbirth',
  'vietnamese': 'Áp xe vú phối hợp với đẻ',
}, {
  'id': 'O91.2',
  'english': 'Nonpurulent mastitis associated with childbirth',
  'vietnamese': 'Viêm vú không thành mủ phối hợp với đẻ',
}, {
  'id': 'O92',
  'english': 'Other disorders of breast and lactation associated with childbirth',
  'vietnamese': 'Các rối loạn khác của vú và tiết sữa phối hợp với đẻ',
}, {
  'id': 'O92.0',
  'english': 'Retracted nipple associated with childbirth',
  'vietnamese': 'Tụt núm vú phối hợp với đẻ',
}, {
  'id': 'O92.1',
  'english': 'Cracked nipple associated with childbirth',
  'vietnamese': 'Nứt đầu vú phối hợp với đẻ',
}, {
  'id': 'O92.2',
  'english': 'Other and unspecified disorders of breast associated with childbirth',
  'vietnamese': 'Các rối loạn khác chưa xác định rõ của vú phối hợp với đẻ',
}, {'id': 'O92.3', 'english': 'Agalactia', 'vietnamese': 'Không có sữa'}, {
  'id': 'O92.4',
  'english': 'Hypogalactia',
  'vietnamese': 'Thiếu sữa, ít sữa',
}, {'id': 'O92.5', 'english': 'Suppressed lactation', 'vietnamese': 'Cắt sữa'}, {
  'id': 'O92.6',
  'english': 'Galactorrhoea',
  'vietnamese': 'Tiết sữa',
}, {
  'id': 'O92.7',
  'english': 'Other and unspecified disorders of lactation',
  'vietnamese': 'Rối loạn tiết sữa khác chưa xác định rõ',
}, {
  'id': 'O94',
  'english': 'Sequelae of complication of prenancy, childbirth and the puerperium',
  'vietnamese': 'Di chứng của biến chứng do thai nghén, sinh đẻ và sau đẻ',
}, {
  'id': 'O95',
  'english': 'Obstetric death of unspecified cause',
  'vietnamese': 'Tử vong sản khoa vi nguyên nhân chưa xác định rõ',
}, {
  'id': 'O96',
  'english': 'Death from any obstetric cause occurring more than 42 days but less than one year after delivery',
  'vietnamese': 'Tử vong vì bất kỳ nguyên nhân sản khoa nào khác sau khi đẻ 42 ngày và dưới 1 năm',
}, {
  'id': 'O96.0',
  'english': 'Death from direct obstetric cause',
  'vietnamese': 'Tử vong do nguyên nhân sản khoa trực tiếp',
}, {
  'id': 'O96.1',
  'english': 'Death from indirect obstetric cause',
  'vietnamese': 'Tử vong do nguyên nhân sản khoa gián tiếp',
}, {
  'id': 'O96.9',
  'english': 'Death from unspecified obstetric cause',
  'vietnamese': 'Tử vong do nguyên nhân sản khoa không đặc hiệu',
}, {
  'id': 'O97',
  'english': 'Death from sequelae of obstetric causes',
  'vietnamese': 'Tử vong vì di chứng của nguyên nhân sản khoa trực tiếp',
}, {
  'id': 'O97.0',
  'english': 'Death from sequelae of direct obstetric cause',
  'vietnamese': 'Tử vong do di chứng của nguyên nhân sản khoa trực tiếp',
}, {
  'id': 'O97.1',
  'english': 'Death from sequelae of indirect obstetric cause',
  'vietnamese': 'Tử vong do di chứng của nguyên nhân sản khoa gián tiếp',
}, {
  'id': 'O97.9',
  'english': 'Death from sequelae of obstetric cause, unspecified',
  'vietnamese': 'Tử vong do di chứng của nguyên nhân sản khoa, không đặc hiệu',
}, {
  'id': 'O98',
  'english': 'Maternal infectious and parasitic diseases classifiable elsewhere but complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Nhiễm khuẩn bà mẹ và bệnh ký sinh trùng đã được xếp loại nhưng gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.0',
  'english': 'Tuberculosis complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh lao gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O98.1',
  'english': 'Syphilis complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Giang mai gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O98.2',
  'english': 'Gonorrhoea complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh lậu gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.3',
  'english': 'Other infections with a predominantly sexual mode of transmission complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Nhiễm khuẩn khác chủ yếu lây truyền qua đường tình dục gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O98.4',
  'english': 'Viral hepatitis complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Viêm gan virus gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.5',
  'english': 'Other viral diseases complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh do virus khác gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.6',
  'english': 'Protozoal diseases complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh do đơn bào gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.7',
  'english': 'Human immunodeficiency virus [HIV] disease complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh do virus gây suy giảm miễn dịch ở người [HIV] gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O98.8',
  'english': 'Other maternal infectious and parasitic diseases complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh nhiễm khuẩn và nhiễm KST khác của bà mẹ gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O98.9',
  'english': 'Unspecified maternal infectious or parasitic disease complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh nhiễm khuẩn và nhiễm KST chưa xác định của bà mẹ gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O99',
  'english': 'Other maternal diseases classifiable elsewhere but complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Các bệnh khác của bà mẹ đã được xếp loại nhưng gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O99.0',
  'english': 'Anaemia complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Thiếu máu gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.1',
  'english': 'Other diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Các bệnh khác của máu, cơ quan tạo máu và rối loại cơ chế miễn dịch gây biến chứng cho thai nghén, khi đẻ và sau khi đẻ',
}, {
  'id': 'O99.2',
  'english': 'Endocrine, nutritional and metabolic diseases complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh nội tiết, dinh dưỡng và chuyển hóa gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.3',
  'english': 'Mental disorders and diseases of the nervous system complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Rối loạn tâm thần và bệnh lý hệ thần kinh gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.4',
  'english': 'Diseases of the circulatory system complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh của hệ thống tuần hoà gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.5',
  'english': 'Diseases of the respiratory system complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh của hệ thống hô hấp gây biến chứng thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.6',
  'english': 'Diseases of the digestive system complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh của hệ thống tiêu hóa gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.7',
  'english': 'Diseases of the skin and subcutaneous tissue complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh của da và tổ chức dưới da gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'O99.8',
  'english': 'Other specified diseases and conditions complicating pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh và các tình trạng xác định khác gây biến chứng cho thai nghén, khi đẻ và sau đẻ',
}, {
  'id': 'P00',
  'english': 'Fetus and newborn affected by maternal conditions that may be unrelated to present pregnancy',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các yếu tố của mẹ có thể không liên quan đến lần thai nghén hiện nay',
}, {
  'id': 'P00.0',
  'english': 'Fetus and newborn affected by maternal hypertensive disorders',
  'vietnamese': 'Thai nhi và trẻ sơ sinh ảnh hưởng do các rối loạn tăng huyết áp của mẹ',
}, {
  'id': 'P00.1',
  'english': 'Fetus and newborn affected by maternal renal and urinary tract diseases',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do bệnh thận và bệnh đường tiết niệu của mẹ',
}, {
  'id': 'P00.2',
  'english': 'Fetus and newborn affected by maternal infectious and parasitic diseases',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bệnh nhiễm khuẩn và nhiễm ký sinh trùng của mẹ',
}, {
  'id': 'P00.3',
  'english': 'Fetus and newborn affected by other maternal circulatory and respiratory diseases',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bệnh lý khác về tuần hoàn và hô hấp của mẹ',
}, {
  'id': 'P00.4',
  'english': 'Fetus and newborn affected by maternal nutritional disorders',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các rối loạn dinh dưỡng của mẹ',
}, {
  'id': 'P00.5',
  'english': 'Fetus and newborn affected by maternal injury',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do mẹ bị chấn thương',
}, {
  'id': 'P00.6',
  'english': 'Fetus and newborn affected by surgical procedure on mother',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các phẫu thuật của mẹ',
}, {
  'id': 'P00.7',
  'english': 'Fetus and newborn affected by other medical procedures on mother, not elsewhere classified',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các thủ thuật nội khoa khác của mẹ, chưa được phân loại nơi khác',
}, {
  'id': 'P00.8',
  'english': 'Fetus and newborn affected by other maternal conditions',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bệnh lý khác của mẹ',
}, {
  'id': 'P00.9',
  'english': 'Fetus and newborn affected by unspecified maternal condition',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các tình trạng bệnh lý chưa xác định của mẹ',
}, {
  'id': 'P01',
  'english': 'Fetus and newborn affected by maternal complications of pregnancy',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do biến chứng thai nghén của mẹ',
}, {
  'id': 'P01.0',
  'english': 'Fetus and newborn affected by incompetent cervix',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do hở eo cổ tử cung',
}, {
  'id': 'P01.1',
  'english': 'Fetus and newborn affected by premature rupture of membranes',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do ối vỡ non',
}, {
  'id': 'P01.2',
  'english': 'Fetus and newborn affected by oligohydramnios',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do thiểu ối',
}, {
  'id': 'P01.3',
  'english': 'Fetus and newborn affected by polyhydramnios',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đa ối',
}, {
  'id': 'P01.4',
  'english': 'Fetus and newborn affected by ectopic pregnancy',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do chửa ngoài tử cung',
}, {
  'id': 'P01.5',
  'english': 'Fetus and newborn affected by multiple pregnancy',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đa thai',
}, {
  'id': 'P01.6',
  'english': 'Fetus and newborn affected by maternal death',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do tử vong mẹ',
}, {
  'id': 'P01.7',
  'english': 'Fetus and newborn affected by malpresentation before labour',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do ngôi bất thường trước khi chuyển dạ',
}, {
  'id': 'P01.8',
  'english': 'Fetus and newborn affected by other maternal complications of pregnancy',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng thai nghén khác của mẹ',
}, {
  'id': 'P01.9',
  'english': 'Fetus and newborn affected by maternal complication of pregnancy, unspecified',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng thai nghén của mẹ, chưa xác định',
}, {
  'id': 'P02',
  'english': 'Fetus and newborn affected by complications of placenta, cord and membranes',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng của bánh rau, dây rốn và các màng thai',
}, {
  'id': 'P02.0',
  'english': 'Fetus and newborn affected by placenta praevia',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do rau tiền đạo',
}, {
  'id': 'P02.1',
  'english': 'Fetus and newborn affected by other forms of placental separation and haemorrhage',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các hình thái bong rau và chảy máu khác',
}, {
  'id': 'P02.2',
  'english': 'Fetus and newborn affected by other and unspecified morphological and functional abnormalities of placenta',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bất thường về hình thái và chức năng khác chưa xác định của bánh rau',
}, {
  'id': 'P02.3',
  'english': 'Fetus and newborn affected by placental transfusion syndromes',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do hội chứng truyền máu trong bánh rau',
}, {
  'id': 'P02.4',
  'english': 'Fetus and newborn affected by prolapsed cord',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do sa dây rốn',
}, {
  'id': 'P02.5',
  'english': 'Fetus and newborn affected by other compression of umbilical cord',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các chèn ép khác của dây rốn',
}, {
  'id': 'P02.6',
  'english': 'Fetus and newborn affected by other and unspecified conditions of umbilical cord',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các tình trạng bệnh lý khác chưa xác định của dây rốn',
}, {
  'id': 'P02.7',
  'english': 'Fetus and newborn affected by chorioamnionitis',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do nhiễm khuẩn ối',
}, {
  'id': 'P02.8',
  'english': 'Fetus and newborn affected by other abnormalities of membranes',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bất thường khác của màng rau',
}, {
  'id': 'P02.9',
  'english': 'Fetus and newborn affected by abnormality of membranes, unspecified',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các bất thường của màng rau, chưa xác định',
}, {
  'id': 'P03',
  'english': 'Fetus and newborn affected by other complications of labour and delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng của chuyển dạ và cuộc đẻ',
}, {
  'id': 'P03.0',
  'english': 'Fetus and newborn affected by breech delivery and extraction',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đẻ và kéo thai trong ngôi mông',
}, {
  'id': 'P03.1',
  'english': 'Fetus and newborn affected by other malpresentation, malposition and disproportion during labour and delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do ngôi, thế bất thường, bất tương xứng trong chuyển dạ và trong đẻ',
}, {
  'id': 'P03.2',
  'english': 'Fetus and newborn affected by forceps delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đẻ foóc xép',
}, {
  'id': 'P03.3',
  'english': 'Fetus and newborn affected by delivery by vacuum extractor [ventouse]',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đẻ bằng giác hút',
}, {
  'id': 'P03.4',
  'english': 'Fetus and newborn affected by caesarean delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do mổ lấy thai',
}, {
  'id': 'P03.5',
  'english': 'Fetus and newborn affected by precipitate delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do đẻ quá nhanh',
}, {
  'id': 'P03.6',
  'english': 'Fetus and newborn affected by abnormal uterine contractions',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do cơn co tử cung bất thường',
}, {
  'id': 'P03.8',
  'english': 'Fetus and newborn affected by other specified complications of labour and delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng khác được xác định của chuyển dạ và cuộc đẻ',
}, {
  'id': 'P03.9',
  'english': 'Fetus and newborn affected by complication of labour and delivery, unspecified',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các biến chứng chưa xác định của chuyển dạ và cuộc đẻ',
}, {
  'id': 'P04',
  'english': 'Fetus and newborn affected by noxious influences transmitted via placenta or breast milk',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do độc tố truyền qua bánh rau hoặc qua sữa mẹ',
}, {
  'id': 'P04.0',
  'english': 'Fetus and newborn affected by maternal anaesthesia and analgesia in pregnancy, labour and delivery',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do thuốc gây mê, gây tê trong khi có thai, trong lúc chuyển dạ và trong khi đẻ',
}, {
  'id': 'P04.1',
  'english': 'Fetus and newborn affected by other maternal medication',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ dùng các thuốc khác',
}, {
  'id': 'P04.2',
  'english': 'Fetus and newborn affected by maternal use of tobacco',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ hút thuốc lá',
}, {
  'id': 'P04.3',
  'english': 'Fetus and newborn affected by maternal use of alcohol',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ dùng rượu',
}, {
  'id': 'P04.4',
  'english': 'Fetus and newborn affected by maternal use of drugs of addiction',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ nghiện thuốc',
}, {
  'id': 'P04.5',
  'english': 'Fetus and newborn affected by maternal use of nutritional chemical substances',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ sử dụng các hóa chất trong thực phẩm',
}, {
  'id': 'P04.6',
  'english': 'Fetus and newborn affected by maternal exposure to environmental chemical substances',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do người mẹ có tiếp xúc với các hóa chất trong môi trường',
}, {
  'id': 'P04.8',
  'english': 'Fetus and newborn affected by other maternal noxious influences',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các độc tố khác từ mẹ',
}, {
  'id': 'P04.9',
  'english': 'Fetus and newborn affected by maternal noxious influence, unspecified',
  'vietnamese': 'Thai nhi và trẻ sơ sinh bị ảnh hưởng do các độc tố khác từ mẹ, chưa xác định',
}, {
  'id': 'P05',
  'english': 'Slow fetal growth and fetal malnutrition',
  'vietnamese': 'Thai nhi chậm phát triển và thai suy dinh dưỡng',
}, {
  'id': 'P05.0',
  'english': 'Light for gestational age',
  'vietnamese': 'Nhẹ cân so với tuổi thai',
}, {
  'id': 'P05.1',
  'english': 'Small for gestational age',
  'vietnamese': 'Nhỏ so với tuổi thai',
}, {
  'id': 'P05.2',
  'english': 'Fetal malnutrition without mention of light or small for gestational age',
  'vietnamese': 'Thai suy dinh dưỡng mà không đề cập đến nhỏ hay nhẹ cân so với tuổi thai',
}, {
  'id': 'P05.9',
  'english': 'Slow fetal growth, unspecified',
  'vietnamese': 'Thai chậm phát triển, chưa xác định',
}, {
  'id': 'P07',
  'english': 'Disorders related to short gestation and low birth weight, not elsewhere classified',
  'vietnamese': 'Các rối loạn liên quan đến đẻ non và nhẹ cân lúc đẻ, chưa phân loại nơi khác',
}, {
  'id': 'P07.0',
  'english': 'Extremely low birth weight',
  'vietnamese': 'Trẻ có cân nặng cực thấp',
}, {'id': 'P07.1', 'english': 'Other low birth weight', 'vietnamese': 'Trẻ nhẹ cân khác'}, {
  'id': 'P07.2',
  'english': 'Extreme immaturity',
  'vietnamese': 'Trẻ cực non',
}, {'id': 'P07.3', 'english': 'Other preterm infants', 'vietnamese': 'Trẻ đẻ non khác'}, {
  'id': 'P08',
  'english': 'Disorders related to long gestation and high birth weight',
  'vietnamese': 'Rối loạn liên quan đến thai già tháng và trẻ nặng cân',
}, {'id': 'P08.0', 'english': 'Exceptionally large baby', 'vietnamese': 'Trẻ nặng cân'}, {
  'id': 'P08.1',
  'english': 'Other heavy for gestational age infants',
  'vietnamese': 'Trẻ nặng cân khác so với tuổi thai',
}, {
  'id': 'P08.2',
  'english': 'Post-term infant, not heavy for gestational age',
  'vietnamese': 'Trẻ già tháng nhưng không nặng so với tuổi thai',
}, {
  'id': 'P10',
  'english': 'Intracranial laceration and haemorrhage due to birth injury',
  'vietnamese': 'Rách, vỡ và chảy máu nội sọ do chấn thương khi sinh',
}, {
  'id': 'P10.0',
  'english': 'Subdural haemorrhage due to birth injury',
  'vietnamese': 'Chảy máu dưới màng cứng do chấn thương khi sinh',
}, {
  'id': 'P10.1',
  'english': 'Cerebral haemorrhage due to birth injury',
  'vietnamese': 'Chảy máu não do chấn thương khi sinh',
}, {
  'id': 'P10.2',
  'english': 'Intraventricular haemorrhage due to birth injury',
  'vietnamese': 'Chảy máu trong não thất do chấn thương khi sinh',
}, {
  'id': 'P10.3',
  'english': 'Subarachnoid haemorrhage due to birth injury',
  'vietnamese': 'Chảy máu dưới màng nhện do chấn thương khi sinh',
}, {
  'id': 'P10.4',
  'english': 'Tentorial tear due to birth injury',
  'vietnamese': 'Rách lều não do chấn thương khi sinh',
}, {
  'id': 'P10.8',
  'english': 'Other intracranial lacerations and haemorrhages due to birth injury',
  'vietnamese': 'Rách, vỡ và chảy máu nội sọ khác do chấn thương trong khi sinh',
}, {
  'id': 'P10.9',
  'english': 'Unspecified intracranial laceration and haemorrhage due to birth injury',
  'vietnamese': 'Rách, vỡ và chảy máu nội sọ do chấn thương khi sinh chưa xác định',
}, {
  'id': 'P11',
  'english': 'Other birth injuries to central nervous system',
  'vietnamese': 'Các chấn thương hệ thần kinh trung ương khi sinh',
}, {
  'id': 'P11.0',
  'english': 'Cerebral oedema due to birth injury',
  'vietnamese': 'Phù não do chấn thương khi sinh',
}, {
  'id': 'P11.1',
  'english': 'Other specified brain damage due to birth injury',
  'vietnamese': 'Tổn thương não khác được xác định do chấn thương khi sinh',
}, {
  'id': 'P11.2',
  'english': 'Unspecified brain damage due to birth injury',
  'vietnamese': 'Tổn thương não chưa xác định do chấn thương khi sinh',
}, {
  'id': 'P11.3',
  'english': 'Birth injury to facial nerve',
  'vietnamese': 'Tổn thương thần kinh mặt do chấn thương khi sinh',
}, {
  'id': 'P11.4',
  'english': 'Birth injury to other cranial nerves',
  'vietnamese': 'Tổn thương các dây thần kinh sọ khác do chấn thương khi sinh',
}, {
  'id': 'P11.5',
  'english': 'Birth injury to spine and spinal cord',
  'vietnamese': 'Tổn thương cột sống và tủy sống do chấn thương khi sinh',
}, {
  'id': 'P11.9',
  'english': 'Birth injury to central nervous system, unspecified',
  'vietnamese': 'Tổn thương hệ thần kinh trung ương do chấn thương khi sinh, chưa xác định',
}, {
  'id': 'P12',
  'english': 'Birth injury to scalp',
  'vietnamese': 'Chấn thương da đầu khi sinh',
}, {
  'id': 'P12.0',
  'english': 'Cephalhaematoma due to birth injury',
  'vietnamese': 'Máu tụ dưới cốt mạc do chấn thương khi sinh',
}, {
  'id': 'P12.1',
  'english': 'Chignon due to birth injury',
  'vietnamese': 'Bong da đầu do chấn thương khi sinh',
}, {
  'id': 'P12.2',
  'english': 'Epicranial subaponeurotic haemorrhage due to birth injury',
  'vietnamese': 'Chảy máu dưới cân ngoài sọ do chấn thương khi sinh',
}, {
  'id': 'P12.3',
  'english': 'Bruising of scalp due to birth injury',
  'vietnamese': 'Bầm tím da đầu do chấn thương khi sinh',
}, {
  'id': 'P12.4',
  'english': 'Monitoring injury of scalp of newborn',
  'vietnamese': 'Chấn thương da đầu trẻ sơ sinh do máy monitor',
}, {
  'id': 'P12.8',
  'english': 'Other birth injuries to scalp',
  'vietnamese': 'Chấn thương da đầu khác khi sinh',
}, {
  'id': 'P12.9',
  'english': 'Birth injury to scalp, unspecified',
  'vietnamese': 'Chấn thương da đầu khi sinh, chưa xác định',
}, {
  'id': 'P13',
  'english': 'Birth injury to skeleton',
  'vietnamese': 'Chấn thương hệ xương khi sinh',
}, {
  'id': 'P13.0',
  'english': 'Fracture of skull due to birth injury',
  'vietnamese': 'Vỡ sọ do chấn thương khi sinh',
}, {
  'id': 'P13.1',
  'english': 'Other birth injuries to skull',
  'vietnamese': 'Chấn thương khi sinh khác lên vòm sọ',
}, {
  'id': 'P13.2',
  'english': 'Birth injury to femur',
  'vietnamese': 'Chấn thương xương đùi khi sinh',
}, {
  'id': 'P13.3',
  'english': 'Birth injury to other long bones',
  'vietnamese': 'Chấn thương các xương dài khác khi sinh',
}, {
  'id': 'P13.4',
  'english': 'Fracture of clavicle due to birth injury',
  'vietnamese': 'gẫy xương đòn do chấn thương khi sinh',
}, {
  'id': 'P13.8',
  'english': 'Birth injuries to other parts of skeleton',
  'vietnamese': 'Các chấn thương xương khác khi sinh',
}, {
  'id': 'P13.9',
  'english': 'Birth injury to skeleton, unspecified',
  'vietnamese': 'Chấn thương xương khi sinh, chưa xác định',
}, {
  'id': 'P14',
  'english': 'Birth injury to peripheral nervous system',
  'vietnamese': 'Chấn thương hệ thần kinh ngoại vi khi sinh',
}, {
  'id': 'P14.0',
  'english': 'Erb s paralysis due to birth injury',
  'vietnamese': 'Liệt Erb do chấn thương khi sinh',
}, {
  'id': 'P14.1',
  'english': 'Klumpke s paralysis due to birth injury',
  'vietnamese': 'Liệt Klumpke do chấn thương khi sinh',
}, {
  'id': 'P14.2',
  'english': 'Phrenic nerve paralysis due to birth injury',
  'vietnamese': 'Liệt dây thần kinh hoành do chấn thương khi sinh',
}, {
  'id': 'P14.3',
  'english': 'Other brachial plexus birth injuries',
  'vietnamese': 'Chấn thương khác của đám rối cánh tay khi sinh',
}, {
  'id': 'P14.8',
  'english': 'Birth injuries to other parts of peripheral nervous system',
  'vietnamese': 'Chấn thương các phần khác của hệ thần kinh ngoại vi khi sinh',
}, {
  'id': 'P14.9',
  'english': 'Birth injury to peripheral nervous system, unspecified',
  'vietnamese': 'Chấn thương hệ thần kinh ngoại vi khi sinh, không được định rõ',
}, {'id': 'P15', 'english': 'Other birth injuries', 'vietnamese': 'Chấn thương khác khi sinh'}, {
  'id': 'P15.0',
  'english': 'Birth injury to liver',
  'vietnamese': 'Chấn thương gan khi sinh',
}, {
  'id': 'P15.1',
  'english': 'Birth injury to spleen',
  'vietnamese': 'Chấn thương lách khi sinh',
}, {
  'id': 'P15.2',
  'english': 'Sternomastoid injury due to birth injury',
  'vietnamese': 'Chấn thương ức chũm khi sinh',
}, {'id': 'P15.3', 'english': 'Birth injury to eye', 'vietnamese': 'Chấn thương mắt khi sinh'}, {
  'id': 'P15.4',
  'english': 'Birth injury to face',
  'vietnamese': 'Chấn thương mặt khi sinh',
}, {
  'id': 'P15.5',
  'english': 'Birth injury to external genitalia',
  'vietnamese': 'Chấn thương bộ phận sinh dục ngoài khi sinh',
}, {
  'id': 'P15.6',
  'english': 'Subcutaneous fat necrosis due to birth injury',
  'vietnamese': 'Hoại tử tổ chức mỡ dưới da do chấn thương khi sinh',
}, {
  'id': 'P15.8',
  'english': 'Other specified birth injuries',
  'vietnamese': 'Chấn thương khi sinh được xác định rõ khác',
}, {
  'id': 'P15.9',
  'english': 'Birth injury, unspecified',
  'vietnamese': 'Chấn thương khi sinh không được định rõ',
}, {'id': 'P20', 'english': 'Intrauterine hypoxia', 'vietnamese': 'Thiếu oxy trong tử cung'}, {
  'id': 'P20.0',
  'english': 'Intrauterine hypoxia first noted before onset of labour',
  'vietnamese': 'Giảm oxy máu của thai nhi trong tử cung từ trước khi bắt đầu chuyển dạ',
}, {
  'id': 'P20.1',
  'english': 'Intrauterine hypoxia first noted during labour and delivery',
  'vietnamese': 'Giảm oxy máu của thai nhi trong tử cung trong chuyển dạ và trong đẻ',
}, {
  'id': 'P20.9',
  'english': 'Intrauterine hypoxia, unspecified',
  'vietnamese': 'Giảm oxy máu của thai nhi trong tử cung chưa xác định',
}, {'id': 'P21', 'english': 'Birth asphyxia', 'vietnamese': 'Ngạt khi sinh'}, {
  'id': 'P21.0',
  'english': 'Severe birth asphyxia',
  'vietnamese': 'Ngạt nặng khi sinh',
}, {
  'id': 'P21.1',
  'english': 'Mild and moderate birth asphyxia',
  'vietnamese': 'Ngạt nhẹ và trung bình khi sinh',
}, {
  'id': 'P21.9',
  'english': 'Birth asphyxia, unspecified',
  'vietnamese': 'Ngạt khi đẻ, chưa xác định',
}, {
  'id': 'P22',
  'english': 'Respiratory distress of newborn',
  'vietnamese': 'Suy hô hấp của trẻ sơ sinh',
}, {
  'id': 'P22.0',
  'english': 'Respiratory distress syndrome of newborn',
  'vietnamese': 'Hội chứng suy hô hấp của trẻ sơ sinh',
}, {
  'id': 'P22.1',
  'english': 'Transient tachypnoea of newborn',
  'vietnamese': 'Thở nhanh thóang qua của trẻ sơ sinh',
}, {
  'id': 'P22.8',
  'english': 'Other respiratory distress of newborn',
  'vietnamese': 'Suy hô hấp khác của trẻ sơ sinh',
}, {
  'id': 'P22.9',
  'english': 'Respiratory distress of newborn, unspecified',
  'vietnamese': 'Suy hô hấp của trẻ sơ sinh, chưa xác định',
}, {'id': 'P23', 'english': 'Congenital pneumonia', 'vietnamese': 'Viêm phổi bẩm sinh'}, {
  'id': 'P23.0',
  'english': 'Congenital pneumonia due to viral agent',
  'vietnamese': 'Viêm phổi bẩm sinh do virus',
}, {
  'id': 'P23.1',
  'english': 'Congenital pneumonia due to Chlamydia',
  'vietnamese': 'Viêm phổi bẩm sinh do chlamydia',
}, {
  'id': 'P23.2',
  'english': 'Congenital pneumonia due to staphylococcus',
  'vietnamese': 'Viêm phổi bẩm sinh do tụ cầu',
}, {
  'id': 'P23.3',
  'english': 'Congenital pneumonia due to streptococcus, group B',
  'vietnamese': 'Viêm phổi bẩm sinh do liên cầu nhóm B',
}, {
  'id': 'P23.4',
  'english': 'Congenital pneumonia due to Escherichia coli',
  'vietnamese': 'Viêm phổi bẩm sinh do E Coli',
}, {
  'id': 'P23.5',
  'english': 'Congenital pneumonia due to Pseudomonas',
  'vietnamese': 'Viêm phổi bẩm sinh do Pseudomonas',
}, {
  'id': 'P23.6',
  'english': 'Congenital pneumonia due to other bacterial agents',
  'vietnamese': 'Viêm phổi bẩm sinh do các vi khuẩn khác',
}, {
  'id': 'P23.8',
  'english': 'Congenital pneumonia due to other organisms',
  'vietnamese': 'Viêm phổi bẩm sinh do các mầm bệnh khác',
}, {
  'id': 'P23.9',
  'english': 'Congenital pneumonia, unspecified',
  'vietnamese': 'Viêm phổi bẩm sinh, chưa xác định',
}, {
  'id': 'P24',
  'english': 'Neonatal aspiration syndromes',
  'vietnamese': 'Các hội chứng hít của trẻ sơ sinh',
}, {
  'id': 'P24.0',
  'english': 'Neonatal aspiration of meconium',
  'vietnamese': 'Sơ sinh hít phân su',
}, {
  'id': 'P24.1',
  'english': 'Neonatal aspiration of amniotic fluid and mucus',
  'vietnamese': 'Sơ sinh hít nước ối và niêm dịch',
}, {
  'id': 'P24.2',
  'english': 'Neonatal aspiration of blood',
  'vietnamese': 'Sơ sinh hít phải máu',
}, {
  'id': 'P24.3',
  'english': 'Neonatal aspiration of milk and regurgitated food',
  'vietnamese': 'Sơ sinh hít sữa và thức ăn trào lên',
}, {
  'id': 'P24.8',
  'english': 'Other neonatal aspiration syndromes',
  'vietnamese': 'Các hội chứng hít khác của trẻ sơ sinh',
}, {
  'id': 'P24.9',
  'english': 'Neonatal aspiration syndrome, unspecified',
  'vietnamese': 'Hội chứng hít của trẻ sơ sinh, chưa xác định',
}, {
  'id': 'P25',
  'english': 'Interstitial emphysema and related conditions originating in the perinatal period',
  'vietnamese': 'Tràn khí tổ chức kẽ phổi và các bệnh lý liên quan xuất phát trong thời kỳ chu sinh',
}, {
  'id': 'P25.0',
  'english': 'Interstitial emphysema originating in the perinatal period',
  'vietnamese': 'Tràn khí tổ chức kẽ phổi (xuất phát từ giai đoạn) chu sinh',
}, {
  'id': 'P25.1',
  'english': 'Pneumothorax originating in the perinatal period',
  'vietnamese': 'Tràn khí màng phổi chu sinh',
}, {
  'id': 'P25.2',
  'english': 'Pneumomediastinum originating in the perinatal period',
  'vietnamese': 'Tràn khí trung thất chu sinh',
}, {
  'id': 'P25.3',
  'english': 'Pneumopericardium originating in the perinatal period',
  'vietnamese': 'Tràn khí màng tim chu sinh',
}, {
  'id': 'P25.8',
  'english': 'Other conditions related to interstitial emphysema originating in the perinatal period',
  'vietnamese': 'Các bệnh lý khác liên quan đến tràn khí tô chức kẽ phổi chu sinh',
}, {
  'id': 'P26',
  'english': 'Pulmonary haemorrhage originating in the perinatal period',
  'vietnamese': 'Chảy máu phổi chu sinh',
}, {
  'id': 'P26.0',
  'english': 'Tracheobronchial haemorrhage originating in the perinatal period',
  'vietnamese': 'Chảy máu khí phế quản chu sinh',
}, {
  'id': 'P26.1',
  'english': 'Massive pulmonary haemorrhage originating in the perinatal period',
  'vietnamese': 'Chảy máu phổi nặng chu sinh',
}, {
  'id': 'P26.8',
  'english': 'Other pulmonary haemorrhages originating in the perinatal period',
  'vietnamese': 'Các chảy máu phổi khác chu sinh',
}, {
  'id': 'P26.9',
  'english': 'Unspecified pulmonary haemorrhage originating in the perinatal period',
  'vietnamese': 'Các chảy máu phổi chu sinh chưa xác định',
}, {
  'id': 'P27',
  'english': 'Chronic respiratory disease originating in the perinatal period',
  'vietnamese': 'Bệnh hô hấp mãn tính xuất phát từ giai đoạn chu sinh',
}, {
  'id': 'P27.0',
  'english': 'Wilson-Mikity syndrome',
  'vietnamese': 'Hội chứng Wilson-Mikity',
}, {
  'id': 'P27.1',
  'english': 'Bronchopulmonary dysplasia originating in the perinatal period',
  'vietnamese': 'Loạn sản phế quản phổi xuất phát từ giai đoạn chu sinh',
}, {
  'id': 'P27.8',
  'english': 'Other chronic respiratory diseases originating in the perinatal period',
  'vietnamese': 'Các bệnh phổi mãn tính khác xuất phát từ giai đoạn chu sinh',
}, {
  'id': 'P27.9',
  'english': 'Unspecified chronic respiratory disease originating in the perinatal period',
  'vietnamese': 'Các bệnh phổi mãn tính không được định rõ xuất phát trong giai đoạn chu sinh',
}, {
  'id': 'P28',
  'english': 'Other respiratory conditions originating in the perinatal period',
  'vietnamese': 'Các bệnh lý hô hấp khác xuất phát từ giai đoạn chu sinh',
}, {
  'id': 'P28.0',
  'english': 'Primary atelectasis of newborn',
  'vietnamese': 'Xẹp phổi nguyên phát của trẻ sơ sinh',
}, {
  'id': 'P28.1',
  'english': 'Other and unspecified atelectasis of newborn',
  'vietnamese': 'Xẹp phổi khác và không xác định của trẻ sơ sinh',
}, {
  'id': 'P28.2',
  'english': 'Cyanotic attacks of newborn',
  'vietnamese': 'Cơn tím tái của trẻ sơ sinh',
}, {
  'id': 'P28.3',
  'english': 'Primary sleep apnoea of newborn',
  'vietnamese': 'Ngừng thở khi ngủ nguyên phát của trẻ sơ sinh',
}, {
  'id': 'P28.4',
  'english': 'Other apnoea of newborn',
  'vietnamese': 'Ngừng thở khác của trẻ sơ sinh',
}, {
  'id': 'P28.5',
  'english': 'Respiratory failure of newborn',
  'vietnamese': 'Suy hô hấp của trẻ sơ sinh',
}, {
  'id': 'P28.8',
  'english': 'Other specified respiratory conditions of newborn',
  'vietnamese': 'Các bệnh lý hô hấp xác định rõ khác của trẻ sơ sinh',
}, {
  'id': 'P28.9',
  'english': 'Respiratory condition of newborn, unspecified',
  'vietnamese': 'Bệnh lý hô hấp của trẻ sơ sinh, chưa xác định',
}, {
  'id': 'P29',
  'english': 'Cardiovascular disorders originating in the perinatal period',
  'vietnamese': 'Rối loạn tim mạch xuất phát từ thời kỳ chu sinh',
}, {'id': 'P29.0', 'english': 'Neonatal cardiac failure', 'vietnamese': 'Suy tim sơ sinh'}, {
  'id': 'P29.1',
  'english': 'Neonatal cardiac dysrhythmia',
  'vietnamese': 'Loạn nhịp tim sơ sinh',
}, {'id': 'P29.2', 'english': 'Neonatal hypertension', 'vietnamese': 'Tăng huyết áp sơ sinh'}, {
  'id': 'P29.3',
  'english': 'Persistent fetal circulation',
  'vietnamese': 'Tồn tại tuần hoàn thai nhi',
}, {
  'id': 'P29.4',
  'english': 'Transient myocardial ischaemia of newborn',
  'vietnamese': 'Thiếu máu cơ tim thóang qua của trẻ sơ sinh',
}, {
  'id': 'P29.8',
  'english': 'Other cardiovascular disorders originating in the perinatal period',
  'vietnamese': 'Các rối loạn tim mạch khác xuất phát từ thời kỳ chu sinh',
}, {
  'id': 'P29.9',
  'english': 'Cardiovascular disorder originating in the perinatal period, unspecified',
  'vietnamese': 'Các rối loạn tim mạch không được định rõ xuất phát từ thời kỳ chu sinh',
}, {
  'id': 'P35',
  'english': 'Congenital viral diseases',
  'vietnamese': 'Bệnh nhiễm virus bẩm sinh',
}, {
  'id': 'P35.0',
  'english': 'Congenital rubella syndrome',
  'vietnamese': 'Hội chứng rubêon bẩm sinh',
}, {
  'id': 'P35.1',
  'english': 'Congenital cytomegalovirus infection',
  'vietnamese': 'Nhiễm virus cự bào bẩm sinh',
}, {
  'id': 'P35.2',
  'english': 'Congenital herpesviral [herpes simplex] infection',
  'vietnamese': 'Nhiễm virus Herpes bẩm sinh',
}, {
  'id': 'P35.3',
  'english': 'Congenital viral hepatitis',
  'vietnamese': 'Viêm gan virus bẩm sinh',
}, {
  'id': 'P35.8',
  'english': 'Other congenital viral diseases',
  'vietnamese': 'Các bệnh virus bẩm sinh khác',
}, {
  'id': 'P35.9',
  'english': 'Congenital viral disease, unspecified',
  'vietnamese': 'Bệnh virus bẩm sinh, chưa xác định',
}, {
  'id': 'P36',
  'english': 'Bacterial sepsis of newborn',
  'vietnamese': 'Nhiễm khuẩn của trẻ sơ sinh',
}, {
  'id': 'P36.0',
  'english': 'Sepsis of newborn due to streptococcus, group B',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do liên cầu nhóm B',
}, {
  'id': 'P36.1',
  'english': 'Sepsis of newborn due to other and unspecified streptococci',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do các liên cầu khác không được định rõ',
}, {
  'id': 'P36.2',
  'english': 'Sepsis of newborn due to Staphylococcus aureus',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do tụ cầu vàng',
}, {
  'id': 'P36.3',
  'english': 'Sepsis of newborn due to other and unspecified staphylococci',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do các tụ cầu khác không được định rõ',
}, {
  'id': 'P36.4',
  'english': 'Sepsis of newborn due to Escherichia coli',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do E. Coli',
}, {
  'id': 'P36.5',
  'english': 'Sepsis of newborn due to anaerobes',
  'vietnamese': 'Nhiễm khuẩn sơ sinh do vi khuẩn kỵ khí',
}, {
  'id': 'P36.8',
  'english': 'Other bacterial sepsis of newborn',
  'vietnamese': 'Nhiễm các vi khuẩn khác của trẻ sơ sinh',
}, {
  'id': 'P36.9',
  'english': 'Bacterial sepsis of newborn, unspecified',
  'vietnamese': 'Nhiễm khuẩn không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P37',
  'english': 'Other congenital infectious and parasitic diseases',
  'vietnamese': 'Bệnh nhiễm khuẩn và ký sinh trùng bẩm sinh khác',
}, {'id': 'P37.0', 'english': 'Congenital tuberculosis', 'vietnamese': 'Lao bẩm sinh'}, {
  'id': 'P37.1',
  'english': 'Congenital toxoplasmosis',
  'vietnamese': 'Toxoplasma bẩm sinh',
}, {
  'id': 'P37.2',
  'english': 'Neonatal (disseminated) listeriosis',
  'vietnamese': 'Listeria bẩm sinh (rải rác)',
}, {
  'id': 'P37.3',
  'english': 'Congenital falciparum malaria',
  'vietnamese': 'Sốt rét falciparum bẩm sinh',
}, {
  'id': 'P37.4',
  'english': 'Other congenital malaria',
  'vietnamese': 'Sốt rét bẩm sinh khác',
}, {
  'id': 'P37.5',
  'english': 'Neonatal candidiasis',
  'vietnamese': 'Nấm Candida bẩm sinh',
}, {
  'id': 'P37.8',
  'english': 'Other specified congenital infectious and parasitic diseases',
  'vietnamese': 'Bệnh nhiễm khuẩn và ký sinh trùng được định rõ khác',
}, {
  'id': 'P37.9',
  'english': 'Congenital infectious and parasitic disease, unspecified',
  'vietnamese': 'Bệnh nhiễm khuẩn và ký sinh trùng không được định rõ',
}, {
  'id': 'P38',
  'english': 'Omphalitis of newborn with or without mild haemorrhage',
  'vietnamese': 'Viêm rốn trẻ sơ sinh có hoặc không có chảy máu mức độ nhẹ',
}, {
  'id': 'P39',
  'english': 'Other infections specific to the perinatal period',
  'vietnamese': 'Nhiễm khuẩn đặc hiệu khác trong thời kỳ chu sinh',
}, {
  'id': 'P39.0',
  'english': 'Neonatal infective mastitis',
  'vietnamese': 'Viêm vú nhiễm khuẩn trẻ sơ sinh',
}, {
  'id': 'P39.1',
  'english': 'Neonatal conjunctivitis and dacryocystitis',
  'vietnamese': 'Viêm kết mạc và ống dẫn lệ của trẻ sơ sinh',
}, {
  'id': 'P39.2',
  'english': 'Intra-amniotic infection of fetus, not elsewhere classified',
  'vietnamese': 'Nhiễm khuẩn thai trong buồng ối không xếp loại ở chỗ nào khác',
}, {
  'id': 'P39.3',
  'english': 'Neonatal urinary tract infection',
  'vietnamese': 'Nhiễm khuẩn đường tiết niệu trẻ sơ sinh',
}, {
  'id': 'P39.4',
  'english': 'Neonatal skin infection',
  'vietnamese': 'Nhiễm khuẩn da trẻ sơ sinh',
}, {
  'id': 'P39.8',
  'english': 'Other specified infections specific to the perinatal period',
  'vietnamese': 'Nhiễm khuẩn được định rõ khác đặc hiệu cho giai đoạn chu sinh',
}, {
  'id': 'P39.9',
  'english': 'Infection specific to the perinatal period, unspecified',
  'vietnamese': 'Nhiễm khuẩn không được định rõ khác đặc hiệu cho giai đoạn chu sinh',
}, {'id': 'P50', 'english': 'Fetal blood loss', 'vietnamese': 'Mất máu thai'}, {
  'id': 'P50.0',
  'english': 'Fetal blood loss from vasa praevia',
  'vietnamese': 'Mất máu thai vì mạch máu tiền đạo',
}, {
  'id': 'P50.1',
  'english': 'Fetal blood loss from ruptured cord',
  'vietnamese': 'Mất máu thai vì vỡ dây rốn',
}, {
  'id': 'P50.2',
  'english': 'Fetal blood loss from placenta',
  'vietnamese': 'Mất máu thai từ rau',
}, {
  'id': 'P50.3',
  'english': 'Haemorrhage into co-twin',
  'vietnamese': 'Chảy máu giữa hai thai sinh đôi (truyền máu thai sang thai)',
}, {
  'id': 'P50.4',
  'english': 'Haemorrhage into maternal circulation',
  'vietnamese': 'Chảy máu vào tuần hoàn người mẹ',
}, {
  'id': 'P50.5',
  'english': 'Fetal blood loss from cut end of co-twin s cord',
  'vietnamese': 'Mất máu thai do cắt vào dây rốn chung của thai sinh đôi',
}, {'id': 'P50.8', 'english': 'Other fetal blood loss', 'vietnamese': 'Mất máu thai khác'}, {
  'id': 'P50.9',
  'english': 'Fetal blood loss, unspecified',
  'vietnamese': 'Mất máu thai không được định rõ',
}, {
  'id': 'P51',
  'english': 'Umbilical haemorrhage of newborn',
  'vietnamese': 'Chảy máu rốn của trẻ sơ sinh',
}, {
  'id': 'P51.0',
  'english': 'Massive umbilical haemorrhage of newborn',
  'vietnamese': 'Chảy máu rốn nặng của trẻ sơ sinh',
}, {
  'id': 'P51.8',
  'english': 'Other umbilical haemorrhages of newborn',
  'vietnamese': 'Chảy máu rốn khác của trẻ sơ sinh',
}, {
  'id': 'P51.9',
  'english': 'Umbilical haemorrhage of newborn, unspecified',
  'vietnamese': 'Chảy máu rốn không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P52',
  'english': 'Intracranial nontraumatic haemorrhage of fetus and newborn',
  'vietnamese': 'Chảy máu nội sọ không do chấn thương của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.0',
  'english': 'Intraventricular (nontraumatic) haemorrhage, grade 1, of fetus and newborn',
  'vietnamese': 'Chảy máu trong não thất (không chấn thương) độ 1 của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.1',
  'english': 'Intraventricular (nontraumatic) haemorrhage, grade 2, of fetus and newborn',
  'vietnamese': 'Chảy máu trong não thất (không chấn thương) độ 2 của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.2',
  'english': 'Intraventricular (nontraumatic) haemorrhage, grade 3, of fetus and newborn',
  'vietnamese': 'Chảy máu trong não thất (không chấn thương) độ 3 của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.3',
  'english': 'Unspecified intraventricular (nontraumatic) haemorrhage of fetus and newborn',
  'vietnamese': 'Chảy máu trong não thất (không chấn thương) không được định rõ của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.4',
  'english': 'Intracerebral (nontraumatic) haemorrhage of fetus and newborn',
  'vietnamese': 'Chảy máu trong não (không chấn thương) của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.5',
  'english': 'Subarachnoid (nontraumatic) haemorrhage of fetus and newborn',
  'vietnamese': 'Chảy máu dưới màng nhện (không chấn thương) của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.6',
  'english': 'Cerebellar (nontraumatic) and posterior fossa haemorrhage of fetus and newborn',
  'vietnamese': 'Chảy máu tiểu não (không chấn thương) và hố sau của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.8',
  'english': 'Other intracranial (nontraumatic) haemorrhages of fetus and newborn',
  'vietnamese': 'Chảy máu nội sọ khác (không chấn thương) của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P52.9',
  'english': 'Intracranial (nontraumatic) haemorrhage of fetus and newborn, unspecified',
  'vietnamese': 'Chảy máu nội sọ (không chấn thương) không được định rõ của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P53',
  'english': 'Haemorrhagic disease of fetus and newborn',
  'vietnamese': 'Bệnh chảy máu của thai nhi và trẻ sơ sinh',
}, {
  'id': 'P54',
  'english': 'Other neonatal haemorrhages',
  'vietnamese': 'Các chảy máu sơ sinh khác',
}, {'id': 'P54.0', 'english': 'Neonatal haematemesis', 'vietnamese': 'Nôn ra máu sơ sinh -'}, {
  'id': 'P54.1',
  'english': 'Neonatal melaena',
  'vietnamese': 'ỉa ra máu',
}, {
  'id': 'P54.2',
  'english': 'Neonatal rectal haemorrhage',
  'vietnamese': 'Chảy máu trực tràng của trẻ sơ sinh',
}, {
  'id': 'P54.3',
  'english': 'Other neonatal gastrointestinal haemorrhage',
  'vietnamese': 'Chảy máu dạ dày ruột khác của trẻ sơ sinh',
}, {
  'id': 'P54.4',
  'english': 'Neonatal adrenal haemorrhage',
  'vietnamese': 'Chảy máu thượng thận trẻ sơ sinh',
}, {
  'id': 'P54.5',
  'english': 'Neonatal cutaneous haemorrhage',
  'vietnamese': 'Xuất huyết dưới da của trẻ sơ sinh',
}, {
  'id': 'P54.6',
  'english': 'Neonatal vaginal haemorrhage',
  'vietnamese': 'Chảy máu âm đạo của trẻ sơ sinh',
}, {
  'id': 'P54.8',
  'english': 'Other specified neonatal haemorrhages',
  'vietnamese': 'Chảy máu sơ sinh được định rõ khác',
}, {
  'id': 'P54.9',
  'english': 'Neonatal haemorrhage, unspecified',
  'vietnamese': 'Chảy máu sơ sinh không được định rõ',
}, {
  'id': 'P55',
  'english': 'Haemolytic disease of fetus and newborn',
  'vietnamese': 'Bệnh huyết tán của bào thai và trẻ sơ sinh',
}, {
  'id': 'P55.0',
  'english': 'Rh isoimmunization of fetus and newborn',
  'vietnamese': 'Huyết tán do bất đồng nhóm máu Rh của mẹ và thai nhi / trẻ sơ sinh',
}, {
  'id': 'P55.1',
  'english': 'ABO isoimmunization of fetus and newborn',
  'vietnamese': 'Huyết tán do bất đồng nhóm máu ABO của mẹ và thai nhi / trẻ sơ sinh',
}, {
  'id': 'P55.8',
  'english': 'Other haemolytic diseases of fetus and newborn',
  'vietnamese': 'Bệnh huyết tán khác của thai và trẻ sơ sinh',
}, {
  'id': 'P55.9',
  'english': 'Haemolytic disease of fetus and newborn, unspecified',
  'vietnamese': 'Bệnh huyết tán của thai và trẻ sơ sinh, chưa xác định',
}, {
  'id': 'P56',
  'english': 'Hydrops fetalis due to haemolytic disease',
  'vietnamese': 'Phù thai do bệnh huyết tán',
}, {
  'id': 'P56.0',
  'english': 'Hydrops fetalis due to isoimmunization',
  'vietnamese': 'Phù thai do miễn dịch đồng loại',
}, {
  'id': 'P56.9',
  'english': 'Hydrops fetalis due to other and unspecified haemolytic disease',
  'vietnamese': 'Phù thai do bệnh lý huyết tán khác và chưa xác định',
}, {'id': 'P57', 'english': 'Kernicterus', 'vietnamese': 'Vàng da nhân xám'}, {
  'id': 'P57.0',
  'english': 'Kernicterus due to isoimmunization',
  'vietnamese': 'Vàng da nhân xám do bất đồng miễn dịch',
}, {
  'id': 'P57.8',
  'english': 'Other specified kernicterus',
  'vietnamese': 'Vàng da nhân xám được định rõ khác',
}, {
  'id': 'P57.9',
  'english': 'Kernicterus, unspecified',
  'vietnamese': 'Vàng da nhân xám, chưa xác định',
}, {
  'id': 'P58',
  'english': 'Neonatal jaundice due to other excessive haemolysis',
  'vietnamese': 'Vàng da sơ sinh do huyết tán quá mức',
}, {
  'id': 'P58.0',
  'english': 'Neonatal jaundice due to bruising',
  'vietnamese': 'Vàng da sơ sinh do bầm tím',
}, {
  'id': 'P58.1',
  'english': 'Neonatal jaundice due to bleeding',
  'vietnamese': 'Vàng da sơ sinh do chảy máu',
}, {
  'id': 'P58.2',
  'english': 'Neonatal jaundice due to infection',
  'vietnamese': 'Vàng da sơ sinh do nhiễm khuẩn',
}, {
  'id': 'P58.3',
  'english': 'Neonatal jaundice due to polycythaemia',
  'vietnamese': 'Vàng da sơ sinh do đa hồng cầu',
}, {
  'id': 'P58.4',
  'english': 'Neonatal jaundice due to drugs or toxins transmitted from mother or given to newborn',
  'vietnamese': 'Vàng da sơ sinh do thuốc, độc tố truyền từ mẹ sang hay dùng cho trẻ sơ sinh',
}, {
  'id': 'P58.5',
  'english': 'Neonatal jaundice due to swallowed maternal blood',
  'vietnamese': 'Vàng da sơ sinh do nuốt phải máu mẹ',
}, {
  'id': 'P58.8',
  'english': 'Neonatal jaundice due to other specified excessive haemolysis',
  'vietnamese': 'Vàng da sơ sinh do huyết tán quá mức được định rõ khác',
}, {
  'id': 'P58.9',
  'english': 'Neonatal jaundice due to excessive haemolysis, unspecified',
  'vietnamese': 'Vàng da sơ sinh do huyết tán quá mức không được định rõ',
}, {
  'id': 'P59',
  'english': 'Neonatal jaundice from other and unspecified causes',
  'vietnamese': 'Vàng da sơ sinh do các nguyên nhân khác và nguyên nhân không được định rõ',
}, {
  'id': 'P59.0',
  'english': 'Neonatal jaundice associated with preterm delivery',
  'vietnamese': 'Vàng da sơ sinh phối hợp với đẻ non',
}, {'id': 'P59.1', 'english': 'Inspissated bile syndrome', 'vietnamese': 'Hội chứng mật đặc'}, {
  'id': 'P59.2',
  'english': 'Neonatal jaundice from other and unspecified hepatocellular damage',
  'vietnamese': 'Vàng da sơ sinh vì các tổn thương tế bào gan khác và không đặc hiệu',
}, {
  'id': 'P59.3',
  'english': 'Neonatal jaundice from breast milk inhibitor',
  'vietnamese': 'Vàng da sơ sinh vì yếu tố ức chế của sữa mẹ',
}, {
  'id': 'P59.8',
  'english': 'Neonatal jaundice from other specified causes',
  'vietnamese': 'Vàng da sơ sinh vì những nguyên nhân đặc hiệu khác',
}, {
  'id': 'P59.9',
  'english': 'Neonatal jaundice, unspecified',
  'vietnamese': 'Vàng da sơ sinh không được định rõ',
}, {
  'id': 'P60',
  'english': 'Disseminated intravascular coagulation of fetus and newborn',
  'vietnamese': 'Đông máu rải rác trong lòng mạch của thai và trẻ sơ sinh',
}, {
  'id': 'P61',
  'english': 'Other perinatal haematological disorders',
  'vietnamese': 'Các rối loạn huyết học chu sinh khác',
}, {
  'id': 'P61.0',
  'english': 'Transient neonatal thrombocytopenia',
  'vietnamese': 'Giảm tiểu cầu sơ sinh tạm thời',
}, {'id': 'P61.1', 'english': 'Polycythaemia neonatorum', 'vietnamese': 'Đa hồng cầu sơ sinh'}, {
  'id': 'P61.2',
  'english': 'Anaemia of prematurity',
  'vietnamese': 'Thiếu máu của trẻ non tháng',
}, {
  'id': 'P61.3',
  'english': 'Congenital anaemia from fetal blood loss',
  'vietnamese': 'Thiếu máu bẩm sinh vì mất máu thai',
}, {
  'id': 'P61.4',
  'english': 'Other congenital anaemias, not elsewhere classified',
  'vietnamese': 'Các thiếu máu bẩm sinh khác, chưa phân loại nơi khác',
}, {
  'id': 'P61.5',
  'english': 'Transient neonatal neutropenia',
  'vietnamese': 'Giảm bạch cầu đa nhân trung tính thóang quá của sơ sinh',
}, {
  'id': 'P61.6',
  'english': 'Other transient neonatal disorders of coagulation',
  'vietnamese': 'Rối loạn đông máu khác thóang qua của sơ sinh',
}, {
  'id': 'P61.8',
  'english': 'Other specified perinatal haematological disorders',
  'vietnamese': 'Các rối loạn huyết học sơ sinh được định rõ khác',
}, {
  'id': 'P61.9',
  'english': 'Perinatal haematological disorder, unspecified',
  'vietnamese': 'Các rối loạn huyết học sơ sinh không được định rõ',
}, {
  'id': 'P70',
  'english': 'Transitory disorders of carbohydrate metabolism specific to fetus and newborn',
  'vietnamese': 'Các rối loạn chuyển hóa carbon hydrat thóang qua đặc hiệu với thai và trẻ sơ sinh',
}, {
  'id': 'P70.0',
  'english': 'Syndrome of infant of mother with gestational diabetes',
  'vietnamese': 'Hội chứng trẻ sơ sinh của bà mẹ bị đái tháo đường thai nghén',
}, {
  'id': 'P70.1',
  'english': 'Syndrome of infant of a diabetic mother',
  'vietnamese': 'Hội chứng trẻ sơ sinh của bà mẹ bị đái tháo đường',
}, {
  'id': 'P70.2',
  'english': 'Neonatal diabetes mellitus',
  'vietnamese': 'Đái tháo đường sơ sinh',
}, {
  'id': 'P70.3',
  'english': 'Iatrogenic neonatal hypoglycaemia',
  'vietnamese': 'Hạ đường huyết sơ sinh do thầy thuốc',
}, {
  'id': 'P70.4',
  'english': 'Other neonatal hypoglycaemia',
  'vietnamese': 'Hạ đường huyết sơ sinh khác',
}, {
  'id': 'P70.8',
  'english': 'Other transitory disorders of carbohydrate metabolism of fetus and newborn',
  'vietnamese': 'Các rối loạn tạm thời khác của chuyển hóa hydrat cacbon ở thai và trẻ sơ sinh',
}, {
  'id': 'P70.9',
  'english': 'Transitory disorder of carbohydrate metabolism of fetus and newborn, unspecified',
  'vietnamese': 'Rối loạn tạm thời không được định rõ của chuyển hóa cacbon hydrat ở thai và trẻ sơ sinh',
}, {
  'id': 'P71',
  'english': 'Transitory neonatal disorders of calcium and magnesium metabolism',
  'vietnamese': 'Các rối loạn chuyển hóa calci và magie thóang qua của sơ sinh',
}, {
  'id': 'P71.0',
  'english': 'Cow s milk hypocalcaemia in newborn',
  'vietnamese': 'Hạ calci máu khi dùng sữa bò của trẻ sơ sinh',
}, {
  'id': 'P71.1',
  'english': 'Other neonatal hypocalcaemia',
  'vietnamese': 'Hạ calci máu khác của sơ sinh',
}, {
  'id': 'P71.2',
  'english': 'Neonatal hypomagnesaemia',
  'vietnamese': 'Hạ magnesi máu của trẻ sơ sinh',
}, {
  'id': 'P71.3',
  'english': 'Neonatal tetany without calcium or magnesium deficiency',
  'vietnamese': 'Tetany sơ sinh không do thiếu calci và magie',
}, {
  'id': 'P71.4',
  'english': 'Transitory neonatal hypoparathyroidism',
  'vietnamese': 'Thiểu năng cận giáp tạm thời của trẻ sơ sinh',
}, {
  'id': 'P71.8',
  'english': 'Other transitory neonatal disorders of calcium and magnesium metabolism',
  'vietnamese': 'Các rối loạn tạm thời khác của chuyển hóa calci và magnesi ở trẻ sơ sinh',
}, {
  'id': 'P71.9',
  'english': 'Transitory neonatal disorder of calcium and magnesium metabolism, unspecified',
  'vietnamese': 'Các rối loạn tạm thời không chỉ rõ của chuyển hóa calci và magnesi ở trẻ sơ sinh',
}, {
  'id': 'P72',
  'english': 'Other transitory neonatal endocrine disorders',
  'vietnamese': 'Các rối loạn nội tiết tạm thời khác của trẻ sơ sinh',
}, {
  'id': 'P72.0',
  'english': 'Neonatal goitre, not elsewhere classified',
  'vietnamese': 'Bướu giáp trạng sơ sinh không xếp loại ở chỗ khác',
}, {
  'id': 'P72.1',
  'english': 'Transitory neonatal hyperthyroidism',
  'vietnamese': 'Cường năng giáp trạng tạm thời của trẻ sơ sinh',
}, {
  'id': 'P72.2',
  'english': 'Other transitory neonatal disorders of thyroid function, not elsewhere classified',
  'vietnamese': 'Rối loạn chức năng tuyến giáp tạm thời của trẻ sơ sinh không xếp loại ở chỗ khác',
}, {
  'id': 'P72.8',
  'english': 'Other specified transitory neonatal endocrine disorders',
  'vietnamese': 'Các rối loạn nội tiết tạm thời đặc hiệu khác của trẻ sơ sinh',
}, {
  'id': 'P72.9',
  'english': 'Transitory neonatal endocrine disorder, unspecified',
  'vietnamese': 'Các rối loạn nội tiết tạm thời không được định rõ',
}, {
  'id': 'P74',
  'english': 'Other transitory neonatal electrolyte and metabolic disturbances',
  'vietnamese': 'Các rối loạn chuyển hóa và điện giải tạm thời khác của trẻ sơ sinh',
}, {
  'id': 'P74.0',
  'english': 'Late metabolic acidosis of newborn',
  'vietnamese': 'Toan chuyển hóa muộn của trẻ sơ sinh',
}, {
  'id': 'P74.1',
  'english': 'Dehydration of newborn',
  'vietnamese': 'Mất nước của trẻ sơ sinh',
}, {
  'id': 'P74.2',
  'english': 'Disturbances of sodium balance of newborn',
  'vietnamese': 'Rối loạn cân bằng natri của trẻ sơ sinh',
}, {
  'id': 'P74.3',
  'english': 'Disturbances of potassium balance of newborn',
  'vietnamese': 'Rối loạn cân bằng kali của trẻ sơ sinh',
}, {
  'id': 'P74.4',
  'english': 'Other transitory electrolyte disturbances of newborn',
  'vietnamese': 'Các rối loạn điện giải tạm thời khác của trẻ sơ sinh',
}, {
  'id': 'P74.5',
  'english': 'Transitory tyrosinaemia of newborn',
  'vietnamese': 'Tăng tyrosin máu tạm thời của trẻ sơ sinh',
}, {
  'id': 'P74.8',
  'english': 'Other transitory metabolic disturbances of newborn',
  'vietnamese': 'Các rối loạn chuyển hóa tạm thời khác của trẻ sơ sinh',
}, {
  'id': 'P74.9',
  'english': 'Transitory metabolic disturbance of newborn, unspecified',
  'vietnamese': 'Các rối loạn chuyển hóa tạm thời không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P75*',
  'english': 'Meconium ileus in cystic fibrosis (E84.1† )',
  'vietnamese': 'Tắc ruột phân xu trong bệnh xơ nang (E84.1†)',
}, {
  'id': 'P76',
  'english': 'Other intestinal obstruction of newborn',
  'vietnamese': 'Tắc ruột non khác của trẻ sơ sinh',
}, {'id': 'P76.0', 'english': 'Meconium plug syndrome', 'vietnamese': 'Hội chứng nút phân su'}, {
  'id': 'P76.1',
  'english': 'Transitory ileus of newborn',
  'vietnamese': 'Tắc ruột tạm thời của trẻ sơ sinh',
}, {
  'id': 'P76.2',
  'english': 'Intestinal obstruction due to inspissated milk',
  'vietnamese': 'Tắc ruột non do sữa đặc',
}, {
  'id': 'P76.8',
  'english': 'Other specified intestinal obstruction of newborn',
  'vietnamese': 'Tắc ruột non được định rõ khác của trẻ sơ sinh',
}, {
  'id': 'P76.9',
  'english': 'Intestinal obstruction of newborn, unspecified',
  'vietnamese': 'Tắc ruột non không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P77',
  'english': 'Necrotizing enterocolitis of fetus and newborn',
  'vietnamese': 'Viêm ruột hoại tử của thai và trẻ sơ sinh',
}, {
  'id': 'P78',
  'english': 'Other perinatal digestive system disorders',
  'vietnamese': 'Các rối loạn khác của hệ tiêu hóa trong giai đoạn chu sinh',
}, {
  'id': 'P78.0',
  'english': 'Perinatal intestinal perforation',
  'vietnamese': 'Thủng ruột non chu sinh',
}, {
  'id': 'P78.1',
  'english': 'Other neonatal peritonitis',
  'vietnamese': 'Các viêm phúc mạc sơ sinh khác',
}, {
  'id': 'P78.2',
  'english': 'Neonatal haematemesis and melaena due to swallowed maternal blood',
  'vietnamese': 'Nôn ra máu, ỉa ra máu do nuốt phải máu mẹ của trẻ sơ sinh',
}, {
  'id': 'P78.3',
  'english': 'Noninfective neonatal diarrhoea',
  'vietnamese': 'ỉa chảy sơ sinh không do nhiễm khuẩn',
}, {
  'id': 'P78.8',
  'english': 'Other specified perinatal digestive system disorders',
  'vietnamese': 'Các rối loạn đặc hiệu khác của hệ tiêu hóa ở giai đoạn chu sinh',
}, {
  'id': 'P78.9',
  'english': 'Perinatal digestive system disorder, unspecified',
  'vietnamese': 'Rối loạn tiêu hóa không được định rõ của giai đoạn sơ sinh',
}, {
  'id': 'P80',
  'english': 'Hypothermia of newborn',
  'vietnamese': 'Hạ thân nhiệt của trẻ sơ sinh',
}, {
  'id': 'P80.0',
  'english': 'Cold injury syndrome',
  'vietnamese': 'Hội chứng tổn thương do lạnh',
}, {
  'id': 'P80.8',
  'english': 'Other hypothermia of newborn',
  'vietnamese': 'Hạ thân nhiệt khác của trẻ sơ sinh',
}, {
  'id': 'P80.9',
  'english': 'Hypothermia of newborn, unspecified',
  'vietnamese': 'Hạ thân nhiệt không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P81',
  'english': 'Other disturbances of temperature regulation of newborn',
  'vietnamese': 'Các rối loạn điều nhiệt khác của trẻ sơ sinh',
}, {
  'id': 'P81.0',
  'english': 'Environmental hyperthermia of newborn',
  'vietnamese': 'Tăng thân nhiệt do môi trường của trẻ sơ sinh',
}, {
  'id': 'P81.8',
  'english': 'Other specified disturbances of temperature regulation of newborn',
  'vietnamese': 'Các rối loạn điều nhiệt được định rõ khác của trẻ sơ sinh',
}, {
  'id': 'P81.9',
  'english': 'Disturbance of temperature regulation of newborn, unspecified',
  'vietnamese': 'Rối loạn điều nhiệt không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P83',
  'english': 'Other conditions of integument specific to fetus and newborn',
  'vietnamese': 'Các bệnh lý khác của da đặc hiệu cho thai và trẻ sơ sinh',
}, {'id': 'P83.0', 'english': 'Sclerema neonatorum', 'vietnamese': 'Cứng bì của trẻ sơ sinh'}, {
  'id': 'P83.1',
  'english': 'Neonatal erythema toxicum',
  'vietnamese': 'Đỏ da nhiễm độc của trẻ sơ sinh',
}, {
  'id': 'P83.2',
  'english': 'Hydrops fetalis not due to haemolytic disease',
  'vietnamese': 'Phù thai không do bệnh huyết tán',
}, {
  'id': 'P83.3',
  'english': 'Other and unspecified oedema specific to fetus and newborn',
  'vietnamese': 'Phù da khác không được định rõ đặc hiệu cho thai và trẻ sơ sinh',
}, {
  'id': 'P83.4',
  'english': 'Breast engorgement of newborn',
  'vietnamese': 'Cương tức vú của trẻ sơ sinh',
}, {
  'id': 'P83.5',
  'english': 'Congenital hydrocele',
  'vietnamese': 'Ứ nước màng tinh hoàn bẩm sinh',
}, {
  'id': 'P83.6',
  'english': 'Umbilical polyp of newborn',
  'vietnamese': 'Polyp rốn của trẻ sơ sinh',
}, {
  'id': 'P83.8',
  'english': 'Other specified conditions of integument specific to fetus and newborn',
  'vietnamese': 'Các bệnh lý được định rõ khác của da đặc hiệu cho thai và trẻ sơ sinh',
}, {
  'id': 'P83.9',
  'english': 'Condition of integument specific to fetus and newborn, unspecified',
  'vietnamese': 'Bệnh lý không được định rõ của da đặc hiệu cho thai và trẻ sơ sinh',
}, {
  'id': 'P90',
  'english': 'Convulsions of newborn',
  'vietnamese': 'Cơn co giật của trẻ sơ sinh',
}, {
  'id': 'P91',
  'english': 'Other disturbances of cerebral status of newborn',
  'vietnamese': 'Rối loạn khác của tình trạng não trẻ sơ sinh',
}, {
  'id': 'P91.0',
  'english': 'Neonatal cerebral ischaemia',
  'vietnamese': 'Thiếu máu cục bộ sơ sinh',
}, {
  'id': 'P91.1',
  'english': 'Acquired periventricular cysts of newborn',
  'vietnamese': 'Nang quanh não thất mắc phải của trẻ sơ sinh',
}, {
  'id': 'P91.2',
  'english': 'Neonatal cerebral leukomalacia',
  'vietnamese': 'Nhũn chất trắng não sơ sinh',
}, {
  'id': 'P91.3',
  'english': 'Neonatal cerebral irritability',
  'vietnamese': 'Não dễ kích thích của trẻ sơ sinh',
}, {'id': 'P91.4', 'english': 'Neonatal cerebral depression', 'vietnamese': 'Lõm não sơ sinh'}, {
  'id': 'P91.5',
  'english': 'Neonatal coma',
  'vietnamese': 'Hôn mê sơ sinh',
}, {
  'id': 'P91.6',
  'english': 'Hypoxic ischaemic encephalopathy of newborn',
  'vietnamese': 'Bệnh não thiếu máu cục bộ do giảm oxy ở trẻ sơ sinh',
}, {
  'id': 'P91.8',
  'english': 'Other specified disturbances of cerebral status of newborn',
  'vietnamese': 'Các rối loạn được định rõ khác của tình trạng não trẻ sơ sinh',
}, {
  'id': 'P91.9',
  'english': 'Disturbance of cerebral status of newborn, unspecified',
  'vietnamese': 'Rối loạn không chỉ rõ tình trạng não của sơ sinh',
}, {
  'id': 'P92',
  'english': 'Feeding problems of newborn',
  'vietnamese': 'Vấn đề nuôi dưỡng sơ sinh',
}, {'id': 'P92.0', 'english': 'Vomiting in newborn', 'vietnamese': 'Nôn ở trẻ sơ sinh'}, {
  'id': 'P92.1',
  'english': 'Regurgitation and rumination in newborn',
  'vietnamese': 'Nôn vọt và nhai lại của trẻ sơ sinh',
}, {
  'id': 'P92.2',
  'english': 'Slow feeding of newborn',
  'vietnamese': 'Nuôi dưỡng chậm của trẻ sơ sinh',
}, {
  'id': 'P92.3',
  'english': 'Underfeeding of newborn',
  'vietnamese': 'Nuôi dưỡng dưới mức của trẻ sơ sinh',
}, {
  'id': 'P92.4',
  'english': 'Overfeeding of newborn',
  'vietnamese': 'Nuôi dưỡng quá mức của trẻ sơ sinh',
}, {
  'id': 'P92.5',
  'english': 'Neonatal difficulty in feeding at breast',
  'vietnamese': 'Trẻ bú sữa mẹ khó khăn',
}, {
  'id': 'P92.8',
  'english': 'Other feeding problems of newborn',
  'vietnamese': 'Vấn đề khác của nuôi dưỡng trẻ sơ sinh',
}, {
  'id': 'P92.9',
  'english': 'Feeding problem of newborn, unspecified',
  'vietnamese': 'Vấn đề nuôi dưỡng không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P93',
  'english': 'Reactions and intoxications due to drugs administered to fetus and newborn',
  'vietnamese': 'Phản ứng và nhiễm độc thuốc dùng cho thai và trẻ sơ sinh',
}, {
  'id': 'P94',
  'english': 'Disorders of muscle tone of newborn',
  'vietnamese': 'Rối loạn trương lực cơ của trẻ sơ sinh',
}, {
  'id': 'P94.0',
  'english': 'Transient neonatal myasthenia gravis',
  'vietnamese': 'Nhược cơ nặng thóang qua của trẻ sơ sinh',
}, {
  'id': 'P94.1',
  'english': 'Congenital hypertonia',
  'vietnamese': 'Tăng trương lực cơ bẩm sinh',
}, {
  'id': 'P94.2',
  'english': 'Congenital hypotonia',
  'vietnamese': 'Giảm trương lực cơ bẩm sinh',
}, {
  'id': 'P94.8',
  'english': 'Other disorders of muscle tone of newborn',
  'vietnamese': 'Các rối loạn trương lực cơ khác của trẻ sơ sinh',
}, {
  'id': 'P94.9',
  'english': 'Disorder of muscle tone of newborn, unspecified',
  'vietnamese': 'Rối loạn trương lực cơ không được định rõ của trẻ sơ sinh',
}, {
  'id': 'P95',
  'english': 'Fetal death of unspecified cause',
  'vietnamese': 'Thai chết vì nguyên nhân không được định rõ',
}, {
  'id': 'P96',
  'english': 'Other conditions originating in the perinatal period',
  'vietnamese': 'Các bệnh lý khác xuất phát trong giai đoạn chu sinh',
}, {'id': 'P96.0', 'english': 'Congenital renal failure', 'vietnamese': 'Suy thận bẩm sinh'}, {
  'id': 'P96.1',
  'english': 'Neonatal withdrawal symptoms from maternal use of drugs of addiction',
  'vietnamese': 'Hội chứng cai thuốc của trẻ sơ sinh vì mẹ nghiện dùng thuốc',
}, {
  'id': 'P96.2',
  'english': 'Withdrawal symptoms from therapeutic use of drugs in newborn',
  'vietnamese': 'Hội chứng cai thuốc vì dùng thuốc để điều trị cho trẻ sơ sinh',
}, {
  'id': 'P96.3',
  'english': 'Wide cranial sutures of newborn',
  'vietnamese': 'Giãn khớp sọ của trẻ sơ sinh',
}, {
  'id': 'P96.4',
  'english': 'Termination of pregnancy, affecting fetus and newborn',
  'vietnamese': 'Đình chỉ thai nghén, ảnh hưởng thai và trẻ sơ sinh',
}, {
  'id': 'P96.5',
  'english': 'Complications of intrauterine procedures, not elsewhere classified',
  'vietnamese': 'Biến chứng của thủ thuật trong tử cung không xếp loại ở chỗ khác',
}, {
  'id': 'P96.8',
  'english': 'Other specified conditions originating in the perinatal period',
  'vietnamese': 'Các bệnh lý được định rõ khác xuất phát từ trong giai đoạn chu sinh',
}, {
  'id': 'P96.9',
  'english': 'Condition originating in the perinatal period, unspecified',
  'vietnamese': 'Bệnh lý không được định rõ xuất phát từ trong giai đoạn chu sinh',
}, {
  'id': 'Q00',
  'english': 'Anencephaly and similar malformations',
  'vietnamese': 'Dị tật không não và các dị tật tương tự',
}, {'id': 'Q00.0', 'english': 'Anencephaly', 'vietnamese': 'Tật không có não'}, {
  'id': 'Q00.1',
  'english': 'Craniorachischisis',
  'vietnamese': 'Tật nứt sọ-cột sống',
}, {'id': 'Q00.2', 'english': 'Iniencephaly', 'vietnamese': 'Tật thóat vị não chẩm'}, {
  'id': 'Q01',
  'english': 'Encephalocele',
  'vietnamese': 'Thóat vị não',
}, {'id': 'Q01.0', 'english': 'Frontal encephalocele', 'vietnamese': 'Thóat vị não thuỳ trán'}, {
  'id': 'Q01.1',
  'english': 'Nasofrontal encephalocele',
  'vietnamese': 'Thóat vị não qua vùng mũi trán',
}, {
  'id': 'Q01.2',
  'english': 'Occipital encephalocele',
  'vietnamese': 'Thóat vị não vùng chẩm',
}, {
  'id': 'Q01.8',
  'english': 'Encephalocele of other sites',
  'vietnamese': 'Thóat vị não ở những vị trí khác',
}, {
  'id': 'Q01.9',
  'english': 'Encephalocele, unspecified',
  'vietnamese': 'Thóat vị não không đặc hiệu',
}, {'id': 'Q02', 'english': 'Microcephaly', 'vietnamese': 'Tật đầu nhỏ'}, {
  'id': 'Q03',
  'english': 'Congenital hydrocephalus',
  'vietnamese': 'Não úng thủy bẩm sinh',
}, {
  'id': 'Q03.0',
  'english': 'Malformations of aqueduct of Sylvius',
  'vietnamese': 'Dị tật kênh Sylvius',
}, {
  'id': 'Q03.1',
  'english': 'Atresia of foramina of Magendie and Luschka',
  'vietnamese': 'Khuyết lỗ Magendie và Luschka',
}, {
  'id': 'Q03.8',
  'english': 'Other congenital hydrocephalus',
  'vietnamese': 'Não úng thủy bẩm sinh khác',
}, {
  'id': 'Q03.9',
  'english': 'Congenital hydrocephalus, unspecified',
  'vietnamese': 'Não úng thủy bẩm sinh, không đặc hiệu',
}, {
  'id': 'Q04',
  'english': 'Other congenital malformations of brain',
  'vietnamese': 'Dị tật bẩm sinh khác của não',
}, {
  'id': 'Q04.0',
  'english': 'Congenital malformations of corpus callosum',
  'vietnamese': 'Các dị tật bẩm sinh thể chai',
}, {'id': 'Q04.1', 'english': 'Arhinencephaly', 'vietnamese': 'Tật không khứu não'}, {
  'id': 'Q04.2',
  'english': 'Holoprosencephaly',
  'vietnamese': 'Tật toàn bộ não trước',
}, {
  'id': 'Q04.3',
  'english': 'Other reduction deformities of brain',
  'vietnamese': 'Khuyết tật khác của não',
}, {
  'id': 'Q04.4',
  'english': 'Septo-optic dysplasia',
  'vietnamese': 'Loạn sản vách ngăn thần kinh thị giác',
}, {'id': 'Q04.5', 'english': 'Megalencephaly', 'vietnamese': 'Tật não to'}, {
  'id': 'Q04.6',
  'english': 'Congenital cerebral cysts',
  'vietnamese': 'Nang nước não bẩm sinh',
}, {
  'id': 'Q04.8',
  'english': 'Other specified congenital malformations of brain',
  'vietnamese': 'Dị tật bẩm sinh đặc hiệu khác của não',
}, {
  'id': 'Q04.9',
  'english': 'Congenital malformation of brain, unspecified',
  'vietnamese': 'Dị tật bẩm sinh não, không đặc hiệu',
}, {'id': 'Q05', 'english': 'Spina bifida', 'vietnamese': 'Nứt đốt sống'}, {
  'id': 'Q05.0',
  'english': 'Cervical spina bifida with hydrocephalus',
  'vietnamese': 'Nứt đốt sống cổ với não úng thủy',
}, {
  'id': 'Q05.1',
  'english': 'Thoracic spina bifida with hydrocephalus',
  'vietnamese': 'Nứt đốt sống ngực với não úng thủy',
}, {
  'id': 'Q05.2',
  'english': 'Lumbar spina bifida with hydrocephalus',
  'vietnamese': 'Nứt đốt sống thắt lưng với não úng thủy',
}, {
  'id': 'Q05.3',
  'english': 'Sacral spina bifida with hydrocephalus',
  'vietnamese': 'Nứt đốt cùng cụt với não úng thủy',
}, {
  'id': 'Q05.4',
  'english': 'Unspecified spina bifida with hydrocephalus',
  'vietnamese': 'Nứt đốt sống không đặc hiệu với não úng thủy',
}, {
  'id': 'Q05.5',
  'english': 'Cervical spina bifida without hydrocephalus',
  'vietnamese': 'Nứt đốt sống cổ không có não úng thủy',
}, {
  'id': 'Q05.6',
  'english': 'Thoracic spina bifida without hydrocephalus',
  'vietnamese': 'Nứt đốt sống ngực không có não úng thủy',
}, {
  'id': 'Q05.7',
  'english': 'Lumbar spina bifida without hydrocephalus',
  'vietnamese': 'Nứt đốt sống thắt lưng không có não úng thủy',
}, {
  'id': 'Q05.8',
  'english': 'Sacral spina bifida without hydrocephalus',
  'vietnamese': 'Nứt đốt cùng cụt không có não úng thủy',
}, {
  'id': 'Q05.9',
  'english': 'Spina bifida, unspecified',
  'vietnamese': 'Nứt đốt sống không đặc hiệu',
}, {
  'id': 'Q06',
  'english': 'Other congenital malformations of spinal cord',
  'vietnamese': 'Các dị tật bẩm sinh khác của tủy sống',
}, {'id': 'Q06.0', 'english': 'Amyelia', 'vietnamese': 'Tật không có tủy sống'}, {
  'id': 'Q06.1',
  'english': 'Hypoplasia and dysplasia of spinal cord',
  'vietnamese': 'Thiểu sản và loạn sản thừng cột sống',
}, {'id': 'Q06.2', 'english': 'Diastematomyelia', 'vietnamese': 'Dị tật bẩm sinh dọc tủy sống'}, {
  'id': 'Q06.3',
  'english': 'Other congenital cauda equina malformations',
  'vietnamese': 'Các dị tật bẩm sinh khác ở vùng đuôi ngựa',
}, {'id': 'Q06.4', 'english': 'Hydromyelia', 'vietnamese': 'Ứ nước ống nội tủy'}, {
  'id': 'Q06.8',
  'english': 'Other specified congenital malformations of spinal cord',
  'vietnamese': 'Dị tật bẩm sinh tủy sống đặc hiệu khác',
}, {
  'id': 'Q06.9',
  'english': 'Congenital malformation of spinal cord, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của thừng tủy sống, không đặc hiệu',
}, {
  'id': 'Q07',
  'english': 'Other congenital malformations of nervous system',
  'vietnamese': 'Dị tật bẩm sinh khác của hệ thần kinh',
}, {
  'id': 'Q07.0',
  'english': 'Arnold-Chiari syndrome',
  'vietnamese': 'Hội chứng Arnold Chiari',
}, {
  'id': 'Q07.8',
  'english': 'Other specified congenital malformations of nervous system',
  'vietnamese': 'Dị tật bẩm sinh của hệ thần kinh đặc hiệu khác',
}, {
  'id': 'Q07.9',
  'english': 'Congenital malformation of nervous system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh hệ thần kinh, không đặc hiệu',
}, {
  'id': 'Q10',
  'english': 'Congenital malformations of eyelid, lacrimal apparatus and orbit',
  'vietnamese': 'Các dị tật bẩm sinh của mi mắt, ổ mắt và bộ máy bài tiết nước mắt',
}, {'id': 'Q10.0', 'english': 'Congenital ptosis', 'vietnamese': 'Sụp mí bẩm sinh'}, {
  'id': 'Q10.1',
  'english': 'Congenital ectropion',
  'vietnamese': 'Lộn mí bẩm sinh',
}, {'id': 'Q10.2', 'english': 'Congenital entropion', 'vietnamese': 'Quặm bẩm sinh'}, {
  'id': 'Q10.3',
  'english': 'Other congenital malformations of eyelid',
  'vietnamese': 'Các dị tật mí mắt bẩm sinh khác',
}, {
  'id': 'Q10.4',
  'english': 'Absence and agenesis of lacrimal apparatus',
  'vietnamese': 'Không có hoặc vô sản bộ phận liên quan đến nước mắt',
}, {
  'id': 'Q10.5',
  'english': 'Congenital stenosis and stricture of lacrimal duct',
  'vietnamese': 'Hẹp hoặc chít hẹp bẩm sinh ống dẫn lệ',
}, {
  'id': 'Q10.6',
  'english': 'Other congenital malformations of lacrimal apparatus',
  'vietnamese': 'Các dị tật bẩm sinh khác của ống dẫn lệ',
}, {
  'id': 'Q10.7',
  'english': 'Congenital malformation of orbit',
  'vietnamese': 'Dị tật bẩm sinh hố mắt',
}, {
  'id': 'Q11',
  'english': 'Anophthalmos, microphthalmos and macrophthalmos',
  'vietnamese': 'Các dị tật không có mắt, mắt bé, mắt to',
}, {'id': 'Q11.0', 'english': 'Cystic eyeball', 'vietnamese': 'Nhãn cầu dạng túi'}, {
  'id': 'Q11.1',
  'english': 'Other anophthalmos',
  'vietnamese': 'Các tật không có mắt khác',
}, {'id': 'Q11.2', 'english': 'Microphthalmos', 'vietnamese': 'Dị tật mắt bé'}, {
  'id': 'Q11.3',
  'english': 'Macrophthalmos',
  'vietnamese': 'Dị tật mắt to',
}, {
  'id': 'Q12',
  'english': 'Congenital lens malformations',
  'vietnamese': 'Các dị tật bẩm sinh của thủy tinh thể',
}, {
  'id': 'Q12.0',
  'english': 'Congenital cataract',
  'vietnamese': 'Đục thủy tinh thể bẩm sinh',
}, {
  'id': 'Q12.1',
  'english': 'Congenital displaced lens',
  'vietnamese': 'Lệch thủy tinh thể bẩm sinh',
}, {'id': 'Q12.2', 'english': 'Coloboma of lens', 'vietnamese': 'Khuyết thủy tinh thể'}, {
  'id': 'Q12.3',
  'english': 'Congenital aphakia',
  'vietnamese': 'Thiếu thủy tinh thể bẩm sinh',
}, {'id': 'Q12.4', 'english': 'Spherophakia', 'vietnamese': 'Thủy tinh thể hình cầu'}, {
  'id': 'Q12.8',
  'english': 'Other congenital lens malformations',
  'vietnamese': 'Các dị tật bẩm sinh khác của thủy tinh thể',
}, {
  'id': 'Q12.9',
  'english': 'Congenital lens malformation, unspecified',
  'vietnamese': 'Các dị tật thủy tinh thể bẩm sinh không đặc hiệu',
}, {
  'id': 'Q13',
  'english': 'Congenital malformations of anterior segment of eye',
  'vietnamese': 'Dị tật bẩm sinh phần trước mắt',
}, {'id': 'Q13.0', 'english': 'Coloboma of iris', 'vietnamese': 'Tật khuyết mống mắt'}, {
  'id': 'Q13.1',
  'english': 'Absence of iris',
  'vietnamese': 'Tật không có mống mắt',
}, {
  'id': 'Q13.2',
  'english': 'Other congenital malformations of iris',
  'vietnamese': 'Các dị tật bẩm sinh khác của mống mắt',
}, {
  'id': 'Q13.3',
  'english': 'Congenital corneal opacity',
  'vietnamese': 'Đục giác mạc bẩm sinh',
}, {
  'id': 'Q13.4',
  'english': 'Other congenital corneal malformations',
  'vietnamese': 'Các dị tật bẩm sinh khác của giác mạc',
}, {'id': 'Q13.5', 'english': 'Blue sclera', 'vietnamese': 'Củng mạc xanh'}, {
  'id': 'Q13.8',
  'english': 'Other congenital malformations of anterior segment of eye',
  'vietnamese': 'Các dị tật bẩm sinh khác ở phần trước của mắt',
}, {
  'id': 'Q13.9',
  'english': 'Congenital malformation of anterior segment of eye, unspecified',
  'vietnamese': 'Dị tật bẩm sinh phần trước không đặc hiệu',
}, {
  'id': 'Q14',
  'english': 'Congenital malformations of posterior segment of eye',
  'vietnamese': 'Các dị tật bẩm sinh phần sau của mắt',
}, {
  'id': 'Q14.0',
  'english': 'Congenital malformation of vitreous humour',
  'vietnamese': 'Dị tật bẩm sinh thủy tinh dịch',
}, {
  'id': 'Q14.1',
  'english': 'Congenital malformation of retina',
  'vietnamese': 'Dị tật bẩm sinh võng mạc',
}, {
  'id': 'Q14.2',
  'english': 'Congenital malformation of optic disc',
  'vietnamese': 'Dị tật bẩm sinh đĩa thị giác',
}, {
  'id': 'Q14.3',
  'english': 'Congenital malformation of choroid',
  'vietnamese': 'Dị tật bẩm sinh màng mạch',
}, {
  'id': 'Q14.8',
  'english': 'Other congenital malformations of posterior segment of eye',
  'vietnamese': 'Các dị tật bẩm sinh khác ở phần sau của mắt',
}, {
  'id': 'Q14.9',
  'english': 'Congenital malformation of posterior segment of eye, unspecified',
  'vietnamese': 'Dị tật bẩm sinh không đặc hiệu ở phần sau của mắt',
}, {
  'id': 'Q15',
  'english': 'Other congenital malformations of eye',
  'vietnamese': 'Các dị tật bẩm sinh khác của mắt',
}, {
  'id': 'Q15.0',
  'english': 'Congenital glaucoma',
  'vietnamese': 'Glôcôm (tăng nhãn áp) bẩm sinh',
}, {
  'id': 'Q15.8',
  'english': 'Other specified congenital malformations of eye',
  'vietnamese': 'Các dị tật bẩm sinh mắt đặc hiệu khác',
}, {
  'id': 'Q15.9',
  'english': 'Congenital malformation of eye, unspecified',
  'vietnamese': 'Dị tật bẩm sinh mắt không đặc hiệu',
}, {
  'id': 'Q16',
  'english': 'Congenital malformations of ear causing impairment of hearing',
  'vietnamese': 'Các dị tật ở tai gây ảnh hưởng tới thính lực',
}, {
  'id': 'Q16.0',
  'english': 'Congenital absence of (ear) auricle',
  'vietnamese': 'Dị tật thiếu tai ngoài bẩm sinh',
}, {
  'id': 'Q16.1',
  'english': 'Congenital absence, atresia and stricture of auditory canal (external)',
  'vietnamese': 'Thiếu, teo hoặc chít hẹp bẩm sinh ống tai ngoài',
}, {
  'id': 'Q16.2',
  'english': 'Absence of eustachian tube',
  'vietnamese': 'Không có vòi Eustache',
}, {
  'id': 'Q16.3',
  'english': 'Congenital malformation of ear ossicles',
  'vietnamese': 'Dị tật bẩm sinh các xương con của tai',
}, {
  'id': 'Q16.4',
  'english': 'Other congenital malformations of middle ear',
  'vietnamese': 'Các dị tật bẩm sinh của tai giữa',
}, {
  'id': 'Q16.5',
  'english': 'Congenital malformation of inner ear',
  'vietnamese': 'Dị tật bẩm sinh tai trong',
}, {
  'id': 'Q16.9',
  'english': 'Congenital malformation of ear causing impairment of hearing, unspecified',
  'vietnamese': 'Dị tật bẩm sinh ở tai gây ảnh hưởng tới thính lực không đặc hiệu',
}, {
  'id': 'Q17',
  'english': 'Other congenital malformations of ear',
  'vietnamese': 'Các dị tật bẩm sinh khác ở tai',
}, {'id': 'Q17.0', 'english': 'Accessory auricle', 'vietnamese': 'Dị tật thừa ở vành tai'}, {
  'id': 'Q17.1',
  'english': 'Macrotia',
  'vietnamese': 'Dị tật tai to',
}, {'id': 'Q17.2', 'english': 'Microtia', 'vietnamese': 'Dị tật tai bé'}, {
  'id': 'Q17.3',
  'english': 'Other misshapen ear',
  'vietnamese': 'Tật tai có hình dị thường',
}, {'id': 'Q17.4', 'english': 'Misplaced ear', 'vietnamese': 'Tật tai ở vị trí bất thường'}, {
  'id': 'Q17.5',
  'english': 'Prominent ear',
  'vietnamese': 'Tật tai nhô',
}, {
  'id': 'Q17.8',
  'english': 'Other specified congenital malformations of ear',
  'vietnamese': 'Những dị tật bẩm sinh đặc hiệu khác của tai',
}, {
  'id': 'Q17.9',
  'english': 'Congenital malformation of ear, unspecified',
  'vietnamese': 'Dị tật bẩm sinh tai không đặc hiệu',
}, {
  'id': 'Q18',
  'english': 'Other congenital malformations of face and neck',
  'vietnamese': 'Các dị tật bẩm sinh khác của mặt và cổ',
}, {
  'id': 'Q18.0',
  'english': 'Sinus, fistula and cyst of branchial cleft',
  'vietnamese': 'Tạo xoang, lỗ rò và nang nứt kẽ mang',
}, {
  'id': 'Q18.1',
  'english': 'Preauricular sinus and cyst',
  'vietnamese': 'Xoang và mang trước tai',
}, {
  'id': 'Q18.2',
  'english': 'Other branchial cleft malformations',
  'vietnamese': 'Dị tật khe hở mang khác',
}, {'id': 'Q18.3', 'english': 'Webbing of neck', 'vietnamese': 'Màng da cổ'}, {
  'id': 'Q18.4',
  'english': 'Macrostomia',
  'vietnamese': 'Tật mồm rộng',
}, {'id': 'Q18.5', 'english': 'Microstomia', 'vietnamese': 'Tật mồm nhỏ'}, {
  'id': 'Q18.6',
  'english': 'Macrocheilia',
  'vietnamese': 'Môi to',
}, {'id': 'Q18.7', 'english': 'Microcheilia', 'vietnamese': 'Môi nhỏ'}, {
  'id': 'Q18.8',
  'english': 'Other specified congenital malformations of face and neck',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác ở mặt và cổ',
}, {
  'id': 'Q18.9',
  'english': 'Congenital malformation of face and neck, unspecified',
  'vietnamese': 'Dị tật bẩm sinh ở mặt và cổ, không đặc hiệu',
}, {
  'id': 'Q20',
  'english': 'Congenital malformations of cardiac chambers and connections',
  'vietnamese': 'Các dị tật bẩm sinh của các buồng tim và bộ phận nối',
}, {'id': 'Q20.0', 'english': 'Common arterial trunk', 'vietnamese': 'Thân động mạch chung'}, {
  'id': 'Q20.1',
  'english': 'Double outlet right ventricle',
  'vietnamese': 'Thất phải hai đường ra',
}, {
  'id': 'Q20.2',
  'english': 'Double outlet left ventricle',
  'vietnamese': 'Thất trái hai đường ra',
}, {
  'id': 'Q20.3',
  'english': 'Discordant ventriculoarterial connection',
  'vietnamese': 'Phần nối thất - động mạch không phù hợp',
}, {'id': 'Q20.4', 'english': 'Double inlet ventricle', 'vietnamese': 'Thất hai đường vào'}, {
  'id': 'Q20.5',
  'english': 'Discordant atrioventricular connection',
  'vietnamese': 'Phần nối nhĩ - thất không phù hợp',
}, {
  'id': 'Q20.6',
  'english': 'Isomerism of atrial appendages',
  'vietnamese': 'Đồng phân của tiểu nhĩ',
}, {
  'id': 'Q20.8',
  'english': 'Other congenital malformations of cardiac chambers and connections',
  'vietnamese': 'Các dị tật bẩm sinh khác của các buồng tim và phần nối',
}, {
  'id': 'Q20.9',
  'english': 'Congenital malformation of cardiac chambers and connections, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của các buồng tim và các phần nối, không đặc hiệu',
}, {
  'id': 'Q21',
  'english': 'Congenital malformations of cardiac septa',
  'vietnamese': 'Các dị tật bẩm sinh của vách tim',
}, {'id': 'Q21.0', 'english': 'Ventricular septal defect', 'vietnamese': 'Thông liên thất'}, {
  'id': 'Q21.1',
  'english': 'Atrial septal defect',
  'vietnamese': 'Thông liên nhĩ',
}, {
  'id': 'Q21.2',
  'english': 'Atrioventricular septal defect',
  'vietnamese': 'Thông vách nhĩ thất',
}, {'id': 'Q21.3', 'english': 'Tetralogy of Fallot', 'vietnamese': 'Tứ chứng Fallot'}, {
  'id': 'Q21.4',
  'english': 'Aortopulmonary septal defect',
  'vietnamese': 'Thông vách động mạch chủ - phổi',
}, {
  'id': 'Q21.8',
  'english': 'Other congenital malformations of cardiac septa',
  'vietnamese': 'Những dị tật bẩm sinh khác của vách tim',
}, {
  'id': 'Q21.9',
  'english': 'Congenital malformation of cardiac septum, unspecified',
  'vietnamese': 'Những dị tật bẩm sinh khác của vách tim, không đặc hiệu',
}, {
  'id': 'Q22',
  'english': 'Congenital malformations of pulmonary and tricuspid valves',
  'vietnamese': 'Các dị tật bẩm sinh của van ba lá và động mạch phổi',
}, {
  'id': 'Q22.0',
  'english': 'Pulmonary valve atresia',
  'vietnamese': 'Teo van động mạch phổi',
}, {
  'id': 'Q22.1',
  'english': 'Congenital pulmonary valve stenosis',
  'vietnamese': 'Hẹp van động mạch phổi bẩm sinh',
}, {
  'id': 'Q22.2',
  'english': 'Congenital pulmonary valve insufficiency',
  'vietnamese': 'Hở van động mạch phổi bẩm sinh',
}, {
  'id': 'Q22.3',
  'english': 'Other congenital malformations of pulmonary valve',
  'vietnamese': 'Các dị tật bẩm sinh khác của van động mạch phổi',
}, {
  'id': 'Q22.4',
  'english': 'Congenital tricuspid stenosis',
  'vietnamese': 'Hẹp van ba lá bẩm sinh',
}, {'id': 'Q22.5', 'english': 'Ebstein s anomaly', 'vietnamese': 'Dị tật Ebstein'}, {
  'id': 'Q22.6',
  'english': 'Hypoplastic right heart syndrome',
  'vietnamese': 'Hội chứng tim phải thiểu sản',
}, {
  'id': 'Q22.8',
  'english': 'Other congenital malformations of tricuspid valve',
  'vietnamese': 'Các dị tật bẩm sinh khác của van ba lá',
}, {
  'id': 'Q22.9',
  'english': 'Congenital malformation of tricuspid valve, unspecified',
  'vietnamese': 'Dị tật bẩm sinh van ba lá, không đặc hiệu',
}, {
  'id': 'Q23',
  'english': 'Congenital malformations of aortic and mitral valves',
  'vietnamese': 'Các dị tật bẩm sinh của van hai lá và động mạch chủ',
}, {
  'id': 'Q23.0',
  'english': 'Congenital stenosis of aortic valve',
  'vietnamese': 'Hẹp van động mạch chủ bẩm sinh',
}, {
  'id': 'Q23.1',
  'english': 'Congenital insufficiency of aortic valve',
  'vietnamese': 'Hở van động mạch chủ bẩm sinh',
}, {
  'id': 'Q23.2',
  'english': 'Congenital mitral stenosis',
  'vietnamese': 'Hẹp van hai lá bẩm sinh',
}, {
  'id': 'Q23.3',
  'english': 'Congenital mitral insufficiency',
  'vietnamese': 'Hở van hai lá bẩm sinh',
}, {
  'id': 'Q23.4',
  'english': 'Hypoplastic left heart syndrome',
  'vietnamese': 'Hội chứng tim trái thiểu sản',
}, {
  'id': 'Q23.8',
  'english': 'Other congenital malformations of aortic and mitral valves',
  'vietnamese': 'Các dị tật bẩm sinh khác của van hai lá và động mạch chủ',
}, {
  'id': 'Q23.9',
  'english': 'Congenital malformation of aortic and mitral valves, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của van hai lá và động mạch chủ, không đặc hiệu',
}, {
  'id': 'Q24',
  'english': 'Other congenital malformations of heart',
  'vietnamese': 'Các dị tật bẩm sinh khác của tim',
}, {'id': 'Q24.0', 'english': 'Dextrocardia', 'vietnamese': 'Tim sang phải'}, {
  'id': 'Q24.1',
  'english': 'Laevocardia',
  'vietnamese': 'Tim bên trái',
}, {'id': 'Q24.2', 'english': 'Cor triatriatum', 'vietnamese': 'Nhĩ ba buồng'}, {
  'id': 'Q24.3',
  'english': 'Pulmonary infundibular stenosis',
  'vietnamese': 'Hẹp phễu động mạch phổi',
}, {
  'id': 'Q24.4',
  'english': 'Congenital subaortic stenosis',
  'vietnamese': 'Hẹp dưới van động mạch chủ bẩm sinh',
}, {
  'id': 'Q24.5',
  'english': 'Malformation of coronary vessels',
  'vietnamese': 'Dị tật của mạch vành',
}, {'id': 'Q24.6', 'english': 'Congenital heart block', 'vietnamese': 'Nghẽn tim bẩm sinh'}, {
  'id': 'Q24.8',
  'english': 'Other specified congenital malformations of heart',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của tim',
}, {
  'id': 'Q24.9',
  'english': 'Congenital malformation of heart, unspecified',
  'vietnamese': 'Các dị tật bẩm sinh của tim, không đặc hiệu',
}, {
  'id': 'Q25',
  'english': 'Congenital malformations of great arteries',
  'vietnamese': 'Các dị tật bẩm sinh của các động mạch lớn',
}, {'id': 'Q25.0', 'english': 'Patent ductus arteriosus', 'vietnamese': 'Còn ống động mạch'}, {
  'id': 'Q25.1',
  'english': 'Coarctation of aorta',
  'vietnamese': 'Hẹp eo động mạch chủ',
}, {'id': 'Q25.2', 'english': 'Atresia of aorta', 'vietnamese': 'Teo động mạch chủ'}, {
  'id': 'Q25.3',
  'english': 'Stenosis of aorta',
  'vietnamese': 'Hẹp động mạch chủ',
}, {
  'id': 'Q25.4',
  'english': 'Other congenital malformations of aorta',
  'vietnamese': 'Các dị tật bẩm sinh khác của động mạch chủ',
}, {
  'id': 'Q25.5',
  'english': 'Atresia of pulmonary artery',
  'vietnamese': 'Teo động mạch phổi',
}, {
  'id': 'Q25.6',
  'english': 'Stenosis of pulmonary artery',
  'vietnamese': 'Hẹp động mạch phổi',
}, {
  'id': 'Q25.7',
  'english': 'Other congenital malformations of pulmonary artery',
  'vietnamese': 'Các dị tật bẩm sinh khác của động mạch phổi',
}, {
  'id': 'Q25.8',
  'english': 'Other congenital malformations of great arteries',
  'vietnamese': 'Các dị tật bẩm sinh khác của động mạch lớn',
}, {
  'id': 'Q25.9',
  'english': 'Congenital malformation of great arteries, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của các động mạch lớn, không đặc hiệu',
}, {
  'id': 'Q26',
  'english': 'Congenital malformations of great veins',
  'vietnamese': 'Các dị tật bẩm sinh của các tĩnh mạch lớn',
}, {
  'id': 'Q26.0',
  'english': 'Congenital stenosis of vena cava',
  'vietnamese': 'Hẹp tĩnh mạch chủ bẩm sinh',
}, {
  'id': 'Q26.1',
  'english': 'Persistent left superior vena cava',
  'vietnamese': 'Còn tĩnh mạch chủ trên trái',
}, {
  'id': 'Q26.2',
  'english': 'Total anomalous pulmonary venous connection',
  'vietnamese': 'Hồi lưu tĩnh mạch phổi bất thường hoàn toàn',
}, {
  'id': 'Q26.3',
  'english': 'Partial anomalous pulmonary venous connection',
  'vietnamese': 'Hồi lưu tĩnh mạch phổi bất thường một phần',
}, {
  'id': 'Q26.4',
  'english': 'Anomalous pulmonary venous connection, unspecified',
  'vietnamese': 'Hồi lưu tĩnh mạch phổi bất thường, không đặc hiệu',
}, {
  'id': 'Q26.5',
  'english': 'Anomalous portal venous connection',
  'vietnamese': 'Hồi lưu tĩnh mạch phổi bất thường',
}, {
  'id': 'Q26.6',
  'english': 'Portal vein-hepatic artery fistula',
  'vietnamese': 'Rò động mạch gan - tĩnh mạch cửa',
}, {
  'id': 'Q26.8',
  'english': 'Other congenital malformations of great veins',
  'vietnamese': 'Các dị tật bẩm sinh khác của các tĩnh mạch lớn',
}, {
  'id': 'Q26.9',
  'english': 'Congenital malformation of great vein, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của các tĩnh mạch lớn, không đặc hiệu',
}, {
  'id': 'Q27',
  'english': 'Other congenital malformations of peripheral vascular system',
  'vietnamese': 'Các dị tật bẩm sinh khác của hệ thống mạch máu ngoại biên',
}, {
  'id': 'Q27.0',
  'english': 'Congenital absence and hypoplasia of umbilical artery',
  'vietnamese': 'Không có hoặc thiểu sản động mạch rốn bẩm sinh',
}, {
  'id': 'Q27.1',
  'english': 'Congenital renal artery stenosis',
  'vietnamese': 'Hẹp động mạch thận bẩm sinh',
}, {
  'id': 'Q27.2',
  'english': 'Other congenital malformations of renal artery',
  'vietnamese': 'Các dị tật bẩm sinh khác của động mạch thận',
}, {
  'id': 'Q27.3',
  'english': 'Peripheral arteriovenous malformation',
  'vietnamese': 'Dị tật động - tĩnh mạch ngoại biên',
}, {
  'id': 'Q27.4',
  'english': 'Congenital phlebectasia',
  'vietnamese': 'Dãn tĩnh mạch bẩm sinh',
}, {
  'id': 'Q27.8',
  'english': 'Other specified congenital malformations of peripheral vascular system',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của hệ thống mạch ngoại biên',
}, {
  'id': 'Q27.9',
  'english': 'Congenital malformation of peripheral vascular system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của hệ thống mạch ngoại biên, không đặc hiệu',
}, {
  'id': 'Q28',
  'english': 'Other congenital malformations of circulatory system',
  'vietnamese': 'Các dị tật bẩm sinh khác của hệ thống tuần hoàn',
}, {
  'id': 'Q28.0',
  'english': 'Arteriovenous malformation of precerebral vessels',
  'vietnamese': 'Dị tật động - tĩnh mạch của các mạch máu trước não',
}, {
  'id': 'Q28.1',
  'english': 'Other malformations of precerebral vessels',
  'vietnamese': 'Các dị tật khác của các mạch máu trước não',
}, {
  'id': 'Q28.2',
  'english': 'Arteriovenous malformation of cerebral vessels',
  'vietnamese': 'Dị tật động - tĩnh mạch của các mạch máu não',
}, {
  'id': 'Q28.3',
  'english': 'Other malformations of cerebral vessels',
  'vietnamese': 'Các dị tật khác của các mạch máu não',
}, {
  'id': 'Q28.8',
  'english': 'Other specified congenital malformations of circulatory system',
  'vietnamese': 'Những dị tật đặc hiệu khác của hệ tuần hoàn',
}, {
  'id': 'Q28.9',
  'english': 'Congenital malformation of circulatory system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh hệ tuần hoàn, không đặc hiệu',
}, {
  'id': 'Q30',
  'english': 'Congenital malformations of nose',
  'vietnamese': 'Các bất thường-bẩm sinh ở mũi',
}, {'id': 'Q30.0', 'english': 'Choanal atresia', 'vietnamese': 'Teo lỗ mũi'}, {
  'id': 'Q30.1',
  'english': 'Agenesis and underdevelopment of nose',
  'vietnamese': 'Bất sản và kém phát triển mũi',
}, {
  'id': 'Q30.2',
  'english': 'Fissured, notched and cleft nose',
  'vietnamese': 'Mũi có rãnh, có lõm hoặc nứt kẽ',
}, {
  'id': 'Q30.3',
  'english': 'Congenital perforated nasal septum',
  'vietnamese': 'Thủng vách mũi bẩm sinh',
}, {
  'id': 'Q30.8',
  'english': 'Other congenital malformations of nose',
  'vietnamese': 'Các dị tật bẩm sinh khác ở mũi',
}, {
  'id': 'Q30.9',
  'english': 'Congenital malformation of nose, unspecified',
  'vietnamese': 'Bất thường bẩm sinh ở mũi, không đặc hiệu',
}, {
  'id': 'Q31',
  'english': 'Congenital malformations of larynx',
  'vietnamese': 'Các bất thường bẩm sinh của thanh quản',
}, {'id': 'Q31.0', 'english': 'Web of larynx', 'vietnamese': 'Màng thanh quản'}, {
  'id': 'Q31.1',
  'english': 'Congenital subglottic stenosis',
  'vietnamese': 'Hẹp vùng hạ thanh môn bẩm sinh',
}, {'id': 'Q31.2', 'english': 'Laryngeal hypoplasia', 'vietnamese': 'Thiểu sản thanh quản'}, {
  'id': 'Q31.3',
  'english': 'Laryngocele',
  'vietnamese': 'Thóat vị thanh quản',
}, {
  'id': 'Q31.5',
  'english': 'Congenital laryngomalacia',
  'vietnamese': 'Nhuyễn cơ thanh quản bẩm sinh',
}, {
  'id': 'Q31.8',
  'english': 'Other congenital malformations of larynx',
  'vietnamese': 'Các dị tật bẩm sinh khác của thanh quản',
}, {
  'id': 'Q31.9',
  'english': 'Congenital malformation of larynx, unspecified',
  'vietnamese': 'Bất thường thanh quản bẩm sinh, không đặc hiệu',
}, {
  'id': 'Q32',
  'english': 'Congenital malformations of trachea and bronchus',
  'vietnamese': 'Các bất thường bẩm sinh ở khí quản và phế quản',
}, {
  'id': 'Q32.0',
  'english': 'Congenital tracheomalacia',
  'vietnamese': 'Nhuyễn khí quản bẩm sinh',
}, {
  'id': 'Q32.1',
  'english': 'Other congenital malformations of trachea',
  'vietnamese': 'Các bất thường khác của khí quản',
}, {
  'id': 'Q32.2',
  'english': 'Congenital bronchomalacia',
  'vietnamese': 'Nhuyễn phế quản bẩm sinh',
}, {
  'id': 'Q32.3',
  'english': 'Congenital stenosis of bronchus',
  'vietnamese': 'Hẹp phế quản bẩm sinh',
}, {
  'id': 'Q32.4',
  'english': 'Other congenital malformations of bronchus',
  'vietnamese': 'Các bất thường bẩm sinh khác của phế quản',
}, {
  'id': 'Q33',
  'english': 'Congenital malformations of lung',
  'vietnamese': 'Các dị tật bẩm sinh của phổi',
}, {'id': 'Q33.0', 'english': 'Congenital cystic lung', 'vietnamese': 'Nang phổi bẩm sinh'}, {
  'id': 'Q33.1',
  'english': 'Accessory lobe of lung',
  'vietnamese': 'Phổi có thùy phụ',
}, {'id': 'Q33.2', 'english': 'Sequestration of lung', 'vietnamese': 'Phổi biệt lập'}, {
  'id': 'Q33.3',
  'english': 'Agenesis of lung',
  'vietnamese': 'Bất sản phổi',
}, {
  'id': 'Q33.4',
  'english': 'Congenital bronchiectasis',
  'vietnamese': 'Giãn phế quản bẩm sinh',
}, {
  'id': 'Q33.5',
  'english': 'Ectopic tissue in lung',
  'vietnamese': 'Lạc chỗ của một tổ chức khác tại phổi',
}, {
  'id': 'Q33.6',
  'english': 'Hypoplasia and dysplasia of lung',
  'vietnamese': 'Thiểu sản và loạn sản phổi',
}, {
  'id': 'Q33.8',
  'english': 'Other congenital malformations of lung',
  'vietnamese': 'Các bất thường bẩm sinh khác của phổi',
}, {
  'id': 'Q33.9',
  'english': 'Congenital malformation of lung, unspecified',
  'vietnamese': 'Các dị tật bẩm sinh khác của phổi không đặc hiệu',
}, {
  'id': 'Q34',
  'english': 'Other congenital malformations of respiratory system',
  'vietnamese': 'Các bất thường bẩm sinh khác của bộ máy hô hấp',
}, {'id': 'Q34.0', 'english': 'Anomaly of pleura', 'vietnamese': 'Bất thường ở màng phổi'}, {
  'id': 'Q34.1',
  'english': 'Congenital cyst of mediastinum',
  'vietnamese': 'Kén trung thất bẩm sinh',
}, {
  'id': 'Q34.8',
  'english': 'Other specified congenital malformations of respiratory system',
  'vietnamese': 'Các dị tật đường hô hấp bẩm sinh đặc hiệu khác',
}, {
  'id': 'Q34.9',
  'english': 'Congenital malformation of respiratory system, unspecified',
  'vietnamese': 'Bất thường bẩm sinh bộ máy hô hấp không đặc hiệu',
}, {'id': 'Q35', 'english': 'Cleft palate', 'vietnamese': 'Khe hở vòm miệng'}, {
  'id': 'Q35.1',
  'english': 'Cleft hard palate',
  'vietnamese': 'Khe hở vòm miệng cứng',
}, {'id': 'Q35.3', 'english': 'Cleft soft palate', 'vietnamese': 'Khe hở vòm miệng mềm'}, {
  'id': 'Q35.5',
  'english': 'Cleft hard palate with cleft soft palate',
  'vietnamese': 'Khe hở vòm miệng cứng và mềm',
}, {'id': 'Q35.7', 'english': 'Cleft uvula', 'vietnamese': 'Khe hở lưỡi gà'}, {
  'id': 'Q35.9',
  'english': 'Cleft palate, unspecified',
  'vietnamese': 'Khe hở vòm miệng, không đặc hiệu, một bên',
}, {'id': 'Q36', 'english': 'Cleft lip', 'vietnamese': 'Khe hở môi'}, {
  'id': 'Q36.0',
  'english': 'Cleft lip, bilateral',
  'vietnamese': 'Khe hở môi, hai bên',
}, {'id': 'Q36.1', 'english': 'Cleft lip, median', 'vietnamese': 'Khe hở giữa môi'}, {
  'id': 'Q36.9',
  'english': 'Cleft lip, unilateral',
  'vietnamese': 'Khe hở môi, một bên',
}, {
  'id': 'Q37',
  'english': 'Cleft palate with cleft lip',
  'vietnamese': 'Khe hở vòm miệng cùng với khe hở môi',
}, {
  'id': 'Q37.0',
  'english': 'Cleft hard palate with bilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng cứng và môi, hai bên',
}, {
  'id': 'Q37.1',
  'english': 'Cleft hard palate with unilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng cứng và môi, một bên',
}, {
  'id': 'Q37.2',
  'english': 'Cleft soft palate with bilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng mềm và môi, hai bên',
}, {
  'id': 'Q37.3',
  'english': 'Cleft soft palate with unilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng mềm và môi, một bên',
}, {
  'id': 'Q37.4',
  'english': 'Cleft hard and soft palate with bilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng cứng, mềm và môi, hai bên',
}, {
  'id': 'Q37.5',
  'english': 'Cleft hard and soft palate with unilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng cứng, mềm và môi, một bên',
}, {
  'id': 'Q37.8',
  'english': 'Unspecified cleft palate with bilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng không xác định và khe hở môi hai bên',
}, {
  'id': 'Q37.9',
  'english': 'Unspecified cleft palate with unilateral cleft lip',
  'vietnamese': 'Khe hở vòm miệng không xác định và khe hở môi một bên',
}, {
  'id': 'Q38',
  'english': 'Other congenital malformations of tongue, mouth and pharynx',
  'vietnamese': 'Các dị tật bẩm sinh khác của lưỡi, miệng và họng',
}, {
  'id': 'Q38.0',
  'english': 'Congenital malformations of lips, not elsewhere classified',
  'vietnamese': 'Các dị tật bẩm sinh của môi, không phân loại ở mục khác',
}, {'id': 'Q38.1', 'english': 'Ankyloglossia', 'vietnamese': 'Dính lưỡi'}, {
  'id': 'Q38.2',
  'english': 'Macroglossia',
  'vietnamese': 'Lưỡi to',
}, {
  'id': 'Q38.3',
  'english': 'Other congenital malformations of tongue',
  'vietnamese': 'Các dị tật bẩm sinh khác của lưỡi',
}, {
  'id': 'Q38.4',
  'english': 'Congenital malformations of salivary glands and ducts',
  'vietnamese': 'Các dị tật bẩm sinh của các tuyến và ống dẫn nước bọt',
}, {
  'id': 'Q38.5',
  'english': 'Congenital malformations of palate, not elsewhere classified',
  'vietnamese': 'Các dị tật bẩm sinh của vòm miệng, không phân loại ở mục khác',
}, {
  'id': 'Q38.6',
  'english': 'Other congenital malformations of mouth',
  'vietnamese': 'Các dị tật bẩm sinh khác của miệng',
}, {'id': 'Q38.7', 'english': 'Pharyngeal pouch', 'vietnamese': 'Túi họng'}, {
  'id': 'Q38.8',
  'english': 'Other congenital malformations of pharynx',
  'vietnamese': 'Các dị tật bẩm sinh khác của họng',
}, {
  'id': 'Q39',
  'english': 'Congenital malformations of oesophagus',
  'vietnamese': 'Các dị tật bẩm sinh của thực quản',
}, {
  'id': 'Q39.0',
  'english': 'Atresia of oesophagus without fistula',
  'vietnamese': 'Teo thực quản không có đường rò',
}, {
  'id': 'Q39.1',
  'english': 'Atresia of oesophagus with tracheo-oesophageal fistula',
  'vietnamese': 'Teo thực quản có đường rò thực quản - khí quản',
}, {
  'id': 'Q39.2',
  'english': 'Congenital tracheo-oesophageal fistula without atresia',
  'vietnamese': 'Đường rò thực quản - khí quản bẩm sinh, không có, teo thực quản',
}, {
  'id': 'Q39.3',
  'english': 'Congenital stenosis and stricture of oesophagus',
  'vietnamese': 'Hẹp và hẹp khít thực quản bẩm sinh',
}, {'id': 'Q39.4', 'english': 'Oesophageal web', 'vietnamese': 'Màng ngăn thực quản'}, {
  'id': 'Q39.5',
  'english': 'Congenital dilatation of oesophagus',
  'vietnamese': 'Dãn thực quản bẩm sinh',
}, {'id': 'Q39.6', 'english': 'Diverticulum of oesophagus', 'vietnamese': 'Túi thừa thực quản'}, {
  'id': 'Q39.8',
  'english': 'Other congenital malformations of oesophagus',
  'vietnamese': 'Các dị tật bẩm sinh khác của thực quản',
}, {
  'id': 'Q39.9',
  'english': 'Congenital malformation of oesophagus, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của thực quản, không đặc hiệu',
}, {
  'id': 'Q40',
  'english': 'Other congenital malformations of upper alimentary tract',
  'vietnamese': 'Các dị tật bẩm sinh khác của đường tiêu hóa trên',
}, {
  'id': 'Q40.0',
  'english': 'Congenital hypertrophic pyloric stenosis',
  'vietnamese': 'Hẹp phù đại môn vị bẩm sinh',
}, {
  'id': 'Q40.1',
  'english': 'Congenital hiatus hernia',
  'vietnamese': 'Thóat vị khe thực quản bẩm sinh',
}, {
  'id': 'Q40.2',
  'english': 'Other specified congenital malformations of stomach',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của dạ dày',
}, {
  'id': 'Q40.3',
  'english': 'Congenital malformation of stomach, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của dạ dày không đặc hiệu',
}, {
  'id': 'Q40.8',
  'english': 'Other specified congenital malformations of upper alimentary tract',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của đường tiêu hóa trên',
}, {
  'id': 'Q40.9',
  'english': 'Congenital malformation of upper alimentary tract, unspecified',
  'vietnamese': 'Dị tật bẩm sinh không đặc hiệu của đường tiêu hóa trên',
}, {
  'id': 'Q41',
  'english': 'Congenital absence, atresia and stenosis of small intestine',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh tiểu tràng',
}, {
  'id': 'Q41.0',
  'english': 'Congenital absence, atresia and stenosis of duodenum',
  'vietnamese': 'Không có, teo và hẹp hành tá tràng bẩm sinh',
}, {
  'id': 'Q41.1',
  'english': 'Congenital absence, atresia and stenosis of jejunum',
  'vietnamese': 'Không có, teo và hẹp hỗng tràng bẩm sinh',
}, {
  'id': 'Q41.2',
  'english': 'Congenital absence, atresia and stenosis of ileum',
  'vietnamese': 'Không có, teo và hẹp hồi tràng bẩm sinh',
}, {
  'id': 'Q41.8',
  'english': 'Congenital absence, atresia and stenosis of other specified parts of small intestine',
  'vietnamese': 'Không có, teo và hẹp mọt phần tiểu tràng xác định khác bẩm sinh',
}, {
  'id': 'Q41.9',
  'english': 'Congenital absence, atresia and stenosis of small intestine, part unspecified',
  'vietnamese': 'Không có, teo và hẹp tiểu tràng phần không đặc hiệu',
}, {
  'id': 'Q42',
  'english': 'Congenital absence, atresia and stenosis of large intestine',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh đại tràng',
}, {
  'id': 'Q42.0',
  'english': 'Congenital absence, atresia and stenosis of rectum with fistula',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh trực tràng có đường rò',
}, {
  'id': 'Q42.1',
  'english': 'Congenital absence, atresia and stenosis of rectum without fistula',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh trực tràng không có đường rò',
}, {
  'id': 'Q42.2',
  'english': 'Congenital absence, atresia and stenosis of anus with fistula',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh hậu môn có đường rò',
}, {
  'id': 'Q42.3',
  'english': 'Congenital absence, atresia and stenosis of anus without fistula',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh hậu môn không có đường rò',
}, {
  'id': 'Q42.8',
  'english': 'Congenital absence, atresia and stenosis of other parts of large intestine',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh các phần khác của đại tràng',
}, {
  'id': 'Q42.9',
  'english': 'Congenital absence, atresia and stenosis of large intestine, part unspecified',
  'vietnamese': 'Không có, teo và hẹp bẩm sinh đại tràng, phần không đặc hiệu',
}, {
  'id': 'Q43',
  'english': 'Other congenital malformations of intestine',
  'vietnamese': 'Các dị tật bẩm sinh khác của ruột',
}, {'id': 'Q43.0', 'english': 'Meckel s diverticulum', 'vietnamese': 'Túi thừa Meckel'}, {
  'id': 'Q43.1',
  'english': 'Hirschsprung s disease',
  'vietnamese': 'Bệnh Hirschsprung',
}, {
  'id': 'Q43.2',
  'english': 'Other congenital functional disorders of colon',
  'vietnamese': 'Các rối loạn chức năng bẩm sinh khác của ruột kết',
}, {
  'id': 'Q43.3',
  'english': 'Congenital malformations of intestinal fixation',
  'vietnamese': 'Các dị tật cố định bẩm sinh ở ruột',
}, {'id': 'Q43.4', 'english': 'Duplication of intestine', 'vietnamese': 'Ruột đôi'}, {
  'id': 'Q43.5',
  'english': 'Ectopic anus',
  'vietnamese': 'Hậu môn lạc chỗ',
}, {
  'id': 'Q43.6',
  'english': 'Congenital fistula of rectum and anus',
  'vietnamese': 'Đường rò bẩm sinh của hậu môn và trực tràng',
}, {'id': 'Q43.7', 'english': 'Persistent cloaca', 'vietnamese': 'Tồn tại ổ nhớp'}, {
  'id': 'Q43.8',
  'english': 'Other specified congenital malformations of intestine',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của ruột',
}, {
  'id': 'Q43.9',
  'english': 'Congenital malformation of intestine, unspecified',
  'vietnamese': 'Dị tật bẩm sinh ruột, không đặc hiệu',
}, {
  'id': 'Q44',
  'english': 'Congenital malformations of gallbladder, bile ducts and liver',
  'vietnamese': 'Các dị tật bẩm sinh của túi mật, đường mật và gan',
}, {
  'id': 'Q44.0',
  'english': 'Agenesis, aplasia and hypoplasia of gallbladder',
  'vietnamese': 'Không phát triển, bất sản và giảm sản túi mật',
}, {
  'id': 'Q44.1',
  'english': 'Other congenital malformations of gallbladder',
  'vietnamese': 'Các dị tật bẩm sinh khác của túi mật',
}, {'id': 'Q44.2', 'english': 'Atresia of bile ducts', 'vietnamese': 'Teo đường mật'}, {
  'id': 'Q44.3',
  'english': 'Congenital stenosis and stricture of bile ducts',
  'vietnamese': 'Hẹp và hẹp khít bẩm sinh khác của đường mật',
}, {'id': 'Q44.4', 'english': 'Choledochal cyst', 'vietnamese': 'U nang ống mật chủ'}, {
  'id': 'Q44.5',
  'english': 'Other congenital malformations of bile ducts',
  'vietnamese': 'Các dị tật bẩm sinh khác của đường mật',
}, {'id': 'Q44.6', 'english': 'Cystic disease of liver', 'vietnamese': 'Các bệnh nang của gan'}, {
  'id': 'Q44.7',
  'english': 'Other congenital malformations of liver',
  'vietnamese': 'Các dị tật bẩm sinh khác của gan',
}, {
  'id': 'Q45',
  'english': 'Other congenital malformations of digestive system',
  'vietnamese': 'Các dị tật bẩm sinh khác của hệ tiêu hóa',
}, {
  'id': 'Q45.0',
  'english': 'Agenesis, aplasia and hypoplasia of pancreas',
  'vietnamese': 'Không phát triển, bất sản và giảm sản tụy',
}, {'id': 'Q45.1', 'english': 'Annular pancreas', 'vietnamese': 'Tụy hình vòng'}, {
  'id': 'Q45.2',
  'english': 'Congenital pancreatic cyst',
  'vietnamese': 'Nang tụy bẩm sinh',
}, {
  'id': 'Q45.3',
  'english': 'Other congenital malformations of pancreas and pancreatic duct',
  'vietnamese': 'Các dị tật bẩm sinh khác của tụy và ống tụy',
}, {
  'id': 'Q45.8',
  'english': 'Other specified congenital malformations of digestive system',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của hệ tiêu hóa',
}, {
  'id': 'Q45.9',
  'english': 'Congenital malformation of digestive system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của hệ tiêu hóa, không đặc hiệu',
}, {
  'id': 'Q50',
  'english': 'Congenital malformations of ovaries, fallopian tubes and broad ligaments',
  'vietnamese': 'Những dị tật bẩm sinh của buồng trứng, vòi trứng và dây chằng rộng',
}, {
  'id': 'Q50.0',
  'english': 'Congenital absence of ovary',
  'vietnamese': 'Không có buồng trứng bẩm sinh',
}, {'id': 'Q50.1', 'english': 'Developmental ovarian cyst', 'vietnamese': 'Nang buồng trứng'}, {
  'id': 'Q50.2',
  'english': 'Congenital torsion of ovary',
  'vietnamese': 'Xoắn bẩm sinh của buồng trứng',
}, {
  'id': 'Q50.3',
  'english': 'Other congenital malformations of ovary',
  'vietnamese': 'Những dị tật bẩm sinh khác của buồng trứng',
}, {
  'id': 'Q50.4',
  'english': 'Embryonic cyst of fallopian tube',
  'vietnamese': 'Nang bào thai của vòi trứng',
}, {
  'id': 'Q50.5',
  'english': 'Embryonic cyst of broad ligament',
  'vietnamese': 'Nang nguồn gốc bào thai của dây chằng rộng',
}, {
  'id': 'Q50.6',
  'english': 'Other congenital malformations of fallopian tube and broad ligament',
  'vietnamese': 'Những dị tật bẩm sinh khác của vòi trứng và dây chằng rộng',
}, {
  'id': 'Q51',
  'english': 'Congenital malformations of uterus and cervix',
  'vietnamese': 'Dị tật bẩm sinh của tử cung và cổ tử cung',
}, {
  'id': 'Q51.0',
  'english': 'Agenesis and aplasia of uterus',
  'vietnamese': 'Bất sản và ngừng phát triển của tử cung',
}, {
  'id': 'Q51.1',
  'english': 'Doubling of uterus with doubling of cervix and vagina',
  'vietnamese': 'Tử cung đôi với cổ tử cung và âm đạo đôi',
}, {
  'id': 'Q51.2',
  'english': 'Other doubling of uterus',
  'vietnamese': 'Các loại tử cung đôi khác',
}, {'id': 'Q51.3', 'english': 'Bicornate uterus', 'vietnamese': 'Tử cung hai sừng'}, {
  'id': 'Q51.4',
  'english': 'Unicornate uterus',
  'vietnamese': 'Tử cung một sừng',
}, {
  'id': 'Q51.5',
  'english': 'Agenesis and aplasia of cervix',
  'vietnamese': 'Bất sản và ngừng phát triển của cổ tử cung',
}, {
  'id': 'Q51.6',
  'english': 'Embryonic cyst of cervix',
  'vietnamese': 'Nang nguồn gốc bào thai của cổ tử cung',
}, {
  'id': 'Q51.7',
  'english': 'Congenital fistulae between uterus and digestive and urinary tracts',
  'vietnamese': 'Rò bẩm sinh giữa tử cung với ống tiêu hóa và đường tiết niệu',
}, {
  'id': 'Q51.8',
  'english': 'Other congenital malformations of uterus and cervix',
  'vietnamese': 'Các dị tật bẩm sinh khác của tử cung và cổ tử cung',
}, {
  'id': 'Q51.9',
  'english': 'Congenital malformation of uterus and cervix, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của tử cung và cổ tử cung không đặc hiệu',
}, {
  'id': 'Q52',
  'english': 'Other congenital malformations of female genitalia',
  'vietnamese': 'Các dị tật bẩm sinh khác của cơ quan sinh dục nữ',
}, {
  'id': 'Q52.0',
  'english': 'Congenital absence of vagina',
  'vietnamese': 'Không có âm đạo bẩm sinh',
}, {'id': 'Q52.1', 'english': 'Doubling of vagina', 'vietnamese': 'Âm đạo đôi'}, {
  'id': 'Q52.2',
  'english': 'Congenital rectovaginal fistula',
  'vietnamese': 'Rò trực tràng âm đạo bẩm sinh',
}, {'id': 'Q52.3', 'english': 'Imperforate hymen', 'vietnamese': 'Màng trinh không thủng'}, {
  'id': 'Q52.4',
  'english': 'Other congenital malformations of vagina',
  'vietnamese': 'Các dị tật bẩm sinh khác của âm đạo',
}, {'id': 'Q52.5', 'english': 'Fusion of labia', 'vietnamese': 'Dính môi lớn'}, {
  'id': 'Q52.6',
  'english': 'Congenital malformation of clitoris',
  'vietnamese': 'Dị tật bẩm sinh của âm vật',
}, {
  'id': 'Q52.7',
  'english': 'Other congenital malformations of vulva',
  'vietnamese': 'Các dị tật bẩm sinh khác của âm hộ',
}, {
  'id': 'Q52.8',
  'english': 'Other specified congenital malformations of female genitalia',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của cơ quan sinh dục nữ',
}, {
  'id': 'Q52.9',
  'english': 'Congenital malformation of female genitalia, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của cơ quan sinh dục nữ không đặc hiệu',
}, {'id': 'Q53', 'english': 'Undescended testicle', 'vietnamese': 'Tinh hoàn lạc chỗ'}, {
  'id': 'Q53.0',
  'english': 'Ectopic testis',
  'vietnamese': 'Tinh hoàn lạc chỗ',
}, {
  'id': 'Q53.1',
  'english': 'Undescended testicle, unilateral',
  'vietnamese': 'Tinh hoàn chưa xuống bìu một bên',
}, {
  'id': 'Q53.2',
  'english': 'Undescended testicle, bilateral',
  'vietnamese': 'Tinh hoàn chưa xuống bìu hai bên',
}, {
  'id': 'Q53.9',
  'english': 'Undescended testicle, unspecified',
  'vietnamese': 'Tinh hoàn chưa xuống bìu, không đặc hiệu',
}, {'id': 'Q54', 'english': 'Hypospadias', 'vietnamese': 'Lỗ đái lệnh thấp'}, {
  'id': 'Q54.0',
  'english': 'Hypospadias, balanic',
  'vietnamese': 'Lỗ đái thấp thể quy đầu',
}, {
  'id': 'Q54.1',
  'english': 'Hypospadias, penile',
  'vietnamese': 'Lỗ đái lệch thấp thể dương vật',
}, {
  'id': 'Q54.2',
  'english': 'Hypospadias, penoscrotal',
  'vietnamese': 'Lỗ đái lệch thấp thể dương vật - bìu',
}, {
  'id': 'Q54.3',
  'english': 'Hypospadias, perineal',
  'vietnamese': 'Lỗ đái lệch thấp ở tầng sinh môn',
}, {'id': 'Q54.4', 'english': 'Congenital chordee', 'vietnamese': 'Cong dương vật bẩm sinh'}, {
  'id': 'Q54.8',
  'english': 'Other hypospadias',
  'vietnamese': 'Các thể lỗ đái lệch thấp khác',
}, {
  'id': 'Q54.9',
  'english': 'Hypospadias, unspecified',
  'vietnamese': 'Lỗ đái lệch thấp không đặc hiệu',
}, {
  'id': 'Q55',
  'english': 'Other congenital malformations of male genital organs',
  'vietnamese': 'Các dị tật bẩm sinh khác của cơ quan sinh dục nam',
}, {
  'id': 'Q55.0',
  'english': 'Absence and aplasia of testis',
  'vietnamese': 'Không có hoặc bất sản tinh hoàn',
}, {
  'id': 'Q55.1',
  'english': 'Hypoplasia of testis and scrotum',
  'vietnamese': 'Giảm sản tinh hoàn và bìu',
}, {
  'id': 'Q55.2',
  'english': 'Other congenital malformations of testis and scrotum',
  'vietnamese': 'Các dị tật bẩm sinh khác của tinh hoàn và bìu',
}, {'id': 'Q55.3', 'english': 'Atresia of vas deferens', 'vietnamese': 'Teo ống dẫn tinh'}, {
  'id': 'Q55.4',
  'english': 'Other congenital malformations of vas deferens, epididymis, seminal vesicles and prostate',
  'vietnamese': 'Các dị tật bẩm sinh khác của ống dẫn tinh, mào tinh, túi tinh và tuyến tiền liệt',
}, {
  'id': 'Q55.5',
  'english': 'Congenital absence and aplasia of penis',
  'vietnamese': 'Thiếu và bất sản dương vật bẩm sinh',
}, {
  'id': 'Q55.6',
  'english': 'Other congenital malformations of penis',
  'vietnamese': 'Dị tật bẩm sinh khác của dương vật',
}, {
  'id': 'Q55.8',
  'english': 'Other specified congenital malformations of male genital organs',
  'vietnamese': 'Dị tật bẩm sinh đặc hiệu khác của cơ quan sinh dục nam',
}, {
  'id': 'Q55.9',
  'english': 'Congenital malformation of male genital organ, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của cơ quan sinh dục nam không đặc hiệu',
}, {
  'id': 'Q56',
  'english': 'Indeterminate sex and pseudohermaphroditism',
  'vietnamese': 'Không xác định giới tính và hội chứng giả lưỡng giới',
}, {
  'id': 'Q56.0',
  'english': 'Hermaphroditism, not elsewhere classified',
  'vietnamese': 'Hội chứng lưỡng giới, không phân loại ở chỗ khác',
}, {
  'id': 'Q56.1',
  'english': 'Male pseudohermaphroditism, not elsewhere classified',
  'vietnamese': 'Hội chứng lưỡng giới giả nam không, phân loại ở chỗ khác',
}, {
  'id': 'Q56.2',
  'english': 'Female pseudohermaphroditism, not elsewhere classified',
  'vietnamese': 'Hội chứng lưỡng giới giả nữ không phân loại ở chỗ khác',
}, {
  'id': 'Q56.3',
  'english': 'Pseudohermaphroditism, unspecified',
  'vietnamese': 'Hội chứng lưỡng giới giả không đặc hiệu',
}, {
  'id': 'Q56.4',
  'english': 'Indeterminate sex, unspecified',
  'vietnamese': 'không đặc hiệu giới tính không đặc hiệu',
}, {
  'id': 'Q60',
  'english': 'Renal agenesis and other reduction defects of kidney',
  'vietnamese': 'Không có thận và các Khuyết tật khác của thận',
}, {
  'id': 'Q60.0',
  'english': 'Renal agenesis, unilateral',
  'vietnamese': 'Không có thận một bên',
}, {
  'id': 'Q60.1',
  'english': 'Renal agenesis, bilateral',
  'vietnamese': 'Không có thận cả hai bên',
}, {
  'id': 'Q60.2',
  'english': 'Renal agenesis, unspecified',
  'vietnamese': 'Không có thận không đặc hiệu',
}, {
  'id': 'Q60.3',
  'english': 'Renal hypoplasia, unilateral',
  'vietnamese': 'Giảm sản thận một bên',
}, {
  'id': 'Q60.4',
  'english': 'Renal hypoplasia, bilateral',
  'vietnamese': 'Giảm sản thận hai bên',
}, {
  'id': 'Q60.5',
  'english': 'Renal hypoplasia, unspecified',
  'vietnamese': 'Giảm sản thận không đặc hiệu',
}, {'id': 'Q60.6', 'english': 'Potter s syndrome', 'vietnamese': 'Hội chứng Potter'}, {
  'id': 'Q61',
  'english': 'Cystic kidney disease',
  'vietnamese': 'Các bệnh nang thận',
}, {
  'id': 'Q61.0',
  'english': 'Congenital single renal cyst',
  'vietnamese': 'Nang thận đơn bẩm sinh',
}, {
  'id': 'Q61.1',
  'english': 'Polycystic kidney, autosomal recessive',
  'vietnamese': 'Bệnh thận đa nang, di truyền lặn trên nhiễm sắc thể thường',
}, {
  'id': 'Q61.2',
  'english': 'Polycystic kidney, autosomal dominant',
  'vietnamese': 'Bệnh thận đa nang, di truyền trội trên nhiễm sắc thể thường',
}, {
  'id': 'Q61.3',
  'english': 'Polycystic kidney, unspecified',
  'vietnamese': 'Thận đa nang không đặc hiệu',
}, {'id': 'Q61.4', 'english': 'Renal dysplasia', 'vietnamese': 'Loạn sản thận'}, {
  'id': 'Q61.5',
  'english': 'Medullary cystic kidney',
  'vietnamese': 'Nang ở tủy thận',
}, {
  'id': 'Q61.8',
  'english': 'Other cystic kidney diseases',
  'vietnamese': 'Các loại bệnh nang thận khác',
}, {
  'id': 'Q61.9',
  'english': 'Cystic kidney disease, unspecified',
  'vietnamese': 'Bệnh nang thận không đặc hiệu',
}, {
  'id': 'Q62',
  'english': 'Congenital obstructive defects of renal pelvis and congenital malformations of ureter',
  'vietnamese': 'Các bệnh lý tắc bẩm sinh của bể thận và các dị tật bẩm sinh của niệu quản',
}, {
  'id': 'Q62.0',
  'english': 'Congenital hydronephrosis',
  'vietnamese': 'Ứ nước thận bẩm sinh',
}, {
  'id': 'Q62.1',
  'english': 'Atresia and stenosis of ureter',
  'vietnamese': 'Teo và hẹp niệu quản',
}, {
  'id': 'Q62.2',
  'english': 'Congenital megaloureter',
  'vietnamese': 'Phình to niệu quản bẩm sinh',
}, {
  'id': 'Q62.3',
  'english': 'Other obstructive defects of renal pelvis and ureter',
  'vietnamese': 'Các bệnh lý tắc khác của bể và niệu quản',
}, {'id': 'Q62.4', 'english': 'Agenesis of ureter', 'vietnamese': 'Không có niệu quản'}, {
  'id': 'Q62.5',
  'english': 'Duplication of ureter',
  'vietnamese': 'Niệu quản đôi',
}, {
  'id': 'Q62.6',
  'english': 'Malposition of ureter',
  'vietnamese': 'Thay đổi vị trí của niệu quản',
}, {
  'id': 'Q62.7',
  'english': 'Congenital vesico-uretero-renal reflux',
  'vietnamese': 'Trào ngược bàng quang - niệu quản - thận',
}, {
  'id': 'Q62.8',
  'english': 'Other congenital malformations of ureter',
  'vietnamese': 'Các dị tật bẩm sinh khác của niệu quản',
}, {
  'id': 'Q63',
  'english': 'Other congenital malformations of kidney',
  'vietnamese': 'Các dị tật bẩm sinh khác của thận',
}, {'id': 'Q63.0', 'english': 'Accessory kidney', 'vietnamese': 'Thận phụ'}, {
  'id': 'Q63.1',
  'english': 'Lobulated, fused and horseshoe kidney',
  'vietnamese': 'Thận móng ngựa, thận dính, thận phân thùy',
}, {'id': 'Q63.2', 'english': 'Ectopic kidney', 'vietnamese': 'Thận lạc chỗ'}, {
  'id': 'Q63.3',
  'english': 'Hyperplastic and giant kidney',
  'vietnamese': 'Thận khổng lồ và tăng sản',
}, {
  'id': 'Q63.8',
  'english': 'Other specified congenital malformations of kidney',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của thận',
}, {
  'id': 'Q63.9',
  'english': 'Congenital malformation of kidney, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của thận không đặc hiệu',
}, {
  'id': 'Q64',
  'english': 'Other congenital malformations of urinary system',
  'vietnamese': 'Các dị tật bẩm sinh khác của hệ tiết niệu',
}, {'id': 'Q64.0', 'english': 'Epispadias', 'vietnamese': 'Lỗ đái lệch cao'}, {
  'id': 'Q64.1',
  'english': 'Exstrophy of urinary bladder',
  'vietnamese': 'Bàng quang lộ ngoài',
}, {
  'id': 'Q64.2',
  'english': 'Congenital posterior urethral valves',
  'vietnamese': 'Van niệu đạo sau bẩm sinh',
}, {
  'id': 'Q64.3',
  'english': 'Other atresia and stenosis of urethra and bladder neck',
  'vietnamese': 'Teo và hẹp niệu đạo và cổ bàng quang bẩm sinh khác',
}, {'id': 'Q64.4', 'english': 'Malformation of urachus', 'vietnamese': 'Dị tật ống niệu rốn'}, {
  'id': 'Q64.5',
  'english': 'Congenital absence of bladder and urethra',
  'vietnamese': 'Không có bẩm sinh bàng quang và niệu đạo',
}, {
  'id': 'Q64.6',
  'english': 'Congenital diverticulum of bladder',
  'vietnamese': 'Túi thừa bẩm sinh của bàng quang',
}, {
  'id': 'Q64.7',
  'english': 'Other congenital malformations of bladder and urethra',
  'vietnamese': 'Các dị tật bẩm sinh khác của bàng quang và niệu đạo',
}, {
  'id': 'Q64.8',
  'english': 'Other specified congenital malformations of urinary system',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của hệ tiết niệu',
}, {
  'id': 'Q64.9',
  'english': 'Congenital malformation of urinary system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của hệ tiết niệu không đặc hiệu',
}, {
  'id': 'Q65',
  'english': 'Congenital deformities of hip',
  'vietnamese': 'Biến dạng bẩm sinh của khớp háng',
}, {
  'id': 'Q65.0',
  'english': 'Congenital dislocation of hip, unilateral',
  'vietnamese': 'Trật khớp háng bẩm sinh, một bên',
}, {
  'id': 'Q65.1',
  'english': 'Congenital dislocation of hip, bilateral',
  'vietnamese': 'Trật khớp háng bẩm sinh, hai bên',
}, {
  'id': 'Q65.2',
  'english': 'Congenital dislocation of hip, unspecified',
  'vietnamese': 'Trật khớp háng bẩm sinh, không đặc hiệu',
}, {
  'id': 'Q65.3',
  'english': 'Congenital subluxation of hip, unilateral',
  'vietnamese': 'Sai khớp háng nhẹ bẩm sinh, một bên',
}, {
  'id': 'Q65.4',
  'english': 'Congenital subluxation of hip, bilateral',
  'vietnamese': 'Sai khớp háng nhẹ bẩm sinh, hai bên',
}, {
  'id': 'Q65.5',
  'english': 'Congenital subluxation of hip, unspecified',
  'vietnamese': 'Sai khớp háng nhẹ bẩm sinh, không đặc hiệu',
}, {'id': 'Q65.6', 'english': 'Unstable hip', 'vietnamese': 'Khớp háng không ổn định'}, {
  'id': 'Q65.8',
  'english': 'Other congenital deformities of hip',
  'vietnamese': 'Các biến dạng bẩm sinh khác của khớp háng',
}, {
  'id': 'Q65.9',
  'english': 'Congenital deformity of hip, unspecified',
  'vietnamese': 'Biến dạng bẩm sinh của khớp háng, không đặc hiệu',
}, {
  'id': 'Q66',
  'english': 'Congenital deformities of feet',
  'vietnamese': 'Các biến dạng bẩm sinh của bàn chân',
}, {'id': 'Q66.0', 'english': 'Talipes equinovarus', 'vietnamese': 'Bàn chân khoèo'}, {
  'id': 'Q66.1',
  'english': 'Talipes calcaneovarus',
  'vietnamese': 'Bàn chân gót vẹo vào trong',
}, {
  'id': 'Q66.2',
  'english': 'Metatarsus varus',
  'vietnamese': 'Xương đốt bàn chân vẹo vào trong',
}, {
  'id': 'Q66.3',
  'english': 'Other congenital varus deformities of feet',
  'vietnamese': 'Các biến dạng vẹo vào trong bẩm sinh khác của bàn chân',
}, {
  'id': 'Q66.4',
  'english': 'Talipes calcaneovalgus',
  'vietnamese': 'Bàn chân gót vẹo ra ngoài',
}, {
  'id': 'Q66.5',
  'english': 'Congenital pes planus',
  'vietnamese': 'Bàn chân bẹt bẩm sinh',
}, {
  'id': 'Q66.6',
  'english': 'Other congenital valgus deformities of feet',
  'vietnamese': 'Các biến dạng vẹo ra bẩm sinh khác của bàn chân',
}, {'id': 'Q66.7', 'english': 'Pes cavus', 'vietnamese': 'Bàn chân lõm'}, {
  'id': 'Q66.8',
  'english': 'Other congenital deformities of feet',
  'vietnamese': 'Các biến dạng bẩm sinh khác của bàn chân',
}, {
  'id': 'Q66.9',
  'english': 'Congenital deformity of feet, unspecified',
  'vietnamese': 'Biến dạng bẩm sinh của bàn chân, không đặc hiệu',
}, {
  'id': 'Q67',
  'english': 'Congenital musculoskeletal deformities of head, face, spine and chest',
  'vietnamese': 'Các biến dạng cơ xương bẩm sinh của đầu, mặt, cột sống và ngực',
}, {'id': 'Q67.0', 'english': 'Facial asymmetry', 'vietnamese': 'Mất cân đối mặt'}, {
  'id': 'Q67.1',
  'english': 'Compression facies',
  'vietnamese': 'Mặt bị ép',
}, {'id': 'Q67.2', 'english': 'Dolichocephaly', 'vietnamese': 'Đầu dài'}, {
  'id': 'Q67.3',
  'english': 'Plagiocephaly',
  'vietnamese': 'Tật sọ nghiêng',
}, {
  'id': 'Q67.4',
  'english': 'Other congenital deformities of skull, face and jaw',
  'vietnamese': 'Các biến dạng bẩm sinh khác của sọ, mặt và xương hàm',
}, {
  'id': 'Q67.5',
  'english': 'Congenital deformity of spine',
  'vietnamese': 'Biến dạng cột sống bẩm sinh',
}, {'id': 'Q67.6', 'english': 'Pectus excavatum', 'vietnamese': 'Ngực lõm'}, {
  'id': 'Q67.7',
  'english': 'Pectus carinatum',
  'vietnamese': 'Ngực lồi',
}, {
  'id': 'Q67.8',
  'english': 'Other congenital deformities of chest',
  'vietnamese': 'Các biến dạng bẩm sinh khác của ngực',
}, {
  'id': 'Q68',
  'english': 'Other congenital musculoskeletal deformities',
  'vietnamese': 'Các biến dạng cơ xương bẩm sinh khác',
}, {
  'id': 'Q68.0',
  'english': 'Congenital deformity of sternocleidomastoid muscle',
  'vietnamese': 'Biến dạng bẩm sinh của cơ ức đòn chũm',
}, {
  'id': 'Q68.1',
  'english': 'Congenital deformity of hand',
  'vietnamese': 'Biến dạng bẩm sinh của bàn tay',
}, {
  'id': 'Q68.2',
  'english': 'Congenital deformity of knee',
  'vietnamese': 'Biến dạng bẩm sinh của đầu gối',
}, {
  'id': 'Q68.3',
  'english': 'Congenital bowing of femur',
  'vietnamese': 'Cong xương đùi bẩm sinh',
}, {
  'id': 'Q68.4',
  'english': 'Congenital bowing of tibia and fibula',
  'vietnamese': 'Xương chầy và xương mác cong bẩm sinh',
}, {
  'id': 'Q68.5',
  'english': 'Congenital bowing of long bones of leg, unspecified',
  'vietnamese': 'Cong bẩm sinh các xương dài của chân, không đặc hiệu',
}, {
  'id': 'Q68.8',
  'english': 'Other specified congenital musculoskeletal deformities',
  'vietnamese': 'Các biến dạng cơ xương bẩm sinh đặc hiệu khác',
}, {'id': 'Q69', 'english': 'Polydactyly', 'vietnamese': 'Tật đa ngón'}, {
  'id': 'Q69.0',
  'english': 'Accessory finger(s)',
  'vietnamese': 'Ngón tay phụ',
}, {'id': 'Q69.1', 'english': 'Accessory thumb(s)', 'vietnamese': 'Ngón cái phụ'}, {
  'id': 'Q69.2',
  'english': 'Accessory toe(s)',
  'vietnamese': 'Các ngón chân phụ',
}, {
  'id': 'Q69.9',
  'english': 'Polydactyly, unspecified',
  'vietnamese': 'Tật đa ngón, không đặc hiệu',
}, {'id': 'Q70', 'english': 'Syndactyly', 'vietnamese': 'Tật dính ngón'}, {
  'id': 'Q70.0',
  'english': 'Fused fingers',
  'vietnamese': 'Các ngón dính nhau',
}, {'id': 'Q70.1', 'english': 'Webbed fingers', 'vietnamese': 'Ngón mảng da'}, {
  'id': 'Q70.2',
  'english': 'Fused toes',
  'vietnamese': 'Dính các ngón chân',
}, {
  'id': 'Q70.3',
  'english': 'Webbed toes',
  'vietnamese': 'Tật dính da ngón đơn thuần không có dính xương',
}, {'id': 'Q70.4', 'english': 'Polysyndactyly', 'vietnamese': 'Dính nhiều ngón'}, {
  'id': 'Q70.9',
  'english': 'Syndactyly, unspecified',
  'vietnamese': 'Tật dính ngón không đặc hiệu',
}, {
  'id': 'Q71',
  'english': 'Reduction defects of upper limb',
  'vietnamese': 'Các Khuyết tật thiếu hụt của chi trên',
}, {
  'id': 'Q71.0',
  'english': 'Congenital complete absence of upper limb(s)',
  'vietnamese': 'Thiếu toàn bộ chi trên bẩm sinh',
}, {
  'id': 'Q71.1',
  'english': 'Congenital absence of upper arm and forearm with hand present',
  'vietnamese': 'Tật thiếu cánh - cẳng tay bẩm sinh có bàn tay',
}, {
  'id': 'Q71.2',
  'english': 'Congenital absence of both forearm and hand',
  'vietnamese': 'Tật thiếu cả hai bàn tay và cẳng tay bẩm sinh',
}, {
  'id': 'Q71.3',
  'english': 'Congenital absence of hand and finger(s)',
  'vietnamese': 'Tật thiếu bàn tay và ngón tay bẩm sinh',
}, {
  'id': 'Q71.4',
  'english': 'Longitudinal reduction defect of radius',
  'vietnamese': 'Khuyết tật thiếu hụt theo chiều dài xương quay',
}, {
  'id': 'Q71.5',
  'english': 'Longitudinal reduction defect of ulna',
  'vietnamese': 'Khuyết tật thiếu hụt theo chiều dài xương trụ',
}, {'id': 'Q71.6', 'english': 'Lobster-claw hand', 'vietnamese': 'Bàn tay hình càng cua'}, {
  'id': 'Q71.8',
  'english': 'Other reduction defects of upper limb(s)',
  'vietnamese': 'Các khuyết tật thiếu hụt khác của chi trên',
}, {
  'id': 'Q71.9',
  'english': 'Reduction defect of upper limb, unspecified',
  'vietnamese': 'Khuyết tật thiếu hụt của chi trên, không đặc hiệu',
}, {
  'id': 'Q72',
  'english': 'Reduction defects of lower limb',
  'vietnamese': 'Các khuyết tật thiếu hụt của chi dưới',
}, {
  'id': 'Q72.0',
  'english': 'Congenital complete absence of lower limb(s)',
  'vietnamese': 'Thiếu hoàn toàn chi dưới bẩm sinh',
}, {
  'id': 'Q72.1',
  'english': 'Congenital absence of thigh and lower leg with foot present',
  'vietnamese': 'Tật thiếu đùi và cẳng chân có bàn chân bẩm sinh',
}, {
  'id': 'Q72.2',
  'english': 'Congenital absence of both lower leg and foot',
  'vietnamese': 'Tật thiếu cẳng chân và bàn chân bẩm sinh',
}, {
  'id': 'Q72.3',
  'english': 'Congenital absence of foot and toe(s)',
  'vietnamese': 'Tật thiếu bàn chân và ngón chân bẩm sinh',
}, {
  'id': 'Q72.4',
  'english': 'Longitudinal reduction defect of femur',
  'vietnamese': 'Khuyết tật thiếu hụt theo chiều dài của xương đùi',
}, {
  'id': 'Q72.5',
  'english': 'Longitudinal reduction defect of tibia',
  'vietnamese': 'Khuyết tật thiếu hụt theo chiều dài xương chầy',
}, {
  'id': 'Q72.6',
  'english': 'Longitudinal reduction defect of fibula',
  'vietnamese': 'Khuyết tật thiếu hụt theo chiều dài xương mác',
}, {'id': 'Q72.7', 'english': 'Split foot', 'vietnamese': 'Bàn chân chẻ'}, {
  'id': 'Q72.8',
  'english': 'Other reduction defects of lower limb(s)',
  'vietnamese': 'Các khuyết tật thiếu hụt khác của chi dưới',
}, {
  'id': 'Q72.9',
  'english': 'Reduction defect of lower limb, unspecified',
  'vietnamese': 'Các khuyết tật thiếu hụt khác của chi dưới không đặc hiệu',
}, {
  'id': 'Q73',
  'english': 'Reduction defects of unspecified limb',
  'vietnamese': 'Các khuyết tật thiếu hụt của chi không đặc hiệu',
}, {
  'id': 'Q73.0',
  'english': 'Congenital absence of unspecified limb(s)',
  'vietnamese': 'Khuyết chi không đặc hiệu bẩm sinh',
}, {
  'id': 'Q73.1',
  'english': 'Phocomelia, unspecified limb(s)',
  'vietnamese': 'Khuyết tật giống hải cẩu, các chi không đặc hiệu',
}, {
  'id': 'Q73.8',
  'english': 'Other reduction defects of unspecified limb(s)',
  'vietnamese': 'Các Khuyết tật thu nhỏ khác của chi không đặc hiệu',
}, {
  'id': 'Q74',
  'english': 'Other congenital malformations of limb(s)',
  'vietnamese': 'Các dị tật bẩm sinh khác của chi',
}, {
  'id': 'Q74.0',
  'english': 'Other congenital malformations of upper limb(s), including shoulder girdle',
  'vietnamese': 'Các dị tật bẩm sinh khác của chi trên, kể cả vòng ngực',
}, {
  'id': 'Q74.1',
  'english': 'Congenital malformation of knee',
  'vietnamese': 'Dị tật bẩm sinh của gối',
}, {
  'id': 'Q74.2',
  'english': 'Other congenital malformations of lower limb(s), including pelvic girdle',
  'vietnamese': 'Các dị tật bẩm sinh khác của chi dưới, kể cả đai chậu',
}, {
  'id': 'Q74.3',
  'english': 'Arthrogryposis multiplex congenita',
  'vietnamese': 'Co cứng đa khớp bẩm sinh',
}, {
  'id': 'Q74.8',
  'english': 'Other specified congenital malformations of limb(s)',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của chi',
}, {
  'id': 'Q74.9',
  'english': 'Unspecified congenital malformation of limb(s)',
  'vietnamese': 'Dị tật bẩm sinh không đặc hiệu của chi',
}, {
  'id': 'Q75',
  'english': 'Other congenital malformations of skull and face bones',
  'vietnamese': 'Các dị tật bẩm sinh khác của xương sọ và xương mặt',
}, {'id': 'Q75.0', 'english': 'Craniosynostosis', 'vietnamese': 'Liền sớm khớp sọ'}, {
  'id': 'Q75.1',
  'english': 'Craniofacial dysostosis',
  'vietnamese': 'Loạn phát xương sọ mặt',
}, {'id': 'Q75.2', 'english': 'Hypertelorism', 'vietnamese': 'Chứng quá cách xa'}, {
  'id': 'Q75.3',
  'english': 'Macrocephaly',
  'vietnamese': 'Tật đầu to',
}, {
  'id': 'Q75.4',
  'english': 'Mandibulofacial dysostosis',
  'vietnamese': 'Loạn phát xương mắt hàm',
}, {
  'id': 'Q75.5',
  'english': 'Oculomandibular dysostosis',
  'vietnamese': 'Loạn phát xương mặt - hàm',
}, {
  'id': 'Q75.8',
  'english': 'Other specified congenital malformations of skull and face bones',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác của xương sọ và mặt',
}, {
  'id': 'Q75.9',
  'english': 'Congenital malformation of skull and face bones, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của xương sọ và xương mặt',
}, {
  'id': 'Q76',
  'english': 'Congenital malformations of spine and bony thorax',
  'vietnamese': 'Các dị tật bẩm sinh của xương sống và xương lồng ngực',
}, {
  'id': 'Q76.0',
  'english': 'Spina bifida occulta',
  'vietnamese': 'Đốt sống tách đôi kín đáo',
}, {
  'id': 'Q76.1',
  'english': 'Klippel-Feil syndrome',
  'vietnamese': 'Hội chứng Klippel - Feil',
}, {
  'id': 'Q76.2',
  'english': 'Congenital spondylolisthesis',
  'vietnamese': 'Chứng trượt đốt sống bẩm sinh',
}, {
  'id': 'Q76.3',
  'english': 'Congenital scoliosis due to congenital bony malformation',
  'vietnamese': 'Vẹo cột sống bẩm sinh do dị tật xương bẩm sinh',
}, {
  'id': 'Q76.4',
  'english': 'Other congenital malformations of spine, not associated with scoliosis',
  'vietnamese': 'Các dị tật bẩm sinh khác của xương sống, không liên quan đến vẹo cột sống',
}, {'id': 'Q76.5', 'english': 'Cervical rib', 'vietnamese': 'Xương sườn cổ'}, {
  'id': 'Q76.6',
  'english': 'Other congenital malformations of ribs',
  'vietnamese': 'Các dị tật bẩm sinh khác ở vùng cổ',
}, {
  'id': 'Q76.7',
  'english': 'Congenital malformation of sternum',
  'vietnamese': 'Dị tật bẩm sinh của xương ức',
}, {
  'id': 'Q76.8',
  'english': 'Other congenital malformations of bony thorax',
  'vietnamese': 'Các dị tật bẩm sinh khác của xương ngực',
}, {
  'id': 'Q76.9',
  'english': 'Congenital malformation of bony thorax, unspecified',
  'vietnamese': 'Các dị tật bẩm sinh của xương ngực không đặc hiệu',
}, {
  'id': 'Q77',
  'english': 'Osteochondrodysplasia with defects of growth of tubular bones and spine',
  'vietnamese': 'Loạn sản xương sụn với các Khuyết tật trưởng thành của các xương ống và cột sống',
}, {'id': 'Q77.0', 'english': 'Achondrogenesis', 'vietnamese': 'Vô sản sụn'}, {
  'id': 'Q77.1',
  'english': 'Thanatophoric short stature',
  'vietnamese': 'Tầm vóc ngắn',
}, {'id': 'Q77.2', 'english': 'Short rib syndrome', 'vietnamese': 'Hội chứng xương sườn ngắn'}, {
  'id': 'Q77.3',
  'english': 'Chondrodysplasia punctata',
  'vietnamese': 'Loạn sản sụn từng đám nhỏ',
}, {'id': 'Q77.4', 'english': 'Achondroplasia', 'vietnamese': 'Vô sản sụn'}, {
  'id': 'Q77.5',
  'english': 'Dystrophic dysplasia',
  'vietnamese': 'Loạn sản gây trật khớp',
}, {
  'id': 'Q77.6',
  'english': 'Chondroectodermal dysplasia',
  'vietnamese': 'Loạn sản sụn ngoại bì',
}, {
  'id': 'Q77.7',
  'english': 'Spondyloepiphyseal dysplasia',
  'vietnamese': 'Loạn sản đầu đốt xương cột sống',
}, {
  'id': 'Q77.8',
  'english': 'Other osteochondrodysplasia with defects of growth of tubular bones and spine',
  'vietnamese': 'Các loạn sản xương sụn khác với các khuyết tật trưởng thành của các xương ống và cột sống',
}, {
  'id': 'Q77.9',
  'english': 'Osteochondrodysplasia with defects of growth of tubular bones and spine, unspecified',
  'vietnamese': 'Các loạn sản xương sụn với các khuyết tật trưởng thành của các xương ống và cột sống không đặc hiệu',
}, {
  'id': 'Q78',
  'english': 'Other osteochondrodysplasias',
  'vietnamese': 'Các loạn sản xương sụn khác',
}, {
  'id': 'Q78.0',
  'english': 'Osteogenesis imperfecta',
  'vietnamese': 'Quá trình tạo xương không hoàn toàn',
}, {
  'id': 'Q78.1',
  'english': 'Polyostotic fibrous dysplasia',
  'vietnamese': 'Loạn sản sợi - đa xương',
}, {'id': 'Q78.2', 'english': 'Osteopetrosis', 'vietnamese': 'Xương hóa đá'}, {
  'id': 'Q78.3',
  'english': 'Progressive diaphyseal dysplasia',
  'vietnamese': 'Loạn sản thân xương tiến triển',
}, {'id': 'Q78.4', 'english': 'Enchondromatosis', 'vietnamese': 'Loạn sản nội sụn'}, {
  'id': 'Q78.5',
  'english': 'Metaphyseal dysplasia',
  'vietnamese': 'Loạn sản hành xương',
}, {
  'id': 'Q78.6',
  'english': 'Multiple congenital exostoses',
  'vietnamese': 'Lồi xương bẩm sinh nhiều nơi',
}, {
  'id': 'Q78.8',
  'english': 'Other specified osteochondrodysplasias',
  'vietnamese': 'Các loạn sản xương sụn đặc hiệu khác',
}, {
  'id': 'Q78.9',
  'english': 'Osteochondrodysplasia, unspecified',
  'vietnamese': 'Loạn sản xương sụn không đặc hiệu',
}, {
  'id': 'Q79',
  'english': 'Congenital malformations of the musculoskeletal system, not elsewhere classified',
  'vietnamese': 'Các dị tật bẩm sinh của hệ cơ xương, chưa được phân loại ở chỗ khác',
}, {
  'id': 'Q79.0',
  'english': 'Congenital diaphragmatic hernia',
  'vietnamese': 'Thóat vị hoành bẩm sinh',
}, {
  'id': 'Q79.1',
  'english': 'Other congenital malformations of diaphragm',
  'vietnamese': 'Các dị tật bẩm sinh khác của cơ hoành',
}, {'id': 'Q79.2', 'english': 'Exomphalos', 'vietnamese': 'Lồi rốn'}, {
  'id': 'Q79.3',
  'english': 'Gastroschisis',
  'vietnamese': 'Khe hở thành bụng',
}, {'id': 'Q79.4', 'english': 'Prune belly syndrome', 'vietnamese': 'Hội chứng bụng không rõ'}, {
  'id': 'Q79.5',
  'english': 'Other congenital malformations of abdominal wall',
  'vietnamese': 'Các dị tật bẩm sinh khác của thành bụng',
}, {
  'id': 'Q79.6',
  'english': 'Ehlers-Danlos syndrome',
  'vietnamese': 'Hội chứng Ehiers - Vanlos',
}, {
  'id': 'Q79.8',
  'english': 'Other congenital malformations of musculoskeletal system',
  'vietnamese': 'Các dị tật bẩm sinh khác của hệ cơ xương',
}, {
  'id': 'Q79.9',
  'english': 'Congenital malformation of musculoskeletal system, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của hệ cơ xương, không đặc hiệu',
}, {'id': 'Q80', 'english': 'Congenital ichthyosis', 'vietnamese': 'Bệnh vảy cá bẩm sinh'}, {
  'id': 'Q80.0',
  'english': 'Ichthyosis vulgaris',
  'vietnamese': 'Bệnh vảy cá thông thường',
}, {
  'id': 'Q80.1',
  'english': 'X-linked ichthyosis',
  'vietnamese': 'Bệnh vảy cá liên kết nhiễm sắc thể X',
}, {'id': 'Q80.2', 'english': 'Lamellar ichthyosis', 'vietnamese': 'Bệnh vảy cá dạng lá'}, {
  'id': 'Q80.3',
  'english': 'Congenital bullous ichthyosiform erythroderma',
  'vietnamese': 'Chứng đỏ da dạng vảy cá bóng bẩm sinh',
}, {'id': 'Q80.4', 'english': 'Harlequin fetus', 'vietnamese': 'Thai mắc bệnh vảy cá'}, {
  'id': 'Q80.8',
  'english': 'Other congenital ichthyosis',
  'vietnamese': 'Bệnh vảy cá bẩm sinh khác',
}, {
  'id': 'Q80.9',
  'english': 'Congenital ichthyosis, unspecified',
  'vietnamese': 'Bệnh vảy cá bẩm sinh không đặc hiệu',
}, {'id': 'Q81', 'english': 'Epidermolysis bullosa', 'vietnamese': 'Bong biểu bì bọng nước'}, {
  'id': 'Q81.0',
  'english': 'Epidermolysis bullosa simplex',
  'vietnamese': 'Bong biểu bì bọng nước giản đơn',
}, {
  'id': 'Q81.1',
  'english': 'Epidermolysis bullosa letalis',
  'vietnamese': 'Bong biểu bì bọng nước dạng gây tử vong',
}, {
  'id': 'Q81.2',
  'english': 'Epidermolysis bullosa dystrophica',
  'vietnamese': 'Loạn dưỡng do bong biểu bì bọng nước',
}, {
  'id': 'Q81.8',
  'english': 'Other epidermolysis bullosa',
  'vietnamese': 'Bong biểu bì bọng nước khác',
}, {
  'id': 'Q81.9',
  'english': 'Epidermolysis bullosa, unspecified',
  'vietnamese': 'Bong biểu bì không đặc hiệu',
}, {
  'id': 'Q82',
  'english': 'Other congenital malformations of skin',
  'vietnamese': 'Dị tật bẩm sinh khác về da',
}, {
  'id': 'Q82.0',
  'english': 'Hereditary lymphoedema',
  'vietnamese': 'Phù bạch huyết di truyền',
}, {
  'id': 'Q82.1',
  'english': 'Xeroderma pigmentosum',
  'vietnamese': 'Bệnh khô da nhiễm sắc tố',
}, {'id': 'Q82.2', 'english': 'Mastocytosis', 'vietnamese': 'Bệnh dưỡng bào'}, {
  'id': 'Q82.3',
  'english': 'Incontinentia pigmenti',
  'vietnamese': 'Sắc tố không kiềm chế được',
}, {
  'id': 'Q82.4',
  'english': 'Ectodermal dysplasia (anhidrotic)',
  'vietnamese': 'Loạn sản ngoại bì (làm giảm tiết mồ hôi)',
}, {
  'id': 'Q82.5',
  'english': 'Congenital non-neoplastic naevus',
  'vietnamese': 'Nê-vi bẩm sinh không tạo u',
}, {
  'id': 'Q82.8',
  'english': 'Other specified congenital malformations of skin',
  'vietnamese': 'Các dị tật về da bẩm sinh, đặc hiệu khác',
}, {
  'id': 'Q82.9',
  'english': 'Congenital malformation of skin, unspecified',
  'vietnamese': 'Dị tật bẩm sinh về da không đặc hiệu',
}, {
  'id': 'Q83',
  'english': 'Congenital malformations of breast',
  'vietnamese': 'Dị tật bẩm sinh của vú',
}, {
  'id': 'Q83.0',
  'english': 'Congenital absence of breast with absent nipple',
  'vietnamese': 'Thiếu vú và núm vú bẩm sinh',
}, {'id': 'Q83.1', 'english': 'Accessory breast', 'vietnamese': 'Vú phụ'}, {
  'id': 'Q83.2',
  'english': 'Absent nipple',
  'vietnamese': 'Thiếu núm vú',
}, {'id': 'Q83.3', 'english': 'Accessory nipple', 'vietnamese': 'Núm vú phụ'}, {
  'id': 'Q83.8',
  'english': 'Other congenital malformations of breast',
  'vietnamese': 'Dị tật bẩm sinh khác của vú',
}, {
  'id': 'Q83.9',
  'english': 'Congenital malformation of breast, unspecified',
  'vietnamese': 'Dị tật bẩm sinh của vú không đặc hiệu',
}, {
  'id': 'Q84',
  'english': 'Other congenital malformations of integument',
  'vietnamese': 'Dị tật bẩm sinh khác của bộ phận bao bọc',
}, {'id': 'Q84.0', 'english': 'Congenital alopecia', 'vietnamese': 'Rụng lông tóc bẩm sinh'}, {
  'id': 'Q84.1',
  'english': 'Congenital morphological disturbances of hair, not elsewhere classified',
  'vietnamese': 'Rối loạn bẩm sinh hình thái của tóc mà không xếp loại ở chỗ khác',
}, {
  'id': 'Q84.2',
  'english': 'Other congenital malformations of hair',
  'vietnamese': 'Dị tật bẩm sinh khác của lông tóc',
}, {'id': 'Q84.3', 'english': 'Anonychia', 'vietnamese': 'Tật không móng'}, {
  'id': 'Q84.4',
  'english': 'Congenital leukonychia',
  'vietnamese': 'Móng đốm trắng bẩm sinh',
}, {
  'id': 'Q84.5',
  'english': 'Enlarged and hypertrophic nails',
  'vietnamese': 'Móng to và phì đại',
}, {
  'id': 'Q84.6',
  'english': 'Other congenital malformations of nails',
  'vietnamese': 'Dị tật bẩm sinh khác của móng',
}, {
  'id': 'Q84.8',
  'english': 'Other specified congenital malformations of integument',
  'vietnamese': 'Dị tật bẩm sinh đặc hiệu khác của bộ phận bao bọc',
}, {
  'id': 'Q84.9',
  'english': 'Congenital malformation of integument, unspecified',
  'vietnamese': 'Dị tật bẩm sinh khác của bộ phận bao bọc không đặc hiệu',
}, {
  'id': 'Q85',
  'english': 'Phakomatoses, not elsewhere classified',
  'vietnamese': 'Hội chứng u thần kinh - da ngoại bì, chưa được phân loại',
}, {
  'id': 'Q85.0',
  'english': 'Neurofibromatosis (nonmalignant)',
  'vietnamese': 'U xơ thần kinh (lành tính)',
}, {'id': 'Q85.1', 'english': 'Tuberous sclerosis', 'vietnamese': 'Bệnh xơ não củ'}, {
  'id': 'Q85.8',
  'english': 'Other phakomatoses, not elsewhere classified',
  'vietnamese': 'Hội chứng u thần kinh da ngoại bì khác, chưa được phân loại',
}, {
  'id': 'Q85.9',
  'english': 'Phakomatosis, unspecified',
  'vietnamese': 'Hội chứng u thần kinh da ngoại bì không đặc hiệu',
}, {
  'id': 'Q86',
  'english': 'Congenital malformation syndromes due to known exogenous causes, not elsewhere classified',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh do những nguyên nhân bên ngoài đã biết, chưa được phân loại',
}, {
  'id': 'Q86.0',
  'english': 'Fetal alcohol syndrome (dysmorphic)',
  'vietnamese': 'Hội chứng cồn bào thai (dị hình)',
}, {
  'id': 'Q86.1',
  'english': 'Fetal hydantoin syndrome',
  'vietnamese': 'Hội chứng hydantoin bào thai',
}, {
  'id': 'Q86.2',
  'english': 'Dysmorphism due to warfarin',
  'vietnamese': 'Dị hình do warfarin',
}, {
  'id': 'Q86.8',
  'english': 'Other congenital malformation syndromes due to known exogenous causes',
  'vietnamese': 'Các dị tật bẩm sinh khác do các nguyên nhân bên ngoài đã biết',
}, {
  'id': 'Q87',
  'english': 'Other specified congenital malformation syndromes affecting multiple systems',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh khác gây tổn thương nhiều hệ thống',
}, {
  'id': 'Q87.0',
  'english': 'Congenital malformation syndromes predominantly affecting facial appearance',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh gây tổn thương chủ yếu ở hình dạng của mặt',
}, {
  'id': 'Q87.1',
  'english': 'Congenital malformation syndromes predominantly associated with short stature',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh liên quan chủ yếu đến thân hình ngắn',
}, {
  'id': 'Q87.2',
  'english': 'Congenital malformation syndromes predominantly involving limbs',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh liên quan chủ yếu đến các chi',
}, {
  'id': 'Q87.3',
  'english': 'Congenital malformation syndromes involving early overgrowth',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh có phát triển sớm quá mức',
}, {'id': 'Q87.4', 'english': 'Marfan s syndrome', 'vietnamese': 'Hội chứng Marfan'}, {
  'id': 'Q87.5',
  'english': 'Other congenital malformation syndromes with other skeletal changes',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh khác đi kèm các thay đổi xương khác',
}, {
  'id': 'Q87.8',
  'english': 'Other specified congenital malformation syndromes, not elsewhere classified',
  'vietnamese': 'Các hội chứng dị tật bẩm sinh đặc hiệu khác, chưa phân loại',
}, {
  'id': 'Q89',
  'english': 'Other congenital malformations, not elsewhere classified',
  'vietnamese': 'Các dị tật bẩm sinh khác, chưa phân loại',
}, {
  'id': 'Q89.0',
  'english': 'Congenital malformations of spleen',
  'vietnamese': 'Các dị tật bẩm sinh của lách',
}, {
  'id': 'Q89.1',
  'english': 'Congenital malformations of adrenal gland',
  'vietnamese': 'Các dị tật bẩm sinh của tuyến thượng thận',
}, {
  'id': 'Q89.2',
  'english': 'Congenital malformations of other endocrine glands',
  'vietnamese': 'Các dị tật bẩm sinh của các tuyến nội tiết khác',
}, {'id': 'Q89.3', 'english': 'Situs inversus', 'vietnamese': 'Đảo ngược phủ tạng'}, {
  'id': 'Q89.4',
  'english': 'Conjoined twins',
  'vietnamese': 'Sinh đôi dính nhau',
}, {
  'id': 'Q89.7',
  'english': 'Multiple congenital malformations, not elsewhere classified',
  'vietnamese': 'Các đa dị tật bẩm sinh khác, chưa phân loại',
}, {
  'id': 'Q89.8',
  'english': 'Other specified congenital malformations',
  'vietnamese': 'Các dị tật bẩm sinh đặc hiệu khác',
}, {
  'id': 'Q89.9',
  'english': 'Congenital malformation, unspecified',
  'vietnamese': 'Dị tật bẩm sinh không đặc hiệu',
}, {'id': 'Q90', 'english': 'Down s syndrome', 'vietnamese': 'Hội chứng Down'}, {
  'id': 'Q90.0',
  'english': 'Trisomy 21, meiotic nondisjunction',
  'vietnamese': 'Ba nhiễm sắc thể, giảm phân không phân ly',
}, {
  'id': 'Q90.1',
  'english': 'Trisomy 21, mosaicism (mitotic nondisjunction)',
  'vietnamese': 'Ba nhiễm sắc thể, thể khảm (gián phân không phân ly)',
}, {
  'id': 'Q90.2',
  'english': 'Trisomy 21, translocation',
  'vietnamese': 'Ba nhiễm sắc thể, chuyển đoạn',
}, {
  'id': 'Q90.9',
  'english': 'Down s syndrome, unspecified',
  'vietnamese': 'Hội chứng Down không đặc hiệu',
}, {
  'id': 'Q91',
  'english': 'Edwards syndrome and Patau s syndrome',
  'vietnamese': 'Hội chứng Edwards và hội chứng Patau',
}, {
  'id': 'Q91.0',
  'english': 'Trisomy 18, meiotic nondisjunction',
  'vietnamese': 'Ba nhiễm sắc thể 18, giảm phân không phân ly',
}, {
  'id': 'Q91.1',
  'english': 'Trisomy 18, mosaicism (mitotic nondisjunction)',
  'vietnamese': 'Ba nhiễm sắc thể, thể khảm (gián phân không phân ly)',
}, {
  'id': 'Q91.2',
  'english': 'Trisomy 18, translocation',
  'vietnamese': 'Ba nhiễm sắc thể, chuyển đoạn',
}, {
  'id': 'Q91.3',
  'english': 'Edwards syndrome, unspecified',
  'vietnamese': 'Hội chứng Edward không đặc hiệu',
}, {
  'id': 'Q91.4',
  'english': 'Trisomy 13, meiotic nondisjunction',
  'vietnamese': 'Ba nhiễm sắc thể 13, không phân ly khi giảm phân',
}, {
  'id': 'Q91.5',
  'english': 'Trisomy 13, mosaicism (mitotic nondisjunction)',
  'vietnamese': 'Ba nhiễm sắc thể 13, thể khảm (không phân ly khi gián phân)',
}, {
  'id': 'Q91.6',
  'english': 'Trisomy 13, translocation',
  'vietnamese': 'Ba nhiễm sắc thể 13, chuyển đoạn',
}, {
  'id': 'Q91.7',
  'english': 'Patau s syndrome, unspecified',
  'vietnamese': 'Hội chứng Patau, không đặc hiệu',
}, {
  'id': 'Q92',
  'english': 'Other trisomies and partial trisomies of the autosomes, not elsewhere classified',
  'vietnamese': 'Ba nhiễm sắc thể và một phần ba nhiễm sắc thể khác của nhiễm sắc thể thường, không được xếp loại chỗ khác',
}, {
  'id': 'Q92.0',
  'english': 'Whole chromosome trisomy, meiotic nondisjunction',
  'vietnamese': 'Ba nhiễm sắc thể hoàn toàn, không phân ly khi giảm phân',
}, {
  'id': 'Q92.1',
  'english': 'Whole chromosome trisomy, mosaicism (mitotic nondisjunction)',
  'vietnamese': 'Ba nhiễm sắc thể hoàn toàn, thể khảm (không phân ly khi gián phân)',
}, {
  'id': 'Q92.2',
  'english': 'Major partial trisomy',
  'vietnamese': 'Ba nhiễm sắc thể một phần lớn',
}, {
  'id': 'Q92.3',
  'english': 'Minor partial trisomy',
  'vietnamese': 'Ba nhiễm sắc thể một phần nhỏ',
}, {
  'id': 'Q92.4',
  'english': 'Duplications seen only at prometaphase',
  'vietnamese': 'Sự nhân đôi chỉ thấy ở tiền pha giữa (tiền trung kỳ)',
}, {
  'id': 'Q92.5',
  'english': 'Duplications with other complex rearrangements',
  'vietnamese': 'Nhân đôi cùng với sự sắp xếp lại các phức hợp khác',
}, {
  'id': 'Q92.6',
  'english': 'Extra marker chromosomes',
  'vietnamese': 'Nhiễm sắc thể có dấu ấn ngoài',
}, {'id': 'Q92.7', 'english': 'Triploidy and polyploidy', 'vietnamese': 'Tam bội và đa bội'}, {
  'id': 'Q92.8',
  'english': 'Other specified trisomies and partial trisomies of autosomes',
  'vietnamese': 'Ba nhiễm sắc thể đặc hiệu khác và ba nhiễm sắc thể một phần của nhiễm sắc thể thường',
}, {
  'id': 'Q92.9',
  'english': 'Trisomy and partial trisomy of autosomes, unspecified',
  'vietnamese': 'Ba nhiễm sắc thể và một phần ba nhiễm sắc thể của nhiễm sắc thể thường, không đặc hiệu',
}, {
  'id': 'Q93',
  'english': 'Monosomies and deletions from the autosomes, not elsewhere classified',
  'vietnamese': 'Đơn nhiễm sắc thể và thiếu một đoạn của nhiễm sắc thể thường, không được xếp loại chỗ khác',
}, {
  'id': 'Q93.0',
  'english': 'Whole chromosome monosomy, meiotic nondisjunction',
  'vietnamese': 'Đơn nhiễm sắc thể hoàn toàn không phân ly khi giảm phân',
}, {
  'id': 'Q93.1',
  'english': 'Whole chromosome monosomy, mosaicism (mitotic nondisjunction)',
  'vietnamese': 'Đơn nhiễm sắc thể hoàn toàn, thể khảm (không phân ly khi gián phân)',
}, {
  'id': 'Q93.2',
  'english': 'Chromosome replaced with ring or dicentric',
  'vietnamese': 'Nhiễm sắc thể vòng hay hai trung tâm',
}, {
  'id': 'Q93.3',
  'english': 'Deletion of short arm of chromosome 4',
  'vietnamese': 'Thiếu cánh ngắn nhiễm sắc thể số 4',
}, {
  'id': 'Q93.4',
  'english': 'Deletion of short arm of chromosome 5',
  'vietnamese': 'Thiếu cánh ngắn nhiễm sắc thể số 5',
}, {
  'id': 'Q93.5',
  'english': 'Other deletions of part of a chromosome',
  'vietnamese': 'Thiếu đoạn khác của nhiễm sắc thể',
}, {
  'id': 'Q93.6',
  'english': 'Deletions seen only at prometaphase',
  'vietnamese': 'Thiếu đoạn chỉ thấy ở tiền pha giữa (tiền trung kỳ)',
}, {
  'id': 'Q93.7',
  'english': 'Deletions with other complex rearrangements',
  'vietnamese': 'Thiếu đoạn với sắp xếp lại các phức hợp khác',
}, {
  'id': 'Q93.8',
  'english': 'Other deletions from the autosomes',
  'vietnamese': 'Thiếu đoạn của nhiễm sắc thể thường khác',
}, {
  'id': 'Q93.9',
  'english': 'Deletion from autosomes, unspecified',
  'vietnamese': 'Thiếu đoạn của nhiễm sắc thể thường, không đặc hiệu',
}, {
  'id': 'Q95',
  'english': 'Balanced rearrangements and structural markers, not elsewhere classified',
  'vietnamese': 'Sắp xếp lại cân bằng và dấu ấn cấu trúc, không xếp loại ở chỗ khác',
}, {
  'id': 'Q95.0',
  'english': 'Balanced translocation and insertion in normal individual',
  'vietnamese': 'Chuyển và gắn đoạn cân bằng ở cá thể bình thường',
}, {
  'id': 'Q95.1',
  'english': 'Chromosome inversion in normal individual',
  'vietnamese': 'Đảo đoạn nhiễm sắc thể ở cá thể bình thường',
}, {
  'id': 'Q95.2',
  'english': 'Balanced autosomal rearrangement in abnormal individual',
  'vietnamese': 'Sắp xếp lại cân bằng nhiễm sắc thể thường ở cá thể không bình thường',
}, {
  'id': 'Q95.3',
  'english': 'Balanced sex/autosomal rearrangement in abnormal individual',
  'vietnamese': 'Sắp xếp lại cân bằng nhiễm sắc thể thường/nhiễm sắc thể giới tính ở cá thể không bình thường',
}, {
  'id': 'Q95.4',
  'english': 'Individuals with marker heterochromatin',
  'vietnamese': 'Cá thể có dấu ấn chất dị nhiễm sắt',
}, {
  'id': 'Q95.5',
  'english': 'Individuals with autosomal fragile site',
  'vietnamese': 'Cá thể có đoạn nhiễm sắc thể thường dễ gẫy',
}, {
  'id': 'Q95.8',
  'english': 'Other balanced rearrangements and structural markers',
  'vietnamese': 'Sắp xếp lại cân bằng và dấu ấn cấu trúc khác',
}, {
  'id': 'Q95.9',
  'english': 'Balanced rearrangement and structural marker, unspecified',
  'vietnamese': 'Sắp xếp lại cân bằng và dấu ấn cấu trúc, không đặc hiệu',
}, {'id': 'Q96', 'english': 'Turner s syndrome', 'vietnamese': 'Hội chứng Turner'}, {
  'id': 'Q96.0',
  'english': 'Karyotype 45,X',
  'vietnamese': 'Kiểu nhiễm sắc thể 45,X',
}, {
  'id': 'Q96.1',
  'english': 'Karyotype 46,X iso (Xq)',
  'vietnamese': 'Kiểu nhiễm sắc thể 46, X iso (Xq)',
}, {
  'id': 'Q96.2',
  'english': 'Karyotype 46,X with abnormal sex chromosome, except iso (Xq)',
  'vietnamese': 'Kiểu nhiễm sắc thể 46,X với nhiễm sắc thể giới tính bất thường, trừ iso (Xq)',
}, {
  'id': 'Q96.3',
  'english': 'Mosaicism, 45,X/46,XX or XY',
  'vietnamese': 'Thể khảm, 45,X/46,XX hay XY',
}, {
  'id': 'Q96.4',
  'english': 'Mosaicism, 45,X/other cell line(s) with abnormal sex chromosome',
  'vietnamese': 'Thể khảm, 45,X /dòng tế bào khác có nhiễm sắc thể giới tính bất thường',
}, {
  'id': 'Q96.8',
  'english': 'Other variants of Turner s syndrome',
  'vietnamese': 'Các dạng khác của hội chứng Turner',
}, {
  'id': 'Q96.9',
  'english': 'Turner syndrome, unspecified',
  'vietnamese': 'Hội chứng Turner, không đặc hiệu',
}, {
  'id': 'Q97',
  'english': 'Other sex chromosome abnormalities, female phenotype, not elsewhere classified',
  'vietnamese': 'Bất thường nhiễm sắc thể giới tính khác, kiểu hình nữ, không xếp loại được ở chỗ khác',
}, {'id': 'Q97.0', 'english': 'Karyotype 47,XXX', 'vietnamese': 'Kiểu nhiễm sắc thể 47,XXX'}, {
  'id': 'Q97.1',
  'english': 'Female with more than three X chromosomes',
  'vietnamese': 'Nữ có hơn 3 nhiễm sắc thể X',
}, {
  'id': 'Q97.2',
  'english': 'Mosaicism, lines with various numbers of X chromosomes',
  'vietnamese': 'Thể khảm, dòng có nhiễm sắc thể X khác nhau',
}, {
  'id': 'Q97.3',
  'english': 'Female with 46,XY karyotype',
  'vietnamese': 'Nữ có kiểu nhiễm sắc thể 46,XY',
}, {
  'id': 'Q97.8',
  'english': 'Other specified sex chromosome abnormalities, female phenotype',
  'vietnamese': 'Kiểu hình nữ có bất thường nhiễm sắc thể giới tính đặc hiệu khác',
}, {
  'id': 'Q97.9',
  'english': 'Sex chromosome abnormality, female phenotype, unspecified',
  'vietnamese': 'Kiểu hình nữ có bất thường nhiễm sắc thể giới tính, không đặc hiệu',
}, {
  'id': 'Q98',
  'english': 'Other sex chromosome abnormalities, male phenotype, not elsewhere classified',
  'vietnamese': 'Bất thường nhiễm sắc thể giới tính khác, kiểu hình nam không xếp loại được ở chỗ khác',
}, {
  'id': 'Q98.0',
  'english': 'Klinefelter s syndrome karyotype 47,XXY',
  'vietnamese': 'Hội chứng Kilne feiter với kiểu nhiễm sắc thể 47,XXY',
}, {
  'id': 'Q98.1',
  'english': 'Klinefelter s syndrome, male with more than two X chromosomes',
  'vietnamese': 'Hội chứng Kilne feiter, nam có hơn 2 nhiễm sắc thể X',
}, {
  'id': 'Q98.2',
  'english': 'Klinefelter s syndrome, male with 46,XX karyotype',
  'vietnamese': 'Hội chứng Kilne feiter, nam có kiểu nhiễm sắc thể 46,XX',
}, {
  'id': 'Q98.3',
  'english': 'Other male with 46,XX karyotype',
  'vietnamese': 'Nam khác với kiểu nhiễm sắc thể 46,XX',
}, {
  'id': 'Q98.4',
  'english': 'Klinefelter s syndrome, unspecified',
  'vietnamese': 'Hội chứng Kilne feiter không đặc hiệu',
}, {'id': 'Q98.5', 'english': 'Karyotype 47,XYY', 'vietnamese': 'Kiểu nhiễm sắc thể 47,XYY'}, {
  'id': 'Q98.6',
  'english': 'Male with structurally abnormal sex chromosome',
  'vietnamese': 'Nam có cấu trúc nhiễm sắc thể giới tính bất thường',
}, {
  'id': 'Q98.7',
  'english': 'Male with sex chromosome mosaicism',
  'vietnamese': 'Nam có thể khảm nhiễm sắc thể giới tính',
}, {
  'id': 'Q98.8',
  'english': 'Other specified sex chromosome abnormalities, male phenotype',
  'vietnamese': 'Các bất thường đặc hiệu của nhiễm sắc thể giới tính, kiểu hình nam',
}, {
  'id': 'Q98.9',
  'english': 'Sex chromosome abnormality, male phenotype, unspecified',
  'vietnamese': 'Bất thường nhiễm sắc thể giới tính, kiểu hiện nam, không đặc hiệu',
}, {
  'id': 'Q99',
  'english': 'Other chromosome abnormalities, not elsewhere classified',
  'vietnamese': 'Bất thường nhiễm sắc thể khác không xếp loại chỗ khác',
}, {'id': 'Q99.0', 'english': 'Chimera 46,XX/46,XY', 'vietnamese': 'Thể khảm 46, XX/46,XY'}, {
  'id': 'Q99.1',
  'english': '46,XX true hermaphrodite',
  'vietnamese': 'Lưỡng tính thật 46,XX',
}, {'id': 'Q99.2', 'english': 'Fragile X chromosome', 'vietnamese': 'Nhiễm sắc thể X dễ gẫy'}, {
  'id': 'Q99.8',
  'english': 'Other specified chromosome abnormalities',
  'vietnamese': 'Bất thường nhiễm sắc thể đặc hiệu khác',
}, {
  'id': 'Q99.9',
  'english': 'Chromosomal abnormality, unspecified',
  'vietnamese': 'Bất thường nhiễm sắc thể, không đặc hiệu',
}, {
  'id': 'R00',
  'english': 'Abnormalities of heart beat',
  'vietnamese': 'Bất thường của nhịp tim',
}, {
  'id': 'R00.0',
  'english': 'Tachycardia, unspecified',
  'vietnamese': 'Nhịp nhanh tim, không đặc hiệu',
}, {
  'id': 'R00.1',
  'english': 'Bradycardia, unspecified',
  'vietnamese': 'Nhịp tim chậm, không đặc hiệu',
}, {'id': 'R00.2', 'english': 'Palpitations', 'vietnamese': 'Đánh trống ngực'}, {
  'id': 'R00.8',
  'english': 'Other and unspecified abnormalities of heart beat',
  'vietnamese': 'Bất thường không xác định và các bất thường khác của nhịp tim',
}, {
  'id': 'R01',
  'english': 'Cardiac murmurs and other cardiac sounds',
  'vietnamese': 'Tiếng rì rào tim và các tiếng tim khác',
}, {
  'id': 'R01.0',
  'english': 'Benign and innocent cardiac murmurs',
  'vietnamese': 'Tiếng rì rào tim lành tính và không hại',
}, {
  'id': 'R01.1',
  'english': 'Cardiac murmur, unspecified',
  'vietnamese': 'Tiếng thổi tim không đặc hiệu',
}, {'id': 'R01.2', 'english': 'Other cardiac sounds', 'vietnamese': 'Tiếng tim khác'}, {
  'id': 'R02',
  'english': 'Gangrene, not elswhere classified',
  'vietnamese': 'Hoại thư, chưa được phân loại ở nơi khác',
}, {
  'id': 'R03',
  'english': 'Abnormal blood-pressure reading, without diagnosis',
  'vietnamese': 'Bất thường số đo huyết áp, không có chẩn đoán',
}, {
  'id': 'R03.0',
  'english': 'Elevated blood-pressure reading, without diagnosis of hypertension',
  'vietnamese': 'Số đo huyết áp tăng, không chẩn đoán cao huyết áp',
}, {
  'id': 'R03.1',
  'english': 'Nonspecific low blood-pressure reading',
  'vietnamese': 'Số đo huyết áp thấp không xác định',
}, {
  'id': 'R04',
  'english': 'Haemorrhage from respiratory passages',
  'vietnamese': 'Chảy máu đường hô hấp',
}, {'id': 'R04.0', 'english': 'Epistaxis', 'vietnamese': 'Chảy máu cam'}, {
  'id': 'R04.1',
  'english': 'Haemorrhage from throat',
  'vietnamese': 'Chảy máu họng',
}, {'id': 'R04.2', 'english': 'Haemoptysis', 'vietnamese': 'Ho ra máu'}, {
  'id': 'R04.8',
  'english': 'Haemorrhage from other sites in respiratory passages',
  'vietnamese': 'Chảy máu các vị trí khác của đường hô hấp',
}, {
  'id': 'R04.9',
  'english': 'Haemorrhage from respiratory passages, unspecified',
  'vietnamese': 'Chảy máu đường hô hấp không đặc hiệu',
}, {'id': 'R05', 'english': 'Cough', 'vietnamese': 'Ho'}, {
  'id': 'R06',
  'english': 'Abnormalities of breathing',
  'vietnamese': 'Bất thường về thở',
}, {'id': 'R06.0', 'english': 'Dyspnoea', 'vietnamese': 'Khó thở'}, {
  'id': 'R06.1',
  'english': 'Stridor',
  'vietnamese': 'Thở rít',
}, {'id': 'R06.2', 'english': 'Wheezing', 'vietnamese': 'Thở khò khè'}, {
  'id': 'R06.3',
  'english': 'Periodic breathing',
  'vietnamese': 'Thở có tính chu kỳ',
}, {'id': 'R06.4', 'english': 'Hyperventilation', 'vietnamese': 'Tăng thông khí'}, {
  'id': 'R06.5',
  'english': 'Mouth breathing',
  'vietnamese': 'Thở bằng miệng',
}, {'id': 'R06.6', 'english': 'Hiccough', 'vietnamese': 'Thở nấc'}, {
  'id': 'R06.7',
  'english': 'Sneezing',
  'vietnamese': 'Hắt hơi',
}, {
  'id': 'R06.8',
  'english': 'Other and unspecified abnormalities of breathing',
  'vietnamese': 'Bất thường không xác định và bất thường khác về nhịp thở',
}, {'id': 'R07', 'english': 'Pain in throat and chest', 'vietnamese': 'Đau họng và ngực'}, {
  'id': 'R07.0',
  'english': 'Pain in throat',
  'vietnamese': 'Đau tại họng',
}, {'id': 'R07.1', 'english': 'Chest pain on breathing', 'vietnamese': 'Đau ngực khi thở'}, {
  'id': 'R07.2',
  'english': 'Precordial pain',
  'vietnamese': 'Đau trước tim',
}, {'id': 'R07.3', 'english': 'Other chest pain', 'vietnamese': 'Đau ngực khác'}, {
  'id': 'R07.4',
  'english': 'Chest pain, unspecified',
  'vietnamese': 'Đau ngực không đặc hiệu',
}, {
  'id': 'R09',
  'english': 'Other symptoms and signs involving the circulatory and respiratory systems',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan tới hệ tuần hoàn và hô hấp',
}, {'id': 'R09.0', 'english': 'Asphyxia', 'vietnamese': 'Ngạt'}, {
  'id': 'R09.1',
  'english': 'Pleurisy',
  'vietnamese': 'Viêm màng phổi',
}, {'id': 'R09.2', 'english': 'Respiratory arrest', 'vietnamese': 'Ngừng thở'}, {
  'id': 'R09.3',
  'english': 'Abnormal sputum',
  'vietnamese': 'Đờm bất thường',
}, {
  'id': 'R09.8',
  'english': 'Other specified symptoms and signs involving the circulatory and respiratory systems',
  'vietnamese': 'Các triệu chứng và dấu hiệu đặc hiệu khác liên quan tới hệ tuần hoàn và hô hấp',
}, {'id': 'R10', 'english': 'Abdominal and pelvic pain', 'vietnamese': 'Đau bụng và vùng chậu'}, {
  'id': 'R10.0',
  'english': 'Acute abdomen',
  'vietnamese': 'Bụng cấp',
}, {
  'id': 'R10.1',
  'english': 'Pain localized to upper abdomen',
  'vietnamese': 'Đau bụng khu trú bụng trên',
}, {
  'id': 'R10.2',
  'english': 'Pelvic and perineal pain',
  'vietnamese': 'Đau vùng chậu và đáy chậu',
}, {
  'id': 'R10.3',
  'english': 'Pain localized to other parts of lower abdomen',
  'vietnamese': 'Đau khu trú tại các vùng khác của bụng dưới',
}, {
  'id': 'R10.4',
  'english': 'Other and unspecified abdominal pain',
  'vietnamese': 'Đau bụng không xác định và đau bụng khác',
}, {'id': 'R11', 'english': 'Nausea and vomitting', 'vietnamese': 'Buồn nôn và nôn'}, {
  'id': 'R12',
  'english': 'Heartburn',
  'vietnamese': 'Nóng rát ngực',
}, {'id': 'R13', 'english': 'Dysphagia', 'vietnamese': 'Khó nuốt'}, {
  'id': 'R14',
  'english': 'Flatulance and related conditions',
  'vietnamese': 'Đầy hơi và các tình trạng liên quan',
}, {'id': 'R15', 'english': 'Faecal incontinence', 'vietnamese': 'Đại tiện mất tự chủ'}, {
  'id': 'R16',
  'english': 'Hepatomegaly and splenomegaly, not elsewhere classified',
  'vietnamese': 'Gan to và lá lách to, chưa được phân loại ở phần khác',
}, {
  'id': 'R16.0',
  'english': 'Hepatomegaly, not elsewhere classified',
  'vietnamese': 'Gan to, không phân loại ở phần khác',
}, {
  'id': 'R16.1',
  'english': 'Splenomegaly, not elsewhere classified',
  'vietnamese': 'Lách to, chưa được phân loại ở phần khác',
}, {
  'id': 'R16.2',
  'english': 'Hepatomegaly with splenomegaly, not elsewhere classified',
  'vietnamese': 'Gan to kèm lách to, chưa được phân loại ở phần khác',
}, {'id': 'R17', 'english': 'Unspecified jaundice', 'vietnamese': 'Vàng da không xác định'}, {
  'id': 'R18',
  'english': 'Ascites',
  'vietnamese': 'Cổ chướng',
}, {
  'id': 'R19',
  'english': 'Other symptoms and signs involving the digestive system and abdomen',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan tới hệ tiêu hóa và bụng',
}, {
  'id': 'R19.0',
  'english': 'Intra-abdominal and pelvic swelling, mass and lump',
  'vietnamese': 'Sưng hoặc khối u trong bụng và vùng chậu hông',
}, {'id': 'R19.1', 'english': 'Abnormal bowel sounds', 'vietnamese': 'Tiếng ruột bất thường'}, {
  'id': 'R19.2',
  'english': 'Visible peristalsis',
  'vietnamese': 'Nhu động ruột quan sát thấy',
}, {'id': 'R19.3', 'english': 'Abdominal rigidity', 'vietnamese': 'Cứng bụng'}, {
  'id': 'R19.4',
  'english': 'Change in bowel habit',
  'vietnamese': 'Thay đổi thói quen của ruột',
}, {
  'id': 'R19.5',
  'english': 'Other faecal abnormalities',
  'vietnamese': 'Bất thường khác của phân',
}, {'id': 'R19.6', 'english': 'Halitosis', 'vietnamese': 'Chứng hôi miệng'}, {
  'id': 'R19.8',
  'english': 'Other specified symptoms and signs involving the digestive system and abdomen',
  'vietnamese': 'Triệu chứng và dấu hiệu đặc hiệu khác liên quan tới hệ tiêu hóa và bụng',
}, {
  'id': 'R20',
  'english': 'Disturbances of skin sensation',
  'vietnamese': 'Rối loạn cảm giác da',
}, {
  'id': 'R20.0',
  'english': 'Anaesthesia of skin',
  'vietnamese': 'Mất cảm giác da',
}, {'id': 'R20.1', 'english': 'Hypoaesthesia of skin', 'vietnamese': 'Giảm cảm giác da'}, {
  'id': 'R20.2',
  'english': 'Paraesthesia of skin',
  'vietnamese': 'Dị cảm da',
}, {'id': 'R20.3', 'english': 'Hyperaesthesia', 'vietnamese': 'Tăng cảm giác'}, {
  'id': 'R20.8',
  'english': 'Other and unspecified disturbances of skin sensation',
  'vietnamese': 'Rối loạn cảm giác da không xác định và các rối loạn khác của da',
}, {
  'id': 'R21',
  'english': 'Rash and other nonspecific skin eruption',
  'vietnamese': 'Ban và phát ban không đặc hiệu',
}, {
  'id': 'R22',
  'english': 'Localized swelling, mass and lump of skin and subcutaneous tissue',
  'vietnamese': 'Sưng cục bộ, khối cục ở da và tổ chức dưới da',
}, {
  'id': 'R22.0',
  'english': 'Localized swelling, mass and lump, head',
  'vietnamese': 'Sưng khu trú, khối và cục ở đầu',
}, {
  'id': 'R22.1',
  'english': 'Localized swelling, mass and lump, neck',
  'vietnamese': 'Sưng khu trú, khối và cục ở cổ',
}, {
  'id': 'R22.2',
  'english': 'Localized swelling, mass and lump, trunk',
  'vietnamese': 'Sưng khu trú, khối và cục ở thân mình',
}, {
  'id': 'R22.3',
  'english': 'Localized swelling, mass and lump, upper limb',
  'vietnamese': 'Sưng khu trú, khối và cục ở chi trên',
}, {
  'id': 'R22.4',
  'english': 'Localized swelling, mass and lump, lower limb',
  'vietnamese': 'Sưng khu trú, khối và cục ở chi dưới',
}, {
  'id': 'R22.7',
  'english': 'Localized swelling, mass and lump, multiple sites',
  'vietnamese': 'Sưng khu trú, khối và cục ở nhiều vị trí',
}, {
  'id': 'R22.9',
  'english': 'Localized swelling, mass and lump, unspecified',
  'vietnamese': 'Sưng khu trú, khối và cục, không đặc hiệu',
}, {'id': 'R23', 'english': 'Other skin changes', 'vietnamese': 'Các thay đổi khác của da'}, {
  'id': 'R23.0',
  'english': 'Cyanosis',
  'vietnamese': 'Da xanh tím',
}, {'id': 'R23.1', 'english': 'Pallor', 'vietnamese': 'Da xanh nhợt'}, {
  'id': 'R23.2',
  'english': 'Flushing',
  'vietnamese': 'Chứng đỏ bừng mặt',
}, {
  'id': 'R23.3',
  'english': 'Spontaneous ecchymoses',
  'vietnamese': 'Vết bầm tím xuất huyết ngẫu phát',
}, {'id': 'R23.4', 'english': 'Changes in skin texture', 'vietnamese': 'Thay đổi hình thể da'}, {
  'id': 'R23.8',
  'english': 'Other and unspecified skin changes',
  'vietnamese': 'Các thay đổi của không xác định khác của da',
}, {
  'id': 'R25',
  'english': 'Abnormal involuntary movements',
  'vietnamese': 'Vận động không tự chủ bất thường',
}, {
  'id': 'R25.0',
  'english': 'Abnormal head movements',
  'vietnamese': 'Bất thường vận động đầu',
}, {
  'id': 'R25.1',
  'english': 'Tremor, unspecified',
  'vietnamese': 'Rung, không đặc hiệu',
}, {'id': 'R25.2', 'english': 'Cramp and spasm', 'vietnamese': 'Chuột rút và co cứng'}, {
  'id': 'R25.3',
  'english': 'Fasciculation',
  'vietnamese': 'Co cứng cơ cục bộ',
}, {
  'id': 'R25.8',
  'english': 'Other and unspecified abnormal involuntary movements',
  'vietnamese': 'Vận động không tự chủ bất thường không xác định khác',
}, {
  'id': 'R26',
  'english': 'Abnormalities of gait and mobility',
  'vietnamese': 'Bất thường dáng đi và chuyển động',
}, {'id': 'R26.0', 'english': 'Ataxic gait', 'vietnamese': 'Dáng đi mất điều vận'}, {
  'id': 'R26.1',
  'english': 'Paralytic gait',
  'vietnamese': 'Dáng đi liệt',
}, {
  'id': 'R26.2',
  'english': 'Difficulty in walking, not elsewhere classified',
  'vietnamese': 'Khó khăn khi đi chưa phân loại ở phần khác',
}, {'id': 'R26.3', 'english': 'Immobility', 'vietnamese': 'Bất động'}, {
  'id': 'R26.8',
  'english': 'Other and unspecified abnormalities of gait and mobility',
  'vietnamese': 'Dáng đi và di chuyển bất thường không đặc hiệu',
}, {'id': 'R27', 'english': 'Other lack of coordination', 'vietnamese': 'Thiếu phối hợp khác'}, {
  'id': 'R27.0',
  'english': 'Ataxia, unspecified',
  'vietnamese': 'Mất điều vận, không đặc hiệu',
}, {
  'id': 'R27.8',
  'english': 'Other and unspecified lack of coordination',
  'vietnamese': 'Thiếu phối hợp không xác định khác',
}, {
  'id': 'R29',
  'english': 'Other symptoms and signs involving the nervous and musculoskeletal systems',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan tới hệ thần kinh và cơ xương',
}, {'id': 'R29.0', 'english': 'Tetany', 'vietnamese': 'Co cứng'}, {
  'id': 'R29.1',
  'english': 'Meningismus',
  'vietnamese': 'Hội chứng (co cứng) màng não',
}, {'id': 'R29.2', 'english': 'Abnormal reflex', 'vietnamese': 'Phản xạ bất thường'}, {
  'id': 'R29.3',
  'english': 'Abnormal posture',
  'vietnamese': 'Tư thế bất thường',
}, {'id': 'R29.4', 'english': 'Clicking hip', 'vietnamese': 'Chậu hông khoèo'}, {
  'id': 'R29.6',
  'english': 'Tendency to fall, not elsewhere classified',
  'vietnamese': 'Dễ ngã, chưa được phân loại ở phần khác',
}, {
  'id': 'R29.8',
  'english': 'Other and unspecified symptoms and signs involving the nervous and musculoskeletal systems',
  'vietnamese': 'Triệu chứng và dấu hiệu không xác định khác liên quan tới hệ thần kinh và cơ xương',
}, {
  'id': 'R30',
  'english': 'Pain associated with micturition',
  'vietnamese': 'Đau liên quan với tiểu tiện',
}, {'id': 'R30.0', 'english': 'Dysuria', 'vietnamese': 'Đái khó'}, {
  'id': 'R30.1',
  'english': 'Vesical tenesmus',
  'vietnamese': 'Đau thắt bàng quang',
}, {
  'id': 'R30.9',
  'english': 'Painful micturition, unspecified',
  'vietnamese': 'Đái dắt đau, không đặc hiệu',
}, {'id': 'R31', 'english': 'Unspecified haematuria', 'vietnamese': 'Đái máu không xác định'}, {
  'id': 'R32',
  'english': 'Unspecified urinary incontinence',
  'vietnamese': 'Tiểu tiện mất tự chủ không xác định',
}, {'id': 'R33', 'english': 'Retention of urine', 'vietnamese': 'Bí đái'}, {
  'id': 'R34',
  'english': 'Anuria and oliguria',
  'vietnamese': 'Vô niệu và thiểu niệu',
}, {'id': 'R35', 'english': 'Polyuria', 'vietnamese': 'Đa niệu'}, {
  'id': 'R36',
  'english': 'Urethral discharge',
  'vietnamese': 'Chất tiết niệu đạo',
}, {
  'id': 'R39',
  'english': 'Other symptoms and signs involving the urinary system',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan tới hệ tiết niệu',
}, {'id': 'R39.0', 'english': 'Extravasation of urine', 'vietnamese': 'Tràn máu nước tiểu'}, {
  'id': 'R39.1',
  'english': 'Other difficulties with micturition',
  'vietnamese': 'Các khó khăn khác khi tiểu tiện',
}, {'id': 'R39.2', 'english': 'Extrarenal uraemia', 'vietnamese': 'Urê máu cao ngoài thận'}, {
  'id': 'R39.8',
  'english': 'Other and unspecified symptoms and signs involving the urinary system',
  'vietnamese': 'Triệu chứng và dấu hiệu không xác định khác liên quan tới hệ tiết niệu',
}, {
  'id': 'R40',
  'english': 'Somnolence, stupor and coma',
  'vietnamese': 'Buồn ngủ, ngẩn ngơ và hôn mê',
}, {'id': 'R40.0', 'english': 'Somnolence', 'vietnamese': 'Buồn ngủ'}, {
  'id': 'R40.1',
  'english': 'Stupor',
  'vietnamese': 'Ngẩn ngơ',
}, {'id': 'R40.2', 'english': 'Coma, unspecified', 'vietnamese': 'Hôn mê, không đặc hiệu'}, {
  'id': 'R41',
  'english': 'Other symptoms and signs involving cognitive functions and awareness',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan đến chức năng nhận thức và tri giác',
}, {
  'id': 'R41.0',
  'english': 'Disorientation, unspecified',
  'vietnamese': 'Mất định hướng, không đặc hiệu',
}, {'id': 'R41.1', 'english': 'Anterograde amnesia', 'vietnamese': 'Quên thuận chiều'}, {
  'id': 'R41.2',
  'english': 'Retrograde amnesia',
  'vietnamese': 'Quên ngược chiều',
}, {'id': 'R41.3', 'english': 'Other amnesia', 'vietnamese': 'Các loại quên khác'}, {
  'id': 'R41.8',
  'english': 'Other and unspecified symptoms and signs involving cognitive functions and awareness',
  'vietnamese': 'Triệu chứng và dấu hiệu không xác định khác liên quan đến chức năng nhận thức và nhận biết',
}, {'id': 'R42', 'english': 'Dizziness and giddiness', 'vietnamese': 'Hoa mắt và chóng mặt'}, {
  'id': 'R43',
  'english': 'Disturbances of smell and taste',
  'vietnamese': 'Rối loạn về mùi và vị',
}, {'id': 'R43.0', 'english': 'Anosmia', 'vietnamese': 'Mất khứu giác'}, {
  'id': 'R43.1',
  'english': 'Parosmia',
  'vietnamese': 'Loạn khứu giác',
}, {'id': 'R43.2', 'english': 'Parageusia', 'vietnamese': 'Loạn vị giác'}, {
  'id': 'R43.8',
  'english': 'Other and unspecified disturbances of smell and taste',
  'vietnamese': 'Rối loạn không xác định và rối loạn khác về mùi và vị',
}, {
  'id': 'R44',
  'english': 'Other symptoms and signs involving general sensations and perceptions',
  'vietnamese': 'Triệu chứng và dấu hiệu khác về cảm giác và tri giác tổng quát',
}, {'id': 'R44.0', 'english': 'Auditory hallucinations', 'vietnamese': 'Ảo giác âm thanh'}, {
  'id': 'R44.1',
  'english': 'Visual hallucinations',
  'vietnamese': 'Ảo giác nhìn',
}, {'id': 'R44.2', 'english': 'Other hallucinations', 'vietnamese': 'Các ảo giác khác'}, {
  'id': 'R44.3',
  'english': 'Hallucinations, unspecified',
  'vietnamese': 'Ảo giác, không đặc hiệu',
}, {
  'id': 'R44.8',
  'english': 'Other and unspecified symptoms and signs involving general sensations and perceptions',
  'vietnamese': 'Triệu chứng và dấu hiệu không xác định khác về cảm giác và tri giác toàn thể',
}, {
  'id': 'R45',
  'english': 'Symptoms and signs involving emotional state',
  'vietnamese': 'Triệu chứng và dấu hiệu về trạng thái cảm xúc',
}, {'id': 'R45.0', 'english': 'Nervousness', 'vietnamese': 'Căng thẳng'}, {
  'id': 'R45.1',
  'english': 'Restlessness and agitation',
  'vietnamese': 'Không nghỉ và kích động',
}, {'id': 'R45.2', 'english': 'Unhappiness', 'vietnamese': 'Bất ổn'}, {
  'id': 'R45.3',
  'english': 'Demoralization and apathy',
  'vietnamese': 'Vô đạo đức và vô cảm',
}, {'id': 'R45.4', 'english': 'Irritability and anger', 'vietnamese': 'Cáu gắt và tức giận'}, {
  'id': 'R45.5',
  'english': 'Hostility',
  'vietnamese': 'Thù định',
}, {
  'id': 'R45.6',
  'english': 'Physical violence',
  'vietnamese': 'Bạo hành về thể xác (thể lực)',
}, {
  'id': 'R45.7',
  'english': 'State of emotional shock and stress, unspecified',
  'vietnamese': 'Trạng thái choáng cảm xúc và stress, không đặc hiệu',
}, {
  'id': 'R45.8',
  'english': 'Other symptoms and signs involving emotional state',
  'vietnamese': 'Triệu chứng và dấu hiệu khác về trạng thái cảm xúc',
}, {
  'id': 'R46',
  'english': 'Symptoms and signs involving appearance and behaviour',
  'vietnamese': 'Triệu chứng và dấu hiệu khác về vẻ bề ngoài và hành vi',
}, {
  'id': 'R46.0',
  'english': 'Very low level of personal hygiene',
  'vietnamese': 'Ý thức vệ sinh cá nhân kém',
}, {
  'id': 'R46.1',
  'english': 'Bizarre personal appearance',
  'vietnamese': 'Vẻ bề ngoài kỳ quặc con người',
}, {
  'id': 'R46.2',
  'english': 'Strange and inexplicable behaviour',
  'vietnamese': 'Hành vi kỳ lạ và khó hiểu',
}, {'id': 'R46.3', 'english': 'Overactivity', 'vietnamese': 'Tăng động'}, {
  'id': 'R46.4',
  'english': 'Slowness and poor responsiveness',
  'vietnamese': 'Phản ứng nghèo nàn và chậm chạp',
}, {
  'id': 'R46.5',
  'english': 'Suspiciousness and marked evasiveness',
  'vietnamese': 'Nghi ngờ và lảng tránh rõ rệt',
}, {
  'id': 'R46.6',
  'english': 'Undue concern and preoccupation with stressful events',
  'vietnamese': 'Lo lắng và bận tâm quá mức vì stress',
}, {
  'id': 'R46.7',
  'english': 'Verbosity and circumstantial detail obscuring reason for contact',
  'vietnamese': 'Chứng nói dài và quá chi tiết gây khó hiể',
}, {
  'id': 'R46.8',
  'english': 'Other symptoms and signs involving appearance and behaviour',
  'vietnamese': 'Triệu chứng và dấu hiệu khác về vẻ bề ngoài và hành vi',
}, {
  'id': 'R47',
  'english': 'Speech disturbances, not elsewhere classified',
  'vietnamese': 'Rối loạn lời nói, chưa được phân loại ở phần khác',
}, {
  'id': 'R47.0',
  'english': 'Dysphasia and aphasia',
  'vietnamese': 'rối loạn khả năng nói và mất khả năng n',
}, {
  'id': 'R47.1',
  'english': 'Dysarthria and anarthria',
  'vietnamese': 'Rối loạn vận ngôn và mất vận ngôn',
}, {
  'id': 'R47.8',
  'english': 'Other and unspecified speech disturbances',
  'vietnamese': 'Rối loạn ngôn ngữ khác và không xác định',
}, {
  'id': 'R48',
  'english': 'Dyslexia and other symbolic dysfunctions, not elsewhere classified',
  'vietnamese': 'Rối loạn khả năng đọc và các rối loạn khác chưa được phân loại ở phần khác',
}, {
  'id': 'R48.0',
  'english': 'Dyslexia and alexia',
  'vietnamese': 'Rối loạn khả năng đọc và mất khả năng đọc',
}, {'id': 'R48.1', 'english': 'Agnosia', 'vietnamese': 'Mất nhận thức'}, {
  'id': 'R48.2',
  'english': 'Apraxia',
  'vietnamese': 'Mất khả năng điều khiển động tác',
}, {
  'id': 'R48.8',
  'english': 'Other and unspecified symbolic dysfunctions',
  'vietnamese': 'Các rối loạn khác',
}, {'id': 'R49', 'english': 'Voice disturbances', 'vietnamese': 'Rối loạn giọng nói'}, {
  'id': 'R49.0',
  'english': 'Dysphonia',
  'vietnamese': 'Chứng khó phát âm',
}, {'id': 'R49.1', 'english': 'Aphonia', 'vietnamese': 'Mất tiếng'}, {
  'id': 'R49.2',
  'english': 'Hypernasality and hyponasality',
  'vietnamese': 'Giọng mũi cao và giọng âm mũi thấp',
}, {
  'id': 'R49.8',
  'english': 'Other and unspecified voice disturbances',
  'vietnamese': 'Rối loạn giọng nói không đặc hiệu và k',
}, {
  'id': 'R50',
  'english': 'Fever of other and unknown origin',
  'vietnamese': 'Sốt không rõ nguyên nhân và khác',
}, {'id': 'R50.2', 'english': 'Drug-induced fever', 'vietnamese': 'Sốt do thuốc'}, {
  'id': 'R50.8',
  'english': 'Other specified fever',
  'vietnamese': 'Sốt xác định khác',
}, {'id': 'R50.9', 'english': 'Fever, unspecified', 'vietnamese': 'Sốt, không đặc hiệu'}, {
  'id': 'R51',
  'english': 'Headache',
  'vietnamese': 'Đau đầu',
}, {
  'id': 'R52',
  'english': 'Pain, not elsewhere classified',
  'vietnamese': 'Đau chưa được phân loại ở phần khác',
}, {'id': 'R52.0', 'english': 'Acute pain', 'vietnamese': 'Đau cấp tính'}, {
  'id': 'R52.1',
  'english': 'Chronic intractable pain',
  'vietnamese': 'Đau mãn tính khó chữa',
}, {'id': 'R52.2', 'english': 'Other chronic pain', 'vietnamese': 'Đau mãn tính khác'}, {
  'id': 'R52.9',
  'english': 'Pain, unspecified',
  'vietnamese': 'Đau, không đặc hiệu',
}, {'id': 'R53', 'english': 'Malaise and fatigue', 'vietnamese': 'Khó ở và mệt mỏi'}, {
  'id': 'R54',
  'english': 'Senility',
  'vietnamese': 'Suy yếu do tuổi già',
}, {'id': 'R55', 'english': 'Syncope and collapse', 'vietnamese': 'Ngắt và ngã quỵ'}, {
  'id': 'R56',
  'english': 'Convulsions, not elsewhere classified',
  'vietnamese': 'Co giật, chưa phân loại ở nơi khác',
}, {'id': 'R56.0', 'english': 'Febrile convulsions', 'vietnamese': 'Co giật do sốt'}, {
  'id': 'R56.8',
  'english': 'Other and unspecified convulsions',
  'vietnamese': 'Co giật không đặc hiệu khác',
}, {
  'id': 'R57',
  'english': 'Shock, not elsewhere classified',
  'vietnamese': 'choáng chưa được phân loại ở phần khác',
}, {'id': 'R57.0', 'english': 'Cardiogenic shock', 'vietnamese': 'Sốc tim'}, {
  'id': 'R57.1',
  'english': 'Hypovolaemic shock',
  'vietnamese': 'Sốc Giảm thể tíchh',
}, {'id': 'R57.2', 'english': 'Septic shock', 'vietnamese': 'Sốc (choáng) nhiễm khuẩn'}, {
  'id': 'R57.8',
  'english': 'Other shock',
  'vietnamese': 'Sốc khác',
}, {'id': 'R57.9', 'english': 'Shock, unspecified', 'vietnamese': 'Sốc không đặc hiệu'}, {
  'id': 'R58',
  'english': 'Haemorrhage, not elsewhere classified',
  'vietnamese': 'Chảy máu chưa được phân loại ở nơi khác',
}, {'id': 'R59', 'english': 'Enlarged lymph nodes', 'vietnamese': 'Hạch bạch huyết to'}, {
  'id': 'R59.0',
  'english': 'Localized enlarged lymph nodes',
  'vietnamese': 'Hạch to khu trú',
}, {
  'id': 'R59.1',
  'english': 'Generalized enlarged lymph nodes',
  'vietnamese': 'Hạch to toàn thân',
}, {
  'id': 'R59.9',
  'english': 'Enlarged lymph nodes, unspecified',
  'vietnamese': 'Hạch to, không đặc hiệu',
}, {
  'id': 'R60',
  'english': 'Oedema, not elsewhere classified',
  'vietnamese': 'Phù, chưa phân loại nơi khác',
}, {'id': 'R60.0', 'english': 'Localized oedema', 'vietnamese': 'Phù khu trú'}, {
  'id': 'R60.1',
  'english': 'Generalized oedema',
  'vietnamese': 'Phù toàn thể',
}, {'id': 'R60.9', 'english': 'Oedema, unspecified', 'vietnamese': 'Phù không đặc hiệu'}, {
  'id': 'R61',
  'english': 'Hyperhidrosis',
  'vietnamese': 'Tăng tiết mồ hôi',
}, {
  'id': 'R61.0',
  'english': 'Localized hyperhidrosis',
  'vietnamese': 'Tiết nhiều mồ hôi khu trú',
}, {
  'id': 'R61.1',
  'english': 'Generalized hyperhidrosis',
  'vietnamese': 'Tăng tiết mồ hôi toàn thể',
}, {
  'id': 'R61.9',
  'english': 'Hyperhidrosis, unspecified',
  'vietnamese': 'Tiết nhiều mồ hôi, không đặc hiệu',
}, {
  'id': 'R62',
  'english': 'Lack of expected normal physiological development',
  'vietnamese': 'Phát triển sinh lý không bình thường như mong muốn',
}, {'id': 'R62.0', 'english': 'Delayed milestone', 'vietnamese': 'Chậm đạt các mốc phát triển'}, {
  'id': 'R62.8',
  'english': 'Other lack of expected normal physiological development',
  'vietnamese': 'Phát triển sinh lý không bình thường khác',
}, {
  'id': 'R62.9',
  'english': 'Lack of expected normal physiological development, unspecified',
  'vietnamese': 'Phát triển sinh lí không bình thường, không đặc hiệu',
}, {
  'id': 'R63',
  'english': 'Symptoms and signs concerning food and fluid intake',
  'vietnamese': 'Triệu chứng và dấu hiệu liên quan tới thực phẩm và dịch đưa vào cơ thể',
}, {'id': 'R63.0', 'english': 'Anorexia', 'vietnamese': 'Chán ăn'}, {
  'id': 'R63.1',
  'english': 'Polydipsia',
  'vietnamese': 'Chứng khát nước nhiều',
}, {'id': 'R63.2', 'english': 'Polyphagia', 'vietnamese': 'Chứng ăn nhiều'}, {
  'id': 'R63.3',
  'english': 'Feeding difficulties and mismanagement',
  'vietnamese': 'Khó khăn khi cho ăn và quản lý kém',
}, {'id': 'R63.4', 'english': 'Abnormal weight loss', 'vietnamese': 'Giảm cân bất thường'}, {
  'id': 'R63.5',
  'english': 'Abnormal weight gain',
  'vietnamese': 'Tăng cân bất thường',
}, {
  'id': 'R63.6',
  'english': 'Insufficient intake of food and water due to self neglect',
  'vietnamese': 'Thực phẩm và nước đưa vào cơ thể không đủ do tự bỏ mặc (R63.6)',
}, {
  'id': 'R63.8',
  'english': 'Other symptoms and signs concerning food and fluid intake',
  'vietnamese': 'Triệu chứng và dấu hiệu khác liên quan đến thức ăn và dịch đưa vào cơ thể',
}, {'id': 'R64', 'english': 'Cachexia', 'vietnamese': 'Suy mòn'}, {
  'id': 'R65',
  'english': 'Systemic Inflammatory Response Syndrome [SIRS]',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống',
}, {
  'id': 'R65.0',
  'english': 'Systemic Inflammatory Response Syndrome of infectious origin without organ failure',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống do nhiễm trùng không có suy cơ quan',
}, {
  'id': 'R65.1',
  'english': 'Systemic Inflammatory Response Syndrome of infectious origin with organ failure',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống do nhiễm trùng có suy cơ quan',
}, {
  'id': 'R65.2',
  'english': 'Systemic Inflammatory Response Syndrome of non-infectious origin without organ failure',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống không do nhiễm trùng không có suy cơ quan',
}, {
  'id': 'R65.3',
  'english': 'Systemic Inflammatory Response Syndrome of non-infectious origin with organ failure',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống không do nhiễm trùng có suy cơ quan',
}, {
  'id': 'R65.9',
  'english': 'Systemic Inflammatory Response Syndrome, unspecified',
  'vietnamese': 'Hội chứng đáp ứng viêm hệ thống, không đặc hiệu',
}, {
  'id': 'R68',
  'english': 'Other general symptoms and signs',
  'vietnamese': 'Triệu chứng và dấu hiệu toàn thể khác',
}, {
  'id': 'R68.0',
  'english': 'Hypothermia, not associated with low environmental temperature',
  'vietnamese': 'Hạ thân nhiệt, không liên quan đến nhiệt độ môi trường thấp',
}, {
  'id': 'R68.1',
  'english': 'Nonspecific symptoms peculiar to infancy',
  'vietnamese': 'Triệu chứng riêng không đặc hiệu của trẻ em',
}, {
  'id': 'R68.2',
  'english': 'Dry mouth, unspecified',
  'vietnamese': 'Miệng khô không đặc hiệu',
}, {
  'id': 'R68.3',
  'english': 'Clubbing of fingers',
  'vietnamese': 'Chứng ngón tay hình dùi trống',
}, {
  'id': 'R68.8',
  'english': 'Other specified general symptoms and signs',
  'vietnamese': 'Triệu chứng và dấu hiệu toàn thể đặc hiệu khác',
}, {
  'id': 'R69',
  'english': 'Unknown and unspecified causes of morbidity',
  'vietnamese': 'Nguyên nhân mắc bệnh không đặc hiệu và không rõ',
}, {
  'id': 'R70',
  'english': 'Elevated erythrocyte sedimentation rate and abnormality of plasma viscosity',
  'vietnamese': 'Độ lắng hồng cầu và bất thường độ nhớt huyết tương',
}, {
  'id': 'R70.0',
  'english': 'Elevated erythrocyte sedimentation rate',
  'vietnamese': 'Tăng độ lắng hồng cầu',
}, {
  'id': 'R70.1',
  'english': 'Abnormal plasma viscosity',
  'vietnamese': 'Bất thường độ nhớt huyết tương',
}, {
  'id': 'R71',
  'english': 'Abnormality of red blood cells',
  'vietnamese': 'Bất thường về hồng cầu',
}, {
  'id': 'R72',
  'english': 'Abnormality of white blood cells, not elsewhere classified',
  'vietnamese': 'Bất thường về bạch cầu, chưa được phân loại ở phần khác',
}, {
  'id': 'R73',
  'english': 'Elevated blood glucose level',
  'vietnamese': 'Tăng nồng độ Glucoza máu',
}, {
  'id': 'R73.0',
  'english': 'Abnormal glucose tolerance test',
  'vietnamese': 'Test dung nạp glucoza bất thường',
}, {
  'id': 'R73.9',
  'english': 'Hyperglycaemia, unspecified',
  'vietnamese': 'Tăng đường huyết, không đặc hiệu',
}, {
  'id': 'R74',
  'english': 'Abnormal serum enzyme levels',
  'vietnamese': 'Bất thường nồng độ enzym huyết thanh',
}, {
  'id': 'R74.0',
  'english': 'Elevation of levels of transaminase and lactic acid dehydrogenase [LDH]',
  'vietnamese': 'Tăng mức men tranzaminase và men acid lactic dehydrogenase (LDH)',
}, {
  'id': 'R74.8',
  'english': 'Abnormal levels of other serum enzymes',
  'vietnamese': 'Các bất thường nồng độ enzym huyết thanh khác',
}, {
  'id': 'R74.9',
  'english': 'Abnormal level of unspecified serum enzyme',
  'vietnamese': 'Bất thường nồng độ enzym huyết thanh không đặc hiệu',
}, {
  'id': 'R75',
  'english': 'Labotary evidence of human immunodeficiency virus',
  'vietnamese': 'Biểu hiện cận lâm sàng của virus gây suy giảm miễn dịch ở người (HIV)',
}, {
  'id': 'R76',
  'english': 'Other abnormal immunological findings in serum',
  'vietnamese': 'Các phát hiện bất thường khác về miễn dịch trong huyết thanh',
}, {'id': 'R76.0', 'english': 'Raised antibody titre', 'vietnamese': 'Tăng chuẩn độ kháng thể'}, {
  'id': 'R76.1',
  'english': 'Abnormal reaction to tuberculin test',
  'vietnamese': 'Phản ứng bất thường với test tuberculin',
}, {
  'id': 'R76.2',
  'english': 'False-positive serological test for syphilis',
  'vietnamese': 'Test huyết thanh dương tính giả với giang mai',
}, {
  'id': 'R76.8',
  'english': 'Other specified abnormal immunological findings in serum',
  'vietnamese': 'Các kết quả miễn dịch bất thường đặc hiệu khác của huyết thanh',
}, {
  'id': 'R76.9',
  'english': 'Abnormal immunological finding in serum, unspecified',
  'vietnamese': 'Phát hiện miễn dịch bất thường trong huyết thanh, không đặc hiệu',
}, {
  'id': 'R77',
  'english': 'Other abnormalities of plasma proteins',
  'vietnamese': 'Bất thường khác về protein huyết tương',
}, {'id': 'R77.0', 'english': 'Abnormality of albumin', 'vietnamese': 'Bất thường của albumin'}, {
  'id': 'R77.1',
  'english': 'Abnormality of globulin',
  'vietnamese': 'Bất thường của globulin',
}, {
  'id': 'R77.2',
  'english': 'Abnormality of alphafetoprotein',
  'vietnamese': 'Bất thường của alphafetoprotein',
}, {
  'id': 'R77.8',
  'english': 'Other specified abnormalities of plasma proteins',
  'vietnamese': 'Bất thường đặc hiệu khác của protein huyết tương',
}, {
  'id': 'R77.9',
  'english': 'Abnormality of plasma protein, unspecified',
  'vietnamese': 'Bất thường của protein huyết tương, không đặc hiệu',
}, {
  'id': 'R78',
  'english': 'Findings of drugs and other substances, not normally found in blood',
  'vietnamese': 'Phát hiện các loại thuốc và các chất khác không thường có trong máu',
}, {
  'id': 'R78.0',
  'english': 'Finding of alcohol in blood',
  'vietnamese': 'Phát hiện có rượu trong máu',
}, {
  'id': 'R78.1',
  'english': 'Finding of opiate drug in blood',
  'vietnamese': 'Phát hiện các chế phẩm thuốc phiện trong máu',
}, {
  'id': 'R78.2',
  'english': 'Finding of cocaine in blood',
  'vietnamese': 'Phát hiện cocain trong máu',
}, {
  'id': 'R78.3',
  'english': 'Finding of hallucinogen in blood',
  'vietnamese': 'Phát hiện chất gây ảo giác trong máu',
}, {
  'id': 'R78.4',
  'english': 'Finding of other drugs of addictive potential in blood',
  'vietnamese': 'Phát hiện các chất khác có khả năng gây nghiện trong máu',
}, {
  'id': 'R78.5',
  'english': 'Finding of psychotropic drug in blood',
  'vietnamese': 'Phát hiện các chất hướng thần trong máu',
}, {
  'id': 'R78.6',
  'english': 'Finding of steroid agent in blood',
  'vietnamese': 'Phát hiện chất stieroid trong máu',
}, {
  'id': 'R78.7',
  'english': 'Finding of abnormal level of heavy metals in blood',
  'vietnamese': 'Phát hiện nồng độ kim loại nặng bất thường trong máu',
}, {
  'id': 'R78.8',
  'english': 'Finding of other specified substances, not normally found in blood',
  'vietnamese': 'Phát hiện các chất đặc hiệu khác mà bình thường không có trong máu',
}, {
  'id': 'R78.9',
  'english': 'Finding of unspecified substance, not normally found in blood',
  'vietnamese': 'Phát hiện chất không xác định mà bình thường không có trong máu',
}, {
  'id': 'R79',
  'english': 'Other abnormal findings of blood chemistry',
  'vietnamese': 'Các phát hiện sinh hóa máu bất thường khác',
}, {
  'id': 'R79.0',
  'english': 'Abnormal level of blood mineral',
  'vietnamese': 'Bất thường nồng độ khóang chất trong máu',
}, {
  'id': 'R79.8',
  'english': 'Other specified abnormal findings of blood chemistry',
  'vietnamese': 'Các phát hiện bất thường đặc hiệu khác về hóa sịnh máu',
}, {
  'id': 'R79.9',
  'english': 'Abnormal finding of blood chemistry, unspecified',
  'vietnamese': 'Phát hiện bất thường về hóa sinh máu, không đặc hiệu',
}, {'id': 'R80', 'english': 'Isolated proteinuria', 'vietnamese': 'Protein niệu riêng lẻ'}, {
  'id': 'R81',
  'english': 'Glycosuria',
  'vietnamese': 'Glucose niệu',
}, {
  'id': 'R82',
  'english': 'Other abnormal findings in urine',
  'vietnamese': 'Các phát hiện bất thường khác về nước tiểu',
}, {'id': 'R82.0', 'english': 'Chyluria', 'vietnamese': 'Dưỡng chấp niệu'}, {
  'id': 'R82.1',
  'english': 'Myoglobinuria',
  'vietnamese': 'Myoglobin niệu',
}, {'id': 'R82.2', 'english': 'Biliuria', 'vietnamese': 'Đái sắc tố mật'}, {
  'id': 'R82.3',
  'english': 'Haemoglobinuria',
  'vietnamese': 'Haemoglobin niệu',
}, {'id': 'R82.4', 'english': 'Acetonuria', 'vietnamese': 'Aceton niệu'}, {
  'id': 'R82.5',
  'english': 'Elevated urine levels of drugs, medicaments and biological substances',
  'vietnamese': 'Tăng nồng độ thuốc, dược phẩm sinh học cao trong nước tiểu',
}, {
  'id': 'R82.6',
  'english': 'Abnormal urine levels of substances chiefly nonmedicinal as to source',
  'vietnamese': 'Nồng độ bất thường của các chất có nguồn chủ yếu không dược liệu',
}, {
  'id': 'R82.7',
  'english': 'Abnormal findings on microbiological examination of urine',
  'vietnamese': 'Các phát hiện bất thường về xét nghiệm vi khuẩn trong nước tiểu',
}, {
  'id': 'R82.8',
  'english': 'Abnormal findings on cytological and histological examination of urine',
  'vietnamese': 'Phát hiện bất thường về xét nghiệm tế bào và tổ chức học trong nước tiểu',
}, {
  'id': 'R82.9',
  'english': 'Other and unspecified abnormal findings in urine',
  'vietnamese': 'Phát hiện bất thường không đặc hiệu khác trong nước tiểu',
}, {
  'id': 'R83',
  'english': 'Abnormal findings in cerebrospinal fluid',
  'vietnamese': 'Các phát hiện bất thường về dịch não tủy',
}, {
  'id': 'R84',
  'english': 'Abnormal findings in specimens from respiratory organs and thorax',
  'vietnamese': 'Các phát hiện bất thường trong mẫu bệnh phẩm từ cơ quan hô hấp và lồng ngực',
}, {
  'id': 'R85',
  'english': 'Abnormal findings in specimens from digestive organs and abdominal cavity',
  'vietnamese': 'Các phát hiện bất thường trong mẫu bệnh phẩm từ cơ quan tiêu hóa và ổ bụng',
}, {
  'id': 'R86',
  'english': 'Abnormal findings in specimens from male genital organs',
  'vietnamese': 'Các phát hiện bất thường trong mẫu vật của cơ quan sinh dục nam',
}, {
  'id': 'R87',
  'english': 'Abnormal findings in specimens from female genital organs',
  'vietnamese': 'Các phát hiện bất thường trong mẫu vật của cơ quan sinh dục nữ',
}, {
  'id': 'R89',
  'english': 'Abnormal findings in specimens from other organs, systems and tissues',
  'vietnamese': 'Các phát hiện bất thường trong mẫu vật của các cơ quan, hệ thống và mô khác',
}, {
  'id': 'R90',
  'english': 'Abnormal findings on diagnostic imaging of central nervous system',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của hệ thần kinh trung ương',
}, {
  'id': 'R90.0',
  'english': 'Intracranial space-occupying lesion',
  'vietnamese': 'Tổn thương chóan chỗ trong sọ',
}, {
  'id': 'R90.8',
  'english': 'Other abnormal findings on diagnostic imaging of central nervous system',
  'vietnamese': 'Các phát hiện bất thường khác về chẩn đoán hình ảnh của hệ thần kinh trung ương',
}, {
  'id': 'R91',
  'english': 'Abnormal findings on diagnostic imaging of lung',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của phổi không xác định',
}, {
  'id': 'R92',
  'english': 'Abnormal findings on diagnostic imaging of breast',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của ngực',
}, {
  'id': 'R93',
  'english': 'Abnormal findings on diagnostic imaging of other body structures',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của các cơ quan khác',
}, {
  'id': 'R93.0',
  'english': 'Abnormal findings on diagnostic imaging of skull and head, not elsewhere classified',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của xương sọ và đầu, chưa được phân loại ở phần khác',
}, {
  'id': 'R93.1',
  'english': 'Abnormal findings on diagnostic imaging of heart and coronary circulation',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của tim và mạch vành, không đặc hiệu',
}, {
  'id': 'R93.2',
  'english': 'Abnormal findings on diagnostic imaging of liver and biliary tract',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của gan và đường dẫn mật',
}, {
  'id': 'R93.3',
  'english': 'Abnormal findings on diagnostic imaging of other parts of digestive tract',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của các phần khác của đường tiêu hóa',
}, {
  'id': 'R93.4',
  'english': 'Abnormal findings on diagnostic imaging of urinary organs',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của cơ quan tiết niệu',
}, {
  'id': 'R93.5',
  'english': 'Abnormal findings on diagnostic imaging of other abdominal regions, including retroperitoneum',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của vùng bụng khác, kể cả khoang sau màng bụng',
}, {
  'id': 'R93.6',
  'english': 'Abnormal findings on diagnostic imaging of limbs',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của các chi',
}, {
  'id': 'R93.7',
  'english': 'Abnormal findings on diagnostic imaging of other parts of musculoskeletal system',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của các phần khác của hệ xương',
}, {
  'id': 'R93.8',
  'english': 'Abnormal findings on diagnostic imaging of other specified body structures',
  'vietnamese': 'Các phát hiện bất thường về chẩn đoán hình ảnh của các cấu trúc cơ thể xác định khác',
}, {
  'id': 'R94',
  'english': 'Abnormal results of function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng',
}, {
  'id': 'R94.0',
  'english': 'Abnormal results of function studies of central nervous system',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng của hệ thần kinh trung ương',
}, {
  'id': 'R94.1',
  'english': 'Abnormal results of function studies of peripheral nervous system and special senses',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng của hệ thần kinh ngoại biên và các giác quan đặc hiệu',
}, {
  'id': 'R94.2',
  'english': 'Abnormal results of pulmonary function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng phổi',
}, {
  'id': 'R94.3',
  'english': 'Abnormal results of cardiovascular function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng tuần hoàn',
}, {
  'id': 'R94.4',
  'english': 'Abnormal results of kidney function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng thận',
}, {
  'id': 'R94.5',
  'english': 'Abnormal results of liver function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng gan',
}, {
  'id': 'R94.6',
  'english': 'Abnormal results of thyroid function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng tuyến giáp',
}, {
  'id': 'R94.7',
  'english': 'Abnormal results of other endocrine function studies',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng nội tiết khác',
}, {
  'id': 'R94.8',
  'english': 'Abnormal results of function studies of other organs and systems',
  'vietnamese': 'Kết quả bất thường về thăm dò chức năng các cơ quan và hệ thống khác',
}, {
  'id': 'R95',
  'english': 'Sudden infant death syndrome',
  'vietnamese': 'Hội chứng đột tử trẻ em',
}, {
  'id': 'R95.0',
  'english': 'Sudden infant death syndrome with mention of autopsy',
  'vietnamese': 'Hội chứng đột tử trẻ em có đề cập đến khám nghiệm tử thi',
}, {
  'id': 'R95.9',
  'english': 'Sudden infant death syndrome without mention of autopsy',
  'vietnamese': 'Hội chứng đột tử trẻ em không đề cập đến khám nghiệm tử thi',
}, {
  'id': 'R96',
  'english': 'Other sudden death, cause unknown',
  'vietnamese': 'Đột tử khác không rõ nguyên nhân',
}, {'id': 'R96.0', 'english': 'Instantaneous death', 'vietnamese': 'Đột tử'}, {
  'id': 'R96.1',
  'english': 'Death occurring less than 24 hours from onset of symptoms, not otherwise explained',
  'vietnamese': 'Tử vong dưới 24 giờ sau khi khởi phát các triệu chứng, không có cách giải thích',
}, {'id': 'R98', 'english': 'Unattended death', 'vietnamese': 'Tử vong không ai biết'}, {
  'id': 'R99',
  'english': 'Other ill-defined and unspecified causes of morality',
  'vietnamese': 'Nguyên nhân tử vong không xác định khác và bệnh xác định không đặc hiệu khác',
}, {
  'id': 'S00',
  'english': 'Superficial injury of head',
  'vietnamese': 'Tổn thương nông ở đầu',
}, {
  'id': 'S00.0',
  'english': 'Superficial injury of scalp',
  'vietnamese': 'Tổn thương nông của da đầu',
}, {
  'id': 'S00.1',
  'english': 'Contusion of eyelid and periocular area',
  'vietnamese': 'đụng giập mi mắt và vùng quanh ổ mắt',
}, {
  'id': 'S00.2',
  'english': 'Other superficial injuries of eyelid and periocular area',
  'vietnamese': 'Tổn thương nông khác của mi mắt và vùng quanh ổ mắt',
}, {
  'id': 'S00.3',
  'english': 'Superficial injury of nose',
  'vietnamese': 'Tổn thương nông của mũi',
}, {
  'id': 'S00.4',
  'english': 'Superficial injury of ear',
  'vietnamese': 'Tổn thương nông của tai',
}, {
  'id': 'S00.5',
  'english': 'Superficial injury of lip and oral cavity',
  'vietnamese': 'Tổn thương nông của môi và khoang miệng',
}, {
  'id': 'S00.7',
  'english': 'Multiple superficial injuries of head',
  'vietnamese': 'Tổn thương nông và nhiều ở đầu',
}, {
  'id': 'S00.8',
  'english': 'Superficial injury of other parts of head',
  'vietnamese': 'Tổn thương của các phần khác của đầu',
}, {
  'id': 'S00.9',
  'english': 'Superficial injury of head, part unspecified',
  'vietnamese': 'Tổn thương nông ở đầu, phần không xác định',
}, {'id': 'S01', 'english': 'Open wound of head', 'vietnamese': 'Vết thương hở ở đầu'}, {
  'id': 'S01.0',
  'english': 'Open wound of scalp',
  'vietnamese': 'Vết thương hở của da đầu',
}, {
  'id': 'S01.1',
  'english': 'Open wound of eyelid and periocular area',
  'vietnamese': 'Vết thương hở của mi mắt và vùng quanh mắt',
}, {'id': 'S01.2', 'english': 'Open wound of nose', 'vietnamese': 'Vết thương hở của mũi'}, {
  'id': 'S01.3',
  'english': 'Open wound of ear',
  'vietnamese': 'Vết thương hở của tai',
}, {
  'id': 'S01.4',
  'english': 'Open wound of cheek and temporomandibular area',
  'vietnamese': 'Vết thương hở của má và vùng thái dương - xương hàm dưới',
}, {
  'id': 'S01.5',
  'english': 'Open wound of lip and oral cavity',
  'vietnamese': 'Vết thương của môi và khoang miệng',
}, {
  'id': 'S01.7',
  'english': 'Multiple open wounds of head',
  'vietnamese': 'Nhiều vết thương hở của đầu',
}, {
  'id': 'S01.8',
  'english': 'Open wound of other parts of head',
  'vietnamese': 'Vết thương hở của các phần khác của đầu',
}, {
  'id': 'S01.9',
  'english': 'Open wound of head, part unspecified',
  'vietnamese': 'Vết thương hở của đầu, phần không xác định',
}, {
  'id': 'S02',
  'english': 'Fracture of skull and facial bones',
  'vietnamese': 'Vỡ xương sọ và xương mặt',
}, {'id': 'S02.0', 'english': 'Fracture of vault of skull', 'vietnamese': 'Vỡ vòm sọ'}, {
  'id': 'S02.1',
  'english': 'Fracture of base of skull',
  'vietnamese': 'Vỡ nền sọ',
}, {'id': 'S02.2', 'english': 'Fracture of nasal bones', 'vietnamese': 'Vỡ xương mũi'}, {
  'id': 'S02.3',
  'english': 'Fracture of orbital floor',
  'vietnamese': 'Vỡ xương sàn ổ mắt',
}, {
  'id': 'S02.4',
  'english': 'Fracture of malar and maxillary bones',
  'vietnamese': 'Vỡ xương má và xương hàm',
}, {'id': 'S02.5', 'english': 'Fracture of tooth', 'vietnamese': 'Gẫy răng'}, {
  'id': 'S02.6',
  'english': 'Fracture of mandible',
  'vietnamese': 'Vỡ xương hàm',
}, {
  'id': 'S02.7',
  'english': 'Multiple fractures involving skull and facial bones',
  'vietnamese': 'Nhiều mảnh vỡ của xương sọ và xương mặt',
}, {
  'id': 'S02.8',
  'english': 'Fractures of other skull and facial bones',
  'vietnamese': 'Vỡ các xương khác của sọ và mặt',
}, {
  'id': 'S02.9',
  'english': 'Fracture of skull and facial bones, part unspecified',
  'vietnamese': 'Vỡ các xương sọ và xương mặt, phần không xác định',
}, {
  'id': 'S03',
  'english': 'Dislocation, sprain and strain of joints and ligaments of head',
  'vietnamese': 'Sai khớp, bong gân và căng cơ của khớp và các dây chằng của đầu',
}, {'id': 'S03.0', 'english': 'Dislocation of jaw', 'vietnamese': 'Sai khớp hàm'}, {
  'id': 'S03.1',
  'english': 'Dislocation of septal cartilage of nose',
  'vietnamese': 'Sai khớp của sụn vách mũi',
}, {'id': 'S03.2', 'english': 'Dislocation of tooth', 'vietnamese': 'Sai khớp răng'}, {
  'id': 'S03.3',
  'english': 'Dislocation of other and unspecified parts of head',
  'vietnamese': 'Sai khớp của các phần không xác định của đầu',
}, {
  'id': 'S03.4',
  'english': 'Sprain and strain of jaw',
  'vietnamese': 'Bong gân và căng cơ của hàm',
}, {
  'id': 'S03.5',
  'english': 'Sprain and strain of joints and ligaments of other and unspecified parts of head',
  'vietnamese': 'Bong gân và căng cơ của khớp và dây chằng của các phần khác không xác định của đầu',
}, {
  'id': 'S04',
  'english': 'Injury of cranial nerves',
  'vietnamese': 'Tổn thương dây thần kinh sọ',
}, {
  'id': 'S04.0',
  'english': 'Injury of optic nerve and pathways',
  'vietnamese': 'Tổn thương dây thần kinh thị giác và đường đi',
}, {
  'id': 'S04.1',
  'english': 'Injury of oculomotor nerve',
  'vietnamese': 'Tổn thương dây thần kinh vận nhãn',
}, {
  'id': 'S04.2',
  'english': 'Injury of trochlear nerve',
  'vietnamese': 'Tổn thương dây thần kinh ròng rọc',
}, {
  'id': 'S04.3',
  'english': 'Injury of trigeminal nerve',
  'vietnamese': 'Tổn thương dây thần kinh thứ 3',
}, {
  'id': 'S04.4',
  'english': 'Injury of abducent nerve',
  'vietnamese': 'Tổn thương dây thần kinh',
}, {
  'id': 'S04.5',
  'english': 'Injury of facial nerve',
  'vietnamese': 'Tổn thương dây thần kinh thính giác',
}, {
  'id': 'S04.6',
  'english': 'Injury of acoustic nerve',
  'vietnamese': 'Tổn thương dây thần kinh thính giác',
}, {
  'id': 'S04.7',
  'english': 'Injury of accessory nerve',
  'vietnamese': 'Tổn thương dây thần kinh phụ',
}, {
  'id': 'S04.8',
  'english': 'Injury of other cranial nerves',
  'vietnamese': 'Tổn thương dác dây thần kinh sọ khác',
}, {
  'id': 'S04.9',
  'english': 'Injury of unspecified cranial nerve',
  'vietnamese': 'Tổn thương dây thần kinh sọ không xác định',
}, {'id': 'S05', 'english': 'Injury of eye and orbit', 'vietnamese': 'Tổn thương mắt và ổ mắt'}, {
  'id': 'S05.0',
  'english': 'Injury of conjunctiva and corneal abrasion without mention of foreign body',
  'vietnamese': 'Tổn thương kết mạc và xước giác mạc không có dị vật',
}, {
  'id': 'S05.1',
  'english': 'Contusion of eyeball and orbital tissues',
  'vietnamese': 'Đụng giập nhãn cầu và tổ chức hố mắt',
}, {
  'id': 'S05.2',
  'english': 'Ocular laceration and rupture with prolapse or loss of intraocular tissue',
  'vietnamese': 'Rách xé nhãn cầu và thủng với sa hay mất tổ chức trong nhãn cầu',
}, {
  'id': 'S05.3',
  'english': 'Ocular laceration without prolapse or loss of intraocular tissue',
  'vietnamese': 'Rách xé nhãn cầu không có sa hay mất tổ chức trong nhãn cầu',
}, {
  'id': 'S05.4',
  'english': 'Penetrating wound of orbit with or without foreign body',
  'vietnamese': 'Vết thương thấu nhãn cầu có hay không có dị vật',
}, {
  'id': 'S05.5',
  'english': 'Penetrating wound of eyeball with foreign body',
  'vietnamese': 'Vết thương thấu nhãn cầu có dị vật',
}, {
  'id': 'S05.6',
  'english': 'Penetrating wound of eyeball without foreign body',
  'vietnamese': 'Vết thương thấu nhãn cầu không có dị vật',
}, {'id': 'S05.7', 'english': 'Avulsion of eye', 'vietnamese': 'Nhổ giật mắt'}, {
  'id': 'S05.8',
  'english': 'Other injuries of eye and orbit',
  'vietnamese': 'Các tổn thương khác của mắt và ổ mắt',
}, {
  'id': 'S05.9',
  'english': 'Injury of eye and orbit, unspecified',
  'vietnamese': 'Tổn thương của mắt và ổ mắt, phần không đặc hiệu',
}, {'id': 'S06', 'english': 'Intracranial injury', 'vietnamese': 'Tổn thương nội sọ'}, {
  'id': 'S06.0',
  'english': 'Concussion',
  'vietnamese': 'Chấn động',
}, {'id': 'S06.1', 'english': 'Traumatic cerebral oedema', 'vietnamese': 'Phù não chấn thương'}, {
  'id': 'S06.2',
  'english': 'Diffuse brain injury',
  'vietnamese': 'Tổn thương não lan toả',
}, {'id': 'S06.3', 'english': 'Focal brain injury', 'vietnamese': 'Tổn thương ổ của não'}, {
  'id': 'S06.4',
  'english': 'Epidural haemorrhage',
  'vietnamese': 'Chảy máu trên màng cứng',
}, {
  'id': 'S06.5',
  'english': 'Traumatic subdural haemorrhage',
  'vietnamese': 'Chảy máu dưới màng cứng',
}, {
  'id': 'S06.6',
  'english': 'Traumatic subarachnoid haemorrhage',
  'vietnamese': 'Chảy máu dưới màng nhện chấn thương',
}, {
  'id': 'S06.7',
  'english': 'Intracranial injury with prolonged coma',
  'vietnamese': 'Tổn thương nội sọ có hôn mê kéo dài',
}, {
  'id': 'S06.8',
  'english': 'Other intracranial injuries',
  'vietnamese': 'Tổn thương nội sọ khác',
}, {
  'id': 'S06.9',
  'english': 'Intracranial injury, unspecified',
  'vietnamese': 'Tổn thương nội sọ không đặc hiệu',
}, {
  'id': 'S07',
  'english': 'Crushing injury of head',
  'vietnamese': 'Tổn thương vùi lấp của đầu',
}, {
  'id': 'S07.0',
  'english': 'Crushing injury of face',
  'vietnamese': 'Tổn thương vùi lấp của mặt',
}, {
  'id': 'S07.1',
  'english': 'Crushing injury of skull',
  'vietnamese': 'Tổn thương vùi lấp của hộp sọ',
}, {
  'id': 'S07.8',
  'english': 'Crushing injury of other parts of head',
  'vietnamese': 'Tổn thương vì lấp của các phần khác của đầu',
}, {
  'id': 'S07.9',
  'english': 'Crushing injury of head, part unspecified',
  'vietnamese': 'Tổn thương vùi lấp của đầu, các phần không xác định',
}, {
  'id': 'S08',
  'english': 'Traumatic amputation of part of head',
  'vietnamese': 'Cắt cụt phần của đầu do chấn thương',
}, {'id': 'S08.0', 'english': 'Avulsion of scalp', 'vietnamese': 'Nhổ giật da đầu'}, {
  'id': 'S08.1',
  'english': 'Traumatic amputation of ear',
  'vietnamese': 'Cắt cụt do chấn thương tai',
}, {
  'id': 'S08.8',
  'english': 'Traumatic amputation of other parts of head',
  'vietnamese': 'Cắt cụt các phần khác của đầu do chấn thương của',
}, {
  'id': 'S08.9',
  'english': 'Traumatic amputation of unspecified part of head',
  'vietnamese': 'Cắt cụt của phần không xác định của đầu do chấn thương',
}, {
  'id': 'S09',
  'english': 'Other and unspecified injuries of head',
  'vietnamese': 'Tổn thương khác và không đặc hiệu của đầu',
}, {
  'id': 'S09.0',
  'english': 'Injury of blood vessels of head, not elsewhere classified',
  'vietnamese': 'Tổn thương các mạch máu của đầu, không xếp hạng nơi khác',
}, {
  'id': 'S09.1',
  'english': 'Injury of muscle and tendon of head',
  'vietnamese': 'Tổn thương cơ và gân của đầu',
}, {
  'id': 'S09.2',
  'english': 'Traumatic rupture of ear drum',
  'vietnamese': 'Rách chấn thương màng nhĩ',
}, {
  'id': 'S09.7',
  'english': 'Multiple injuries of head',
  'vietnamese': 'Tổn thương phức tạp của đầu',
}, {
  'id': 'S09.8',
  'english': 'Other specified injuries of head',
  'vietnamese': 'Tổn thương đặc hiệu khác của đầu',
}, {
  'id': 'S09.9',
  'english': 'Unspecified injury of head',
  'vietnamese': 'Tổn thương không đặc hiệu của đầu',
}, {
  'id': 'S10',
  'english': 'Superficial injury of neck',
  'vietnamese': 'Tổn thương nông của cổ',
}, {
  'id': 'S10.0',
  'english': 'Contusion of throat',
  'vietnamese': 'Đụng giập họng',
}, {
  'id': 'S10.1',
  'english': 'Other and unspecified superficial injuries of throat',
  'vietnamese': 'Tổn thương nông không đặc hiệu và khác của họng',
}, {
  'id': 'S10.7',
  'english': 'Multiple superficial injuries of neck',
  'vietnamese': 'Đa tổn thương nông của cổ',
}, {
  'id': 'S10.8',
  'english': 'Superficial injury of other parts of neck',
  'vietnamese': 'Tổn thương nông của các phần khác của cổ',
}, {
  'id': 'S10.9',
  'english': 'Superficial injury of neck, part unspecified',
  'vietnamese': 'Tổn thương nông của cổ, phần không đặc hiệu',
}, {'id': 'S11', 'english': 'Open wound of neck', 'vietnamese': 'Vết thương hở của cổ'}, {
  'id': 'S11.0',
  'english': 'Open wound involving larynx and trachea',
  'vietnamese': 'Vết thương hở bao gồm thanh quản và khí quản',
}, {
  'id': 'S11.1',
  'english': 'Open wound involving thyroid gland',
  'vietnamese': 'Vết thương hở bao gồm tuyến giáp',
}, {
  'id': 'S11.2',
  'english': 'Open wound involving pharynx and cervical oesophagus',
  'vietnamese': 'Vết thương hở bao gồm hầu và thực quản phần cổ',
}, {
  'id': 'S11.7',
  'english': 'Multiple open wounds of neck',
  'vietnamese': 'Đa vết thương hở của cổ',
}, {
  'id': 'S11.8',
  'english': 'Open wound of other parts of neck',
  'vietnamese': 'Vết thương hở của các phần khác của cổ',
}, {
  'id': 'S11.9',
  'english': 'Open wound of neck, part unspecified',
  'vietnamese': 'Vết thương hở của cổ, phần không đặc hiệu',
}, {'id': 'S12', 'english': 'Fracture of neck', 'vietnamese': 'Gẫy cổ'}, {
  'id': 'S12.0',
  'english': 'Fracture of first cervical vertebra',
  'vietnamese': 'Gẫy đốt sống cổ thứ nhất',
}, {
  'id': 'S12.1',
  'english': 'Fracture of second cervical vertebra',
  'vietnamese': 'Gẫy đốt sống cổ thứ hai',
}, {
  'id': 'S12.2',
  'english': 'Fracture of other specified cervical vertebra',
  'vietnamese': 'Gẫy đốt sống cổ đặc hiệu khác',
}, {
  'id': 'S12.7',
  'english': 'Multiple fractures of cervical spine',
  'vietnamese': 'Gẫy nhiều chỗ của đốt sống cổ',
}, {
  'id': 'S12.8',
  'english': 'Fracture of other parts of neck',
  'vietnamese': 'Gẫy các phần khác của cổ',
}, {
  'id': 'S12.9',
  'english': 'Fracture of neck, part unspecified',
  'vietnamese': 'Gẫy cổ, phần không đặc hiệu',
}, {
  'id': 'S13',
  'english': 'Dislocation, sprain and strain of joints and ligaments at neck level',
  'vietnamese': 'Sai khớp, bong gân và căng cơ quá mức của khớp và các dây chằng thuộc vùng cổ',
}, {
  'id': 'S13.0',
  'english': 'Traumatic rupture of cervical intervertebral disc',
  'vietnamese': 'Vỡ đĩa liên đốt sống cổ do chấn thương',
}, {
  'id': 'S13.1',
  'english': 'Dislocation of cervical vertebra',
  'vietnamese': 'Sai khớp đốt sống cổ',
}, {
  'id': 'S13.2',
  'english': 'Dislocation of other and unspecified parts of neck',
  'vietnamese': 'Sai khớp của các phần khác và không đặc hiệu của cổ',
}, {
  'id': 'S13.3',
  'english': 'Multiple dislocations of neck',
  'vietnamese': 'Sai khớp phức tạp của cổ',
}, {
  'id': 'S13.4',
  'english': 'Sprain and strain of cervical spine',
  'vietnamese': 'Bong gân và căng cơ quá mức của đốt sống cổ',
}, {
  'id': 'S13.5',
  'english': 'Sprain and strain of thyroid region',
  'vietnamese': 'Bong gân và căng cơ vùng giáp',
}, {
  'id': 'S13.6',
  'english': 'Sprain and strain of joints and ligaments of other and unspecified parts of neck',
  'vietnamese': 'Bong gân và căng cơ cả khớp và dây chằng của các phân không đặc hiệu và khác của cổ',
}, {
  'id': 'S14',
  'english': 'Injury of nerves and spinal cord at neck level',
  'vietnamese': 'Tổn thương dây thần kinh và tủy sống (đoạn) cổ',
}, {
  'id': 'S14.0',
  'english': 'Concussion and oedema of cervical spinal cord',
  'vietnamese': 'Chấn động và phù tủy sống đoạn cổ',
}, {
  'id': 'S14.1',
  'english': 'Other and unspecified injuries of cervical spinal cord',
  'vietnamese': 'Tổn thương không đặc hiệu và khác của tủy sống cổ',
}, {
  'id': 'S14.2',
  'english': 'Injury of nerve root of cervical spine',
  'vietnamese': 'Tổn thương rễ thần kinh của gai sống cổ',
}, {
  'id': 'S14.3',
  'english': 'Injury of brachial plexus',
  'vietnamese': 'Tổn thương đám rối thần kinh cánh tay',
}, {
  'id': 'S14.4',
  'english': 'Injury of peripheral nerves of neck',
  'vietnamese': 'Tổn thương đám rối thần kinh ngoại vi của cổ',
}, {
  'id': 'S14.5',
  'english': 'Injury of cervical sympathetic nerves',
  'vietnamese': 'Tổn thương dây thần kinh giao cảm cổ',
}, {
  'id': 'S14.6',
  'english': 'Injury of other and unspecified nerves of neck',
  'vietnamese': 'Tổn thương các dây thần kinh không đặc hiệu và khác của cổ',
}, {
  'id': 'S15',
  'english': 'Injury of blood vessels at neck level',
  'vietnamese': 'Tổn thương mạch máu vùng cổ',
}, {
  'id': 'S15.0',
  'english': 'Injury of carotid artery',
  'vietnamese': 'Tổn thương động mạch cảnh',
}, {
  'id': 'S15.1',
  'english': 'Injury of vertebral artery',
  'vietnamese': 'Tổn thương động mạch sống',
}, {
  'id': 'S15.2',
  'english': 'Injury of external jugular vein',
  'vietnamese': 'Tổn thương tĩnh mạch cổ ngoài',
}, {
  'id': 'S15.3',
  'english': 'Injury of internal jugular vein',
  'vietnamese': 'Tổn thương tĩnh mạch cổ trong',
}, {
  'id': 'S15.7',
  'english': 'Injury of multiple blood vessels at neck level',
  'vietnamese': 'Tổn thương nhiều mạch máu vùng cổ',
}, {
  'id': 'S15.8',
  'english': 'Injury of other blood vessels at neck level',
  'vietnamese': 'Tổn thương nhiều mạch máu khác vùng cổ',
}, {
  'id': 'S15.9',
  'english': 'Injury of unspecified blood vessel at neck level',
  'vietnamese': 'Tổn thương mạch máu không đặc hiệu vùng cổ',
}, {
  'id': 'S16',
  'english': 'Injury of muscle and tendon at neck level',
  'vietnamese': 'Tổn thương cơ và gân vùng cổ',
}, {
  'id': 'S17',
  'english': 'Crushing injury of neck',
  'vietnamese': 'Tổn thương vùi lấp của cổ',
}, {
  'id': 'S17.0',
  'english': 'Crushing injury of larynx and trachea',
  'vietnamese': 'Tổn thương vùi lấp của thanh quản và phế quản',
}, {
  'id': 'S17.8',
  'english': 'Crushing injury of other parts of neck',
  'vietnamese': 'Tổn thương vùi lấp của các phần khác của cổ',
}, {
  'id': 'S17.9',
  'english': 'Crushing injury of neck, part unspecified',
  'vietnamese': 'Tổn thương vùi lấp của cổ, phần không đặc hiệu',
}, {
  'id': 'S18',
  'english': 'Traumatic amputation at neck level',
  'vietnamese': 'Cắt cụt vùng cổ do chấn thương',
}, {
  'id': 'S19',
  'english': 'Other and unspecified injuries of neck',
  'vietnamese': 'Tổn thương không đặc hiệu khác của cổ',
}, {
  'id': 'S19.7',
  'english': 'Multiple injuries of neck',
  'vietnamese': 'Tổn thương phức tạp của cổ',
}, {
  'id': 'S19.8',
  'english': 'Other specified injuries of neck',
  'vietnamese': 'Tổn thương đặc hiệu khác của cổ',
}, {
  'id': 'S19.9',
  'english': 'Unspecified injury of neck',
  'vietnamese': 'Tổn thương không đặc hiệu của cổ',
}, {
  'id': 'S20',
  'english': 'Superficial injury of thorax',
  'vietnamese': 'Tổn thương của lồng ngực',
}, {'id': 'S20.0', 'english': 'Contusion of breast', 'vietnamese': 'đụng giập vú'}, {
  'id': 'S20.1',
  'english': 'Other and unspecified superficial injuries of breast',
  'vietnamese': 'Tổn thương nông không đặc hiệu và khác của vú',
}, {'id': 'S20.2', 'english': 'Contusion of thorax', 'vietnamese': 'Đụng giập lồng ngực'}, {
  'id': 'S20.3',
  'english': 'Other superficial injuries of front wall of thorax',
  'vietnamese': 'Tổn thương nông khác của thành trước ngực',
}, {
  'id': 'S20.4',
  'english': 'Other superficial injuries of back wall of thorax',
  'vietnamese': 'Tổn thương nông khác của thành sau ngực',
}, {
  'id': 'S20.7',
  'english': 'Multiple superficial injuries of thorax',
  'vietnamese': 'Tổn thương phức tạp nông của ngực',
}, {
  'id': 'S20.8',
  'english': 'Superficial injury of other and unspecified parts of thorax',
  'vietnamese': 'Tổn thương nông của các phần không đặc hiệu và khác của ngực',
}, {'id': 'S21', 'english': 'Open wound of thorax', 'vietnamese': 'Vết thương hở của ngực'}, {
  'id': 'S21.0',
  'english': 'Open wound of breast',
  'vietnamese': 'Vết thương hở của vú',
}, {
  'id': 'S21.1',
  'english': 'Open wound of front wall of thorax',
  'vietnamese': 'Vết thương hở của thành trước ngực',
}, {
  'id': 'S21.2',
  'english': 'Open wound of back wall of thorax',
  'vietnamese': 'Vết thương hở của thành sau ngực',
}, {
  'id': 'S21.7',
  'english': 'Multiple open wounds of thoracic wall',
  'vietnamese': 'Vết thương hở phức tạp của thành ngực',
}, {
  'id': 'S21.8',
  'english': 'Open wound of other parts of thorax',
  'vietnamese': 'Vết thương hở của các phần khác của ngực',
}, {
  'id': 'S21.9',
  'english': 'Open wound of thorax, part unspecified',
  'vietnamese': 'Vết thương hở của ngực-phần không đặc hiệu',
}, {
  'id': 'S22',
  'english': 'Fracture of rib(s), sternum and thoracic spine',
  'vietnamese': 'Gẫy xương (nhiều) sườn, xương ức và gai sống ngực',
}, {
  'id': 'S22.0',
  'english': 'Fracture of thoracic vertebra',
  'vietnamese': 'Gẫy đốt sống ngực',
}, {
  'id': 'S22.1',
  'english': 'Multiple fractures of thoracic spine',
  'vietnamese': 'Gẫy phức tạp gai sống ngực',
}, {'id': 'S22.2', 'english': 'Fracture of sternum', 'vietnamese': 'Gẫy xương ức'}, {
  'id': 'S22.3',
  'english': 'Fracture of rib',
  'vietnamese': 'Gẫy xương sườn',
}, {
  'id': 'S22.4',
  'english': 'Multiple fractures of ribs',
  'vietnamese': 'Gẫy phức tạp xương sườn',
}, {'id': 'S22.5', 'english': 'Flail chest', 'vietnamese': 'Mảng sườn di động'}, {
  'id': 'S22.8',
  'english': 'Fracture of other parts of bony thorax',
  'vietnamese': 'Gẫy các phần khác của xương ngực',
}, {
  'id': 'S22.9',
  'english': 'Fracture of bony thorax, part unspecified',
  'vietnamese': 'Gẫy xương ngực, phần không đặc hiệu',
}, {
  'id': 'S23',
  'english': 'Dislocation, sprain and strain of joints and ligaments of thorax',
  'vietnamese': 'Sai khớp, bong gân và căng cơ quá mức của khớp và dây chằng của ngực',
}, {
  'id': 'S23.0',
  'english': 'Traumatic rupture of thoracic intervertebral disc',
  'vietnamese': 'Vỡ chấn thương của đĩa liên đốt ngực',
}, {
  'id': 'S23.1',
  'english': 'Dislocation of thoracic vertebra',
  'vietnamese': 'Sai khớp của các phần không đặc hiệu và khác của ngực',
}, {
  'id': 'S23.2',
  'english': 'Dislocation of other and unspecified parts of thorax',
  'vietnamese': 'Sai khớp của các phần không đặc hiệu và khác của ngực',
}, {
  'id': 'S23.3',
  'english': 'Sprain and strain of thoracic spine',
  'vietnamese': 'Bong gân và căng cơ của đốt sống ngực',
}, {
  'id': 'S23.4',
  'english': 'Sprain and strain of ribs and sternum',
  'vietnamese': 'Bong gân và căng cơ của xương sườn và xương ức',
}, {
  'id': 'S23.5',
  'english': 'Sprain and strain of other and unspecified parts of thorax',
  'vietnamese': 'Bong gân và căng cơ của các phần không đặc hiệu và khác của ngực',
}, {
  'id': 'S24',
  'english': 'Injury of nerves and spinal cord at thorax level',
  'vietnamese': 'Tổn thương dây thần kinh và tủy sống (vùng) ngực',
}, {
  'id': 'S24.0',
  'english': 'Concussion and oedema of thoracic spinal cord',
  'vietnamese': 'Chấn động và phù của tủy sống ngực',
}, {
  'id': 'S24.1',
  'english': 'Other and unspecified injuries of thoracic spinal cord',
  'vietnamese': 'Tổn thương không đặc hiệu và khác của tủy sống ngực',
}, {
  'id': 'S24.2',
  'english': 'Injury of nerve root of thoracic spine',
  'vietnamese': 'Tổn thương rễ thần kinh của đốt sống ngực',
}, {
  'id': 'S24.3',
  'english': 'Injury of peripheral nerves of thorax',
  'vietnamese': 'Tổn thương dây thần kinh ngoại vi của ngực',
}, {
  'id': 'S24.4',
  'english': 'Injury of thoracic sympathetic nerves',
  'vietnamese': 'Tổn thương dây thần kinh giao cảm ngực',
}, {
  'id': 'S24.5',
  'english': 'Injury of other nerves of thorax',
  'vietnamese': 'Tổn thương mạch máu của ngực',
}, {
  'id': 'S24.6',
  'english': 'Injury of unspecified nerve of thorax',
  'vietnamese': 'Tổn thương dây thần kinh không đặc hiệu của ngực',
}, {
  'id': 'S25',
  'english': 'Injury of blood vessels of thorax',
  'vietnamese': 'Tổn thương mạch máu của ngực',
}, {
  'id': 'S25.0',
  'english': 'Injury of thoracic aorta',
  'vietnamese': 'Tổn thương động mạch chủ ngực',
}, {
  'id': 'S25.1',
  'english': 'Injury of innominate or subclavian artery',
  'vietnamese': 'Tổn thương động mạch vô danh hay dưới đòn',
}, {
  'id': 'S25.2',
  'english': 'Injury of superior vena cava',
  'vietnamese': 'Tổn thương tĩnh mạch chủ trên-',
}, {
  'id': 'S25.3',
  'english': 'Injury of innominate or subclavian vein',
  'vietnamese': 'Tổn thương tĩnh mạch nào đó hay tĩnh mạch dưới đòn',
}, {
  'id': 'S25.4',
  'english': 'Injury of pulmonary blood vessels',
  'vietnamese': 'Tổn thương mạch máu phổi',
}, {
  'id': 'S25.5',
  'english': 'Injury of intercostal blood vessels',
  'vietnamese': 'Tổn thương mạch máu liên sườn',
}, {
  'id': 'S25.7',
  'english': 'Injury of multiple blood vessels of thorax',
  'vietnamese': 'Tổn thương mạch máu phức tạp của ngực',
}, {
  'id': 'S25.8',
  'english': 'Injury of other blood vessels of thorax',
  'vietnamese': 'Tổn thương mạch máu khác của ngực',
}, {
  'id': 'S25.9',
  'english': 'Injury of unspecified blood vessel of thorax',
  'vietnamese': 'Tổn thương mạch máu không đặc hiệu của ngực',
}, {'id': 'S26', 'english': 'Injury of heart', 'vietnamese': 'Tổn thương tim'}, {
  'id': 'S26.0',
  'english': 'Injury of heart with haemopericardium',
  'vietnamese': 'Tổn thương tim có tràn máu màng tim',
}, {
  'id': 'S26.8',
  'english': 'Other injuries of heart',
  'vietnamese': 'Tổn thương khác của tim',
}, {
  'id': 'S26.9',
  'english': 'Injury of heart, unspecified',
  'vietnamese': 'Tổn thương tim, không đặc hiệu',
}, {
  'id': 'S27',
  'english': 'Injury of other and unspecified intrathoracic organs',
  'vietnamese': 'Tổn thương của các cơ quan không đặc hiệu và khác trong lồng ngực',
}, {
  'id': 'S27.0',
  'english': 'Traumatic pneumothorax',
  'vietnamese': 'Tràn khí phổi do chấn thương',
}, {
  'id': 'S27.1',
  'english': 'Traumatic haemothorax',
  'vietnamese': 'Tràn máu phổi do chấn thương',
}, {
  'id': 'S27.2',
  'english': 'Traumatic haemopneumothorax',
  'vietnamese': 'Tràn khí máu phổi sang chấn',
}, {
  'id': 'S27.3',
  'english': 'Other injuries of lung',
  'vietnamese': 'Tổn thương khác của phổi',
}, {
  'id': 'S27.4',
  'english': 'Injury of bronchus',
  'vietnamese': 'Tổn thương phế quản',
}, {
  'id': 'S27.5',
  'english': 'Injury of thoracic trachea',
  'vietnamese': 'Tổn thương khí quản ngực',
}, {'id': 'S27.6', 'english': 'Injury of pleura', 'vietnamese': 'Tổn thương màng phổi'}, {
  'id': 'S27.7',
  'english': 'Multiple injuries of intrathoracic organs',
  'vietnamese': 'Đa tổn thương của các cơ quan trong ổ ngực',
}, {
  'id': 'S27.8',
  'english': 'Injury of other specified intrathoracic organs',
  'vietnamese': 'Tổn thương của các cơ quan khác đặc hiệu trong ổ ngực',
}, {
  'id': 'S27.9',
  'english': 'Injury of unspecified intrathoracic organ',
  'vietnamese': 'Tổn thương vùi lấp của ngực và cắt cụt sang chấn',
}, {
  'id': 'S28',
  'english': 'Crushing injury of thorax and traumatic amputation of part of thorax',
  'vietnamese': 'Tổn thương vùi lấp của ngực và cắt cụt sang chấn phần của ngực',
}, {'id': 'S28.0', 'english': 'Crushed chest', 'vietnamese': 'Ngực vùi lấp'}, {
  'id': 'S28.1',
  'english': 'Traumatic amputation of part of thorax',
  'vietnamese': 'Cắt cụt các phần sang chấn của ngực',
}, {
  'id': 'S29',
  'english': 'Other and unspecified injuries of thorax',
  'vietnamese': 'Tổn thương không đặc hiệu và khác của ngực',
}, {
  'id': 'S29.0',
  'english': 'Injury of muscle and tendon at thorax level',
  'vietnamese': 'Tổn thương cơ và gân vùng ngực',
}, {
  'id': 'S29.7',
  'english': 'Multiple injuries of thorax',
  'vietnamese': 'Tổn thương phức tạp của ngực',
}, {
  'id': 'S29.8',
  'english': 'Other specified injuries of thorax',
  'vietnamese': 'Tổn thương đặc hiệu khác của ngực',
}, {
  'id': 'S29.9',
  'english': 'Unspecified injury of thorax',
  'vietnamese': 'Tổn thương không đặc hiệu của ngực',
}, {
  'id': 'S30',
  'english': 'Superficial injury of abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương nông của bụng, lưng dưới và chậu hông',
}, {
  'id': 'S30.0',
  'english': 'Contusion of lower back and pelvis',
  'vietnamese': 'đụng giập của lưng dưới và chân, hông',
}, {
  'id': 'S30.1',
  'english': 'Contusion of abdominal wall',
  'vietnamese': 'đụng giập thành bụng',
}, {
  'id': 'S30.2',
  'english': 'Contusion of external genital organs',
  'vietnamese': 'Đụng giập cơ quan sinh dục ngoài',
}, {
  'id': 'S30.7',
  'english': 'Multiple superficial injuries of abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương nông phức tạp ở bụng, lưng dưới và chậu hông',
}, {
  'id': 'S30.8',
  'english': 'Other superficial injuries of abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương nông khác của bụng, lưng dưới và chậu hông',
}, {
  'id': 'S30.9',
  'english': 'Superficial injury of abdomen, lower back and pelvis, part unspecified',
  'vietnamese': 'Tổn thương nông của bụng, lưng dưới và chậu hông, phần không xác định',
}, {
  'id': 'S31',
  'english': 'Open wound of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương hở của bụng, lưng dưới và chậu hông',
}, {
  'id': 'S31.0',
  'english': 'Open wound of lower back and pelvis',
  'vietnamese': 'Vết thương hở của lưng dưới và chậu hông, phần không xác định',
}, {
  'id': 'S31.1',
  'english': 'Open wound of abdominal wall',
  'vietnamese': 'Vết thương hở của thành bụng',
}, {
  'id': 'S31.2',
  'english': 'Open wound of penis',
  'vietnamese': 'Vết thương hở của dương vật',
}, {
  'id': 'S31.3',
  'english': 'Open wound of scrotum and testes',
  'vietnamese': 'Vết thương hở của bìu và tinh hoàn',
}, {
  'id': 'S31.4',
  'english': 'Open wound of vagina and vulva',
  'vietnamese': 'Vết thương hở của âm đạo và âm hộ',
}, {
  'id': 'S31.5',
  'english': 'Open wound of other and unspecified external genital organs',
  'vietnamese': 'Vết thương hở của các cơ quan sinh dục ngoài không đặc hiệu và khác',
}, {
  'id': 'S31.7',
  'english': 'Multiple open wounds of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương hở phức tạp của bụng, lưng dưới và chậu hông',
}, {
  'id': 'S31.8',
  'english': 'Open wound of other and unspecified parts of abdomen',
  'vietnamese': 'Vết thương hở của các phần không đặc hiệu và khác của bụng',
}, {
  'id': 'S32',
  'english': 'Fracture of lumbar spine and pelvis',
  'vietnamese': 'Gẫy cột sống thắt lưng và chậu hông',
}, {
  'id': 'S32.0',
  'english': 'Fracture of lumbar vertebra',
  'vietnamese': 'Gẫy sống thắt lưng',
}, {
  'id': 'S32.1',
  'english': 'Fracture of sacrum',
  'vietnamese': 'Gẫy xương cùng',
}, {'id': 'S32.2', 'english': 'Fracture of coccyx', 'vietnamese': 'Gây xương cụt'}, {
  'id': 'S32.3',
  'english': 'Fracture of ilium',
  'vietnamese': 'Gẫy xương chậu',
}, {'id': 'S32.4', 'english': 'Fracture of acetabulum', 'vietnamese': 'Gẫy xương ổ cối'}, {
  'id': 'S32.5',
  'english': 'Fracture of pubis',
  'vietnamese': 'Gẫy xương mu',
}, {
  'id': 'S32.7',
  'english': 'Multiple fractures of lumbar spine and pelvis',
  'vietnamese': 'Gẫy phức tạp cột sống thắt lưng và chậu hông',
}, {
  'id': 'S32.8',
  'english': 'Fracture of other and unspecified parts of lumbar spine and pelvis',
  'vietnamese': 'Gẫy các phần không xác định và khác của gai thắt lưng và chậu',
}, {
  'id': 'S33',
  'english': 'Dislocation, sprain and strain of joints and ligaments of lumbar spine and pelvis',
  'vietnamese': 'Sai khớp, bong gân và giãn khớp và dây chằng cột sống thắt lưng và chậu',
}, {
  'id': 'S33.0',
  'english': 'Traumatic rupture of lumbar intervertebral disc',
  'vietnamese': 'Vỡ đĩa liên đốt thắt lưng do chấn thương',
}, {
  'id': 'S33.1',
  'english': 'Dislocation of lumbar vertebra',
  'vietnamese': 'Sai khớp sống thắt lưng',
}, {
  'id': 'S33.2',
  'english': 'Dislocation of sacroiliac and sacrococcygeal joint',
  'vietnamese': 'Sai khớp cùng chậu và cùng cụt',
}, {
  'id': 'S33.3',
  'english': 'Dislocation of other and unspecified parts of lumbar spine and pelvis',
  'vietnamese': 'Sai khớp các phần không xác định và khác của gai thắt lưng và chậu',
}, {
  'id': 'S33.4',
  'english': 'Traumatic rupture of symphysis pubis',
  'vietnamese': 'Vỡ khớp mu do chấn thương',
}, {
  'id': 'S33.5',
  'english': 'Sprain and strain of lumbar spine',
  'vietnamese': 'Bong gân và giãn khớp sống thắt lưng',
}, {
  'id': 'S33.6',
  'english': 'Sprain and strain of sacroiliac joint',
  'vietnamese': 'Bong gân và giãn khớp cùng chậu',
}, {
  'id': 'S33.7',
  'english': 'Sprain and strain of other and unspecified parts of lumbar spine and pelvis',
  'vietnamese': 'Bong gân và giãn khớp của các phần không xác định và khác của cột sống thắt lưng vàchậu',
}, {
  'id': 'S34',
  'english': 'Injury of nerves and lumbar spinal cord at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương dây thần kinh và dây sống tại bụng, lưng và chậu hông',
}, {
  'id': 'S34.0',
  'english': 'Concussion and oedema of lumbar spinal cord',
  'vietnamese': 'Chấn động và phù của dây sống thắt lưng',
}, {
  'id': 'S34.1',
  'english': 'Other injury of lumbar spinal cord',
  'vietnamese': 'Vết thương khác của dây sống thắt lưng',
}, {
  'id': 'S34.2',
  'english': 'Injury of nerve root of lumbar and sacral spine',
  'vietnamese': 'Vết thương rễ thần kinh của đốt sống thắt lưng và gai cùng',
}, {
  'id': 'S34.3',
  'english': 'Injury of cauda equina',
  'vietnamese': 'Tổn thương dây thần kinh đuôi ngựa',
}, {
  'id': 'S34.4',
  'english': 'Injury of lumbosacral plexus',
  'vietnamese': 'Tổn thương đám rối thần kinh thắt lưng cùng',
}, {
  'id': 'S34.5',
  'english': 'Injury of lumbar, sacral and pelvic sympathetic nerves',
  'vietnamese': 'Tổn thương dây thần kinh giao cảm vùng thắt lưng, cùng và chậu',
}, {
  'id': 'S34.6',
  'english': 'Injury of peripheral nerve(s) of abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương (các) dây thần kinh ngoại vi của bụng, lưng và chậu',
}, {
  'id': 'S34.8',
  'english': 'Injury of other and unspecified nerves at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương các dây thần kinh không xác định và khác của bụng, lưng và chậu',
}, {
  'id': 'S35',
  'english': 'Injury of blood vessels at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương mạch máu vùng bụng, lưng và chậu',
}, {
  'id': 'S35.0',
  'english': 'Injury of abdominal aorta',
  'vietnamese': 'Tổn thương động mạch chủ bụng',
}, {
  'id': 'S35.1',
  'english': 'Injury of inferior vena cava',
  'vietnamese': 'Tổn thương tĩnh mạch chủ dưới',
}, {
  'id': 'S35.2',
  'english': 'Injury of coeliac or mesenteric artery',
  'vietnamese': 'Tổn thương động mạch bụng hay tràng',
}, {
  'id': 'S35.3',
  'english': 'Injury of portal or splenic vein',
  'vietnamese': 'Tổn thương tĩnh mạch cửa hay gan',
}, {
  'id': 'S35.4',
  'english': 'Injury of renal blood vessels',
  'vietnamese': 'Tổn thương mạch máu thận',
}, {
  'id': 'S35.5',
  'english': 'Injury of iliac blood vessels',
  'vietnamese': 'Tổn thương các mạch máu của chậu hông',
}, {
  'id': 'S35.7',
  'english': 'Injury of multiple blood vessels at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương phức tạp mạch máu vùng bụng, lưng dưới và chậu',
}, {
  'id': 'S35.8',
  'english': 'Injury of other blood vessels at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương các mạch máu khác vùng bụng, lưng dưới và chậu',
}, {
  'id': 'S35.9',
  'english': 'Injury of unspecified blood vessel at abdomen, lower back and pelvis level',
  'vietnamese': 'Tổn thương mạch máu không xác định vùng bụng, lưng và chậu',
}, {
  'id': 'S36',
  'english': 'Injury of intra-abdominal organs',
  'vietnamese': 'Tổn thương các cơ quan trong ổ bụng',
}, {'id': 'S36.0', 'english': 'Injury of spleen', 'vietnamese': 'Vết thương lách'}, {
  'id': 'S36.1',
  'english': 'Injury of liver or gallbladder',
  'vietnamese': 'Vết thương gan hay túi mật',
}, {'id': 'S36.2', 'english': 'Injury of pancreas', 'vietnamese': 'Vết thương tụy'}, {
  'id': 'S36.3',
  'english': 'Injury of stomach',
  'vietnamese': 'Vết thương dạ dày',
}, {'id': 'S36.4', 'english': 'Injury of small intestine', 'vietnamese': 'Vết thương ruột non'}, {
  'id': 'S36.5',
  'english': 'Injury of colon',
  'vietnamese': 'Vết thương đại tràng',
}, {'id': 'S36.6', 'english': 'Injury of rectum', 'vietnamese': 'Vết thương trực tràng'}, {
  'id': 'S36.7',
  'english': 'Injury of multiple intra-abdominal organs',
  'vietnamese': 'Vết thương nhiều cơ quan trong ổ bụng',
}, {
  'id': 'S36.8',
  'english': 'Injury of other intra-abdominal organs',
  'vietnamese': 'Vết thương các cơ quan khác trong ổ bụng',
}, {
  'id': 'S36.9',
  'english': 'Injury of unspecified intra-abdominal organ',
  'vietnamese': 'Vết thương cơ quan trong ổ bụng không xác định',
}, {
  'id': 'S37',
  'english': 'Injury of urinary and pelvic organs',
  'vietnamese': 'Tổn thương của hệ tiết niệu và cơ quan ở chậu hông',
}, {'id': 'S37.0', 'english': 'Injury of kidney', 'vietnamese': 'Vết thương thận'}, {
  'id': 'S37.1',
  'english': 'Injury of ureter',
  'vietnamese': 'Vết thương niệu quản',
}, {'id': 'S37.2', 'english': 'Injury of bladder', 'vietnamese': 'Vết thương bàng quang'}, {
  'id': 'S37.3',
  'english': 'Injury of urethra',
  'vietnamese': 'Vết thương niệu đạo',
}, {'id': 'S37.4', 'english': 'Injury of ovary', 'vietnamese': 'Vết thương buồng trứng'}, {
  'id': 'S37.5',
  'english': 'Injury of fallopian tube',
  'vietnamese': 'Vết thương vòi trứng',
}, {'id': 'S37.6', 'english': 'Injury of uterus', 'vietnamese': 'Vết thương tử cung'}, {
  'id': 'S37.7',
  'english': 'Injury of multiple pelvic organs',
  'vietnamese': 'Vết thương nhiều cơ quan vùng chậu',
}, {
  'id': 'S37.8',
  'english': 'Injury of other pelvic organs',
  'vietnamese': 'Vết thương cơ quan vùng chậu khác',
}, {
  'id': 'S37.9',
  'english': 'Injury of unspecified pelvic organ',
  'vietnamese': 'Vết thương cơ quan chậu không xác định',
}, {
  'id': 'S38',
  'english': 'Crushing injury and traumatic amputation of part of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương vùi lấp và cắt đoạn sang chấn một phần của bụng, lưng dưới và chậu',
}, {
  'id': 'S38.0',
  'english': 'Crushing injury of external genital organs',
  'vietnamese': 'Vết thương vùi lấp cơ quan sinh dục ngoài',
}, {
  'id': 'S38.1',
  'english': 'Crushing injury of other and unspecified parts of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương vùi lấp ở các phần không đặc hiệu khác của bụng, lưng dưới, chậu',
}, {
  'id': 'S38.2',
  'english': 'Traumatic amputation of external genital organs',
  'vietnamese': 'Cắt đoạn sang chấn cơ quan sinh dục ngoài',
}, {
  'id': 'S38.3',
  'english': 'Traumatic amputation of other and unspecified parts of abdomen, lower back and pelvis',
  'vietnamese': 'Cắt đoạn sang chấn các phần không xác định và khác của bụng, lưng dưới và chậu',
}, {
  'id': 'S39',
  'english': 'Other and unspecified injuries of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương không xác định và khác của bụng, lưng dưới và chậu',
}, {
  'id': 'S39.0',
  'english': 'Injury of muscle and tendon of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương cơ và gân của bụng, lưng dưới và chậu',
}, {
  'id': 'S39.6',
  'english': 'Injury of intra-abdominal organ(s) with pelvic organ(s)',
  'vietnamese': 'Vết thương (các) cơ quan trong ổ bụng và (các) cơ quan chậu',
}, {
  'id': 'S39.7',
  'english': 'Other multiple injuries of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương phức tạp của bụng, lưng dưới và chậu',
}, {
  'id': 'S39.8',
  'english': 'Other specified injuries of abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương đặc hiệu khác của bụng, lưng dưới và chậu',
}, {
  'id': 'S39.9',
  'english': 'Unspecified injury of abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương bụng, dưới lưng và chậu',
}, {
  'id': 'S40',
  'english': 'Superficial injury of shoulder and upper arm',
  'vietnamese': 'Vết thương nông của vai và cánh tay',
}, {
  'id': 'S40.0',
  'english': 'Contusion of shoulder and upper arm',
  'vietnamese': 'Chấn động vai và cánh tay',
}, {
  'id': 'S40.7',
  'english': 'Multiple superficial injuries of shoulder and upper arm',
  'vietnamese': 'Vết thương nông phức tạp của vai và cánh tay',
}, {
  'id': 'S40.8',
  'english': 'Other superficial injuries of shoulder and upper arm',
  'vietnamese': 'Vết thương nông khác của vai và cánh tay',
}, {
  'id': 'S40.9',
  'english': 'Superficial injury of shoulder and upper arm, unspecified',
  'vietnamese': 'Vết thương nông của vai và cánh tay, không đặc hiệu',
}, {
  'id': 'S41',
  'english': 'Open wound of shoulder and upper arm',
  'vietnamese': 'Vết thương hở của vai và cánh tay',
}, {'id': 'S41.0', 'english': 'Open wound of shoulder', 'vietnamese': 'Vết thương hở của vai'}, {
  'id': 'S41.1',
  'english': 'Open wound of upper arm',
  'vietnamese': 'Vết thương hở của cánh tay',
}, {
  'id': 'S41.7',
  'english': 'Multiple open wounds of shoulder and upper arm',
  'vietnamese': 'Vết thương hở phức tạp của vai và cánh tay',
}, {
  'id': 'S41.8',
  'english': 'Open wound of other and unspecified parts of shoulder girdle',
  'vietnamese': 'Vết thương hở của các phần không xác định và khác của vòng ngực',
}, {
  'id': 'S42',
  'english': 'Fracture of shoulder and upper arm',
  'vietnamese': 'Gẫy xương vai và xương cánh tay',
}, {'id': 'S42.0', 'english': 'Fracture of clavicle', 'vietnamese': 'Gẫy xương đòn'}, {
  'id': 'S42.1',
  'english': 'Fracture of scapula',
  'vietnamese': 'Gẫy xương vai',
}, {
  'id': 'S42.2',
  'english': 'Fracture of upper end of humerus',
  'vietnamese': 'Gẫy phần trên xương cánh tay',
}, {
  'id': 'S42.3',
  'english': 'Fracture of shaft of humerus',
  'vietnamese': 'Gẫy thân xương cánh tay',
}, {
  'id': 'S42.4',
  'english': 'Fracture of lower end of humerus',
  'vietnamese': 'Gẫy phần dướu xương cánh tay',
}, {
  'id': 'S42.7',
  'english': 'Multiple fractures of clavicle, scapula and humerus',
  'vietnamese': 'Gẫy phức tạp xương đòn, xương vai và xương cánh tay',
}, {
  'id': 'S42.8',
  'english': 'Fracture of other parts of shoulder and upper arm',
  'vietnamese': 'Gẫy các phần khác của xương vai và xương cánh tay',
}, {
  'id': 'S42.9',
  'english': 'Fracture of shoulder girdle, part unspecified',
  'vietnamese': 'Gẫy xương của vòng ngực, phần không xác định',
}, {
  'id': 'S43',
  'english': 'Dislocation, sprain and strain of joints and ligaments of shoulder girdle',
  'vietnamese': 'Sai khớp, bong gân và giãn khớp và dây chằng của vòng ngực',
}, {'id': 'S43.0', 'english': 'Dislocation of shoulder joint', 'vietnamese': 'Sai khớp vai'}, {
  'id': 'S43.1',
  'english': 'Dislocation of acromioclavicular joint',
  'vietnamese': 'Sai khớp mỏm cùng vai-đòn',
}, {
  'id': 'S43.2',
  'english': 'Dislocation of sternoclavicular joint',
  'vietnamese': 'Sai khớp ức-đòn',
}, {
  'id': 'S43.3',
  'english': 'Dislocation of other and unspecified parts of shoulder girdle',
  'vietnamese': 'Sai khớp các phần không xác định và các phần khác của vòng ngực',
}, {
  'id': 'S43.4',
  'english': 'Sprain and strain of shoulder joint',
  'vietnamese': 'Bong gân và giãn khớp của vai',
}, {
  'id': 'S43.5',
  'english': 'Sprain and strain of acromioclavicular joint',
  'vietnamese': 'Bong gân và giãn khớp mỏm cùng vai -đòn',
}, {
  'id': 'S43.6',
  'english': 'Sprain and strain of sternoclavicular joint',
  'vietnamese': 'Bong gân và giãn khớp của các phần khác và không xác định của vòng ngực',
}, {
  'id': 'S43.7',
  'english': 'Sprain and strain of other and unspecified parts of shoulder girdle',
  'vietnamese': 'Bong gân và giãn khớp của các phần khác và không xác định của vòng ngực',
}, {
  'id': 'S44',
  'english': 'Injury of nerves at shoulder and upper arm level',
  'vietnamese': 'Vết thương dây thần kinh tại vai và cánh tay trên',
}, {
  'id': 'S44.0',
  'english': 'Injury of ulnar nerve at upper arm level',
  'vietnamese': 'Vết thương dây thần kinh tại vai và cánh tay trên',
}, {
  'id': 'S44.1',
  'english': 'Injury of median nerve at upper arm level',
  'vietnamese': 'Vết thương dây thần kinh giữa tại cánh tay trên',
}, {
  'id': 'S44.2',
  'english': 'Injury of radial nerve at upper arm level',
  'vietnamese': 'Vết thương dây thân kinh quay tại cánh tay trên',
}, {
  'id': 'S44.3',
  'english': 'Injury of axillary nerve',
  'vietnamese': 'Vết thương dây thần kinh hố nách',
}, {
  'id': 'S44.4',
  'english': 'Injury of musculocutaneous nerve',
  'vietnamese': 'Vết thương dây thần kinh cơ-da',
}, {
  'id': 'S44.5',
  'english': 'Injury of cutaneous sensory nerve at shoulder and upper arm level',
  'vietnamese': 'Vết thương dây thần kinh cảm giác-da tai vai và cánh tay trên',
}, {
  'id': 'S44.7',
  'english': 'Injury of multiple nerves at shoulder and upper arm level',
  'vietnamese': 'Vết thương nhiều dây thần kinh tại vai và cánh tay trên',
}, {
  'id': 'S44.8',
  'english': 'Injury of other nerves at shoulder and upper arm level',
  'vietnamese': 'Vết thương các dây thần kinh khác tại vai và cánh tay trên',
}, {
  'id': 'S44.9',
  'english': 'Injury of unspecified nerve at shoulder and upper arm level',
  'vietnamese': 'Vết thương dây thần kinh không xác định tại vai và cánh tay trên',
}, {
  'id': 'S45',
  'english': 'Injury of blood vessels at shoulder and upper arm level',
  'vietnamese': 'Vết thương mạch máu tại vai cánh tay trên',
}, {
  'id': 'S45.0',
  'english': 'Injury of axillary artery',
  'vietnamese': 'Vết thương động mạch nách',
}, {
  'id': 'S45.1',
  'english': 'Injury of brachial artery',
  'vietnamese': 'Vết thương động mạch cánh tay',
}, {
  'id': 'S45.2',
  'english': 'Injury of axillary or brachial vein',
  'vietnamese': 'Vết thương tĩnh mạch nách hay cánh tay',
}, {
  'id': 'S45.3',
  'english': 'Injury of superficial vein at shoulder and upper arm level',
  'vietnamese': 'Vết thương tĩnh mạch máu nông tại vai hay cánh tay trên',
}, {
  'id': 'S45.7',
  'english': 'Injury of multiple blood vessels at shoulder and upper arm level',
  'vietnamese': 'Vết thương nhiều mạch máu tại vai và cánh tay trên',
}, {
  'id': 'S45.8',
  'english': 'Injury of other blood vessels at shoulder and upper arm level',
  'vietnamese': 'Vết thương các mạch máu khác tại vai và cánh tay trên',
}, {
  'id': 'S45.9',
  'english': 'Injury of unspecified blood vessel at shoulder and upper arm level',
  'vietnamese': 'Vết thương mạch máu không xác định tại vai và cánh tay trên',
}, {
  'id': 'S46',
  'english': 'Injury of muscle and tendon at shoulder and upper arm level',
  'vietnamese': 'Vết thương cơ và gân tại vai và cánh tay trên',
}, {
  'id': 'S46.0',
  'english': 'Injury of tendon of the rotator cuff of shoulder',
  'vietnamese': 'Tổn thương của các cơ và gân dải quay của vai',
}, {
  'id': 'S46.1',
  'english': 'Injury of muscle and tendon of long head of biceps',
  'vietnamese': 'Vết thương cơ và gân của đầu dài cơ nhị đầu',
}, {
  'id': 'S46.2',
  'english': 'Injury of muscle and tendon of other parts of biceps',
  'vietnamese': 'Vết thương cơ và gân của các phần khác của cơ nhị đầu',
}, {
  'id': 'S46.3',
  'english': 'Injury of muscle and tendon of triceps',
  'vietnamese': 'Vết thương cơ và gân cơ tam đầu',
}, {
  'id': 'S46.7',
  'english': 'Injury of multiple muscles and tendons at shoulder and upper arm level',
  'vietnamese': 'Vết thương nhiều cơ và gân tại vai và cánh tay trên',
}, {
  'id': 'S46.8',
  'english': 'Injury of other muscles and tendons at shoulder and upper arm level',
  'vietnamese': 'Vết thương các cơ và gân khác tại vai và cánh tay trên',
}, {
  'id': 'S46.9',
  'english': 'Injury of unspecified muscle and tendon at shoulder and upper arm level',
  'vietnamese': 'Vết thương cơ và gân không xác định tại vai và cánh tay trên',
}, {
  'id': 'S47',
  'english': 'Crushing injury of shoulder and upper arm',
  'vietnamese': 'Tổn thương giập nát vai và cánh tay trên',
}, {
  'id': 'S48',
  'english': 'Traumatic amputation of shoulder and upper arm',
  'vietnamese': 'Chấn thương cắt cụt vai và cánh tay trên',
}, {
  'id': 'S48.0',
  'english': 'Traumatic amputation at shoulder joint',
  'vietnamese': 'Chấn thương cắt cụt khớp vai',
}, {
  'id': 'S48.1',
  'english': 'Traumatic amputation at level between shoulder and elbow',
  'vietnamese': 'Chấn thương cát cụt tại giữa vai và khủyu',
}, {
  'id': 'S48.9',
  'english': 'Traumatic amputation of shoulder and upper arm, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt ở vai và cánh tay trên, tầm chưa xác định',
}, {
  'id': 'S49',
  'english': 'Other and unspecified injuries of shoulder and upper arm',
  'vietnamese': 'Tổn thương khác và chưa xác định ở vai và cánh tay trên',
}, {
  'id': 'S49.7',
  'english': 'Multiple injuries of shoulder and upper arm',
  'vietnamese': 'Đa tổn thương ở vai và cánh tay trên',
}, {
  'id': 'S49.8',
  'english': 'Other specified injuries of shoulder and upper arm',
  'vietnamese': 'Tổn thương xác định khác ở vai và cánh tay trên',
}, {
  'id': 'S49.9',
  'english': 'Unspecified injury of shoulder and upper arm',
  'vietnamese': 'Tổn thương chưa xác định ở vai và cánh tay trên',
}, {
  'id': 'S50',
  'english': 'Superficial injury of forearm',
  'vietnamese': 'Tổn thương nông ở cẳng tay',
}, {'id': 'S50.0', 'english': 'Contusion of elbow', 'vietnamese': 'đụng giập ở khủyu tay'}, {
  'id': 'S50.1',
  'english': 'Contusion of other and unspecified parts of forearm',
  'vietnamese': 'đụng giập khác và phần chưa xác định khác của cẳng tay',
}, {
  'id': 'S50.7',
  'english': 'Multiple superficial injuries of forearm',
  'vietnamese': 'Đa tổn thương nông khác cẳng tay',
}, {
  'id': 'S50.8',
  'english': 'Other superficial injuries of forearm',
  'vietnamese': 'Tổn thương khác cẳng tay',
}, {
  'id': 'S50.9',
  'english': 'Superficial injury of forearm, unspecified',
  'vietnamese': 'Tổn thương nông cẳng tay, chưa xác định',
}, {'id': 'S51', 'english': 'Open wound of forearm', 'vietnamese': 'Vết thương hở ở cẳng tay'}, {
  'id': 'S51.0',
  'english': 'Open wound of elbow',
  'vietnamese': 'Vết thương hở ở khủyu tay',
}, {
  'id': 'S51.7',
  'english': 'Multiple open wounds of forearm',
  'vietnamese': 'Đa vết thương hở ở cẳng tay',
}, {
  'id': 'S51.8',
  'english': 'Open wound of other parts of forearm',
  'vietnamese': 'Vết thương hở ở phần khác của cẳng tay',
}, {
  'id': 'S51.9',
  'english': 'Open wound of forearm, part unspecified',
  'vietnamese': 'Vết thương hở cẳng tay, phần chưa xác định',
}, {'id': 'S52', 'english': 'Fracture of forearm', 'vietnamese': 'Gẫy xương ở cẳng tay'}, {
  'id': 'S52.0',
  'english': 'Fracture of upper end of ulna',
  'vietnamese': 'Gẫy xương đầu trên của xương trụ',
}, {
  'id': 'S52.1',
  'english': 'Fracture of upper end of radius',
  'vietnamese': 'Gẫy xương đầu trên của xương quay',
}, {'id': 'S52.2', 'english': 'Fracture of shaft of ulna', 'vietnamese': 'Gẫy thân xương trụ'}, {
  'id': 'S52.3',
  'english': 'Fracture of shaft of radius',
  'vietnamese': 'Gẫy thân xương quay',
}, {
  'id': 'S52.4',
  'english': 'Fracture of shafts of both ulna and radius',
  'vietnamese': 'Gẫy thân xương cả trụ và quay',
}, {
  'id': 'S52.5',
  'english': 'Fracture of lower end of radius',
  'vietnamese': 'Gẫy xương ở đầu thấp và xương quay',
}, {
  'id': 'S52.6',
  'english': 'Fracture of lower end of both ulna and radius',
  'vietnamese': 'Gẫy xương đầu dưới cả xương trụ và xương quay',
}, {
  'id': 'S52.7',
  'english': 'Multiple fractures of forearm',
  'vietnamese': 'Đa gẫy xương cẳng tay',
}, {
  'id': 'S52.8',
  'english': 'Fracture of other parts of forearm',
  'vietnamese': 'Gẫy xương phần khác của cẳng tay',
}, {
  'id': 'S52.9',
  'english': 'Fracture of forearm, part unspecified',
  'vietnamese': 'Gẫy xương cẳng tay, phần chưa xác định',
}, {
  'id': 'S53',
  'english': 'Dislocation, sprain and strain of joints and ligaments of elbow',
  'vietnamese': 'Sai khớp, bong gân và căng cơ của khớp và dây chằng khủyu tay',
}, {
  'id': 'S53.0',
  'english': 'Dislocation of radial head',
  'vietnamese': 'Sai khớp đầu xương quay',
}, {
  'id': 'S53.1',
  'english': 'Dislocation of elbow, unspecified',
  'vietnamese': 'Sai khớp khủyu, chưa xác định',
}, {
  'id': 'S53.2',
  'english': 'Traumatic rupture of radial collateral ligament',
  'vietnamese': 'Chấn thương đứt dây chằng hai bên xương quay',
}, {
  'id': 'S53.3',
  'english': 'Traumatic rupture of ulnar collateral ligament',
  'vietnamese': 'Chấn thương đứt dây chằng hai bên xương trụ',
}, {
  'id': 'S53.4',
  'english': 'Sprain and strain of elbow',
  'vietnamese': 'Bong gân và căng cơ khủyu tay',
}, {
  'id': 'S54',
  'english': 'Injury of nerves at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh cẳng tay',
}, {
  'id': 'S54.0',
  'english': 'Injury of ulnar nerve at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh trụ ở tầm cẳng tay',
}, {
  'id': 'S54.1',
  'english': 'Injury of median nerve at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh xương quay ở tầm cẳng tay',
}, {
  'id': 'S54.2',
  'english': 'Injury of radial nerve at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh xương quay ở tầm cẳng tay',
}, {
  'id': 'S54.3',
  'english': 'Injury of cutaneous sensory nerve at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh cảm giác da ở tầm cẳng tay',
}, {
  'id': 'S54.7',
  'english': 'Injury of multiple nerves at forearm level',
  'vietnamese': 'Tổn thương đa dây thần kinh ở tầm cẳng tay',
}, {
  'id': 'S54.8',
  'english': 'Injury of other nerves at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh khác ở tầm cẳng tay',
}, {
  'id': 'S54.9',
  'english': 'Injury of unspecified nerve at forearm level',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định ở tầm cẳng tay',
}, {
  'id': 'S55',
  'english': 'Injury of blood vessels at forearm level',
  'vietnamese': 'Tổn thương mạch máu ở tầm cẳng tay',
}, {
  'id': 'S55.0',
  'english': 'Injury of ulnar artery at forearm level',
  'vietnamese': 'Tổn thương động mạch xương trụ ở tầm cẳng tay',
}, {
  'id': 'S55.1',
  'english': 'Injury of radial artery at forearm level',
  'vietnamese': 'Tổn thương động mạch xương quay ở tầm cẳng tay',
}, {
  'id': 'S55.2',
  'english': 'Injury of vein at forearm level',
  'vietnamese': 'Tổn thương đa mạch máu ở tầm cẳng tay',
}, {
  'id': 'S55.7',
  'english': 'Injury of multiple blood vessels at forearm level',
  'vietnamese': 'Tổn thương đa mạch máu ở tầm cẳng tay',
}, {
  'id': 'S55.8',
  'english': 'Injury of other blood vessels at forearm level',
  'vietnamese': 'Tổn thương mạch máu khác ở tầm cẳng tay',
}, {
  'id': 'S55.9',
  'english': 'Injury of unspecified blood vessel at forearm level',
  'vietnamese': 'Tổn thương mạch máu chưa rõ ở tầm cẳng tay',
}, {
  'id': 'S56',
  'english': 'Injury of muscle and tendon at forearm level',
  'vietnamese': 'Tổn thương cơ bắp và gân ở tầm cẳng tay',
}, {
  'id': 'S56.0',
  'english': 'Injury of flexor muscle and tendon of thumb at forearm level',
  'vietnamese': 'Tổn thương cơ gấp và gân ngón tay cái ở tầm cẳng tay',
}, {
  'id': 'S56.1',
  'english': 'Injury of long flexor muscle and tendon of other finger(s) at forearm level',
  'vietnamese': 'Tổn thương cơ gấp và ngón tay khác ở tầm cẳng tay',
}, {
  'id': 'S56.2',
  'english': 'Injury of other flexor muscle and tendon at forearm level',
  'vietnamese': 'Tổn thương cơ gấp khác và gân ở tầm cẳng tay',
}, {
  'id': 'S56.3',
  'english': 'Injury of extensor or abductor muscles and tendons of thumb at forearm level',
  'vietnamese': 'Tổn thương cơ duỗi và cơ giạng và gân ngón tay cái ở tầm cẳng tay',
}, {
  'id': 'S56.4',
  'english': 'Injury of extensor muscle and tendon of other finger(s) at forearm level',
  'vietnamese': 'Tổn thương cơ duỗi và gân ngón khác ở tầm cẳng tay',
}, {
  'id': 'S56.5',
  'english': 'Injury of other extensor muscle and tendon at forearm level',
  'vietnamese': 'Tổn thương cơ duỗi khác và gân ở tầm cẳng tay',
}, {
  'id': 'S56.7',
  'english': 'Injury of multiple muscles and tendons at forearm level',
  'vietnamese': 'Tổn thương đa cơ và gân ở tầm cẳng tay',
}, {
  'id': 'S56.8',
  'english': 'Injury of other and unspecified muscles and tendons at forearm level',
  'vietnamese': 'Tổn thương cơ khác, chưa xác định và gân tầm cẳng tay',
}, {
  'id': 'S57',
  'english': 'Crushing injury of forearm',
  'vietnamese': 'Tổn thương dập nát ở cẳng tay',
}, {
  'id': 'S57.0',
  'english': 'Crushing injury of elbow',
  'vietnamese': 'Tổn thương dập nát ở khủyu tay',
}, {
  'id': 'S57.8',
  'english': 'Crushing injury of other parts of forearm',
  'vietnamese': 'Tổn thương dập nát ở phần khác cẳng tay',
}, {
  'id': 'S57.9',
  'english': 'Crushing injury of forearm, part unspecified',
  'vietnamese': 'Tổn thương dập nát ở cẳng tay phần chưa xác định',
}, {
  'id': 'S58',
  'english': 'Traumatic amputation of forearm',
  'vietnamese': 'Chấn thương cắt cụt ở cẳng tay',
}, {
  'id': 'S58.0',
  'english': 'Traumatic amputation at elbow level',
  'vietnamese': 'Chấn thương cắt cụt ở tầm khủyu tay',
}, {
  'id': 'S58.1',
  'english': 'Traumatic amputation at level between elbow and wrist',
  'vietnamese': 'Chấn thương cắt cụt ở tầm giữa khủyu và cổ tay',
}, {
  'id': 'S58.9',
  'english': 'Traumatic amputation of forearm, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt ở cẳng tay, tầm chưa xác định',
}, {
  'id': 'S59',
  'english': 'Other and unspecified injuries of forearm',
  'vietnamese': 'Tổn thương khác và chưa xác định của cẳng tay',
}, {
  'id': 'S59.7',
  'english': 'Multiple injuries of forearm',
  'vietnamese': 'Đa tổn thương cẳng tay',
}, {
  'id': 'S59.8',
  'english': 'Other specified injuries of forearm',
  'vietnamese': 'Tổn thương khác được xác định ở cẳng tay',
}, {
  'id': 'S59.9',
  'english': 'Unspecified injury of forearm',
  'vietnamese': 'Tổn thương chưa xác định ở cẳng tay',
}, {
  'id': 'S60',
  'english': 'Superficial injury of wrist and hand',
  'vietnamese': 'Tổn thương nông ở cổ tay và bàn tay',
}, {
  'id': 'S60.0',
  'english': 'Contusion of finger(s) without damage to nail',
  'vietnamese': 'đụng giập các ngón tay không tổn thương móng',
}, {
  'id': 'S60.1',
  'english': 'Contusion of finger(s) with damage to nail',
  'vietnamese': 'đụng giập các ngón tay với tổn thương móng',
}, {
  'id': 'S60.2',
  'english': 'Contusion of other parts of wrist and hand',
  'vietnamese': 'đụng giập các phần khác cổ tay và bàn tay',
}, {
  'id': 'S60.7',
  'english': 'Multiple superficial injuries of wrist and hand',
  'vietnamese': 'Đa tổn thương nông ở cổ tay và bàn tay',
}, {
  'id': 'S60.8',
  'english': 'Other superficial injuries of wrist and hand',
  'vietnamese': 'Tổn thương nông khác ở cổ tay và bàn tay',
}, {
  'id': 'S60.9',
  'english': 'Superficial injury of wrist and hand, unspecified',
  'vietnamese': 'Tổn thương nông ở cổ tay và bàn tay không đặc hiệu',
}, {
  'id': 'S61',
  'english': 'Open wound of wrist and hand',
  'vietnamese': 'Vết thương hở ở cổ tay và bàn tay',
}, {
  'id': 'S61.0',
  'english': 'Open wound of finger(s) without damage to nail',
  'vietnamese': 'Vết thương hở các ngón tay không tổn thương móng',
}, {
  'id': 'S61.1',
  'english': 'Open wound of finger(s) with damage to nail',
  'vietnamese': 'Vết thương hở các ngón tay với tổn thương móng',
}, {
  'id': 'S61.7',
  'english': 'Multiple open wounds of wrist and hand',
  'vietnamese': 'Đa vết thương hở ở cổ tay và bàn tay',
}, {
  'id': 'S61.8',
  'english': 'Open wound of other parts of wrist and hand',
  'vietnamese': 'Vết thương hở các phần khác của cổ tay và bàn tay',
}, {
  'id': 'S61.9',
  'english': 'Open wound of wrist and hand part, part unspecified',
  'vietnamese': 'Vết thương hở của cổ tay và bàn tay, phần không xác định',
}, {
  'id': 'S62',
  'english': 'Fracture at wrist and hand level',
  'vietnamese': 'Gẫy xương tầm cổ tay và bàn tay',
}, {
  'id': 'S62.0',
  'english': 'Fracture of navicular [scaphoid] bone of hand',
  'vietnamese': 'Gẫy xương của xương thuyền bàn tay',
}, {
  'id': 'S62.1',
  'english': 'Fracture of other carpal bone(s)',
  'vietnamese': 'Gẫy xương khớp cổ tay khác',
}, {
  'id': 'S62.2',
  'english': 'Fracture of first metacarpal bone',
  'vietnamese': 'Gẫy xương đốt đầu bàn tay',
}, {
  'id': 'S62.3',
  'english': 'Fracture of other metacarpal bone',
  'vietnamese': 'Gẫy xương đốt khác bàn tay',
}, {
  'id': 'S62.4',
  'english': 'Multiple fractures of metacarpal bones',
  'vietnamese': 'Gẫy nhiều xương đốt bàn tay',
}, {'id': 'S62.5', 'english': 'Fracture of thumb', 'vietnamese': 'Gẫy xương ngón tay cái'}, {
  'id': 'S62.6',
  'english': 'Fracture of other finger',
  'vietnamese': 'Gẫy xương các ngón tay khác',
}, {
  'id': 'S62.7',
  'english': 'Multiple fractures of fingers',
  'vietnamese': 'Gẫy nhiều xương ngón tay',
}, {
  'id': 'S62.8',
  'english': 'Fracture of other and unspecified parts of wrist and hand',
  'vietnamese': 'Gẫy xương khác và phần chưa xác định cổ tay và bàn tay',
}, {
  'id': 'S63',
  'english': 'Dislocation, sprain and strain of joints and ligaments at wrist and hand level',
  'vietnamese': 'Sai khớp, bong gân và căng cơ của khớp và dây chằng tầm cổ tay và bàn tay',
}, {'id': 'S63.0', 'english': 'Dislocation of wrist', 'vietnamese': 'Sai khớp cổ tay'}, {
  'id': 'S63.1',
  'english': 'Dislocation of finger',
  'vietnamese': 'Sai khớp ngón tay',
}, {
  'id': 'S63.2',
  'english': 'Multiple dislocations of fingers',
  'vietnamese': 'Sai khớp nhiều ngón tay',
}, {
  'id': 'S63.3',
  'english': 'Traumatic rupture of ligament of wrist and carpus',
  'vietnamese': 'Chấn thương đứt dây chằng cổ tay và khớp xương cổ tay',
}, {
  'id': 'S63.4',
  'english': 'Traumatic rupture of ligament of finger at metacarpophalangeal and interphalangeal joint(s)',
  'vietnamese': 'Chấn thương đứt dây chằng ngón tay tại xương đốt bàn tay ngón tay và khớp xương cổ tay và gian đốt ngón (khớp)',
}, {
  'id': 'S63.5',
  'english': 'Sprain and strain of wrist',
  'vietnamese': 'Bong gân và căng cơ cổ tay',
}, {
  'id': 'S63.6',
  'english': 'Sprain and strain of finger(s)',
  'vietnamese': 'Bong gân và căng cơ các ngón tay',
}, {
  'id': 'S63.7',
  'english': 'Sprain and strain of other and unspecified parts of hand',
  'vietnamese': 'Bong gân và căng cơ phần khác, chưa xác định của bàn tay',
}, {
  'id': 'S64',
  'english': 'Injury of nerves at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh tầm cổ tay và bàn tay',
}, {
  'id': 'S64.0',
  'english': 'Injury of ulnar nerve at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh xương trụ tầm cổ tay và bàn tay',
}, {
  'id': 'S64.1',
  'english': 'Injury of median nerve at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh giữa tầm cổ tay và bàn tay',
}, {
  'id': 'S64.2',
  'english': 'Injury of radial nerve at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh xương quay tầm cổ tay và bàn tay',
}, {
  'id': 'S64.3',
  'english': 'Injury of digital nerve of thumb',
  'vietnamese': 'Tổn thương dây thần kinh ngón tay của ngón tay cái',
}, {
  'id': 'S64.4',
  'english': 'Injury of digital nerve of other finger',
  'vietnamese': 'Tổn thương dây thần kinh ngón tay của ngón tay khác',
}, {
  'id': 'S64.7',
  'english': 'Injury of multiple nerves at wrist and hand level',
  'vietnamese': 'Tổn thương đa dây thần kinh tại tầm cổ tay và bàn tays',
}, {
  'id': 'S64.8',
  'english': 'Injury of other nerves at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh khác tại tầm cổ tay và bàn tay',
}, {
  'id': 'S64.9',
  'english': 'Injury of unspecified nerve at wrist and hand level',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tầm cổ tay và bàn tay',
}, {
  'id': 'S65',
  'english': 'Injury of blood vessels at wrist and hand level',
  'vietnamese': 'Tổn thương mạch máu tại tầm cổ tay và bàn tay',
}, {
  'id': 'S65.0',
  'english': 'Injury of ulnar artery at wrist and hand level',
  'vietnamese': 'Tổn thương động mạch xương trụ tại tầm cổ tay và bàn tay',
}, {
  'id': 'S65.1',
  'english': 'Injury of radial artery at wrist and hand level',
  'vietnamese': 'Tổn thương động mạch quay tầm cổ tay và bàn tay',
}, {
  'id': 'S65.2',
  'english': 'Injury of superficial palmar arch',
  'vietnamese': 'Tổn thương nông cung gan bàn tay',
}, {
  'id': 'S65.3',
  'english': 'Injury of deep palmar arch',
  'vietnamese': 'Tổn thương sâu cung gan bàn tay',
}, {
  'id': 'S65.4',
  'english': 'Injury of blood vessel(s) of thumb',
  'vietnamese': 'Tổn thương mạch máu ngòn tay cái',
}, {
  'id': 'S65.5',
  'english': 'Injury of blood vessel(s) of other finger',
  'vietnamese': 'Tổn thương mạch máu ngón tay khác',
}, {
  'id': 'S65.7',
  'english': 'Injury of multiple blood vessels at wrist and hand level',
  'vietnamese': 'Tổn thương đa mạch máu tại tầm cổ tay và bàn tay',
}, {
  'id': 'S65.8',
  'english': 'Injury of other blood vessels at wrist and hand level',
  'vietnamese': 'Tổn thương mạch máu khác tại tầm cổ tay và bàn tay',
}, {
  'id': 'S65.9',
  'english': 'Injury of unspecified blood vessel at wrist and hand level',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66',
  'english': 'Injury of muscle and tendon at wrist and hand level',
  'vietnamese': 'Tổn thương cơ bắp và gân tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.0',
  'english': 'Injury of long flexor muscle and tendon of thumb at wrist and hand level',
  'vietnamese': 'Tổn thương cơ bắp gấp dài và gân ngón tay cái tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.1',
  'english': 'Injury of flexor muscle and tendon of other finger at wrist and hand level',
  'vietnamese': 'Tổn thương cơ gấp và gân ngón tay khác tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.2',
  'english': 'Injury of extensor muscle and tendon of thumb at wrist and hand level',
  'vietnamese': 'Tổn thương cơ duỗi và gân ngón tay cái tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.3',
  'english': 'Injury of extensor muscle and tendon of other finger at wrist and hand level',
  'vietnamese': 'Tổn thương cơ duỗi và gân ngón tay khác tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.4',
  'english': 'Injury of intrinsic muscle and tendon of thumb at wrist and hand level',
  'vietnamese': 'Tổn thương cơ nội tại và gân ngón tay cái tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.5',
  'english': 'Injury of intrinsic muscle and tendon of other finger at wrist and hand level',
  'vietnamese': 'Tổn thương cơ nội tại và gân ngón tay khác tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.6',
  'english': 'Injury of multiple flexor muscles and tendons at wrist and hand level',
  'vietnamese': 'Tổn thương đa cơ gấp và gân tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.7',
  'english': 'Injury of multiple extensor muscles and tendons at wrist and hand level',
  'vietnamese': 'Tổn thương đa cơ duỗi và gân tại tầm cổ tay và bàn tay',
}, {
  'id': 'S66.8',
  'english': 'Injury of other muscles and tendons at wrist and hand level',
  'vietnamese': 'Tổn thương cơ khác và gân tầm cổ tay và bàn tay',
}, {
  'id': 'S66.9',
  'english': 'Injury of unspecified muscle and tendon at wrist and hand level',
  'vietnamese': 'Tổn thương cơ chưa xác định và gân tại tầm cổ tay và bàn tay',
}, {
  'id': 'S67',
  'english': 'Crushing injury of wrist and hand',
  'vietnamese': 'Tổn thương dập nát cổ tay và bàn tay',
}, {
  'id': 'S67.0',
  'english': 'Crushing injury of thumb and other finger(s)',
  'vietnamese': 'Tổn thương dập nát ngón tay cái và các ngón tay khác',
}, {
  'id': 'S67.8',
  'english': 'Crushing injury of other and unspecified parts of wrist and hand',
  'vietnamese': 'Tổn thương dập nát phần khác và chưa xác định của cổ tay và bàn tay',
}, {
  'id': 'S68',
  'english': 'Traumatic amputation of wrist and hand',
  'vietnamese': 'Chấn thương cắt cụt cổ tay và bàn tay',
}, {
  'id': 'S68.0',
  'english': 'Traumatic amputation of thumb (complete)(partial)',
  'vietnamese': 'Chấn thương cắt cụt ngón tay cái (toàn phần) (một phần)',
}, {
  'id': 'S68.1',
  'english': 'Traumatic amputation of other single finger (complete)(partial)',
  'vietnamese': 'Chấn thương cắt cụt ngón tay khác (toàn phần) (một phần)',
}, {
  'id': 'S68.2',
  'english': 'Traumatic amputation of two or more fingers alone (complete)(partial)',
  'vietnamese': 'Chấn thương cắt cụt hai hoặc nhiều ngón tay đơn thuần (toàn phần) (một phần)',
}, {
  'id': 'S68.3',
  'english': 'Combined traumatic amputation of (part of) finger(s) with other parts of wrist and hand',
  'vietnamese': 'Chấn thương cắt cụt kết hợp một phần ngón tay với phần khác của cổ tay và bàn tay',
}, {
  'id': 'S68.4',
  'english': 'Traumatic amputation of hand at wrist level',
  'vietnamese': 'Chấn thương cắt cụt tại cổ tay và bàn tay',
}, {
  'id': 'S68.8',
  'english': 'Traumatic amputation of other parts of wrist and hand',
  'vietnamese': 'Chấn thương cắt cụt phần khác của cổ tay và bàn tay',
}, {
  'id': 'S68.9',
  'english': 'Traumatic amputation of wrist and hand, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt cổ tay và bàn tay tầm chưa xác định',
}, {
  'id': 'S69',
  'english': 'Other and unspecified injuries of wrist and hand',
  'vietnamese': 'Tổn thương khác và chưa xác định của cổ tay và bàn tay',
}, {
  'id': 'S69.7',
  'english': 'Multiple injuries of wrist and hand',
  'vietnamese': 'Đa tổn thương cổ tay và bàn tay',
}, {
  'id': 'S69.8',
  'english': 'Other specified injuries of wrist and hand',
  'vietnamese': 'Tổn thương xác định khác của cổ tay và bàn tay',
}, {
  'id': 'S69.9',
  'english': 'Unspecified injury of wrist and hand',
  'vietnamese': 'Tổn thương chưa xác định của cổ tay và bàn tay',
}, {
  'id': 'S70',
  'english': 'Superficial injury of hip and thigh',
  'vietnamese': 'Tổn thương nông tại háng và đùi',
}, {'id': 'S70.0', 'english': 'Contusion of hip', 'vietnamese': 'đụng giập tại háng'}, {
  'id': 'S70.1',
  'english': 'Contusion of thigh',
  'vietnamese': 'đụng giập tại đùi',
}, {
  'id': 'S70.7',
  'english': 'Multiple superficial injuries of hip and thigh',
  'vietnamese': 'Đa tổn thương nông tại háng và đùi',
}, {
  'id': 'S70.8',
  'english': 'Other superficial injuries of hip and thigh',
  'vietnamese': 'Tổn thương nông khác tại háng và đùi',
}, {
  'id': 'S70.9',
  'english': 'Superficial injury of hip and thigh, unspecified',
  'vietnamese': 'Tổn thương nông tại háng và đùi, chưa xác định',
}, {
  'id': 'S71',
  'english': 'Open wound of hip and thigh',
  'vietnamese': 'Vết thương hở tại háng và đùi',
}, {'id': 'S71.0', 'english': 'Open wound of hip', 'vietnamese': 'Vết thương hở tại háng'}, {
  'id': 'S71.1',
  'english': 'Open wound of thigh',
  'vietnamese': 'Vết thương hở tại đùi',
}, {
  'id': 'S71.7',
  'english': 'Multiple open wounds of hip and thigh',
  'vietnamese': 'Đa vết thương hở tại háng và đùi',
}, {
  'id': 'S71.8',
  'english': 'Open wound of other and unspecified parts of pelvic girdle',
  'vietnamese': 'Vết thương hở phần khác, chưa xác định của khung chậu',
}, {'id': 'S72', 'english': 'Fracture of femur', 'vietnamese': 'Gẫy xương đùi'}, {
  'id': 'S72.0',
  'english': 'Fracture of neck of femur',
  'vietnamese': 'Gẫy cổ xương đùi',
}, {'id': 'S72.1', 'english': 'Pertrochanteric fracture', 'vietnamese': 'Gẫy mấu chuyển'}, {
  'id': 'S72.2',
  'english': 'Subtrochanteric fracture',
  'vietnamese': 'Gẫy mấu chuyển phụ',
}, {'id': 'S72.3', 'english': 'Fracture of shaft of femur', 'vietnamese': 'Gẫy thân xương đùi'}, {
  'id': 'S72.4',
  'english': 'Fracture of lower end of femur',
  'vietnamese': 'Gẫy xương đầu dưới xương đùi',
}, {
  'id': 'S72.7',
  'english': 'Multiple fractures of femur',
  'vietnamese': 'Gẫy đa xương xương đùi',
}, {
  'id': 'S72.8',
  'english': 'Fractures of other parts of femur',
  'vietnamese': 'Gẫy xương phần khác xương đùi',
}, {
  'id': 'S72.9',
  'english': 'Fracture of femur, part unspecified',
  'vietnamese': 'Gẫy xương đùi phần chưa xác định',
}, {
  'id': 'S73',
  'english': 'Dislocation, sprain and strain of joint and ligaments of hip',
  'vietnamese': 'Sai khớp, bong gân và căng cơ khớp và dây chằng háng',
}, {'id': 'S73.0', 'english': 'Dislocation of hip', 'vietnamese': 'Sai khớp háng'}, {
  'id': 'S73.1',
  'english': 'Sprain and strain of hip',
  'vietnamese': 'Bong gân và căng cơ của khớp háng',
}, {
  'id': 'S74',
  'english': 'Injury of nerves at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh tầm háng và đùi',
}, {
  'id': 'S74.0',
  'english': 'Injury of sciatic nerve at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh hông tại tầm háng và đùi',
}, {
  'id': 'S74.1',
  'english': 'Injury of femoral nerve at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh đùi tại tầm háng và đùi',
}, {
  'id': 'S74.2',
  'english': 'Injury of cutaneous sensory nerve at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh cảm giác da tại tầm háng và đùi',
}, {
  'id': 'S74.7',
  'english': 'Injury of multiple nerves at hip and thigh level',
  'vietnamese': 'Tổn thương đa dây thần kinh tại tầm háng và đùi',
}, {
  'id': 'S74.8',
  'english': 'Injury of other nerves at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh khác tại tầm háng và đùi',
}, {
  'id': 'S74.9',
  'english': 'Injury of unspecified nerve at hip and thigh level',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tầm háng và đùi',
}, {
  'id': 'S75',
  'english': 'Injury of blood vessels at hip and thigh level',
  'vietnamese': 'Tổn thương mạch máu tại tầm háng và đùi',
}, {
  'id': 'S75.0',
  'english': 'Injury of femoral artery',
  'vietnamese': 'Tổn thương động mạch đùi',
}, {
  'id': 'S75.1',
  'english': 'Injury of femoral vein at hip and thigh level',
  'vietnamese': 'Tổn thương tĩnh mạch đùi tại tầm háng và đùi',
}, {
  'id': 'S75.2',
  'english': 'Injury of greater saphenous vein at hip and thigh level',
  'vietnamese': 'Tổn thương tĩnh mạch hiển lớn tại tầm háng và đùi',
}, {
  'id': 'S75.7',
  'english': 'Injury of multiple blood vessels at hip and thigh level',
  'vietnamese': 'Tổn thương đa mạch máu tại tầm háng và đùi',
}, {
  'id': 'S75.8',
  'english': 'Injury of other blood vessels at hip and thigh level',
  'vietnamese': 'Tổn thương mạch máu khác tại tầm háng và đùi',
}, {
  'id': 'S75.9',
  'english': 'Injury of unspecified blood vessel at hip and thigh level',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tại tầm háng và đùi',
}, {
  'id': 'S76',
  'english': 'Injury of muscle and tendon at hip and thigh level',
  'vietnamese': 'Tổn thương cơ bắp và gân tại tầm háng và đùi',
}, {
  'id': 'S76.0',
  'english': 'Injury of muscle and tendon of hip',
  'vietnamese': 'Tổn thương cơ và gân háng',
}, {
  'id': 'S76.1',
  'english': 'Injury of quadriceps muscle and tendon',
  'vietnamese': 'Tổn thương cơ tứ đầu và gân',
}, {
  'id': 'S76.2',
  'english': 'Injury of adductor muscle and tendon of thigh',
  'vietnamese': 'Tổn thương cơ khép và gân đùi',
}, {
  'id': 'S76.3',
  'english': 'Injury of muscle and tendon of the posterior muscle group at thigh level',
  'vietnamese': 'Tổn thương cơ và gân của nhóm cơ sau tầm đùi',
}, {
  'id': 'S76.4',
  'english': 'Injury of other and unspecified muscles and tendons at thigh level',
  'vietnamese': 'Tổn thương cơ khác và chưa xác định và gân tầm đùi',
}, {
  'id': 'S76.7',
  'english': 'Injury of multiple muscles and tendons at hip and thigh level',
  'vietnamese': 'Tổn thương đa cơ và gân tầm háng và đùi',
}, {
  'id': 'S77',
  'english': 'Crushing injury of hip and thigh',
  'vietnamese': 'Crushing injury of hip and thigh',
}, {'id': 'S77.0', 'english': 'Crushing injury of hip', 'vietnamese': 'Crushing injury of hip'}, {
  'id': 'S77.1',
  'english': 'Crushing injury of thigh',
  'vietnamese': 'Crushing injury of thigh',
}, {
  'id': 'S77.2',
  'english': 'Crushing injury of hip with thigh',
  'vietnamese': 'Crushing injury of hip with thigh',
}, {
  'id': 'S78',
  'english': 'Traumatic amputation of hip and thigh',
  'vietnamese': 'Chấn thương cắt cụt ở háng và đùi',
}, {
  'id': 'S78.0',
  'english': 'Traumatic amputation at hip joint',
  'vietnamese': 'Chấn thương cắt cụt ở háng và đùi',
}, {
  'id': 'S78.1',
  'english': 'Traumatic amputation at level between hip and knee',
  'vietnamese': 'Chấn thương cắt cụt tầm giữa háng và khớp gối',
}, {
  'id': 'S78.9',
  'english': 'Traumatic amputation of hip and thigh, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt háng và đùi tầm chưa xác định',
}, {
  'id': 'S79',
  'english': 'Other and specified injuries of hip and thigh',
  'vietnamese': 'Tổn thương khác chưa xác định của háng và đùi',
}, {
  'id': 'S79.7',
  'english': 'Multiple injuries of hip and thigh',
  'vietnamese': 'Đa tổn thương ở háng và đùi',
}, {
  'id': 'S79.8',
  'english': 'Other specified injuries of hip and thigh',
  'vietnamese': 'Tổn thương khác xác định của háng và đùi',
}, {
  'id': 'S79.9',
  'english': 'Unspecified injury of hip and thigh',
  'vietnamese': 'Tổn thương chưa xác định của háng và đùi',
}, {
  'id': 'S80',
  'english': 'Superficial injury of lower leg',
  'vietnamese': 'Tổn thương nông tại cẳng chân',
}, {'id': 'S80.0', 'english': 'Contusion of knee', 'vietnamese': 'đụng giập tại đầu gối'}, {
  'id': 'S80.1',
  'english': 'Contusion of other and unspecified parts of lower leg',
  'vietnamese': 'đụng giập tại phần khác và chưa xác định của cẳng chân',
}, {
  'id': 'S80.7',
  'english': 'Multiple superficial injuries of lower leg',
  'vietnamese': 'Đa tổn thương nông tại cẳng chân',
}, {
  'id': 'S80.8',
  'english': 'Other superficial injuries of lower leg',
  'vietnamese': 'Tổn thương nông khác tại cẳng chân',
}, {
  'id': 'S80.9',
  'english': 'Superficial injury of lower leg, unspecified',
  'vietnamese': 'Tổn thương nông tại cẳng chân chưa xác định',
}, {
  'id': 'S81',
  'english': 'Open wound of lower leg',
  'vietnamese': 'Vết thương hở tại cẳng chân',
}, {
  'id': 'S81.0',
  'english': 'Open wound of knee',
  'vietnamese': 'Vết thương hở đầu gối',
}, {
  'id': 'S81.7',
  'english': 'Multiple open wounds of lower leg',
  'vietnamese': 'Đa vết thương hở chi dưới',
}, {
  'id': 'S81.8',
  'english': 'Open wound of other parts of lower leg',
  'vietnamese': 'Vết thương hở phần khác của chi dưới',
}, {
  'id': 'S81.9',
  'english': 'Open wound of lower leg, part unspecified',
  'vietnamese': 'Vết thương hở chi dưới, phần không xác định',
}, {
  'id': 'S82',
  'english': 'Fracture of lower leg, including ankle',
  'vietnamese': 'Gẫy xương cẳng chân, bao gồm cổ chân',
}, {'id': 'S82.0', 'english': 'Fracture of patella', 'vietnamese': 'Gẫy xương bánh chè'}, {
  'id': 'S82.1',
  'english': 'Fracture of upper end of tibia',
  'vietnamese': 'Gẫy đầu trên của xương chày',
}, {
  'id': 'S82.2',
  'english': 'Fracture of shaft of tibia',
  'vietnamese': 'Gẫy thân xương chày',
}, {
  'id': 'S82.3',
  'english': 'Fracture of lower end of tibia',
  'vietnamese': 'Gẫy xương đầu dưới xương chày',
}, {
  'id': 'S82.4',
  'english': 'Fracture of fibula alone',
  'vietnamese': 'Gẫy xương đơn thuần xương mác',
}, {
  'id': 'S82.5',
  'english': 'Fracture of medial malleolus',
  'vietnamese': 'Gẫy xương giữa mắt cá chân',
}, {
  'id': 'S82.6',
  'english': 'Fracture of lateral malleolus',
  'vietnamese': 'Gẫy xương bên mắt cá chân',
}, {
  'id': 'S82.7',
  'english': 'Multiple fractures of lower leg',
  'vietnamese': 'Gẫy xương tại cẳng chân',
}, {
  'id': 'S82.8',
  'english': 'Fractures of other parts of lower leg',
  'vietnamese': 'Gẫy xương khác của cẳng chân',
}, {
  'id': 'S82.9',
  'english': 'Fracture of lower leg, part unspecified',
  'vietnamese': 'Gẫy xương cẳng chân, phần chưa xác định',
}, {
  'id': 'S83',
  'english': 'Dislocation, sprain and strain of joints and ligaments of knee',
  'vietnamese': 'Sai khớp, bong gân và căng cơ khớp và dây chằng đầu gối',
}, {
  'id': 'S83.0',
  'english': 'Dislocation of patella',
  'vietnamese': 'Sai khớp xương bánh chè',
}, {
  'id': 'S83.1',
  'english': 'Dislocation of knee',
  'vietnamese': 'Sai khớp gối',
}, {
  'id': 'S83.2',
  'english': 'Tear of meniscus, current',
  'vietnamese': 'Vết rách sụn chêm, hiện tại',
}, {
  'id': 'S83.3',
  'english': 'Tear of articular cartilage of knee, current',
  'vietnamese': 'Rách khớp sụn đầu gối, hiện tại',
}, {
  'id': 'S83.4',
  'english': 'Sprain and strain involving (fibular)(tibial) collateral ligament of knee',
  'vietnamese': 'Bong gân và căng cơ (xương mác) (xương chày) tổn thương phụ dây chằng khớp gối',
}, {
  'id': 'S83.5',
  'english': 'Sprain and strain involving (anterior)(posterior) cruciate ligament of knee',
  'vietnamese': 'Bong gân và căng cơ (phía trước) (phía sau) tổn thương dây chằng chéo khớp gối',
}, {
  'id': 'S83.6',
  'english': 'Sprain and strain of other and unspecified parts of knee',
  'vietnamese': 'Bong gân và căng cơ phần khác và chưa xác định khớp gối',
}, {
  'id': 'S83.7',
  'english': 'Injury to multiple structures of knee',
  'vietnamese': 'Tổn thương đa cấu trúc khớp gối',
}, {
  'id': 'S84',
  'english': 'Injury of nerves at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh tại tầm cẳng chân',
}, {
  'id': 'S84.0',
  'english': 'Injury of tibial nerve at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh xương chày tại tầm cẳng chân',
}, {
  'id': 'S84.1',
  'english': 'Injury of peroneal nerve at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh xương mác tại tầm cẳng chân',
}, {
  'id': 'S84.2',
  'english': 'Injury of cutaneous sensory nerve at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh xương cảm giác da tại tầm cẳng chân',
}, {
  'id': 'S84.7',
  'english': 'Injury of multiple nerves at lower leg level',
  'vietnamese': 'Tổn thương đa dây thần kinh tại tầm cẳng chân',
}, {
  'id': 'S84.8',
  'english': 'Injury of other nerves at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh khác tại tầm cẳng chân',
}, {
  'id': 'S84.9',
  'english': 'Injury of unspecified nerve at lower leg level',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tầm cẳng chân',
}, {
  'id': 'S85',
  'english': 'Injury of blood vessels at lower leg level',
  'vietnamese': 'Tổn thương mạch máu tại tầm cẳng chân',
}, {
  'id': 'S85.0',
  'english': 'Injury of popliteal artery',
  'vietnamese': 'Tổn thương động mạch kheo',
}, {
  'id': 'S85.1',
  'english': 'Injury of (anterior)(posterior) tibial artery',
  'vietnamese': 'Tổn thương (phía trước) (phía sau) động mạch xương chày',
}, {
  'id': 'S85.2',
  'english': 'Injury of peroneal artery',
  'vietnamese': 'Tổn thương động mạch xương mác',
}, {
  'id': 'S85.3',
  'english': 'Injury of greater saphenous vein at lower leg level',
  'vietnamese': 'Tổn thương tĩnh mạch hiển lớn tại tầm cẳng chân',
}, {
  'id': 'S85.4',
  'english': 'Injury of lesser saphenous vein at lower leg level',
  'vietnamese': 'Tổn thương tĩnh mạch hiển nhỏ tại tầm cẳng chân',
}, {
  'id': 'S85.5',
  'english': 'Injury of popliteal vein',
  'vietnamese': 'Tổn thương tĩnh mạch kheo',
}, {
  'id': 'S85.7',
  'english': 'Injury of multiple blood vessels at lower leg level',
  'vietnamese': 'Tổn thương đa mạch máu tại tầm cẳng chân',
}, {
  'id': 'S85.8',
  'english': 'Injury of other blood vessels at lower leg level',
  'vietnamese': 'Tổn thương mạch máu khác tại tầm cẳng chân',
}, {
  'id': 'S85.9',
  'english': 'Injury of unspecified blood vessel at lower leg level',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tại tầm cẳng chân',
}, {
  'id': 'S86',
  'english': 'Injury of muscle and tendon at lower leg level',
  'vietnamese': 'Tổn thương cơ bắp và gân tại tầm cẳng chân',
}, {
  'id': 'S86.0',
  'english': 'Injury of Achilles tendon',
  'vietnamese': 'Tổn thương gân gót (Achilles)',
}, {
  'id': 'S86.1',
  'english': 'Injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level',
  'vietnamese': 'Tổn thương cơ khác và gân nhóm cơ sau tại tầm cẳng chân',
}, {
  'id': 'S86.2',
  'english': 'Injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level',
  'vietnamese': 'Tổn thương cơ khác và gân nhóm cơ trước tại tầm cẳng chân',
}, {
  'id': 'S86.3',
  'english': 'Injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level',
  'vietnamese': 'Tổn thương cơ bắp và gân nhóm cơ trước tại tầm cẳng chân',
}, {
  'id': 'S86.7',
  'english': 'Injury of multiple muscles and tendons at lower leg level',
  'vietnamese': 'Tổn thương đa cơ và gân tại tầm cẳng chân',
}, {
  'id': 'S86.8',
  'english': 'Injury of other muscles and tendons at lower leg level',
  'vietnamese': 'Tổn thương cơ khác và gân tại tầm cẳng chân',
}, {
  'id': 'S86.9',
  'english': 'Injury of unspecified muscle and tendon at lower leg level',
  'vietnamese': 'Tổn thương cơ chưa xác định và gân tầm cẳng chân',
}, {
  'id': 'S87',
  'english': 'Crushing injury of lower leg',
  'vietnamese': 'Tổn thương dập nát tại cẳng chân',
}, {
  'id': 'S87.0',
  'english': 'Crushing injury of knee',
  'vietnamese': 'Tổn thương dập nát khớp gối',
}, {
  'id': 'S87.8',
  'english': 'Crushing injury of other and unspecified parts of lower leg',
  'vietnamese': 'Tổn thương dập nát phần khác, chưa xác định của cẳng chân',
}, {
  'id': 'S88',
  'english': 'Traumatic amputation of lower leg',
  'vietnamese': 'Chấn thương cắt cụt tầm khớp gối',
}, {
  'id': 'S88.0',
  'english': 'Traumatic amputation at knee level',
  'vietnamese': 'Chấn thương cắt cụt tầm khớp gối',
}, {
  'id': 'S88.1',
  'english': 'Traumatic amputation at level between knee and ankle',
  'vietnamese': 'Chấn thương cắt cụt tầm giữa khớp gối và cổ chân',
}, {
  'id': 'S88.9',
  'english': 'Traumatic amputation of lower leg, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt cẳng chân tầm chưa xác định',
}, {
  'id': 'S89',
  'english': 'Other and unspecified injuries of lower leg',
  'vietnamese': 'Tổn thương khác và chưa xác định tại cẳng chân',
}, {
  'id': 'S89.7',
  'english': 'Multiple injuries of lower leg',
  'vietnamese': 'Đa tổn thương tại cẳng chân',
}, {
  'id': 'S89.8',
  'english': 'Other specified injuries of lower leg',
  'vietnamese': 'Tổn thương xác định khác tại cẳng chân',
}, {
  'id': 'S89.9',
  'english': 'Unspecified injury of lower leg',
  'vietnamese': 'Tổn thương chưa xác định tại cẳng chân',
}, {
  'id': 'S90',
  'english': 'Superficial injury of ankle and foot',
  'vietnamese': 'Tổn thương nông tại cổ chân và bàn chân',
}, {'id': 'S90.0', 'english': 'Contusion of ankle', 'vietnamese': 'đụng giập tại cổ chân'}, {
  'id': 'S90.1',
  'english': 'Contusion of toe(s) without damage to nail',
  'vietnamese': 'đụng giập ngón chân không có tổn thương móng',
}, {
  'id': 'S90.2',
  'english': 'Contusion of toe(s) with damage to nail',
  'vietnamese': 'đụng giập ngón chân có tổn thương móng',
}, {
  'id': 'S90.3',
  'english': 'Contusion of other and unspecified parts of foot',
  'vietnamese': 'đụng giập phần khác và chưa xác định tại bàn chân',
}, {
  'id': 'S90.7',
  'english': 'Multiple superficial injuries of ankle and foot',
  'vietnamese': 'Đa tổn thương nông tại cổ chân và bàn chân',
}, {
  'id': 'S90.8',
  'english': 'Other superficial injuries of ankle and foot',
  'vietnamese': 'Tổn thương nông khác tại cổ chân và bàn chân',
}, {
  'id': 'S90.9',
  'english': 'Superficial injury of ankle and foot, unspecified',
  'vietnamese': 'Tổn thương nông tại cổ chân và bàn chân chưa xác định',
}, {
  'id': 'S91',
  'english': 'Open wound of ankle and foot',
  'vietnamese': 'Vết thương hở tại cổ chân và bàn chân',
}, {'id': 'S91.0', 'english': 'Open wound of ankle', 'vietnamese': 'Vết thương hở tại cổ chân'}, {
  'id': 'S91.1',
  'english': 'Open wound of toe(s) without damage to nail',
  'vietnamese': 'Vết thương hở tại ngón chân không có tổn thương móng',
}, {
  'id': 'S91.2',
  'english': 'Open wound of toe(s) with damage to nail',
  'vietnamese': 'Vết thương hở tại ngón chân với tổn thương móng',
}, {
  'id': 'S91.3',
  'english': 'Open wound of other parts of foot',
  'vietnamese': 'Vết thương hở tại phần khác của bàn chân',
}, {
  'id': 'S91.7',
  'english': 'Multiple open wounds of ankle and foot',
  'vietnamese': 'Đa vết thương hở ở cẳng chân và bàn chân',
}, {
  'id': 'S92',
  'english': 'Fracture of foot, except ankle',
  'vietnamese': 'gẫy xương bàn chân, trừ cổ chân',
}, {'id': 'S92.0', 'english': 'Fracture of calcaneus', 'vietnamese': 'Gẫy xương gót'}, {
  'id': 'S92.1',
  'english': 'Fracture of talus',
  'vietnamese': 'Gẫy xương mắt cá',
}, {
  'id': 'S92.2',
  'english': 'Fracture of other tarsal bone(s)',
  'vietnamese': 'Gẫy xương cổ chân khác',
}, {
  'id': 'S92.3',
  'english': 'Fracture of metatarsal bone',
  'vietnamese': 'Gẫy xương đốt bàn chân',
}, {'id': 'S92.4', 'english': 'Fracture of great toe', 'vietnamese': 'Gẫy xương ngón chân cái'}, {
  'id': 'S92.5',
  'english': 'Fracture of other toe',
  'vietnamese': 'Gẫy xương ngón chân khác',
}, {
  'id': 'S92.7',
  'english': 'Multiple fractures of foot',
  'vietnamese': 'Gẫy nhiều xương bàn chân',
}, {
  'id': 'S92.9',
  'english': 'Fracture of foot, unspecified',
  'vietnamese': 'Gẫy xương bàn chân chưa xác định',
}, {
  'id': 'S93',
  'english': 'Dislocation, sprain and strain of joints and ligaments at ankle and foot level',
  'vietnamese': 'Sai khớp, bong gân, căng cơ khớp và dây chằng tại tầm cẳng chân và bàn chân',
}, {'id': 'S93.0', 'english': 'Dislocation of ankle joint', 'vietnamese': 'Sai khớp cổ chân'}, {
  'id': 'S93.1',
  'english': 'Dislocation of toe(s)',
  'vietnamese': 'Sai khớp ngón chân',
}, {
  'id': 'S93.2',
  'english': 'Rupture of ligaments at ankle and foot level',
  'vietnamese': 'Đứt dây chằng tại tầm cẳng chân và bàn chân',
}, {
  'id': 'S93.3',
  'english': 'Dislocation of other and unspecified parts of foot',
  'vietnamese': 'Sai khớp phần khác và chưa xác định tại bàn chân',
}, {
  'id': 'S93.4',
  'english': 'Sprain and strain of ankle',
  'vietnamese': 'Bong gân và căng cơ cổ chân',
}, {
  'id': 'S93.5',
  'english': 'Sprain and strain of toe(s)',
  'vietnamese': 'Bong gân và căng cơ ngón chân',
}, {
  'id': 'S93.6',
  'english': 'Sprain and strain of other and unspecified parts of foot',
  'vietnamese': 'Bong gân và căng cơ phần khác, chưa xác định của bàn chân',
}, {
  'id': 'S94',
  'english': 'Injury of nerves at ankle and foot level',
  'vietnamese': 'Tổn thương dây thần kinh tại tầm cổ chân và bàn chân',
}, {
  'id': 'S94.0',
  'english': 'Injury of lateral plantar nerve',
  'vietnamese': 'Tổn thương dây thần kinh bên bàn chân',
}, {
  'id': 'S94.1',
  'english': 'Injury of medial plantar nerve',
  'vietnamese': 'Tổn thương dây thần kinh giữa bàn chân',
}, {
  'id': 'S94.2',
  'english': 'Injury of deep peroneal nerve at ankle and foot level',
  'vietnamese': 'Tổn thương dây thần kinh sâu xương mác tại tầm cổ chân và bàn chân',
}, {
  'id': 'S94.3',
  'english': 'Injury of cutaneous sensory nerve at ankle and foot level',
  'vietnamese': 'Tổn thương dây thần kinh cảm giác da tầm cổ chân và bàn chân',
}, {
  'id': 'S94.7',
  'english': 'Injury of multiple nerves at ankle and foot level',
  'vietnamese': 'Tổn thương đa dây thần kinh tại tầm cổ chân và bàn chân',
}, {
  'id': 'S94.8',
  'english': 'Injury of other nerves at ankle and foot level',
  'vietnamese': 'Tổn thương dây thần kinh khác tại tầm cổ chân và bàn chân',
}, {
  'id': 'S94.9',
  'english': 'Injury of unspecified nerve at ankle and foot level',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tại tầm cổ chân và bàn chân',
}, {
  'id': 'S95',
  'english': 'Injury of blood vessels at ankle and foot level',
  'vietnamese': 'Tổn thương mạch máu tại tầm cổ chân và bàn chân',
}, {
  'id': 'S95.0',
  'english': 'Injury of dorsal artery of foot',
  'vietnamese': 'Tổn thương động mạch mu bàn chân',
}, {
  'id': 'S95.1',
  'english': 'Injury of plantar artery of foot',
  'vietnamese': 'Tổn thương động mạch gan bàn chân',
}, {
  'id': 'S95.2',
  'english': 'Injury of dorsal vein of foot',
  'vietnamese': 'Tổn thương tĩnh mạch mu bàn chân',
}, {
  'id': 'S95.7',
  'english': 'Injury of multiple blood vessels at ankle and foot level',
  'vietnamese': 'Tổn thương đa mạch máu tại tầm cổ chân và bàn chân',
}, {
  'id': 'S95.8',
  'english': 'Injury of other blood vessels at ankle and foot level',
  'vietnamese': 'Tổn thương mạch máu khác tại tầm cổ chân và bàn chân',
}, {
  'id': 'S95.9',
  'english': 'Injury of unspecified blood vessel at ankle and foot level',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tầm cổ chân và bàn chân',
}, {
  'id': 'S96',
  'english': 'Injury of muscle and tendon at ankle and foot level',
  'vietnamese': 'Tổn thương cơ bắp và gân tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.0',
  'english': 'Injury of muscle and tendon of long flexor muscle of toe at ankle and foot level',
  'vietnamese': 'Tổn thương cơ và gân cơ gấp dài của ngón tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.1',
  'english': 'Injury of muscle and tendon of long extensor muscle of toe at ankle and foot level',
  'vietnamese': 'Tổn thương cơ và gân cơ duỗi dài của ngón tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.2',
  'english': 'Injury of intrinsic muscle and tendon at ankle and foot level',
  'vietnamese': 'Tổn thương cơ trong và gân tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.7',
  'english': 'Injury of multiple muscles and tendons at ankle and foot level',
  'vietnamese': 'Tổn thương đa cơ và gân tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.8',
  'english': 'Injury of other muscles and tendons at ankle and foot level',
  'vietnamese': 'Tổn thương cơ khác và gân tại tầm cổ chân và bàn chân',
}, {
  'id': 'S96.9',
  'english': 'Injury of unspecified muscle and tendon at ankle and foot level',
  'vietnamese': 'Tổn thương cơ chưa xác định và gân tầm cổ chân và bàn chân',
}, {
  'id': 'S97',
  'english': 'Crushing injury of ankle and foot',
  'vietnamese': 'Tổn thương dập nát tại cổ chân và bàn chân',
}, {
  'id': 'S97.0',
  'english': 'Crushing injury of ankle',
  'vietnamese': 'Tổn thương dập nát tại cổ chân',
}, {
  'id': 'S97.1',
  'english': 'Crushing injury of toe(s)',
  'vietnamese': 'Tổn thương dập nát tại ngón chân',
}, {
  'id': 'S97.8',
  'english': 'Crushing injury of other parts of ankle and foot',
  'vietnamese': 'Tổn thương dập nát tại phần khác của cổ chân và bàn chân',
}, {
  'id': 'S98',
  'english': 'Traumatic amputation of ankle and foot',
  'vietnamese': 'Chấn thương cắt cụt cổ chân và bàn chân',
}, {
  'id': 'S98.0',
  'english': 'Traumatic amputation of foot at ankle level',
  'vietnamese': 'Chấn thương cắt cụt bàn chân tại tầm cổ chân',
}, {
  'id': 'S98.1',
  'english': 'Traumatic amputation of one toe',
  'vietnamese': 'Chấn thương cắt cụt một ngón chân',
}, {
  'id': 'S98.2',
  'english': 'Traumatic amputation of two or more toes',
  'vietnamese': 'Chấn thương cắt cụt hai hoặc nhiều ngón chân',
}, {
  'id': 'S98.3',
  'english': 'Traumatic amputation of other parts of foot',
  'vietnamese': 'Chấn thương cắt cụt phần khác của bàn chân',
}, {
  'id': 'S98.4',
  'english': 'Traumatic amputation of foot, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt bàn chân tại tầm chưa xác định',
}, {
  'id': 'S99',
  'english': 'Other and unspecified injuries of ankle and foot',
  'vietnamese': 'Tổn thương khác và chưa xác định của cổ chân và bàn chân',
}, {
  'id': 'S99.7',
  'english': 'Multiple injuries of ankle and foot',
  'vietnamese': 'Đa tổn thương tại cổ chân và bàn chân',
}, {
  'id': 'S99.8',
  'english': 'Other specified injuries of ankle and foot',
  'vietnamese': 'Tổn thương khác đã xác định tại cổ chân và bàn chân',
}, {
  'id': 'S99.9',
  'english': 'Unspecified injury of ankle and foot',
  'vietnamese': 'Tổn thương chưa xác định tại cổ chân và bàn chân',
}, {
  'id': 'T00',
  'english': 'Superficial injuries involving multiple body regions',
  'vietnamese': 'Tổn thương nông tác động nhiều vùng cơ thể',
}, {
  'id': 'T00.0',
  'english': 'Superficial injuries involving head with neck',
  'vietnamese': 'Tổn thương nông tác động đầu với cổ',
}, {
  'id': 'T00.1',
  'english': 'Superficial injuries involving thorax with abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương nông tác động ngực với bụng, lưng dưới và khung chậu',
}, {
  'id': 'T00.2',
  'english': 'Superficial injuries involving multiple regions of upper limb(s)',
  'vietnamese': 'Tổn thương nông tác động nhiều vùng chi trên',
}, {
  'id': 'T00.3',
  'english': 'Superficial injuries involving multiple regions of lower limb(s)',
  'vietnamese': 'Tổn thương nông tác động nhiều vùng chi dưới',
}, {
  'id': 'T00.6',
  'english': 'Superficial injuries involving multiple regions of upper limb(s) with lower limb(s)',
  'vietnamese': 'Tổn thương nông tác động nhiều vùng chi trên và chi dưới',
}, {
  'id': 'T00.8',
  'english': 'Superficial injuries involving other combinations of body regions',
  'vietnamese': 'Tổn thương nông tác động kết hợp khác các vùng cơ thể',
}, {
  'id': 'T00.9',
  'english': 'Multiple superficial injuries, unspecified',
  'vietnamese': 'Nhiều tổn thương nông chưa xác định',
}, {
  'id': 'T01',
  'english': 'Open wounds involving multiple body regions',
  'vietnamese': 'Vết thương hở tác động nhiều vùng cơ thể',
}, {
  'id': 'T01.0',
  'english': 'Open wounds involving head with neck',
  'vietnamese': 'Vết thương hở tác động đầu với cổ',
}, {
  'id': 'T01.1',
  'english': 'Open wounds involving thorax with abdomen, lower back and pelvis',
  'vietnamese': 'Vết thương hở tác động ngực với bụng, dưới lưng và khung chậu',
}, {
  'id': 'T01.2',
  'english': 'Open wounds involving multiple regions of upper limb(s)',
  'vietnamese': 'Vết thương hở ở tác động nhiều vùng của chi trên',
}, {
  'id': 'T01.3',
  'english': 'Open wounds involving multiple regions of lower limb(s)',
  'vietnamese': 'Vết thương hở tác động của nhiều vùng của chi dưới',
}, {
  'id': 'T01.6',
  'english': 'Open wounds involving multiple regions of upper limb(s) with lower limb(s)',
  'vietnamese': 'Vết thương hở với tác động của nhiều vùng chi trên và chi dưới',
}, {
  'id': 'T01.8',
  'english': 'Open wounds involving other combinations of body regions',
  'vietnamese': 'Vết thương hở tác động kết hợp khác của nhiều vùng cơ thể',
}, {
  'id': 'T01.9',
  'english': 'Multiple open wounds, unspecified',
  'vietnamese': 'Đa vết thương hở chưa xác định',
}, {
  'id': 'T02',
  'english': 'Fractures involving multiple body regions',
  'vietnamese': 'Gẫy xương tác động nhiều vùng cơ thể',
}, {
  'id': 'T02.0',
  'english': 'Fractures involving head with neck',
  'vietnamese': 'Gẫy xương tác động đầu với cổ',
}, {
  'id': 'T02.1',
  'english': 'Fractures involving thorax with lower back and pelvis',
  'vietnamese': 'Gẫy xương tác động ngực, dưới lưng và khung châu',
}, {
  'id': 'T02.2',
  'english': 'Fractures involving multiple regions of one upper limb',
  'vietnamese': 'Gẫy xương tác động nhiều vùng của một chi trên',
}, {
  'id': 'T02.3',
  'english': 'Fractures involving multiple regions of one lower limb',
  'vietnamese': 'Gẫy xương tác động nhiều vùng của một chi dưới',
}, {
  'id': 'T02.4',
  'english': 'Fractures involving multiple regions of both upper limbs',
  'vietnamese': 'Gẫy xương tác động nhiều vùng của cả hai chi trên',
}, {
  'id': 'T02.5',
  'english': 'Fractures involving multiple regions of both lower limbs',
  'vietnamese': 'Gẫy xương tác động nhiều vùng của cả hai chi dưới',
}, {
  'id': 'T02.6',
  'english': 'Fractures involving multiple regions of upper limb(s) with lower limb(s)',
  'vietnamese': 'Gẫy xương tác động nhiều vùng của chi trên và chi dưới',
}, {
  'id': 'T02.7',
  'english': 'Fractures involving thorax with lower back and pelvis with limb(s)',
  'vietnamese': 'Gẫy xương tác động ngực với lưng dưới và khung chậu với chi',
}, {
  'id': 'T02.8',
  'english': 'Fractures involving other combinations of body regions',
  'vietnamese': 'Gẫy xương tác động kết hợp khác của nhiều vùng cơ thể',
}, {
  'id': 'T02.9',
  'english': 'Multiple fractures, unspecified',
  'vietnamese': 'Đa gẫy xương, chưa xác định',
}, {
  'id': 'T03',
  'english': 'Dislocations, sprains and strains involving multiple body regions',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động của nhiều vùng cơ thể',
}, {
  'id': 'T03.0',
  'english': 'Dislocations, sprains and strains involving head with neck',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động của nhiều vùng cơ thể',
}, {
  'id': 'T03.1',
  'english': 'Dislocations, sprains and strains involving thorax with lower back and pelvis',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động của ngực với lưng dưới và khung chậu',
}, {
  'id': 'T03.2',
  'english': 'Dislocations, sprains and strains involving multiple regions of upper limb(s)',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động nhiều vùng chi trên',
}, {
  'id': 'T03.3',
  'english': 'Dislocations, sprains and strains involving multiple regions of lower limb(s)',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động nhiều của vùng chi dưới',
}, {
  'id': 'T03.4',
  'english': 'Dislocations, sprains and strains involving multiple regions of upper limb(s) with lower limb(s)',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động của nhiều vùng của chi trên và chi dưới',
}, {
  'id': 'T03.8',
  'english': 'Dislocations, sprains and strains involving other combinations of body regions',
  'vietnamese': 'Sai khớp, bong gân, căng cơ tác động kết hợp khác của các vùng cơ thể',
}, {
  'id': 'T03.9',
  'english': 'Multiple dislocations, sprains and strains, unspecified',
  'vietnamese': 'Nhiều sai khớp, bong gân, căng cơ chưa xác định',
}, {
  'id': 'T04',
  'english': 'Crushing injuries involving multiple body regions',
  'vietnamese': 'Tổn thương dập nát tác động nhiều vùng cơ thể',
}, {
  'id': 'T04.0',
  'english': 'Crushing injuries involving head with neck',
  'vietnamese': 'Tổn thương dập nát tác động đầu với cổ',
}, {
  'id': 'T04.1',
  'english': 'Crushing injuries involving thorax with abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương dập nát tác động ngực với bụng lưng dưới và khung chậu',
}, {
  'id': 'T04.2',
  'english': 'Crushing injuries involving multiple regions of upper limb(s)',
  'vietnamese': 'Tổn thương giập nát tác động nhiều vùng của chi trên',
}, {
  'id': 'T04.3',
  'english': 'Crushing injuries involving multiple regions of lower limb(s)',
  'vietnamese': 'Tổn thương dập nát tác động nhiều vùng các chi dưới',
}, {
  'id': 'T04.4',
  'english': 'Crushing injuries involving multiple regions of upper limb(s) with lower limb(s)',
  'vietnamese': 'Tổn thương dập nát tác động nhiều vùng của các chi trên và các chi dưới',
}, {
  'id': 'T04.7',
  'english': 'Crushing injuries of thorax with abdomen, lower back and pelvis with limb(s)',
  'vietnamese': 'Tổn thương dập nát của ngực với bụng, lưng dưới và khung chậu với các chi',
}, {
  'id': 'T04.8',
  'english': 'Crushing injuries involving other combinations of body regions',
  'vietnamese': 'Tổn thương dập nát tác động kết hợp các vùng cơ thể',
}, {
  'id': 'T04.9',
  'english': 'Multiple crushing injuries, unspecified',
  'vietnamese': 'Đa tổn thương dập nát, chưa xác định',
}, {
  'id': 'T05',
  'english': 'Traumatic amputations involving multiple body regions',
  'vietnamese': 'Chấn thương cắt cụt tác động nhiều vùng cơ thể',
}, {
  'id': 'T05.0',
  'english': 'Traumatic amputation of both hands',
  'vietnamese': 'Chấn thương cắt cụt cả hai bàn tay',
}, {
  'id': 'T05.1',
  'english': 'Traumatic amputation of one hand and other arm [any level, except hand]',
  'vietnamese': 'Chấn thương cắt cụt một bàn tay và tay khác (tầm bất kỳ trừ bàn tay)',
}, {
  'id': 'T05.2',
  'english': 'Traumatic amputation of both arms [any level]',
  'vietnamese': 'Chấn thương cắt cụt cả hai bàn tay (tầm bất kỳ)',
}, {
  'id': 'T05.3',
  'english': 'Traumatic amputation of both feet',
  'vietnamese': 'Chấn thương cắt cụt cả hai bàn chân',
}, {
  'id': 'T05.4',
  'english': 'Traumatic amputation of one foot and other leg [any level, except foot]',
  'vietnamese': 'Chấn thương cắt cụt một bàn chân và chân khác (tầm bất kỳ trừ bàn chân)',
}, {
  'id': 'T05.5',
  'english': 'Traumatic amputation of both legs [any level]',
  'vietnamese': 'Chấn thương cắt cụt cả hai chân (tầm bất kỳ)',
}, {
  'id': 'T05.6',
  'english': 'Traumatic amputation of upper and lower limbs, any combination [any level]',
  'vietnamese': 'Chấn thương cắt cụt cả hai chân (tầm bất kỳ)',
}, {
  'id': 'T05.8',
  'english': 'Traumatic amputations involving other combinations of body regions',
  'vietnamese': 'Chấn thương cắt cụt tác động kết hợp khác các vùng cơ thể',
}, {
  'id': 'T05.9',
  'english': 'Multiple traumatic amputations, unspecified',
  'vietnamese': 'Đa chấn thương cắt cụt chưa xác định',
}, {
  'id': 'T06',
  'english': 'Other injuries involving multiple body regions, not elsewhere classified',
  'vietnamese': 'Tổn thương khác tác động nhiều vùng cơ thể, không xếp loại nơi khác',
}, {
  'id': 'T06.0',
  'english': 'Injuries of brain and cranial nerves with injuries of nerves and spinal cord at neck level',
  'vietnamese': 'Tổn thương của não và dây thần kinh sọ với tổn thương của dây thần kinh và cột sống tại tầm cổ',
}, {
  'id': 'T06.1',
  'english': 'Injuries of nerves and spinal cord involving other multiple body regions',
  'vietnamese': 'Tổn thương dây thần kinh và cột sống tác động nhiều vùng khác',
}, {
  'id': 'T06.2',
  'english': 'Injuries of nerves involving multiple body regions',
  'vietnamese': 'Tổn thương dây thần kinh tác động nhiều vùng cơ thể',
}, {
  'id': 'T06.3',
  'english': 'Injuries of blood vessels involving multiple body regions',
  'vietnamese': 'Tổn thương mạch máu tác động nhiều vùng cơ thể',
}, {
  'id': 'T06.4',
  'english': 'Injuries of muscles and tendons involving multiple body regions',
  'vietnamese': 'Tổn thương cơ và gân tác động nhiều vùng cơ thể',
}, {
  'id': 'T06.5',
  'english': 'Injuries of intrathoracic organs with intra-abdominal and pelvic organs',
  'vietnamese': 'Tổn thương phủ tạng trong lồng ngực với trong ổ bụng và phủ tạng khung chậu',
}, {
  'id': 'T06.8',
  'english': 'Other specified injuries involving multiple body regions',
  'vietnamese': 'Tổn thương xác định khác tác động nhiều vùng cơ thể',
}, {
  'id': 'T07',
  'english': 'Unspecified multipe injuries',
  'vietnamese': 'Đa tổn thương chưa xác định',
}, {
  'id': 'T08',
  'english': 'Fracture of spine, level unspecified',
  'vietnamese': 'gẫy cột sống, mức độ chưa xác định',
}, {
  'id': 'T09',
  'english': 'Other injuries of spine and trunk, level unspecified',
  'vietnamese': 'Tổn thương khác của cột sống và thân, tầm chưa xác định',
}, {
  'id': 'T09.0',
  'english': 'Superficial injury of trunk, level unspecified',
  'vietnamese': 'Tổn thương nông tại thân, tầm chưa xác định',
}, {
  'id': 'T09.1',
  'english': 'Open wound of trunk, level unspecified',
  'vietnamese': 'Vết thương hở tại thân, tầm chưa xác định',
}, {
  'id': 'T09.2',
  'english': 'Dislocation, sprain and strain of unspecified joint and ligament of trunk',
  'vietnamese': 'Sai khớp, bong gân và căng cơ của khớp chưa xác định và dây chằng của thân',
}, {
  'id': 'T09.3',
  'english': 'Injury of spinal cord, level unspecified',
  'vietnamese': 'Tổn thương cột sống, tầm chưa xác định',
}, {
  'id': 'T09.4',
  'english': 'Injury of unspecified nerve, spinal nerve root and plexus of trunk',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định, rễ dây thần kinh cột sống và tùng của thân',
}, {
  'id': 'T09.5',
  'english': 'Injury of unspecified muscle and tendon of trunk',
  'vietnamese': 'Tổn thương chưa xác định cơ và gân của thân',
}, {
  'id': 'T09.6',
  'english': 'Traumatic amputation of trunk, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt của thân, tầm chưa xác định',
}, {
  'id': 'T09.8',
  'english': 'Other specified injuries of trunk, level unspecified',
  'vietnamese': 'Tổn thương khác xác định tại thân, tầm chưa xác định',
}, {
  'id': 'T09.9',
  'english': 'Unspecified injury of trunk, level unspecified',
  'vietnamese': 'Tổn thương chưa xác định của thân, tầm chưa xác định',
}, {
  'id': 'T10',
  'english': 'Fracture of upper limb, level unspecified',
  'vietnamese': 'gẫy xương của chi trên, tầm chưa xác định',
}, {
  'id': 'T11',
  'english': 'Other injuries of upper limb, level unspecified',
  'vietnamese': 'Tổn thương khác tại chi trên, tầm chưa xác định',
}, {
  'id': 'T11.0',
  'english': 'Superficial injury of upper limb, level unspecified',
  'vietnamese': 'Tổn thương nông chi trên, tầm chưa xác định',
}, {
  'id': 'T11.1',
  'english': 'Open wound of upper limb, level unspecified',
  'vietnamese': 'Vết thương hở chi trên, tầm chưa xác định',
}, {
  'id': 'T11.2',
  'english': 'Dislocation, sprain and strain of unspecified joint and ligament of upper limb, level unspecified',
  'vietnamese': 'Sai khớp, bong gân và căng cơ khớp chưa xác định và dây chằng chi trên, tầm chưa xác định',
}, {
  'id': 'T11.3',
  'english': 'Injury of unspecified nerve of upper limb, level unspecified',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tại chi trên, tầm chưa xác định',
}, {
  'id': 'T11.4',
  'english': 'Injury of unspecified blood vessel of upper limb, level unspecified',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tại chi trên, tầm chưa xác định',
}, {
  'id': 'T11.5',
  'english': 'Injury of unspecified muscle and tendon of upper limb, level unspecified',
  'vietnamese': 'Tổn thương cơ chưa xác định và gân tại chi trên, tầm chưa xác định',
}, {
  'id': 'T11.6',
  'english': 'Traumatic amputation of upper limb, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt tại chi trên, tầm không xác định',
}, {
  'id': 'T11.8',
  'english': 'Other specified injuries of upper limb, level unspecified',
  'vietnamese': 'Tổn thương khác đã xác định tại chi trên, tầm chưa xác định',
}, {
  'id': 'T11.9',
  'english': 'Unspecified injury of upper limb, level unspecified',
  'vietnamese': 'Tổn thương chưa xác định tại chi trên, tầm chưa xác định',
}, {
  'id': 'T12',
  'english': 'Fracture of lower limb, level unspecified',
  'vietnamese': 'gẫy xương chi dưới, tầm chưa xác định',
}, {
  'id': 'T13',
  'english': 'Other injuries of lower limb, level unspecified',
  'vietnamese': 'Tổn thương khác chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.0',
  'english': 'Superficial injury of lower limb, level unspecified',
  'vietnamese': 'Tổn thương nông chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.1',
  'english': 'Open wound of lower limb, level unspecified',
  'vietnamese': 'Vết thương hở chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.2',
  'english': 'Dislocation, sprain and strain of unspecified joint and ligament of lower limb, level unspecified',
  'vietnamese': 'Sai khớp, bong gân và căng cơ khớp chưa xác định và dây chằng chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.3',
  'english': 'Injury of unspecified nerve of lower limb, level unspecified',
  'vietnamese': 'Tổn thương dây thần kinh chưa xác định tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.4',
  'english': 'Injury of unspecified blood vessel of lower limb, level unspecified',
  'vietnamese': 'Tổn thương mạch máu chưa xác định tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.5',
  'english': 'Injury of unspecified muscle and tendon of lower limb, level unspecified',
  'vietnamese': 'Tổn thương cơ bắp chưa xác định và gân tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.6',
  'english': 'Traumatic amputation of lower limb, level unspecified',
  'vietnamese': 'Chấn thương cắt cụt tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.8',
  'english': 'Other specified injuries of lower limb, level unspecified',
  'vietnamese': 'Tổn thương khác đã xác định tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T13.9',
  'english': 'Unspecified injury of lower limb, level unspecified',
  'vietnamese': 'Tổn thương chưa xác định tại chi dưới, tầm chưa xác định',
}, {
  'id': 'T14',
  'english': 'Injury of unspecified body region',
  'vietnamese': 'Tổn thương chưa xác định vùng cơ thể',
}, {
  'id': 'T14.0',
  'english': 'Superficial injury of unspecified body region',
  'vietnamese': 'Tổn thương nông tại vùng cơ thể chưa xác định',
}, {
  'id': 'T14.1',
  'english': 'Open wound of unspecified body region',
  'vietnamese': 'Vết thương hở chưa xác định vùng cơ thể',
}, {
  'id': 'T14.2',
  'english': 'Fracture of unspecified body region',
  'vietnamese': 'Gẫy xương chưa xác định vùng cơ thể',
}, {
  'id': 'T14.3',
  'english': 'Dislocation, sprain and strain of unspecified body region',
  'vietnamese': 'Dislocation, sprain and strain of unspecified body region',
}, {
  'id': 'T14.4',
  'english': 'Injury of nerve(s) of unspecified body region',
  'vietnamese': 'Tổn thương (nhiều) dây thần kinh vùng cơ thể chưa xác định',
}, {
  'id': 'T14.5',
  'english': 'Injury of blood vessel(s) of unspecified body region',
  'vietnamese': 'Tổn thương mạch máu vùng cơ thể chưa xác định',
}, {
  'id': 'T14.6',
  'english': 'Injury of muscles and tendons of unspecified body region',
  'vietnamese': 'Tổn thương gân và cơ bắp cùng cơ thể chưa xác định',
}, {
  'id': 'T14.7',
  'english': 'Crushing injury and traumatic amputation of unspecified body region',
  'vietnamese': 'Tổn thương dập nát và chấn thương cắt cụt vùng cơ thể chưa xác định',
}, {
  'id': 'T14.8',
  'english': 'Other injuries of unspecified body region',
  'vietnamese': 'Tổn thương khác của vùng cơ thể chưa xác định',
}, {'id': 'T14.9', 'english': 'Injury, unspecified', 'vietnamese': 'Tổn thương, chưa xác định'}, {
  'id': 'T15',
  'english': 'Foreign body on external eye',
  'vietnamese': 'Vật lạ ngoài mắt',
}, {'id': 'T15.0', 'english': 'Foreign body in cornea', 'vietnamese': 'Vật lạ trong giác mạc'}, {
  'id': 'T15.1',
  'english': 'Foreign body in conjunctival sac',
  'vietnamese': 'Vật lạ trong túi kết mạc',
}, {
  'id': 'T15.8',
  'english': 'Foreign body in other and multiple parts of external eye',
  'vietnamese': 'Vật lạ nơi khác và nhiều phần ở ngoài mắt',
}, {
  'id': 'T15.9',
  'english': 'Foreign body on external eye, part unspecified',
  'vietnamese': 'Vật lạ ở ngoài mắt, phần chưa xác định',
}, {'id': 'T16', 'english': 'Foreign body in ear', 'vietnamese': 'Vật lạ ở tai'}, {
  'id': 'T17',
  'english': 'Foreign body in respiratory tract',
  'vietnamese': 'Vật lạ tại đường hô hấp',
}, {
  'id': 'T17.0',
  'english': 'Foreign body in nasal sinus',
  'vietnamese': 'Vật lạ trong xoang mũi',
}, {
  'id': 'T17.1',
  'english': 'Foreign body in nostril',
  'vietnamese': 'Vật lạ trong lỗ ngoài mũi',
}, {
  'id': 'T17.2',
  'english': 'Foreign body in pharynx',
  'vietnamese': 'Vật lạ trong hầu họng',
}, {
  'id': 'T17.3',
  'english': 'Foreign body in larynx',
  'vietnamese': 'Vật lạ trong thanh quản',
}, {
  'id': 'T17.4',
  'english': 'Foreign body in trachea',
  'vietnamese': 'Vật lạ trong khí quản',
}, {
  'id': 'T17.5',
  'english': 'Foreign body in bronchus',
  'vietnamese': 'Vật lạ trong phế quản',
}, {
  'id': 'T17.8',
  'english': 'Foreign body in other and multiple parts of respiratory tract',
  'vietnamese': 'Vật lạ nơi khác và nhiều phần của đường hô hấp',
}, {
  'id': 'T17.9',
  'english': 'Foreign body in respiratory tract, part unspecified',
  'vietnamese': 'Vật lạ trong đường hô hấp, phần chưa xác định',
}, {
  'id': 'T18',
  'english': 'Foreign body in alimentary tract',
  'vietnamese': 'Vật lạ trong đường tiêu hóa',
}, {'id': 'T18.0', 'english': 'Foreign body in mouth', 'vietnamese': 'Vật lạ trong miệng'}, {
  'id': 'T18.1',
  'english': 'Foreign body in oesophagus',
  'vietnamese': 'Vật lạ trong thực quản',
}, {'id': 'T18.2', 'english': 'Foreign body in stomach', 'vietnamese': 'Vật lạ trong dạ dày'}, {
  'id': 'T18.3',
  'english': 'Foreign body in small intestine',
  'vietnamese': 'Vật lạ trong ruột non',
}, {'id': 'T18.4', 'english': 'Foreign body in colon', 'vietnamese': 'Vật lạ trong ruột'}, {
  'id': 'T18.5',
  'english': 'Foreign body in anus and rectum',
  'vietnamese': 'Vật lạ trong hậu môn và trực tràng',
}, {
  'id': 'T18.8',
  'english': 'Foreign body in other and multiple parts of alimentary tract',
  'vietnamese': 'Vật lạ nơi khác và nhiều phần của đường tiêu hóa',
}, {
  'id': 'T18.9',
  'english': 'Foreign body in alimentary tract, part unspecified',
  'vietnamese': 'Vật lạ trong đường tiêu hóa, chưa xác định',
}, {
  'id': 'T19',
  'english': 'Foreign body in genitourinary tract',
  'vietnamese': 'Vật lạ đường tiết niệu sinh dục',
}, {'id': 'T19.0', 'english': 'Foreign body in urethra', 'vietnamese': 'Vật lạ trong niệu đạo'}, {
  'id': 'T19.1',
  'english': 'Foreign body in bladder',
  'vietnamese': 'Vật lạ trong bàng quang',
}, {
  'id': 'T19.2',
  'english': 'Foreign body in vulva and vagina',
  'vietnamese': 'Vật lạ trong âm hộ và âm đạo',
}, {
  'id': 'T19.3',
  'english': 'Foreign body in uterus [any part]',
  'vietnamese': 'Vật lạ trong tử cung (bất kỳ chỗ nào)',
}, {
  'id': 'T19.8',
  'english': 'Foreign body in other and multiple parts of genitourinary tract',
  'vietnamese': 'Vật lạ nơi khác và nhiều phần của đường tiết niệu sinh dục',
}, {
  'id': 'T19.9',
  'english': 'Foreign body in genitourinary tract, part unspecified',
  'vietnamese': 'Vật lạ trong đường tiết niệu sinh dục, phần chưa xác định',
}, {
  'id': 'T20',
  'english': 'Burn and corrosion of head and neck',
  'vietnamese': 'Bỏng và ăn mòn tại đầu và cổ',
}, {
  'id': 'T20.0',
  'english': 'Burn of unspecified degree of head and neck',
  'vietnamese': 'Bỏng độ chưa xác định tại đầu và cổ',
}, {
  'id': 'T20.1',
  'english': 'Burn of first degree of head and neck',
  'vietnamese': 'Bỏng độ một tại đầu và cổ',
}, {
  'id': 'T20.2',
  'english': 'Burn of second degree of head and neck',
  'vietnamese': 'Bỏng độ hai tại đầu và cổ',
}, {
  'id': 'T20.3',
  'english': 'Burn of third degree of head and neck',
  'vietnamese': 'Bỏng độ ba tại đầu và cổ',
}, {
  'id': 'T20.4',
  'english': 'Corrosion of unspecified degree of head and neck',
  'vietnamese': 'Ăn mòn độ chưa xác định tại đầu và cổ',
}, {
  'id': 'T20.5',
  'english': 'Corrosion of first degree of head and neck',
  'vietnamese': 'Ăn mòn độ một tại đầu và cổ',
}, {
  'id': 'T20.6',
  'english': 'Corrosion of second degree of head and neck',
  'vietnamese': 'Ăn mòn độ hai tại đầu và cổ',
}, {
  'id': 'T20.7',
  'english': 'Corrosion of third degree of head and neck',
  'vietnamese': 'Ăn mòn độ ba tại đầu và cổ',
}, {
  'id': 'T21',
  'english': 'Burn and corrosion of trunk',
  'vietnamese': 'Bỏng và ăn mòn tại thân mình',
}, {
  'id': 'T21.0',
  'english': 'Burn of unspecified degree of trunk',
  'vietnamese': 'Bỏng tại thân độ chưa xác định',
}, {
  'id': 'T21.1',
  'english': 'Burn of first degree of trunk',
  'vietnamese': 'Bỏng tại thân độ một',
}, {
  'id': 'T21.2',
  'english': 'Burn of second degree of trunk',
  'vietnamese': 'Bỏng tại thân độ hai',
}, {
  'id': 'T21.3',
  'english': 'Burn of third degree of trunk',
  'vietnamese': 'Bỏng tại thân độ ba',
}, {
  'id': 'T21.4',
  'english': 'Corrosion of unspecified degree of trunk',
  'vietnamese': 'Ăn mòn tại thân mình độ chưa xác định',
}, {
  'id': 'T21.5',
  'english': 'Corrosion of first degree of trunk',
  'vietnamese': 'Ăn mòn tại thân mình độ một',
}, {
  'id': 'T21.6',
  'english': 'Corrosion of second degree of trunk',
  'vietnamese': 'Ăn mòn tại thân mình độ hai',
}, {
  'id': 'T21.7',
  'english': 'Corrosion of third degree of trunk',
  'vietnamese': 'Ăn mòn tại thân mình độ ba',
}, {
  'id': 'T22',
  'english': 'Burn and corrosion of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Bỏng và ăn mòn tại vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.0',
  'english': 'Burn of unspecified degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Bỏng độ chưa xác định của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.1',
  'english': 'Burn of first degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Bỏng độ một của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.2',
  'english': 'Burn of second degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Bỏng độ hai của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.3',
  'english': 'Burn of third degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Bỏng độ ba của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.4',
  'english': 'Corrosion of unspecified degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Ăn mòn độ chưa xác định của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.5',
  'english': 'Corrosion of first degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Ăn mòn độ một của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.6',
  'english': 'Corrosion of second degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Ăn mòn độ hai của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T22.7',
  'english': 'Corrosion of third degree of shoulder and upper limb, except wrist and hand',
  'vietnamese': 'Ăn mòn độ ba của vai và chi trên, trừ cổ tay và bàn tay',
}, {
  'id': 'T23',
  'english': 'Burn and corrosion of wrist and hand',
  'vietnamese': 'Bỏng và ăn mòn tại cổ tay và bàn tay',
}, {
  'id': 'T23.0',
  'english': 'Burn of unspecified degree of wrist and hand',
  'vietnamese': 'Bỏng độ chưa xác định của cổ tay và bàn tay',
}, {
  'id': 'T23.1',
  'english': 'Burn of first degree of wrist and hand',
  'vietnamese': 'Bỏng độ một của cổ tay và bàn tay',
}, {
  'id': 'T23.2',
  'english': 'Burn of second degree of wrist and hand',
  'vietnamese': 'Bỏng độ hai của cổ tay và bàn tay',
}, {
  'id': 'T23.3',
  'english': 'Burn of third degree of wrist and hand',
  'vietnamese': 'Bỏng độ ba của cổ tay và bàn tay',
}, {
  'id': 'T23.4',
  'english': 'Corrosion of unspecified degree of wrist and hand',
  'vietnamese': 'Ăn mòn độ chưa xác định của cổ tay và bàn tay',
}, {
  'id': 'T23.5',
  'english': 'Corrosion of first degree of wrist and hand',
  'vietnamese': 'Ăn mòn độ một của cổ tay và bàn tay',
}, {
  'id': 'T23.6',
  'english': 'Corrosion of second degree of wrist and hand',
  'vietnamese': 'Ăn mòn độ hai của cổ tay và bàn tay',
}, {
  'id': 'T23.7',
  'english': 'Corrosion of third degree of wrist and hand',
  'vietnamese': 'Ăn mòn độ ba của cổ tay và bàn tay',
}, {
  'id': 'T24',
  'english': 'Burn and corrosion of hip and lower limb, except ankle and foot',
  'vietnamese': 'Bỏng và ăn mòn tại háng và chi dưới trừ cổ chân và bàn chân',
}, {
  'id': 'T24.0',
  'english': 'Burn of unspecified degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Bỏng độ chưa xác định tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.1',
  'english': 'Burn of first degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Bỏng độ một tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.2',
  'english': 'Burn of second degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Bỏng độ hai tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.3',
  'english': 'Burn of third degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Bỏng độ ba tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.4',
  'english': 'Corrosion of unspecified degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Ăn mòn độ chưa xác định tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.5',
  'english': 'Corrosion of first degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Ăn mòn độ một tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.6',
  'english': 'Corrosion of second degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Ăn mòn độ hai tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T24.7',
  'english': 'Corrosion of third degree of hip and lower limb, except ankle and foot',
  'vietnamese': 'Ăn mòn độ ba tại háng và chi dưới, trừ cổ chân và bàn chân',
}, {
  'id': 'T25',
  'english': 'Burn and corrosion of ankle and foot',
  'vietnamese': 'Bỏng và ăn mòn tại cổ chân và bàn chân',
}, {
  'id': 'T25.0',
  'english': 'Burn of unspecified degree of ankle and foot',
  'vietnamese': 'Bỏng độ chưa xác định tại cổ chân và bàn chân',
}, {
  'id': 'T25.1',
  'english': 'Burn of first degree of ankle and foot',
  'vietnamese': 'Bỏng độ một tại cổ chân và bàn chân',
}, {
  'id': 'T25.2',
  'english': 'Burn of second degree of ankle and foot',
  'vietnamese': 'Bỏng độ hai tại cổ chân và bàn chân',
}, {
  'id': 'T25.3',
  'english': 'Burn of third degree of ankle and foot',
  'vietnamese': 'Bỏng độ ba tại cổ chân và bàn chân',
}, {
  'id': 'T25.4',
  'english': 'Corrosion of unspecified degree of ankle and foot',
  'vietnamese': 'Ăn mòn độ chưa xác định tại cổ chân và bàn chân',
}, {
  'id': 'T25.5',
  'english': 'Corrosion of first degree of ankle and foot',
  'vietnamese': 'Ăn mòn độ một tại cổ chân và bàn chân',
}, {
  'id': 'T25.6',
  'english': 'Corrosion of second degree of ankle and foot',
  'vietnamese': 'Ăn mòn độ hai tại cổ chân và bàn chân',
}, {
  'id': 'T25.7',
  'english': 'Corrosion of third degree of ankle and foot',
  'vietnamese': 'Ăn mòn độ ba tại cổ chân và bàn chân',
}, {
  'id': 'T26',
  'english': 'Burn and corrosion confined to eye and adnexa',
  'vietnamese': 'Bỏng và ăn mòn giới hạn tại mắt và phần phụ của mắt',
}, {
  'id': 'T26.0',
  'english': 'Burn of eyelid and periocular area',
  'vietnamese': 'Bỏng tại mi mắt và vùng quanh nhãn cầu',
}, {
  'id': 'T26.1',
  'english': 'Burn of cornea and conjunctival sac',
  'vietnamese': 'Bỏng tại giác mạc và túi kết mạc',
}, {
  'id': 'T26.2',
  'english': 'Burn with resulting rupture and destruction of eyeball',
  'vietnamese': 'Bỏng với hậu quả gây vỡ và phá hủy nhãn cầu',
}, {
  'id': 'T26.3',
  'english': 'Burn of other parts of eye and adnexa',
  'vietnamese': 'Bỏng tại phần khác của mắt và phần phụ của mắt',
}, {
  'id': 'T26.4',
  'english': 'Burn of eye and adnexa, part unspecified',
  'vietnamese': 'Bỏng tại mắt và phần phụ của mắt, phần chưa xác định',
}, {
  'id': 'T26.5',
  'english': 'Corrosion of eyelid and periocular area',
  'vietnamese': 'Ăn mòn tại mi mắt và vùng quanh nhãn cầu',
}, {
  'id': 'T26.6',
  'english': 'Corrosion of cornea and conjunctival sac',
  'vietnamese': 'Ăn mòn tại giác mạc và túi kết mạc',
}, {
  'id': 'T26.7',
  'english': 'Corrosion with resulting rupture and destruction of eyeball',
  'vietnamese': 'Ăn mòn với hậu quả gây vỡ và phá hủy nhãn cầu',
}, {
  'id': 'T26.8',
  'english': 'Corrosion of other parts of eye and adnexa',
  'vietnamese': 'Ăn mòn tại phần khác của mắt và phần phụ của mắt',
}, {
  'id': 'T26.9',
  'english': 'Corrosion of eye and adnexa, part unspecified',
  'vietnamese': 'Ăn mòn tại mắt và phần phụ mắt, phần chưa xác định',
}, {
  'id': 'T27',
  'english': 'Burn and corrosion of respiratory tract',
  'vietnamese': 'Bỏng và ăn mòn đường hô hấp',
}, {
  'id': 'T27.0',
  'english': 'Burn of larynx and trachea',
  'vietnamese': 'Bỏng thanh quản và khí quản',
}, {
  'id': 'T27.1',
  'english': 'Burn involving larynx and trachea with lung',
  'vietnamese': 'Bỏng tác động thanh quản và khí quản với phổi',
}, {
  'id': 'T27.2',
  'english': 'Burn of other parts of respiratory tract',
  'vietnamese': 'Bỏng phần khác của đường hô hấp',
}, {
  'id': 'T27.3',
  'english': 'Burn of respiratory tract, part unspecified',
  'vietnamese': 'Bỏng đường hô hấp, phần chưa xác định',
}, {
  'id': 'T27.4',
  'english': 'Corrosion of larynx and trachea',
  'vietnamese': 'Ăn mòn thanh quản và khí quản',
}, {
  'id': 'T27.5',
  'english': 'Corrosion involving larynx and trachea with lung',
  'vietnamese': 'Ăn mòn tác động thanh quản và khí quản với phổi',
}, {
  'id': 'T27.6',
  'english': 'Corrosion of other parts of respiratory tract',
  'vietnamese': 'Ăn mòn phần khác của đường hô hấp',
}, {
  'id': 'T27.7',
  'english': 'Corrosion of respiratory tract, part unspecified',
  'vietnamese': 'Ăn mòn đường hô hấp, phần chưa xác định',
}, {
  'id': 'T28',
  'english': 'Burn and corrosion of other internal organs',
  'vietnamese': 'Bỏng và ăn mòn nội tạng khác',
}, {
  'id': 'T28.0',
  'english': 'Burn of mouth and pharynx',
  'vietnamese': 'Bỏng tại miệng và hầu họng',
}, {'id': 'T28.1', 'english': 'Burn of oesophagus', 'vietnamese': 'Bỏng tại thực quản'}, {
  'id': 'T28.2',
  'english': 'Burn of other parts of alimentary tract',
  'vietnamese': 'Bỏng tại phần khác của đường tiêu hóa',
}, {
  'id': 'T28.3',
  'english': 'Burn of internal genitourinary organs',
  'vietnamese': 'Bỏng tại phần trong của đường tiết niệu sinh dục',
}, {
  'id': 'T28.4',
  'english': 'Burn of other and unspecified internal organs',
  'vietnamese': 'Bỏng nơi khác và các nội tạng chưa xác định',
}, {
  'id': 'T28.5',
  'english': 'Corrosion of mouth and pharynx',
  'vietnamese': 'Ăn mòn tại miệng và thanh quản',
}, {'id': 'T28.6', 'english': 'Corrosion of oesophagus', 'vietnamese': 'Ăn mòn tại thực quản'}, {
  'id': 'T28.7',
  'english': 'Corrosion of other parts of alimentary tract',
  'vietnamese': 'Ăn mòn tại phần khác của đường tiêu hóa',
}, {
  'id': 'T28.8',
  'english': 'Corrosion of internal genitourinary organs',
  'vietnamese': 'Ăn mòn tại phần trong của đường sinh dục, tiết niệu',
}, {
  'id': 'T28.9',
  'english': 'Corrosion of other and unspecified internal organs',
  'vietnamese': 'Ăn mòn tại nơi khác và các nội tạng chưa xác định',
}, {
  'id': 'T29',
  'english': 'Burns and corrosions of multiple body regions',
  'vietnamese': 'Bỏng và ăn mòn nhiều vùng cơ thể',
}, {
  'id': 'T29.0',
  'english': 'Burns of multiple regions, unspecified degree',
  'vietnamese': 'Bỏng nhiều vùng độ chưa xác định',
}, {
  'id': 'T29.1',
  'english': 'Burns of multiple regions, no more than first-degree burns mentioned',
  'vietnamese': 'Bỏng nhiều vùng, không hơn bỏng độ một đã đề cập',
}, {
  'id': 'T29.2',
  'english': 'Burns of multiple regions, no more than second-degree burns mentioned',
  'vietnamese': 'Bỏng nhiều vùng, không hơn bỏng độ hai đã đề cập',
}, {
  'id': 'T29.3',
  'english': 'Burns of multiple regions, at least one burn of third degree mentioned',
  'vietnamese': 'Bỏng nhiều vùng, ít nhất có một bỏng độ ba đã đề cập',
}, {
  'id': 'T29.4',
  'english': 'Corrosions of multiple regions, unspecified degree',
  'vietnamese': 'Ăn mòn nhiều vùng độ chưa xác định',
}, {
  'id': 'T29.5',
  'english': 'Corrosions of multiple regions, no more than first-degree corrosions mentioned',
  'vietnamese': 'Ăn mòn nhiều vùng, không hơn ăn mòn độ một đã đề cập',
}, {
  'id': 'T29.6',
  'english': 'Corrosions of multiple regions, no more than second-degree corrosions mentioned',
  'vietnamese': 'Ăn mòn nhiều vùng, không hơn ăn mòn độ hai đã đề cập',
}, {
  'id': 'T29.7',
  'english': 'Corrosions of multiple regions, at least one corrosion of third degree mentioned',
  'vietnamese': 'Ăn mòn nhiều vùng, ít nhất có một ăn mòn độ ba đã đề cập',
}, {
  'id': 'T30',
  'english': 'Burn and corrosion, body region unspecified',
  'vietnamese': 'Bỏng và ăn mòn, vùng cơ thể chưa xác định',
}, {
  'id': 'T30.0',
  'english': 'Burn of unspecified body region, unspecified degree',
  'vietnamese': 'Bỏng vùng cơ thể chưa xác định, độ chưa xác định',
}, {
  'id': 'T30.1',
  'english': 'Burn of first degree, body region unspecified',
  'vietnamese': 'Bỏng độ một, vùng cơ thể chưa xác định',
}, {
  'id': 'T30.2',
  'english': 'Burn of second degree, body region unspecified',
  'vietnamese': 'Bỏng độ hai, vùng cơ thể chưa xác định',
}, {
  'id': 'T30.3',
  'english': 'Burn of third degree, body region unspecified',
  'vietnamese': 'Bỏng độ ba, vùng cơ thể chưa xác định',
}, {
  'id': 'T30.4',
  'english': 'Corrosion of unspecified body region, unspecified degree',
  'vietnamese': 'Ăn mòn vùng cơ thể chưa xác định, độ chưa xác định',
}, {
  'id': 'T30.5',
  'english': 'Corrosion of first degree, body region unspecified',
  'vietnamese': 'Ăn mòn độ một, vùng cơ thể chưa xác định khác',
}, {
  'id': 'T30.6',
  'english': 'Corrosion of second degree, body region unspecified',
  'vietnamese': 'Ăn mòn độ hai, vùng cơ thể chưa xác định',
}, {
  'id': 'T30.7',
  'english': 'Corrosion of third degree, body region unspecified',
  'vietnamese': 'Ăn mòn độ ba, vùng cơ thể chưa xác định',
}, {
  'id': 'T31',
  'english': 'Burns classified according to extent of body surface involved',
  'vietnamese': 'Bỏng xếp loại tương ứng với mức độ bề mặt cơ thể bị tổn thương',
}, {
  'id': 'T31.0',
  'english': 'Burns involving less than 10% of body surface',
  'vietnamese': 'Bỏng tổn thương ít hơn 10% bề mặt cơ thể',
}, {
  'id': 'T31.1',
  'english': 'Burns involving 10-19% of body surface',
  'vietnamese': 'Bỏng tổn thương10-19% bề mặt cơ thể',
}, {
  'id': 'T31.2',
  'english': 'Burns involving 20-29% of body surface',
  'vietnamese': 'Bỏng tổn thương 20-29% bề mặt cơ thể',
}, {
  'id': 'T31.3',
  'english': 'Burns involving 30-39% of body surface',
  'vietnamese': 'Bỏng tổn thương 30-39% bề mặt cơ thể',
}, {
  'id': 'T31.4',
  'english': 'Burns involving 40-49% of body surface',
  'vietnamese': 'Bỏng tổn thương 40-49% bề mặt cơ thể',
}, {
  'id': 'T31.5',
  'english': 'Burns involving 50-59% of body surface',
  'vietnamese': 'Bỏng tổn thương 50-59% bề mặt cơ thể',
}, {
  'id': 'T31.6',
  'english': 'Burns involving 60-69% of body surface',
  'vietnamese': 'Bỏng tổn thương 60-69% bề mặt cơ thể',
}, {
  'id': 'T31.7',
  'english': 'Burns involving 70-79% of body surface',
  'vietnamese': 'Bỏng tổn thương 70-79% bề mặt cơ thể',
}, {
  'id': 'T31.8',
  'english': 'Burns involving 80-89% of body surface',
  'vietnamese': 'Bỏng tổn thương 80-89% bề mặt cơ thể',
}, {
  'id': 'T31.9',
  'english': 'Burns involving 90% or more of body surface',
  'vietnamese': 'Bỏng tổn thương 90% hoặc hơn, bề mặt cơ thể',
}, {
  'id': 'T32',
  'english': 'Corrosions classified according to extent of body surface involved',
  'vietnamese': 'Ăn mòn xếp loại tương ứng với mức độ phụ thuộc phạm vi bề mặt cơ thể bị tổn thương',
}, {
  'id': 'T32.0',
  'english': 'Corrosions involving less than 10% of body surface',
  'vietnamese': 'Ăn mòn tổn thương ít hơn 10% bề mặt cơ thể',
}, {
  'id': 'T32.1',
  'english': 'Corrosions involving 10-19% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 10-19% bề mặt cơ thể',
}, {
  'id': 'T32.2',
  'english': 'Corrosions involving 20-29% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 20-29% bề mặt cơ thể',
}, {
  'id': 'T32.3',
  'english': 'Corrosions involving 30-39% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 30-39% bề mặt cơ thể',
}, {
  'id': 'T32.4',
  'english': 'Corrosions involving 40-49% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 40-49% bề mặt cơ thể',
}, {
  'id': 'T32.5',
  'english': 'Corrosions involving 50-59% of body surface',
  'vietnamese': 'Ăm mòn tổn thương 50-59% bề mặt cơ thể',
}, {
  'id': 'T32.6',
  'english': 'Corrosions involving 60-69% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 60-69% bề mặt cơ thể',
}, {
  'id': 'T32.7',
  'english': 'Corrosions involving 70-79% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 70-79% bề mặt cơ thể',
}, {
  'id': 'T32.8',
  'english': 'Corrosions involving 80-89% of body surface',
  'vietnamese': 'Ăn mòn tổn thương 80-89% bề mặt cơ thể',
}, {
  'id': 'T32.9',
  'english': 'Corrosions involving 90% or more of body surface',
  'vietnamese': 'Ăn mòn tổn thương 90% hoặc hơn, bề mặt cơ thể',
}, {
  'id': 'T33',
  'english': 'Superficial frostbite',
  'vietnamese': 'Tổn thương nông do cóng lạnh',
}, {
  'id': 'T33.0',
  'english': 'Superficial frostbite of head',
  'vietnamese': 'Tổn thương do cóng lạnh tại đầu',
}, {
  'id': 'T33.1',
  'english': 'Superficial frostbite of neck',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại cổ',
}, {
  'id': 'T33.2',
  'english': 'Superficial frostbite of thorax',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại ngực',
}, {
  'id': 'T33.3',
  'english': 'Superficial frostbite of abdominal wall, lower back and pelvis',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại thành bụng, lưng dưới và khung chậu',
}, {
  'id': 'T33.4',
  'english': 'Superficial frostbite of arm',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại cánh tay',
}, {
  'id': 'T33.5',
  'english': 'Superficial frostbite of wrist and hand',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại cổ tay và bàn tay',
}, {
  'id': 'T33.6',
  'english': 'Superficial frostbite of hip and thigh',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại háng và đùi',
}, {
  'id': 'T33.7',
  'english': 'Superficial frostbite of knee and lower leg',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại đầu gối và cẳng chân',
}, {
  'id': 'T33.8',
  'english': 'Superficial frostbite of ankle and foot',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại cổ chân và bàn chân',
}, {
  'id': 'T33.9',
  'english': 'Superficial frostbite of other and unspecified sites',
  'vietnamese': 'Tổn thương nông do cóng lạnh tại vị trí khác và chưa xác định',
}, {
  'id': 'T34',
  'english': 'Frostbite with tissue necrosis',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô',
}, {
  'id': 'T34.0',
  'english': 'Frostbite with tissue necrosis of head',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại đầu',
}, {
  'id': 'T34.1',
  'english': 'Frostbite with tissue necrosis of neck',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại cổ',
}, {
  'id': 'T34.2',
  'english': 'Frostbite with tissue necrosis of thorax',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại ngực',
}, {
  'id': 'T34.3',
  'english': 'Frostbite with tissue necrosis of abdominal wall, lower back and pelvis',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại thành bụng, lưng dưới và khung chậu',
}, {
  'id': 'T34.4',
  'english': 'Frostbite with tissue necrosis of arm',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại cánh tay',
}, {
  'id': 'T34.5',
  'english': 'Frostbite with tissue necrosis of wrist and hand',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại cổ tay và bàn tay',
}, {
  'id': 'T34.6',
  'english': 'Frostbite with tissue necrosis of hip and thigh',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại háng và đùi',
}, {
  'id': 'T34.7',
  'english': 'Frostbite with tissue necrosis of knee and lower leg',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại đầu gối và cẳng chân',
}, {
  'id': 'T34.8',
  'english': 'Frostbite with tissue necrosis of ankle and foot',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô cổ chân và bàn chân',
}, {
  'id': 'T34.9',
  'english': 'Frostbite with tissue necrosis of other and unspecified sites',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tại nơi khác và vị trí chưa xác định',
}, {
  'id': 'T35',
  'english': 'Frostbite involving multiple body regions and unspecified frostbite',
  'vietnamese': 'Tổn thương do cóng lạnh tác động nhiều vùng cơ thể và chưa xác định',
}, {
  'id': 'T35.0',
  'english': 'Superficial frostbite involving multiple body regions',
  'vietnamese': 'Tổn thương do cóng lạnh tác động nhiều vùng cơ thể',
}, {
  'id': 'T35.1',
  'english': 'Frostbite with tissue necrosis involving multiple body regions',
  'vietnamese': 'Tổn thương do cóng lạnh với hoại tử mô tác động nhiều vùng cơ thể',
}, {
  'id': 'T35.2',
  'english': 'Unspecified frostbite of head and neck',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tại đầu và cổ',
}, {
  'id': 'T35.3',
  'english': 'Unspecified frostbite of thorax, abdomen, lower back and pelvis',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tại ngực, bụng, lưng dưới và khung chậu',
}, {
  'id': 'T35.4',
  'english': 'Unspecified frostbite of upper limb',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tại chi trên',
}, {
  'id': 'T35.5',
  'english': 'Unspecified frostbite of lower limb',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tại chi dưới',
}, {
  'id': 'T35.6',
  'english': 'Unspecified frostbite involving multiple body regions',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tác động nhiều vùng cơ thể',
}, {
  'id': 'T35.7',
  'english': 'Unspecified frostbite of unspecified site',
  'vietnamese': 'Tổn thương do cóng lạnh chưa xác định tại vị trí chưa xác định',
}, {
  'id': 'T36',
  'english': 'Poisoning by systemic antibiotics',
  'vietnamese': 'Nhiễm độc do dùng kháng sinh toàn thân',
}, {
  'id': 'T36.0',
  'english': 'Poisoning: Penicillins',
  'vietnamese': 'Ngộ độc kháng sinh Penicillin',
}, {
  'id': 'T36.1',
  'english': 'Poisoning: Cefalosporins and other beta-lactam antibiotics',
  'vietnamese': 'Ngộ độc kháng sinh Cefalosporin và kháng sinh beta-lactam khác',
}, {
  'id': 'T36.2',
  'english': 'Poisoning: Chloramphenicol group',
  'vietnamese': 'Ngộ độc kháng sinh nhóm Chloramphenicol',
}, {
  'id': 'T36.3',
  'english': 'Poisoning: Macrolides',
  'vietnamese': 'Ngộ độc kháng sinh Macrolid',
}, {
  'id': 'T36.4',
  'english': 'Poisoning: Tetracyclines',
  'vietnamese': 'Ngộ độc kháng sinh Tetracyclin',
}, {
  'id': 'T36.5',
  'english': 'Poisoning: Aminoglycosides',
  'vietnamese': 'Ngộ độc kháng sinh Aminoglycosid',
}, {
  'id': 'T36.6',
  'english': 'Poisoning: Rifamycins',
  'vietnamese': 'Ngộ độc kháng sinh Rifamycin',
}, {
  'id': 'T36.7',
  'english': 'Poisoning: Antifungal antibiotics, systemically used',
  'vietnamese': 'Ngộ độc kháng sinh chống nấm toàn thân',
}, {
  'id': 'T36.8',
  'english': 'Poisoning: Other systemic antibiotics',
  'vietnamese': 'Ngộ độc kháng sinh toàn thân khác',
}, {
  'id': 'T36.9',
  'english': 'Poisoning: Systemic antibiotic, unspecified',
  'vietnamese': 'Ngộ độc kháng sinh toàn thân chưa xác định',
}, {
  'id': 'T37',
  'english': 'Poisoning by other systemic anti-infectives and antiparasitics',
  'vietnamese': 'Ngộ độc do chất chống nhiễm trùng và chống ký sinh trùng khác dùng toàn thân',
}, {'id': 'T37.0', 'english': 'Poisoning: Sulfonamides', 'vietnamese': 'Ngộ độc Sulfonamid'}, {
  'id': 'T37.1',
  'english': 'Poisoning: Antimycobacterial drugs',
  'vietnamese': 'Ngộ độc thuốc chống mycobacterial',
}, {
  'id': 'T37.2',
  'english': 'Poisoning: Antimalarials and drugs acting on other blood protozoa',
  'vietnamese': 'Ngộ độc thuốc chống sốt rét và thuốc tác động trên đơn bào máu khác',
}, {
  'id': 'T37.3',
  'english': 'Poisoning: Other antiprotozoal drugs',
  'vietnamese': 'Ngộ độc thuốc chống đơn bào khác',
}, {
  'id': 'T37.4',
  'english': 'Poisoning: Anthelminthics',
  'vietnamese': 'Ngộ độc thuốc chống giun sán',
}, {
  'id': 'T37.5',
  'english': 'Poisoning: Antiviral drugs',
  'vietnamese': 'Ngộ độc thuốc chống virus',
}, {
  'id': 'T37.8',
  'english': 'Poisoning: Other specified systemic anti-infectives and antiparasitics',
  'vietnamese': 'Ngộ độc thuốc chống nhiễm trùng và chống ký sinh trùng khác dùng toàn thân đã xác định',
}, {
  'id': 'T37.9',
  'english': 'Poisoning: Systemic anti-infective and antiparasitic, unspecified',
  'vietnamese': 'Ngộ độc thuốc chống nhiễm trùng và ký sinh trùng khác dùng toàn thân chưa xác định',
}, {
  'id': 'T38',
  'english': 'Poisoning by hormones and their synthetic substitutes and antagonists, not elsewhere classified',
  'vietnamese': 'Ngộ độc do nội tiết và chất tổng hợp thay thế và chất đối kháng, không xếp loại nơi khác',
}, {
  'id': 'T38.0',
  'english': 'Poisoning: Glucocorticoids and synthetic analogues',
  'vietnamese': 'Ngộ độc Glucocorticoid và chất tổng hợp tương tự',
}, {
  'id': 'T38.1',
  'english': 'Poisoning: Thyroid hormones and substitutes',
  'vietnamese': 'Ngộ độc Hormon tuyến giáp và chất thay thế',
}, {
  'id': 'T38.2',
  'english': 'Poisoning: Antithyroid drugs',
  'vietnamese': 'Ngộ độc Thuốc chống tuyến giáp',
}, {
  'id': 'T38.3',
  'english': 'Poisoning: Insulin and oral hypoglycaemic [antidiabetic] drugs',
  'vietnamese': 'Ngộ độc Insulin và thuốc hạ đường huyết uống (chống đái tháo đường)',
}, {
  'id': 'T38.4',
  'english': 'Poisoning: Oral contraceptives',
  'vietnamese': 'Ngộ độc Thuốc tránh thai uống',
}, {
  'id': 'T38.5',
  'english': 'Poisoning: Other estrogens and progestogens',
  'vietnamese': 'Ngộ độc Estrogen và progestogen khác',
}, {
  'id': 'T38.6',
  'english': 'Poisoning: Antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Kháng Gonadotropin, kháng tiestrogen, kháng androgen, không xếp loại ở phần nào khác',
}, {
  'id': 'T38.7',
  'english': 'Poisoning: Androgens and anabolic congeners',
  'vietnamese': 'Ngộ độc Androgen và sản phẩm đồng hóa tương tự',
}, {
  'id': 'T38.8',
  'english': 'Poisoning: Other and unspecified hormones and their synthetic substitutes',
  'vietnamese': 'Ngộ độc Hormon khác và hormon chưa xác định và chất tổng hợp thay thế',
}, {
  'id': 'T38.9',
  'english': 'Poisoning: Other and unspecified hormone antagonists',
  'vietnamese': 'Ngộ độc Hormon đối kháng và chưa xác định',
}, {
  'id': 'T39',
  'english': 'Poisoning by nonopioid analgesics, antipyretics and antirheumatics',
  'vietnamese': 'Ngộ độc do thuốc giảm đau không opioid, hạ nhiệt và chống thấp khớp',
}, {'id': 'T39.0', 'english': 'Poisoning: Salicylates', 'vietnamese': 'Ngộ độc Salicylat'}, {
  'id': 'T39.1',
  'english': 'Poisoning: 4-Aminophenol derivatives',
  'vietnamese': 'Ngộ độc chất Dẫn xuất 4- aminophenol',
}, {
  'id': 'T39.2',
  'english': 'Poisoning: Pyrazolone derivatives',
  'vietnamese': 'Ngộ độc chất Dẫn xuất pyrazolone',
}, {
  'id': 'T39.3',
  'english': 'Poisoning: Other nonsteroidal anti-inflammatory drugs [NSAID]',
  'vietnamese': 'Ngộ độc Thuốc chống viêm không steroid (NSAID)khác',
}, {
  'id': 'T39.4',
  'english': 'Poisoning: Antirheumatics, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Chống thấp khớp, không xếp loại ở phần nào',
}, {
  'id': 'T39.8',
  'english': 'Poisoning: Other nonopioid analgesics and antipyretics, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc giảm đau không opioid hạ nhiệt khác không xếp loại ở đâu',
}, {
  'id': 'T39.9',
  'english': 'Poisoning: Nonopioid analgesic, antipyretic and antirheumatic, unspecified',
  'vietnamese': 'Ngộ độc Thuốc giảm đau không opioid, hạ nhiệt và trị khớp chưa xác định',
}, {
  'id': 'T40',
  'english': 'Poisoning by narcotics and psychodysleptics [hallucinogens]',
  'vietnamese': 'Ngộ độc do thuốc gây ngủ và gây ảo giác (sinh ảo giác)',
}, {'id': 'T40.0', 'english': 'Poisoning: Opium', 'vietnamese': 'Ngộ độc Opium (thuốc phiện)'}, {
  'id': 'T40.1',
  'english': 'Poisoning: Heroin',
  'vietnamese': 'Ngộ độc Heroin',
}, {
  'id': 'T40.2',
  'english': 'Poisoning: Other opioids',
  'vietnamese': 'Ngộ độc Thuốc có thuốc phiện khác',
}, {'id': 'T40.3', 'english': 'Poisoning: Methadone', 'vietnamese': 'Ngộ độc Methadon'}, {
  'id': 'T40.4',
  'english': 'Poisoning: Other synthetic narcotics',
  'vietnamese': 'Ngộ độc Thuốc gây ngủ tổng hợp khác-',
}, {'id': 'T40.5', 'english': 'Poisoning: Cocaine', 'vietnamese': 'Ngộ độc Cocain'}, {
  'id': 'T40.6',
  'english': 'Poisoning: Other and unspecified narcotics',
  'vietnamese': 'Ngộ độc Thuốc gây ngủ khác, chưa xác định',
}, {
  'id': 'T40.7',
  'english': 'Poisoning: Cannabis (derivatives)',
  'vietnamese': 'Ngộ độc Cannabis (dẫn xuất)',
}, {
  'id': 'T40.8',
  'english': 'Poisoning: Lysergide [LSD]',
  'vietnamese': 'Ngộ độc Lysergid [LSD]',
}, {
  'id': 'T40.9',
  'english': 'Poisoning: Other and unspecified psychodysleptics [hallucinogens]',
  'vietnamese': 'Ngộ độc Thuốc gây ảo giác (sinh ảo giác) khác, chưa xác định',
}, {
  'id': 'T41',
  'english': 'Poisoning by anaesthetics and therapeutic gases',
  'vietnamese': 'Ngộ độc do gây tê và khí trị liệu',
}, {
  'id': 'T41.0',
  'english': 'Poisoning: Inhaled anaesthetics',
  'vietnamese': 'Ngộ độc do Gây mê đường thở',
}, {
  'id': 'T41.1',
  'english': 'Poisoning: Intravenous anaesthetics',
  'vietnamese': 'Ngộ độc do Gây mê đường tĩnh mạch',
}, {
  'id': 'T41.2',
  'english': 'Poisoning: Other and unspecified general anaesthetics',
  'vietnamese': 'Ngộ độc do Gây mê toàn thân khác và chưa xác định',
}, {
  'id': 'T41.3',
  'english': 'Poisoning: Local anaesthetics',
  'vietnamese': 'Ngộ độc do Gây mê tại chỗ',
}, {
  'id': 'T41.4',
  'english': 'Poisoning: Anaesthetic, unspecified',
  'vietnamese': 'Ngộ độc do Gây mê, chưa xác định',
}, {
  'id': 'T41.5',
  'english': 'Poisoning: Therapeutic gases',
  'vietnamese': 'Ngộ độc do Khí trị liệu',
}, {
  'id': 'T42',
  'english': 'Poisoning by antiepileptic, sedative-hypnotic and antiparkinsonism drugs',
  'vietnamese': 'Ngộ độc do thuốc động kinh, an thần- gây ngủ và chống hội chứng Parkison',
}, {
  'id': 'T42.0',
  'english': 'Poisoning: Hydantoin derivatives',
  'vietnamese': 'Ngộ độc do Dẫn xuất hydantoin',
}, {
  'id': 'T42.1',
  'english': 'Poisoning: Iminostilbenes',
  'vietnamese': 'Ngộ độc do Iminostiben',
}, {
  'id': 'T42.2',
  'english': 'Poisoning: Succinimides and oxazolidinediones',
  'vietnamese': 'Ngộ độc do Succinimid và oxazoildinedion',
}, {'id': 'T42.3', 'english': 'Poisoning: Barbiturates', 'vietnamese': 'Ngộ độc Barbiturat'}, {
  'id': 'T42.4',
  'english': 'Poisoning: Benzodiazepines',
  'vietnamese': 'Ngộ độc Benzodiazepin',
}, {
  'id': 'T42.5',
  'english': 'Poisoning: Mixed antiepileptics, not elsewhere classified',
  'vietnamese': 'Ngộ độc Hỗn hợp chống động kinh, không xếp loại ở phần nào',
}, {
  'id': 'T42.6',
  'english': 'Poisoning: Other antiepileptic and sedative-hypnotic drugs',
  'vietnamese': 'Ngộ độc Thuốc chống động kinh an thần gây ngủ khác',
}, {
  'id': 'T42.7',
  'english': 'Poisoning: Antiepileptic and sedative-hypnotic drugs, unspecified',
  'vietnamese': 'Ngộ độc Thuốc chống động kinh, an thần gây ngủ, chưa xác định',
}, {
  'id': 'T42.8',
  'english': 'Poisoning: Antiparkinsonism drugs and other central muscle-tone depressants',
  'vietnamese': 'Ngộ độc Thuốc chống hội chứng Parkinson và thuốc ức chế trương lực cơ trung tâm khác',
}, {
  'id': 'T43',
  'english': 'Poisoning by psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc hướng tâm thần, không xếp loại ở nơi khác',
}, {
  'id': 'T43.0',
  'english': 'Poisoning: Tricyclic and tetracyclic antidepressants',
  'vietnamese': 'Ngộ độc thuốc Chống trầm cảm ba vòng và bốn vòng',
}, {
  'id': 'T43.1',
  'english': 'Poisoning: Monoamine-oxidase-inhibitor antidepressants',
  'vietnamese': 'Ngộ độc thuốc Chống trầm cảm nhóm ức chế men IMAO',
}, {
  'id': 'T43.2',
  'english': 'Poisoning: Other and unspecified antidepressants',
  'vietnamese': 'Ngộ độc thuốc Chống trầm cảm khác và chưa xác định',
}, {
  'id': 'T43.3',
  'english': 'Poisoning: Phenothiazine antipsychotics and neuroleptics',
  'vietnamese': 'Ngộ độc thuốc Phenothiazin thuốc tâm thần và an thần',
}, {
  'id': 'T43.4',
  'english': 'Poisoning: Butyrophenone and thioxanthene neuroleptics',
  'vietnamese': 'Ngộ độc Thuốc an thần butyrophenon và thioxanthen',
}, {
  'id': 'T43.5',
  'english': 'Poisoning: Other and unspecified antipsychotics and neuroleptics',
  'vietnamese': 'Ngộ độc Thuốc tâm thần và an thần',
}, {
  'id': 'T43.6',
  'english': 'Poisoning: Psychostimulants with abuse potential',
  'vietnamese': 'Ngộ độc thuốc Kích thích tâm thần có khả năng lạm dụng thuốc',
}, {
  'id': 'T43.8',
  'english': 'Poisoning: Other psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc tâm thần khác, không xếp loại phần nào',
}, {
  'id': 'T43.9',
  'english': 'Poisoning: Psychotropic drug, unspecified',
  'vietnamese': 'Ngộ độc Thuốc hướng tâm thần, chưa xác định',
}, {
  'id': 'T44',
  'english': 'Poisoning by drugs primarily affecting the autonomic nervous system',
  'vietnamese': 'Ngộ độc thuốc Ngộ độc do thuốc tác động chủ yếu hệ thần kinh tự động',
}, {
  'id': 'T44.0',
  'english': 'Poisoning: Anticholinesterase agents',
  'vietnamese': 'Ngộ độc Thuốc kháng cholinesterase',
}, {
  'id': 'T44.1',
  'english': 'Poisoning: Other parasympathomimetics [cholinergics]',
  'vietnamese': 'Ngộ độc Thuốc giống phó giao cảm khác',
}, {
  'id': 'T44.2',
  'english': 'Poisoning: Ganglionic blocking drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc chẹn hạch, chưa xếp loại ở phần nào',
}, {
  'id': 'T44.3',
  'english': 'Poisoning: Other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc hủy phó giao cảm (chống tiết cholin và chống ngộ độc muscarinin) và giảm co cứng, không xếp loại ở phần nào',
}, {
  'id': 'T44.4',
  'english': 'Poisoning: Predominantly alpha-adrenoreceptor agonists, not elsewhere classified',
  'vietnamese': 'Ngộ độc Chủ yếu đối kháng - receptor alpha-adrenalin, không xếp loại nơi khác',
}, {
  'id': 'T44.5',
  'english': 'Poisoning: Predominantly beta-adrenoreceptor agonists, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Chủ yếu đối kháng receptor beta -adrenalin, không xếp loại nơi khác',
}, {
  'id': 'T44.6',
  'english': 'Poisoning: Alpha-adrenoreceptor antagonists, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Đối kháng receptor alpha adrenalin, không xếp loại nơi khác',
}, {
  'id': 'T44.7',
  'english': 'Poisoning: Beta-adrenoreceptor antagonists, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Đối kháng receptor beta -adrenalin, không xếp loại ở phần nào',
}, {
  'id': 'T44.8',
  'english': 'Poisoning: Centrally acting and adrenergic-neuron-blocking agents, not elsewhere classified',
  'vietnamese': 'Ngộ độc thuốc Tác động trung tâm và thuốc chẹn thần kinh gây tiết adrenalin không xếp loại nơi khác',
}, {
  'id': 'T44.9',
  'english': 'Poisoning: Other and unspecified drugs primarily affecting the autonomic nervous system',
  'vietnamese': 'Ngộ độc Thuốc tác động chủ yếu hệ thần kinh tự động khác và chưa xác định',
}, {
  'id': 'T45',
  'english': 'Poisoning by primarily systemic and haematological agents, not elsewhere classified',
  'vietnamese': 'Ngộ độc do thuốc chủ yếu toàn thân và huyết học, không xếp loại ở phần nào',
}, {
  'id': 'T45.0',
  'english': 'Poisoning: Antiallergic and antiemetic drugs',
  'vietnamese': 'Ngộ độc Thuốc chống nôn và chống dị ứng',
}, {
  'id': 'T45.1',
  'english': 'Poisoning: Antineoplastic and immunosuppressive drugs',
  'vietnamese': 'Ngộ độc Thuốc chống ung thư và ức chế miễn dịch',
}, {
  'id': 'T45.2',
  'english': 'Poisoning: Vitamins, not elsewhere classified',
  'vietnamese': 'Ngộ độc Vitamin, không xếp loại ở phần nào',
}, {
  'id': 'T45.3',
  'english': 'Poisoning: Enzymes, not elsewhere classified',
  'vietnamese': 'Ngộ độc các Enzym, không xếp loại ở phần nào',
}, {
  'id': 'T45.4',
  'english': 'Poisoning: Iron and its compounds',
  'vietnamese': 'Ngộ độc Sắt và thành phẩm',
}, {
  'id': 'T45.5',
  'english': 'Poisoning: Anticoagulants',
  'vietnamese': 'Ngộ độc thuốc Chống đông máu',
}, {
  'id': 'T45.6',
  'english': 'Poisoning: Fibrinolysis-affecting drugs',
  'vietnamese': 'Ngộ độc Thuốc tác động phân hủy fibrin',
}, {
  'id': 'T45.7',
  'english': 'Poisoning: Anticoagulant antagonists, vitamin K and other coagulants',
  'vietnamese': 'Ngộ độc thuốc Đối kháng chống đông máu, vitamin K, chống đông máu khác',
}, {
  'id': 'T45.8',
  'english': 'Poisoning: Other primarily systemic and haematological agents',
  'vietnamese': 'Ngộ độc Thuốc dùng chủ yếu toàn thân và huyết học khác',
}, {
  'id': 'T45.9',
  'english': 'Poisoning: Primarily systemic and haematological agent, unspecified',
  'vietnamese': 'Ngộ độc Thuốc dùng chủ yếu toàn thân và huyết học chưa xác định',
}, {
  'id': 'T46',
  'english': 'Poisoning by agents primarily affecting the cardiovascular system',
  'vietnamese': 'Ngộ độc thuốc tác động chủ yếu hệ thống tim mạch',
}, {
  'id': 'T46.0',
  'english': 'Poisoning: Cardiac-stimulant glycosides and drugs of similar action',
  'vietnamese': 'Ngộ độc Glycosid kích thích tim và thuốc tác dụng tương tự',
}, {
  'id': 'T46.1',
  'english': 'Poisoning: Calcium-channel blockers',
  'vietnamese': 'Ngộ độc thuốc Chẹn calci',
}, {
  'id': 'T46.2',
  'english': 'Poisoning: Other antidysrhythmic drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc chống loạn nhịp khác, không xếp loại ở phần nào',
}, {
  'id': 'T46.3',
  'english': 'Poisoning: Coronary vasodilators, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc giãn động mạch vành, không xếp loại ở nơi khác',
}, {
  'id': 'T46.4',
  'english': 'Poisoning: Angiotensin-converting-enzyme inhibitors',
  'vietnamese': 'Ngộ độc thuốc Ức chế men chuyển đổi angiotensin',
}, {
  'id': 'T46.5',
  'english': 'Poisoning: Other antihypertensive drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc hạ huyết áp khác, không xếp loại ở nơi khác',
}, {
  'id': 'T46.6',
  'english': 'Poisoning: Antihyperlipidaemic and antiarteriosclerotic drugs',
  'vietnamese': 'Ngộ độc Thuốc hạ lipid máu và chống xơ cứng động mạch',
}, {
  'id': 'T46.7',
  'english': 'Poisoning: Peripheral vasodilators',
  'vietnamese': 'Ngộ độc thuốc Giãn động mạch ngoại vi',
}, {
  'id': 'T46.8',
  'english': 'Poisoning: Antivaricose drugs, including sclerosing agents',
  'vietnamese': 'Ngộ độc Thuốc chống giãn tĩnh mạch, kể cả thuốc xơ cứng',
}, {
  'id': 'T46.9',
  'english': 'Poisoning: Other and unspecified agents primarily affecting the cardiovascular system',
  'vietnamese': 'Ngộ độc Thuốc tác động chủ yếu hệ thống tim mạch khác và chưa xác định',
}, {
  'id': 'T47',
  'english': 'Poisoning by agents primarily affecting the gastrointestinal system',
  'vietnamese': 'Ngộ độc do thuốc tác động chủ yếu trên hệ thống tiêu hóa',
}, {
  'id': 'T47.0',
  'english': 'Poisoning: Histamine H2-receptor antagonists',
  'vietnamese': 'Ngộ độc Chất đối kháng thụ cảm Histamin H2',
}, {
  'id': 'T47.1',
  'english': 'Poisoning: Other antacids and anti-gastric-secretion drugs',
  'vietnamese': 'Ngộ độc Thuốc chống acid và chống tiết dịch vị khác',
}, {
  'id': 'T47.2',
  'english': 'Poisoning: Stimulant laxatives',
  'vietnamese': 'Ngộ độc thuốc Kích thích nhuận tràng',
}, {
  'id': 'T47.3',
  'english': 'Poisoning: Saline and osmotic laxatives',
  'vietnamese': 'Ngộ độc thuốc Nhuận tràng thẩm thấu và muối',
}, {
  'id': 'T47.4',
  'english': 'Poisoning: Other laxatives',
  'vietnamese': 'Ngộ độc thuốc Nhuận tràng khác',
}, {
  'id': 'T47.5',
  'english': 'Poisoning: Digestants',
  'vietnamese': 'Ngộ độc thuốc Lợi tiêu hóa',
}, {
  'id': 'T47.6',
  'english': 'Poisoning: Antidiarrhoeal drugs',
  'vietnamese': 'Ngộ độc Thuốc chống ỉa chảy',
}, {'id': 'T47.7', 'english': 'Poisoning: Emetics', 'vietnamese': 'Ngộ độc Chất gây nôn'}, {
  'id': 'T47.8',
  'english': 'Poisoning: Other agents primarily affecting the gastrointestinal system',
  'vietnamese': 'Ngộ độc Thuốc khác tác động chủ yếu trên hệ thống tiêu hóa',
}, {
  'id': 'T47.9',
  'english': 'Poisoning: Agent primarily affecting the gastrointestinal system, unspecified',
  'vietnamese': 'Ngộ độc Thuốc tác động chủ yếu trên hệ thống tiêu hóa, chưa xác định',
}, {
  'id': 'T48',
  'english': 'Poisoning by agents primarily acting on smooth and skeletal muscles and the respiratory system',
  'vietnamese': 'Ngộ độc thuốc tác động chủ yếu trên cơ trơn và cơ xương và hệ thống hô hấp',
}, {
  'id': 'T48.0',
  'english': 'Poisoning: Oxytocic drugs',
  'vietnamese': 'Ngộ độc Thuốc trợ đẻ',
}, {
  'id': 'T48.1',
  'english': 'Poisoning: Skeletal muscle relaxants [neuromuscular blocking agents]',
  'vietnamese': 'Ngộ độc Thuốc dãn cơ (thuốc ức chế thần kinh cơ)',
}, {
  'id': 'T48.2',
  'english': 'Poisoning: Other and unspecified agents primarily acting on muscles',
  'vietnamese': 'Ngộ độc Thuốc chủ yếu tác động trên cơ khác và chưa xác định',
}, {
  'id': 'T48.3',
  'english': 'Poisoning: Antitussives',
  'vietnamese': 'Ngộ độc thuốc Chống ho',
}, {
  'id': 'T48.4',
  'english': 'Poisoning: Expectorants',
  'vietnamese': 'Ngộ độc thuốc Long đờm',
}, {
  'id': 'T48.5',
  'english': 'Poisoning: Anti-common-cold drugs',
  'vietnamese': 'Ngộ độc Thuốc chống cảm lạnh',
}, {
  'id': 'T48.6',
  'english': 'Poisoning: Antiasthmatics, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc trị hen, không xếp loại phần nào',
}, {
  'id': 'T48.7',
  'english': 'Poisoning: Other and unspecified agents primarily acting on the respiratory system',
  'vietnamese': 'Ngộ độc Thuốc tác động chủ yếu trên hệ hô hấp khác và chưa xác định',
}, {
  'id': 'T49',
  'english': 'Poisoning by topical agents primarily affecting skin and mucous membrane and by ophthalmological, otorhinolaryngological and dental drugs',
  'vietnamese': 'Ngộ độc thuốc dùng tại chỗ tác động chủ yếu trên da và niêm mạc và do thuốc dùng chữa mắt, tai và răng',
}, {
  'id': 'T49.0',
  'english': 'Poisoning: Local antifungal, anti-infective and anti-inflammatory drugs, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc chống nấm, chống nhiễm trùng, chống viêm tại chỗ không xếp loại ở phần nào',
}, {
  'id': 'T49.1',
  'english': 'Poisoning: Antipruritics',
  'vietnamese': 'Ngộ độc Thuốc chống ngứa',
}, {
  'id': 'T49.2',
  'english': 'Poisoning: Local astringents and local detergents',
  'vietnamese': 'Ngộ độc Thuốc làm săn da tại chỗ và thuốc sát trùng tại chỗ',
}, {
  'id': 'T49.3',
  'english': 'Poisoning: Emollients, demulcents and protectants',
  'vietnamese': 'Ngộ độc Thuốc làm mềm da, làm dịu da và bảo vệ da',
}, {
  'id': 'T49.4',
  'english': 'Poisoning: Keratolytics, keratoplastics and other hair treatment drugs and preparations',
  'vietnamese': 'Ngộ độc Thuốc làm tróc lớp sừng, tạo hình lớp sừng và thuốc và các chế phẩm điều trị tóc khác',
}, {
  'id': 'T49.5',
  'english': 'Poisoning: Ophthalmological drugs and preparations',
  'vietnamese': 'Ngộ độc Thuốc và chế phẩm điều trị mắt',
}, {
  'id': 'T49.6',
  'english': 'Poisoning: Otorhinolaryngological drugs and preparations',
  'vietnamese': 'Ngộ độc Thuốc và chế phẩm điều trị tai, mũi, họng',
}, {
  'id': 'T49.7',
  'english': 'Poisoning: Dental drugs, topically applied',
  'vietnamese': 'Ngộ độc Thuốc nha khoa bôi tại chỗ',
}, {
  'id': 'T49.8',
  'english': 'Poisoning: Other topical agents',
  'vietnamese': 'Ngộ độc Thuốc dùng tại chỗ khác',
}, {
  'id': 'T49.9',
  'english': 'Poisoning: Topical agent, unspecified',
  'vietnamese': 'Ngộ độc Thuốc dùng tại chỗ, chưa xác định',
}, {
  'id': 'T50',
  'english': 'Poisoning by diuretics and other and unspecified drugs, medicaments and biological substances',
  'vietnamese': 'Ngộ độc thuốc lợi tiểu và thuốc khác, chưa xác định, thuốc và chất sinh học',
}, {
  'id': 'T50.0',
  'english': 'Poisoning: Mineralocorticoids and their antagonists',
  'vietnamese': 'Ngộ độc thuốc Mineralocorticoid và chất đối kháng',
}, {
  'id': 'T50.1',
  'english': 'Poisoning: Loop [high-ceiling] diuretics',
  'vietnamese': 'Ngộ độc thuốc Lợi niệu quai tiểu quản thận',
}, {
  'id': 'T50.2',
  'english': 'Poisoning: Carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics',
  'vietnamese': 'Ngộ độc thuốc Ức chế anhydrase carbonic, benzothladiazid và lợi tiểu khác',
}, {
  'id': 'T50.3',
  'english': 'Poisoning: Electrolytic, caloric and water-balance agents',
  'vietnamese': 'Ngộ độc Thuốc cân bằng nước, điện giải và nhiệt lượng',
}, {
  'id': 'T50.4',
  'english': 'Poisoning: Drugs affecting uric acid metabolism',
  'vietnamese': 'Ngộ độc Thuốc tác động chuyển hóa acid uric',
}, {
  'id': 'T50.5',
  'english': 'Poisoning: Appetite depressants',
  'vietnamese': 'Ngộ độc Thuốc giảm ngon miệng',
}, {
  'id': 'T50.6',
  'english': 'Poisoning: Antidotes and chelating agents, not elsewhere classified',
  'vietnamese': 'Ngộ độc Thuốc giải độc và tác nhân giải độc, không xếp loại ở phần nào',
}, {
  'id': 'T50.7',
  'english': 'Poisoning: Analeptics and opioid receptor antagonists',
  'vietnamese': 'Ngộ độc Thuốc hồi sức và đối kháng cảm thụ opioid',
}, {
  'id': 'T50.8',
  'english': 'Poisoning: Diagnostic agents',
  'vietnamese': 'Ngộ độc Thuốc chẩn đoán',
}, {
  'id': 'T50.9',
  'english': 'Poisoning: Other and unspecified drugs, medicaments and biological substances',
  'vietnamese': 'Ngộ độc Thuốc dược phẩm và chất sinh học khác và chưa xác định',
}, {
  'id': 'T51',
  'english': 'Toxic effect of alcohol',
  'vietnamese': 'Ngộ độc cồn [đồ uống có cồn]',
}, {'id': 'T51.0', 'english': 'Toxic effect: Ethanol', 'vietnamese': 'Ngộ độc Ethanol'}, {
  'id': 'T51.1',
  'english': 'Toxic effect: Methanol',
  'vietnamese': 'Ngộ độc Methanol',
}, {'id': 'T51.2', 'english': 'Toxic effect: 2-Propanol', 'vietnamese': 'Ngộ độc 2-Propanol'}, {
  'id': 'T51.3',
  'english': 'Toxic effect: Fusel oil',
  'vietnamese': 'Ngộ độc Dầu fusel (dầu rượu tạp)',
}, {'id': 'T51.8', 'english': 'Toxic effect: Other alcohols', 'vietnamese': 'Ngộ độc Cồn khác'}, {
  'id': 'T51.9',
  'english': 'Toxic effect: Alcohol, unspecified',
  'vietnamese': 'Ngộ độc Cồn chưa xác định',
}, {
  'id': 'T52',
  'english': 'Toxic effect of organic solvents',
  'vietnamese': 'Ngộ độc dung môi hữu cơ',
}, {
  'id': 'T52.0',
  'english': 'Toxic effect: Petroleum products',
  'vietnamese': 'Ngộ độc Sản phẩm dầu hoả',
}, {'id': 'T52.1', 'english': 'Toxic effect: Benzene', 'vietnamese': 'Ngộ độc Benzene'}, {
  'id': 'T52.2',
  'english': 'Toxic effect: Homologues of benzene',
  'vietnamese': 'Ngộ độc Chất đồng đẳng của benzen',
}, {'id': 'T52.3', 'english': 'Toxic effect: Glycols', 'vietnamese': 'Ngộ độc Glycol'}, {
  'id': 'T52.4',
  'english': 'Toxic effect: Ketones',
  'vietnamese': 'Ngộ độc Keton',
}, {
  'id': 'T52.8',
  'english': 'Toxic effect: Other organic solvents',
  'vietnamese': 'Ngộ độc Dung môi hữu cơ khác',
}, {
  'id': 'T52.9',
  'english': 'Toxic effect: Organic solvent, unspecified',
  'vietnamese': 'Ngộ độc Dung môi hữu cơ chưa xác định',
}, {
  'id': 'T53',
  'english': 'Toxic effect of halogen derivatives of aliphatic and aromatic hydrocarbons',
  'vietnamese': 'Ngộ độc dẫn xuất halogen của hydrocarbon béo và thơm',
}, {
  'id': 'T53.0',
  'english': 'Toxic effect: Carbon tetrachloride',
  'vietnamese': 'Ngộ độc Carbon tetrachlorid',
}, {'id': 'T53.1', 'english': 'Toxic effect: Chloroform', 'vietnamese': 'Ngộ độc Chloroform'}, {
  'id': 'T53.2',
  'english': 'Toxic effect: Trichloroethylene',
  'vietnamese': 'Ngộ độc Trichloroethylene',
}, {
  'id': 'T53.3',
  'english': 'Toxic effect: Tetrachloroethylene',
  'vietnamese': 'Ngộ độc Tetrachloroethylen',
}, {
  'id': 'T53.4',
  'english': 'Toxic effect: Dichloromethane',
  'vietnamese': 'Ngộ độc Dichloromethan',
}, {
  'id': 'T53.5',
  'english': 'Toxic effect: Chlorofluorocarbons',
  'vietnamese': 'Ngộ độc Chlorofluorocarbon',
}, {
  'id': 'T53.6',
  'english': 'Toxic effect: Other halogen derivatives of aliphatic hydrocarbons',
  'vietnamese': 'Ngộ độc Dẫn xuất halogen của hydrocarbon béo',
}, {
  'id': 'T53.7',
  'english': 'Toxic effect: Other halogen derivatives of aromatic hydrocarbons',
  'vietnamese': 'Ngộ độc Dẫn xuất halogen của hydrocarbon thơm',
}, {
  'id': 'T53.9',
  'english': 'Toxic effect: Halogen derivative of aliphatic and aromatic hydrocarbons, unspecified',
  'vietnamese': 'Ngộ độc Dẫn xuất halogen của hydrocarbon béo và hydrocarbon thơm chưa xác định',
}, {
  'id': 'T54',
  'english': 'Toxic effect of corrosive substances',
  'vietnamese': 'Ngộ độc chất ăn mòn',
}, {
  'id': 'T54.0',
  'english': 'Toxic effect: Phenol and phenol homologues',
  'vietnamese': 'Ngộ độc Phenol và chất đồng đẳng của phenol',
}, {
  'id': 'T54.1',
  'english': 'Toxic effect: Other corrosive organic compounds',
  'vietnamese': 'Ngộ độc Hợp chất hữu cơ ăn mòn khác',
}, {
  'id': 'T54.2',
  'english': 'Toxic effect: Corrosive acids and acid-like substances',
  'vietnamese': 'Ngộ độc Acid ăn mòn và chất giống acid',
}, {
  'id': 'T54.3',
  'english': 'Toxic effect: Corrosive alkalis and alkali-like substances',
  'vietnamese': 'Ngộ độc Kiềm ăn mòn và chất giống kiềm',
}, {
  'id': 'T54.9',
  'english': 'Toxic effect: Corrosive substance, unspecified',
  'vietnamese': 'Ngộ độc Chất ăn mòn, chưa xác định',
}, {
  'id': 'T55',
  'english': 'Toxic effect of soaps and detergents',
  'vietnamese': 'Ngộ độc xà phòng và chất giặt tẩy',
}, {'id': 'T56', 'english': 'Toxic effect of metals', 'vietnamese': 'Ngộ độc kim loại'}, {
  'id': 'T56.0',
  'english': 'Toxic effect: Lead and its compounds',
  'vietnamese': 'Nhiễm độc Chì và hợp chất của chì',
}, {
  'id': 'T56.1',
  'english': 'Toxic effect: Mercury and its compounds',
  'vietnamese': 'Ngộ độc Thủy ngân và hợp chất của thủy ngân',
}, {
  'id': 'T56.2',
  'english': 'Toxic effect: Chromium and its compounds',
  'vietnamese': 'Ngộ độc Crôm và hợp chất của Crom',
}, {
  'id': 'T56.3',
  'english': 'Toxic effect: Cadmium and its compounds',
  'vietnamese': 'Ngộ độc Dadmi và hợp chất của Dadmi',
}, {
  'id': 'T56.4',
  'english': 'Toxic effect: Copper and its compounds',
  'vietnamese': 'Ngộ độc Đồng và hợp chất của Đồng',
}, {
  'id': 'T56.5',
  'english': 'Toxic effect: Zinc and its compounds',
  'vietnamese': 'Ngộ độc Kẽm và hợp chất của Kẽm',
}, {
  'id': 'T56.6',
  'english': 'Toxic effect: Tin and its compounds',
  'vietnamese': 'Ngộ độc Thiếc và hợp chất của Thiếc',
}, {
  'id': 'T56.7',
  'english': 'Toxic effect: Beryllium and its compounds',
  'vietnamese': 'Ngộ độc Beryllium và hợp chất của Beryllium',
}, {
  'id': 'T56.8',
  'english': 'Toxic effect: Other metals',
  'vietnamese': 'Ngộ độc Kim loại khác',
}, {
  'id': 'T56.9',
  'english': 'Toxic effect: Metal, unspecified',
  'vietnamese': 'Ngộ độc Kim loại chưa xác định',
}, {
  'id': 'T57',
  'english': 'Toxic effect of other inorganic substances',
  'vietnamese': 'Ngộ độc chất vô cơ',
}, {
  'id': 'T57.0',
  'english': 'Toxic effect: Arsenic and its compounds',
  'vietnamese': 'Ngộ độc Arsen và hợp chất của Arsen',
}, {
  'id': 'T57.1',
  'english': 'Toxic effect: Phosphorus and its compounds',
  'vietnamese': 'Ngộ độc Phospho và hợp chất của Phospho',
}, {
  'id': 'T57.2',
  'english': 'Toxic effect: Manganese and its compounds',
  'vietnamese': 'Ngộ độc Manganes và hợp chất của Manganes',
}, {
  'id': 'T57.3',
  'english': 'Toxic effect: Hydrogen cyanide',
  'vietnamese': 'Ngộ độc Toxic effect: Hydrogen cyanide',
}, {
  'id': 'T57.8',
  'english': 'Toxic effect: Other specified inorganic substances',
  'vietnamese': 'Ngộ độc Chất vô cơ đã xác định khác',
}, {
  'id': 'T57.9',
  'english': 'Toxic effect: Inorganic substance, unspecified',
  'vietnamese': 'Ngộ độc Chất vô cơ, chưa xác định',
}, {
  'id': 'T58',
  'english': 'Toxic effect of carbon monoxide',
  'vietnamese': 'Ngộ độc carbon monoxide',
}, {
  'id': 'T59',
  'english': 'Toxic effect of other gases, fumes and vapours',
  'vietnamese': 'Ngộ độc khí khác, khói và hơi',
}, {
  'id': 'T59.0',
  'english': 'Toxic effect: Nitrogen oxides',
  'vietnamese': 'Ngộ độc Nitrogen oxid',
}, {
  'id': 'T59.1',
  'english': 'Toxic effect: Sulfur dioxide',
  'vietnamese': 'Ngộ độc Sulfur dioxid',
}, {
  'id': 'T59.2',
  'english': 'Toxic effect: Formaldehyde',
  'vietnamese': 'Ngộ độc Formaldehyd',
}, {
  'id': 'T59.3',
  'english': 'Toxic effect: Lacrimogenic gas',
  'vietnamese': 'Ngộ độc Hơi chảy nước mắt',
}, {
  'id': 'T59.4',
  'english': 'Toxic effect: Chlorine gas',
  'vietnamese': 'Ngộ độc Hơi chlorin',
}, {
  'id': 'T59.5',
  'english': 'Toxic effect: Fluorine gas and hydrogen fluoride',
  'vietnamese': 'Ngộ độc Hơi fluorin và hydrogen fluorid',
}, {
  'id': 'T59.6',
  'english': 'Toxic effect: Hydrogen sulfide',
  'vietnamese': 'Hydrogen sulfid',
}, {
  'id': 'T59.7',
  'english': 'Toxic effect: Carbon dioxide',
  'vietnamese': 'Carbon dioxid',
}, {
  'id': 'T59.8',
  'english': 'Toxic effect: Other specified gases, fumes and vapours',
  'vietnamese': 'Khí, khói hơi đã xác định khác',
}, {
  'id': 'T59.9',
  'english': 'Toxic effect: Gases, fumes and vapours, unspecified',
  'vietnamese': 'Khí, khói hơi chưa xác định',
}, {
  'id': 'T60',
  'english': 'Toxic effect of pesticides',
  'vietnamese': 'Ngộ độc thuốc trừ sâu',
}, {
  'id': 'T60.0',
  'english': 'Toxic effect: Organophosphate and carbamate insecticides',
  'vietnamese': 'Thuốc trừ sâu phosphat hữu cơ và carbamat',
}, {
  'id': 'T60.1',
  'english': 'Toxic effect: Halogenated insecticides',
  'vietnamese': 'Thuốc diệt côn trùng halogen',
}, {
  'id': 'T60.2',
  'english': 'Toxic effect: Other insecticides',
  'vietnamese': 'Thuốc diệt côn trùng khác',
}, {
  'id': 'T60.3',
  'english': 'Toxic effect: Herbicides and fungicides',
  'vietnamese': 'Thuốc diệt cỏ và thuốc diệt nấm',
}, {'id': 'T60.4', 'english': 'Toxic effect: Rodenticides', 'vietnamese': 'Thuốc diệt chuột'}, {
  'id': 'T60.8',
  'english': 'Toxic effect: Other pesticides',
  'vietnamese': 'Thuốc trừ sâu khác',
}, {
  'id': 'T60.9',
  'english': 'Toxic effect: Pesticide, unspecified',
  'vietnamese': 'Thuốc trừ sâu chưa xác định',
}, {
  'id': 'T61',
  'english': 'Toxic effect of noxious substances eaten as seafood',
  'vietnamese': 'Ngộ độc các chất độc do ăn đồ biển',
}, {
  'id': 'T61.0',
  'english': 'Toxic effect: Ciguatera fish poisoning',
  'vietnamese': 'Ngộ độc cá Ciguatera',
}, {
  'id': 'T61.1',
  'english': 'Toxic effect: Scombroid fish poisoning',
  'vietnamese': 'Ngộ độc cá Scombroid',
}, {
  'id': 'T61.2',
  'english': 'Toxic effect: Other fish and shellfish poisoning',
  'vietnamese': 'Ngộ độc cá khác và sò ốc',
}, {
  'id': 'T61.8',
  'english': 'Toxic effect: Toxic effect of other seafoods',
  'vietnamese': 'Ngộ độc hải sản khác',
}, {
  'id': 'T61.9',
  'english': 'Toxic effect: Toxic effect of unspecified seafood',
  'vietnamese': 'Ngộ độc hải sản chưa phân loại nơi khác',
}, {
  'id': 'T62',
  'english': 'Toxic effect of other noxious substances eaten as food',
  'vietnamese': 'Ngộ độc thức ăn khác',
}, {'id': 'T62.0', 'english': 'Toxic effect: Ingested mushrooms', 'vietnamese': 'Ăn nấm'}, {
  'id': 'T62.1',
  'english': 'Toxic effect: Ingested berries',
  'vietnamese': 'Ăn quả dâu',
}, {
  'id': 'T62.2',
  'english': 'Toxic effect: Other ingested (parts of) plant(s)',
  'vietnamese': 'Ăn phần khác của cây',
}, {
  'id': 'T62.8',
  'english': 'Toxic effect: Other specified noxious substances eaten as food',
  'vietnamese': 'Ăn chất độc khác đã xác định như thực phẩm',
}, {
  'id': 'T62.9',
  'english': 'Toxic effect: Noxious substance eaten as food, unspecified',
  'vietnamese': 'Ăn chất độc như thực phẩm chưa xác định',
}, {
  'id': 'T63',
  'english': 'Toxic effect of contact with venomous animals',
  'vietnamese': 'Tác dụng độc do tiếp xúc với động vật tiết nọc độc',
}, {'id': 'T63.0', 'english': 'Toxic effect: Snake venom', 'vietnamese': 'Nọc độc rắn'}, {
  'id': 'T63.1',
  'english': 'Toxic effect: Venom of other reptiles',
  'vietnamese': 'Nọc độc của loài bò sát khác',
}, {
  'id': 'T63.2',
  'english': 'Toxic effect: Venom of scorpion',
  'vietnamese': 'Nọc độc của bọ cạp',
}, {'id': 'T63.3', 'english': 'Toxic effect: Venom of spider', 'vietnamese': 'Nọc độc nhện'}, {
  'id': 'T63.4',
  'english': 'Toxic effect: Venom of other arthropods',
  'vietnamese': 'Nọc độc của các tiết túc khác',
}, {
  'id': 'T63.5',
  'english': 'Toxic effect: Toxic effect of contact with fish',
  'vietnamese': 'Tác dụng độc do tiếp xúc cá',
}, {
  'id': 'T63.6',
  'english': 'Toxic effect: Toxic effect of contact with other marine animals',
  'vietnamese': 'Tác dụng độc do tiếp xúc với động vật biển khác',
}, {
  'id': 'T63.8',
  'english': 'Toxic effect: Toxic effect of contact with other venomous animals',
  'vietnamese': 'Tác dụng độc do tiếp xúc với xúc vật tiết nọc độc khác',
}, {
  'id': 'T63.9',
  'english': 'Toxic effect: Toxic effect of contact with unspecified venomous animal',
  'vietnamese': 'Tác dụng độc do tiếp xúc vật tiết nọc độc chưa xác định',
}, {
  'id': 'T64',
  'english': 'Toxic effect of aflatoxin and other mycotoxin food contaminants',
  'vietnamese': 'Tác dụng độc do thực phẩm nhiễm aflatoxin và độc tố nấm khác',
}, {
  'id': 'T65',
  'english': 'Toxic effect of other and unspecified substances',
  'vietnamese': 'Tác dụng độc do chất khác và chưa xác định',
}, {'id': 'T65.0', 'english': 'Toxic effect: Cyanides', 'vietnamese': 'Cyanid'}, {
  'id': 'T65.1',
  'english': 'Toxic effect: Strychnine and its salts',
  'vietnamese': 'Strychnin và muối của nó',
}, {
  'id': 'T65.2',
  'english': 'Toxic effect: Tobacco and nicotine',
  'vietnamese': 'Thuốc lá và nicotin',
}, {
  'id': 'T65.3',
  'english': 'Toxic effect: Nitroderivatives and aminoderivatives of benzene and its homologues',
  'vietnamese': 'Dẫn xuất nitro và amino của benzen và chất đồng đẳng',
}, {
  'id': 'T65.4',
  'english': 'Toxic effect: Carbon disulfide',
  'vietnamese': 'Carbon disulfid',
}, {
  'id': 'T65.5',
  'english': 'Toxic effect: Nitroglycerin and other nitric acids and esters',
  'vietnamese': 'Nitroglycerin và acid nitric và ester khác',
}, {
  'id': 'T65.6',
  'english': 'Toxic effect: Paints and dyes, not elsewhere classified',
  'vietnamese': 'Sơn và thuốc nhuộm, không xếp loại ở nơi khác',
}, {
  'id': 'T65.8',
  'english': 'Toxic effect: Toxic effect of other specified substances',
  'vietnamese': 'Ngộ độc các chất đã xác định khác',
}, {
  'id': 'T65.9',
  'english': 'Toxic effect: Toxic effect of unspecified substance',
  'vietnamese': 'Ngộ độc các chất chưa xác định',
}, {
  'id': 'T66',
  'english': 'Unspecified effects of radiation',
  'vietnamese': 'Tác dụng chưa xác định của tia xạ',
}, {
  'id': 'T67',
  'english': 'Effects of heat and light',
  'vietnamese': 'Tác dụng do nhiệt và ánh sáng',
}, {
  'id': 'T67.0',
  'english': 'Heatstroke and sunstroke',
  'vietnamese': 'Đột quỵ do nhiệt và say nắng',
}, {'id': 'T67.1', 'english': 'Heat syncope', 'vietnamese': 'Ngất do nhiệt'}, {
  'id': 'T67.2',
  'english': 'Heat cramp',
  'vietnamese': 'Chuột rút do nhiệt',
}, {
  'id': 'T67.3',
  'english': 'Heat exhaustion, anhydrotic',
  'vietnamese': 'Suy kiệt do nhiệt, giảm tiết mồ hôi',
}, {
  'id': 'T67.4',
  'english': 'Heat exhaustion due to salt depletion',
  'vietnamese': 'Suy kiệt do nhiệt bởi mất muối',
}, {
  'id': 'T67.5',
  'english': 'Heat exhaustion, unspecified',
  'vietnamese': 'Suy kiệt do nhiệt, chưa xác định',
}, {
  'id': 'T67.6',
  'english': 'Heat fatigue, transient',
  'vietnamese': 'Mệt do nhiệt, nhất thời',
}, {
  'id': 'T67.7',
  'english': 'Heat oedema',
  'vietnamese': 'Phù do nhiệt',
}, {
  'id': 'T67.8',
  'english': 'Other effects of heat and light',
  'vietnamese': 'Tác động khác của nhiệt và ánh sáng',
}, {
  'id': 'T67.9',
  'english': 'Effect of heat and light, unspecified',
  'vietnamese': 'Tác động nhiệt và ánh sáng, chưa xác định',
}, {'id': 'T68', 'english': 'Hypothermia', 'vietnamese': 'Hạ nhiệt độ'}, {
  'id': 'T69',
  'english': 'Other effects of reduced temperature',
  'vietnamese': 'Tác dụng khác của giảm nhiệt độ',
}, {
  'id': 'T69.0',
  'english': 'Immersion hand and foot',
  'vietnamese': 'Ngâm nước bàn tay và bàn chân',
}, {'id': 'T69.1', 'english': 'Chilblains', 'vietnamese': 'Bệnh cước'}, {
  'id': 'T69.8',
  'english': 'Other specified effects of reduced temperature',
  'vietnamese': 'Tác dụng xác định khác của hạ nhiệt',
}, {
  'id': 'T69.9',
  'english': 'Effect of reduced temperature, unspecified',
  'vietnamese': 'Tác dụng hạ nhiệt, chưa xác định',
}, {
  'id': 'T70',
  'english': 'Effects of air pressure and water pressure',
  'vietnamese': 'Tác động của áp lực không khí và áp lực nước',
}, {
  'id': 'T70.0',
  'english': 'Otitic barotrauma',
  'vietnamese': 'Viêm tai do chấn thương khí áp',
}, {
  'id': 'T70.1',
  'english': 'Sinus barotrauma',
  'vietnamese': 'Viêm xoang do chấn thương khí áp',
}, {
  'id': 'T70.2',
  'english': 'Other and unspecified effects of high altitude',
  'vietnamese': 'Tác dụng khác và chưa xác định của độ cao',
}, {
  'id': 'T70.3',
  'english': 'Caisson disease [decompression sickness]',
  'vietnamese': 'Bệnh do thùng lặn (bệnh lý do giảm áp)',
}, {
  'id': 'T70.4',
  'english': 'Effects of high-pressure fluids',
  'vietnamese': 'Tác động của áp lực dịch cao',
}, {
  'id': 'T70.8',
  'english': 'Other effects of air pressure and water pressure',
  'vietnamese': 'Tác động khác của áp lực khí và áp lực nước',
}, {
  'id': 'T70.9',
  'english': 'Effect of air pressure and water pressure, unspecified',
  'vietnamese': 'Tác động áp lực khí và áp lực nước chưa xác định',
}, {'id': 'T71', 'english': 'Asphyxiation', 'vietnamese': 'Ngạt thở'}, {
  'id': 'T73',
  'english': 'Effects of other deprivation',
  'vietnamese': 'Tác động của các thiếu hụt khác',
}, {'id': 'T73.0', 'english': 'Effects of hunger', 'vietnamese': 'Tác động của đói'}, {
  'id': 'T73.1',
  'english': 'Effects of thirst',
  'vietnamese': 'Tác động của khát',
}, {
  'id': 'T73.2',
  'english': 'Exhaustion due to exposure',
  'vietnamese': 'Kiệt sức do phơi nhiễm',
}, {
  'id': 'T73.3',
  'english': 'Exhaustion due to excessive exertion',
  'vietnamese': 'Kiệt sức do cố gắng quá sức',
}, {
  'id': 'T73.8',
  'english': 'Other effects of deprivation',
  'vietnamese': 'Tác động khác của thiếu hụt',
}, {
  'id': 'T73.9',
  'english': 'Effect of deprivation, unspecified',
  'vietnamese': 'Tác dụng thiếu hụt chưa xác định',
}, {
  'id': 'T74',
  'english': 'Maltreatment syndromes',
  'vietnamese': 'Hội chứng đối xử sai trái',
}, {
  'id': 'T74.0',
  'english': 'Neglect or abandonment',
  'vietnamese': 'Sao nhãng hoặc từ bỏ',
}, {'id': 'T74.1', 'english': 'Physical abuse', 'vietnamese': 'Lạm dụng thể lực'}, {
  'id': 'T74.2',
  'english': 'Sexual abuse',
  'vietnamese': 'Lạm dụng tình dục',
}, {'id': 'T74.3', 'english': 'Psychological abuse', 'vietnamese': 'Lạm dụng tâm lý học'}, {
  'id': 'T74.8',
  'english': 'Other maltreatment syndromes',
  'vietnamese': 'Hội chứng đối xử sai trái khác',
}, {
  'id': 'T74.9',
  'english': 'Maltreatment syndrome, unspecified',
  'vietnamese': 'Hội chứng đối xử sai trái, chưa xác định',
}, {
  'id': 'T75',
  'english': 'Effects of other external causes',
  'vietnamese': 'Tác động của căn nguyên bên ngoài',
}, {
  'id': 'T75.0',
  'english': 'Effects of lightning',
  'vietnamese': 'Tác động của tia chớp sét',
}, {
  'id': 'T75.1',
  'english': 'Drowning and nonfatal submersion',
  'vietnamese': 'Đuối và bị nhấn chìm không chết',
}, {'id': 'T75.2', 'english': 'Effects of vibration', 'vietnamese': 'Tác động của chấn động'}, {
  'id': 'T75.3',
  'english': 'Motion sickness',
  'vietnamese': 'Chuyển động gây bệnh',
}, {
  'id': 'T75.4',
  'english': 'Effects of electric current',
  'vietnamese': 'Tác động của dòng điện',
}, {
  'id': 'T75.8',
  'english': 'Other specified effects of external causes',
  'vietnamese': 'Tác động đã xác định khác của căn nguyên bên ngoài',
}, {
  'id': 'T78',
  'english': 'Adverse effects, not elsewhere classified',
  'vietnamese': 'Tác động phụ không xếp loại ở nơi khác',
}, {
  'id': 'T78.0',
  'english': 'Anaphylactic shock due to adverse food reaction',
  'vietnamese': 'choáng phản vệ do phản ứng phụ của thực phẩm',
}, {
  'id': 'T78.1',
  'english': 'Other adverse food reactions, not elsewhere classified',
  'vietnamese': 'Phản ứng phụ khác của thực phẩm không xếp loại ở nơi khác',
}, {
  'id': 'T78.2',
  'english': 'Anaphylactic shock, unspecified',
  'vietnamese': 'choáng phản vệ, chưa xác định',
}, {
  'id': 'T78.3',
  'english': 'Angioneurotic oedema',
  'vietnamese': 'Phù nề do rối loạn thần kinh huyết quản',
}, {'id': 'T78.4', 'english': 'Allergy, unspecified', 'vietnamese': 'Dị ứng chưa xác định'}, {
  'id': 'T78.8',
  'english': 'Other adverse effects, not elsewhere classified',
  'vietnamese': 'Tác dụng phụ khác, không xếp loại ở nơi khác',
}, {
  'id': 'T78.9',
  'english': 'Adverse effect, unspecified',
  'vietnamese': 'Tác dụng phụ chưa xác định',
}, {
  'id': 'T79',
  'english': 'Certain early complications of trauma, not elsewhere classified',
  'vietnamese': 'Một vài biến chứng sớm của chấn thương không xếp loại ở nơi khác',
}, {
  'id': 'T79.0',
  'english': 'Air embolism (traumatic)',
  'vietnamese': 'Nghẽn mạch không khí (chấn thương)',
}, {
  'id': 'T79.1',
  'english': 'Fat embolism (traumatic)',
  'vietnamese': 'Nghẽn mạch mỡ (chấn thương)',
}, {
  'id': 'T79.2',
  'english': 'Traumatic secondary and recurrent haemorrhage',
  'vietnamese': 'Chấn thương thứ phát và chảy máu tái phát',
}, {
  'id': 'T79.3',
  'english': 'Post-traumatic wound infection, not elsewhere classified',
  'vietnamese': 'Nhiễm trùng vết thương sau chấn thương, không xếp loại ở nơi khác',
}, {'id': 'T79.4', 'english': 'Traumatic shock', 'vietnamese': 'choáng chấn thương'}, {
  'id': 'T79.5',
  'english': 'Traumatic anuria',
  'vietnamese': 'Vô niệu do chấn thương',
}, {
  'id': 'T79.6',
  'english': 'Traumatic ischaemia of muscle',
  'vietnamese': 'Thiếu máu của cơ do chấn thương',
}, {
  'id': 'T79.7',
  'english': 'Traumatic subcutaneous emphysema',
  'vietnamese': 'Khí thũng do chấn thương',
}, {
  'id': 'T79.8',
  'english': 'Other early complications of trauma',
  'vietnamese': 'Biến chứng sớm khác của chấn thương',
}, {
  'id': 'T79.9',
  'english': 'Unspecified early complication of trauma',
  'vietnamese': 'Biến chứng sớm chưa xác định của chấn thương',
}, {
  'id': 'T80',
  'english': 'Complications following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Biến chứng huyết quản sau tiêm truyền, truyền máu và tiêm thuốc',
}, {
  'id': 'T80.0',
  'english': 'Air embolism following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Nghẽn mạch không khí sau tiêm truyền, truyền máu và tiêm thuốc',
}, {
  'id': 'T80.1',
  'english': 'Vascular complications following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Biến chứng huyết quản sau tiêm truyền, truyền máu và tiêm thuốc',
}, {
  'id': 'T80.2',
  'english': 'Infections following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Nhiễm trùng sau tiêm truyền, truyền máu và tiêm thuốc',
}, {
  'id': 'T80.3',
  'english': 'ABO incompatibility reaction',
  'vietnamese': 'Phản ứng tương tác ABO',
}, {
  'id': 'T80.4',
  'english': 'Rh incompatibility reaction',
  'vietnamese': 'Phản ứng tương tác Rh',
}, {
  'id': 'T80.5',
  'english': 'Anaphylactic shock due to serum',
  'vietnamese': 'choáng phản vệ do huyết thanh',
}, {
  'id': 'T80.6',
  'english': 'Other serum reactions',
  'vietnamese': 'Phản ứng huyết thanh khác',
}, {
  'id': 'T80.8',
  'english': 'Other complications following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Biến chứng khác sau tiêm truyền, truyền máu và tiêm thuốc',
}, {
  'id': 'T80.9',
  'english': 'Unspecified complication following infusion, transfusion and therapeutic injection',
  'vietnamese': 'Biến chứng khác sau tiêm truyền, truyền máu và tiêm thuốc chưa xác định',
}, {
  'id': 'T81',
  'english': 'Complications of procedures, not elsewhere classified',
  'vietnamese': 'Biến chứng của thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.0',
  'english': 'Haemorrhage and haematoma complicating a procedure, not elsewhere classified',
  'vietnamese': 'Biến chứng chảy máu và tụ máu do thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.1',
  'english': 'Shock during or resulting from a procedure, not elsewhere classified',
  'vietnamese': 'choáng kéo dài hoặc hậu quả từ thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.2',
  'english': 'Accidental puncture and laceration during a procedure, not elsewhere classified',
  'vietnamese': 'Tai biến thủng và rách trong thủ thuật không xếp loại ở nơi khác',
}, {
  'id': 'T81.3',
  'english': 'Disruption of operation wound, not elsewhere classified',
  'vietnamese': 'Thủng của vết mổ, không xếp loại ở nơi khác',
}, {
  'id': 'T81.4',
  'english': 'Infection following a procedure, not elsewhere classified',
  'vietnamese': 'Nhiễm trùng sau thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.5',
  'english': 'Foreign body accidentally left in body cavity or operation wound following a procedure',
  'vietnamese': 'Vật lạ do vô ý để lại trong khoang cơ thể hoặc vết mổ sau khi thủ thuật',
}, {
  'id': 'T81.6',
  'english': 'Acute reaction to foreign substance accidentally left during a procedure',
  'vietnamese': 'Phản ứng cấp tính do vật lạ vô ý để lại trong khi thủ thuật',
}, {
  'id': 'T81.7',
  'english': 'Vascular complications following a procedure, not elsewhere classified',
  'vietnamese': 'Biến chứng huyết quản sau thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.8',
  'english': 'Other complications of procedures, not elsewhere classified',
  'vietnamese': 'Biến chứng khác của thủ thuật, không xếp loại ở nơi khác',
}, {
  'id': 'T81.9',
  'english': 'Unspecified complication of procedure',
  'vietnamese': 'Biến chứng sau thủ thuật chưa xác định',
}, {
  'id': 'T82',
  'english': 'Complications of cardiac and vascular prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng các thiết bị nhân tạo của tim và huyết quản, cấy và ghép',
}, {
  'id': 'T82.0',
  'english': 'Mechanical complication of heart valve prosthesis',
  'vietnamese': 'biến chứng cơ học của van tim nhân tạo',
}, {
  'id': 'T82.1',
  'english': 'Mechanical complication of cardiac electronic device',
  'vietnamese': 'Biến chứng cơ học của thiết bị điện tử tại tim',
}, {
  'id': 'T82.2',
  'english': 'Mechanical complication of coronary artery bypass and valve grafts',
  'vietnamese': 'Biến chứng cơ học của đường thay thế động mạch vành và van ghép',
}, {
  'id': 'T82.3',
  'english': 'Mechanical complication of other vascular grafts',
  'vietnamese': 'Biến chứng cơ học của ghép huyết quản khác',
}, {
  'id': 'T82.4',
  'english': 'Mechanical complication of vascular dialysis catheter',
  'vietnamese': 'Biến chứng cơ học của ống thông thẩm tách huyết quản',
}, {
  'id': 'T82.5',
  'english': 'Mechanical complication of other cardiac and vascular devices and implants',
  'vietnamese': 'Biến chứng cơ học của thiết bị tim mạch và cấy ghép khác',
}, {
  'id': 'T82.6',
  'english': 'Infection and inflammatory reaction due to cardiac valve prosthesis',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do van tim nhân tạo',
}, {
  'id': 'T82.7',
  'english': 'Infection and inflammatory reaction due to other cardiac and vascular devices, implants and grafts',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị tim mạch cấy ghép khác',
}, {
  'id': 'T82.8',
  'english': 'Other complications of cardiac and vascular prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng khác của thiết bị tim và huyết quản nhân tạo, cấy và ghép',
}, {
  'id': 'T82.9',
  'english': 'Unspecified complication of cardiac and vascular prosthetic device, implant and graft',
  'vietnamese': 'Unspecified complication of cardiac and vascular prosthetic device, implant and graft',
}, {
  'id': 'T83',
  'english': 'Complications of genitourinary prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng của thiết bị nhân tạo cơ quan tiết niệu sinh dục, cấy và ghép',
}, {
  'id': 'T83.0',
  'english': 'Mechanical complication of urinary (indwelling) catheter',
  'vietnamese': 'Biến chứng cơ học của ống thông nước tiểu',
}, {
  'id': 'T83.1',
  'english': 'Mechanical complication of other urinary devices and implants',
  'vietnamese': 'Biến chứng cơ học của thiết bị tiết niệu khác và cấy',
}, {
  'id': 'T83.2',
  'english': 'Mechanical complication of graft of urinary organ',
  'vietnamese': 'Biến chứng cơ học của ghép cơ quan tiết niệu',
}, {
  'id': 'T83.3',
  'english': 'Mechanical complication of intrauterine contraceptive device',
  'vietnamese': 'Biến chứng cơ học của thiết bị tránh thai trong tử cung',
}, {
  'id': 'T83.4',
  'english': 'Mechanical complication of other prosthetic devices, implants and grafts in genital tract',
  'vietnamese': 'Biến chứng cơ học của thiết bị nhân tạo khác, cấy và ghép trong đường sinh dục',
}, {
  'id': 'T83.5',
  'english': 'Infection and inflammatory reaction due to prosthetic device, implant and graft in urinary system',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị nhân tạo cấy và ghép hệ thống tiết niệu',
}, {
  'id': 'T83.6',
  'english': 'Infection and inflammatory reaction due to prosthetic device, implant and graft in genital tract',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị nhân tạo cấy và ghép đường sinh dục',
}, {
  'id': 'T83.8',
  'english': 'Other complications of genitourinary prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng của thiết bị nhân tạo tiết niệu sinh dục khác cấy và ghép',
}, {
  'id': 'T83.9',
  'english': 'Unspecified complication of genitourinary prosthetic device, implant and graft',
  'vietnamese': 'Biến chứng chưa xác định của thiết bị tiết niệu sinh dục nhân tạo, cấy và ghép',
}, {
  'id': 'T84',
  'english': 'Complications of internal orthopaedic prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng của thiết bị chỉnh hình bên trong, cấy và ghép',
}, {
  'id': 'T84.0',
  'english': 'Mechanical complication of internal joint prosthesis',
  'vietnamese': 'Biến chứng cơ học của khớp nhân tạo bên trong',
}, {
  'id': 'T84.1',
  'english': 'Mechanical complication of internal fixation device of bones of limb',
  'vietnamese': 'Biến chứng cơ học của thiết bị cố định bên trong xương các chi',
}, {
  'id': 'T84.2',
  'english': 'Mechanical complication of internal fixation device of other bones',
  'vietnamese': 'Biến chứng cơ học của thiết bị cố định bên trong xương khác',
}, {
  'id': 'T84.3',
  'english': 'Mechanical complication of other bone devices, implants and grafts',
  'vietnamese': 'Biến chứng cơ học của thiết bị xương khác cấy và ghép',
}, {
  'id': 'T84.4',
  'english': 'Mechanical complication of other internal orthopaedic devices, implants and grafts',
  'vietnamese': 'Biến chứng cơ học của thiết bị chỉnh hình bên trong khác, cấy và ghép',
}, {
  'id': 'T84.5',
  'english': 'Infection and inflammatory reaction due to internal joint prosthesis',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do khớp nhân tạo bên trong',
}, {
  'id': 'T84.6',
  'english': 'Infection and inflammatory reaction due to internal fixation device [any site]',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị nhân tạo chỉnh hình bên trong (bất kỳ vị trí nào)',
}, {
  'id': 'T84.7',
  'english': 'Infection and inflammatory reaction due to other internal orthopaedic prosthetic devices, implants and grafts',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị nhân tạo chỉnh hình bên trong khác cấy và ghép',
}, {
  'id': 'T84.8',
  'english': 'Other complications of internal orthopaedic prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng khác của thiết bị nhân tạo chỉnh hình bên trong cấy và ghép',
}, {
  'id': 'T84.9',
  'english': 'Unspecified complication of internal orthopaedic prosthetic device, implant and graft',
  'vietnamese': 'Biến chứng chưa xác định của thiết bị nhân tạo chỉnh hình bên trong, cấy và ghép',
}, {
  'id': 'T85',
  'english': 'Complications of other internal prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng của thiết bị nhân tạo bên trong khác, cấy và ghép',
}, {
  'id': 'T85.0',
  'english': 'Mechanical complication of ventricular intracranial (communicating) shunt',
  'vietnamese': 'Biến chứng cơ học của (lưu thông) não thất nội sọ chuyển hướng',
}, {
  'id': 'T85.1',
  'english': 'Mechanical complication of implanted electronic stimulator of nervous system',
  'vietnamese': 'Biến chứng cơ học của cấy kích thích điện tử của hệ thần kinh',
}, {
  'id': 'T85.2',
  'english': 'Mechanical complication of intraocular lens',
  'vietnamese': 'Biến chứng cơ học của thủy tinh thể trong nội nhãn cầu',
}, {
  'id': 'T85.3',
  'english': 'Mechanical complication of other ocular prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng cơ học của thiết bị mắt nhân tạo khác cấy và ghép',
}, {
  'id': 'T85.4',
  'english': 'Mechanical complication of breast prosthesis and implant',
  'vietnamese': 'Biến chứng cơ của vú nhân tạo và ghép',
}, {
  'id': 'T85.5',
  'english': 'Mechanical complication of gastrointestinal prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng cơ học của thiết bị nhân tạo dạ dày ruột cấy và ghép',
}, {
  'id': 'T85.6',
  'english': 'Mechanical complication of other specified internal prosthetic devices, implants and grafts',
  'vietnamese': 'Biến chứng cơ học thiết bị nhân tạo đặc hiệu bên trong khác, cấy và ghép',
}, {
  'id': 'T85.7',
  'english': 'Infection and inflammatory reaction due to other internal prosthetic devices, implants and grafts',
  'vietnamese': 'Nhiễm trùng và phản ứng viêm do thiết bị nhân tạo bên trong khác cấy và ghép',
}, {
  'id': 'T85.8',
  'english': 'Other complications of internal prosthetic devices, implants and grafts, not elsewhere classified',
  'vietnamese': 'Biến chứng khác của thiết bị nhân tạo bên trong cấy và ghép không xếp loại ở nơi khác',
}, {
  'id': 'T85.9',
  'english': 'Unspecified complication of internal prosthetic device, implant and graft',
  'vietnamese': 'Biến chứng của thiết bị nhân tạo bên trong, cấy và ghép không xác định khác',
}, {
  'id': 'T86',
  'english': 'Failure and rejection of transplanted organs and tissues',
  'vietnamese': 'Thất bại và thải bỏ ghép phủ tạng và tổ chức',
}, {
  'id': 'T86.0',
  'english': 'Bone-marrow transplant rejection',
  'vietnamese': 'Thải bỏ ghép tủy xương',
}, {
  'id': 'T86.1',
  'english': 'Kidney transplant failure and rejection',
  'vietnamese': 'Thất bại và thải bỏ ghép thận',
}, {
  'id': 'T86.2',
  'english': 'Heart transplant failure and rejection',
  'vietnamese': 'Thất bại và thải bỏ ghép tim',
}, {
  'id': 'T86.3',
  'english': 'Heart-lung transplant failure and rejection',
  'vietnamese': 'Thất bại và thải bỏ ghép tim phổi',
}, {
  'id': 'T86.4',
  'english': 'Liver transplant failure and rejection',
  'vietnamese': 'Thất bại và thải bỏ các ghép phủ tạng và tổ chức khác',
}, {
  'id': 'T86.8',
  'english': 'Failure and rejection of other transplanted organs and tissues',
  'vietnamese': 'Thất bại và thải bỏ ghép gan',
}, {
  'id': 'T86.9',
  'english': 'Failure and rejection of unspecified transplanted organ and tissue',
  'vietnamese': 'Thất bại và thải bỏ ghép phủ tạng và tổ chức chưa xác định',
}, {
  'id': 'T87',
  'english': 'Complications peculiar to reattachment and amputation',
  'vietnamese': 'Biến chứng nối lại và cắt cụt',
}, {
  'id': 'T87.0',
  'english': 'Complications of reattached (part of) upper extremity',
  'vietnamese': 'Biến chứng nối lại (phần của) chi trên',
}, {
  'id': 'T87.1',
  'english': 'Complications of reattached (part of) lower extremity',
  'vietnamese': 'Biến chứng nối lại (phần của) chi dưới',
}, {
  'id': 'T87.2',
  'english': 'Complications of other reattached body part',
  'vietnamese': 'Biến chứng nối lại phần của cơ thể',
}, {
  'id': 'T87.3',
  'english': 'Neuroma of amputation stump',
  'vietnamese': 'U thần kinh của mỏm cắt cụt',
}, {
  'id': 'T87.4',
  'english': 'Infection of amputation stump',
  'vietnamese': 'Nhiễm trùng của mỏm cắt cụt',
}, {
  'id': 'T87.5',
  'english': 'Necrosis of amputation stump',
  'vietnamese': 'Hoại tử của mỏm cắt cụt',
}, {
  'id': 'T87.6',
  'english': 'Other and unspecified complications of amputation stump',
  'vietnamese': 'Biến chứng khác chưa xác định của mỏm cắt cụt',
}, {
  'id': 'T88',
  'english': 'Other complications of surgical and medical care, not elsewhere classified',
  'vietnamese': 'Biến chứng khác của phẫu thuật và chăm sóc y tế không xếp loại ở nơi khác',
}, {
  'id': 'T88.0',
  'english': 'Infection following immunization',
  'vietnamese': 'Nhiễm trùng sau gây miễn dịch',
}, {
  'id': 'T88.1',
  'english': 'Other complications following immunization, not elsewhere classified',
  'vietnamese': 'Biến chứng khác sau gây miễn dịch, không xếp loại ở nơi khác',
}, {'id': 'T88.2', 'english': 'Shock due to anaesthesia', 'vietnamese': 'choáng do gây mê'}, {
  'id': 'T88.3',
  'english': 'Malignant hyperthermia due to anaesthesia',
  'vietnamese': 'Sốt cao ác tính do gây mê',
}, {
  'id': 'T88.4',
  'english': 'Failed or difficult intubation',
  'vietnamese': 'Thất bại hoặc khó đặt khí quản',
}, {
  'id': 'T88.5',
  'english': 'Other complications of anaesthesia',
  'vietnamese': 'Biến chứng khác của gây mê',
}, {
  'id': 'T88.6',
  'english': 'Anaphylactic shock due to adverse effect of correct drug or medicament properly administered',
  'vietnamese': 'choáng phản vệ do tác dụng phụ của thuốc hoặc dược phẩm thích hợp và sử dụng đúng quy cách',
}, {
  'id': 'T88.7',
  'english': 'Unspecified adverse effect of drug or medicament',
  'vietnamese': 'Tác dụng phụ chưa xác định của thuốc hoặc dược phẩm',
}, {
  'id': 'T88.8',
  'english': 'Other specified complications of surgical and medical care, not elsewhere classified',
  'vietnamese': 'Biến chứng đã xác định của phẫu thuật và chăm sóc y tế không xếp loại ở nơi khác',
}, {
  'id': 'T88.9',
  'english': 'Complication of surgical and medical care, unspecified',
  'vietnamese': 'Biến chứng phẫu thuật và chăm sóc y tế, chưa xác định',
}, {
  'id': 'T90',
  'english': 'Sequelae of injuries of head',
  'vietnamese': 'Di chứng tổn thương đầu',
}, {
  'id': 'T90.0',
  'english': 'Sequelae of superficial injury of head',
  'vietnamese': 'Di chứng tổn thương nông ở đầu',
}, {
  'id': 'T90.1',
  'english': 'Sequelae of open wound of head',
  'vietnamese': 'Di chứng vết thương hở ở đầu',
}, {
  'id': 'T90.2',
  'english': 'Sequelae of fracture of skull and facial bones',
  'vietnamese': 'Di chứng gẫy xương sọ và xương mặt',
}, {
  'id': 'T90.3',
  'english': 'Sequelae of injury of cranial nerves',
  'vietnamese': 'Di chứng tổn thương các dây thần kinh sọ não',
}, {
  'id': 'T90.4',
  'english': 'Sequelae of injury of eye and orbit',
  'vietnamese': 'Di chứng tổn thương ở mắt và hố mắt',
}, {
  'id': 'T90.5',
  'english': 'Sequelae of intracranial injury',
  'vietnamese': 'Di chứng tổn thương nội sọ',
}, {
  'id': 'T90.8',
  'english': 'Sequelae of other specified injuries of head',
  'vietnamese': 'Di chứng tổn thương khác đã xác định ở đầu',
}, {
  'id': 'T90.9',
  'english': 'Sequelae of unspecified injury of head',
  'vietnamese': 'Di chứng tổn thương chưa xác định ở đầu',
}, {
  'id': 'T91',
  'english': 'Sequelae of injuries of neck and trunk',
  'vietnamese': 'Di chứng tổn thương cổ và thân mình',
}, {
  'id': 'T91.0',
  'english': 'Sequelae of superficial injury and open wound of neck and trunk',
  'vietnamese': 'Di chứng tổn thương nông và vết thương hở tại cổ và thân',
}, {
  'id': 'T91.1',
  'english': 'Sequelae of fracture of spine',
  'vietnamese': 'Di chứng gẫy xương cột sống',
}, {
  'id': 'T91.2',
  'english': 'Sequelae of other fracture of thorax and pelvis',
  'vietnamese': 'Di chứng gẫy xương ngực và khung chậu khác',
}, {
  'id': 'T91.3',
  'english': 'Sequelae of injury of spinal cord',
  'vietnamese': 'Di chứng tổn thương tủy sống',
}, {
  'id': 'T91.4',
  'english': 'Sequelae of injury of intrathoracic organs',
  'vietnamese': 'Di chứng tổn thương nội tạng trong lồng ngực',
}, {
  'id': 'T91.5',
  'english': 'Sequelae of injury of intra-abdominal and pelvic organs',
  'vietnamese': 'Di chứng tổn thương nội tạng trong bụng và khung chậu',
}, {
  'id': 'T91.8',
  'english': 'Sequelae of other specified injuries of neck and trunk',
  'vietnamese': 'Di chứng tổn thương khác đã xác định tại cổ và thân',
}, {
  'id': 'T91.9',
  'english': 'Sequelae of unspecified injury of neck and trunk',
  'vietnamese': 'Di chứng tổn thương chưa xác định tại cổ và thân',
}, {
  'id': 'T92',
  'english': 'Sequelae of injuries of upper limb',
  'vietnamese': 'Di chứng tổn thương chi trên',
}, {
  'id': 'T92.0',
  'english': 'Sequelae of open wound of upper limb',
  'vietnamese': 'Di chứng vết thương hở chi trên-',
}, {
  'id': 'T92.1',
  'english': 'Sequelae of fracture of arm',
  'vietnamese': 'Di chứng gẫy xương tay',
}, {
  'id': 'T92.2',
  'english': 'Sequelae of fracture at wrist and hand level',
  'vietnamese': 'Di chứng gẫy xương tầm cổ tay và bàn tay',
}, {
  'id': 'T92.3',
  'english': 'Sequelae of dislocation, sprain and strain of upper limb',
  'vietnamese': 'Di chứng sai khớp, bong gân và căng cơ chi trên',
}, {
  'id': 'T92.4',
  'english': 'Sequelae of injury of nerve of upper limb',
  'vietnamese': 'Di chứng tổn thương dây thần kinh chi trên',
}, {
  'id': 'T92.5',
  'english': 'Sequelae of injury of muscle and tendon of upper limb',
  'vietnamese': 'Di chứng tổn thương cơ và gân chi trên',
}, {
  'id': 'T92.6',
  'english': 'Sequelae of crushing injury and traumatic amputation of upper limb',
  'vietnamese': 'Di chứng tổn thương dập nát và chấn thương cắt cụt chi trên',
}, {
  'id': 'T92.8',
  'english': 'Sequelae of other specified injuries of upper limb',
  'vietnamese': 'Di chứng tổn thương khác đã xác định của chi trên',
}, {
  'id': 'T92.9',
  'english': 'Sequelae of unspecified injury of upper limb',
  'vietnamese': 'Di chứng tổn thương chưa xác định của chi trên',
}, {
  'id': 'T93',
  'english': 'Sequelae of injuries of lower limb',
  'vietnamese': 'Di chứng tổn thương chi dưới',
}, {
  'id': 'T93.0',
  'english': 'Sequelae of open wound of lower limb',
  'vietnamese': 'Di chứng vết thương hở chi dưới',
}, {
  'id': 'T93.1',
  'english': 'Sequelae of fracture of femur',
  'vietnamese': 'Di chứng gẫy xương đùi',
}, {
  'id': 'T93.2',
  'english': 'Sequelae of other fractures of lower limb',
  'vietnamese': 'Di chứng gẫy xương khác chi dưới',
}, {
  'id': 'T93.3',
  'english': 'Sequelae of dislocation, sprain and strain of lower limb',
  'vietnamese': 'Di chứng sai khớp, bong gân và căng cơ chi dưới',
}, {
  'id': 'T93.4',
  'english': 'Sequelae of injury of nerve of lower limb',
  'vietnamese': 'Di chứng tổn thương dây thần kinh chi dưới',
}, {
  'id': 'T93.5',
  'english': 'Sequelae of injury of muscle and tendon of lower limb',
  'vietnamese': 'Di chứng tổn thương cơ và gân chi dưới',
}, {
  'id': 'T93.6',
  'english': 'Sequelae of crushing injury and traumatic amputation of lower limb',
  'vietnamese': 'Di chứng tổn thương dập nát và chấn thương cắt cụt chi dưới',
}, {
  'id': 'T93.8',
  'english': 'Sequelae of other specified injuries of lower limb',
  'vietnamese': 'Di chứng tổn thương khác đã xác định của chi dưới',
}, {
  'id': 'T93.9',
  'english': 'Sequelae of unspecified injury of lower limb',
  'vietnamese': 'Di chứng tổn thương chưa xác định của chi dưới',
}, {
  'id': 'T94',
  'english': 'Sequelae of injuries involving multiple and unspecified body regions',
  'vietnamese': 'Di chứng tổn thương và chưa xác định vùng cơ thể',
}, {
  'id': 'T94.0',
  'english': 'Sequelae of injuries involving multiple body regions',
  'vietnamese': 'Di chứng tổn thương nhiều vùng cơ thể',
}, {
  'id': 'T94.1',
  'english': 'Sequelae of injuries, not specified by body region',
  'vietnamese': 'Di chứng tổn thương chưa xác định vùng cơ thể',
}, {
  'id': 'T95',
  'english': 'Sequelae of burns, corrosions and frostbite',
  'vietnamese': 'Di chứng bỏng, ăn mòn và tổn thương do cóng lạnh',
}, {
  'id': 'T95.0',
  'english': 'Sequelae of burn, corrosion and frostbite of head and neck',
  'vietnamese': 'Di chứng bỏng, ăn mòn và tổn thương do cóng lạnh tại đầu và cổ',
}, {
  'id': 'T95.1',
  'english': 'Sequelae of burn, corrosion and frostbite of trunk',
  'vietnamese': 'Di chứng bỏng ăn mòn và tổn thương do cóng lạnh tại thân',
}, {
  'id': 'T95.2',
  'english': 'Sequelae of burn, corrosion and frostbite of upper limb',
  'vietnamese': 'Di chứng bỏng, ăn mòn và tổn thương do cóng lạnh tại chi trên',
}, {
  'id': 'T95.3',
  'english': 'Sequelae of burn, corrosion and frostbite of lower limb',
  'vietnamese': 'Di chứng bỏng, ăn mòn và tổn thương do cóng lạnh tại chi dưới',
}, {
  'id': 'T95.4',
  'english': 'Sequelae of burn and corrosion classifiable only according to extent of body surface involved',
  'vietnamese': 'Di chứng bỏng, ăn mòn xếp loại chỉ phụ thuộc phạm vi bề mặt cơ thể tổn thương',
}, {
  'id': 'T95.8',
  'english': 'Sequelae of other specified burn, corrosion and frostbite',
  'vietnamese': 'Di chứng bỏng ăn mòn và tổn thương do cóng lạnh khác đã xác định',
}, {
  'id': 'T95.9',
  'english': 'Sequelae of unspecified burn, corrosion and frostbite',
  'vietnamese': 'Di chứng bỏng, ăn mòn và tổn thương do cóng lạnh chưa xác định',
}, {
  'id': 'T96',
  'english': 'Sequelae of poisioning by drugs, medicaments and biological substances',
  'vietnamese': 'Di chứng nhiễm độc thuốc, dược phẩm và chất sinh học',
}, {
  'id': 'T97',
  'english': 'Sequelae toxic effects of substances chiefly nonmedicinal as to source',
  'vietnamese': 'Di chứng có tác động độc của chất nguồn chủ yếu không phải dược phẩm',
}, {
  'id': 'T98',
  'english': 'Sequelae of other and unspecified effects of external causes',
  'vietnamese': 'Di chứng do tác động khác và chưa xác định của căn nguyên từ bên ngoài',
}, {
  'id': 'T98.0',
  'english': 'Sequelae of effects of foreign body entering through natural orifice',
  'vietnamese': 'Di chứng do tác động của vật lạ vào qua lỗ tự nhiên',
}, {
  'id': 'T98.1',
  'english': 'Sequelae of other and unspecified effects of external causes',
  'vietnamese': 'Di chứng do tác động khác và chưa xác định căn nguyên bên ngoài',
}, {
  'id': 'T98.2',
  'english': 'Sequelae of certain early complications of trauma',
  'vietnamese': 'Di chứng của một vài biến chứng sớm của chấn thương',
}, {
  'id': 'T98.3',
  'english': 'Sequelae of complications of surgical and medical care, not elsewhere classified',
  'vietnamese': 'Di chứng biến chứng phẫu thuật và chăm sóc y tế không xếp loại ở phần khác',
}, {
  'id': 'U04',
  'english': 'Severe acute respiratory syndrome [SARS]',
  'vietnamese': 'Hội chứng suy hô hấp cấp tính nặng [SARS]',
}, {
  'id': 'U04.9',
  'english': 'Severe acute respiratory syndrome, unspecified',
  'vietnamese': 'Hội chứng suy hô hấp cấp tính nặng [SARS], không đặc hiệu',
}, {
  'id': 'U06',
  'english': 'Emergency use of U06',
  'vietnamese': 'Sử dụng mã U06 trong trường cấp cứu',
}, {
  'id': 'U06.0',
  'english': 'Emergency use of U06.0',
  'vietnamese': 'Sử dụng mã U06.0 trong cấp cứu',
}, {
  'id': 'U06.1',
  'english': 'Emergency use of U06.1',
  'vietnamese': 'Sử dụng mã U06.1 trong cấp cứu',
}, {
  'id': 'U06.2',
  'english': 'Emergency use of U06.2',
  'vietnamese': 'Sử dụng mã U06.2 trong cấp cứu',
}, {
  'id': 'U06.3',
  'english': 'Emergency use of U06.3',
  'vietnamese': 'Sử dụng mã U06.3 trong cấp cứu',
}, {
  'id': 'U06.4',
  'english': 'Emergency use of U06.4',
  'vietnamese': 'Sử dụng mã U06.4 trong cấp cứu',
}, {
  'id': 'U06.5',
  'english': 'Emergency use of U06.5',
  'vietnamese': 'Sử dụng mã U06.5 trong cấp cứu',
}, {
  'id': 'U06.6',
  'english': 'Emergency use of U06.6',
  'vietnamese': 'Sử dụng mã U06.6 trong cấp cứu',
}, {
  'id': 'U06.7',
  'english': 'Emergency use of U06.7',
  'vietnamese': 'Sử dụng mã U06.7 trong cấp cứu',
}, {
  'id': 'U06.8',
  'english': 'Emergency use of U06.8',
  'vietnamese': 'Sử dụng mã U06.8 trong cấp cứu',
}, {
  'id': 'U06.9',
  'english': 'Emergency use of U06.9',
  'vietnamese': 'Sử dụng mã U06.9 trong cấp cứu',
}, {
  'id': 'U07',
  'english': 'Emergency use of U07',
  'vietnamese': 'Sử dụng mã U07 trong cấp cứu',
}, {
  'id': 'U07.0',
  'english': 'Emergency use of U07.0',
  'vietnamese': 'Sử dụng mã U07.0 trong cấp cứu',
}, {
  'id': 'U07.1',
  'english': 'Emergency use of U07.1',
  'vietnamese': 'Sử dụng mã U07.1 trong cấp cứu',
}, {
  'id': 'U07.2',
  'english': 'Emergency use of U07.2',
  'vietnamese': 'Sử dụng mã U07.2 trong cấp cứu',
}, {
  'id': 'U07.3',
  'english': 'Emergency use of U07.3',
  'vietnamese': 'Sử dụng mã U07.3 trong cấp cứu',
}, {
  'id': 'U07.4',
  'english': 'Emergency use of U07.4',
  'vietnamese': 'Sử dụng mã U07.4 trong cấp cứu',
}, {
  'id': 'U07.5',
  'english': 'Emergency use of U07.5',
  'vietnamese': 'Sử dụng mã U07.5 trong cấp cứu',
}, {
  'id': 'U07.6',
  'english': 'Emergency use of U07.6',
  'vietnamese': 'Sử dụng mã U07.6 trong cấp cứu',
}, {
  'id': 'U07.7',
  'english': 'Emergency use of U07.7',
  'vietnamese': 'Sử dụng mã U07.7 trong cấp cứu',
}, {
  'id': 'U07.8',
  'english': 'Emergency use of U07.8',
  'vietnamese': 'Sử dụng mã U07.8 trong cấp cứu',
}, {
  'id': 'U07.9',
  'english': 'Emergency use of U07.9',
  'vietnamese': 'Sử dụng mã U07.9 trong cấp cứu',
}, {
  'id': 'U82',
  'english': 'Resistance to betalactam antibiotics',
  'vietnamese': 'Kháng kháng sinh họ betalactam',
}, {
  'id': 'U82.0',
  'english': 'Resistance to penicillin, Amoxicillin, Ampicillin',
  'vietnamese': 'Kháng penicillin',
}, {
  'id': 'U82.1',
  'english': 'Resistance to methicillin, cloxacillin',
  'vietnamese': 'Kháng Methicillin',
}, {
  'id': 'U82.8',
  'english': 'Resistance to other betalactam antibiotics',
  'vietnamese': 'Kháng kháng sinh họ betalactam khác',
}, {
  'id': 'U82.9',
  'english': 'Resistance to betalactam antibiotics, unspecified',
  'vietnamese': 'Kháng kháng sinh họ betalactam, không đặc hiệu',
}, {
  'id': 'U83',
  'english': 'Resistance to other antibiotics',
  'vietnamese': 'Kháng các kháng sinh khác',
}, {'id': 'U83.0', 'english': 'Resistance to vancomycin', 'vietnamese': 'Kháng vancomycin'}, {
  'id': 'U83.1',
  'english': 'Resistance to other vancomycin related antibiotics',
  'vietnamese': 'Kháng các kháng sinh thuộc họ vancomycin khác',
}, {'id': 'U83.2', 'english': 'Resistance to quinolones', 'vietnamese': 'Kháng nhóm quinolon'}, {
  'id': 'U83.7',
  'english': 'Resistance to multiple antibiotics',
  'vietnamese': 'Kháng đa kháng sinh',
}, {
  'id': 'U83.8',
  'english': 'Resistance to other single specified antibiotic',
  'vietnamese': 'Kháng một loại kháng sinh xác định, khác',
}, {
  'id': 'U83.9',
  'english': 'Resistance to unspecified antibiotic',
  'vietnamese': 'Kháng kháng sinh không xác định',
}, {
  'id': 'U84',
  'english': 'Resistance to other antimicrobial drugs',
  'vietnamese': 'Kháng các thuốc kháng sinh khác',
}, {
  'id': 'U84.0',
  'english': 'Resistance to antiparasitic drug(s)',
  'vietnamese': 'Kháng (các) thuốc diệt ký sinh trùng',
}, {
  'id': 'U84.1',
  'english': 'Resistance to antifungal drug(s)',
  'vietnamese': 'Kháng (các) thuốc chống nấm',
}, {
  'id': 'U84.2',
  'english': 'Resistance to antiviral drug(s)',
  'vietnamese': 'Kháng (các) thuốc diệt virus',
}, {
  'id': 'U84.3',
  'english': 'Resistance to tuberculostatic drug(s)',
  'vietnamese': 'Kháng (các) thuốc chống lao',
}, {
  'id': 'U84.7',
  'english': 'Resistance to multiple antimicrobial drugs',
  'vietnamese': 'Kháng đa thuốc kháng vi sinh vật',
}, {
  'id': 'U84.8',
  'english': 'Resistance to other specified antimicrobial drug',
  'vietnamese': 'Kháng thuốc kháng vi sinh vật xác định khác',
}, {
  'id': 'U84.9',
  'english': 'Resistance to unspecified antimicrobial drugs',
  'vietnamese': 'Kháng các thuốc kháng vi sinh vật không xác định',
}, {
  'id': 'U85',
  'english': 'Resistance to antineolastics drugs',
  'vietnamese': 'Kháng các thuốc kháng ung thư',
}, {
  'id': 'V01',
  'english': 'Pedestrian injured in collision with pedal cycle',
  'vietnamese': 'Người đi bộ bị thương do va chạm với xe đạp',
}, {
  'id': 'V02',
  'english': 'Pedestrian injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người đi bộ bị thương do va chạm với xe mô tô 2-3 bánh',
}, {
  'id': 'V03',
  'english': 'Pedestrian injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người đi bộ bị thương do va chạm với ô tô, xe tải thu nhặt, xe tải',
}, {
  'id': 'V04',
  'english': 'Pedestrian injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người đi bộ bị thương do va chạm với xe tải hạng nặng, xe buýt',
}, {
  'id': 'V05',
  'english': 'Pedestrian injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người đi bộ bị thương do va chạm với tầu hỏa, phương tiện đi trên ray',
}, {
  'id': 'V06',
  'english': 'Pedestrian injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người đi bộ bị thương do va chạm với xe không động cơ khác',
}, {
  'id': 'V09',
  'english': 'Pedestrian injured in other and unspecified transport accidents',
  'vietnamese': 'Người đi bộ bị thương trong những tai nạn giao thông khác không xác định',
}, {
  'id': 'V09.0',
  'english': 'Pedestrian injured in nontraffic accident involving other and unspecified motor vehicles',
  'vietnamese': 'Người đi bộ bị thương trong tai nạn không phải xe cộ bao gồm xe có động khác và không xác định',
}, {
  'id': 'V09.1',
  'english': 'Pedestrian injured in unspecified nontraffic accident',
  'vietnamese': 'Người đi bộ bị thương trong tai nạn không phải xe cộ không xác định',
}, {
  'id': 'V09.2',
  'english': 'Pedestrian injured in traffic accident involving other and unspecified motor vehicles',
  'vietnamese': 'Người đi bộ bị thương do tai nạn giao thông có sự tham gia của xe có động cơ khác không xác định',
}, {
  'id': 'V09.3',
  'english': 'Pedestrian injured in unspecified traffic accident',
  'vietnamese': 'Người đi bộ bị thương trong tai nạn giao thông không xác định',
}, {
  'id': 'V09.9',
  'english': 'Pedestrian injured in unspecified transport accident',
  'vietnamese': 'Người đi bộ bị thương trong tai nạn giao thông không xác định',
}, {
  'id': 'V10',
  'english': 'Pedal cyclist injured in collision with pedestrian or animal',
  'vietnamese': 'Người đi xe đạp bị thương vì va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V11',
  'english': 'Pedal cyclist injured in collision with other pedal cycle',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với người đạp xe đạp khác',
}, {
  'id': 'V12',
  'english': 'Pedal cyclist injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người đi xe đạp bị thương do va chạm với mô tô 2-3 bánh',
}, {
  'id': 'V13',
  'english': 'Pedal cyclist injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với ô tô, xe tải thu góp và xe tải',
}, {
  'id': 'V14',
  'english': 'Pedal cyclist injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với xe tải hay xe buýt',
}, {
  'id': 'V15',
  'english': 'Pedal cyclist injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với tầu hỏa, phương tiện đi trên ray',
}, {
  'id': 'V16',
  'english': 'Pedal cyclist injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với xe không động cơ khác',
}, {
  'id': 'V17',
  'english': 'Pedal cyclist injured in collision with fixed or stationary object',
  'vietnamese': 'Người đi xe đạp bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V18',
  'english': 'Pedal cyclist injured in noncollision transport accident',
  'vietnamese': 'Người đi xe đạp bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V19',
  'english': 'Pedal cyclist injured in other and unspecified transport accidents',
  'vietnamese': 'Người đi xe đạp bị thương trong những tai nạn giao thông khác, không rõ đặc điểm',
}, {
  'id': 'V19.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người lái xe bị thương khi va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải do tai nạn giao thông',
}, {
  'id': 'V19.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Hành khách bị thương khi va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải do giao thông',
}, {
  'id': 'V19.2',
  'english': 'Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi xe đạp không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn không phải xe cộ',
}, {
  'id': 'V19.3',
  'english': 'Pedal cyclist [any] injured in unspecified nontraffic accident',
  'vietnamese': 'Mọi người đạp xe bị thương trong tai nạn không phải xe cộ không rõ đặc điểm',
}, {
  'id': 'V19.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V19.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V19.6',
  'english': 'Unspecified pedal cyclist injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đạp xe không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V19.8',
  'english': 'Pedal cyclist [any] injured in other specified transport accidents',
  'vietnamese': 'Mọi người đạp xe bị thương trong các tai nạn giao thông khác, có đặc điểm rõ',
}, {
  'id': 'V19.9',
  'english': 'Pedal cyclist [any] injured in unspecified traffic accident',
  'vietnamese': 'Mọi người đạp xe bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V20',
  'english': 'Motorcycle rider injured in collision with pedestrian or animal',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V21',
  'english': 'Motorcycle rider injured in collision with pedal cycle',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với xe đạp',
}, {
  'id': 'V22',
  'english': 'Motorcycle rider injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với mô tô 2-3 bánh',
}, {
  'id': 'V23',
  'english': 'Motorcycle rider injured in collision with car, pick-up truck or van',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với ô tô, xe tải thu nhặt hay xe tải',
}, {
  'id': 'V24',
  'english': 'Motorcycle rider injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V25',
  'english': 'Motorcycle rider injured in collision with railway train or railway vehicle',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với tầu hỏa, phương tiện đi trên ray',
}, {
  'id': 'V26',
  'english': 'Motorcycle rider injured in collision with other nonmotor vehicle',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với xe không động cơ khác',
}, {
  'id': 'V27',
  'english': 'Motorcycle rider injured in collision with fixed or stationary object',
  'vietnamese': 'Lái xe mô tô bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V28',
  'english': 'Motorcycle rider injured in noncollision transport accident',
  'vietnamese': 'Lái xe mô tô bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V29',
  'english': 'Motorcycle rider injured in other and unspecified transport accidents',
  'vietnamese': 'Người lái xe mô tô bị thương trong tai nạn giao thông khác không rõ đặc điểm',
}, {
  'id': 'V29.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải giao thông',
}, {
  'id': 'V29.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người ngồi xe bị thương khi va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải giao thông',
}, {
  'id': 'V29.2',
  'english': 'Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe mô tô không rõ đặc điểm, bị thương khi va chạm với mô tô khác không rõ đặc điểm trong tai nạn không phải giao thông',
}, {
  'id': 'V29.3',
  'english': 'Motorcycle rider [any] injured in unspecified nontraffic accident',
  'vietnamese': 'Mọi lái xe mô tô bị thương trong tai nạn không phải giao thông không rõ đặc điểm',
}, {
  'id': 'V29.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V29.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người ngồi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V29.6',
  'english': 'Unspecified motorcycle rider injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe mô tô không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V29.8',
  'english': 'Motorcycle rider [any] injured in other specified transport accidents',
  'vietnamese': 'Mọi lái xe mô tô bị thương trong các tai nạn giao thông khác, rõ đặc điểm',
}, {
  'id': 'V29.9',
  'english': 'Motorcycle rider [any] injured in unspecified traffic accident',
  'vietnamese': 'Mọi lái xe mô tô bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V30',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with pedestrian or animal',
  'vietnamese': 'Người đi xe mô tô 3 bánh bị thương khi va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V31',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with pedal cycle',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với xe đạp',
}, {
  'id': 'V32',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với mô tô 2 hay 3 bánh',
}, {
  'id': 'V33',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với ô tô, xe tải thu nhặt và xe tải',
}, {
  'id': 'V34',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V35',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V36',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với xe có động cơ khác',
}, {
  'id': 'V37',
  'english': 'Occupant of three-wheeled motor vehicle injured in collision with fixed or stationary object',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V38',
  'english': 'Occupant of three-wheeled motor vehicle injured in noncollision transport accident',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V39',
  'english': 'Occupant of three-wheeled motor vehicle injured in other and unspecified transport accidents',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương trong tai nạn giao thông khác không rõ đặc điểm',
}, {
  'id': 'V39.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác trong tai nạn không phải do giao thông',
}, {
  'id': 'V39.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người trên xe bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác trong tai nạn không phải giao thông',
}, {
  'id': 'V39.2',
  'english': 'Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người ở trên mô tô 3 bánh không rõ đặc điểm bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác trong tai nạn không phải giao thông',
}, {
  'id': 'V39.3',
  'english': 'Occupant [any] of three-wheeled motor vehicle injured in unspecified nontraffic accident',
  'vietnamese': 'Mọi người trên xe mô tô 3 bánh bị thương trong tai nạn không rõ đặc điểm, không phải tai nạn giao thông',
}, {
  'id': 'V39.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V39.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V39.6',
  'english': 'Unspecified occupant of three-wheeled motor vehicle injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người trên xe mô tô 3 bánh bị thương khi va chạm với xe động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V39.8',
  'english': 'Occupant [any] of three-wheeled motor vehicle injured in other specified transport accidents',
  'vietnamese': 'Mọi người trên xe mô tô 3 bánh bị thương trong tai nạn giao thông khác có biết rõ đặc điểm',
}, {
  'id': 'V39.9',
  'english': 'Occupant [any] of three-wheeled motor vehicle injured in unspecified traffic accident',
  'vietnamese': 'Mọi người trên xe mô tô 3 bánh bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V40',
  'english': 'Car occupant injured in collision with pedestrian or animal',
  'vietnamese': 'Người trên ô tô bị thương khi va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V41',
  'english': 'Car occupant injured in collision with pedal cycle',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với xe đạp',
}, {
  'id': 'V42',
  'english': 'Car occupant injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với mô tô 2 hay 3 bánh',
}, {
  'id': 'V43',
  'english': 'Car occupant injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với xe ô tô, xe tải thu nhặt và xe tải',
}, {
  'id': 'V44',
  'english': 'Car occupant injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V45',
  'english': 'Car occupant injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V46',
  'english': 'Car occupant injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với xe không động cơ khác',
}, {
  'id': 'V47',
  'english': 'Car occupant injured in collision with fixed or stationary object',
  'vietnamese': 'Người đi ô tô bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V48',
  'english': 'Car occupant injured in noncollision transport accident',
  'vietnamese': 'Người đi ô tô bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V49',
  'english': 'Car occupant injured in other and unspecified transport accidents',
  'vietnamese': 'Người đi ô tô bị thương trong tai nạn giao thông khác và không rõ đặc điểm',
}, {
  'id': 'V49.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương trong va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V49.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi ô tô bị thương do va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V49.2',
  'english': 'Unspecified car occupant injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi ô tô không rõ đặc điểm -bị thương do va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V49.3',
  'english': 'Car occupant [any] injured in unspecified nontraffic accident',
  'vietnamese': 'Mọi người đi ô tô bị thương trong tai nạn không rõ đặc điểm không phải tai nạn giao thông',
}, {
  'id': 'V49.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương vì va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V49.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi ô tô bị thương vì va chạm với xe động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V49.6',
  'english': 'Unspecified car occupant injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người trên ô tô không rõ đặc điểm bị thương vì va chạm với xe có động cơ không rõ đặc điểm khác trong trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V49.8',
  'english': 'Car occupant [any] injured in other specified transport accidents',
  'vietnamese': 'Mọi người trên ô tô bị thương trong tai nạn giao thông khác, có biết đặc điểm',
}, {
  'id': 'V49.9',
  'english': 'Car occupant [any] injured in unspecified traffic accident',
  'vietnamese': 'Mọi người trên ô tô bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V50',
  'english': 'Occupant of pick-up truck or van injured in collision with pedestrian or animal',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V51',
  'english': 'Occupant of pick-up truck or van injured in collision with pedal cycle',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với xe đạp',
}, {
  'id': 'V52',
  'english': 'Occupant of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với mô tô 2 hay 3 bánh',
}, {
  'id': 'V53',
  'english': 'Occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với ô tô,tải, xe kéo',
}, {
  'id': 'V54',
  'english': 'Occupant of pick-up truck or van injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V55',
  'english': 'Occupant of pick-up truck or van injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với tầu hỏa, phương tiện đi trên ray',
}, {
  'id': 'V56',
  'english': 'Occupant of pick-up truck or van injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với xe không động cơ khác',
}, {
  'id': 'V57',
  'english': 'Occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương do va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V58',
  'english': 'Occupant of pick-up truck or van injured in noncollision transport accident',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V59',
  'english': 'Occupant of pick-up truck or van injured in other and unspecified transport accidents',
  'vietnamese': 'Người trên xe tải, xe kéo bị thương trong tai nạn giao thông khác không rõ đặc điểm',
}, {
  'id': 'V59.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm không phải trong tai nạn giao thông',
}, {
  'id': 'V59.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V59.2',
  'english': 'Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người của xe tải, xe kéo không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V59.3',
  'english': 'Occupant [any] of pick-up truck or van injured in unspecified nontraffic accident',
  'vietnamese': 'Mọi người trên xe tải, xe kéo bị thương trong tai nạn không rõ đặc điểm, không phải tai nạn giao thông',
}, {
  'id': 'V59.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V59.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V59.6',
  'english': 'Unspecified occupant of pick-up truck or van injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người của xe tải, xe kéo không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V59.8',
  'english': 'Occupant [any] of pick-up truck or van injured in other specified transport accidents',
  'vietnamese': 'Mọi người trên xe tải, xe kéo bị thương trong tai nạn giao thông khác có biết đặc điểm',
}, {
  'id': 'V59.9',
  'english': 'Occupant [any] of pick-up truck or van injured in unspecified traffic accident',
  'vietnamese': 'Mọi người trên xe tải, xe kéo bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V60',
  'english': 'Occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V61',
  'english': 'Occupant of heavy transport vehicle injured in collision with pedal cycle',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với xe đạp',
}, {
  'id': 'V62',
  'english': 'Occupant of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với mô tô 2 hay 3 bánh',
}, {
  'id': 'V63',
  'english': 'Occupant of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với ô tô, tải, xe kéo',
}, {
  'id': 'V64',
  'english': 'Occupant of heavy transport vehicle injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V65',
  'english': 'Occupant of heavy transport vehicle injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V66',
  'english': 'Occupant of heavy transport vehicle injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với xe không động cơ khác',
}, {
  'id': 'V67',
  'english': 'Occupant of heavy transport vehicle injured in collision with fixed or stationary object',
  'vietnamese': 'Người trên xe tải nặng bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V68',
  'english': 'Occupant of heavy transport vehicle injured in noncollision transport accident',
  'vietnamese': 'Người trên xe tải nặng bị thương trong Tai nạn giao thông không có va chạm',
}, {
  'id': 'V69',
  'english': 'Occupant of heavy transport vehicle injured in other and unspecified transport accidents',
  'vietnamese': 'Người trên xe tải nặng bị thương trong Tai nạn giao thông khác không rõ đặc điểm',
}, {
  'id': 'V69.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm không phải trong tai nạn giao thông',
}, {
  'id': 'V69.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm không phải trong tai nạn giao thông',
}, {
  'id': 'V69.2',
  'english': 'Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi xe tải nặng không rõ đặc điểm bị thương khi va chạm với xe có động cơ không rõ đặc điểm khác không phải trong Tai nạn giao thông',
}, {
  'id': 'V69.3',
  'english': 'Occupant [any] of heavy transport vehicle injured in unspecified nontraffic accident',
  'vietnamese': 'Người đi xe tải nặng bị thương trong tai nạn không rõ đặc điểm không phải do Tai nạn giao thông',
}, {
  'id': 'V69.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V69.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V69.6',
  'english': 'Unspecified occupant of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe tải nặng không rõ đặc điểm bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm trong tai nạn giao thông',
}, {
  'id': 'V69.8',
  'english': 'Occupant [any] of heavy transport vehicle injured in other specified transport accidents',
  'vietnamese': 'Người trên xe tải nặng bị thương trong Tai nạn giao thông đặc biệt khác',
}, {
  'id': 'V69.9',
  'english': 'Occupant [any] of heavy transport vehicle injured in unspecified traffic accident',
  'vietnamese': 'Người đi xe tải nặng bị thương trong Tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V70',
  'english': 'Bus occupant injured in collision with pedestrian or animal',
  'vietnamese': 'Người trên xe B bị thương khi va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V71',
  'english': 'Bus occupant injured in collision with pedal cycle',
  'vietnamese': 'Người trên xe buýt (bus) bị thương khi va chạm với xe đạp',
}, {
  'id': 'V72',
  'english': 'Bus occupant injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với mô tô 2 hay 3 bánh',
}, {
  'id': 'V73',
  'english': 'Bus occupant injured in collision with car, pick-up truck or van',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với ô tô, xe bán tải hoặc xe tải',
}, {
  'id': 'V74',
  'english': 'Bus occupant injured in collision with heavy transport vehicle or bus',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với xe tải nặng hay xe buýt',
}, {
  'id': 'V75',
  'english': 'Bus occupant injured in collision with railway train or railway vehicle',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V76',
  'english': 'Bus occupant injured in collision with other nonmotor vehicle',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với xe không có động cơ khác',
}, {
  'id': 'V77',
  'english': 'Bus occupant injured in collision with fixed or stationary object',
  'vietnamese': 'người trên xe buýt (bus) bị thương khi va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V78',
  'english': 'Bus occupant injured in noncollision transport accident',
  'vietnamese': 'người trên xe buýt (bus) bị thương trong tai nạn giao thông không có va chạm',
}, {
  'id': 'V79',
  'english': 'Bus occupant injured in other and unspecified transport accidents',
  'vietnamese': 'người trên xe buýt (bus) bị thương trong tai nạn giao thông khác không rõ đặc điểm',
}, {
  'id': 'V79.0',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Lái xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm không phải do tai nạn giao thông',
}, {
  'id': 'V79.1',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người đi xe bị thương khi va chạm với xe có động cơ khác không rõ đặc điểm không phải do tai nạn giao thông',
}, {
  'id': 'V79.2',
  'english': 'Unspecified bus occupant injured in collision with other and unspecified motor vehicles in nontraffic accident',
  'vietnamese': 'Người trên xe buýt (bus) không rõ đặc điểm bị thương khi va chạm với xe động cơ khác không rõ đặc điểm không phải do tai nạn giao thông',
}, {
  'id': 'V79.3',
  'english': 'Bus occupant [any] injured in unspecified nontraffic accident',
  'vietnamese': 'người trên xe buýt (bus) bị thương không do tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V79.4',
  'english': 'Driver injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Lái xe bị thương trong va chạm với xe động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V79.5',
  'english': 'Passenger injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người đi xe bị thương trong va chạm với xe động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V79.6',
  'english': 'Unspecified bus occupant injured in collision with other and unspecified motor vehicles in traffic accident',
  'vietnamese': 'Người trên xe buýt (bus) không rõ đặc điểm bị thương khi va chạm với xe động cơ không rõ đặc điểm khác trong tai nạn giao thông',
}, {
  'id': 'V79.8',
  'english': 'Bus occupant [any] injured in other specified transport accidents',
  'vietnamese': 'Người trên xe buýt (bus) bị thương trong tai nạn giao thông có biết đặc điểm',
}, {
  'id': 'V79.9',
  'english': 'Bus occupant [any] injured in unspecified traffic accident',
  'vietnamese': 'Người trên xe buýt (bus) bị thương trong tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V80',
  'english': 'Animal-rider or occupant of animal-drawn vehicle injured in transport accident',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe súc vật kéo bị thương trong tai nạn giao thông',
}, {
  'id': 'V80.0',
  'english': 'Rider or occupant injured by fall from or being thrown from animal or animal-drawn vehicle in noncollision accident',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do ngã từ hay ném đi từ súc vật hay xe súc vật kéo trong tai nạn không có va chạm',
}, {
  'id': 'V80.1',
  'english': 'Rider or occupant injured in collision with pedestrian or animal',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với người đi bộ hay súc vật',
}, {
  'id': 'V80.2',
  'english': 'Rider or occupant injured in collision with pedal cycle',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với xe đạp',
}, {
  'id': 'V80.3',
  'english': 'Rider or occupant injured in collision with two- or three-wheeled motor vehicle',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với xe mô tô 2 hay 3 bánh',
}, {
  'id': 'V80.4',
  'english': 'Rider or occupant injured in collision with car, pick-up truck, van, heavy transport vehicle or bus',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với ô tô, xe đầu kéo, xe tải, tải nặng hay xe bus',
}, {
  'id': 'V80.5',
  'english': 'Rider or occupant injured in collision with other specified motor vehicle',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với xe có động cơ có biết đặc điểm khác',
}, {
  'id': 'V80.6',
  'english': 'Rider or occupant injured in collision with railway train or railway vehicle',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V80.7',
  'english': 'Rider or occupant injured in collision with other nonmotor vehicle',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương do va chạm với xe có động cơ khác',
}, {
  'id': 'V80.8',
  'english': 'Rider or occupant injured in collision with fixed or stationary object',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương trong va chạm với vật cố định hay tĩnh vật',
}, {
  'id': 'V80.9',
  'english': 'Rider or occupant injured in other and unspecified transport accidents',
  'vietnamese': 'Người cưỡi súc vật hay người trên xe bị thương trong tai nạn giao thông không biết rõ đặc điểm khác',
}, {
  'id': 'V81',
  'english': 'Occupant of railway train or railway vehicle injured in transport accident',
  'vietnamese': 'Người đi tầu hỏa hay phương tiện đi trên ray bị thương trong tai nạn giao thông',
}, {
  'id': 'V81.0',
  'english': 'Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic accident',
  'vietnamese': 'Công nhân đường sắt hoặc phương tiện chạy trên ray bị thương khi va chạm với xe có động cơ trong tai nạn không phải do giao thông',
}, {
  'id': 'V81.1',
  'english': 'Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic accident',
  'vietnamese': 'Công nhân đường sắt hoặc phương tiện chạy trên ray bị thương khi va chạm với xe có động cơ trong tai nạn giao thông',
}, {
  'id': 'V81.2',
  'english': 'Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock',
  'vietnamese': 'Công nhân đường sắt hoặc phương tiện chạy trên ray bị thương khi va chạm với hay bị đập bởi khối hàng hóa lăn',
}, {
  'id': 'V81.3',
  'english': 'Occupant of railway train or railway vehicle injured in collision with other object',
  'vietnamese': 'Công nhân đường sắt hoặc phương tiện chạy trên ray bị thương khi va chạm với vật khác',
}, {
  'id': 'V81.4',
  'english': 'Person injured while boarding or alighting from railway train or railway vehicle',
  'vietnamese': 'Người bị thương khi lên hay xuống tầu hay phương tiện đi trên ray',
}, {
  'id': 'V81.5',
  'english': 'Occupant of railway train or railway vehicle injured by fall in railway train or railway vehicle',
  'vietnamese': 'Hành khách đi tàu hoặc phương tiện chạy trên ray bị thương do ngã trong tầu hay phương tiện đi trên ray',
}, {
  'id': 'V81.6',
  'english': 'Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle',
  'vietnamese': 'Hành khách đi tàu hoặc phương tiện chạy trên ray bị thương do ngã từ tầu hỏa hay phương tiện đi trên ray',
}, {
  'id': 'V81.7',
  'english': 'Occupant of railway train or railway vehicle injured in derailment without antecedent collision',
  'vietnamese': 'Hành khách đi tàu hoặc phương tiện chạy trên ray bị thương khi tầu hay OTR trật bánh mà không có va chạm trước đó',
}, {
  'id': 'V81.8',
  'english': 'Occupant of railway train or railway vehicle injured in other specified railway accidents',
  'vietnamese': 'Hành khách đi tàu hoặc phương tiện chạy trên ray bị thương trong những tai nạn đường sắt biết rõ đặc điểm',
}, {
  'id': 'V81.9',
  'english': 'Occupant of railway train or railway vehicle injured in unspecified railway accident',
  'vietnamese': 'Hành khách đi tàu hoặc phương tiện chạy trên ray bị thương trong tai nạn đường sắt không rõ đặc điểm',
}, {
  'id': 'V82',
  'english': 'Occupant of streetcar injured in transport accident',
  'vietnamese': 'Hành khách xe điện bị thương trong tai nạn giao thông',
}, {
  'id': 'V82.0',
  'english': 'Occupant of streetcar injured in collision with motor vehicle in nontraffic accident',
  'vietnamese': 'Hành khách xe điện bị thương khi va chạm với xe có động cơ trong tai nạn không phải tai nạn giao thông',
}, {
  'id': 'V82.1',
  'english': 'Occupant of streetcar injured in collision with motor vehicle in traffic accident',
  'vietnamese': 'Hành khách xe điện bị thương khi va chạm với xe có động cơ trong tai nạn giao thông',
}, {
  'id': 'V82.2',
  'english': 'Occupant of streetcar injured in collision with or hit by rolling stock',
  'vietnamese': 'Hành khách xe điện bị thương khi va chạm với hay bị đập bởi khối hàng hóa bị lăn',
}, {
  'id': 'V82.3',
  'english': 'Occupant of streetcar injured in collision with other object',
  'vietnamese': 'Hành khách xe điện bị thương khi va chạm với vật khác',
}, {
  'id': 'V82.4',
  'english': 'Person injured while boarding or alighting from streetcar',
  'vietnamese': 'Hành khách xe điện bị thương khi lên hay xuống xe',
}, {
  'id': 'V82.5',
  'english': 'Occupant of streetcar injured by fall in streetcar',
  'vietnamese': 'Hành khách xe điện bị thương do ngã trên xe điện',
}, {
  'id': 'V82.6',
  'english': 'Occupant of streetcar injured by fall from streetcar',
  'vietnamese': 'Hành khách xe điện bị thương khi ngã từ xe điện',
}, {
  'id': 'V82.7',
  'english': 'Occupant of streetcar injured in derailment without antecedent collision',
  'vietnamese': 'Hành khách xe điện bị thương khi trật bánh mà không có va chạm trước đó',
}, {
  'id': 'V82.8',
  'english': 'Occupant of streetcar injured in other specified transport accidents',
  'vietnamese': 'Hành khách xe điện bị thương trong tai nạn giao thông khác có biết đặc điểm',
}, {
  'id': 'V82.9',
  'english': 'Occupant of streetcar injured in unspecified traffic accident',
  'vietnamese': 'Hành khách xe điện bị thương trong tai nạn giao thông không biết rõ đặc điểm',
}, {
  'id': 'V83',
  'english': 'Occupant of special vehicle mainly used on industrial premises injured in transport accident',
  'vietnamese': 'Người đi xe sử dụng riêng biệt trong khuôn viên công nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V83.0',
  'english': 'Driver of special industrial vehicle injured in traffic accident',
  'vietnamese': 'Lái xe của xe đặc chủng công nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V83.1',
  'english': 'Passenger of special industrial vehicle injured in traffic accident',
  'vietnamese': 'Người đi xe công nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V83.2',
  'english': 'Person on outside of special industrial vehicle injured in traffic accident',
  'vietnamese': 'Người ở bên ngoài xe công nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V83.3',
  'english': 'Unspecified occupant of special industrial vehicle injured in traffic accident',
  'vietnamese': 'Người của xe công nghiệp không rõ đặc điểm bị thương trong tai nạn giao thông',
}, {
  'id': 'V83.4',
  'english': 'Person injured while boarding or alighting from special industrial vehicle',
  'vietnamese': 'Người bị thương khi lên xuống xe công nghiệp',
}, {
  'id': 'V83.5',
  'english': 'Driver of special industrial vehicle injured in nontraffic accident',
  'vietnamese': 'Lái xe của xe công nghiệp bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V83.6',
  'english': 'Passenger of special industrial vehicle injured in nontraffic accident',
  'vietnamese': 'Người đi xe công nghiệp bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V83.7',
  'english': 'Person on outside of special industrial vehicle injured in nontraffic accident',
  'vietnamese': 'Người ở ngoài xe công nghiệp bị thương trong tai nạn không phải giao thông',
}, {
  'id': 'V83.9',
  'english': 'Unspecified occupant of special industrial vehicle injured in nontraffic accident',
  'vietnamese': 'Người của xe công nghiệp không rõ đặc điểm bị thương tai nạn không phải giao thông',
}, {
  'id': 'V84',
  'english': 'Occupant of special vehicle mainly used in agriculture injured in transport accident',
  'vietnamese': 'Người đi xe chuyên dùng sử dụng trong nông nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V84.0',
  'english': 'Driver of special agricultural vehicle injured in traffic accident',
  'vietnamese': 'Lái xe chuyên dùng trong nông nghiệp (xe nông nghiệp) bị thương trong tai nạn giao thông',
}, {
  'id': 'V84.1',
  'english': 'Passenger of special agricultural vehicle injured in traffic accident',
  'vietnamese': 'Người đi xe nông nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V84.2',
  'english': 'Person on outside of special agricultural vehicle injured in traffic accident',
  'vietnamese': 'Người ở bên ngoài xe nông nghiệp bị thương trong tai nạn giao thông',
}, {
  'id': 'V84.3',
  'english': 'Unspecified occupant of special agricultural vehicle injured in traffic accident',
  'vietnamese': 'Người của xe nông nghiệp không rõ đặc điểm bị thương trong tai nạn giao thông',
}, {
  'id': 'V84.4',
  'english': 'Person injured while boarding or alighting from special agricultural vehicle',
  'vietnamese': 'Người bị thương khi lên xuống xe nông nghiệp',
}, {
  'id': 'V84.5',
  'english': 'Driver of special agricultural vehicle injured in nontraffic accident',
  'vietnamese': 'Lái xe của xe nông nghiệp bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V84.6',
  'english': 'Passenger of special agricultural vehicle injured in nontraffic accident',
  'vietnamese': 'Người đi xe nông nghiệp bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V84.7',
  'english': 'Person on outside of special agricultural vehicle injured in nontraffic accident',
  'vietnamese': 'Người ở bên ngoài xe nông nghiệp bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V84.9',
  'english': 'Unspecified occupant of special agricultural vehicle injured in nontraffic accident',
  'vietnamese': 'Người ở xe nông nghiệp không rõ đặc điểm bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V85',
  'english': 'Occupant of special construction vehicle injured in transport accident',
  'vietnamese': 'Người đi xe đặc chủng xây dựng (xe xây dựng) bị thương trong tai nạn giao thông',
}, {
  'id': 'V85.0',
  'english': 'Driver of special construction vehicle injured in traffic accident',
  'vietnamese': 'Lái xe của xe xây dựng bị thương trong tai nạn giao thông',
}, {
  'id': 'V85.1',
  'english': 'Passenger of special construction vehicle injured in traffic accident',
  'vietnamese': 'Người đi trên xe xây dựng bị thương trong tai nạn giao thông',
}, {
  'id': 'V85.2',
  'english': 'Person on outside of special construction vehicle injured in traffic accident',
  'vietnamese': 'Người ở bên ngoài xe xây dựng bị thương trong tai nạn giao thông',
}, {
  'id': 'V85.3',
  'english': 'Unspecified occupant of special construction vehicle injured in traffic accident',
  'vietnamese': 'Người trên xe xây dựng không rõ đặc điểm bị thương trong tai nạn giao thông',
}, {
  'id': 'V85.4',
  'english': 'Person injured while boarding or alighting from special construction vehicle',
  'vietnamese': 'Người bị thương trong khi lên xuống xe xây dựng',
}, {
  'id': 'V85.5',
  'english': 'Driver of special construction vehicle injured in nontraffic accident',
  'vietnamese': 'Lái xe của xe xây dựng bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V85.6',
  'english': 'Passenger of special construction vehicle injured in nontraffic accident',
  'vietnamese': 'Người đi xe xây dựng bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V85.7',
  'english': 'Person on outside of special construction vehicle injured in nontraffic accident',
  'vietnamese': 'Người ở bên ngoài xe xây dựng bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V85.9',
  'english': 'Unspecified occupant of special construction vehicle injured in nontraffic accident',
  'vietnamese': 'Người của xe xây dựng không rõ đặc điểm bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V86',
  'english': 'Occupant of special all-terrain or other motor vehicle designed primarily for off-road use, injured in transport accident',
  'vietnamese': 'Người đi xe đặc chủng mọi địa hình (xe địa hình) hay xe có động cơ khác thiết kế nguyên thủy cho sử dụng ngoài quốc lộ (xe máy địa hình) bị thương trong tai nạn giao thông',
}, {
  'id': 'V86.0',
  'english': 'Driver of all-terrain or other off-road motor vehicle injured in traffic accident',
  'vietnamese': 'Lái xe địa hình hay xe máy địa hình bị thương trong tai nạn giao thông',
}, {
  'id': 'V86.1',
  'english': 'Passenger of all-terrain or other off-road motor vehicle injured in traffic accident',
  'vietnamese': 'Người đi xe địa hình hay xe máy địa hình bị thương trong tai nạn giao thông',
}, {
  'id': 'V86.2',
  'english': 'Person on outside of all-terrain or other off-road motor vehicle injured in traffic accident',
  'vietnamese': 'Người ở bên ngoài xe địa hình hay xe máy địa hình bị thương trong tai nạn giao thông',
}, {
  'id': 'V86.3',
  'english': 'Unspecified occupant of all-terrain or other off-road motor vehicle injured in traffic accident',
  'vietnamese': 'Người ở xe địa hình hay xe máy địa hình không rõ đặc điểm bị thương trong tai nạn giao thông',
}, {
  'id': 'V86.4',
  'english': 'Person injured while boarding or alighting from all-terrain or other off-road motor vehicle',
  'vietnamese': 'Người bị thương trong khi lên xuống xe địa hình hay xe máy địa hình',
}, {
  'id': 'V86.5',
  'english': 'Driver of all-terrain or other off-road motor vehicle injured in nontraffic accident',
  'vietnamese': 'Lái xe của xe địa hình hay xe máy địa hình bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V86.6',
  'english': 'Passenger of all-terrain or other off-road motor vehicle injured in nontraffic accident',
  'vietnamese': 'Người đi xe địa hình hay xe máy địa hình bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V86.7',
  'english': 'Person on outside of all-terrain or other off-road motor vehicle injured in nontraffic accident',
  'vietnamese': 'Người ở bên ngoài xe địa hình hay xe máy địa hình bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V86.9',
  'english': 'Unspecified occupant of all-terrain or other off-road motor vehicle injured in nontraffic accident',
  'vietnamese': 'Người trên xe địa hình hay xe máy địa hình không rõ đặc điểm bị thương không phải do tai nạn giao thông',
}, {
  'id': 'V87',
  'english': 'Traffic accident of specified type but victim s mode of transport unknown',
  'vietnamese': 'Tai nạn giao thông (tai nạn giao thông) không rõ phương tiện vận tải của nạn nhân',
}, {
  'id': 'V87.0',
  'english': 'Person injured in collision between car and two- or three-wheeled motor vehicle (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe mô tô 2 hay 3 bánh (do giao thông)',
}, {
  'id': 'V87.1',
  'english': 'Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa xe động cơ và mô tô 2 hay 3 bánh (do giao thông)',
}, {
  'id': 'V87.2',
  'english': 'Person injured in collision between car and pick-up truck or van (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe tải thu góp hay xe tải (do giao thông)',
}, {
  'id': 'V87.3',
  'english': 'Person injured in collision between car and bus (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe buýt (do giao thông)',
}, {
  'id': 'V87.4',
  'english': 'Person injured in collision between car and heavy transport vehicle (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe tải nặng (do giao thông)',
}, {
  'id': 'V87.5',
  'english': 'Person injured in collision between heavy transport vehicle and bus (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa xe tải nặng và xe bus (do giao thông)',
}, {
  'id': 'V87.6',
  'english': 'Person injured in collision between railway train or railway vehicle and car (traffic)',
  'vietnamese': 'Người bị thương trong va chạm giữa tầu hỏa hay phương tiện đi trên ray và ô tô (do giao thông)',
}, {
  'id': 'V87.7',
  'english': 'Person injured in collision between other specified motor vehicles (traffic)',
  'vietnamese': 'Người bị thương trong va chạm các xe động cơ đặc chủng khác (do giao thông)',
}, {
  'id': 'V87.8',
  'english': 'Person injured in other specified noncollision transport accidents involving motor vehicle (traffic)',
  'vietnamese': 'Người bị thương trong tai nạn giao thông biết đặc điểm không có va chạm của xe có động cơ (do giao thông)',
}, {
  'id': 'V87.9',
  'english': 'Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle (traffic)',
  'vietnamese': 'Người bị thương trong tai nạn giao thông biết đặc điểm (có va chạm) (không va chạm) của xe không có động cơ (do giao thông)',
}, {
  'id': 'V88',
  'english': 'Nontraffic accident of specified type but victim s mode of transport unknown',
  'vietnamese': 'Tai nạn không phải do tai nạn giao thông đặc biệt nhưng không biết phương tiện giao thông của nạn nhân',
}, {
  'id': 'V88.0',
  'english': 'Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và mô tô 2 hay 3 bánh (không phải do giao thông)',
}, {
  'id': 'V88.1',
  'english': 'Person injured in collision between other motor vehicle and two- or three-wheeled motor vehicle, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa xe có động cơ khác và mô tô 2 hay 3 bánh không phải do giao thông',
}, {
  'id': 'V88.2',
  'english': 'Person injured in collision between car and pick-up truck or van, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe tải thu góp hay tải không phải do giao thông',
}, {
  'id': 'V88.3',
  'english': 'Person injured in collision between car and bus, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe bus không phải do giao thông',
}, {
  'id': 'V88.4',
  'english': 'Person injured in collision between car and heavy transport vehicle, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa ô tô và xe tải nặng không phải do giao thông',
}, {
  'id': 'V88.5',
  'english': 'Person injured in collision between heavy transport vehicle and bus, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa xe tải nặng và bus không phải do giao thông',
}, {
  'id': 'V88.6',
  'english': 'Person injured in collision between railway train or railway vehicle and car, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa tầu hỏa hay phương tiện đi trên ray với ô tô không phải giao thông',
}, {
  'id': 'V88.7',
  'english': 'Person injured in collision between other specified motor vehicles, nontraffic',
  'vietnamese': 'Người bị thương trong va chạm giữa các xe có động cơ biết rõ đặc điểm khác không phải giao thông',
}, {
  'id': 'V88.8',
  'english': 'Person injured in other specified noncollision transport accidents involving motor vehicle, nontraffic',
  'vietnamese': 'Người bị thương trong tai nạn giao thông biết rõ đặc điểm khác, không có va chạm, liên quan đến xe động cơ, không phải do giao thông',
}, {
  'id': 'V88.9',
  'english': 'Person injured in other specified (collision)(noncollision) transport accidents involving nonmotor vehicle, nontraffic',
  'vietnamese': 'Người bị thương trong tai nạn giao thông khác biết rõ đặc điểm (có va chạm) (không có va chạm) liên quan đến xe không động cơ, không phải do giao thông',
}, {
  'id': 'V89',
  'english': 'Motor- or nonmotor-vehicle accident, type of vehicle unspecified',
  'vietnamese': 'Tai nạn xe có hay không có động cơ không biết loại xe',
}, {
  'id': 'V89.0',
  'english': 'Person injured in unspecified motor-vehicle accident, nontraffic',
  'vietnamese': 'Người bị thương trong tai nạn xe động cơ không biết rõ đặc điểm, không phải do giao thông',
}, {
  'id': 'V89.1',
  'english': 'Person injured in unspecified nonmotor-vehicle accident, nontraffic',
  'vietnamese': 'Người bị thương trong tai nạn xe không có động cơ, không rõ đặc điểm, không phải do giao thông không phải do giao thông',
}, {
  'id': 'V89.2',
  'english': 'Person injured in unspecified motor-vehicle accident, traffic',
  'vietnamese': 'Người bị thương trong tai nạn xe có động cơ không biết rõ đặc điểm, giao thông',
}, {
  'id': 'V89.3',
  'english': 'Person injured in unspecified nonmotor-vehicle accident, traffic',
  'vietnamese': 'Người bị thương trong tai nạn xe không động cơ không biết rõ đặc điểm, giao thông',
}, {
  'id': 'V89.9',
  'english': 'Person injured in unspecified vehicle accident',
  'vietnamese': 'Người bị thương trong Tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'V90',
  'english': 'Accident to watercraft causing drowning and submersion',
  'vietnamese': 'Tai nạn đường thủy gây ngã xuống nước, ngập nước',
}, {
  'id': 'V91',
  'english': 'Accident to watercraft causing other injury',
  'vietnamese': 'Tai nạn phương tiện giao thông thủy gây thương tổn khác',
}, {
  'id': 'V92',
  'english': 'Water-transport-related drowning and submersion without accident to watercraft',
  'vietnamese': 'Ngã và ngạt nước liên quan tới phương tiện giao thông đường thủy mà không có tai nạn do phương tiện gây ra',
}, {
  'id': 'V93',
  'english': 'Accident on board watercraft without accident to watercraft, not causing drowning and submersion',
  'vietnamese': 'Tai nạn trên phương tiện thủy không có tai nạn của phương tịên, không gây ngã xuống nước hay ngập nước',
}, {
  'id': 'V94',
  'english': 'Other and unspecified water transport accidents',
  'vietnamese': 'Các tai nạn giao thông không rõ đặc điểm khác',
}, {
  'id': 'V95',
  'english': 'Accident to powered aircraft causing injury to occupant',
  'vietnamese': 'Tai nạn do phương tiện bay có động cơ gây tổn thương cho người đi',
}, {
  'id': 'V95.0',
  'english': 'Helicopter accident injuring occupant',
  'vietnamese': 'Tai nạn máy bay trực thăng làm bị thương người đi máy bay',
}, {
  'id': 'V95.1',
  'english': 'Ultralight, microlight or powered-glider accident injuring occupant',
  'vietnamese': 'Tai nạn tầu lượn hay có động cơ gây thương tích cho hành khách đi tàu',
}, {
  'id': 'V95.2',
  'english': 'Accident to other private fixed-wing aircraft, injuring occupant',
  'vietnamese': 'Tai nạn của máy bay tư, cánh cố định khác, gây thương tổn người đi máy bay',
}, {
  'id': 'V95.3',
  'english': 'Accident to commercial fixed-wing aircraft, injuring occupant',
  'vietnamese': 'Tai nạn của máy bay thương mại cánh cố định, gây thương tổn cho người đi máy bay',
}, {
  'id': 'V95.4',
  'english': 'Spacecraft accident injuring occupant',
  'vietnamese': 'Tai nạn tầu vũ trụ làm thương tổn người trên tầu',
}, {
  'id': 'V95.8',
  'english': 'Other aircraft accidents injuring occupant',
  'vietnamese': 'Tai nạn máy bay khác làm thương tổn người trên máy bay',
}, {
  'id': 'V95.9',
  'english': 'Unspecified aircraft accident injuring occupant',
  'vietnamese': 'Tai nạn máy bay khác không rõ đặc điểm làm thương tổn người trên máy bay',
}, {
  'id': 'V96',
  'english': 'Accident to nonpowered aircraft causing injury to occupant',
  'vietnamese': 'Tai nạn của phương tiện bay không động cơ làm bị thương người trên tầu',
}, {
  'id': 'V96.0',
  'english': 'Balloon accident injuring occupant',
  'vietnamese': 'Tai nạn kinh khí cầu tổn thương người đi trên đó',
}, {
  'id': 'V96.1',
  'english': 'Hang-glider accident injuring occupant',
  'vietnamese': 'Tai nạn tầu lượn treo tổn thương người đi trên đó',
}, {
  'id': 'V96.2',
  'english': 'Glider (nonpowered) accident injuring occupant',
  'vietnamese': 'Tai nạn tầu lượn (không động cơ) tổn thương người đi trên đó',
}, {
  'id': 'V96.8',
  'english': 'Other nonpowered-aircraft accidents injuring occupant',
  'vietnamese': 'Các tai nạn phương tiện bay không động cơ khác, thương tổn người đi trên đó',
}, {
  'id': 'V96.9',
  'english': 'Unspecified nonpowered-aircraft accident injuring occupant',
  'vietnamese': 'Tai nạn không rõ đặc điểm của phương tiện động cơ gây thương tổn người đi trên đó',
}, {
  'id': 'V97',
  'english': 'Other specified air transport accidents',
  'vietnamese': 'Các tai nạn do phương tịên bay đặc biệt khác',
}, {
  'id': 'V97.0',
  'english': 'Occupant of aircraft injured in other specified air transport accidents',
  'vietnamese': 'Người ở trên phương tiện bay bị thương trong tai nạn hàng không đặc biệt khác',
}, {
  'id': 'V97.1',
  'english': 'Person injured while boarding or alighting from aircraft',
  'vietnamese': 'Người bị thương khi lên xuống máy bay',
}, {
  'id': 'V97.2',
  'english': 'Parachutist injured in air transport accident',
  'vietnamese': 'Người nhẩy dù bị thương trong tai nạn hàng không',
}, {
  'id': 'V97.3',
  'english': 'Person on ground injured in air transport accident',
  'vietnamese': 'Người ở mặt đất bị thương trong tai nạn hàng không',
}, {
  'id': 'V97.8',
  'english': 'Other air transport accidents, not elsewhere classified',
  'vietnamese': 'Các tai nạn hàng không khác chưa phân loại nơi khác',
}, {
  'id': 'V98',
  'english': 'Other specified transport accidents',
  'vietnamese': 'Các tai nạn giao thông khác',
}, {
  'id': 'V99',
  'english': 'Unspecified transport accident',
  'vietnamese': 'Tai nạn giao thông không rõ đặc điểm',
}, {
  'id': 'W00',
  'english': 'Fall on same level involving ice and snow',
  'vietnamese': 'Ngã trên cùng một mặt bằng liên quan băng và tuyết',
}, {
  'id': 'W01',
  'english': 'Fall on same level from slipping, tripping and stumbling',
  'vietnamese': 'Ngã trên cùng mặt bằng do trượt, vấp và lộn nhào',
}, {
  'id': 'W02',
  'english': 'Fall involving ice-skates, skis, roller-skates or skateboards',
  'vietnamese': 'Ngã liên quan trượt băng, ski, trượt bánh xe (patanh) hay trên thanh trượt',
}, {
  'id': 'W03',
  'english': 'Other fall on same level due to collision with, or pushing by, another person',
  'vietnamese': 'Ngã khác trên cùng mặt bằng do va chạm với, bị đẩy bởi người khác',
}, {
  'id': 'W04',
  'english': 'Fall while being carried or supported by other persons',
  'vietnamese': 'Ngã khi đang được người khác bế, vác',
}, {
  'id': 'W05',
  'english': 'Fall involving wheelchair',
  'vietnamese': 'Ngã liên quan đến ghế xe đẩy',
}, {'id': 'W06', 'english': 'Fall involving bed', 'vietnamese': 'Ngã liên quan đến giường'}, {
  'id': 'W07',
  'english': 'Fall involving chair',
  'vietnamese': 'Ngã ghế',
}, {
  'id': 'W08',
  'english': 'Fall involving other furniture',
  'vietnamese': 'Ngã liên quan đến đồ đạc khác',
}, {
  'id': 'W09',
  'english': 'Fall involving playground equipment',
  'vietnamese': 'Ngã liên quan đến đồ chơi ở sân',
}, {
  'id': 'W10',
  'english': 'Fall on and from stairs and steps',
  'vietnamese': 'Ngã trên và từ bậc thang, bậc thềm',
}, {'id': 'W11', 'english': 'Fall on and from ladder', 'vietnamese': 'Ngã trên và từ thang'}, {
  'id': 'W12',
  'english': 'Fall on and from scaffolding',
  'vietnamese': 'Ngã trên và từ dàn giáo',
}, {
  'id': 'W13',
  'english': 'Fall from, out of or through building or structure',
  'vietnamese': 'Ngã từ, ra ngoài hay qua nhà, kiến trúc',
}, {'id': 'W14', 'english': 'Fall from tree', 'vietnamese': 'Ngã cây'}, {
  'id': 'W15',
  'english': 'Fall from cliff',
  'vietnamese': 'Ngã vách đá',
}, {
  'id': 'W16',
  'english': 'Diving or jumping into water causing injury other than drowning or submersion',
  'vietnamese': 'Nhẩy lao đầu hay nhẩy xuống nước gây chấn thương khác với ngã xuống nước hay ngập nước',
}, {
  'id': 'W17',
  'english': 'Other fall from one level to another',
  'vietnamese': 'Ngã khác từ một mức này đến mức khác',
}, {'id': 'W18', 'english': 'Other fall on same level', 'vietnamese': 'Ngã khác cùng mức'}, {
  'id': 'W19',
  'english': 'Unspecified fall',
  'vietnamese': 'Ngã không rõ đặc điểm',
}, {
  'id': 'W20',
  'english': 'Struck by thrown, projected or falling object',
  'vietnamese': 'Bị tác động của vật ném, tung hay đang rơi',
}, {
  'id': 'W21',
  'english': 'Striking against or struck by sports equipment',
  'vietnamese': 'Va phải hay bị đập bởi dụng cụ thể thao',
}, {
  'id': 'W22',
  'english': 'Striking against or struck by other objects',
  'vietnamese': 'Va phải hay bị đập bởi vật khác',
}, {
  'id': 'W23',
  'english': 'Caught, crushed, jammed or pinched in or between objects',
  'vietnamese': 'Bị bắt, kẹp, ép hay cấu trong hay giữa các vật',
}, {
  'id': 'W24',
  'english': 'Contact with lifting and transmission devices, not elsewhere classified',
  'vietnamese': 'Tiếp xúc với dụng cụ nâng, truyền không được xếp loại ở nơi khác',
}, {'id': 'W25', 'english': 'Contact with sharp glass', 'vietnamese': 'Tiếp xúc với kính nhọn'}, {
  'id': 'W26',
  'english': 'Contact with knife, sword or dagger',
  'vietnamese': 'Tiếp xúc với dao, kiếm, dao găm',
}, {
  'id': 'W27',
  'english': 'Contact with nonpowered hand tool',
  'vietnamese': 'Tiếp xúc với dụng cụ thủ công không động cơ',
}, {
  'id': 'W28',
  'english': 'Contact with powered lawnmower',
  'vietnamese': 'Tiếp xúc với máy xén cỏ',
}, {
  'id': 'W29',
  'english': 'Contact with other powered hand tools and household machinery',
  'vietnamese': 'Tiếp xúc với dụng cụ thủ công có động cơ khác và máy nội trợ',
}, {
  'id': 'W30',
  'english': 'Contact with agricultural machinery',
  'vietnamese': 'Tiếp xúc với máy nông nghiệp',
}, {
  'id': 'W31',
  'english': 'Contact with other and unspecified machinery',
  'vietnamese': 'Tiếp xúc với máy khác không rõ đặc điểm',
}, {'id': 'W32', 'english': 'Handgun discharge', 'vietnamese': 'Đạn súng tay'}, {
  'id': 'W33',
  'english': 'Rifle, shotgun and larger firearm discharge',
  'vietnamese': 'Đạn súng có rãnh xoắn, súng ngắn, súng dài',
}, {
  'id': 'W34',
  'english': 'Discharge from other and unspecified firearms',
  'vietnamese': 'Đạn của súng tay khác không rõ đặc điểm',
}, {
  'id': 'W35',
  'english': 'Explosion and rupture of boiler',
  'vietnamese': 'Nổ vỡ bình hơi nước',
}, {
  'id': 'W36',
  'english': 'Explosion and rupture of gas cylinder',
  'vietnamese': 'Nổ, vỡ bình ga',
}, {
  'id': 'W37',
  'english': 'Explosion and rupture of pressurized tyre, pipe or hose',
  'vietnamese': 'Nổ và vỡ lốp, ống dẫn hay vòi có điều hòa áp lực',
}, {
  'id': 'W38',
  'english': 'Explosion and rupture of other specified pressurized devices',
  'vietnamese': 'Nổ và vỡ các dụng cụ khác không rõ đặc điểm có điều hòa áp lực',
}, {'id': 'W39', 'english': 'Discharge of firework', 'vietnamese': 'Nổ pháo hoa'}, {
  'id': 'W40',
  'english': 'Explosion of other materials',
  'vietnamese': 'Nổ vật tư khác',
}, {
  'id': 'W41',
  'english': 'Exposure to high-pressure jet',
  'vietnamese': 'Bị ảnh hưởng tia áp lực cao',
}, {'id': 'W42', 'english': 'Exposure to noise', 'vietnamese': 'Ảnh hưởng tiếng ồn'}, {
  'id': 'W43',
  'english': 'Exposure to vibration',
  'vietnamese': 'ảnh hưởng độ rung',
}, {
  'id': 'W44',
  'english': 'Foreign body entering into or through eye or natural orifice',
  'vietnamese': 'Dị vật vào trong hay đi qua mắt hay lỗ tự nhiên',
}, {
  'id': 'W45',
  'english': 'Foreign body or object entering through skin',
  'vietnamese': 'Dị vật vào qua da',
}, {
  'id': 'W46',
  'english': 'Contact with hypodermic needle',
  'vietnamese': 'Tiếp xúc với kim tiêm dưới da',
}, {
  'id': 'W49',
  'english': 'Exposure to other and unspecified inanimate mechanical forces',
  'vietnamese': 'Bị ảnh hưởng của lực cơ học bất động khác không rõ đặc điểm',
}, {
  'id': 'W50',
  'english': 'Hit, struck, kicked, twisted, bitten or scratched by another person',
  'vietnamese': 'Bị người khác đập đánh, đá, vặn, cắn, cào',
}, {
  'id': 'W51',
  'english': 'Striking against or bumped into by another person',
  'vietnamese': 'Bị người khác đánh lại hay húc vào',
}, {
  'id': 'W52',
  'english': 'Crushed, pushed or stepped on by crowd or human stampede',
  'vietnamese': 'Bị đám đông hay đám người chạy toán loạn ép, đẩy hay dẫm lên',
}, {'id': 'W53', 'english': 'Bitten by rat', 'vietnamese': 'Chuột cắn'}, {
  'id': 'W54',
  'english': 'Bitten or struck by dog',
  'vietnamese': 'Bị chó cắn, đả thương',
}, {
  'id': 'W55',
  'english': 'Bitten or struck by other mammals',
  'vietnamese': 'Cắn, đả thương do loài động vật có vú khác',
}, {
  'id': 'W56',
  'english': 'Contact with marine animal',
  'vietnamese': 'Tiếp xúc với động vật có vú ở dưới nước',
}, {
  'id': 'W57',
  'english': 'Bitten or stung by nonvenomous insect and other nonvenomous arthropods',
  'vietnamese': 'Bị côn trùng không có nọc và các loài tiết túc không có nọc độc cắn hay đốt',
}, {
  'id': 'W58',
  'english': 'Bitten or struck by crocodile or alligator',
  'vietnamese': 'Bị cá sấu, cá sấu Mỹ cắn hay đả thương',
}, {
  'id': 'W59',
  'english': 'Bitten or crushed by other reptiles',
  'vietnamese': 'Bị loài bò sát khác cắn hay kẹp',
}, {
  'id': 'W60',
  'english': 'Contact with plant thorns and spines and sharp leaves',
  'vietnamese': 'Tiếp xúc với cây gai, gai, lá nhọn',
}, {
  'id': 'W64',
  'english': 'Exposure to other and unspecified animate mechanical forces',
  'vietnamese': 'Bị ảnh hưởng của lực cơ học không xác định do chuyển động',
}, {
  'id': 'W65',
  'english': 'Drowning and submersion while in bath-tub',
  'vietnamese': 'Ngã xuống nước, ngập nước khi ở trong bồn tắm',
}, {
  'id': 'W66',
  'english': 'Drowning and submersion following fall into bath-tub',
  'vietnamese': 'Ngã xuống nước, ngập nước sau khi ngã vào bồn tắm',
}, {
  'id': 'W67',
  'english': 'Drowning and submersion while in swimming-pool',
  'vietnamese': 'Ngã xuống nước và đuối nước khi ở bể bơi',
}, {
  'id': 'W68',
  'english': 'Drowning and submersion following fall into swimming-pool',
  'vietnamese': 'Ngã xuống nước và đuối nước sau khi ngã vào bể bơi',
}, {
  'id': 'W69',
  'english': 'Drowning and submersion while in natural water',
  'vietnamese': 'Ngã xuống nước và đuối nước khi ở trong nước tự nhiên',
}, {
  'id': 'W70',
  'english': 'Drowning and submersion following fall into natural water',
  'vietnamese': 'Ngã xuống nước và đuối nước sau khi ngã xuống nước tự nhiên',
}, {
  'id': 'W73',
  'english': 'Other specified drowning and submersion',
  'vietnamese': 'Ngã xuống nước và đuối nước khác có rõ đặc điểm',
}, {
  'id': 'W74',
  'english': 'Unspecified drowning and submersion',
  'vietnamese': 'Ngã xuống nước và đuối nước không rõ đặc điểm',
}, {
  'id': 'W75',
  'english': 'Accidental suffocation and strangulation in bed',
  'vietnamese': 'Tai nạn ngạt thở, nghẹt thở trên giường',
}, {
  'id': 'W76',
  'english': 'Other accidental hanging and strangulation',
  'vietnamese': 'Tai nạn treo và thắt nghẹt khác',
}, {
  'id': 'W77',
  'english': 'Threat to breathing due to cave-in, falling earth and other substances',
  'vietnamese': 'Đe dọa thở do sụt đất, đất rơi và các chất khác',
}, {
  'id': 'W78',
  'english': 'Inhalation of gastric contents',
  'vietnamese': 'Hít phải chất chứa trong dạ dày',
}, {
  'id': 'W79',
  'english': 'Inhalation and ingestion of food causing obstruction of respiratory tract',
  'vietnamese': 'Hít và nuốt thức ăn gây tắc đường hô hấp',
}, {
  'id': 'W80',
  'english': 'Inhalation and ingestion of other objects causing obstruction of respiratory tract',
  'vietnamese': 'Hít và nuốt vật khác gây tắc đường thở',
}, {
  'id': 'W81',
  'english': 'Confined to or trapped in a low-oxygen environment',
  'vietnamese': 'Bị nhốt, mắc bẫy trong môi trường oxy thấp',
}, {
  'id': 'W83',
  'english': 'Other specified threats to breathing',
  'vietnamese': 'Các đe dọa thở khác, biết đặc điểm',
}, {
  'id': 'W84',
  'english': 'Unspecified threat to breathing',
  'vietnamese': 'Đe dọa thở không rõ đặc điểm',
}, {
  'id': 'W85',
  'english': 'Exposure to electric transmission lines',
  'vietnamese': 'Phơi nhiễm dây tải địên',
}, {
  'id': 'W86',
  'english': 'Exposure to other specified electric current',
  'vietnamese': 'Phơi nhiễm dòng điện biết rõ đặc điểm khác',
}, {
  'id': 'W87',
  'english': 'Exposure to unspecified electric current',
  'vietnamese': 'Phơi nhiễm dòng điện không rõ đặc điểm',
}, {
  'id': 'W88',
  'english': 'Exposure to ionizing radiation',
  'vietnamese': 'Phơi nhiễm tia xạ ion hóa',
}, {
  'id': 'W89',
  'english': 'Exposure to man-made visible and ultraviolet light',
  'vietnamese': 'Phơi nhiễm ánh sáng nhìn thấy nhân tạo và ánh sáng cực tím',
}, {
  'id': 'W90',
  'english': 'Exposure to other nonionizing radiation',
  'vietnamese': 'Phơi nhiễm tia xạ ion hóa khác',
}, {
  'id': 'W91',
  'english': 'Exposure to unspecified type of radiation',
  'vietnamese': 'Phơi nhiễm tia xạ không rõ đặc điểm',
}, {
  'id': 'W92',
  'english': 'Exposure to excessive heat of man-made origin',
  'vietnamese': 'Phơi nhiễm nhiệt độ cao quá mức do con người tạo ra',
}, {
  'id': 'W93',
  'english': 'Exposure to excessive cold of man-made origin',
  'vietnamese': 'Phơi nhiễm nhiệt lạnh quá mức do con người tạo ra',
}, {
  'id': 'W94',
  'english': 'Exposure to high and low air pressure and changes in air pressure',
  'vietnamese': 'Phơi nhiễm không khí cao áp, hạ áp và thay đổi áp lực',
}, {
  'id': 'W99',
  'english': 'Exposure to other and unspecified man-made environmental factors',
  'vietnamese': 'Phơi nhiễm với các yếu tố môi trường không đặc hiệu khác do con người tạo ra',
}, {
  'id': 'X00',
  'english': 'Exposure to uncontrolled fire in building or structure',
  'vietnamese': 'Phơi nhiễm cháy không kiểm soát được ở tòa nhà hay cấu trúc',
}, {
  'id': 'X01',
  'english': 'Exposure to uncontrolled fire, not in building or structure',
  'vietnamese': 'Phơi nhiễm cháy không kiểm soát được không phải từ nhà hay cấu trúc',
}, {
  'id': 'X02',
  'english': 'Exposure to controlled fire in building or structure',
  'vietnamese': 'Phơi nhiễm cháy nhà hay cấu trúc kiểm soát được',
}, {
  'id': 'X03',
  'english': 'Exposure to controlled fire, not in building or structure',
  'vietnamese': 'Phơi nhiễm cháy kiểm soát được nhưng không phải ở nhà hay cấu trúc',
}, {
  'id': 'X04',
  'english': 'Exposure to ignition of highly flammable material',
  'vietnamese': 'Phơi nhiễm của vật rất dễ cháy',
}, {
  'id': 'X05',
  'english': 'Exposure to ignition or melting of nightwear',
  'vietnamese': 'Phơi nhiễm quần áo ngủ cháy hay nóng chảy',
}, {
  'id': 'X06',
  'english': 'Exposure to ignition or melting of other clothing and apparel',
  'vietnamese': 'Phơi nhiễm áo quần hay dụng cụ bốc cháy hay nóng chảy',
}, {
  'id': 'X08',
  'english': 'Exposure to other specified smoke, fire and flames',
  'vietnamese': 'Phơi nhiễm khói, cháy và lửa rõ đặc điểm khác',
}, {
  'id': 'X09',
  'english': 'Exposure to unspecified smoke, fire and flames',
  'vietnamese': 'Phơi nhiễm khói, cháy và lửa không rõ đặc điểm',
}, {
  'id': 'X10',
  'english': 'Contact with hot drinks, food, fats and cooking oils',
  'vietnamese': 'Tiếp xúc với đồ uống, thức ăn, mỡ và dầu ăn nóng',
}, {
  'id': 'X11',
  'english': 'Contact with hot tap-water',
  'vietnamese': 'Tiếp xúc với nước máy nóng',
}, {
  'id': 'X12',
  'english': 'Contact with other hot fluids',
  'vietnamese': 'Tiếp xúc với chất lỏng nóng khác',
}, {
  'id': 'X13',
  'english': 'Contact with steam and hot vapours',
  'vietnamese': 'Tiếp xúc với hơi nước sôi và hơi nước nóng',
}, {
  'id': 'X14',
  'english': 'Contact with hot air and gases',
  'vietnamese': 'Tiếp xúc với không khí nóng, chất hơi nóng',
}, {
  'id': 'X15',
  'english': 'Contact with hot household appliances',
  'vietnamese': 'Tiếp xúc với vật dụng trong nhà nóng',
}, {
  'id': 'X16',
  'english': 'Contact with hot heating appliances, radiators and pipes',
  'vietnamese': 'Tiếp xúc với dụng cụ làm nóng, lò sưởi, ống dẫn',
}, {
  'id': 'X17',
  'english': 'Contact with hot engines, machinery and tools',
  'vietnamese': 'Tiếp xúc với máy, dụng cụ nóng',
}, {
  'id': 'X18',
  'english': 'Contact with other hot metals',
  'vietnamese': 'Tiếp xúc với kim loại nóng khác',
}, {
  'id': 'X19',
  'english': 'Contact with other and unspecified heat and hot substances',
  'vietnamese': 'Tiếp xúc với các vật làm nóng và chất nóng khác không rõ đặc điểm',
}, {
  'id': 'X20',
  'english': 'Contact with venomous snakes and lizards',
  'vietnamese': 'Tiếp xúc với rắn độc và thằn lằn độc',
}, {
  'id': 'X21',
  'english': 'Contact with venomous spiders',
  'vietnamese': 'Tiếp xúc với nhện độc',
}, {
  'id': 'X22',
  'english': 'Contact with scorpions',
  'vietnamese': 'Tiếp xúc với bọ cạp',
}, {
  'id': 'X23',
  'english': 'Contact with hornets, wasps and bees',
  'vietnamese': 'Tiếp xúc với các loại ong',
}, {
  'id': 'X24',
  'english': 'Contact with centipedes and venomous millipedes (tropical)',
  'vietnamese': 'Tiếp xúc với các loại rết',
}, {
  'id': 'X25',
  'english': 'Contact with other venomous arthropods',
  'vietnamese': 'Tiếp xúc với loại tiết túc có nọc độc, biết rõ đặc điểm',
}, {
  'id': 'X26',
  'english': 'Contact with venomous marine animals and plants',
  'vietnamese': 'Tiếp xúc với xúc vật và cây độc dưới nước',
}, {
  'id': 'X27',
  'english': 'Contact with other specified venomous animals',
  'vietnamese': 'Tiếp xúc với xúc vật có nọc độc khác, biết rõ đặc điểm',
}, {
  'id': 'X28',
  'english': 'Contact with other specified venomous plants',
  'vietnamese': 'Tiếp xúc với các cây độc khác biết rõ đặc điểm',
}, {
  'id': 'X29',
  'english': 'Contact with unspecified venomous animal or plant',
  'vietnamese': 'Tiếp xúc với xúc vật hay cây độc không rõ đặc điểm',
}, {
  'id': 'X30',
  'english': 'Exposure to excessive natural heat',
  'vietnamese': 'Phơi nhiễm nhiệt thiên nhiên quá mức',
}, {
  'id': 'X31',
  'english': 'Exposure to excessive natural cold',
  'vietnamese': 'Phơi nhiễm lạnh thiên nhiên quá mức',
}, {'id': 'X32', 'english': 'Exposure to sunlight', 'vietnamese': 'Phơi nhiễm nắng'}, {
  'id': 'X33',
  'english': 'Victim of lightning',
  'vietnamese': 'Nạn nhân của chớp',
}, {'id': 'X34', 'english': 'Exposure to forces of nature', 'vietnamese': 'Nạn nhân động đất'}, {
  'id': 'X34.0',
  'english': 'Victim of cataclysmic earth movements caused by earthquake',
  'vietnamese': 'Nạn nhân chuyển động lớn của đất do động đất gây ra',
}, {'id': 'X34.1', 'english': 'Victim of tsunamI', 'vietnamese': 'Nạn nhân sóng thần'}, {
  'id': 'X34.8',
  'english': 'Victim of other specified effects of earthquake',
  'vietnamese': 'Nạn nhân các ảnh hưởng của động đất xác định khác',
}, {
  'id': 'X34.9',
  'english': 'Victim of unspecified effect of earthquake',
  'vietnamese': 'Nạn nhân ảnh hưởng của động đất không xác định',
}, {
  'id': 'X35',
  'english': 'Victim of volcanic eruption',
  'vietnamese': 'Nạn nhân núi lửa phun',
}, {
  'id': 'X36',
  'english': 'Victim of avalanche, landslide and other earth movements',
  'vietnamese': 'Nạn nhân tuyết lở, đất trượt, hoặc các chuyển động khác của đất',
}, {
  'id': 'X37',
  'english': 'Victim of cataclysmic storm',
  'vietnamese': 'Nạn nhân của bão lớn',
}, {
  'id': 'X38',
  'english': 'Victim of flood',
  'vietnamese': 'Nạn nhân của lụt',
}, {
  'id': 'X39',
  'english': 'Exposure to other and unspecified forces of nature',
  'vietnamese': 'Phơi nhiễm các lực thiên nhiên khác không rõ đặc điểm',
}, {
  'id': 'X40',
  'english': 'Accidental poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics',
  'vietnamese': 'Tai nạn ngộ độc do phơi nhiễm với thuốc giảm đau không có thuốc phiện, hạ nhiệt và chống thấp khớp',
}, {
  'id': 'X41',
  'english': 'Accidental poisoning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Tai nạn ngộ độc do phơi nhiễm thuốc chống động kinh, an thần - gây ngủ, chống parkinson, tâm thần không được xếp loại ở nơi khác',
}, {
  'id': 'X42',
  'english': 'Accidental poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified',
  'vietnamese': 'Tai nạn ngộ độc do phơi nhiễm thuốc ngủ và thuốc hưng thần (sinh hoang tưởng) không được xếp loại nơi khác',
}, {
  'id': 'X43',
  'english': 'Accidental poisoning by and exposure to other drugs acting on the autonomic nervous system',
  'vietnamese': 'Tai nạn ngộ độc do phơi nhiễm thuốc khác tác động lên hệ thống thần kinh tự động',
}, {
  'id': 'X44',
  'english': 'Accidental poisoning by and exposure to other and unspecified drugs, medicaments and biological substances',
  'vietnamese': 'Tai nạn ngộ độc do phơi nhiễm các thuốc và sinh chất không rõ đặc điểm',
}, {
  'id': 'X45',
  'english': 'Accidental poisoning by and exposure to alcohol',
  'vietnamese': 'Tai nạn ngộ độc và phơi nhiễm rượu',
}, {
  'id': 'X46',
  'english': 'Accidental poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours',
  'vietnamese': 'Tai nạn ngộ do và phơi nhiễm dung môi hữu cơ và hydrat carbon halogen và các hơi của chúng',
}, {
  'id': 'X47',
  'english': 'Accidental poisoning by and exposure to other gases and vapours',
  'vietnamese': 'Tai nạn ngộ độc bởi và phơi nhiễm các khí và hơi khác',
}, {
  'id': 'X48',
  'english': 'Accidental poisoning by and exposure to pesticides',
  'vietnamese': 'Tai nạn ngộ độc bởi và phơi nhiễm chất diệt xúc vật có hại',
}, {
  'id': 'X49',
  'english': 'Accidental poisoning by and exposure to other and unspecified chemicals and noxious substances',
  'vietnamese': 'Tai nạn ngộ độc bởi và phơi nhiễm các chất hóa học và chất có hại khác không rõ đặc điểm',
}, {
  'id': 'X50',
  'english': 'Overexertion and strenuous or repetitive movements',
  'vietnamese': 'Cố gắng quá mức, và những động tác nặng nhọc hoặc lặp đi lặp lại',
}, {'id': 'X51', 'english': 'Travel and motion', 'vietnamese': 'Du lịch và vận động'}, {
  'id': 'X52',
  'english': 'Prolonged stay in weightless environment',
  'vietnamese': 'Ở lâu trong môi trường không trọng lượng',
}, {'id': 'X53', 'english': 'Lack of food', 'vietnamese': 'Thiếu thức ăn'}, {
  'id': 'X54',
  'english': 'Lack of water',
  'vietnamese': 'Thiếu nước',
}, {
  'id': 'X57',
  'english': 'Unspecified privation',
  'vietnamese': 'Thiếu thốn không rõ đặc điểm',
}, {
  'id': 'X58',
  'english': 'Exposure to other specified factors',
  'vietnamese': 'Phơi nhiễm các yếu tố khác có biết đặc điểm',
}, {
  'id': 'X59',
  'english': 'Exposure to unspecified factor',
  'vietnamese': 'Phơi nhiễm các yếu tố không rõ đặc điểm',
}, {
  'id': 'X59.0',
  'english': 'Exposure to unspecified factor causing fracture',
  'vietnamese': 'Phơi nhiễm với yếu tố không rõ đặc điểm gây gẫy xương',
}, {
  'id': 'X59.9',
  'english': 'Exposure to unspecified factor causing other and unspecified injury',
  'vietnamese': 'Phơi nhiễm với yếu tố không rõ đặc điểm và tổn thương không xác định',
}, {
  'id': 'X60',
  'english': 'Intentional self-poisoning by and exposure to nonopioid analgesics, antipyretics and antirheumatics',
  'vietnamese': 'Cố ý tự đầu độc (tự tử) bằng và phơi nhiễm chất giảm đau hạ sốt và chống thấp khớp không có thuốc phiện',
}, {
  'id': 'X61',
  'english': 'Intentional self-poisoning by and exposure to antiepileptic, sedative- hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm thuốc chống động kinh, an thần - gây ngủ, chống parkinson và thuốc tâm thần, không được xếp loại ở nơi khác',
}, {
  'id': 'X62',
  'english': 'Intentional self-poisoning by and exposure to narcotics and psychodysleptics [hallucinogens], not elsewhere classified',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm thuốc mê và hưng phấn tâm thần (gây hoang tưởng) không xếp loại ở chỗ khác',
}, {
  'id': 'X63',
  'english': 'Intentional self-poisoning by and\nexposure to other drugs acting on\nthe autonomic nervous system',
  'vietnamese': 'Cố tình tự đầu độc(tự tử) bằng và phơi nhiễm các thuốc khác tác dụng lên hệ thống thần kinh tự động',
}, {
  'id': 'X64',
  'english': 'Intentional self-poisoning by and exposure to other and unspecified drugs, medicaments and biological substances',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm các thuốc và sinh chất không rõ đặc điểm khác',
}, {
  'id': 'X65',
  'english': 'Intentional self-poisoning by and exposure to alcohol',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm dung môi hữu cơ,\nhydrocacbon halogen và hơi của chúng',
}, {
  'id': 'X66',
  'english': 'Intentional self-poisoning by and exposure to organic solvents and halogenated hydrocarbons and their vapours',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm dung môi hữu cơ,\nhydrocacbon halogen và hơi của chúng',
}, {
  'id': 'X67',
  'english': 'Intentional self-poisoning by and exposure to other gases and vapours',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm các khí và hơi khác',
}, {
  'id': 'X68',
  'english': 'Intentional self-poisoning by and exposure to pesticides',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm chất diệt súc vật',
}, {
  'id': 'X69',
  'english': 'Intentional self-poisoning by and\nexposure to other and unspecified\nchemicals and noxious substances',
  'vietnamese': 'Cố tình tự đầu độc (tự tử) bằng và phơi nhiễm các hóa chất và chất có hại khác không rõ đặc điểm',
}, {
  'id': 'X70',
  'english': 'Intentional self-harm by hanging, strangulation and suffocation',
  'vietnamese': 'Cố tình tự hại (tự tử) bằng treo, làm nghẹt và ngạt thở',
}, {
  'id': 'X71',
  'english': 'Intentional self-harm by drowning and submersion',
  'vietnamese': 'Cố tình tự hại (tự tử) bằng nhảy xuống nước, trầm mình dưới nước',
}, {
  'id': 'X72',
  'english': 'Intentional self-harm by handgun discharge',
  'vietnamese': 'Cố tình tự hại (tự tử) bằng đạn súng ngắn',
}, {
  'id': 'X73',
  'english': 'Intentional self-harm by rifle, shotgun and larger firearm discharge',
  'vietnamese': 'Cố tình tự hại bằng súng trường, súng dài hơn',
}, {
  'id': 'X74',
  'english': 'Intentional self-harm by other and unspecified firearm discharge',
  'vietnamese': 'Cố tình tự hại bằng súng khác không rõ đặc điểm',
}, {
  'id': 'X75',
  'english': 'Intentional self-harm by explosive material',
  'vietnamese': 'Cố tình tự (tự tử) hại bằng chất nổ',
}, {
  'id': 'X76',
  'english': 'Intentional self-harm by smoke, fire and flames',
  'vietnamese': 'Cố tình tự hại bằng khói, cháy và lửa',
}, {
  'id': 'X77',
  'english': 'Intentional self-harm by steam, hot vapours and hot objects',
  'vietnamese': 'Cố tình tự hại bằng hơi nước, hơi nóng và vật nóng',
}, {
  'id': 'X78',
  'english': 'Intentional self-harm by sharp object',
  'vietnamese': 'Cố tình tự hại bằng vật nhọn',
}, {
  'id': 'X79',
  'english': 'Intentional self-harm by blunt object',
  'vietnamese': 'Cố tình tự hại bằng vật tầy',
}, {
  'id': 'X80',
  'english': 'Intentional self-harm by jumping from a high place',
  'vietnamese': 'Cố tình tự hại bằng nhảy từ 1 chỗ\ncao',
}, {
  'id': 'X81',
  'english': 'Intentional self-harm by jumping or lying before moving object',
  'vietnamese': 'Cố tình tự hại bằng nhẩy hay nằm ra trước vật đang chuyển động',
}, {
  'id': 'X82',
  'english': 'Intentional self-harm by crashing of motor vehicle',
  'vietnamese': 'Cố tình tự hại bằng đâm xe động cơ',
}, {
  'id': 'X83',
  'english': 'Intentional self-harm by other specified means',
  'vietnamese': 'Cố tình tự hại bằng các phương tiện khác có biết đặc điểm',
}, {
  'id': 'X84',
  'english': 'Intentional self-harm by unspecified means',
  'vietnamese': 'Cố tình tự hại bằng các phương tiện không rõ đặc điểm',
}, {
  'id': 'X85',
  'english': 'Assault by drugs, medicaments and biolocigal substances',
  'vietnamese': 'Tấn công bằng thuốc, dược chất và chất sinh học',
}, {
  'id': 'X86',
  'english': 'Assault by corrosive substance',
  'vietnamese': 'Tấn công bằng chất ăn da',
}, {
  'id': 'X87',
  'english': 'Assault by pesticides',
  'vietnamese': 'Tấn công bằng chất diệt súc vật',
}, {
  'id': 'X88',
  'english': 'Assault by gases and vapours',
  'vietnamese': 'Tấn công bằng khí và hơi nước',
}, {
  'id': 'X89',
  'english': 'Assault by other specified chemicals and noxious substances',
  'vietnamese': 'Tấn công bằng hóa chất hay chất có hại khác biết rõ đặc điểm',
}, {
  'id': 'X90',
  'english': 'Assault by other unspecified chemicals and noxious substances',
  'vietnamese': 'Tấn công bằng hóa chất hay chất có hại không rõ đặc điểm',
}, {
  'id': 'X91',
  'english': 'Assault by hanging, strangulation and suffocation',
  'vietnamese': 'Tấn công bằng treo, làm nghẹt, ngạt thở',
}, {
  'id': 'X92',
  'english': 'Assault by drowning and submersion',
  'vietnamese': 'Tấn công bằng bỏ xuống nước, làm ngập nước',
}, {
  'id': 'X93',
  'english': 'Assault by handgun discharge',
  'vietnamese': 'Tấn công bằng đạn súng tay',
}, {
  'id': 'X94',
  'english': 'Assault by rifle, shotgun and larger firearm discharge',
  'vietnamese': 'Tấn công bằng súng trường, súng ngắn và súng lớn hơn',
}, {
  'id': 'X95',
  'english': 'Assault by other and unspecified firearm discharge',
  'vietnamese': 'Tấn công bằng súng khác và không rõ đặc điểm',
}, {
  'id': 'X96',
  'english': 'Assault by explosive material',
  'vietnamese': 'Tấn công bằng chất nổ',
}, {
  'id': 'X97',
  'english': 'Assault by smoke, fire and flames',
  'vietnamese': 'Tấn công bằng khói, cháy và lửa',
}, {
  'id': 'X98',
  'english': 'Assault by steam, hot vapours and hot objects',
  'vietnamese': 'Tấn công bằng hơi nước, hơi nước nóng và vật nóng',
}, {
  'id': 'X99',
  'english': 'Assault by sharp object',
  'vietnamese': 'Tấn công bằng vật nhọn, sắc',
}, {
  'id': 'Y00',
  'english': 'Assault by blunt object',
  'vietnamese': 'Tấn công bằng vật tù',
}, {
  'id': 'Y01',
  'english': 'Assault by pushing from high place',
  'vietnamese': 'Tấn công bằng đẩy từ chỗ cao',
}, {
  'id': 'Y02',
  'english': 'Assault by pushing or placing victim before moving object',
  'vietnamese': 'Tấn công bằng đẩy hay đặt nạn nhân trước vật đang chuyển động',
}, {
  'id': 'Y03',
  'english': 'Assault by crashing of motor vehicle',
  'vietnamese': 'Tấn công bằng đâm xe động cơ',
}, {
  'id': 'Y04',
  'english': 'Assault by bodily force',
  'vietnamese': 'Tấn công bằng sức cơ thể',
}, {
  'id': 'Y05',
  'english': 'Sexual assault by bodily force',
  'vietnamese': 'Tấn công tình dục bằng sức người',
}, {'id': 'Y06', 'english': 'Neglect and abandonment', 'vietnamese': 'Cẩu thả hay bỏ rơi'}, {
  'id': 'Y06.0',
  'english': 'By spouse or partner',
  'vietnamese': 'Do vợ - chồng hay người cộng tác',
}, {'id': 'Y06.1', 'english': 'By parent', 'vietnamese': 'Do cha mẹ'}, {
  'id': 'Y06.2',
  'english': 'By acquaintance or friend',
  'vietnamese': 'Do người quen hay bạn',
}, {
  'id': 'Y06.8',
  'english': 'By other specified persons',
  'vietnamese': 'Do người khác, biết rõ đặc điểm',
}, {
  'id': 'Y06.9',
  'english': 'By unspecified person',
  'vietnamese': 'Do người khác, không biết rõ đặc điểm',
}, {
  'id': 'Y07',
  'english': 'Other maltreatment syndromes',
  'vietnamese': 'Các hội chứng hành hạ khác',
}, {
  'id': 'Y07.0',
  'english': 'By spouse or partner',
  'vietnamese': 'Do vợ - chồng hay người cộng tác',
}, {'id': 'Y07.1', 'english': 'By parent', 'vietnamese': 'Do cha mẹ'}, {
  'id': 'Y07.2',
  'english': 'By acquaintance or friend',
  'vietnamese': 'Do người quen hay bạn',
}, {
  'id': 'Y07.3',
  'english': 'By official authorities',
  'vietnamese': 'Do công chức có thẩm quyền',
}, {
  'id': 'Y07.8',
  'english': 'By other specified persons',
  'vietnamese': 'Do người khác, biết đặc điểm',
}, {
  'id': 'Y07.9',
  'english': 'By unspecified person',
  'vietnamese': 'Do người không rõ đặc điểm',
}, {
  'id': 'Y08',
  'english': 'Assault by other specified means',
  'vietnamese': 'Tấn công bằng phương tiện khác, biết đặc điểm',
}, {
  'id': 'Y09',
  'english': 'Assault by unspecified means',
  'vietnamese': 'Tấn công bằng phương tiện khác không rõ đặc điểm',
}, {
  'id': 'Y10',
  'english': 'Poisioning by and exposure to nonopinoid analgesics, antipyretics and antirheumatics, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm thuốc giảm đau, hạ sốt, chống thấp không có thuốc phiện, không rõ ý đồ',
}, {
  'id': 'Y11',
  'english': 'Poisioning by and exposure to antiepileptic, sedative-hypnotic, antiparkinsonism and psychotropic drugs, not elsewhere classified and undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm thuốc chống động kinh, an thần-gây ngủ, chống parkinson và hướng thần, không được xếp loại ở chỗ khác, không rõ ý đồ',
}, {
  'id': 'Y12',
  'english': 'Poisioning by and exposure to narcotics and psychodisleptics (hallucinogens), not elsewhere classified and undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm thuốc mê và thuốc làm hưng phấn tinh thần (sinh hoang tưởng) không xếp loại ở nơi khác, không rõ ý đồ',
}, {
  'id': 'Y13',
  'english': 'Poisioning by and exposure to other drugs acting on the autonomic nervous system, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm các thuốc khác tác dụng trên hệ thần kinh tự động không rõ ý đồ',
}, {
  'id': 'Y14',
  'english': 'Poisioning by and exposure to other and unspecified drugs, medicaments and biological substances, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm thuốc, dược chất, sinh chất khác không rõ đặc điểm, không rõ ý đồ',
}, {
  'id': 'Y15',
  'english': 'Poisioning by and exposure to alcohol, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm rượu, không rõ ý đồ',
}, {
  'id': 'Y16',
  'english': 'Poisioning by and exposure to organic solvents and halogenated hydrocarbons and their vapours, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm chất dung môi hữu cơ và hologenat carbon và các chất hơi của chúng, không rõ ý đồ',
}, {
  'id': 'Y17',
  'english': 'Poisioning by and exposure to other gases and vapours, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm các khí và hơi khác, không rõ ý đồ',
}, {
  'id': 'Y18',
  'english': 'Poisioning by and exposure to pesticides, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm chất diệt súc vật, không rõ ý đồ',
}, {
  'id': 'Y19',
  'english': 'Poisioning by and exposure to other and unspecified chemicals and noxious substances, undetermined intent',
  'vietnamese': 'Ngộ độc và phơi nhiễm với hóa chất và chất có hại khác không rõ đặc điểm, không rõ ý đồ',
}, {
  'id': 'Y20',
  'english': 'Hanging, strangulation and suffocation, undetermined intent',
  'vietnamese': 'Treo, làm nghẹt, làm ngạt thở, không rõ ý đồ',
}, {
  'id': 'Y21',
  'english': 'Drowning and submersion, undetermined intent',
  'vietnamese': 'Ngã xuống nước, ngập nước, không rõ ý đồ',
}, {
  'id': 'Y22',
  'english': 'Handgun discharge, undetermined intent',
  'vietnamese': 'Đạn súng tay không rõ ý đồ',
}, {
  'id': 'Y23',
  'english': 'Rifle, shotgun and larger firearm discharge, undetermined intent',
  'vietnamese': 'Đạn súng trường, súng ngắn và súng to hơn không rõ ý đồ',
}, {
  'id': 'Y24',
  'english': 'Other and unspecified firearm discharge, undetermined intent',
  'vietnamese': 'Đạn súng khác và không rõ đặc điểm, không rõ ý đồ',
}, {
  'id': 'Y25',
  'english': 'Contact with explosive material, undetermined intent',
  'vietnamese': 'Tiếp xúc chất nổ, không rõ ý đồ',
}, {
  'id': 'Y26',
  'english': 'Exposure to smoke, fire, flames, undetermined intent',
  'vietnamese': 'Phơi nhiễm khói, cháy và lửa, không rõ ý đồ',
}, {
  'id': 'Y27',
  'english': 'Contact with steam, hot vapours and hot objects, undetermined intent',
  'vietnamese': 'Phơi nhiễm hơi nước, hơi nước nóng và vật nóng, không rõ ý đồ',
}, {
  'id': 'Y28',
  'english': 'Contact with sharp object, undetermined intent',
  'vietnamese': 'Tiếp xúc vật nhọn, sắc, không rõ ý đồ',
}, {
  'id': 'Y29',
  'english': 'Contact with blunt object, undetermined intent',
  'vietnamese': 'Tiếp xúc vật tù, không rõ ý đồ',
}, {
  'id': 'Y30',
  'english': 'Falling, jumping or pushed from a high place, undetermined intent',
  'vietnamese': 'Ngã, nhảy hay bị đẩy từ một chỗ cao, không rõ ý đồ',
}, {
  'id': 'Y31',
  'english': 'Falling, or lying or running before or into moving object, undetermined intent',
  'vietnamese': 'Ngã, nằm hay chạy trước hay chạy vào vật đang chuyển động, không rõ ý đồ',
}, {
  'id': 'Y32',
  'english': 'Crashing of motor vehicle, undetermined intent',
  'vietnamese': 'Bị mô tô cán không rõ ý đồ',
}, {
  'id': 'Y33',
  'english': 'Other specified events, undetermined intent',
  'vietnamese': 'Các biến cố khác có biết rõ đặc điểm, không rõ ý đồ',
}, {
  'id': 'Y34',
  'english': 'Unspecified events, undetermined intent',
  'vietnamese': 'Biến cố không rõ đặc điểm, không rõ ý đồ',
}, {'id': 'Y35', 'english': 'Legal intervention', 'vietnamese': 'Can thiệp hợp pháp'}, {
  'id': 'Y35.0',
  'english': 'Legal intervention involving firearm discharge',
  'vietnamese': 'Can thiệp hợp pháp liên quan đến đạn súng tay',
}, {
  'id': 'Y35.1',
  'english': 'Legal intervention involving explosives',
  'vietnamese': 'Can thiệp hợp pháp liên quan đến chất nổ',
}, {
  'id': 'Y35.2',
  'english': 'Legal intervention involving gas',
  'vietnamese': 'Can thiệp hợp pháp liên quan đến khí',
}, {
  'id': 'Y35.3',
  'english': 'Legal intervention involving blunt objects',
  'vietnamese': 'Can thiệp hợp pháp liên quan vật tầy',
}, {
  'id': 'Y35.4',
  'english': 'Legal intervention involving sharp objects',
  'vietnamese': 'Can thiệp hợp pháp liên quan vật sắc nhọn',
}, {'id': 'Y35.5', 'english': 'Legal execution', 'vietnamese': 'Hành hình hợp pháp'}, {
  'id': 'Y35.6',
  'english': 'Legal intervention involving other specified means',
  'vietnamese': 'Can thiệp hợp pháp liên quan các biện pháp khác có được biết rõ',
}, {
  'id': 'Y35.7',
  'english': 'Legal intervention, means unspecified',
  'vietnamese': 'Can thiệp hợp pháp, không biết rõ biện pháp',
}, {'id': 'Y36', 'english': 'Operations of war', 'vietnamese': 'Hành động trong chiến tranh'}, {
  'id': 'Y36.0',
  'english': 'War operations involving explosion of marine weapons',
  'vietnamese': 'Hoạt động chiến tranh do hỏa khí của lính thủy gây ra',
}, {
  'id': 'Y36.1',
  'english': 'War operations involving destruction of aircraft',
  'vietnamese': 'Hoạt động chiến tranh liên quan đến phá hủy phương tiện bay',
}, {
  'id': 'Y36.2',
  'english': 'War operations involving other explosions and fragments',
  'vietnamese': 'Hoạt động chiến tranh liên quan đến chất nổ và bom khác',
}, {
  'id': 'Y36.3',
  'english': 'War operations involving fires, conflagrations and hot substances',
  'vietnamese': 'Hành động chiến tranh liên quan cháy, cháy to và chất nóng',
}, {
  'id': 'Y36.4',
  'english': 'War operations involving firearm discharge and other forms of conventional warfare',
  'vietnamese': 'Hành động chiến tranh liên quan đạn súng tay và các loại thông thường khác trong chiến tranh',
}, {
  'id': 'Y36.5',
  'english': 'War operations involving nuclear weapons',
  'vietnamese': 'Hoạt động chiến tranh liên quan vũ khí hạt nhân',
}, {
  'id': 'Y36.6',
  'english': 'War operations involving biological weapons',
  'vietnamese': 'Hoạt động chiến tranh liên quan vũ khí sinh học',
}, {
  'id': 'Y36.7',
  'english': 'War operations involving chemical weapons and other forms of unconventional warfare',
  'vietnamese': 'Hoạt động chiến tranh liên quan đến vũ khí hóa học và các vũ khí khác không theo qui ước chiến tranh',
}, {
  'id': 'Y36.8',
  'english': 'War operations occurring after cessation of hostilities',
  'vietnamese': 'Hoạt động chiến tranh xẩy ra sau khi đã ngừng hận thù',
}, {
  'id': 'Y36.9',
  'english': 'War operations, unspecified',
  'vietnamese': 'Chiến tranh, không rõ đặc điểm',
}, {'id': 'Y40', 'english': 'Systemic antibiotics', 'vietnamese': 'Kháng sinh toàn thân'}, {
  'id': 'Y40.0',
  'english': 'Penicillins',
  'vietnamese': 'Penicillin',
}, {
  'id': 'Y40.1',
  'english': 'Cefalosporins and other beta-lactam antibiotics',
  'vietnamese': 'Cefalosporin và các KS β - lactam khác',
}, {'id': 'Y40.2', 'english': 'Chloramphenicol group', 'vietnamese': 'Nhóm chloramphenicol'}, {
  'id': 'Y40.3',
  'english': 'Macrolides',
  'vietnamese': 'Macrolid',
}, {'id': 'Y40.4', 'english': 'Tetracyclines', 'vietnamese': 'Tetracyclin'}, {
  'id': 'Y40.5',
  'english': 'Aminoglycosides',
  'vietnamese': 'Aminoglycosid',
}, {'id': 'Y40.6', 'english': 'Rifamycins', 'vietnamese': 'Rifamycin'}, {
  'id': 'Y40.7',
  'english': 'Antifungal antibiotics, systemically used',
  'vietnamese': 'KS chống nấm dùng đường toàn thân',
}, {
  'id': 'Y40.8',
  'english': 'Other systemic antibiotics',
  'vietnamese': 'Các KS dùng đường toàn thân khác',
}, {
  'id': 'Y40.9',
  'english': 'Systemic antibiotic, unspecified',
  'vietnamese': 'KS toàn thân không rõ đặc điểm',
}, {
  'id': 'Y41',
  'english': 'Other systemic anti-infectives and antiparasitics',
  'vietnamese': 'Các loại kháng khuẩn và ký sinh vật, đường toàn thân',
}, {'id': 'Y41.0', 'english': 'Sulfonamides', 'vietnamese': 'Sulfonamid'}, {
  'id': 'Y41.1',
  'english': 'Antimycobacterial drugs',
  'vietnamese': 'Thuốc chống mycobacter',
}, {
  'id': 'Y41.2',
  'english': 'Antimalarials and drugs acting on other blood protozoa',
  'vietnamese': 'Chống sốt rét và thuốc tác dụng trên đơn bào khác trong máu',
}, {
  'id': 'Y41.3',
  'english': 'Other antiprotozoal drugs',
  'vietnamese': 'Thuốc chống đơn bào khác',
}, {'id': 'Y41.4', 'english': 'Anthelminthics', 'vietnamese': 'Thuốc giun'}, {
  'id': 'Y41.5',
  'english': 'Antiviral drugs',
  'vietnamese': 'Thuốc kháng virus',
}, {
  'id': 'Y41.8',
  'english': 'Other specified systemic anti-infectives and antiparasitics',
  'vietnamese': 'Các thuốc đường toàn thân chống nhiễm khuẩn và ký sinh vật khác, có biết rõ đặc điểm',
}, {
  'id': 'Y41.9',
  'english': 'Systemic anti-infective and antiparasitic, unspecified',
  'vietnamese': 'Thuốc đường toàn thân chống nhiễm khuẩn và ký sinh vật không rõ đặc điểm',
}, {
  'id': 'Y42',
  'english': 'Hormones and their synthetic substitutes and antagonists, not elsewhere classified',
  'vietnamese': 'Nội tiết tố và các chế phẩm tổng hợp của nó và thuốc đối kháng, không được xếp loại ở chỗ khác',
}, {
  'id': 'Y42.0',
  'english': 'Glucocorticoids and synthetic analogues',
  'vietnamese': 'Glucocorticoid và chất tổng hợp',
}, {
  'id': 'Y42.1',
  'english': 'Thyroid hormones and substitutes',
  'vietnamese': 'Hormon giáp trạng và chế phẩm',
}, {'id': 'Y42.2', 'english': 'Antithyroid drugs', 'vietnamese': 'Thuốc kháng giáp trạng'}, {
  'id': 'Y42.3',
  'english': 'Insulin and oral hypoglycaemic [antidiabetic] drugs',
  'vietnamese': 'Insulin và thuốc hạ đường huyết đường uống (chống đái tháo đường)',
}, {
  'id': 'Y42.4',
  'english': 'Oral contraceptives',
  'vietnamese': 'chống thụ thai thuốc đường uống',
}, {
  'id': 'Y42.5',
  'english': 'Other estrogens and progestogens',
  'vietnamese': 'Các thuốc estrogen và progestogen khác',
}, {
  'id': 'Y42.6',
  'english': 'Antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified',
  'vietnamese': 'Kháng gonadotrophin, kháng estrogen, kháng androgen không được xếp loại ở nơi khác',
}, {
  'id': 'Y42.7',
  'english': 'Androgens and anabolic congeners',
  'vietnamese': 'Androgen và các chất dị hóa',
}, {
  'id': 'Y42.8',
  'english': 'Other and unspecified hormones and their synthetic substitutes',
  'vietnamese': 'Các hormon khác, không rõ đặc điểm và các chất thay thế tổng hợp của chúng',
}, {
  'id': 'Y42.9',
  'english': 'Other and unspecified hormone antagonists',
  'vietnamese': 'Các loại chống nội tiết tố khác và không rõ đặc điểm',
}, {
  'id': 'Y43',
  'english': 'Primarily systemic agents',
  'vietnamese': 'Chất tác dụng toàn thân trước tiên',
}, {
  'id': 'Y43.0',
  'english': 'Antiallergic and antiemetic drugs',
  'vietnamese': 'Thuốc chống dị ứng và nôn',
}, {
  'id': 'Y43.1',
  'english': 'Antineoplastic antimetabolites',
  'vietnamese': 'Chống chuyển hóa chống sinh u',
}, {
  'id': 'Y43.2',
  'english': 'Antineoplastic natural products',
  'vietnamese': 'Sản phẩm thiên nhiên chống sinh u',
}, {
  'id': 'Y43.3',
  'english': 'Other antineoplastic drugs',
  'vietnamese': 'Thuốc chống sinh u khác',
}, {
  'id': 'Y43.4',
  'english': 'Immunosuppressive agents',
  'vietnamese': 'Chất làm giảm miễn dịch',
}, {
  'id': 'Y43.5',
  'english': 'Acidifying and alkalizing agents',
  'vietnamese': 'Chất làm toan hóa và kiềm hóa',
}, {
  'id': 'Y43.6',
  'english': 'Enzymes, not elsewhere classified',
  'vietnamese': 'Men, không được xếp loại ở nơi khác',
}, {
  'id': 'Y43.8',
  'english': 'Other primarily systemic agents, not elsewhere classified',
  'vietnamese': 'Các chất tác dụng hệ thống đầu tiên, không được xếp loại ở chỗ khác',
}, {
  'id': 'Y43.9',
  'english': 'Primarily systemic agent, unspecified',
  'vietnamese': 'Chất tác dụng chủ yếu khác cho hệ thống không đặc hiệu',
}, {
  'id': 'Y44',
  'english': 'Agents primarily affecting blood constituents',
  'vietnamese': 'Chất tác dụng trước tiên lên các thành phần của máu',
}, {
  'id': 'Y44.0',
  'english': 'Iron preparations and other anti-hypochromic-anaemia preparations',
  'vietnamese': 'Chế phẩm sắt và những chế phẩm khác chống thiếu máu nhược sắc',
}, {
  'id': 'Y44.1',
  'english': 'Vitamin B12, folic acid and other anti-megaloblastic-anaemia preparations',
  'vietnamese': 'Vitamin B12, acid folic và những chế phẩm khác chống thiếu máu đại huyết bào non (megaloblast)',
}, {'id': 'Y44.2', 'english': 'Anticoagulants', 'vietnamese': 'Chống đông máu'}, {
  'id': 'Y44.3',
  'english': 'Anticoagulant antagonists, vitamin K and other coagulants',
  'vietnamese': 'Chất đối kháng chống đông máu, vitamin K và các chất làm đông máu',
}, {
  'id': 'Y44.4',
  'english': 'Antithrombotic drugs [platelet-aggregation inhibitors]',
  'vietnamese': 'Thuốc chống huyết khối (ức chế ngưng tụ tiểu cầu)',
}, {'id': 'Y44.5', 'english': 'Thrombolytic drugs', 'vietnamese': 'Thuốc làm tan huyết khối'}, {
  'id': 'Y44.6',
  'english': 'Natural blood and blood products',
  'vietnamese': 'Máu tự nhiên và sản phẩm máu',
}, {'id': 'Y44.7', 'english': 'Plasma substitutes', 'vietnamese': 'Chất thay thế huyết tương'}, {
  'id': 'Y44.9',
  'english': 'Other and unspecified agents affecting blood constituents',
  'vietnamese': 'Chất khác và không rõ đặc điểm tác dụng lên các thành phần của máu',
}, {
  'id': 'Y45',
  'english': 'Analgesics, antipyretics and anti-inflammatory drugs',
  'vietnamese': 'Thuốc giảm đau, hạ sốt và chống viêm',
}, {
  'id': 'Y45.0',
  'english': 'Opioids and related analgesics',
  'vietnamese': 'Thuốc phiện và các chất giảm đau có liên quan',
}, {'id': 'Y45.1', 'english': 'Salicylates', 'vietnamese': 'Salicylat'}, {
  'id': 'Y45.2',
  'english': 'Propionic acid derivatives',
  'vietnamese': 'Dẫn xuất acid propionic',
}, {
  'id': 'Y45.3',
  'english': 'Other nonsteroidal anti-inflammatory drugs [NSAID]',
  'vietnamese': 'Thuốc kháng viêm khác không phải steroid',
}, {'id': 'Y45.4', 'english': 'Antirheumatics', 'vietnamese': 'Chống thấp'}, {
  'id': 'Y45.5',
  'english': '4-Aminophenol derivatives',
  'vietnamese': 'Dẫn xuất 4 aminophenol',
}, {
  'id': 'Y45.8',
  'english': 'Other analgesics and antipyretics',
  'vietnamese': 'Thuốc giảm đau và hạ sốt khác',
}, {
  'id': 'Y45.9',
  'english': 'Analgesic, antipyretic and anti-inflammatory drug, unspecified',
  'vietnamese': 'Thuốc giảm đau, hạ sốt và chống viêm không rõ đặc điểm',
}, {
  'id': 'Y46',
  'english': 'Antiepileptics and antiparkinsonism drugs',
  'vietnamese': 'Thuốc chống động kinh và Parkinson',
}, {'id': 'Y46.0', 'english': 'Succinimides', 'vietnamese': 'Succinimid'}, {
  'id': 'Y46.1',
  'english': 'Oxazolidinediones',
  'vietnamese': 'Oxazolidinedion',
}, {'id': 'Y46.2', 'english': 'Hydantoin derivatives', 'vietnamese': 'Dẫn xuất hydantoin'}, {
  'id': 'Y46.3',
  'english': 'Deoxybarbiturates',
  'vietnamese': 'Deoxybarbiturat',
}, {'id': 'Y46.4', 'english': 'Iminostilbenes', 'vietnamese': 'Iminostilben'}, {
  'id': 'Y46.5',
  'english': 'Valproic acid',
  'vietnamese': 'Acid valproic',
}, {
  'id': 'Y46.6',
  'english': 'Other and unspecified antiepileptics',
  'vietnamese': 'Thuốc chống động kinh khác và không rõ đặc điểm',
}, {'id': 'Y46.7', 'english': 'Antiparkinsonism drugs', 'vietnamese': 'Thuốc chống Parkinson'}, {
  'id': 'Y46.8',
  'english': 'Antispasticity drugs',
  'vietnamese': 'Thuốc chống liệt cứng',
}, {
  'id': 'Y47',
  'english': 'Sedatives, hypnotics and antianxiety drugs',
  'vietnamese': 'Thuốc an thần, gây ngủ và chống lo lắng',
}, {
  'id': 'Y47.0',
  'english': 'Barbiturates, not elsewhere classified',
  'vietnamese': 'Barbiturat, không được xếp loại ở nơi khác',
}, {'id': 'Y47.1', 'english': 'Benzodiazepines', 'vietnamese': 'Benzodiazepin'}, {
  'id': 'Y47.2',
  'english': 'Cloral derivatives',
  'vietnamese': 'Dẫn xuất cloral',
}, {'id': 'Y47.3', 'english': 'Paraldehyde', 'vietnamese': 'Paraldehyd'}, {
  'id': 'Y47.4',
  'english': 'Bromine compounds',
  'vietnamese': 'Chế phẩm brom',
}, {
  'id': 'Y47.5',
  'english': 'Mixed sedatives and hypnotics, not elsewhere classified',
  'vietnamese': 'Thuốc kết hợp an thần và gây ngủ, không được xếp loại ở nơi khác',
}, {
  'id': 'Y47.8',
  'english': 'Other sedatives, hypnotics and antianxiety drugs',
  'vietnamese': 'Các thuốc an thần, gây ngủ và chống lo lắng khác',
}, {
  'id': 'Y47.9',
  'english': 'Sedative, hypnotic and antianxiety drug, unspecified',
  'vietnamese': 'Thuốc an thần, gây ngủ và chống lo lắng không rõ đặc điểm',
}, {
  'id': 'Y48',
  'english': 'Anaesthetics and therapeutic gases',
  'vietnamese': 'Khí gây mê và điều trị',
}, {'id': 'Y48.0', 'english': 'Inhaled anaesthetics', 'vietnamese': 'Thuốc gây mê hô hấp'}, {
  'id': 'Y48.1',
  'english': 'Parenteral anaesthetics',
  'vietnamese': 'Thuốc gây mê tiêm',
}, {
  'id': 'Y48.2',
  'english': 'Other and unspecified general anaesthetics',
  'vietnamese': 'Thuốc gây mê toàn thân khác và không rõ đặc điểm',
}, {'id': 'Y48.3', 'english': 'Local anaesthetics', 'vietnamese': 'Thuốc tê tại chỗ'}, {
  'id': 'Y48.4',
  'english': 'Anaesthetic, unspecified',
  'vietnamese': 'Thuốc mê - tê, không rõ đặc điểm',
}, {'id': 'Y48.5', 'english': 'Therapeutic gases', 'vietnamese': 'Khí điều trị'}, {
  'id': 'Y49',
  'english': 'Psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Thuốc hướng thần không được xếp loại ở nơi khác',
}, {
  'id': 'Y49.0',
  'english': 'Tricyclic and tetracyclic antidepressants',
  'vietnamese': 'Thuốc chống trầm cảm 3 vòng và 4 vòng',
}, {
  'id': 'Y49.1',
  'english': 'Monoamine-oxidase-inhibitor antidepressants',
  'vietnamese': 'Thuốc chống trầm cảm ức chế men monoamin-oxidase',
}, {
  'id': 'Y49.2',
  'english': 'Other and unspecified antidepressants',
  'vietnamese': 'Các thuốc chống trầm cảm khác và không rõ đặc điểm',
}, {
  'id': 'Y49.3',
  'english': 'Phenothiazine antipsychotics and neuroleptics',
  'vietnamese': 'Ức chế tâm thần và an thần loại phenothiazin',
}, {
  'id': 'Y49.4',
  'english': 'Butyrophenone and thioxanthene neuroleptics',
  'vietnamese': 'An thần loại butyrophenon và thioxanthen',
}, {
  'id': 'Y49.5',
  'english': 'Other antipsychotics and neuroleptics',
  'vietnamese': 'Ức chế tâm thần và an thần khác',
}, {
  'id': 'Y49.6',
  'english': 'Psychodysleptics [hallucinogens]',
  'vietnamese': 'Thuốc gây ảo giác (sinh hoang tưởng)',
}, {
  'id': 'Y49.7',
  'english': 'Psychostimulants with abuse potential',
  'vietnamese': 'Thuốc kích thích tâm thần và lạm dụng thuốc',
}, {
  'id': 'Y49.8',
  'english': 'Other psychotropic drugs, not elsewhere classified',
  'vietnamese': 'Các thuốc hướng thần khác chưa được xếp loại ở nơi khác',
}, {
  'id': 'Y49.9',
  'english': 'Psychotropic drug, unspecified',
  'vietnamese': 'Thuốc hướng thần không rõ đặc điểm',
}, {
  'id': 'Y50',
  'english': 'Central nervous system stimulants, not elsewhere classified',
  'vietnamese': 'Kích thích hệ thần kinh trung ương, không xếp loại ở nơi khác',
}, {'id': 'Y50.0', 'english': 'Analeptics', 'vietnamese': 'Thuốc hồi sức'}, {
  'id': 'Y50.1',
  'english': 'Opioid receptor antagonists',
  'vietnamese': 'Chất đối kháng thụ cảm opioid',
}, {
  'id': 'Y50.2',
  'english': 'Methylxanthines, not elsewhere classified',
  'vietnamese': 'Methylxanthin không được xếp loại ở nơi khác',
}, {
  'id': 'Y50.8',
  'english': 'Other central nervous system stimulants',
  'vietnamese': 'Chất kích thích hệ thần kinh trung ương khác',
}, {
  'id': 'Y50.9',
  'english': 'Central nervous system stimulant, unspecified',
  'vietnamese': 'Chất kích thích hệ thần kinh trung ương không rõ đặc điểm',
}, {
  'id': 'Y51',
  'english': 'Drugs primarily affecting the autonomic nervous system',
  'vietnamese': 'Thuốc tác dụng chủ yếu trên hệ thần kinh tự động',
}, {
  'id': 'Y51.0',
  'english': 'Anticholinesterase agents',
  'vietnamese': 'Chất kháng cholinesterase',
}, {
  'id': 'Y51.1',
  'english': 'Other parasympathomimetics [cholinergics]',
  'vietnamese': 'Chất giống phó giao cảm khác (cholinergic)',
}, {
  'id': 'Y51.2',
  'english': 'Ganglionic blocking drugs, not elsewhere classified',
  'vietnamese': 'Thuốc chặn hạch, không xếp loại ở nơi khác',
}, {
  'id': 'Y51.3',
  'english': 'Other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics, not elsewhere classified',
  'vietnamese': 'Các thuốc giải phó giao cảm (kháng cholinergic và kháng muscarin) và giảm co bóp khác, không xếp loại ở nơi khác',
}, {
  'id': 'Y51.4',
  'english': 'Predominantly alpha-adrenoreceptor agonists, not elsewhere classified',
  'vietnamese': 'Chất ưu tiên cảm thụ  adrenergic, không xếp loại ở nơi khác',
}, {
  'id': 'Y51.5',
  'english': 'Predominantly beta-adrenoreceptor agonists, not elsewhere classified',
  'vietnamese': 'Chất ưu tiên trên cảm thụ  adrenergic, không xếp loại ở nơi khác',
}, {
  'id': 'Y51.6',
  'english': 'Alpha-adrenoreceptor antagonists, not elsewhere classified',
  'vietnamese': 'Chất đối kháng cảm thụ  adrenalin, không xếp loại ở nơi khác',
}, {
  'id': 'Y51.7',
  'english': 'Beta-adrenoreceptor antagonists, not elsewhere classified',
  'vietnamese': 'Chất đối kháng cảm thụ , không xếp loại nơi khác',
}, {
  'id': 'Y51.8',
  'english': 'Centrally acting and adrenergic-neuron-blocking agents, not elsewhere classified',
  'vietnamese': 'Chất hoạt động ở trung ương và chẹn hệ adrenergic không xếp loại chỗ khác',
}, {
  'id': 'Y51.9',
  'english': 'Other and unspecified drugs primarily affecting the autonomic nervous system',
  'vietnamese': 'Thuốc khác, không rõ đặc điểm, tác dụng trước hết lên hệ thần kinh tự động',
}, {
  'id': 'Y52',
  'english': 'Agents primarily affecting the cardiovascular system',
  'vietnamese': 'Thuốc tác dụng chủ yếu lên hệ tim mạch',
}, {
  'id': 'Y52.0',
  'english': 'Cardiac-stimulant glycosides and drugs of similar action',
  'vietnamese': 'Glycosid kích thích tim, và thuốc có tác dụng tương tự',
}, {
  'id': 'Y52.1',
  'english': 'Calcium-channel blockers',
  'vietnamese': 'Thuốc chẹn kênh calci',
}, {
  'id': 'Y52.2',
  'english': 'Other antidysrhythmic drugs, not elsewhere classified',
  'vietnamese': 'Các thuốc chống loạn nhịp khác, không xếp loại chỗ khác',
}, {
  'id': 'Y52.3',
  'english': 'Coronary vasodilators, not elsewhere classified',
  'vietnamese': 'Dãn mạch vành, không xếp loại chỗ khác',
}, {
  'id': 'Y52.4',
  'english': 'Angiotensin-converting-enzyme inhibitors',
  'vietnamese': 'Chất ức chế men chuyển angiotensin',
}, {
  'id': 'Y52.5',
  'english': 'Other antihypertensive drugs, not elsewhere classified',
  'vietnamese': 'Các thuốc chống cao huyết áp khác không xếp loại chỗ khác',
}, {
  'id': 'Y52.6',
  'english': 'Antihyperlipidaemic and antiarteriosclerotic drugs',
  'vietnamese': 'Thuốc chống tăng mỡ máu và chống xơ động mạch',
}, {
  'id': 'Y52.7',
  'english': 'Peripheral vasodilators',
  'vietnamese': 'Thuốc dãn mạch ngoại vi',
}, {
  'id': 'Y52.8',
  'english': 'Antivaricose drugs, including sclerosing agents',
  'vietnamese': 'Thuốc chống dãn tĩnh mạch, bao gồm chất gây xơ',
}, {
  'id': 'Y52.9',
  'english': 'Other and unspecified agents primarily affecting the cardiovascular system',
  'vietnamese': 'Thuốc khác và không rõ đặc điểm tác dụng trước tiên lên hệ tim mạch',
}, {
  'id': 'Y53',
  'english': 'Agents primarily affecting the gastrointestinal system',
  'vietnamese': 'Thuốc tác dụng chủ yếu đến hệ tiêu hóa',
}, {
  'id': 'Y53.0',
  'english': 'Histamine H2-receptor antagonists',
  'vietnamese': 'Chất đối kháng thụ thể histamln H2',
}, {
  'id': 'Y53.1',
  'english': 'Other antacids and anti-gastric-secretion drugs',
  'vietnamese': 'Thuốc kháng acid và kháng tiết toan dạ dày khác',
}, {'id': 'Y53.2', 'english': 'Stimulant laxatives', 'vietnamese': 'Kích thích nhuận tràng'}, {
  'id': 'Y53.3',
  'english': 'Saline and osmotic laxatives',
  'vietnamese': 'Nhuận tràng muối và thẩm thấu',
}, {'id': 'Y53.4', 'english': 'Other laxatives', 'vietnamese': 'Nhuận tràng khác'}, {
  'id': 'Y53.5',
  'english': 'Digestants',
  'vietnamese': 'Thuốc lợi tiểu hóa',
}, {'id': 'Y53.6', 'english': 'Antidiarrhoeal drugs', 'vietnamese': 'Thuốc chống ỉa chảy'}, {
  'id': 'Y53.7',
  'english': 'Emetics',
  'vietnamese': 'Gây nôn',
}, {
  'id': 'Y53.8',
  'english': 'Other agents primarily affecting the gastrointestinal system',
  'vietnamese': 'Thuốc khác tác dụng trước hết lên hệ tiêu hóa',
}, {
  'id': 'Y53.9',
  'english': 'Agent primarily affecting the gastrointestinal system, unspecified',
  'vietnamese': 'Thuốc không rõ đặc điểm tác dụng trước hết lên hệ tiêu hóa',
}, {
  'id': 'Y54',
  'english': 'Agents primarily affecting water-balance and mineral and uric acid metabolism',
  'vietnamese': 'Chất tác dụng trước hết lên cồn bằng nước và chuyển hóa chất khóang và acid uric',
}, {
  'id': 'Y54.0',
  'english': 'Mineralocorticoids',
  'vietnamese': 'Corticoiid chuyển hóa muối nước',
}, {
  'id': 'Y54.1',
  'english': 'Mineralocorticoid antagonists [aldosterone antagonists]',
  'vietnamese': 'Thuốc đối kháng corticod chuyển hóa muối nước (đối kháng aldosteron)',
}, {
  'id': 'Y54.2',
  'english': 'Carbonic-anhydrase inhibitors',
  'vietnamese': 'Ức chế carbonic-anhydrase',
}, {
  'id': 'Y54.3',
  'english': 'Benzothiadiazine derivatives',
  'vietnamese': 'Dẫn xuất benzothladiazin',
}, {'id': 'Y54.4', 'english': 'Loop [high-ceiling] diuretics', 'vietnamese': 'Lợi tiểu vòng'}, {
  'id': 'Y54.5',
  'english': 'Other diuretics',
  'vietnamese': 'Thuốc lợi tiểu khác',
}, {
  'id': 'Y54.6',
  'english': 'Electrolytic, caloric and water-balance agents',
  'vietnamese': 'Chất cân bằng điện giải, năng lượng và nước',
}, {
  'id': 'Y54.7',
  'english': 'Agents affecting calcification',
  'vietnamese': 'Chất ảnh hưởng calci hóa',
}, {
  'id': 'Y54.8',
  'english': 'Agents affecting uric acid metabolism',
  'vietnamese': 'Chất ảnh hưởng tới chuyển hóa acid uric',
}, {
  'id': 'Y54.9',
  'english': 'Mineral salts, not elsewhere classified',
  'vietnamese': 'Muối khóang, không xếp loại chỗ khác',
}, {
  'id': 'Y55',
  'english': 'Agents primarily acting on smooth and skeletal muscles and the respiratory system',
  'vietnamese': 'Chất tác dụng trước lên cơ trơn, cơ vân và hệ hô hấp',
}, {'id': 'Y55.0', 'english': 'Oxytocic drugs', 'vietnamese': 'Thuốc trợ đẻ (oxytocin)'}, {
  'id': 'Y55.1',
  'english': 'Skeletal muscle relaxants [neuromuscular blocking agents]',
  'vietnamese': 'Thuốc dãn cơ vân (chất chẹn thần kinh cơ)',
}, {
  'id': 'Y55.2',
  'english': 'Other and unspecified agents primarily acting on muscles',
  'vietnamese': 'Thuốc khác, không rõ đặc điểm tác dụng đầu tiên lên cơ',
}, {'id': 'Y55.3', 'english': 'Antitussives', 'vietnamese': 'Chống ho'}, {
  'id': 'Y55.4',
  'english': 'Expectorants',
  'vietnamese': 'Gây long đờn',
}, {'id': 'Y55.5', 'english': 'Anti-common-cold drugs', 'vietnamese': 'Thuốc chống cảm cúm'}, {
  'id': 'Y55.6',
  'english': 'Antiasthmatics, not elsewhere classified',
  'vietnamese': 'Chống hen không xếp chỗ khác',
}, {
  'id': 'Y55.7',
  'english': 'Other and unspecified agents primarily acting on the respiratory system',
  'vietnamese': 'Thuốc khác và không rõ đặc điểm tác dụng trước hết lên hệ hô hấp',
}, {
  'id': 'Y56',
  'english': 'Topical agents primarily affecting skin and mucous membrane and ophthalmological, otorhinolaryngological and dental drugs',
  'vietnamese': 'Tác dụng tại chỗ, trước hết lên da và niêm mạc và thuốc mắt, tai mũi họng răng',
}, {
  'id': 'Y56.0',
  'english': 'Local antifungal, anti-infective and anti-inflammatory drugs, not elsewhere classified',
  'vietnamese': 'Thuốc tại chỗ chống nấm, chống nhiễm khuẩn và chống viêm không xếp loại chỗ khác',
}, {'id': 'Y56.1', 'english': 'Antipruritics', 'vietnamese': 'Chống ngứa'}, {
  'id': 'Y56.2',
  'english': 'Local astringents and local detergents',
  'vietnamese': 'Gây se tại chỗ, tẩy tại chỗ',
}, {
  'id': 'Y56.3',
  'english': 'Emollients, demulcents and protectants',
  'vietnamese': 'Làm mềm, làm dịu, bảo vệ',
}, {
  'id': 'Y56.4',
  'english': 'Keratolytics, keratoplastics and other hair treatment drugs and preparations',
  'vietnamese': 'Thuốc và chế phẩm phân giải keratin, tạo keratin và chữa tóc khác',
}, {
  'id': 'Y56.5',
  'english': 'Ophthalmological drugs and preparations',
  'vietnamese': 'Thuốc và chế phẩm nhãn khoa',
}, {
  'id': 'Y56.6',
  'english': 'Otorhinolaryngological drugs and preparations',
  'vietnamese': 'Thuốc và chế phẩm tai mũi họng',
}, {
  'id': 'Y56.7',
  'english': 'Dental drugs, topically applied',
  'vietnamese': 'Thuốc răng dùng tại chỗ',
}, {'id': 'Y56.8', 'english': 'Other topical agents', 'vietnamese': 'Các thuốc tại chỗ khác'}, {
  'id': 'Y56.9',
  'english': 'Topical agent, unspecified',
  'vietnamese': 'Thuốc tại chỗ không rõ đặc điểm',
}, {
  'id': 'Y57',
  'english': 'Other and unspecified drugs and medicaments',
  'vietnamese': 'Thuốc và dược chất khác không đặc hiệu',
}, {
  'id': 'Y57.0',
  'english': 'Appetite depressants [anorectics]',
  'vietnamese': 'Giảm ngon miệng (làm chán ăn)',
}, {'id': 'Y57.1', 'english': 'Lipotropic drugs', 'vietnamese': 'Thuốc hướng mỡ'}, {
  'id': 'Y57.2',
  'english': 'Antidotes and chelating agents, not elsewhere classified',
  'vietnamese': 'Chất giải độc và chất chelating - không xếp loại chỗ khác',
}, {'id': 'Y57.3', 'english': 'Alcohol deterrents', 'vietnamese': 'Thuốc giải rượu'}, {
  'id': 'Y57.4',
  'english': 'Pharmaceutical excipients',
  'vietnamese': 'Tá dược',
}, {'id': 'Y57.5', 'english': 'X-ray contrast media', 'vietnamese': 'Chất cản quang'}, {
  'id': 'Y57.6',
  'english': 'Other diagnostic agents',
  'vietnamese': 'Các chất dùng để chẩn đoán khác',
}, {
  'id': 'Y57.7',
  'english': 'Vitamins, not elsewhere classified',
  'vietnamese': 'Vitamin, không xếp loại nơi khác',
}, {
  'id': 'Y57.8',
  'english': 'Other drugs and medicaments',
  'vietnamese': 'Thuốc, dược chất khác',
}, {
  'id': 'Y57.9',
  'english': 'Drug or medicament, unspecified',
  'vietnamese': 'Thuốc, dược chất không rõ đặc điểm',
}, {'id': 'Y58', 'english': 'Bacterial vaccines', 'vietnamese': 'Vaccin vi khuẩn'}, {
  'id': 'Y58.0',
  'english': 'BCG vaccine',
  'vietnamese': 'Vaccin BCG',
}, {
  'id': 'Y58.1',
  'english': 'Typhoid and paratyphoid vaccine',
  'vietnamese': 'Vaccin thương hàn và phó thương hàn',
}, {'id': 'Y58.2', 'english': 'Cholera vaccine', 'vietnamese': 'Vaccin tả'}, {
  'id': 'Y58.3',
  'english': 'Plague vaccine',
  'vietnamese': 'Vaccin dịch hạch',
}, {'id': 'Y58.4', 'english': 'Tetanus vaccine', 'vietnamese': 'Vaccin uốn ván'}, {
  'id': 'Y58.5',
  'english': 'Diphtheria vaccine',
  'vietnamese': 'Vaccin bạch hầu',
}, {
  'id': 'Y58.6',
  'english': 'Pertussis vaccine, including combinations with a pertussis component',
  'vietnamese': 'Vaccin ho gà, bao gồm phối hợp với 1 thành phần ho gà',
}, {
  'id': 'Y58.8',
  'english': 'Mixed bacterial vaccines, except combinations with a pertussis component',
  'vietnamese': 'Vaccin phối hợp vi khuẩn, trừ phối hợp 1 thành phần ho gà',
}, {
  'id': 'Y58.9',
  'english': 'Other and unspecified bacterial vaccines',
  'vietnamese': 'Vaccin khác và không rõ đặc điểm',
}, {
  'id': 'Y59',
  'english': 'Other and unspecified vaccines and biological substances',
  'vietnamese': 'Các Vaccin và sinh chất khác, không đặc hiệu',
}, {'id': 'Y59.0', 'english': 'Viral vaccines', 'vietnamese': 'Vaccin virus'}, {
  'id': 'Y59.1',
  'english': 'Rickettsial vaccines',
  'vietnamese': 'Vaccin Rickettsia',
}, {'id': 'Y59.2', 'english': 'Protozoal vaccines', 'vietnamese': 'Vaccin đơn bào'}, {
  'id': 'Y59.3',
  'english': 'Immunoglobulin',
  'vietnamese': 'Globulin miễn dịch',
}, {
  'id': 'Y59.8',
  'english': 'Other specified vaccines and biological substances',
  'vietnamese': 'Các Vaccin và sinh chất đặc biệt khác',
}, {
  'id': 'Y59.9',
  'english': 'Vaccine or biological substance, unspecified',
  'vietnamese': 'Các Vaccin và sinh chất không đặc hiệu',
}, {
  'id': 'Y60',
  'english': 'Unintentional cut, puncture, perforation or haemorrhage during surgical and medical care',
  'vietnamese': 'Không cố ý cắt, chọc, làm thủng, chảy máu trong trong phẫu thuật và thủ thuật',
}, {'id': 'Y60.0', 'english': 'During surgical operation', 'vietnamese': 'Trong khi mổ'}, {
  'id': 'Y60.1',
  'english': 'During infusion or transfusion',
  'vietnamese': 'Trong truyền dịch hay máu',
}, {
  'id': 'Y60.2',
  'english': 'During kidney dialysis or other perfusion',
  'vietnamese': 'Trong chạy thận nhân tạo hay truyền khác',
}, {
  'id': 'Y60.3',
  'english': 'During injection or immunization',
  'vietnamese': 'Trong khi tiêm hay gây miễn dịch',
}, {
  'id': 'Y60.4',
  'english': 'During endoscopic examination',
  'vietnamese': 'Trong khám nội soi',
}, {
  'id': 'Y60.5',
  'english': 'During heart catheterization',
  'vietnamese': 'Trong thông tim',
}, {
  'id': 'Y60.6',
  'english': 'During aspiration, puncture and other catheterization',
  'vietnamese': 'Trong hút, chọc hay thông khác',
}, {'id': 'Y60.7', 'english': 'During administration of enema', 'vietnamese': 'Trong khi thụt'}, {
  'id': 'Y60.8',
  'english': 'During other surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa khác',
}, {
  'id': 'Y60.9',
  'english': 'During unspecified surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa không đặc biệt',
}, {
  'id': 'Y61',
  'english': 'Foreign object accidentally left in body during surgical and medical care',
  'vietnamese': 'Dị vật nhỡ để quên trong cơ thể trong khi chăm sóc nội, ngoại khoa',
}, {'id': 'Y61.0', 'english': 'During surgical operation', 'vietnamese': 'Trong mổ'}, {
  'id': 'Y61.1',
  'english': 'During infusion or transfusion',
  'vietnamese': 'Trong truyền dịch hay máu',
}, {
  'id': 'Y61.2',
  'english': 'During kidney dialysis or other perfusion',
  'vietnamese': 'Trong chạy thận nhân tạo hay truyền khác',
}, {
  'id': 'Y61.3',
  'english': 'During injection or immunization',
  'vietnamese': 'Trong tiêm hay gây miễn dịch',
}, {
  'id': 'Y61.4',
  'english': 'During endoscopic examination',
  'vietnamese': 'Trong khám nội soi',
}, {
  'id': 'Y61.5',
  'english': 'During heart catheterization',
  'vietnamese': 'Trong thông tim',
}, {
  'id': 'Y61.6',
  'english': 'During aspiration, puncture and other catheterization',
  'vietnamese': 'Trong hút, chọc và thông khác',
}, {
  'id': 'Y61.7',
  'english': 'During removal of catheter or packing',
  'vietnamese': 'Trong khi rút catheter hay bọc lại',
}, {
  'id': 'Y61.8',
  'english': 'During other surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa khác',
}, {
  'id': 'Y61.9',
  'english': 'During unspecified surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa không xác định chính xác',
}, {
  'id': 'Y62',
  'english': 'Failure of sterile precautions during surgical and medical care',
  'vietnamese': 'Không đảm bảo vô trùng trong chăm sóc nội, ngoại khoa',
}, {'id': 'Y62.0', 'english': 'During surgical operation', 'vietnamese': 'Trong mổ'}, {
  'id': 'Y62.1',
  'english': 'During infusion or transfusion',
  'vietnamese': 'Trong truyền dịch hay máu',
}, {
  'id': 'Y62.2',
  'english': 'During kidney dialysis or other perfusion',
  'vietnamese': 'Trong chạy thận nhân tạo hay truyền khác',
}, {
  'id': 'Y62.3',
  'english': 'During injection or immunization',
  'vietnamese': 'Trong tiêm hay gây miễn dịch',
}, {
  'id': 'Y62.4',
  'english': 'During endoscopic examination',
  'vietnamese': 'Trong khám nội soi',
}, {
  'id': 'Y62.5',
  'english': 'During heart catheterization',
  'vietnamese': 'Trong thông tim',
}, {
  'id': 'Y62.6',
  'english': 'During aspiration, puncture and other catheterization',
  'vietnamese': 'Trong hút, chọc, và thông khác',
}, {
  'id': 'Y62.8',
  'english': 'During other surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa khác',
}, {
  'id': 'Y62.9',
  'english': 'During unspecified surgical and medical care',
  'vietnamese': 'Trong chăm sóc nội, ngoại khoa không xác định chính xác',
}, {
  'id': 'Y63',
  'english': 'Failure in dosage during surgical and medical care',
  'vietnamese': 'Không đảm bảo liều lượng trong chăm sóc nội, ngoại khoa',
}, {
  'id': 'Y63.0',
  'english': 'Excessive amount of blood or other fluid given during transfusion or infusion',
  'vietnamese': 'Cho quá nhiều máu hay dịch trong truyền máu, truyền dịch',
}, {
  'id': 'Y63.1',
  'english': 'Incorrect dilution of fluid used during infusion',
  'vietnamese': 'Pha loãng dịch không đúng trong truyền dịch',
}, {
  'id': 'Y63.2',
  'english': 'Overdose of radiation given during therapy',
  'vietnamese': 'Quá liều tia xạ trong khi điều trị',
}, {
  'id': 'Y63.3',
  'english': 'Inadvertent exposure of patient to radiation during medical care',
  'vietnamese': 'Phơi nhiễm của bệnh nhân do bất cẩn trước tia xạ trong chăm sóc y tế',
}, {
  'id': 'Y63.4',
  'english': 'Failure in dosage in electroshock or insulin-shock therapy',
  'vietnamese': 'Sai liều trong sốc điện hay liệu pháp sốc insulin',
}, {
  'id': 'Y63.5',
  'english': 'Inappropriate temperature in local application and packing',
  'vietnamese': 'Nhiệt độ không phù hợp trong khi áp tại chỗ hay đắp quanh người',
}, {
  'id': 'Y63.6',
  'english': 'Nonadministration of necessary drug, medicament or biological substance',
  'vietnamese': 'Không dùng thuốc, dược chất, sinh chất không cần thiết',
}, {
  'id': 'Y63.8',
  'english': 'Failure in dosage during other surgical and medical care',
  'vietnamese': 'Sự cố trong chăm sóc nội, ngoại khoa khác',
}, {
  'id': 'Y63.9',
  'english': 'Failure in dosage during unspecified surgical and medical care',
  'vietnamese': 'Sự cố trong chăm sóc nội, ngoại khoa không xác định chính xác',
}, {
  'id': 'Y64',
  'english': 'Contaminated medical or biological substances',
  'vietnamese': 'Chất liệu y tế hay sinh học lây nhiễm',
}, {
  'id': 'Y64.0',
  'english': 'Contaminated medical or biological substance, transfused or infused',
  'vietnamese': 'Chất liệu y tế, sinh học, máu truyền, chất truyền lây nhiễm',
}, {
  'id': 'Y64.1',
  'english': 'Contaminated medical or biological substance, injected or used for immunization',
  'vietnamese': 'Chất liệu y tế hay sinh học bị lây nhiễm, tiêm hay sử dụng gây miễn dịch',
}, {
  'id': 'Y64.8',
  'english': 'Contaminated medical or biological substance administered by other means',
  'vietnamese': 'Chất liệu y tế hay sinh học bị lây nhiễm vào cơ thể bằng các phương tiện khác',
}, {
  'id': 'Y64.9',
  'english': 'Contaminated medical or biological substance administered by unspecified means',
  'vietnamese': 'Chất liệu y tế hay sinh học bị lây nhiễm vào cơ không rõ bằng phương tiện nào',
}, {
  'id': 'Y65',
  'english': 'Other misadventures during surgical and medical care',
  'vietnamese': 'Các rủi ro khác trong chăm sóc nội, ngoại khoa',
}, {
  'id': 'Y65.0',
  'english': 'Mismatched blood used in transfusion',
  'vietnamese': 'Nhầm nhóm máu khi truyền',
}, {'id': 'Y65.1', 'english': 'Wrong fluid used in infusion', 'vietnamese': 'Truyền nhầm dịch'}, {
  'id': 'Y65.2',
  'english': 'Failure in suture or ligature during surgical operation',
  'vietnamese': 'Không khâu hay thắt được trong mổ',
}, {
  'id': 'Y65.3',
  'english': 'Endotracheal tube wrongly placed during anaesthetic procedure',
  'vietnamese': 'Đặt sai ống nội khí quản trong gây mê',
}, {
  'id': 'Y65.4',
  'english': 'Failure to introduce or to remove other tube or instrument',
  'vietnamese': 'Thất bại trong đưa vào hay lấy ra ống hay dụng cụ khác',
}, {
  'id': 'Y65.5',
  'english': 'Performance of inappropriate operation',
  'vietnamese': 'Tiến hành phẫu thuật không phù hợp',
}, {
  'id': 'Y65.8',
  'english': 'Other specified misadventures during surgical and medical care',
  'vietnamese': 'Các rủi ro đặc biệt khác trong khi chăm sóc nội, ngoại khoa',
}, {
  'id': 'Y66',
  'english': 'Nonadministration of surgical and medical care',
  'vietnamese': 'Không thực hiện chăm sóc nội khoa và ngoại khoa',
}, {
  'id': 'Y69',
  'english': 'Unspecified misadventures during surgical and medical care',
  'vietnamese': 'Các rủi ro không xác định chính xác trong chăm sóc nội, ngoại khoa',
}, {
  'id': 'Y70',
  'english': 'Anaesthesiology devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ gây mê kết hợp với tai biến',
}, {
  'id': 'Y71',
  'english': 'Cardiovascular devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ tim mạch kết hợp với tai biến',
}, {
  'id': 'Y72',
  'english': 'Otorhinolaryngological devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ tai mũi họng kết hợp với tai biến',
}, {
  'id': 'Y73',
  'english': 'Gastroenterology and urology devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ tiêu hóa, tiết niệu kết hợp với tai biến',
}, {
  'id': 'Y74',
  'english': 'General hospital and persional-use devices associated with adverse incidents',
  'vietnamese': 'Bệnh viện đa khoa và các phương tiện sử dụng cá nhân với các tai biến',
}, {
  'id': 'Y75',
  'english': 'Neurological devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ thần kinh kết hợp với tai biến',
}, {
  'id': 'Y76',
  'english': 'Obstetric and gynaecological devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ sản phụ khoa kết hợp với tai biến',
}, {
  'id': 'Y77',
  'english': 'Ophthalmic devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ mắt kết hợp với tai biến',
}, {
  'id': 'Y78',
  'english': 'Radiological devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ điện quang kết hợp với tai biến',
}, {
  'id': 'Y79',
  'english': 'Orthopaedic devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ chỉnh hình kết hợp với tai biến',
}, {
  'id': 'Y80',
  'english': 'Physical medicine devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ y tế kết hợp với tai biến',
}, {
  'id': 'Y81',
  'english': 'General and plastic surgery devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ phẫu thuật chung và tạo hình kết hợp với tai biến',
}, {
  'id': 'Y82',
  'english': 'Other and unspecified devices associated with adverse incidents',
  'vietnamese': 'Dụng cụ khác và không rõ đặc điểm kết hợp với tai biến',
}, {
  'id': 'Y83',
  'english': 'Surgical operation and other surgical procedures as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure',
  'vietnamese': 'Phẫu thuật và các thủ thuật ngoại khoa khác là nguyên nhân của phản ứng bất thường của bệnh nhân hay biến chứng sau này, mà không được nêu ra là rủi ro tại thời điểm thực hiện',
}, {
  'id': 'Y83.0',
  'english': 'Surgical operation with transplant of whole organ',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa với ghép toàn bộ 1 tạng phủ',
}, {
  'id': 'Y83.1',
  'english': 'Surgical operation with implant of artificial internal device',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa với ghép một dụng cụ nhân tạo',
}, {
  'id': 'Y83.2',
  'english': 'Surgical operation with anastomosis, bypass or graft',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa với khâu nối, bắc cầu hay ghép',
}, {
  'id': 'Y83.3',
  'english': 'Surgical operation with formation of external stoma',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa với thực hiện một lỗ thông ra ngoài',
}, {
  'id': 'Y83.4',
  'english': 'Other reconstructive surgery',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa tái tạo khác',
}, {
  'id': 'Y83.5',
  'english': 'Amputation of limb(s)',
  'vietnamese': 'Tai biến do Phẫu thuật ngoại khoa Cắt cụt (nhiều) chi',
}, {
  'id': 'Y83.6',
  'english': 'Removal of other organ (partial) (total)',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa cắt bỏ cơ quan khác (1 phần) (toàn bộ)',
}, {
  'id': 'Y83.8',
  'english': 'Other surgical procedures',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa khác',
}, {
  'id': 'Y83.9',
  'english': 'Surgical procedure, unspecified',
  'vietnamese': 'Tai biến do phẫu thuật ngoại khoa chưa xác định chính xác',
}, {
  'id': 'Y84',
  'english': 'Other medical procedures as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure',
  'vietnamese': 'Các thủ thuật nội khoa khác là nguyên nhân của phản ứng bất thường ở người bệnh hay biến chứng sau này, mà không nêu rõ vấn đề rủi ro trong lúc tiến hành thủ thuật',
}, {'id': 'Y84.0', 'english': 'Cardiac catheterization', 'vietnamese': 'Tai biến do thông tim'}, {
  'id': 'Y84.1',
  'english': 'Kidney dialysis',
  'vietnamese': 'Tai biến do chạy thận nhân tạo',
}, {
  'id': 'Y84.2',
  'english': 'Radiological procedure and radiotherapy',
  'vietnamese': 'Tai biến do thủ thuật điện quang, điều trị tia xạ',
}, {'id': 'Y84.3', 'english': 'Shock therapy', 'vietnamese': 'Tai biến do choáng điều trị'}, {
  'id': 'Y84.4',
  'english': 'Aspiration of fluid',
  'vietnamese': 'Tai biến do Hút dịch',
}, {
  'id': 'Y84.5',
  'english': 'Insertion of gastric or duodenal sound',
  'vietnamese': 'Tai biến do đặt ống thông dạ dày hay tá tràng',
}, {
  'id': 'Y84.6',
  'english': 'Urinary catheterization',
  'vietnamese': 'Tai biến do thông tiểu [thông đái]',
}, {'id': 'Y84.7', 'english': 'Blood-sampling', 'vietnamese': 'Tai biến do lấy mẫu máu'}, {
  'id': 'Y84.8',
  'english': 'Other medical procedures',
  'vietnamese': 'Tai biến do các thủ thuật nội khoa khác',
}, {
  'id': 'Y84.9',
  'english': 'Medical procedure, unspecified',
  'vietnamese': 'Tai biến do Thủ thuật nội khoa không xác định khác',
}, {
  'id': 'Y85',
  'english': 'Sequelae of transport accidents',
  'vietnamese': 'Di chứng của tai nạn giao thông',
}, {
  'id': 'Y85.0',
  'english': 'Sequelae of motor-vehicle accident',
  'vietnamese': 'Di chứng của tai nạn xe động cơ',
}, {
  'id': 'Y85.9',
  'english': 'Sequelae of other and unspecified transport accidents',
  'vietnamese': 'Di chứng của các tại nạn giao thông khác và không rõ đặc điểm',
}, {
  'id': 'Y86',
  'english': 'Sequelae of other accidents',
  'vietnamese': 'Di chứng của các tai nạn khác',
}, {
  'id': 'Y87',
  'english': 'Sequelae of intentional self-harm, assault and events of undetermined intent',
  'vietnamese': 'Di chứng của cố ý tự hại, tấn công hay biến cố không rõ đặc điểm',
}, {
  'id': 'Y87.0',
  'english': 'Sequelae of intentional self-harm',
  'vietnamese': 'Di chứng của cố ý tự hại',
}, {'id': 'Y87.1', 'english': 'Sequelae of assault', 'vietnamese': 'Di chứng của tấn công'}, {
  'id': 'Y87.2',
  'english': 'Sequelae of events of undetermined intent',
  'vietnamese': 'Di chứng của biến cố không xác định được',
}, {
  'id': 'Y88',
  'english': 'Sequelae with surgical and medical care as external cause',
  'vietnamese': 'Di chứng của chăm sóc ngoại khoa và nội khoa như là nguyên nhân bên ngoài',
}, {
  'id': 'Y88.0',
  'english': 'Sequelae of adverse effects caused by drugs, medicaments and biological substances in therapeutic use',
  'vietnamese': 'Di chứng của tác dụng ngược lại của thuốc, dược chất hay sinh chất dùng trong điều trị',
}, {
  'id': 'Y88.1',
  'english': 'Sequelae of misadventures to patients during surgical and medical procedures',
  'vietnamese': 'Di chứng trong rủi ro của bệnh nhân trong thủ thuật nội, ngoại khoa',
}, {
  'id': 'Y88.2',
  'english': 'Sequelae of adverse incidents associated with medical devices in diagnostic and therapeutic use',
  'vietnamese': 'Di chứng của tác dụng ngược kết hợp với dụng cụ y tế dùng trong chẩn đoán và điều trị',
}, {
  'id': 'Y88.3',
  'english': 'Sequelae of surgical and medical procedures as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure',
  'vietnamese': 'Di chứng của thủ thuật nội, ngoại khoa - coi như là nguyên nhân của phản ứng bất thường ở người bệnh, hay biến chứng sau này, mà không thấy rõ rủi ro trong lúc tiến hành thủ thuật',
}, {
  'id': 'Y89',
  'english': 'Sequelae of other external causes',
  'vietnamese': 'Di chứng của các nguyên nhân bên ngoài khác',
}, {
  'id': 'Y89.0',
  'english': 'Sequelae of legal intervention',
  'vietnamese': 'Di chứng của can thiệp hợp pháp',
}, {
  'id': 'Y89.1',
  'english': 'Sequelae of war operations',
  'vietnamese': 'Di chứng của hoạt động chiến tranh',
}, {
  'id': 'Y89.9',
  'english': 'Sequelae of unspecified external cause',
  'vietnamese': 'Di chứng của nguyên nhân bên ngoài không xác định chính xác',
}, {
  'id': 'Y90',
  'english': 'Evidence of alcohol involvement determined by blood alcohol level',
  'vietnamese': 'Bằng chứng liên quan đến rượu xác định bằng mức cồn trong máu',
}, {
  'id': 'Y90.0',
  'english': 'Blood alcohol level of less than 20 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu dưới 20 mg/100 ml',
}, {
  'id': 'Y90.1',
  'english': 'Blood alcohol level of 20-39 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 20-39mg/100 ml',
}, {
  'id': 'Y90.2',
  'english': 'Blood alcohol level of 40-59 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 40-59mg/100 ml',
}, {
  'id': 'Y90.3',
  'english': 'Blood alcohol level of 60-79 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 60-79mg/100 ml',
}, {
  'id': 'Y90.4',
  'english': 'Blood alcohol level of 80-99 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 80-99mg/100 ml',
}, {
  'id': 'Y90.5',
  'english': 'Blood alcohol level of 100-119 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 100-119mg/100 ml',
}, {
  'id': 'Y90.6',
  'english': 'Blood alcohol level of 120-199 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 120-119mg/100 ml',
}, {
  'id': 'Y90.7',
  'english': 'Blood alcohol level of 200-239 mg/100 ml',
  'vietnamese': 'Mức cồn trong máu ở 220-239mg/100 ml',
}, {
  'id': 'Y90.8',
  'english': 'Blood alcohol level of 240 mg/100 ml or more',
  'vietnamese': 'Mức cồn trong máu ở 240mg/ml hay hơn',
}, {
  'id': 'Y90.9',
  'english': 'Presence of alcohol in blood, level not specified',
  'vietnamese': 'Có cồn trong máu không rõ mức độ',
}, {
  'id': 'Y91',
  'english': 'Evidence of alcohol involvement determined by level of intoxication',
  'vietnamese': 'Bằng chứng liên quan đến rượu xác định bằng mức độ nhiễm độc',
}, {'id': 'Y91.0', 'english': 'Mild alcohol intoxication', 'vietnamese': 'Nhiễm độc rượu nhẹ'}, {
  'id': 'Y91.1',
  'english': 'Moderate alcohol intoxication',
  'vietnamese': 'Nhiễm độc rượu trung bình',
}, {
  'id': 'Y91.2',
  'english': 'Severe alcohol intoxication',
  'vietnamese': 'Nhiễm độc rượu nặng',
}, {
  'id': 'Y91.3',
  'english': 'Very severe alcohol intoxication',
  'vietnamese': 'Nhiễm độc rượu rất nặng',
}, {
  'id': 'Y91.9',
  'english': 'Alcohol involvement, not otherwise specified',
  'vietnamese': 'Có liên quan đến rượu, không đặc hiệu',
}, {'id': 'Y95', 'english': 'Nosocomial condition', 'vietnamese': 'Điều kiện bệnh viện'}, {
  'id': 'Y96',
  'english': 'Work-related condition',
  'vietnamese': 'Điều kiện liên quan đến lao động',
}, {
  'id': 'Y97',
  'english': 'Environment-population-related condition',
  'vietnamese': 'Điều kiện liên quan đến ô nhiễm môi trường',
}, {
  'id': 'Y98',
  'english': 'Lifestyle-related condition',
  'vietnamese': 'Điều kiện liên quan đến cách sống',
}, {
  'id': 'Z00',
  'english': 'General examination and investigation of persons without complaint and reported diagnosis',
  'vietnamese': 'Khám tổng quát và kiểm tra sức khỏe cho những người không có điều gì than phiền về sức khỏe hoặc những người đã có chẩn đoán',
}, {
  'id': 'Z00.0',
  'english': 'General medical examination',
  'vietnamese': 'Khám sức khỏe tổng quát',
}, {
  'id': 'Z00.1',
  'english': 'Routine child health examination',
  'vietnamese': 'Khám trẻ em thường quy',
}, {
  'id': 'Z00.2',
  'english': 'Examination for period of rapid growth in childhood',
  'vietnamese': 'Khám thời kỳ phát triển nhanh của trẻ',
}, {
  'id': 'Z00.3',
  'english': 'Examination for adolescent development state',
  'vietnamese': 'Khám tình trạng phát triển ở tuổi thanh thiếu niên',
}, {
  'id': 'Z00.4',
  'english': 'General psychiatric examination, not elsewhere classified',
  'vietnamese': 'Khám tâm thần tổng quát, chưa được phân loại ở phần khác',
}, {
  'id': 'Z00.5',
  'english': 'Examination of potential donor of organ and tissue',
  'vietnamese': 'Khám cho người muốn hiến tạng và mô',
}, {
  'id': 'Z00.6',
  'english': 'Examination for normal comparison and control in clinical research programme',
  'vietnamese': 'Khám để so sánh bình thường và đối chứng trong chương trình nghiên cứu lâm sàng',
}, {
  'id': 'Z00.8',
  'english': 'Other general examinations',
  'vietnamese': 'Các loại khám tổng quát khác',
}, {
  'id': 'Z01',
  'english': 'Other special examinations and investigations of persons without complaint or reported diagnosis',
  'vietnamese': 'Khám đặc biệt và kiểm tra sức khỏe khác cho những người không có điều gì than phiền về sức khỏe hay đã có chẩn đoán trước',
}, {
  'id': 'Z01.0',
  'english': 'Examination of eyes and vision',
  'vietnamese': 'Khám mắt và thị lực',
}, {
  'id': 'Z01.1',
  'english': 'Examination of ears and hearing',
  'vietnamese': 'Khám tai và thính lực',
}, {'id': 'Z01.2', 'english': 'Dental examination', 'vietnamese': 'Khám răng'}, {
  'id': 'Z01.3',
  'english': 'Examination of blood pressure',
  'vietnamese': 'Đo huyết áp',
}, {
  'id': 'Z01.4',
  'english': 'Gynaecological examination (general)(routine)',
  'vietnamese': 'Khám phụ khoa (tổng quát) (thường kỳ)',
}, {
  'id': 'Z01.5',
  'english': 'Diagnostic skin and sensitization tests',
  'vietnamese': 'chẩn đoán về da và các test mẫn cảm',
}, {
  'id': 'Z01.6',
  'english': 'Radiological examination, not elsewhere classified',
  'vietnamese': 'Khám điện quang, chưa được phân loại ở phần khác',
}, {
  'id': 'Z01.7',
  'english': 'Laboratory examination',
  'vietnamese': 'Xét nghiệm cận lâm sàng',
}, {
  'id': 'Z01.8',
  'english': 'Other specified special examinations',
  'vietnamese': 'Các khám xét đặc biệt xác định khác',
}, {
  'id': 'Z01.9',
  'english': 'Special examination, unspecified',
  'vietnamese': 'Khám xét đặc biệt, không đặc hiệu',
}, {
  'id': 'Z02',
  'english': 'Examination and encounter for administrative purposes',
  'vietnamese': 'Khám vì lý do hành chính',
}, {
  'id': 'Z02.0',
  'english': 'Examination for admission to educational institution',
  'vietnamese': 'Khám để nhập trường học',
}, {
  'id': 'Z02.1',
  'english': 'Pre-employment examination',
  'vietnamese': 'Khám trước khi nhận công tác',
}, {
  'id': 'Z02.2',
  'english': 'Examination for admission to residential institutions',
  'vietnamese': 'Khám để nhập vào nơi cư trú',
}, {
  'id': 'Z02.3',
  'english': 'Examination for recruitment to armed forces',
  'vietnamese': 'Khám tuyển quân',
}, {
  'id': 'Z02.4',
  'english': 'Examination for driving licence',
  'vietnamese': 'Khám để chứng nhận giấy phép lái xe',
}, {
  'id': 'Z02.5',
  'english': 'Examination for participation in sport',
  'vietnamese': 'Khám để tham gia thể thao',
}, {
  'id': 'Z02.6',
  'english': 'Examination for insurance purposes',
  'vietnamese': 'Khám vì lý do bảo hiểm',
}, {
  'id': 'Z02.7',
  'english': 'Issue of medical certificate',
  'vietnamese': 'Cấp giấy chứng nhận y học',
}, {
  'id': 'Z02.8',
  'english': 'Other examinations for administrative purposes',
  'vietnamese': 'Các khám khác vì lý do hành chính',
}, {
  'id': 'Z02.9',
  'english': 'Examination for administrative purposes, unspecified',
  'vietnamese': 'Khám vì lý do hành chính, không đặc hiệu',
}, {
  'id': 'Z03',
  'english': 'Medical observation and evaluation for suspected diseases and conditions',
  'vietnamese': 'Quan sát thăm khám khi nghi ngờ bệnh và những tình trạng nghi ngờ bệnh',
}, {
  'id': 'Z03.0',
  'english': 'Observation for suspected tuberculosis',
  'vietnamese': 'Quan sát khi nghi ngờ mắc bệnh lao',
}, {
  'id': 'Z03.1',
  'english': 'Observation for suspected malignant neoplasm',
  'vietnamese': 'Quan sát khi nghi u ác tính',
}, {
  'id': 'Z03.2',
  'english': 'Observation for suspected mental and behavioural disorders',
  'vietnamese': 'Quan sát khi nghi ngờ các rối loạn tâm thần và hành vi',
}, {
  'id': 'Z03.3',
  'english': 'Observation for suspected nervous system disorder',
  'vietnamese': 'Quan sát khi nghi có rối loạn hệ thần kinh',
}, {
  'id': 'Z03.4',
  'english': 'Observation for suspected myocardial infarction',
  'vietnamese': 'Quan sát khi nghi ngờ có nhồi máu cơ tim',
}, {
  'id': 'Z03.5',
  'english': 'Observation for other suspected cardiovascular diseases',
  'vietnamese': 'Quan sát khi nghi ngờ có các bệnh tim mạch khác',
}, {
  'id': 'Z03.6',
  'english': 'Observation for suspected toxic effect from ingested substance',
  'vietnamese': 'Quan sát khi nghi ngờ ảnh hưởng của độc tính trong thực phẩm',
}, {
  'id': 'Z03.8',
  'english': 'Observation for other suspected diseases and conditions',
  'vietnamese': 'Quan sát khi có các bệnh và tình trạng nghi ngờ bệnh tật khác',
}, {
  'id': 'Z03.9',
  'english': 'Observation for suspected disease or condition, unspecified',
  'vietnamese': 'Quan sát khi có các bệnh và trạng thái bệnh nghi ngờ không đặc hiệu',
}, {
  'id': 'Z04',
  'english': 'Examination and observation for other reasons',
  'vietnamese': 'Khám và quan sát vì những lý do khác',
}, {
  'id': 'Z04.0',
  'english': 'Blood-alcohol and blood-drug test',
  'vietnamese': 'Xét nghiệm phát hiện rượu trong máu và ma túy trong máu',
}, {
  'id': 'Z04.1',
  'english': 'Examination and observation following transport accident',
  'vietnamese': 'Khám và quan sát sau tai nạn giao thông',
}, {
  'id': 'Z04.2',
  'english': 'Examination and observation following work accident',
  'vietnamese': 'Khám và quan sát sau tai nạn lao động',
}, {
  'id': 'Z04.3',
  'english': 'Examination and observation following other accident',
  'vietnamese': 'Khám và quan sát sau tai nạn khác',
}, {
  'id': 'Z04.4',
  'english': 'Examination and observation following alleged rape and seduction',
  'vietnamese': 'Khám và quan sát sau cưỡng dâm và dụ dỗ',
}, {
  'id': 'Z04.5',
  'english': 'Examination and observation following other inflicted injury',
  'vietnamese': 'Khám và quan sát sau khi bị vết thương khác gây ra',
}, {
  'id': 'Z04.6',
  'english': 'General psychiatric examination, requested by authority',
  'vietnamese': 'Khám tâm thần tổng quát do nhà chức trách yêu cầu',
}, {
  'id': 'Z04.8',
  'english': 'Examination and observation for other specified reasons',
  'vietnamese': 'Khám và quan sát vì lý do xác định khác',
}, {
  'id': 'Z04.9',
  'english': 'Examination and observation for unspecified reason',
  'vietnamese': 'Khám và quan sát vì lý do không xác định',
}, {
  'id': 'Z08',
  'english': 'Follow-up examination after treatment for malignant neoplasms',
  'vietnamese': 'Khám theo dõi sau điều trị u ác tính',
}, {
  'id': 'Z08.0',
  'english': 'Follow-up examination after surgery for malignant neoplasm',
  'vietnamese': 'Khám theo dõi sau phẫu thuật khối u ác tính',
}, {
  'id': 'Z08.1',
  'english': 'Follow-up examination after radiotherapy for malignant neoplasm',
  'vietnamese': 'Khám theo dõi sau điều trị xạ trị u ác tính',
}, {
  'id': 'Z08.2',
  'english': 'Follow-up examination after chemotherapy for malignant neoplasm',
  'vietnamese': 'Khám theo dõi hóa trị liệu u ác tính',
}, {
  'id': 'Z08.7',
  'english': 'Follow-up examination after combined treatment for malignant neoplasm',
  'vietnamese': 'Khám theo dõi sau điều trị phối hợp u ác tính',
}, {
  'id': 'Z08.8',
  'english': 'Follow-up examination after other treatment for malignant neoplasm',
  'vietnamese': 'Khám theo dõi sau điều trị khác của u ác tính',
}, {
  'id': 'Z08.9',
  'english': 'Follow-up examination after unspecified treatment for malignant neoplasm',
  'vietnamese': 'Khám theo dõi sau điều trị không đặc hiệu của u ác tính',
}, {
  'id': 'Z09',
  'english': 'Follow-up examination after treatment for conditions other than malignant neoplasms',
  'vietnamese': 'Khám theo dõi sau điều trị các tình trạng bệnh khác đi kèm với khối u ác tính',
}, {
  'id': 'Z09.0',
  'english': 'Follow-up examination after surgery for other conditions',
  'vietnamese': 'Khám theo dõi sau phẫu các tình trạng khác',
}, {
  'id': 'Z09.1',
  'english': 'Follow-up examination after radiotherapy for other conditions',
  'vietnamese': 'Khám theo dõi sau xạ trị liệu các tình trạng khác',
}, {
  'id': 'Z09.2',
  'english': 'Follow-up examination after chemotherapy for other conditions',
  'vietnamese': 'Khám theo dõi sau hóa trị liệu các trạng thái khác',
}, {
  'id': 'Z09.3',
  'english': 'Follow-up examination after psychotherapy',
  'vietnamese': 'Khám theo dõi sau tâm lí liệu pháp',
}, {
  'id': 'Z09.4',
  'english': 'Follow-up examination after treatment of fracture',
  'vietnamese': 'Khám theo dõi sau điều trị gẫy xương',
}, {
  'id': 'Z09.7',
  'english': 'Follow-up examination after combined treatment for other conditions',
  'vietnamese': 'Khám theo dõi sau điều trị phối hợp các trạng thái khác',
}, {
  'id': 'Z09.8',
  'english': 'Follow-up examination after other treatment for other conditions',
  'vietnamese': 'Khám theo dõi sau điều trị khác của các trạng thái khác',
}, {
  'id': 'Z09.9',
  'english': 'Follow-up examination after unspecified treatment for other conditions',
  'vietnamese': 'Khám theo dõi sau điều trị không đặc hiệu của các trạng thái khác',
}, {
  'id': 'Z10',
  'english': 'Routine general health check-up of defined subpopulation',
  'vietnamese': 'Kiểm tra sức khỏe tổng quát thường quy cho nhóm dân cư xác định',
}, {
  'id': 'Z10.0',
  'english': 'Occupational health examination',
  'vietnamese': 'Khám sức khỏe về nghề nghiệp',
}, {
  'id': 'Z10.1',
  'english': 'Routine general health check-up of inhabitants of institutions',
  'vietnamese': 'Kiểm tra sức khỏe tổng quát thường quy cho nhân viên cơ quan',
}, {
  'id': 'Z10.2',
  'english': 'Routine general health check-up of armed forces',
  'vietnamese': 'Kiểm tra sức khỏe tổng quát thường quy của lực lượng vũ trang',
}, {
  'id': 'Z10.3',
  'english': 'Routine general health check-up of sports teams',
  'vietnamese': 'Kiểm tra sức khỏe tổng quát thường quy cho các đội thể thao',
}, {
  'id': 'Z10.8',
  'english': 'Routine general health check-up of other defined subpopulations',
  'vietnamese': 'Kiểm tra sức khỏe tổng quát thường quy cho các nhóm dân cư xác định khác',
}, {
  'id': 'Z11',
  'english': 'Special screening examination for infectious and parasitic diseases',
  'vietnamese': 'Khám sàng lọc đặc biệt các bệnh nhiễm khuẩn và ký sinh trùng',
}, {
  'id': 'Z11.0',
  'english': 'Special screening examination for intestinal infectious diseases',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh nhiễm khuẩn đường ruột',
}, {
  'id': 'Z11.1',
  'english': 'Special screening examination for respiratory tuberculosis',
  'vietnamese': 'Khám sàng lọc đặc biệt về lao phổi',
}, {
  'id': 'Z11.2',
  'english': 'Special screening examination for other bacterial diseases',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh nhiễm khuẩn khác',
}, {
  'id': 'Z11.3',
  'english': 'Special screening examination for infections with a predominantly sexual mode of transmission',
  'vietnamese': 'Khám sàng lọc đặc biệt về các nhiễm khuẩn có đường lây chủ yếu qua tình dục',
}, {
  'id': 'Z11.4',
  'english': 'Special screening examination for human immunodeficiency virus [HIV]',
  'vietnamese': 'Khám sàng lọc đặc biệt về virus suy giảm miễn dịch người (HIV)',
}, {
  'id': 'Z11.5',
  'english': 'Special screening examination for other viral diseases',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh virus khác',
}, {
  'id': 'Z11.6',
  'english': 'Special screening examination for other protozoal diseases and helminthiases',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh do động vật đơn bào khác và giun sán',
}, {
  'id': 'Z11.8',
  'english': 'Special screening examination for other infectious and parasitic diseases',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh nhiễm khuẩn và ký sinh vật khác',
}, {
  'id': 'Z11.9',
  'english': 'Special screening examination for infectious and parasitic diseases, unspecified',
  'vietnamese': 'Khám sàng lọc đặc biệt về các bệnh nhiễm khuẩn và ký sinh vật khác, không đặc hiệu',
}, {
  'id': 'Z12',
  'english': 'Special screening examination for neoplasms',
  'vietnamese': 'Khám sàng lọc đặc biệt các khối u',
}, {
  'id': 'Z12.0',
  'english': 'Special screening examination for neoplasm of stomach',
  'vietnamese': 'Khám sàng lọc đặc biệt u dạ dày',
}, {
  'id': 'Z12.1',
  'english': 'Special screening examination for neoplasm of intestinal tract',
  'vietnamese': 'Khám sàng lọc đặc biệt u đường ruột',
}, {
  'id': 'Z12.2',
  'english': 'Special screening examination for neoplasm of respiratory organs',
  'vietnamese': 'Khám sàng lọc đặc biệt u cơ quan hô hấp',
}, {
  'id': 'Z12.3',
  'english': 'Special screening examination for neoplasm of breast',
  'vietnamese': 'Khám sàng lọc đặc biệt u vú',
}, {
  'id': 'Z12.4',
  'english': 'Special screening examination for neoplasm of cervix',
  'vietnamese': 'Khám sàng lọc đặc biệt về u cổ tử cung',
}, {
  'id': 'Z12.5',
  'english': 'Special screening examination for neoplasm of prostate',
  'vietnamese': 'Khám sàng lọc đặc biệt u tiền liệt tuyến',
}, {
  'id': 'Z12.6',
  'english': 'Special screening examination for neoplasm of bladder',
  'vietnamese': 'Khám sàng lọc đặc biệt u bàng quang',
}, {
  'id': 'Z12.8',
  'english': 'Special screening examination for neoplasms of other sites',
  'vietnamese': 'Khám sàng lọc đặc biệt khối u ở vị trí khác',
}, {
  'id': 'Z12.9',
  'english': 'Special screening examination for neoplasm, unspecified',
  'vietnamese': 'Khám sàng lọc đặc biệt khối u, không đặc hiệu',
}, {
  'id': 'Z13',
  'english': 'Special screening examination for other diseases and disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các bệnh và các rối loạn khác',
}, {
  'id': 'Z13.0',
  'english': 'Special screening examination for diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
  'vietnamese': 'Khám sàng lọc đặc biệt các bệnh về máu và các cơ quan tạo máu và một số rối loạn liên quan đến cơ chế miễn dịch',
}, {
  'id': 'Z13.1',
  'english': 'Special screening examination for diabetes mellitus',
  'vietnamese': 'Khám sàng lọc đặc biệt đái tháo đường',
}, {
  'id': 'Z13.2',
  'english': 'Special screening examination for nutritional disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các rối loạn dinh dưỡng',
}, {
  'id': 'Z13.3',
  'english': 'Special screening examination for mental and behavioural disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các rối loạn tâm thần và hành vi',
}, {
  'id': 'Z13.4',
  'english': 'Special screening examination for certain developmental disorders in childhood',
  'vietnamese': 'Khám sàng lọc đặc biệt một số loạn phát triển của trẻ em',
}, {
  'id': 'Z13.5',
  'english': 'Special screening examination for eye and ear disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các rối loạn thị giác và thính giác',
}, {
  'id': 'Z13.6',
  'english': 'Special screening examination for cardiovascular disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các rối loạn tim mạch',
}, {
  'id': 'Z13.7',
  'english': 'Special screening examination for congenital malformations, deformations and chromosomal abnormalities',
  'vietnamese': 'Khám sàng lọc đặc biệt các dị dạng bẩm sinh, các biến dạng khác và các bất thường nhiễm sắc thể',
}, {
  'id': 'Z13.8',
  'english': 'Special screening examination for other specified diseases and disorders',
  'vietnamese': 'Khám sàng lọc đặc biệt các bệnh và các rối loạn xác định khác',
}, {
  'id': 'Z13.9',
  'english': 'Special screening examination, unspecified',
  'vietnamese': 'Khám sàng lọc đặc biệt, không đặc hiệu',
}, {
  'id': 'Z20',
  'english': 'Contact with and exposure to communicable diseases',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với các bệnh lây truyền',
}, {
  'id': 'Z20.0',
  'english': 'Contact with and exposure to intestinal infectious diseases',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với các bệnh nhiễm khuẩn đường ruột',
}, {
  'id': 'Z20.1',
  'english': 'Contact with and exposure to tuberculosis',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với vi khuẩn lao',
}, {
  'id': 'Z20.2',
  'english': 'Contact with and exposure to infections with a predominantly sexual mode of transmission',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với các bệnh nhiễm khuẩn lây truyền chủ yếu qua đường tình dục',
}, {
  'id': 'Z20.3',
  'english': 'Contact with and exposure to rabies',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với bệnh virus dại',
}, {
  'id': 'Z20.4',
  'english': 'Contact with and exposure to rubella',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với virus rubella',
}, {
  'id': 'Z20.5',
  'english': 'Contact with and exposure to viral hepatitis',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với virus viêm gan',
}, {
  'id': 'Z20.6',
  'english': 'Contact with and exposure to human immunodeficiency virus [HIV]',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với virus suy giảm miễn dịch người (HIV)',
}, {
  'id': 'Z20.7',
  'english': 'Contact with and exposure to pediculosis, acariasis and other infestations',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với chấy rận, giun đũa và các ký sinh trùng khác',
}, {
  'id': 'Z20.8',
  'english': 'Contact with and exposure to other communicable diseases',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với các bệnh lây truyền khác',
}, {
  'id': 'Z20.9',
  'english': 'Contact with and exposure to unspecified communicable disease',
  'vietnamese': 'Tiếp xúc và phơi nhiễm với bệnh lây truyền không xác định',
}, {
  'id': 'Z21',
  'english': 'Asymptomatic human immunodeficiency virus (HIV) infection status',
  'vietnamese': 'Trạng thái nhiễm virus suy giảm miễn dịch (HIV) không triệu chứng',
}, {
  'id': 'Z22',
  'english': 'Carrier of infectious disease',
  'vietnamese': 'Mang mầm bệnh nhiễm khuẩn',
}, {'id': 'Z22.0', 'english': 'Carrier of typhoid', 'vietnamese': 'Mang mầm bệnh thương hàn'}, {
  'id': 'Z22.1',
  'english': 'Carrier of other intestinal infectious diseases',
  'vietnamese': 'Mang mầm bệnh nhiễm khuẩn đường ruột khác',
}, {'id': 'Z22.2', 'english': 'Carrier of diphtheria', 'vietnamese': 'Mang mầm bệnh bạch hầu'}, {
  'id': 'Z22.3',
  'english': 'Carrier of other specified bacterial diseases',
  'vietnamese': 'Mang mầm bệnh các vi khuẩn xác định khác',
}, {
  'id': 'Z22.4',
  'english': 'Carrier of infections with a predominantly sexual mode of transmission',
  'vietnamese': 'Mang mầm bệnh nhiễm khuẩn có đường lây chủ yếu qua tình dục',
}, {
  'id': 'Z22.5',
  'english': 'Carrier of viral hepatitis',
  'vietnamese': 'Mang virus viêm gan',
}, {
  'id': 'Z22.6',
  'english': 'Carrier of human T-lymphotropic virus type- 1 [HTLV-1] infection',
  'vietnamese': 'Mang virus typ 1 hướng lympho T người [HTLV.1 ]',
}, {
  'id': 'Z22.8',
  'english': 'Carrier of other infectious diseases',
  'vietnamese': 'Mang mầm bệnh nhiễm khuẩn khác',
}, {
  'id': 'Z22.9',
  'english': 'Carrier of infectious disease, unspecified',
  'vietnamese': 'Mang mầm bệnh nhiễm khuẩn, không đặc hiệu',
}, {
  'id': 'Z23',
  'english': 'Need for immunization against single bacterial diseases',
  'vietnamese': 'Cần tiêm chủng phòng các bệnh nhiễm khuẩn đơn thuần',
}, {
  'id': 'Z23.0',
  'english': 'Need for immunization against cholera alone',
  'vietnamese': 'Tiêm chủng phòng thương hàn đơn độc',
}, {
  'id': 'Z23.1',
  'english': 'Need for immunization against typhoid-paratyphoid alone [TAB]',
  'vietnamese': 'Tiêm chủng phòng thương hàn - cận thương hàn đơn độc [TAB]',
}, {
  'id': 'Z23.2',
  'english': 'Need for immunization against tuberculosis [BCG]',
  'vietnamese': 'Tiêm chủng phòng lao [BCG]',
}, {
  'id': 'Z23.3',
  'english': 'Need for immunization against plague',
  'vietnamese': 'Tiêm chủng phòng dịch hạch',
}, {
  'id': 'Z23.4',
  'english': 'Need for immunization against tularaemia',
  'vietnamese': 'Tiêm chủng phòng tularemia',
}, {
  'id': 'Z23.5',
  'english': 'Need for immunization against tetanus alone',
  'vietnamese': 'Tiêm chủng phòng uốn ván đơn độc',
}, {
  'id': 'Z23.6',
  'english': 'Need for immunization against diphtheria alone',
  'vietnamese': 'Tiêm chủng phòng bạch hầu đơn độc',
}, {
  'id': 'Z23.7',
  'english': 'Need for immunization against pertussis alone',
  'vietnamese': 'Tiêm chủng phòng ho gà đơn độc',
}, {
  'id': 'Z23.8',
  'english': 'Need for immunization against other single bacterial diseases',
  'vietnamese': 'Tiêm chủng phòng bệnh nhiễm khuẩn đơn thuần khác',
}, {
  'id': 'Z24',
  'english': 'Need for immunization against certain single viral diseases',
  'vietnamese': 'Cần tiêm chủng phòng một số bệnh virus đơn thuần',
}, {
  'id': 'Z24.0',
  'english': 'Need for immunization against poliomyelitis',
  'vietnamese': 'Tiêm chủng phòng bại liệt',
}, {
  'id': 'Z24.1',
  'english': 'Need for immunization against arthropod-borne viral encephalitis',
  'vietnamese': 'Tiêm chủng phòng viêm não virus do tiết túc mang',
}, {
  'id': 'Z24.2',
  'english': 'Need for immunization against rabies',
  'vietnamese': 'Tiêm chủng phòng bệnh dại',
}, {
  'id': 'Z24.3',
  'english': 'Need for immunization against yellow fever',
  'vietnamese': 'Tiêm chủng phòng sốt vàng da',
}, {
  'id': 'Z24.4',
  'english': 'Need for immunization against measles alone',
  'vietnamese': 'Tiêm chủng phòng sởi đơn độc',
}, {
  'id': 'Z24.5',
  'english': 'Need for immunization against rubella alone',
  'vietnamese': 'Tiêm chủng phòng rubêôn đơn độc',
}, {
  'id': 'Z24.6',
  'english': 'Need for immunization against viral hepatitis',
  'vietnamese': 'Tiêm chủng phòng viêm gan virus',
}, {
  'id': 'Z25',
  'english': 'Need for immunization against other single viral diseases',
  'vietnamese': 'Cần tiêm chủng phòng các bệnh virus khác',
}, {
  'id': 'Z25.0',
  'english': 'Need for immunization against mumps alone',
  'vietnamese': 'Tiêm chủng phòng quai bị đơn độc',
}, {
  'id': 'Z25.1',
  'english': 'Need for immunization against influenza',
  'vietnamese': 'Tiêm chủng phòng bệnh cúm',
}, {
  'id': 'Z25.8',
  'english': 'Need for immunization against other specified single viral diseases',
  'vietnamese': 'Tiêm chủng phòng các bệnh virus đơn độc xác định khác',
}, {
  'id': 'Z26',
  'english': 'Need for immunization against other single infectious diseases',
  'vietnamese': 'Cần tiêm chủng phòng các bệnh nhiễm khuẩn đơn độc khác',
}, {
  'id': 'Z26.0',
  'english': 'Need for immunization against leishmaniasis',
  'vietnamese': 'Tiêm chủng phòng lesmania',
}, {
  'id': 'Z26.8',
  'english': 'Need for immunization against other specified single infectious diseases',
  'vietnamese': 'Tiêm chủng phòng các bệnh nhiễm khuẩn đơn độc xác định khác',
}, {
  'id': 'Z26.9',
  'english': 'Need for immunization against unspecified infectious disease',
  'vietnamese': 'Tiêm chủng phòng các bệnh nhiễm khuẩn không xác định',
}, {
  'id': 'Z27',
  'english': 'Need for immunization against combinations of infectious diseases',
  'vietnamese': 'Cần tiêm chủng phòng các bệnh nhiễm khuẩn phối hợp',
}, {
  'id': 'Z27.0',
  'english': 'Need for immunization against cholera with typhoid-paratyphoid [cholera + TAB]',
  'vietnamese': 'Tiêm chủng phòng tả kèm theo thương hàn - phó thương hàn [tả + TAB]',
}, {
  'id': 'Z27.1',
  'english': 'Need for immunization against diphtheria-tetanus-pertussis, combined [DTP]',
  'vietnamese': 'Tiêm chủng phòng bạch hầu - uốn ván - ho gà phối hợp [DTP]',
}, {
  'id': 'Z27.2',
  'english': 'Need for immunization against diphtheria-tetanus-pertussis with typhoid-paratyphoid [DTP + TAB]',
  'vietnamese': 'Tiêm chủng phòng bạch hầu - uốn ván - ho gà kèm theo thương hàn - phó thương hàn [DPT + TAB]',
}, {
  'id': 'Z27.3',
  'english': 'Need for immunization against diphtheria-tetanus-pertussis with poliomyelitis [DTP + polio]',
  'vietnamese': 'Tiêm chủng phòng bạch hầu - uốn ván - ho gà kèm bại liệt [DPT+ bại liệt]',
}, {
  'id': 'Z27.4',
  'english': 'Need for immunization against measles-mumps-rubella [MMR]',
  'vietnamese': 'Tiêm chủng phòng sởi - quai bị - rubêôn [MMR]',
}, {
  'id': 'Z27.8',
  'english': 'Need for immunization against other combinations of infectious diseases',
  'vietnamese': 'Tiêm chủng phòng các bệnh nhiễm khuẩn phối hợp khác',
}, {
  'id': 'Z27.9',
  'english': 'Need for immunization against unspecified combinations of infectious diseases',
  'vietnamese': 'Tiêm chủng phòng các bệnh nhiễm khuẩn phối hợp không đặc hiệu khác',
}, {'id': 'Z28', 'english': 'Immunization not carried out', 'vietnamese': 'Chưa tiêm chủng'}, {
  'id': 'Z28.0',
  'english': 'Immunization not carried out because of contraindication',
  'vietnamese': 'Chưa tiêm chủng do chống chỉ định',
}, {
  'id': 'Z28.1',
  'english': 'Immunization not carried out because of patient s decision for reasons of belief or group pressure',
  'vietnamese': 'Chưa tiêm chủng do người bệnh chưa quyết định vì lòng tin hay áp lực của nhóm',
}, {
  'id': 'Z28.2',
  'english': 'Immunization not carried out because of patient s decision for other and unspecified reasons',
  'vietnamese': 'Chưa tiêm chủng do người bệnh chưa quyết định và những lý do không xác định khác',
}, {
  'id': 'Z28.8',
  'english': 'Immunization not carried out for other reasons',
  'vietnamese': 'Chưa tiêm chủng vì những lý do không xác định',
}, {
  'id': 'Z28.9',
  'english': 'Immunization not carried out for unspecified reason',
  'vietnamese': 'Chưa tiêm chủng vì lý do không xác định',
}, {
  'id': 'Z29',
  'english': 'Need for other prophylactic measures',
  'vietnamese': 'Cần có các biện pháp phòng bệnh khác',
}, {'id': 'Z29.0', 'english': 'Isolation', 'vietnamese': 'Cách ly'}, {
  'id': 'Z29.1',
  'english': 'Prophylactic immunotherapy',
  'vietnamese': 'Miễn dịch liệu pháp dự phòng',
}, {
  'id': 'Z29.2',
  'english': 'Other prophylactic chemotherapy',
  'vietnamese': 'hóa trị liệu dự phòng khác',
}, {
  'id': 'Z29.8',
  'english': 'Other specified prophylactic measures',
  'vietnamese': 'Các biện pháp dự phòng đặc hiệu khác',
}, {
  'id': 'Z29.9',
  'english': 'Prophylactic measure, unspecified',
  'vietnamese': 'Biện pháp dự phòng, không đặc hiệu',
}, {'id': 'Z30', 'english': 'Contraceptive management', 'vietnamese': 'Tránh thai'}, {
  'id': 'Z30.0',
  'english': 'General counselling and advice on contraception',
  'vietnamese': 'Tư vấn tổng quát và lời khuyên về tránh thai',
}, {
  'id': 'Z30.1',
  'english': 'Insertion of (intrauterine) contraceptive device',
  'vietnamese': 'Đặt dụng cụ tránh thai (trong tử cung)',
}, {'id': 'Z30.2', 'english': 'Sterilization', 'vietnamese': 'Triệt sản'}, {
  'id': 'Z30.3',
  'english': 'Menstrual extraction',
  'vietnamese': 'Hút kinh nguyệt',
}, {
  'id': 'Z30.4',
  'english': 'Surveillance of contraceptive drugs',
  'vietnamese': 'Theo dõi sử dụng thuốc tránh thai',
}, {
  'id': 'Z30.5',
  'english': 'Surveillance of (intrauterine) contraceptive device',
  'vietnamese': 'Theo dõi dụng cụ tránh thai (trong tử cung)',
}, {
  'id': 'Z30.8',
  'english': 'Other contraceptive management',
  'vietnamese': 'Quản lí tránh thai khác',
}, {
  'id': 'Z30.9',
  'english': 'Contraceptive management, unspecified',
  'vietnamese': 'Quản lí tránh thai, không đặc hiệu',
}, {'id': 'Z31', 'english': 'Procreative management', 'vietnamese': 'Quản lý sinh sản'}, {
  'id': 'Z31.0',
  'english': 'Tuboplasty or vasoplasty after previous sterilization',
  'vietnamese': 'Tái tạo vòi trứng hay ống dẫn tinh sau khi dùng biện pháp triệt sản trước đó',
}, {'id': 'Z31.1', 'english': 'Artificial insemination', 'vietnamese': 'Thụ tinh nhân tạo'}, {
  'id': 'Z31.2',
  'english': 'In vitro fertilization',
  'vietnamese': 'Thụ tinh trong ống nghiệm',
}, {
  'id': 'Z31.3',
  'english': 'Other assisted fertilization methods',
  'vietnamese': 'Các phương pháp hỗ trợ sinh sản khác',
}, {
  'id': 'Z31.4',
  'english': 'Procreative investigation and testing',
  'vietnamese': 'Thăm khám lượng giá và xét nghiệm sinh sản',
}, {'id': 'Z31.5', 'english': 'Genetic counselling', 'vietnamese': 'Tư vấn về gen'}, {
  'id': 'Z31.6',
  'english': 'General counselling and advice on procreation',
  'vietnamese': 'Tư vấn tổng quát và lời khuyên về sinh sản',
}, {
  'id': 'Z31.8',
  'english': 'Other procreative management',
  'vietnamese': 'Quản lí sinh sản khác',
}, {
  'id': 'Z31.9',
  'english': 'Procreative management, unspecified',
  'vietnamese': 'Quản lý sinh sản, không đặc hiệu',
}, {
  'id': 'Z32',
  'english': 'Pregnancy examination and test',
  'vietnamese': 'Thăm khám và xét nghiệm xác định có thai',
}, {
  'id': 'Z32.0',
  'english': 'Pregnancy, not (yet) confirmed',
  'vietnamese': 'Có thai chưa khẳng định',
}, {
  'id': 'Z32.1',
  'english': 'Pregnancy confirmed',
  'vietnamese': 'Có thai đã được khẳng định',
}, {
  'id': 'Z33',
  'english': 'Pregnant stage, incidental',
  'vietnamese': 'Tình trạng có thai, tình cờ',
}, {
  'id': 'Z34',
  'english': 'Supervision of normal pregnancy',
  'vietnamese': 'Theo dõi thai bình thường',
}, {
  'id': 'Z34.0',
  'english': 'Supervision of normal first pregnancy',
  'vietnamese': 'Theo dõi thai bình thường lần đầu',
}, {
  'id': 'Z34.8',
  'english': 'Supervision of other normal pregnancy',
  'vietnamese': 'Theo dõi thai bình thường khác',
}, {
  'id': 'Z34.9',
  'english': 'Supervision of normal pregnancy, unspecified',
  'vietnamese': 'Theo dõi thai bình thường, không đặc hiệu',
}, {
  'id': 'Z35',
  'english': 'Supervision of high-risk pregnancy',
  'vietnamese': 'Theo dõi thai có nguy cơ cao',
}, {
  'id': 'Z35.0',
  'english': 'Supervision of pregnancy with history of infertility',
  'vietnamese': 'Theo dõi thai phụ có tiền sử vô sinh',
}, {
  'id': 'Z35.1',
  'english': 'Supervision of pregnancy with history of abortive outcome',
  'vietnamese': 'Theo dõi thai phụ có tiền sử sảy thai',
}, {
  'id': 'Z35.2',
  'english': 'Supervision of pregnancy with other poor reproductive or obstetric history',
  'vietnamese': 'Theo dõi thai phụ có bệnh sử chửa khó hoặc đẻ khó',
}, {
  'id': 'Z35.3',
  'english': 'Supervision of pregnancy with history of insufficient antenatal care',
  'vietnamese': 'Theo dõi thai phụ có tiền sử chăm sóc trước sinh không đầy đủ',
}, {
  'id': 'Z35.4',
  'english': 'Supervision of pregnancy with grand multiparity',
  'vietnamese': 'Theo dõi thai với các bà mẹ đẻ nhiều lần',
}, {
  'id': 'Z35.5',
  'english': 'Supervision of elderly primigravida',
  'vietnamese': 'Theo dõi thai lần đầu ở người nhiều tuổi',
}, {
  'id': 'Z35.6',
  'english': 'Supervision of very young primigravida',
  'vietnamese': 'Theo dõi thai lần đầu ở người quá trẻ tuổi',
}, {
  'id': 'Z35.7',
  'english': 'Supervision of high-risk pregnancy due to social problems',
  'vietnamese': 'Theo dõi thai có nguy cơ cao do các vấn đề xã hội',
}, {
  'id': 'Z35.8',
  'english': 'Supervision of other high-risk pregnancies',
  'vietnamese': 'Theo dõi thai có nguy cơ cao khác',
}, {
  'id': 'Z35.9',
  'english': 'Supervision of high-risk pregnancy, unspecified',
  'vietnamese': 'Theo dõi thai có nguy cơ cao, không đặc hiệu',
}, {'id': 'Z36', 'english': 'Antenatal screening', 'vietnamese': 'Khám sàng lọc trước sinh'}, {
  'id': 'Z36.0',
  'english': 'Antenatal screening for chromosomal anomalies',
  'vietnamese': 'Khám sàng lọc trước sinh về bất thường nhiễm sắc thể',
}, {
  'id': 'Z36.1',
  'english': 'Antenatal screening for raised alphafetoprotein level',
  'vietnamese': 'Khám sàng lọc trước sinh về mức Alphafetoprotein tăng',
}, {
  'id': 'Z36.2',
  'english': 'Other antenatal screening based on amniocentesis',
  'vietnamese': 'Khám sàng lọc trước sinh khác dựa vào chọc màng ối qua thành bụng',
}, {
  'id': 'Z36.3',
  'english': 'Antenatal screening for malformations using ultrasound and other physical methods',
  'vietnamese': 'Khám sàng lọc trước sinh về các dị dạng qua siêu âm hay phương pháp vật lý khác',
}, {
  'id': 'Z36.4',
  'english': 'Antenatal screening for fetal growth retardation using ultrasound and other physical methods',
  'vietnamese': 'Khám sàng lọc trước sinh về thai chậm lớn bằng siêu âm hay các phương pháp vật lý khác',
}, {
  'id': 'Z36.5',
  'english': 'Antenatal screening for isoimmunization',
  'vietnamese': 'Khám sàng lọc trước sinh về miễn dịch đồng loại',
}, {
  'id': 'Z36.8',
  'english': 'Other antenatal screening',
  'vietnamese': 'Khám sàng lọc trước sinh khác',
}, {
  'id': 'Z36.9',
  'english': 'Antenatal screening, unspecified',
  'vietnamese': 'Khám sàng lọc trước sinh, không đặc hiệu',
}, {'id': 'Z37', 'english': 'Outcome of delivery', 'vietnamese': 'Trẻ sinh (đẻ)'}, {
  'id': 'Z37.0',
  'english': 'Single live birth',
  'vietnamese': 'Sinh một con, trẻ sống',
}, {
  'id': 'Z37.1',
  'english': 'Single stillbirth',
  'vietnamese': 'Sinh một con, trẻ chết khi sinh',
}, {
  'id': 'Z37.2',
  'english': 'Twins, both liveborn',
  'vietnamese': 'Sinh đôi, sống cả hai trẻ',
}, {
  'id': 'Z37.3',
  'english': 'Twins, one liveborn and one stillborn',
  'vietnamese': 'Sinh đôi, một trẻ sinh ra sống, một trẻ chết khi sinh',
}, {
  'id': 'Z37.4',
  'english': 'Twins, both stillborn',
  'vietnamese': 'Sinh đôi, cả hai chết khi sinh',
}, {
  'id': 'Z37.5',
  'english': 'Other multiple births, all liveborn',
  'vietnamese': 'Sinh nhiều con, tất cả đều sống',
}, {
  'id': 'Z37.6',
  'english': 'Other multiple births, some liveborn',
  'vietnamese': 'Sinh nhiều con, trong đó một số trẻ sinh ra sống',
}, {
  'id': 'Z37.7',
  'english': 'Other multiple births, all stillborn',
  'vietnamese': 'Sinh nhiều con, tất cả chết khi sinh',
}, {
  'id': 'Z37.9',
  'english': 'Outcome of delivery, unspecified',
  'vietnamese': 'Chuyển dạ đẻ, không đặc hiệu',
}, {
  'id': 'Z38',
  'english': 'Liveborn infants according to place of birth',
  'vietnamese': 'Trẻ sinh ra sống (theo nơi sinh)',
}, {
  'id': 'Z38.0',
  'english': 'Singleton, born in hospital',
  'vietnamese': 'Sinh một con, tại bệnh viện',
}, {
  'id': 'Z38.1',
  'english': 'Singleton, born outside hospital',
  'vietnamese': 'Trẻ sinh đơn ngoài bệnh viện',
}, {
  'id': 'Z38.2',
  'english': 'Singleton, unspecified as to place of birth',
  'vietnamese': 'Trẻ sinh đơn, nơi sinh không xác định',
}, {
  'id': 'Z38.3',
  'english': 'Twin, born in hospital',
  'vietnamese': 'Trẻ sinh đôi tại bệnh viện',
}, {
  'id': 'Z38.4',
  'english': 'Twin, born outside hospital',
  'vietnamese': 'Sinh đôi, ngoài bệnh viện',
}, {
  'id': 'Z38.5',
  'english': 'Twin, unspecified as to place of birth',
  'vietnamese': 'Sinh đôi, nơi sinh không xác định',
}, {
  'id': 'Z38.6',
  'english': 'Other multiple, born in hospital',
  'vietnamese': 'Sinh nhiều con, sinh tại bệnh viện',
}, {
  'id': 'Z38.7',
  'english': 'Other multiple, born outside hospital',
  'vietnamese': 'Sinh nhiều con, ngoài bệnh viện',
}, {
  'id': 'Z38.8',
  'english': 'Other multiple, unspecified as to place of birth',
  'vietnamese': 'Sinh nhiều con, nơi sinh không xác định',
}, {
  'id': 'Z39',
  'english': 'Postpartum care and examination',
  'vietnamese': 'Chăm sóc và thăm khám sau đẻ',
}, {
  'id': 'Z39.0',
  'english': 'Care and examination immediately after delivery',
  'vietnamese': 'Chăm sóc và khám ngay sau đẻ',
}, {
  'id': 'Z39.1',
  'english': 'Care and examination of lactating mother',
  'vietnamese': 'Chăm sóc và khám các bà mẹ cho con bú',
}, {
  'id': 'Z39.2',
  'english': 'Routine postpartum follow-up',
  'vietnamese': 'Theo dõi thường quy sau đẻ',
}, {'id': 'Z40', 'english': 'Prophylactic surgery', 'vietnamese': 'Phẫu thuật dự phòng'}, {
  'id': 'Z40.0',
  'english': 'Prophylactic surgery for risk-factors related to malignant neoplasms',
  'vietnamese': 'Phẫu thuật dự phòng các nguy cơ liên quan đến khối u ác tính',
}, {
  'id': 'Z40.8',
  'english': 'Other prophylactic surgery',
  'vietnamese': 'Phẫu thuật dự phòng khác',
}, {
  'id': 'Z40.9',
  'english': 'Prophylactic surgery, unspecified',
  'vietnamese': 'Phẫu thuật dự phòng, không đặc hiệu',
}, {
  'id': 'Z41',
  'english': 'Procedures for purposes other than remedying health state',
  'vietnamese': 'Các thủ thuật vì những mục đích khác không phải phục hồi sức khỏe',
}, {'id': 'Z41.0', 'english': 'Hair transplant', 'vietnamese': 'Cấy tóc'}, {
  'id': 'Z41.1',
  'english': 'Other plastic surgery for unacceptable cosmetic appearance',
  'vietnamese': 'Phẫu thuật tạo hình do vẻ bên ngoài xấu',
}, {
  'id': 'Z41.2',
  'english': 'Routine and ritual circumcision',
  'vietnamese': 'Cắt bao qui đầu theo tập tục và thường quy',
}, {'id': 'Z41.3', 'english': 'Ear piercing', 'vietnamese': 'Bấm lỗ tai'}, {
  'id': 'Z41.8',
  'english': 'Other procedures for purposes other than remedying health state',
  'vietnamese': 'Các thủ thuật khác vì các mục đích khác không phải phục hồi sức khỏe',
}, {
  'id': 'Z41.9',
  'english': 'Procedure for purposes other than remedying health state, unspecified',
  'vietnamese': 'Các thủ thuật vì những mục đích khác không phải phục hồi sức khỏe, không đặc hiệu',
}, {
  'id': 'Z42',
  'english': 'Follow-up care involving plastic surgery',
  'vietnamese': 'Chăm sóc theo dõi liên quan phẫu thuật tạo hình',
}, {
  'id': 'Z42.0',
  'english': 'Follow-up care involving plastic surgery of head and neck',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình vùng đầu và cổ',
}, {
  'id': 'Z42.1',
  'english': 'Follow-up care involving plastic surgery of breast',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình vú',
}, {
  'id': 'Z42.2',
  'english': 'Follow-up care involving plastic surgery of other parts of trunk',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình các phần khác của thân mình',
}, {
  'id': 'Z42.3',
  'english': 'Follow-up care involving plastic surgery of upper extremity',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình chi trên',
}, {
  'id': 'Z42.4',
  'english': 'Follow-up care involving plastic surgery of lower extremity',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình chi dưới',
}, {
  'id': 'Z42.8',
  'english': 'Follow-up care involving plastic surgery of other body part',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình các phần khác của cơ thể',
}, {
  'id': 'Z42.9',
  'english': 'Follow-up care involving plastic surgery, unspecified',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật tạo hình không đặc hiệu',
}, {
  'id': 'Z43',
  'english': 'Attention to artificial openings',
  'vietnamese': 'Chăm sóc các lỗ mở nhân tạo',
}, {
  'id': 'Z43.0',
  'english': 'Attention to tracheostomy',
  'vietnamese': 'Chăm sóc lỗ mở khí quản',
}, {
  'id': 'Z43.1',
  'english': 'Attention to gastrostomy',
  'vietnamese': 'Chăm sóc lỗ mở thông dạ dày',
}, {
  'id': 'Z43.2',
  'english': 'Attention to ileostomy',
  'vietnamese': 'Chăm sóc lỗ mở thông hồi tràng',
}, {
  'id': 'Z43.3',
  'english': 'Attention to colostomy',
  'vietnamese': 'Chăm sóc lỗ mở thông đại tràng',
}, {
  'id': 'Z43.4',
  'english': 'Attention to other artificial openings of digestive tract',
  'vietnamese': 'Chăm sóc các lỗ mở nhân tạo khác của đường tiêu hóa',
}, {
  'id': 'Z43.5',
  'english': 'Attention to cystostomy',
  'vietnamese': 'Chăm sóc lỗ mở thông bàng quang',
}, {
  'id': 'Z43.6',
  'english': 'Attention to other artificial openings of urinary tract',
  'vietnamese': 'Chăm sóc các lỗ mở nhân tạo khác của đường tiết niệu',
}, {
  'id': 'Z43.7',
  'english': 'Attention to artificial vagina',
  'vietnamese': 'Chăm sóc âm đạo nhân tạo',
}, {
  'id': 'Z43.8',
  'english': 'Attention to other artificial openings',
  'vietnamese': 'Chăm sóc các lỗ mở nhân tạo khác',
}, {
  'id': 'Z43.9',
  'english': 'Attention to unspecified artificial opening',
  'vietnamese': 'Chăm sóc lỗ mở nhân tạo không xác định',
}, {
  'id': 'Z44',
  'english': 'Fitting and adjustment of external prosthetic device',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ chỉnh hình bên ngoài',
}, {
  'id': 'Z44.0',
  'english': 'Fitting and adjustment of artificial arm (complete)(partial)',
  'vietnamese': 'Lắp ráp và điều chỉnh tay giả (toàn bộ) (một phần)',
}, {
  'id': 'Z44.1',
  'english': 'Fitting and adjustment of artificial leg (complete)(partial)',
  'vietnamese': 'Lắp ráp và điều chỉnh chân giả (toàn bộ) (một phần)',
}, {
  'id': 'Z44.2',
  'english': 'Fitting and adjustment of artificial eye',
  'vietnamese': 'Lắp ráp và điều chỉnh mắt giả',
}, {
  'id': 'Z44.3',
  'english': 'Fitting and adjustment of external breast prosthesis',
  'vietnamese': 'Lắp ráp và điều chỉnh các ngoại dụng cụ chỉnh hình vú',
}, {
  'id': 'Z44.8',
  'english': 'Fitting and adjustment of other external prosthetic devices',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ chỉnh hình bên ngoài khác',
}, {
  'id': 'Z44.9',
  'english': 'Fitting and adjustment of unspecified external prosthetic device',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ chỉnh hình bên ngoài, không đặc hiệu',
}, {
  'id': 'Z45',
  'english': 'Adjustment and management of implanted device',
  'vietnamese': 'Điều chỉnh và quản lý dụng cụ cấy ghép',
}, {
  'id': 'Z45.0',
  'english': 'Adjustment and management of cardiac pacemaker',
  'vietnamese': 'Điều chỉnh và quản lý dụng cụ tim',
}, {
  'id': 'Z45.1',
  'english': 'Adjustment and management of infusion pump',
  'vietnamese': 'Điều chỉnh và quản lý bơm tiêm điện',
}, {
  'id': 'Z45.2',
  'english': 'Adjustment and management of vascular access device',
  'vietnamese': 'Điều chỉnh và quản lý dụng cụ tiếp cận mạch máu',
}, {
  'id': 'Z45.3',
  'english': 'Adjustment and management of implanted hearing device',
  'vietnamese': 'Điều chỉnh và quản lý dụng cụ thính giác được cấy ghép',
}, {
  'id': 'Z45.8',
  'english': 'Adjustment and management of other implanted devices',
  'vietnamese': 'Điều chỉnh và quản lý các dụng cụ cấy ghép khác',
}, {
  'id': 'Z45.9',
  'english': 'Adjustment and management of unspecified implanted device',
  'vietnamese': 'Điều chỉnh và quản lý dụng cụ cấy ghép không xác định',
}, {
  'id': 'Z46',
  'english': 'Fitting and adjustment of other devices',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ khác',
}, {
  'id': 'Z46.0',
  'english': 'Fitting and adjustment of spectacles and contact lenses',
  'vietnamese': 'Lắp ráp và điều chỉnh kính và thấu kính tiếp xúc',
}, {
  'id': 'Z46.1',
  'english': 'Fitting and adjustment of hearing aid',
  'vietnamese': 'Lắp ráp và điều chỉnh dụng cụ trợ thính',
}, {
  'id': 'Z46.2',
  'english': 'Fitting and adjustment of other devices related to nervous system and special senses',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ khác liên quan tới hệ thần kinh và các giác quan đặc biệt',
}, {
  'id': 'Z46.3',
  'english': 'Fitting and adjustment of dental prosthetic device',
  'vietnamese': 'Lắp ráp và chỉnh hình răng',
}, {
  'id': 'Z46.4',
  'english': 'Fitting and adjustment of orthodontic device',
  'vietnamese': 'Lắp ráp và điều chỉnh dụng cụ chỉnh hình răng hàm mặt',
}, {
  'id': 'Z46.5',
  'english': 'Fitting and adjustment of ileostomy and other intestinal appliances',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ gắn vào hỗng tràng và ruột non',
}, {
  'id': 'Z46.6',
  'english': 'Fitting and adjustment of urinary device',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ tiết niệu',
}, {
  'id': 'Z46.7',
  'english': 'Fitting and adjustment of orthopaedic device',
  'vietnamese': 'Lắp ráp và điều chỉnh dụng cụ chỉnh hình',
}, {
  'id': 'Z46.8',
  'english': 'Fitting and adjustment of other specified devices',
  'vietnamese': 'Lắp ráp và điều chỉnh các dụng cụ đặc hiệu khác',
}, {
  'id': 'Z46.9',
  'english': 'Fitting and adjustment of unspecified device',
  'vietnamese': 'Lắp ráp và điều chỉnh hình dụng cụ không đặc hiệu',
}, {
  'id': 'Z47',
  'english': 'Other orthopaedic follow-up care',
  'vietnamese': 'Chăm sóc theo dõi chỉnh hình khác',
}, {
  'id': 'Z47.0',
  'english': 'Follow-up care involving removal of fracture plate and other internal fixation device',
  'vietnamese': 'Chăm sóc theo dõi liên quan đến tháo bỏ nẹp xương gẫy và dụng cụ cố định bên trong khác',
}, {
  'id': 'Z47.8',
  'english': 'Other specified orthopaedic follow-up care',
  'vietnamese': 'Chăm sóc theo dõi chỉnh hình không xác định',
}, {
  'id': 'Z47.9',
  'english': 'Orthopaedic follow-up care, unspecified',
  'vietnamese': 'Chăm sóc theo dõi chỉnh hình không đặc hiệu',
}, {
  'id': 'Z48',
  'english': 'Other surgical follow-up care',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật khác',
}, {
  'id': 'Z48.0',
  'english': 'Attention to surgical dressings and sutures',
  'vietnamese': 'Chăm sóc băng bó và chỉ khâu',
}, {
  'id': 'Z48.8',
  'english': 'Other specified surgical follow-up care',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật xác định khác',
}, {
  'id': 'Z48.9',
  'english': 'Surgical follow-up care, unspecified',
  'vietnamese': 'Chăm sóc theo dõi phẫu thuật, không đặc hiệu',
}, {'id': 'Z49', 'english': 'Care involving dialysis', 'vietnamese': 'Chăm sóc về lọc máu'}, {
  'id': 'Z49.0',
  'english': 'Preparatory care for dialysis',
  'vietnamese': 'Chăm sóc chuẩn bị cho lọc máu',
}, {'id': 'Z49.1', 'english': 'Extracorporeal dialysis', 'vietnamese': 'Lọc máu ngoài cơ thể'}, {
  'id': 'Z49.2',
  'english': 'Other dialysis',
  'vietnamese': 'Các lọc máu khác',
}, {
  'id': 'Z50',
  'english': 'Care involving use of rehabilitation procedures',
  'vietnamese': 'Chăm sóc về sử dụng các phương pháp điều trị phục hồi chức năng',
}, {'id': 'Z50.0', 'english': 'Cardiac rehabilitation', 'vietnamese': 'Phục hồi chức năng tim'}, {
  'id': 'Z50.1',
  'english': 'Other physical therapy',
  'vietnamese': 'Vật lí trị liệu khác',
}, {
  'id': 'Z50.2',
  'english': 'Alcohol rehabilitation',
  'vietnamese': 'Phục hồi chức năng nghiện rượu',
}, {
  'id': 'Z50.3',
  'english': 'Drug rehabilitation',
  'vietnamese': 'Phục hồi chức năng nghiện ma túy',
}, {
  'id': 'Z50.4',
  'english': 'Psychotherapy, not elsewhere classified',
  'vietnamese': 'Tâm lý trị liệu, chưa được phân loại ở phần khác',
}, {'id': 'Z50.5', 'english': 'Speech therapy', 'vietnamese': 'Âm ngữ trị liệu'}, {
  'id': 'Z50.6',
  'english': 'Orthoptic training',
  'vietnamese': 'Huấn luyện về chỉnh thị',
}, {
  'id': 'Z50.7',
  'english': 'Occupational therapy and vocational rehabilitation, not elsewhere classified',
  'vietnamese': 'Hoạt động trị liệu và phục hồi chức năng nghề nghiệp, không xác định',
}, {
  'id': 'Z50.8',
  'english': 'Care involving use of other rehabilitation procedures',
  'vietnamese': 'Chăm sóc liên quan đến sử dụng các phương pháp điều trị phục hồi chức năng khác',
}, {
  'id': 'Z50.9',
  'english': 'Care involving use of rehabilitation procedure, unspecified',
  'vietnamese': 'Chăm sóc liên quan đến điều trị phục hồi chức năng, không đặc hiệu',
}, {'id': 'Z51', 'english': 'Other medical care', 'vietnamese': 'Chăm sóc y học khác'}, {
  'id': 'Z51.0',
  'english': 'Radiotherapy session',
  'vietnamese': 'Xạ trị liệu',
}, {
  'id': 'Z51.1',
  'english': 'Chemotherapy session for neoplasm',
  'vietnamese': 'hóa trị liệu khối u',
}, {'id': 'Z51.2', 'english': 'Other chemotherapy', 'vietnamese': 'hóa trị liệu khác'}, {
  'id': 'Z51.3',
  'english': 'Blood transfusion without reported diagnosis',
  'vietnamese': 'Truyền máu không có chẩn đoán ghi nhận',
}, {
  'id': 'Z51.4',
  'english': 'Preparatory care for subsequent treatment, not elsewhere classified',
  'vietnamese': 'Chăm sóc chuẩn bị cho điều trị tiếp theo, chưa được phân loại ở phần khác',
}, {'id': 'Z51.5', 'english': 'Palliative care', 'vietnamese': 'Chăm sóc làm giảm nhẹ'}, {
  'id': 'Z51.6',
  'english': 'Desensitization to allergens',
  'vietnamese': 'Giải mẫn cảm các dị nguyên',
}, {
  'id': 'Z51.8',
  'english': 'Other specified medical care',
  'vietnamese': 'Chăm sóc y học đặc hiệu khác',
}, {
  'id': 'Z51.9',
  'english': 'Medical care, unspecified',
  'vietnamese': 'Chăm sóc y học không đặc hiệu',
}, {'id': 'Z52', 'english': 'Donors of organs and tissues', 'vietnamese': 'Hiến tạng và mô'}, {
  'id': 'Z52.0',
  'english': 'Blood donor',
  'vietnamese': 'Hiến máu',
}, {'id': 'Z52.1', 'english': 'Skin donor', 'vietnamese': 'Hiến da'}, {
  'id': 'Z52.2',
  'english': 'Bone donor',
  'vietnamese': 'Hiến xương',
}, {'id': 'Z52.3', 'english': 'Bone marrow donor', 'vietnamese': 'Hiến tủy xương'}, {
  'id': 'Z52.4',
  'english': 'Kidney donor',
  'vietnamese': 'Hiến thận',
}, {'id': 'Z52.5', 'english': 'Cornea donor', 'vietnamese': 'Hiến giác mạc'}, {
  'id': 'Z52.6',
  'english': 'Liver donor',
  'vietnamese': 'Hiến gan',
}, {'id': 'Z52.7', 'english': 'Heart donor', 'vietnamese': 'Hiến tim'}, {
  'id': 'Z52.8',
  'english': 'Donor of other organs and tissues',
  'vietnamese': 'Hiến các tạng và mô khác',
}, {
  'id': 'Z52.9',
  'english': 'Donor of unspecified organ or tissue',
  'vietnamese': 'Hiến các tạng và mô không đặc hiệu',
}, {
  'id': 'Z53',
  'english': 'Persons encountering health services for specific procedures, not carried out',
  'vietnamese': 'Người bệnh đến các cơ sở y tế do chưa được thực hiện những thủ thuật đặc hiệu',
}, {
  'id': 'Z53.0',
  'english': 'Procedure not carried out because of contraindication',
  'vietnamese': 'Chưa được thực hiện chống chỉ định',
}, {
  'id': 'Z53.1',
  'english': 'Procedure not carried out because of patient s decision for reasons of belief and group pressure',
  'vietnamese': 'Chưa thực hiện do người bệnh chưa quyết định vì chưa tin tưởng hay vì áp lực của nhóm',
}, {
  'id': 'Z53.2',
  'english': 'Procedure not carried out because of patient s decision for other and unspecified reasons',
  'vietnamese': 'Chưa thực hiện do người bệnh chưa quyết định vì những lý do khác không xác định',
}, {
  'id': 'Z53.8',
  'english': 'Procedure not carried out for other reasons',
  'vietnamese': 'Chưa thực hiện vì những lý do khác',
}, {
  'id': 'Z53.9',
  'english': 'Procedure not carried out, unspecified reason',
  'vietnamese': 'Chưa thực hiện vì lý do không xác định',
}, {'id': 'Z54', 'english': 'Convalescence', 'vietnamese': 'Thời kỳ dưỡng sức'}, {
  'id': 'Z54.0',
  'english': 'Convalescence following surgery',
  'vietnamese': 'Dưỡng sức sau phẫu thuật',
}, {
  'id': 'Z54.1',
  'english': 'Convalescence following radiotherapy',
  'vietnamese': 'Dưỡng sức sau xạ trị liệu',
}, {
  'id': 'Z54.2',
  'english': 'Convalescence following chemotherapy',
  'vietnamese': 'Dưỡng sức sau hóa trị liệu',
}, {
  'id': 'Z54.3',
  'english': 'Convalescence following psychotherapy',
  'vietnamese': 'Dưỡng sức sau liệu pháp tâm thần',
}, {
  'id': 'Z54.4',
  'english': 'Convalescence following treatment of fracture',
  'vietnamese': 'Dưỡng sức sau điều trị gẫy xương',
}, {
  'id': 'Z54.7',
  'english': 'Convalescence following combined treatment',
  'vietnamese': 'Dưỡng sức sau điều trị phối hợp',
}, {
  'id': 'Z54.8',
  'english': 'Convalescence following other treatment',
  'vietnamese': 'Dưỡng sức sau điều trị khác',
}, {
  'id': 'Z54.9',
  'english': 'Convalescence following unspecified treatment',
  'vietnamese': 'Dưỡng sức sau điều trị không đặc hiệu',
}, {
  'id': 'Z55',
  'english': 'Problems related to education and literacy',
  'vietnamese': 'Những vấn đề liên quan đến giáo dục và biết chữ',
}, {
  'id': 'Z55.0',
  'english': 'Illiteracy and low-level literacy',
  'vietnamese': 'Mù chữ và biết chữ mức thấp',
}, {
  'id': 'Z55.1',
  'english': 'Schooling unavailable and unattainable',
  'vietnamese': 'Không có trường học và không tới được',
}, {'id': 'Z55.2', 'english': 'Failed examinations', 'vietnamese': 'Thi trượt'}, {
  'id': 'Z55.3',
  'english': 'Underachievement in school',
  'vietnamese': 'Học kém ở trường',
}, {
  'id': 'Z55.4',
  'english': 'Educational maladjustment and discord with teachers and classmates',
  'vietnamese': 'Bất hoà với giáo viên và bạn cùng lớp',
}, {
  'id': 'Z55.8',
  'english': 'Other problems related to education and literacy',
  'vietnamese': 'Các vấn đề khác liên quan đến giáo dục và mù chữ',
}, {
  'id': 'Z55.9',
  'english': 'Problem related to education and literacy, unspecified',
  'vietnamese': 'Vấn đề liên quan đến giáo dục và biết chữ, không đặc hiệu',
}, {
  'id': 'Z56',
  'english': 'Problems related to employment and unemployment',
  'vietnamese': 'Những vấn đề liên quan đến việc làm và thất nghiệp',
}, {
  'id': 'Z56.0',
  'english': 'Unemployment, unspecified',
  'vietnamese': 'Thất nghiệp, không đặc hiệu',
}, {'id': 'Z56.1', 'english': 'Change of job', 'vietnamese': 'Thay đổi công việc'}, {
  'id': 'Z56.2',
  'english': 'Threat of job loss',
  'vietnamese': 'Sợ mất việc',
}, {
  'id': 'Z56.3',
  'english': 'Stressful work schedule',
  'vietnamese': 'Lịch làm việc căng thẳng',
}, {
  'id': 'Z56.4',
  'english': 'Discord with boss and workmates',
  'vietnamese': 'Bất hoà với chủ và các đồng nghiệp',
}, {'id': 'Z56.5', 'english': 'Uncongenial work', 'vietnamese': 'Việc làm không thích hợp'}, {
  'id': 'Z56.6',
  'english': 'Other physical and mental strain related to work',
  'vietnamese': 'Căng thẳng tinh thần và thể lực liên quan đến công việc',
}, {
  'id': 'Z56.7',
  'english': 'Other and unspecified problems related to employment',
  'vietnamese': 'Các vấn đề khác không xác định liên quan đến công việc',
}, {
  'id': 'Z57',
  'english': 'Occupational exposure to risk-factors',
  'vietnamese': 'Nghề nghiệp tiếp xác với các yếu tố nguy cơ',
}, {
  'id': 'Z57.0',
  'english': 'Occupational exposure to noise',
  'vietnamese': 'Nghề nghiệp tiếp xúc với tiếng ồn',
}, {
  'id': 'Z57.1',
  'english': 'Occupational exposure to radiation',
  'vietnamese': 'Nghề nghiệp tiếp xúc với tia xạ',
}, {
  'id': 'Z57.2',
  'english': 'Occupational exposure to dust',
  'vietnamese': 'Nghề nghiệp tiếp xúc với bụi',
}, {
  'id': 'Z57.3',
  'english': 'Occupational exposure to other air contaminants',
  'vietnamese': 'Nghề nghiệp tiếp xúc với không khí lây nhiễm khác',
}, {
  'id': 'Z57.4',
  'english': 'Occupational exposure to toxic agents in agriculture',
  'vietnamese': 'Nghề nghiệp tiếp xúc với các chất độc hại trong nông nghiệp',
}, {
  'id': 'Z57.5',
  'english': 'Occupational exposure to toxic agents in other industries',
  'vietnamese': 'Nghề nghiệp tiếp xúc với các chất độc trong các ngành công nghiệp khác',
}, {
  'id': 'Z57.6',
  'english': 'Occupational exposure to extreme temperature',
  'vietnamese': 'Nghề nghiệp tiếp xúc với nhiệt độ cao',
}, {
  'id': 'Z57.7',
  'english': 'Occupational exposure to vibration',
  'vietnamese': 'Nghề nghiệp tiếp xúc với độ rung',
}, {
  'id': 'Z57.8',
  'english': 'Occupational exposure to other risk-factors',
  'vietnamese': 'Nghề nghiệp tiếp xúc với các yếu tố nguy cơ khác',
}, {
  'id': 'Z57.9',
  'english': 'Occupational exposure to unspecified risk-factor',
  'vietnamese': 'Nghề nghiệp tiếp xúc với các yếu tố nguy cơ không xác định',
}, {
  'id': 'Z58',
  'english': 'Problems related to physical environment',
  'vietnamese': 'Những vấn đề liên quan đến môi trường vật lý',
}, {'id': 'Z58.0', 'english': 'Exposure to noise', 'vietnamese': 'Phơi nhiễm với tiếng ồn'}, {
  'id': 'Z58.1',
  'english': 'Exposure to air pollution',
  'vietnamese': 'Phơi nhiễm với không khí ô nhiễm',
}, {
  'id': 'Z58.2',
  'english': 'Exposure to water pollution',
  'vietnamese': 'Phơi nhiễm với nước ô nhiễm',
}, {
  'id': 'Z58.3',
  'english': 'Exposure to soil pollution',
  'vietnamese': 'Phơi nhiễm với đất ô nhiễm',
}, {'id': 'Z58.4', 'english': 'Exposure to radiation', 'vietnamese': 'Phơi nhiễm với tia xạ'}, {
  'id': 'Z58.5',
  'english': 'Exposure to other pollution',
  'vietnamese': 'Phơi nhiễm với các ô nhiễm khác',
}, {
  'id': 'Z58.6',
  'english': 'Inadequate drinking-water supply',
  'vietnamese': 'Cung cấp nước uống không đầy đủ',
}, {
  'id': 'Z58.7',
  'english': 'Exposure to tobacco smoke',
  'vietnamese': 'Tiếp cận tình thế với khói thuốc',
}, {
  'id': 'Z58.8',
  'english': 'Other problems related to physical environment',
  'vietnamese': 'Các vấn đề khác liên quan đến môi trường vật lý',
}, {
  'id': 'Z58.9',
  'english': 'Problem related to physical environment, unspecified',
  'vietnamese': 'Các vấn đề liên quan đến môi trường vật lý, không đặc hiệu',
}, {
  'id': 'Z59',
  'english': 'Problems related to housing and economic circumstances',
  'vietnamese': 'Những vấn đề liên quan đến nhà ở và hoàn cảnh kinh tế',
}, {'id': 'Z59.0', 'english': 'Homelessness', 'vietnamese': 'Tình trạng vô gia cư'}, {
  'id': 'Z59.1',
  'english': 'Inadequate housing',
  'vietnamese': 'Nhà ở không đầy đủ',
}, {
  'id': 'Z59.2',
  'english': 'Discord with neighbours, lodgers and landlord',
  'vietnamese': 'Bất hoà với hàng xóm, người thuê và chủ nhà',
}, {
  'id': 'Z59.3',
  'english': 'Problems related to living in residential institution',
  'vietnamese': 'Những vấn đề liên quan đến sinh sống tại nơi cư trú',
}, {'id': 'Z59.4', 'english': 'Lack of adequate food', 'vietnamese': 'Thiếu thức ăn'}, {
  'id': 'Z59.5',
  'english': 'Extreme poverty',
  'vietnamese': 'Quá nghèo',
}, {'id': 'Z59.6', 'english': 'Low income', 'vietnamese': 'Thu nhập thấp'}, {
  'id': 'Z59.7',
  'english': 'Insufficient social insurance and welfare support',
  'vietnamese': 'Trợ cấp xã hội và bảo hiểm xã hội không đảm bảo',
}, {
  'id': 'Z59.8',
  'english': 'Other problems related to housing and economic circumstances',
  'vietnamese': 'Những vấn đề khác liên quan đến nhà ở và hoàn cảnh kinh tế',
}, {
  'id': 'Z59.9',
  'english': 'Problem related to housing and economic circumstances, unspecified',
  'vietnamese': 'Vấn đề liên quan đến nhà ở và hoàn cảnh kinh tế, không đặc hiệu',
}, {
  'id': 'Z60',
  'english': 'Problems related to social environment',
  'vietnamese': 'Vấn đề liên quan đến môi trường xã hội',
}, {
  'id': 'Z60.0',
  'english': 'Problems of adjustment to life-cycle transitions',
  'vietnamese': 'Vấn đề do điều chỉnh luân chuyển vòng đời',
}, {
  'id': 'Z60.1',
  'english': 'Atypical parenting situation',
  'vietnamese': 'Hoàn cảnh cha hay mẹ không điển hình',
}, {'id': 'Z60.2', 'english': 'Living alone', 'vietnamese': 'Sống đơn độc'}, {
  'id': 'Z60.3',
  'english': 'Acculturation difficulty',
  'vietnamese': 'Khó khăn trong việc tiếp nhận biến đổi văn hóa',
}, {
  'id': 'Z60.4',
  'english': 'Social exclusion and rejection',
  'vietnamese': 'Loại trừ và đào thải xã hội',
}, {
  'id': 'Z60.5',
  'english': 'Target of perceived adverse discrimination and persecution',
  'vietnamese': 'Mục tiêu phân biệt đối xử chống đối cảm nhận được và ngược đãi',
}, {
  'id': 'Z60.8',
  'english': 'Other problems related to social environment',
  'vietnamese': 'Các vấn đề khác liên quan đến môi trường xã hội',
}, {
  'id': 'Z60.9',
  'english': 'Problem related to social environment, unspecified',
  'vietnamese': 'Vấn đề liên quan đến môi trường xã hội, không đặc hiệu',
}, {
  'id': 'Z61',
  'english': 'Problems related to negative life events in childhood',
  'vietnamese': 'Những vấn đề liên quan đến sự kiện tiêu cực của đời sống thơ ấu',
}, {
  'id': 'Z61.0',
  'english': 'Loss of love relationship in childhood',
  'vietnamese': 'Mất quan hệ gần gũi trong thời kỳ thơ ấu',
}, {'id': 'Z61.1', 'english': 'Removal from home in childhood', 'vietnamese': 'Trẻ bỏ nhà'}, {
  'id': 'Z61.2',
  'english': 'Altered pattern of family relationships in childhood',
  'vietnamese': 'Những mô hình khác của mối quan hệ gia đình trong thời kỳ thơ ấu',
}, {
  'id': 'Z61.3',
  'english': 'Events resulting in loss of self-esteem in childhood',
  'vietnamese': 'Những sự kiện làm trẻ mất tự trọng',
}, {
  'id': 'Z61.4',
  'english': 'Problems related to alleged sexual abuse of child by person within primary support group',
  'vietnamese': 'Những vấn đề được xem là lạm dụng tình dục trẻ em của người lớn có quan hệ nương tựa chủ chốt',
}, {
  'id': 'Z61.5',
  'english': 'Problems related to alleged sexual abuse of child by person outside primary support group',
  'vietnamese': 'Những vấn đề được xem là lạm dụng tình dục trẻ em của người lớn ngoài quan hệ nương tựa chủ chốt',
}, {
  'id': 'Z61.6',
  'english': 'Problems related to alleged physical abuse of child',
  'vietnamese': 'Những vấn đề được xem là lạm dụng thân thể trẻ em',
}, {
  'id': 'Z61.7',
  'english': 'Personal frightening experience in childhood',
  'vietnamese': 'Yếu tố trực tiếp gây hoảng sợ cho trẻ',
}, {
  'id': 'Z61.8',
  'english': 'Other negative life events in childhood',
  'vietnamese': 'Những sự kiện tiêu cực khác ở tuổi ấu thơ',
}, {
  'id': 'Z61.9',
  'english': 'Negative life event in childhood, unspecified',
  'vietnamese': 'Sự kiện tiêu cực, không đặc hiệu',
}, {
  'id': 'Z62',
  'english': 'Other problems related to upbringing',
  'vietnamese': 'Những vấn đề khác liên quan đến giáo dưỡng',
}, {
  'id': 'Z62.0',
  'english': 'Inadequate parental supervision and control',
  'vietnamese': 'Theo dõi và kiểm soát không đầy đủ của cha mẹ',
}, {'id': 'Z62.1', 'english': 'Parental overprotection', 'vietnamese': 'Cha mẹ bảo vệ quá mức'}, {
  'id': 'Z62.2',
  'english': 'Institutional upbringing',
  'vietnamese': 'Giáo dưỡng thể chế',
}, {
  'id': 'Z62.3',
  'english': 'Hostility towards and scapegoating of child',
  'vietnamese': 'Thái độ thù địch với trẻ',
}, {
  'id': 'Z62.4',
  'english': 'Emotional neglect of child',
  'vietnamese': 'Thái độ thờ ơ với trẻ',
}, {
  'id': 'Z62.5',
  'english': 'Other problems related to neglect in upbringing',
  'vietnamese': 'Các vấn đề khác liên quan đến giáo dưỡng thờ ơ',
}, {
  'id': 'Z62.6',
  'english': 'Inappropriate parental pressure and other abnormal qualities of upbringing',
  'vietnamese': 'Áp đặt không phù hợp của cha mẹ và kiểu cách giáo dưỡng bất thường',
}, {
  'id': 'Z62.8',
  'english': 'Other specified problems related to upbringing',
  'vietnamese': 'Các vấn đề đặc hiệu khác liên quan tới giáo dưỡng',
}, {
  'id': 'Z62.9',
  'english': 'Problem related to upbringing, unspecified',
  'vietnamese': 'Vấn đề liên quan tới giáo dưỡng, không đặc hiệu',
}, {
  'id': 'Z63',
  'english': 'Other problems related to primary support group, including family circumstances',
  'vietnamese': 'Những vấn đề khác liên quan đến nhóm trợ giúp, bao gồm cả những hoàn cảnh gia đình',
}, {
  'id': 'Z63.0',
  'english': 'Problems in relationship with spouse or partner',
  'vietnamese': 'Những vấn đề liên quan đến vợ chồng hay thành viên',
}, {
  'id': 'Z63.1',
  'english': 'Problems in relationship with parents and in-laws',
  'vietnamese': 'Những vấn đề liên quan đến cha mẹ và thông gia',
}, {
  'id': 'Z63.2',
  'english': 'Inadequate family support',
  'vietnamese': 'Hỗ trợ không đầy đủ của gia đình',
}, {
  'id': 'Z63.3',
  'english': 'Absence of family member',
  'vietnamese': 'Thiếu vắng thành viên trong gia đình',
}, {
  'id': 'Z63.4',
  'english': 'Disappearance and death of family member',
  'vietnamese': 'Sự biến mất hay chết của thành viên gia đình',
}, {
  'id': 'Z63.5',
  'english': 'Disruption of family by separation and divorce',
  'vietnamese': 'Sự tan vỡ của gia đình do ly thân và ly dị',
}, {
  'id': 'Z63.6',
  'english': 'Dependent relative needing care at home',
  'vietnamese': 'Người thân sống lệ thuộc vào chăm sóc tại nhà',
}, {
  'id': 'Z63.7',
  'english': 'Other stressful life events affecting family and household',
  'vietnamese': 'Các sự kiện chấn động đời sống khác ảnh hưởng đến gia quyến và hộ gia đình',
}, {
  'id': 'Z63.8',
  'english': 'Other specified problems related to primary support group',
  'vietnamese': 'Các vấn đề đặc hiệu khác liên quan đến nhóm trợ giúp',
}, {
  'id': 'Z63.9',
  'english': 'Problem related to primary support group, unspecified',
  'vietnamese': 'Vấn đề khác liên quan đến nhóm trợ giúp, không đặc hiệu',
}, {
  'id': 'Z64',
  'english': 'Problems related to certain psychosocial circumstances',
  'vietnamese': 'Các vấn đề liên quan đến một số hoàn cảnh tâm lí xã hội nhất định',
}, {
  'id': 'Z64.0',
  'english': 'Problems related to unwanted pregnancy',
  'vietnamese': 'Các vấn đề liên quan đến mang thai không mong muốn',
}, {
  'id': 'Z64.1',
  'english': 'Problems related to multiparity',
  'vietnamese': 'Những vấn đề liên quan đến đẻ nhiều con',
}, {
  'id': 'Z64.2',
  'english': 'Seeking and accepting physical, nutritional and chemical interventions known to be hazardous and harmful',
  'vietnamese': 'Phát hiện và thừa nhận các yếu tố vật lí, dinh dưỡng và hóa học, xác định là có nguy cơ và có hại',
}, {
  'id': 'Z64.3',
  'english': 'Seeking and accepting behavioural and psychological interventions known to be hazardous and harmful',
  'vietnamese': 'Phát hiện và thừa nhận các yếu tố ứng xử và tâm lý xác định là có nguy cơ và có hại',
}, {
  'id': 'Z64.4',
  'english': 'Discord with counsellors',
  'vietnamese': 'Bất hoà với các nhà tư vấn',
}, {
  'id': 'Z65',
  'english': 'Problems related to other psychosocial circumstances',
  'vietnamese': 'Vấn đề liên quan đến hoàn cảnh tâm lý xã hội khác',
}, {
  'id': 'Z65.0',
  'english': 'Conviction in civil and criminal proceedings without imprisonment',
  'vietnamese': 'Kết án với các vụ kiện dân sự và hình sự không tống giam',
}, {
  'id': 'Z65.1',
  'english': 'Imprisonment and other incarceration',
  'vietnamese': 'Tống giam và các giam cầm khác',
}, {
  'id': 'Z65.2',
  'english': 'Problems related to release from prison',
  'vietnamese': 'Vấn đề liên quan đến ra tù',
}, {
  'id': 'Z65.3',
  'english': 'Problems related to other legal circumstances',
  'vietnamese': 'Vấn đề liên quan đến pháp luật',
}, {
  'id': 'Z65.4',
  'english': 'Victim of crime and terrorism',
  'vietnamese': 'Nạn nhân của tội ác và khủng bố',
}, {
  'id': 'Z65.5',
  'english': 'Exposure to disaster, war and other hostilities',
  'vietnamese': 'Tiếp cận tình thế với thảm hoạ, chiến tranh và thù địch',
}, {
  'id': 'Z65.8',
  'english': 'Other specified problems related to psychosocial circumstances',
  'vietnamese': 'Những vấn đề đặc hiệu khác liên quan đến hoàn cảnh tâm lí xã hội',
}, {
  'id': 'Z65.9',
  'english': 'Problem related to unspecified psychosocial circumstances',
  'vietnamese': 'Những vấn đề liên quan đến các hoàn cảnh tâm lí xã hội không đặc hiệu',
}, {
  'id': 'Z70',
  'english': 'Counselling related to sexual attitude, behaviour and orientation',
  'vietnamese': 'Tư vấn liên quan đến thái độ, hành vi và khuynh hướng tình dục',
}, {
  'id': 'Z70.0',
  'english': 'Counselling related to sexual attitude',
  'vietnamese': 'Tư vấn liên quan đến thái độ tình dục',
}, {
  'id': 'Z70.1',
  'english': 'Counselling related to patient s sexual behaviour and orientation',
  'vietnamese': 'Tư vấn về thái độ và khuynh hướng tình dục của người bệnh',
}, {
  'id': 'Z70.2',
  'english': 'Counselling related to sexual behaviour and orientation of third party',
  'vietnamese': 'Tư vấn về thái độ và khuynh hướng tình dục của nhân vật thứ ba',
}, {
  'id': 'Z70.3',
  'english': 'Counselling related to combined concerns regarding sexual attitude, behaviour and orientation',
  'vietnamese': 'Tư vấn liên quan đến các lo âu hỗn hợp về hành vi, thái độ và khuynh hướng tình dục',
}, {
  'id': 'Z70.8',
  'english': 'Other sex counselling',
  'vietnamese': 'Các tư vấn khác về tình dục',
}, {
  'id': 'Z70.9',
  'english': 'Sex counselling, unspecified',
  'vietnamese': 'Tư vấn tình dục, không đặc hiệu',
}, {
  'id': 'Z71',
  'english': 'Persons encountering health services for other counselling and medical advice, not elsewhere classified',
  'vietnamese': 'Những người đến cơ sở y tế để tư vấn y học và tư vấn khác, không xác định',
}, {
  'id': 'Z71.0',
  'english': 'Person consulting on behalf of another person',
  'vietnamese': 'Người thay mặt cho người khác đến tư vấn',
}, {
  'id': 'Z71.1',
  'english': 'Person with feared complaint in whom no diagnosis is made',
  'vietnamese': 'Người than phiền lo sợ những việc không được chứng minh',
}, {
  'id': 'Z71.2',
  'english': 'Person consulting for explanation of investigation findings',
  'vietnamese': 'Người đến tư vấn để được giải thích về các kết quả đã phát hiện',
}, {
  'id': 'Z71.3',
  'english': 'Dietary counselling and surveillance',
  'vietnamese': 'Tư vấn và theo dõi chế độ ăn uống',
}, {
  'id': 'Z71.4',
  'english': 'Alcohol abuse counselling and surveillance',
  'vietnamese': 'Tư vấn và theo dõi lạm dụng rượu',
}, {
  'id': 'Z71.5',
  'english': 'Drug abuse counselling and surveillance',
  'vietnamese': 'Tư vấn và giám sát lạm dụng ma túy',
}, {
  'id': 'Z71.6',
  'english': 'Tobacco abuse counselling',
  'vietnamese': 'Tư vấn lạm dụng thuốc lá',
}, {
  'id': 'Z71.7',
  'english': 'Human immunodeficiency virus [HIV] counselling',
  'vietnamese': 'Tư vấn về virus suy giảm miễn dịch người (HIV)',
}, {
  'id': 'Z71.8',
  'english': 'Other specified counselling',
  'vietnamese': 'Tư vấn đặc hiệu khác',
}, {
  'id': 'Z71.9',
  'english': 'Counselling, unspecified',
  'vietnamese': 'Tư vấn không đặc hiệu',
}, {
  'id': 'Z72',
  'english': 'Problems related to lifestyle',
  'vietnamese': 'Các vấn đề liên quan đến lối sống',
}, {'id': 'Z72.0', 'english': 'Tobacco use', 'vietnamese': 'Sử dụng thuốc lá'}, {
  'id': 'Z72.1',
  'english': 'Alcohol use',
  'vietnamese': 'Sử dụng rượu',
}, {'id': 'Z72.2', 'english': 'Drug use', 'vietnamese': 'Sử dụng ma túy'}, {
  'id': 'Z72.3',
  'english': 'Lack of physical exercise',
  'vietnamese': 'Không luyện tập thể dục',
}, {
  'id': 'Z72.4',
  'english': 'Inappropriate diet and eating habits',
  'vietnamese': 'Chế độ ăn uống và thói quen ăn uống không thích hợp',
}, {
  'id': 'Z72.5',
  'english': 'High-risk sexual behaviour',
  'vietnamese': 'Hành vi tình dục nguy cơ cao',
}, {'id': 'Z72.6', 'english': 'Gambling and betting', 'vietnamese': 'Đánh bạc và đánh cuộc'}, {
  'id': 'Z72.8',
  'english': 'Other problems related to lifestyle',
  'vietnamese': 'Các vấn đề khác liên quan đến lối sống',
}, {
  'id': 'Z72.9',
  'english': 'Problem related to lifestyle, unspecified',
  'vietnamese': 'Vấn đề liên quan đến lối sống, không xác định',
}, {
  'id': 'Z73',
  'english': 'Problems related to life-management difficulty',
  'vietnamese': 'Các vấn đề liên quan đến khó khăn về quản trị cuộc sống',
}, {'id': 'Z73.0', 'english': 'Burn-out', 'vietnamese': 'Cạn kiệt'}, {
  'id': 'Z73.1',
  'english': 'Accentuation of personality traits',
  'vietnamese': 'Những nét cá tính nổi bật',
}, {
  'id': 'Z73.2',
  'english': 'Lack of relaxation and leisure',
  'vietnamese': 'Thiếu thư giãn và giải trí',
}, {
  'id': 'Z73.3',
  'english': 'Stress, not elsewhere classified',
  'vietnamese': 'Sang chấn, chưa được phân loại ở phần khác',
}, {
  'id': 'Z73.4',
  'english': 'Inadequate social skills, not elsewhere classified',
  'vietnamese': 'Kỹ năng xã hội không đầy đủ, chưa được phân loại ở phần khác',
}, {
  'id': 'Z73.5',
  'english': 'Social role conflict, not elsewhere classified',
  'vietnamese': 'Mâu thuẫn vai trò xã hội, chưa được phân loại ở phần khác',
}, {
  'id': 'Z73.6',
  'english': 'Limitation of activities due to disability',
  'vietnamese': 'Hạn chế hoạt động vì khuyết tật',
}, {
  'id': 'Z73.8',
  'english': 'Other problems related to life-management difficulty',
  'vietnamese': 'Các vấn đề khác liên quan đến khó khăn về quản trị cuộc sống',
}, {
  'id': 'Z73.9',
  'english': 'Problem related to life-management difficulty, unspecified',
  'vietnamese': 'Vấn đề liên quan đến khó khăn về quản trị cuộc sống, không xác định',
}, {
  'id': 'Z74',
  'english': 'Problems related to care-provider dependency',
  'vietnamese': 'Các vấn đề liên quan đến việc phụ thuộc vào người chăm sóc',
}, {
  'id': 'Z74.0',
  'english': 'Reduced mobility',
  'vietnamese': 'Nhu cầu trợ giúp cho giảm di chuyển',
}, {
  'id': 'Z74.1',
  'english': 'Need for assistance with personal care',
  'vietnamese': 'Nhu cầu trợ giúp chăm sóc cho bản thân',
}, {
  'id': 'Z74.2',
  'english': 'Need for assistance at home and no other household member able to render care',
  'vietnamese': 'Nhu cầu trợ giúp chăm sóc tại nhà và nhu cầu trợ giúp khi trong nhà không ai có khả năng chăm sóc',
}, {
  'id': 'Z74.3',
  'english': 'Need for continuous supervision',
  'vietnamese': 'Nhu cầu Theo dõi liên tục',
}, {
  'id': 'Z74.8',
  'english': 'Other problems related to care-provider dependency',
  'vietnamese': 'Các vấn đề khác liên quan đến phụ thuộc của bản thân vào người chăm sóc',
}, {
  'id': 'Z74.9',
  'english': 'Problem related to care-provider dependency, unspecified',
  'vietnamese': 'Vấn đề liên quan đến lệ thuộc của bản thân vào người chăm sóc, không đặc hiệu',
}, {
  'id': 'Z75',
  'english': 'Problems related to medical facilities and other health care',
  'vietnamese': 'Những vấn đề liên quan đến phương tiện y học và chăm sóc sức khỏe khác',
}, {
  'id': 'Z75.0',
  'english': 'Medical services not available in home',
  'vietnamese': 'Các dịch vụ y học không có sẵn tại nhà',
}, {
  'id': 'Z75.1',
  'english': 'Person awaiting admission to adequate facility elsewhere',
  'vietnamese': 'Người chờ đợi được thu nhận vào một cơ sở thích hợp nào đó',
}, {
  'id': 'Z75.2',
  'english': 'Other waiting period for investigation and treatment',
  'vietnamese': 'Giai đoạn chờ đợi để kiểm tra và điều trị',
}, {
  'id': 'Z75.3',
  'english': 'Unavailability and inaccessibility of health-care facilities',
  'vietnamese': 'Không sử dụng được và không tiếp cận được các phương tiện chăm sóc',
}, {
  'id': 'Z75.4',
  'english': 'Unavailability and inaccessibility of other helping agencies',
  'vietnamese': 'Không có sẵn hoặc không tiếp cận được với các cơ quan trợ giúp khác',
}, {'id': 'Z75.5', 'english': 'Holiday relief care', 'vietnamese': 'Chăm sóc trong ngày nghỉ'}, {
  'id': 'Z75.8',
  'english': 'Other problems related to medical facilities and other health care',
  'vietnamese': 'Những vấn đề khác liên quan đến phương tiện y học và chăm sóc sức khỏe',
}, {
  'id': 'Z75.9',
  'english': 'Unspecified problem related to medical facilities and other health care',
  'vietnamese': 'Vấn đề không đặc hiệu liên quan đến phương tiện y học và chăm sóc sức khỏe khác',
}, {
  'id': 'Z76',
  'english': 'Persons encountering health services in other circumstances',
  'vietnamese': 'Những người đến cơ quan y tế trong những hoàn cảnh khác',
}, {
  'id': 'Z76.0',
  'english': 'Issue of repeat prescription',
  'vietnamese': 'Chỉ định y lệnh tiếp',
}, {
  'id': 'Z76.1',
  'english': 'Health supervision and care of foundling',
  'vietnamese': 'Theo dõi sức khỏe và chăm sóc trẻ bị bỏ rơi',
}, {
  'id': 'Z76.2',
  'english': 'Health supervision and care of other healthy infant and child',
  'vietnamese': 'Theo dõi sức khỏe và chăm sóc trẻ em khỏe mạnh khác',
}, {
  'id': 'Z76.3',
  'english': 'Healthy person accompanying sick person',
  'vietnamese': 'Người khỏe đi theo người ốm',
}, {
  'id': 'Z76.4',
  'english': 'Other boarder in health-care facility',
  'vietnamese': 'Người nội trú khác trong cơ sở y tế',
}, {
  'id': 'Z76.5',
  'english': 'Malingerer [conscious simulation]',
  'vietnamese': 'Giả bệnh [ đóng giả có ý thức]',
}, {
  'id': 'Z76.8',
  'english': 'Persons encountering health services in other specified circumstances',
  'vietnamese': 'Người đến cơ sở y tế trong hoàn cảnh xác định khác',
}, {
  'id': 'Z76.9',
  'english': 'Person encountering health services in unspecified circumstances',
  'vietnamese': 'Người đến cơ sở y tế trong hoàn cảnh không xác định',
}, {
  'id': 'Z80',
  'english': 'Family history of malignant neoplasm',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính',
}, {
  'id': 'Z80.0',
  'english': 'Family history of malignant neoplasm of digestive organs',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính ở cơ quan tiêu hóa',
}, {
  'id': 'Z80.1',
  'english': 'Family history of malignant neoplasm of trachea, bronchus and lung',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính ở khí quản, phế quản và phổi',
}, {
  'id': 'Z80.2',
  'english': 'Family history of malignant neoplasm of other respiratory and intrathoracic organs',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính ở các cơ quan hô hấp và trong lồng ngực khác',
}, {
  'id': 'Z80.3',
  'english': 'Family history of malignant neoplasm of breast',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính ở vú',
}, {
  'id': 'Z80.4',
  'english': 'Family history of malignant neoplasm of genital organs',
  'vietnamese': 'bệnh sử gia đình có khối u ác tính ở cơ quan sinh dục',
}, {
  'id': 'Z80.5',
  'english': 'Family history of malignant neoplasm of urinary tract',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính ở đường tiết niệu',
}, {
  'id': 'Z80.6',
  'english': 'Family history of leukaemia',
  'vietnamese': 'Bệnh sử gia đình có bệnh bạch cầu',
}, {
  'id': 'Z80.7',
  'english': 'Family history of other malignant neoplasms of lymphoid, haematopoietic and related tissues',
  'vietnamese': 'Bệnh sử gia đình có u ác tính dạng lympho (bạch huyết), hệ tạo máu và các tổ chức liên quan',
}, {
  'id': 'Z80.8',
  'english': 'Family history of malignant neoplasm of other organs or systems',
  'vietnamese': 'Bệnh sử gia đình có khối u ác tính của cơ quan và hệ thống khác',
}, {
  'id': 'Z80.9',
  'english': 'Family history of malignant neoplasm, unspecified',
  'vietnamese': 'Bệnh sử đình có khối u ác tính, không đặc hiệu',
}, {
  'id': 'Z81',
  'english': 'Family history of mental and behavioural disorders',
  'vietnamese': 'Bệnh sử gia đình có rối loạn tâm thần và hành vi',
}, {
  'id': 'Z81.0',
  'english': 'Family history of mental retardation',
  'vietnamese': 'Bệnh sử gia đình có chậm phát triển tâm thần',
}, {
  'id': 'Z81.1',
  'english': 'Family history of alcohol abuse',
  'vietnamese': 'Bệnh sử gia đình có lạm dụng rượu',
}, {
  'id': 'Z81.2',
  'english': 'Family history of tobacco abuse',
  'vietnamese': 'Bệnh sử gia đình có lạm dụng thuốc lá',
}, {
  'id': 'Z81.3',
  'english': 'Family history of other psychoactive substance abuse',
  'vietnamese': 'Bệnh sử gia đình có lạm dụng thuốc kích thần',
}, {
  'id': 'Z81.4',
  'english': 'Family history of other substance abuse',
  'vietnamese': 'Bệnh sử gia đình có lạm dụng các chất khác',
}, {
  'id': 'Z81.8',
  'english': 'Family history of other mental and behavioural disorders',
  'vietnamese': 'Bệnh sử gia đình có các rối loạn hành vi và tâm thần',
}, {
  'id': 'Z82',
  'english': 'Family history of certain disabilities and chronic diseases leading to disablement',
  'vietnamese': 'Bệnh sử gia đình có một số khuyết tật và bệnh mãn tính dẫn đến tàn tật',
}, {
  'id': 'Z82.0',
  'english': 'Family history of epilepsy and other diseases of the nervous system',
  'vietnamese': 'Bệnh sử gia đình có động kinh và các bệnh khác của hệ thần kinh',
}, {
  'id': 'Z82.1',
  'english': 'Family history of blindness and visual loss',
  'vietnamese': 'Bệnh sử gia đình có mù loà và mất thị giác',
}, {
  'id': 'Z82.2',
  'english': 'Family history of deafness and hearing loss',
  'vietnamese': 'Bệnh sử gia đình có điếc và mất thính giác',
}, {
  'id': 'Z82.3',
  'english': 'Family history of stroke',
  'vietnamese': 'Bệnh sử gia đình có tai biến mạch máu não',
}, {
  'id': 'Z82.4',
  'english': 'Family history of ischaemic heart disease and other diseases of the circulatory system',
  'vietnamese': 'Bệnh sử gia đình có bệnh thiếu máu cơ tim và các bệnh khác của hệ tuần hoàn',
}, {
  'id': 'Z82.5',
  'english': 'Family history of asthma and other chronic lower respiratory diseases',
  'vietnamese': 'Bệnh sử gia đình có hen và các bệnh mãn tính khác của đường hô hấp dưới',
}, {
  'id': 'Z82.6',
  'english': 'Family history of arthritis and other diseases of the musculoskeletal system and connective tissue',
  'vietnamese': 'Bệnh sử gia đình có viêm khớp và các bệnh khác về hệ cơ xương và tổ chức liên kết',
}, {
  'id': 'Z82.7',
  'english': 'Family history of congenital malformations, deformations and chromosomal abnormalities',
  'vietnamese': 'Bệnh sử gia đình có dị tật bẩm sinh, dị tật và bất thường nhiễm sắc thể',
}, {
  'id': 'Z82.8',
  'english': 'Family history of other disabilities and chronic diseases leading to disablement, not elsewhere classified',
  'vietnamese': 'Bệnh sử gia đình có khuyết tật và các bệnh mãn tính khác dẫn đến tàn tật, chưa phân loại ở phần khác',
}, {
  'id': 'Z83',
  'english': 'Family history of other specific disorders',
  'vietnamese': 'Bệnh sử gia đình có các rối loạn đặc biệt khác',
}, {
  'id': 'Z83.0',
  'english': 'Family history of human immunodeficiency virus [HIV] disease',
  'vietnamese': 'Bệnh sử gia đình có bệnh suy giảm miễn dịch virus người (HIV)',
}, {
  'id': 'Z83.1',
  'english': 'Family history of other infectious and parasitic diseases',
  'vietnamese': 'Bệnh sử gia đình có các bệnh nhiễm khuẩn và ký sinh trùng khác',
}, {
  'id': 'Z83.2',
  'english': 'Family history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
  'vietnamese': 'Bệnh sử gia đình có các bệnh về máu và cơ quan tạo máu và một số rối loạn về cơ chế miễn dịch',
}, {
  'id': 'Z83.3',
  'english': 'Family history of diabetes mellitus',
  'vietnamese': 'Bệnh sử gia đình có bệnh đái tháo đường',
}, {
  'id': 'Z83.4',
  'english': 'Family history of other endocrine, nutritional and metabolic diseases',
  'vietnamese': 'Bệnh sử gia đình có các bệnh về nội tiết, dinh dưỡng và chuyển hóa',
}, {
  'id': 'Z83.5',
  'english': 'Family history of eye and ear disorders',
  'vietnamese': 'Bệnh sử gia đình có các rối loạn thị giác và thính giác',
}, {
  'id': 'Z83.6',
  'english': 'Family history of diseases of the respiratory system',
  'vietnamese': 'Bệnh sử gia đình có các bệnh hô hấp',
}, {
  'id': 'Z83.7',
  'english': 'Family history of diseases of the digestive system',
  'vietnamese': 'Bệnh sử gia đình có các bệnh thuộc hệ tiêu hóa',
}, {
  'id': 'Z84',
  'english': 'Family history of other conditions',
  'vietnamese': 'Bệnh sử gia đình có các bệnh khác',
}, {
  'id': 'Z84.0',
  'english': 'Family history of diseases of the skin and subcutaneous tissue',
  'vietnamese': 'Bệnh sử gia đình có các bệnh về da và tổ chức dưới da',
}, {
  'id': 'Z84.1',
  'english': 'Family history of disorders of kidney and ureter',
  'vietnamese': 'Bệnh sử gia đình có các rối loạn về thận và niệu quản',
}, {
  'id': 'Z84.2',
  'english': 'Family history of other diseases of the genitourinary system',
  'vietnamese': 'Bệnh sử gia đình có các bệnh thuộc hệ sinh dục tiết niệu',
}, {
  'id': 'Z84.3',
  'english': 'Family history of consanguinity',
  'vietnamese': 'Bệnh sử gia đình có các bệnh về huyết thống',
}, {
  'id': 'Z84.8',
  'english': 'Family history of other specified conditions',
  'vietnamese': 'Bệnh sử gia đình có các bệnh đặc hiệu khác',
}, {
  'id': 'Z85',
  'english': 'Personal history of malignant neoplasm',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính',
}, {
  'id': 'Z85.0',
  'english': 'Personal history of malignant neoplasm of digestive organs',
  'vietnamese': 'Bệnh sử cá nhân có u ác tính của cơ quan tiêu hóa',
}, {
  'id': 'Z85.1',
  'english': 'Personal history of malignant neoplasm of trachea, bronchus and lung',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính ở khí quản, phế quản và phổi',
}, {
  'id': 'Z85.2',
  'english': 'Personal history of malignant neoplasm of other respiratory and intrathoracic organs',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính ở các cơ quan hô hấp và các cơ quan khác trong lồng ngực',
}, {
  'id': 'Z85.3',
  'english': 'Personal history of malignant neoplasm of breast',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính ở vú',
}, {
  'id': 'Z85.4',
  'english': 'Personal history of malignant neoplasm of genital organs',
  'vietnamese': 'Bệnh sử cá nhân có u ác tính ở các cơ quan sinh dục',
}, {
  'id': 'Z85.5',
  'english': 'Personal history of malignant neoplasm of urinary tract',
  'vietnamese': 'Bệnh sử cá nhân có u ác tính của đường tiết niệu',
}, {
  'id': 'Z85.6',
  'english': 'Personal history of leukaemia',
  'vietnamese': 'Bệnh sử cá nhân có bệnh bạch cầu',
}, {
  'id': 'Z85.7',
  'english': 'Personal history of other malignant neoplasms of lymphoid, haematopoietic and related tissues',
  'vietnamese': 'Bệnh sử cá nhân có u ác tính hệ lympho, cơ quan tạo máu và tổ chức tạo máu',
}, {
  'id': 'Z85.8',
  'english': 'Personal history of malignant neoplasms of other organs and systems',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính ở các cơ quan và hệ thống khác',
}, {
  'id': 'Z85.9',
  'english': 'Personal history of malignant neoplasm, unspecified',
  'vietnamese': 'Bệnh sử cá nhân có khối u ác tính, không đặc hiệu',
}, {
  'id': 'Z86',
  'english': 'Personal history of certain other diseases',
  'vietnamese': 'Bệnh sử cá nhân có một số bệnh khác',
}, {
  'id': 'Z86.0',
  'english': 'Personal history of other neoplasms',
  'vietnamese': 'Bệnh sử cá nhân có các khối u khác',
}, {
  'id': 'Z86.1',
  'english': 'Personal history of infectious and parasitic diseases',
  'vietnamese': 'Bệnh sử cá nhân có bệnh nhiễm khuẩn và ký sinh trùng',
}, {
  'id': 'Z86.2',
  'english': 'Personal history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh về máu và cơ quan tạo máu và một số rối loạn về cơ chế miễn dịch',
}, {
  'id': 'Z86.3',
  'english': 'Personal history of endocrine, nutritional and metabolic diseases',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh về nội tiết dinh dưỡng và chuyển hóa',
}, {
  'id': 'Z86.4',
  'english': 'Personal history of psychoactive substance abuse',
  'vietnamese': 'Bệnh sử cá nhân có lạm dụng chất kích thần',
}, {
  'id': 'Z86.5',
  'english': 'Personal history of other mental and behavioural disorders',
  'vietnamese': 'Bệnh sử cá nhân có các rối loạn tâm thần và hành vi khác',
}, {
  'id': 'Z86.6',
  'english': 'Personal history of diseases of the nervous system and sense organs',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh về hệ thần kinh và các giác quan',
}, {
  'id': 'Z86.7',
  'english': 'Personal history of diseases of the circulatory system',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh về hệ tuần hoàn',
}, {
  'id': 'Z87',
  'english': 'Personal history of other diseases and conditions',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh và trạng thái bệnh khác',
}, {
  'id': 'Z87.0',
  'english': 'Personal history of diseases of the respiratory system',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh thuộc hệ hô hấp',
}, {
  'id': 'Z87.1',
  'english': 'Personal history of diseases of the digestive system',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh thuộc hệ tiêu hóa',
}, {
  'id': 'Z87.2',
  'english': 'Personal history of diseases of the skin and subcutaneous tissue',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh về da và tổ chức dưới da',
}, {
  'id': 'Z87.3',
  'english': 'Personal history of diseases of the musculoskeletal system and connective tissue',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh thuộc hệ cơ xương và tổ chức liên kết',
}, {
  'id': 'Z87.4',
  'english': 'Personal history of diseases of the genitourinary system',
  'vietnamese': 'Bệnh sử cá nhân có các bệnh thuộc hệ sinh dục tiết niệu',
}, {
  'id': 'Z87.5',
  'english': 'Personal history of complications of pregnancy, childbirth and the puerperium',
  'vietnamese': 'Bệnh sử cá nhân có các biến chứng của thai nghén, sinh đẻ và sau đẻ',
}, {
  'id': 'Z87.6',
  'english': 'Personal history of certain conditions arising in the perinatal period',
  'vietnamese': 'Bệnh sử cá nhân có một số bệnh xuất hiện trong thời kỳ chu sinh',
}, {
  'id': 'Z87.7',
  'english': 'Personal history of congenital malformations, deformations and chromosomal abnormalities',
  'vietnamese': 'Bệnh sử cá nhân có các dị tật bẩm sinh, dị tật và bất thường nhiễm sắc thể',
}, {
  'id': 'Z87.8',
  'english': 'Personal history of other specified conditions',
  'vietnamese': 'Bệnh sử cá nhân có các trạng thái bệnh đặc hiệu khác',
}, {
  'id': 'Z88',
  'english': 'Personal history of allergy to drugs, medicaments and biological substances',
  'vietnamese': 'Bệnh sử cá nhân có có các dị ứng với ma túy, thuốc và các chất sinh vật khác',
}, {
  'id': 'Z88.0',
  'english': 'Personal history of allergy to penicillin',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với penicillin',
}, {
  'id': 'Z88.1',
  'english': 'Personal history of allergy to other antibiotic agents',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với các kháng sinh khác',
}, {
  'id': 'Z88.2',
  'english': 'Personal history of allergy to sulfonamides',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với sulfonamides',
}, {
  'id': 'Z88.3',
  'english': 'Personal history of allergy to other anti-infective agents',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với các chất chống nhiễm khuẩn khác',
}, {
  'id': 'Z88.4',
  'english': 'Personal history of allergy to anaesthetic agent',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với thuốc gây mê',
}, {
  'id': 'Z88.5',
  'english': 'Personal history of allergy to narcotic agent',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với các chất gây ngủ',
}, {
  'id': 'Z88.6',
  'english': 'Personal history of allergy to analgesic agent',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với các chất giảm đau',
}, {
  'id': 'Z88.7',
  'english': 'Personal history of allergy to serum and vaccine',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với huyết thanh và vaccin',
}, {
  'id': 'Z88.8',
  'english': 'Personal history of allergy to other drugs, medicaments and biological substances',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với các chất ma túy, thuốc và các chất sinh học khác',
}, {
  'id': 'Z88.9',
  'english': 'Personal history of allergy to unspecified drugs, medicaments and biological substances',
  'vietnamese': 'Bệnh sử cá nhân dị ứng với chất ma túy, thuốc các chất sinh học không đặc hiệu khác',
}, {'id': 'Z89', 'english': 'Acquired absence of limb', 'vietnamese': 'Mất chi mắc phải'}, {
  'id': 'Z89.0',
  'english': 'Acquired absence of finger(s) [including thumb], unilateral',
  'vietnamese': 'Mất nhiều ngón tay mắc phải [kể cả ngón cái], một bên',
}, {
  'id': 'Z89.1',
  'english': 'Acquired absence of hand and wrist',
  'vietnamese': 'Mất bàn tay và cổ tay mắc phải',
}, {
  'id': 'Z89.2',
  'english': 'Acquired absence of upper limb above wrist',
  'vietnamese': 'Mất chi trên ở trên ở cổ tay mắc phải',
}, {
  'id': 'Z89.3',
  'english': 'Acquired absence of both upper limbs [any level]',
  'vietnamese': 'Mất cả hai chi trên mắc phải [bất kỳ mức nào ]',
}, {
  'id': 'Z89.4',
  'english': 'Acquired absence of foot and ankle',
  'vietnamese': 'Mất bàn chân và mắt cá chân mắc phải (nhiều) ngón chân',
}, {
  'id': 'Z89.5',
  'english': 'Acquired absence of leg at or below knee',
  'vietnamese': 'Mất cẳng chân tại khớp gối hay dưới khớp gối mắc phải',
}, {
  'id': 'Z89.6',
  'english': 'Acquired absence of leg above knee',
  'vietnamese': 'Mất cẳng chân trên khớp gối mắc phải',
}, {
  'id': 'Z89.7',
  'english': 'Acquired absence of both lower limbs [any level, except toes alone]',
  'vietnamese': 'Mất cả hai chi dưới mắc phải [bất kỳ mức nào, trừ riêng ngón chân]',
}, {
  'id': 'Z89.8',
  'english': 'Acquired absence of upper and lower limbs [any level]',
  'vietnamese': 'Mất chi trên và chi dưới mắc phải (bất kỳ mức nào)',
}, {
  'id': 'Z89.9',
  'english': 'Acquired absence of limb, unspecified',
  'vietnamese': 'Mất chi mắc phải, không đặc hiệu',
}, {
  'id': 'Z90',
  'english': 'Acquired absence of organs, not elsewhere classified',
  'vietnamese': 'Mất cơ quan mắc phải, chưa phân loại ở phần khác',
}, {
  'id': 'Z90.0',
  'english': 'Acquired absence of part of head and neck',
  'vietnamese': 'Mất một phần của đầu và cổ mắc phải',
}, {
  'id': 'Z90.1',
  'english': 'Acquired absence of breast(s)',
  'vietnamese': 'Mất (một hoặc hai bên) vú mắc phải',
}, {
  'id': 'Z90.2',
  'english': 'Acquired absence of lung [part of]',
  'vietnamese': 'Mất (một phần) phổi mắc phải',
}, {
  'id': 'Z90.3',
  'english': 'Acquired absence of part of stomach',
  'vietnamese': 'Mất một phần dạ dày mắc phải',
}, {
  'id': 'Z90.4',
  'english': 'Acquired absence of other parts of digestive tract',
  'vietnamese': 'Mất các phần khác của đường tiêu hóa mắc phải',
}, {'id': 'Z90.5', 'english': 'Acquired absence of kidney', 'vietnamese': 'Mất thận mắc phải'}, {
  'id': 'Z90.6',
  'english': 'Acquired absence of other organs of urinary tract',
  'vietnamese': 'Mất các phần khác của đường tiết niệu mắc phải',
}, {
  'id': 'Z90.7',
  'english': 'Acquired absence of genital organ(s)',
  'vietnamese': 'Mất (nhiều) cơ quan sinh dục mắc phải',
}, {
  'id': 'Z90.8',
  'english': 'Acquired absence of other organs',
  'vietnamese': 'Mất các cơ quan khác mắc phải',
}, {
  'id': 'Z91',
  'english': 'Personal history of risk-factors, not elsewhere classified',
  'vietnamese': 'Bệnh sử cá nhân có yếu tố nguy cơ, chưa phân loại ở phần khác',
}, {
  'id': 'Z91.0',
  'english': 'Personal history of allergy, other than to drugs and biological substances',
  'vietnamese': 'Bệnh sử cá nhân có dị ứng, không kể dị ứng thuốc và chất sinh vật',
}, {
  'id': 'Z91.1',
  'english': 'Personal history of noncompliance with medical treatment and regimen',
  'vietnamese': 'Bệnh sử cá nhân có chế độ điều trị và chế độ ăn không thuận',
}, {
  'id': 'Z91.2',
  'english': 'Personal history of poor personal hygiene',
  'vietnamese': 'Bệnh sử cá nhân có chế độ vệ sinh thân thể kém',
}, {
  'id': 'Z91.3',
  'english': 'Personal history of unhealthy sleep-wake schedule',
  'vietnamese': 'Bệnh sử cá nhân có giờ giấc ngủ thao thức không ngon',
}, {
  'id': 'Z91.4',
  'english': 'Personal history of psychological trauma, not elsewhere classified',
  'vietnamese': 'Bệnh sử cá nhân có sang chấn tâm lí, chưa phân loại ở phần khác',
}, {
  'id': 'Z91.5',
  'english': 'Personal history of self-harm',
  'vietnamese': 'Bệnh sử cá nhân về tự hại',
}, {
  'id': 'Z91.6',
  'english': 'Personal history of other physical trauma',
  'vietnamese': 'Bệnh sử cá nhân có sang chấn thực thể khác',
}, {
  'id': 'Z91.8',
  'english': 'Personal history of other specified risk-factors, not elsewhere classified',
  'vietnamese': 'Bệnh sử cá nhân có các yếu tố nguy cơ khác, chưa phân loại ở phần khác',
}, {
  'id': 'Z92',
  'english': 'Personal history of medical treatment',
  'vietnamese': 'Bệnh sử cá nhân liên quan đến điều trị',
}, {
  'id': 'Z92.0',
  'english': 'Personal history of contraception',
  'vietnamese': 'Bệnh sử cá nhân về tránh thai',
}, {
  'id': 'Z92.1',
  'english': 'Personal history of long-term (current) use of anticoagulants',
  'vietnamese': 'Bệnh sử cá nhân (hiệu đang) sử dụng dài ngày các chất chống đông',
}, {
  'id': 'Z92.2',
  'english': 'Personal history of long-term (current) use of other medicaments',
  'vietnamese': 'Bệnh sử cá nhân (hiệu đang) sử dụng dài ngày các chất khác',
}, {
  'id': 'Z92.3',
  'english': 'Personal history of irradiation',
  'vietnamese': 'Bệnh sử cá nhân có điều trị tia xạ',
}, {
  'id': 'Z92.4',
  'english': 'Personal history of major surgery, not elsewhere classified',
  'vietnamese': 'Bệnh sử cá nhân có đại phẫu thuật, chưa phân loại ở phần khác',
}, {
  'id': 'Z92.5',
  'english': 'Personal history of rehabilitation measures',
  'vietnamese': 'Bệnh sử cá nhân có điều trị bằng các phương pháp phục hồi chức năng',
}, {
  'id': 'Z92.6',
  'english': 'Personal history of chemotherapy for neoplastic disease',
  'vietnamese': 'Bệnh sử cá nhân về hóa trị liệu bệnh u bướu',
}, {
  'id': 'Z92.8',
  'english': 'Personal history of other medical treatment',
  'vietnamese': 'Bệnh sử cá nhân có các điều trị khác',
}, {
  'id': 'Z92.9',
  'english': 'Personal history of medical treatment, unspecified',
  'vietnamese': 'Bệnh sử cá nhân có các điều trị, không đặc hiệu',
}, {
  'id': 'Z93',
  'english': 'Artificial opening status',
  'vietnamese': 'Tình trạng lỗ mở nhân tạo',
}, {
  'id': 'Z93.0',
  'english': 'Tracheostomy status',
  'vietnamese': 'Tình trạng lỗ mở khí quản',
}, {'id': 'Z93.1', 'english': 'Gastrostomy status', 'vietnamese': 'Tình trạng lỗ mở dạ dày'}, {
  'id': 'Z93.2',
  'english': 'Ileostomy status',
  'vietnamese': 'Tình trạng lỗ mở hồi tràng',
}, {'id': 'Z93.3', 'english': 'Colostomy status', 'vietnamese': 'Tình trạng lỗ mở đại tràng'}, {
  'id': 'Z93.4',
  'english': 'Other artificial openings of gastrointestinal tract status',
  'vietnamese': 'Tình trạng lỗ mở nhân tạo khác của dạ dày - ruột',
}, {'id': 'Z93.5', 'english': 'Cystostomy status', 'vietnamese': 'Tình trạng lỗ mở bàng quang'}, {
  'id': 'Z93.6',
  'english': 'Other artificial openings of urinary tract status',
  'vietnamese': 'Tình trạng lỗ mở khác của đường tiết niệu',
}, {
  'id': 'Z93.8',
  'english': 'Other artificial opening status',
  'vietnamese': 'Tình trạng lỗ mở nhân tạo khác',
}, {
  'id': 'Z93.9',
  'english': 'Artificial opening status, unspecified',
  'vietnamese': 'Tình trạng lỗ mở nhân tạo, không đặc hiệu',
}, {
  'id': 'Z94',
  'english': 'Transplanted organ and tissue status',
  'vietnamese': 'Tình trạng của mảnh ghép cơ quan và tổ chức',
}, {'id': 'Z94.0', 'english': 'Kidney transplant status', 'vietnamese': 'Tình trạng ghép thận'}, {
  'id': 'Z94.1',
  'english': 'Heart transplant status',
  'vietnamese': 'Tình trạng ghép tim',
}, {'id': 'Z94.2', 'english': 'Lung transplant status', 'vietnamese': 'Tình trạng ghép phổi'}, {
  'id': 'Z94.3',
  'english': 'Heart and lungs transplant status',
  'vietnamese': 'Tình trạng ghép tim và phổi',
}, {'id': 'Z94.4', 'english': 'Liver transplant status', 'vietnamese': 'Tình trạng ghép gan'}, {
  'id': 'Z94.5',
  'english': 'Skin transplant status',
  'vietnamese': 'Tình trạng ghép da',
}, {'id': 'Z94.6', 'english': 'Bone transplant status', 'vietnamese': 'Tình trạng ghép xương'}, {
  'id': 'Z94.7',
  'english': 'Corneal transplant status',
  'vietnamese': 'Tình trạng ghép giác mạc',
}, {
  'id': 'Z94.8',
  'english': 'Other transplanted organ and tissue status',
  'vietnamese': 'Tình trạng cơ quan và tổ chức khác được cấy ghép',
}, {
  'id': 'Z94.9',
  'english': 'Transplanted organ and tissue status, unspecified',
  'vietnamese': 'Tình trạng cơ quan và tổ chức được cấy ghép, không đặc hiệu',
}, {
  'id': 'Z95',
  'english': 'Presence of cardiac and vascular implants and grafts',
  'vietnamese': 'Sự có mặt của các thiết bị cấy ghép tim và mạch máu',
}, {
  'id': 'Z95.0',
  'english': 'Presence of cardiac pacemaker',
  'vietnamese': 'Tồn tại của thiết bị điện tại tim',
}, {
  'id': 'Z95.1',
  'english': 'Presence of aortocoronary bypass graft',
  'vietnamese': 'Sự có mặt của mảnh ghép nối thông động mạch chủ - vành (bypass)',
}, {
  'id': 'Z95.2',
  'english': 'Presence of prosthetic heart valve',
  'vietnamese': 'Sự có mặt của van tim chỉnh hình',
}, {
  'id': 'Z95.3',
  'english': 'Presence of xenogenic heart valve',
  'vietnamese': 'Sự có mặt của van tim ngoại lai',
}, {
  'id': 'Z95.4',
  'english': 'Presence of other heart-valve replacement',
  'vietnamese': 'Sự có mặt của van tim thay thế khác',
}, {
  'id': 'Z95.5',
  'english': 'Presence of coronary angioplasty implant and graft',
  'vietnamese': 'Sự có mặt của dụng cụ cấy ghép tạo hình động mạch vành',
}, {
  'id': 'Z95.8',
  'english': 'Presence of other cardiac and vascular implants and grafts',
  'vietnamese': 'Sự có mặt của dụng cụ cấy và mảnh ghép tim và mạch máu khác',
}, {
  'id': 'Z95.9',
  'english': 'Presence of cardiac and vascular implant and graft, unspecified',
  'vietnamese': 'Sự có mặt của dụng cụ cấy và mảnh ghép tim và mạch máu, không đặc hiệu',
}, {
  'id': 'Z96',
  'english': 'Presence of other functional implants',
  'vietnamese': 'Tồn tại của dụng cụ cấy ghép chức năng khác',
}, {
  'id': 'Z96.0',
  'english': 'Presence of urogenital implants',
  'vietnamese': 'Sự có mặt các dụng cụ cấy ghép tiết niệu sinh dục',
}, {
  'id': 'Z96.1',
  'english': 'Presence of intraocular lens',
  'vietnamese': 'Sự có mặt của thấu kính nội nhãn',
}, {
  'id': 'Z96.2',
  'english': 'Presence of otological and audiological implants',
  'vietnamese': 'Sự có mặt của dụng cụ cấy ghép tai và thính giác',
}, {
  'id': 'Z96.3',
  'english': 'Presence of artificial larynx',
  'vietnamese': 'Sự có mặt của họng nhân tạo',
}, {
  'id': 'Z96.4',
  'english': 'Presence of endocrine implants',
  'vietnamese': 'Sự có mặt của dụng cụ cấy ghép nội tiết',
}, {
  'id': 'Z96.5',
  'english': 'Presence of tooth-root and mandibular implants',
  'vietnamese': 'Sự có mặt của dụng cụ cấy chân răng và hàm má',
}, {
  'id': 'Z96.6',
  'english': 'Presence of orthopaedic joint implants',
  'vietnamese': 'Sự có mặt của dụng cụ chỉnh hình khớp',
}, {
  'id': 'Z96.7',
  'english': 'Presence of other bone and tendon implants',
  'vietnamese': 'Sự có mặt dụng cụ cấy ghép xương và gân',
}, {
  'id': 'Z96.8',
  'english': 'Presence of other specified functional implants',
  'vietnamese': 'Sự có mặt dụng cụ cấy ghép chức năng xác định khác',
}, {
  'id': 'Z96.9',
  'english': 'Presence of functional implant, unspecified',
  'vietnamese': 'Sự có mặt dụng cụ cấy ghép chức năng, không đặc hiệu',
}, {
  'id': 'Z97',
  'english': 'Presence of other devices',
  'vietnamese': 'Sự có mặt của các dụng cụ cấy ghép khác',
}, {'id': 'Z97.0', 'english': 'Presence of artificial eye', 'vietnamese': 'Sự có mặt mắt giả'}, {
  'id': 'Z97.1',
  'english': 'Presence of artificial limb (complete)(partial)',
  'vietnamese': 'Sự có mặt của chi giả (toàn bộ) (một phần)',
}, {
  'id': 'Z97.2',
  'english': 'Presence of dental prosthetic device (complete)(partial)',
  'vietnamese': 'Sự có mặt của dụng cụ chỉnh hình răng (toàn bộ) (một phần)',
}, {
  'id': 'Z97.3',
  'english': 'Presence of spectacles and contact lenses',
  'vietnamese': 'Sự có mặt của mắt kính và thấu kính tiếp xúc',
}, {
  'id': 'Z97.4',
  'english': 'Presence of external hearing-aid',
  'vietnamese': 'Sự có mặt của dụng cụ trợ thính bên ngoài',
}, {
  'id': 'Z97.5',
  'english': 'Presence of (intrauterine) contraceptive device',
  'vietnamese': 'Sự có mặt của dụng cụ tránh thai (trong tử cung)',
}, {
  'id': 'Z97.8',
  'english': 'Presence of other specified devices',
  'vietnamese': 'Sự có mặt của các dụng cụ xác định khác',
}, {
  'id': 'Z98',
  'english': 'Other postsurgical states',
  'vietnamese': 'Các tình trạng hậu phẫu thuật',
}, {
  'id': 'Z98.0',
  'english': 'Intestinal bypass and anastomosis status',
  'vietnamese': 'Tình trạng nối tắt ruột và nối ruột',
}, {'id': 'Z98.1', 'english': 'Arthrodesis status', 'vietnamese': 'Tình trạng đóng cứng khớp'}, {
  'id': 'Z98.2',
  'english': 'Presence of cerebrospinal fluid drainage device',
  'vietnamese': 'Sự có mặt dụng cụ dẫn lưu dịch não tủy',
}, {
  'id': 'Z98.8',
  'english': 'Other specified postsurgical states',
  'vietnamese': 'Các tình trạng hậu phẫu xác định khác',
}, {
  'id': 'Z99',
  'english': 'Dependence on enabling machines and devices, not elsewhere classified',
  'vietnamese': 'Tình trạng phụ thuộc vào các thiết bị và dụng cụ, chưa được phân loại ở phần khác',
}, {'id': 'Z99.0', 'english': 'Dependence on aspirator', 'vietnamese': 'Phụ thuộc máy hút'}, {
  'id': 'Z99.1',
  'english': 'Dependence on respirator',
  'vietnamese': 'Phụ thuộc máy thở',
}, {
  'id': 'Z99.2',
  'english': 'Dependence on renal dialysis',
  'vietnamese': 'Phụ thuộc chạy thận nhân tạo',
}, {'id': 'Z99.3', 'english': 'Dependence on wheelchair', 'vietnamese': 'Phụ thuộc vào xe lăn'}, {
  'id': 'Z99.4',
  'english': 'Phụ thuộc vào tim nhân tạo',
  'vietnamese': 'Lệ thuộc vào tim nhân tạo',
}, {
  'id': 'Z99.8',
  'english': 'Dependence on other enabling machines and devices',
  'vietnamese': 'Phụ thuộc vào các thiết bị và dụng cụ khác',
}, {
  'id': 'Z99.9',
  'english': 'Dependence on unspecified enabling machine and device',
  'vietnamese': 'Phụ thuộc vào thiết bị và dụng cụ không xác định',
}];
