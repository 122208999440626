import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
  },
}));

export const LoadingContainer = props => {
  const {isLoading, isError, children, ...otherProps} = props;
  const classes = useStyles();

  return (
    <Container component="main" {...otherProps}>
      <div className={classes.centered}>
        {isError && 'Xảy ra lỗi khi tải dữ liệu. Xin kiểm tra lại đường dẫn.'}
        {!isError && isLoading && (
          <CircularProgress/>
        )}
      </div>
      {!isError && !isLoading && children}
    </Container>
  );
};

LoadingContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};
