import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PrescriptionToPrint } from './PrescriptionToPrint';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  prescription: {
    padding: theme.spacing(3, 2),
  },
}));

export const PrescriptionPaper = props => {
  const classes = useStyles();
  const {template, patient, visit, isInSettings} = props;

  return (
    <Paper className={classes.prescription} elevation={3} variant={isInSettings ? 'outlined' : null}>
      <PrescriptionToPrint patient={patient}
                           visit={visit}
                           template={template}/>
    </Paper>
  );
};

PrescriptionPaper.propTypes = {
  patient: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  template: PropTypes.object,
};
