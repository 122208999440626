import { useState } from 'react'
import firebase from 'firebase'

export const useUserId = (username) => {
  const [userId, setUserId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getUserId = async () => {
    setIsLoading(true)
    try {
      const doc = await firebase.firestore().collection('usernames').doc(username).get()
      const data = doc.data()
      if (doc.exists && data.userId && !!data.userId.trim()) {
        setUserId(data.userId)
        setIsLoading(false)
        setIsError(false)
      } else {
        setIsLoading(false)
        setIsError(true)
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setIsError(true)
    }
  }

  if (username && !isLoading && !isError && !userId) {
    getUserId().then()
  }

  return [userId, isLoading, isError]
}
