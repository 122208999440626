import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ColumnChart from '../components/ColumnChart';
import { AppContext } from '../App';
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const DashboardPage = props => {
  const classes = useStyles();
  const {dashboard, authUser = {}} = useContext(AppContext);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const visitCount = dashboard && dashboard['visit_count'] ? dashboard['visit_count'] : {};
    const data = [];

    for (let daysAway = 29; daysAway >= 0; daysAway--) {
      const dateMoment = moment().subtract(daysAway, 'days');
      const date = dateMoment.format('YYYY-MM-DD');
      data.push({
        name: dateMoment.format('ddd DD/MM'),
        value: visitCount[date] ? visitCount[date] : 0,
      });
    }

    setGraphData(data);
  }, [dashboard]);

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('dashboard_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              Thống kê lượt khám
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ColumnChart data={graphData} unit={'Lượt khám'}/>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
