import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './TranslationEN.json';
import translationVI from './TranslationVI.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'vi',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
