import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React from 'react'
import { Gender } from '../constants/GenderEnum'
import { reformatName } from '../utils/patient'
import Input from '@material-ui/core/Input'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

export const PatientEdit = (props) => {
  const {
    draftPatient,
    errorProps,
    showNoteField = true,
    showAddressField = true,
    showNameFields = true
  } = props

  const onDraftPatientChange = (updatedField) => {
    props.onChange({...draftPatient, ...updatedField})
  }

  return (
    <Grid container spacing={3}>
      {showNameFields && (
        <>
          <Grid item xs={12} sm={4}>
            <TextField label="Họ" fullWidth required
                       value={draftPatient.lastName}
                       placeholder={'Nguyễn'}
                       onChange={e => onDraftPatientChange({
                         lastName: e.target.value,
                       })}
                       onBlur={e => onDraftPatientChange({
                         lastName: reformatName(e.target.value),
                       })}
                       {...errorProps.lastName}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Tên đệm" fullWidth
                       value={draftPatient.middleName}
                       placeholder={'Thị Thu'}
                       onChange={e => onDraftPatientChange({
                         middleName: e.target.value,
                       })}
                       onBlur={e => onDraftPatientChange({
                         middleName: reformatName(e.target.value),
                       })}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Tên" fullWidth required
                       value={draftPatient.firstName}
                       placeholder={'Hà'}
                       onChange={e => onDraftPatientChange({
                         firstName: e.target.value,
                       })}
                       onBlur={e => onDraftPatientChange({
                         firstName: reformatName(e.target.value),
                       })}
                       {...errorProps.firstName}/>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={4}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            openTo={'year'} views={['year', 'month', 'date']}
            autoOk variant={'inline'} format={'DD/MM/YYYY'}
            label={'Ngày sinh'} fullWidth disableFuture
            value={moment(draftPatient.dateOfBirth)}
            onChange={dateMoment =>
              dateMoment && dateMoment.isValid() && onDraftPatientChange({
                dateOfBirth: dateMoment.format('YYYY-MM-DD'),
              })}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label="Số điện thoại" fullWidth type="tel" inputProps={{pattern: '[0-9]*'}}
                   required
                   value={draftPatient.phoneNumber}
                   placeholder={'0983112257'}
                   onChange={e => e.target.validity.valid && onDraftPatientChange({
                     phoneNumber: e.target.value,
                   })}
                   {...errorProps.phoneNumber}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel htmlFor="gender-helper">Giới tính</InputLabel>
          <Select input={<Input name="gender" id="gender-helper"/>}
                  value={draftPatient.gender}
                  onChange={e => onDraftPatientChange({
                    gender: e.target.value,
                  })}>
            <MenuItem value={Gender.UNKNOWN}>Không rõ</MenuItem>
            <MenuItem value={Gender.MALE}>Nam</MenuItem>
            <MenuItem value={Gender.FEMALE}>Nữ</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {showAddressField && (
        <Grid item xs={12}>
          <TextField label="Địa chỉ" fullWidth
                     value={draftPatient.address}
                     placeholder={'171 Lê Đình Lý, Hải Châu, Đà Nẵng'}
                     onChange={e => onDraftPatientChange({
                       address: e.target.value,
                     })}/>
        </Grid>
      )}
      {showNoteField && (
        <Grid item xs={12}>
          <TextField label="Ghi chú" fullWidth multiline
                     value={draftPatient.note}
                     onChange={e => onDraftPatientChange({
                       note: e.target.value,
                     })}/>
        </Grid>
      )}
    </Grid>
  )
}

PatientEdit.propTypes = {
  draftPatient: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errorProps: PropTypes.object.isRequired,
  showNoteField: PropTypes.bool,
  showAddressField: PropTypes.bool,
  showNameFields: PropTypes.bool,
}
