import { validateNotEmpty } from '../../utils/validation';

export const validateStep = (activeStep, data) => {
  if (activeStep === 0) return validateStep0(data);
  if (activeStep === 1) return validateStep1(data);
  return [true, {}];
};

const validateStep0 = data => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = re.test(data.email.toLowerCase());
  const isValidPassword = !!data.password && data.password.length >= 6;
  const isValidConfirmPassword = data.confirmPassword === data.password;
  return [isValidEmail && isValidPassword && isValidConfirmPassword, {
    email: {
      error: !isValidEmail,
      helperText: isValidEmail ? undefined : 'Email không hợp lệ',
    },
    password: {
      error: !isValidPassword,
      helperText: isValidPassword ? undefined : (!data.password ? 'Nhập mật khẩu' : 'Mật khẩu ít nhất 6 ký tự'),
    },
    confirmPassword: {
      error: !isValidConfirmPassword,
      helperText: isValidConfirmPassword ? undefined : 'Nhập sai mật khẩu',
    },
  }];
};

const validateStep1 = data => {
  let isAllValid = true;
  const errorProps = {};
  const fields = ['clinicName', 'clinicAddress', 'clinicContact', 'signatureName'];
  fields.forEach(key => {
    const [isValid, error] = validateNotEmpty(data[key]);
    isAllValid = (isAllValid && isValid);
    errorProps[key] = error;
  });
  return [isAllValid, errorProps];
};
