import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from '../App';

export const PrivateRoute = props => {
  const {authUser} = useContext(AppContext);
  const {component: Component, ...rest} = props;

  return (
    <Route
      {...rest}
      render={props =>
        authUser ?
          (<Component {...props} />) :
          (<Redirect to={{pathname: '/signin', state: {from: props.location}}}/>)
      }
    />
  );
};
