import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import ScheduleIcon from '@material-ui/icons/Schedule'
import HomeIcon from '@material-ui/icons/Home'
import { Pill, Stethoscope, Thermometer } from 'mdi-material-ui'
import MenuIcon from '@material-ui/icons/Menu'
import firebase from 'firebase'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { Link, Redirect, Route } from 'react-router-dom'
import { AddVisitPage } from './AddVisitPage/AddVisitPage'
import { DashboardPage } from './DashboardPage'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { Badge, makeStyles, useTheme } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { EmojiPeople, ExpandLess, ExpandMore, History } from '@material-ui/icons'
import { SymptomPage } from './SymptomPage'
import { DrugPage } from './DrugPage'
import { AppContext } from '../App'
import { RecentPage } from './RecentPage'
import IdleTimer from 'react-idle-timer'
import { OpeningHoursPage } from './OpeningHoursPage'
import { ProfileSettingsPage } from './ProfileSettingsPage'
import { QueuePage } from './QueuePage'
import { format } from 'date-fns'
import { useFetchResults } from '../hooks/useFetchResults'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export const RootDrawer = props => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [expandSettings, setExpandSettings] = useState(true)
  const {getFirestoreRef, settings} = useContext(AppContext)
  const clinicName =
    settings && settings.prescription && settings.prescription.clinicName
      ? settings.prescription.clinicName : 'Clinic'
  const now = new Date()
  const {current: fetchQuery} =
    useRef(getFirestoreRef('queue').where('visitDate', '==', format(now, 'yyyy-MM-dd')))
  const [queueResults, , , refresh] = useFetchResults(fetchQuery)
  const queueLength = queueResults.filter(booking => !booking.isDone).length

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .catch(error => console.error(error))
  }

  const setActive = async () => {
    await getFirestoreRef('settings').doc('onlineStatus').set({lastActive: new Date()})
  }

  const navigateToQueuePage = useCallback(
    () => {
      props.history.push('/queue')
      refresh()
    }, [props.history, refresh])

  return (
    <div className={classes.root}>
      <IdleTimer
        onAction={setActive}
        throttle={1000 * 60}/>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer"
                      onClick={handleDrawerOpen} edge="start"
                      className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap>
            <Link to="/" className={classes.title}>{clinicName}</Link>
          </Typography>
          <div className={classes.grow}/>
          <IconButton onClick={navigateToQueuePage} color="inherit">
            <Badge color="secondary" badgeContent={queueLength}>
              <EmojiPeople/>
            </Badge>
          </IconButton>
          <IconButton onClick={signOut} color="inherit">
            <ExitToAppIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <List>
          <ListItem button onClick={() => props.history.push('/add')}>
            <ListItemIcon><Stethoscope/></ListItemIcon>
            <ListItemText primary="Khám bệnh"/>
          </ListItem>
          <ListItem button onClick={navigateToQueuePage}>
            <ListItemIcon><EmojiPeople/></ListItemIcon>
            <ListItemText primary="Hàng đợi"/>
          </ListItem>
          <ListItem button onClick={() => props.history.push('/recent')}>
            <ListItemIcon><History/></ListItemIcon>
            <ListItemText primary="Gần đây"/>
          </ListItem>
          <ListItem button onClick={() => props.history.push('/dashboard')}>
            <ListItemIcon><DashboardIcon/></ListItemIcon>
            <ListItemText primary="Tổng quan"/>
          </ListItem>
          <ListItem button onClick={() => props.history.push('/drugs')}>
            <ListItemIcon><Pill/></ListItemIcon>
            <ListItemText primary="Danh mục thuốc"/>
          </ListItem>
          <ListItem button onClick={() => props.history.push('/symptoms')}>
            <ListItemIcon><Thermometer/></ListItemIcon>
            <ListItemText primary="Triệu chứng"/>
          </ListItem>
          <ListItem button onClick={() => setExpandSettings(!expandSettings)}>
            <ListItemIcon>
              <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Tuỳ chỉnh"/>
            {expandSettings ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={expandSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}
                        onClick={() => props.history.push('/settings/profile')}>
                <ListItemIcon>
                  <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary="Phòng khám"/>
              </ListItem>
              <ListItem button className={classes.nested}
                        onClick={() => props.history.push('/settings/opening-hours')}>
                <ListItemIcon>
                  <ScheduleIcon/>
                </ListItemIcon>
                <ListItemText primary="Giờ khám bệnh"/>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader}/>
        <Container maxWidth="md">
          <Route exact path="/" render={() => <Redirect to="/add"/>}/>
          <Route path="/add/" component={AddVisitPage}/>
          <Route path="/queue/" component={QueuePage}/>
          <Route path="/recent/" component={RecentPage}/>
          <Route path="/dashboard/" component={DashboardPage}/>
          <Route path="/symptoms/" component={SymptomPage}/>
          <Route path="/drugs/" component={DrugPage}/>
          <Route path="/settings/profile/" component={ProfileSettingsPage}/>
          <Route path="/settings/opening-hours/" component={OpeningHoursPage}/>
        </Container>
      </main>
    </div>
  )
}
