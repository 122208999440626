import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { TagInputComponents } from './components';
import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const TagInput = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {label, isMulti, value, options, handleInputChange} = props;

  const handleChange = options => {
    props.handleChange(options ? options : (isMulti ? [] : null));
  };

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes} styles={selectStyles} inputId={`react-select-multiple-${label}`}
          components={TagInputComponents}
          TextFieldProps={{
            label: label,
            InputLabelProps: {htmlFor: `react-select-multiple-${label}`, shrink: true},
          }}
          options={options} value={value} onChange={handleChange}
          placeholder="Chọn..." noOptionsMessage={() => 'Không tìm thấy kết quả'}
          isMulti={isMulti} isClearable onInputChange={handleInputChange} filterOption={null}
        />
      </NoSsr>
    </div>
  );

};

TagInput.propTypes = {
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  options: PropTypes.array.isRequired,
};
