export const getPatientRecordInVisit = firestorePatient => ({
  id: firestorePatient.id,
  lastName: firestorePatient.lastName,
  middleName: firestorePatient.middleName,
  firstName: firestorePatient.firstName,
  dateOfBirth: firestorePatient.dateOfBirth,
  gender: firestorePatient.gender,
  phoneNumber: firestorePatient.phoneNumber,
  address: firestorePatient.address,
  note: firestorePatient.note,
});
