import React, { useContext, useEffect } from 'react'
import { AsyncTable } from '../components/AsyncTable'
import { getNormalisedText } from '../utils/string'
import firebase from 'firebase'
import { AppContext } from '../App'

export const DrugPage = props => {
  const {getFirestoreRef, authUser = {}} = useContext(AppContext)

  const removeMessage = drugName => {
    return 'Xoá thuốc "' + drugName + '" ?'
  }

  const prepareFirestoreDrug = (drug, newDrugName) => {
    const drugRef = getFirestoreRef('drugs').doc(drug.id)
    const updatedDrug = {
      id: drug.id,
      name: newDrugName,
      nameSearch: getNormalisedText(newDrugName),
      defaultUsage: drug.defaultUsage,
      createdAt: drug.createdAt,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    return [drugRef, updatedDrug]
  }

  const editFirestoreDrug = async (drug, newDrugName) => {
    const [drugRef, firestoreDrug] = prepareFirestoreDrug(drug, newDrugName)
    await drugRef.set(firestoreDrug)
    return firestoreDrug
  }

  const deleteFirestoreDrug = async drugId => {
    await getFirestoreRef('drugs').doc(drugId).delete()
  }

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('drug_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return <AsyncTable collectionFirestoreRef={getFirestoreRef('drugs')}
                     onEdit={editFirestoreDrug}
                     onDelete={deleteFirestoreDrug}
                     removeMessage={removeMessage}
                     title={'Thuốc'}/>
}
