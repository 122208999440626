import { useEffect, useState } from 'react'
import firebase from 'firebase'
import { addDays, format, isAfter, isSaturday, isSunday, isWithinInterval, parseISO } from 'date-fns'

export const useOpeningHours = (userId, nextDays) => {
  const [results, setResults] = useState({})
  const [calendar, setCalendar] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openingHoursRef, setOpeningHoursRef] = useState(null)

  const saveCalendar = async calendar => {
    setCalendar(calendar)
    const record = {}
    calendar.forEach((hours, index) => record[index] = hours)
    await openingHoursRef.set(record)
  }

  useEffect(() => {
    if (userId && !openingHoursRef) {
      setOpeningHoursRef(
        firebase
          .firestore()
          .collection('users')
          .doc(userId)
          .collection('settings')
          .doc('openingHours'))
    }
  }, [openingHoursRef, userId])

  useEffect(() => {
    const parseTimeRange = (openingRangeByDate, keyDate, rangeDate, startTime, endTime) => {
      const key = format(keyDate, 'yyyy-MM-dd')
      const rangeDateStr = format(rangeDate, 'yyyy-MM-dd')

      if (key === rangeDateStr) {
        const start = parseISO(rangeDateStr + 'T' + format(startTime, 'HH:mm') + ':00')
        let end = parseISO(rangeDateStr + 'T' + format(endTime, 'HH:mm') + ':00')
        if (isAfter(start, end)) {
          end = addDays(end, 1)
        }

        openingRangeByDate[key].push([start, end])
      }
    }

    const calculateOpeningHoursByDate = () => {
      const now = new Date()
      const openingRangeByDate = {}

      for (let i = 0; i < nextDays; i++) {
        const currentDate = addDays(now, i)
        const key = format(currentDate, 'yyyy-MM-dd')
        const weekday =
          isSunday(currentDate) ? 'sunday' : (isSaturday(currentDate) ? 'saturday' : 'weekdays')
        openingRangeByDate[key] = []

        calendar.filter(h => h.type === weekday).forEach(hours => {
          parseTimeRange(openingRangeByDate, currentDate, currentDate, hours.start, hours.end)
        })

        calendar.filter(h => ['on', 'off'].includes(h.type)).forEach(hours => {
          const date = format(hours.date, 'yyyy-MM-dd')
          const nextDate = format(addDays(hours.date, 1), 'yyyy-MM-dd')
          const start = parseISO(date + 'T00:00:00')
          const end = parseISO(nextDate + 'T00:00:00')
          if (isWithinInterval(currentDate, {start, end})) {
            openingRangeByDate[key] = []
          }
        })

        calendar.filter(h => h.type === 'on').forEach(hours => {
          parseTimeRange(openingRangeByDate, currentDate, hours.date, hours.start, hours.end)
        })
      }
      return openingRangeByDate
    }

    if (calendar && calendar.length > 0) {
      setResults(calculateOpeningHoursByDate())
    }
  }, [calendar, nextDays])

  useEffect(() => {
    const fetchCalendar = async () => {
      const doc = await openingHoursRef.get()
      const data = doc.data() || {}
      let calendar = Object.values(data);
      ['date', 'start', 'end'].forEach(key => {
        calendar = calendar.map(hours =>
          hours[key] ? ({
            ...hours,
            [key]: hours[key].toDate(),
          }) : hours)
      })
      calendar.sort((l, r) => {
        const typeOrder = ['weekdays', 'saturday', 'sunday', 'on', 'off']
        return typeOrder.indexOf(l.type) - typeOrder.indexOf(r.type)
      })
      setCalendar(calendar)
      setIsLoading(false)
    }

    if (openingHoursRef) {
      fetchCalendar().then()
    }
  }, [openingHoursRef])

  return userId ? [results, isLoading, calendar, saveCalendar] : [[], false, null]
}
