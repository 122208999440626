import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../components/LanguageSelector';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © KiTrau '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  errorMessage: {
    color: 'red',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

export const SignInPage = props => {
  const classes = useStyles();
  const {authUser} = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRemembered, setIsRemembered] = useState(true);
  const {from} = props.location.state || {from: {pathname: '/'}};
  const {t} = useTranslation();

  const handleLogIn = (event) => {
    firebase
      .auth()
      .setPersistence(
        isRemembered ?
          firebase.auth.Auth.Persistence.LOCAL :
          firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
      .catch(error => {
        // Handle Errors here.
        switch (error.code) {
          case 'auth/wrong-password':
            setErrorMessage('Sai mật khẩu.');
            break;
          case 'auth/user-not-found':
            setErrorMessage('Tài khoản không tồn tại.');
            break;
          default:
            setErrorMessage(error.message);
        }
      });
  };

  if (authUser) return <Redirect to={from}/>;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('login')}
        </Typography>
        <form className={classes.form} noValidate>
          <Typography className={classes.errorMessage}>{errorMessage}</Typography>
          <TextField variant="outlined" margin="normal" required fullWidth id="email"
                     label={t('email')}
                     name="email" autoComplete="email" autoFocus
                     onChange={e => setEmail(e.target.value)} value={email}/>
          <TextField variant="outlined" margin="normal" required fullWidth name="password"
                     label={t('password')} type="password" id="password"
                     autoComplete="current-password"
                     onChange={e => setPassword(e.target.value)} value={password}/>
          <FormControlLabel
            control={<Checkbox checked={isRemembered} color="primary"/>}
            label={t('rememberLogin')}
            onChange={() => setIsRemembered(!isRemembered)}
          />
          <Button onClick={handleLogIn} fullWidth
                  variant="contained" color="primary" className={classes.submit}>
            {t('login')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot_password" className={classes.link}>{t('forgotPassword')}</Link>
            </Grid>
            <Grid item>
              <Link to="/signup" className={classes.link}>{t('signup')}</Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <LanguageSelector/>
        <Copyright/>
      </Box>
    </Container>
  );
};
