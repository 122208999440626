import React, { useContext, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { AppContext } from '../App'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility } from '@material-ui/icons'
import { PrescriptionPaper } from '../components/PrescriptionPaper'
import { prescriptionMockPatient, prescriptionMockVisit } from '../constants/PrescriptionMockData'
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  textField: {
    width: '100%',
  },
}))

export const ProfileSettingsPage = props => {
  const {authUser = {}, settings, getFirestoreRef} = useContext(AppContext)
  const [updatedFields, setUpdatedFields] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isDuplicatedUsername, setIsDuplicatedUsername] = useState(false)
  const draftSettings = {...(settings?.prescription || {}), ...(updatedFields || {})}
  const classes = useStyles()

  const onFieldUpdated = (fieldKey, value) => {
    setUpdatedFields(updatedFields => ({...(updatedFields || {}), [fieldKey]: value}))
    setIsDuplicatedUsername(false)
    setIsSaved(false)
  }

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('profile_settings_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  useEffect(() => {
    const updateSettings = async () => {
      const batchWrite = firebase.firestore().batch()
      const prescriptionRef = getFirestoreRef('settings').doc('prescription')
      if (updatedFields?.username && settings?.prescription?.username) {
        const usernamesRef = firebase.firestore().collection('usernames')
        const oldUsernameSnapshots = await usernamesRef.where('userId', '==', authUser.uid).get()
        const newUsernameRef = usernamesRef.doc(updatedFields.username)
        oldUsernameSnapshots.forEach(doc => batchWrite.delete(doc.ref))
        batchWrite.set(newUsernameRef, {userId: authUser.uid})
      }
      batchWrite.set(prescriptionRef, updatedFields, {merge: true})
      try {
        await batchWrite.commit()
      } catch (e) {
        setIsDuplicatedUsername(true)
      }
      setIsLoading(false)
    }

    if (updatedFields && getFirestoreRef && !isLoading && !isSaved) {
      setIsLoading(true)
      setIsSaved(true)
      updateSettings().then()
    }
  }, [authUser, getFirestoreRef, isLoading, isSaved, settings, updatedFields])

  return (
    <Paper className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" color={'primary'} gutterBottom>
              Thông tin phòng khám
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Trang chủ"
                  className={classes.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">kitrau.com/</InputAdornment>,
                    endAdornment:
                      <IconButton target='_blank' href={'https://kitrau.com/' + draftSettings.username}>
                        <Visibility/>
                      </IconButton>,
                  }}
                  error={isDuplicatedUsername}
                  helperText={isDuplicatedUsername ? 'Đã có người sử dụng' : ''}
                  value={draftSettings.username || ''}
                  onChange={(e) => onFieldUpdated('username', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Tên phòng khám"
                  value={draftSettings.clinicName || ''}
                  onChange={(e) => onFieldUpdated('clinicName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Địa chỉ"
                  value={draftSettings.clinicAddress || ''}
                  onChange={(e) => onFieldUpdated('clinicAddress', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Liên hệ"
                  value={draftSettings.clinicContact || ''}
                  onChange={(e) => onFieldUpdated('clinicContact', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Tên bác sĩ"
                  value={draftSettings.signatureName || ''}
                  onChange={(e) => onFieldUpdated('signatureName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Chức danh"
                  value={draftSettings.signatureTitle || ''}
                  onChange={(e) => onFieldUpdated('signatureTitle', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  multiline
                  rows={4}
                  label="Thông báo"
                  value={draftSettings.announcement || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => onFieldUpdated('announcement', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <PrescriptionPaper
              isInSettings
              patient={prescriptionMockPatient}
              visit={prescriptionMockVisit}
              template={draftSettings}/>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  )
}

ProfileSettingsPage.propTypes = {}
