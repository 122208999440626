import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../App'
import { useFetchResults } from '../hooks/useFetchResults'
import { useAtBottomPage } from '../hooks/useAtBottomPage'
import { Card, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { addMinutes, format, formatDistance } from 'date-fns'
import vi from 'date-fns/locale/vi'
import Button from '@material-ui/core/Button'
import { AirlineSeatReclineExtra, Snooze, Undo, Visibility, VisibilityOff } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import firebase from 'firebase'
import { Gender } from '../constants/GenderEnum'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    padding: '0 1rem',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  spaceDivider: {
    padding: theme.spacing(2),
  },
  progressContainer: {
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    minWidth: '6rem',
    marginRight: '1rem'
  },
}))

export const QueuePage = props => {
  const now = new Date()
  const {getFirestoreRef, authUser = {}} = useContext(AppContext)
  const {current: fetchQuery} =
    useRef(
      getFirestoreRef('queue')
        .orderBy('visitDate', 'asc')
        .orderBy('number', 'asc')
        .where('visitDate', '>=', format(now, 'yyyy-MM-dd'))
        .limit(10))
  const [results, isLoadingMoreBooking, requestMoreResults, refresh, ids] = useFetchResults(fetchQuery)
  const [isUpdatingBooking, setIsUpdatingBooking] = useState(null)
  const [hideDone, setHideDone] = useState(true)
  const [isSnoozingBooking, setIsSnoozingBooking] = useState(null)
  const isBottom = useAtBottomPage()
  const classes = useStyles()

  const selectPatient = async (bookingPatient, index, isDone) => {
    await updateBookingDone(index, isDone)
    props.history.push('/add', {bookingPatient})
  }

  const updateBookingDone = async (index, isDone) => {
    setIsUpdatingBooking(index)
    await getFirestoreRef('queue')
      .doc(ids[index])
      .set({isDone}, {merge: true})
    setIsUpdatingBooking(null)
  }

  const snoozeBooking = async index => {
    setIsSnoozingBooking(index)
    const snoozeUntil = addMinutes(now, 10)
    await getFirestoreRef('queue')
      .doc(ids[index])
      .set({snoozeUntil}, {merge: true})
    setIsSnoozingBooking(null)
    refresh()
  }

  const toggleDoneVisibility = () => {
    setHideDone(hideDone => !hideDone)
  }

  useEffect(() => {
    if (isBottom) {
      requestMoreResults()
    }
  }, [isBottom, requestMoreResults])

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('queue_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return (
    <>
      {results.map((booking, index) => {
        const {draftPatient: patient, visitDate, number, createdAt, isDone = false, snoozeUntil} = booking
        const visitDateObject = new Date(visitDate)
        const dateOfBirthObject = new Date(patient.dateOfBirth)
        const showHeader = index === 0 || visitDate !== results[index - 1].visitDate
        const showBooking = !hideDone || (!isDone && (!snoozeUntil || snoozeUntil.toDate() < now))
        return (
          <React.Fragment key={`booking-${visitDate}-${number}`}>
            {showHeader && (
              <>
                {index > 0 && <div className={classes.spaceDivider}/>}
                <Grid container justify={'space-between'}>
                  <Grid item>
                    <Typography variant="h6" color={'primary'}>
                      {format(addMinutes(visitDateObject, visitDateObject.getTimezoneOffset()), 'EEEE, dd/MM/yyyy', {locale: vi})}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton aria-label="visibility" onClick={toggleDoneVisibility}>
                      {hideDone ? <VisibilityOff/> : <Visibility color="primary"/>}
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )}
            {showBooking && (
              <>
                <Card>
                  <CardContent className={classes.contentRoot}>
                    <div className={classes.spaceDivider}/>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h6" color={'primary'}>Mã số đăng ký</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant="h6" color={'primary'}>{number}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1">Ngày sinh</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant="subtitle1">
                          {format(
                            addMinutes(
                              dateOfBirthObject,
                              dateOfBirthObject.getTimezoneOffset()),
                            'dd/MM/yyyy')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1">Số điện thoại</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant="subtitle1">
                          {patient.phoneNumber}
                        </Typography>
                      </Grid>
                      {patient.gender !== Gender.UNKNOWN && (
                        <>
                          <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1">Giới tính</Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Typography variant="subtitle1">
                              {patient.gender === Gender.MALE ? 'Nam' : 'Nữ'}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1">Đăng ký lúc</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant="subtitle1">
                          {format(createdAt.toDate(), 'hh:mm B, \'ngày\' dd/MM/yyyy', {locale: vi})}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.spaceDivider}/>
                    <Grid container>
                      {!isDone && (
                        <>
                          <Button
                            variant={'outlined'}
                            color="primary"
                            size="small"
                            startIcon={
                              isUpdatingBooking === index
                                ? <CircularProgress size={18}/>
                                : <AirlineSeatReclineExtra/>
                            }
                            className={classes.button}
                            onClick={() => selectPatient(patient, index, true)}
                          >
                            Khám
                          </Button>
                          <Button
                            variant={'outlined'}
                            color="primary"
                            size="small"
                            startIcon={isSnoozingBooking === index ? <CircularProgress size={18}/> : <Snooze/>}
                            className={classes.button}
                            onClick={() => snoozeBooking(index)}
                          >
                            {snoozeUntil && snoozeUntil.toDate() > now
                              ? formatDistance(snoozeUntil.toDate(), now, {locale: vi})
                              : 'Chờ'}
                          </Button>
                        </>
                      )}
                      {isDone && (
                        <Button
                          variant={'outlined'}
                          color="primary"
                          size="small"
                          startIcon={isUpdatingBooking === index ? <CircularProgress size={18}/> : <Undo/>}
                          className={classes.button}
                          onClick={async () => {
                            await updateBookingDone(index, false)
                            setHideDone(true)
                            refresh()
                          }}
                        >
                          Chưa khám
                        </Button>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
                <div className={classes.spaceDivider}/>
              </>
            )}
          </React.Fragment>
        )
      })}
      <div className={classes.progressContainer}>
        {isLoadingMoreBooking && <CircularProgress/>}
        {!isLoadingMoreBooking && results.length === 0 && <Typography>Không có kết quả</Typography>}
      </div>
    </>
  )
}

QueuePage.propTypes = {}
