import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { getNormalisedText, trimAndCapitalised } from '../utils/string';
import { AsyncTagInput } from './TagInput/AsyncTagInput';
import { AppContext } from '../App';

export const SymptomInput = (props) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const {getFirestoreRef} = useContext(AppContext);

  const symptomToTag = symptom => {
    const isNewSymptom = !symptom.id;
    return {value: isNewSymptom ? symptom.name : symptom.id, label: symptom.name, symptom};
  };

  const symptomsToTags = symptoms => {
    return symptoms.map(symptomToTag);
  };

  const tagsToSymptoms = tags => {
    return tags.map(tag => {
      const isNewSymptom = tag.label === tag.value;
      return isNewSymptom ? ({
        id: isNewSymptom ? null : tag.value,
        name: trimAndCapitalised(tag.label),
      }) : tag.symptom;
    });
  };

  const updateSearchQuery = searchInput => {
    if (!searchInput) return setSearchQuery(null);
    const symptomNameSearch = getNormalisedText(searchInput);
    const searchQuery = getFirestoreRef('symptoms')
      .where('nameSearch', '>=', symptomNameSearch)
      .orderBy('nameSearch').limit(20);
    setSearchQuery(searchQuery);
  };

  const {symptoms, onChange} = props;

  return (
    <AsyncTagInput label='Triệu chứng' isMulti={true}
                   value={symptomsToTags(symptoms)}
                   handleChange={options => onChange(tagsToSymptoms(options))}
                   handleInputChange={updateSearchQuery}
                   query={searchQuery}
                   resultToTag={symptomToTag}/>
  );
};

SymptomInput.propTypes = {
  symptoms: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
