export const prescriptionMockPatient = {
  'address': '123 Nguyễn Văn Linh - Đà Nẵng',
  'dateOfBirth': '2019-01-01',
  'firstName': 'Anh',
  'gender': 'male',
  'lastName': 'Nguyễn',
  'middleName': 'Văn',
  'phoneNumber': '09876543210',
};

export const prescriptionMockVisit = {
  'bodyTemperature': '38.3',
  'complication': '',
  'dateTime': '2019-10-22T13:32',
  'note': 'Ngậm nước muối ngày 2 lần',
  'prescribedDrugs': [{
    'id': '1',
    'instruction': 'Uống ngày 3 lần, mỗi lần 2 viên',
    'name': 'Acemuc 250mg',
    'quantity': '4 viên',
  }, {
    'id': '2',
    'instruction': '1 lần sốt nhét hậu môn 1 viên',
    'name': 'Efferalgan 150mg',
    'quantity': '6 viên',
  }, {
    'id': '3',
    'instruction': 'Uống 1 viên khi cần',
    'name': 'Acemuc 1000mg',
    'quantity': '2 viên',
  }, {
    'id': '4',
    'instruction': 'Uống ngày 3 lần, mỗi lần 2 viên',
    'name': 'Acemuc 250mg',
    'quantity': '4 viên',
  }, {
    'id': '5',
    'instruction': '1 lần sốt nhét hậu môn 1 viên',
    'name': 'Efferalgan 150mg',
    'quantity': '6 viên',
  }, {
    'id': '6',
    'instruction': 'Uống 1 viên khi cần',
    'name': 'Acemuc 1000mg',
    'quantity': '2 viên',
  }],
  'primaryDiagnosis': {
    'english': 'Acute bronchitis',
    'id': 'J20',
    'vietnamese': 'Viêm phế quản cấp',
  },
  'revisitDate': '2019-10-27',
  'secondaryDiagnoses': [],
  'weight': '28',
};