import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export const PatientReadOnly = props => {
  const {patient} = props
  return (
    <Grid container spacing={1}>
      {!!patient.phoneNumber && (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <Typography variant='body1'>Điện thoại</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant='body1'>{patient.phoneNumber}</Typography>
          </Grid>
        </React.Fragment>
      )}
      {!!patient.address && (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <Typography variant='body1'>Địa chỉ</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant='body1'>{patient.address}</Typography>
          </Grid>
        </React.Fragment>
      )}
      {!!patient.note && (
        <React.Fragment>
          <Grid item xs={12} sm={3}>
            <Typography variant='body1'>Ghi chú</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant='body1'>{patient.note}</Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

PatientReadOnly.propTypes = {
  patient: PropTypes.object.isRequired
}
