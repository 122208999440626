import { useState } from 'react'
import firebase from 'firebase'
import { firestoreRecordToVisit } from '../utils/visit'
import { useUserId } from './useUserId'

export const usePrescriptionPage = (username, visitId) => {
  const [userId, isUserIdLoading, isUserIdError] = useUserId(username)
  const [isPrescriptionLoading, setIsPrescriptionLoading] = useState(false)
  const [isPrescriptionError, setIsPrescriptionError] = useState(false)
  const [visit, setVisit] = useState(null)
  const [template, setTemplate] = useState(null)
  const isLoading = isUserIdLoading || isPrescriptionLoading
  const isError = isUserIdError || isPrescriptionError

  const fetchPrescription = async () => {
    setIsPrescriptionLoading(true)
    try {
      const visit = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('visits')
        .doc(visitId)
        .get()
      const template = await firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('settings')
        .doc('prescription')
        .get()
      if (visit.exists && template.exists) {
        setVisit(firestoreRecordToVisit(visit.data()))
        setTemplate(template.data())
        setIsPrescriptionLoading(false)
        setIsPrescriptionError(false)
      } else {
        setIsPrescriptionLoading(false)
        setIsPrescriptionError(true)
      }
    } catch (e) {
      console.error(e)
      setIsPrescriptionLoading(false)
      setIsPrescriptionError(true)
    }
  }

  if (userId && !isLoading && !isError && !visit && !template) {
    fetchPrescription().then()
  }

  return [visit, template, isLoading, isError]
}
