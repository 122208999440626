import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { ConfirmDialog } from './ConfirmDialog'
import { Gender } from '../constants/GenderEnum'
import { calculateAge, getFullName } from '../utils/patient'
import { VisitList } from './VisitList'
import BoyAvatar from '../assets/boy-default-512x512.png'
import GirlAvatar from '../assets/girl-default-512x512.png'
import DefaultAvatar from '../assets/default-512x512.png'
import { PatientEdit } from './PatientEdit'
import { getPatientRecordInVisit } from '../utils/firebase'
import firebase from 'firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AppContext } from '../App'
import { validatePatient } from '../pages/AddVisitPage/validation'
import { PatientReadOnly } from './PatientReadOnly'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    padding: '0 16px',
  },
  contentItem: {
    padding: '8px 0',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    width: 60,
    height: 60,
  },
}))

export const PatientView = props => {
  const {patient, onUnselect, defaultExpanded = false} = props
  const classes = useStyles()
  const {getFirestoreRef} = useContext(AppContext)
  const [patientErrorProps, setPatientErrorProps] = useState({})
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)
  const [draftPatient, setDraftPatient] = useState(null)
  const [isUpdatingPatient, setIsUpdatingPatient] = useState(false)
  const dateOfBirthString = moment(patient.dateOfBirth)
    .format('DD/MM/YYYY') + ' • ' + calculateAge(patient.dateOfBirth)
  const genderString = patient.gender === Gender.MALE ? ' • Nam' : (patient.gender === Gender.FEMALE ? ' • Nữ' : '')
  const subHeaderString = dateOfBirthString + genderString

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded)
  }

  const deletePatient = async () => {
    const batchWrite = firebase.firestore().batch()
    batchWrite.delete(getFirestoreRef('patients').doc(props.patient.id))
    const snapshot = await getFirestoreRef('visits').where('patient.id', '==', props.patient.id).get()
    snapshot.forEach(doc => {
      const visitDate = moment(doc.data().dateTime.toDate()).format('YYYY-MM-DD')
      const visitCountRef = getFirestoreRef('dashboard').doc('visit_count')
      const updatedVisitCount = {[visitDate]: firebase.firestore.FieldValue.increment(-1)}
      batchWrite.set(visitCountRef, updatedVisitCount, {merge: true})
      batchWrite.delete(doc.ref)
    })
    await batchWrite.commit()

    if (props.onDelete) {
      props.onDelete()
    }
  }

  const onSave = async () => {
    const [isPatientValid, patientError] = validatePatient(draftPatient)
    setPatientErrorProps(patientError)
    if (isPatientValid) await saveUpdatedPatient()
  }

  const onEditCancel = () => {
    setDraftPatient(null)
    setPatientErrorProps({})
  }

  const saveUpdatedPatient = async () => {
    setIsUpdatingPatient(true)
    const batchWrite = firebase.firestore().batch()
    const firestoreServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()
    const snapshot = await getFirestoreRef('visits').where('patient.id', '==', props.patient.id).get()
    const updatedFirestorePatient = {
      ...draftPatient,
      lastVisit: {
        ...draftPatient.lastVisit,
        dateTime: moment(draftPatient.lastVisit.dateTime).toDate(),
      },
      updatedAt: firestoreServerTimestamp,
    }

    batchWrite.set(getFirestoreRef('patients').doc(props.patient.id), updatedFirestorePatient)

    snapshot.forEach(doc => {
      const updatedVisit = {
        ...doc.data(),
        patient: getPatientRecordInVisit(updatedFirestorePatient),
        updatedAt: firestoreServerTimestamp,
      }

      batchWrite.set(doc.ref, updatedVisit)
    })

    await batchWrite.commit()
    setIsUpdatingPatient(false)
    setDraftPatient(null)

    if (props.onEdit) {
      props.onEdit(updatedFirestorePatient)
    }
  }

  const renderReadOnlyActions = () => (
    <React.Fragment>
      <IconButton onClick={onUnselect}>
        <ChevronLeftIcon/>
      </IconButton>
      <IconButton onClick={() => setDraftPatient({...patient})}>
        <EditIcon/>
      </IconButton>
      <ConfirmDialog title={'Xoá bệnh nhân?'}
                     content={'Xoá bệnh nhân và tất cả những lần khám của bệnh nhân này.'}
                     onAccept={deletePatient}>
        <IconButton>
          <DeleteIcon/>
        </IconButton>
      </ConfirmDialog>
      <IconButton className={clsx(classes.expand, {[classes.expandOpen]: isExpanded})}
                  onClick={handleExpandClick}>
        <ExpandMoreIcon/>
      </IconButton>
    </React.Fragment>
  )

  const renderEditActions = () => (
    <React.Fragment>
      <IconButton disabled={isUpdatingPatient} onClick={onSave}>
        {isUpdatingPatient && <CircularProgress size={24}/>}
        {!isUpdatingPatient && <DoneIcon/>}
      </IconButton>
      <IconButton disabled={isUpdatingPatient} onClick={onEditCancel}>
        <CloseIcon/>
      </IconButton>
    </React.Fragment>
  )

  return (
    <Card>
      <CardHeader title={getFullName(patient)}
                  titleTypographyProps={{variant: 'h6', color: 'primary'}}
                  subheader={subHeaderString}
                  avatar={
                    <Avatar className={classes.avatar} src={
                      patient.gender === Gender.MALE ? BoyAvatar :
                        (patient.gender === Gender.FEMALE ? GirlAvatar : DefaultAvatar)
                    }/>
                  }
      />
      <CardContent className={classes.contentRoot}>
        {!draftPatient && (<PatientReadOnly patient={patient}/>)}
        {!!draftPatient && (
          <PatientEdit draftPatient={draftPatient}
                       onChange={setDraftPatient}
                       errorProps={patientErrorProps}/>
        )}
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {!draftPatient && renderReadOnlyActions()}
        {!!draftPatient && renderEditActions()}
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <VisitList patient={patient}/>
        </CardContent>
      </Collapse>
    </Card>
  )

}

PatientView.propTypes = {
  patient: PropTypes.object.isRequired,
  onUnselect: PropTypes.func.isRequired,
  defaultExpanded: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}
