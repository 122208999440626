import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { getNormalisedText, trimAndCapitalised } from '../utils/string';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { AsyncTagInput } from './TagInput/AsyncTagInput';
import { AppContext } from '../App';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  headerWithSpace: {
    marginTop: 40,
  },
  drugButton: {
    margin: theme.spacing(1),
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  drugContainer: {
    marginTop: 32,
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  removeDrugButton: {
    cursor: 'pointer',
  },
  spaceDivider: {
    margin: '16px 0',
  },
  drugQuantity: {
    marginTop: 4,
  },
}));

export const DrugInput = props => {
  const {getFirestoreRef} = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState(null);
  const classes = useStyles();

  const onDrugChanged = (changedDrugIndex, updatedField) => {
    const drugs = props.drugs.map((drug, drugIndex) => {
      if (drugIndex === changedDrugIndex) {
        return {...drug, ...updatedField};
      }
      return drug;
    });
    props.onChange(drugs);
  };

  const onAddDrug = () => {
    const drugs = [...props.drugs, {quantity: '', instruction: ''}];
    props.onChange(drugs);
  };

  const onRemoveDrug = () => {
    const drugs = [...props.drugs];
    drugs.pop();
    props.onChange(drugs);
  };

  const drugToTag = drug => {
    if (!drug.name) return null;

    const isNewDrug = !drug.id;
    return {value: isNewDrug ? drug.name : drug.id, label: drug.name, drug};
  };

  const onSelectDrug = (drugIndex, tag) => {
    if (!tag) return onDrugChanged(drugIndex, {id: null, name: null});

    const isNewDrug = tag.value === tag.label;
    const {instruction, quantity} = props.drugs[drugIndex];
    const shouldSetDefaultInstruction = !isNewDrug;
    const shouldSetDefaultQuantity = !isNewDrug;

    onDrugChanged(drugIndex, {
      ...tag.drug,
      id: isNewDrug ? null : tag.value,
      name: trimAndCapitalised(tag.label),
      instruction: shouldSetDefaultInstruction ? tag.drug.defaultUsage.instruction : instruction,
      quantity: shouldSetDefaultQuantity ? tag.drug.defaultUsage.quantity : quantity,
    });
  };

  const updateSearchQuery = searchInput => {
    if (!searchInput) return setSearchQuery(null);
    const drugNameSearch = getNormalisedText(searchInput);
    const searchQuery = getFirestoreRef('drugs')
      .where('nameSearch', '>=', drugNameSearch)
      .orderBy('nameSearch').limit(20);
    setSearchQuery(searchQuery);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" color={'primary'} gutterBottom className={classes.headerWithSpace}>
        Đơn thuốc
      </Typography>
      {props.drugs.map((drug, drugIndex) => (
        <React.Fragment key={'drug-input-' + drugIndex}>
          {drugIndex > 0 ? <div className={classes.spaceDivider}/> : null}
          <Paper className={clsx(classes.root, classes.grow)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={9}>
                <FormControl fullWidth>
                  <AsyncTagInput label='Tên thuốc và hàm lượng' isMulti={false}
                                 value={drugToTag(drug)}
                                 handleChange={tag => onSelectDrug(drugIndex, tag)}
                                 handleInputChange={updateSearchQuery}
                                 query={searchQuery}
                                 resultToTag={drugToTag}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField className={classes.drugQuantity} label="Tổng liều" fullWidth
                           autoComplete="drug-quantity" InputLabelProps={{shrink: true}}
                           value={drug.quantity}
                           onChange={e => onDrugChanged(drugIndex, {quantity: e.target.value})}/>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Hướng dẫn" fullWidth
                           autoComplete="drug-instruction" InputLabelProps={{shrink: true}}
                           value={drug.instruction}
                           onChange={e => onDrugChanged(drugIndex, {instruction: e.target.value})}/>
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      ))}
      <div className={classes.centeredContent}>
        <Button
          aria-label='Add' variant='contained' color='primary'
          className={classes.drugButton} onClick={onAddDrug} startIcon={<AddIcon/>}>
          Thêm thuốc
        </Button>
        <Button
          aria-label='Remove' variant='contained' color='primary'
          disabled={props.drugs.length === 0}
          className={classes.drugButton} onClick={onRemoveDrug} startIcon={<RemoveIcon/>}>
          Xoá thuốc
        </Button>
      </div>
    </React.Fragment>
  );
};

DrugInput.propTypes = {
  drugs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
