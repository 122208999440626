import React, { useEffect, useState } from 'react'
import { PatientEdit } from '../components/PatientEdit'
import { emptyDraftPatient } from '../utils/patient'
import { makeStyles, Paper } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Home, HowToReg } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import { useUserId } from '../hooks/useUserId'
import { validateBooking } from './AddVisitPage/validation'
import { useBooking } from '../hooks/useBooking'
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from 'firebase'
import { useOpeningHours } from '../hooks/useOpeningHours'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  spaceDivider: {
    padding: theme.spacing(2, 0),
  },
  circularProgress: {
    color: 'white'
  }
}))

export const BookingPage = props => {
  const {username} = props.match.params
  const [userId] = useUserId(username)
  const [draftPatient, setDraftPatient] = useState(emptyDraftPatient())
  const [visitDate, setVisitDate] = useState(new Date())
  const [errorProps, setErrorProps] = useState({})
  const [addToQueue, isLoading, isError] = useBooking(userId)
  const [openingHours] = useOpeningHours(userId, 90)
  const [queueNumber, setQueueNumber] = useState(null)
  const classes = useStyles()

  const register = async () => {
    const visitDateString = format(visitDate, 'yyyy-MM-dd')
    const [valid, errorProps] = validateBooking(draftPatient)
    setErrorProps(errorProps)
    if (valid) {
      const number = await addToQueue(visitDateString, draftPatient)
      setQueueNumber(number)
      firebase.analytics().logEvent('booking_register_success', {
        username,
        visitDateString,
        number,
        timestamp: new Date(),
      })
    } else {
      firebase.analytics().logEvent('booking_register_error', {
        username,
        visitDateString,
        draftPatient,
        errorProps,
        timestamp: new Date(),
      })
    }
  }

  const redirectToHome = () => {
    props.history.push(`/${username}`)
  }

  useEffect(() => {
    firebase.analytics().logEvent('booking_page_view', {
      username,
      timestamp: new Date(),
    })
  })

  return (
    <>
      <Container maxWidth="md">
        <Paper className={classes.root} elevation={3}>
          {!queueNumber && (
            <>
              <Typography variant="h6" color={'primary'} gutterBottom>
                Chọn ngày khám
              </Typography>
              <Grid item xs={12}>
                <Grid container>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Ngày khám"
                      onChange={setVisitDate}
                      value={visitDate}
                      disablePast
                      shouldDisableDate={(d => {
                        let month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear()
                        if (month.length < 2) month = '0' + month
                        if (day.length < 2) day = '0' + day
                        const dateStr = [year, month, day].join('-')
                        return !openingHours[dateStr] || openingHours[dateStr].length === 0
                      })}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <div className={classes.spaceDivider}/>
              <Typography variant="h6" color={'primary'} gutterBottom>
                Thông tin bệnh nhân
              </Typography>
              <PatientEdit
                draftPatient={draftPatient}
                onChange={setDraftPatient}
                showNoteField={false}
                showAddressField={false}
                showNameFields={false}
                showPlaceholder={true}
                errorProps={errorProps}/>
              <div className={classes.spaceDivider}/>
              <Grid item xs={12}>
                <Grid container justify={'center'}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={
                      isLoading
                        ? <CircularProgress size={20} className={classes.circularProgress}/>
                        : <HowToReg/>
                    }
                    onClick={register}
                  >
                    Đăng ký
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {queueNumber && !isLoading && !isError && (
            <>
              <Typography variant="h4" color={'primary'} gutterBottom>
                Đăng ký thành công
              </Typography>
              <div className={classes.spaceDivider}/>
              <Typography variant="subtitle1" gutterBottom>
                Mã số đăng ký: {queueNumber}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ngày khám: {format(visitDate, 'dd/MM/yyyy')}<br/>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Tin nhắn xác nhận sẽ được gửi qua số điện thoại sau ít phút.
              </Typography>
              <div className={classes.spaceDivider}/>
              <Grid item xs={12}>
                <Grid container justify={'center'}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Home/>}
                    onClick={redirectToHome}
                  >
                    Trang chủ
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Container>
    </>
  )
}
