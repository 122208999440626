import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'
import PrintIcon from '@material-ui/icons/Print'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import clsx from 'clsx'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { PatientView } from '../../components/PatientView'
import { PatientList } from '../../components/PatientList'
import { PrescriptionToPrint } from '../../components/PrescriptionToPrint'
import { VisitEdit } from '../../components/VisitEdit'
import firebase from 'firebase'
import {
  prepareFirestoreDrugs,
  prepareFirestorePatient,
  prepareFirestoreSymptoms,
  prepareFirestoreVisit,
  prepareFirestoreVisitCount,
} from './firebase'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { PatientEdit } from '../../components/PatientEdit'
import { useAtBottomPage } from '../../hooks/useAtBottomPage'
import { useSearchPatients } from '../../hooks/useSearchPatients'
import { AppContext } from '../../App'
import { validatePatient, validateVisit } from './validation'
import Button from '@material-ui/core/Button'
import { sendPrescriptionViaSms } from '../../utils/sms'
import { useUsername } from '../../hooks/useUsername'
import { emptyDraftPatient } from '../../utils/patient'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  spaceDivider: {
    padding: theme.spacing(2, 0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  sendSmsButton: {
    marginRight: theme.spacing(2),
  },
  circularProgress: {
    color: 'white',
  },
}))

export const AddVisitPage = (props) => {
  const getBookingPatient = () => {
    const {bookingPatient = null} = props.history.location.state || {}
    props.history.location.state = undefined
    return bookingPatient
  }

  const emptyDraftVisit = () => ({
    dateTime: moment().format('YYYY-MM-DDTHH:mm'),
    weight: '',
    bodyTemperature: '37',
    symptoms: [],
    primaryDiagnosis: null,
    secondaryDiagnoses: [],
    complication: '',
    prescribedDrugs: [],
    revisitDate: null,
    note: '',
  })

  const {authUser = {}, getFirestoreRef} = useContext(AppContext)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [draftPatient, setDraftPatient] = useState(getBookingPatient() || emptyDraftPatient())
  const [draftVisit, setDraftVisit] = useState(emptyDraftVisit)
  const [username] = useUsername(authUser.uid)
  const [savingStatus, setSavingStatus] = useState({
    isSms: false,
    loading: false,
    success: false,
  })
  const [
    searchResultPatients,
    isSearchingPatient,
    requestMoreResults,
    refreshSearchResultPatients,
  ] = useSearchPatients(draftPatient)
  const isAtBottomPage = useAtBottomPage()
  const classes = useStyles()
  const prescriptionRef = useRef(null)
  const hasSearchResult = searchResultPatients.length > 0
  const shouldDisplayVisitForm = !!selectedPatient || (!isSearchingPatient && !hasSearchResult)
  const [patientErrorProps, setPatientErrorProps] = useState({})
  const [visitErrorProps, setVisitErrorProps] = useState({})

  useEffect(() => {
    if (isAtBottomPage && !shouldDisplayVisitForm) requestMoreResults()
  }, [isAtBottomPage, requestMoreResults, shouldDisplayVisitForm])

  const validatePatientAndVisit = () => {
    const [isPatientValid, patientError] = selectedPatient ? [true, {}] : validatePatient(draftPatient)
    const [isVisitValid, visitError] = validateVisit(draftVisit)
    setPatientErrorProps(patientError)
    setVisitErrorProps(visitError)

    if (!isPatientValid || !isVisitValid) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      return Promise.reject('Validation error')
    }

    return isPatientValid && isVisitValid
  }

  const saveVisit = async (isSms = false) => {
    const patient = selectedPatient ? selectedPatient : draftPatient
    if (!savingStatus.loading) {
      setSavingStatus({isSms, success: false, loading: true})
      const visitId = await submitNewVisit(patient, draftVisit)
      if (isSms) {
        await sendPrescriptionViaSms(username, patient.phoneNumber, visitId, getFirestoreRef)
      }
      setSavingStatus({isSms, success: true, loading: false})
      setTimeout(resetStateAndScrollTop, 1000)
    }
  }

  const renderSaveButton = (isSms) => {
    const {loading, success} = savingStatus
    const buttonClassname = clsx({
      [classes.buttonSuccess]: success && isSms === savingStatus.isSms,
      [classes.sendSmsButton]: isSms,
    })

    return (
      <Button
        variant='contained'
        color='primary'
        className={buttonClassname}
        onClick={async () => {
          if (isSms) {
            await validatePatientAndVisit()
            await saveVisit(isSms)
          }
        }}
        startIcon={isSms === savingStatus.isSms && success ? (
          <CheckIcon/>
        ) : (isSms === savingStatus.isSms && loading ? (
          <CircularProgress size={20} className={classes.circularProgress}/>
        ) : (isSms ? (
          <PhoneIphoneIcon/>
        ) : (
          <PrintIcon/>
        )))}
      >
        {isSms ? 'Gửi' : 'In'} đơn thuốc
      </Button>
    )
  }

  const resetStateAndScrollTop = () => {
    setSelectedPatient(null)
    setDraftPatient(emptyDraftPatient())
    setDraftVisit(emptyDraftVisit())
    setSavingStatus({isSms: false, success: false, loading: false})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const submitNewVisit = async (patient, visit) => {
    const batchWrite = firebase.firestore().batch()
    const preparedFirestoreSymptoms = prepareFirestoreSymptoms(visit, getFirestoreRef)
    const preparedFirestoreDrugs = prepareFirestoreDrugs(visit, getFirestoreRef)
    const [visitRef, firestoreVisit] = prepareFirestoreVisit(patient, visit, preparedFirestoreSymptoms, preparedFirestoreDrugs, getFirestoreRef)
    const [patientRef, firestorePatient] = prepareFirestorePatient(patient, firestoreVisit, getFirestoreRef)
    const [visitCountRef, firestoreVisitCount] = prepareFirestoreVisitCount(visit, getFirestoreRef)

    batchWrite.set(visitRef, firestoreVisit)
    batchWrite.set(patientRef, firestorePatient)
    preparedFirestoreSymptoms.map(([symptomRef, firestoreSymptom]) => batchWrite.set(symptomRef, firestoreSymptom))
    preparedFirestoreDrugs.map(([drugRef, firestoreDrug]) => batchWrite.set(drugRef, firestoreDrug))
    batchWrite.set(visitCountRef, firestoreVisitCount, {merge: true})
    await batchWrite.commit()
    return visitRef.id
  }

  const unselectPatient = () => setSelectedPatient(null)

  const onSelectedPatientDelete = () => {
    refreshSearchResultPatients()
    unselectPatient()
  }

  const onSelectedPatientEdit = updatedPatient => {
    refreshSearchResultPatients()
    setSelectedPatient(updatedPatient)
  }

  const renderNewPatientForm = () => (
    <Paper className={classes.root} elevation={1}>
      <Typography variant="h6" color={'primary'} gutterBottom>
        Bệnh nhân
      </Typography>
      <PatientEdit draftPatient={draftPatient} onChange={setDraftPatient}
                   errorProps={patientErrorProps}/>
    </Paper>
  )

  return (
    <div>
      {!selectedPatient ?
        renderNewPatientForm() :
        <PatientView patient={selectedPatient}
                     onUnselect={unselectPatient}
                     onEdit={onSelectedPatientEdit}
                     onDelete={onSelectedPatientDelete}/>}
      <div className={classes.spaceDivider}/>
      {shouldDisplayVisitForm ? (
        <React.Fragment>
          <VisitEdit draftVisit={draftVisit} onChange={setDraftVisit} errorProps={visitErrorProps}/>
          <div className={classes.spaceDivider}/>
          <div className={classes.wrapper}>
            {renderSaveButton(true)}
            <ReactToPrint
              trigger={() => renderSaveButton(false)}
              content={() => prescriptionRef.current}
              pageStyle='@page { size: A5; } @media print { body { -webkit-print-color-adjust: exact; } }'
              onBeforeGetContent={validatePatientAndVisit}
              onAfterPrint={() => saveVisit(false)}
            />
          </div>
          <div hidden={true}>
            <div ref={prescriptionRef}>
              <PrescriptionToPrint patient={selectedPatient ? selectedPatient : draftPatient}
                                   visit={draftVisit}/>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PatientList
            title={'Kết quả tìm kiếm'}
            isSearching={isSearchingPatient}
            patients={searchResultPatients}
            onSelect={setSelectedPatient}/>
        </React.Fragment>
      )}
    </div>
  )
}
