import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
  },
  languageSelector: {
    display: 'flex',
  },
  englishButton: {
    marginRight: '2em',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  vietnameseButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  selected: {
    fontWeight: 'bold',
  },
}));

export const LanguageSelector = props => {
  const classes = useStyles();
  const {i18n} = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.languageSelector}>
        <div className={clsx(classes.englishButton, i18n.language === 'en' && classes.selected)}
             onClick={() => i18n.changeLanguage('en')}>English
        </div>
        <div className={clsx(classes.vietnameseButton, i18n.language === 'vi' && classes.selected)}
             onClick={() => i18n.changeLanguage('vi')}>
          Tiếng Việt
        </div>
      </div>
    </div>
  );
};

LanguageSelector.propTypes = {};
