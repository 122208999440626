import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { SymptomInput } from './SymptomInput'
import { DiseaseInput } from './DiseaseInput'
import { makeStyles } from '@material-ui/core'
import { DrugInput } from './DrugInput'
import MomentUtils from '@date-io/moment'
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  headerWithSpace: {
    marginTop: 40,
  },
}))

export const VisitEdit = (props) => {
  const classes = useStyles()
  const {draftVisit, errorProps} = props

  const onDraftVisitChange = (updatedField) => {
    props.onChange({...draftVisit, ...updatedField})
  }

  const onRevisitToggle = isEnabled => {
    if (isEnabled) {
      const threeDaysLater = moment().add(5, 'days').format('YYYY-MM-DD')
      onDraftVisitChange({revisitDate: threeDaysLater})
    } else {
      onDraftVisitChange({revisitDate: null})
    }
  }

  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h6" color={'primary'} gutterBottom>
          Chi tiết khám
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                fullWidth autoOk variant="inline" label="Ngày khám" disableFuture
                value={draftVisit.dateTime}
                onChange={timeMoment => onDraftVisitChange({
                  dateTime: timeMoment.format('YYYY-MM-DDTHH:mm'),
                })}
                format="DD/MM/YYYY hh:mm a"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <TextField fullWidth label="Cân nặng" type="number" required
                       InputProps={{
                         endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                       }}
                       value={draftVisit.weight}
                       onChange={e => onDraftVisitChange({
                         weight: e.target.value,
                       })}
                       {...errorProps.weight}/>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <TextField fullWidth label="Nhiệt độ" type="number" required
                       InputProps={{
                         endAdornment: <InputAdornment position="start">°C</InputAdornment>,
                       }}
                       value={draftVisit.bodyTemperature}
                       onChange={e => onDraftVisitChange({
                         bodyTemperature: e.target.value,
                       })}
                       {...errorProps.bodyTemperature}/>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SymptomInput symptoms={draftVisit.symptoms}
                            onChange={symptoms => onDraftVisitChange({symptoms})}/>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" color={'primary'} gutterBottom className={classes.headerWithSpace}>
          Chẩn đoán
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DiseaseInput label={'Bệnh chính'} isMulti={false}
                            disease={draftVisit.primaryDiagnosis}
                            onChange={primaryDiagnosis => onDraftVisitChange({
                              primaryDiagnosis,
                            })}/>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DiseaseInput label={'Bệnh kèm'} isMulti={true}
                            disease={draftVisit.secondaryDiagnoses}
                            onChange={secondaryDiagnoses => onDraftVisitChange({
                              secondaryDiagnoses,
                            })}/>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Biến chứng" fullWidth InputLabelProps={{shrink: true}}
                       value={draftVisit.complication}
                       onChange={e => onDraftVisitChange({
                         complication: e.target.value,
                       })}/>
          </Grid>
        </Grid>
        <DrugInput drugs={draftVisit.prescribedDrugs}
                   onChange={prescribedDrugs => onDraftVisitChange({prescribedDrugs})}/>
        <Typography variant="h6" color={'primary'} gutterBottom className={classes.headerWithSpace}>
          Ghi chú
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormGroup row>
              <FormControlLabel
                control={<Switch checked={!!draftVisit.revisitDate}
                                 onChange={e => onRevisitToggle(e.target.checked)}
                                 color="primary"/>}
                label="Tái khám"/>
            </FormGroup>
          </Grid>
          {!!draftVisit.revisitDate && (
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  views={['year', 'month', 'date']}
                  autoOk variant={'inline'} format={'DD/MM/YYYY'}
                  disablePast
                  label={'Ngày tái khám'}
                  value={moment(draftVisit.revisitDate)}
                  onChange={dateMoment => onDraftVisitChange({
                    revisitDate: dateMoment.format('YYYY-MM-DD'),
                  })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField label="Lời dặn" fullWidth multiline
                       value={draftVisit.note}
                       onChange={e => onDraftVisitChange({note: e.target.value})}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}

VisitEdit.propTypes = {
  draftVisit: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errorProps: PropTypes.object.isRequired,
}
