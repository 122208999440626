import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import PatientSearchResultSingle from './PatientSearchResultSingle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  spaceDivider: {
    margin: '2em 0',
  },
  progressContainer: {
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const PatientList = props => {
  const classes = useStyles();
  const {patients, onSelect, isSearching, title} = props;

  return (
    <>
      <div>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <SearchIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title}/>
          </ListItem>
        </List>
        {patients.map((patient, index) => (
          <React.Fragment key={'patient-search-result-single-' + index}>
            <PatientSearchResultSingle patient={patient} onSelect={onSelect}/>
            <div className={classes.spaceDivider}/>
          </React.Fragment>
        ))}
      </div>
      <div className={classes.progressContainer}>
        {isSearching && <CircularProgress/>}
        {!isSearching && patients.length === 0 && <Typography>Không có kết quả</Typography>}
      </div>
    </>
  );
};

PatientList.propTypes = {
  patients: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
