import React, { useContext } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { RootDrawer } from './RootDrawer'
import InitialLoader from '../components/InitialLoader/InitialLoader'
import { SignInPage } from './SignInPage'
import { PrivateRoute } from '../components/PrivateRoute'
import { SignUpPage } from './SignUpPage/SignUpPage'
import { AppContext } from '../App'
import { PrescriptionPage } from './PrescriptionPage'
import { DoctorHomePage } from './DoctorHomePage'
import { BookingPage } from './BookingPage'

export const Router = props => {
  const {isLoading} = useContext(AppContext)

  return (
    <BrowserRouter>
      <div>
        <Switch>
          {(isLoading === undefined || isLoading) && <Route path="/" component={InitialLoader}/>}
          <PrivateRoute path="/(add|queue|recent|dashboard|symptoms|drugs|settings)/" component={RootDrawer}/>
          <PrivateRoute exact path="/" component={RootDrawer}/>
          <Route exact path="/signin/" component={SignInPage}/>
          <Route exact path="/signup/" component={SignUpPage}/>
          <Route exact path="/:username/" component={DoctorHomePage}/>
          <Route exact path="/:username/p/:visitId/" component={PrescriptionPage}/>
          <Route exact path="/:username/r/" component={BookingPage}/>
        </Switch>
      </div>
    </BrowserRouter>
  )
}
