import PropTypes from 'prop-types';
import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

class ColumnChart extends React.PureComponent {

  render() {
    const {data, unit} = this.props;
    return (
      <ResponsiveContainer height={280}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis allowDecimals={false}/>
          <Tooltip/>
          <Bar name={unit} dataKey="value" fill="#8884d8"/>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

ColumnChart.propTypes = {
  data: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
};

export default ColumnChart;
