import moment from 'moment';
import i18n from '../translation';
import { Gender } from '../constants/GenderEnum'

export const emptyDraftPatient = () => ({
  lastName: '',
  middleName: '',
  firstName: '',
  dateOfBirth: moment().format('YYYY-MM-DD'),
  gender: Gender.UNKNOWN,
  phoneNumber: '',
  address: '',
  note: '',
});

export const getFullName = patient => {
  if (!patient) return '';

  return [
    patient.lastName,
    patient.middleName,
    patient.firstName,
  ].filter(Boolean).join(' ');
};

export const reformatName = nameString => {
  return nameString
    .trim() // Removed white-space
    .split(' ')
    .filter(Boolean) // Only one white-space between words
    .map(namePart =>
      namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase(),
    ) // Capitalised first character of each word
    .join(' ');
};

export const calculateAge = (dateOfBirth, toDate = null) => {
  if (!toDate) {
    toDate = moment();
  } else {
    toDate = moment(toDate);
  }

  const yearsDiff = toDate.diff(dateOfBirth, 'years');
  const monthsDiff = toDate.diff(dateOfBirth, 'months');
  const daysDiff = toDate.diff(dateOfBirth, 'days');
  if (yearsDiff >= 6) {
    return yearsDiff + ' ' + i18n.t('yearsOld');
  } else if (monthsDiff >= 2) {
    return monthsDiff + ' ' + (i18n.t('monthsOld'));
  } else if (daysDiff >= 0) {
    return daysDiff + ' ' + (i18n.t('daysOld'));
  }
  return '';
};
