import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PrintIcon from '@material-ui/icons/Print'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { PrescriptionToPrint } from './PrescriptionToPrint'
import { getDiseaseTextWithCode } from '../utils/disease'
import { calculateAge } from '../utils/patient'
import { CircularProgress } from '@material-ui/core'
import { useFetchAllPatientVisit } from '../hooks/useFetchAllPatientVisits'
import { AppContext } from '../App'
import DoneIcon from '@material-ui/icons/Done'
import { sendPrescriptionViaSms } from '../utils/sms'
import { useUsername } from '../hooks/useUsername'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
  },
}))

export const VisitList = props => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(null)
  const prescriptionRef = useRef({})
  const {patient} = props
  const [visits, isFetchingVisits] = useFetchAllPatientVisit(patient.id)
  const {authUser = {}, getFirestoreRef} = useContext(AppContext)
  const [username] = useUsername(authUser.uid)
  const [smsStatus, setSmsStatus] = useState({isLoading: false, isSuccess: false})

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const resendPrescription = async (visitId) => {
    if (smsStatus.isLoading || smsStatus.isSuccess) {
      return
    }

    setSmsStatus({isLoading: true, isSuccess: false})
    await sendPrescriptionViaSms(username, patient.phoneNumber, visitId, getFirestoreRef)
    setSmsStatus({isLoading: false, isSuccess: true})
    setTimeout(() => setSmsStatus({isLoading: false, isSuccess: false}), 5000)
  }

  useEffect(() => {
    prescriptionRef.current = {}
  }, [patient])

  return (
    <div className={classes.root}>
      {isFetchingVisits && (
        <div className={classes.circularProgressContainer}>
          <CircularProgress/>
        </div>
      )}
      {!isFetchingVisits && !visits.length && (
        <Typography variant='h6' color={'primary'} gutterBottom>Không có lần khám</Typography>
      )}
      {!isFetchingVisits && !!visits.length && (
        <Typography variant='h6' color={'primary'} gutterBottom>Lần khám gần đây</Typography>
      )}
      {!isFetchingVisits && visits.map((visit, index) => (
        <Accordion
          key={'patient-visit-list-' + index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography className={classes.heading}>
              {moment(visit.dateTime).locale('vi').fromNow()}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {visit.primaryDiagnosis ?
                visit.primaryDiagnosis.id + ' ' + visit.primaryDiagnosis.vietnamese : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={'primary'}>Chi tiết khám</Typography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Typography variant='body1'>Ngày khám</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant='body1'>
                      {moment(visit.dateTime).locale('en').format('DD/MM/YYYY hh:mm a')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Typography variant='body1'>Tuổi lúc khám</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant='body1'>
                      {calculateAge(patient.dateOfBirth, visit.dateTime)}
                    </Typography>
                  </Grid>

                  {!!visit.weight && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Cân nặng</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant='body1'>{visit.weight} kg</Typography>
                      </Grid>
                    </React.Fragment>
                  )}

                  {!!visit.bodyTemperature && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Nhiệt độ</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant='body1'>{visit.bodyTemperature}°C</Typography>
                      </Grid>
                    </React.Fragment>
                  )}

                  {!!visit.symptoms && !!visit.symptoms.length && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Triệu chứng</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant='body1'>
                          {visit.symptoms.map(symptom => symptom.name).join(', ')}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={'primary'}>
                      Chẩn đoán
                    </Typography>
                  </Grid>
                  {!!visit.primaryDiagnosis && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Bệnh chính</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography
                          variant='body1'>{getDiseaseTextWithCode(visit.primaryDiagnosis)}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}

                  {!!visit.secondaryDiagnoses && !!visit.secondaryDiagnoses.length && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Bệnh kèm</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant='body1'>
                          {visit.secondaryDiagnoses.map(getDiseaseTextWithCode).join(', ')}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}

                  {!!visit.complication && (
                    <React.Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant='body1'>Biến chứng</Typography>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Typography variant='body1'>{visit.complication}</Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              {!!visit.prescribedDrugs && !!visit.prescribedDrugs.length && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color={'primary'}>Đơn thuốc</Typography>
                    </Grid>
                    {!!visit.prescribedDrugs && visit.prescribedDrugs.map((prescribedDrug, index) => (
                      <Grid item xs={12} key={'prescribed-drug-' + index}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant='body1'>
                              {index + 1}. {prescribedDrug.name} x {prescribedDrug.quantity}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body2'>
                              {prescribedDrug.instruction}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {(!!visit.revisitDate || !!visit.note) && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color={'primary'}>Ghi chú</Typography>
                    </Grid>
                    {!!visit.revisitDate && (
                      <React.Fragment>
                        <Grid item xs={12} sm={3}>
                          <Typography variant='body1'>Ngày tái khám</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography variant='body1'>
                            {moment(visit.dateTime).locale('en').format('DD/MM/YYYY hh:mm a')}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                    {!!visit.note && (
                      <React.Fragment>
                        <Grid item xs={12} sm={3}>
                          <Typography variant='body1'>Lời dặn</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography variant='body1'>
                            {visit.note}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid container justify='flex-end'>
                <IconButton color='primary' onClick={() => resendPrescription(visit.id)}>
                  {smsStatus.isSuccess
                    ? <DoneIcon/>
                    : (smsStatus.isLoading
                      ? <CircularProgress size={24}/>
                      : <PhoneIphoneIcon/>)}
                </IconButton>
                <ReactToPrint
                  trigger={() => <IconButton color="primary"> <PrintIcon/> </IconButton>}
                  content={() => prescriptionRef.current[index]}
                  pageStyle='@page { size: A5; } @media print { body { -webkit-print-color-adjust: exact; } }'
                />
              </Grid>
              <div hidden={true}>
                <div ref={refNode => prescriptionRef.current[index] = refNode}>
                  <PrescriptionToPrint patient={patient} visit={visit}/>
                </div>
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

VisitList.propTypes = {
  patient: PropTypes.object.isRequired,
}
