import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/vi';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Gender } from '../constants/GenderEnum';
import { calculateAge, getFullName } from '../utils/patient';
import { getCombinedDrugNameAndQuantity } from '../utils/drug';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  normalText: {
    fontFamily: 'Times New Roman',
    color: 'black',
    fontSize: '1.1em',
  },
  headingText: {
    fontFamily: 'Times New Roman',
    color: 'black',
    fontSize: '1.8em',
    fontWeight: 'bold',
  },
  centeredChildren: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    width: '100%',
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  horizontalLine: {
    width: '60%',
  },
  drugs: {
    flexGrow: 1,
  },
}));

export const PrescriptionToPrint = (props) => {
  const classes = useStyles();
  const {settings} = useContext(AppContext);
  const template = props.template || settings?.prescription || {};
  const {t, i18n} = useTranslation();

  const renderDrugs = () => {
    const {prescribedDrugs} = props.visit;
    return (
      <div style={{width: '100%'}}>
        {prescribedDrugs.map((prescribedDrug, index) => {
          return (
            <div key={'prescribed-drug-' + index} className={classes.row}>
              <div className={classes.normalText} style={{width: '1.8em'}}>
                {(index + 1) + '. '}
              </div>
              <div className={classes.normalText} style={{flexGrow: 1}}>
                <div className={clsx(classes.row, classes.normalText)}
                     style={{fontWeight: 'bold'}}>
                  {getCombinedDrugNameAndQuantity(prescribedDrug)}
                </div>
                <div className={clsx(classes.row, classes.normalText)}>
                  {prescribedDrug.instruction}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    const {visit} = props;
    const dateTimeString = t('day') + moment(visit.dateTime).locale(i18n.language).format('LL');
    return (
      <div className={clsx(classes.footer)}>
        <div className={clsx(classes.row)}>
          <div style={{flexGrow: 1}}>
            {!!visit.revisitDate && (
              <div className={clsx(classes.row, classes.normalText)}>
                {t('checkupDate') + ': ' + moment(visit.revisitDate).format('DD/MM/YYYY')}
              </div>
            )}
            {!!visit.note && (
              <div className={clsx(classes.row, classes.normalText)}>
                {t('adviceNote') + ': ' + visit.note}
              </div>
            )}
          </div>
          <div style={{minWidth: '14em'}}>
            <div className={clsx(classes.row, classes.normalText)}
                 style={{justifyContent: 'center'}}>
              {dateTimeString}
            </div>
            <div className={clsx(classes.row, classes.normalText)}
                 style={{justifyContent: 'center'}}>
              {t('doctorResponsible')}
            </div>
            <div className={classes.row} style={{height: '3.5em'}}/>
            <div className={clsx(classes.row, classes.normalText)}
                 style={{justifyContent: 'center', fontSize: '0.9em', fontWeight: 'bold'}}>
              {template.signatureName}
            </div>
            <div className={clsx(classes.row, classes.normalText)}
                 style={{justifyContent: 'center', fontSize: '0.9em'}}>
              {template.signatureTitle}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={classes.row}>
        <div>
          <div className={classes.normalText}>{template.clinicName}</div>
          <div className={classes.normalText}>{template.clinicAddress}</div>
          <div className={classes.normalText}>{template.clinicContact}</div>
        </div>
        <div className={clsx(classes.headingText, classes.centeredChildren)}
             style={{flexGrow: 1, textTransform: 'uppercase'}}>
          {t('prescription')}
        </div>
      </div>
    );
  };

  const renderSummaryInfo = () => {
    const {patient, visit} = props;
    const fullName = getFullName(patient);
    const age = calculateAge(patient.dateOfBirth, visit.dateTime);
    const gender = patient.gender === Gender.MALE ?
      t('male') : (patient.gender === Gender.FEMALE ? t('female') : t('nonBinary'));
    const primaryDiagnosisString = visit.primaryDiagnosis ? visit.primaryDiagnosis.vietnamese : '';
    const secondaryDiagnosesString = visit.secondaryDiagnoses ?
      visit.secondaryDiagnoses.map(disease => disease.vietnamese).join(', ') : '';

    return (
      <div style={{width: '100%'}}>
        <div className={clsx(classes.row, classes.normalText)}>
          {t('patientsFullName') + ': '}
          <span className={classes.normalText} style={{fontWeight: 'bold', marginLeft: '0.2em'}}>
						{fullName.toUpperCase()}
					</span>
        </div>
        <div className={classes.row}>
          <div className={classes.normalText} style={{flexGrow: 1}}>
            {t('age') + ': ' + age}
          </div>
          <div className={classes.normalText} style={{flexGrow: 1}}>
            {t('weight') + ': ' + (visit.weight ? visit.weight + ' kg' : '')}
          </div>
          <div className={classes.normalText} style={{width: '8em'}}>
            {t('gender') + ': ' + gender}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.normalText} style={{flexGrow: 1}}>
            {t('address') + ': ' + patient.address}
          </div>
          <div className={classes.normalText} style={{width: '8em'}}>
            {t('phoneNumber') + ': ' + patient.phoneNumber}
          </div>
        </div>
        {primaryDiagnosisString && (
          <div className={clsx(classes.row, classes.normalText)}>
            {t('primaryDiagnosis') + ': ' + primaryDiagnosisString}
          </div>
        )}
        {secondaryDiagnosesString && (
          <div className={clsx(classes.row, classes.normalText)}>
            {t('secondaryDiagnosis') + ': ' + secondaryDiagnosesString}
          </div>
        )}
        {visit.complication && (
          <div className={clsx(classes.row, classes.normalText)}>
            {t('complication') + ': ' + visit.complication}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div>
        {renderHeader()}
        <br/>
        {renderSummaryInfo()}
        <hr className={classes.horizontalLine}/>
      </div>
      <div className={classes.drugs}>
        {renderDrugs()}
      </div>
      <div>
        <hr className={classes.horizontalLine}/>
        {renderFooter()}
      </div>
    </div>
  );
};

PrescriptionToPrint.propTypes = {
  patient: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  template: PropTypes.object,
};
