import { useState } from 'react'
import firebase from 'firebase'

export const useUsername = (userId) => {
  const [username, setUsername] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getUsername = async () => {
    setIsLoading(true)
    try {
      const snapshot =
        await firebase
          .firestore()
          .collection('usernames')
          .where('userId', '==', userId)
          .get()
      if (!snapshot.empty) {
        snapshot.forEach(doc => {
          setUsername(doc.id)
          setIsLoading(false)
          setIsError(false)
        })
      } else {
        setIsLoading(false)
        setIsError(true)
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setIsError(true)
    }
  }

  if (userId && !isLoading && !isError && !username) {
    getUsername().then()
  }

  return [username, isLoading, isError]
}
