import { useState } from 'react'
import firebase from 'firebase'

export const useClinicInfo = (userId) => {
  const [clinicInfo, setClinicInfo] = useState(null)
  const [lastActive, setLastActive] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getUserPageInfo = async () => {
    setIsLoading(true)
    try {
      const settingsRef = firebase.firestore().collection('users').doc(userId).collection('settings')
      const clinicInfoDoc = await settingsRef.doc('prescription').get()
      const onlineStatusDoc = await settingsRef.doc('onlineStatus').get()
      const clinicInfo = clinicInfoDoc.data()
      const onlineStatus = onlineStatusDoc.data() || {}

      if (clinicInfoDoc.exists) {
        setClinicInfo(clinicInfo)
        setLastActive(onlineStatus.lastActive ? onlineStatus.lastActive.toDate() : null)
        setIsLoading(false)
        setIsError(false)
      } else {
        setIsLoading(false)
        setIsError(true)
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setIsError(true)
    }
  }

  if (userId && !isLoading && !isError && !clinicInfo && !lastActive) {
    getUserPageInfo().then()
  }

  return [clinicInfo, lastActive, isLoading, isError]
}
