import React, { useContext, useEffect, useRef, useState } from 'react'
import { PatientList } from '../components/PatientList'
import { useAtBottomPage } from '../hooks/useAtBottomPage'
import { PatientView } from '../components/PatientView'
import firebase from 'firebase'
import { AppContext } from '../App'
import { useFetchPatients } from '../hooks/useFetchPatients'

export const RecentPage = props => {
  const {getFirestoreRef, authUser = {}} = useContext(AppContext)
  const query = useRef(getFirestoreRef('patients').orderBy('lastVisit.dateTime', 'desc').limit(10))
  const [patients, isSearchingPatient, requestMoreResults, refresh] = useFetchPatients(query.current)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const isAtBottomPage = useAtBottomPage()

  useEffect(() => {
    if (isAtBottomPage) {
      requestMoreResults()
    }
  }, [isAtBottomPage, requestMoreResults])

  useEffect(() => {
    if (authUser.uid) {
      firebase.analytics().logEvent('recent_page_view', {
        userId: authUser.uid,
        timestamp: new Date(),
      })
    }
  }, [authUser])

  return (
    <>
      {!!selectedPatient ? (
        <PatientView
          patient={selectedPatient}
          defaultExpanded={true}
          onUnselect={() => setSelectedPatient(null)}
          onEdit={updatedPatient => {
            setSelectedPatient(updatedPatient)
            refresh()
          }}
          onDelete={refresh}/>
      ) : (
        <PatientList
          title={'Lần khám gần đây'}
          isSearching={isSearchingPatient}
          patients={patients}
          onSelect={setSelectedPatient}/>
      )}
    </>
  )
}

RecentPage.propTypes = {}
