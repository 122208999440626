import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { green } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CakeIcon from '@material-ui/icons/Cake';
import EventNoteIcon from '@material-ui/icons/EventNote';
import moment from 'moment';
import 'moment/locale/vi';
import PropTypes from 'prop-types';
import React from 'react';
import { calculateAge, getFullName } from '../utils/patient';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { getDiseaseTextWithCode } from '../utils/disease';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  spaceDivider: {
    margin: '32px 0',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    marginLeft: theme.spacing(1),
  },
});

class PatientSearchResultSingle extends React.PureComponent {
  render() {
    const {classes, patient, onSelect} = this.props;
    const formattedDateOfBirth = moment(patient.dateOfBirth).format('DD/MM/YYYY');
    const dateOfBirthString = [formattedDateOfBirth, calculateAge(patient.dateOfBirth)].join(' • ');
    const {lastVisit} = patient;
    const diagnosisString = !lastVisit ? 'Không có lần khám' : [
      getDiseaseTextWithCode(lastVisit.primaryDiagnosis),
      moment(lastVisit.dateTime).locale('vi').fromNow(),
    ].join(' • ');

    return (
      <div>
        <Card onClick={() => {
          onSelect(patient);
          window.scrollTo({top: 0, behavior: 'smooth'});
        }}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h5" color={'primary'}>
                {getFullName(patient)}
              </Typography>
              <List>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ListItem className={classes.contentItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <CakeIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Ngày sinh" secondary={dateOfBirthString}/>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ListItem className={classes.contentItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <EventNoteIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Lần khám gần nhất" secondary={diagnosisString}/>
                    </ListItem>
                  </Grid>
                </Grid>
              </List>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

PatientSearchResultSingle.propTypes = {
  classes: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(PatientSearchResultSingle);
