import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TagInput } from './TagInput/TagInput';
import { getNormalisedText } from '../utils/string';
import { Diseases } from '../constants/Diseases';
import { getDiseaseTextWithCode } from '../utils/disease';

export const DiseaseInput = (props) => {
  const [searchResults, setSearchResults] = useState([]);
  const {disease, isMulti, onChange, label} = props;

  const diseaseToTag = disease => {
    if (!disease) return null;

    return {
      label: getDiseaseTextWithCode(disease),
      value: disease.id,
      disease,
    };
  };

  const diseasesToTags = diseases => {
    return diseases.map(disease => diseaseToTag(disease));
  };

  const tagToDisease = tag => tag ? tag.disease : null;

  const tagsToDiseases = tags => tags.map(tag => tagToDisease(tag));

  const getDiseaseSuggestions = searchText => {
    const normalisedSearchText = getNormalisedText(searchText);
    if (!normalisedSearchText) return [];

    return Diseases.filter(disease => {
      const diseaseText = getNormalisedText(disease.id + ' ' + disease.vietnamese);
      return diseaseText.includes(normalisedSearchText);
    }).slice(0, 50);
  };

  return (
    <TagInput label={label} isMulti={isMulti}
              value={isMulti ? diseasesToTags(disease) : diseaseToTag(disease)}
              handleChange={option => {
                if (isMulti) onChange(tagsToDiseases(option));
                else onChange(tagToDisease(option));
              }}
              handleInputChange={searchText => setSearchResults(getDiseaseSuggestions(searchText))}
              options={diseasesToTags(searchResults)}/>
  );
};

DiseaseInput.propTypes = {
  label: PropTypes.string.isRequired,
  disease: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  isMulti: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
