import { useContext, useEffect, useState } from 'react';
import { useFetchResults } from './useFetchResults';
import { AppContext } from '../App';
import { firestoreRecordToVisit } from '../utils/visit';

export const useFetchAllPatientVisit = patientId => {
  const {getFirestoreRef} = useContext(AppContext);
  const [searchPatientQuery, setSearchPatientQuery] = useState(null);
  const [fetchedVisits, isLoading] = useFetchResults(searchPatientQuery);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const query = getFirestoreRef('visits')
      .where('patient.id', '==', patientId)
      .orderBy('dateTime', 'desc');
    setSearchPatientQuery(query);
  }, [getFirestoreRef, patientId]);

  useEffect(() => {
    setPatients(fetchedVisits.map(firestoreRecordToVisit));
  }, [fetchedVisits]);

  return [patients, isLoading];
};
