import { useEffect, useState } from 'react';

export const useAtBottomPage = () => {
  const [isAtBottomPage, setIsAtBottomPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const {documentElement} = document;
      const reachedBottom = window.innerHeight + documentElement.scrollTop + 1 >= documentElement.offsetHeight;
      if (reachedBottom) setIsAtBottomPage(true);
      setIsAtBottomPage(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isAtBottomPage;
};
